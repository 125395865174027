<template>
  <div
    id="login-form"
    class="bg-blue"
  >
    <div class="user_card">
      <div class="d-flex justify-content-center">
        <div class="brand_logo_container">
          <img
            src="/images/kirei_lign_logo.png"
            class="brand_logo"
            alt="Logo"
            height="160"
          >
        </div>
      </div>
      <div class="d-flex justify-content-center ip-info">
        <div class="ip-top-text">
          未登録のIPアドレスからのアクセスです
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="btn-ip">
          {{ ip }}
        </div>
        <br>
      </div>
      <div class="ip-bottom">
        <div class="ip-bottom-text">
          このIPアドレスからのアクセスは記録されました。
        </div>
        <div class="ip-bottom-text">
          アクセスを許可したい場合は、マネージャー権限の
        </div>
        <div class="ip-bottom-text">
          保有者にこのIPアドレスをお伝えください
        </div>
        <a
          class="ip-bottom-logout-text"
          @click="logOut"
        >
          <p>{{ $t('common_page.log_out') }}</p>
        </a>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    data () {
      return {
        ip: 0

      }
    },
    created () {
      this.$store.dispatch('insertIpAddress')
      this.ip = localStorage.getItem('ip')
      this.$store.dispatch('getLoggedInfo').then((result) => {
        if (typeof result.data.results.disallow_ip === 'undefined') {
          localStorage.removeItem('ip')
          this.$store.commit('disallow_ip', false)
        }
      })
    },
    methods: {
      async logOut () {
        this.$store.dispatch('logout').then((result) => {
          this.$router.push({ name: 'auth.login' })
        }).catch((error) => {
          this.validationErrors = error.response.data.errors
        })
      }
    }
  }
</script>
