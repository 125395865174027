<template>
  <div class="setting-v2--background setting-v2__content setting-v2__content--reservation-type">
    <div class="setting-v2__header">
      <div class="setting-v2__header__box">
        <label class="setting-v2__header__box__text">{{ $t('objects.setting.text.reservation_type_setting') }}</label>
      </div>
    </div>
    <div class="setting-v2__main">
      <reservation-type-setting />
    </div>
    <loading />
  </div>
</template>

<script>
  import Loading from '../../../admin/components/Loading'
  import ReservationTypeSetting from '../../../components/setting/v2/ReservationTypeSetting'

  export default {
    name: 'ClinicType',
    components: {
      Loading,
      ReservationTypeSetting
    },
    created () {
      document.title = this.$t('objects.page_title.setting').toString()
    }
  }
</script>
