var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "interview-other-v2 content-inner bg-white" },
    [
      _c("according-block", {
        attrs: { label: _vm.$t("new_text.other"), checked: _vm.show_block },
        on: {
          handleClick: function($event) {
            _vm.show_block = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show_block,
              expression: "show_block"
            }
          ],
          staticClass:
            "interview-other-v2__content arrow-toggle-content page-content"
        },
        [
          _c("div", { staticClass: "form-group row pt-4" }, [
            _c("div", { staticClass: "col-6 pre-block" }, [
              _c(
                "div",
                { staticClass: "item-pre" },
                [
                  _c("div", { staticClass: "question" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("objects.patient.text.other_question.1")
                          )
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "Answer",
                    {
                      attrs: {
                        answer: _vm.others.plan_to_move,
                        "question-id": "plan_to_move"
                      }
                    },
                    [
                      _c("div", { staticClass: "form-group d-flex" }, [
                        _c(
                          "label",
                          { staticClass: "control-label same-width my-0" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("objects.patient.text.when")) + "："
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "control-text my-0" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.others.when_to_move1) +
                              "\n                "
                          ),
                          _c(
                            "span",
                            { staticClass: "interview-other-v2__date-times" },
                            [_vm._v(_vm._s(_vm.$t("new_text.year")))]
                          ),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.others.when_to_move2) +
                              "\n                "
                          ),
                          _c(
                            "span",
                            { staticClass: "interview-other-v2__date-monthly" },
                            [_vm._v(_vm._s(_vm.$t("new_text.around_month")))]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group d-flex" }, [
                        _c(
                          "label",
                          { staticClass: "control-label same-width my-0" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("objects.patient.text.move_destination")
                              ) + "："
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "control-text my-0" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.others.moving_destination) +
                              "\n              "
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6 pre-block" }, [
              _c("div", { staticClass: "item-pre" }, [
                _c("div", { staticClass: "question" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "title" }, [
                      _vm._v(
                        _vm._s(_vm.$t("objects.patient.text.other_question.2"))
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("span", { staticClass: "white-space-wrap" }, [
                      _vm._v(_vm._s(_vm.others.other_requests))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }