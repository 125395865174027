import axios from 'axios'
import { state as auth } from './auth'
import { state as patientState } from './patient'
import { RESERVATION_STATUS } from '../../utils/constants'

export const state = {
  reservations: [],
  sfUserIdsCanPrint: []
}

export const mutations = {
  set_reservations (state, reservations) {
    state.reservations = reservations
  },
  setSfUserIdsCanPrint (state, sfUserIdsCanPrint) {
    state.sfUserIdsCanPrint = sfUserIdsCanPrint
  }
}

export const getters = {
  getReservations (state) {
    return state.reservations
  },
  getSfUserIdsCanPrint (state) {
    return state.sfUserIdsCanPrint
  }
}

export const actions = {
  insertAllocation ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/insertColumn', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getListReservation ({ dispatch, commit }, { date }) {
    return axios.get('/api/reservation', {
      params: {
        date: date
      },
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        commit('set_reservations', res.data.results.reservations)
        commit('setSfUserIdsCanPrint', res.data.results.sfUserIdsCanPrint)

        return res
      })
      .catch(error => {
        throw error
      })
  },
  getListReservationName ({ dispatch, commit }) {
    return axios.get('/api/reservation/patient', {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },

  updateOrderAllocation ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/resetOrderColumn', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  deleteAllocation ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/deleteAllocation', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  checkExistReservation ({ dispatch, commit }, reservationId) {
    return axios.get(`/api/reservation-allocation/${reservationId}/exist-reservation`, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
  },
  getListReservationType ({ dispatch, commit }, isSettingType) {
    return axios.get('/api/reservation/type', {
      params: {
        is_setting_type: isSettingType
      },
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  updateReservation ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/updateReservation', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  addNewReservation ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/store', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  updateReservationInfo ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/update', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  cancelReservation ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/cancel', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        // eslint-disable-next-line eqeqeq
        if (patientState.first_reservation && postData.reservation_id == patientState.first_reservation.id) {
          patientState.first_reservation.status = RESERVATION_STATUS.CANCELLED
        }
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getListDutyMaster ({ dispatch, commit }) {
    return axios.get('/api/duty-master', {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  insertReservationType ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/master/insert', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  updateReservationType ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/master/update', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  deleteReservationType ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/master/delete', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  addReservationMasterTab ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/master/tab/insert', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  updateReservationMasterTab ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/master/tab/update', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  deleteReservationMasterTab ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/master/tab/delete', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },

  updateDutyMaster ({ dispatch, commit }, postData) {
    return axios.post('/api/duty-master/update', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },

  getListReservationSecretariat () {
    return axios.get('/api/reservation/secretariat', {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getReservationSetting ({ dispatch, commit }) {
    return axios.get('/api/reservation/setting/config', {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  updateReservationSetting ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/setting/update', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  changeClinicReservableStatus ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/clinic/reservable', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getCsvData ({ dispatch, commit }) {
    return axios.get('/api/reservation/setting/csv', {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  importCsv ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/setting/importCsv', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getFrame ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/frame', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getStaffByWorkingTime ({ dispatch, commit }, param) {
    return axios.get('/api/reservation/staff/working', {
      params: {
        reservation_id: param.reservation_id,
        start_time: param.start_time,
        end_time: param.end_time
      },
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  checkExistReservationTime ({ dispatch, commit }, param) {
    return axios.get('/api/reservation/existReservation', {
      params: {
        allocation_id: param.allocation_id,
        date: param.date,
        start_time: param.start_time,
        end_time: param.end_time,
        reservation_id: param.reservation_id || null
      },
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getByPatientData ({ dispatch, commit }, params) {
    return axios.get('/api/reservation/search', {
      params: {
        keyword: params.keyword,
        from_date: params.from_date,
        page: params.page
      },
      headers: { Authorization: 'Bearer ' + auth.token }
    })
  },
  isFirstReservation ({ dispatch, commit }, params) {
    return axios.get('/api/reservation/check-is-first-reservation', {
      params: {
        started_to: params.started_to,
        patient_id: params.patient_id,
        id: params.id
      },
      headers: { Authorization: 'Bearer ' + auth.token }
    })
  },
  getListLimitReservation ({ dispatch, commit }, postData) {
    return axios.post('/api/reservation/limit-reservation', postData, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
  },
  getListReservationWithDuties ({ dispatch, commit }, params) {
    return axios.get('/api/reservation/list-with-duties', {
      params: params,
      headers: { Authorization: 'Bearer ' + auth.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getListReservationCancel ({ dispatch }, params) {
    return axios.get('/api/reservation/cancelled', {
      params: params,
      headers: { Authorization: 'Bearer ' + auth.token }
    })
  },
  getBusinessTime ({ dispatch, commit }, params) {
    return axios.get('/api/reservation/setting/business-time', {
      params,
      headers: { Authorization: 'Bearer ' + auth.token }
    })
  },
  getDataPrintMultipleExamination ({ dispatch }, params) {
    return axios.get('/api/reservation/get-data-print-multiple-examination', {
      params: params,
      headers: { Authorization: 'Bearer ' + auth.token }
    })
  },
  storeOperationReasons ({ dispatch, commit }, params = {}) {
    return axios.post('/api/operation-reasons', params, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
  }
}
