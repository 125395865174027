var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-v2 setting-v2--background" },
    [
      _c(
        "div",
        {
          staticClass:
            "setting-v2__content setting-v2__content--reservation-datetime"
        },
        [
          _c("div", { staticClass: "setting-v2__header" }, [
            _c("div", { staticClass: "setting-v2__header__box" }, [
              _c("div", { staticClass: "setting-v2__header__box__text" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("objects.setting.text.reservation_date_setting")
                    ) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "setting-v2__header__box__button" },
                [
                  _c("common-button", {
                    staticClass: "button-v2--primary",
                    attrs: { disabled: _vm.is_disable_button_save },
                    on: { click: _vm.actionUpdate },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("new_text.button.apply_changes")
                                ) +
                                "\n            "
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-v2__main" },
            [
              _c("reservation-date-setting", {
                ref: "saveSetting",
                on: {
                  "disable-button-save": function(value) {
                    return (_vm.is_disable_button_save = value)
                  }
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("Loading")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }