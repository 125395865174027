var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "patient-questionnaires" }, [
    _c("div", { staticClass: "patient-questionnaires__header" }, [
      _c("h2", { staticClass: "patient-questionnaires__header__title" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("objects.patient.text.questionnaire.title")) +
            "\n    "
        )
      ])
    ]),
    _vm._v(" "),
    _vm.lazyLoad || _vm.questionnaires.length > 0
      ? _c(
          "div",
          {
            class: [
              "patient-questionnaires__content",
              { "lazy-load": _vm.lazyLoad && !_vm.isLoading }
            ]
          },
          _vm._l(_vm.questionnaires, function(item, index) {
            return _c("questionnaire", {
              key: index,
              attrs: { questionnaire: item }
            })
          }),
          1
        )
      : _c("div", { staticClass: "patient-questionnaires__no-data" }, [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t("objects.patient.text.questionnaire.no_questionnaires")
              ) +
              "\n  "
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }