<template>
    <div class="date-setting-content">
        <div class="date-setting-content__header">
            <h2 class="date-setting-content__header__title">{{ $t('text.datetime_setting') }}</h2>
        </div>
        <div class="row business-day-setting">
            <div class="col-md-12">
                <div class="row-content">
                    <div class="action-content">
                        <p class="medusa-bg">{{ $t('text.business_days_setting') }}</p>
                    </div>
                    <div class="col-12 m0 m-0 p-0">
                        <div class="block-setting">
                            <div class="block-business"
                                 v-for="(setting, key) in businessSetting">
                                <div class="character-setting">
                                    <ul>
                                        <li v-for="(item, index) in setting.setting"
                                            :key="index"
                                            v-on:click="item.isActive = !item.isActive"
                                            v-bind:class="{active:item.isActive}">
                                            <p>{{item.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                                <div class="time-setting medusa-date medusa-time">
                                    <date-picker
                                        class="form-control time-input"
                                        readonly="readonly"
                                        name="start_time"
                                        placeholder="--:--"
                                        v-model="setting.timeSetting.startTime"
                                        :config="dpOptionsTime">
                                    </date-picker>
                                    〜
                                    <date-picker
                                        class="form-control time-input"
                                        readonly="readonly"
                                        name="end_time"
                                        placeholder="--:--"
                                        v-model="setting.timeSetting.endTime"
                                        :config="dpOptionsTime">
                                    </date-picker>
                                </div>
                                <div v-if="setting.removable"
                                     v-on:click="removeBusinessBlock(key)" class="remove-item">
                                    <img :src="'/images/remove-block.svg'"/></div>
                                <div v-else class="not-remove-item"></div>
                            </div>
                            <div class="errors">
                                {{errorBusinessSetting}}
                            </div>
                            <div class="add-block"><span v-on:click="addBusinessSetting"><img
                                :src="'/images/add-block.svg'"/></span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row sabbatical-setting">
            <div class="col-md-12">
                <div class="row-content">
                    <div class="action-content">
                        <p class="medusa-bg">{{ $t('text.long_term_leave_setting') }}</p>
                    </div>
                    <div class="col-12 m0 m-0 p-0">
                        <div class="block-setting">
                            <div class="block-business"
                                 v-for="(setting, key) in sabbaticalSetting">
                                <div class="date-setting medusa-date">
                                    <date-picker
                                        class="form-control border-blue"
                                        placeholder="2019年12月31日"
                                        v-model="setting.startTime" :config="options">
                                    </date-picker>
                                    〜
                                    <date-picker
                                        class="form-control border-blue"
                                        placeholder="2019年12月31日"
                                        v-model="setting.endTime" :config="options">
                                    </date-picker>
                                </div>
                                <div v-on:click="removeSabbaticalBlock(key)"
                                     class="remove-item">
                                    <img :src="'/images/remove-block.svg'"/></div>
                            </div>
                            <div class="errors">
                                {{errorSabbaticalSetting}}
                            </div>
                            <div class="add-block"><span v-on:click="addSabbaticalSetting"><img
                                :src="'/images/add-block.svg'"/></span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row regular-setting">
            <div class="col-md-12">
                <div class="row-content">
                    <div class="action-content">
                        <p class="medusa-bg">{{ $t('text.periodic_block_setting') }}</p>
                        <span class="periodic-week-setting">{{ $t('text.periodic_week_setting') }}</span>
                    </div>
                    <div class="col-12 m0 m-0 p-0">
                        <div class="block-setting">
                            <div class="block-business"
                                 v-for="(setting, key) in regularSetting">
                                <div class="character-setting">
                                    <div class="week">
                                        {{ $t('common.first') }}
                                        <input type="text" maxlength="1" class="form-control border-blue number-only"
                                               @keydown="isNumber"
                                               @input="numberPeriodicRegex(key)"
                                               v-model="setting.week"/>
                                        {{ $t('common.week') }}
                                    </div>
                                    <ul>
                                        <li v-for="(item, index) in setting.setting"
                                            :key="index"
                                            v-on:click="regularDayClick(item, key)"
                                            v-bind:class="{active:item.isActive}">
                                            <p>{{item.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                                <div class="time-setting medusa-date medusa-time">
                                    <date-picker
                                        class="form-control time-input"
                                        readonly="readonly"
                                        name="start_time"
                                        placeholder="--:--"
                                        v-model="setting.timeSetting.startTime"
                                        :config="dpOptionsTime">
                                    </date-picker>
                                    〜
                                    <date-picker
                                        class="form-control time-input"
                                        readonly="readonly"
                                        name="end_time"
                                        placeholder="--:--"
                                        v-model="setting.timeSetting.endTime"
                                        :config="dpOptionsTime">
                                    </date-picker>
                                </div>
                                <div v-if="setting.removable"
                                     v-on:click="removeRegularBlock(key)" class="remove-item">
                                    <img :src="'/images/remove-block.svg'"/></div>
                                <div v-else class="not-remove-item"></div>
                            </div>
                            <div class="errors">
                                {{errorRegularSetting}}
                            </div>
                            <div class="add-block"><span v-on:click="addRegularSetting"><img
                                :src="'/images/add-block.svg'"/></span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isShowCSV" class="row csv-setting">
            <div class="col-md-12">
                <div class="row-content">
                    <div class="action-content">
                        <p class="medusa-bg">{{ $t('text.csv_reservation_importing') }}</p>
                    </div>
                    <div class="col-12 m0 m-0 p-0">
                        <div class="csv-block-inner">
                            <div class="csv-block">
                                <div class="csv-title">{{ $t('text.csv_template_setting') }}</div>
                                <div class="csv-action">
                                    <download-csv
                                        class="btn btn-default"
                                        :data="csv_data"
                                        name="template_shift.csv">
                                        {{ $t('common.download') }}
                                    </download-csv>
                                </div>
                            </div>
                            <div class="csv-block">
                                <div class="csv-title">{{ $t('text.csv_contribution') }}</div>
                                <div class="csv-date">
                                    <div class="date-setting medusa-date">
                                        <div class="start-date">
                                            <date-picker
                                                @dp-change="changeStartTime"
                                                id="start-date-frame"
                                                class="form-control border-blue"
                                                :placeholder="frame.startTimeFormat"
                                                v-model="frame.startTime" :config="options">
                                            </date-picker>
                                        </div>
                                        <div class="middle-seperate">〜</div>
                                        <div class="end-date">
                                            <date-picker
                                                @dp-change="changeEndTime"
                                                id="end-date-frame"
                                                class="form-control border-blue"
                                                :placeholder="frame.endTimeFormat"
                                                v-model="frame.endTime" :config="options">
                                            </date-picker>
                                        </div>
                                    </div>
                                </div>
                                <div class="csv-action">
                                    <button class="btn" @click="getFrame">{{ $t('common.output') }}</button>
                                </div>
                            </div>
                            <div class="csv-block">
                                <div class="csv-title">{{ $t('text.csv_upload_for_setting') }}</div>
                                <div class="csv-action upload-btn-wrapper">
                                    <label for="file-upload" class="custom-file-upload">
                                        {{ $t('common.upload') }}
                                    </label>

                                    <input id="file-upload" ref="csv" accept=".csv" type="file"
                                           @change.prevent="validFileMimeType"
                                           name="csv"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="date-setting-content__action">
            <button class="date-setting-content__action__button" @click="saveReservationSetting"><span>{{ $t('common.apply_change') }}</span></button>
        </div>
    <modal-reservation
      v-if="show_modal_reservation"
      :reservations="limitReservations"
      @close="show_modal_reservation = false"
    />
        <loading/>
    </div>
</template>
<script>
  import TimeCalculator from './TimeCalculator'
  import datePicker from 'vue-bootstrap-datetimepicker'
  import helpers from '../utils/helpers.js'
  import Loading from './Loading'
  import mimeTypes from 'mime-types'
  import Papa from 'papaparse'
  import * as Moment from 'moment'
  import { extendMoment } from 'moment-range'
  import downloadCsv from 'vue-json-csv'
  import JsonCSV from './JSON2CSV'
  import { numeric, required, between } from 'vuelidate/lib/validators'
  import { trans } from '../utils/translate'
  import { isEqual, sortBy } from 'lodash'
  import ModalReservation from '../components/reservation/ModalReservation'

    const moment = extendMoment(Moment)

    export default {
        props: {
            validation: {
                type: Boolean,
                default: true,
            },
            fileMimeTypes: {
                type: Array,
                default: () => {
                    return ["text/csv", "text/x-csv", "application/vnd.ms-excel"]
                }
            }
        },
        components: {
            TimeCalculator,
            datePicker,
            Loading,
            downloadCsv,
            JsonCSV,
          ModalReservation
        },
        data() {
            return {
                csv_data: [],
                isValidFileMimeType: false,
                csv: null,
                defaultBusinessSetting: {},
                businessSetting: [],
                errorBusinessSetting: '',
                errorRegularSetting: '',
                errorSabbaticalSetting: '',
                defaultSabbatical: {},
                sabbaticalSetting: [],
                defaultRegularSetting: {},
                regularSetting: [],
                date: new Date(),
                options: {
                    format: 'YYYY年MM月DD日',
                    useCurrent: false,
                    dayViewHeaderFormat: 'YYYY / MM',
                    locale: 'ja',
                },
                timeChange: {},
                keyChange: '',
                frame: {
                    startTime: '',
                    startTimeFormat: '',
                    endTime: '',
                    endTimeFormat: ''
                },
                isShowCSV: false,
                reservable_limit: 0,
                reserve_due: 0,
                currentRegularSettingIndex: -1,
                dpOptionsTime: {
                    format: 'HH:mm',
                    useCurrent: false,
                    locale: 'ja',
                    ignoreReadonly: true,
                    stepping: 15
                },
        show_modal_reservation: false,
        limitReservations: []
            }
        },
        computed: {
            loading_count() {
                return this.$store.getters.loading_count
            }
        },
        created() {
            this.getReservationSetting()
            this.getCsvTemplateData()
            this.frame.startTime = moment().startOf('month')
            this.frame.startTimeFormat = moment(this.frame.startTime).format('YYYY年MM月DD日')
            this.frame.endTime = moment().endOf('month')
            this.frame.endTimeFormat = moment(this.frame.endTime).format('YYYY年MM月DD日')
        },
        methods: {
            addBusinessSetting: function () {
                let settingClone = _.cloneDeep(this.defaultBusinessSetting)
                settingClone.removable = true
                this.businessSetting.push(settingClone)
            },
            removeBusinessBlock: function (keyIndex) {
                this.businessSetting.splice(keyIndex, 1)
                this.errorBusinessSetting = ''
            },
            addSabbaticalSetting: function () {
                this.sabbaticalSetting.push(_.cloneDeep(this.defaultSabbatical))
            },
            removeSabbaticalBlock: function (keyIndex) {
                this.sabbaticalSetting.splice(keyIndex, 1)
                this.errorSabbaticalSetting = ''
            },
            addRegularSetting: function () {
                let settingClone = _.cloneDeep(this.defaultRegularSetting)
                settingClone.removable = true
                this.regularSetting.push(settingClone)
            },
            removeRegularBlock: function (keyIndex) {
                this.regularSetting.splice(keyIndex, 1)
                this.errorRegularSetting = ''
            },
            getReservationSetting: function () {
                this.$store.commit('set_loading', true)
                this.$store.commit('set_loading_count', this.loading_count + 1)
                this.$store.dispatch('getReservationSetting').then((result) => {
                    this.businessSetting = helpers.safeGet(result, 'data.results.reservationConfig.businessSetting')
                    this.sabbaticalSetting = helpers.safeGet(result, 'data.results.reservationConfig.sabbaticalSetting')
          const regularSetting = helpers.safeGet(result, 'data.results.reservationConfig.regularSetting')
          const defaultRegularSetting = helpers.safeGet(result, 'data.results.defaultRegularSetting')

          if (!this.isEmptyDataSetting(regularSetting, defaultRegularSetting)) {
            this.regularSetting = regularSetting
          }

          // Default setting
          this.defaultBusinessSetting = helpers.safeGet(result, 'data.results.defaultBusinessSetting')
          this.defaultSabbatical = helpers.safeGet(result, 'data.results.defaultSabbaticalSetting')
          this.defaultRegularSetting = defaultRegularSetting

                    const { data: { results: { clinic }}} = result
                    const { reservable_limit } = clinic
                    this.reservable_limit = reservable_limit

                    this.$store.commit('set_loading_count', this.loading_count - 1)
                    if (this.loading_count === 0) {
                        this.$store.commit('set_loading', false)
                    }
                }).catch((error) => {
                    this.$store.commit('set_loading_count', this.loading_count - 1)
                    if (this.loading_count === 0) {
                        this.$store.commit('set_loading', false)
                    }
                })
            },
      isEmptyDataSetting: function (data, dataDefault) {
        if (data.length === 0) {
          return true
        }

        return data.filter(function (item) {
          return !isEqual(sortBy(item), sortBy(dataDefault)) &&
            item.timeSetting?.startTime &&
            item.timeSetting?.endTime
        }).length === 0
      },
            getCsvTemplateData: function () {
                this.$store.commit('set_loading', true)
                this.$store.commit('set_loading_count', this.loading_count + 1)
                this.$store.dispatch('getCsvData').then((result) => {
                    let data = helpers.safeGet(result, 'data.results')
                    if (_.size(data)) {
                        this.csv_data = data
                    }
                    this.$store.commit('set_loading_count', this.loading_count - 1)
                    if (this.loading_count === 0) {
                        this.$store.commit('set_loading', false)
                    }
                }).catch((error) => {
                    this.$store.commit('set_loading_count', this.loading_count - 1)
                    if (this.loading_count === 0) {
                        this.$store.commit('set_loading', false)
                    }
                })
            },
      saveReservationSetting: function () {
        if (this.validateMessage()) {
          const postData = {
            businessSetting: this.businessSetting,
            sabbaticalSetting: this.sabbaticalSetting,
            regularSetting: this.regularSetting
          }
          this.$store.commit('set_loading', true)
          this.$store.dispatch('getListLimitReservation', postData).then((result) => {
            this.limitReservations = helpers.safeGet(result.data, 'results')

            if (this.limitReservations) {
              this.show_modal_reservation = true
              this.$store.commit('set_loading', false)
            } else {
              this.$store.dispatch('updateReservationSetting', postData).then((result) => {
                const data = helpers.safeGet(result, 'data.results')
                if (data.errorBusinessSetting || data.errorSabbaticalSetting || data.errorRegularSetting || data.errorOverLapSetting) {
                  this.errorBusinessSetting = data.errorBusinessSetting
                  this.errorSabbaticalSetting = data.errorSabbaticalSetting
                  this.errorRegularSetting = data.errorRegularSetting
                  if (data.errorOverLapSetting) {
                    this.errorRegularSetting = data.errorOverLapSetting
                  }
                } else {
                  helpers.showSuccessModal(this.$t('sweet_alert.saved'))
                }
                this.$store.commit('set_loading', false)
              }).catch(() => {
                helpers.showErrorModal(this.$t('message.something_went_wrong'))
                this.$store.commit('set_loading', false)
              })
            }
          }).catch(() => {
            helpers.showErrorModal(this.$t('message.something_went_wrong'))
            this.$store.commit('set_loading', false)
          })
        }
      },
            validateMessage: function () {
                this.errorBusinessSetting = ''
                this.errorRegularSetting = ''
                this.errorSabbaticalSetting = ''
                this.errorBusinessSetting = this.validateSettingData(this.businessSetting)
                this.errorRegularSetting = this.validateSettingData(this.regularSetting)
                this.errorSabbaticalSetting = this.validateSabbaticalData(this.sabbaticalSetting)
                if (!this.errorBusinessSetting) {
                    this.errorBusinessSetting = this.validateOverLap(this.businessSetting)
                }

                if (!this.validate()) {
                    return false
                }

                if (parseInt(this.reserve_due, 10) >= parseInt(this.reservable_limit, 10)) {
                    helpers.showErrorModal(this.$t('validation.please_enter_validate_reserve_due'))
                    return false
                }

                if (this.errorBusinessSetting
                    || this.errorRegularSetting
                    || this.errorSabbaticalSetting) {
                    return false
                }
                return true
            },
            validateSettingData: function (settingData) {
                let errorMessage = ''
        if (!Array.isArray(settingData) || (Array.isArray(settingData) && settingData.length === 0)) {
          return errorMessage
        }
                let _this = this
                settingData.forEach(function (business) {
                    if (business.timeSetting.startTime && business.timeSetting.endTime) {
                        if (moment(business.timeSetting.startTime, "HH:mm", true).isValid()
                            && moment(business.timeSetting.endTime, "HH:mm", true).isValid()
                        ) {
                            let startDate = moment(business.timeSetting.startTime || '00:00', "HH:mm").format('HH:mm')
                            let endDate = moment(business.timeSetting.endTime || '00:00', "HH:mm").format('HH:mm')

                            if (startDate >= endDate) {
                                errorMessage = _this.$t('settings.invalid_input')
                            }
                        } else {
                            errorMessage = _this.$t('settings.invalid_input')
                        }
                    } else if (business.timeSetting.startTime && !business.timeSetting.endTime) {
                        errorMessage = _this.$t('settings.invalid_input')
                    } else if (!business.timeSetting.startTime && business.timeSetting.endTime) {
                        errorMessage = _this.$t('settings.invalid_input')
                    }
                    // check periodic number is valid
                    if (typeof business.week != "undefined") {
                        let countCheck = 0
                        business.setting.forEach(function (item) {
                            if (item.isActive) {
                                countCheck ++
                            }
                        })
                        if (countCheck === 0) {
                            errorMessage = _this.$t('settings.periodic_require_day_of_week')
                        }
                        if (!business.week) {
                            errorMessage = _this.$t('settings.periodic_require_week');
                        } else if (/[^0-5]/g.test(business.week)) {
                            errorMessage = _this.$t('settings.invalid_input');
                        }
                    }
                })

                return errorMessage
            },
            validateOverLap: function (settingData) {
                let errorMessage = ''
                let arrayDate = []
                let _this = this
                settingData.forEach(function (business) {
                    if (business.timeSetting.startTime && business.timeSetting.endTime) {
                        business.setting.forEach(function (item) {
                            if (item.isActive) {
                                if (arrayDate[item.value]) {
                                    arrayDate[item.value].push({
                                        startTime: business.timeSetting.startTime,
                                        endTime: business.timeSetting.endTime
                                    })
                                } else {
                                    arrayDate[item.value] = [{
                                        startTime: business.timeSetting.startTime,
                                        endTime: business.timeSetting.endTime
                                    }]
                                }
                            }
                        })
                    }
                })
                let arrayWorkingDate = arrayDate.filter(function () {
                    return true
                })

                arrayWorkingDate.forEach(function (workDate) {
                    let lengthData = workDate.length
                    let overlap = false
                    if (lengthData > 1) {
                        for (let i = 0; i <= lengthData - 1; i++) {
                            let range1 = moment.range(moment(workDate[i].startTime, 'HH:mm'), moment(workDate[i].endTime, 'HH:mm'))
                            for (let j = i + 1; j <= lengthData - 1; j++) {
                                let range2 = moment.range(moment(workDate[j].startTime, 'HH:mm'), moment(workDate[j].endTime, 'HH:mm'))
                                if (range1.overlaps(range2)) {
                                    overlap = true
                                }
                            }
                        }
                    }
                    if (overlap) {
                        errorMessage = _this.$t('settings.reservation_duplicate_time_zone')
                    }
                })

                return errorMessage
            },
            validateSabbaticalData(sabbaticalData) {
                var errorMessage = ''
                let _this = this
                _.map(sabbaticalData, function (o, i) {
                    if (o.startTime && o.endTime) {
                        var starTime = moment(o.startTime, _this.$t('datetime.year_month_day')).format(_this.$t('datetime.year_month_day', 'en'))
                        var endTime = moment(o.endTime, _this.$t('datetime.year_month_day')).format(_this.$t('datetime.year_month_day', 'en'))
                        if (starTime > endTime) {
                            errorMessage = _this.$t('settings.invalid_input')
                        }
                    } else if (o.startTime && !o.endTime) {
                        errorMessage = _this.$t('settings.invalid_input')
                    } else if (!o.startTime && o.endTime) {
                        errorMessage = _this.$t('settings.invalid_input')
                    }
                })
                _.map(sabbaticalData, function (o, i) {
                    var eq = _.find(sabbaticalData, function (e, ind) {
                        if (i > ind) {
                            return _.isEqual(e, o)
                        }
                    })

                    if (eq) {
                        if (eq.startTime || eq.endTime)
                            errorMessage = _this.$t('settings.overlapped_block_time')
                    }
                })

                return errorMessage
            },
            validFileMimeType: function () {
                let file = this.$refs.csv.files[0]
                const mimeType = file.type === "" ? mimeTypes.lookup(file.name) : file.type
                if (file) {
                    this.isValidFileMimeType = this.validation ? this.validateMimeType(mimeType) : true
                    if (!this.isValidFileMimeType) {
                        helpers.showErrorModal(this.$t('message.csv_malformed'))
                        return false
                    }
                    this.submitFile()
                }
            },
            validateMimeType: function (type) {
                return this.fileMimeTypes.indexOf(type) > -1
            },
            submitFile: function () {
                const _this = this
                this.readFile((output) => {
                    _this.csv = _.get(Papa.parse(output, {skipEmptyLines: true}), "data")
                    if (_this.csv) {
                        this.csv = _.drop(this.csv)
                        _this.importCsvData()
                        document.getElementById("file-upload").value = ''
                    }
                })
            },
            readFile(callback) {
                let file = this.$refs.csv.files[0]

                if (file) {
                    let reader = new FileReader()
                    reader.readAsText(file, "UTF-8")
                    reader.onload = function (evt) {
                        callback(evt.target.result)
                    }
                    reader.onerror = function () {
                    }
                }
            },
            importCsvData: function () {
                let postData = {
                    csvData: this.csv,
                }
                this.$store.commit('set_loading', true)
                this.$store.dispatch('importCsv', postData).then((result) => {
                    let is_Duplicate = helpers.safeGet(result, 'data.results.isDuplidate')
                    let is_Format = helpers.safeGet(result, 'data.results.is_Format')
                    if (is_Format) {
                        if (is_Duplicate) {
                            helpers.showWarningModal(this.$t('message.reservation_not_reflected_in_frame'))
                        } else {
                            helpers.showSuccessModal(this.$t('message.reservable_slots_reflected'))
                        }
                    } else {
                        helpers.showErrorModal(this.$t('message.csv_malformed'))
                    }
                    this.$store.commit('set_loading', false)
                }).catch((error) => {
                    this.$store.commit('set_loading', false)
                })
            },
            isNumber: function (evt) {
                evt = (evt) ? evt : window.event
                const charCode = (evt.which) ? evt.which : evt.keyCode
                if ((charCode > 31 && (charCode < 48 || (charCode > 57 && charCode < 96) || charCode > 105)) && charCode !== 46) {
                    evt.preventDefault()
                } else {
                    return true
                }
            },
            numberRegex: function(event) {
                var regexp = /[^0-9]/g
                if (event.target.value.match(regexp)) {
                    event.target.value= (event.target.value.replace(regexp, ''))
                }
            },
            getFrame() {
                if (this.frame.endTime < this.frame.startTime) {
                    helpers.showErrorModal(this.$t('settings.invalid_input_2'))
                    return false
                } else {
                    let postData = {
                        'started_to': this.frame.startTime.format(this.$t('datetime.year_month_day', 'en')),
                        'ended_from': this.frame.endTime.format(this.$t('datetime.year_month_day', 'en'))
                    }
                    this.$store.commit('set_loading', true)
                    var that = this
                    this.$store.dispatch('getFrame', postData).then((result) => {
                        let data = helpers.safeGet(result, 'data.results')
                        that.$store.commit('set_loading', false)
                        if (data.length > 0) {
                            let csvContent = "data:text/csv;charset=utf-8,\ufeff"
                            csvContent += [
                                Object.keys(data[0]).join(","),
                                ...data.map(item => Object.values(item).join(","))
                            ]
                                .join("\n")
                                .replace(/(^\[)|(\]$)/gm, "")

                            const dataContent = encodeURI(csvContent)
                            const link = document.createElement("a")
                            let filename = 'output_' + this.frame.startTime.format('YYYYMMDD') + '_' + this.frame.endTime.format('YYYYMMDD') + '.csv'
                            link.setAttribute("href", dataContent)
                            link.setAttribute("download", filename)
                            link.click()
                        }
                    }).catch((error) => {
                        that.$store.commit('set_loading', false)
                    })
                }
            },
            changeStartTime: function (e) {
                this.frame.startTime = e.date === '' ? moment().startOf('month') : e.date
            },
            changeEndTime: function (e) {
                this.frame.endTime = e.date === '' ? moment().endOf('month') : e.date
            },
            validate: function () {
                this.$v.$touch()
                if (!this.$v.$anyError) return true

                let errors = []
                let errorMessages = {
                    'reservable_limit.required': trans(this.$t('validation.required'), { attribute: this.$t('text.reservable_limit') }),
                    'reservable_limit.between': trans(this.$t('validation.between'), { attribute: this.$t('text.reservable_limit'), min: 1, max: 365 }),
                    'reservable_limit.numeric': trans(this.$t('validation.numeric'), { attribute: this.$t('text.reservable_limit') })
                }
                for(let key in errorMessages) {
                    let parts = key.split('.')
                    if (this.$v[ parts[0] ] && this.$v[ parts[0] ][ parts[1] ] === false) {
                        errors.push(errorMessages[key])
                    }
                }
                if (errors.length) {
                    helpers.showErrorModal(errors.join('<br>'))
                    return false
                }
                return true
            },
            numberPeriodicRegex: function(index) {
                if (typeof this.regularSetting[index].week != "undefined") {
                    this.regularSetting[index].week = this.regularSetting[index].week.toString().replace(/[^0-5]/g, '');
                }
            },
            validateNumber: function(theEvent, field) {
                if (this[field]) {
                    this[field] = this[field].toString().replace(/[^0-9]/g, '')
                }
            },
            regularDayClick: function(item, index) {
                const daily = "8";
                item.isActive = !item.isActive;
                if (typeof this.regularSetting[index].setting == 'undefined') return;
                if (item.value == daily) {
                    this.regularSetting[index].setting.filter(function(val) {
                        if (item.isActive && val.value != item.value) {
                            val.isActive = false;
                        }
                        return val;
                    });
                } else {
                    this.regularSetting[index].setting.find(val => val.value == daily).isActive = false;
                }
            }
        },
        validations() {
            return {
                reservable_limit: {required, numeric, between: between(1, 365)}
            }
        }
    }
</script>
