<template>
  <div :class="['calendar', { 'calendar__focus--cancelled': is_reservation_cancelled }]">
    <full-calendar
      id="calendar"
      ref="fullCalendar"
      default-view="resourceTimeGridDay"
      :scroll-time="scroll_time"
      :locale="locale"
      :plugins="calendar_plugins"
      :scheduler-license-key="scheduler_licenseKey"
      :header="header"
      :title-format="title_format"
      :default-date="selectedDate"
      :custom-buttons="custom_buttons"
      :time-format="time_format"
      :min-time="is_closed_all_day ? '00:00:00' : min_active_time"
      :max-time="is_closed_all_day ? '24:00:00' : max_active_time"
      :slot-label-format="slot_label_format"
      :slot-duration="slot_duration"
      :slot-label-interval="slot_label_interval"
      :snap-duration="snap_duration"
      :event-time-format="event_time_format"
      :editable="editable"
      :selectable="selectable"
      :unselect-cancel="unselect_class"
      :event-limit="event_limit"
      :select-mirror="select_mirror"
      :resource-order="resource_order"
      :resources="resources"
      :events="events"
      :event-overlap="handleEventOverLap"
      :select-overlap="handleSelectOverLap"
      :resource-render="resourceRender"
      :dates-render="datesRender"
      :event-render="eventRender"
      @windowResize="windowResize"
      @select="clickToFullCalendar"
      @eventClick="clickToReservation"
      @eventResize="handleDropResizeEvent($event)"
      @eventDrop="handleDropResizeEvent($event, true)"
      @eventDragStart="handleDropResizeEventStart($event)"
      @eventDragStop="handleDropResizeEventStop"
      @dateClick="removeActiveReservation"
      @eventResizeStart="handleDropResizeEventStart($event)"
      @eventResizeStop="handleDropResizeEventStop"
    />
    <full-calendar
      id="print-calendar"
      ref="printedCalendar"
      default-view="resourceTimeGridDay"
      :scroll-time="scroll_time"
      :locale="locale"
      :plugins="calendar_plugins"
      :scheduler-license-key="scheduler_licenseKey"
      :header="header"
      :title-format="title_format"
      :default-date="selectedDate"
      :custom-buttons="custom_buttons"
      :time-format="time_format"
      :min-time="is_closed_all_day ? '00:00:00' : min_active_time_print"
      :max-time="is_closed_all_day ? '24:00:00' : max_active_time_print"
      :slot-label-format="slot_label_format"
      :slot-duration="slot_duration"
      :slot-label-interval="slot_label_interval"
      :event-time-format="event_time_format"
      :editable="isPrintMemo"
      :selectable="selectable"
      :unselect-cancel="unselect_class"
      :event-limit="event_limit"
      :select-mirror="select_mirror"
      :resource-order="resource_order"
      :resources="resources_print"
      :events="events"
      :event-overlap="handleEventOverLap"
      :select-overlap="handleSelectOverLap"
      :resource-render="resourceRender"
      :dates-render="datesPrintRender"
      :event-render="eventPrintRender"
      @windowResize="windowResize"
    />
    <modal-create-reservation
      v-if="data_create_reservation"
      :data-calendar="data_create_reservation"
      @close="[data_create_reservation = null, reloadReservationData(), removeFrameReservation()]"
      @created="handleAfterCreateReservation"
    />
    <modal-update-reservation
      v-if="data_update_reservation"
      :event-edit="data_update_reservation"
      :allocations="allocations"
      @close="[data_update_reservation = null, reloadReservationData(), is_reservation_cancelled = false]"
      @updated="handleAfterUpdateReservation"
      @canceled="handleAfterCancel"
    />
    <div
      id="btn-left"
      :class="['icon-switch switch-prev', { 'switch-prev--disable': direction_position === 0 }]"
      @click="switchReservationFrame('left')"
    />
    <div
      id="btn-right"
      :class="['icon-switch switch-next', { 'switch-next--disable': direction_position === (resources.length - max_resource) }]"
      @click="switchReservationFrame()"
    />
    <modal-patient-detail
      v-if="data_patient_detail"
      :data-patient="data_patient_detail"
      @close="[data_patient_detail = null, reloadReservationData()]"
    />
    <modal-confirm
      v-if="is_show_modal_confirm_first_reservation"
      :title="$t('patient_page.first_reservation_modal_title_change')"
      :text="$t('patient_page.first_reservation_confirmation_change')"
      @no="resetReservationDataResizeOrMove()"
      @yes="handleUpdateReservation(update_reservation_type.RESIZE_OR_DROP)"
    />
    <modal-confirm
      v-if="show_modal_update_draft"
      :title="$t('patient_page.first_reservation_modal_title_change')"
      :text="$t('patient_page.first_reservation_confirmation_change')"
      @no="hideModalUpdateDraft"
      @yes="showMoveConfirmUpdateReservation"
    />
    <modal-confirm
      v-if="move_mode_show_modal_confirm_update_reservation"
      :title="updateReservationModalMessageTitle"
      :text="updateReservationModalMessageBody"
      @no="moveModeDismissUpdateReservation"
      @yes="handleUpdateReservation(update_reservation_type.MOVE_MODE)"
    />
    <modal-confirm
      v-if="move_mode_show_modal_confirm_continue"
      :title="continueMoveModeMessageTitle"
      :text="continueMoveModeMessageBody"
      @no="[moveModeExit(), changeDate()]"
      @yes="moveModeContinue"
    />
    <modal-create-allocation
      v-if="is_show_modal_create_allocation"
      :allocations="allocations"
      :selected-date="selectedDate"
      @close="[is_show_modal_create_allocation = false, reloadReservationData()]"
      @succeed="handleSucceedAllocation"
    />
    <modal-update-allocation
      v-if="allocation_edit"
      :allocations="allocations"
      :selected-date="selectedDate"
      :allocation="allocation_edit"
      @close="[allocation_edit = null, reloadReservationData()]"
      @succeed="handleSucceedAllocation"
    />
    <modal-operation-reason
      v-if="is_show_modal_operation_reasons"
      :is-update="true"
      :is-block="eventUpdateTypeBlock"
      :resources="operationReasonResources"
      @back="dismissUpdateReservation"
      @confirm="submitUpdateReservation"
    />
  </div>
</template>

<script>
  import Vue from 'vue'
  import jaLocale from '@fullcalendar/core/locales/ja'
  import * as moment from 'moment'
  import FullCalendar from '@fullcalendar/vue'
  import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import { showErrorModal, showSuccessModal, openNewTab, getBrandNameFromType } from '../../utils/helpers'
  import {
    COLUMN_TYPE,
    KIDS_BRAND_TYPE,
    RESPONSE_STATUS,
    RESPONSE_TYPE,
    OPERATION_REASON_TYPE,
    UPDATE_RESERVATION_TYPE
  } from '../../utils/constants'
  import Event from '../../components/reservation/Event'
  import EventPrint from '../../components/reservation/EventPrint'
  import ModalCreateReservation from './ModalCreateReservation'
  import ModalUpdateReservation from './ModalUpdateReservation'
  import ModalPatientDetail from './ModalPatientDetail'
  import { calendar, resizeOrDrop } from '../../mixins/reservation'
  import { printCalendar } from '../../mixins/print-calendar'
  import { checkReservation, createReservation, popupReservation } from '../../mixins/move-mode'
  import ModalConfirm from '../common/ModalConfirm'
  import ModalCreateAllocation from './ModalCreateAllocation'
  import ModalUpdateAllocation from './ModalUpdateAllocation'
  import ModalOperationReason from './ModalOperationReason'
  import { INTERNAL_SERVER_ERROR } from '../../utils/http-code'
  import { get } from 'lodash'

  const EventClass = Vue.extend(Event)
  const EventPrintClass = Vue.extend(EventPrint)

  export default {
    components: {
      ModalUpdateAllocation,
      ModalCreateAllocation,
      FullCalendar,
      ModalCreateReservation,
      ModalUpdateReservation,
      ModalPatientDetail,
      ModalConfirm,
      ModalOperationReason
    },
    mixins: [
      calendar,
      resizeOrDrop,
      createReservation,
      checkReservation,
      popupReservation,
      printCalendar
    ],
    beforeRouteLeave (to, from, next) {
      if (to.name !== 'dashboard' && to.name !== 'dashboard') {
        this.resetReservationMoveMode()
      }
      next()
    },
    props: {
      toggle: {
        type: Boolean,
        default: true
      },
      isUsersManipulating: {
        type: Boolean,
        default: false
      },
      selectedDate: {
        type: String,
        default: ''
      },
      isPrintMemo: {
        type: Number,
        default: 0
      }
    },
    emits: [
      'selected-date',
      'set-total-reservation-cancelled',
      'set-total-reservation-reserved',
      'set-reservation-print-instruction',
      'set-reservation-print-technical-info'
    ],
    data () {
      return {
        max_resource: 7,
        init_resource_add: {
          id: 'add',
          column_type: COLUMN_TYPE.ADD_NEW,
          sortOrder: 1000,
          title: this.$t('reservation.add_new_frame'),
          eventClassNames: 'icon-event-disable',
          businessHours: {
            startTime: '00:00',
            endTime: '00:00'
          }
        },
        init_resource_empty: {
          column_type: COLUMN_TYPE.EMPTY,
          sortOrder: 2000,
          title: '',
          eventClassNames: 'icon-event-disable',
          businessHours: {
            startTime: '00:00',
            endTime: '00:00'
          }
        },
        calendar_plugins: [resourceTimeGridPlugin, interactionPlugin],
        editable: true,
        event_limit: true,
        event_time_format: {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        },
        header: {
          left: 'namePage',
          center: 'today prevMonth prev title next nextMonth',
          right: 'reloadPage'
        },
        locale: jaLocale,
        resource_order: 'sortOrder',
        scheduler_licenseKey: 'GPL-My-Project-Is-Open-Source',
        selectable: true,
        unselect_class: '.modal-create-reservation',
        select_mirror: true,
        slot_duration: '00:10:00',
        snap_duration: '00:05:00',
        duration: 10,
        slot_label_format: [
          {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          }
        ],
        slot_label_interval: 10,
        title_format: { month: '2-digit', day: '2-digit' },
        time_format: 'H:mm',
        scroll_time: (
          this.$route.query.search_date &&
          moment(this.$route.query.search_date).format('HH:mm:ss') !== '00:00:00'
        )
          ? moment(this.$route.query.search_date).format('HH:mm:ss')
          : moment.tz('Asia/Tokyo').format('HH:mm:ss'), // now not working
        custom_buttons: this.customHeader(),
        is_closed_all_day: false,
        resources: [],
        events: [],
        resources_print: [],
        min_active_time: '00:00:00',
        max_active_time: '23:45:00',
        min_active_time_print: '00:00:00',
        max_active_time_print: '23:45:00',
        is_admin: 0,
        time_fixed_length: 0,
        data_create_reservation: null,
        data_update_reservation: null,
        cancelled: [],
        cancelled_width: 0,
        left_position: [],
        upper_position: [],
        data_patient_detail: null,
        overlay: false,
        timer: '',
        enable_auto_reload: false,
        event_resize_or_move: null,
        is_drop_reservation: false,
        is_show_modal_confirm_first_reservation: false,
        notification_cycle: '',
        notifications: [],
        notifications_tmp: [],
        active_reservation_id: null,
        active_reservation_on_mouse: false,
        show_modal_update_draft: false,
        move_mode_show_modal_confirm_update_reservation: false,
        move_mode_show_modal_confirm_continue: false,
        is_block_frame_manipulation: false,
        reservationInfoInDraft: null,
        move_mode_current_trigger_action: null,
        on_mouse_coordinate: null,
        on_mouse_data_time: null,
        on_mouse_allocation_id: null,
        url_reservation_id: null,
        url_reservation_data: {},
        is_show_modal_create_allocation: false,
        allocation_edit: null,
        allocations: [],
        date_start_move_mode: '',
        is_resize_or_move: false,
        page_print_range: {},
        is_print_calendar: false,
        direction_position: 0,
        reservation_cancelled: {},
        is_reservation_cancelled: false,
        is_first_render_new_reservation: true,
        diff_minutes_new_reservation: 0,
        minutes_end_5: ['05', '15', '25', '35', '45', '55'],
        is_first_show: false,
        show_current_line: '',
        is_lock_reservation: false,
        allocation_destination: {},
        is_show_modal_operation_reasons: false,
        update_reservation_type: UPDATE_RESERVATION_TYPE,
        current_update_reservation_type: 0,
        operation_reason_post_data: null
      }
    },
    computed: {
      userInfo: function () {
        return this.$store.state.auth.user
      },
      updateReservationModalMessageTitle: function () {
        return this.is_block_frame_manipulation
          ? this.$t('text.move_mode_block_frame_popup_title')
          : this.$t('text.move_mode_reservation_popup_title')
      },
      updateReservationModalMessageBody: function () {
        if (this.reservationInfoInDraft) {
          const dateTimeString = moment(this.reservationInfoInDraft.started_to)
            .format(this.$t('datetime.year_month_day_hour_minute_str'))

          return this.is_block_frame_manipulation
            ? this.$t('message.block_frame_confirm_update', { dateTime: dateTimeString })
            : this.$t('message.reservation_confirm_update', { dateTime: dateTimeString })
        }
        return ''
      },
      continueMoveModeMessageTitle () {
        if (this.is_block_frame_manipulation) {
          return this.$t('text.move_mode_block_frame_popup_title')
        }

        return this.$t('text.move_mode_reservation_popup_title')
      },
      continueMoveModeMessageBody () {
        if (this.is_block_frame_manipulation) {
          return this.$t('message.block_frame_confirm_continue_move_mode')
        }

        return this.move_mode_current_trigger_action
          ? this.$t('message.reservation_confirm_continue_move_mode_trigger_by_action')
          : this.$t('message.reservation_confirm_continue_move_mode')
      },
      isDetectAutoFrameOperation: function () {
        return this.$store.getters.isDetectAutoFrameOperation &&
          [COLUMN_TYPE.AUTO, COLUMN_TYPE.CSL].includes(
            get(this.allocation_destination, 'column_type', COLUMN_TYPE.NOT_AUTO)
          )
      },
      operationReasonResources () {
        const event = this.event_resize_or_move?.event || {}
        let eventData = get(event, 'extendedProps', {})
        let startDate = event.start ? moment(event.start) : ''
        let endDate = event.end ? moment(event.end) : ''
        if (this.isEnableMoveMode) {
          eventData = { ...this.reservationInfoInDraft }
          startDate = eventData.start ? moment(eventData.start) : ''
          endDate = eventData.end ? moment(eventData.end) : ''
        }

        if (startDate && startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) {
          startDate = endDate.clone().startOf('day')
        }

        const reservationTime = (startDate ? startDate.format('YYYY/MM/DD HH:mm') : '') +
          ' ~ ' + (startDate ? endDate.format('HH:mm') : '')

        let brandName = ''
        const cslBrandType = get(eventData, 'csl_brand_type', null)
        if (cslBrandType !== null) {
          const brandNameFromType = getBrandNameFromType(cslBrandType, this.$t('objects.patient.brand_master_filter'))
          if (brandNameFromType) {
            brandName = `${brandNameFromType} ${this.$t('new_text.csl_character')}`
          }
        }

        if (!brandName) {
          brandName = get(eventData, 'patient_info.brand_master.name', '')
        }

        return {
          sf_user_id: get(eventData, 'sf_user_id', 0),
          name: get(eventData, 'patient_info.name', ''),
          allocation_name: get(this.allocation_destination, 'column_name'),
          brand_type_name: brandName,
          memo: get(eventData, 'memo', ''),
          reservation_time: reservationTime
        }
      },
      eventUpdateTypeBlock () {
        if (this.isEnableMoveMode) {
          return false
        }

        return !!get(this.event_resize_or_move, 'event.extendedProps.blocked', false)
      }
    },
    watch: {
      userInfo: function (userInfo) {
        if (typeof userInfo.clinic !== 'undefined') {
          const calendarApi = this.$refs.fullCalendar.getApi()
          calendarApi.rerenderEvents()
        }
      },
      toggle: function (value) {
        if (value) {
          this.max_resource = 7
        } else {
          this.max_resource = 8
        }

        this.generateResources(this.allocations)
        this.removeActiveReservation()
        this.calculateWidthCalendar()
      },
      enable_auto_reload (newValue) {
        if (
          newValue &&
          !this.isEnableMoveMode &&
          !this.data_create_reservation &&
          !this.data_update_reservation &&
          !this.data_patient_detail &&
          !this.isUsersManipulating &&
          !this.url_reservation_id &&
          !this.is_show_modal_create_allocation &&
          !this.allocation_edit &&
          !this.is_show_modal_confirm_first_reservation &&
          !this.event_resize_or_move &&
          !this.is_resize_or_move &&
          !this.is_print_calendar
        ) {
          this.requestDataCalendar()
        }
      },
      isUsersManipulating () {
        this.removeActiveReservation()
      },
      selectedDate (newValue) {
        if (newValue) {
          this.handleGoToDate()
        }
      }
    },
    created () {
      this.is_admin = 1
      this.requestDataCalendar()
      this.requestClinicInfo()
      this.url_reservation_id = this.$route.query.reservation_id ? parseInt(this.$route.query.reservation_id) : null
      this.show_current_line = setInterval(this.showLineCurrentTime, 1000)
    },
    mounted () {
      $(document).on('click', '.header-column--add', () => {
        this.is_show_modal_create_allocation = true
        this.removeActiveReservation()
      })
      $(document).on('click', '.header-column__dotted', () => {
        this.removeActiveReservation()
        const allocationId = $(event.target).data('column')
        this.allocation_edit = this.resources.filter((c) => c.id === allocationId)[0] || {}
      })
    },
    updated () {
      window.offset = el => {
        if (el) {
          const rect = el.getBoundingClientRect()
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop

          return rect.top + scrollTop
        }

        return null
      }

      this.$nextTick(() => {
        this.handleTimeGridHover()

        if (!this.getReservationMirror() || !this.getReservationMirrorContainer()) {
          this.createReservationMirrorContainer()
          this.appendMirrorFromStore()
        }
      })
    },
    beforeDestroy () {
      $(document).off('click', '.header-column__dotted')
      $(document).off('click', '.header-column-add')
      clearInterval(this.timer)
      clearInterval(this.show_current_line)
    },
    methods: {
      async requestDataCalendar () {
        await this.requestBusinessTime()
        this.requestListReservation()
      },
      handleSucceedAllocation (message) {
        this.is_show_modal_create_allocation = false
        this.allocation_edit = null
        showSuccessModal(message)
        this.requestDataCalendar()
      },
      handleEventOverLap: function () {
        return false
      },
      handleSelectOverLap: function () {
        return false
      },
      scrollFixedTime: function () {
        $('.fixed-time').scrollTop($('.fc-resourceTimeGridDay-view').scrollTop())
      },
      applyScrollCalendar: function () {
        let timeout

        $('.fc-resourceTimeGridDay-view, .fixed-time').on('scroll', function callback () {
          clearTimeout(timeout)

          const source = $(this)
          const target = $(source.is('.fc-resourceTimeGridDay-view') ? '.fixed-time' : '.fc-resourceTimeGridDay-view')

          target.off('scroll').scrollTop(source.scrollTop())

          timeout = setTimeout(function () {
            target.on('scroll', callback)
          }, 100)
        })
      },
      checkIsValidDate: function (date) {
        if (moment(date) > moment().add(365, 'days')) {
          showErrorModal(this.$t('sweet_alert.available_date_warning'))

          return false
        }
        if (this.checkIsBeforeReleaseDate(date)) {
          showErrorModal(this.$t('sweet_alert.before_release_date_warning'), {
            customClass: {
              container: 'med-message med-message__under-date',
              popup: 'med-message__popup med-message__popup--error',
              title: 'med-message__title',
              header: 'med-message__header',
              image: 'med-message__image'
            }
          })

          return false
        }

        return true
      },
      checkIsBeforeReleaseDate: function (date) {
        const releaseDate = moment(process.env.MIX_SERVICE_RELEASE_DATE)

        return moment(date) < releaseDate
      },
      setSearchDate: function (searchDate) {
        this.$emit('selected-date', searchDate)
        this.$router.push({ path: '/reservation/list', query: { search_date: searchDate } })
        this.requestDataCalendar()
        this.removeActiveReservation()
      },
      customHeader: function () {
        return {
          namePage: {
            text: this.$t('page_title.u8_2')
          },
          today: {
            text: this.$t('common.today'),
            click: () => {
              const date = moment().format('YYYY-MM-DD')
              if (this.checkIsValidDate(date)) {
                this.$emit('selected-date', date)
              }
            }
          },
          prevMonth: {
            icon: 'prev-month',
            click: () => {
              const calendarApi = this.$refs.fullCalendar.getApi()
              const date = moment(calendarApi.getDate()).subtract(1, 'months').format('YYYY-MM-DD')
              if (this.checkIsValidDate(date)) {
                this.$emit('selected-date', date)
              }
            }
          },
          nextMonth: {
            icon: 'next-month',
            click: () => {
              const calendarApi = this.$refs.fullCalendar.getApi()
              const date = moment(calendarApi.getDate()).add(1, 'months').format('YYYY-MM-DD')
              if (this.checkIsValidDate(date)) {
                this.$emit('selected-date', date)
              }
            }
          },
          prev: {
            click: () => {
              const calendarApi = this.$refs.fullCalendar.getApi()
              const date = moment(calendarApi.getDate()).subtract(1, 'days').format('YYYY-MM-DD')
              if (this.checkIsValidDate(date)) {
                this.$emit('selected-date', date)
              }
            }
          },
          next: {
            click: () => {
              const calendarApi = this.$refs.fullCalendar.getApi()
              const date = moment(calendarApi.getDate()).add(1, 'days').format('YYYY-MM-DD')
              if (this.checkIsValidDate(date)) {
                this.$emit('selected-date', date)
              }
            }
          },
          reloadPage: {
            icon: 'reload-page',
            click: () => {
              this.requestDataCalendar()
              this.removeActiveReservation()
            }
          }
        }
      },
      calculateHeightCalendar: function () {
        $('.fc-view-container').height($(window).height() - 60)
        $('.fixed-time').height($(window).height() - 60)
      },
      resourceRender: function (renderInfo) {
        let frameAuto = ''
        let headerClass = ''
        let actionHtml = ''

        if (renderInfo.resource.extendedProps.column_type === COLUMN_TYPE.AUTO) {
          frameAuto = `<div class='header-column__auto-label'>${this.$t('reservation.frame_auto')}</div>`
          headerClass = 'header-column--auto'
        }

        if (renderInfo.resource.extendedProps.column_type === COLUMN_TYPE.CSL) {
          frameAuto = '<div class="header-column__auto-label header-column__auto-label--csl">' +
            `${this.$t('objects.allocation.frame_csl')}</div>`
          headerClass = 'header-column--csl'
        }

        if (renderInfo.resource.extendedProps.column_type === COLUMN_TYPE.ADD_NEW) {
          headerClass = 'header-column--add'
        }

        if (renderInfo.resource.extendedProps.column_type === COLUMN_TYPE.EMPTY) {
          headerClass = 'header-column--empty'
        }

        if (
          renderInfo.resource.extendedProps.column_type === COLUMN_TYPE.NOT_AUTO ||
          renderInfo.resource.extendedProps.column_type === COLUMN_TYPE.AUTO ||
          renderInfo.resource.extendedProps.column_type === COLUMN_TYPE.CSL
        ) {
          actionHtml = '<div data-staff="' + renderInfo.resource.extendedProps.staff_id +
            '" data-type="' + renderInfo.resource.extendedProps.column_type +
            '" data-column="' + renderInfo.resource.id +
            '" class="header-column__dotted"></div>'
        }
        if (this.is_admin) {
          $(renderInfo.el).wrapInner("<div class='header-column__name'></div>")
          $(renderInfo.el).prepend(frameAuto)
          $(renderInfo.el).prepend(actionHtml)
          $(renderInfo.el).wrapInner(
            "<div class='header-column " + headerClass + ' header-column-' + renderInfo.resource.id + "'></div>"
          )
        } else {
          $(renderInfo.el).wrapInner("<div class='header-column__name'></div>")
          $(renderInfo.el).prepend(frameAuto)
          $(renderInfo.el).wrapInner(
            "<div class='header-column " + headerClass + ' header-column-' + renderInfo.resource.id + "'></div>"
          )
        }
      },
      calculateWidthCalendar: function () {
        let windowWidth = window.innerWidth - 60 - 5
        let maxResource = this.max_resource + 1

        if (!this.toggle) {
          windowWidth -= 20
          maxResource -= 1
        }

        const allocationItemWidth = windowWidth / maxResource
        const allocationWidth = (allocationItemWidth * this.resources.length) + 60

        if (this.toggle) {
          $('.calendar').css('width', `calc(100% - ${allocationItemWidth}px)`)
          document.getElementById('btn-right').style.right = (allocationItemWidth + 15) + 'px'
        } else {
          $('.calendar').css('width', 'calc(100% - 20px)')
          document.getElementById('btn-right').removeAttribute('style')
        }

        $('#calendar .calendar-manage').css('width', allocationWidth + 'px')
      },
      windowResize: function () {
        this.calculateWidthCalendar()
        this.calculateHeightCalendar()
      },
      renderClassForTimeListLine (timePart) {
        const minute = parseInt(moment('2016-12-20T' + timePart).format('m'))
        if (minute % 10 === 0) {
          return 'time-line'
        }
      },
      datesPrintRender: function (info) {
        const vm = this
        const firstTime = moment('2016-12-20 ' + vm.min_active_time_print)
        vm.page_print_range = {}

        $('#print-calendar .fc-axis.fc-time').each(function () {
          const timeItem = moment('2016-12-20 ' + $(this).parent().data('time'))
          const minutesDiff = timeItem.diff(firstTime, 'minutes')
          const minutesPerPage = 540 // 9 hour
          const page = minutesDiff / minutesPerPage

          if (minutesDiff % minutesPerPage === 0) {
            if (vm.page_print_range['page-' + (page - 1)]) {
              vm.page_print_range['page-' + (page - 1)].max_time = moment('2016-12-20 ' + $(this).parent()
                .data('time')).add(10, 'minutes').format('HH:mm:ss')
            }

            vm.page_print_range['page-' + page] = {
              min_time: $(this).parent().data('time'),
              max_time: vm.max_active_time_print
            }
          }
          $(this).addClass(vm.renderClassForTimeList($(this).parent().data('time')))
          $(this).parent().find('td:eq(1)').addClass(vm.renderClassForTimeListLine($(this).parent().data('time')))
        })
        $(info.el).addClass('custom-view calendar-manage')

        const calendarApi = vm.$refs.fullCalendar.getApi()
        const startDate = moment(calendarApi.getDate()).format('MM/DD')
        const endDate = moment(calendarApi.getDate()).locale(vm.$i18n.locale).format('dd')

        $('#print-calendar .fc-center h2').html(
          `<div class="input-group date header-calendar datepicker datepicker--header-calender medusa-date">
              <input type="hidden" class="form-control" />
              <span class="input-group-addon">${startDate}(${endDate})</span>
          </div>`)
      },
      datesRender: function (info) {
        const vm = this

        $('#calendar .fc-axis.fc-time').each(function () {
          const customClass = vm.renderClassForTimeList($(this).parent().data('time'))

          $(this).addClass(customClass)
          $(this).parent().find('td:eq(1)').addClass(customClass)
        })
        $(info.el).addClass('custom-view calendar-manage')

        if ($('.fixed-time').length) {
          $('.fixed-time').html('<div class="hide-top"></div>' + this.renderFixedTime(vm))
          $('.fixed-time').append('<span class="time-line-text"></span>')
        } else {
          $(info.el).parent().parent().append(
            '<div class="fixed-time"><span class="time-line-text"></span><div class="hide-top"></div>' + this.renderFixedTime(vm) + '</div>'
          )
        }

        const calendarApi = this.$refs.fullCalendar.getApi()
        const startDate = moment(calendarApi.getDate()).format('MM/DD')
        const endDate = moment(calendarApi.getDate()).locale(this.$i18n.locale).format('dd')

        $('#calendar .fc-center h2').html(
          `<div class="input-group date header-calendar datepicker datepicker--header-calender calendar--header-date medusa-date" id="datetimepicker-header">
              <input type="hidden" class="form-control" />
              <span class="input-group-addon">${startDate}</span>
              <span class="input-group-addon input-group-addon--end-date">(${endDate})</span>
              <div class="calendar__overlay" />
          </div>`)
      },
      renderFixedTime: (ref) => {
        const generationListTime = () => {
          const period = 5
          const listTime = []
          const minActiveTimeShort = ref.min_active_time.slice(0, -3)
          const maxActiveTimeShort = ref.max_active_time.slice(0, -3)

          for (let i = 0; i < 1440; i += period) { // 1440 = 60*24
            const hour = Math.floor(i / 60)
            const minute = i % 60
            const timeStr = (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute

            if (ref.is_closed_all_day || (timeStr >= minActiveTimeShort && timeStr < maxActiveTimeShort)) {
              listTime.push(timeStr)
            }
          }

          return listTime
        }

        let html = '<ul>'
        const listTime = generationListTime()
        ref.time_fixed_length = listTime.length

        for (const i in listTime) {
          const item = listTime[i]
          let customClass = (parseInt(i) + 1) % 4 === 0 ? 'solid-border' : ''
          customClass += /5$/.test(item) ? ' time-hide' : ''
          customClass += /(00|30)$/.test(item) ? ' time-bold' : ' time-regular'
          html += `<li class="${customClass} time-${listTime[i].replace(':', '')}"><span>` + listTime[i] + '</span></li>'
        }

        html += '</ul>'

        return html
      },
      renderClassForTimeList (timePart) {
        const minute = parseInt(moment('2016-12-20T' + timePart).format('m'))
        if (minute === 0 || minute === 30) {
          return 'time-bold'
        } else {
          return 'time-regular'
        }
      },
      handleScrollToViewStartTime: function (viewStartTime) {
        if (viewStartTime !== this.min_active_time) {
          const selectedEle = $("tr[data-time='" + viewStartTime + "']")

          if (selectedEle.length) {
            $('.fc-resourceTimeGridDay-view').scrollTop(selectedEle.position().top - 20)
          }
        }
      },
      generateResources: function (allocations) {
        const newAllocations = Array.from(allocations)

        if (this.is_admin) {
          newAllocations.push(this.init_resource_add)
        }

        if (newAllocations.length < this.max_resource) {
          const lengthResourceEmpty = this.max_resource - newAllocations.length

          for (let i = 0; i < lengthResourceEmpty; i++) {
            newAllocations.push(Object.assign({ ...this.init_resource_empty }, { id: 'empty-' + i }))
          }
        }

        this.resources = newAllocations
      },
      generateResourcesPrint: function (allocations) {
        const newAllocations = Array.from(allocations)

        if (newAllocations.length < 6) {
          const lengthResourceEmpty = 6 - newAllocations.length

          for (let i = 0; i < lengthResourceEmpty; i++) {
            newAllocations.push(Object.assign({ ...this.init_resource_empty }, { id: 'empty-' + i }))
          }
        }

        this.resources_print = newAllocations
      },
      eventRender: function (info) {
        if (typeof this.userInfo.clinic === 'undefined') {
          return
        }

        Vue.nextTick(() => {
          this.diff_minutes_new_reservation = 0
          if (info.event.id === '' && this.is_first_render_new_reservation) {
            this.modifyNewFrameReservation(info)
          }
        })

        const patientInfo = info.event.extendedProps.patient_info
        const isEventKIDS = patientInfo && patientInfo.brand_type === KIDS_BRAND_TYPE
        const event = new EventClass({
          i18n: this.$i18n,
          propsData: {
            event: info.event,
            isInterview: Number(this.userInfo.clinic.is_interview || 0),
            isKids: isEventKIDS
          }
        })

        event.$on('patientDetail', this.handlePatientDetailShow)
        event.$on('patientQuestionnaires', this.handlePatientQuestionnairesShow)
        event.$mount()
        info.el.querySelector('.fc-time').style.display = 'none'
        info.el.querySelector('.fc-content').appendChild(event.$el)
        info.el.classList.add(`reservation-id-${info.event.id}`)
        info.el.classList.add(`event-id-${info.event.id}`)
        info.el.classList.add('frame-reservation')
        info.el.setAttribute('data-reservation-id', info.event.id)
        if (info.event.id) {
          info.el.classList.add('fc-event--booked')
          info.el.classList.add(`event-id-${info.event.id}`)
          if (this.url_reservation_id === parseInt(info.event.id)) {
            info.el.classList.add('hide-mask')
          }
        }
        if (this.isEnableMoveMode && parseInt(info.event.id) === this.getReservation.id) {
          info.el.classList.add('target-move-event')
        }
      },
      eventPrintRender: function (info) {
        const event = new EventPrintClass({
          i18n: this.$i18n,
          propsData: {
            event: info.event,
            isInterview: Number(this.userInfo.clinic.is_interview || 0),
            isPrintMemo: this.isPrintMemo,
            allocations: this.resources_print.length
          }
        })

        event.$mount()

        info.el.querySelector('.fc-time').style.display = 'none'
        info.el.querySelector('.fc-content').appendChild(event.$el)

        if (info.event.id) {
          info.el.classList.add('fc-event--booked')
        }
      },
      requestListReservation: function (resetMoveBookingMode = false) {
        if (!this.selectedDate || !this.$i18n) {
          return
        }

        this.$store.commit('set_loading', true)
        const submitData = {
          date: moment(this.selectedDate, this.$t('datetime.year_month_day')).format(this.$t('datetime.year_month_day', 'en'))
        }

        this.$store.dispatch('getListReservation', submitData).then((result) => {
          const {
            data: {
              results: {
                reservations,
                allocations,
                isAdmin,
                minActiveTime,
                maxActiveTime,
                viewStartTime
              },
              status
            }
          } = result

          this.$store.commit('set_loading', false)

          if (status !== RESPONSE_STATUS.OK) {
            showErrorModal(this.$t('sweet_alert.something_went_wrong'))

            return
          }
          if (resetMoveBookingMode) {
            this.hardResetDomEvent()
            this.resetReservationMoveMode()
            this.$forceUpdate()
          }
          this.is_admin = isAdmin || 0
          this.is_closed_all_day = (minActiveTime === maxActiveTime)
          this.min_active_time = this.calculateMinOrMaxTime(minActiveTime)
          this.max_active_time = this.calculateMinOrMaxTime(maxActiveTime, false)
          this.allocations = allocations
          this.generateResources(allocations)
          this.generateResourcesPrint(allocations)
          this.events = reservations
          this.enable_auto_reload = false
          this.getReservationPrintInstruction()
          this.getReservationCancelled()
          this.getReservationPrintTechnicalInfo()

          const correctReservations = _.filter(reservations, (reservation) => reservation.patient_id !== 0)
          const reservationIds = _.map(correctReservations, 'id')
          this.getReservationQuestionnaires(reservationIds || [])

          let reservationReserved = 0
          reservations.forEach(element => {
            if (element.patient_id !== 0) {
              reservationReserved += 1
            }
          })
          this.$emit('set-total-reservation-reserved', reservationReserved)

          this.$nextTick(() => {
            this.calculateWidthCalendar()
            this.calculateHeightCalendar()
            this.applyScrollCalendar()
            this.handleScrollToViewStartTime(viewStartTime || this.min_active_time)
            this.scrollFixedTime()
            this.showLineCurrentTime(true)
            this.isExistReservationHaveInterview()
            this.setReservationActiveData()
            this.addEventToDateTimePicker()
            this.removeFrameReservation()
            this.addTimeEmptyToPrintCalendar()

            const timeArr = this.min_active_time.split(':')

            if (this.is_closed_all_day || timeArr[1] === '00' || timeArr[1] === '30') {
              $('#calendar .fc-body .fc-widget-content .fc-day-grid .fc-bg').addClass('fc-bg--line-bold')
            }
          })
        }).catch((error) => {
          const {
            response: {
              status,
              data: { errors }
            }
          } = error

          this.$store.commit('set_loading', false)
          const resErrors = errors || {}
          const arrErrors = []
          let errorMessage = this.$t('sweet_alert.something_went_wrong')

          if (status !== INTERNAL_SERVER_ERROR) {
            for (const index in resErrors) {
              if (resErrors[index]) {
                arrErrors.push(resErrors[index])
              }
            }

            if (arrErrors.length > 0) {
              errorMessage = arrErrors.join('<br>')
            }
          }

          showErrorModal(errorMessage)
        })
      },
      getReservationQuestionnaires (reservationIds) {
        this.$store.dispatch('getReservationQuestionnaires', { ids: reservationIds }).then((result) => {
          const results = _.get(result, 'data.results') || []
          const calendarApi = this.$refs.fullCalendar.getApi()
          for (let i = 0; i < results.length; i++) {
            const event = calendarApi.getEventById(results[i])
            event.setExtendedProp('has_completed_questionnaire', true)
          }

          this.removeFrameReservation()
        }).catch(() => {
          showErrorModal(this.$t('new_message.something_went_wrong'))
        })
      },
      calculateMinOrMaxTime (time, isMin = true) {
        let result = time

        if (result) {
          const timeArr = result.split(':')
          let hour = parseInt(timeArr[0])
          let minutes = parseInt(timeArr[1])

          if (minutes % 2 !== 0) {
            minutes = isMin ? minutes - 5 : minutes + 5
          }

          if (minutes === 60) {
            minutes = 0
            hour++
          }

          minutes = minutes < 10 ? '0' + minutes : minutes
          hour = hour < 10 ? '0' + hour : hour
          result = `${hour}:${minutes}:00`
        }

        return result
      },
      clickToFullCalendar (arg) {
        this.is_reservation_cancelled = false
        if (this.isEnableMoveMode) {
          this.allocation_destination = arg.resource.extendedProps
          const allocationId = arg.resource.id

          if (allocationId === 'add') {
            return
          }

          const startTime = moment(arg.start).format('YYYY-MM-DD HH:mm:ss')
          const timePeriod = moment(this.getReservation.ended_from).diff(moment(this.getReservation.started_to))
          const endTime = moment(arg.start).add(moment.duration(timePeriod).asMinutes(), 'minutes').format('YYYY-MM-DD HH:mm:ss')
          const expectedReservationData = Object.assign({}, this.getReservation)
          expectedReservationData.started_to = startTime
          expectedReservationData.ended_from = endTime
          expectedReservationData.end = endTime
          expectedReservationData.start = startTime
          expectedReservationData.allocation_id = parseInt(allocationId)
          if (this.checkExistReservationTime(expectedReservationData)) {
            showErrorModal(this.$t('reservation.reservations_already_exist'))
            const eventMirror = document.querySelector('.fc-mirror')
            eventMirror.style.opacity = '0'
            return
          }
          this.date_start_move_mode = moment(this.getReservation.started_to).format('YYYY-MM-DD')
          this.reservationInfoInDraft = expectedReservationData
          this.is_block_frame_manipulation = expectedReservationData.block === 1
          const submitData = {
            started_to: moment(startTime, this.$t('datetime.year_month_day_hour_minute')).format(this.$t('datetime.year_month_day_hour_minute', 'en')),
            patient_id: expectedReservationData.patient_id,
            id: expectedReservationData.id
          }
          const vm = this
          const handleMain = function () {
            vm.move_mode_show_modal_confirm_update_reservation = true
          }

          this.$store.commit('set_loading', true)
          this.checkPopupChangeFirstReservation(submitData).then(obj => {
            const { data: { status, results } } = obj
            this.$store.commit('set_loading', false)
            if (status === RESPONSE_TYPE.OK) {
              if (results.is_first_reservation === true) {
                this.show_modal_update_draft = true
                return
              }
              handleMain()
              return
            }
            showErrorModal(this.$t('message.error_occurred'))
          }).catch(() => {
            showErrorModal(this.$t('message.error_occurred'))
            this.$store.commit('set_loading', false)
          })
          this.active_reservation_on_mouse = false
          const eventClone = this.getReservationMirror()
          if (eventClone) {
            const eventMirror = document.querySelector('.fc-mirror')
            const eventAllocationParent = eventMirror.parentElement.parentElement
            const eventWrapper = eventAllocationParent.querySelector('.fc-event-container:not(.fc-mirror-container)')
            eventWrapper.appendChild(eventClone)
            eventClone.style.top = eventMirror.style.getPropertyValue('top')
            eventClone.style.left = eventMirror.style.getPropertyValue('left')
            eventClone.style.position = 'absolute'
            eventMirror.style.opacity = '0'
          }
          return
        }
        if (
          arg.allDay ||
          arg.resource.extendedProps.column_type === COLUMN_TYPE.ADD_NEW ||
          arg.resource.extendedProps.column_type === COLUMN_TYPE.EMPTY
        ) {
          return
        }

        if (this.diff_minutes_new_reservation) {
          arg.endStr = moment(arg.start).add(this.diff_minutes_new_reservation, 'minutes').format()
        }

        this.diff_minutes_new_reservation = 0
        this.is_first_render_new_reservation = true
        this.data_create_reservation = arg
      },
      modifyNewFrameReservation (arg) {
        const { el: elEvent, event } = arg

        function getResourceId () {
          const eventOffsetX = getOffsetX(elEvent, '#calendar .fc-view-container')
          const resources = [...document.querySelectorAll('#calendar .fc-widget-header .fc-resource-cell')]
          const resource = resources.find(function (element) {
            const resourceOffsetX = getOffsetX(element, '#calendar .fc-view-container')
            return eventOffsetX - 2 < resourceOffsetX && resourceOffsetX < eventOffsetX + 2
          })

          if (resource) {
            return parseInt(resource.dataset.resourceId)
          }

          return 0
        }

        function getOffsetX (element, parentSelector) {
          const rectChild = element.getBoundingClientRect()
          const rectParent = document.querySelector(parentSelector).getBoundingClientRect()

          return rectChild.left - rectParent.left
        }

        try {
          const resourceId = getResourceId()
          let diffMinutes = 20

          if (!resourceId ||
            moment(event.end).diff(moment(event.start), 'minutes') >= diffMinutes
          ) {
            return false
          }

          const startTime = moment(event.start).format('HH:mm')
          let endTime = moment(event.end).add(15, 'minutes').format('HH:mm')
          let eventOverLap = null

          this.events.forEach(event => {
            if (event.allocation_id !== resourceId) {
              return
            }

            const start = moment(event.started_to).format('HH:mm')

            if (start > startTime && start < endTime) {
              if (!eventOverLap) {
                eventOverLap = event
              } else {
                const startTimeOverlap = moment(eventOverLap.started_to).format('HH:mm')

                if (start < startTimeOverlap) {
                  eventOverLap = event
                }
              }
            }
          })

          if (eventOverLap) {
            const start = moment(event.start)
            const startOverlap = moment(eventOverLap.started_to)
            diffMinutes = startOverlap.diff(start, 'minutes')
          }

          elEvent.style.height = (36 * diffMinutes / 10) - 3 + 'px'
          const contentEl = elEvent.querySelector('.event-card__empty > div')
          endTime = moment(event.start).add(diffMinutes, 'minutes').format('HH:mm')
          contentEl.innerHTML = `<p>${startTime}〜${endTime}</p><p>(${diffMinutes}<span>分</span>)</p>`

          this.diff_minutes_new_reservation = diffMinutes
          this.is_first_render_new_reservation = false
        } catch (e) {
          return false
        }
      },
      handleAfterCreateReservation (data) {
        const successMessage = data.blocked ? this.$t('message.block_created') : this.$t('message.reservation_created')
        showSuccessModal(successMessage)
        this.data_create_reservation = null
        this.requestDataCalendar()
      },
      removeFrameReservation () {
        const eventMirror = document.querySelector('.fc-mirror')
        if (eventMirror) {
          eventMirror.remove()
        }
      },
      switchReservationFrame: function (direction = 'right') {
        let switchIndex = 0
        const container = document.getElementsByClassName('fc-view-container')[0]
        let i
        for (i = 0; i < this.resources.length; i++) {
          const anchorXX = $('.fc-head-container table th.fc-resource-cell').eq(i + 1).offset().left -
            $('.fc-head-container table th.fc-resource-cell').eq(0).offset().left
          let scrollLeftView = container.scrollLeft

          if (direction === 'right') {
            scrollLeftView = scrollLeftView + 2
          } else {
            scrollLeftView = scrollLeftView - 2
          }

          if (scrollLeftView < anchorXX) {
            switchIndex = i

            if (direction === 'left') {
              switchIndex = i + 1
            }
            break
          }
        }

        if (direction === 'left') {
          if (switchIndex === 0 && container.scrollLeft === 0) {
            return
          }
          if (switchIndex > 0) {
            switchIndex--
          }
        } else {
          if (switchIndex === (this.resources.length - this.max_resource)) {
            return
          }
          switchIndex++
        }
        this.direction_position = switchIndex

        const anchorX = $('.fc-head-container table th.fc-resource-cell').eq(switchIndex).offset()
        const timeWidth = $('.fc-head-container table th.fc-resource-cell').eq(0).offset().left

        let scrollLeft = anchorX.left - timeWidth

        if (scrollLeft < 0) {
          scrollLeft = 0
        }

        $('.fc-view-container').animate({
          scrollLeft
        }, 0)
      },
      clickToReservation (arg) {
        this.is_block_frame_manipulation = arg?.event?.extendedProps?.block === 1
        if (this.isEnableMoveMode) {
          return
        }
        if (this.handleClickEnableMoveMode(arg)) {
          const patient = arg?.event?.extendedProps?.patient || {}
          if (patient && parseInt(arg?.event?.id) === parseInt(patient.first_reservation_id)) {
            if (patient.first_reservation_month) {
              return showErrorModal(this.$t('objects.reservation.messages.reservation_can_not_change'))
            }

            if (patient.order_count > 0) {
              return showErrorModal(
                this.$t('objects.reservation.messages.reservation_can_not_change_because_has_order'),
                { width: 'auto' }
              )
            }
          }

          this.active_reservation_id = parseInt(arg.event.id)
          const targetReservation = _.find(this.events, { id: this.active_reservation_id })
          const currentDom = document.querySelector(`.fc-event[data-reservation-id="${this.active_reservation_id}"]`)
          const currentPosition = currentDom.getBoundingClientRect()
          const initPosition = { x: currentPosition.left, y: currentPosition.top }
          this.active_reservation_on_mouse = true
          this.initMoveModeData({
            reservation: targetReservation
          })
          this.createMirrorReservationOnMove(initPosition)
          this.lockEventDraggable()
          this.lockEventResize()
          return
        }
        this.data_update_reservation = arg.event
        this.removeActiveReservation()
      },
      handleAfterUpdateReservation (data) {
        const successMessage = data.blocked ? this.$t('message.block_updated') : this.$t('message.reservation_updated')
        showSuccessModal(successMessage)
        this.data_update_reservation = null
        this.requestDataCalendar()
      },
      getPosition: function (time, anotherTime, isStartedTo = 1) {
        const timeArr = time.split(':')
        const rowTimes = Object.keys(this.upper_position)
        const valuePositions = Object.values(this.upper_position)
        if (!this.minutes_end_5.includes(timeArr[1])) {
          if (rowTimes.indexOf(time) === -1) {
            return isStartedTo ? valuePositions.shift() : valuePositions.pop()
          }

          return this.upper_position[time]
        }

        const startTime = moment(time, 'HH:mm:ss').subtract(5, 'minutes').format('HH:mm:ss')
        const endTime = moment(time, 'HH:mm:ss').add(5, 'minutes').format('HH:mm:ss')
        if (isStartedTo && rowTimes.indexOf(startTime) === -1) {
          return valuePositions.shift()
        }

        if (!isStartedTo && rowTimes.indexOf(endTime) === -1) {
          return valuePositions.pop()
        }

        return (this.upper_position[startTime] + this.upper_position[endTime]) / 2
      },
      showReservationCancelled: function () {
        if (this.cancelled !== null && typeof this.cancelled !== 'undefined') {
          const seat = document.querySelectorAll('#calendar .fc-widget-header .fc-resource-cell')
          const times = document.querySelectorAll('#calendar .fc-time-grid tr[data-time]')
          const node = document.querySelector('#calendar .fc-time-grid .fc-content-skeleton')
          this.upper_position = []
          let timeHeight = 0
          times.forEach(item => {
            if (!timeHeight) {
              timeHeight = item.offsetHeight
            }

            this.upper_position[item.getAttribute('data-time')] = item.offsetTop
          })

          const valuePositions = Object.values(this.upper_position)
          this.upper_position[this.max_active_time] = valuePositions.pop() + timeHeight
          seat.forEach((item, index) => {
            this.left_position[item.getAttribute('data-resource-id')] = {
              left: `calc((100% - 61px) / ${seat.length} * ${index} + 59px)`
            }
          })
          this.cancelled_width = 'calc((100% - 61px) / ' + seat.length + ')'
          $('.calendar__cancelled').remove()
          $('.calendar__cancelled').removeClass('calendar__cancelled--focus')
          this.cancelled.forEach(item => {
            if (item.id === this.url_reservation_id) {
              this.reservation_cancelled = item
              this.is_reservation_cancelled = true
            }

            const reservationCancelledStartedTo = moment(item.started_to)
            const reservationCancelledEndedFrom = moment(item.ended_from)
            const minTime = this.is_closed_all_day ? '24:00:00' : this.min_active_time
            const maxTime = this.is_closed_all_day ? '24:00:00' : this.max_active_time
            if (
              reservationCancelledStartedTo.isBefore(moment(this.selectedDate + ' ' + maxTime)) &&
              reservationCancelledEndedFrom.isAfter(moment(this.selectedDate + ' ' + minTime))
            ) {
              const startedTo = item.started_to.split(' ').pop()
              const endedFrom = item.ended_from.split(' ').pop()
              const positionStartTime = this.getPosition(startedTo, endedFrom)
              const positionEndTime = this.getPosition(endedFrom, startedTo, 0)
              const height = `${Math.abs(positionEndTime - positionStartTime)}px`
              const top = `${positionStartTime}px`
              node.insertAdjacentHTML(
                'beforeend',
                `<div
                  class="
                    calendar__cancelled
                    calendar__cancelled--${item.id}
                    ${item.id === this.url_reservation_id ? 'calendar__cancelled--focus' : ''}
                  "
                  style="
                    position: absolute;
                    top: ${top};
                    left: ${this.left_position[item.allocation_id].left};
                    width: ${this.cancelled_width};
                    height: ${height};
                  "></div>`)
            }
          })
          this.focusToReservationCancelled()
        }
      },
      getReservationCancelled: function () {
        this.$store.dispatch('getListReservationCancel', { date: this.selectedDate }).then((res) => {
          this.cancelled = res.data.results
          this.$emit('set-total-reservation-cancelled', this.cancelled.length)
          this.showReservationCancelled()
        }).catch(() => {
          showErrorModal(this.$t('sweet_alert.something_went_wrong'))
        })
      },
      handlePatientDetailShow: function (event) {
        this.data_patient_detail = event
      },
      handlePatientQuestionnairesShow: function (event) {
        const windowReference = window.open()
        openNewTab(this.$router.resolve({
          name: 'patient.questionnaires',
          params: { id: event.patienId },
          query: { reservation_id: event.reservationId }
        }).href)
          .then((response) => {
            windowReference.location = response
          })
          .catch((error) => {
            console.log('Error open new tab: ', error)
          })
      },
      handleAfterCancel (isBlocked) {
        const successMessage = isBlocked ? this.$t('message.block_cancelled') : this.$t('message.reservation_canceled')
        showSuccessModal(successMessage)
        this.data_update_reservation = null
        this.requestDataCalendar()
      },
      enableReloadReservationData () {
        this.enable_auto_reload = true
      },
      reloadReservationData: function () {
        if (this.enable_auto_reload) {
          this.requestDataCalendar()
        }
        if (!this.url_reservation_id) {
          this.is_reservation_cancelled = false
        }
      },
      requestClinicInfo () {
        this.$store.dispatch('getInfoClinic').then((result) => {
          const { data: { results, status } } = result

          if (status !== RESPONSE_STATUS.OK) {
            showErrorModal(this.$t('sweet_alert.something_went_wrong'))

            return
          }

          if (results.is_notify && results.notification_cycle) {
            this.notification_cycle = setInterval(this.requestNotificationData, parseInt(results.notification_cycle) * 60000)
          }
          if (results.is_reload && results.reloading_cycle) {
            this.timer = setInterval(this.enableReloadReservationData, parseInt(results.reloading_cycle) * 60000)
          }
        })
      },
      showLineCurrentTime (isFirstShow = false) {
        const today = new Date()
        const date = moment().format('YYYY-MM-DD')
        const time = moment().format('HH:mm:ss')
        this.is_first_show = isFirstShow
        if (moment(this.selectedDate, 'YYYY-MM-DD').isSame(moment(date, 'YYYY-MM-DD')) &&
          moment(this.max_active_time, 'HH:mm:ss').isSameOrAfter(moment(time, 'HH:mm:ss'))
        ) {
          if (this.is_first_show || today.getSeconds() === 0) {
            $('.fc-now-indicator.fc-now-indicator-line').remove()
            const timeGridHeight = $('.fc-time-grid-container').height()
            const minTime = parseInt(this.min_active_time.slice(0, 2)) * 60 + parseInt(this.min_active_time.slice(3, 5))
            const currentTime = today.getHours() * 60 + today.getMinutes()
            const top = (Math.floor(timeGridHeight / (this.time_fixed_length / 2))) * ((currentTime - minTime) / this.duration)

            $('.fc-content-col').append('<div class="fc-now-indicator fc-now-indicator-line"></div>')
            $('.fc-now-indicator-line').css('top', top)
            $('.fc-nonbusiness').parent().next().hide()

            $('.time-line-text').css('top', parseFloat($('.fc-now-indicator-line').css('top').replace(/px$/g)) + 69 + 'px')
          }
        } else {
          $('.fc-now-indicator.fc-now-indicator-line').remove()
          $('.time-line-text').remove()
        }
      },
      requestNotificationData: function () {
        this.$store.dispatch('getUnreadNotifications').then((result) => {
          this.notifications_tmp = this.notifications
          this.notifications = result?.data?.results
          const newNotifications = (this.notifications).filter(x => (this.notifications_tmp).indexOf(x) === -1)
          if (newNotifications.length) {
            showSuccessModal(this.$t('reservation.auto_notification'), {
              position: 'top-end',
              imageUrl: '/images/bell.svg',
              customClass: {
                container: 'med-message med-notify',
                popup: 'med-message__popup med-message__popup--success',
                title: 'med-message__title',
                header: 'med-message__header',
                image: 'med-message__image'
              }
            })
          }
        })
      },
      isExistReservationHaveInterview () {
        if (!this.events) {
          return false
        }

        const arr = this.events.filter((e) => { return e.interview_info && e.id === e.patient.first_reservation_id })

        if (arr.length !== 0) {
          $('#print-interview-sheet').prop('disabled', false)
        } else {
          $('#print-interview-sheet').prop('disabled', true)
        }
      },
      addEventToDateTimePicker () {
        const vm = this
        const calendarApi = this.$refs.fullCalendar.getApi()
        $('#datetimepicker-header').datetimepicker(
          {
            date: calendarApi.getDate(),
            format: 'YYYY年MM月DD日',
            useCurrent: false,
            dayViewHeaderFormat: 'YYYY / MM',
            locale: this.$i18n.locale
          }
        ).on('dp.change', function (event) {
          const date = event.date.format('YYYY-MM-DD')
          const currentDate = moment(calendarApi.getDate()).format('YYYY-MM-DD')
          if (vm.selectedDate === date) return false

          if (vm.checkIsValidDate(date)) {
            vm.$emit('selected-date', date)
          } else {
            $('.calendar__overlay').removeClass('calendar__overlay--active')
            $('#datetimepicker-header').data('DateTimePicker').date(currentDate)
          }
        }).on('dp.hide', function () {
          $('.calendar__overlay').removeClass('calendar__overlay--active')
        })

        $('#datetimepicker-header').click(() => {
          $('.calendar__overlay').addClass('calendar__overlay--active')
          $('#datetimepicker-header').datetimepicker('show')
        })

        $(document.body).on('click', '.calendar__overlay', function () {
          $('.calendar__overlay').removeClass('calendar__overlay--active')
          $('#datetimepicker-header').datetimepicker('hide')
        })
      },
      handleGoToDate: function () {
        const calendarApi = this.$refs.fullCalendar.getApi()
        const printedCalendarApi = this.$refs.printedCalendar.getApi()
        calendarApi.gotoDate(this.selectedDate)
        printedCalendarApi.gotoDate(this.selectedDate)
        this.setSearchDate(this.selectedDate)
      },
      changeDate () {
        if (this.checkIsValidDate(this.date_start_move_mode)) {
          this.$emit('selected-date', this.date_start_move_mode)
        }
      },
      async requestBusinessTime () {
        await this.$store.dispatch('getBusinessTime', { date: this.selectedDate }).then((result) => {
          const { data: { results } } = result
          let startTime = results.start_time
          const endTime = results.end_time

          if (startTime) {
            const startTimeArr = startTime.split(':')
            let minutes = parseInt(startTimeArr[1])

            if (minutes < 30) {
              minutes = '00'
            } else if (minutes > 30) {
              minutes = '30'
            }

            startTime = `${startTimeArr[0]}:${minutes}:00`
          }

          this.min_active_time_print = startTime
          this.max_active_time_print = endTime
        }).catch(() => {
          showErrorModal(this.$t('sweet_alert.something_went_wrong'))
        })
      },
      addTimeEmptyToPrintCalendar () {
        const minTime = moment('2016-12-20 ' + this.min_active_time_print)
        const maxTime = moment('2016-12-20 ' + this.max_active_time_print)
        const hour = 9
        const rowPerHour = 6

        const diffTime = maxTime.diff(minTime, 'minutes') / 60
        let totalRowEmpty = (hour - diffTime) * rowPerHour
        let i = 1

        const minuteOfMaxTime = parseInt(this.max_active_time_print.substr(3, 2))

        if (minuteOfMaxTime === 0 || minuteOfMaxTime === 30) {
          i = 2
          totalRowEmpty++
        }

        $('tr.time-empty').remove()

        for (i; i <= totalRowEmpty; i++) {
          $('#print-calendar .fc-scroller .fc-slats table tbody').append('<tr class="time-empty page-1">' +
            '<td class="fc-time fc-widget-content"></td>' +
            '<td class="fc-widget-content ' + (i % 2 === 0 ? 'time-line' : '') + '"></td>' +
            '</tr>')
        }
      },
      getReservationPrintInstruction: function () {
        const listReservation = []
        for (const key in this.events) {
          if (this.events[key].patient_id) {
            const reservation = this.events[key]
            listReservation.push({
              id: reservation.id,
              first_reservation_id: reservation.patient.first_reservation_id,
              patient_id: reservation.patient_id,
              sf_user_id: reservation.sf_user_id,
              name: reservation.patient_info.name,
              birth_of_date: reservation.patient_info.birth_of_date,
              kana_last_name: reservation.patient_info.kana_last_name,
              kana_first_name: reservation.patient_info.kana_first_name,
              started_to: reservation.started_to,
              duties: reservation.duties
            })
          }
        }
        listReservation.sort((item1, item2) => moment(item1.started_to) - moment(item2.started_to))
        this.$emit('set-reservation-print-instruction', listReservation)
      },
      getReservationPrintTechnicalInfo: function () {
        const listReservation = []
        const sfUserIds = this.$store.getters.getSfUserIdsCanPrint
        if (sfUserIds.length === 0) {
          this.$emit('set-reservation-print-technical-info', [])
          return
        }

        for (const key in this.events) {
          if (!this.events[key].patient_id) {
            continue
          }

          if (sfUserIds.filter(item => parseInt(item) === parseInt(this.events[key].sf_user_id)).length === 0) {
            continue
          }

          const reservation = this.events[key]
          if (listReservation.filter(item => item.patient_id === reservation.patient_id).length > 0) {
            continue
          }

          listReservation.push({
            id: reservation.id,
            patient_id: reservation.patient_id,
            sf_user_id: reservation.sf_user_id,
            name: reservation.patient_info.name,
            birth_of_date: reservation.patient_info.birth_of_date,
            kana_last_name: reservation.patient_info.kana_last_name,
            kana_first_name: reservation.patient_info.kana_first_name,
            duties: reservation.duties
          })
        }

        this.$emit('set-reservation-print-technical-info', listReservation)
      },
      handleUpdateReservation (type) {
        this.current_update_reservation_type = type
        if (this.isDetectAutoFrameOperation) {
          this.$store.commit('set_loading', false)
          this.is_show_modal_operation_reasons = true
          this.is_show_modal_confirm_first_reservation = false
          this.move_mode_show_modal_confirm_update_reservation = false

          return
        }

        this.submitUpdateReservation()
      },
      submitUpdateReservation (operationReasonPostData = null) {
        this.operation_reason_post_data = operationReasonPostData
        this.is_show_modal_operation_reasons = false
        if (this.current_update_reservation_type === this.update_reservation_type.RESIZE_OR_DROP) {
          return this.submitUpdateReservationResizeOrMove()
        }

        return this.moveModeSubmitUpdateReservation()
      },
      dismissUpdateReservation () {
        this.is_show_modal_operation_reasons = false
        if (this.current_update_reservation_type === this.update_reservation_type.RESIZE_OR_DROP) {
          return this.resetReservationDataResizeOrMove()
        }

        this.moveModeExit()
        this.changeDate()
      },
      storeOperationReasons (reservationId, isEventTypeBlock) {
        if (!this.operation_reason_post_data) {
          return
        }

        const postData = {
          ...this.operation_reason_post_data,
          reservation_id: reservationId,
          operation_type: isEventTypeBlock
            ? OPERATION_REASON_TYPE.UPDATE_BLOCK
            : OPERATION_REASON_TYPE.UPDATE_RESERVATION
        }

        this.$store.dispatch('storeOperationReasons', postData).finally(() => {
          this.is_show_modal_operation_reasons = false
        }).catch(() => {})
      }
    }
  }
</script>
