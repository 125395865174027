<template>
  <div class="order-list-files__card">
    <div :class="['order-list-files__thumb', {'order-list-files__thumb--lazy-load': is_loading}]">
      <div
        v-if="
          order.has_instruction_thumb === order_file_thumb_status.LOADED &&
            /\.pdf|\.PDF/.test(order.instruction_thumb_url)
        "
        class="order-list-files__image order-list-files__image--instruction"
      >
        <pdf :src="order.instruction_thumb_url" />
      </div>
      <img
        v-else-if="order.has_instruction_thumb === order_file_thumb_status.LOADED"
        class="order-list-files__image"
        :src="order.instruction_thumb_url"
        @load="onImgLoaded"
      >
      <div
        v-else-if="isLoadFileFail"
        class="order-list-files__image order-list-files__image--default"
      />
      <div
        v-else
        class="order-list-files__image"
      />
    </div>
    <div class="order-list-files__info">
      <div class="order-list-files__text">
        <div
          :class="[
            'order-list-files__name',
            { 'order-list-files__name--disabled': isLoadFileFail }
          ]"
          @click="previewInstruction"
        >
          {{ order.instruction_file }}
        </div>
        <div class="order-list-files__created-at">
          {{ formatDateTime(order.instruction_created_at, 'YYYY/M/D HH:mm:ss') }}
        </div>
        <div class="order-list-files__created-by">
          {{ safeGet(order, 'instruction_upload_by.name') }}
        </div>
      </div>
      <div
        v-if="isShowDownload"
        :class="[
          'order-list-files__download',
          { 'order-list-files__download--disabled': isLoadFileFail }
        ]"
      >
        <img
          :src="isLoadFileFail ? '/images/icon-download-gray.svg' : '/images/icon-download-blue.svg'"
          @click="downloadOrderFile(getInstructionFileDownloadUrl)"
        >
      </div>
      <div
        v-if="isShowPrintStatus"
        class="order-list-files__print"
      >
        {{ formatDateTime(order.instruction_print_date, 'YYYY/M/D HH:mm:ss') }}
        <span class="order-list-files__print-status">{{ printStatus }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import orderMixin from '../../mixins/order'
  import { formatDateTime, safeGet } from '../../utils/helpers'
  import pdf from 'vue-pdf'

  export default {
    components: {
      pdf
    },
    mixins: [orderMixin],
    props: {
      order: {
        type: Object,
        default: () => {}
      },
      isShowDownload: {
        type: Boolean,
        default: true
      },
      isShowPrintStatus: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        is_loading: true,
        instruction_print_status: 0,
        instruction_print_date: ''
      }
    },
    computed: {
      getInstructionFileDownloadUrl () {
        return `/order/attach/1/${this.order.sf_order_id || this.order.id}`
      },
      printStatus () {
        return this.instruction_print_status ? this.$t('new_text.printed') : this.$t('new_text.unprinted')
      },
      isInstructionAdmin () {
        return this.$store.getters.isInstructionAdmin
      },
      isLoadFileFail () {
        return this.order.has_instruction_thumb === this.order_file_thumb_status.FAILED
      }
    },
    watch: {
      order (newVal) {
        this.instruction_print_status = !!newVal.instruction_print_status
      }
    },
    mounted () {
      this.instruction_print_status = !!this.order.instruction_print_status
    },
    methods: {
      formatDateTime (dateTime, format) {
        return formatDateTime(dateTime, format)
      },
      safeGet (object, key) {
        return safeGet(object, key)
      },
      onImgLoaded () {
        this.is_loading = false
      },
      previewInstruction: async function () {
        if (this.isLoadFileFail) {
          return false
        }

        window.open(this.getInstructionFileDownloadUrl, '_blank')
      }
    }
  }
</script>
