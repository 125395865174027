var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-inner bg-white interview-block" }, [
    _c("div", { staticClass: "page-content" }, [
      _c("div", { staticClass: "header-title" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("interview.other_consultation")))]),
        _vm._v(" "),
        _c("p", {
          staticClass: "gray-arrow",
          class: { "toggle-icon": _vm.show },
          on: { click: _vm.handleClick }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ],
          staticClass: "other-page content-block"
        },
        [
          _c("div", { staticClass: "patient-detail-left" }, [
            _c("div", { staticClass: "row-content" }, [
              _c("div", { staticClass: "col-6 pre-block" }, [
                _c(
                  "div",
                  { staticClass: "item-pre" },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "Answer",
                      {
                        attrs: {
                          answer: _vm.others.plan_to_move,
                          question_id: "plan_to_move"
                        }
                      },
                      [
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v(_vm._s(_vm.$t("interview.when")) + "：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "　" +
                                _vm._s(_vm.others.when_to_move1) +
                                "　年　　" +
                                _vm._s(_vm.others.when_to_move2) +
                                "　月 ごろ"
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("interview.relocation")) + "："
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(_vm._s(_vm.others.moving_destination))
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 pre-block" }, [
                _c("div", { staticClass: "item-pre" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "answer" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("span", {
                        staticClass: "white-space-wrap",
                        domProps: {
                          innerHTML: _vm._s(_vm.others.other_requests)
                        }
                      })
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "question" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "control-text" }, [
          _vm._v("1. 転居の予定はありますか？")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "question" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "control-text" }, [
          _vm._v(
            "2. その他、診察に際してご要望などございました\n                    ら、ご記入ください。"
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }