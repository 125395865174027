<template>
  <div class="setting-v2 setting-v2--background">
    <div class="setting-v2__content setting-v2__content--reservation-datetime">
      <div class="setting-v2__header">
        <div class="setting-v2__header__box">
          <div class="setting-v2__header__box__text">
            {{ $t('objects.setting.text.reservation_date_setting') }}
          </div>
          <div class="setting-v2__header__box__button">
            <common-button
              class="button-v2--primary"
              :disabled="is_disable_button_save"
              @click="actionUpdate"
            >
              <template #content>
                {{ $t('new_text.button.apply_changes') }}
              </template>
            </common-button>
          </div>
        </div>
      </div>
      <div class="setting-v2__main">
        <reservation-date-setting
          ref="saveSetting"
          @disable-button-save="value => is_disable_button_save = value"
        />
      </div>
    </div>
    <Loading />
  </div>
</template>

<script>
  import Loading from '../../../components/Loading'
  import ReservationDateSetting from '../../../components/setting/v2/ReservationDateSetting'
  import Button from '../../../components/common/Button'

  export default {
    components: {
      CommonButton: Button,
      ReservationDateSetting,
      Loading
    },
    data () {
      return {
        is_disable_button_save: false
      }
    },
    created () {
      document.title = this.$t('objects.page_title.setting').toString()
    },
    methods: {
      actionUpdate () {
        this.$refs.saveSetting.saveReservationSetting()
      }
    }
  }
</script>
