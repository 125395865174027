<template>
  <transition name="modal">
    <div
      :class="['mc-modal mc-modal__pdf', customClass]"
      @click="$emit('close-modal')"
    >
      <div :class="['mc-modal__pdf__wrapper', { 'mc-modal__pdf__wrapper--img': extension !== 'application' }]">
        <div
          v-if="extension === 'application'"
          class="mc-modal__pdf__inner"
        >
          <pdf
            v-for="page in num_pages"
            :key="page"
            :src="src"
            :page="page"
            class="mc-modal__pdf__canvas"
          />
          <infinite-loading
            :identifier="infinite_scroll_id"
            @infinite="infiniteScrollHandler"
          >
            <template #no-more>
              <div class="d-none" />
            </template>
            <template #no-results>
              <div class="d-none" />
            </template>
          </infinite-loading>
        </div>
        <img
          v-if="extension === 'image'"
          :src="url"
        >
      </div>
      <div
        class="mc-modal__pdf__close"
        @click="$emit('close-modal')"
      >
        <img src="/images/close-x.svg">
      </div>
    </div>
  </transition>
</template>
<script>
  import pdf from 'vue-pdf'
  import InfiniteLoading from 'vue-infinite-loading'

  export default {
    name: 'ModalPdf',
    components: {
      pdf,
      InfiniteLoading
    },
    props: {
      customClass: {
        type: String,
        default: ''
      },
      url: {
        type: String,
        default: ''
      },
      extension: {
        type: String,
        default: ''
      }
    },
    emits: ['close-modal'],
    data () {
      return {
        src: pdf.createLoadingTask(this.url),
        total_page: 0,
        num_pages: 0,
        per_page: 2,
        infinite_scroll_id: 1
      }
    },
    mounted () {
      this.src.promise.then(pdf => {
        this.total_page = pdf.numPages

        if (pdf.numPages < this.per_page) {
          this.num_pages = pdf.numPages
        } else {
          this.num_pages = this.per_page
        }
      })
    },
    methods: {
      infiniteScrollHandler: function ($state) {
        if (this.num_pages === 0) {
          $state.reset()
        }

        if (this.num_pages === this.total_page) {
          if (this.num_pages !== 0) {
            $state.complete()
          }

          return
        }

        if ((this.total_page - this.num_pages) > this.per_page) {
          this.num_pages += this.per_page
        } else {
          this.num_pages += this.total_page - this.num_pages
        }

        this.infinite_scroll_id++
        $state.loaded()
      }
    }
  }
</script>
