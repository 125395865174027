var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "interview-consultation interview-input-block content-inner bg-white"
    },
    [
      _c("according-block", {
        attrs: { label: "事前問診", checked: _vm.show_block },
        on: {
          handleClick: function($event) {
            _vm.show_block = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show_block,
              expression: "show_block"
            }
          ],
          staticClass:
            "interview-consultation__content arrow-toggle-content page-content"
        },
        [
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("question-1", {
                  ref: "question_1",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-1"
                  }
                }),
                _vm._v(" "),
                _c("question-3", {
                  ref: "question_3",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-3"
                  }
                }),
                _vm._v(" "),
                _c("question-5", {
                  ref: "question_5",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-5"
                  }
                }),
                _vm._v(" "),
                _c("question-7", {
                  ref: "question_7",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-7"
                  }
                }),
                _vm._v(" "),
                _c("question-9", {
                  ref: "question_9",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-9"
                  }
                }),
                _vm._v(" "),
                _c("question-11", {
                  ref: "question_11",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-11",
                    patientInfo: _vm.patientInfo
                  }
                }),
                _vm._v(" "),
                _c("question-13", {
                  ref: "question_13",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-13"
                  }
                }),
                _vm._v(" "),
                _c("question-15", {
                  ref: "question_15",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-15"
                  }
                }),
                _vm._v(" "),
                _c("question-17", {
                  ref: "question_17",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-17",
                    patientInfo: _vm.patientInfo
                  }
                }),
                _vm._v(" "),
                _c("question-18", {
                  ref: "question_18",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-18",
                    patientInfo: _vm.patientInfo
                  }
                }),
                _vm._v(" "),
                _c("question-21", {
                  ref: "question_21",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-21"
                  }
                }),
                _vm._v(" "),
                _c("question-24", {
                  ref: "question_24",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-24"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("question-2", {
                  ref: "question_2",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-2"
                  }
                }),
                _vm._v(" "),
                _c("question-4", {
                  ref: "question_4",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-4"
                  }
                }),
                _vm._v(" "),
                _c("question-6", {
                  ref: "question_6",
                  attrs: { className: "interview-consultation__content__block" }
                }),
                _vm._v(" "),
                _c("question-8", {
                  ref: "question_8",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-8"
                  }
                }),
                _vm._v(" "),
                _c("question-10", {
                  ref: "question_10",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-10"
                  }
                }),
                _vm._v(" "),
                _c("question-12", {
                  ref: "question_12",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-12"
                  }
                }),
                _vm._v(" "),
                _c("question-14", {
                  ref: "question_14",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-14"
                  }
                }),
                _vm._v(" "),
                _c("question-16", {
                  ref: "question_16",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-16"
                  }
                }),
                _vm._v(" "),
                _c("question-19", {
                  ref: "question_19",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-19"
                  }
                }),
                _vm._v(" "),
                _c("question-22", {
                  ref: "question_22",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-22"
                  }
                }),
                _vm._v(" "),
                _c("question-23", {
                  ref: "question_23",
                  attrs: {
                    className:
                      "interview-consultation__content__block question-no-23"
                  }
                })
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }