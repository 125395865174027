var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "interview-information-v2 interview-information-v2--confirm"
    },
    [
      _c("according-block", {
        attrs: {
          label: _vm.$t("objects.patient.text.patient_information"),
          checked: _vm.show_block
        },
        on: {
          handleClick: function($event) {
            _vm.show_block = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show_block,
              expression: "show_block"
            }
          ],
          staticClass: "interview-information-v2__content arrow-toggle-content"
        },
        [
          _c("div", { staticClass: "interview-information-v2__group row" }, [
            _c("div", { staticClass: "col-md-6 page-content" }, [
              _c("h3", { staticClass: "title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("objects.patient.text.basic_information")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("new_text.name")))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "form-text" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.patientFullName) +
                          "\n              "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("new_text.furigana")))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "form-text" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.kanaName) +
                          "\n              "
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("new_text.roman")))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "form-text" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.romaName) +
                          "\n              "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("new_text.sex")))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "form-text" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.getGender) +
                          "\n              "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.info.pregnancy_status
                      ? _c("div", { staticClass: "status-pregnant" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("interview.currently_pregnant")) +
                              "\n              "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.info.pregnancy_status && _vm.info.gender === 1
                      ? _c("div", { staticClass: "status-not-pregnant" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("interview.not_pregnant")) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("new_text.birthday")))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "form-text" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.getBirthday) +
                          "\n              "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("new_text.profession")))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "form-text" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.getPatientOccupation) +
                          "\n              "
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 page-content" }, [
              _c("h3", { staticClass: "title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("objects.patient.text.address_contact")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v(_vm._s(_vm.$t("new_text.address")))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "form-text" }, [
                  _vm._v(
                    "\n            " + _vm._s(_vm.info.address) + "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v(_vm._s(_vm.$t("new_text.phone_number")))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "form-text" }, [
                  _vm._v(
                    "\n            " + _vm._s(_vm.info.tel) + "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v(_vm._s(_vm.$t("new_text.email")))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "form-text" }, [
                  _vm._v(
                    "\n            " + _vm._s(_vm.info.email) + "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v(_vm._s(_vm.$t("objects.patient.text.sport")))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "form-text" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.info.lesson_sport) +
                      "\n          "
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.getAge >= 0 && _vm.getAge < 18
            ? _c(
                "div",
                { staticClass: "interview-information-v2__guardian-info" },
                [
                  _c("div", { staticClass: "heading-two" }, [
                    _c("h3", { staticClass: "title" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("objects.patient.text.parent_information")
                          ) +
                          "\n        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row-content d-flex" }, [
                    _c("div", { staticClass: "col-3 m-0 p-0" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v(_vm._s(_vm.$t("new_text.name")))
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "form-text" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.guardianName) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v(_vm._s(_vm.$t("new_text.contact")))
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "form-text" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.info.guardian_phone_number) +
                              "\n            "
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-9 m-0 p-0" }, [
                      _c("div", { staticClass: "d-flex two-label" }, [
                        _c("div", { staticClass: "form-group col-4" }, [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("new_text.hiragana")))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "form-text" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.furiganaName) +
                                "\n              "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group col-4" }, [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("new_text.relationship")))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "form-text" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.info.guardian_relationship) +
                                "\n              "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group col-4" }, [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("new_text.profession")))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "form-text" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.getGuardianOccupation) +
                                "\n              "
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v(_vm._s(_vm.$t("new_text.other_address")))
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "form-text" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.info.guardian_address) +
                              "\n            "
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "heading-two" }, [
                    _c("h3", { staticClass: "title title--no-border" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("objects.patient.text.parent_info")) +
                          "\n        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row-content d-flex" }, [
                    _c("div", { staticClass: "col-3 m-0 p-0" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v(_vm._s(_vm.$t("new_text.name")))
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "form-text" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.guardian2Name) +
                              "\n            "
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-9 m-0 p-0" }, [
                      _c("div", { staticClass: "d-flex two-label" }, [
                        _c("div", { staticClass: "form-group col-4" }, [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("new_text.hiragana")))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "form-text" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.guardian2FuriganaName) +
                                "\n              "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group col-4" }, [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("new_text.relationship")))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "form-text" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.info.guardian2_relationship) +
                                "\n              "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group col-4" }, [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("new_text.contact")))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "form-text" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.info.guardian2_phone_number) +
                                "\n              "
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }