<template>
  <modal-action
    class="delete-allocation"
    :title="$t('new_message.confirmation_delete')"
    :is-show-close-button="false"
  >
    <template #footer>
      <button-common
        class="cancel-reservation__submit"
        @click="deleteColumn"
      >
        <template #content>
          {{ $t('new_text.button.delete') }}
        </template>
      </button-common>
      <button-common
        class="cancel-reservation__close"
        @click="$emit('close')"
      >
        <template #content>
          {{ $t('new_text.button.no') }}
        </template>
      </button-common>
    </template>
  </modal-action>
</template>

<script>
  import ModalAction from '../../../components/common/ModalAction'
  import ButtonCommon from '../../../components/common/Button'
  import { showErrorModal } from '../../../utils/helpers'
  import { get } from 'lodash'
  import { RESPONSE_ERRORS } from '../../../clinic-admin/utils/constants'

  export default {
    components: {
      ModalAction,
      ButtonCommon
    },
    props: {
      ipData: {
        type: Object,
        default: () => {}
      }
    },
    emits: ['close'],
    methods: {
      deleteColumn: function () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('deleteWhiteList', { id: this.ipData.id }).then(() => {
          this.$emit('deleted')
          this.$store.commit('set_loading', false)
        }).catch((error) => {
          this.$store.commit('set_loading', false)
          let errorMsg = this.$t('new_message.something_went_wrong')
          const resCode = get(error, 'response.status', null)
          const resErrors = get(error, 'response.data.errors', null)

          if (resCode === 422 && resErrors) {
            Object.keys(resErrors).some(key => {
              if (key === 'id' && resErrors[key] === RESPONSE_ERRORS.INVALID) {
                errorMsg = this.$t('objects.whitelist.message.ip_has_already_been_deleted')
              }
            })
          }

          showErrorModal(errorMsg)
        })
      }
    }
  }
</script>
