var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-modal-action", {
    staticClass: "modal-allocation",
    attrs: { title: _vm.$t("reservation.allocation.title") },
    on: {
      close: function($event) {
        return _vm.$emit("close")
      },
      confirm: _vm.submit
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "modal-allocation__container" }, [
              _c("div", { staticClass: "modal-allocation__group" }, [
                _c(
                  "label",
                  {
                    staticClass:
                      "modal-allocation__item modal-allocation__item--label"
                  },
                  [_vm._v(_vm._s(_vm.$t("reservation.allocation.name")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-allocation__item-content" },
                  [
                    _c("common-input", {
                      class: [
                        "modal-allocation__item-content__name",
                        { "common-input-error": _vm.errors.name_allocation }
                      ],
                      attrs: {
                        type: "text",
                        maxlength: "50",
                        placeholder: _vm.$t(
                          "new_text.placeholder.allocation_name"
                        ),
                        required: "required"
                      },
                      on: { input: _vm.handleNameChange },
                      model: {
                        value: _vm.name_allocation,
                        callback: function($$v) {
                          _vm.name_allocation = $$v
                        },
                        expression: "name_allocation"
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.name_allocation
                      ? _c("span", { staticClass: "common-msg-error" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.name_allocation) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-allocation__group" }, [
                _c(
                  "label",
                  {
                    staticClass:
                      "modal-allocation__item modal-allocation__item--label"
                  },
                  [_vm._v(_vm._s(_vm.$t("reservation.allocation.position")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "modal-allocation__item-content modal-allocation__item-content--position"
                  },
                  [
                    _c("common-select", {
                      staticClass: "common-select--color-primary",
                      attrs: {
                        options: _vm.order_list,
                        "key-value": "sortOrder",
                        "key-display": "sortOrder",
                        "default-value": _vm.sort_order
                      },
                      on: {
                        change: function(sortOrder) {
                          return (_vm.sort_order = sortOrder)
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-allocation__group" }, [
                _c(
                  "label",
                  {
                    staticClass:
                      "modal-allocation__item modal-allocation__item--label"
                  },
                  [_vm._v(_vm._s(_vm.$t("reservation.allocation.auto_check")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "modal-allocation__item-content modal-allocation__item-content--type"
                  },
                  [
                    _c("common-select", {
                      staticClass: "common-select--color-primary",
                      attrs: {
                        options: _vm.types,
                        "key-value": "value",
                        "key-display": "text",
                        "default-value": _vm.selected_type
                      },
                      on: {
                        change: function(value) {
                          return (_vm.selected_type = value)
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "modal-allocation__item modal-allocation__item--text"
                },
                [_vm._v(_vm._s(_vm.$t("reservation.allocation.text")))]
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "confirm_button_content",
        fn: function() {
          return [
            _vm._v("\n    " + _vm._s(_vm.$t("new_text.button.save")) + "\n  ")
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }