var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "interview-information-v2" },
    [
      _c("according-block", {
        attrs: {
          label: _vm.$t("objects.patient.text.patient_information"),
          checked: _vm.show_block
        },
        on: {
          handleClick: function($event) {
            _vm.show_block = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show_block,
              expression: "show_block"
            }
          ],
          staticClass: "interview-information-v2__content arrow-toggle-content"
        },
        [
          _c("div", { staticClass: "interview-information-v2__group row" }, [
            _c("div", { staticClass: "col-md-6 page-content" }, [
              _c("h3", { staticClass: "title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("objects.patient.text.basic_information")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("new_text.name")) +
                      "\n            "
                  ),
                  _c("span", { staticClass: "required" }, [_vm._v("*")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.lastName,
                          expression: "lastName"
                        }
                      ],
                      class: [
                        "form-control",
                        { "form-control--error": _vm.errors.last_name }
                      ],
                      attrs: {
                        type: "text",
                        name: "lastName",
                        placeholder: _vm.$t("new_text.last_name"),
                        maxlength: "80"
                      },
                      domProps: { value: _vm.lastName },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.lastName = $event.target.value
                          },
                          function($event) {
                            _vm.callUpdateFuriganaLast($event)
                            _vm.checkEmptyLastName($event)
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.last_name
                      ? _c("span", { staticClass: "error" }, [
                          _c("img", {
                            attrs: { src: "/images/icon-error-input.svg" }
                          }),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.last_name) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.firstName,
                          expression: "firstName"
                        }
                      ],
                      class: [
                        "form-control",
                        { "form-control--error": _vm.errors.first_name }
                      ],
                      attrs: {
                        type: "text",
                        name: "firstName",
                        placeholder: _vm.$t("new_text.first_name"),
                        maxlength: "40"
                      },
                      domProps: { value: _vm.firstName },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.firstName = $event.target.value
                          },
                          function($event) {
                            _vm.callUpdateFuriganaFirst($event)
                            _vm.checkEmptyFirstName($event)
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.first_name
                      ? _c("span", { staticClass: "error" }, [
                          _c("img", {
                            attrs: { src: "/images/icon-error-input.svg" }
                          }),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.first_name) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("new_text.roman")) +
                        "\n              "
                    ),
                    _c("span", { staticClass: "required" }, [_vm._v("*")])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.lastRomajiName,
                            expression: "lastRomajiName"
                          }
                        ],
                        class: [
                          "form-control",
                          { "form-control--error": _vm.errors.lastRomajiName }
                        ],
                        attrs: {
                          type: "text",
                          name: "lastRomajiName",
                          placeholder: _vm.$t("new_text.placeholder.sei"),
                          maxlength: "191"
                        },
                        domProps: { value: _vm.lastRomajiName },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.lastRomajiName = $event.target.value
                            },
                            _vm.checkHaffSizeLastRomajiName
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.lastRomajiName
                        ? _c("span", { staticClass: "error" }, [
                            _c("img", {
                              attrs: { src: "/images/icon-error-input.svg" }
                            }),
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.errors.lastRomajiName) +
                                "\n                "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.firstRomajiName,
                            expression: "firstRomajiName"
                          }
                        ],
                        class: [
                          "form-control",
                          { "form-control--error": _vm.errors.firstRomajiName }
                        ],
                        attrs: {
                          type: "text",
                          name: "",
                          placeholder: "Mei",
                          maxlength: "191"
                        },
                        domProps: { value: _vm.firstRomajiName },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.firstRomajiName = $event.target.value
                            },
                            _vm.checkHalfSizeFirstRomajiName
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.firstRomajiName
                        ? _c("span", { staticClass: "error" }, [
                            _c("img", {
                              attrs: { src: "/images/icon-error-input.svg" }
                            }),
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.errors.firstRomajiName) +
                                "\n                "
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("new_text.furigana")) +
                        "\n              "
                    ),
                    _c("span", { staticClass: "required" }, [_vm._v("*")])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.lastKana,
                            expression: "lastKana"
                          }
                        ],
                        class: [
                          "form-control",
                          { "form-control--error": _vm.errors.last_name_kana }
                        ],
                        attrs: {
                          type: "text",
                          name: "",
                          placeholder: "せい",
                          maxlength: "191"
                        },
                        domProps: { value: _vm.lastKana },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.lastKana = $event.target.value
                            },
                            _vm.update_kata_last
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.last_name_kana
                        ? _c("span", { staticClass: "error" }, [
                            _c("img", {
                              attrs: { src: "/images/icon-error-input.svg" }
                            }),
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.errors.last_name_kana) +
                                "\n                "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.firstKana,
                            expression: "firstKana"
                          }
                        ],
                        class: [
                          "form-control",
                          { "form-control--error": _vm.errors.first_name_kana }
                        ],
                        attrs: {
                          type: "text",
                          name: "",
                          placeholder: "めい",
                          maxlength: "191"
                        },
                        domProps: { value: _vm.firstKana },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.firstKana = $event.target.value
                            },
                            _vm.update_kata_first
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.first_name_kana
                        ? _c("span", { staticClass: "error" }, [
                            _c("img", {
                              attrs: { src: "/images/icon-error-input.svg" }
                            }),
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.errors.first_name_kana) +
                                "\n                "
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("new_text.sex")) +
                      "\n            "
                  ),
                  _c("span", { staticClass: "required" }, [_vm._v("*")])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "radio-group h-align" },
                  [
                    _vm._l(_vm.select_sex, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.value,
                          staticClass: "check-container ml-0",
                          on: {
                            click: function($event) {
                              return _vm.selectGender(item)
                            }
                          }
                        },
                        [
                          _c("span", {
                            class: [
                              "common-radio-button",
                              item.value === _vm.sex ? "checked" : ""
                            ]
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "label-text" }, [
                            _vm._v(_vm._s(item.label))
                          ])
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkbox-solid" }, [
                      _c(
                        "div",
                        {
                          class: ["check-container", _vm.disablePregnant],
                          on: {
                            click: function($event) {
                              _vm.is_pregnant = !_vm.is_pregnant
                            }
                          }
                        },
                        [
                          _c("span", {
                            class: [
                              "checkbox-button",
                              _vm.is_pregnant ? "checked" : ""
                            ]
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "label-text highlight-black" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("new_text.currently_pregnant"))
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.errors.sex
                  ? _c("span", { staticClass: "error" }, [
                      _vm._v(_vm._s(_vm.errors.sex))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_pregnant
                  ? _c("p", {
                      staticClass: "error error--pregnant",
                      domProps: { innerHTML: _vm._s(_vm.pregnancy_warning) }
                    })
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v(_vm._s(_vm.$t("new_text.birthday")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "interview-information-v2__date" }, [
                  _c(
                    "div",
                    { staticClass: "interview-information-v2__date__group" },
                    [
                      _c("div", { staticClass: "select-option input-select" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.birthday_year,
                                expression: "birthday_year"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { name: "year" },
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.birthday_year = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function($event) {
                                  return _vm.updateAge()
                                }
                              ]
                            }
                          },
                          _vm._l(_vm.years, function(year) {
                            return _c(
                              "option",
                              {
                                key: year,
                                domProps: {
                                  value: year,
                                  selected: _vm.birthday_year === year
                                }
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(year) +
                                    "\n                  "
                                )
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "interview-information-v2__date__text"
                          },
                          [_vm._v("年")]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "interview-information-v2__date__group" },
                    [
                      _c("div", { staticClass: "select-option input-select" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.birthday_month,
                                expression: "birthday_month"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { name: "month" },
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.birthday_month = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function($event) {
                                  return _vm.updateAge()
                                }
                              ]
                            }
                          },
                          _vm._l(12, function(month) {
                            return _c(
                              "option",
                              {
                                key: month,
                                domProps: {
                                  value: month,
                                  selected: _vm.birthday_month === month
                                }
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(month) +
                                    "\n                  "
                                )
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "interview-information-v2__date__text"
                          },
                          [_vm._v("月")]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "interview-information-v2__date__group" },
                    [
                      _c("div", { staticClass: "select-option input-select" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.birthday_day,
                                expression: "birthday_day"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { name: "day" },
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.birthday_day = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function($event) {
                                  return _vm.updateAge()
                                }
                              ]
                            }
                          },
                          _vm._l(_vm.daysOfYear, function(day) {
                            return _c(
                              "option",
                              {
                                key: day,
                                domProps: {
                                  value: day,
                                  selected: _vm.birthday_day === day
                                }
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(day) +
                                    "\n                  "
                                )
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "interview-information-v2__date__text"
                          },
                          [_vm._v("日")]
                        )
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm.errors.birthday
                  ? _c("span", { staticClass: "error" }, [
                      _vm._v(_vm._s(_vm.errors.birthday))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group select-profession" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("new_text.profession")) +
                      "\n            "
                  ),
                  _c("span", { staticClass: "required" }, [_vm._v("*")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "select-option w-60" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.professionId,
                          expression: "professionId"
                        }
                      ],
                      class: [
                        "form-control w-100",
                        { "form-control--error": _vm.errors.professions }
                      ],
                      attrs: { name: "profession" },
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.professionId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function($event) {
                            return _vm.checkProfessions($event)
                          }
                        ]
                      }
                    },
                    [
                      _vm.invalidAge
                        ? _c(
                            "option",
                            {
                              attrs: { selected: "selected", hidden: "" },
                              domProps: { value: _vm.professionNotEnteredId }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("new_text.placeholder.please_select")
                                  ) +
                                  "\n              "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.professions, function(profession) {
                        return _c(
                          "option",
                          {
                            key: profession.id,
                            attrs: {
                              checked: profession.id === _vm.professionId
                            },
                            domProps: { value: profession.id }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(profession.label) +
                                "\n              "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.errors.professions
                    ? _c("span", { staticClass: "error" }, [
                        _c("img", {
                          attrs: { src: "/images/icon-error-input.svg" }
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.errors.professions) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm.professionId === _vm.professionOtherId
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.professionOther,
                            expression: "professionOther"
                          }
                        ],
                        class: [
                          "form-control profession-other",
                          { "form-control--error": _vm.errors.professionOther }
                        ],
                        attrs: {
                          type: "text",
                          placeholder: "その他の場合ご記入ください"
                        },
                        domProps: { value: _vm.professionOther },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.professionOther = $event.target.value
                            },
                            _vm.checkProfessionOther
                          ]
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errors.professionOther
                    ? _c("span", { staticClass: "error" }, [
                        _c("img", {
                          attrs: { src: "/images/icon-error-input.svg" }
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.errors.professionOther) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 page-content" }, [
              _c("h3", { staticClass: "title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("objects.patient.text.address_contact")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("new_text.address")) +
                      "\n            "
                  ),
                  _c("span", { staticClass: "required" }, [_vm._v("*")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row pd-b-10" }, [
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.zipCode,
                          expression: "zipCode"
                        }
                      ],
                      class: [
                        "form-control",
                        { "form-control--error": _vm.errors.zipCode }
                      ],
                      attrs: {
                        type: "text",
                        placeholder: "郵便番号",
                        maxlength: "7"
                      },
                      domProps: { value: _vm.zipCode },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.zipCode = $event.target.value
                          },
                          _vm.checkZipcode
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.zipCode
                      ? _c("span", { staticClass: "error" }, [
                          _c("img", {
                            attrs: { src: "/images/icon-error-input.svg" }
                          }),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.zipCode) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn interview-information-v2__btn-address-fill",
                        attrs: { id: "btn_zip_code" },
                        on: { click: _vm.validateZipCode }
                      },
                      [_vm._v(_vm._s(_vm.$t("new_text.address_search")))]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row pd-b-10" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.address,
                          expression: "address"
                        }
                      ],
                      class: [
                        "form-control",
                        { "form-control--error": _vm.errors.address }
                      ],
                      attrs: { placeholder: "住所" },
                      domProps: { value: _vm.address },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.address = $event.target.value
                          },
                          _vm.checkAddress
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.address
                      ? _c("span", { staticClass: "error" }, [
                          _c("img", {
                            attrs: { src: "/images/icon-error-input.svg" }
                          }),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.address) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v(_vm._s(_vm.$t("new_text.phone_number")))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.phoneNumber,
                      expression: "phoneNumber"
                    }
                  ],
                  class: [
                    "form-control",
                    { "form-control--error": _vm.errors.phone }
                  ],
                  attrs: { type: "text", maxlength: "11", disabled: true },
                  domProps: { value: _vm.phoneNumber },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.phoneNumber = $event.target.value
                      },
                      function($event) {
                        return _vm.validation("phone")
                      }
                    ]
                  }
                }),
                _vm._v(" "),
                _vm.errors.phone
                  ? _c("span", { staticClass: "error" }, [
                      _c("img", {
                        attrs: { src: "/images/icon-error-input.svg" }
                      }),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.errors.phone) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v(_vm._s(_vm.$t("new_text.email")))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email"
                    }
                  ],
                  class: [
                    "form-control",
                    { "form-control--error": _vm.errors.email }
                  ],
                  attrs: {
                    type: "text",
                    maxlength: "80",
                    placeholder: _vm.$t("new_text.email")
                  },
                  domProps: { value: _vm.email },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.email = $event.target.value
                      },
                      function($event) {
                        return _vm.validation("email")
                      }
                    ]
                  }
                }),
                _vm._v(" "),
                _vm.errors.email
                  ? _c("span", { staticClass: "error" }, [
                      _c("img", {
                        attrs: { src: "/images/icon-error-input.svg" }
                      }),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.errors.email) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "form-label" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("objects.patient.text.sport")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group mt-2" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.lessonSport,
                      expression: "lessonSport"
                    }
                  ],
                  staticClass: "form-control font-size-15",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("new_text.placeholder.lesson_or_sport"),
                    maxlength: "100"
                  },
                  domProps: { value: _vm.lessonSport },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.lessonSport = $event.target.value
                    }
                  }
                })
              ])
            ])
          ]),
          _vm._v(" "),
          !_vm.invalidAge
            ? _c("div", { staticClass: "page-content guardian-info" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("objects.patient.text.parent_information")
                      ) +
                      "\n      "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6 page-content" }, [
                    _c("label", { staticClass: "form-label font-bold" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("objects.patient.text.parent_name")) +
                          "\n            "
                      ),
                      _c("span", { staticClass: "required" }, [_vm._v("*")])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.guardian_last_name,
                              expression: "guardian_last_name"
                            }
                          ],
                          class: [
                            "form-control",
                            {
                              "form-control--error":
                                _vm.errors.guardian_last_name
                            }
                          ],
                          attrs: {
                            disabled: _vm.invalidAge,
                            type: "text",
                            name: "",
                            placeholder: _vm.$t("new_text.last_name")
                          },
                          domProps: { value: _vm.guardian_last_name },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.guardian_last_name = $event.target.value
                              },
                              function($event) {
                                return _vm.validation("guardian_last_name")
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.guardian_last_name
                          ? _c("span", { staticClass: "error" }, [
                              _c("img", {
                                attrs: { src: "/images/icon-error-input.svg" }
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.guardian_last_name) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.guardian_first_name,
                              expression: "guardian_first_name"
                            }
                          ],
                          class: [
                            "form-control",
                            {
                              "form-control--error":
                                _vm.errors.guardian_first_name
                            }
                          ],
                          attrs: {
                            disabled: _vm.invalidAge,
                            type: "text",
                            placeholder: _vm.$t("new_text.first_name")
                          },
                          domProps: { value: _vm.guardian_first_name },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.guardian_first_name = $event.target.value
                              },
                              function($event) {
                                return _vm.validation("guardian_first_name")
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.guardian_first_name
                          ? _c("span", { staticClass: "error" }, [
                              _c("img", {
                                attrs: { src: "/images/icon-error-input.svg" }
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.guardian_first_name) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "form-label font-bold" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("new_text.relationship")) +
                            "\n              "
                        ),
                        _c("span", { staticClass: "required" }, [_vm._v("*")])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.guardian_relationship,
                            expression: "guardian_relationship"
                          }
                        ],
                        class: [
                          "form-control",
                          {
                            "form-control--error":
                              _vm.errors.guardian_relationship
                          }
                        ],
                        attrs: {
                          disabled: _vm.invalidAge,
                          type: "text",
                          placeholder: "例：父、母、祖母など"
                        },
                        domProps: { value: _vm.guardian_relationship },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.guardian_relationship = $event.target.value
                            },
                            function($event) {
                              _vm.validation("guardian_relationship")
                              _vm.handleGuardianRelationshipChange
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.guardian_relationship
                        ? _c("span", { staticClass: "error" }, [
                            _c("img", {
                              attrs: { src: "/images/icon-error-input.svg" }
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.guardian_relationship) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "form-label font-bold" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("new_text.profession")) +
                          "\n            "
                      ),
                      _c("span", { staticClass: "required" }, [_vm._v("*")])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "select-option" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.guardianProfessionId,
                                  expression: "guardianProfessionId"
                                }
                              ],
                              staticClass: "form-control w-100",
                              attrs: { name: "guardian_profession" },
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.guardianProfessionId = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function($event) {
                                    _vm.validation("guardianProfessionId")
                                    _vm.checkProfessions($event)
                                  }
                                ]
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { hidden: "", value: "0" } },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "new_text.placeholder.please_select"
                                        )
                                      ) +
                                      "\n                  "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.guardianProfessionsFilter, function(
                                profession
                              ) {
                                return _c(
                                  "option",
                                  {
                                    key: profession.id,
                                    domProps: { value: profession.id }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(profession.label) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-6" }, [
                        _vm.guardianProfessionId === _vm.professionOtherId
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.guardianProfessionOther,
                                  expression: "guardianProfessionOther"
                                }
                              ],
                              class: [
                                "form-control profession-other",
                                {
                                  "form-control--error":
                                    _vm.errors.guardianProfessionId
                                }
                              ],
                              attrs: {
                                type: "text",
                                placeholder: "その他の場合入力"
                              },
                              domProps: { value: _vm.guardianProfessionOther },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.guardianProfessionOther =
                                    $event.target.value
                                }
                              }
                            })
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _vm.errors["guardianProfessionId"]
                          ? _c("span", { staticClass: "error" }, [
                              _vm._v(_vm._s(_vm.errors.guardianProfessionId))
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6 page-content" }, [
                    _c("label", { staticClass: "form-label font-bold" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("new_text.furigana")) +
                          "\n            "
                      ),
                      _c("span", { staticClass: "required" }, [_vm._v("*")])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.guardian_kata_last_name,
                              expression: "guardian_kata_last_name"
                            }
                          ],
                          class: [
                            "form-control profession-other",
                            {
                              "form-control--error":
                                _vm.errors.guardian_kata_last_name
                            }
                          ],
                          attrs: {
                            disabled: _vm.invalidAge,
                            type: "text",
                            placeholder: "せい"
                          },
                          domProps: { value: _vm.guardian_kata_last_name },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.guardian_kata_last_name =
                                  $event.target.value
                              },
                              function($event) {
                                return _vm.validation("guardian_kata_last_name")
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors["guardian_kata_last_name"]
                          ? _c("span", { staticClass: "error" }, [
                              _c("img", {
                                attrs: { src: "/images/icon-error-input.svg" }
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.guardian_kata_last_name) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.guardian_kata_first_name,
                              expression: "guardian_kata_first_name"
                            }
                          ],
                          class: [
                            "form-control",
                            {
                              "form-control--error":
                                _vm.errors.guardian_kata_first_name
                            }
                          ],
                          attrs: {
                            disabled: _vm.invalidAge,
                            type: "text",
                            placeholder: "めい"
                          },
                          domProps: { value: _vm.guardian_kata_first_name },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.guardian_kata_first_name =
                                  $event.target.value
                              },
                              function($event) {
                                return _vm.validation(
                                  "guardian_kata_first_name"
                                )
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.guardian_kata_first_name
                          ? _c("span", { staticClass: "error" }, [
                              _c("img", {
                                attrs: { src: "/images/icon-error-input.svg" }
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.guardian_kata_first_name) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "form-label font-bold" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("new_text.contact")) +
                            "\n              "
                        ),
                        _c("span", { staticClass: "required" }, [_vm._v("*")])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.guardian_phone_number,
                            expression: "guardian_phone_number"
                          }
                        ],
                        class: [
                          "form-control",
                          {
                            "form-control--error":
                              _vm.errors.guardian_phone_number
                          }
                        ],
                        attrs: {
                          disabled: _vm.invalidAge,
                          type: "text",
                          name: "",
                          placeholder: "0800000000",
                          maxlength: "11"
                        },
                        domProps: { value: _vm.guardian_phone_number },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.guardian_phone_number = $event.target.value
                            },
                            function($event) {
                              return _vm.validation("guardian_phone_number")
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.guardian_phone_number
                        ? _c("span", { staticClass: "error" }, [
                            _c("img", {
                              attrs: { src: "/images/icon-error-input.svg" }
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.guardian_phone_number) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "form-label font-bold" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t("objects.patient.text.other_address")
                            ) +
                            "\n              "
                        ),
                        _c("span", { staticClass: "required" }, [_vm._v("*")])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.guardian_address,
                            expression: "guardian_address"
                          }
                        ],
                        class: [
                          "form-control",
                          { "form-control--error": _vm.errors.guardian_address }
                        ],
                        attrs: {
                          disabled: _vm.invalidAge,
                          type: "text",
                          name: "",
                          placeholder: "住所"
                        },
                        domProps: { value: _vm.guardian_address },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.guardian_address = $event.target.value
                            },
                            function($event) {
                              _vm.validation("guardian_address")
                              _vm.handleGuardianAddressChange
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.guardian_address
                        ? _c("span", { staticClass: "error" }, [
                            _c("img", {
                              attrs: { src: "/images/icon-error-input.svg" }
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.guardian_address) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("h3", { staticClass: "title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("objects.patient.text.parent_info")) +
                      "\n      "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6 page-content" }, [
                    _c("label", { staticClass: "form-label font-bold" }, [
                      _vm._v(_vm._s(_vm.$t("objects.patient.text.parent_name")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.guardian2_last_name,
                              expression: "guardian2_last_name"
                            }
                          ],
                          class: [
                            "form-control",
                            {
                              "form-control--error":
                                _vm.errors.guardian2_last_name
                            }
                          ],
                          attrs: {
                            disabled: _vm.invalidAge,
                            type: "text",
                            name: "",
                            placeholder: _vm.$t("new_text.last_name")
                          },
                          domProps: { value: _vm.guardian2_last_name },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.guardian2_last_name = $event.target.value
                              },
                              function($event) {
                                return _vm.validation("guardian2_last_name")
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.guardian2_last_name
                          ? _c("span", { staticClass: "error" }, [
                              _c("img", {
                                attrs: { src: "/images/icon-error-input.svg" }
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.guardian2_last_name) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.guardian2_first_name,
                              expression: "guardian2_first_name"
                            }
                          ],
                          class: [
                            "form-control",
                            {
                              "form-control--error":
                                _vm.errors.guardian2_first_name
                            }
                          ],
                          attrs: {
                            disabled: _vm.invalidAge,
                            type: "text",
                            placeholder: _vm.$t("new_text.first_name")
                          },
                          domProps: { value: _vm.guardian2_first_name },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.guardian2_first_name = $event.target.value
                              },
                              function($event) {
                                return _vm.validation("guardian2_first_name")
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.guardian2_first_name
                          ? _c("span", { staticClass: "error" }, [
                              _c("img", {
                                attrs: { src: "/images/icon-error-input.svg" }
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.guardian2_first_name) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "form-label font-bold" }, [
                        _vm._v(_vm._s(_vm.$t("new_text.relationship")))
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.guardian2_relationship,
                            expression: "guardian2_relationship"
                          }
                        ],
                        class: [
                          "form-control",
                          {
                            "form-control--error":
                              _vm.errors.guardian2_relationship
                          }
                        ],
                        attrs: {
                          disabled: _vm.invalidAge,
                          type: "text",
                          placeholder: "例：父、母、祖母など"
                        },
                        domProps: { value: _vm.guardian2_relationship },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.guardian2_relationship = $event.target.value
                            },
                            function($event) {
                              _vm.validation("guardian2_relationship")
                              _vm.handleGuardian2RelationshipChange
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.guardian2_relationship
                        ? _c("span", { staticClass: "error" }, [
                            _c("img", {
                              attrs: { src: "/images/icon-error-input.svg" }
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.guardian2_relationship) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6 page-content" }, [
                    _c("label", { staticClass: "form-label font-bold" }, [
                      _vm._v(_vm._s(_vm.$t("new_text.furigana")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.guardian2_kata_last_name,
                              expression: "guardian2_kata_last_name"
                            }
                          ],
                          class: [
                            "form-control",
                            {
                              "form-control--error":
                                _vm.errors.guardian2_kata_last_name
                            }
                          ],
                          attrs: {
                            disabled: _vm.invalidAge,
                            type: "text",
                            placeholder: "せい"
                          },
                          domProps: { value: _vm.guardian2_kata_last_name },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.guardian2_kata_last_name =
                                  $event.target.value
                              },
                              function($event) {
                                return _vm.validation(
                                  "guardian2_kata_last_name"
                                )
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors["guardian2_kata_last_name"]
                          ? _c("span", { staticClass: "error" }, [
                              _c("img", {
                                attrs: { src: "/images/icon-error-input.svg" }
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.guardian2_kata_last_name) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.guardian2_kata_first_name,
                              expression: "guardian2_kata_first_name"
                            }
                          ],
                          class: [
                            "form-control",
                            {
                              "form-control--error":
                                _vm.errors.guardian2_kata_first_name
                            }
                          ],
                          attrs: {
                            disabled: _vm.invalidAge,
                            type: "text",
                            placeholder: "めい"
                          },
                          domProps: { value: _vm.guardian2_kata_first_name },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.guardian2_kata_first_name =
                                  $event.target.value
                              },
                              function($event) {
                                return _vm.validation(
                                  "guardian2_kata_first_name"
                                )
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.guardian2_kata_first_name
                          ? _c("span", { staticClass: "error" }, [
                              _c("img", {
                                attrs: { src: "/images/icon-error-input.svg" }
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.guardian2_kata_first_name) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "form-label font-bold" }, [
                        _vm._v(_vm._s(_vm.$t("new_text.contact")))
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.guardian2_phone_number,
                            expression: "guardian2_phone_number"
                          }
                        ],
                        class: [
                          "form-control",
                          {
                            "form-control--error":
                              _vm.errors.guardian2_phone_number
                          }
                        ],
                        attrs: {
                          disabled: _vm.invalidAge,
                          type: "text",
                          name: "",
                          placeholder: "0800000000",
                          maxlength: "11"
                        },
                        domProps: { value: _vm.guardian2_phone_number },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.guardian2_phone_number = $event.target.value
                            },
                            function($event) {
                              return _vm.validation("guardian2_phone_number")
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.guardian2_phone_number
                        ? _c("span", { staticClass: "error" }, [
                            _c("img", {
                              attrs: { src: "/images/icon-error-input.svg" }
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.guardian2_phone_number) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }