<template>
  <div :class="[className]">
    <sub-question
      ref="sub_question"
      :question="question"
      :options="option_yes_no"
      :checked="select_option"
      @handleAnswer="handleAnswer($event)"
    />
    <div :class="handleChildAnswerClass">
      <div class="form-group">
        <label class="color-blue">いつ頃：</label>
        <input
          v-model="time"
          class="form-control"
          type="text"
          name=""
        >
      </div>

      <div class="form-group">
        <checkbox-common
          v-model="selections"
          label="顎の関節痛"
          val="顎の関節痛"
        />
        <checkbox-common
          v-model="selections"
          label="顎の関節音（ガクガク、ゴリゴリなど）"
          val="顎の関節音（ガクガク、ゴリゴリなど）"
        />
        <checkbox-common
          v-model="selections"
          label="口が開きづらい"
          val="口が開きづらい"
        />
        <checkbox-common
          v-model="selections"
          label="口が閉じづらい"
          val="口が閉じづらい"
        />
        <div class="form-inline selection-other-option pt-3">
          <checkbox-common
            v-model="selections"
            label="その他"
            val="その他"
          />
          <div :class="['other-input', diableOtherInput]">
            <input
              v-model="other"
              class="form-control w-100"
              type="text"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SubQuestion from '../template/SubQuestion'
  import CheckboxCommon from '../template/CheckboxCommon'
  import { INTERVIEW_COMMON_OPTIONS } from '../../../../utils/constants'
  import Common from './CommonFunction'

  export default {
    components: {
      SubQuestion,
      CheckboxCommon
    },
    mixins: [Common],
    props: {
      className: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        number: 4,
        select_option: INTERVIEW_COMMON_OPTIONS.NO,
        question: '顎の症状で、今まで経験したものはありますか？',
        option_yes_no: [
          INTERVIEW_COMMON_OPTIONS.YES,
          INTERVIEW_COMMON_OPTIONS.NO
        ],
        time: '',
        selections: [],
        other: '',
        translate: {
          いつ頃: 'time',
          選択結果: 'selections',
          その他: 'other'
        }
      }
    }
  }
</script>
