const messages = {
  account_is_deactivated: 'アカウントは無効化されています',
  account_is_deleted: 'アカウントは停止されています',
  available_date_warning: '表示可能な日程は1年間です',
  before_release_date_warning: 'サービス提供前の日付は選択できません',
  confirmation_delete: '削除しますか？',
  do_you_want_delete_memo: 'メモを削除しますか？',
  forbidden: 'このアカウントではアクセスできません。',
  forgotten_staff_id_or_email: 'スタッフID/メールアドレスが不明の場</br>合、クリニック内管理者にお問い合わせ</br>ください',
  has_been_deleted: '削除しました',
  has_been_updated: '更新しました',
  has_registered: '登録しました',
  invalid_staff_input: '入力された情報に一致するスタッフ情報が存在しません',
  login_failed: 'ログイン情報に誤りがあります',
  login_forbidden: 'このアカウントではログインできません',
  no_result_search: '条件に一致するデータがありません',
  not_found: '見つかりません',
  password_empty: 'パスワードを入力してください',
  password_is_incorrect: 'パスワードが間違っています',
  preset_items_cannot_be_changed: 'プリセットの項目は変更できません',
  saved: '保存しました',
  something_went_wrong: '予期せぬエラーが発生しました。\n' +
    '少し時間を空けてもう一度操作を行ってください。\n' +
    '繰返し発生する場合はサポートセンターにお問い合わせください',
  unauthorized: '許可されていない',
  unprocessable_entity: '処理できないエンティティ'
}

export default messages
