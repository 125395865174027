var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-blue", attrs: { id: "login-form" } }, [
    _c("div", { staticClass: "user_card" }, [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-center" }, [
        _c("div", { staticClass: "btn-ip" }, [
          _vm._v("\n        " + _vm._s(_vm.ip) + "\n      ")
        ]),
        _vm._v(" "),
        _c("br")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ip-bottom" }, [
        _c("div", { staticClass: "ip-bottom-text" }, [
          _vm._v(
            "\n        このIPアドレスからのアクセスは記録されました。\n      "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ip-bottom-text" }, [
          _vm._v(
            "\n        アクセスを許可したい場合は、マネージャー権限の\n      "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ip-bottom-text" }, [
          _vm._v("\n        保有者にこのIPアドレスをお伝えください\n      ")
        ]),
        _vm._v(" "),
        _c(
          "a",
          { staticClass: "ip-bottom-logout-text", on: { click: _vm.logOut } },
          [_c("p", [_vm._v(_vm._s(_vm.$t("common_page.log_out")))])]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c("div", { staticClass: "brand_logo_container" }, [
        _c("img", {
          staticClass: "brand_logo",
          attrs: {
            src: "/images/kirei_lign_logo.png",
            alt: "Logo",
            height: "160"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-center ip-info" }, [
      _c("div", { staticClass: "ip-top-text" }, [
        _vm._v("\n        未登録のIPアドレスからのアクセスです\n      ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }