<template>
  <div :class="['patient-detail patient-history', {'lazy-load lazy-load--big-space': lazy_load}]">
    <div class="patient-detail__content w-100">
      <div class="patient-detail__content__header">
        <label class="patient-detail__content__title">{{ $t('objects.patient.text.patient_information') }}</label>
        <div class="patient-detail__content__action patient-detail--top">
          <div class="patient-group__cont patient-group__cont-second">
            <label class="cont-label fs-13">{{ $t('objects.patient.text.edit_date') }}</label>
            <div class="cont-text fs-14">
              <span>{{ renderCreatedAt }}</span>
            </div>
          </div>
          <button
            type="button"
            class="px-4 button-v2 button-v2--tertiary"
            @click="back()"
          >
            {{ $t('new_text.button.back') }}
          </button>
        </div>
      </div>
      <div class="patient-detail__content__information">
        <div class="patient-group">
          <div class="patient-group__title">
            <h3 class="text">
              {{ $t('objects.patient.text.basic_info') }}
            </h3>
          </div>
          <div class="patient-group__col">
            <div class="patient-group__cont">
              <label class="cont-label">{{ $t('new_text.name') }}</label>
              <div class="cont-text break-word pr-30">
                <span :class="[isChanged('name') ? 'highlight-green-bg' : '']">{{ patient_full_name }}</span>
              </div>
            </div>
            <div class="patient-group__cont">
              <label class="cont-label">{{ $t('new_text.roman') }}</label>
              <div class="cont-text break-word pr-30">
                <span :class="[isChanged('roma_first_name') || isChanged('roma_last_name') ? 'highlight-green-bg' : '']">
                  {{ patient_roma_name }}
                </span>
              </div>
            </div>
            <div class="patient-group__cont">
              <label class="cont-label">{{ $t('new_text.birthday') }}</label>
              <div class="cont-text">
                <span :class="[isChanged('birth_of_date') ? 'highlight-green-bg' : '']">{{ renderBirthDate }} {{ renderAge }}</span>
              </div>
            </div>
          </div>
          <div class="patient-group__col">
            <div class="patient-group__cont">
              <label class="cont-label">{{ $t('new_text.hiragana') }}</label>
              <div class="cont-text break-word">
                <span
                  :class="
                    [
                      isChanged('kana_first_name') || isChanged('kana_last_name') ?
                        'highlight-green-bg' :
                        ''
                    ]
                  "
                >{{ patient_kata_name }}</span>
              </div>
            </div>
            <div class="patient-group__cont">
              <label class="cont-label">{{ $t('new_text.sex') }}</label>
              <div class="cont-text">
                <span :class="[isChanged('gender') ? 'highlight-green-bg' : '']">{{ renderSex }}</span>
              </div>
            </div>
            <div
              v-if="brand_master"
              class="patient-group__cont"
            >
              <label class="cont-label">{{ $t('new_text.brand_type') }}</label>
              <div class="cont-text">
                <span :class="[isChanged('brand_type') ? 'highlight-green-bg' : '']">{{ brand_master.name }}</span>
              </div>
            </div>
          </div>
          <div class="c-both" />
          <div
            v-if="patient_cur_data.reason_for_non_contract"
            class="patient-group__cont"
          >
            <label class="cont-label">{{ $t('objects.patient.text.reason_for_non_contracting') }}</label>
            <div class="cont-text">
              <span
                :class="['ws-pre-wrap', { 'highlight-green-bg': isChanged('reason_for_non_contract') }]"
              >{{ patient_cur_data.reason_for_non_contract }}</span>
            </div>
          </div>
        </div>
        <div class="patient-group">
          <div class="patient-group__title">
            <h3 class="text">
              {{ $t('objects.patient.text.contact_info') }}
            </h3>
          </div>
          <div class="patient-group__cont">
            <label class="cont-label">{{ $t('new_text.address') }}</label>
            <div class="cont-text">
              <span :class="[isChanged('address') ? 'highlight-green-bg' : '']">{{ address }}</span>
            </div>
          </div>
          <div class="patient-group__cont">
            <label class="cont-label">{{ $t('new_text.phone_number') }}</label>
            <div class="cont-text">
              <span :class="[isChanged('tel') ? 'highlight-green-bg' : '']">{{ phone_number }}</span>
            </div>
          </div>
          <div class="patient-group__cont">
            <label class="cont-label">{{ $t('new_text.email') }}</label>
            <div class="cont-text">
              <span :class="[isChanged('email') ? 'highlight-green-bg' : '']">{{ email_address }}</span>
            </div>
          </div>
        </div>
        <div class="patient-group mb-0">
          <div class="patient-group__title">
            <h3 class="text">
              {{ $t('objects.patient.text.delivery_setting') }}
            </h3>
          </div>
          <div class="patient-group__cont patient-group__cont-first">
            <label class="cont-label">{{ $t('objects.patient.text.sms_delivery_setting') }}</label>
            <div class="cont-text">
              <span :class="[isChanged('is_receive_sms') ? 'highlight-green-bg' : '']">
                {{ checkDeliver(is_receive_sms) }}
              </span>
            </div>
          </div>
          <div class="patient-group__cont patient-group__cont-first">
            <label class="cont-label">{{ $t('objects.patient.text.mail_delivery_setting') }}</label>
            <div class="cont-text">
              <span :class="[isChanged('is_receive_sms') ? 'highlight-green-bg' : '']">
                {{ checkDeliver(is_receive_email) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="patient-detail__content__header">
        <label class="patient-detail__content__title">{{ $t('objects.patient.text.system_information') }}</label>
      </div>
      <div class="patient-detail__content__information">
        <div class="patient-group">
          <div class="patient-group__cont patient-group__cont-second mt-0">
            <label class="cont-label">{{ $t('objects.patient.text.patient_status') }}</label>
            <div class="cont-text">
              <span>{{ patientStatus }}</span>
            </div>
          </div>
          <div class="patient-group__cont patient-group__cont-second">
            <label class="cont-label">{{ $t('new_text.registration_date') }}</label>
            <div class="cont-text">
              <span>{{ createdAt }}</span>
            </div>
          </div>
          <div class="patient-group__cont patient-group__cont-second">
            <label class="cont-label">{{ $t('new_text.last_updated') }}</label>
            <div class="cont-text">
              <span>{{ renderCreatedAt }}</span>
            </div>
          </div>
        </div>
        <div class="patient-group">
          <div class="patient-group__title">
            <h3 class="text">
              {{ $t('objects.patient.text.assignee_info') }}
            </h3>
          </div>
          <div
            v-for="(item, index) in history_person_in_charges"
            :key="index"
            class="patient-group__cont patient-group__cont-second"
          >
            <label class="cont-label">{{ getPositionName(item) }}</label>
            <div class="cont-text">
              <span
                :class="[isPersonChanged(item.staff_id) ? 'highlight-green-bg' : 'highlight-red-bg']"
              >{{ item.staff.name }}</span>
            </div>
          </div>
        </div>
        <div class="patient-group">
          <div class="patient-group__title">
            <h3 class="text">
              {{ $t('objects.patient.text.other_notes') }}
            </h3>
          </div>
          <div class="patient-group__cont">
            <div class="cont-text">
              <span :class="[isChanged('note') ? 'highlight-green-bg' : '']">{{ other_note }}</span>
            </div>
          </div>
        </div>
        <div class="patient-group">
          <div class="patient-group__cont mt-0">
            <label class="cont-label">{{ $t('objects.patient.text.service_management') }}</label>
            <patient-service-summary
              v-if="serviceSummary.length"
              :service-summary="serviceSummary"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatDateTime, safeGet, getAgeString } from '../../../utils/helpers.js'
  import PatientServiceSummary from '../../../components/patient/v2/PatientServiceSummary'
  import { get } from 'lodash'

  export default {
    components: {
      PatientServiceSummary
    },
    data () {
      return {
        patient_id: '',
        patient_info_id: '',
        patient_cur_data: {},
        patient_prev_data: {},
        patient_full_name: '',
        patient_kata_name: '',
        patient_roma_name: '',
        sex: '',
        address: '',
        phone_number: '',
        email_address: '',
        is_receive_sms: 0,
        is_receive_email: 0,
        birth_date: '',
        person_in_charge_name: '',
        person_in_charge_pos: '',
        other_note: '',
        created_at: '',
        brand_master: {},
        lazy_load: true,
        history_person_in_charges: [],
        new_person_in_charge_ids: [],
        reservation_id: ''
      }
    },
    computed: {
      renderSex () {
        return this.sex === 1 ? this.$t('new_text.female') : (this.sex === 2 ? this.$t('new_text.male') : '')
      },
      renderCreatedAt () {
        return formatDateTime(this.created_at, this.$t('new_text.datetime.year_month_day_hour_minute'))
      },
      renderBirthDate () {
        return formatDateTime(this.birth_date, this.$t('new_text.datetime.year_month_day'))
      },
      renderAge () {
        return getAgeString(this.birth_date, this.$t('new_text.age'))
      },
      patient: function () {
        return this.$store.getters.get_patient
      },
      patientStatus () {
        return this.$t('objects.patient.sf_status_c.' + (this.patient.sf_status_c || 0))
      },
      createdAt () {
        return formatDateTime(this.patient.created_at, this.$t('new_text.datetime.year_month_day_hour_minute'))
      },
      serviceSummary: function () {
        return this.patient.service_summary
      }
    },
    created () {
      document.title = this.$t('objects.page_title.patient').toString()
      this.patient_id = this.$route.params.id
      this.patient_info_id = this.$route.params.hid
      this.reservation_id = this.$route.query.reservation_id
      this.getPatientHistoryDetail()
    },
    methods: {
      isChanged: function (key) {
        return this.patient_prev_data && safeGet(this.patient_cur_data, key) !== safeGet(this.patient_prev_data, key)
      },
      isPersonChanged: function (key) {
        return this.new_person_in_charge_ids.includes(key)
      },
      getPositionName (data) {
        return data?.staff?.position?.name || ''
      },
      getPatientHistoryDetail: function () {
        this.lazy_load = true
        this.$store.dispatch('getPatientHistoryDetail', {
          patient_id: this.patient_id,
          patient_info_id: this.patient_info_id
        }).then((result) => {
          const data = safeGet(result.data, 'results')

          this.created_at = safeGet(data, 'info_history.created_at')
          this.patient_cur_data = get(data, 'info_history', {}) || {}
          this.patient_prev_data = get(data, 'previous_version', {}) || {}
          this.patient_kata_name = `${safeGet(data, 'info_history.kana_last_name')} ${safeGet(data, 'info_history.kana_first_name')}`
          this.patient_roma_name = `${safeGet(data, 'info_history.roma_last_name')} ${safeGet(data, 'info_history.roma_first_name')}`
          this.patient_full_name = safeGet(data, 'info_history.last_name').length === 0 || safeGet(data, 'info_history.first_name').length === 0
            ? safeGet(data, 'info_history.name')
            : `${safeGet(data, 'info_history.last_name')} ${safeGet(data, 'info_history.first_name')}`
          this.sex = safeGet(data, 'info_history.gender')
          this.address = safeGet(data, 'info_history.address')
          this.phone_number = safeGet(data, 'info_history.tel')
          this.email_address = safeGet(data, 'info_history.email')
          this.other_note = safeGet(data, 'info_history.note')
          this.is_receive_sms = safeGet(data, 'info_history.is_receive_sms')
          this.is_receive_email = safeGet(data, 'info_history.is_receive_email')

          this.history_person_in_charges = safeGet(data, 'historyPersonInCharges')
          this.new_person_in_charge_ids = safeGet(data, 'newPersonInChargeIds')
          const newPersonInChargeIdsObj = this.new_person_in_charge_ids || {}
          const newPersonInChargeIds = Object.keys(newPersonInChargeIdsObj).map((key) => newPersonInChargeIdsObj[key])
          this.new_person_in_charge_ids = [...newPersonInChargeIds]
          this.person_in_charge_name = safeGet(data, 'info_history.person_in_charge.staff.name')
          this.person_in_charge_pos = safeGet(data, 'info_history.person_in_charge.staff.position.name')
          this.birth_date = safeGet(data, 'info_history.birth_of_date')
          this.brand_master = safeGet(data, 'info_history.brand_master')
          this.lazy_load = false
        }).catch(() => {
          this.lazy_load = false
        })
      },
      checkDeliver: function (deliver = 0) {
        return (typeof deliver !== 'undefined' && deliver === 1) ? this.$t('new_text.receive_sms') : this.$t('new_text.do_not_deliver')
      },
      back () {
        this.$router.push({
          name: 'patient.detail',
          params: { id: this.patient_id },
          query: { reservation_id: this.reservation_id }
        })
      }
    }
  }
</script>
