var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-action", {
    staticClass: "modify-duty",
    attrs: {
      title: _vm.$t("objects.setting.text.edit_tab"),
      "is-show-delete-button": true
    },
    on: {
      close: function($event) {
        return _vm.$emit("close")
      },
      confirm: function($event) {
        return _vm.$emit("confirm", { type_name: _vm.type_name })
      },
      delete: function($event) {
        return _vm.$emit("delete")
      }
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "modify-duty__form" }, [
              _c("div", { staticClass: "modify-duty__group" }, [
                _c(
                  "label",
                  {
                    staticClass: "modify-duty__label",
                    attrs: { for: "show-name" }
                  },
                  [_vm._v(_vm._s(_vm.$t("new_text.showing_name")))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modify-duty__content" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.type_name,
                        expression: "type_name"
                      }
                    ],
                    class: [
                      "modify-duty__input",
                      { "common-input-error": !_vm.type_name }
                    ],
                    attrs: {
                      id: "show-name",
                      type: "text",
                      placeholder: _vm.$t("objects.setting.text.tab_name")
                    },
                    domProps: { value: _vm.type_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.type_name = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.type_name
                    ? _c("span", { staticClass: "common-msg-error" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("new_validation.common.required", {
                                attribute: _vm.$t("new_text.showing_name")
                              })
                            ) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }