export default {
  brand_master_filter: [
    {
      type: 0,
      name: 'キレイライン'
    },
    {
      type: 1,
      name: 'キレイラインKIDS'
    },
    {
      type: 2,
      name: 'インビザライン'
    },
    {
      type: 3,
      name: 'セラミック'
    },
    {
      type: 4,
      name: 'キヨクリア'
    },
    {
      type: 5,
      name: '口ゴボ'
    },
    {
      type: 6,
      name: '矯正歯科ガイド'
    },
    {
      type: 7,
      name: 'ブラーバ'
    }
  ],
  button: {
    create_technician_instructions: '技工発注を行う',
    history: '履歴',
    patient_registration: '患者登録',
    print_examination: '技工指示書印刷',
    template_first_visit: '{patient_id}初診時テンプレート.pdf',
    template_re_visit: '{patient_id}再診時テンプレート.pdf'
  },
  gender_filter: [
    {
      id: 0,
      name: '選択なし'
    },
    {
      id: 1,
      name: '女性'
    },
    {
      id: 2,
      name: '男性'
    }
  ],
  message: {
    complaint_comment: 'できるだけご自身の言葉で具体的に記載をお願いします。治療の希望もご記載ください。',
    has_error_input: '入力に誤りがあります',
    no_history_of_sending_sms: 'SMSの送信履歴はありません',
    phone_number_empty: '電話番号が登録されていません。\n問診票は患者様の手書き記入となります。',
    phone_incorrect: '電話番号の形式が正しくありません。\n正しい電話番号を登録してからSMSを送信してください。',
    phone_number_not_available: 'SMSが送信できない電話番号が登録されています。\n問診票は患者様の手書き記入となります。',
    pregnancy_warning: '妊娠中の方、妊娠の可能性がある方はレントゲン撮影が<br/>行えないため、治療を受けていただくことができません。',
    save_draft_message: '下書き保存では問診票は"入力済み"になりません。問診票\nを"入力済み"にしたい場合は、"保存"を行なってください。',
    sms_can_be_sent_if_reservation_exist: 'SMSは予約情報を登録した後に送信できます。',
    sms_sending_confirmation: ':tel に\nSMSが送信されますがよろしいですか？',
    sms_sent: 'SMSの送信が完了しました',
    symptoms_type: {
      mouth: '※症状が強い場合は適応にならない場合があります。',
      gummy: '※本来であればスマイル時に、上顎の歯肉はギリギリ見えないのが理想的。',
      logo: '※矯正歯科領域では、一般的に上下顎前突、上顎前突など、口元が突出している状態のことが言われるケースが多い。<br/>' +
        '※歯科矯正学ではこのような表現は存在しません。<br/>' +
        '※症状が強い場合は適応にならない場合があります。'
    }
  },
  patient_condition: [
    {
      id: 'none',
      name: 'なし'
    },
    {
      id: 'first_visit',
      name: '初診'
    },
    {
      id: 're_examination',
      name: '再診'
    }
  ],
  placeholder: {
    consultation_symptom: '例：上の前歯のすき間が空いているのが気になります。すき間が埋まれば、多少がたつきが残ってもいいので、安く済ませたいです。\n\n 全体的にがたがたしているので、綺麗に揃えたいお金と期間がかかってもいいので、満足行くまでやりたい。「少し/できるだけ」前歯を引っ込めたい',
    other_reason_placeholder: 'その他の場合ご記入ください',
    post_comment: 'コメントを投稿する'
  },
  sf_pre_contract_simulation: {
    0: '- 未実施',
    1: '◯ 実施'
  },
  sf_status_c: {
    0: 'キャンセル',
    1: '来店待ち',
    2: '新規申し込み',
    3: '日程再調整中',
    4: '来店済み',
    5: 'クリニック既存患者',
    6: '他矯正への切り替え',
    9: '削除済'
  },
  sf_status_c_new: [
    {
      id: 0,
      name: 'キャンセル'
    },
    {
      id: 1,
      name: '来店待ち'
    },
    {
      id: 2,
      name: '新規申し込み'
    },
    {
      id: 4,
      name: '来店済み'
    },
    {
      id: 5,
      name: 'クリニック既存患者'
    },
    {
      id: 9,
      name: '削除済'
    }
  ],
  sf_treatmentend_status_c: {
    1: '途中解約（返金）',
    2: '途中解約（他治療で継続）',
    3: '途中解約（患者満足）',
    4: '治療終了',
    5: '治療終了（他治療で継続）'
  },
  text: {
    accounting_creation: '会計作成',
    activity_log: 'アクティビティログ',
    add_charge: '担当を追加',
    address_contact: '住所・連絡先',
    aligner: 'アライナー',
    areas_concern: '気になる箇所・症状',
    assignee_info: '担当情報',
    basic_info: '基本情報',
    basic_information: '基本情報',
    centered_off: '中心がずれている（正中不一致）',
    cleaning: 'クリーニング',
    clear_teeth: 'すきっ歯（空隙歯列）',
    clinic_name: '医院名',
    comment: 'コメント',
    consultation_content: '相談内容',
    consultation_symptom: '主訴 (どこが気になっていますか)',
    contact_info: '連絡先・住所情報',
    content_of_consultation: 'ご相談内容',
    csl_reservation_information: 'カウンセリング再診情報',
    deep_occlusion: '噛み合わせが深い（過蓋咬合）',
    delivery_setting: '配信設定',
    dental_hygienist: '歯科衛生士',
    dental_teacher: '歯科医師先生',
    dentist: '歯科医師',
    dentition_number: '歯列番号',
    description_delete_modal: 'これまでの予約レコードは全て削除され、元には戻せません。\n削除したい場合は、患者情報削除より前に操作を完了させてください。',
    edit_date: '編集日時',
    edit_history: '編集履歴',
    editor: '編集者',
    enter_questionnaire: '問診票を直接入力する',
    expanded_floor: '拡大床',
    front_teeth: '前歯が閉じない（開咬）',
    gummy_smile: 'スマイル時に上顎の歯肉が露出している（ガミースマイル）',
    ip_address_of_first_appointment: '初診予約時のIPアドレス',
    left_side: '左側',
    logobo: '口ゴボ',
    lower_jaw: '下顎',
    mail_delivery_setting: 'アンケートメール配信設定',
    main_symptom: '主訴',
    medical_record_creation: 'カルテ作成',
    modal_confirm_save: {
      title: '問診票を保存します',
      text: '保存を行うと再編集ができなくなります。\nよろしいですか。'
    },
    modal_reason_for_non_contract_title: '契約に至らなかった理由を選択してください',
    move_destination: '転居先',
    new_reservation: '新規予約',
    none: 'なし',
    not_obtained: '未取得',
    note_delete_modal: '削除を実行するには、ログインパスワードを入力ください',
    number_services: '残役務数',
    orthodontic: '矯正装置',
    orthodontic_treatment: '矯正歯科治療',
    other_address: '住所※お住まいが別の場合記入',
    other_notes: 'その他備考',
    other_question: {
      1: '1. 転居の予定はありますか？',
      2: '2. その他、診察に際してご要望などございましたら、ご記入ください。'
    },
    overall: '全体',
    overall_service: '役務全体',
    parent_info: '保護者情報',
    parent_information: '保護者情報（未成年の方）',
    parent_name: '保護者名',
    part: '部位',
    patient_id: '患者ID',
    patient_information: '患者情報',
    patient_status: '患者ステータス',
    patient_status_0: '新規申し込み',
    patient_status_1: '受付前',
    patient_status_2: '受付済',
    patient_status_3: '会計前',
    patient_status_4: '会計済',
    patient_technician_order: '患者の技工発注',
    period_end_date: '期間終了日',
    period_start_date: '期間開始日',
    person_in_charge_information: '担当情報',
    please_reload_while_getting_id: 'Salesforceに問い合わせ中。リロードしてください',
    print_interview: '印刷する',
    prior_interview: '事前問診',
    priority: '優先順位',
    protruding: '出っ歯（上顎前突）',
    questionnaire: {
      answer_date: '回答日時',
      comment: '良かったコメント',
      description_rating: '説明評価',
      first: '初診',
      improvement_comment: '改善コメント',
      manager: '担当者',
      re_exam: '再診',
      title: '来院アンケート',
      treatment_evaluation: '治療評価',
      visit_date: '来院日時',
      q2: {
        1: '十分かつ丁寧であった',
        2: '十分だった',
        3: '不十分だった',
        4: '説明はなかった'
      },
      v2q1: {
        1: '非常に満足',
        2: 'やや満足',
        3: 'どちらともいえない',
        4: 'やや不満',
        5: '非常に不満'
      },
      no_questionnaires: '回答済みのアンケートがありません'
    },
    questions: {
      1: '他の医院で歯並びの相談をされた事はありますか？',
      2: '過去に矯正歯科治療された事はありますか？'
    },
    rattling_double: 'ガタガタした歯並びや八重歯（叢生）',
    reason_for_non_contract_update_description: '※理由を選択後、「保存する」ボタンを押すことで更新が完了します',
    reason_for_non_contracting: '未契約理由',
    receive_value: ['配信しない', '配信する'],
    receptacle: '受け口（反対咬合）',
    remaining_service: '残役務金額',
    reservation_date: '予約日時',
    reservation_schedule: '予約日程',
    right_side: '右側',
    search_by_brand_type: '選択してください',
    search_by_email: 'メールアドレスで検索',
    search_by_id: '患者IDで検索',
    search_by_name: '患者名で検索',
    search_by_sf_status_c: '選択してください',
    search_by_tel: '電話番号で検索',
    send_sms: 'SMSを送信する',
    send_sms_title: '事前問診票案内SMS送信',
    service_category: '役務カテゴリ',
    service_management: '役務管理',
    simulation_flag: '契約前シミュレーション',
    sms_delivery_setting: 'SMS配信設定',
    sms_history: 'SMS送信履歴',
    sms_history_status: {
      0: '送信失敗',
      1: '送信成功'
    },
    sms_sending_confirmation_title: 'SMSを送信します',
    specifically: '具体的に',
    specify_the_period: '期間指定',
    sport: '習い事・スポーツ',
    symptom: '症状',
    symptomatology: '症状',
    syncing_to_salesforce: '(Salesforceに問い合わせ中。\nリロードしてください)',
    system_information: 'システム情報',
    technician_instructions_template: '技工指示書テンプレート',
    technician_order: '技工発注',
    title_delete_modal: '患者情報を削除します',
    title_editing: '患者情報編集',
    title_registration: '患者登録',
    transmission_history: '送信履歴（携帯電話会社へのSMS受渡しが成功/失敗したかを示します）',
    treatment_details: '処置内容',
    treatmentend_status: '治療終了理由',
    upper_jaw: '上顎',
    when: 'いつ頃',
    whitening: 'ホワイトニング'
  }
}
