<template>
  <div class="modal-operation-reason modal-operation-reason--allocation">
    <modal-action
      :title="$t('objects.operation_reason.send_the_reason_to_the_supervisor')"
      @close="$emit('back')"
    >
      <template #body>
        <div class="modal-operation-reason__body">
          <div class="modal-operation-reason__info">
            <div class="modal-operation-reason__row">
              <label class="modal-operation-reason__label">{{ $t('new_text.reservations_frame') }}</label>
              <div
                v-if="isUpdate"
                class="modal-operation-reason__data d-flex align-items-center"
              >
                <span
                  class="modal-operation-reason__data__fixed-width"
                >{{ truncateString(resources.before_name || '', 13) }}</span>
                <img
                  class="modal-operation-reason__data__divide"
                  src="/images/arrow_right_alt.svg"
                >
                <span>{{ truncateString(resources.after_name || '', 13) }}</span>
              </div>
              <span
                v-else
                class="modal-operation-reason__data"
              >{{ resources.before_name }}</span>
            </div>
            <div class="modal-operation-reason__row">
              <label class="modal-operation-reason__label">{{ $t('new_text.reservations_frame_auto') }}</label>
              <div
                v-if="isUpdate"
                class="modal-operation-reason__data d-flex align-items-center"
              >
                <span
                  class="modal-operation-reason__data__fixed-width"
                >{{ beforeTypeName }}</span>
                <img
                  class="modal-operation-reason__data__divide"
                  src="/images/arrow_right_alt.svg"
                >
                <span>{{ afterTypeName }}</span>
              </div>
              <span
                v-else
                class="modal-operation-reason__data"
              >{{ beforeTypeName }}</span>
            </div>
          </div>
          <div class="modal-operation-reason__content">
            <div class="modal-operation-reason__row">
              <label class="modal-operation-reason__label modal-operation-reason__label--required">
                {{ $t('new_text.name') }}
                <span class="modal-operation-reason__required">{{ $t('new_text.required') }}</span>
              </label>
              <div class="modal-operation-reason__input">
                <input
                  ref="operator_name"
                  v-model="operator_name"
                  :placeholder="$t('validation.required', { attribute: $t('new_text.name') })"
                  :class="['input-default', { 'common-input-error': errors.operator_name }]"
                  @input="validate('operator_name')"
                >
                <span
                  v-if="errors.operator_name"
                  class="common-msg-error error-message"
                >{{ errors.operator_name }}</span>
              </div>
            </div>
            <div class="modal-operation-reason__row modal-operation-reason__row--operator-reason">
              <label class="modal-operation-reason__label modal-operation-reason__label--operator-reason modal-operation-reason__label--required">
                {{ $t('new_text.reason') }}
                <span class="modal-operation-reason__required">{{ $t('new_text.required') }}</span>
              </label>
              <div class="modal-operation-reason__operator-reason">
                <textarea
                  v-model="operation_reason"
                  class="textarea-default textarea-default--resize-none textarea-default--h-70"
                  :placeholder="$t('new_text.placeholder.please_enter')"
                  :class="['modal-operation-reason__textarea', { 'common-input-error': errors.operation_reason }]"
                  @input="validate('operation_reason')"
                />
                <span
                  v-if="errors.operation_reason"
                  class="common-msg-error error-message"
                >{{ errors.operation_reason }}</span>
                <p class="modal-operation-reason__note">{{ note }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="modal-operation-reason__footer-default">
          <div class="modal-operation-reason__footer-group">
            <button
              class="button-v2 button-v2--secondary button-v2--120"
              @click="$emit('back')"
            >
              {{ $t('new_text.button.back') }}
            </button>
            <button
              class="button-v2 button-v2--primary button-v2--120"
              :disabled="!isNotError"
              @click="handleSubmit"
            >
              {{ $t('new_text.button.send') }}
            </button>
          </div>
        </div>
      </template>
    </modal-action>
  </div>
</template>

<script>
  import ModalAction from '../common/ModalAction'
  import { mbtrim, truncateString } from '../../utils/helpers'
  import { every, isEmpty } from 'lodash'

  export default {
    components: {
      ModalAction
    },
    props: {
      isUpdate: { type: Boolean, default: false },
      resources: { type: Object, default: () => ({}) }
    },
    emits: ['back', 'confirm'],
    data () {
      return {
        errors: {
          operator_name: '',
          operation_reason: ''
        },
        operator_name: '',
        operation_reason: ''
      }
    },
    computed: {
      note () {
        if (this.isUpdate) {
          return this.$t('objects.operation_reason.note_send_reason_when_update_allocation')
        }

        return this.$t('objects.operation_reason.note_send_reason_when_delete_allocation')
      },
      beforeTypeName () {
        if (typeof this.resources.before_type === 'undefined') {
          return ''
        }

        return this.$t(`objects.allocation.allocation_types.${this.resources.before_type}`)
      },
      afterTypeName () {
        if (typeof this.resources.after_type === 'undefined') {
          return ''
        }

        return this.$t(`objects.allocation.allocation_types.${this.resources.after_type}`)
      },
      isNotError () {
        return Boolean(every(this.errors, isEmpty) && this.operator_name && this.operation_reason)
      }
    },
    mounted () {
      this.$nextTick(() => this.$refs.operator_name.focus())
    },
    methods: {
      truncateString,
      handleSubmit () {
        this.validate()
        if (!this.isNotError) {
          return
        }

        this.$emit('confirm', { operator_name: this.operator_name, operation_reason: this.operation_reason })
      },
      validate (field = null) {
        if (!field || field === 'operator_name') {
          if (!mbtrim(this.operator_name)) {
            this.errors.operator_name = this.$t('new_validation.common.required', { attribute: this.$t('new_text.name') })
          } else if (this.operator_name.length > 191) {
            this.errors.operator_name = this.$t('new_validation.common.maximum', { max: 191, attribute: this.$t('new_text.name') })
          } else {
            this.errors.operator_name = ''
          }
        }

        if (!field || field === 'operation_reason') {
          if (!mbtrim(this.operation_reason)) {
            this.errors.operation_reason = this.$t('new_validation.common.required', { attribute: this.$t('new_text.reason') })
          } else {
            this.errors.operation_reason = ''
          }
        }
      }
    }
  }
</script>
