<template>
    <transition name="modal">
        <div :class="['mc-modal', custom_class]">
            <div class="mc-modal__wrapper">
                <div class="mc-modal__container">

                    <div class="mc-modal__header">
                        <slot name="header"></slot>
                        <button v-if="is_show_close_button" type="button" aria-label="Close this dialog" class="mc-modal__header__close" @click="$emit('close')">×
                        </button>
                    </div>

                    <div class="mc-modal__body">
                        <slot name="body"></slot>
                    </div>

          <div
            :class="[
              'mc-modal__footer',
              { 'mc-modal__footer--reverse': reverseButton }
            ]"
          >
                        <button v-if="is_show_cancellation_button" class="mc-modal__footer__button mc-modal__footer__button--first" @click="$emit('close')">
                            <slot name="cancel_button_content"></slot>
                        </button>
                        <button v-if="is_show_approval_button" class="mc-modal__footer__button mc-modal__footer__button--second" @click="$emit('confirmButtonClick')">
                            <slot name="confirm_button_content"></slot>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    props: {
        custom_class: String,
        is_show_close_button: {
            type: Boolean,
            default: true
        },
        is_show_cancellation_button: {
            type: Boolean,
            default: true
        },
        is_show_approval_button: {
            type: Boolean,
            default: true
        },
      reverseButton: {
        type: Boolean,
        default: false
      }
    }
}
</script>
