const common = {
  attributes: {
    digit_number: '桁の数値',
    email: 'メールアドレス',
    last_name: 'フリガナ姓',
    staff_name: 'スタッフ名',
    staff_id: 'スタッフID',
    password: '初期パスワード',
    phone_number: '電話番号',
    position: '役職',
    reservable_limit: '最大予約日数',
    roman_name: 'ローマ字氏名',
    roman_last_name: 'フリガナ姓'
  },
  between: '{attribute}は{min}~{max}{type}を入力してください',
  first_name_required: '名を入力してください',
  invalid: '{attribute}は有効な{attribute}ではありません',
  invalid_email: 'メールアドレスは有効なメールアドレスではありません',
  invalid_file: '{name}ファイルが無効です',
  last_name_required: '姓を入力してください',
  max_file_upload: '一度にアップロードできるファイルは{maximum}ファイルです',
  maximum: '最大 %{attribute}は %{max}文字です',
  note_maxlength: '文字列が長すぎます。\n半角で{max}文字まで入力できます',
  numeric: '{attribute}は数値で入力してください',
  post_max_size: '一度にアップロードできるファイルサイズの合計は{maximum}MBです',
  required: '{attribute}を入力してください',
  invalid_date: '終了日は開始日より後の日付を設定してください',
  invalid_filename: 'ファイル名に使用できない記号が含まれています。<br> {symbols}',
  romaji_first_name_required: 'ローマ字Meiを入力してください',
  romaji_haffsize: '半角英字を入力してください',
  romaji_last_name_required: 'ローマ字Seiを入力してください'

}

export default common
