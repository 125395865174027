var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-action", {
    staticClass: "delete-allocation",
    attrs: {
      title: _vm.$t("new_message.confirmation_delete"),
      "is-show-close-button": false
    },
    scopedSlots: _vm._u([
      {
        key: "footer",
        fn: function() {
          return [
            _c("button-common", {
              staticClass: "cancel-reservation__submit",
              on: { click: _vm.deleteColumn },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("new_text.button.delete")) +
                          "\n      "
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            }),
            _vm._v(" "),
            _c("button-common", {
              staticClass: "cancel-reservation__close",
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("new_text.button.no")) +
                          "\n      "
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }