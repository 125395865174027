var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-list-charge" },
    [
      _c("modal-action", {
        attrs: { title: _vm.$t("reservation.select_person_charge") },
        on: {
          close: function($event) {
            return _vm.$emit("close")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("div", { staticClass: "modal-list-charge__content" }, [
                  _c("div", { staticClass: "modal-list-charge__menu" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.position_data, function(obj, id) {
                        return _c(
                          "li",
                          {
                            key: id,
                            class: { active: _vm.checkMenuActive(id) },
                            on: {
                              click: function($event) {
                                return _vm.selectPerson(id)
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "modal-list-charge__name" },
                              [_vm._v(_vm._s(obj.name))]
                            ),
                            _vm._v(" "),
                            obj.staff_count !== 0
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.checkMenuActive(id)
                                      ? "/images/arrow-right-blue.svg"
                                      : "/images/arrow-right-grey-thin.svg"
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-list-charge__filter" }, [
                    _c(
                      "ul",
                      { staticClass: "nav modal-list-charge__tabs" },
                      _vm._l(Object.keys(_vm.staffs), function(key) {
                        return _c("li", { key: key, staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              class: {
                                "nav-link": true,
                                active: key === _vm.filter_active,
                                disabled: _vm.getNumberStaffs(key) === 0
                              },
                              on: {
                                click: function($event) {
                                  return _vm.selectFilter(key)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.filter_chars[key]))]
                          )
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-list-charge__tab" },
                      _vm._l(_vm.staffs[_vm.filter_active], function(item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "modal-list-charge__item",
                            on: {
                              click: function($event) {
                                return _vm.updateStaffData(item)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.name) +
                                "\n                "
                            ),
                            _c(
                              "span",
                              { staticClass: "modal-list-charge__romaji" },
                              [_vm._v("(" + _vm._s(item.name_romaji) + ")")]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }