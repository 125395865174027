var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-inner bg-white interview-block" }, [
    _c("div", { staticClass: "page-content" }, [
      _c("div", { staticClass: "header-title" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("interview.basic_info")))]),
        _vm._v(" "),
        _c("p", {
          staticClass: "gray-arrow",
          class: { "toggle-icon": _vm.show },
          on: { click: _vm.handleClick }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ],
          staticClass: "interview-info-page content-block"
        },
        [
          _c("div", { staticClass: "two-content" }, [
            _c("div", { staticClass: "patient-detail-left col-md-6" }, [
              _c("div", { staticClass: "heading-two" }, [
                _c("p", { staticClass: "content-title " }, [
                  _vm._v(_vm._s(_vm.$t("interview.basic_info")))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row-content d-flex" }, [
                _c("div", { staticClass: "col-12 m-0 p-0" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("interview.patient_name")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control-text" }, [
                      _vm._v(_vm._s(_vm.patientFullName))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6 m-0 p-0" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("interview.patient_romaji")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control-text" }, [
                      _vm._v(_vm._s(_vm.romaName))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("interview.birthday")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control-text" }, [
                      _vm._v(_vm._s(_vm.getBirthday))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("interview.occupation")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control-text" }, [
                      _vm._v(_vm._s(_vm.getPatientOccupation))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6 m-0 p-0" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("interview.patient_furigana")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control-text" }, [
                      _vm._v(_vm._s(_vm.kanaName))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex two-label" }, [
                    _c("div", { staticClass: "col-6 form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("interview.age")))
                      ]),
                      _vm._v(" "),
                      _vm.getAge >= 0
                        ? _c("div", { staticClass: "control-text" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.getAgeWithText) +
                                "\n                  "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6 form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("interview.sex")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control-text d-flex" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.getGender) +
                            "\n                    "
                        ),
                        _vm.info.pregnancy_status
                          ? _c("div", { staticClass: "status-pregnant" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.$t("interview.currently_pregnant")
                                  ) +
                                  "\n                    "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.info.pregnancy_status && _vm.info.gender === 1
                          ? _c("div", { staticClass: "status-not-pregnant" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(_vm.$t("interview.not_pregnant")) +
                                  "\n                    "
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("interview.kid_learning_sport")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control-text" }, [
                      _vm._v(_vm._s(_vm.info.lesson_sport))
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "patient-detail-left col-md-6" }, [
              _c("div", { staticClass: "heading-two" }, [
                _c("p", { staticClass: "content-title" }, [
                  _vm._v(_vm._s(_vm.$t("interview.address_contact")))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row-content" }, [
                _c("div", { staticClass: "col-12 m-0 p-0" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("interview.street_address")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control-text" }, [
                      _vm._v(_vm._s(_vm.info.address))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("interview.phone_number")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control-text" }, [
                      _vm._v(_vm._s(_vm.info.tel))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("interview.mail_address")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control-text" }, [
                      _vm._v(_vm._s(_vm.info.email))
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.getAge >= 0 && _vm.getAge < 20
            ? _c("div", { staticClass: "patient-detail-left col-md-12" }, [
                _c("div", { staticClass: "heading-two" }, [
                  _c("p", { staticClass: "content-title" }, [
                    _vm._v(_vm._s(_vm.$t("interview.minors_info")))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row-content d-flex" }, [
                  _c("div", { staticClass: "col-3 m-0 p-0" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("interview.guardian_name")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control-text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.guardianName) +
                            "\n              "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("interview.contact_info")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control-text" }, [
                        _vm._v(_vm._s(_vm.info.guardian_phone_number))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-9 m-0 p-0" }, [
                    _c("div", { staticClass: "d-flex two-label" }, [
                      _c("div", { staticClass: "form-group col-4" }, [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v(_vm._s(_vm.$t("interview.patient_furigana")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "control-text" }, [
                          _vm._v(_vm._s(_vm.furiganaName))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group col-4" }, [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v(_vm._s(_vm.$t("interview.relationship")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "control-text" }, [
                          _vm._v(" " + _vm._s(_vm.info.guardian_relationship))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group col-4" }, [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v(
                            _vm._s(_vm.$t("interview.guardian_occupation"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "control-text" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.getGuardianOccupation) +
                              "\n                "
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("interview.other_address")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control-text" }, [
                        _vm._v(_vm._s(_vm.info.guardian_address))
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "heading-two" }, [
                  _c(
                    "p",
                    { staticClass: "content-title content-title--no-border" },
                    [_vm._v(_vm._s(_vm.$t("interview.guardian_info")))]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row-content d-flex" }, [
                  _c("div", { staticClass: "col-3 m-0 p-0" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("interview.guardian_name")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control-text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.guardian2Name) +
                            "\n              "
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-9 m-0 p-0" }, [
                    _c("div", { staticClass: "d-flex two-label" }, [
                      _c("div", { staticClass: "form-group col-4" }, [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v(_vm._s(_vm.$t("interview.patient_furigana")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "control-text" }, [
                          _vm._v(_vm._s(_vm.guardian2FuriganaName))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group col-4" }, [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v(_vm._s(_vm.$t("interview.relationship")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "control-text" }, [
                          _vm._v(" " + _vm._s(_vm.info.guardian2_relationship))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group col-4" }, [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v(_vm._s(_vm.$t("interview.contact_info")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "control-text" }, [
                          _vm._v(_vm._s(_vm.info.guardian2_phone_number))
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }