<template>
  <transition name="modal">
    <div class="moldal-timepicker">
      <div
        v-show="show_timepicker"
        class="timepicker-picker"
      >
        <table class="table-condensed">
          <tr>
            <td>
              <a
                href="#"
                tabindex="-1"
                title="Increment Hour"
                class="btn"
                data-action="incrementHours"
                @click="incrementHours"
              />
            </td>
            <td class="separator" />
            <td>
              <a
                href="#"
                tabindex="-1"
                title="Increment Minute"
                class="btn"
                data-action="incrementMinutes"
                @click="incrementMinutes"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span
                class="timepicker-hour"
                data-time-component="hours"
                title="Pick Hour"
                data-action="showHours"
                @click="showHours"
              >
                {{ hour }}
              </span>
            </td>
            <td class="separator">
              :
            </td>
            <td>
              <span
                class="timepicker-minute"
                data-time-component="minutes"
                title="Pick Minute"
                data-action="showMinutes"
                @click="showMinutes"
              >
                {{ minute }}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="#"
                tabindex="-1"
                title="Decrement Hour"
                class="btn"
                data-action="decrementHours"
                @click="decrementHours"
              />
            </td>
            <td class="separator" />
            <td>
              <a
                href="#"
                tabindex="-1"
                title="Decrement Minute"
                class="btn"
                data-action="decrementMinutes"
                @click="decrementMinutes"
              />
            </td>
          </tr>
        </table>
      </div>
      <div
        v-show="show_timepicker_hours"
        class="timepicker-hours"
      >
        <table class="table-condensed">
          <tr
            v-for="(valueHour, keyHour) in option_hour"
            :key="keyHour"
          >
            <td
              v-for="(value, key) in valueHour"
              :key="key"
              @click="showHour(value)"
            >
              {{ getHour(value) }}
            </td>
          </tr>
        </table>
      </div>
      <div
        v-show="show_timepicker_minutes"
        class="timepicker-minutes"
      >
        <table class="table-condensed">
          <tr
            v-for="(valueMinute, keyMinute) in option_minute"
            :key="keyMinute"
          >
            <td
              v-for="(value, key) in valueMinute"
              :key="key"
              @click="showMinute(value)"
            >
              {{ getMinute(value) }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </transition>
</template>
<script>

  export default {
    name: 'ModalTimeSetting',
    props: {
      defaultValue: {
        type: String,
        default: ''
      }
    },
    emits: ['set-time'],
    data () {
      return {
        option_minute: [
          [0, 5, 10, 15],
          [20, 25, 30, 35],
          [40, 45, 50, 55]
        ],
        option_hour: [
          [0, 1, 2, 3, 4, 5],
          [6, 7, 8, 9, 10, 11],
          [12, 13, 14, 15, 16, 17],
          [18, 19, 20, 21, 22, 23]
        ],
        hour: '00',
        minute: '00',
        show_timepicker: true,
        show_timepicker_hours: false,
        show_timepicker_minutes: false
      }
    },
    watch: {
      hour: function (hour) {
        if (hour) {
          this.emitTimeChange()
        }
      },
      minute: function (minute) {
        if (minute) {
          this.emitTimeChange()
        }
      }
    },
    created () {
      const getTime = this.defaultValue.split(':')
      this.hour = getTime[0] ? getTime[0] : '00'
      this.minute = getTime[1] ? getTime[1] : '00'
    },
    methods: {
      showHours () {
        this.show_timepicker = false
        this.show_timepicker_hours = true
        this.show_timepicker_minutes = false
      },
      showMinutes () {
        this.show_timepicker = false
        this.show_timepicker_hours = false
        this.show_timepicker_minutes = true
      },
      showHour (value) {
        this.hour = value < 10 ? '0' + value : value
        this.emitTimeChange()
        this.show_timepicker = true
        this.show_timepicker_hours = false
      },
      getHour (value) {
        return value < 10 ? '0' + value : value
      },
      showMinute (value) {
        this.minute = value < 10 ? '0' + value : value
        this.emitTimeChange()
        this.show_timepicker = true
        this.show_timepicker_minutes = false
      },
      getMinute (value) {
        return value < 10 ? '0' + value : value
      },
      incrementHours () {
        const hourUpdate = parseInt(this.hour) + 1
        if (hourUpdate === 24) {
          this.hour = '00'
        } else {
          this.hour = hourUpdate < 10 ? '0' + hourUpdate : hourUpdate
        }
      },
      decrementHours () {
        const hourUpdate = parseInt(this.hour) - 1
        if (hourUpdate === -1) {
          this.hour = '23'
        } else {
          this.hour = hourUpdate < 10 ? '0' + hourUpdate : hourUpdate
        }
      },
      incrementMinutes () {
        const minuteUpdate = parseInt(this.minute) + 5
        if (minuteUpdate === 60) {
          this.minute = '00'
          this.incrementHours()
        } else {
          this.minute = minuteUpdate === 0 || minuteUpdate === 5 ? '0' + minuteUpdate : minuteUpdate
        }
      },
      decrementMinutes () {
        const minuteUpdate = parseInt(this.minute) - 5
        if (minuteUpdate === -5) {
          this.minute = '55'
          this.decrementHours()
        } else {
          this.minute = minuteUpdate === 0 || minuteUpdate === 5 ? '0' + minuteUpdate : minuteUpdate
        }
      },
      emitTimeChange () {
        const time = this.hour + ':' + this.minute
        this.$emit('set-time', time)
      }
    }
  }
</script>
