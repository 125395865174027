const common = {
  attributes: {
    digit_number: 'digit number',
    email: 'Mail address',
    last_name: 'Furigana surname',
    staff_name: 'Staff name',
    staff_id: 'Staff ID',
    password: 'Initial password',
    phone_number: 'Phone number',
    position: 'Position',
    reservable_limit: 'Maximum reservation days',
    roman_name: 'Romaji name',
    roman_last_name: 'Furigana surname'
  },
  between: 'Please enter {min} ~ {max} {type} for {attribute}',
  first_name_required: 'Please enter your name',
  invalid: '{attribute} is not a valid {attribute}',
  invalid_email: 'Email address is not a valid email address',
  invalid_file: 'The {name} file isn\'t valid',
  last_name_required: 'Please enter your surname',
  max_file_upload: 'Up to {maximum} files can be uploaded at one time',
  maximum: 'Please enter an %{max}-digit %{attribute}',
  note_maxlength: 'The string is too long. \nYou can enter up to {max} single-byte characters',
  numeric: 'Please enter the {attribute} as a numerical value',
  post_max_size: 'The total file size that can be uploaded at one time is {maximum} MB',
  required: 'Please enter the {attribute}',
  invalid_date: 'Set the end date after the start date',
  invalid_filename: 'The filename contains unusable symbols. <br> {symbols}',
  romaji_first_name_required: 'Please enter Romaji Mei',
  romaji_haffsize: 'Please enter half-width letters',
  romaji_last_name_required: 'Please enter Romaji Sei'
}

export default common
