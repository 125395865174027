var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-inner bg-white interview-block" }, [
    _c("div", { staticClass: "page-content" }, [
      _c("div", { staticClass: "header-title" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("interview.consultation_title")))]),
        _vm._v(" "),
        _c("p", {
          staticClass: "gray-arrow",
          class: { "toggle-icon": _vm.show },
          on: { click: _vm.handleClick }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ],
          staticClass: "advance-consultation-page content-block"
        },
        [
          _c(
            "div",
            { staticClass: "patient-detail-left col-md-12" },
            [
              _c("div", { staticClass: "heading-two" }, [
                _c("p", { staticClass: "content-title" }, [
                  _vm._v(_vm._s(_vm.$t("interview.consultation_content")))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row-content" }, [
                _c("div", { staticClass: "col-12 m-0 p-0" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "control-text" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.contentPosition) +
                          "\n                            "
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "heading-two" }, [
                _c("p", { staticClass: "content-title" }, [
                  _vm._v(_vm._s(_vm.$t("interview.places_of_concern_symptoms")))
                ])
              ]),
              _vm._v(" "),
              _vm._l(_vm.consultation.points_symptoms, function(
                symptom,
                index
              ) {
                return _c("div", { staticClass: "row-content" }, [
                  _c("div", { staticClass: "col-12 m-0 p-0" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        { staticClass: "control-label block-title" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("interview.priority_number", {
                                number: index + 1
                              })
                            )
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 m-0 p-0" }, [
                    _c("div", { staticClass: "d-flex two-label" }, [
                      _c("div", { staticClass: "form-group col-3" }, [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v(_vm._s(_vm.$t("interview.location")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "control-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.getPartName(symptom.part, symptom.part_detail)
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group col-3" }, [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v(_vm._s(_vm.$t("interview.dentition_number")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "control-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.getDentitionNumber(symptom.dentition_number)
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group col-6" }, [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v(
                            _vm._s(_vm.$t("interview.specific_consultation"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "control-text" }, [
                          _vm._v(_vm._s(symptom.consultation_content))
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 m-0 p-0" }, [
                    _c("div", { staticClass: "form-group col-12" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("interview.symptom")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control-text" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.getSymptoms(symptom)) +
                            "\n              "
                        ),
                        _c("p", { staticClass: "symptoms-comment" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.getSymptomsComment(symptom.symptoms)) +
                              "\n              "
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              })
            ],
            2
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }