var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "interview-advance-consultation-v2 interview-input-block content-inner bg-white"
    },
    [
      _c("according-block", {
        attrs: {
          label: _vm.$t("objects.patient.text.prior_interview"),
          checked: _vm.show_block
        },
        on: {
          handleClick: function($event) {
            _vm.show_block = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show_block,
              expression: "show_block"
            }
          ],
          staticClass:
            "interview-advance-consultation-v2__content arrow-toggle-content page-content"
        },
        [
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "div",
              { staticClass: "col-6 pre-block" },
              [
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-1",
                    attrs: {
                      question:
                        "他の医院で歯並びの相談をされた事はありますか？",
                      answer: _vm.answers.question_1.answer,
                      "question-id": _vm.answers.question_1.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "content-text" },
                        _vm._l(_vm.answers.question_1.items.details, function(
                          detail,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "form-group d-flex" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label same-width my-0"
                                },
                                [_vm._v(_vm._s(detail.title))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "control-text my-0" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(detail.content) +
                                    "\n                "
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-3",
                    attrs: {
                      question: "アレルギーはありますか?",
                      answer: _vm.answers.question_3.answer,
                      "question-id": _vm.answers.question_3.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "content-text" }, [
                        _vm.answers.question_3.view_content_1
                          ? _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.answers.question_3.view_content_1
                                  ) +
                                  "\n              "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("金属名：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_3.view_content_2) +
                                "\n                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.answers.question_3.view_content_3
                          ? _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.answers.question_3.view_content_3
                                  ) +
                                  "\n              "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("薬品名：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_3.view_content_4) +
                                "\n                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.answers.question_3.view_content_5
                          ? _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.answers.question_3.view_content_5
                                  ) +
                                  "\n              "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("発症時期：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_3.view_content_6) +
                                "\n                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("原因植物：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_3.view_content_7) +
                                "\n                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.answers.question_3.view_content_8
                          ? _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.answers.question_3.view_content_8
                                  ) +
                                  "\n              "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("原因物質：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_3.view_content_9) +
                                "\n                "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-6",
                    attrs: {
                      question: "今までに口や顔の周りの癖はありましたか？",
                      answer: _vm.answers.question_6.answer,
                      "question-id": _vm.answers.question_6.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "content-text" }, [
                        _c("div", { staticClass: "control-text my-0" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.answers.question_6.view_content_2) +
                              "\n              "
                          )
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-7",
                    attrs: {
                      question:
                        "喋りにくい言葉はありますか？（サ行、タ行が言いづらいなど）",
                      answer: _vm.answers.question_7.answer,
                      "question-id": _vm.answers.question_7.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "content-text" }, [
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("objects.patient.text.specifically")
                                ) + ":"
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_7.view_content) +
                                "\n                "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-10",
                    attrs: {
                      question:
                        "家族に不正咬合の方はいらっしゃいますか？</br>例：でこぼこ、出っ歯、反っ歯、受け口など",
                      answer: _vm.answers.question_10.answer,
                      "question-id": _vm.answers.question_10.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "content-text" },
                        _vm._l(_vm.answers.question_10.items, function(
                          item,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "d-flex" },
                            [
                              _c(
                                "label",
                                {
                                  class: [
                                    "answer-number",
                                    "number-" + (index + 1),
                                    "position-y-0"
                                  ]
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.getNumber(index + 1)) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "content-text" },
                                _vm._l(item.details, function(detail, i) {
                                  return _c("div", { key: i }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group d-flex" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "control-label same-width my-0"
                                          },
                                          [_vm._v(_vm._s(detail.title) + ":")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "control-text my-0" },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.convertOptions(detail)
                                                ) +
                                                "\n                      "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                }),
                                0
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-12",
                    attrs: {
                      question: "現在、通院されていますか？",
                      answer: _vm.answers.question_12.answer,
                      "question-id": _vm.answers.question_12.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "content-text" },
                        _vm._l(_vm.answers.question_12.items, function(
                          item,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "d-flex" },
                            [
                              _c(
                                "label",
                                {
                                  class: [
                                    "answer-number",
                                    "number-" + (index + 1),
                                    "position-y-0"
                                  ]
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.getNumber(index + 1)) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "content-text" },
                                _vm._l(item.details, function(detail, i) {
                                  return _c("div", { key: i }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group d-flex" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "control-label same-width my-0"
                                          },
                                          [_vm._v(_vm._s(detail.title) + ":")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "control-text my-0" },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.convertOptions(detail)
                                                ) +
                                                "\n                      "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                }),
                                0
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-14",
                    attrs: {
                      question: "今までにかかった病気はありますか？",
                      answer: _vm.answers.question_14.answer,
                      "question-id": _vm.answers.question_14.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "content-text" }, [
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.convertOptions(
                                    _vm.answers.question_14.view_content
                                  )
                                ) +
                                "\n                "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    attrs: {
                      question: "今までに次のようなことがありましたか？",
                      answer: _vm.answers.question_15.answer,
                      "question-id": _vm.answers.question_15.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "content-text" }, [
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_15.view_content) +
                                "\n                "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-17",
                    attrs: {
                      question: "喫煙習慣はありますか？",
                      answer: _vm.answers.question_17.answer,
                      "question-id": _vm.answers.question_17.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "content-text" }, [
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("一日量：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.convertOptions(
                                    _vm.answers.question_17.items.details[0]
                                  )
                                ) +
                                "\n                  "
                            ),
                            _c("span", { staticClass: "p-2 mr-95" }, [
                              _vm._v("本/日")
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-18",
                    attrs: {
                      question: "飲酒習慣はありますか？",
                      answer: _vm.answers.question_18.answer,
                      "question-id": _vm.answers.question_18.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "content-text" }, [
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label same-width my-0 mr-77"
                            },
                            [_vm._v("よく飲まれるお酒：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_18.view_content_1) +
                                "\n                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("一日量：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_18.view_content_2) +
                                "\n                  "
                            ),
                            _c("span", { staticClass: "p-2 mr-95" }, [
                              _vm._v("mlまたは")
                            ]),
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_18.view_content_3) +
                                "\n                  "
                            ),
                            _c("span", { staticClass: "p-2" }, [_vm._v("合位")])
                          ])
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-21",
                    attrs: {
                      question: "血圧の状態は？",
                      answer: _vm.answers.question_21.answer,
                      "no-answer": true,
                      "question-id": _vm.answers.question_21.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "content-text" }, [
                        _c(
                          "div",
                          { staticClass: "form-group d-flex option-answer" },
                          [
                            _c(
                              "div",
                              {
                                class: [
                                  "option",
                                  {
                                    choose:
                                      _vm.answers.question_21.view_content_1 ===
                                      "高い"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n                  高い\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class: [
                                  "option",
                                  {
                                    choose:
                                      _vm.answers.question_21.view_content_1 ===
                                      "普通"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n                  普通\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class: [
                                  "option",
                                  {
                                    choose:
                                      _vm.answers.question_21.view_content_1 ===
                                      "低い"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n                  低い\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class: [
                                  "option",
                                  {
                                    choose:
                                      _vm.answers.question_21.view_content_1 ===
                                      "不明"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n                  不明\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class: [
                                  "option",
                                  {
                                    choose:
                                      _vm.answers.question_21.view_content_1 ===
                                      "未入力"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n                  未入力\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v(_vm._s(_vm.$t("interview.highest")) + "：")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "control-text my-0 mr-100" },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.answers.question_21.view_content_2
                                  ) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v(_vm._s(_vm.$t("interview.lowest")) + "：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_21.view_content_3) +
                                "\n                "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-24",
                    attrs: {
                      question: "最近、歯科医院を受診されましたか？",
                      answer: _vm.answers.question_24.answer,
                      "question-id": _vm.answers.question_24.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "content-text" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("期間：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_24.view_content_1) +
                                "\n                  "
                            ),
                            _c("span", { staticClass: "p-2 date-range" }, [
                              _vm._v("年")
                            ]),
                            _vm._v(
                              _vm._s(_vm.answers.question_24.view_content_2) +
                                "\n                  "
                            ),
                            _c("span", { staticClass: "p-2 date-range" }, [
                              _vm._v("月ごろ、または")
                            ]),
                            _vm._v(
                              _vm._s(_vm.answers.question_24.view_content_3) +
                                "\n                  "
                            ),
                            _c("span", { staticClass: "p-2 date-range" }, [
                              _vm._v("年")
                            ]),
                            _vm._v(
                              _vm._s(_vm.answers.question_24.view_content_4) +
                                "\n                  "
                            ),
                            _c("span", { staticClass: "p-2 date-range--end" }, [
                              _vm._v("ヶ月前")
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("治療内容：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_24.view_content_5) +
                                "\n                "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-6 pre-block" },
              [
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-2",
                    attrs: {
                      question: "過去に矯正歯科治療されたことはありますか？",
                      answer: _vm.answers.question_2.answer,
                      "question-id": _vm.answers.question_2.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "content-text" }, [
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("いつ頃：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_2.view_content_1) +
                                "\n                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("症状：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_2.view_content_2) +
                                "\n                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("矯正装置：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_2.view_content_3) +
                                "\n                "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    attrs: {
                      question: "顎の症状で、今まで経験したものはありますか？",
                      answer: _vm.answers.question_4.answer,
                      "question-id": _vm.answers.question_4.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "content-text" }, [
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("いつ頃：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_4.view_content_1) +
                                "\n                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_4.view_content_2) +
                                "\n                "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-5",
                    attrs: {
                      question:
                        "今まで顔や顎を強くぶつけたりケガをしたことはありましたか？",
                      answer: _vm.answers.question_5.answer,
                      "question-id": _vm.answers.question_5.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "content-text" },
                        _vm._l(_vm.answers.question_5.items.details, function(
                          detail,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "form-group d-flex" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label same-width my-0"
                                },
                                [_vm._v(_vm._s(detail.title))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "control-text my-0" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(detail.content) +
                                    "\n                "
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-8",
                    attrs: {
                      question: "次のような症状はありますか？",
                      answer: _vm.answers.question_8.answer,
                      "question-id": _vm.answers.question_8.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "content-text" }, [
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.convertOptions(
                                    _vm.answers.question_8.view_content
                                  )
                                ) +
                                "\n                "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-pre item-pre--no" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "content-text" },
                      _vm._l(_vm.answers.question_9.view_content, function(
                        detail,
                        index
                      ) {
                        return _c(
                          "div",
                          { key: index, staticClass: "form-group" },
                          [
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(detail) +
                                  "\n                "
                              )
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-11",
                    attrs: {
                      question: "今まで耳鼻科を受診したことはありますか？",
                      answer: _vm.answers.question_11.answer,
                      "question-id": _vm.answers.question_11.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "content-text" },
                        _vm._l(_vm.answers.question_11.items, function(
                          item,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "d-flex" },
                            [
                              _c(
                                "label",
                                {
                                  class: [
                                    "answer-number",
                                    "number-" + (index + 1),
                                    "position-y-0"
                                  ]
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.getNumber(index + 1)) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "content-text" },
                                _vm._l(item.details, function(detail, i) {
                                  return _c("div", { key: i }, [
                                    detail.title !== "治療状況"
                                      ? _c(
                                          "div",
                                          { staticClass: "form-group d-flex" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "control-label same-width my-0"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(detail.title) + ":"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "control-text my-0"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.convertOptions(detail)
                                                    ) +
                                                    "\n                      "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-group d-flex option-answer"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  "option",
                                                  {
                                                    choose: detail.content.includes(
                                                      "完治"
                                                    )
                                                  }
                                                ]
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        完治\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  "option",
                                                  {
                                                    choose: detail.content.includes(
                                                      "現在通院中"
                                                    )
                                                  }
                                                ]
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        現在通院中\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  "option",
                                                  {
                                                    choose:
                                                      detail.content.includes(
                                                        "症状が出た時毎に通院"
                                                      ) ||
                                                      detail.content.includes(
                                                        "症状が出た時ごとに通院"
                                                      )
                                                  }
                                                ]
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        症状が出た時毎に通院\n                      "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                  ])
                                }),
                                0
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-13",
                    attrs: {
                      question:
                        "身体全体で先天性の疾患や生まれつき気になる事、部位はありますか？",
                      answer: _vm.answers.question_13.answer,
                      "question-id": _vm.answers.question_13.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "content-text" },
                        _vm._l(_vm.answers.question_13.items, function(
                          item,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "d-flex" },
                            [
                              _c(
                                "label",
                                {
                                  class: [
                                    "answer-number",
                                    "number-" + (index + 1),
                                    "position-y-0"
                                  ]
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.getNumber(index + 1)) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "content-text" },
                                _vm._l(item.details, function(detail, i) {
                                  return _c("div", { key: i }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group d-flex" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "control-label same-width my-0"
                                          },
                                          [_vm._v(_vm._s(detail.title) + ":")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "control-text my-0" },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.convertOptions(detail)
                                                ) +
                                                "\n                      "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                }),
                                0
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-16",
                    attrs: {
                      question: "現在飲んでいる薬はありますか？",
                      answer: _vm.answers.question_16.answer,
                      "question-id": _vm.answers.question_16.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "content-text" },
                        [
                          _c("div", { staticClass: "control-text" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.answers.question_16.view_content) +
                                "\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.answers.question_16.custom_items, function(
                            details,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "d-flex" },
                              [
                                _c(
                                  "label",
                                  {
                                    class: [
                                      "answer-number",
                                      "number-" + (index + 1),
                                      "position-y-0"
                                    ]
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.getNumber(index + 1)) +
                                        "\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "content-text" },
                                  _vm._l(details, function(detail, i) {
                                    return _c("div", { key: i }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group d-flex" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "control-label same-width my-0"
                                            },
                                            [_vm._v(_vm._s(detail.title) + ":")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "control-text my-0"
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.convertOptions(detail)
                                                  ) +
                                                  "\n                      "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-pre question-no-19" }, [
                  _c("div", { staticClass: "answer" }, [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "content-text" }, [
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("身長：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.answers.question_19.view_content_1) +
                                "\n                    "
                            ),
                            _c("span", { staticClass: "height" }, [
                              _vm._v("cm")
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("体重：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.answers.question_19.view_content_2) +
                                "\n                    "
                            ),
                            _c("span", { staticClass: "weight" }, [
                              _vm._v("kg")
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("血液型：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.answers.question_20.view_content) +
                                "\n                  "
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-22",
                    attrs: {
                      question: "女性の方のみ",
                      answer: _vm.answers.question_22.answer,
                      "no-answer": true,
                      "question-id": _vm.answers.question_22.question
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "content-text" }, [
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "control-label same-width my-0" },
                            [_vm._v("初潮年齢：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_22.view_content_1)
                            ),
                            _c("span", { staticClass: "p-2" }, [_vm._v("歳")]),
                            _vm._v(
                              _vm._s(_vm.answers.question_22.view_content_2)
                            ),
                            _c("span", { staticClass: "pt-2" }, [
                              _vm._v("ヶ月")
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "answer-common",
                  {
                    staticClass: "question-no-23",
                    attrs: {
                      question: "以前に歯科医院で歯を抜いたことがありますか？",
                      answer: _vm.answers.question_23.answer,
                      "question-id": _vm.answers.question_23.question
                    }
                  },
                  [
                    _c("label", { staticClass: "sub-title" }, [
                      _vm._v("その時異常はありましたか？")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex mt-2" }, [
                      _c("div", { staticClass: "content-text mt-2" }, [
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.answers.question_23.view_content) +
                                "\n                "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "question" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(
            "\n                寝相はどの状態が多いですか？\n              "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }