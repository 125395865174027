var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("logo", [
    _c("div", { staticClass: "justify-content-center mt-5" }, [
      _c("div", { staticClass: "form-group email-center" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.staff_id,
              expression: "staff_id"
            }
          ],
          staticClass: "form-control",
          attrs: {
            id: "staff_id",
            type: "text",
            placeholder: _vm.$t("login_page.staff_id"),
            disabled: ""
          },
          domProps: { value: _vm.staff_id },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.staff_id = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { autocomplete: "off", method: "post" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.initPassword($event)
          }
        }
      },
      [
        _c("div", { staticClass: "justify-content-center form_container" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "pwd" } }, [
              _vm._v(_vm._s(_vm.$t("common_page.password")))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "note-tip" }, [
              _vm._v(_vm._s(_vm.$t("common_page.input_characters")))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password"
                }
              ],
              staticClass: "form-control",
              attrs: { id: "pwd", type: "password", placeholder: "" },
              domProps: { value: _vm.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "confirm-password" } }, [
              _vm._v(_vm._s(_vm.$t("common_page.confirm_password")))
            ]),
            _c("span", { staticClass: "note-tip" }, [
              _vm._v(_vm._s(_vm.$t("common_page.input_characters")))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password_confirmation,
                  expression: "password_confirmation"
                }
              ],
              staticClass: "form-control",
              attrs: { id: "confirm-password", type: "password" },
              domProps: { value: _vm.password_confirmation },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password_confirmation = $event.target.value
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-2 forget-password" },
          [
            _vm.validationErrors
              ? _c("validation-errors", {
                  attrs: { errors: _vm.validationErrors }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center mt-3 mb-4 login_container"
          },
          [
            _c(
              "button",
              {
                staticClass: "btn login_btn",
                attrs: { type: "submit", name: "button" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("common_page.btn_login")) +
                    "\n      "
                )
              ]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }