<template>
  <common-modal-action
    class="modal-allocation"
    :title="$t('reservation.allocation.title')"
    @close="$emit('close')"
    @confirm="submit"
  >
    <template #body>
      <div class="modal-allocation__container">
        <div class="modal-allocation__group">
          <label class="modal-allocation__item modal-allocation__item--label">{{ $t('reservation.allocation.name') }}</label>
          <div class="modal-allocation__item-content">
            <common-input
              v-model="name_allocation"
              type="text"
              maxlength="50"
              :class="['modal-allocation__item-content__name', {'common-input-error': errors.name_allocation }]"
              :placeholder="$t('new_text.placeholder.allocation_name')"
              required="required"
              @input="handleNameChange"
            />
            <span
              v-if="errors.name_allocation"
              class="common-msg-error"
            >
              {{ errors.name_allocation }}
            </span>
          </div>
        </div>
        <div class="modal-allocation__group">
          <label class="modal-allocation__item modal-allocation__item--label">{{ $t('reservation.allocation.position') }}</label>
          <div class="modal-allocation__item-content modal-allocation__item-content--position">
            <common-select
              class="common-select--color-primary"
              :options="order_list"
              key-value="sortOrder"
              key-display="sortOrder"
              :default-value="sort_order"
              @change="sortOrder => sort_order = sortOrder"
            />
          </div>
        </div>
        <div class="modal-allocation__group">
          <label class="modal-allocation__item modal-allocation__item--label">{{ $t('reservation.allocation.auto_check') }}</label>
          <div class="modal-allocation__item-content modal-allocation__item-content--type">
            <common-select
              class="common-select--color-primary"
              :options="types"
              key-value="value"
              key-display="text"
              :default-value="selected_type"
              @change="value => selected_type = value"
            />
          </div>
        </div>
        <label class="modal-allocation__item modal-allocation__item--text">{{ $t('reservation.allocation.text') }}</label>
      </div>
    </template>
    <template #confirm_button_content>
      {{ $t('new_text.button.save') }}
    </template>
  </common-modal-action>
</template>

<script>
  import { CommonInput, CommonModalAction, CommonSelect } from '../common'
  import { get } from 'lodash'
  import { required, maxLength } from 'vuelidate/lib/validators'
  import { initData, resetSortOrder } from '../../mixins/reservation-allocation'
  import { RESPONSE_ERRORS } from '../../utils/constants'
  import { showErrorModal, showMessageError } from '../../utils/helpers'

  export default {
    components: {
      CommonSelect,
      CommonModalAction,
      CommonInput
    },
    mixins: [initData, resetSortOrder],
    props: {
      selectedDate: {
        type: String,
        default: ''
      }
    },
    emits: ['close'],
    data () {
      return {
        errors: {}
      }
    },
    validations () {
      return {
        name_allocation: { required, maxLength: maxLength(50) },
        sort_order: { required },
        selected_type: { required }
      }
    },
    created () {
      this.getSortOrderList(true)
      this.getTypes()
      this.sort_order = this.allocations.length + 1
    },
    methods: {
      submit () {
        this.errors = {}
        if (!this.validate()) {
          return
        }
        const postData = {
          column_name: this.name_allocation,
          column_type: this.selected_type,
          sortOrder: this.sort_order
        }
        this.$store.commit('set_loading', true)
        this.$store.dispatch('insertAllocation', postData).then((result) => {
          const data = result.data.results || ''
          const postDataNew = {
            id: data.allocations.id,
            column_name: data.allocations.column_name,
            column_type: data.allocations.column_type,
            sortOrder: data.allocations.ordering,
            staff_id: 0
          }

          const allocations = []
          let isChangeOrder = false

          this.allocations.forEach(element => {
            if (element.sortOrder === postDataNew.sortOrder) {
              isChangeOrder = true
              allocations.push(postDataNew)
            }

            allocations.push(element)
          })

          if (!isChangeOrder) {
            allocations.push(postDataNew)
          }

          this.resetSortOrder(allocations, 'created')
        }).catch((error) => {
          this.$store.commit('set_loading', false)
          const resErrors = get(error, 'response.data.errors') || {}
          if (get(resErrors, 'clinic', '') === RESPONSE_ERRORS.NO_CSL_SUPPORTED) {
            showErrorModal(this.$t('new_validation.allocation.clinic_not_support_csl'))

            return
          }

          showMessageError(error, this)
        })
      },
      validate () {
        this.$v.$touch()
        if (!this.$v.$anyError) {
          return true
        }

        const errors = []
        const errorMessages = {
          'name_allocation.required': this.$t('reservation.allocation.errors.required', { field: this.$t('reservation.allocation.name') }),
          'name_allocation.maxLength': this.$t('reservation.allocation.errors.maximum', { field: this.$t('reservation.allocation.name'), max: 50 }),
          'sort_order.required': this.$t('reservation.allocation.errors.required', { field: this.$t('reservation.allocation.position') }),
          'selected_type.required': this.$t('reservation.allocation.errors.required', { field: this.$t('reservation.allocation.auto_check') })
        }
        for (const key in errorMessages) {
          const parts = key.split('.')
          if (this.$v[parts[0]] && this.$v[parts[0]][parts[1]] === false) {
            this.errors[parts[0]] = errorMessages[key]
            errors.push(errorMessages[key])
          }
        }
        if (errors.length) {
          showErrorModal(errors.join('<br>'), { width: '440px' })

          return false
        }

        return true
      },
      handleNameChange (value) {
        this.errors.name_allocation = ''

        if (!value) {
          this.errors.name_allocation = this.$t('reservation.allocation.errors.required', { field: this.$t('reservation.allocation.name') })
        }
      }
    }
  }
</script>
