<template>
  <div class="patient-activity__item patient-activity__item--reservation">
    <div class="patient-activity__item__info">
      <p class="patient-activity__item__log-content">
        {{ logContent }}
      </p>
      <div
        v-if="isShowAuthor"
        class="patient-activity__item__author"
      >
        <label class="patient-activity__item__label">{{ renderTextStaffCreate }}</label>
        <span class="patient-activity__item__name">{{ authorName || patientName }}</span>
      </div>
    </div>
    <div class="patient-activity__item__date-time">
      <span>{{ logDate }}</span>
    </div>
    <div class="patient-activity__item__action">
      <router-link
        v-if="isShowButton"
        class="patient-activity__item__link"
        target="_blank"
        :to="{ name: 'reservation.list', query: { search_date: searchDay, reservation_id: modelData.id } }"
      >
        {{ $t('new_text.button.display') }}
      </router-link>
    </div>
  </div>
</template>
<script>
  import * as moment from 'moment'
  import { ACTIVITY_LOG_ACTION, RESERVATION_STATUS } from '../../../utils/constants'
  import { safeGet } from '../../../utils/helpers'

  export default {
    name: 'ReservationActivity',
    props: {
      activity: {
        type: Object,
        default: () => {}
      },
      modelData: {
        type: Object,
        default: () => {}
      },
      maxDate: {
        type: String,
        default: ''
      },
      patientName: {
        type: String,
        default: ''
      }
    },
    computed: {
      logDate: function () {
        return moment(this.activity.created_at).format(this.$t('new_text.datetime.year_month_day_hour_minute'))
      },
      searchDay: function () {
        const metaData = this.activity.meta_data ? JSON.parse(this.activity.meta_data) : null
        const startedTo = metaData ? metaData.started_to : this.modelData.started_to

        return moment(startedTo).format('YYYY-MM-DD')
      },
      isShowButton: function () {
        const createdAt = this.activity ? this.activity.created_at : ''
        return this.activity &&
          this.activity.action !== ACTIVITY_LOG_ACTION.CANCEL &&
          this.modelData &&
          this.modelData.status !== RESERVATION_STATUS.CANCELLED &&
          this.maxDate === createdAt
      },
      isShowAuthor: function () {
        return this.activity && (this.activity.staff || this.activity.action !== ACTIVITY_LOG_ACTION.CHECK_IN)
      },
      logContent: function () {
        const metaData = this.activity.meta_data ? JSON.parse(this.activity.meta_data) : null
        let startedTo = metaData ? metaData.started_to : this.modelData.started_to
        if (!this.activity || !startedTo) {
          return ''
        }
        startedTo = moment(startedTo).format('YYYY-MM-DD HH:mm')

        return this.$t('objects.activity.reservation.' + this.activity.action, { date: startedTo })
      },
      renderTextStaffCreate: function () {
        if (
          this.activity.action === ACTIVITY_LOG_ACTION.APPECT ||
          this.activity.action === ACTIVITY_LOG_ACTION.CANCEL ||
          this.activity.action === ACTIVITY_LOG_ACTION.CHANGE_SCHEDULE
        ) {
          return this.$t('new_text.correspondent')
        }
        return this.$t('new_text.author')
      },
      authorName: function () {
        return safeGet(this.activity, 'staff.name')
      }
    }
  }
</script>
