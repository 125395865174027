<template>
  <div class="patient-activity__item patient-activity__item--invoice">
    <div class="patient-activity__item__info">
      <p class="patient-activity__item__log-content">
        {{ logContent }}
      </p>
    </div>
    <div class="patient-activity__item__date-time">
      <span>{{ logDate }}</span>
    </div>
    <div class="patient-activity__item__action">
      <router-link
        v-if="isShowButton"
        class="patient-activity__item__link"
        target="_blank"
        :to="{ name: 'patient.accounting.detail', params: { id: activity.patient_id, aid: activity.activityable_id } }"
      >
        {{ $t('new_text.button.display') }}
      </router-link>
    </div>
  </div>
</template>
<script>
  import * as moment from 'moment'
  import { ACTIVITY_LOG_ACTION } from '../../../utils/constants'

  export default {
    name: 'InvoiceActivity',
    props: {
      activity: {
        type: Object,
        default: () => {}
      },
      modelData: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      logDate: function () {
        return moment(this.activity.created_at).format(this.$t('new_text.datetime.year_month_day_hour_minute'))
      },
      isShowButton: function () {
        return this.activity && this.activity.action !== ACTIVITY_LOG_ACTION.DELETE && this.modelData && !this.modelData.is_deleted
      },
      logContent: function () {
        if (!this.activity) return ''

        return this.$t('objects.activity.invoice.' + this.activity.action)
      }
    }
  }
</script>
