<template>
  <div :class="[className]">
    <sub-question
      ref="sub_question"
      :question="question"
      :options="option_yes_no"
      :checked="select_option"
      @handleAnswer="handleAnswer($event)"
    />
    <div :class="handleChildAnswerClass">
      <div
        v-for="detail in details"
        :key="detail.detail_index"
        class="form-group block-group"
      >
        <label class="block-group__first">{{ detail.detail_index }}</label>
        <div class="block-group__inline">
          <div class="form-group">
            <label class="color-blue">続柄：</label>
            <input
              v-model="detail.relationship"
              class="form-control"
              type="text"
              name=""
            >
          </div>
          <div class="form-group">
            <label class="color-blue">症状：</label>
            <input
              v-model="detail.symptom"
              class="form-control"
              type="text"
              name=""
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SubQuestion from '../template/SubQuestion'
  import { INTERVIEW_COMMON_OPTIONS, INTERVIEW_OPTIONS_NOT_ENTERED } from '../../../../utils/constants'

  export default {
    components: {
      SubQuestion
    },
    props: {
      className: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        number: 10,
        select_option: '',
        question: '家族に不正咬合の方はいらっしゃいますか？<br/> 例：でこぼこ、出っ歯、反っ歯、受け口など',
        option_yes_no: [
          INTERVIEW_COMMON_OPTIONS.YES,
          INTERVIEW_COMMON_OPTIONS.NO,
          INTERVIEW_OPTIONS_NOT_ENTERED
        ],
        details: [
          {
            detail_index: 1,
            relationship: '',
            symptom: ''
          },
          {
            detail_index: 2,
            relationship: '',
            symptom: ''
          },
          {
            detail_index: 3,
            relationship: '',
            symptom: ''
          }
        ],
        translate: {
          続柄: 'relationship',
          症状: 'symptom'
        }
      }
    },
    computed: {
      handleChildAnswerClass: function () {
        return {
          'disable-child-answer': this.select_option === INTERVIEW_COMMON_OPTIONS.NO
        }
      }

    },
    created: function () {
      this.loadAnswerData()
    },
    methods: {
      handleAnswer: function (value) {
        this.select_option = value
      },
      loadAnswerData: function () {
        const dataState = this.$store.getters.get_question(this.number)
        this.select_option = dataState.answer

        dataState.items.forEach((objMain, index) => {
          objMain.details.forEach(objDetail => {
            for (const jaName in this.translate) {
              if (objDetail.title === jaName) {
                const enName = this.translate[jaName]
                this.details[index][enName] = objDetail.content || ''
              }
            }
          })
        })
      },
      formatResult: function () {
        const data = this.$store.getters.get_default_answer_items(this.number)
        data.answer = this.select_option

        if (data.answer === INTERVIEW_COMMON_OPTIONS.YES) {
          data.items.forEach((objMain, index) => {
            objMain.details.forEach(objDetail => {
              for (const jaName in this.translate) {
                if (objDetail.title === jaName) {
                  const enName = this.translate[jaName]
                  objDetail.content = this.details[index][enName]
                }
              }
            })
          })
        }
        return data
      }
    }
  }
</script>
