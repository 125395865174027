import objectsEn from './lang/en/objects/index'
import validationEn from './lang/en/validation/index'
import messageEn from './lang/en/messages'
import textEn from './lang/en/text'

import objectsJa from './lang/ja/objects/index'
import validationJa from './lang/ja/validation/index'
import messageJa from './lang/ja/messages'
import textJa from './lang/ja/text'

const messages = {
  en: {
    objects: objectsEn,
    new_validation: validationEn,
    new_message: messageEn,
    new_text: textEn,
    common: {
      cancel: 'Cancel',
      cancel2: 'Cancel',
      yes: 'Yes',
      no: 'No',
      delete: 'Delete',
      deleted: 'Deleted',
      cancel_reservation: 'Cancel',
      download: 'Download',
      output: 'Output',
      upload: 'Upload',
      apply_change: 'Apply changes',
      first: 'First',
      week: 'Week',
      minute: 'Minute',
      color: 'Color',
      selection: 'Selection',
      save: 'Save',
      save2: 'Save',
      accepted: 'Accepted',
      update: 'Update',
      date_time: 'Date and time',
      name: 'Name',
      responsible: 'Responsible',
      note: 'Note',
      block: 'Block',
      read_more: 'Read more',
      comment: 'Comment',
      send: 'Send',
      close: 'Close',
      display: 'Display',
      search: 'Search',
      ID: 'ID',
      undo_deletion: 'Undo deletion',
      not_yet_entered: 'Not filled',
      required: 'required',
      move: 'Move',
      no_data: 'No data',
      decision: 'Decision',
      today: 'Today'
    },
    message: {
      already_booked: 'Already booked',
      block_cancelled: 'Block frame has been released',
      block_created: 'Block frame has been created',
      block_removal_confirmation: 'Do you want to unblock?',
      block_updated: 'Updated memo',
      booking_reservation_completed: 'Booking new reservation completed',
      confirmation_delete: 'Are you sure you want to delete?',
      csv_malformed: 'The CSV is malformed',
      comment_deleted: 'Comment deleted',
      comment_deleted_success: 'Comment deleted',
      comment_removal_confirmation: 'Do you want to delete comment?',
      error_occurred: 'An error occurred!',
      has_been_deleted: 'Has been deleted',
      has_been_updated: 'Has been updated',
      has_registered: 'Has registered',
      loading: 'Loading...',
      no_history_of_sending_sms: 'There is no history of sending SMS',
      no_more_patient: 'No more patient',
      patient_not_found: 'Patient not found',
      phone_number_empty: 'The phone number is not registered.\nThe questionnaire will be handwritten by the patient.',
      phone_number_not_available: 'A phone number that cannot send SMS has been registered.\nThe questionnaire will be handwritten by the patient.',
      please_select_patient: 'Please select a patient',
      reservable_slots_reflected: 'Reservable slots have been reflected',
      reservation_canceled: 'Reservation canceled',
      reservation_created: 'Reservation created',
      reservation_not_reflected_in_frame: 'It will not be reflected in the frame with reservation',
      reservation_updated: 'Changed the reservation details',
      reservation_updated_exist_time: 'Please change the reservation date time because the reservation is duplicated\n {dateTime}',
      reservation_updated_day_off: 'The expected date of reservation is set on a closed day.\nPlease select another date or change the business day setting.',
      reservation_confirm_update: 'Do you want to change booking time to {dateTime} ?',
      block_frame_confirm_update: 'Do you want to change block time to {dateTime} ?',
      reservation_confirm_continue_move_mode: 'Do you want to continue change the booking time?',
      block_frame_confirm_continue_move_mode: 'Do you want to continue changing the block frame?',
      reservation_confirm_continue_move_mode_trigger_by_action: 'You need close the move mode to processing print.\nDo you want to continue change the booking time?',
      saved: 'Saved',
      something_went_wrong: 'Something went wrong',
      sms_can_be_sent_if_reservation_exist: 'SMS can be sent after registering the reservation information.',
      sms_failed: 'Failed to send SMS',
      sms_sending_confirmation: 'To :tel\nAre you sure you want to send an SMS?',
      sms_sent: 'SMS has been sent',
      password_empty: 'Please enter your password',
      undo_deletion_patient_exist_error: 'The deletion cannot be canceled because the patient information using the same email address and phone number exists.\nIf you need to cancel the deletion, please change the patient information with duplicate email address and phone number.',
      complete_the_visit_reception_note: 'Click to complete the visit reception',
      reservation_confirm_message_title: 'Move the time frame.</br>Are you sure?',
      block_time_confirm_message_move: 'Are you sure you want to move the block frame?',
      block_time_confirm_message_change: 'Are you sure you want to change the block frame?',
      allocation_can_not_delete_all: 'Can not delete all allocation frame',
      no_data_to_export_csv: 'The data output has empty.'
    },
    text: {
      add_charge: 'Add charge',
      alias: 'Alias',
      before_reception: 'Before Reception',
      before_reception_title: 'Reception - Before Reception',
      block_frame_editing: 'Block Frame Editing',
      block_frame_label: 'Block this frame',
      business_days_setting: 'Setting business days',
      cancel_reservation: 'Cancel Reservation',
      cancellation_note_placeholder: 'Enter notes',
      cancellation_notification: 'If you cancel the first reservation, the patient status will be cancelled.',
      cancellation_reason: 'Reason for cancellation',
      reason_for_cancellation: 'Reason for cancellation',
      cancellation_reason_from_patient: 'Cancellation of reservation from patient',
      cancellation_reason_from_duplicated: 'Change to another hospital, duplicate registration',
      cancellation_reason_unauthorized: 'Unauthorized cancellation',
      chart_creation: 'Chart creation',
      create_notes_to_activity_log: 'You can post a note to the activity log',
      csv_contribution: 'CSV contribution',
      csv_reservation_importing: 'Reservable frame information CSV generation/reading',
      csv_template_setting: 'Template csv setting',
      csv_upload_for_setting: 'CSV upload for settings',
      datetime_setting: 'Reservation date time setting',
      display_name: 'Display name',
      duty_in_charge: 'Duty in charge',
      edit_current_tab: 'Edit currently displayed tab',
      edit_reservation: 'Edit Reservation',
      edit_tab: 'Edit tab',
      job_master_setting: 'Job master setting',
      job_title: 'Job title',
      long_term_leave_setting: 'Long-term leave setting',
      new_reservation: 'New Reservation',
      no_reservation: 'No reservation',
      patient_details: 'Patient details',
      patient_status: {
        status_0: 'New reservation',
        status_1: 'Before Reception',
        status_2: 'Checked In',
        status_3: 'Before Actuarial',
        status_4: 'Paid'
      },
      periodic_block_setting: 'Periodic block setting',
      periodic_week_setting: 'Enter 0 to set weekly',
      please_enter: 'Please enter',
      please_select_date: 'Please select a date',
      receive_time: 'Receive time',
      register_reservation: 'Register Reservation',
      reservable_limit: 'Maximum reservation days',
      type_setting: 'Reservation type setting',
      reservation_type: 'Reservation type',
      reservation_type_master_setting: 'Reservation type master setting',
      reservation_type_registration: 'Reservation type registration',
      select_patient_placeholder: 'Please select a patient',
      select_reservation_type_placeholder: 'Please select reservation type',
      tap_to_add_item: 'Click to add item',
      time_required: 'Time required',
      unblock: 'Unblock',
      update_memo: 'Memo update',
      update_reservation: 'Update Reservation',
      send_sms: 'Send SMS',
      enter_questionnaire: 'Enter the Questionnaire',
      send_sms_title: 'Preliminary inquiry sheet guidance SMS transmission',
      questionnaire: 'Questionnaire',
      not_entered: 'Not entered',
      questionnaire_printing: 'Questionnaire Printing',
      sms_history: 'SMS History',
      reservation_date: 'Reservation date',
      phone_number: 'Phone number',
      transmission_history: 'Transmission history',
      sms_sending_confirmation_title: 'Send SMS',
      patient_interview_created: 'Questionnaire was created',
      patient_interview_completed: 'The questionnaire has been completed',
      author: 'Author',
      interview_status: {
        0: 'No questionnaire input',
        1: 'The questionnaire has been entered'
      },
      sms_history_status: {
        0: 'Transmission failure',
        1: 'Transmission successful'
      },
      deleted_time: 'Deleted time',
      move_mode_reservation_popup_title: 'Change the booking time',
      move_mode_block_frame_popup_title: 'Change the block frame ',
      block_frame_changed_successfully: 'Block frame changed successfully',
      block_frame_moved_successfully: 'Block frame moved successfully',
      sms_delivery_setting: 'SMS delivery settings',
      reservation_date_change: 'Change date and time',
      changed: 'Changed'
    },
    validation: {
      attributes: {
        sex: 'Sex',
        birthday: 'Birthday',
        phone_number: 'Phone number',
        contact_information: 'Contact information',
        keyword_searching: 'Keyword searching'
      },
      required: 'Please enter the {attribute}',
      numeric: 'The :attribute must be a number.',
      between: 'The :attribute must be between :min and :max.',
      clinic: {
        formal_name_required: 'Enter the clinic name',
        formal_name_format: 'Clinic name can be up to 50 characters',
        email_required: 'Enter your email address',
        email_format: 'Email address is not a valid email address',
        phone_only_number: 'Telephone numbers can only be numbers',
        phone_format: 'Phone number must be at least 10 characters',
        phone_required: 'Enter your phone number'
      },
      interview: {
        basic_information: 'The basic information is required',
        content_consultation: 'The content consultation is required',
        prior_consultation: 'The prior consultation is required',
        other_consultation: 'The other consultation is required',
        last_name_required: 'Please enter your name',
        first_name_required: 'Please enter your name',
        romaji_required: 'Please enter your name (in Roman letters)',
        romaji_haffsize: 'Please enter half-width letters',
        kana_only: 'Please enter in hiragana',
        kana_required: 'Please enter furigana',
        required_email: 'Email address is a required',
        invalid_email: 'Email address is not a valid email address',
        zipcode_required: 'Enter the postal code in 7 digits',
        zipcode_error: 'Enter the postal code in 7 digits',
        select_city: 'Please enter the city',
        locality: 'Please enter the street address and the following address',
        empty_street: 'Please enter the street address and the following address',
        empty_address: 'Please enter the address and the following address',
        phone_only_number: 'Phone numbers can only use numbers',
        phone_only_single_number: 'Please enter only half-width numbers',
        invalid_phone: 'Phone number must be at least 10 characters',
        invalid_profession: 'Please select a profession',
        maxlength: 'Character limit',
        question: 'Need an answer',
        relationship_required: 'Please be sure to enter the relationship',
        contact_info_required: 'Please be sure to enter your contact information',
        address_required: 'Please be sure to enter your address.'
      },
      patient: {
        phone_number_required: 'Please enter your phone number',
        birthdate_required: 'Please select the correct date of birth'
      },
      reservation: {
        cancelled_reason_required: 'Please select the reason for cancellation',
        cancelled_reason_other_detail_required: 'Please enter other reasons for cancellation'
      },
      allocation: {
        empty_data: 'Please enter data',
        column_name_required: 'Please enter column name',
        order_required: 'Please enter order',
        order_integer: 'Order must be integer'
      }
    },
    sidebar_menu: {
      reservation_calendar: 'Reservation calendar',
      reserve: 'Reserve',
      recept: 'Reception',
      patient: 'Patient',
      accounting: 'Accounts',
      order: 'Order',
      japanese: 'Japanese',
      english: 'English',
      log_out: 'Log out',
      change_language: 'Change language',
      preferences: 'Preferences',
      help: 'Help',
      clinic_name: 'Clinic {name}'
    },
    order: {
      sidebar: {
        instructions: 'Order',
        search: 'Order / Patient Search',
        confirmation: 'Clinic confirmation required',
        checking: 'Checking before ordering',
        setup: 'Setup design',
        prepare_ship: 'Preparing to ship',
        shipment_completed: 'Shipment completed',
        delivery_completed: 'Delivery completed',
        cancel: 'Cancel',
        draft: 'Instructions for saving draft'
      }
    },
    main: {
      back_to_index: 'Back to main',
      acepted: 'Accepted',
      create_chart: 'Create chart',
      create_technical_instruction: 'Create technical instruction',
      history: 'History',
      edit: 'Edit',
      visiting_reception: 'Visiting reception',
      visiting_reception_comment: 'Tap to complete reception',
      patient_detais: 'Patient details',
      account_information: 'Accounting information',
      medical_chart_information: 'Medical chart information',
      show_all: 'Show all',
      responsible: 'Responsible',
      visit_schedule_printing: 'Visit schedule printing',
      visit_date: 'Visit date',
      service_category: 'Service category',
      overall_service: 'Overall service',
      number_remaining_service: 'Number of remaining service',
      remaining_service_amount: 'Remaining service amount',
      basic_information: 'Basic information',
      patient_name: 'Patient name',
      romaji: 'Romaji',
      sex: 'Sex',
      reading: 'Reading',
      birthday: 'Birthday',
      contact_address_information: 'Contact/address information',
      street_address: 'Street address',
      phone_number: 'Phone number',
      mail_address: 'Mail address',
      profession: 'Profession',
      contact_information: 'Contact information',
      dentist: 'Dentist',
      conselor: 'Conselor',
      dental_hygienist: 'Dental hygienist',
      dental_assistant: 'Dental assistant',
      other_remarks: 'Other Remarks',
      service_management: 'Service management',
      no_patient_search: 'No patients searched were found',
      system_error_occurred: 'System error occurred',
      accounting_creation: 'Accounting creation',
      visit_schedule: 'Visit Schedule'
    },
    invoice: {
      invalid_type_of_value: 'Invalid type of value',
      blank_number: 'Blank number',
      no_payment_choice: 'No payment choice',
      none_for_0_yen: 'Please select "None" for 0 yen',
      exceeded_number_of_characters: 'Exceeded number of characters ',
      rest_service_less_than_0: 'Rest service less than 0',
      service_total_amount_blank: 'Please enter total service amount'
    },
    settings: {
      tap_to_create_new: 'Tap to create new',
      resend_email_success: 'Successful',
      resend_email_failed: 'Failed',
      resend_reset_password_success: 'Successful',
      resend_reset_password_failed: 'Failed',
      search_by_staff_name_id: 'Search by name / ID',
      maximum_reservation_day: 'Set the maximum reservation days between 10 and 365',
      reservation_duplicate_time_zone: 'Duplicate time zone setting',
      invalid_input: 'The time format is incorrect',
      invalid_input_2: 'Invalid input',
      overlapped_block_time: 'The block frame is included in the specified time zone',
      periodic_require_week: 'Please enter the week from 0 to 5',
      periodic_require_day_of_week: 'Please select the day of the week',
      not_have_any_staff: 'No staff information was found.'
    },
    datetime: {
      year_month_day: 'YYYY-MM-DD',
      start_date: 'Start date',
      end_date: 'End date',
      year_month_day_hour_minute: 'YYYY-MM-DD HH:mm',
      year_month_day_hour_minute_str: 'YYYY-MM-DD HH:mm',
      month_day: 'MM-DD',
      hour_minute: 'HH:mm'
    },
    patient: {
      current_status_0: 'New reservation',
      current_status_1: 'Before Reception',
      current_status_2: 'Checked In',
      current_status_3: 'Before Actuarial',
      current_status_4: 'Paid',
      patient_name_required: 'Patient name is required',
      patient_name_invalid: 'No symbols can be used in the name',
      patient_last_name_required: 'Please enter the patient last name',
      patient_first_name_required: 'Please enter the patient name',
      first_symptom: 'First symptom',
      zipcode_greater_than_7: 'Please enter 7-digit postal code',
      zipcode_invalid: 'Not a valid zip code',
      region_required: 'Please select region',
      locality_required: 'Please enter the locality',
      street_required: 'Please enter the street',
      patient_kata_last_name_required: 'Please enter the furigana surname',
      patient_kata_first_name_required: 'Please enter the furigana name',
      please_enter_last_name_hiragana: '(Furigana surname) Please enter in hiragana',
      please_enter_first_name_hiragana: '(Furigana name) Please enter in hiragana',
      phone_number_invalid: 'The phone number is not valid',
      sf_status_c: [
        {
          key: 0,
          value: 'Cancelled'
        },
        {
          key: 1,
          value: 'Waiting for customer'
        },
        {
          key: 2,
          value: 'New customer with no reservation'
        },
        {
          key: 3,
          value: 'Reservation date adjusting'
        },
        {
          key: 4,
          value: 'Already arrived'
        },
        {
          key: 5,
          value: 'Existing patients at the clinic'
        },
        {
          key: 6,
          value: 'Switching to other corrections'
        },
        {
          key: 9,
          value: 'Deleted'
        }
      ],
      salesforce_status_c: {
        0: 'Cancelled',
        1: 'Waiting for customer',
        2: 'New customer with no reservation',
        3: 'Reservation date adjusting',
        4: 'Already arrived',
        5: 'Existing patients at the clinic',
        6: 'Switching to other corrections',
        9: 'Deleted'
      },
      cancellation_time: 'Cancellation Time',
      cancellation_first_time: 'Cancellation first Time',
      first_visit_time: 'First Visit Time',
      search_header: 'Search by ":string"',
      please_reload_while_getting_ID: 'Contacting Salesforce. Please reload',
      btn_search_detail: 'Detail search',
      btn_clear_search_detail: 'Clear search',
      sorting: 'Sorting',
      sort: {
        'id-asc': 'ID ascending',
        'id-desc': 'ID descending',
        'created_at-asc': 'Registration date ascending',
        'created_at-desc': 'Registration date descending',
        'started_to-asc': 'First consultation appointment date ascending',
        'started_to-desc': 'First visit appointment date descending'
      },
      modal: {
        title: 'Patient search',
        place_holder_search_name: 'Please enter in kanji or furigana',
        search_info: 'Patient information',
        search_reservation_info: 'Contract information',
        range_datetime_reservation: 'Specify period',
        condition_reservation: 'Conditions',
        age: 'Age',
        from: 'from',
        select_treatment_placeholder: 'Please select the action content',
        gender_options: {
          no_select: 'No selection',
          male: 'Male',
          female: 'Female'
        },
        reservation_condition: {
          first_visit: 'First visit',
          re_examination: 'Re-examination'
        },
        treatment_detail: 'Treatment detail',
        invalid_data_reservation: 'Set the end date after the start date',
        btn_show_treatment: 'Choice',
        search_no_result: 'No patient information was found that matches the criteria'
      },
      title_delete_modal: 'Delete patient information',
      description_delete_modal: 'After deleting the patient information, the medical record and accounting information cannot be modified.\nIn addition, all the reserved records so far are deleted and cannot be restored.\nIf you want to delete it, please complete the operation before deleting the patient information.',
      note_delete_modal: 'Please enter your login password to perform the deletion',
      note_cancel_delete: 'Do you want to cancel the deletion of patient information?'
    },
    medicalrecord: {
      staffs_column_requires_to_be_fully_filled: '"Staffs" column requires to be fully filled',
      sentence_on_main_conplaint_is_required: 'Sentence on "main conplaint" is required',
      you_must_enter_fees: 'You must enter fees',
      please_enter_of_all_treatments: 'Please enter of all treatments',
      you_enter_invalid_inputs: 'You enter invalid inputs',
      treatment_name: 'Treatment name',
      treatment_number_of_set: 'Number of sets',
      treatment_unit_price: 'Unit price (excluding tax)',
      treatment_total_not_tax: 'Total (excluding tax)',
      treatment_total_include_tax: 'Total (tax included)',
      treatment_content_not_set: 'Treatment content has not been set',
      you_must_enter_next_consultation: 'You must enter next consultation',
      save_medical_record: 'Save medical record',
      save_draft_medical_record: 'Save medical record',
      create_medical_record: 'Create medical record',
      create_draft_medical_record: 'Save a draft of the medical record.',
      enter_your_password: 'Enter your password',
      enter_your_password_draft_modal: 'Please enter your own password.',
      password: 'Password',
      password_is_incorrect: 'Your password is incorrect',
      author: 'Author',
      correspondent: 'Correspondent',
      treatment_number_select_bigger_service_remain: 'Treatment number selected bigger service remain.',
      digested_services: 'The following services will be digested'
    },
    page_title: {
      u1_1: 'Login',
      u1_2: 'Password Recovery',
      u1_3: 'Password Recovery',
      u1_4: 'Reset Password',
      u1_5: 'Reset Password',
      u1_6: 'User Registration',
      u2_1a: 'Reception - All',
      u2_1b: 'Reception - Reserved',
      u2_1c: 'Reception - Checked in',
      u2_1d: 'Reception - Before accounting',
      u2_1e: 'Reception - Paid',
      u7_1: 'Patients',
      u3_1: 'Patient Detail',
      u3_2: 'Patient Edit',
      u3_3: 'Patient History Detail',
      u3_4: 'Patient History',
      u3_5: 'Patient Registration',
      u4_1: 'Medical Record List',
      u4_2: 'Medical Record Detail',
      u4_3: 'Medical Record Registration',
      u4_5: 'Medical Record Edit',
      u4_6: 'Medical Record Edit History',
      u6_1: 'Patient Accounting',
      u6_2: 'Patient Accounting Edit',
      u6_3: 'Patient Accounting Detail',
      u8_1: 'Accounting',
      u8_2: 'Reservation Calendar',
      s2_1: 'Setting - Accounts',
      s2_2: 'Setting - IP address',
      s2_3: 'Setting - Clinic',
      s3_1: 'Setting - Treatments',
      s3_2: 'Setting - Independent treatment',
      s3_3: 'Setting - Services',
      s4_1: 'Setting - Reservation date time',
      s5_1: 'Setting - Reservation type',
      u9_1: 'Notice',
      patient_deleted: 'Setting - Deleted patient information'
    },
    login_page: {
      input_characters: 'Using half-width English more than 8 characters numbers and letters',
      forgot_pass: 'Forgot Password?',
      staff_id: 'Staff ID',
      please_enter_staff_id: 'Please enter staff ID',
      forgotten_staff_id: 'If you have forgotten your staff ID,</br>contact the clinic administrator',
      password_placeholder: 'Please enter staff password',
      failed: 'These credentials do not match our records.',
      account_is_deleted: 'Account is deleted',
      account_is_deactivated: 'Account is deactivated'
    },
    reset_password_complete: {
      text: 'Your password has been reset successfully'
    },
    reset_password: {
      token_expired: 'The password reset link has expired.\nPlease request the password reset again.',
      btn_reset: 'Password Reset'
    },
    common_page: {
      email: 'Email',
      password: 'Password',
      confirm_password: 'Re-typing password',
      input_characters: 'Using half-width English more than 8 characters numbers and letters',
      btn_login: 'Log In',
      chart_info: 'Medical chart information',
      please_change_your_password: 'Please change your password',
      button_settings: 'Settings',
      log_out: 'Log out'
    },
    email_forgot_password: {
      text_1: 'A password reset email has been sent.',
      text_2: 'Please check your email.',
      text_3: 'Please log in again from the link in the email.'
    },
    register_page: {
      company_name: 'NEOLAB Inc.',
      account_name: 'Account name',
      btn_check_text_terms: 'I agree with Terms of Services & Privacy Policy',
      btn_add: 'Add account'
    },
    reception_page: {
      recept: 'Reception',
      register_patient: 'Patient registration',
      show_all: 'All display',
      before_visit: 'Before Reception',
      accepted: 'Accepted',
      before_accounting: 'Before accounting',
      accounted_patient: 'Accepted accounting',
      accepted_1: 'Accepted',
      date_text: 'Select display period',
      date_start: 'Start date',
      btn_save: 'Save',
      btn_reset: 'Reset',
      date_year: 'Year',
      date_month: 'Month',
      date_day: 'Day',
      influencer: 'Influencer',
      recept_time: 'Reception time',
      billing_amount: 'Billed this time',
      payment_time: 'This time payment amount',
      note: 'Note',
      first_visit_patient: 'First visit patient',
      price_unit: '¥'
    },
    patient_page: {
      back_to_list: 'Back to list',
      btn_create_chart: 'Add record',
      patient_info: 'Patient information',
      system_info: 'System information',
      quick_booking: 'Quick reservation registration',
      created_at: 'Create at',
      updated_at: 'Update at',
      patient_activity_log: 'Patient activity log',
      reservation_schedule: 'Reservation schedule',
      new_reservation: 'New reservation',
      accounting_info: 'Accounts information',
      basic_info: 'Basic information',
      name: 'Name',
      romaji: 'Romaji',
      sex: 'Sex',
      furigana: 'Furigana',
      birthday: 'Birthday',
      contact_info: 'Contact / Street address',
      address: 'Address',
      phone_number: 'Phone',
      email: 'Email',
      assignee_info: 'Assignee information',
      other_notes: 'Other notes',
      influencer: 'Influencer',
      btn_history: 'History',
      btn_edit: 'Edit',
      edit_history: 'Editorial history',
      back_to_patient: 'Back to page',
      accept: 'Accepted',
      display: 'Display',
      tab_info: 'Tab information',
      required: 'required',
      last_name: 'Last name',
      first_name: 'First name',
      kata_last_name: 'Kata last name',
      kata_first_name: 'Kata first name',
      roma_last_name: 'Roma last name',
      roma_first_name: 'Roma first name',
      last_name2: 'Last name',
      first_name2: 'First name',
      tap_select: 'Please select',
      man: 'Man',
      woman: 'Woman',
      tap_to_add_item: 'Tap to add item',
      btn_back_without_saving: 'Return without saving',
      btn_save: 'Save',
      patient_search: 'Search for patient',
      all: 'All',
      assignee: 'Assignee',
      recept_time: 'Reception time',
      no_results: 'No patients searched were found',
      back: 'Back',
      billing_amount: 'billing amount',
      amount_paid: 'amount paid',
      deduction_amount: 'deduction amount',
      amounted: 'amounted',
      before_accounting: 'before accounting',
      date_issue: 'Date of issue',
      payment_note: 'The following was received correctly',
      tax_included: 'Tax included',
      detail: 'Details',
      total: 'Total',
      yen: 'yen',
      more_than: 'More than',
      tech_instruction_creation: 'Technical instruction creation',
      tech_instruction: 'Technical instruction',
      visit_date: 'Visit date',
      content_treatment: 'Treatment details',
      display_record: 'View record',

      complete_accounting: 'Complete accounting',
      tap_to_complete_accounting: 'Tap to complete accounting',
      receipt_printing: 'Receipt printing',
      color_setting: 'Color setting',
      zip_code: 'Zip code',
      btn_zip_code: 'Address Search',
      patient_status: 'Patient status',
      first_reservation_modal_title_insert: 'Booking first reservation',
      first_reservation_modal_title_change: 'Change the first reservation',
      first_reservation_confirmation_insert: 'It will be registered as the first appointment,\nAre you sure?',
      first_reservation_confirmation_change: 'Your first reservation will be changed.\nAre you sure?',
      brand_type: 'Brand type',
      age: 'age',
      receive_sms: 'deliver',
      receive_sms_title: 'SMS delivery settings',
      receive_sms_value: ['Don\'t deliver', 'Deliver'],
      delivery_settings: 'Delivery settings',
      field_receive_email: 'Questionnaire mail delivery settings',
      is_not_receive_email: 'Do not deliver'
    },
    error_page: {
      error: 'The page can not be found.'
    },
    setting_page: {
      setting: 'Setting',
      account_setting: 'Account settings',
      whitelist_setting: 'Whitelist settings',
      deleted_patient: 'Deleted patient information',
      patient_deleted: 'Deleted patient',
      master_setting: 'Master settings',
      treatment_detail: 'Treatment details',
      service_management: 'Services management',
      invite: 'Invitation',
      add_member: 'Add member',
      send_invite: 'Send an invitation email',
      select_role: 'Please select a role',
      role_admin: 'Admin',
      role_view_only: 'View only',
      invite_date: 'Invitation date',
      possible_login: 'Possible to login',
      last_login: 'Last logged in',
      normal: 'Normal',
      master_title: 'Master title',
      default_price: 'Default unit price',
      yen: 'yen',
      send_email_reset_password: 'Send email reset password',
      delete_staff: 'Delete Staff',
      edit: 'Edit',
      requesting: 'Requesting',
      disallow: 'Disapproval',
      allow: 'Permission',
      clinic_setting: 'Clinic settings',
      clinic: {
        info: 'Clinic information',
        formal_name: 'Clinic name',
        info_desc: 'The entered clinic name will be reflected in the receipt clinic information. <br> It does not affect the notation on the official website of Kirei Line.',
        contact: 'contact info',
        email: 'Email',
        phone: 'Phone',
        save_success: 'Saved',
        confirm_update_title: 'Clinic settings',
        confirm_update_content: 'Do you want to save your edits?',
        revenue_report: 'Daily sales report',
        send_report: 'Send daily sales report',
        report_time: 'Send time',
        report_to: 'Emails',
        report_description: 'You can enter multiple emails separated by commas.',
        auto: 'Automatic update',
        reload: 'Automatic dashboard reservation update',
        notification: 'Automatic reservation information notification',
        unit: 'Every minute'
      },
      master_action: 'Action master',
      independent_treatment: {
        name: 'Hospital-specific treatment',
        title: 'Hospital-specific treatment items',
        create: 'Create your own action master',
        treatment_name: 'Treatment name',
        price: 'Price',
        msg_used: 'Cannot be changed as it is used in the medical record'
      },
      treatment: {
        name: 'Treatment name',
        price: 'Price',
        service: 'Services'
      }
    },
    accounting_list_page: {
      accounting: 'Reservation / Accounting',
      select_date: 'Select date',
      download: 'Download',
      report_data: 'Daily report data',
      report_treatment_list_data: 'Treatment list CSV',
      report_revenue_receivables_data: 'Revenue receivables CSV',
      payment_amount: 'Payment amount',
      accrued: 'Accrued accruals',
      total_receiv: 'Total receivables',
      full_name: 'Full name',
      payment_type: 'Payment type',
      yen: 'yen',
      reservations_frame: 'Reservations frame',
      reservation_type: 'Reservation type',
      number_of_reservations: 'Number of reservations',
      number_of_visits: 'Number of Visits',
      no_data_warning: 'Can\'t download because accounting data does not exist',
      download_csv_modal: 'Download',
      no_data_to_export_csv: 'The data to be output does not exist.',
      daily_sale_report_label: 'Daily sales report',
      daily_sale_report_csv: 'Daily sales report CSV',
      total_report_label: 'Total report'
    },
    medical_page: {
      back: 'Back',
      btn_create_chart: 'Add record',
      tech_instruction_creation: 'Technical instruction creation',
      patient_info: 'Patient information',
      accounting_info: 'Accounts information',
      tech_instruction: 'Technical instruction',
      visit_date: 'Visit date',
      display_for_printing: 'Display for printing',
      btn_history: 'History',
      btn_edit: 'Edit',
      person_in_charge: 'Person in charge',
      main_complaint: 'Main complaint',
      remarks_column: 'Remarks column',
      treatment_detail: 'Treatment details',
      yen: 'yen',
      total: 'Total',
      back_to_list: 'Back to list',
      tap_to_add_item: 'Tap to add item',
      memo: 'Note',
      btn_register: 'Register',
      btn_save_draft: 'Save draft',
      accepted: 'Accepted',
      back_to_latest: 'Back to lastest record',
      edit_history: 'Editorial history',
      btn_display: 'Display',
      chart_print: 'Print',
      other_item: 'Other item',
      regenerate_accounting: 'Regenerate Accounting',
      regenerated_accounting: 'Accounting Regenerated',
      next_consultation_content: 'Next consultation content',
      previous_consultation_content: 'Previous consultation content',
      before_change: 'Before change',
      object: 'Object',
      assessment: 'Assessment',
      plan: 'Plan',
      other: 'Other',
      examiner: 'Examiner',
      add_charge: 'Add Charge',
      main_charge_dentist: 'Main charge Dr. (dentist)',
      main_charge_counselor: 'Counselor',
      please_select_dentist: 'Please select dentist who will be in charge of the medical record',
      please_select_person_in_charge: 'Please register the staff in charge other than the above',
      please_select_counselor: {
        first_line: 'Please select counselor'
      }
    },
    component: {
      hp_responsible: 'Person in charge',
      hp_visit_date: 'Visit date',
      hp_first_medical: 'First medical consultation',
      service_category: 'Service category',
      overall_service: 'Overall service',
      number_remaining: 'Number of remaining services',
      remaining_amount: 'Remaining service amount',
      yen: 'yen',
      dp_title: 'Select display period',
      dp_recent_specified: 'Recently specified',
      dp_specify_period: 'Specify period',
      dp_most_recent: 'Most recent from',
      dp_show_day: 'days',
      dp_start_date: 'Start date',
      dp_end_date: 'End date',
      dp_year: 'year',
      dp_month: 'month',
      dp_day: 'day',
      dp_btn_save: 'Save',
      dp_btn_reset: 'Reset',
      mrdl_patient_info: 'Medical chart information',
      mrdl_display_all: 'Display all',
      mrdl_draft: 'Draft'
    },
    accounting_page: {
      treatment_detail: 'Treatment details',
      service_category: 'Services category',
      overall_service: 'Overall services',
      number_added: 'Number added this time',
      provided: 'Provided this time',
      number_remaining: 'Number of remaining services',
      remaining_amount: 'Remaining service amount',
      yen: 'yen',
      previous_receivables: 'Previous receivables (overpayment)',
      billing_amount: 'Billing amount',
      sub_total: 'Sub total',
      discount_amount: 'Discount amount',
      deposit_amount: 'Deposit amount',
      unpaid_money: 'Unpaid money this time',
      remarks_column: 'Remarks column',
      service_management: 'Service management',
      payment_instrument: 'Payment instrument',
      payment_method: 'Please select a payment method',
      none: 'None',
      tap_to_add_item: 'Tap to add item',
      expiration_date: 'Expiration date',
      accounting_creator: 'Accounting creator',
      nodmr_invoice_can_not_print_detailed_invoice: 'Invoice without medical record can not print detailed invoice',
      invoice_printing: 'Invoice printing',
      with_detail: 'With detail',
      no_detail: 'No detail',
      show_today: 'Show today',
      show_this_month: 'Show this month'
    },
    placeholder: {
      patient_search: 'Search by Name / ID',
      patient_search_advance: 'Search conditions are set',
      input_text: 'Tap to input text',
      draw_illus: 'Tap to draw illustrations',
      enter_text: 'Input text',
      post_comment: 'Post a comment',
      tap_to_input: 'Tap to input',
      post_code: 'No hyphen is required',
      please_enter: 'Please enter',
      medical: {
        subject: 'Enter the chief complaint.',
        object: 'Enter your findings.',
        assessment: 'Enter your assessment.',
        plan: 'Enter your treatment plan.',
        other: 'Enter your other content'
      }
    },
    accounting: {
      person_in_charge: 'Person in charge',
      payment_type: 'Payment Type',
      treatment_type: 'Treatment Type',
      tax_amount: 'Tax amount',
      payment_info: 'Payment information',
      receipt_printing: 'Receipt printing',
      please_select_creditcard_type: 'Please select creditcard type',
      please_select_sale_credit_type: 'Please select sale credit type',
      last_overpaid_unpaid_amount: 'Last overpaid/unpaid amount',
      last_overpaid_amount: 'Last overpaid amount',
      last_unpaid_amount: 'Last unpaid amount',
      current_overpaid_unpaid_amount: 'Current overpaid/unpaid amount',
      current_overpaid_amount: 'Current overpaid amount',
      current_unpaid_amount: 'Current unpaid amount',
      credit_card: 'Credit card',
      sale_credit: 'Sale credit',
      medical_record_person_in_charge: 'Person in charge on medical record',
      person_in_charge_retired: 'Retired'
    },
    reservation: {
      reservation_canceled: 'Reservation canceled',
      reservation_created: 'Reservation created',
      reservation_updated: 'Changed the reservation details',
      reservations_already_exist: 'Reservations already exist',
      reservations_name_field_is_blanked: 'Name field is blanked',
      do_you_want_to_cancel_this_reservation: 'Do you want to delete the reservation?',
      do_you_really_want_to_cancel_this_reservation: 'Are you sure you want to delete the reservation?',
      conflict_with_blocked_frame: 'Conflict with blocked frame',
      clinic_name: 'Clinic name',
      number_of_new_slots_reserved: 'Number of new slots reserved',
      number_of_existing_slots_reserved: 'Number of existing slots reserved',
      new_frame_reception: 'New frame reception',
      reservation_setting: 'Reservation setting',
      spreadsheet: 'Spreadsheet',
      number_of_reservations_by_type: 'Number of reservations by type',
      new_customer_reception_status: 'New customer reception status',
      frame_moved: 'The frame has been moved',
      added_frame: 'Added frame',
      deleted_frame: 'The frame has been deleted',
      presets_cannot_be_changed: 'Presets cannot be changed.',
      please_select_content: 'Please select content',
      preset_items_cannot_be_changed: 'Preset items cannot be changed',
      reservation_register_type: 'Register reservation type',
      frame_auto: 'Auto',
      move_frame_guide: 'Where you want to go</br>Please click',
      reason_cancel: {
        inconvenient: 'Because it became inconvenient',
        troublesome: 'Because it became troublesome',
        poor_physical_condition: 'Due to poor physical condition',
        another_method: 'Because I decided on another correction method',
        other: 'Other',
        dont_correct: 'Because I decided not to do clean line correction',
        dont_satisfied_with_price: 'Because I am not satisfied with the price and treatment policy',
        want_to_reservation_at_another_hospital: 'Because I want to make a reservation at another hospital',
        moving: 'For moving / transfer',
        unauthorized_cancellation: 'Unauthorized cancellation'
      },
      search_title: 'Reservation search',
      time_start_to: 'Start date and time',
      no_search_result: 'The corresponding reservation information was not found',
      please_enter_duty_name: 'Please enter duty name',
      please_enter_display_name: 'Please enter the display name',
      please_enter_type_name: 'Please enter the type name',
      reservation_type_deleted: 'The reservation type has been deleted. <br> Please reload the screen and reselect the reservation type.',
      invalid_email: 'Email address is not a valid email address',
      warning_setting: 'Reservations exist outside business hours',
      content_warning: 'After changing the date and time of the following reservations or changing the business day setting',
      check_warning: 'Please try again.',
      allocation: {
        not_found: 'Not found Allocation',
        title: 'Allocation',
        name: 'Frame name',
        position: 'Position',
        placeholder: 'Please enter the frame name',
        auto_check: 'Automatic reservation frame',
        set_auto_check: 'Set to automatic reservation frame',
        text: 'When selecting anything other than \'Not set,\' it will become the target of the corresponding automatic reservation',
        button: {
          create: 'Close',
          cancel: 'Cancel'
        },
        errors: {
          maximum: 'Please enter an %{max}-digit %{field}',
          required: 'Please enter the %{field}'
        }
      },
      reservation_note_placeholder: 'Please enter a note',
      add_reservation_type: 'Add reservation type',
      select_patient: 'Select a patient',
      reservation_status: 'Reservation status',
      first_visit_date: 'First visit date',
      select_person_charge: 'Select a person in charge',
      add_new_frame: 'Add a new frame',
      cancel_reservation: 'Cancel reservation',
      auto_notification: 'Reservation information has been updated'
    },
    reservation_type: {
      msg_confirm_delete: 'Do you want to remove the tab {active_tab_name}？',
      errors: {
        reservation_type_using: 'This reservation type is already in use and cannot be deleted.'
      }
    },
    sweet_alert: {
      something_went_wrong: 'Something went wrong',
      available_date_warning: 'Available dates are one year',
      before_release_date_warning: 'Date before service provided cannot be selected',
      saved: 'Saved',
      support_chrome_only: 'This function is not available in your browser, please use Google Chrome.',
      has_input_interview_sheet: 'Has input interview sheet'
    },
    staff: {
      retiree: 'Retiree',
      add_new_staff: 'Add new staff',
      full_name: 'Full name',
      surname: 'Surname',
      roman_name: 'Roman name',
      position: 'Position',
      please_select_a_position: 'Please select a position',
      administrator_rights: 'Administrator rights',
      staff_information: 'Staff information',
      name: 'name',

      login_information: 'Login information',
      staff_id: 'Staff ID',
      please_enter_staff_id: 'Please enter staff ID',
      please_enter_alphanumeric_characters: 'Please enter alphanumeric characters',
      please_enter_alphanumeric_characters_and_symbols_only_at_dot: 'Please enter alphanumeric characters (allow ".",  "@")',
      email_address_optional: 'Email address (optional)',
      please_enter_your_email_address: 'Please enter your e-mail address',
      send_invitation_email: 'Send invitation email',
      initial_password: 'Initial password',
      password: 'Password',
      enter_your_initial_password: 'Enter your initial password',
      enter_your_new_password: 'Enter your new password',
      please_enter_at_least_8_alphanumeric_characters: 'Please enter at least 8 alphanumeric characters',
      cancel: 'Cancel',
      to_add: 'to add',
      none: 'None',
      yes: 'Yes',
      please_enter_surname_of_full_name: 'Please enter surname of full_name',
      please_enter_firstname_of_full_name: 'Please enter firstname of full_name',
      please_enter_surname_of_roma_name: 'Please ente surname of romaji name',
      please_enter_firstname_of_roma_name: 'Please enter firstname of romaji name',
      please_enter_valid_staff_id: 'Please enter a valid staff ID',
      please_enter_valid_email: 'Please enter a valid email',
      please_enter_valid_password: 'Please enter a valid password',

      new_staff_addition_completed: 'New staff addition completed',
      the_following_staff_members_have_been_added: 'The following staff members have been added',
      the_following_login_information_is_displayed_only_once: 'The following login information is displayed only once.',
      please_copy_and_tell: 'Please copy and tell',
      login_id: 'Login ID',
      login_url: 'Login URL',
      copy_to_clipboard: 'Copy to clipboard',
      copied_to_clipboard: 'Copied to clipboard',
      account_information: 'Account Information',
      close: 'Close',
      login_information_is: 'Login information is',
      sent_to_email: 'Sent to {email}',
      please_contact_us_to_check_your_mailbox_please_copy_and_tell: 'Please contact us to check your mailbox. Please copy and tell',
      editing_staff_information: 'Editing staff information',
      revoke: 'revoke',
      change: 'change',
      do_you_really_want_to_delete_this: 'Do you really want to delete this',
      delete: 'delete',
      deleted_staff: 'staff was deleted',
      staff_info_updated: 'staff information was updated'
    },
    white_list_page: {
      status: 'Status',
      ip: 'IP',
      label: 'Label',
      staff_id: 'Staff ID',
      setting: 'Setting',
      edit: 'Edit',
      save: 'Save',
      addIpAddress: 'Add IP address',
      invalid_character_label: 'Only Japanese characters, alphanumeric characters and symbols -_ can be entered',
      long_label: 'You can enter up to 50 characters',
      invalid_character_ip_address: 'Only half-width alphanumeric characters and symbols . can be entered for the IP address',
      invalid_ip_address: 'Please enter the IP address in the range of 0.0.0.0 to 255.255.255.255',
      label_placeholder: 'Network IP in clinic',
      ip_exist: 'IP address already exists.'
    },
    activity_log: {
      medical_record_created: 'Medical record #{id} created',
      medical_record_updated: 'Medical record #{id} has been updated',
      medical_record_deleted: 'Medical record #{id} has been deleted',
      comment_medical_record_posted: 'A comment was posted to the chart #{id}',

      invoice_issued: 'Patient accounting has been issued',
      invoice_reissued: 'Patient accounting reissued',
      invoice_completed: 'Patient accounting completed',
      invoice_deleted: 'Patient accounting deleted',
      invoice_updated: 'Patient accounting has been updated',

      patient_updated: 'Patient information updated',
      patient_checked_in: 'I checked in',
      patient_checked_in_cancel: 'I cancel checked in',
      patient_delete: 'Patient information deleted',
      patient_recover: 'The deletion of patient information has been cancelled',

      reservation_accepted: 'New booking accepted',
      reservation_schedule_changed: 'New booking schedule changed',
      reservation_cancelled: 'New booking canceled',

      lab_instruction_created: 'Technical instruction #{id} has been created',
      lab_instruction_status_changed: 'Lab Instruction #{id} status updated to {status}',

      patient_action: 'Patient action',
      note: 'Note',
      activity_log: 'Activity log',
      notes_only: 'Notes only',
      send: 'Send',
      delete: 'Delete',
      visit_reception: 'Visit reception',
      visit_reception_note: 'Clinic visit reception',
      visit_reception_cancel: 'Cancellation of visit reception',
      visit_reception_accepted: 'Visit accepted',
      reservation_schedule_adjustment: 'Reservation schedule adjustment',
      reservation_schedule_adjustment_note: 'Setting the date and time of the next visit',
      chart_creation: 'Chart creation',
      chart_creation_note: 'Creating a new chart',
      accounting: 'Accounting',
      accounting_note: 'Accounting',
      all: 'All',
      load_more: 'Show the next 20',
      memo_deleted: 'Deleted memo',
      visit_reception_cancel_text: 'Cancel the visit reception',
      visit_reception_cancel_sub_text: 'Would you like to return to the state before your visit?',
      visit_reception_cancel_confirm_button: 'Cancel reception'
    },
    notification_page: {
      title: 'Notification',
      notification: 'notification',
      mr: 'Mr',
      display_patient_information: 'Display patient information',
      show_reservation: 'Show reservation',
      reservation_date_and_time: 'Reservation date and time',
      notice_will_be_deleted_in_30_days: 'Notice will be deleted in 30 days',
      mark_all_as_read: 'Mark all as read',
      currently_no_notifications: 'Currently no notifications',
      makes_new_reservation: 'When a patient makes a new reservation, changes or cancels a reservation, <br> you will be notified.'
    },
    interview: {
      basic_info: 'Patient information',
      patient_name: 'Patient name',
      patient_romaji: 'Romaji',
      patient_furigana: 'Furigana',
      birthday: 'Birthday',
      occupation: 'Occupation',
      age: 'Age',
      sex: 'Sex',
      currently_pregnant: 'Currently Pregnant',
      not_pregnant: 'Not Pregnant',
      address_contact: 'Address/Contact',
      street_address: 'Street address',
      phone_number: 'Phone number',
      mail_address: 'Mail Address',
      minors_info: 'Parent information (minors)',
      guardian_info: 'Guardian information',
      guardian2_desc: '※Please enter the information of another guardian',
      guardian_name: 'Guardian name',
      contact_info: 'Contact information',
      relationship: 'Relationship',
      other_address: 'Address * Fill in if your residence is different',
      consultation_title: 'Consultation',
      consultation_content: 'Consultation content',
      places_of_concern_symptoms: 'Places of concern/symptoms',
      priority_1: 'Priority 1',
      priority_2: 'Priority 2',
      priority_3: 'Priority 3',
      location: 'Location',
      symptom: 'Symptom',
      dentition_number: 'Dentition number',
      specific_consultation: 'Specific consultation content regarding the part/symptom of concern',
      when: 'When',
      relocation: 'Relocation',
      prior_consultation: 'Prior consultation',
      other_consultation: 'Other',
      clinic_name: 'Clinic name',
      straightening_device: 'Straightening device',
      diagnosis: 'Diagnosis',
      daily_dose: 'Daily dose',
      highest: 'Highest',
      lowest: 'Lowest',
      treatment: 'Treatment',
      height: 'Height',
      weight: 'Weight',
      blood_type: 'Blood type',
      currently_hospital: 'Currently in hospital',
      priority_number: 'Priority {number}',
      today: 'Today',
      modal_confirm_save: {
        title: 'Save the questionnaire',
        content_1: 'If you save it, you will not be able to edit it again.',
        content_2: 'Is it OK.'
      },
      kid_learning_sport: 'Learning - Sport',
      guardian_occupation: 'Guardian occupation',
      symptoms_type: {
        mouth: '※If the symptoms are strong, it may not be indicated.',
        gummy: '※Ideally, the gums of the upper jaw should not be visible when smiling.',
        logo: '※In the field of orthodontics, it is often said that the mouth is protruding, such as maxillary prognathism, maxillary prognathism, and mandibular retraction.\n' +
          '※There is no such expression in orthodontics.\n' +
          '※If the symptoms are strong, it may not be indicated.'
      }
    },
    treatment: {
      name_placeholder: 'Please enter the action name',
      confirm_delete_tile: 'Are you sure you want to delete the action? ',
      confirm_delete_msg: 'The treatment already described in the chart will remain, but \n you will not be able to select a new one',
      confirm_update_title: 'Do you want to change the action? ',
      confirm_update_msg: 'The action already described in the chart will also be changed, \n Are you sure you want to make the change?'
    },
    prints: {
      toggle_button: 'Print',
      calendar: 'Print calendar'
    },
    orders: {
      search: {
        label: {
          order_date: 'Order date',
          patient_id: 'patient ID',
          clinic_name: 'Clinic name',
          order_id: 'Order ID',
          product_id: 'Course Name',
          count: 'Count',
          patient_name: 'Patient name',
          intended_date_send: 'Intended date send',
          status: 'Order status',
          code: 'Code',
          worker_setup_id: 'Setup staff',
          worker_presscut_id: 'Press cut person in charge',
          lab_name: 'Laboratory name',
          unprinted: 'Unprinted'
        },
        placeholder: {
          please_select: 'Please select',
          patient_id: 'Search by patient ID',
          order_id: 'Search by order ID',
          count: 'Search by number of times',
          patient_name: 'Search by patient name',
          code: 'Search by g number'
        },
        option: {
          status: {
            clinic_confirmation: 'Clinic confirmation required',
            check_before_order: 'Checking before ordering',
            during_setup_design: 'During setup design',
            preparing_to_ship: 'Preparing to ship',
            shipment_completed: 'Shipment completed',
            cancel: 'Cancel',
            delivery_completed: 'Delivery completed'
          }
        },
        button: {
          reset: 'Reset'
        }
      },
      list: {
        item: {
          order_id: 'Order ID',
          created_at: 'Order day',
          order_product_name: 'Course name',
          order_product_number: 'Number of times',
          patient_name: 'Patient name',
          patient_id: 'Patient ID',
          status: 'Status',
          clinic_name: 'Clinic',
          order_shipping_date: 'Shipping date',
          order_delivery_date: 'Delivery date',
          g_bangou: 'G Number',
          worker_setup: 'Worker setup',
          worker_presscut: 'Worker presscut',
          lab_name: 'Lab name'
        },
        print_status: {
          unprinted: 'Unprinted',
          printed: 'Printed'
        },
        status: {
          confirmation: 'Clinic confirm',
          checking: 'Check before order',
          setup_design: 'Setup design',
          prepare: 'Prepare ship',
          shipped: 'Ship complete'
        },
        message: {
          no_result_search: 'There is no data that matches the conditions'
        }
      }
    }
  },
  ja: {
    objects: objectsJa,
    new_validation: validationJa,
    new_message: messageJa,
    new_text: textJa,
    common: {
      cancel: 'キャンセル',
      cancel2: '取消す',
      yes: 'はい',
      no: 'いいえ',
      delete: '削除',
      deleted: '削除済',
      cancel_reservation: '取り消す',
      download: 'ダウンロード',
      output: '出力',
      upload: 'アップロード',
      apply_change: '変更を適用',
      first: '第',
      week: '週',
      minute: '分',
      color: '色',
      selection: '選択',
      save: '保存',
      save2: '保存する',
      accepted: '受付済み',
      update: '更新',
      date_time: '日時',
      name: '名前',
      responsible: '担当',
      note: 'メモ',
      block: 'ブロック',
      read_more: 'さらに読み込む',
      comment: 'コメント',
      send: '送信',
      close: '閉じる',
      display: '表示',
      search: '検索',
      ID: 'ID',
      undo_deletion: '削除を取り消す',
      not_yet_entered: '未入力',
      required: '必須',
      move: '移動する',
      no_data: 'データなし',
      decision: '決定',
      today: '今日'
    },
    message: {
      already_booked: 'すでに予約が入っています',
      block_cancelled: 'ブロック枠を解除しました',
      block_created: 'ブロック枠を作成しました',
      block_removal_confirmation: 'ブロック枠を解除しますか？',
      block_updated: 'メモを更新しました',
      booking_reservation_completed: '予約が完了しました',
      confirmation_delete: '削除しますか？',
      csv_malformed: '不正なフォーマットのcsvです',
      comment_deleted: 'コメントは削除されました',
      comment_deleted_success: 'コメントを削除しました',
      comment_removal_confirmation: 'コメントを削除しますか？',
      error_occurred: 'エラーが発生しました！',
      has_been_deleted: '削除しました',
      has_been_updated: '更新しました',
      has_registered: '登録しました',
      loading: '読み込んでいます｡｡｡',
      no_more_patient: 'もう患者がいません',
      no_history_of_sending_sms: 'SMSの送信履歴はありません',
      patient_not_found: '患者が見つかりません',
      please_select_patient: '患者を選択してください',
      reservable_slots_reflected: '予約可能枠が反映されました',
      reservation_canceled: '予約を取り消しました',
      reservation_created: '予約を作成しました',
      reservation_not_reflected_in_frame: '予約可能枠が反映されました',
      reservation_updated: '予約内容を変更しました',
      reservation_updated_exist_time: '予約が重複しているため予約日時の変更を行ってください\n {dateTime}',
      reservation_updated_day_off: '予約の移動先が休診日に設定されています。\n他の日付を選択するか、休診日の設定を変更してください',
      reservation_confirm_update: '{dateTime} に予約を変更しますか？',
      block_frame_confirm_update: '{dateTime} にブロック時間を変更しますか？',
      reservation_confirm_continue_move_mode: '予約変更を続けますか？',
      block_frame_confirm_continue_move_mode: 'ブロック枠変更を続けますか？',
      reservation_confirm_continue_move_mode_trigger_by_action: '印刷を行うためには予約変更を終了する必要があります。\n予約変更を続けますか？',
      saved: '保存しました',
      something_went_wrong: '予期せぬエラーが発生しました。\n' +
        '少し時間を空けてもう一度操作を行ってください。\n' +
        '繰返し発生する場合はサポートセンターにお問い合わせください',
      phone_number_empty: '電話番号が登録されていません。\n問診票は患者様の手書き記入となります。',
      phone_number_not_available: 'SMSが送信できない電話番号が登録されています。\n問診票は患者様の手書き記入となります。',
      sms_can_be_sent_if_reservation_exist: 'SMSは予約情報を登録した後に送信できます。',
      sms_failed: 'SMSの送信に失敗しました',
      sms_sending_confirmation: ':tel に\nSMSが送信されますがよろしいですか？',
      sms_sent: 'SMSの送信が完了しました',
      password_empty: 'パスワードを入力してください',
      undo_deletion_patient_exist_error: '同じメールアドレスと電話番号を使用している患者情報が存在しているため削除の取り消しが行なえません。\n削除の取り消しが必要な場合はメールアドレスと電話番号が重複している患者情報を変更してください。',
      complete_the_visit_reception_note: 'クリックすると来院受付が完了します',
      reservation_confirm_message_title: '時間枠を移動します。</br>よろしいでしょうか？',
      block_time_confirm_message_move: 'ブロック枠を移動しますよろしいでしょうか？',
      block_time_confirm_message_change: 'ブロック枠を変更しますよろしいでしょうか？',
      allocation_can_not_delete_all: '全ての予約枠を削除することはできません',
      no_data_to_export_csv: '出力対象データが存在しません。'
    },
    text: {
      add_charge: '担当を追加',
      alias: '種別名',
      before_reception: '受付前',
      before_reception_title: '受付 - 受付前',
      block_frame_editing: 'ブロック枠編集',
      block_frame_label: 'この枠をブロックする',
      business_days_setting: '営業日設定',
      cancel_reservation: '予約キャンセル',
      cancellation_notification: '初診予約をキャンセルした場合、患者ステータスはキャンセル状態になります',
      cancellation_reason: 'キャンセル理由を選択して下さい',
      reason_for_cancellation: 'キャンセル理由',
      cancellation_reason_unauthorized: '無断キャンセル',
      cancellation_reason_from_patient: '患者からの予約キャンセル',
      cancellation_reason_from_duplicated: '他院への変更、登録の重複',
      cancellation_note_placeholder: 'その他の場合ご記入ください',
      chart_creation: 'カルテ作成',
      create_notes_to_activity_log: 'メモをアクティビティログに投稿できます',
      csv_contribution: 'CSV出力',
      csv_reservation_importing: '予約可能枠情報CSV生成・読込',
      csv_template_setting: '設定用テンプレートCSV',
      csv_upload_for_setting: '設定用CSVアップロード',
      datetime_setting: '予約日時設定',
      display_name: '表示名',
      duty_in_charge: '担当職務',
      edit_current_tab: '現在表示しているタブを編集',
      edit_reservation: '予約編集',
      edit_tab: 'タブの編集',
      job_master_setting: '職務マスタ設定',
      job_title: '職務名',
      long_term_leave_setting: '長期休業設定',
      new_reservation: '新規予約',
      no_reservation: 'まだ来院予約がありません',
      patient_details: '患者の詳細',
      patient_status: {
        status_0: '新規申し込み',
        status_1: '受付前',
        status_2: '受付済',
        status_3: '会計前',
        status_4: '会計済'
      },
      periodic_block_setting: '定期ブロック設定',
      periodic_week_setting: '0を入力すると、毎週の設定となります',
      please_enter: '入力してください',
      please_select_date: '日付を選択ください',
      receive_time: '受付時刻',
      register_reservation: '予約を登録',
      reservable_limit: '最大予約日数',
      reservation_type: '予約種別',
      reservation_type_master_setting: '予約種別マスタ設定',
      reservation_type_registration: '予約種別登録',
      select_patient_placeholder: '患者を選択してください',
      select_reservation_type_placeholder: '予約種別を選択してください',
      tap_to_add_item: 'クリックで項目追加',
      type_setting: '予約種別設定',
      time_required: '所要時間',
      unblock: 'ブロック枠解除',
      update_memo: 'メモを更新',
      update_reservation: '予約を更新',
      send_sms: 'SMSを送信する',
      enter_questionnaire: '問診票を直接入力する',
      send_sms_title: '事前問診票案内SMS送信',
      questionnaire: '問診票',
      not_entered: '未入力',
      questionnaire_printing: '問診表印刷',
      sms_history: 'SMS送信履歴',
      reservation_date: '予約日時',
      phone_number: '電話番号',
      transmission_history: '送信履歴',
      sms_sending_confirmation_title: 'SMSを送信します',
      patient_interview_created: '問診票が作成されました',
      patient_interview_completed: '問診票が完了しました',
      author: '作成者',
      correspondent: '対応者',
      interview_status: {
        0: '問診票未入力',
        1: '問診票入力済み'
      },
      sms_history_status: {
        0: '送信失敗',
        1: '送信成功'
      },
      deleted_time: '削除日時',
      move_mode_reservation_popup_title: '予約変更',
      move_mode_block_frame_popup_title: 'ブロック枠変更',
      block_frame_changed_successfully: 'ブロック枠を変更しました',
      block_frame_moved_successfully: 'ブロック枠を移動しました',
      sms_delivery_setting: 'SMS配信設定',
      reservation_date_change: '変更日時',
      changed: '変更'
    },
    validation: {
      attributes: {
        sex: '性別',
        birthday: '生年月日',
        phone_number: '電話番号',
        contact_information: 'ご連絡先',
        keyword_searching: '検索キーワード'
      },
      required: '{attribute}を入力してください',
      numeric: ':attributeには、数字を指定してください。',
      between: ':attributeには、:minから、:maxまでの数字を指定してください。',
      clinic: {
        formal_name_required: 'クリニック名を入力してください',
        formal_name_format: 'クリニック名は50文字まで入力できます',
        email_required: 'メールアドレスを入力してください',
        email_format: 'メールアドレスは有効なメールアドレスではありません',
        phone_only_number: '電話番号は数字のみ使用できます',
        phone_format: '電話番号は10文字以上でなければなりません',
        phone_required: '電話番号を入力してください'
      },
      interview: {
        last_name_required: 'お名前を入力してください',
        first_name_required: 'お名前を入力してください',
        romaji_required: 'お名前(ローマ字表記)を入力してください',
        romaji_haffsize: '半角英字を入力してください',
        kana_only: 'ひらがなで入力してください',
        kana_required: 'ふりがなを入力してください',
        required_email: 'メールアドレスは必須項目です',
        invalid_email: 'メールアドレスは有効なメールアドレスではありません',
        zipcode_required: '郵便番号は7桁の半角数字で入力してください',
        zipcode_error: '郵便番号は7桁の半角数字で入力してください',
        select_city: '市区町村を入力してください',
        locality: '番地・以降の住所を入力してください',
        empty_street: '番地・以降の住所を入力してください',
        empty_address: '住所を入力してください',
        phone_only_number: '電話番号は数字のみ使用できます',
        phone_only_single_number: '半角数字のみ入力してください',
        invalid_phone: '電話番号は10文字以上でなければなりません',
        invalid_profession: 'ご職業を選択してください',
        maxlength: '使用できる文字数制限',
        question: '回答が必要です',
        basic_information: '患者情報の必須項目を入力してください',
        content_consultation: '相談内容の必須項目を入力してください',
        prior_consultation: '事前問診の必須項目を入力してください',
        other_consultation: 'その他の必須項目を入力してください',
        relationship_required: '続柄は必ず入力してください',
        contact_info_required: 'ご連絡先は必ず入力してください',
        address_required: 'ご住所は必ず入力してください。'
      },
      patient: {
        phone_number_required: '電話番号を入力してください',
        birthdate_required: '正しい生年月日を選択してください'
      },
      reservation: {
        cancelled_reason_required: 'キャンセル理由を選択して下さい',
        cancelled_reason_other_detail_required: 'その他のキャンセル理由を入力して下さい'
      },
      allocation: {
        empty_data: 'データを入力してください',
        column_name_required: '枠名が空欄です',
        order_required: '位置を入力してください',
        order_integer: '位置は整数でなければなりません'
      }
    },
    sidebar_menu: {
      reservation_calendar: '予約カレンダー',
      reserve: '予約',
      recept: '受付',
      patient: '患者',
      accounting: '会計',
      order: '発注',
      japanese: '日本語',
      english: '英語',
      log_out: 'ログアウト',
      change_language: '表示言語の切り替え',
      preferences: '環境設定',
      help: 'サポートサイトを表示',
      clinic_name: '{name}クリニック'
    },
    order: {
      sidebar: {
        instructions: '発注',
        search: '注文/患者検索',
        confirmation: 'クリニック要確認',
        checking: '受注前確認中',
        setup: 'セットアップデザイン',
        prepare_ship: '発送準備中',
        shipment_completed: '発送完了',
        delivery_completed: '納品完了',
        cancel: 'キャンセル',
        draft: '下書き保存中の指示書'
      }
    },
    main: {
      back_to_index: '一覧に戻る',
      acepted: '受付済',
      create_chart: 'カルテ作成',
      create_technical_instruction: '技工指示書作成',
      history: '履歴',
      edit: '編集',
      visiting_reception: '来院受付',
      visiting_reception_comment: 'タップすると来院受付が完了します',
      patient_detais: '患者詳細',
      account_information: '会計情報',
      medical_chart_information: 'カルテ情報',
      show_all: 'すべて表示',
      responsible: '担当',
      visit_schedule_printing: '来院予定印刷',
      visit_date: '来院日時',
      service_category: '役務カテゴリ',
      overall_service: '役務全体',
      number_remaining_service: '残役務数',
      remaining_service_amount: '残役務金額',
      basic_information: '基本情報',
      patient_name: '患者名',
      romaji: 'ローマ字',
      sex: '性別',
      reading: 'フリガナ',
      birthday: '生年月日',
      contact_address_information: '連絡先・住所情報',
      street_address: '住所',
      phone_number: '電話番号',
      mail_address: 'メールアドレス',
      profession: '職業',
      contact_information: '担当情報',
      dentist: '歯科医師',
      conselor: 'コーディネーター',
      dental_hygienist: '歯科衛生士',
      dental_assistant: '歯科助手',
      other_remarks: 'その他備考',
      service_management: '役務管理',
      no_patient_search: '検索された患者は見つかりませんでした',
      system_error_occurred: 'システムエラーが発生しました',
      accounting_creation: '会計作成',
      visit_schedule: '来院スケジュール'
    },
    invoice: {
      invalid_type_of_value: '不正な値が入力されています',
      blank_number: '数値を入力してください',
      no_payment_choice: '決済手段が選択されていません',
      none_for_0_yen: '0円の場合は「なし」を選択してください',
      exceeded_number_of_characters: '備考欄は1000文字以内で入力してください',
      rest_service_less_than_0: '残役務数は0未満にはなりません',
      service_total_amount_blank: '役務全体を入力してください'
    },
    settings: {
      tap_to_create_new: 'タップで新規作成',
      resend_email_success: 'メールを送信しました',
      resend_email_failed: '失敗した',
      resend_reset_password_success: '成功した',
      resend_reset_password_failed: '失敗した',
      search_by_staff_name_id: 'スタッフ名・IDで検索',
      maximum_reservation_day: '最大予約日数は10〜365の間で設定してください',
      reservation_duplicate_time_zone: '時間帯設定が重複しています',
      invalid_input: '時刻のフォーマットが正しくありません',
      invalid_input_2: '不正な入力です',
      overlapped_block_time: '指定の時間帯にブロック枠を含んでいます',
      periodic_require_week: '0〜5で週を入力してください',
      periodic_require_day_of_week: '曜日を選択してください',
      not_have_any_staff: 'スタッフ情報が見つかりませんでした'
    },
    datetime: {
      year_month_day: 'YYYY年MM月DD日',
      start_date: '開始日',
      end_date: '終了日',
      year_month_day_hour_minute: 'YYYY年MM月DD日 HH:mm',
      year_month_day_hour_minute_str: 'YYYY年MM月DD日(dd) HH:mm',
      month_day: 'MM月DD日',
      hour_minute: 'HH:mm'
    },
    patient: {
      current_status_0: '新規申し込み',
      current_status_1: '受付前',
      current_status_2: '受付済',
      current_status_3: '会計前',
      current_status_4: '会計済',
      patient_name_required: '患者名は入力必須です',
      patient_name_invalid: '名前に記号は使用できません',
      patient_last_name_required: '姓を入力してください',
      patient_first_name_required: '名を入力してください',
      first_symptom: '初回主訴',
      zipcode_greater_than_7: '郵便番号は7桁で入力してください',
      zipcode_invalid: '有効な郵便番号ではありません',
      region_required: '都道府県を選択してください',
      locality_required: '市区町村を入力してください',
      street_required: '番地・以降の住所を入力してください',
      patient_kata_last_name_required: 'ふりがな姓を入力してください',
      patient_kata_first_name_required: 'ふりがな名を入力してください',
      please_enter_last_name_hiragana: '(ふりがな姓)ひらがなで入力ください',
      please_enter_first_name_hiragana: '(ふりがな名)ひらがなで入力ください',
      phone_number_invalid: '電話番号は有効ではありません',
      sf_status_c: [
        {
          key: 0,
          value: 'キャンセル'
        },
        {
          key: 1,
          value: '来店待ち'
        },
        {
          key: 2,
          value: '新規申し込み'
        },
        {
          key: 3,
          value: '日程再調整中'
        },
        {
          key: 4,
          value: '来店済み'
        },
        {
          key: 5,
          value: 'クリニック既存患者'
        },
        {
          key: 6,
          value: '他矯正への切り替え'
        },
        {
          key: 9,
          value: '削除済'
        }
      ],
      salesforce_status_c: {
        0: '初回キャンセル',
        1: '初回来院予定',
        2: '新規申し込み',
        3: '日程再調整中',
        4: '初回来院済み',
        5: 'クリニック既存患者',
        6: '他矯正への切り替え',
        9: '削除済'
      },
      cancellation_time: 'キャンセル日時',
      cancellation_first_time: '初回キャンセル',
      first_visit_time: '初回来院日',
      search_header: '「:string」で検索',
      please_reload_while_getting_ID: 'Salesforceに問い合わせ中。リロードしてください',
      btn_search_detail: '詳細検索',
      btn_clear_search_detail: '検索クリア',
      sorting: '並べ替え',
      sort: {
        'id-asc': '患者ID昇順',
        'id-desc': '患者ID降順',
        'created_at-asc': '登録日昇順',
        'created_at-desc': '登録日降順',
        'started_to-asc': '初診予約日昇順',
        'started_to-desc': '初診予約日降順'
      },
      modal: {
        title: '患者検索',
        place_holder_search_name: '漢字・ふりがなで入力ください',
        search_info: '患者情報',
        search_reservation_info: '契約情報',
        range_datetime_reservation: '期間指定',
        condition_reservation: '条件',
        age: '歳',
        from: 'から',
        select_treatment_placeholder: '処置内容を選択ください',
        gender_options: {
          no_select: '選択なし',
          male: '男性',
          female: '女性'
        },
        reservation_condition: {
          first_visit: '初診',
          re_examination: '再診'
        },
        treatment_detail: '処置内容',
        invalid_data_reservation: '終了日は開始日より後の日付を設定してください',
        btn_show_treatment: '選択',
        search_no_result: '条件に一致する患者情報が見つかりませんでした'
      },
      title_delete_modal: '患者情報を削除します',
      description_delete_modal: '患者情報の削除後はカルテや会計情報の修正は行えなくなります。\nまた、これまでの予約レコードは全て削除され、元には戻せません。\n削除したい場合は、患者情報削除より前に操作を完了させてください。',
      note_delete_modal: '削除を実行するには、ログインパスワードを入力ください',
      note_cancel_delete: '患者情報の削除を取り消しますか？'
    },
    medicalrecord: {
      staffs_column_requires_to_be_fully_filled: '担当者はすべて埋めてください',
      sentence_on_main_conplaint_is_required: '主訴-文章は入力必須です',
      you_must_enter_fees: '料金を入力してください',
      please_enter_of_all_treatments: '処置名を入力してください',
      you_enter_invalid_inputs: '不正な値が入力されています',
      treatment_name: '処置名',
      treatment_number_of_set: 'セット数',
      treatment_unit_price: '単価(税抜)',
      treatment_total_not_tax: '合計(税抜)',
      treatment_total_include_tax: '合計(税込)',
      treatment_content_not_set: '処置内容が設定されていません',
      you_must_enter_next_consultation: '次回診療内容を入力してください',
      save_medical_record: 'カルテ保存',
      save_draft_medical_record: '下書き保存',
      create_medical_record: 'カルテの作成を行います。',
      create_draft_medical_record: 'カルテの下書き保存をします。',
      enter_your_password: 'ご自身のパスワードを入力ください',
      enter_your_password_draft_modal: 'ご自身のパスワードを入力してください。',
      password: 'パスワード',
      password_is_incorrect: 'パスワードが間違っています',
      author: '作成者',
      treatment_number_select_bigger_service_remain: '選択した施術の数が残りの役務数を超えています',
      digested_services: '以下の役務が消化されます'
    },
    page_title: {
      u1_1: 'ログイン',
      u1_2: 'パスワード再設定',
      u1_3: '再設定メール送信完了',
      u1_4: 'パスワード再設定',
      u1_5: 'パスワード再設定完了',
      u1_6: 'ユーザー新規登録',
      u2_1a: '受付 - すべて表示',
      u2_1b: '受付 - 来院前',
      u2_1c: '受付 - 受付済',
      u2_1d: '受付 - 会計前',
      u2_1e: '受付 - 会計済',
      u7_1: '患者検索',
      u3_1: '患者詳細',
      u3_2: '患者詳細編集',
      u3_3: '患者詳細編集履歴閲覧',
      u3_4: '患者詳細編集履歴一覧',
      u3_5: '患者登録',
      u4_1: 'カルテ一覧',
      u4_2: 'カルテ(単一表示)',
      u4_3: 'カルテ新規登録',
      u4_5: 'カルテ編集',
      u4_6: 'カルテ編集履歴一覧',
      u6_1: '会計一覧',
      u6_2: '会計入力',
      u6_3: '会計済み',
      u8_1: '会計',
      u8_2: '予約カレンダー',
      s2_1: '設定 - アカウントリスト',
      s2_2: '設定 - IPアドレス制限',
      s2_3: '設定 - クリニック',
      s3_1: '設定 - 役務管理マスタ一覧',
      s3_2: '設定 - 院独自処置',
      s3_3: '設定 - 処置内容マスタ一覧',
      s4_1: '設定 - 予約日時設定',
      s5_1: '設定 - 予約種別設定',
      u9_1: 'お知らせ',
      patient_deleted: '設定 - 削除済患者情報'
    },
    login_page: {
      input_characters: '半角英数字8文字以上',
      forgot_pass: 'パスワードを忘れた場合はこちら',
      staff_id: 'スタッフID',
      please_enter_staff_id: 'スタッフIDを入力してください',
      forgotten_staff_id: 'スタッフIDをお忘れの場合、</br>クリニック内管理者に</br>お問い合わせください',
      password_placeholder: 'パスワードを入力してください',
      failed: 'ログイン情報に誤りがあります',
      account_is_deleted: 'アカウントは停止されています',
      account_is_deactivated: 'アカウントは無効化されています'
    },
    reset_password_complete: {
      text: 'パスワードの再設定が完了しました。'
    },
    reset_password: {
      token_expired: 'パスワード再設定リンクが期限切れとなりました。\n再度、パスワード再設定依頼を行ってください。',
      btn_reset: 'パスワードリセット'
    },
    common_page: {
      email: 'メールアドレス',
      password: 'パスワード',
      confirm_password: 'パスワード(再入力)',
      input_characters: '半角英数字8文字以上',
      btn_login: 'ログイン',
      chart_info: 'カルテ情報',
      please_change_your_password: 'パスワードを変更ください',
      button_settings: '設定',
      log_out: 'ログアウト'
    },
    email_forgot_password: {
      text_1: 'パスワード再設定メールを送信しました。',
      text_2: 'メールボックスをご確認ください。',
      text_3: 'メール内のリンクより再度ログインお願いいたします。'
    },
    register_page: {
      company_name: '株式会社ネオラボ',
      account_name: 'アカウント名',
      btn_check_text_terms: '利用規約およびプライバシーポリシーに同意します',
      btn_add: 'アカウント作成'
    },
    reception_page: {
      recept: '受付',
      register_patient: '患者登録',
      show_all: 'すべて表示',
      before_visit: '受付前',
      accepted: '受付済',
      before_accounting: '会計前',
      accounted_patient: '会計済',
      accepted_1: '受付済',
      date_text: '表示期間を選択',
      date_start: '開始日',
      btn_save: '保存',
      btn_reset: 'リセット',
      date_year: '年',
      date_month: '月',
      date_day: '日',
      influencer: 'インフルエンサー',
      recept_time: '受付時刻',
      billing_amount: '今回請求額',
      payment_time: '今回支払額',
      note: 'メモ',
      first_visit_patient: '初診患者',
      price_unit: '円'
    },
    patient_page: {
      back_to_list: '一覧に戻る',
      btn_create_chart: 'カルテ作成',
      patient_info: '患者情報',
      system_info: 'システム情報',
      quick_booking: 'クイック予約登録',
      created_at: '登録日',
      updated_at: '最終更新日',
      patient_activity_log: 'アクティビティ',
      reservation_schedule: '予約日程',
      new_reservation: '新規予約',
      accounting_info: '会計情報',
      basic_info: '基本情報',
      name: '患者名',
      romaji: 'ローマ字',
      sex: '性別',
      furigana: 'ふりがな',
      birthday: '生年月日',
      contact_info: '連絡先・住所情報',
      address: '住所',
      phone_number: '電話番号',
      email: 'メールアドレス',
      assignee_info: '担当情報',
      other_notes: 'その他備考',
      influencer: 'インフルエンサー',
      btn_history: '履歴',
      btn_edit: '編集',
      edit_history: '編集履歴',
      back_to_patient: '患者情報に戻る',
      accept: '受付済',
      display: '表示',
      tab_info: 'タグ情報',
      required: '必須',
      last_name: 'フリガナ姓',
      kata_last_name: 'ふりがな姓',
      kata_first_name: 'ふりがな名',
      roma_last_name: 'ローマ字姓',
      roma_first_name: 'ローマ字名',
      first_name: 'ローマ字姓',
      last_name2: '姓',
      first_name2: '名',
      tap_select: 'タップで選択',
      man: '男性',
      woman: '女性',
      tap_to_add_item: 'タップで項目追加',
      btn_back_without_saving: '保存せずに戻る',
      btn_save: '保存する',
      patient_search: '患者',
      all: 'すべて',
      assignee: '担当',
      recept_time: '受付時刻',
      no_results: '検索された患者は見つかりませんでした',
      back: '戻る',
      billing_amount: '請求額',
      amount_paid: '支払額',
      deduction_amount: '差引額',
      amounted: '会計済',
      before_accounting: '会計前',
      date_issue: '発行日',
      payment_note: '下記、正に領収いたしました',
      tax_included: '税込',
      detail: '明細',
      total: '計',
      yen: '円',
      more_than: '以上',
      tech_instruction_creation: '技工指示書作成',
      tech_instruction: '技工指示書',
      visit_date: '来院日時',
      content_treatment: '処置内容',
      display_record: 'カルテを表示',

      service_management: '役務管理',
      service_category: '役務カテゴリ',
      overall_service: '役務全体',

      complete_accounting: '会計完了',
      tap_to_complete_accounting: 'タップすると会計が完了します',
      receipt_printing: '領収書印刷',
      color_setting: '患者枠色設定',
      zip_code: '郵便番号',
      btn_zip_code: '住所検索',
      patient_status: '患者ステータス',
      first_reservation_modal_title_insert: '最初の予約を登録します',
      first_reservation_modal_title_change: '最初の予約を変更します',
      first_reservation_confirmation_insert: '初診予約として登録されますが、\nよろしいですか？',
      first_reservation_confirmation_change: '初診予約が変更されますが、\nよろしいですか？',
      brand_type: 'ブランド種別',
      age: '歳',
      receive_sms: '配信する',
      receive_sms_title: 'SMS配信設定',
      receive_sms_value: ['配信しない', '配信する'],
      delivery_settings: '配信設定',
      field_receive_email: 'アンケートメール配信設定',
      is_not_receive_email: '配信しない'
    },
    error_page: {
      error: 'ページが見つかりません。'
    },
    setting_page: {
      setting: '設定',
      account_setting: 'アカウント設定',
      whitelist_setting: 'IPアドレス制限',
      deleted_patient: '削除済患者情報',
      patient_deleted: '削除済患者',
      master_setting: 'マスタ設定',
      treatment_detail: '役務管理',
      service_management: '処置内容',
      invite: '招待済み',
      add_member: 'メンバーを追加',
      send_invite: '招待メール送信',
      select_role: '役割を選択ください',
      role_admin: '管理者',
      role_view_only: '閲覧のみ',
      invite_date: '招待メール送信日',
      possible_login: 'ログイン可能',
      last_login: '最終ログイン',
      normal: '通常',
      master_title: 'マスタ名称',
      default_price: 'デフォルト単価',
      yen: '円',
      send_email_reset_password: 'パスワード再設定メール送信',
      delete_staff: 'メンバー削除',
      edit: '編集',
      requesting: 'リクエスト中',
      disallow: '不許可',
      allow: '許可',
      clinic_setting: 'クリニック設定',
      clinic: {
        info: 'クリニック情報',
        formal_name: 'クリニック名',
        info_desc: '入力されたクリニック名は領収書クリニック情報に反映されます。<br>キレイライン公式ホームページ表記には影響ありません。',
        contact: '連絡先情報',
        email: 'メールアドレス',
        phone: '電話番号',
        save_success: '予約を作成しました',
        confirm_update_title: 'クリニック設定',
        confirm_update_content: '編集内容を保存しますか？',
        revenue_report: '売上日報',
        send_report: '売上日報を送信する',
        report_time: '送信時刻',
        report_to: '送信先',
        report_description: 'カンマ区切りで複数入力可能です',
        auto: '自動更新機能',
        reload: '予約ダッシュボード自動更新',
        notification: '予約情報自動通知',
        unit: '分毎'
      },
      master_action: '処置マスタ',
      independent_treatment: {
        name: '院独自処置',
        title: '院独自処置項目',
        create: '独自処置マスタを作成',
        treatment_name: '処置名',
        price: '金額',
        msg_used: 'カルテで使用されているため変更できません'
      },
      treatment: {
        name: '処置名',
        price: '金額',
        service: '発生する役務'
      }
    },
    accounting_list_page: {
      accounting: '予約・会計',
      select_date: '日付を選択',
      download: 'ダウンロード',
      report_data: '日報用データ',
      report_treatment_list_data: '処置一覧CSV',
      report_revenue_receivables_data: '未収金CSV',
      payment_amount: '支払額',
      accrued: '発生未過収金',
      total_receiv: '未過収金合計',
      full_name: '氏名',
      payment_type: '支払種別',
      yen: '円',
      reservations_frame: '予約枠',
      reservation_type: '予約種別',
      number_of_reservations: '予約数',
      number_of_visits: '来院数',
      no_data_warning: '会計データが存在しないため、<br/>ダウンロードできません',
      download_csv_modal: 'ダウンロード',
      no_data_to_export_csv: '出力対象データが存在しません。',
      daily_sale_report_label: '売上日報',
      daily_sale_report_csv: '売上日報 CSV',
      total_report_label: '合計レポート'
    },
    medical_page: {
      back: '戻る',
      btn_create_chart: 'カルテ作成',
      tech_instruction_creation: '技工指示書作成',
      patient_info: '患者情報',
      accounting_info: '会計情報',
      tech_instruction: '技工指示書',
      visit_date: '来院日時',
      display_for_printing: '印刷用表示',
      btn_history: '履歴',
      btn_edit: '編集',
      person_in_charge: '担当者',
      main_complaint: '主訴',
      remarks_column: '備考欄',
      treatment_detail: '処置内容',
      yen: '円',
      total: '計',
      back_to_list: '一覧に戻る',
      tap_to_add_item: 'タップで項目追加',
      memo: 'メモ',
      btn_register: 'カルテ登録',
      btn_save_draft: '下書き保存',
      accepted: '受付済',
      back_to_latest: '最新のカルテに戻る',
      edit_history: '編集履歴',
      btn_display: '表示',
      chart_print: 'カルテ印刷',
      other_item: 'その他の項目',
      regenerate_accounting: '会計を再生成する',
      regenerated_accounting: '会計を再生成しました',
      next_consultation_content: '次回診療内容',
      previous_consultation_content: '前回診療内容',
      before_change: '変更前',
      object: '所見',
      assessment: '評価',
      plan: '治療計画',
      other: 'その他・メモ',
      examiner: '診察担当者',
      add_charge: '担当を追加',
      main_charge_dentist: '主担当Dr.(歯科医師)',
      main_charge_counselor: 'コーディネーター',
      please_select_dentist: '当該カルテの主担当となる歯科医師を選択ください',
      please_select_person_in_charge: '上記以外の担当スタッフを登録ください',
      please_select_counselor: {
        first_line: 'コーディネーターを選択ください'
      }
    },
    interview_input_page: {
      content_consulation: '相談内容'
    },
    component: {
      hp_responsible: '担当',
      hp_visit_date: '最終来院日',
      hp_first_medical: '初診主訴',
      service_category: '役務カテゴリ',
      overall_service: '役務全体',
      number_remaining: '残役務数',
      remaining_amount: '残役務金額',
      yen: '円',
      dp_title: '表示期間を選択',
      dp_recent_specified: '最近から指定',
      dp_specify_period: '期間を指定',
      dp_most_recent: '現在より直近',
      dp_show_day: '日を表示',
      dp_start_date: '開始日',
      dp_end_date: '終了日',
      dp_year: '年',
      dp_month: '月',
      dp_day: '日',
      dp_btn_save: '保存',
      dp_btn_reset: 'リセット',
      mrdl_patient_info: 'カルテ情報',
      mrdl_display_all: 'すべて表示',
      mrdl_draft: '下書き'
    },
    accounting_page: {
      treatment_detail: '処置内容',
      service_category: '役務カテゴリ',
      overall_service: '役務全体',
      number_added: '今回追加数',
      provided: '今回提供',
      number_remaining: '残役務数',
      remaining_amount: '残役務金額',
      yen: '円',
      previous_receivables: '前回未収金(過収金)',
      billing_amount: '請求額',
      sub_total: '小計',
      discount_amount: '割引額',
      deposit_amount: '入金額',
      unpaid_money: '今回未過収金',
      remarks_column: '備考欄',
      service_management: '役務管理',
      payment_instrument: '決済手段',
      payment_method: '決済手段を選択ください',
      none: 'なし',
      tap_to_add_item: 'タップで項目追加',
      expiration_date: '有効期限',
      accounting_creator: '会計作成者',
      nodmr_invoice_can_not_print_detailed_invoice: '会計のみの領収書は、明細は発行できません',
      invoice_printing: '領収書印刷',
      with_detail: '明細付',
      no_detail: '明細無',
      show_today: '本日を表示',
      show_this_month: '今月を表示'
    },
    placeholder: {
      patient_search: '患者名・IDで検索',
      patient_search_advance: '検索条件が設定されています',
      input_text: 'タップで文章入力',
      draw_illus: 'タップでイラストを描く',
      enter_text: 'テキストを入力',
      post_comment: 'コメントを投稿する',
      tap_to_input: 'タップで入力',
      post_code: 'ハイフンは不要です',
      please_enter: '入力してください',
      medical: {
        subject: '主訴を入力してください。',
        object: '所見を入力してください。',
        assessment: '評価を入力してください。',
        plan: '治療計画を入力してください。',
        other: 'その他、診察時のメモを入力して下さい。'
      }
    },
    accounting: {
      person_in_charge: '担当者',
      payment_type: '支払い種別',
      treatment_type: '処置種別',
      tax_amount: '税額',
      payment_info: '支払情報',
      receipt_printing: '領収書印刷',
      please_select_creditcard_type: 'クレジットカードの種類を選択してください',
      please_select_sale_credit_type: '信販の種類を選択してください',
      last_overpaid_unpaid_amount: '前回未過収金',
      last_overpaid_amount: '前回過収金',
      last_unpaid_amount: '前回未収金',
      current_overpaid_unpaid_amount: '今回未過収金',
      current_overpaid_amount: '今回過収金',
      current_unpaid_amount: '今回未収金',
      credit_card: 'クレジットカード',
      sale_credit: '信販',
      medical_record_person_in_charge: 'カルテ担当者',
      person_in_charge_retired: '退職済'
    },
    reservation: {
      reservation_canceled: '予約を取り消しました',
      reservation_created: '予約を作成しました',
      reservation_updated: '予約内容を変更しました',
      reservations_already_exist: '予約が入っています',
      reservations_name_field_is_blanked: '枠名が空欄です',
      do_you_really_want_to_cancel_this_reservation: 'この予約を取り消しますか？',
      do_you_want_to_cancel_this_reservation: '予約を削除しますか？',
      conflict_with_blocked_frame: 'ブロック枠と競合する',
      clinic_name: 'クリニック名',
      number_of_new_slots_reserved: '新規枠予約数',
      number_of_existing_slots_reserved: '既存枠予約数',
      new_frame_reception: '新規枠受付',
      reservation_setting: '予約設定',
      spreadsheet: '集計表',
      number_of_reservations_by_type: '種別毎予約数',
      new_customer_reception_status: '新規顧客受付状況',
      frame_moved: '枠を移動しました',
      added_frame: '枠を追加しました',
      deleted_frame: '枠を削除しました',
      presets_cannot_be_changed: 'プリセットのものは変更できません。',
      please_select_content: '表示名を入力してください',
      preset_items_cannot_be_changed: 'プリセットの項目は変更できません',
      reservation_register_type: '予約種別を登録',
      frame_auto: '自動',
      move_frame_guide: '移動したい場所を</br>タップしてください',
      reason_cancel: {
        inconvenient: '都合が悪くなったため',
        troublesome: '面倒になったため',
        poor_physical_condition: '体調不良のため',
        another_method: '他の矯正法に決めたため',
        other: 'その他',
        dont_correct: '歯科矯正をやらないことにしたため',
        dont_satisfied_with_price: '料金、治療方針に納得がいかないため',
        want_to_reservation_at_another_hospital: '他院で予約したいため',
        moving: '引越・転勤のため',
        unauthorized_cancellation: '無断キャンセル'
      },
      search_title: '予約検索',
      time_start_to: '予約開始日時',
      no_search_result: '該当する予約情報は見つかりませんでした',
      please_enter_duty_name: '種別名を入力してください',
      please_enter_display_name: '表示名を入力してください',
      please_enter_type_name: '種別名を入力してください',
      reservation_type_deleted: '該当の予約種別は削除されています。 <br> 画面のリロードを行い予約種別を再選択してください。',
      invalid_email: 'メールアドレスは有効なメールアドレスではありません',
      warning_setting: '営業時間外に予約が存在します',
      content_warning: '以下の予約の日時変更、または営業日設定の変更を行ってから',
      check_warning: 'もう一度処理を行ってください',
      allocation: {
        not_found: '割り当てが見つかりません',
        title: '枠設定',
        name: '枠名',
        position: '位置',
        placeholder: '枠名を入力してください',
        auto_check: '自動予約枠',
        set_auto_check: '自動予約枠に設定する',
        text: '「設定しない」以外を選択すると該当する自動予約の対象となります',
        button: {
          create: '閉じる',
          cancel: 'この枠を削除'
        },
        errors: {
          maximum: '最大 %{field}は %{max}文字です',
          required: '%{field}を入力してください'
        }
      },
      reservation_note_placeholder: 'メモを入力してください',
      add_reservation_type: '予約種別を追加',
      select_patient: '患者を選択',
      reservation_status: '予約ステータス',
      first_visit_date: '初回来院日 ',
      select_person_charge: '担当者を選択',
      add_new_frame: '新しく枠を追加',
      cancel_reservation: '予約取消',
      auto_notification: '予約情報が更新されました'
    },
    reservation_type: {
      msg_confirm_delete: 'タブ {active_tab_name} を削除しますか？',
      errors: {
        reservation_type_using: 'この予約種別はすでに使用されているため削除出来ません。'
      }
    },
    sweet_alert: {
      something_went_wrong: '予期せぬエラーが発生しました。\n' +
        '少し時間を空けてもう一度操作を行ってください。\n' +
        '繰返し発生する場合はサポートセンターにお問い合わせください',
      available_date_warning: '表示可能な日程は1年間です',
      before_release_date_warning: 'サービス提供前の日付は選択できません',
      saved: '保存しました',
      support_chrome_only: 'ご利用のブラウザでは本機能はご利用いただけません。Google Chromeをご利用ください。',
      has_input_interview_sheet: '問診票入力済みです'
    },
    staff: {
      retiree: '退職者',
      add_new_staff: '新規スタッフの追加',
      full_name: '氏名',
      surname: '名字',
      roman_name: 'ローマ字氏名',
      position: '役職',
      please_select_a_position: '役職を選択してください',
      administrator_rights: '管理者権限',
      staff_information: 'スタッフ情報',
      name: '名前',

      login_information: 'ログイン情報',
      staff_id: 'スタッフID',
      please_enter_staff_id: 'スタッフIDを入力してください',
      please_enter_alphanumeric_characters: '半角英数字で入力ください',
      please_enter_alphanumeric_characters_and_symbols_only_at_dot: '半角英数字および記号(@.のみ利用可能)を入力ください',
      email_address_optional: 'メールアドレス(任意)',
      please_enter_your_email_address: 'メールアドレスを入力してください',
      send_invitation_email: '招待メールを送信する',
      initial_password: '初期パスワード',
      password: 'パスワード',
      enter_your_initial_password: '初期パスワードを入力してください',
      enter_your_new_password: 'パスワード',
      please_enter_at_least_8_alphanumeric_characters: '半角英数字8文字以上で入力してください',
      cancel: 'キャンセル',
      to_add: '追加する',
      none: 'なし',
      yes: 'あり',
      please_enter_surname_of_full_name: '氏名: 名字を入力してください',
      please_enter_firstname_of_full_name: '氏名: 名前入力してください',
      please_enter_surname_of_roma_name: 'ローマ字氏名: 名字を入力してください',
      please_enter_firstname_of_roma_name: 'ローマ字氏名: 名前を入力してください',
      please_enter_valid_staff_id: 'スタッフID: 半角英数字で入力ください',
      please_enter_valid_email: '有効なメールアドレスを入力してください',
      please_enter_valid_password: '初期パスワード: 半角英数字8文字以上で入力ください',

      new_staff_addition_completed: '新規スタッフ追加完了',
      the_following_staff_members_have_been_added: '以下のスタッフの追加が完了しました',
      the_following_login_information_is_displayed_only_once: '以下のログイン情報は一度きりのみ表示です。',
      please_copy_and_tell: 'コピーしてお伝えください',
      login_id: 'ログインID',
      login_url: 'ログインURL',
      copy_to_clipboard: 'クリップボードにコピー',
      copied_to_clipboard: 'クリップボードにコピーしました',
      account_information: 'アカウント情報',
      close: '閉じる',
      login_information_is: 'ログイン情報は',
      sent_to_email: '{email}に送信しました。',
      please_contact_us_to_check_your_mailbox_please_copy_and_tell: 'メールボックスを確認するよう連絡ください。コピーしてお伝えください',
      editing_staff_information: 'スタッフ情報の編集',
      revoke: '取消',
      change: '変更する',
      do_you_really_want_to_delete_this: '本当に削除しますか',
      delete: '削除する',
      deleted_staff: 'スタッフを削除しました',
      staff_info_updated: 'アカウント情報を変更しました'
    },
    white_list_page: {
      status: 'ステータス',
      ip: 'IPアドレス',
      label: 'ラベル',
      staff_id: 'リクエストスタッフID',
      setting: 'アカウント設定',
      edit: '編集',
      save: '保存',
      addIpAddress: 'IPアドレスの追加',
      invalid_character_label: '日本語と英数字および記号は-_のみご入力いただけます',
      long_label: '最大入力文字数は50文字までです',
      invalid_character_ip_address: 'IPアドレスは半角英数字および記号は.のみご入力いただけます',
      invalid_ip_address: 'IPアドレスは 0.0.0.0～255.255.255.255 の範囲でご入力ください',
      label_placeholder: 'クリニック内ネットワークIP',
      ip_exist: 'IPアドレスは既に存在します。'
    },
    activity_log: {
      medical_record_created: 'カルテ#{id}が作成されました',
      medical_record_updated: 'カルテ#{id}が更新されました',
      medical_record_deleted: 'カルテ#{id}が削除されました',
      comment_medical_record_posted: 'カルテ#{id}にコメントが投稿されました',

      invoice_issued: '患者会計が発行されました',
      invoice_reissued: '患者会計が再発行されました',
      invoice_completed: '患者会計が完了しました',
      invoice_deleted: '患者会計が削除されました',
      invoice_updated: '患者会計が更新されました',

      patient_updated: '患者情報が更新されました',
      patient_checked_in: 'チェックインしました',
      patient_checked_in_cancel: '来院受付取り消し',
      patient_delete: '患者情報が削除されました',
      patient_recover: '患者情報の削除が取り消されました',

      reservation_accepted: '{date} の新規予約を受け付けました',
      reservation_schedule_changed: '新規予約日程が {date} に変更されました',
      reservation_cancelled: '{date} の新規予約がキャンセルされました',

      lab_instruction_created: '技工指示書#{id}が作成されました',
      lab_instruction_status_changed: '技工指示書#{id}のステータスが{status}に更新されました',

      patient_action: '患者アクション',
      note: 'メモ',
      activity_log: 'アクティビティログ',
      notes_only: 'メモのみ',
      send: '送信',
      delete: '削除',
      visit_reception: '来院受付',
      visit_reception_note: 'クリニックの来院受付',
      visit_reception_cancel: '来院受付取り消し',
      visit_reception_accepted: '来院受付済',
      reservation_schedule_adjustment: '予約日程調整',
      reservation_schedule_adjustment_note: '次回来院日時の設定',
      chart_creation: 'カルテ作成',
      chart_creation_note: '新規カルテの作成',
      accounting: '会計',
      accounting_note: '会計処理を行う',
      all: '全て',
      load_more: '次の20件を表示',
      memo_deleted: 'メモを削除しました',
      visit_reception_cancel_text: '来院受付を取り消す',
      visit_reception_cancel_sub_text: '来院前の状態に戻しますか？',
      visit_reception_cancel_confirm_button: '受付を取り消す'
    },
    notification_page: {
      title: 'お知らせ',
      notification: '通知',
      mr: '様',
      display_patient_information: '患者情報を表示',
      show_reservation: '予約を表示',
      reservation_date_and_time: '予約日時',
      notice_will_be_deleted_in_30_days: 'お知らせは30日で削除されます',
      mark_all_as_read: 'すべて既読にする',
      currently_no_notifications: '現在、通知はありません',
      makes_new_reservation: '患者様が新規の予約や予約変更・キャンセルを行うと<br>こちらにお知らせが届きます。'
    },
    interview: {
      basic_info: '基本情報',
      patient_name: '患者名前',
      patient_romaji: 'ローマ字',
      patient_furigana: 'ふりがな',
      birthday: '生年月日',
      occupation: '職業',
      age: '年齢',
      sex: '性別',
      currently_pregnant: '現在妊娠中',
      not_pregnant: '妊娠していない',
      address_contact: '住所・連絡先',
      street_address: '住所',
      phone_number: '電話番号',
      mail_address: 'メールアドレス',
      minors_info: '保護者情報（未成年の方）',
      guardian_info: '保護者情報',
      guardian2_desc: '※ご事情を理解されているもう一人の保護者様の情報をご記入ください',
      guardian_name: '保護者名',
      contact_info: 'ご連絡先',
      relationship: '続柄',
      other_address: 'ご住所※お住いが別の場合記入',
      consultation_title: '相談内容',
      consultation_content: 'ご相談内容',
      places_of_concern_symptoms: '気になる箇所・症状',
      priority_1: '優先順位1',
      priority_2: '優先順位2',
      priority_3: '優先順位3',
      location: '部位',
      symptom: '症状',
      dentition_number: '歯列番号',
      specific_consultation: '気になる部位・症状について具体的な相談内容',
      when: 'いつ頃',
      relocation: '転居先',
      prior_consultation: '事前問診',
      other_consultation: 'その他',
      clinic_name: '医院名',
      straightening_device: '矯正装置',
      diagnosis: '診断名',
      daily_dose: '一日量',
      highest: '最高',
      lowest: '最低',
      treatment: '治療内容',
      height: '身長',
      weight: '体重',
      blood_type: '血液型',
      currently_hospital: '現在通院中',
      priority_number: '優先順位{number}',
      today: '本/日',
      modal_confirm_save: {
        title: '問診票を保存します',
        content_1: '保存を行うと再編集ができなくなります。',
        content_2: 'よろしいですか。'
      },
      kid_learning_sport: '習い事・スポーツ',
      guardian_occupation: '職業',
      symptoms_type: {
        mouth: '※症状が強い場合は適応にならない場合があります。',
        gummy: '※本来であればスマイル時に、上顎の歯肉はギリギリ見えないのが理想的。',
        logo: '※矯正歯科領域では、一般的に上下顎前突、上顎前突、下顎骨後退症など、口元が突出している状態のことが言われるケースが多い。\n' +
          '※歯科矯正学ではこのような表現は存在しません。\n' +
          '※症状が強い場合は適応にならない場合があります。'
      }
    },
    treatment: {
      name_placeholder: '処置名を入力ください',
      confirm_delete_tile: '処置を削除しますか？',
      confirm_delete_msg: 'すでにカルテに記載された処置は残りますが、\n 新たに選ぶことはできなくなります',
      confirm_update_title: '処置を変更しますか？',
      confirm_update_msg: 'すでにカルテに記載された処置内容も変更されます。\n 変更を行ってもよろしいですか'
    },
    prints: {
      toggle_button: '印刷をする',
      calendar: '来院予定印刷'
    },
    orders: {
      search: {
        label: {
          order_date: '発注日',
          patient_id: '患者ID',
          clinic_name: 'クリニック',
          order_id: '注文ID',
          product_id: 'コース名',
          count: '回数',
          patient_name: '患者名',
          intended_date_send: '発送予定日',
          status: '注文ステータス',
          code: 'g番号',
          worker_setup_id: 'セットアップ担当者',
          worker_presscut_id: 'プレスカット担当者',
          lab_name: '技工所',
          unprinted: '未印刷'
        },
        placeholder: {
          please_select: '選択してください',
          patient_id: '患者IDで検索',
          order_id: '注文IDで検索',
          count: '回数で検索',
          patient_name: '患者名で検索',
          code: 'g番号で検索'
        },
        option: {
          status: {
            clinic_confirmation: 'クリニック要確認',
            check_before_order: '受注前確認中',
            during_setup_design: 'セットアップデザイン中',
            preparing_to_ship: '発送準備中',
            shipment_completed: '発送完了',
            cancel: 'キャンセル',
            delivery_completed: '納品完了'
          }
        },
        button: {
          search: '検索',
          reset: 'リセット'
        }
      },
      list: {
        item: {
          order_id: '注文ID',
          created_at: '発注日',
          order_product_name: 'コース名',
          order_product_number: '回数',
          patient_name: '患者名',
          patient_id: '患者ID',
          status: 'ステータス',
          clinic_name: 'クリニック',
          order_shipping_date: '発送予定日',
          order_delivery_date: '発送日',
          g_bangou: 'g番号',
          worker_setup: 'セットアップ担当者',
          worker_presscut: 'プレスカット担当者',
          lab_name: '技工所'
        },
        print_status: {
          unprinted: '未印刷',
          printed: '印刷済'
        },
        status: {
          confirmation: 'クリニック要確認',
          checking: '受注前確認中',
          setup_design: 'セットアップデザイン中',
          prepare: '発送準備中',
          shipped: '発送完了'
        },
        message: {
          no_result_search: '条件に一致するデータがありません'
        }
      }
    }
  }
}

export default messages
