<template>
  <form
    class="search-order"
    name="search-order"
    @submit.prevent="handleSubmit"
  >
    <div class="search-order__fields">
      <div class="search-order__top">
        <div class="search-order__row">
          <div class="search-order__group">
            <label>{{ $t('orders.search.label.order_date') }}</label>
            <select-date
              id="order_date"
              :is-read-only="false"
              :default-date="data_search.order_date"
              @change="value => data_search.order_date = value"
            />
          </div>
          <div class="search-order__group">
            <label>{{ $t('orders.search.label.patient_id') }}</label>
            <input-search
              :default-value="data_search.sf_user_id"
              :placeholder="$t('orders.search.placeholder.patient_id')"
              class="input-default"
              @search="searchIDPatient"
              @input="value => data_search.sf_user_id = value"
            />
          </div>
          <div class="search-order__group search-order__group--latest">
            <label>{{ $t('orders.search.label.patient_name') }}</label>
            <input-search
              :default-value="data_search.patient_name"
              :placeholder="$t('orders.search.placeholder.patient_name')"
              type="search-name"
              class="input-default"
              @search="searchNamePatient"
              @input="value => data_search.patient_name = value"
            />
          </div>
          <div
            v-if="isShowFieldSearch('unprinted')"
            class="search-order__group search-order__unprinted"
          >
            <common-checkbox
              :label="$t('orders.search.label.unprinted')"
              :is-checked="data_search.unprinted"
              :value="true"
              @input="value => data_search.unprinted = value"
            />
          </div>
        </div>
      </div>
      <div class="search-order__bottom">
        <h3
          class="search-order__bottom__title"
          @click="[is_show_bottom = !is_show_bottom, $emit('show-search-detail')]"
        >
          {{ $t('new_text.button.advanced_search') }}
          <img :src="is_show_bottom ? '/images/blue-arrow-up.svg' : '/images/blue-arrow-down.svg'">
        </h3>
        <h3
          class="search-order__bottom__title search-order__bottom__title--sp"
          @click="[is_show_bottom = !is_show_bottom, $emit('show-search-detail')]"
        >
          <span>{{ $t('new_text.button.advanced_search') }}</span>
          <div>
            {{ $t('objects.orders.text.close_advanced_search') }}
            <img :src="is_show_bottom ? '/images/blue-arrow-up.svg' : '/images/blue-arrow-down.svg'">
          </div>
        </h3>
        <div
          v-show="is_show_bottom"
          class="search-order__bottom__content"
        >
          <div class="search-order__row">
            <div class="search-order__group">
              <label>{{ $t('orders.search.label.order_id') }}</label>
              <common-input
                :placeholder="$t('orders.search.placeholder.order_id')"
                :default-value="data_search.order_id"
                @input="value => data_search.order_id = value"
              />
            </div>
            <div class="search-order__group">
              <label>{{ $t('orders.search.label.product_id') }}</label>
              <dropdown-filter-common
                :options="optionsProduct"
                :default-value="data_search.product_id"
                :empty-value="$t('new_text.no_selection')"
                :placeholder="$t('orders.search.placeholder.please_select')"
                @change="value => data_search.product_id = value"
              />
            </div>
            <div class="search-order__group">
              <label>{{ $t('orders.search.label.count') }}</label>
              <common-input
                maxlength="2"
                :placeholder="$t('orders.search.placeholder.count')"
                :default-value="data_search.count"
                @input="value => data_search.count = value"
              />
            </div>
            <div
              v-if="$store.getters.isLabDesigner || isInstructionAdmin"
              class="search-order__group search-order__group--latest search-order__group--clinic"
            >
              <label>{{ $t('orders.search.label.clinic_name') }}</label>
              <dropdown-filter-common
                :options="optionsClinic"
                :default-value="data_search.sf_clinic_id"
                :empty-value="$t('new_text.no_selection')"
                :placeholder="$t('orders.search.placeholder.please_select')"
                @change="changeClinic"
              />
            </div>
          </div>
          <div class="search-order__row">
            <div
              v-if="isShowFieldSearch('intended_date_send')"
              class="search-order__group"
            >
              <label>
                {{
                  (currentStatus !== order_status.SHIPPED.value && currentStatus !== order_status.FINISH.value)
                    ? $t('orders.list.item.order_shipping_date')
                    : $t('orders.list.item.order_delivery_date')
                }}
              </label>
              <select-date
                id="intended_date_send"
                :is-read-only="false"
                :default-date="data_search.intended_date_send"
                :is-position-bottom="true"
                @change="value => data_search.intended_date_send = value"
              />
            </div>
            <div
              v-if="isShowFieldSearch('status')"
              class="search-order__group search-order__group--large"
            >
              <label>{{ $t('orders.search.label.status') }}</label>
              <common-select
                :default-value="data_search.status"
                :options="getStatusOptions"
                :placeholder="$t('orders.search.placeholder.please_select')"
                :empty-value="$t('new_text.no_selection')"
                key-value="value"
                key-display="name"
                @change="value => data_search.status = value"
              />
            </div>
            <div
              v-if="isShowFieldSearch('code')"
              class="search-order__group"
            >
              <label>{{ $t('orders.search.label.code') }}</label>
              <common-input
                :placeholder="$t('orders.search.placeholder.code')"
                :default-value="data_search.code"
                @input="value => data_search.code = value"
              />
            </div>
            <div
              v-if="isShowFieldSearch('worker_setup_id')"
              class="search-order__group search-order__group--large"
            >
              <label>{{ $t('orders.search.label.worker_setup_id') }}</label>
              <dropdown-filter-common
                :options="options_designer"
                :default-value="data_search.worker_setup_id"
                :empty-value="$t('new_text.no_selection')"
                :placeholder="$t('orders.search.placeholder.please_select')"
                @change="value => data_search.worker_setup_id = value"
              />
            </div>
            <div
              v-if="isShowFieldSearch('worker_presscut_id')"
              class="search-order__group search-order__group--large"
            >
              <label>{{ $t('orders.search.label.worker_presscut_id') }}</label>
              <dropdown-filter-common
                :options="options_designer"
                :default-value="data_search.worker_presscut_id"
                :empty-value="$t('new_text.no_selection')"
                :placeholder="$t('orders.search.placeholder.please_select')"
                @change="value => data_search.worker_presscut_id = value"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search-order__button">
      <common-button
        class="search-order__button__submit search-order__btn search-order__btn--submit"
        type="submit"
      >
        <template #content>
          {{ $t('common.search') }}
        </template>
      </common-button>
      <common-button
        class="search-order__button__research search-order__btn"
        @click="$emit('set-hide-search-content-in-sp')"
      >
        <template #content>
          {{ $t('new_text.research') }}
        </template>
      </common-button>
      <common-button
        :class="[
          'search-order__button__reset search-order__btn search-order__btn',
          { 'search-order__button__reset--active': enableResetSearch || resetActive }
        ]"
        :disabled="!enableResetSearch && !resetActive"
        @click="resetSearchResult"
      >
        <template #content>
          {{ $t('orders.search.button.reset') }}
        </template>
      </common-button>
    </div>
    <modal-search-name-patient
      v-if="is_show_modal_search_name"
      :sf-clinic-id="data_search.sf_clinic_id"
      :keyword="data_search.patient_name"
      @select="setPatient"
      @close="is_show_modal_search_name = false"
    />
    <modal-search-id-patient
      v-if="is_show_modal_search_id"
      :sf-clinic-id="data_search.sf_clinic_id"
      :keyword="data_search.sf_user_id"
      @select="setPatient"
      @close="is_show_modal_search_id = false"
    />
    <search-patient
      v-if="is_show_modal_search_responsive"
      :keyword-id="data_search.sf_user_id"
      :keyword-name="data_search.patient_name"
      :search-by-id="search_by_id"
      :is-required-sf-user-id="true"
      @select="setPatient"
      @close="closeSearchPatientSP"
    />
  </form>
</template>

<script>
  import Button from '../common/Button'
  import Input from '../common/Input'
  import Select from '../common/Select'
  import SelectDate from '../common/SelectDate'
  import Checkbox from '../common/Checkbox'
  import { showErrorModal, safeGet, checkInstructionForbidden } from '../../utils/helpers'
  import InputSearch from '../common/InputSearch'
  import ModalSearchNamePatient from '../common/ModalSearchNamePatient'
  import ModalSearchIdPatient from '../common/ModalSearchIdPatient'
  import SearchPatient from './SearchPatient'
  import { SCREEN_WIDTH_MEDIUM, ORDER_STATUS, LAYOUT_MODE, SCREEN_WIDTH_SMALL } from '../../utils/constants'
  import DropdownFilterCommon from '../common/DropdownFilterCommon'

  export default {
    components: {
      CommonButton: Button,
      CommonInput: Input,
      CommonSelect: Select,
      CommonCheckbox: Checkbox,
      SelectDate,
      InputSearch,
      ModalSearchNamePatient,
      ModalSearchIdPatient,
      SearchPatient,
      DropdownFilterCommon
    },
    props: {
      fieldsHidden: {
        type: Array,
        default: () => []
      },
      typeSearch: {
        type: String,
        default: 'search'
      },
      currentStatus: {
        type: Number,
        default: 0
      },
      resetActive: {
        type: Boolean,
        default: false
      }
    },
    emits: ['submit', 'reset', 'show-search-detail', 'set-hide-search-content-in-sp'],
    data () {
      return {
        data_search: {
          order_date: '',
          sf_clinic_id: '',
          sf_user_id: '',
          order_id: '',
          product_id: '',
          count: '',
          patient_name: '',
          intended_date_send: '',
          status: '',
          code: '',
          worker_setup_id: '',
          worker_presscut_id: '',
          unprinted: false
        },
        is_show_bottom: false,
        optionsStatus: [
          { value: 2, name: this.$t('orders.search.option.status.check_before_order') },
          { value: 3, name: this.$t('orders.search.option.status.during_setup_design') },
          { value: 4, name: this.$t('orders.search.option.status.preparing_to_ship') },
          { value: 5, name: this.$t('orders.search.option.status.shipment_completed') },
          { value: 6, name: this.$t('orders.search.option.status.cancel') },
          { value: 7, name: this.$t('orders.search.option.status.delivery_completed') }
        ],
        product_list: [],
        is_show_modal_search_name: false,
        is_show_modal_search_id: false,
        is_show_modal_search_responsive: false,
        search_by_id: false,
        designer_list: [],
        clinic_list: [],
        options_designer: [],
        disallow_show_item_search: {
          search: ['unprinted'],
          checking: [
            'intended_date_send',
            'status',
            'code',
            'worker_setup_id',
            'worker_presscut_id'
          ],
          'setup-design': ['unprinted', 'status'],
          prepare: ['unprinted', 'status'],
          shipped: ['unprinted', 'status'],
          cancelled: ['unprinted', 'status'],
          finish: ['unprinted', 'status'],
          other: ['unprinted', 'status']
        },
        order_status: ORDER_STATUS
      }
    },
    computed: {
      optionsProduct () {
        const optionsProduct = []

        this.product_list.forEach(element => {
          optionsProduct.push({ value: element.id, text: element.name })
        })

        return optionsProduct
      },
      optionsClinic () {
        const optionsClinic = []

        this.clinic_list.forEach(element => {
          if (element.sf_clinic_id) {
            optionsClinic.push({ value: element.sf_clinic_id, text: element.name })
          }
        })

        return optionsClinic
      },
      isLabDesigner () {
        return this.$store.getters.isLabDesigner
      },
      getStatusOptions () {
        return _.filter(this.optionsStatus, i => {
          return !this.isLabDesigner || i.value !== 1
        })
      },
      isInstructionAdmin () {
        return this.$store.getters.isInstructionAdmin
      },
      enableResetSearch () {
        return (
          safeGet(this.data_search, 'code') !== '' ||
          safeGet(this.data_search, 'count') !== '' ||
          safeGet(this.data_search, 'intended_date_send') !== '' ||
          safeGet(this.data_search, 'order_date') !== '' ||
          safeGet(this.data_search, 'order_id') !== '' ||
          safeGet(this.data_search, 'patient_name') !== '' ||
          safeGet(this.data_search, 'product_id') !== '' ||
          safeGet(this.data_search, 'sf_clinic_id') !== '' ||
          safeGet(this.data_search, 'sf_user_id') !== '' ||
          safeGet(this.data_search, 'status') !== '' ||
          safeGet(this.data_search, 'unprinted') !== '' ||
          safeGet(this.data_search, 'worker_presscut_id') !== '' ||
          safeGet(this.data_search, 'worker_setup_id') !== ''
        )
      },
      currentLayoutMode () {
        return parseInt(this.$store.state.auth.layout_mode)
      }
    },
    watch: {
      currentStatus (value) {
        this.resetSearch()
        this.is_show_bottom = false
      }
    },
    created () {
      this.getProductList()
      this.getDesignerList()
      this.getClinicList()
    },
    methods: {
      handleSubmit () {
        const dataSearch = this.data_search
        this.disallow_show_item_search[this.typeSearch].forEach(
          field => {
            delete dataSearch[field]
          }
        )

        this.$emit('submit', dataSearch, true)

        if (
          this.currentLayoutMode === LAYOUT_MODE.SP ||
          (!this.currentLayoutMode && window.screen.width <= SCREEN_WIDTH_SMALL)
        ) {
          this.is_show_bottom = false
        }
      },
      resetSearchResult () {
        this.resetSearch()
        const dataSearch = this.data_search
        this.disallow_show_item_search[this.typeSearch].forEach(
          field => {
            delete dataSearch[field]
          }
        )

        if (this.typeSearch === 'search') {
          this.$emit('reset')
        } else {
          this.$emit('submit', dataSearch, true)
        }
      },
      resetSearch () {
        this.data_search = {
          order_date: '',
          sf_user_id: '',
          sf_clinic_id: '',
          order_id: '',
          product_id: '',
          count: '',
          patient_name: '',
          intended_date_send: '',
          status: '',
          code: '',
          worker_setup_id: '',
          worker_presscut_id: '',
          unprinted: false
        }
      },
      getProductList () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('getProductList').then((result) => {
          const { data: { results } } = result
          this.product_list = results
          this.$store.commit('set_loading', false)
        }).catch((error) => {
          showErrorModal(this.$t('new_message.something_went_wrong'))
          this.$store.commit('set_loading', false)
          checkInstructionForbidden(error, this)
        })
      },
      searchIDPatient (keyword) {
        this.data_search.sf_user_id = keyword
        if (this.currentLayoutMode === LAYOUT_MODE.SP || (!this.currentLayoutMode && window.innerWidth <= SCREEN_WIDTH_MEDIUM)) {
          this.is_show_modal_search_responsive = true
          this.search_by_id = true
          document.getElementsByClassName('content__search')[0].style.position = 'unset'
        } else {
          this.is_show_modal_search_id = true
        }
      },
      searchNamePatient (keyword) {
        this.data_search.patient_name = keyword
        if (this.currentLayoutMode === LAYOUT_MODE.SP || (!this.currentLayoutMode && window.innerWidth <= SCREEN_WIDTH_MEDIUM)) {
          this.is_show_modal_search_responsive = true
          this.search_by_id = false
          document.getElementsByClassName('content__search')[0].style.position = 'unset'
        } else {
          this.is_show_modal_search_name = true
        }
      },
      setPatient (patient) {
        this.data_search.sf_user_id = patient.sf_user_id || ''
        this.data_search.patient_name = patient.name
        this.is_show_modal_search_name = false
        this.is_show_modal_search_id = false
        this.closeSearchPatientSP()
      },
      closeSearchPatientSP () {
        this.is_show_modal_search_responsive = false
        if (this.currentLayoutMode === LAYOUT_MODE.SP || (!this.currentLayoutMode && window.innerWidth <= SCREEN_WIDTH_MEDIUM)) {
          document.getElementsByClassName('content__search')[0].style.position = 'fixed'
        }
      },
      getDesignerList () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('getDesignerList').then((result) => {
          const { data: { results } } = result
          this.designer_list = results
          this.getOptionsDesigner()
        }).catch((error) => {
          showErrorModal(this.$t('new_message.something_went_wrong'))
          this.$store.commit('set_loading', false)
          checkInstructionForbidden(error, this)
        })
      },
      getClinicList () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('getClinicList').then((result) => {
          const { data: { results } } = result
          this.clinic_list = results
          this.$store.commit('set_loading', false)
        }).catch((error) => {
          showErrorModal(this.$t('new_message.something_went_wrong'))
          this.$store.commit('set_loading', false)
          checkInstructionForbidden(error, this)
        })
      },
      getOptionsDesigner () {
        if (this.designer_list) {
          this.designer_list.forEach(element => {
            this.options_designer.push({ value: element.sf_worker_id ?? element.id, text: element.name })
          })
        }
      },
      changeClinic (value) {
        this.data_search.sf_clinic_id = value.toString()
        this.data_search.sf_user_id = ''
        this.data_search.patient_name = ''
      },
      isShowFieldSearch (field) {
        return !this.disallow_show_item_search[this.typeSearch].includes(field)
      }
    }
  }
</script>
