export default {
  filter_position: {
    all: 'All',
    a: 'A',
    ka: 'Ka',
    sa: 'Sa',
    ta: 'Ta',
    na: 'Na',
    ha: 'Ha',
    ma: 'Ma',
    ya: 'Ya',
    ra: 'Ra',
    wa: 'Wa'
  },
  filter_designer_list: {
    all: {
      name: 'Show all'
    },
    is_admin: {
      name: 'Manager privileges only',
      id_filter: 1
    },
    not_admin: {
      name: 'Staff authority only',
      id_filter: 0
    }
  },
  filter_staff_list: {
    all: {
      name: 'Show all'
    },
    is_admin: {
      name: 'Manager privileges only',
      id_filter: 1
    },
    not_admin: {
      name: 'Staff authority only',
      id_filter: 0
    },
    is_dentist: {
      name: 'Dentist only',
      id_filter: 1
    },
    clean_staff: {
      name: 'Dental hygienist only',
      id_filter: 3
    },
    counselors: {
      name: 'Coordinator only',
      id_filter: 2
    },
    normal_staff: {
      name: 'Staff only',
      id_filter: 4
    }
  },
  staff_id: 'Staff ID'
}
