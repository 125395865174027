<template>
  <div :class="['patient-activity', { 'patient-activity--no-content': activities_data.length === 0 }]">
    <div class="patient-activity__header">
      <h2 class="patient-activity__header__title">
        {{ $t('objects.patient.text.activity_log') }}
      </h2>
    </div>
    <div class="patient-activity__content">
      <div
        v-if="activities_data.length > 0"
        class="patient-activity__content__list"
      >
        <template v-for="item in activities_data">
          <MedicalRecordActivity
            v-if="item.type === activity_log_type.MEDICAL_RECORD"
            :key="item.id"
            :activity="item"
          />
          <InvoiceActivity
            v-if="item.type === activity_log_type.INVOICE"
            :key="item.id"
            :activity="item"
            :model-data="invoice_data[item.activityable_id] || {}"
          />
          <ReservationActivity
            v-if="item.type === activity_log_type.RESERVATION"
            :key="item.id"
            :activity="item"
            :patient-name="patientFullName"
            :model-data="reservation_data[item.activityable_id] || {}"
            :max-date="maxCreatedAt"
          />
          <PatientActivity
            v-if="item.type === activity_log_type.PATIENT"
            :key="item.id"
            :activity="item"
            :patient-name="patientFullName"
          />
          <PatientInterviewActivity
            v-if="item.type === activity_log_type.PATIENT_INTERVIEW"
            :key="item.id"
            :activity="item"
            :patient-name="patientFullName"
          />
        </template>
      </div>
      <span
        v-else
        class="patient-activity__content__list"
      >
        {{ is_loading ? $t('objects.activity.activity_log_loading') : $t('objects.activity.patient_info_not_found') }}
      </span>
      <div class="patient-activity__content__pagination">
        <pagination-common
          v-if="activities_data.length > 0"
          :current-page="page"
          :last-page="last_page"
          @change-page="getPatientActivities"
        />
      </div>
    </div>
    <Loading />
  </div>
</template>

<script>
  import Loading from '../../../components/Loading'
  import MedicalRecordActivity from '../../../components/v2/activity/MedicalRecordActivity'
  import InvoiceActivity from '../../../components/v2/activity/InvoiceActivity'
  import PatientActivity from '../../../components/v2/activity/PatientActivity'
  import PatientInterviewActivity from '../../../components/v2/activity/PatientInterviewActivity'
  import ReservationActivity from '../../../components/v2/activity/ReservationActivity'
  import { safeGet, showErrorModal } from '../../../utils/helpers'
  import { ACTIVITY_LOG_TYPE } from '../../../utils/constants'
  import PaginationCommon from '../../../components/common/PaginationCommon'

  export default {
    components: {
      Loading,
      MedicalRecordActivity,
      InvoiceActivity,
      PatientActivity,
      PatientInterviewActivity,
      ReservationActivity,
      PaginationCommon
    },
    data () {
      return {
        page: 1,
        last_page: 1,
        activities_data: [],
        invoice_data: {},
        reservation_data: {},
        patient_id: '',
        activity_log_type: ACTIVITY_LOG_TYPE,
        is_loading: true
      }
    },
    computed: {
      maxCreatedAt: function () {
        let dates = []
        dates = this.activities_data

        const lists = []
        let maxDate = ''
        let createdAt = ''
        $.each(dates, function (key, value) {
          if (value.type === ACTIVITY_LOG_TYPE.RESERVATION) {
            createdAt = value ? value.created_at : ''
            lists.push(createdAt)
          }
        })

        maxDate = lists.reduce(function (a, b) { return a > b ? a : b })
        return maxDate
      },
      patient: function () {
        return this.$store.getters.get_patient
      },
      patientInfo: function () {
        return this.patient.info
      },
      patientFullName: function () {
        return this.patientInfo.last_name.length === 0 || this.patientInfo.first_name.length === 0
          ? this.patientInfo.name
          : `${this.patientInfo.last_name} ${this.patientInfo.first_name}`
      }
    },
    created () {
      document.title = this.$t('objects.page_title.patient').toString()
      this.id = this.$route.params.id
      this.patient_id = this.id
      this.getPatientActivities()
    },
    methods: {
      getPatientActivities: async function (page) {
        if (this.page === page) {
          return
        }
        this.page = page

        this.$store.commit('set_loading', true)
        this.is_loading = true

        await this.$store.dispatch('getPatientActivities', {
          page: this.page,
          patient_id: this.patient_id,
          type: this.display_mode
        }).then((result) => {
          const data = safeGet(result.data, 'results')
          const activitiesData = safeGet(data, 'activities') || []
          const invoiceData = safeGet(data, 'invoice') || {}
          const reservationData = safeGet(data, 'reservation') || {}

          this.activities_data = []
          this.activities_data.push(...activitiesData)
          this.page = safeGet(data, 'current_page')
          this.last_page = safeGet(data, 'last_page')
          for (const id in invoiceData) {
            this.$set(this.invoice_data, id, invoiceData[id])
          }
          for (const id in reservationData) {
            this.$set(this.reservation_data, id, reservationData[id])
          }

          this.is_loading = false
          this.$store.commit('set_loading', false)
        }).catch(() => {
          this.is_loading = false
          this.$store.commit('set_loading', false)
          showErrorModal(this.$t('new_message.something_went_wrong'))
        })
      }
    }
  }
</script>
