const reservation = {
  attributes: {
    allocation_id: '枠設定 ID',
    clinics_id: 'クリニック ID',
    ended_from: '終了日時',
    id: 'ID',
    patient_id: '患者ID',
    started_to: '予約開始日時'
  },
  invalid: '{attribute}は有効な{attribute}ではありません',
  invalid_date: '終了日は開始日より後の日付を設定してください',
  memo_maxlength: '文字列が長すぎます。\n半角で65,535文字まで入力できます',
  must_be_date: '日付である必要があります',
  required: '{attribute}を入力してください',
  reservation_canceled: '該当の予約はすでにキャンセルされています',
  reservations_already_exist: '予約が入っています'
}

export default reservation
