var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "modal-person-select__v2",
        { "modal-person-select__v2--hide-menu": _vm.hidePositionName }
      ]
    },
    [
      _c("modal-action", {
        attrs: { title: _vm.$t("new_text.person_in_charge") },
        on: {
          close: function($event) {
            return _vm.$emit("close")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("div", { staticClass: "position-content" }, [
                  !_vm.hidePositionName
                    ? _c("div", { staticClass: "position-menu" }, [
                        _c(
                          "ul",
                          _vm._l(_vm.positionOrdered, function(item) {
                            return _c(
                              "li",
                              {
                                key: item.id,
                                class: {
                                  active: _vm.active_el
                                    ? _vm.active_el === item.id
                                    : item.id === _vm.activeFirst
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.selectPerson(item.id)
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "position-name" }, [
                                  _vm._v(_vm._s(item.name))
                                ]),
                                _vm._v(" "),
                                item.staff_count !== 0
                                  ? _c("span", { staticClass: "caret" })
                                  : _vm._e()
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "position-filter" }, [
                    _c(
                      "ul",
                      { staticClass: "nav nav-tabs" },
                      _vm._l(_vm.staffs, function(staff, key) {
                        return _c("li", { key: key, staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              class: {
                                "nav-link": true,
                                active: key === _vm.filter_active,
                                disabled: _vm.getNumberStaffs(key) === 0
                              },
                              attrs: {
                                "data-toggle": "tab",
                                href: "#menu_" + key
                              },
                              on: {
                                click: function($event) {
                                  return _vm.selectFilter(key)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.filter_chars[key]) +
                                  "\n              "
                              )
                            ]
                          )
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content" },
                      _vm._l(_vm.staffs, function(staff, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            class: {
                              "tab-pane": true,
                              active: index === _vm.filter_active
                            },
                            attrs: { id: "menu_" + index }
                          },
                          _vm._l(staff, function(item, keyItem) {
                            return _c(
                              "div",
                              {
                                key: keyItem,
                                staticClass: "staff-block",
                                on: {
                                  click: function($event) {
                                    return _vm.updateStaffData(item)
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.name) +
                                      "\n                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "romaji-name" }, [
                                  _vm._v(
                                    "\n                  (" +
                                      _vm._s(item.name_romaji) +
                                      ")\n                "
                                  )
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      }),
                      0
                    )
                  ])
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }