<template>
  <transition name="modal">
    <div :class="['mc-modal edit-staff', customClass]">
      <div class="edit-staff__wrapper">
        <div class="edit-staff__header">
          <h2 class="edit-staff__heading">
            {{ $t('objects.setting.text.edit_information') }}
          </h2>
          <div
            class="edit-staff__close"
            @click="$emit('close')"
          >
            <img src="/images/icon-close-modal-blue.svg">
          </div>
        </div>
        <div class="edit-staff__body">
          <h2 class="edit-staff__title">
            {{ $t('objects.setting.text.staff_information') }}
          </h2>
          <div class="edit-staff__row">
            <div class="edit-staff__row edit-staff__row--left">
              {{ $t('new_text.name') }}
            </div>
            <div class="edit-staff__row edit-staff__row--right edit-staff__row--top">
              <div class="edit-staff__column edit-staff__column--1">
                <input-common
                  :default-value="lastname"
                  :class="{ 'common-input-error': errors.lastname }"
                  :placeholder="$t('new_text.placeholder.last_name')"
                  maxlength="80"
                  @input="updateLastName"
                />
                <span
                  v-if="errors.lastname"
                  class="common-msg-error"
                >
                  {{ errors.lastname }}
                </span>
              </div>
              <div class="edit-staff__column edit-staff__column--2">
                <input-common
                  :default-value="firstname"
                  :class="{ 'common-input-error': errors.firstname }"
                  :placeholder="$t('new_text.placeholder.first_name')"
                  :maxlength="40"
                  @input="updateFirstName"
                />
                <span
                  v-if="errors.firstname"
                  class="common-msg-error"
                >
                  {{ errors.firstname }}
                </span>
              </div>
            </div>
          </div>
          <div class="edit-staff__row">
            <div class="edit-staff__row edit-staff__row--left">
              {{ $t('new_text.roman') }}
            </div>
            <div class="edit-staff__row edit-staff__row--right edit-staff__row--top">
              <div class="edit-staff__column edit-staff__column--1">
                <input-common
                  :default-value="lastname_roma"
                  :class="{ 'common-input-error': errors.lastname_roma }"
                  :placeholder="$t('new_text.placeholder.sei')"
                  maxlength="191"
                  @input="updateLastNameRoma"
                />
                <span
                  v-if="errors.lastname_roma"
                  class="common-msg-error"
                >
                  {{ errors.lastname_roma }}
                </span>
              </div>
              <div class="edit-staff__column edit-staff__column--2">
                <input-common
                  :default-value="firstname_roma"
                  :class="{ 'common-input-error': errors.firstname_roma }"
                  :placeholder="$t('new_text.placeholder.mei')"
                  :maxlength="191"
                  @input="updateFirstNameRoma"
                />
                <span
                  v-if="errors.firstname_roma"
                  class="common-msg-error"
                >
                  {{ errors.firstname_roma }}
                </span>
              </div>
            </div>
          </div>
          <div class="edit-staff__row">
            <div class="edit-staff__row edit-staff__row--left">
              {{ $t('new_text.position') }}
            </div>
            <div class="edit-staff__row edit-staff__row--right">
              <div class="edit-staff__column edit-staff__column--1">
                <select-common
                  class="edit-staff__select"
                  :options="positionList"
                  :default-value="position_id"
                  :placeholder="$t('objects.setting.button.please_select')"
                  @change="updatePosition"
                />
              </div>
              <div class="edit-staff__column edit-staff__column--2">
                <label class="edit-staff__label">{{ $t('objects.setting.text.is_admin') }}</label>
                <div class="radio-group h-align">
                  <div
                    v-for="(item, index) in admin_role_options"
                    :key="index"
                    class="edit-staff__radio"
                    @click="is_admin = item.value"
                  >
                    <radio-common
                      :label="item.label"
                      :val="item.value"
                      :is-checked="staffInfo.is_admin === item.value"
                      :name="'admin_role'"
                      @select="updateIsAdmin"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2 class="edit-staff__title">
            {{ $t('new_text.login_information') }}
          </h2>
          <div class="edit-staff__row">
            <div class="edit-staff__row edit-staff__row--left">
              {{ $t('objects.setting.text.staff_id') }}
            </div>
            <div class="edit-staff__row edit-staff__row--right">
              <div class="create-staff__space">
                <input-common
                  :default-value="staff_id"
                  :class="{ 'common-input-error': errors.staff_id }"
                  :placeholder="$t('new_validation.common.required', { attribute: $t('objects.setting.text.staff_id') })"
                  maxlength="191"
                  @input="updateStaffId"
                />
                <span
                  v-if="errors.staff_id"
                  class="common-msg-error"
                >
                  {{ errors.staff_id }}
                </span>
              </div>
              <label class="edit-staff__label edit-staff__label--13">{{ $t('objects.setting.text.staff_id_login_rule') }}</label>
            </div>
          </div>
          <div class="edit-staff__row">
            <div class="edit-staff__row edit-staff__row--left">
              {{ $t('objects.setting.text.email_address_optional') }}
            </div>
            <div class="edit-staff__row edit-staff__row--right">
              <div class="create-staff__space">
                <input-common
                  :class="{ 'common-input-error': errors.staff_email }"
                  :default-value="staff_email"
                  :placeholder="$t('new_validation.common.required', { attribute: $t('new_validation.common.attributes.email') })"
                  maxlength="80"
                  @input="updateStaffEmail"
                />
                <span
                  v-if="errors.staff_email"
                  class="common-msg-error"
                >
                  {{ errors.staff_email }}
                </span>
              </div>
            </div>
          </div>
          <div class="edit-staff__row">
            <div class="edit-staff__row edit-staff__row--left">
              {{ $t('objects.setting.text.password_init') }}
            </div>
            <div class="edit-staff__row edit-staff__row--right">
              <div class="create-staff__space">
                <input-common
                  :default-value="password"
                  :class="{ 'common-input-error': errors.password }"
                  :placeholder="$t('new_validation.common.required', { attribute: $t('objects.setting.text.password_init') })"
                  maxlength="191"
                  type="password"
                  autocomplete="new-password"
                  @input="updateStaffPassword"
                />
                <span
                  v-if="errors.password"
                  class="common-msg-error"
                >
                  {{ errors.password }}
                </span>
              </div>
              <label class="edit-staff__label edit-staff__label--13">{{ $t('objects.setting.text.password_min') }}</label>
            </div>
          </div>
        </div>
        <div :class="['edit-staff__footer', {'edit-staff__footer--delete': !isLabDesigner}]">
          <button-common
            v-if="!isLabDesigner"
            class="edit-staff__button edit-staff__button--delete"
            @click="$emit('show-modal-confirm-delete', staffInfo.id)"
          >
            <template #content>
              {{ $t('new_text.button.delete') }}
            </template>
          </button-common>
          <div class="edit-staff__inner edit-staff__inner--right">
            <button-common
              :class="['edit-staff__button edit-staff__button--reset-password', {'disable' : !staff_email}]"
              :disabled="!staff_email"
              @click="submitForm(true)"
            >
              <template #content>
                {{ $t('objects.setting.text.reset_password') }}
              </template>
            </button-common>
            <button-common
              class="edit-staff__button edit-staff__button--save"
              @click="submitForm"
            >
              <template #content>
                {{ $t('new_text.button.save') }}
              </template>
            </button-common>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import { showValidationErrorModal, checkInvalidRomaji, isEmail } from '../../utils/helpers.js'
  import { VALIDATE_RULE } from '../../utils/constants'
  import { required, minLength, maxLength, alphaNum } from 'vuelidate/lib/validators'
  import InputCommon from '../common/Input'
  import ButtonCommon from '../common/Button'
  import SelectCommon from '../common/Select'
  import RadioCommon from '../common/Radio'

  export default {
    components: {
      InputCommon,
      ButtonCommon,
      RadioCommon,
      SelectCommon
    },
    props: {
      customClass: {
        type: String,
        default: ''
      },
      positionList: {
        type: Array,
        default: () => []
      },
      staffInfo: {
        type: Object,
        default: () => {}
      }
    },
    emits: ['delete-staff', 'close', 'show-modal-confirm-delete'],
    data () {
      return {
        id: '',
        firstname: '',
        lastname: '',
        firstname_roma: '',
        lastname_roma: '',
        position: {},
        position_id: '',
        admin_role_options: [
          {
            value: 0,
            label: this.$t('new_text.button.none')
          },
          {
            value: 1,
            label: this.$t('new_text.button.yes')
          }
        ],
        is_admin: 0,
        staff_id: '',
        staff_email: '',
        password: '',
        errors: {}
      }
    },
    validations () {
      const rules = {
        firstname: { required, customJapaneseName: VALIDATE_RULE.CUSTOM_JAPANESE_NAME, maxLength: maxLength(40) },
        lastname: { required, customJapaneseName: VALIDATE_RULE.CUSTOM_JAPANESE_NAME, maxLength: maxLength(80) },
        firstname_roma: { required },
        lastname_roma: { required },
        position: { required },
        staff_id: { required, customAlphaNumeric: VALIDATE_RULE.CUSTOM_ALPHA_NUMERIC },
        password: { alphaNum, minLength: minLength(8) }
      }
      if (this.staff_email) {
        rules.staff_email = { isEmail, maxLength: maxLength(80) }
      }
      return rules
    },
    computed: {
      isLabDesigner: function () {
        return this.$store.getters.isLabDesigner
      }
    },
    created () {
      this.showStaffInfo()
    },
    methods: {
      validate: function () {
        const errors = []
        const errorMessages = {
          'lastname.required': this.$t('new_validation.common.last_name_required'),
          'lastname.customJapaneseName': this.$t('new_validation.common.invalid', { attribute: this.$t('new_text.placeholder.last_name') }),
          'lastname.maxLength': this.$t('new_validation.common.maximum', {
            attribute: this.$t('new_text.last_name'),
            max: 80
          }),
          'firstname.required': this.$t('new_validation.common.first_name_required'),
          'firstname.customJapaneseName': this.$t('new_validation.common.invalid', { attribute: this.$t('new_text.placeholder.first_name') }),
          'firstname.maxLength': this.$t('new_validation.common.maximum', {
            attribute: this.$t('new_text.first_name'),
            max: 40
          }),
          'lastname_roma.required': this.$t('new_validation.common.romaji_last_name_required'),
          'firstname_roma.required': this.$t('new_validation.common.romaji_first_name_required'),
          'position.required': this.$t('new_validation.common.required', { attribute: this.$t('new_validation.common.attributes.position') }),
          'staff_id.required': this.$t('new_validation.common.required', { attribute: this.$t('new_validation.common.attributes.staff_id') }),
          'staff_id.customAlphaNumeric': this.$t('objects.setting.text.staff_id_login_rule'),
          'staff_email.required': this.$t('new_validation.common.required', { attribute: this.$t('new_validation.common.attributes.email') }),
          'staff_email.isEmail': this.$t('new_validation.common.invalid_email'),
          'staff_email.maxLength': this.$t('new_validation.common.maximum', {
            attribute: this.$t('new_validation.common.attributes.email'),
            max: 80
          }),
          'password.required': this.$t('new_validation.common.required', { attribute: this.$t('new_validation.common.attributes.password') }),
          'password.alphaNum': this.$t('new_validation.staff.min_password', { min: 8 }),
          'password.minLength': this.$t('new_validation.staff.min_password', { min: 8 })
        }
        for (const key in errorMessages) {
          const parts = key.split('.')
          if (this.$v[parts[0]] && this.$v[parts[0]][parts[1]] === false) {
            this.errors[parts[0]] = errorMessages[key]
            errors.push(errorMessages[key])
          }
        }

        const validationHaffSizeLastRomajiName = this.checkHaffSizeRomajiName(this.lastname_roma)
        const validationHaffSizeFirstRomajiName = this.checkHaffSizeRomajiName(this.firstname_roma)
        if (validationHaffSizeLastRomajiName !== '' || validationHaffSizeFirstRomajiName !== '') {
          errors.push(validationHaffSizeFirstRomajiName || validationHaffSizeLastRomajiName)
        }

        const filtered = errors.filter(function (el) {
          return el != null
        })

        if (filtered.length) {
          showValidationErrorModal(filtered)
          return false
        }
        return true
      },
      submitForm: function (isResetPassword = false) {
        this.errors = {}
        if (!this.validate()) return

        const submitData = {
          id: this.id,
          firstname: this.firstname,
          lastname: this.lastname,
          firstname_roma: this.firstname_roma,
          lastname_roma: this.lastname_roma,
          position_id: this.position_id,
          is_admin: this.is_admin,
          staff_id: this.staff_id,
          email: this.staff_email,
          is_reset_password: isResetPassword
        }
        if (this.password !== '') {
          submitData.password = this.password
        }

        this.$emit('update-staff', submitData)
      },
      resetForm: function () {
        this.firstname = ''
        this.lastname = ''
        this.firstname_roma = ''
        this.lastname_roma = ''
        this.position = {}
        this.position_id = ''
        this.is_admin = 0
        this.staff_id = ''
        this.staff_email = ''
        this.send_mail = true
        this.password = ''
      },
      showStaffInfo: function () {
        this.id = this.staffInfo.id
        this.firstname = this.staffInfo.firstname
        this.lastname = this.staffInfo.lastname
        this.firstname_roma = this.staffInfo.firstname_roma
        this.lastname_roma = this.staffInfo.lastname_roma
        this.staff_email = this.staffInfo.email
        this.staff_id = this.staffInfo.login_id
        this.position = this.staffInfo.position
        this.position_id = this.staffInfo.position_id
        this.is_admin = this.staffInfo.is_admin
      },
      updateFirstName: function (data) {
        this.firstname = data
      },
      updateFirstNameRoma: function (data) {
        this.firstname_roma = data
      },
      updateLastName: function (data) {
        this.lastname = data
      },
      updateLastNameRoma: function (data) {
        this.lastname_roma = data
      },
      updateStaffId: function (data) {
        this.staff_id = data
      },
      updateStaffEmail: function (data) {
        this.staff_email = data
      },
      updateStaffPassword: function (data) {
        this.password = data
      },
      updatePosition: function (positionId) {
        this.position_id = positionId
        this.position = this.positionList.find(item => item.id === positionId)
      },
      updateIsAdmin: function (data) {
        this.is_admin = data
      },
      checkHaffSizeRomajiName (value) {
        if (value === '') {
          return ''
        }

        if (checkInvalidRomaji(value)) {
          return this.$t('new_validation.common.romaji_haffsize')
        }
      }
    }
  }
</script>
