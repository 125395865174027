var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "setting-v2--background setting-v2__content setting-v2__content--reservation-type"
    },
    [
      _c("div", { staticClass: "setting-v2__header" }, [
        _c("div", { staticClass: "setting-v2__header__box" }, [
          _c("label", { staticClass: "setting-v2__header__box__text" }, [
            _vm._v(
              _vm._s(_vm.$t("objects.setting.text.reservation_type_setting"))
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "setting-v2__main" },
        [_c("reservation-type-setting")],
        1
      ),
      _vm._v(" "),
      _c("loading")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }