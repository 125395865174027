var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popover show modal-confirm-reservation-change" },
    [
      _c("div", { staticClass: "arrow" }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-confirm-reservation-change__overplay" }),
      _vm._v(" "),
      _c("h3", {
        staticClass: "popover-title modal-confirm-reservation-change__title"
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-confirm-reservation-change__time-range" },
        [
          _c(
            "div",
            { staticClass: "modal-confirm-reservation-change__time-change" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.startDate) +
                  " 〜 " +
                  _vm._s(_vm.endDate) +
                  "\n    "
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-confirm-reservation-change__footer" },
        [
          _c("button-common", {
            staticClass: "btn-cancel",
            attrs: { "data-dismiss": "confirmation" }
          }),
          _vm._v(" "),
          _c("button-common", {
            staticClass: "btn-confirm",
            attrs: { "data-apply": "confirmation" }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }