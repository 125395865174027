<template>
  <div class="modal-search-patient">
    <modal-action
      :title="$t('reservation.select_patient')"
      @close="$emit('close')"
    >
      <template #body>
        <div class="modal-search-patient__content">
          <ul :class="['nav nav-tabs', 'modal-search-patient__tabs', {'lazy-load': lazy_load}]">
            <li class="nav-item">
              <a
                :class="[
                  'nav-link modal-search-patient__tab-all',
                  selected_col === 'all' ? 'active' : '',
                  count_data['total'] > 0 ? '' : 'disabled'
                ]"
                data-toggle="tab"
                href="#col_all"
                data-col="all"
                @click="selectColumn('all')"
              >すべて</a>
            </li>
            <li class="nav-item">
              <a
                :class="[
                  'nav-link',
                  selected_col === 'a' ? 'active' : '',
                  getNumberPatientsOfTab('a') > 0 ? '' : 'disabled'
                ]"
                data-toggle="tab"
                href="#col_a"
                data-col="a"
                @click="selectColumn('a')"
              >あ</a>
            </li>
            <li class="nav-item">
              <a
                :class="[
                  'nav-link',
                  selected_col === 'ka' ? 'active' : '',
                  getNumberPatientsOfTab('ka') > 0 ? '' : 'disabled'
                ]"
                data-toggle="tab"
                href="#col_ka"
                data-col="ka"
                @click="selectColumn('ka')"
              >か</a>
            </li>
            <li class="nav-item">
              <a
                :class="[
                  'nav-link',
                  selected_col === 'sa' ? 'active' : '',
                  getNumberPatientsOfTab('sa') > 0 ? '' : 'disabled'
                ]"
                data-toggle="tab"
                href="#col_sa"
                data-col="sa"
                @click="selectColumn('sa')"
              >さ</a>
            </li>
            <li class="nav-item">
              <a
                :class="[
                  'nav-link',
                  selected_col === 'ta' ? 'active' : '',
                  getNumberPatientsOfTab('ta') > 0 ? '' : 'disabled'
                ]"
                data-toggle="tab"
                href="#col_ta"
                data-col="ta"
                @click="selectColumn('ta')"
              >た</a>
            </li>
            <li class="nav-item">
              <a
                :class="[
                  'nav-link',
                  selected_col === 'na' ? 'active' : '',
                  getNumberPatientsOfTab('na') > 0 ? '' : 'disabled'
                ]"
                data-toggle="tab"
                href="#col_na"
                data-col="na"
                @click="selectColumn('na')"
              >な</a>
            </li>
            <li class="nav-item">
              <a
                :class="[
                  'nav-link',
                  selected_col === 'ha' ? 'active' : '',
                  getNumberPatientsOfTab('ha') > 0 ? '' : 'disabled'
                ]"
                data-toggle="tab"
                href="#col_ha"
                data-col="ha"
                @click="selectColumn('ha')"
              >は</a>
            </li>
            <li class="nav-item">
              <a
                :class="[
                  'nav-link',
                  selected_col === 'ma' ? 'active' : '',
                  getNumberPatientsOfTab('ma') > 0 ? '' : 'disabled'
                ]"
                data-toggle="tab"
                href="#col_ma"
                data-col="ma"
                @click="selectColumn('ma')"
              >ま</a>
            </li>
            <li class="nav-item">
              <a
                :class="[
                  'nav-link',
                  selected_col === 'ya' ? 'active' : '',
                  getNumberPatientsOfTab('ya') > 0 ? '' : 'disabled'
                ]"
                data-toggle="tab"
                href="#col_ya"
                data-col="ya"
                @click="selectColumn('ya')"
              >や</a>
            </li>
            <li class="nav-item">
              <a
                :class="[
                  'nav-link',
                  selected_col === 'ra' ? 'active' : '',
                  getNumberPatientsOfTab('ra') > 0 ? '' : 'disabled'
                ]"
                data-toggle="tab"
                href="#col_ra"
                data-col="ra"
                @click="selectColumn('ra')"
              >ら</a>
            </li>
            <li class="nav-item">
              <a
                :class="[
                  'nav-link',
                  selected_col === 'wa' ? 'active' : '',
                  getNumberPatientsOfTab('wa') > 0 ? '' : 'disabled'
                ]"
                data-toggle="tab"
                href="#col_wa"
                data-col="wa"
                @click="selectColumn('wa')"
              >わ</a>
            </li>
          </ul>
          <div class="modal-search-patient__tab-content">
            <table class="table">
              <thead>
                <tr>
                  <th class="modal-search-patient__id">
                    {{ $t('common.ID') }}
                  </th>
                  <th class="modal-search-patient__name">
                    {{ $t('common.name') }}
                  </th>
                  <th class="modal-search-patient__sf-status">
                    {{ $t('reservation.reservation_status') }}
                  </th>
                  <th class="modal-search-patient__time" />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(p, index) in patients"
                  :key="index"
                  @click="selectionHandler(p)"
                >
                  <td class="modal-search-patient__id">
                    {{ p.sf_user_id ? p.sf_user_id : p.patient_id }}
                  </td>
                  <td class="modal-search-patient__name">
                    {{ p.last_name }}{{ p.first_name }}({{ p.kana_last_name }} {{ p.kana_first_name }})
                  </td>
                  <td class="modal-search-patient__sf-status">
                    {{ patientSFStatus(p.sf_status_c) }}
                  </td>
                  <td class="modal-search-patient__time">
                    <span class="modal-search-patient__patient-status">{{ patientStatus(p.sf_status_c) }}</span>
                    {{ patientVisitedOrCancelledDate(p) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <infinite-loading
              :identifier="infiniteScrollId"
              @infinite="infiniteScrollHandler"
            >
              <div
                slot="no-more"
                class="result-loader"
              />
              <span
                slot="no-results"
                class="result-loader"
              >
                {{ $t('patient.modal.search_no_result') }}
              </span>
            </infinite-loading>
          </div>
        </div>
      </template>
    </modal-action>
  </div>
</template>

<script>
  import InfiniteLoading from 'vue-infinite-loading'
  import helpers from '../../utils/helpers'
  import ModalAction from './ModalAction'

  export default {
    components: {
      InfiniteLoading,
      ModalAction
    },
    props: {
      sfClinicId: {
        type: String,
        default: ''
      },
      keyword: {
        type: String,
        default: ''
      },
      isRequiredSfUserId: {
        type: Boolean,
        default: false
      }
    },
    emits: ['select', 'close'],
    data () {
      return {
        count_data: '',
        selected_col: 'all',
        selected_letter: '',
        infiniteScrollId: 1,
        page: 1,
        patients: [],
        patient_count: 0,
        count_loaded: 0,
        loading: false,
        lazy_load: true
      }
    },
    computed: {
      isLabDesigner: function () {
        return this.$store.getters.isLabDesigner
      },
      isInstructionAdmin () {
        return this.$store.getters.isInstructionAdmin
      }
    },
    created: function () {
      this.keywordData = this.keyword
      this.requestPatientCountData()
    },
    methods: {
      selectionHandler: function (patientInfo) {
        this.$emit('select', {
          id: patientInfo.patient_id,
          name: this.getPatientFullName(patientInfo),
          sf_user_id: patientInfo.sf_user_id,
          sf_status_c: patientInfo.sf_status_c,
          first_reservation_id: patientInfo.first_reservation_id || 0,
          brand_text: patientInfo.brand_master_name,
          roma_last_name: patientInfo.roma_last_name,
          roma_first_name: patientInfo.roma_first_name,
          sf_clinic_id: patientInfo.sf_clinic_id,
          formal_name: patientInfo.formal_name,
          order_count: patientInfo.order_count,
          sf_pre_contract_simulation: patientInfo.sf_pre_contract_simulation
        })
      },
      infiniteScrollHandler: function ($state) {
        if (this.loading) {
          return
        }

        this.loading = true
        this.getListPatient().then((result) => {
          const loadPatients = result?.data?.results?.data || []
          this.patient_count = result?.data?.results?.total || 0

          if (loadPatients.length) {
            this.patients.push(...loadPatients)
            this.page++
            this.count_loaded += loadPatients.length

            if (this.count_loaded < this.patient_count) {
              $state.loaded()
            } else {
              if (this.page === 2) {
                $state.loaded()
                this.$refs.infiniteLoading.stateChanger.status = 0
              }

              $state.complete()
            }
          } else {
            if (this.page === 1) {
              $state.reset()
            }
            $state.complete()
          }

          this.loading = false
        }).catch(() => {
          this.loading = false
          $state.complete()
        })
      },
      getPatientFullName: function (item) {
        return `${item.last_name || ''} ${item.first_name || ''}`
      },
      getNumberPatientsOfTab: function (dataCol) {
        return this.count_data[['count_' + dataCol]]
      },
      selectColumn: function (column) {
        this.selected_col = column
        this.selected_letter = column === 'all' ? '' : column
        this.page = 1
        this.patients = []
        this.count_loaded = 0
        this.patient_count = 0
        this.infiniteScrollId += 1
      },
      requestPatientCountData: function () {
        this.lazy_load = true
        this.$store.dispatch('getPatientCount').then((result) => {
          this.lazy_load = false
          this.count_data = result?.data?.results || ''
        }).catch(() => {
          this.lazy_load = false
        })
      },
      patientSFStatus: function (key) {
        const salesforceStatusC = this.$t('patient.salesforce_status_c')
        return salesforceStatusC[key]
      },
      patientStatus: function (status) {
        if (status === 0) {
          return this.$t('patient.cancellation_time')
        }

        return this.$t('patient.first_visit_time')
      },
      patientVisitedOrCancelledDate: function (patient) {
        if (patient.sf_status_c === 0) {
          return helpers.formatDateTime(patient.sf_cancelled_reservation_date, this.$t('datetime.year_month_day_hour_minute'))
        }
        if (patient.sf_next_reservation_date !== null) {
          return helpers.formatDateTime(patient.sf_next_reservation_date, this.$t('datetime.year_month_day_hour_minute'))
        }

        return this.$t('staff.none')
      },
      getListPatient () {
        const sfClinicId = this.isLabDesigner ? this.sfClinicId
          : this.isInstructionAdmin ? 0
            : this.$store.getters.currentClinic.sf_clinic_id
        this.lazy_load = true

        return this.$store.dispatch('getPatientsByName', {
          sf_clinic_id: sfClinicId,
          search_keyword: this.keyword,
          page: this.page,
          first_letter: this.selected_letter,
          is_required_sf_user_id: this.isRequiredSfUserId
        }).finally(() => {
          this.lazy_load = false
        })
      }
    }
  }
</script>
