import axios from 'axios'
import { state } from './auth'

export const actions = {

  getWhiteList ({ dispatch, commit }, { page }) {
    return axios.get('/api/whitelist', {
      params: { page },
      headers: { Authorization: 'Bearer ' + state.token }
    })
  },
  getWhiteListDetail ({ dispatch, commit }, param) {
    return axios.get('/api/whitelist/show', {
      params: {
        id: param.id
      },
      headers: { Authorization: 'Bearer ' + state.token }

    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  updateWhiteList ({ dispatch, commit }, submitData) {
    return axios.post('/api/whitelist/update', {
      id: submitData.id,
      label: submitData.ip_label,
      ip_address: submitData.ip_address,
      status: submitData.ip_status

    },
    {
      headers: { Authorization: 'Bearer ' + state.token }
    }
    )
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  deleteWhiteList ({ dispatch, commit }, submitData) {
    return axios.post('/api/whitelist/delete', {
      id: submitData.id
    },
    {
      headers: { Authorization: 'Bearer ' + state.token }
    }
    )
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  showMyIpAddress ({ dispatch, commit }, param) {
    return axios.get('/api/whitelist/my-ip-address', {
      params: {},
      headers: { Authorization: 'Bearer ' + state.token }

    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  insertIpAddress () {
    return axios.get('/api/whitelist/insert-ip-address', {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  }
}
