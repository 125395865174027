var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-v2__header" },
    [
      _c(
        "ul",
        { staticClass: "layout-v2__header__menu" },
        [
          _c(
            "li",
            {
              class: [
                "layout-v2__header__logo",
                {
                  "layout-v2__header__logo--only-order-detail":
                    _vm.isOnlyOrderDetail
                }
              ]
            },
            [
              _c("img", { attrs: { src: "/images/logo-header-v2.svg" } }),
              _vm._v(" "),
              _vm.isShowNotificationIcon && _vm.typeNotification
                ? _c(
                    "div",
                    {
                      staticClass: "layout-v2__header__notification",
                      on: {
                        click: function($event) {
                          return _vm.$emit("show-notification")
                        }
                      }
                    },
                    [
                      _c("lottie-player", {
                        attrs: {
                          id: "notification-icon",
                          src:
                            "/images/icon-lottie/icon-notification-" +
                            _vm.typeNotification +
                            ".json",
                          loop: "",
                          autoplay: !_vm.isRead
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          !_vm.isOnlyOrderDetail
            ? [
                _vm.show_reservation &&
                !_vm.isLabDesigner &&
                !_vm.isInstructionAdmin
                  ? _c(
                      "li",
                      [
                        _vm.current_page === "secretariat"
                          ? _c(
                              "router-link",
                              {
                                attrs: { to: { name: "reservation.list" } },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.handleChangeRoute(
                                      "reservation.list"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$t(
                                        "sidebar_menu.reservation_calendar"
                                      )
                                    ) +
                                    "\n        "
                                )
                              ]
                            )
                          : _c(
                              "router-link",
                              {
                                attrs: { to: { name: "dashboard" } },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.handleChangeRoute("dashboard")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$t(
                                        "sidebar_menu.reservation_calendar"
                                      )
                                    ) +
                                    "\n        "
                                )
                              ]
                            )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_clinics_dmr &&
                !_vm.isLabDesigner &&
                !_vm.isInstructionAdmin
                  ? _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: { name: "reception" } },
                            nativeOn: {
                              click: function($event) {
                                return _vm.handleChangeRoute("reception")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("sidebar_menu.recept")) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isLabDesigner && !_vm.isInstructionAdmin
                  ? _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: { name: "patient.list" } },
                            nativeOn: {
                              click: function($event) {
                                return _vm.handleChangeRoute("patient.list")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("sidebar_menu.patient")) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_clinics_dmr &&
                !_vm.isLabDesigner &&
                !_vm.isInstructionAdmin
                  ? _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: { name: "accounting.list" } },
                            nativeOn: {
                              click: function($event) {
                                return _vm.handleChangeRoute("accounting.list")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("sidebar_menu.accounting")) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_show_order
                  ? _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            class: _vm.getClass,
                            attrs: { to: { name: "order.search" } },
                            nativeOn: {
                              click: function($event) {
                                return _vm.handleChangeRoute("order.search")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("sidebar_menu.order")) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isLabDesigner && !_vm.isInstructionAdmin
                  ? _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: { name: "questionnaire" } },
                            nativeOn: {
                              click: function($event) {
                                return _vm.handleChangeRoute("questionnaire")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("new_text.questionnaire_visit")) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isLabDesigner && !_vm.isInstructionAdmin
                  ? _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: _vm.hideSubMenu,
                            expression: "hideSubMenu"
                          }
                        ],
                        staticClass: "layout-v2__header__sub-menu",
                        on: {
                          click: function($event) {
                            return _vm.toggleSubMenu("help_link")
                          }
                        }
                      },
                      [
                        _c("a", { attrs: { href: "javascript:void(0)" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("new_text.supporting_materials"))
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.sub_menu_show === "help_link" &&
                                  _vm.supportMaterialLinks.length !== 0,
                                expression:
                                  "sub_menu_show === 'help_link' && supportMaterialLinks.length !== 0"
                              }
                            ]
                          },
                          _vm._l(_vm.supportMaterialLinks, function(item, key) {
                            return _c(
                              "li",
                              {
                                key: key,
                                on: {
                                  mouseup: function($event) {
                                    if (
                                      "button" in $event &&
                                      $event.button !== 1
                                    ) {
                                      return null
                                    }
                                    return _vm.triggerClick(
                                      _vm.trigger_click_type.MENU,
                                      item.title
                                    )
                                  },
                                  click: function($event) {
                                    return _vm.triggerClick(
                                      _vm.trigger_click_type.MENU,
                                      item.title
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href: item.display_link
                                    }
                                  },
                                  [_vm._v(_vm._s(item.display_title))]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      !_vm.isOnlyOrderDetail
        ? [
            _c("div", { staticClass: "layout-v2__header__info" }, [
              _c("ul", [
                _vm.is_loading
                  ? _c("li", { staticClass: "layout-v2__header__name" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.clinic_formal_name || _vm.$t("new_text.admin")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.staff_position) +
                            " " +
                            _vm._s(
                              _vm.staff_fullname || _vm.instruction_admin_name
                            )
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isLabDesigner && !_vm.isInstructionAdmin
                  ? _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: _vm.hiddenNotification,
                            expression: "hiddenNotification"
                          }
                        ],
                        class: [
                          "layout-v2__header__notify",
                          {
                            "layout-v2__header__notify--active":
                              _vm.show_notification_menu
                          }
                        ]
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "layout-v2__header__notify-cont",
                            on: { click: _vm.showNotification }
                          },
                          [
                            _vm.show_notification_menu || _vm.totalNotification
                              ? _c("img", {
                                  attrs: { src: "/images/bell-red.svg" }
                                })
                              : _c("img", {
                                  attrs: { src: "/images/bell-gray.svg" }
                                }),
                            _vm._v(" "),
                            _vm.totalNotification
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "layout-v2__header__notify-number"
                                  },
                                  [_vm._v(_vm._s(_vm.totalNotification))]
                                )
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _vm.show_notification_menu
                          ? _c("reservation-list", {
                              on: { "update-notify": _vm.UpdateNotify }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.hiddenSettingMenu,
                        expression: "hiddenSettingMenu"
                      }
                    ],
                    staticClass: "layout-v2__header__setting"
                  },
                  [
                    _c("span", { on: { click: _vm.showSettingMenu } }, [
                      _c("img", {
                        attrs: { src: "/images/icon-setting-gray.svg" }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.show_setting_menu,
                            expression: "show_setting_menu"
                          }
                        ],
                        staticClass: "layout-v2__header__setting__menu"
                      },
                      [
                        _vm.isInstructionAdmin
                          ? _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "layout-v2__header__setting__help",
                                  attrs: {
                                    href: _vm.link_help,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("sidebar_menu.help")) +
                                      "\n                "
                                  ),
                                  _c("img", {
                                    attrs: { src: "/images/icon-tab.svg" }
                                  })
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$store.getters.isAdmin
                          ? _c("li", [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.moveToSetting(
                                        "setting.account"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("sidebar_menu.preferences")
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$store.getters.isStaff && _vm.$store.getters.isAdmin
                          ? _c("li", [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.moveToSetting(
                                        "setting.basic-treatment-policy",
                                        true
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "new_text.basic_treatment_policy"
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: { click: _vm.showLanguageMenu }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("sidebar_menu.change_language")
                                  ) +
                                  "\n              "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "layout-v2__header__setting__logout",
                              attrs: { href: "#" },
                              on: { click: _vm.logOut }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("sidebar_menu.log_out")) +
                                  "\n              "
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.show_language_menu
                      ? _c(
                          "ul",
                          { staticClass: "layout-v2__header__setting__menu" },
                          [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  class: {
                                    "disabled-link": _vm.$i18n.locale === "ja"
                                  },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      return _vm.change_language("ja")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("sidebar_menu.japanese")) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  class: {
                                    "disabled-link": _vm.$i18n.locale === "en"
                                  },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      return _vm.change_language("en")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("sidebar_menu.english")) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "layout-v2__header__menu-mobi" }, [
              _c(
                "li",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.hiddenSidebarMenu,
                      expression: "hiddenSidebarMenu"
                    }
                  ],
                  staticClass: "layout-v2__header__sidebar"
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.show_sidebar_menu
                        ? "/images/icon-close-sidebar.svg"
                        : "/images/icon-menu.svg"
                    },
                    on: { click: _vm.showSidebarMenu }
                  }),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show_sidebar_menu,
                          expression: "show_sidebar_menu"
                        }
                      ],
                      staticClass: "layout-v2__header__sidebar__menu"
                    },
                    [
                      _c("div", {
                        staticClass: "layout-v2__header__sidebar__line"
                      }),
                      _vm._v(" "),
                      !_vm.isLabDesigner && !_vm.isInstructionAdmin
                        ? _c("li", [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.page_order === "order.create"
                                    ? "is-active"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.moveToOrder("order.create")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("order.sidebar.instructions")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "layout-v2__header__sidebar__line"
                      }),
                      _vm._v(" "),
                      _vm.is_show_order
                        ? _c("li", [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.page_order === "order.search"
                                    ? "is-active"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.moveToOrder("order.search")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("order.sidebar.search")) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "layout-v2__header__sidebar__line"
                      }),
                      _vm._v(" "),
                      _c("li", [
                        _c("a", { on: { click: _vm.logOut } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("sidebar_menu.log_out")) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "layout-v2__header__sidebar__line"
                      })
                    ]
                  )
                ]
              )
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }