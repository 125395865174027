<template>
  <button
    type="button"
    class="button-v2"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot name="content">
      {{ $t('common.save') }}
    </slot>
  </button>
</template>
<script>
  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false
      }
    },
    emits: ['click']
  }
</script>
