<template>
  <ModalList
    customClass="list-modal--reservation-setting"
    :is_show_cancellation_button="false"
    @close="closeModal"
    @confirm-button-click="closeModal"
  >
    <template #header>
      <h3 class="list-modal__title">
        {{ $t('reservation.warning_setting') }}
      </h3>
    </template>
    <template #body>
      <h3 class="list-modal__description">
        {{ $t('reservation.content_warning') }} <br> {{ $t('reservation.check_warning') }}
      </h3>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t('common.name') }}</th>
            <th>{{ $t('reservation.time_start_to') }}</th>
            <th>{{ $t('accounting_list_page.reservations_frame') }}</th>
          </tr>
        </thead>
        <tbody :class="{'overflow': renderClassOverflow}">
          <tr
            v-for="(reservation, index) in reservations"
            :key="index"
            @click="gotoReservationCalendar(reservation.started_to, reservation.id)"
          >
            <td>{{ reservation.p_sf_user_id || reservation.patient_id }}</td>
            <td>{{ `${reservation.name || ''} (${reservation.kana_last_name || ''} ${reservation.kana_first_name || ''})` }}</td>
            <td>{{ formatDate(reservation.started_to) }}</td>
            <td>{{ reservation.allocation ? (reservation.allocation.column_name || '') : '' }}</td>
          </tr>
        </tbody>
      </table>
    </template>
    <template #confirm_button_content>
      {{ $t('common.close') }}
    </template>
  </ModalList>
</template>

<script>
  import ModalList from '../ModalList'
  import * as moment from 'moment'

  export default {
    components: {
      ModalList
    },
    props: ['reservations'],
    emits: ['close'],
    computed: {
      renderClassOverflow: function () {
        if (window.innerWidth <= 1366) {
          return this.reservations.length >= 4
        }

        return this.reservations.length >= 5
      }
    },
    methods: {
      closeModal: function () {
        this.$emit('close')
      },
      formatDate: function (date) {
        return moment(date).format('YYYY-MM-DD HH:mm')
      },
      gotoReservationCalendar: function (date, reservationId) {
        if (this.date === moment(date).format('YYYY-MM-DD')) {
          this.$router.push({
            name: 'reservation.list',
            query: {
              search_date: moment(date).format('YYYY-MM-DD'),
              reservation_id: reservationId
            }
          })

          location.reload()
          return
        }

        const router = this.$router.resolve({
          name: 'reservation.list',
          query: {
            search_date: moment(date).format('YYYY-MM-DD'),
            reservation_id: reservationId
          }
        })

        window.open(router.href, '_blank')
      }
    }
  }
</script>
