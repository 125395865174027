<template>
  <div class="logo">
    <div
      v-if="staff_id"
      class="user-info__txt"
    >
      <div class="staff__txt">
        {{ clinic_formal_name }} {{ staff_role }} {{ staff_fullname }} ({{ staff_id }})
      </div>
    </div>
    <div class="header-icon">
      <div
        v-if="visibleReloadButton"
        class="header-icon__inner"
        @click="handleReloadPage"
      >
        <img src="/images/reload.svg">
      </div>
      <div class="header-icon__inner header-icon__inner--setting">
        <img
          id="user-settings"
          src="/images/header-setting.svg"
          @click="toggle()"
        >
      </div>
    </div>
    <div class="user-action">
      <div
        class="user_menu"
        style="height: auto"
      >
        <div class="icon">
          <a
            href="#"
            @click="linkHelp"
          >
            <div class="iconImage">
              <img
                class="info-tab"
                src="/images/info.svg"
              >
            </div>
            <p class="iconTitle info-text">{{ $t('sidebar_menu.help') }}</p>
            <div class="iconImage">
              <img
                class="info-tab"
                src="/images/new-tab.svg"
              >
            </div>
          </a>
        </div>
        <div
          v-if="$store.getters.isAdmin"
          class="icon"
        >
          <router-link :to="{ name: 'setting.account' }">
            <div class="iconImage">
              <img src="/images/settings.svg">
            </div>
            <p class="iconTitle">
              {{ $t('sidebar_menu.preferences') }}
            </p>
          </router-link>
        </div>
        <div class="icon">
          <a
            href="#"
            @click="language_toggle()"
          >
            <div class="iconImage">
              <img src="/images/language.svg">
            </div>
            <p class="iconTitle">{{ $t('sidebar_menu.change_language') }}</p>
          </a>
        </div>
        <div class="icon">
          <a
            href="#"
            class="logout"
            @click="logOut"
          >
            <div class="iconImage"><img :src="'/images/logout.svg'"></div>
            <p class="iconTitle">{{ $t('sidebar_menu.log_out') }}</p>
          </a>
        </div>
      </div>
    </div>
    <div class="language_action">
      <div class="language_menu">
        <ul>
          <li>
            <a
              :class="{ 'disabled-link' : this.$i18n.locale === 'ja' }"
              href="#"
              @click="change_language('ja')"
            >
              {{ $t('sidebar_menu.japanese') }}
            </a>
          </li>
          <li>
            <a
              :class="{ 'disabled-link' : this.$i18n.locale === 'en' }"
              href="#"
              @click="change_language('en')"
            >
              {{ $t('sidebar_menu.english') }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  import eventBus from '../../utils/event-bus'
  import { reloadPage } from '../../mixins/reload-page'
  import get from 'lodash'

  export default {
    mixins: [reloadPage],
    data () {
      return {
        staff_name: '',
        staff_id: '',
        staff_fullname: '',
        staff_role: '',
        clinic_name: '',
        current_page: '',
        clinic_formal_name: '',
        show_reservation: process.env.MIX_SHOW_RESERVATION,
        link_help: process.env.MIX_LINK_HELP
      }
    },
    created () {
      this.show_reservation = this.show_reservation === 'true'
      this.$store.dispatch('getLoggedInfo').then((result) => {
        const data = this.safeGet(result.data, 'results')
        this.staff_name = data.user.name_romaji.slice(0, 1).toUpperCase()
        this.staff_id = data.user.login_id
        this.staff_fullname = data.user.name
        this.staff_role = data.user.position.name
        this.clinic_name = data.user.clinic.name
        this.clinic_formal_name = data.user.clinic.formal_name
      }).catch(() => {})
    },
    mounted: function () {
      const rootPath = this.$router.currentRoute.name.split('.')[0].trim()
      const childPath = this.$router.currentRoute.name.split('.')[1] ? this.$router.currentRoute.name.split('.')[1].trim() : ''
      if (rootPath === 'reservation' && childPath === 'secretariat') this.current_page = 'secretariat'
      else if (rootPath === 'reservation') this.current_page = 'reservation'
      else this.current_page = ''

      $(document).on('click', function (e) {
        var target = $(e.target)
        if (target.is($('#user-settings'))) return
        if (!target.parents('.user-action').length) {
          $('.user-action').hide()
        }
        if (target.is($('.user_menu .icon')) || target.parents('.user_menu .icon').length) return
        if (!target.parents('.language_action').length) {
          $('.language_action').hide()
        }
        if (target.is($('.menu-slide-date .menu-slide')) ||
          target.parents('.menu-slide-date .menu-slide').length) return
        if (!target.parents('.menu-slide-date .menu-slide').length) {
          $('.collapse.show').collapse('hide')
        }
      })

      this.$store.dispatch('staff/config')
        .then(config => {
          if (get(config, 'lang')) {
            this.$i18n.locale = config.lang
          }
        })
    },
    methods: {
      toggle: function () {
        $('.user-action').toggle()
        $('.language_action').hide()
      },
      language_toggle: function (lang) {
        $('.language_action').toggle()
        $('.user-action').toggle()
      },
      change_language (lang) {
        this.$store.dispatch('staff/changeLanguage', { lang })
          .then(() => {
            this.$i18n.locale = lang
            eventBus.$emit('change-language', lang)
          })
          .finally(() => {
            this.language_toggle()
          })
      },
      safeGet: function (object, keys) {
        return keys.split('.').reduce((xs, x) => (xs && xs[x]) ? xs[x] : undefined, object) || ''
      },
      linkHelp: function () {
        window.open(this.link_help, '_blank')
      },
      handleReloadPage () {
        this.$store.dispatch('handleReloadAction')
      },
      logOut () {
        this.$store.commit('set_loading', true)

        this.$store.dispatch('logout').then(() => {
          this.$store.commit('set_loading', false)
          this.$router.push({ name: 'auth.login' })
        }).catch(() => {
          this.$store.commit('set_loading', false)
        })
      }
    }
  }
</script>
