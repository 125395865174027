var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "patient-v2",
        {
          "patient-v2--order": _vm.isPatientOrder,
          "patient-v2--order-detail": _vm.isOrderDetailPage
        }
      ]
    },
    [
      !_vm.isOnlyOrderDetail
        ? _c("div", { staticClass: "patient-v2__header" }, [
            _c("div", { staticClass: "patient-v2__header__left" }, [
              _c(
                "div",
                {
                  staticClass:
                    "patient-v2__header__info patient-v2__header__info--name"
                },
                [
                  _c("p", { staticClass: "patient-v2__header__name" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.patientKanaName) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "patient-v2__header__age" }, [
                    _c("span", [_vm._v(_vm._s(_vm.patientFullName))]),
                    _vm._v(_vm._s(_vm.age) + "\n        ")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "patient-v2__header__info patient-v2__header__info--id"
                },
                [
                  _c("div", { staticClass: "patient-v2__header__center" }, [
                    _c("p", { staticClass: "patient-v2__header__label" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("objects.patient.text.patient_id")) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.intVal(_vm.patient.sf_user_id) ||
                              _vm.$t(
                                "objects.patient.text.please_reload_while_getting_id"
                              )
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.is_clinics_dmr === "1" || _vm.is_clinics_dmr === 1
                      ? _c("p", { staticClass: "patient-v2__header__button" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$route.name === "patient.histories"
                                  ? _vm.$t("new_text.accept")
                                  : _vm.$t(
                                      "objects.patient.text.patient_status_" +
                                        _vm.currentStatus
                                    )
                              ) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "patient-v2__header__right" }, [
              _vm.isShowAction
                ? _c(
                    "div",
                    { staticClass: "patient-v2__header__action" },
                    [
                      _c("button-common", {
                        staticClass: "button-v2--secondary button-v2--120",
                        on: {
                          click: function($event) {
                            return _vm.$router.push({ name: "dashboard" })
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function() {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "objects.patient.text.new_reservation"
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2209099061
                        )
                      }),
                      _vm._v(" "),
                      _vm.is_clinics_dmr === "1" || _vm.is_clinics_dmr === 1
                        ? _c("button-common", {
                            staticClass: "button-v2--secondary button-v2--120",
                            on: {
                              click: function($event) {
                                return _vm.$router.push({
                                  name: "patient.accounting.edit",
                                  params: { id: _vm.patient_id, aid: "nodmr" },
                                  query: { reservation_id: _vm.reservation_id }
                                })
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "content",
                                  fn: function() {
                                    return [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t(
                                              "objects.patient.text.accounting_creation"
                                            )
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              327628793
                            )
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.is_clinics_dmr === "1" || _vm.is_clinics_dmr === 1
                        ? _c("button-common", {
                            staticClass: "button-v2--primary button-v2--120",
                            on: { click: _vm.getLastDraftId },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "content",
                                  fn: function() {
                                    return [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t(
                                              "objects.patient.text.medical_record_creation"
                                            )
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              3823099457
                            )
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "patient-v2__body",
            { "patient-v2__body--smart-phone": _vm.isSmartPhone }
          ]
        },
        [
          !_vm.isOnlyOrderDetail && !_vm.isSmartPhone
            ? _c("side-bar", {
                attrs: {
                  "is-hide-side-bar": _vm.is_hide_side_bar,
                  "patient-id": _vm.patient_id,
                  "reservation-id": _vm.reservation_id,
                  "is-clinics-dmr": _vm.is_clinics_dmr,
                  "is-interview": _vm.isInterview
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "patient-v2__content" },
            [_c("router-view")],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isOnlyOrderDetail && !_vm.isSmartPhone
        ? _c("div", {
            class: [
              "patient-v2__sidebar__toggle",
              { "patient-v2__sidebar__toggle--hide": _vm.is_hide_side_bar }
            ],
            attrs: { src: "/images/icon-toggle-left-sidebar.svg" },
            on: {
              click: function($event) {
                _vm.is_hide_side_bar = !_vm.is_hide_side_bar
              }
            }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }