var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-action", {
    staticClass: "modal-confirm",
    attrs: { title: _vm.title, "is-show-close-button": _vm.isShowCloseButton },
    on: {
      close: function($event) {
        return _vm.$emit("no")
      },
      confirm: function($event) {
        return _vm.$emit("yes")
      }
    },
    scopedSlots: _vm._u(
      [
        _vm.text
          ? {
              key: "body",
              fn: function() {
                return [
                  _vm._t("body", [_c("span", [_vm._v(_vm._s(_vm.text))])])
                ]
              },
              proxy: true
            }
          : null,
        {
          key: "cancel_button_content",
          fn: function() {
            return [
              _vm._v(
                "\n    " + _vm._s(_vm.textNo || _vm.$t("common.no")) + "\n  "
              )
            ]
          },
          proxy: true
        },
        {
          key: "confirm_button_content",
          fn: function() {
            return [
              _vm._v(
                "\n    " + _vm._s(_vm.textOk || _vm.$t("common.yes")) + "\n  "
              )
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }