var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "print-component component-wrapper interview-checklist" },
      [
        _c("div", { staticClass: "bg-white" }, [
          _c(
            "table",
            {
              staticClass:
                "table table-interview-check-list table-interview-scan text-center"
            },
            [
              _c("tbody", [
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticClass: "bg-highlight stretch-width",
                      attrs: { colspan: "3" }
                    },
                    [_vm._v("\n            X-ray\n          ")]
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "bg-highlight stretch-width" }, [
                    _vm._v("\n            Photo\n          ")
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "bg-highlight stretch-width" }, [
                    _vm._v("\n            Scan\n          ")
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "bg-highlight stretch-width" }, [
                    _vm._v("\n            パノラマ\n          ")
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "bg-highlight stretch-width" }, [
                    _vm._v("\n            セファロ\n          ")
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "bg-highlight stretch-width" }, [
                    _vm._v("\n            CT\n          ")
                  ]),
                  _vm._v(" "),
                  _c("td", {
                    staticClass: "col-data",
                    attrs: { rowspan: "2" }
                  }),
                  _vm._v(" "),
                  _c("td", { staticClass: "col-data", attrs: { rowspan: "2" } })
                ]),
                _vm._v(" "),
                _c("tr", { staticClass: "row-fill-data" }, [
                  _c("td", { staticClass: "col-data" }),
                  _vm._v(" "),
                  _c("td", { staticClass: "col-data" }),
                  _vm._v(" "),
                  _c("td", { staticClass: "col-data" })
                ])
              ])
            ]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }