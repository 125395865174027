<template>
  <div class="reservation-history">
    <ul
      v-for="history in histories"
      :key="history.id"
      class="reservation-list"
    >
      <li class="reservation__item">
        <div class="reservation__item__allocation">
          <label>{{ safeGet(history, 'allocation.column_name') }}</label>
          <span>{{ $t('objects.reservation.text.change') }}</span>
        </div>
        <div class="reservation__item__date">
          <span class="reservation__item__tag">
            {{ $t('objects.reservation.text.datetime') }}
          </span>
          <span class="reservation__item__time">{{ reservationDate(history) }}</span>
        </div>
        <div class="reservation__item__date-change">
          <span class="reservation__item__tag">
            {{ $t('objects.reservation.text.datetime_change') }}
          </span>
          <span class="reservation__item__time">{{ reservationHistoryCreate(history) }}</span>
        </div>
        <div class="reservation__item__line" />
      </li>
    </ul>
  </div>
</template>

<script>
  import helpers from '../../../../utils/helpers'

  export default {
    name: 'ReservationHistory',
    props: {
      reservation: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {
        histories: this.reservation.reservationHistories ? this.reservation.reservationHistories : []
      }
    },
    created () {
      this.histories = this.histories.reverse()
    },
    methods: {
      reservationHistoryCreate: function (history) {
        return helpers.formatDateTime(history.row_created_at, this.$t('datetime.year_month_day_hour_minute'))
      },
      reservationDate: function (reservation) {
        const date = helpers.formatDateTime(reservation.started_to, this.$t('datetime.year_month_day'))
        const startDate = helpers.formatDateTime(reservation.started_to, this.$t('datetime.hour_minute'))
        const endDate = helpers.formatDateTime(reservation.ended_from, this.$t('datetime.hour_minute'))
        return `${date} ${startDate} ~ ${endDate}`
      },
      safeGet: function (object, keys) {
        return helpers.safeGet(object, keys)
      }
    }
  }
</script>
