<template>
  <div class="patient-v2 patient-v2--normal">
    <patient-header-normal
      :custom-title="$t('objects.patient.text.title_registration')"
      @return="handleReturnAction"
      @submit="handleSubmitAction"
    />
    <div class="patient-v2__body">
      <div class="patient-v2__content">
        <div class="patient-v2__row">
          <div class="col-12 patient-v2__item">
            <h2 class="patient-v2__row__title">
              {{ $t('objects.patient.text.basic_info') }}
            </h2>
          </div>
          <div class="patient-v2__row__group">
            <div class="col-md-6 patient-v2__item">
              <label for="last_name">
                {{ $t('new_text.last_name') }}
              </label>
              <span class="required">{{ $t('new_text.required') }}</span>
              <input-common
                id="last_name"
                v-model="last_name"
                :default-value="last_name"
                name="last_name"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.last_name') })"
                :class="['patient-v2__input', { 'patient-v2__input--error': errors.last_name }]"
                type="text"
                maxlength="80"
                @input="updateFuriganaLast"
              />
              <span
                v-if="errors.last_name"
                class="error"
              >
                {{ errors.last_name }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label for="first_name">
                {{ $t('new_text.first_name') }}
              </label>
              <span class="required">{{ $t('new_text.required') }}</span>
              <input-common
                id="first_name"
                v-model="first_name"
                :default-value="first_name"
                name="first_name"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.first_name') })"
                :class="['patient-v2__input', { 'patient-v2__input--error': errors.first_name }]"
                type="text"
                maxlength="40"
                @input="updateFuriganaFirst"
              />
              <span
                v-if="errors.first_name"
                class="error"
              >
                {{ errors.first_name }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label for="kana_last_name">
                {{ $t('new_text.kata_last_name') }}
              </label>
              <span class="required">{{ $t('new_text.required') }}</span>
              <input-common
                id="kana_last_name"
                v-model="kana_last_name"
                :default-value="kana_last_name"
                name="kana_last_name"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.kata_last_name') })"
                :class="['patient-v2__input', { 'patient-v2__input--error': errors.kana_last_name }]"
                type="text"
                maxlength="191"
                @input="updateKanaLast"
              />
              <span
                v-if="errors.kana_last_name"
                class="error"
              >
                {{ errors.kana_last_name }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label for="kana_first_name">
                {{ $t('new_text.kata_first_name') }}
              </label>
              <span class="required">{{ $t('new_text.required') }}</span>
              <input-common
                id="kana_first_name"
                v-model="kana_first_name"
                :default-value="kana_first_name"
                name="kana_first_name"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.kata_first_name') })"
                :class="['patient-v2__input', { 'patient-v2__input--error': errors.kana_first_name }]"
                type="text"
                maxlength="191"
                @input="updateKanaFirst"
              />
              <span
                v-if="errors.kana_first_name"
                class="error"
              >
                {{ errors.kana_first_name }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label for="roma_last_name">
                {{ $t('new_text.roma_last_name') }}
              </label>
              <span class="required">{{ $t('new_text.required') }}</span>
              <input-common
                id="roma_last_name"
                v-model="roma_last_name"
                :default-value="roma_last_name"
                name="roma_last_name"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.roma_last_name') })"
                type="text"
                class="patient-v2__input"
                maxlength="191"
                @input="errors.roma_last_name=''"
              />
              <span
                v-if="errors.roma_last_name"
                class="error"
              >
                {{ errors.roma_last_name }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label for="roma_first_name">
                {{ $t('new_text.roma_first_name') }}
              </label>
              <span class="required">{{ $t('new_text.required') }}</span>
              <input-common
                id="roma_first_name"
                v-model="roma_first_name"
                :default-value="roma_first_name"
                name="roma_first_name"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.roma_first_name') })"
                type="text"
                class="patient-v2__input"
                maxlength="191"
                @input="errors.roma_first_name=''"
              />
              <span
                v-if="errors.roma_first_name"
                class="error"
              >
                {{ errors.roma_first_name }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label>{{ $t('new_text.sex') }}</label>
              <div class="patient-v2__sex">
                <radio-common
                  name="sex"
                  class="patient-v2__sex__input"
                  :label="$t('new_text.placeholder.no_selection')"
                  :is-checked="true"
                  @select="getDataGender"
                />
                <radio-common
                  name="sex"
                  class="patient-v2__sex__input"
                  :label="$t('new_text.male')"
                  :val="2"
                  @select="getDataGender"
                />
                <radio-common
                  name="sex"
                  class="patient-v2__sex__input"
                  :label="$t('new_text.female')"
                  :val="1"
                  @select="getDataGender"
                />
              </div>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label>{{ $t('new_text.birthday') }}</label>
              <div class="patient-v2__birthday">
                <span class="patient-v2__birthday__group">
                  <select-common
                    :options="listYear"
                    name="birthdate_year"
                    :default-value="birthdate_year"
                    key-value="value"
                    key-display="text"
                    @change="getLeapYear"
                  />
                  <span>{{ $t('new_text.year') }}</span>
                </span>
                <span class="patient-v2__birthday__group">
                  <select-common
                    :options="listMonth"
                    name="birthdate_month"
                    :default-value="birthdate_month"
                    key-value="value"
                    key-display="text"
                    @change="value => birthdate_month = value"
                  />
                  <span>{{ $t('new_text.month') }}</span>
                </span>
                <span class="patient-v2__birthday__group">
                  <select-common
                    :options="listDay"
                    name="birthdate_day"
                    :default-value="birthdate_day"
                    key-value="value"
                    key-display="text"
                    @change="value => birthdate_day = value"
                  />
                  <span>{{ $t('new_text.day') }}</span>
                </span>
              </div>
              <span
                v-if="errors.birthday"
                class="error"
              >
                {{ errors.birthday }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label>{{ $t('new_text.brand_type') }}</label>
              <div class="patient-v2__brand">
                <select-common
                  :placeholder="$t('new_text.placeholder.please_select')"
                  :options="brand_masters"
                  name="brand_type"
                  key-value="type"
                  :default-value="brand_type"
                  @change="value => brand_type = value"
                />
              </div>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label>{{ $t('objects.patient.text.treatmentend_status') }}</label>
              <div class="patient-v2__treatmentend-status">
                <select-common
                  :options="treatmentendStatus"
                  name="sf_treatmentend_status_c"
                  key-value="value"
                  key-display="label"
                  :default-value="sf_treatmentend_status_c"
                  @change="value => sf_treatmentend_status_c = value"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="patient-v2__row">
          <div class="col-12 patient-v2__item">
            <h2 class="patient-v2__row__title">
              {{ $t('objects.patient.text.contact_info') }}
            </h2>
          </div>
          <div class="patient-v2__row__group">
            <div class="col-12 patient-v2__item">
              <label class="patient-v2__label__zipcode">{{ $t('new_text.zip_code') }}</label>
              <input-common
                v-model="zip_code"
                name="zip_code"
                type="text"
                class="patient-v2__input patient-v2__input__zipcode"
                :placeholder="$t('new_text.placeholder.no_hyphens_needed')"
                maxlength="7"
                @input="handleZipcodeChange"
              />
              <button
                class="button-v2 patient-v2__action__zipcode"
                @click="validateZipCode"
              >
                {{ $t('new_text.button.address_search') }}
              </button>
              <span
                v-if="errors.zipcode"
                class="error patient-v2__error-zipcode"
              >
                {{ errors.zipcode }}
              </span>
            </div>
            <div class="col-12 patient-v2__item">
              <label>{{ $t('new_text.address') }}</label>
              <input-common
                v-model="address"
                name="address"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.address') })"
                class="patient-v2__input patient-v2__input--full-width"
                type="text"
              />
            </div>
            <div class="col-md-6 patient-v2__item">
              <label>{{ $t('new_text.phone_number') }}</label>
              <span class="required">{{ $t('new_text.required') }}</span>
              <input-common
                v-model="tel"
                name="tel"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.phone_number') })"
                :class="['patient-v2__input', {'patient-v2__input--error': errors.tel}]"
                type="text"
                maxlength="11"
                @input="handlePhoneChange"
              />
              <span
                v-if="errors.tel"
                class="error"
              >
                {{ errors.tel }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label>{{ $t('objects.patient.text.sms_delivery_setting') }}</label>
              <checkbox-common
                v-model="is_receive_sms"
                name="is_receive_sms"
                :label="$t('new_text.receive_sms')"
                :is-checked="is_receive_sms"
              />
            </div>
            <div class="col-md-6 patient-v2__item">
              <label>{{ $t('new_text.email') }}</label>
              <input-common
                v-model="email"
                name="email"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.email') })"
                :class="['patient-v2__input', {'patient-v2__input--error': errors.email}]"
                type="text"
                maxlength="80"
                @input="handleMailChange"
              />
              <span
                v-if="errors.email"
                class="error"
              >
                {{ errors.email }}
              </span>
            </div>
            <div
              v-if="false"
              class="col-md-6 patient-v2__item"
            >
              <label class="patient-page__label">{{ $t('objects.patient.text.mail_delivery_setting') }}</label>
              <checkbox-common
                v-model="is_receive_email"
                name="is_receive_email"
                :label="$t('new_text.receive_sms')"
                :is-checked="is_receive_email"
              />
            </div>
          </div>
        </div>
        <div class="patient-v2__row">
          <div class="col-12 patient-v2__item">
            <h2 class="patient-v2__row__title">
              {{ $t('objects.patient.text.assignee_info') }}
            </h2>
          </div>
          <div class="patient-v2__row__group">
            <div class="col-12">
              <div class="position-wrapper">
                <div
                  v-if="person_in_charges.length"
                  class="position"
                >
                  <div
                    v-for="(item, index) in person_in_charges"
                    :key="index"
                    class="position__item"
                  >
                    <div class="position-left">
                      <p class="position-left__text">
                        {{ item.staff.position.name }}
                      </p>
                      <span class="position-left__space" />
                      <p class="position-left__text position-left__text--name">
                        {{ item.staff.name }}
                      </p>
                    </div>
                    <div
                      class="position-right"
                      @click="removePerson(item.staff_id)"
                    >
                      <img src="/images/icon-close-circle-gray.svg">
                    </div>
                  </div>
                  <button
                    class="btn btn-default position-btn person-charge__patient"
                    data-target="position"
                    type="button"
                    @click="showPersonModal"
                  >
                    <img
                      class="position-btn__icon"
                      src="/images/icon-plus-add.svg"
                    >
                    <p class="position-btn__text">
                      {{ $t('objects.patient.text.add_charge') }}
                    </p>
                  </button>
                </div>
                <div
                  v-else
                  class="position"
                >
                  <button
                    class="btn btn-default position-btn person-charge__patient"
                    data-target="position"
                    type="button"
                    @click="showPersonModal"
                  >
                    <img
                      class="position-btn__icon"
                      src="/images/icon-plus-add.svg"
                    >
                    <p class="position-btn__text">
                      {{ $t('objects.patient.text.add_charge') }}
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="patient-v2__row">
          <div class="col-12 patient-v2__item">
            <h2 class="patient-v2__row__title">
              {{ $t('objects.patient.text.other_notes') }}
            </h2>
          </div>
          <div class="patient-v2__row__group">
            <div class="col-12 patient-v2__item">
              <textarea
                v-model="note"
                name="note"
                :placeholder="$t('new_text.placeholder.please_enter')"
                class="textarea-common"
                cols="2"
                rows="6"
              />
            </div>
          </div>
        </div>
        <loading />
        <modal-select-person
          v-if="is_show_modal_select_person"
          :position-data="position_data"
          @person-data="updateStaffData"
          @close="is_show_modal_select_person = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import {
    safeGet,
    mbtrim,
    capitalize,
    isValidJapaneseName,
    checkValidEmail,
    checkValidPhone,
    showErrorModal,
    mbTrimRomaji
  } from '../../../utils/helpers'
  import Loading from '../../../components/Loading'
  import { Core as YubinBangoCore } from 'yubinbango-core'
  import ModalSelectPerson from '../../../components/v2/ModalSelectPerson'
  import * as moment from 'moment-timezone'
  import historykana from 'historykana'
  import * as wanakana from 'wanakana'
  import PatientHeaderNormal from '../../../components/patient/v2/PatientHeaderNormal'
  import InputCommon from '../../../components/common/Input'
  import RadioCommon from '../../../components/common/Radio'
  import CheckboxCommon from '../../../components/common/Checkbox'
  import SelectCommon from '../../../components/common/Select'
  import { RESPONSE_TYPE, KIDS_BRAND_TYPE } from '../../../utils/constants'

  export default {
    components: {
      CheckboxCommon,
      SelectCommon,
      RadioCommon,
      InputCommon,
      Loading,
      ModalSelectPerson,
      PatientHeaderNormal
    },
    data () {
      return {
        patient_full_name: '',
        last_name: '',
        first_name: '',
        kana_last_name: '',
        kana_first_name: '',
        roma_last_name: '',
        roma_first_name: '',
        gender: '',
        zip_code: '',
        prefecture: '',
        city: '',
        address: '',
        tel: '',
        email: '',
        patient_id: '',
        birth_of_date: '',
        person_in_charges: [],
        note: '',
        position_data: {},
        error_messages: [],
        history_last: [],
        history_first: [],
        history_kata_last: [],
        history_kata_first: [],
        birthdate_year: '',
        birthdate_month: '',
        birthdate_day: '',
        is_leap_year: false,
        brand_type: 0,
        brand_masters: [],
        errors: {
          last_name: '',
          first_name: '',
          kana_last_name: '',
          kana_first_name: '',
          roma_last_name: '',
          roma_first_name: '',
          tel: '',
          email: '',
          birthday: ''
        },
        is_receive_sms: true,
        is_receive_email: true,
        is_show_modal_select_person: false,
        sf_treatmentend_status_c: null,
        treatmentendStatus: [
          {
            value: null,
            label: '-'
          },
          {
            value: 1,
            label: this.$t('objects.patient.sf_treatmentend_status_c.1')
          },
          {
            value: 2,
            label: this.$t('objects.patient.sf_treatmentend_status_c.2')
          },
          {
            value: 3,
            label: this.$t('objects.patient.sf_treatmentend_status_c.3')
          },
          {
            value: 4,
            label: this.$t('objects.patient.sf_treatmentend_status_c.4')
          },
          {
            value: 5,
            label: this.$t('objects.patient.sf_treatmentend_status_c.5')
          }
        ]
      }
    },
    computed: {
      listYear: function () {
        return this.setOptionsNumber(new Date().getFullYear(), 1920)
      },
      listMonth: function () {
        return this.setOptionsNumber(12)
      },
      listDay: function () {
        let range
        const day31 = [1, 3, 5, 7, 8, 10, 12]
        const day30 = [4, 6, 9, 11]
        if (day30.includes(this.birthdate_month)) {
          range = 30
        } else if (day31.includes(this.birthdate_month)) {
          range = 31
        } else {
          if (this.is_leap_year) {
            range = 29
          } else {
            range = 28
          }
        }
        return this.setOptionsNumber(range)
      },
      isValidBirthday: function () {
        return (!this.birthdate_year &&
          !this.birthdate_month &&
          !this.birthdate_day) ||
          (this.birthdate_year &&
            this.birthdate_month &&
            this.birthdate_day)
      },
      currentClinic () {
        return this.$store.getters.currentClinic
      }
    },
    watch: {
      currentClinic: function (value) {
        if (value.is_kids_only) {
          this.brand_type = KIDS_BRAND_TYPE
        }
      }
    },
    created () {
      document.title = this.$t('objects.page_title.patient').toString()
      this.$store.commit('set_loading', true)
      this.$store.dispatch('getPatientRegistrationFormData').then((result) => {
        const { data: { results } } = result
        this.position_data = results.positions
        this.brand_masters = results.brandMasters
        this.$store.commit('set_loading', false)
      }).catch(() => {
        this.$store.commit('set_loading', false)
      })
    },
    mounted: function () {
      this.$nextTick(function () {
        const romaFirstNameInput = $('#roma_first_name')
        const romaLastNameInput = $('#roma_last_name')
        romaFirstNameInput.change(function (e) {
          romaFirstNameInput.val(mbTrimRomaji(romaFirstNameInput.val()))
        })
        romaLastNameInput.change(function (e) {
          romaLastNameInput.val(mbTrimRomaji(romaLastNameInput.val()))
        })
      })
    },
    methods: {
      getDataGender (value) {
        this.gender = value
      },
      setOptionsNumber (end, start = 1) {
        const options = []

        for (let i = start; i <= end; i++) {
          options.push({ value: i, text: i })
        }

        return options
      },
      handleReturnAction () {
        this.$router.push({ name: 'patient.list' })
      },
      updateFuriganaLast (value) {
        this.errors.last_name = ''
        if (value === '') {
          this.history_last = []
        }

        this.history_last.push(value)
        this.kana_last_name = historykana(this.history_last)
        this.roma_last_name = wanakana.toRomaji(this.kana_last_name).toUpperCase()
        this.roma_last_name = this.roma_last_name.length > 191
          ? this.roma_last_name.substring(0, 191) : this.roma_last_name
      },
      updateFuriganaFirst (value) {
        this.errors.first_name = ''
        if (value === '') {
          this.history_first = []
        }

        this.history_first.push(value)
        this.kana_first_name = historykana(this.history_first)
        this.roma_first_name = capitalize(wanakana.toRomaji(this.kana_first_name))
        this.roma_first_name = this.roma_first_name.length > 191
          ? this.roma_first_name.substring(0, 191) : this.roma_first_name
      },
      updateKanaLast (value) {
        this.errors.kana_last_name = ''
        this.errors.roma_last_name = ''
        if (value === '') {
          this.history_kata_last = []
        }

        this.history_kata_last.push(value)
        this.roma_last_name = wanakana.toRomaji(historykana(this.history_kata_last)).toUpperCase()
        this.roma_last_name = this.roma_last_name.length > 191
          ? this.roma_last_name.substring(0, 191) : this.roma_last_name
      },
      updateKanaFirst (value) {
        this.errors.kana_first_name = ''
        this.errors.roma_first_name = ''
        if (value === '') {
          this.history_kata_first = []
        }

        this.history_kata_first.push(value)
        this.roma_first_name = capitalize(wanakana.toRomaji(historykana(this.history_kata_first)))
        this.roma_first_name = this.roma_first_name.length > 191
          ? this.roma_first_name.substring(0, 191) : this.roma_first_name
      },
      getLeapYear (value) {
        this.birthdate_year = value
        this.is_leap_year = (this.birthdate_year % 4 === 0 &&
          this.birthdate_year % 100 !== 0 &&
          this.birthdate_year % 400 !== 0) ||
          (this.birthdate_year % 100 === 0 &&
            this.birthdate_year % 400 === 0)
      },
      resetErrors () {
        this.error_messages = []
        this.errors = {
          last_name: '',
          first_name: '',
          kana_last_name: '',
          kana_first_name: '',
          tel: '',
          email: '',
          birthday: ''
        }
      },
      validateSubmitData: function (data) {
        this.resetErrors()

        if (!mbtrim(data.last_name)) {
          this.errors.last_name = this.$t('new_validation.patient.last_name_required')
        }

        if (!isValidJapaneseName(data.last_name)) {
          this.errors.last_name = this.$t('new_validation.patient.patient_name_invalid')
        }

        if (!mbtrim(data.first_name)) {
          this.errors.first_name = this.$t('new_validation.patient.first_name_required')
        }

        if (!isValidJapaneseName(data.first_name)) {
          this.errors.first_name = this.$t('new_validation.patient.patient_name_invalid')
        }

        if (!mbtrim(data.kana_last_name)) {
          this.errors.kana_last_name = this.$t('new_validation.patient.kana_last_name_required')
        }

        if (mbtrim(data.kana_last_name) && !(/^([ぁ-ん]+)$/g.test(data.kana_last_name))) {
          this.errors.kana_last_name = this.$t('new_validation.patient.please_enter_hiragana')
        }

        if (!mbtrim(data.kana_first_name)) {
          this.errors.kana_first_name = this.$t('new_validation.patient.kana_first_name_required')
        }

        if (mbtrim(data.kana_first_name) && !(/^([ぁ-ん]+)$/g.test(data.kana_first_name))) {
          this.errors.kana_first_name = this.$t('new_validation.patient.please_enter_hiragana')
        }

        if (!mbtrim(data.roma_last_name)) {
          this.errors.roma_last_name = this.$t('new_validation.patient.roma_last_name_required')
        }

        if (!mbtrim(data.roma_first_name)) {
          this.errors.roma_first_name = this.$t('new_validation.patient.roma_first_name_required')
        }

        if (data.zip_code && (data.zip_code.length !== 7 || !$.isNumeric(data.zip_code))) {
          this.errors.zipcode = this.$t('new_validation.patient.zipcode_greater_than_7')
        }

        if (data.tel && !(/^\d{10}$|^\d{11}$/.test(data.tel))) {
          this.errors.tel = this.$t('new_validation.patient.phone_number_invalid')
        }

        if (!data.tel) {
          this.errors.tel = this.$t('new_validation.common.required',
                                    { attribute: this.$t('new_text.phone_number') })
        }

        if (data.email && !checkValidEmail(data.email)) {
          this.errors.email = this.$t('new_validation.common.invalid_email')
        }

        if (!this.isValidBirthday) {
          this.errors.birthday = this.$t('new_validation.patient.correct_birthday')
        }

        Object.values(this.errors).forEach(val => {
          if (val) {
            this.error_messages.push(val)
          }
        })

        if (this.error_messages.length) {
          showErrorModal(this.$t('objects.patient.message.has_error_input'))
          return false
        }

        return true
      },
      setDate: function (data) {
        this.date = moment(
          data,
          this.$t('new_text.datetime.year_month_day')
        ).format(this.$t('new_text.datetime.year_month_day', 'en'))
      },
      handleSubmitAction: function () {
        const submitData = {
          last_name: this.last_name.trim(),
          first_name: this.first_name.trim(),
          roma_first_name: this.roma_first_name.trim(),
          roma_last_name: this.roma_last_name.trim(),
          kana_first_name: this.kana_first_name.trim(),
          kana_last_name: this.kana_last_name.trim(),
          gender: this.gender,
          address: this.address,
          zip_code: this.zip_code,
          prefecture: this.prefecture,
          city: this.city,
          tel: this.tel,
          email: this.email,
          person_in_charges: this.person_in_charges.map(item => item.staff_id),
          note: this.note,
          brand_type: this.brand_type || 0,
          is_receive_sms: this.is_receive_sms,
          is_receive_email: this.is_receive_email,
          sf_treatmentend_status_c: this.sf_treatmentend_status_c
        }

        if (this.birthdate_year && this.birthdate_month && this.birthdate_day) {
          submitData.birth_of_date =
            `${this.birthdate_year}-${this.formatDayOrMonth(this.birthdate_month)}-${this.formatDayOrMonth(this.birthdate_day)}`
        }

        if (!this.validateSubmitData(submitData)) return
        this.$store.commit('set_loading', true)
        this.$store.dispatch('addNewPatient', submitData).then((result) => {
          this.$store.commit('set_loading', false)
          if (result.status === 200) {
            return this.$router.push({
              name: 'patient.detail',
              params: { id: result.data.results.patient_id }
            })
          }
        }).catch((error) => {
          const resErrors = safeGet(error, 'response.data.errors') || {}
          const errors = []
          let errorMessage = this.$t('message.something_went_wrong')
          for (const index in resErrors) {
            if (resErrors[index]) {
              if (resErrors[index] === RESPONSE_TYPE.MAXIMUM) {
                const max = index === 'last_name' || index === 'email' ? 80 : index === 'first_name' ? 40 : 191
                const message = index === 'note' ? this.$t('new_validation.common.note_maxlength', { max: '32,768' })
                  : this.$t('new_validation.common.maximum', { attribute: this.$t(`new_text.${index}`), max: max })
                this.$store.commit('set_loading', false)
                errors.push(message)
              } else {
                errors.push(resErrors[index])
              }
            }
          }
          if (errors.length) errorMessage = errors.join('<br>')
          showErrorModal(errorMessage, this)
          this.$store.commit('set_loading', false)
        })
      },
      validateZipCode: function () {
        if (this.zip_code.length !== 7 || !$.isNumeric(this.zip_code)) {
          showErrorModal(this.$t('new_validation.patient.zipcode_greater_than_7'))
        } else {
          // eslint-disable-next-line no-new
          new YubinBangoCore(this.zip_code, (address) => {
            this.address = `${address.region}${address.locality}${address.street} ${address.extended || ''}`
            this.prefecture = address.region
            this.city = address.locality
            if (this.address.length === 1) {
              showErrorModal(this.$t('new_validation.patient.zipcode_invalid'))
            }
          })
        }
      },
      showPersonModal: function () {
        this.is_show_modal_select_person = true
      },
      updateStaffData: function (item) {
        // check duplicate
        if (this.person_in_charges.some(value => value.staff_id === item.id)) {
          return false
        }
        this.person_in_charges.push({
          staff_id: item.id,
          staff: {
            name: item.name,
            position: {
              name: item.position_name
            }
          }
        })
        this.is_show_modal_select_person = false
      },
      removePerson: function (staffID) {
        this.person_in_charges = this.person_in_charges.filter(item => item.staff_id !== staffID)
      },
      handleMailChange (value) {
        this.errors.email = ''

        if (value.length > 80) {
          this.errors.email = this.$t('new_validation.common.maximum', { attribute: this.$t('new_validation.common.attributes.email'), max: 80 })

          return
        }

        if (value && !checkValidEmail(value)) {
          this.errors.email = this.$t('new_validation.common.invalid_email')
        }
      },
      handlePhoneChange (value) {
        this.errors.tel = ''

        if (value && checkValidPhone(value)) {
          this.errors.tel = this.$t('new_validation.patient.' + checkValidPhone(value))
        }
      },
      handleZipcodeChange (value) {
        this.errors.zipcode = ''

        if (value && (value.length !== 7 || !$.isNumeric(value))) {
          this.errors.zipcode = this.$t('new_validation.patient.zipcode_greater_than_7')
        }
      },
      formatDayOrMonth (value) {
        if (value < 10) {
          return '0' + value
        }

        return value
      }
    }
  }
</script>
