import { render, staticRenderFns } from "./AnswerCommon.vue?vue&type=template&id=5ffb784b&"
import script from "./AnswerCommon.vue?vue&type=script&lang=js&"
export * from "./AnswerCommon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/medusa/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5ffb784b')) {
      api.createRecord('5ffb784b', component.options)
    } else {
      api.reload('5ffb784b', component.options)
    }
    module.hot.accept("./AnswerCommon.vue?vue&type=template&id=5ffb784b&", function () {
      api.rerender('5ffb784b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/patient/interview/template/AnswerCommon.vue"
export default component.exports