<template>
    <div class="wrap">
        <sidebar-menu></sidebar-menu>
        <main class="secretariat">
            <div class="col-xl-12 main-content">
                <section class="content">
                    <div class="inner">
                        <b-tabs nav-wrapper-class="tabs-nav-wrapper" justified>
                            <b-tab :title="$t('reservation.spreadsheet')" active>
                                <div class="column-filter">
                                    <select v-model="selected_col" class="form-control border-blue"
                                        style="width: 90px; float: right;">
                                        <option value="all">全体</option>
                                        <option value="new">新規枠</option>
                                        <option value="existing">既存枠</option>
                                    </select>
                                </div>
                                <span class="label-bg label-status">
                                    {{$t('reservation.number_of_reservations_by_type')}}
                                </span>
                                <table role="table" aria-busy="false" aria-colcount="3" class="table b-table">
                                    <thead role="rowgroup" class="">
                                        <tr role="row" class="">
                                            <th role="columnheader" scope="col" aria-colindex="1"
                                                class="table-b-table-default text-left">
                                                クリニック名
                                            </th>
                                            <th role="columnheader" scope="col" aria-colindex="2"
                                                    class="table-b-table-default text-center col-20"
                                                    v-if="selected_col == 'all' || selected_col == 'new'">
                                                新規枠予約数
                                            </th>
                                            <th role="columnheader" scope="col" aria-colindex="3"
                                                class="table-b-table-default text-center col-20"
                                                v-if="selected_col == 'all' || selected_col == 'existing'">
                                                既存枠予約数
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody role="rowgroup">
                                        <tr role="row" class="" v-for="item in spread_sheet_items">
                                            <td aria-colindex="1" role="cell" class="text-left">
                                                {{item.clinic_name}}
                                            </td>
                                            <td aria-colindex="2" role="cell" class="text-center"
                                                v-if="selected_col == 'all' || selected_col == 'new'">
                                                {{item.new_number}}
                                            </td>
                                            <td aria-colindex="3" role="cell" class="text-center"
                                                v-if="selected_col == 'all' || selected_col == 'existing'">
                                                {{item.existing_number}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-tab>
                            <b-tab :title="$t('reservation.reservation_setting')">
                                <span
                                        class="label-bg label-status">{{$t('reservation.new_customer_reception_status')}}</span>
                                <b-table sticky-header :items="reservation_setting_items"
                                         :fields="reservation_setting_fields">
                                    <template v-slot:cell(frame_reception_new)="{value, item}">
                                        <b-nav pills class="row-nav" v-if="$store.getters.isAdmin">
                                            <b-nav-item-dropdown :text="value" left class="box-nav-item"
                                                                 :data-id="item.id">
                                                <b-dropdown-item-button :key="action.name"
                                                                        v-for="action in reservation_setting_actions"
                                                                        v-bind:active="value == action.name"
                                                                        @click="eventClickDropdownItem"
                                                                        v-bind:value="action.value">{{action.name}}
                                                </b-dropdown-item-button>
                                            </b-nav-item-dropdown>
                                        </b-nav>
                                        <span v-else style="line-height: 50px">{{value}}</span>
                                    </template>
                                </b-table>
                            </b-tab>
                        </b-tabs>
                    </div>
                </section>
            </div>
            <Loading></Loading>
        </main>
    </div>
</template>
<script>
    import SidebarMenu from '../../components/SidebarMenu'
    import {BDropdownItem, BDropdownItemButton} from 'bootstrap-vue/esm/components/dropdown'
    import {BNav, BNavItemDropdown} from 'bootstrap-vue/esm/components/nav'
    import {BTab, BTabs} from 'bootstrap-vue/esm/components/tabs'
    import {BTable} from 'bootstrap-vue/esm/components/table'
    import Loading from '../../components/Loading'
    import Swal from 'sweetalert2'
    import helpers from '../../utils/helpers.js'

    export default {
        components: {
            SidebarMenu,
            BTabs,
            BTab,
            BTable,
            BNav,
            BNavItemDropdown,
            BDropdownItem,
            BDropdownItemButton,
            Loading
        },
        data() {
            return {
                spread_sheet_fields: [
                    {
                        key: 'clinic_name',
                        label: this.$t('reservation.clinic_name'),
                        thClass: 'text-left',
                        tdClass: 'text-left'
                    },
                    {
                        key: 'new_number',
                        label: this.$t('reservation.number_of_new_slots_reserved'),
                        thClass: 'text-center col-20',
                        tdClass: 'text-center'
                    },
                    {
                        key: 'existing_number',
                        label: this.$t('reservation.number_of_existing_slots_reserved'),
                        thClass: 'text-center col-20',
                        tdClass: 'text-center'
                    }
                ],
                spread_sheet_items: [],
                reservation_setting_fields: [
                    {
                        key: 'clinic_name',
                        label: this.$t('reservation.clinic_name'),
                        thClass: 'text-left',
                        tdClass: 'text-left'
                    },
                    {
                        key: 'frame_reception_new',
                        label: this.$t('reservation.new_frame_reception'),
                        thClass: 'text-center col-30',
                        tdClass: 'text-center full-width'
                    }
                ],
                reservation_setting_items: [],
                reservation_setting_actions: [
                    {name: '可能', value: 1},
                    {name: '停止中', value: 2}
                ],
                selected_col: 'all',
            }
        },
        created() {
            this.requestReservationData();
        },

        methods: {
            eventClickDropdownItem: function (event) {
                var parent = $(event.target).parents('.box-nav-item')[0];
                var selected_value = $(event.target).val();
                var selected_text = $(event.target).text();
                var id = $(parent).data('id');

                if ($(parent).find('.nav-link').text().trim() != selected_text.trim()) {
                    this.$store.commit('set_loading', true)
                    this.$store.dispatch('changeClinicReservableStatus', {clinic_id: id, status: selected_value}).then((result) => {
                        $(parent).find('.nav-link').text(selected_text.trim());
                        $(parent).find('.dropdown-item').removeClass('active');
                        $(event.target).addClass('active');
                        var index = this.reservation_setting_items.map(function (o) {
                            return o.id;
                        }).indexOf(id);
                        this.reservation_setting_items[index].frame_reception_new = selected_text;
                        this.$store.commit('set_loading', false);

                    }).catch((error) => {
                        let res_errors = helpers.safeGet(error, 'response.data.errors') || {},
                            errors = [];
                        for (let index in res_errors) {
                            errors.push(res_errors[index]);
                        }
                        if (errors.length) {
                            Swal.fire({
                                toast: true,
                                type: 'error',
                                position: 'top',
                                title: errors.join('\n'),
                                showConfirmButton: false,
                                timer: 1500,
                                onBeforeOpen: () => {
                                    $('.swal2-top').css({'top': 125 + 'px'});
                                }
                            })
                        }
                        this.$store.commit('set_loading', false);
                    });
                }

            },

            requestReservationData: function () {
                this.$store.commit('set_loading', true);
                this.$store.dispatch('getListReservationSecretariat').then((result) => {
                    this.spread_sheet_items = helpers.safeGet(result, 'data.results.spreadsheet');
                    this.reservation_setting_items = helpers.safeGet(result, 'data.results.setting');
                    this.$store.commit('set_loading', false);
                }).catch((error) => {
                    this.$store.commit('set_loading', false);
                });
            }
        }
    }
</script>


