<template>
  <div class="patient-orders__table">
    <div class="patient-orders__table__title">
      {{ $t('objects.orders.text.number_orders', { number_order: orders.length }) }}
    </div>
    <div class="patient-orders__table__list">
      <table>
        <thead>
          <tr>
            <th>{{ $t('objects.orders.text.order_id') }}</th>
            <th>{{ $t('objects.orders.text.order_date') }}</th>
            <th>{{ $t('objects.orders.text.course_name') }}</th>
            <th>{{ $t('new_text.number_of_time', { number: '' }) }}</th>
            <th>{{ $t('objects.orders.text.estimated_shipping_date') }}</th>
            <th>{{ $t('new_text.status') }}</th>
            <th v-if="isShowButtonPrint" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="order of orders"
            :key="order.id"
            :class="[order.id == orderId ? 'cursor-not-allow' : '']"
          >
            <td @click="moveToOrderDetail(order.id)">
              {{ order.code }}
            </td>
            <td @click="moveToOrderDetail(order.id)">
              {{ formatDateTime(order.created_at) }}
            </td>
            <td
              class="fs-12"
              @click="moveToOrderDetail(order.id)"
            >
              {{ getProductName(order) }}
            </td>
            <td @click="moveToOrderDetail(order.id)">
              {{ $t('new_text.time', { number: order.product_number }) }}
            </td>
            <td @click="moveToOrderDetail(order.id)">
              {{ formatDateTime(order.order_shipping_date) }}
            </td>
            <td
              class="fs-12"
              @click="moveToOrderDetail(order.id)"
            >
              {{ order.order_status ? $t(`objects.orders.text.status.${order.order_status}`) : '' }}
            </td>
            <td v-if="isShowButtonPrint">
              <button-common
                class="patient-orders__table__list__btn-print"
                @click="$emit('print-individual-technical-info', order.id); triggerClick(trigger_click_type.PRINT_OH, order.sf_gbangou, order.id)"
              >
                <template #content>
                  {{ $t('objects.orders.button.print_order_detail') }}
                </template>
              </button-common>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import { formatDateTime, intVal } from '../../../../utils/helpers'
  import ButtonCommon from '../../../common/Button'

  export default {
    components: {
      ButtonCommon
    },
    emit: ['print-individual-technical-info'],
    props: {
      orders: {
        type: Array,
        default: () => []
      },
      orderId: {
        type: Number,
        default: 0
      },
      isShowButtonPrint: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      formatDateTime (orderDate) {
        return formatDateTime(orderDate, 'YYYY/MM/DD')
      },
      getProductName (order) {
        return order?.product?.name || ''
      },
      moveToOrderDetail (orderId) {
        if (orderId === intVal(this.$route.params.order_id)) {
          return
        }

        if (this.$store.getters.isLabDesigner || this.$store.getters.isInstructionAdmin) {
          const url = this.$router.resolve({ name: 'patient.orders.detail', params: { order_id: orderId } }).href

          window.open(url, '_blank')

          return
        }

        this.$router.push({ name: 'patient.orders.detail', params: { order_id: orderId } })
      }
    }
  }
</script>
