var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order-list-files__card" }, [
    _c(
      "div",
      {
        class: [
          "order-list-files__thumb",
          { "order-list-files__thumb--lazy-load": _vm.is_loading }
        ]
      },
      [
        _vm.order.has_instruction_thumb ===
          _vm.order_file_thumb_status.LOADED &&
        /\.pdf|\.PDF/.test(_vm.order.instruction_thumb_url)
          ? _c(
              "div",
              {
                staticClass:
                  "order-list-files__image order-list-files__image--instruction"
              },
              [_c("pdf", { attrs: { src: _vm.order.instruction_thumb_url } })],
              1
            )
          : _vm.order.has_instruction_thumb ===
            _vm.order_file_thumb_status.LOADED
          ? _c("img", {
              staticClass: "order-list-files__image",
              attrs: { src: _vm.order.instruction_thumb_url },
              on: { load: _vm.onImgLoaded }
            })
          : _vm.isLoadFileFail
          ? _c("div", {
              staticClass:
                "order-list-files__image order-list-files__image--default"
            })
          : _c("div", { staticClass: "order-list-files__image" })
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "order-list-files__info" }, [
      _c("div", { staticClass: "order-list-files__text" }, [
        _c(
          "div",
          {
            class: [
              "order-list-files__name",
              { "order-list-files__name--disabled": _vm.isLoadFileFail }
            ],
            on: { click: _vm.previewInstruction }
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.order.instruction_file) + "\n      "
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "order-list-files__created-at" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.formatDateTime(
                  _vm.order.instruction_created_at,
                  "YYYY/M/D HH:mm:ss"
                )
              ) +
              "\n      "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "order-list-files__created-by" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.safeGet(_vm.order, "instruction_upload_by.name")) +
              "\n      "
          )
        ])
      ]),
      _vm._v(" "),
      _vm.isShowDownload
        ? _c(
            "div",
            {
              class: [
                "order-list-files__download",
                { "order-list-files__download--disabled": _vm.isLoadFileFail }
              ]
            },
            [
              _c("img", {
                attrs: {
                  src: _vm.isLoadFileFail
                    ? "/images/icon-download-gray.svg"
                    : "/images/icon-download-blue.svg"
                },
                on: {
                  click: function($event) {
                    return _vm.downloadOrderFile(
                      _vm.getInstructionFileDownloadUrl
                    )
                  }
                }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowPrintStatus
        ? _c("div", { staticClass: "order-list-files__print" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.formatDateTime(
                    _vm.order.instruction_print_date,
                    "YYYY/M/D HH:mm:ss"
                  )
                ) +
                "\n      "
            ),
            _c("span", { staticClass: "order-list-files__print-status" }, [
              _vm._v(_vm._s(_vm.printStatus))
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }