var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "patient-v2__left-sidebar" },
    [
      _c(
        "ul",
        {
          class: [
            "patient-v2__sidebar",
            { "patient-v2__sidebar--hide": _vm.isHideSideBar }
          ]
        },
        [
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  class: {
                    "link-active": _vm.$route.name === "patient.detail"
                  },
                  attrs: {
                    to: {
                      name: "patient.detail",
                      params: { id: _vm.patientId }
                    }
                  }
                },
                [
                  _c("patient-info"),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("objects.nav.patient_info")))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "patient.activity.log",
                      params: { id: _vm.patientId }
                    }
                  }
                },
                [
                  _c("patient-activity"),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("objects.nav.activity_log")))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.isInterview
            ? _c("li", [
                _c(
                  "a",
                  {
                    class: [
                      _vm.$route.name === "patient.interview"
                        ? "router-link-exact-active router-link-active"
                        : "",
                      { "lazy-load": _vm.lazy_load }
                    ],
                    on: {
                      click: function($event) {
                        return _vm.handlePatientInterviewClick()
                      }
                    }
                  },
                  [
                    _c("patient-interview"),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("objects.nav.interview_sheet")))
                    ]),
                    _vm._v(" "),
                    !_vm.isInterviewCompleted
                      ? _c("span", { staticClass: "interview" }, [
                          _vm._v(_vm._s(_vm.$t("text.not_entered")))
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isClinicsDmr === "1" || _vm.isClinicsDmr === 1
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "patients.fee",
                          params: { id: _vm.patientId }
                        }
                      }
                    },
                    [
                      _c("calculator"),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("objects.nav.accounting_info")))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$store.getters.isDisabledLabClinic
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      class: [
                        _vm.$route.name.includes("patient.orders")
                          ? "router-link-exact-active router-link-active"
                          : ""
                      ],
                      attrs: {
                        to: {
                          name: "patient.orders",
                          params: { id: _vm.patientId }
                        }
                      }
                    },
                    [
                      _c("order-list"),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("objects.nav.technician_order")))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  class: [
                    _vm.$route.name.includes("patient.questionnaires")
                      ? "router-link-exact-active router-link-active"
                      : ""
                  ],
                  attrs: {
                    to: {
                      name: "patient.questionnaires",
                      params: { id: _vm.patientId }
                    }
                  }
                },
                [
                  _c("patient-questionnaires"),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("objects.nav.questionnaire_list")))
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.show_sms_sending_modal
        ? _c("modal-interview-s-m-s-sending", {
            on: {
              close: function($event) {
                _vm.show_sms_sending_modal = false
              },
              "detail-button-click": _vm.handleButtonDetailClick,
              "confirm-button-click": _vm.handleButtonConfirmClick
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.show_sms_sending_confirmation
        ? _c("modal-confirm", {
            staticClass: "modal-confirm--black",
            attrs: {
              title: _vm.$t(
                "objects.patient.text.sms_sending_confirmation_title"
              ),
              text: _vm.smsSendingConfirmationMessage
            },
            on: {
              no: function($event) {
                _vm.show_sms_sending_confirmation = false
              },
              yes: _vm.handleSendSMS
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }