import axios from 'axios'
import { CLINIC_RECORD_TYPE, LAYOUT_MODE } from '../../utils/constants'
import { isJson, intVal, checkInstructionForbidden } from '../../utils/helpers'
import { get } from 'lodash'

export const state = {
  status: '',
  token: localStorage.getItem('token') || '',
  user: {},
  clinic: JSON.parse(isJson(localStorage.getItem('clinic')) ? localStorage.getItem('clinic') : '{}'),
  is_instruction_admin: localStorage.getItem('is_instruction_admin') || false,
  disallowIp: '',
  layout_mode: localStorage.getItem('layout_mode') || LAYOUT_MODE.AUTO,
  is_open_notification: localStorage.getItem('is_open_notification') || null
}

export const mutations = {
  authRequest (state) {
    state.status = 'loading'
  },
  authSuccess (state, { token, clinic, isInstructionAdmin = false }) {
    state.status = 'success'
    state.token = token
    state.clinic = clinic
    state.is_instruction_admin = isInstructionAdmin
    localStorage.setItem('token', token)
    localStorage.setItem('clinic', JSON.stringify(clinic))
    localStorage.setItem('is_instruction_admin', isInstructionAdmin)
  },
  authError (state) {
    state.status = 'error'
  },
  logout (state) {
    state.token = ''
    state.user = {}
    state.clinic = {}
    localStorage.removeItem('token')
    localStorage.removeItem('clinic')
    localStorage.removeItem('device_token')
    localStorage.removeItem('search_date')
    localStorage.removeItem('is_instruction_admin')
    localStorage.removeItem('is_open_notification')
  },
  userSuccess (state, { user, clinic, isInstructionAdmin = false }) {
    state.user = user
    state.clinic = clinic
    state.is_instruction_admin = isInstructionAdmin
    localStorage.setItem('clinic', JSON.stringify(clinic))
  },

  disallow_ip (state, status) {
    state.disallowIp = status
  },
  changeLayoutMode (state, mode) {
    state.layout_mode = mode
    localStorage.setItem('layout_mode', mode)
  },
  setIsOpenNotification (state, status) {
    state.is_open_notification = status
    localStorage.setItem('is_open_notification', status)
  },
  updateClinicData (state, { key, value }) {
    const clinic = { ...state.clinic }
    clinic[key] = value
    localStorage.setItem('clinic', JSON.stringify(clinic))
    state.clinic = clinic
  }
}

export const actions = {
  login ({ dispatch, commit }, { data }) {
    commit('authRequest')
    return axios.post('/api/login', data)
      .then((res) => {
        if (!res.data.results.first_login) {
          const token = res?.data?.results?.token || ''
          const clinic = res?.data?.results?.clinic || {}
          const results = res?.data?.results
          const isInstructionAdmin = (results && results.is_instruction_admin) ? results.is_instruction_admin : false
          commit('authSuccess', { token, clinic, isInstructionAdmin })
        }
        return res
      })
      .catch(error => {
        commit('authError')
        commit('logout')
        throw error
      })
  },

  sendMailResetPassword ({ dispatch, commit }, { staff_id, email, lang }) {
    return axios.post('/api/email/reset', {
      staff_id,
      email,
      lang
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getEmailByToken ({ dispatch, commit }, { token }) {
    return axios.post('/api/email/reset/check', {
      token
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  resetPasswordRequest ({ dispatch, commit }, { token, email, password, password_confirmation, staff_id }) {
    return axios.post('/api/reset', {
      token,
      email,
      password,
      password_confirmation,
      staff_id
    })
      .then((res) => {
        const token = res?.data?.results?.token || ''
        const clinic = res?.data?.results?.clinic || {}
        commit('authSuccess', { token, clinic })
        return res
      })
      .catch(error => {
        throw error
      })
  },
  initPasswordRequest ({ dispatch, commit }, { token, staff_id, password, password_confirmation }) {
    return axios.post('/api/initPassword', {
      token,
      staff_id,
      password,
      password_confirmation
    })
      .then((res) => {
        const token = res?.data?.results?.token || ''
        const clinic = res?.data?.results?.clinic || {}
        const results = res?.data?.results
        const isInstructionAdmin = (results && results.is_instruction_admin) ? results.is_instruction_admin : false
        commit('authSuccess', { token, clinic, isInstructionAdmin })
        return res
      })
      .catch(error => {
        throw error
      })
  },
  logout ({ commit }) {
    if (state.token != null) {
      return axios.get('/api/logout', {
        params: {
          device_token: localStorage.getItem('device_token')
        },
        headers: { Authorization: 'Bearer ' + state.token }
      }).then((res) => {
        commit('logout')
        window.location.reload()
        return res
      })
        .catch(error => {
          commit('logout')
          window.location.reload()
          throw error
        })
    }
  },
  getLoggedInfo ({ commit }) {
    return axios.get('/api/staff/info', {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        const user = res?.data?.results?.user
        if (typeof user !== 'undefined') {
          const clinic = user?.clinic || {}
          commit('userSuccess', { user, clinic })
        }
        return res
      })
      .catch(error => {
        commit('user_failure')
        throw error
      })
  },
  verifyStaffPassword ({ dispatch, commit }, postData) {
    return axios.post('/api/staff/password', postData, {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getInstructionAdminInfo ({ commit }) {
    return axios.get('/api/instruction-admin/info', {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        const instructionAdmin = res?.data?.results
        if (typeof instructionAdmin !== 'undefined') {
          const isInstructionAdmin = true
          const clinic = {}
          commit('userSuccess', { user: instructionAdmin, clinic, isInstructionAdmin })
        }
        return res
      })
      .catch(error => {
        checkInstructionForbidden(error, this)
      })
  }
}

export const getters = {
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  isAdmin: state => {
    return typeof state.user !== 'undefined' && typeof state.user.is_admin !== 'undefined' ? state.user.is_admin : 0
  },
  isDisallowIp: state => state.disallowIp,
  currentUser: state => state.user,
  currentClinic: state => state.clinic,
  isLabDesigner: state => {
    const clinic = state.clinic
    return typeof clinic !== 'undefined' &&
      typeof clinic.record_type_id !== 'undefined' &&
      clinic.record_type_id === CLINIC_RECORD_TYPE.LAB_DESIGNER
  },
  isLabClinic: state => {
    const clinic = state.clinic
    return typeof clinic !== 'undefined' &&
      typeof clinic.record_type_id !== 'undefined' &&
      clinic.is_lab === 1
  },
  isInstructionAdmin: state => {
    return String(state.is_instruction_admin) === 'true'
  },
  isDisabledLabClinic: state => {
    const clinic = state.clinic || {}

    return clinic.record_type_id === CLINIC_RECORD_TYPE.CLINIC && intVal(clinic.is_lab) === 0
  },
  isStaff: state => {
    const clinic = state.clinic || {}

    return clinic.record_type_id === CLINIC_RECORD_TYPE.CLINIC
  },
  isInterviewSheet: state => {
    const clinic = state.clinic
    return typeof clinic !== 'undefined' &&
      typeof clinic.is_interview !== 'undefined' &&
      clinic.is_interview === 1
  },
  isDetectAutoFrameOperation: state => {
    return get(state, 'clinic.is_detect_auto_frame_operation', 0) === 1
  },
  isSupportCsl: state => {
    return get(state, 'clinic.is_support_csl', 0) === 1
  }
}
