var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.value,
        expression: "value"
      }
    ],
    staticClass: "input-default",
    domProps: { value: _vm.value },
    on: {
      input: [
        function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.value = $event.target.value
        },
        function($event) {
          return _vm.$emit("input", $event.target.value)
        }
      ],
      keypress: function($event) {
        return _vm.$emit("keypress", $event)
      },
      paste: function($event) {
        return _vm.$emit("paste", $event)
      },
      click: function($event) {
        return _vm.$emit("click", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }