var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.reservationPatients.length > 0
        ? _c(
            "div",
            _vm._l(_vm.reservationPatients, function(res) {
              return _c(
                "ul",
                { key: res.id, staticClass: "reservation-list" },
                [
                  _c("li", { staticClass: "reservation__item" }, [
                    _c(
                      "div",
                      { staticClass: "reservation__item__allocation" },
                      [
                        _c("label", [
                          _vm._v(
                            _vm._s(_vm.safeGet(res, "allocation.column_name"))
                          )
                        ]),
                        _vm._v(" "),
                        _vm.is_clinics_dmr === "1" || _vm.is_clinics_dmr === 1
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.getReservationStatus(res)))
                            ])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "reservation__item__date" }, [
                      _c("span", { staticClass: "reservation__item__tag" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("objects.reservation.text.datetime")
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "reservation__item__time" }, [
                        _vm._v(_vm._s(_vm.getReservationDate(res)))
                      ])
                    ]),
                    _vm._v(" "),
                    res.duties.length
                      ? _c(
                          "div",
                          { staticClass: "reservation__item__duty-type" },
                          _vm._l(res.duties, function(duty, idx) {
                            return _c(
                              "div",
                              {
                                key: idx,
                                staticClass: "reservation__item__type"
                              },
                              [
                                duty.master && duty.master.duty
                                  ? _c("span", {
                                      style: {
                                        background: duty.master.duty.color
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                duty.master
                                  ? _c("label", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(duty.master.duty_name) +
                                          " " +
                                          _vm._s(
                                            _vm.getTakeTime(
                                              duty.master.take_time
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    res.memo
                      ? _c("div", { staticClass: "reservation__item__memo" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "reservation__item__tag reservation__item__custom"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("objects.reservation.text.memo")
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "reservation__item__reason" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(res.memo) +
                                  "\n          "
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    res.reservation_cancelled
                      ? _c("div", { staticClass: "reservation__item__memo" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "reservation__item__tag reservation__item__custom"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "objects.reservation.text.cancelled_reason"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "reservation__item__reason" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.safeGet(
                                      res,
                                      "reservation_cancelled.cancelled_reason"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    res.status === _vm.reservation_status.RESERVED
                      ? _c(
                          "div",
                          { staticClass: "reservation__item__action" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn-v2 btn-v2--print-examination",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.printExamination(res.id)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "objects.patient.button.print_examination"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            res.allocation &&
                            res.clinics_id === res.allocation.clinic_id &&
                            _vm.show_reservation
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "btn-v2 btn-v2--display label-span label--blueBtn",
                                    attrs: {
                                      to: {
                                        name: "reservation.list",
                                        query: {
                                          search_date: res.started_to,
                                          reservation_id: res.id
                                        }
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("new_text.button.display")
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn-v2 btn-v2--cancel",
                                attrs: {
                                  type: "button",
                                  disabled:
                                    _vm.firstReservationMonth &&
                                    _vm.firstReservationId === res.id
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.cancelHandle(res.id)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("new_text.button.cancel")) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "reservation__item__line" })
                  ]),
                  _vm._v(" "),
                  _c("reservation-history", { attrs: { reservation: res } })
                ],
                1
              )
            }),
            0
          )
        : _c(
            "div",
            { staticClass: "no-reservation" },
            [
              _c("img", { attrs: { src: "/images/icon-schedule.svg" } }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("objects.reservation.text.no_reservation"))
                )
              ]),
              _vm._v(" "),
              _c("button-common", {
                staticClass: "button-v2--primary button-v2--120",
                on: {
                  click: function($event) {
                    return _vm.$router.push({ name: "dashboard" })
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t("objects.patient.text.new_reservation")
                            ) +
                            "\n      "
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
      _vm._v(" "),
      _vm.selected_reservation_id
        ? _c("modal-reason-cancel", {
            attrs: { "reservation-id": _vm.selected_reservation_id },
            on: {
              close: function($event) {
                _vm.selected_reservation_id = 0
              },
              submit: _vm.submitCancelReservation
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }