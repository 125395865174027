<template>
  <div :class="{'input-search-v2': true, 'input-search-v2__disabled': disabled }">
    <span
      v-if="iconPosition === 'left'"
      class="input-search-v2__icon-left"
    >
      <img
        :src="icon"
        @click="$emit('search', keyword)"
      >
    </span>
    <textarea
      v-if="type==='search-name' && isMac"
      v-model="keyword"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="onInput"
    />
    <input
      v-else
      v-model="keyword"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$emit('search', keyword)"
    >
    <span
      v-if="iconPosition === 'right' && !disableSelectPatient"
      class="input-search-v2__icon-right"
    >
      <img
        :src="disabled ? iconDisabled : icon"
        @click="$emit('search', keyword)"
      >
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      defaultValue: {
        type: [String, Number],
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: '/images/icon-search-v2.svg'
      },
      iconDisabled: {
        type: String,
        default: '/images/icon-search-gray.svg'
      },
      iconPosition: {
        type: String,
        default: 'right'
      },
      placeholder: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      },
      disableSelectPatient: {
        type: Boolean,
        default: false
      }
    },
    emits: ['input', 'search'],
    data () {
      return {
        keyword: ''
      }
    },
    computed: {
      isMac () {
        return navigator.platform.indexOf('Mac') > -1
      }
    },
    watch: {
      defaultValue (newValue) {
        this.keyword = newValue
      }
    },
    created () {
      this.keyword = this.defaultValue
    },
    methods: {
      onInput (event) {
        const val = event.target.value
        this.$emit('input', val)
        const nlAt = val.indexOf('\n')
        if (nlAt >= 0) {
          this.keyword = val.replace(/\n/g, '')
          this.$emit('search', this.keyword)
        }
      }
    }
  }
</script>
