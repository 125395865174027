<template>
  <router-view />
</template>

<script>
  export default {
    created () {
      if (this.$store.state.auth.token) {
        this.$router.push({ name: 'dashboard' })
      }
    }
  }
</script>
