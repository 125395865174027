var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { class: ["mc-modal", _vm.custom_class] }, [
      _c("div", { staticClass: "mc-modal__wrapper" }, [
        _c("div", { staticClass: "mc-modal__container" }, [
          _c(
            "div",
            { staticClass: "mc-modal__header" },
            [
              _vm._t("header"),
              _vm._v(" "),
              _vm.is_show_close_button
                ? _c(
                    "button",
                    {
                      staticClass: "mc-modal__header__close",
                      attrs: {
                        type: "button",
                        "aria-label": "Close this dialog"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._v("×\n                    ")]
                  )
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mc-modal__body" }, [_vm._t("body")], 2),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                "mc-modal__footer",
                { "mc-modal__footer--reverse": _vm.reverseButton }
              ]
            },
            [
              _vm.is_show_cancellation_button
                ? _c(
                    "button",
                    {
                      staticClass:
                        "mc-modal__footer__button mc-modal__footer__button--first",
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._t("cancel_button_content")],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.is_show_approval_button
                ? _c(
                    "button",
                    {
                      staticClass:
                        "mc-modal__footer__button mc-modal__footer__button--second",
                      on: {
                        click: function($event) {
                          return _vm.$emit("confirmButtonClick")
                        }
                      }
                    },
                    [_vm._t("confirm_button_content")],
                    2
                  )
                : _vm._e()
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }