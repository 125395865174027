export default {
  text: {
    block_frame_editing: 'Edit Reservation',
    cancel_reservation: 'Cancel reservation',
    cancellation_time: 'Cancellation date and time',
    cancelled_reason: 'Reason for cancellation',
    canceller: 'Canceller',
    change: 'Change',
    change_setting_clinic: '(Change the settings from the clinic settings)',
    datetime: 'Reservation date and time',
    datetime_change: 'Change date and time',
    delete_allocation_title: 'Do you want to delete {name}?',
    edit_reservation: 'Edit reservation',
    include_memo: 'Would you like to include memo?',
    instruction_note: '※From the viewpoint of quality control of ordering, we recommend that you create a technical instruction sheet in Kirekal. Thank you for your cooperation.',
    memo: 'Memo',
    memo_title: 'Note {date}',
    name: 'Name',
    new_reservation: 'New reservation',
    no_print_calendar: 'No Memo',
    no_reservation: 'There is no visit reservation',
    print_calendar: 'Print Memo',
    print_instruction: 'Print technician instructions',
    print_multiple_order_details: 'Batch printing of order details',
    print_order_detail_for_people_scheduled: 'Print order details for people scheduled to visit today',
    print_order_detail_of_checked_person: 'Print order details of checked {num} people',
    print_target: 'Print target',
    print_template: 'Print {num} checked templates',
    print_visit_shedule: 'Print the visit schedule',
    reservation_date_and_time: 'Reservation date and time',
    reservation_status: {
      status_0: 'New reservation',
      status_1: 'Before Reception',
      status_2: 'Checked In',
      status_3: 'Before Actuarial',
      status_4: 'Paid'
    },
    reservation_change: 'Reservation change',
    reservation_print_instruction_title: 'Print the technical instructions template for those who are planning to visit today',
    reservation_type: 'Reservation type',
    reservations_frame: 'Reservation frame',
    return_patient: 'Include return patients',
    save_memo_confirm: 'If a memo has already been entered\n' +
      'It will be overwritten, is that okay?',
    save_memo_title: 'Save the note',
    schedule_visit: 'Scheduled to visit today',
    search_cancel_reservation_title: 'Cancellation list',
    unblock_frame: 'Unblock frame',
    update_memo: 'Update memo',
    update_reservation: 'Update reservation'
  },
  messages: {
    allocation_created: 'Created new frame',
    allocation_deleted: 'Removed the frame',
    allocation_updated: 'Updated the frame',
    daily_memo_save_fail: 'Failed to save the memo',
    daily_memo_save_successfully: 'The memo has been saved',
    examination_template_not_exist: 'Template PDF has not been uploaded. Please contact your system administrator.',
    no_reservation_information: 'The technician\'s instructions cannot be downloaded because there is no reservation information.',
    reservation_can_not_cancel_because_has_order: '※Since this patient has already placed an order, the initial appointment cannot be canceled.',
    reservation_can_not_change: '※You cannot change the date and time of a past first-visit appointment.',
    reservation_can_not_change_because_has_order: '※Since this patient has already placed an order, the date and time of the initial appointment cannot be changed.',
    reservation_cancelled: 'Cancelled reservation',
    reservation_has_been_canceled: 'This reservation has already been canceled',
    reservations_already_exist: 'This frame is in use and cannot be deleted',
    reservations_canceled_outside_business_time: 'Cannot be displayed because it is outside business time'
  }
}
