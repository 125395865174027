<template>
  <div class="patient-list patient-list--v2">
    <div class="patient-list__header">
      <h2 class="patient-list__heading">
        {{ $t('new_text.patient_list') }}
        <span class="patient-list__heading__number">{{ separatorNumber(pagination.total) }}</span>
      </h2>
      <button-common
        class="patient-list__btn patient-list__btn--create-patient"
        @click="$router.push({ name: 'patient.registration' })"
      >
        <template #content>
          <img src="/images/icon-edit-patient.svg">
          {{ $t('objects.patient.button.patient_registration') }}
        </template>
      </button-common>
    </div>
    <div class="patient-list__search">
      <form
        name="patient-search"
        @submit.prevent="submitSearchDetail(true)"
      >
        <div class="patient-list__inner patient-list__inner--left">
          <div class="patient-list__search--id">
            <label for="search_id">{{ $t('new_text.patient_id') }}</label>
            <input-common
              id="search_id"
              :default-value="search.id"
              :placeholder="$t('objects.patient.text.search_by_id')"
              name="search_id"
              type="number"
              @input="value => search.id = value"
            />
          </div>
          <div class="patient-list__search--name">
            <label for="search_name">{{ $t('new_text.patient_name') }}</label>
            <input-common
              id="search_name"
              :default-value="search.name"
              :placeholder="$t('objects.patient.text.search_by_name')"
              name="search_name"
              @input="value => search.name = value"
            />
          </div>
          <div class="patient-list__search--phone">
            <label for="search_phone">{{ $t('new_text.phone_number') }}</label>
            <input-common
              id="search_phone"
              v-model="search.tel"
              :default-value="search.tel"
              :placeholder="$t('objects.patient.text.search_by_tel')"
              name="search_phone"
              type="number"
              @keypress="validPhoneNumber"
              @paste="validPhoneNumber"
              @keyup="validPhoneNumber"
            />
          </div>
        </div>
        <div class="patient-list__inner patient-list__inner--right">
          <button-common
            class="patient-list__btn patient-list__btn--search"
            type="submit"
          >
            <template #content>
              {{ $t('new_text.button.search') }}
            </template>
          </button-common>
          <button-common
            :class="[
              'patient-list__btn patient-list__btn--reset',
              { 'patient-list__btn--reset--active': enableResetSearch }
            ]"
            :disabled="!enableResetSearch"
            @click="resetSearch"
          >
            <template #content>
              {{ $t('new_text.button.reset') }}
            </template>
          </button-common>
        </div>
        <div class="patient-list__search--expanded">
          <button-common
            class="patient-list__btn patient-list__btn--detailed-search"
            @click="detailed_search = !detailed_search"
          >
            <template #content>
              {{ $t('new_text.button.advanced_search') }}
              <img
                :class="[{ 'show': detailed_search }]"
                src="/images/blue-arrow-down.svg"
              >
            </template>
          </button-common>
          <div
            v-if="detailed_search"
            class="patient-list__detailed-search"
          >
            <div class="patient-list__wrap">
              <div class="patient-list__search__row patient-list__search__row--radio">
                <label class="patient-list__label patient-list__label--absolute">{{ $t('new_text.sex') }}</label>
                <radio-common
                  v-for="sex in genders"
                  :key="sex.id"
                  :label="sex.name"
                  :val="sex.id"
                  :name="'gender'"
                  :is-checked="sex.id === search.gender"
                  @select="value => search.gender = value"
                />
              </div>
              <div class="patient-list__search__row patient-list__search__row--select-date">
                <div class="patient-list__search__row--year">
                  <label class="patient-list__label patient-list__label--absolute">{{ $t('new_text.birthday') }}</label>
                  <select-common
                    :options="selectYear"
                    :default-value="search.year"
                    class="patient-list__search--year"
                    name="search_year"
                    @change="value => search.year = value"
                  />
                </div>
                <label>{{ $t('new_text.year') }}</label>
                <select-common
                  :options="selectMonth"
                  :default-value="search.month"
                  class="patient-list__search--month"
                  name="search_month"
                  @change="value => search.month = value"
                />
                <label>{{ $t('new_text.month') }}</label>
                <select-common
                  :options="selectDayOfMonth(search.year, search.month)"
                  :default-value="search.day"
                  class="patient-list__search--day"
                  name="search_day"
                  @change="value => search.day = value"
                />
                <label>{{ $t('new_text.day') }}</label>
                <select-common
                  :options="selectAge"
                  :default-value="getAge !== '' ? getAge : search.age"
                  class="patient-list__search--age"
                  name="search_age"
                  @change="setAge"
                />
                <label>{{ $t('new_text.age') }}</label>
              </div>
              <div class="patient-list__search__row patient-list__search__row--email">
                <label class="patient-list__label patient-list__label--absolute">{{ $t('new_text.email') }}</label>
                <input-common
                  id="search_email"
                  :default-value="search.email"
                  :placeholder="$t('objects.patient.text.search_by_email')"
                  name="search_email"
                  class="patient-list__search--email"
                  @input="value => search.email = value"
                />
              </div>
            </div>
            <div class="patient-list__wrap">
              <div class="patient-list__search__row patient-list__search__row--radio patient-list__search__row--period">
                <label class="patient-list__label patient-list__label--absolute">{{ $t('objects.patient.text.specify_the_period') }}</label>
                <radio-common
                  v-for="condition in patient_conditions"
                  :key="condition.id"
                  :label="condition.name"
                  :val="condition.id"
                  :name="'condition'"
                  :is-checked="condition.id === search.patient_condition"
                  @select="value => search.patient_condition = value"
                />
              </div>
              <div class="patient-list__search__row patient-list__search__row--selected-day">
                <div class="patient-list__search__row--year">
                  <label class="patient-list__label patient-list__label--absolute">{{ $t('objects.patient.text.period_start_date') }}</label>
                  <select-common
                    :options="selectReservationYear"
                    :default-value="search.reservation_start.year"
                    :disabled="search.patient_condition === 'none'"
                    class="patient-list__search--year"
                    name="search_reservation_start_year"
                    @change="value => search.reservation_start.year = value"
                  />
                </div>
                <label>{{ $t('new_text.year') }}</label>
                <select-common
                  :options="selectMonth"
                  :default-value="search.reservation_start.month"
                  :disabled="search.patient_condition === 'none'"
                  class="patient-list__search--month"
                  name="search_reservation_start_month"
                  @change="value => search.reservation_start.month = value"
                />
                <label>{{ $t('new_text.month') }}</label>
                <select-common
                  :options="selectDayOfMonth(search.reservation_start.year, search.reservation_start.month)"
                  :default-value="search.reservation_start.day"
                  :disabled="search.patient_condition === 'none'"
                  class="patient-list__search--day"
                  name="search_reservation_start_day"
                  @change="value => search.reservation_start.day = value"
                />
                <label>{{ $t('new_text.day') }}{{ $t('new_text.tilde') }}</label>
                <div class="patient-list__search__row--year patient-list__search__row--relative">
                  <label class="patient-list__label patient-list__label--end-date">{{ $t('objects.patient.text.period_end_date') }}</label>
                  <select-common
                    :options="selectReservationYear"
                    :default-value="search.reservation_end.year"
                    :disabled="search.patient_condition === 'none'"
                    class="patient-list__search--year"
                    name="search_reservation_end_year"
                    @change="value => search.reservation_end.year = value"
                  />
                </div>
                <label>{{ $t('new_text.year') }}</label>
                <select-common
                  :options="selectMonth"
                  :default-value="search.reservation_end.month"
                  :disabled="search.patient_condition === 'none'"
                  class="patient-list__search--month"
                  name="search_reservation_end"
                  @change="value => search.reservation_end.month = value"
                />
                <label>{{ $t('new_text.month') }}</label>
                <select-common
                  :options="selectDayOfMonth(search.reservation_end.year, search.reservation_end.month)"
                  :default-value="search.reservation_end.day"
                  :disabled="search.patient_condition === 'none'"
                  class="patient-list__search--day"
                  name="search_reservation_end_day"
                  @change="value => search.reservation_end.day = value"
                />
                <label>{{ $t('new_text.day') }}</label>
              </div>
              <div
                hidden
                class="patient-list__search__row patient-list__search__row--treatment-details"
              >
                <label class="patient-list__label patient-list__label--absolute">{{ $t('objects.patient.text.treatment_details') }}</label>
                <div @click="showDetailSelectTreatment">
                  <input-common
                    id="search_treatment_id"
                    :default-value="search.treatment_name"
                    :placeholder="$t('new_text.placeholder.please_select')"
                    disabled
                    name="treatment_id"
                  />
                </div>
              </div>
            </div>
            <div class="patient-list__wrap">
              <div class="patient-list__search__row patient-list__search__row--brand-type">
                <label class="patient-list__label patient-list__label--absolute">{{ $t('new_text.brand') }}</label>
                <handmade-select
                  v-model="search.brand_type"
                  :options="brandTypeOptions"
                  key-value="type"
                  :placeholder="$t('objects.patient.text.search_by_brand_type')"
                  :empty-value="$t('new_text.no_selection')"
                  parent-class-scroll="patient-list--v2"
                />
              </div>
              <div class="patient-list__search__row patient-list__search__row--status-c">
                <label class="patient-list__label patient-list__label--absolute">{{ $t('new_text.status') }}</label>
                <handmade-select
                  v-model="search.sf_status_c"
                  :options="sfStatusCForSearchOptions"
                  :placeholder="$t('objects.patient.text.search_by_sf_status_c')"
                  :empty-value="$t('new_text.no_selection')"
                  parent-class-scroll="patient-list--v2"
                />
              </div>
              <div
                v-if="isSupportCsl"
                class="patient-list__search__row patient-list__search__row--csl-brand"
              >
                <label class="patient-list__label patient-list__label--absolute">{{ $t('new_text.csl_brand') }}</label>
                <handmade-select
                  v-model="search.csl_brand_type"
                  :options="cslBrandTypeOptions"
                  :placeholder="$t('new_text.placeholder.please_select')"
                  :empty-value="$t('new_text.no_selection')"
                  key-value="type"
                  parent-class-scroll="patient-list--v2"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div>
      <div
        :class="[
          'common-list common-list--patient',
          { 'common-list--patient-has-csl-brand': isSupportCsl },
          'common-list--sticky'
        ]"
      >
        <table class="main-list">
          <thead>
            <tr>
              <th
                class="common-list__id common-list__sort"
                @click="sortBy('sf_user_id')"
              >
                {{ $t('new_text.id') }}
                <span class="common-list__icon">
                  <img :src="getIconSort('sf_user_id')">
                </span>
              </th>
              <th class="common-list__name">
                {{ $t('new_text.name') }}
              </th>
              <th class="common-list__age">
                {{ $t('new_text.age_title') }}
              </th>
              <th class="common-list__phone">
                {{ $t('new_text.phone_number') }}
              </th>
              <th class="common-list__brand">
                {{ $t('new_text.brand') }}
              </th>
              <th class="common-list__status">
                {{ $t('new_text.status') }}
              </th>
              <th class="common-list__in-charge">
                {{ $t('new_text.in_charge') }}
              </th>
              <th
                class="common-list__first-return-day common-list__sort"
                @click="sortBy('started_to')"
              >
                {{ $t('new_text.first_return_day') }}
                <span class="common-list__icon">
                  <img :src="getIconSort('started_to')">
                </span>
              </th>
              <th
                v-if="isSupportCsl"
                class="common-list__csl-brand"
              >
                {{ $t('new_text.csl_brand') }}
              </th>
            </tr>
          </thead>
          <tbody v-if="patients.length > 0">
            <tr
              v-for="patient in patients"
              :key="patient.id"
              @click="redirectDetail(patient)"
            >
              <td class="common-list__id">
                {{ patient.sf_user_id || patient.patient_id }}
              </td>
              <td class="common-list__name">
                {{ renderFullName(patient) }}
                <span>{{ renderNameFurigana(patient) }}</span>
              </td>
              <td class="common-list__age">
                {{ renderAge(patient.birth_of_date) }}
              </td>
              <td class="common-list__phone">
                {{ patient.tel }}
              </td>
              <td class="common-list__brand">
                {{ renderBrand(patient.brand_type) }}
              </td>
              <td class="common-list__status">
                {{ renderStatus(patient.sf_status_c) }}
              </td>
              <td class="common-list__in-charge">
                {{ patient.name_staff }}
              </td>
              <td class="common-list__first-return-day">
                {{ renderFirstVisitDate(patient) }}
              </td>
              <td
                v-if="isSupportCsl"
                class="common-list__csl-brand"
              >
                {{ renderBrand(patient.csl_brand_type) }}
              </td>
            </tr>
          </tbody>
          <tbody
            v-else
            class="common-list__not-found"
          >
            <tr>
              <td>
                <p class="common-list__label-not-found">
                  {{ $t('new_validation.patient.no_patient_search') }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="patients.length"
          class="common-list__pagination"
        >
          <pagination-common
            v-if="patients.length"
            :current-page="pagination.current_page"
            :last-page="pagination.last_page"
            class="pagination-common--patient"
            @change-page="changePage"
          />
        </div>
      </div>
    </div>
    <modal-select-treatment
      :treatment-data="treatment_data"
      @treatmentData="setTreatment"
    />
  </div>
</template>

<script>
  import ButtonCommon from '../../../components/common/Button'
  import InputCommon from '../../../components/common/Input'
  import RadioCommon from '../../../components/common/Radio'
  import SelectCommon from '../../../components/common/Select'
  import HandmadeSelect from '../../../components/common/HandmadeSelect'
  import {
    safeGet,
    getAgeString,
    formatDateTime,
    formatCurrency,
    showMessageError,
    showErrorModal,
    getBrandNameFromType
  } from '../../../utils/helpers'
  import * as moment from 'moment-timezone'
  import ModalSelectTreatment from '../../../components/ModalSelectTreatment'
  import PaginationCommon from '../../../components/common/PaginationCommon'
  import sortMixin from '../../../mixins/sort'
  import {
    BRAVA_BRAND_TYPE,
    INVISALIGN_BRAND_TYPE,
    KIREILIGN_TYPE,
    PATIENT_SF_STATUS_C,
    WIRE_BRAND_TYPE
  } from '../../../utils/constants'
  import { validInput } from '../../../mixins/valid-input'
  import { filter } from 'lodash'

  export default {
    name: 'PatientList',
    components: {
      InputCommon,
      ButtonCommon,
      RadioCommon,
      SelectCommon,
      PaginationCommon,
      HandmadeSelect,
      ModalSelectTreatment
    },
    mixins: [validInput, sortMixin],
    data () {
      return {
        search: {
          key_word: '',
          id: '',
          name: '',
          tel: '',
          gender: 0,
          age: '',
          email: '',
          patient_condition: 'none',
          year: null,
          month: null,
          day: null,
          reservation_start: {
            year: null,
            month: null,
            day: null
          },
          reservation_end: {
            year: null,
            month: null,
            day: null
          },
          treatment_id: '',
          treatment_type: '',
          treatment_name: '',
          brand_type: '',
          sf_status_c: '',
          csl_brand_type: ''
        },
        patients: [],
        pagination: [],
        detailed_search: false,
        treatment_data: {},
        is_search_detail: false,
        data_search: {},
        brand_type_options: [],
        page: 1
      }
    },
    computed: {
      genders: function () {
        return this.$t('objects.patient.gender_filter')
      },
      patient_conditions: function () {
        return this.$t('objects.patient.patient_condition')
      },
      selectAge: function () {
        const age = []
        for (let i = 1; i <= 150; i++) {
          age.push({ id: i, name: i })
        }

        return age
      },
      selectReservationYear () {
        const year = []
        for (let i = 2019; i <= moment().add(1, 'Y').format('YYYY'); i++) {
          year.push({ id: i, name: i })
        }

        return year
      },
      selectYear () {
        const year = []
        for (let i = 1920; i <= moment().format('YYYY'); i++) {
          year.push({ id: i, name: i })
        }

        return year
      },
      selectMonth: function () {
        const month = []
        for (let i = 1; i <= 12; i++) {
          month.push({ id: i, name: i })
        }

        return month
      },
      getAge: function () {
        if (this.search.year && this.search.month && this.search.day) {
          const birthday = moment(`${this.search.year}-${this.search.month}-${this.search.day}`, 'YYYY-MM-DD')
          return moment().diff(birthday, 'years')
        }

        return ''
      },
      getReservationStart: function () {
        if (this.search.reservation_start.year && this.search.reservation_start.month && this.search.reservation_start.day) {
          const year = this.search.reservation_start.year
          let month = this.search.reservation_start.month || '1'
          month = month < 10 ? '0' + month : month
          let day = this.search.reservation_start.day || '1'
          day = day < 10 ? '0' + day : day

          return moment(`${year}-${month}-${day}`).format('YYYY-MM-DD')
        }
        return ''
      },
      getReservationEnd: function () {
        if (this.search.reservation_end.year && this.search.reservation_end.month && this.search.reservation_end.day) {
          const year = this.search.reservation_end.year
          let month = this.search.reservation_end.month || '1'
          month = month < 10 ? '0' + month : month
          let day = this.search.reservation_end.day || '1'
          day = day < 10 ? '0' + day : day

          return moment(`${year}-${month}-${day}`).format('YYYY-MM-DD')
        }
        return ''
      },
      enableResetSearch () {
        return (
          this.search.id !== '' ||
          this.search.name !== '' ||
          this.search.tel !== '' ||
          this.search.gender !== 0 ||
          this.search.age !== '' ||
          this.search.email !== '' ||
          this.search.year !== null ||
          this.search.month !== null ||
          this.search.day !== null ||
          this.search.patient_condition !== 'none' ||
          this.search.treatment_id !== '' ||
          this.search.treatment_type !== '' ||
          this.search.treatment_name !== '' ||
          this.search.brand_type !== '' ||
          this.search.sf_status_c !== '' ||
          this.search.csl_brand_type !== ''
        )
      },
      brandTypeOptions () {
        return this.brand_type_options.map((item) => {
          return _.merge(filter(this.$t('objects.patient.brand_master_filter'), origin => origin.type === item.type))
        }).flat()
      },
      sfStatusCForSearchOptions () {
        return filter(this.$t('objects.patient.sf_status_c_new'), item => item.id !== PATIENT_SF_STATUS_C.DELETED)
      },
      cslBrandTypeOptions () {
        const wordingBrandIds = [KIREILIGN_TYPE, INVISALIGN_BRAND_TYPE, WIRE_BRAND_TYPE, BRAVA_BRAND_TYPE]

        return filter(this.$t('objects.patient.brand_master_filter'), item => wordingBrandIds.includes(item.type))
      },
      isSupportCsl () {
        return this.$store.getters.isSupportCsl
      }
    },
    watch: {
      'search.reservation_start.month' (newVal) {
        const dayOfMonth = this.selectDayOfMonth(this.search.reservation_start.year, newVal).length
        if (dayOfMonth < this.search.reservation_start.day) {
          this.search.reservation_start.day = dayOfMonth
        }
      },
      'search.reservation_end.month' (newVal) {
        const dayOfMonth = this.selectDayOfMonth(this.search.reservation_end.year, newVal).length
        if (dayOfMonth < this.search.reservation_end.day) {
          this.search.reservation_end.day = dayOfMonth
        }
      },
      'search.patient_condition' (newVal, oldVal) {
        if (newVal === 'none') {
          this.search.reservation_start = {
            year: null,
            month: null,
            day: null
          }
          this.search.reservation_end = {
            year: null,
            month: null,
            day: null
          }
        } else if (newVal !== 'none' && oldVal === 'none') {
          this.defaultSearchReservationStart()
        }
      }
    },
    created () {
      document.title = this.$t('objects.page_title.patient').toString()
      this.getPatientList()
      this.arrivedDateChange()
      this.getBrandTypeOptions()
    },
    methods: {
      defaultSearchReservationStart () {
        const currenTime = moment()

        this.search.reservation_start = {
          year: currenTime.clone().subtract(1, 'months').format('YYYY'),
          month: currenTime.clone().subtract(1, 'months').format('M'),
          day: currenTime.clone().subtract(1, 'months').format('D')
        }

        this.search.reservation_end = {
          year: currenTime.clone().format('YYYY'),
          month: currenTime.clone().format('M'),
          day: currenTime.clone().format('D')
        }

        if (currenTime.clone().subtract(1, 'months').format('D') !== currenTime.format('D')) {
          this.search.reservation_start = {
            year: currenTime.format('YYYY'),
            month: currenTime.format('M'),
            day: currenTime.clone().startOf('month').format('D')
          }
        }
      },
      selectDayOfMonth: function (year, month) {
        year = moment(year).format('YYYY') || moment().format('YYYY')
        month = month || moment().format('MM')
        month = month < 10 ? '0' + month : month
        const daysInMonth = moment(`${year}-${month}-01`).daysInMonth()
        const days = []
        if (daysInMonth) {
          for (let i = 1; i <= daysInMonth; i++) {
            days.push({ id: i, name: i })
          }
        }

        return days
      },
      renderBrand: function (brand) {
        return getBrandNameFromType(brand, this.$t('objects.patient.brand_master_filter'))
      },
      redirectDetail: function (patient) {
        const url = this.$router.resolve({
          name: 'patient.detail',
          params: { id: patient.patient_id },
          query: {
            reservation_id: safeGet(patient, 'reservation_next_id')
              ? safeGet(patient, 'reservation_next_id')
              : undefined
          }
        }).href

        window.open(url, '_blank')
      },
      renderFullName: function (patient) {
        return safeGet(patient, 'last_name').length ||
          safeGet(patient, 'first_name').length
          ? safeGet(patient, 'name')
          : `${safeGet(patient, 'last_name')} ${safeGet(patient, 'first_name')}`
      },
      renderNameFurigana (patient) {
        return patient ? `(${patient.kana_last_name || ''} ${patient.kana_first_name || ''})` : ''
      },
      renderAge: function (date) {
        return getAgeString(date, '').match(/(\d+)/g)?.shift() || ''
      },
      renderStatus: function (status) {
        if (typeof status !== 'undefined') {
          return this.$t('objects.patient.sf_status_c.' + status)
        }

        return ''
      },
      separatorNumber: function (number) {
        return number ? formatCurrency(number) : number
      },
      renderFirstVisitDate: function (patient) {
        const {
          first_reservation_start_to: firstReservationStartTo,
          sf_next_reservation_date: sfNextReservationDate
        } = patient

        if (firstReservationStartTo !== null && firstReservationStartTo === sfNextReservationDate) {
          return formatDateTime(firstReservationStartTo, this.$t('datetime.year_month_day_hour_minute'))
        }

        return this.$t('objects.patient.text.none')
      },
      getPatientList: function () {
        this.$store.commit('set_loading', true)
        this.is_search_detail = false
        const query = {
          page: this.page
        }

        if (this.sort_field) {
          query.order_type = this.sort_type
          query.order_field = this.sort_field
        }

        this.$store.dispatch('getPatients', query).then(result => {
          this.pagination = result.data.results
          this.patients = this.pagination.data
          this.$store.commit('set_loading', false)
        }).catch(() => {
          this.$store.commit('set_loading', false)
        })
      },
      sortBy (field) {
        this.setDataSort(field)

        if (this.is_search_detail) {
          return this.submitSearchDetail()
        }

        this.getPatientList()
      },
      resetSearch: function () {
        if (!this.enableResetSearch) {
          return
        }

        this.search.id = ''
        this.search.name = ''
        this.search.tel = ''
        this.search.gender = 0
        this.search.age = ''
        this.search.email = ''
        this.search.patient_condition = 'none'
        this.search.year = null
        this.search.month = null
        this.search.day = null
        this.search.reservation_start.year = moment().subtract(1, 'months').format('YYYY')
        this.search.reservation_start.month = moment().subtract(1, 'months').format('M')
        this.search.reservation_start.day = moment().subtract(1, 'months').format('D')
        this.search.reservation_end.year = moment().format('YYYY')
        this.search.reservation_end.month = moment().format('M')
        this.search.reservation_end.day = moment().format('D')
        this.search.treatment_id = ''
        this.search.treatment_type = ''
        this.search.treatment_name = ''
        this.search.brand_type = ''
        this.search.sf_status_c = ''
        this.search.csl_brand_type = ''
        this.page = 1
        this.data_search = {}
        this.getPatientList()
      },
      arrivedDateChange: function () {
        this.$store.dispatch('reloadTreatmentData', { medical_date: this.$t('datetime.year_month_day') })
          .then((result) => {
            if (result.status === 200) {
              this.treatment_data = result.data.results
            }
          })
      },
      setTreatment: function (treatment) {
        this.search.treatment_id = treatment.id
        this.search.treatment_type = treatment.treatment_type
        this.search.treatment_name = treatment.name
      },
      showDetailSelectTreatment: function () {
        const modalSelectTreatment = this.$children.filter(item => item.$options.name === 'modal-select-treatment').shift()
        if (typeof modalSelectTreatment !== 'undefined') {
          modalSelectTreatment.show()
        }
      },
      submitSearchDetail: function (isSubmitSearch = false) {
        if (this.getReservationStart > this.getReservationEnd) {
          showErrorModal(this.$t('new_validation.common.invalid_date'))

          return
        }
        if (moment(this.getReservationEnd, moment.defaultFormat).toDate() > moment().add(365, 'days')) {
          showErrorModal(this.$t('new_validation.patient.reservation_end_must_be_less_than_365'))

          return
        }
        this.$store.commit('set_loading', true)
        this.is_search_detail = true
        const search = {
          id: this.search.id,
          name: this.search.name,
          gender: this.search.gender,
          email: this.search.email,
          phone_number: this.search.tel,
          age: this.search.age || this.getAge,
          reservation_start: this.getReservationStart,
          reservation_end: this.getReservationEnd,
          reservation_type: this.search.patient_condition,
          is_search_treatment: this.search.treatment_id !== '' && this.search.treatment_type !== '',
          treatment_id: this.search.treatment_id,
          treatment_type: this.search.treatment_type,
          brand_type: this.search.brand_type,
          sf_status_c: this.search.sf_status_c,
          csl_brand_type: this.search.csl_brand_type
        }
        if (isSubmitSearch) {
          this.page = 1
          this.data_search = { ...search }
        }

        const query = {
          ...this.data_search,
          page: this.page
        }
        if (this.search.year || this.search.month || this.search.day) {
          query.birthday = [
            this.search.year || '0000',
            this.search.month || '00',
            this.search.day || '00'
          ]
        }
        if (this.sort_field) {
          query.order_type = this.sort_type
          query.order_field = this.sort_field
        }
        this.$store.dispatch('searchDetail', query)
          .then(result => {
            this.pagination = result.data.results
            this.patients = this.pagination.data
            this.$store.commit('set_loading', false)
          }).catch(e => {
            this.$store.commit('set_loading', false)
            showMessageError(e, this)
          })
      },
      setAge (age) {
        this.search.age = age
        this.search.year = null
        this.search.month = null
        this.search.day = null
      },
      changePage (page) {
        this.page = page
        return this.is_search_detail ? this.submitSearchDetail() : this.getPatientList()
      },
      getBrandTypeOptions () {
        this.$store.dispatch('getBrandTypeOptions')
          .then(result => {
            this.brand_type_options = result.data.results
          })
          .catch(e => {
            showErrorModal(this.$t('new_message.something_went_wrong'))
          })
      }
    }
  }
</script>
