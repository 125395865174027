export const validInput = {
  methods: {
    validNumber (e) {
      if (e.type === 'paste') {
        const clipboardData = e.clipboardData || window.clipboardData
        const pastedData = clipboardData.getData('Text')
        if (pastedData.match(/[eE+-]/g)) {
          e.stopPropagation()
          e.preventDefault()
          return
        }
      }
      if (['e', 'E', '+', '-'].indexOf(e.key) !== -1) {
        e.preventDefault()
      }
    },
    validPhoneNumber (e) {
      if (e.type === 'paste') {
        const clipboardData = e.clipboardData || window.clipboardData
        const pastedData = clipboardData.getData('Text')
        if (pastedData.match(/[eE+-.,]/g)) {
          e.stopPropagation()
          e.preventDefault()
          return
        }
      }
      if (['e', 'E', '+', '-', '.', ','].indexOf(e.key) !== -1) {
        e.preventDefault()
      }
    }
  }
}
