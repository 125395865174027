var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-action", {
    staticClass: "modal-v2--reservation-cancel",
    attrs: {
      title: _vm.$t("objects.reservation.text.search_cancel_reservation_title"),
      "is-show-cancel-button": false
    },
    on: { close: _vm.closeModal, confirm: _vm.closeModal },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "reservation-cancel-modal" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.loadReservationCancel($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "reservation-cancel-modal__search" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "datepicker datepicker--header-calender"
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("date-picker", {
                                class: [
                                  "reservation-cancel-modal__search__picker",
                                  { "common-input-error": _vm.isValidStartedTo }
                                ],
                                attrs: {
                                  readonly: "readonly",
                                  wrap: false,
                                  config: _vm.dpOptions
                                },
                                model: {
                                  value: _vm.started_to,
                                  callback: function($$v) {
                                    _vm.started_to = $$v
                                  },
                                  expression: "started_to"
                                }
                              }),
                              _vm._v(" "),
                              _vm.isValidStartedTo
                                ? _c(
                                    "span",
                                    { staticClass: "common-msg-error" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.isValidStartedTo) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "reservation-cancel-modal__search__link"
                            },
                            [_vm._v(" 〜 ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("date-picker", {
                                class: [
                                  "reservation-cancel-modal__search__picker",
                                  { "common-input-error": _vm.isValidEndedFrom }
                                ],
                                attrs: {
                                  readonly: "readonly",
                                  wrap: false,
                                  config: _vm.dpOptions
                                },
                                model: {
                                  value: _vm.ended_from,
                                  callback: function($$v) {
                                    _vm.ended_from = $$v
                                  },
                                  expression: "ended_from"
                                }
                              }),
                              _vm._v(" "),
                              _vm.isValidEndedFrom
                                ? _c(
                                    "span",
                                    { staticClass: "common-msg-error" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.isValidEndedFrom) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn-v2 reservation-cancel-modal__search__submit",
                          attrs: { type: "submit" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.search")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "reservation-cancel-modal__search__total"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "reservation-cancel-modal__search__total__text"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("objects.reservation.text.canceller")
                                  ) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", [
                            _c("span", [
                              _vm._v(_vm._s(_vm.reservation_cancelled_total))
                            ]),
                            _vm._v(_vm._s(_vm.$t("new_text.first_name2")))
                          ])
                        ]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "table",
                {
                  class: [
                    "reservation-cancel-modal__table",
                    { "lazy-load lazy-load--no-background": _vm.lazy_load }
                  ]
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "objects.reservation.text.reservations_frame"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "common-list__sort",
                          on: {
                            click: function($event) {
                              return _vm.sortBy("started_to")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "objects.reservation.text.reservation_date_and_time"
                                )
                              ) +
                              "\n              "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "common-list__icon common-list__sort"
                            },
                            [
                              _c("img", {
                                attrs: { src: _vm.getIconSort("started_to") }
                              })
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "common-list__sort",
                          on: {
                            click: function($event) {
                              return _vm.sortBy("reservations.sf_user_id")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("objects.patient.text.patient_id")
                              ) +
                              "\n              "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "common-list__icon common-list__sort"
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.getIconSort(
                                    "reservations.sf_user_id"
                                  )
                                }
                              })
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("th", [_vm._v(_vm._s(_vm.$t("new_text.name")))]),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "common-list__sort",
                          on: {
                            click: function($event) {
                              return _vm.sortBy("cancelled_at")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "objects.reservation.text.cancellation_time"
                                )
                              ) +
                              "\n              "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "common-list__icon common-list__sort"
                            },
                            [
                              _c("img", {
                                attrs: { src: _vm.getIconSort("cancelled_at") }
                              })
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("objects.reservation.text.cancelled_reason")
                          )
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.reservation_cancelled, function(
                        reservation,
                        index
                      ) {
                        return _c(
                          "tr",
                          {
                            key: index,
                            on: {
                              click: function($event) {
                                return _vm.gotoReservationCalendar(
                                  reservation.started_to,
                                  reservation.id,
                                  $event
                                )
                              }
                            }
                          },
                          [
                            _c("td", [
                              _c("span", { staticClass: "three-dot" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(reservation.column_name) +
                                    "\n              "
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(
                                    reservation.started_to,
                                    reservation.ended_from
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      to: {
                                        name: "patient.detail",
                                        params: { id: reservation.patient_id }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          reservation.sf_user_id ||
                                            reservation.patient_id
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "three-dot",
                                    attrs: {
                                      target: "_blank",
                                      to: {
                                        name: "patient.detail",
                                        params: { id: reservation.patient_id }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(reservation.name) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.formatDate(reservation.cancelled_at))
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(reservation.cancelled_reason))
                            ])
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _vm.errors.no_result
                        ? _c("span", { staticClass: "error" }, [
                            _vm._v(_vm._s(_vm.errors.no_result))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("infinite-loading", {
                        attrs: { identifier: _vm.infinite_scroll_id },
                        on: { infinite: _vm.infiniteScrollHandler },
                        scopedSlots: _vm._u([
                          {
                            key: "no-more",
                            fn: function() {
                              return [_c("span", { staticClass: "d-none" })]
                            },
                            proxy: true
                          },
                          {
                            key: "no-results",
                            fn: function() {
                              return [_c("span", { staticClass: "d-none" })]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    2
                  )
                ]
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "confirm_button_content",
        fn: function() {
          return [
            _vm._v("\n    " + _vm._s(_vm.$t("new_text.button.close")) + "\n  ")
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }