<template>
    <div class="wrap">
        <header-layout>
            <span slot="header-layout-left">
                <router-link :to="{ name: 'reservation.list' }"><img :src="'/images/arrow-left.svg'"/><span>戻る</span></router-link>
            </span>
        </header-layout>
        <sidebar-menu/>
        <main class="reservation-setting">
            <div class="col-xl-12 main-content">
                <section class="content">
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab"
                               href="#nav-date-setting" role="tab" aria-controls="nav-home"
                               aria-selected="true">予約日時設定</a>
                            <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-type-setting"
                               role="tab" aria-controls="nav-profile" aria-selected="false">予約種別設定</a>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabSettingContent">
                        <div class="tab-pane fade show active" id="nav-date-setting" role="tabpanel"
                             aria-labelledby="nav-home-tab">
                            <ReservationDateSetting/>
                        </div>
                        <div class="tab-pane fade" id="nav-type-setting" role="tabpanel"
                             aria-labelledby="nav-profile-tab">
                            <ReservationTypeSetting/>
                        </div>
                    </div>
                </section>
            </div>
            <Loading></Loading>
        </main>
    </div>
</template>
<script>
    import SidebarMenu from '../../components/SidebarMenu'
    import Loading from '../../components/Loading'
    import ReservationDateSetting from '../../components/ReservationDateSetting'
    import ReservationTypeSetting from '../../components/ReservationTypeSetting'
    import HeaderLayout from "../../components/headers/HeaderLayout";

    export default {
        components: {
            SidebarMenu,
            Loading,
            ReservationDateSetting,
            ReservationTypeSetting,
            HeaderLayout
        },
    }
</script>
