var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group sub-question" }, [
    _vm.errors
      ? _c("p", { staticClass: "error-massage" }, [_vm._v(_vm._s(_vm.errors))])
      : _vm._e(),
    _vm._v(" "),
    _c("strong", [
      _vm.question
        ? _c("p", { domProps: { innerHTML: _vm._s(_vm.question) } })
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "radio-group h-align" },
      _vm._l(_vm.options, function(item) {
        return _c(
          "div",
          {
            class: ["check-container", "ml-0"],
            on: {
              click: function($event) {
                return _vm.handleClick(item)
              }
            }
          },
          [
            _c("span", { class: ["radio-button", _vm.handleClass(item)] }),
            _vm._v(" "),
            _c("span", { staticClass: "label-text" }, [_vm._v(_vm._s(item))])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }