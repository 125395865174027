import axios from 'axios'
import { state as user } from './auth'

export const state = {
  listMedical: [],
  listTreatment: []
}

export const mutations = {
  setListMedical (state, payload) {
    state.listMedical = payload.data.results
  },
  setListTreatment (state, payload) {
    state.listTreatment = payload.data.results
  }
}

export const actions = {
  getMedicalRecordComment ({ dispatch, commit }, data) {
    const url = `/api/medicalrecord/${data.medical_record_id}/comment`
    return axios.get(url, {
      params: {
        page: data.page
      },
      headers: { Authorization: 'Bearer ' + user.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  storeMedicalRecordComment ({ dispatch, commit }, data) {
    const url = `/api/medicalrecord/${data.medical_record_id}/comment`
    return axios.post(url, data, {
      headers: { Authorization: 'Bearer ' + user.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  deleteMedicalRecordComment ({ dispatch, commit }, data) {
    const url = `/api/medicalrecord/${data.medical_record_id}/comment/${data.id}`
    return axios.delete(url, {
      headers: { Authorization: 'Bearer ' + user.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getDataForCreateForm ({ dispatch, commit }, param) {
    return axios.get('/api/medicalrecord/createform', {
      params: {
        patient_id: param.patient_id,
        reservation_id: param.reservation_id
      },
      headers: { Authorization: 'Bearer ' + user.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  storeMedicalRecordData ({ dispatch, commit }, submitData) {
    return axios.post('/api/medicalrecord', submitData, {
      headers: { Authorization: 'Bearer ' + user.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getMedicalHistoryData ({ dispatch, commit }, getData) {
    return axios.get('/api/medicalrecord/history', {
      params: getData,
      headers: { Authorization: 'Bearer ' + user.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getMedicalHistoryDetail ({ dispatch, commit }, getData) {
    return axios.get('/api/medicalrecord/history/detail', {
      params: getData,
      headers: { Authorization: 'Bearer ' + user.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getMedicalSingleDetail ({ dispatch, commit }, submitData) {
    return axios.get('/api/medicalrecord/detail', {
      params: {
        patient_id: submitData.patient_id,
        medicalrecord_id: submitData.medical_id,
        action: submitData.action
      },
      headers: { Authorization: 'Bearer ' + user.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getMedicalRecordDates ({ dispatch, commit }, patientId) {
    return axios.get('/api/medicalrecord/dates', {
      params: {
        patient_id: patientId
      },
      headers: { Authorization: 'Bearer ' + user.token }
    })
      .then((res) => {
        commit('setListMedical', res)
        return res
      })
      .catch(error => {
        throw error
      })
  },
  updateMedicalRecord ({ dispatch, commit }, submitData) {
    return axios.post('/api/medicalrecord/update', submitData, {
      headers: { Authorization: 'Bearer ' + user.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  reloadTreatmentData ({ dispatch, commit }, submitData) {
    return axios.get('/api/medicalrecord/treatments', {
      params: submitData,
      headers: { Authorization: 'Bearer ' + user.token }
    })
      .then((res) => {
        commit('setListTreatment', res)
        return res
      })
      .catch(error => {
        throw error
      })
  }
}
