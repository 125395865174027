<template>
  <div class="chart-common">
    <div
      v-for="(item, index) in chartData"
      :key="index"
      class="chart-common__item"
    >
      <div class="chart-common__chart">
        <span
          :style="`background-color: ${item.background || '#ad4'}; height: ${renderHeight(item.value)}%`"
          class="chart-common__bar"
        >
          <span
            v-show="item.value"
            class="chart-common__number number"
          >{{ numberWithCommas(item.value) }}</span>
        </span>
      </div>
      <label class="chart-common__label">{{ item.name }}</label>
    </div>
  </div>
</template>

<script>
  import { isEmpty } from 'lodash'
  import { numberWithCommas } from '../../utils/helpers'

  export default {
    props: {
      chartData: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      maxValue () {
        if (isEmpty(this.chartData)) {
          return 0
        }

        return Math.max.apply(
          null,
          this.chartData.map((o) => { return o.value })
        )
      }
    },
    methods: {
      renderHeight (val) {
        if (this.maxValue === 0) {
          return 0
        }

        return val * 100 / this.maxValue
      },
      numberWithCommas (value) {
        return numberWithCommas(value)
      }
    }
  }
</script>
