var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("logo", [
    _c(
      "form",
      {
        staticClass: "login-form",
        attrs: { method: "post", autocomplete: "off" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.login($event)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass:
              "justify-content-center form_container login-form__container"
          },
          [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  {
                    staticClass: "login-form__label",
                    attrs: { for: "staff_id" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("login_page.staff_id")) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "exclusion-icon",
                    attrs: { id: "exclusion-icon" }
                  },
                  [_c("img", { attrs: { src: "/images/exclusion.svg" } })]
                ),
                _vm._v(" "),
                _c(
                  "b-popover",
                  {
                    attrs: {
                      target: "exclusion-icon",
                      placement: "top",
                      triggers: "hover focus",
                      "custom-class": "exclusion-notify",
                      "fallback-placement": "clockwise"
                    }
                  },
                  [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("login_page.forgotten_staff_id")
                        )
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.staff_id,
                      expression: "staff_id"
                    }
                  ],
                  staticClass: "form-control login-form__input",
                  attrs: {
                    id: "staff_id",
                    type: "text",
                    placeholder: _vm.$t("login_page.please_enter_staff_id")
                  },
                  domProps: { value: _vm.staff_id },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.staff_id = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.staff_id_request
                  ? _c("validation-errors", {
                      attrs: {
                        errors: _vm.errors.staff_id_request,
                        "text-left": true
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  { staticClass: "login-form__label", attrs: { for: "pwd" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("common_page.password")) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "login-form__tip note-tip" }, [
                  _vm._v(_vm._s(_vm.$t("common_page.input_characters")))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password"
                    }
                  ],
                  staticClass: "form-control login-form__input",
                  attrs: {
                    id: "pwd",
                    type: "password",
                    placeholder: _vm.$t("login_page.password_placeholder")
                  },
                  domProps: { value: _vm.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.password_request
                  ? _c("validation-errors", {
                      attrs: {
                        "text-left": true,
                        errors: _vm.errors.password_request
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm.errors.common_validation
              ? _c("validation-errors", {
                  attrs: {
                    errors: _vm.errors.common_validation,
                    "text-left": true
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center mt-3 mb-4 login_container"
          },
          [
            _c(
              "button",
              {
                staticClass: "btn login_btn",
                attrs: { type: "submit", name: "button" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("common_page.btn_login")) +
                    "\n      "
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "forget-password login-form__forgot text-center mb-4"
          },
          [
            _c(
              "router-link",
              {
                staticStyle: {
                  padding: "15px 25px",
                  color: "#7FC7EF",
                  "text-decoration": "underline"
                },
                attrs: { to: { name: "password.request" } }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("login_page.forgot_pass")) +
                    "\n      "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "login-form__switch-mode" }, [
          _c("span", { staticClass: "login-form__switch-mode__title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("new_text.switch_mode_pc_title")) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "login-form__switch-mode__action" }, [
            _c(
              "button",
              {
                staticClass:
                  "login-form__switch-mode__button login-form__switch-mode__button--pc",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.changeLayoutMode(_vm.layout_mode.SP)
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("new_text.switch_mode_sp")) +
                    "\n        "
                )
              ]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }