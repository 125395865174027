import axios from 'axios'
import { state } from './auth'

export const actions = {
  getAccountingList ({ dispatch, commit }, queryData) {
    return axios.get('/api/accounting', {
      params: {
        date_from: queryData.date_from,
        date_to: queryData.date_to,
        person_in_charge: queryData.person_in_charge,
        payment_methods: queryData.payment_methods,
        treatment: queryData.treatment,
        form_data: queryData.form_data
      },
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getAccountingDetail ({ dispatch, commit }, queryData) {
    return axios.get('/api/accounting/detail', {
      params: {
        invoice_id: queryData.invoice_id,
        form_data: queryData.form_data || 0,
        is_updating: queryData.is_updating || 0,
        reservation_id: queryData.reservation_id || 0,
        patient_id: queryData.patient_id || 0
      },
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  storeAccountingInvoice ({ dispatch, commit }, postData) {
    return axios.post('/api/accounting/store', postData, {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getPrintData ({ dispatch, commit }, invoiceId) {
    return axios.get('/api/accounting/print', {
      params: {
        invoice_id: invoiceId
      },
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  deleteInvoice ({ dispatch, commit }, invoiceId) {
    return axios.post('/api/accounting/delete', [], {
      params: {
        invoice_id: invoiceId
      },
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  updateAccountingInvoice ({ dispatch, commit }, postData) {
    return axios.post('/api/accounting/update', postData, {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  checkInvoiceChange ({ dispatch, commit }) {
    return axios.get('/api/accounting/checkchange', {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  regenerateAccountingRecord ({ dispatch, commit }, postData) {
    return axios.post('/api/accounting/regenerate', postData, {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getReservationStatistic ({ dispatch, commit }, queryData) {
    return axios.get('/api/accounting/reservation/statistic', {
      params: {
        date_from: queryData.date_from,
        date_to: queryData.date_to
      },
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getAccountingTreatmentList ({ dispatch, commit }, queryData) {
    return axios.get('/api/accounting/treatment', {
      params: {
        date_from: queryData.date_from,
        date_to: queryData.date_to,
        clinic_id: queryData.clinic_id
      },
      headers: { Authorization: `Bearer ${state.token}` }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  }
}
