/**
 * @author vien.lc
 * @param source source example "This is :name"
 * @param attribute attribute example {name:"Park"}
 * @return string
 * @example trans("This is :name, from :country",{name:"Park",country:"Vietnam"});
 *          will return "This is Park, from Vietnam"
 */

export const trans = (source, attribute) => {
  var result = source
  if (typeof attribute !== 'undefined') {
    Object.keys(attribute).forEach(function (item) {
      const pattern = new RegExp(':' + item, 'i')
      result = result.replace(pattern, attribute[item])
    })
  }

  return result
}
