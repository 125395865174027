import { LAYOUT_MODE } from '../utils/constants'

export const LayoutModeMixin = {
  data () {
    return {
      window_width: window.innerWidth,
      layout_mode: LAYOUT_MODE
    }
  },
  computed: {
    currentLayoutMode () {
      return parseInt(this.$store.state.auth.layout_mode)
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    changeLayoutMode (mode) {
      if (this.currentLayoutMode === mode) {
        return
      }

      this.$store.commit('changeLayoutMode', mode)
    },
    onResize () {
      this.window_width = window.innerWidth
    }
  }
}
