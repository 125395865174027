<template>
    <div :class="[className]">
        <sub-question
            ref="sub_question"
            :question="question"
            :options="option_yes_no"
            :checked="select_option"
            @handleAnswer="handleAnswer($event)"
        ></sub-question>
        <div v-bind:class="handleChildAnswerClass">
            <div class="form-group">
                <checkbox-common
                    v-model="selections" 
                    label="糖尿病" 
                    val="糖尿病">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="心臓病" 
                    val="心臓病">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="高血圧" 
                    val="高血圧">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="低血糖" 
                    val="低血糖">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="腎臓病" 
                    val="腎臓病">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="肝臓病" 
                    val="肝臓病">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="結核" 
                    val="結核">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="肝炎" 
                    val="肝炎">
                </checkbox-common>
                <div class="form-inline selection-other-option pt-3">
                    <checkbox-common 
                        v-model="selections"
                        label="その他" 
                        val="その他">
                    </checkbox-common>
                    <div :class="['other-input', diableOtherInput]">
                        <input v-model="other" class="form-control w-100" type="text">  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SubQuestion from '../template/SubQuestion'
    import CheckboxCommon from '../template/CheckboxCommon'
    import { INTERVIEW_COMMON_OPTIONS } from '../../../../utils/constants'
    import Common from "./CommonFunction";

    export default {
        components: {
            SubQuestion,
            CheckboxCommon
        },
        props: ['className'],
        mixins: [Common],
        data() {
            return {
                number: 14,
                select_option: INTERVIEW_COMMON_OPTIONS.NO,
                question: '今までにかかった病気はありますか？',
                option_yes_no: [
                    INTERVIEW_COMMON_OPTIONS.YES,
                    INTERVIEW_COMMON_OPTIONS.NO,
                ],
                selections: [],
                other: '',
                translate: {
                    選択肢: 'selections',
                    その他: 'other'
                },
            }
        },
        computed: {
            handleChildAnswerClass: function () {
                return {
                    'disable-child-answer': this.select_option === INTERVIEW_COMMON_OPTIONS.NO
                }
            },
            diableOtherInput: function () {
                return {
                    'disable-other-answer': !this.selections.includes('その他')
                }
            },
        }
    }
</script>
