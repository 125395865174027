var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-action", {
    staticClass: "modify-duty",
    attrs: {
      title: _vm.title,
      "is-show-delete-button": _vm.isShowButtonDelete,
      "is-show-footer": _vm.isShowFooter
    },
    on: {
      close: _vm.handleClose,
      confirm: _vm.handleSubmit,
      delete: function($event) {
        return _vm.$emit("delete")
      }
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "modify-duty__form" }, [
              _c("div", { staticClass: "modify-duty__group" }, [
                _c(
                  "label",
                  {
                    staticClass: "modify-duty__label",
                    attrs: { for: "show-name" }
                  },
                  [_vm._v(_vm._s(_vm.$t("objects.setting.text.species_alias")))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modify-duty__row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.duty_name,
                        expression: "duty_name"
                      }
                    ],
                    class: [
                      "modify-duty__input",
                      { "common-input-error": !_vm.validDutyName }
                    ],
                    attrs: {
                      id: "show-name",
                      disabled: !!_vm.isPreset,
                      type: "text",
                      placeholder: _vm.$t("new_text.placeholder.required", {
                        attribute: _vm.$t("new_text.species_alias")
                      })
                    },
                    domProps: { value: _vm.duty_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.duty_name = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.validDutyName
                    ? _c("span", { staticClass: "common-msg-error" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("new_validation.common.required", {
                                attribute: _vm.$t("new_text.species_alias")
                              })
                            ) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modify-duty__group" }, [
                _c("label", { staticClass: "modify-duty__label" }, [
                  _vm._v(_vm._s(_vm.$t("objects.setting.text.required_time")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modify-duty__row" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.take_time,
                          expression: "take_time"
                        }
                      ],
                      class: [
                        "modify-duty__select",
                        { "common-input-error": !_vm.validTakeTime }
                      ],
                      attrs: { disabled: !!_vm.isPreset },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.take_time = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "", hidden: "" } }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t("new_text.placeholder.please_select")
                            ) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.takeTimeData, function(time, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: time } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(time) +
                                "\n            "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  !_vm.validTakeTime
                    ? _c("span", { staticClass: "common-msg-error" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("new_validation.common.required", {
                                attribute: _vm.$t("new_text.time_required")
                              })
                            ) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modify-duty__group" }, [
                _c("label", { staticClass: "modify-duty__label" }, [
                  _vm._v(_vm._s(_vm.$t("objects.setting.text.take_a_position")))
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.duty_color,
                        expression: "duty_color"
                      }
                    ],
                    staticClass: "modify-duty__select",
                    attrs: { disabled: !!_vm.isPreset },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.duty_color = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._l(_vm.colorsDuty, function(duty, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: duty.id } },
                      [
                        _vm._v(
                          "\n            " + _vm._s(duty.title) + "\n          "
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }