<template>
  <modal-action
    class="modal-ip-setting modal-ip-setting--create"
    :title="$t('objects.setting.text.create_ip_setting')"
    :is-show-cancel-button="false"
    @close="$emit('close')"
    @confirm="submit"
  >
    <template #body>
      <div class="modal-ip-setting__container">
        <div class="modal-ip-setting__content">
          <div class="modal-ip-setting__content__label">
            <label>{{ $t('objects.setting.text.request_staff_id') }}</label>
            <span class="break-all">{{ userInfo.staff_fullname }}({{ userInfo.login_id }})</span>
          </div>
        </div>
        <label class="modal-ip-setting__item modal-ip-setting__item--label">{{ $t('objects.setting.text.setting') }}</label>
        <div class="modal-ip-setting__group--bd modal-ip-setting__group--content-input">
          <label class="modal-ip-setting__item modal-ip-setting__item--label">{{ $t('new_text.label') }}</label>
          <div class="modal-ip-setting__group--content">
            <input
              v-model="label"
              :class="[
                'modal-ip-setting__item modal-ip-setting__item--control modal-ip-setting__item--label-input',
                { 'common-input-error': error_messages.label }
              ]"
              :placeholder="$t('objects.setting.text.label_placeholder')"
              required="required"
              @input="handleValidationLabel"
            >
            <span
              v-if="error_messages.label"
              class="common-msg-error error-message"
            >
              {{ error_messages.label }}
            </span>
          </div>
        </div>
        <div class="modal-ip-setting__group--content-input">
          <label class="modal-ip-setting__item modal-ip-setting__item--label">{{ $t('objects.setting.text.ip') }}</label>
          <div class="modal-ip-setting__group--content modal-ip-setting__group--content--ip">
            <div class="modal-ip-setting__group--inner-input">
              <input
                v-model="ip_address"
                :class="['modal-ip-setting__item modal-ip-setting__item--control', { 'common-input-error': error_messages.ip_address }]"
                required="required"
                @input="handleIPChange"
              >
              <span
                v-if="error_messages.ip_address"
                class="common-msg-error error-message"
              >
                {{ error_messages.ip_address }}
              </span>
            </div>
            <div class="modal-ip-setting__group--generate-ip">
              <span @click="fillMyIpAddress">{{ $t('objects.setting.text.enter_ip_address') }}</span>
            </div>
          </div>
        </div>
        <div>
          <label class="modal-ip-setting__item modal-ip-setting__item--label modal-ip-setting__item--label__accept">
            {{ $t('objects.setting.text.allow_accept_ip_address') }}
          </label>
        </div>
        <div class="modal-ip-setting__h-align">
          <radio-common
            name="status"
            :val="0"
            class="modal-ip-setting__h-align--right"
            :label="$t('new_text.disallow')"
            :is-checked="status === 0"
            @select="handleSelected"
          />
          <radio-common
            name="status"
            :val="1"
            :label="$t('new_text.allow')"
            :is-checked="status === 1"
            @select="handleSelected"
          />
        </div>
      </div>
    </template>
    <template #confirm_button_content>
      {{ $t('new_text.button.save') }}
    </template>
  </modal-action>
</template>
<script>

  import ModalAction from '../../../components/common/ModalAction'
  import { showErrorModal } from '../../../utils/helpers'
  import { required, maxLength } from 'vuelidate/lib/validators'
  import RadioCommon from '../../../components/common/Radio'
  import { RESPONSE_ERRORS, RESPONSE_STATUS } from '../../../utils/constants'

  export default {
    components: {
      RadioCommon,
      ModalAction
    },
    props: {
      userInfo: {
        type: Object,
        default: () => {}
      }
    },
    emits: ['close'],
    data () {
      return {
        ip_address: '',
        label: '',
        status: 0,
        error_messages: {
          ip_address: '',
          label: ''
        }
      }
    },
    validations () {
      return {
        label: { required, maxLength: maxLength(50) },
        ip_address: { required }
      }
    },
    methods: {
      handleSelected (value) {
        this.status = value
      },
      submit () {
        this.error_messages.label = ''
        if (!this.validate() || this.error_messages.ip_address) {
          return
        }

        const postData = {
          ip_label: this.label,
          ip_address: this.ip_address,
          ip_status: this.status
        }
        this.$store.commit('set_loading', true)
        this.$store.dispatch('updateWhiteList', postData).then((result) => {
          const { data: { status, results } } = result
          if (status !== RESPONSE_STATUS.NG) {
            this.$emit('succeed')
          } else {
            if (results === RESPONSE_ERRORS.IP_EXIST) {
              showErrorModal(this.$t('objects.setting.text.ip_exist'))
            }
            this.$store.commit('set_loading', false)
          }
        }).catch((error) => {
          this.$store.commit('set_loading', false)
          const resErrors = error?.response?.data?.errors || []

          showErrorModal(resErrors, this)
        })
      },
      fillMyIpAddress: function () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('showMyIpAddress').then((res) => {
          if (res.status === 200) {
            this.ip_address = res.data.results
            this.error_messages.ip_address = ''
            this.$store.commit('set_loading', false)
          }
        }).catch(() => {
          showErrorModal(this.$t('new_message.something_went_wrong'))
          this.$store.commit('set_loading', false)
        })
      },
      validate () {
        this.$v.$touch()
        if (!this.$v.$anyError) {
          return true
        }
        const errors = []
        const errorMessages = {
          'label.required': this.$t('new_validation.common.required', { attribute: this.$t('new_text.label') }),
          'label.maxLength': this.$t('new_validation.common.maximum', { attribute: this.$t('new_text.label'), max: 50 }),
          'ip_address.required': this.$t('validation.required', { attribute: this.$t('objects.setting.text.ip_address') })
        }
        for (const key in errorMessages) {
          const parts = key.split('.')

          if (this.$v[parts[0]] && this.$v[parts[0]][parts[1]] === false) {
            this.error_messages[parts[0]] = errorMessages[key]
            errors.push(errorMessages[key])
          }
        }

        if (errors.length) {
          showErrorModal(errors.join('<br>'), { width: '440px' })

          return false
        }

        return true
      },
      handleIPChange: function (event) {
        this.error_messages.ip_address = ''
        const { currentTarget: { value } } = event
        let pattern = /^[0-9.]+$/

        if (!pattern.test(value)) {
          this.error_messages.ip_address = this.$t('new_validation.setting.invalid_character_ip_address')

          return
        }

        pattern = /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/

        if (!pattern.test(value)) {
          this.error_messages.ip_address = this.$t('new_validation.setting.invalid_ip_address')
        }
      },
      handleValidationLabel: function (e) {
        if (!e.target.value) {
          this.error_messages.label = this.$t('new_validation.common.required', { attribute: this.$t('new_text.label') })

          return
        }

        if (e.target.value.length > 50) {
          this.error_messages.label = this.$t('new_validation.common.maximum', { attribute: this.$t('new_text.label'), max: 50 })

          return
        }

        this.error_messages.label = ''
      }
    }
  }
  </script>
