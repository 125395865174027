export const getPositionName = {
  methods: {
    getPositionName (data) {
      return data?.staff?.position?.name || ''
    }
  }
}

export const staffMixin = {
  methods: {
    getPositionName (data) {
      return data?.position?.name || ''
    }
  }
}
