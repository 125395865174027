<template>
  <modal-action
    :title="title || $t('new_text.confirm_delete')"
    :is-show-close-button="false"
    class="modal-confirm modal-confirm-danger"
    @close="$emit('close')"
    @confirm="$emit('confirm')"
  >
    <template
      v-if="text"
      #body
    >
      <slot name="body">
        <span>{{ text }}</span>
      </slot>
    </template>
    <template #cancel_button_content>
      {{ textNo || $t('new_text.button.no') }}
    </template>
    <template #confirm_button_content>
      {{ textOk || $t('new_text.button.exclude') }}
    </template>
  </modal-action>
</template>

<script>
  import ModalAction from './ModalAction'

  export default {
    components: {
      ModalAction
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''
      },
      textNo: {
        type: String,
        default: ''
      },
      textOk: {
        type: String,
        default: ''
      }
    },
    emits: ['no', 'yes']
  }
</script>
