<template>
  <div class="comment-item__group">
    <div class="comment-item__content">
      <span>{{ logContent }}</span>
    </div>
    <div class="comment-item__further">
      <div class="comment-item__info">
        <span class="comment-item__date">{{ logDate }}</span>
        <span class="comment-item__author">{{ authorName }}</span>
      </div>
      <span
        v-if="canDelete"
        class="comment-item__action"
        @click="showModalConfirmDelete"
      >{{ $t('new_text.delete') }}</span>
    </div>
    <modal-confirm-danger
      v-if="is_show_modal_delete_memo"
      :title="$t('new_message.do_you_want_delete_memo')"
      :text-ok="$t('new_text.button.delete')"
      @confirm="handleDeleteMemo"
      @close="is_show_modal_delete_memo = false"
    />
  </div>
</template>

<script>
  import moment from 'moment'
  import { safeGet } from '../../../../utils/helpers'
  import ModalConfirmDanger from '../../../common/ModalConfirmDanger'
  export default {
    components: { ModalConfirmDanger },
    props: {
      activity: {
        type: Object,
        default: () => {}
      },
      author: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        is_show_modal_delete_memo: false
      }
    },
    computed: {
      logDate () {
        return moment(this.activity.created_at).format(this.$t('new_text.datetime.year_month_day_hour_minute'))
      },
      logContent () {
        const memo = this.activity.memo || ''

        return memo.replace(/<br>/g, '\r\n')
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
          .replace(/&quot;/g, '"')
          .replace(/&#039;/g, "'")
          .replace(/&amp;/g, '&')
      },
      canDelete () {
        const staff = this.$store.state.auth.user
        if (staff.is_admin === 1 || staff.id === this.activity.staff_id) {
          return true
        }
        return false
      },
      authorName () {
        return safeGet(this.activity, 'staff.name') || this.author
      }
    },
    methods: {
      showModalConfirmDelete () {
        this.is_show_modal_delete_memo = true
      },
      handleDeleteMemo () {
        this.is_show_modal_delete_memo = false
        this.$emit('delete-memo', { id: this.activity.id })
      }
    }
  }
</script>
