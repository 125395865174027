/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'
import App from './App.vue'
import router from './routes'
import './components'
import VueI18n from 'vue-i18n'
import messages from './messages'
import VueToastr from 'vue-toastr'
import datePicker from 'vue-bootstrap-datetimepicker'
import VueSweetalert2 from 'vue-sweetalert2'
import VModal from 'vue-js-modal'
import TextareaAutosize from 'vue-textarea-autosize'
import VTooltip from 'v-tooltip'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import directiveCustom from './utils/directive-custom'
import VueGtm from 'vue-gtm'
import CONFIGS from './env'
import { SCREEN_WIDTH_MEDIUM, LAYOUT_MODE } from './utils/constants'
import globalMixin from './mixins/global_mixin'

import Nl2br from 'vue-nl2br'
import Vuelidate from 'vuelidate'
// If you don't need the styles, do not connect
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import '@fullcalendar/core/main.css'
import '@fullcalendar/timegrid/main.css'

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

require('./bootstrap')

Vue.component('Nl2br', Nl2br)
Vue.use(Vuelidate)

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674'
}
Vue.directive('click-outside', directiveCustom.click_outside)
Vue.use(VModal)
Vue.use(VueSweetalert2, options)
Vue.use(datePicker)
Vue.use(VueToastr, {
  defaultProgressBar: false,
  defaultPosition: 'toast-top-center',
  defaultTimeout: 3000
})
Vue.use(VTooltip)
Vue.use(VueDOMPurifyHTML, {
  default: {
    html: true,
    ADD_ATTR: ['target']
  }
})
Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(TextareaAutosize)
Vue.mixin(globalMixin)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.config.productionTip = false
Vue.config.devtools = (process.env.MIX_APP_ENV === 'local' || process.env.MIX_APP_ENV === 'develop') || false

Vue.use(VueGtm, {
  id: process.env.MIX_GTM_ID, // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [] // If router, you can exclude some routes name (case insensitive) (optional)
})

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule] // copies rule configuration
  })
})

// Handle 401 error
axios.interceptors.response.use(function (response) {
  if (typeof response.data.results !== typeof undefined && typeof response.data.results.disallow_ip !== typeof undefined) {
    localStorage.setItem('ip', response.data.results.ip)
    store.commit('disallow_ip', true)

    router.push({ name: 'whitelist.login.request' })
  }

  return response
}, function (error) {
  const currentLayoutMode = parseInt(store.state.auth.layout_mode)

  if (error.response.status === 401) {
    store.commit('logout')
    if (currentLayoutMode === LAYOUT_MODE.PC || (!currentLayoutMode && window.innerWidth > SCREEN_WIDTH_MEDIUM)) {
      router.push({ name: 'auth.login' })
    } else {
      router.push({ name: 'auth.login.small' })
    }
  }
  if (error.response.status === 404) {
    router.push({ name: 'not.found' })
  }
  if (error.response.status === 403) {
    if (!store.getters.isLoggedIn) {
      return Promise.reject(error)
    }

    if (store.getters.isLabDesigner || store.getters.isInstructionAdmin) {
      if (router.currentRoute.name === 'order.search') {
        store.commit('logout')
        if (currentLayoutMode === LAYOUT_MODE.PC || (!currentLayoutMode && window.innerWidth > SCREEN_WIDTH_MEDIUM)) {
          window.location.href = router.resolve({ name: 'auth.login' }).href
        } else {
          window.location.href = router.resolve({ name: 'auth.login.small' }).href
        }
      }
      router.push({ name: 'order.search' })
    } else {
      window.location.href = router.resolve({ name: 'dashboard' }).href
    }
  }

  return Promise.reject(error)
})
Vue.prototype.$configs = CONFIGS
Vue.router = router
Vue.use(require('@websanova/vue-auth'), {
  auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js')
})

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'ja',
  messages
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
