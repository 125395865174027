<template>
    <div :class="[className]">
        <sub-question
            ref="sub_question"
            :question="question"
            :options="option_yes_no"
            :checked="select_option"
            @handleAnswer="handleAnswer($event)"
        ></sub-question>
        <div v-bind:class="handleChildAnswerClass">
            <div v-for="(detail, index) in details" :key="detail.detail_index" class="form-group block-group">
                <label class="block-group__first">{{ detail.detail_index }}</label>
                <div class="block-group__inline">
                    <div class="form-group">
                        <label class="color-blue">診断名：</label>
                        <input v-model="detail.name" class="form-control" type="text" name="">
                    </div>
                    <div class="form-group">
                        <label class="color-blue">部位：</label>
                        <input v-model="detail.part" class="form-control" type="text" name="">
                    </div>
                    <div class="form-group">
                        <label class="color-blue">状態：</label>
                        <input v-model="detail.state" class="form-control" type="text" name="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SubQuestion from '../template/SubQuestion'
    import { INTERVIEW_COMMON_OPTIONS } from '../../../../utils/constants'

    export default {
        components: {
            SubQuestion
        },
        props: ['className'],
        data() {
            return {
                number: 13,
                select_option: INTERVIEW_COMMON_OPTIONS.NO,
                question: '身体全体で先天性の疾患や生まれつき気になる事、部位はありますか？',
                option_yes_no: [
                    INTERVIEW_COMMON_OPTIONS.YES,
                    INTERVIEW_COMMON_OPTIONS.NO,
                ],
                details: [
                    {
                        detail_index: 1,
                        name: '',
                        part: '',
                        state: ''
                    },
                    {
                        detail_index: 2,
                        name: '',
                        part: '',
                        state: ''
                    },
                    {
                        detail_index: 3,
                        name: '',
                        part: '',
                        state: ''
                    }
                ],
                translate: {
                    診断名: 'name',
                    部位: 'part',
                    状態: 'state',
                },
            }
        },
        created: function () {
            this.loadAnswerData();
        },
        methods: {
            loadAnswerData: function() {
                const dataState = this.$store.getters.get_question(this.number);
                this.select_option = dataState.answer;

                dataState.items.forEach((objMain, index) => {
                    objMain.details.forEach(objDetail => {
                        for (let jaName in this.translate) {
                            if (objDetail.title === jaName) {
                                let enName = this['translate'][jaName]
                                this.details[index][enName] = objDetail.content || '';
                            }
                        }
                    });
                });
            },
            formatResult: function () {
                let data = this.$store.getters.get_default_answer_items(this.number)
                data.answer = this.select_option

                if (data.answer === INTERVIEW_COMMON_OPTIONS.YES) {
                    data.items.forEach((objMain, index) => {
                        objMain.details.forEach(objDetail => {
                            for (let jaName in this.translate) {
                                if (objDetail.title === jaName) {
                                    let enName = this['translate'][jaName]
                                    objDetail.content = this.details[index][enName]
                                }
                            }
                        })
                    })
                }
                return data
            },
            handleAnswer: function(value) {
                this.select_option = value;
            }
        },
        computed: {
            handleChildAnswerClass: function () {
                return {
                    'disable-child-answer': this.select_option === INTERVIEW_COMMON_OPTIONS.NO
                }
            },

        }
    }
</script>
