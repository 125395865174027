var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.listPatient, function(item, index) {
      return _c(
        "div",
        { key: index },
        [
          item.interview_info.is_completed === 1
            ? _c("interview-print-answer", { attrs: { "patient-info": item } })
            : _vm._e(),
          _vm._v(" "),
          item.interview_info.is_completed === 0
            ? _c("inter-view-print-without-answer", {
                attrs: { "patient-info": item }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }