var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.className] },
    [
      _c("sub-question", {
        ref: "sub_question",
        attrs: {
          question: _vm.question,
          options: _vm.option_yes_no,
          checked: _vm.select_option
        },
        on: {
          handleAnswer: function($event) {
            return _vm.handleAnswer($event)
          }
        }
      }),
      _vm._v(" "),
      _c("div", { class: _vm.handleChildAnswerClass }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "color-blue" }, [_vm._v("いつ頃：")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.time,
                expression: "time"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text", name: "" },
            domProps: { value: _vm.time },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.time = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("checkbox-common", {
              attrs: { label: "顎の関節痛", val: "顎の関節痛" },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c("checkbox-common", {
              attrs: {
                label: "顎の関節音（ガクガク、ゴリゴリなど）",
                val: "顎の関節音（ガクガク、ゴリゴリなど）"
              },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c("checkbox-common", {
              attrs: { label: "口が開きづらい", val: "口が開きづらい" },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c("checkbox-common", {
              attrs: { label: "口が閉じづらい", val: "口が閉じづらい" },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-inline selection-other-option pt-3" },
              [
                _c("checkbox-common", {
                  attrs: { label: "その他", val: "その他" },
                  model: {
                    value: _vm.selections,
                    callback: function($$v) {
                      _vm.selections = $$v
                    },
                    expression: "selections"
                  }
                }),
                _vm._v(" "),
                _c("div", { class: ["other-input", _vm.diableOtherInput] }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.other,
                        expression: "other"
                      }
                    ],
                    staticClass: "form-control w-100",
                    attrs: { type: "text" },
                    domProps: { value: _vm.other },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.other = $event.target.value
                      }
                    }
                  })
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }