<template>
  <div class="order-list-files__card">
    <div :class="['order-list-files__thumb', {'order-list-files__thumb--lazy-load': is_loading}]">
      <img
        v-if="orderFile.has_order_file_thumb === order_file_thumb_status.LOADED"
        class="order-list-files__image"
        :src="getOrderFileThumbUrl"
        @load="onImgLoaded"
      >
      <div
        v-else-if="isLoadFileFail"
        class="order-list-files__image order-list-files__image--default"
      />
      <div
        v-else
        class="order-list-files__image"
      />
    </div>
    <div class="order-list-files__info">
      <div class="order-list-files__text">
        <div class="order-list-files__name order-list-files__name--no-hover">
          {{ orderFile.file_name }}
        </div>
        <div class="order-list-files__created-at">
          {{ formatDateTime(orderFile.created_at, 'YYYY/M/D HH:mm:ss') }}
        </div>
        <div class="order-list-files__created-by">
          {{ safeGet(orderFile, 'upload_by.name') }}
        </div>
      </div>
      <div
        :class="[
          'order-list-files__download',
          { 'order-list-files__download--disabled': isLoadFileFail }
        ]"
      >
        <img
          :src="isLoadFileFail ? '/images/icon-download-gray.svg' : '/images/icon-download-blue.svg'"
          @click="downloadOrderFile(getOrderFileDownloadUrl)"
        >
      </div>
    </div>
  </div>
</template>

<script>
  import orderMixin from '../../mixins/order'
  import { safeGet } from '../../utils/helpers'

  export default {
    mixins: [orderMixin],
    props: {
      orderFile: {
        type: Object,
        default: () => {}
      },
      sfOrderId: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        is_loading: true
      }
    },
    computed: {
      getOrderFileDownloadUrl () {
        return `/order/attach/0/${this.sfOrderId || this.orderFile.order_id}/${this.orderFile.id}`
      },
      getOrderFileThumbUrl () {
        if (typeof this.orderFile === 'undefined' || typeof this.orderFile.file_name === 'undefined') return ''
        const fileName = this.orderFile.file_name.toLowerCase()
        if (/\.pdf|\.PDF$/.test(fileName)) {
          return '/images/icon-pdf.jpg'
        } else if (/\.mov|\.MOV|\.mp4|\.MP4$/.test(fileName)) {
          return '/images/icon-play.jpg'
        } else if (/\.stl|\.STL$/.test(fileName)) {
          return '/images/icon-stl.svg'
        }
        return this.orderFile.order_file_thumb_url
      },
      isLoadFileFail () {
        return this.orderFile.has_order_file_thumb === this.order_file_thumb_status.FAILED
      }
    },
    methods: {
      onImgLoaded () {
        this.is_loading = false
      },
      safeGet (object, key) {
        return safeGet(object, key)
      }
    }
  }
</script>
