import axios from 'axios'
import { state as authState } from './auth'
import { get } from 'lodash'
import { TYPE_LINK_MASTERS } from '../../utils/constants'

export const namespaced = true

export const state = {
  support_material_links: [],
  upload_help_link: '',
  take_picture_help_link: ''
}

export const mutations = {
  setSupportMaterialLinks (state, data) {
    state.support_material_links = [...data]
  },
  setUploadHelpLink (state, data) {
    state.upload_help_link = data
  },
  setTakePictureHelpLink (state, data) {
    state.take_picture_help_link = data
  }
}

export const actions = {
  getLinkMasters ({ dispatch, commit }) {
    return axios.get('/api/link-masters', {
      headers: { Authorization: 'Bearer ' + authState.token }
    }).then((res) => {
      const result = get(res, 'data.results')
      commit('setSupportMaterialLinks', result.filter((item) => {
        return item.type === TYPE_LINK_MASTERS.SUPPORT_MATERIAL
      }) || [])
      commit('setUploadHelpLink', result.find((item) => {
        return item.type === TYPE_LINK_MASTERS.UPLOAD_HELP
      })?.link || '')
      commit('setTakePictureHelpLink', result.find((item) => {
        return item.type === TYPE_LINK_MASTERS.TAKE_A_PICTURE_HELP
      })?.link || '')

      return res
    }).catch(error => {
      throw error
    })
  }
}
