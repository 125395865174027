<template>
  <div class="content-inner bg-white interview-block">
    <div class="page-content">
      <div class="header-title">
        <p>{{ $t('interview.basic_info') }}</p>
        <p
          class="gray-arrow"
          :class="{ 'toggle-icon' : show }"
          @click="handleClick"
        />
      </div>
      <div
        v-show="show"
        class="interview-info-page content-block"
      >
        <div class="two-content">
          <div class="patient-detail-left col-md-6">
            <div class="heading-two">
              <p class="content-title ">{{ $t('interview.basic_info') }}</p>
            </div>
            <div class="row-content d-flex">
              <div class="col-12 m-0 p-0">
                <div class="form-group">
                  <label class="control-label">{{ $t('interview.patient_name') }}</label>
                  <div class="control-text">{{ patientFullName }}</div>
                </div>
              </div>
              <div class="col-6 m-0 p-0">
                <div class="form-group">
                  <label class="control-label">{{ $t('interview.patient_romaji') }}</label>
                  <div class="control-text">{{ romaName }}</div>
                </div>
                <div class="form-group">
                  <label class="control-label">{{ $t('interview.birthday') }}</label>
                  <div class="control-text">{{ getBirthday }}</div>
                </div>
                <div class="form-group">
                  <label class="control-label">{{ $t('interview.occupation') }}</label>
                  <div class="control-text">{{ getPatientOccupation }}</div>
                </div>
              </div>
              <div class="col-6 m-0 p-0">
                <div class="form-group">
                  <label class="control-label">{{ $t('interview.patient_furigana') }}</label>
                  <div class="control-text">{{ kanaName }}</div>
                </div>
                <div class="d-flex two-label">
                  <div class="col-6 form-group">
                    <label class="control-label">{{ $t('interview.age') }}</label>
                    <div
                      v-if="getAge >= 0"
                      class="control-text"
                    >
                      {{ getAgeWithText }}
                    </div>
                  </div>
                  <div class="col-6 form-group">
                    <label class="control-label">{{ $t('interview.sex') }}</label>
                    <div class="control-text d-flex">
                      {{ getGender }}
                      <div
                        v-if="info.pregnancy_status"
                        class="status-pregnant"
                      >
                        {{ $t('interview.currently_pregnant') }}
                      </div>
                      <div
                        v-if="!info.pregnancy_status && info.gender === 1"
                        class="status-not-pregnant"
                      >
                        {{ $t('interview.not_pregnant') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label">{{ $t('interview.kid_learning_sport') }}</label>
                  <div class="control-text">{{ info.lesson_sport }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="patient-detail-left col-md-6">
            <div class="heading-two">
              <p class="content-title">{{ $t('interview.address_contact') }}</p>
            </div>
            <div class="row-content">
              <div class="col-12 m-0 p-0">
                <div class="form-group">
                  <label class="control-label">{{ $t('interview.street_address') }}</label>
                  <div class="control-text">{{ info.address }}</div>
                </div>
                <div class="form-group">
                  <label class="control-label">{{ $t('interview.phone_number') }}</label>
                  <div class="control-text">{{ info.tel }}</div>
                </div>
                <div class="form-group">
                  <label class="control-label">{{ $t('interview.mail_address') }}</label>
                  <div class="control-text">{{ info.email }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="getAge >= 0 && getAge < 20"
          class="patient-detail-left col-md-12"
        >
          <div class="heading-two">
            <p class="content-title">{{ $t('interview.minors_info') }}</p>
          </div>
          <div class="row-content d-flex">
            <div class="col-3 m-0 p-0">
              <div class="form-group">
                <label class="control-label">{{ $t('interview.guardian_name') }}</label>
                <div class="control-text">
                  {{ guardianName }}
                </div>
              </div>
              <div class="form-group">
                <label class="control-label">{{ $t('interview.contact_info') }}</label>
                <div class="control-text">{{ info.guardian_phone_number }}</div>
              </div>
            </div>
            <div class="col-9 m-0 p-0">
              <div class="d-flex two-label">
                <div class="form-group col-4">
                  <label class="control-label">{{ $t('interview.patient_furigana') }}</label>
                  <div class="control-text">{{ furiganaName }}</div>
                </div>
                <div class="form-group col-4">
                  <label class="control-label">{{ $t('interview.relationship') }}</label>
                  <div class="control-text"> {{ info.guardian_relationship }}</div>
                </div>
                <div class="form-group col-4">
                  <label class="control-label">{{ $t('interview.guardian_occupation') }}</label>
                  <div class="control-text">
                    {{ getGuardianOccupation }}
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label">{{ $t('interview.other_address') }}</label>
                <div class="control-text">{{ info.guardian_address }}</div>
              </div>
            </div>
          </div>

          <div class="heading-two">
            <p class="content-title content-title--no-border">{{ $t('interview.guardian_info') }}</p>
          </div>
          <div class="row-content d-flex">
            <div class="col-3 m-0 p-0">
              <div class="form-group">
                <label class="control-label">{{ $t('interview.guardian_name') }}</label>
                <div class="control-text">
                  {{ guardian2Name }}
                </div>
              </div>
            </div>
            <div class="col-9 m-0 p-0">
              <div class="d-flex two-label">
                <div class="form-group col-4">
                  <label class="control-label">{{ $t('interview.patient_furigana') }}</label>
                  <div class="control-text">{{ guardian2FuriganaName }}</div>
                </div>
                <div class="form-group col-4">
                  <label class="control-label">{{ $t('interview.relationship') }}</label>
                  <div class="control-text"> {{ info.guardian2_relationship }}</div>
                </div>
                <div class="form-group col-4">
                  <label class="control-label">{{ $t('interview.contact_info') }}</label>
                  <div class="control-text">{{ info.guardian2_phone_number }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as moment from 'moment'
  import helpers from '../../../../utils/helpers.js'
  import { SELECT_GENDER, PROFESSION_OTHER_ID, PROFESSIONS } from '../../../../utils/constants.js'
  import patientMixins from '../../../../mixins/patient'

  export default {
    name: 'information',
    props: ['patient_info'],
    mixins: [
      patientMixins
    ],
    data () {
      return {
        genders: SELECT_GENDER,
        show: true,
        info: this.patient_info
      }
    },
    computed: {
      kanaName () {
        return [this.info.kana_last_name, this.info.kana_first_name].join(' ')
      },
      romaName () {
        return [this.info.roma_last_name, this.info.roma_first_name].join(' ')
      },
      getGender () {
        const gender = this.genders.find(item => item.value === this.info.gender)
        if (gender) {
          return gender.label
        }

        return ''
      },
      getBirthday () {
        return helpers.formatDateTime(this.info.birth_of_date, this.$t('datetime.year_month_day'))
      },
      getAge () {
        if (this.info.birth_of_date) {
          return moment().diff(this.info.birth_of_date, 'years')
        }

        return -1
      },
      guardianName () {
        return [this.info.guardian_last_name, this.info.guardian_first_name].join(' ')
      },
      furiganaName () {
        return [this.info.guardian_kana_last_name, this.info.guardian_kana_first_name].join(' ')
      },
      guardian2Name () {
        return [this.info.guardian2_last_name, this.info.guardian2_first_name].join(' ')
      },
      guardian2FuriganaName () {
        return [this.info.guardian2_kana_last_name, this.info.guardian2_kana_first_name].join(' ')
      },
      getAgeWithText () {
        return `${this.getAge} 歳`
      },
      getGuardianOccupation () {
        return this.getOccupation(this.info?.guardian_occupation || this.info.occupation, this.info.guardian_other_occupations)
      },
      getPatientOccupation () {
        return this.getOccupation(this.info.occupation, this.info.other_occupations)
      }
    },
    methods: {
      handleClick () {
        this.show = !this.show
      },
      getOccupation (professionId, professionOther) {
        const profession = PROFESSIONS.find((element) => professionId === element.id)
        return (profession.id === PROFESSION_OTHER_ID && professionOther !== null) ? professionOther : profession.label
      }
    }
  }
</script>
