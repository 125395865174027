var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c(
      "div",
      {
        class: ["mc-modal mc-modal__pdf", _vm.customClass],
        on: {
          click: function($event) {
            return _vm.$emit("close-modal")
          }
        }
      },
      [
        _c(
          "div",
          {
            class: [
              "mc-modal__pdf__wrapper",
              { "mc-modal__pdf__wrapper--img": _vm.extension !== "application" }
            ]
          },
          [
            _vm.extension === "application"
              ? _c(
                  "div",
                  { staticClass: "mc-modal__pdf__inner" },
                  [
                    _vm._l(_vm.num_pages, function(page) {
                      return _c("pdf", {
                        key: page,
                        staticClass: "mc-modal__pdf__canvas",
                        attrs: { src: _vm.src, page: page }
                      })
                    }),
                    _vm._v(" "),
                    _c("infinite-loading", {
                      attrs: { identifier: _vm.infinite_scroll_id },
                      on: { infinite: _vm.infiniteScrollHandler },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "no-more",
                            fn: function() {
                              return [_c("div", { staticClass: "d-none" })]
                            },
                            proxy: true
                          },
                          {
                            key: "no-results",
                            fn: function() {
                              return [_c("div", { staticClass: "d-none" })]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3942958022
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.extension === "image"
              ? _c("img", { attrs: { src: _vm.url } })
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mc-modal__pdf__close",
            on: {
              click: function($event) {
                return _vm.$emit("close-modal")
              }
            }
          },
          [_c("img", { attrs: { src: "/images/close-x.svg" } })]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }