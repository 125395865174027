var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "patient-detail patient-history",
        { "lazy-load lazy-load--big-space": _vm.lazy_load }
      ]
    },
    [
      _c("div", { staticClass: "patient-detail__content w-100" }, [
        _c("div", { staticClass: "patient-detail__content__header" }, [
          _c("label", { staticClass: "patient-detail__content__title" }, [
            _vm._v(_vm._s(_vm.$t("objects.patient.text.patient_information")))
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "patient-detail__content__action patient-detail--top"
            },
            [
              _c(
                "div",
                {
                  staticClass: "patient-group__cont patient-group__cont-second"
                },
                [
                  _c("label", { staticClass: "cont-label fs-13" }, [
                    _vm._v(_vm._s(_vm.$t("objects.patient.text.edit_date")))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cont-text fs-14" }, [
                    _c("span", [_vm._v(_vm._s(_vm.renderCreatedAt))])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "px-4 button-v2 button-v2--tertiary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.back()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("new_text.button.back")) +
                      "\n        "
                  )
                ]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "patient-detail__content__information" }, [
          _c("div", { staticClass: "patient-group" }, [
            _c("div", { staticClass: "patient-group__title" }, [
              _c("h3", { staticClass: "text" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("objects.patient.text.basic_info")) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "patient-group__col" }, [
              _c("div", { staticClass: "patient-group__cont" }, [
                _c("label", { staticClass: "cont-label" }, [
                  _vm._v(_vm._s(_vm.$t("new_text.name")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cont-text break-word pr-30" }, [
                  _c(
                    "span",
                    {
                      class: [_vm.isChanged("name") ? "highlight-green-bg" : ""]
                    },
                    [_vm._v(_vm._s(_vm.patient_full_name))]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "patient-group__cont" }, [
                _c("label", { staticClass: "cont-label" }, [
                  _vm._v(_vm._s(_vm.$t("new_text.roman")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cont-text break-word pr-30" }, [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.isChanged("roma_first_name") ||
                        _vm.isChanged("roma_last_name")
                          ? "highlight-green-bg"
                          : ""
                      ]
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.patient_roma_name) +
                          "\n              "
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "patient-group__cont" }, [
                _c("label", { staticClass: "cont-label" }, [
                  _vm._v(_vm._s(_vm.$t("new_text.birthday")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cont-text" }, [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.isChanged("birth_of_date")
                          ? "highlight-green-bg"
                          : ""
                      ]
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.renderBirthDate) +
                          " " +
                          _vm._s(_vm.renderAge)
                      )
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "patient-group__col" }, [
              _c("div", { staticClass: "patient-group__cont" }, [
                _c("label", { staticClass: "cont-label" }, [
                  _vm._v(_vm._s(_vm.$t("new_text.hiragana")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cont-text break-word" }, [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.isChanged("kana_first_name") ||
                        _vm.isChanged("kana_last_name")
                          ? "highlight-green-bg"
                          : ""
                      ]
                    },
                    [_vm._v(_vm._s(_vm.patient_kata_name))]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "patient-group__cont" }, [
                _c("label", { staticClass: "cont-label" }, [
                  _vm._v(_vm._s(_vm.$t("new_text.sex")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cont-text" }, [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.isChanged("gender") ? "highlight-green-bg" : ""
                      ]
                    },
                    [_vm._v(_vm._s(_vm.renderSex))]
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.brand_master
                ? _c("div", { staticClass: "patient-group__cont" }, [
                    _c("label", { staticClass: "cont-label" }, [
                      _vm._v(_vm._s(_vm.$t("new_text.brand_type")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cont-text" }, [
                      _c(
                        "span",
                        {
                          class: [
                            _vm.isChanged("brand_type")
                              ? "highlight-green-bg"
                              : ""
                          ]
                        },
                        [_vm._v(_vm._s(_vm.brand_master.name))]
                      )
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "c-both" }),
            _vm._v(" "),
            _vm.patient_cur_data.reason_for_non_contract
              ? _c("div", { staticClass: "patient-group__cont" }, [
                  _c("label", { staticClass: "cont-label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "objects.patient.text.reason_for_non_contracting"
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cont-text" }, [
                    _c(
                      "span",
                      {
                        class: [
                          "ws-pre-wrap",
                          {
                            "highlight-green-bg": _vm.isChanged(
                              "reason_for_non_contract"
                            )
                          }
                        ]
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.patient_cur_data.reason_for_non_contract)
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "patient-group" }, [
            _c("div", { staticClass: "patient-group__title" }, [
              _c("h3", { staticClass: "text" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("objects.patient.text.contact_info")) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "patient-group__cont" }, [
              _c("label", { staticClass: "cont-label" }, [
                _vm._v(_vm._s(_vm.$t("new_text.address")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cont-text" }, [
                _c(
                  "span",
                  {
                    class: [
                      _vm.isChanged("address") ? "highlight-green-bg" : ""
                    ]
                  },
                  [_vm._v(_vm._s(_vm.address))]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "patient-group__cont" }, [
              _c("label", { staticClass: "cont-label" }, [
                _vm._v(_vm._s(_vm.$t("new_text.phone_number")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cont-text" }, [
                _c(
                  "span",
                  { class: [_vm.isChanged("tel") ? "highlight-green-bg" : ""] },
                  [_vm._v(_vm._s(_vm.phone_number))]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "patient-group__cont" }, [
              _c("label", { staticClass: "cont-label" }, [
                _vm._v(_vm._s(_vm.$t("new_text.email")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cont-text" }, [
                _c(
                  "span",
                  {
                    class: [_vm.isChanged("email") ? "highlight-green-bg" : ""]
                  },
                  [_vm._v(_vm._s(_vm.email_address))]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "patient-group mb-0" }, [
            _c("div", { staticClass: "patient-group__title" }, [
              _c("h3", { staticClass: "text" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("objects.patient.text.delivery_setting")) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "patient-group__cont patient-group__cont-first" },
              [
                _c("label", { staticClass: "cont-label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("objects.patient.text.sms_delivery_setting"))
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cont-text" }, [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.isChanged("is_receive_sms")
                          ? "highlight-green-bg"
                          : ""
                      ]
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.checkDeliver(_vm.is_receive_sms)) +
                          "\n            "
                      )
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "patient-group__cont patient-group__cont-first" },
              [
                _c("label", { staticClass: "cont-label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("objects.patient.text.mail_delivery_setting"))
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cont-text" }, [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.isChanged("is_receive_sms")
                          ? "highlight-green-bg"
                          : ""
                      ]
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.checkDeliver(_vm.is_receive_email)) +
                          "\n            "
                      )
                    ]
                  )
                ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "patient-detail__content__header" }, [
          _c("label", { staticClass: "patient-detail__content__title" }, [
            _vm._v(_vm._s(_vm.$t("objects.patient.text.system_information")))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "patient-detail__content__information" }, [
          _c("div", { staticClass: "patient-group" }, [
            _c(
              "div",
              {
                staticClass:
                  "patient-group__cont patient-group__cont-second mt-0"
              },
              [
                _c("label", { staticClass: "cont-label" }, [
                  _vm._v(_vm._s(_vm.$t("objects.patient.text.patient_status")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cont-text" }, [
                  _c("span", [_vm._v(_vm._s(_vm.patientStatus))])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "patient-group__cont patient-group__cont-second" },
              [
                _c("label", { staticClass: "cont-label" }, [
                  _vm._v(_vm._s(_vm.$t("new_text.registration_date")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cont-text" }, [
                  _c("span", [_vm._v(_vm._s(_vm.createdAt))])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "patient-group__cont patient-group__cont-second" },
              [
                _c("label", { staticClass: "cont-label" }, [
                  _vm._v(_vm._s(_vm.$t("new_text.last_updated")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cont-text" }, [
                  _c("span", [_vm._v(_vm._s(_vm.renderCreatedAt))])
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "patient-group" },
            [
              _c("div", { staticClass: "patient-group__title" }, [
                _c("h3", { staticClass: "text" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("objects.patient.text.assignee_info")) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._l(_vm.history_person_in_charges, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "patient-group__cont patient-group__cont-second"
                  },
                  [
                    _c("label", { staticClass: "cont-label" }, [
                      _vm._v(_vm._s(_vm.getPositionName(item)))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cont-text" }, [
                      _c(
                        "span",
                        {
                          class: [
                            _vm.isPersonChanged(item.staff_id)
                              ? "highlight-green-bg"
                              : "highlight-red-bg"
                          ]
                        },
                        [_vm._v(_vm._s(item.staff.name))]
                      )
                    ])
                  ]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "patient-group" }, [
            _c("div", { staticClass: "patient-group__title" }, [
              _c("h3", { staticClass: "text" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("objects.patient.text.other_notes")) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "patient-group__cont" }, [
              _c("div", { staticClass: "cont-text" }, [
                _c(
                  "span",
                  {
                    class: [_vm.isChanged("note") ? "highlight-green-bg" : ""]
                  },
                  [_vm._v(_vm._s(_vm.other_note))]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "patient-group" }, [
            _c(
              "div",
              { staticClass: "patient-group__cont mt-0" },
              [
                _c("label", { staticClass: "cont-label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("objects.patient.text.service_management"))
                  )
                ]),
                _vm._v(" "),
                _vm.serviceSummary.length
                  ? _c("patient-service-summary", {
                      attrs: { "service-summary": _vm.serviceSummary }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }