import axios from 'axios'

export const actions = {
  storeTreatment: function ({ rootState }, data) {
    return axios.post(
      '/api/treatments',
      data,
      { headers: { Authorization: 'Bearer ' + rootState.auth.token } }
    )
  },
  updateTreatment: function ({ rootState }, data) {
    return axios.post(
      `/api/treatments/${data.id}`,
      { _method: 'PATCH', ...data },
      { headers: { Authorization: 'Bearer ' + rootState.auth.token } }
    )
  },
  deleteTreatment: function ({ rootState }, id) {
    return axios.post(
      `/api/treatments/${id}`,
      { _method: 'DELETE' },
      { headers: { Authorization: 'Bearer ' + rootState.auth.token } }
    )
  },
  getListClinicTreatment: function ({ rootState }) {
    return axios.get(
      '/api/treatments',
      { headers: { Authorization: 'Bearer ' + rootState.auth.token } }
    )
  }
}
