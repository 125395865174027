<template>
  <logo>
    <div class="d-flex justify-content-center send-email">
      <p>
        {{ $t('email_forgot_password.text_1') }}<br>
        {{ $t('email_forgot_password.text_2') }}<br>
        {{ $t('email_forgot_password.text_3') }}
      </p>
    </div>
  </logo>
</template>
<script>
  import logo from '../components/Logo'

  export default {
    components: {
      logo
    },
    created () {
      document.title = this.$t('page_title.u1_3')
    }
  }
</script>
