var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "patient-orders",
        { "patient-orders--only-order-detail": _vm.isOnlyOrderDetail }
      ]
    },
    [
      _c(
        "div",
        { staticClass: "patient-orders__header" },
        [
          _c("p", { staticClass: "patient-orders__header__text" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.isOnlyOrderDetail
                    ? _vm.$t("objects.patient.text.patient_information")
                    : _vm.$t("objects.patient.text.technician_order")
                ) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          !_vm.isOnlyOrderDetail
            ? _c("custom-button", {
                attrs: {
                  disabled:
                    !_vm.intVal(_vm.patient.sf_user_id) || !_vm.isClinicStaff
                },
                on: { click: _vm.moveCreateOrderScreen },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "objects.patient.button.create_technician_instructions"
                                )
                              ) +
                              "\n      "
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1149110226
                )
              })
            : _c("custom-button", {
                attrs: { disabled: !_vm.orderId },
                on: { click: _vm.redirectBack },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("new_text.button.back")) +
                            "\n      "
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "patient-orders__patient-info" }, [
        !_vm.isOnlyOrderDetail
          ? _c("div", { staticClass: "patient-orders__patient-info__title" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("objects.patient.text.patient_information")) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "patient-orders__patient-info__content" }, [
          _c("div", { staticClass: "patient-orders__patient-info__col" }, [
            _c("div", { staticClass: "patient-orders__patient-info__item" }, [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("objects.patient.text.patient_id")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "patient-orders__patient-info__right" },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.intVal(_vm.patient.sf_user_id) || _vm.patient.id
                      )
                    )
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "patient-orders__patient-info__item" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("new_text.full_name")))]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "patient-orders__patient-info__right" },
                [_c("p", [_vm._v(_vm._s(_vm.name))])]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "patient-orders__patient-info__item" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("new_text.roman")))]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "patient-orders__patient-info__right" },
                [_c("p", [_vm._v(_vm._s(_vm.romaName))])]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "patient-orders__patient-info__col" }, [
            _c("div", { staticClass: "patient-orders__patient-info__item" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("new_text.clinic")))]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "patient-orders__patient-info__right" },
                [_c("p", [_vm._v(_vm._s(_vm.formalNameClinic))])]
              )
            ]),
            _vm._v(" "),
            !_vm.isOnlyOrderDetail
              ? _c(
                  "p",
                  {
                    staticClass:
                      "patient-orders__patient-info__item patient-orders__patient-info__item__examination"
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "patient-orders__patient-info__label-examination"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "objects.patient.text.technician_instructions_template"
                              )
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("custom-button", {
                      attrs: { disabled: !_vm.is_ready_print },
                      on: {
                        click: function($event) {
                          return _vm.printInstruction(
                            _vm.type_export.FIRST_EXAMINATION
                          )
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "content",
                            fn: function() {
                              return [
                                _vm.is_ready_print
                                  ? _c("img", {
                                      attrs: {
                                        src: "/images/icon-download-blue.svg"
                                      }
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: "/images/icon-download-gray.svg"
                                      }
                                    }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "objects.patient.button.template_first_visit",
                                        {
                                          patient_id:
                                            _vm.intVal(
                                              _vm.patient.sf_user_id
                                            ) || _vm.patient.id
                                        }
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        307606995
                      )
                    }),
                    _vm._v(" "),
                    _c("custom-button", {
                      staticClass: "button-v2--examination",
                      attrs: { disabled: !_vm.is_ready_print },
                      on: {
                        click: function($event) {
                          return _vm.printInstruction(
                            _vm.type_export.RE_EXAMINATION
                          )
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "content",
                            fn: function() {
                              return [
                                _vm.is_ready_print
                                  ? _c("img", {
                                      attrs: {
                                        src: "/images/icon-download-blue.svg"
                                      }
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: "/images/icon-download-gray.svg"
                                      }
                                    }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "objects.patient.button.template_re_visit",
                                        {
                                          patient_id:
                                            _vm.intVal(
                                              _vm.patient.sf_user_id
                                            ) || _vm.patient.id
                                        }
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3536748158
                      )
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("table-list-order", {
        attrs: {
          orders: _vm.orders,
          "is-show-button-print": !_vm.isOnlyOrderDetail
        },
        on: { "print-individual-technical-info": _vm.print }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "order-list-files__wrapper" },
        _vm._l(_vm.orders, function(order) {
          return _c("div", { key: order.id, staticClass: "order-list-files" }, [
            _c("div", { staticClass: "order-list-files__header" }, [
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.handleShowListImage(order)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("objects.orders.text.number_order_image", {
                          number: order.product_number,
                          suffix: _vm.getOrdinalSuffix(order.product_number)
                        })
                      ) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("img", {
                class: [
                  "order-list-files__toggle",
                  {
                    "order-list-files__toggle__up":
                      _vm.show_image[order.id] || false
                  }
                ],
                attrs: { src: "/images/icon-toggle-down.svg" },
                on: {
                  click: function($event) {
                    return _vm.handleShowListImage(order)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "order-list-files__list",
                  {
                    "order-list-files__list__show":
                      _vm.show_image[order.id] || false
                  }
                ]
              },
              [
                _c("instruction-file-detail", { attrs: { order: order } }),
                _vm._v(" "),
                _vm._l(order.order_files, function(file) {
                  return _c("order-file-detail", {
                    key: file.id,
                    attrs: {
                      "order-file": file,
                      "sf-order-id": order.sf_order_id
                    }
                  })
                })
              ],
              2
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.patient.id
        ? _c("iframe", {
            attrs: { src: _vm.printInstructionUrl, name: "printInstruction" },
            on: { load: _vm.setDataInstruction }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }