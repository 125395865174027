<template>
  <div>
    <div class="menu-sidebar">
      <div class="menu-container">
        <div class="menu-inner">
          <div class="sidebar_content">
            <nav class="nav-menu js-menu">
              <ul class="u-list">
                <li
                  v-if="show_reservation && !isLabDesigner"
                  class="menu_item"
                  :title="$t('sidebar_menu.reserve')"
                >
                  <router-link
                    v-if="current_page == 'secretariat'"
                    :to="{ name: 'reservation.list' }"
                    class="router-link-exact-active router-link-active"
                  >
                    <div class="item_inner">
                      <img
                        :src="'/images/clipboard-with-pencil-.svg'"
                        alt=""
                      /><span>{{ $t('sidebar_menu.reserve') }}</span>
                    </div>
                  </router-link>
                  <router-link :to="{ name: 'dashboard' }" v-else>
                    <div class="item_inner">
                      <img
                        :src="'/images/clipboard-with-pencil-.svg'"
                        alt=""
                      /><span>{{ $t('sidebar_menu.reserve') }}</span>
                    </div>
                  </router-link>
                </li>
                <li
                  v-if="is_clinics_dmr === 1 && !isLabDesigner"
                  class="menu_item"
                  :title="$t('sidebar_menu.recept')"
                >
                  <router-link :to="{ name: 'reception' }">
                    <div class="item_inner"><img
                      :src="'/images/clipboard-reception.svg'"
                      alt=""
                    /><span>{{ $t('sidebar_menu.recept') }}</span>
                    </div>
                  </router-link>
                </li>
                <li
                  v-if="!isLabDesigner"
                  class="menu_item"
                  :title="$t('sidebar_menu.patient')"
                >
                  <router-link :to="{ name: 'patient.list' }">
                    <div class="item_inner"><img
                      :src="'/images/clipboard-patient.svg'"
                      alt=""
                    /><span>{{ $t('sidebar_menu.patient') }}</span>
                    </div>
                  </router-link>
                </li>
                <li
                  v-if="is_clinics_dmr === 1 && !isLabDesigner"
                  class="menu_item"
                  :title="$t('sidebar_menu.accounting')"
                >
                  <router-link :to="{ name: 'accounting.list' }">
                    <div class="item_inner">
                      <img
                        :src="'/images/clipboard-accounting.svg'"
                        alt=""
                      /><span>{{ $t('sidebar_menu.accounting') }}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <div class="notification">
            <router-link :to="{ name: 'notification.list' }">
              <span
                v-if="totalNotification"
                class="notification-number"
              >{{ totalNotification }}</span>
              <button class="btn">
                <svg viewBox="0 0 21 24">
                  <defs></defs>
                  <path
                    class="a"
                    d="M10.5,24a3,3,0,0,0,3-3h-6A3,3,0,0,0,10.5,24Zm10.1-7.018c-.906-.973-2.6-2.437-2.6-7.232a7.4,7.4,0,0,0-6-7.273V1.5a1.5,1.5,0,1,0-3,0v.977A7.4,7.4,0,0,0,3,9.75c0,4.8-1.695,6.259-2.6,7.232A1.465,1.465,0,0,0,0,18a1.5,1.5,0,0,0,1.5,1.5H19.5A1.5,1.5,0,0,0,21,18,1.464,1.464,0,0,0,20.6,16.982Z"></path>
                </svg>
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import firebase from '../config/firebase'
  import eventBus from '../utils/event-bus'

  export default {
    name: 'SidebarMenu',
    data () {
      return {
        current_page: '',
        show_reservation: process.env.MIX_SHOW_RESERVATION,
        is_clinics_dmr: 1,
        totalNotification: 0
      }
    },
    computed: {
      isLabDesigner () {
        return this.$store.getters.isLabDesigner
      }
    },
    created () {
      this.show_reservation = this.show_reservation === 'true'
      if (localStorage.getItem('is_clinics_dmr') == null) {
        localStorage.setItem('is_clinics_dmr', 0)
      }
      this.is_clinics_dmr = Number(localStorage.getItem('is_clinics_dmr'))

      this.$store.dispatch('getLoggedInfo').then((result) => {
        const data = this.safeGet(result.data, 'results')
        if (data.user.clinic.is_dmr != localStorage.getItem('is_clinics_dmr')) {
          this.is_clinics_dmr = data.user.clinic.is_dmr
          localStorage.setItem('is_clinics_dmr', this.is_clinics_dmr)
        }
      }).catch(() => {
      })

      this.getTotalNotification()
    },
    mounted: function () {
      const rootPath = this.$router.currentRoute.name.split('.')[0].trim()
      const childPath = this.$router.currentRoute.name.split('.')[1] ? this.$router.currentRoute.name.split('.')[1].trim() : ''
      if (rootPath == 'reservation' && childPath == 'secretariat') this.current_page = 'secretariat'
      else if (rootPath == 'reservation') this.current_page = 'reservation'
      else this.current_page = ''

      $(document).on('click', function (e) {
        var target = $(e.target)
        if (target.is($('#user-settings'))) return
        if (!target.parents('.user-action').length) {
          $('.user-action').hide()
        }
        if (target.is($('.user_menu .icon')) || target.parents('.user_menu .icon').length) return
        if (!target.parents('.language_action').length) {
          $('.language_action').hide()
        }
        if (target.is($('.menu-slide-date .menu-slide'))
          || target.parents('.menu-slide-date .menu-slide').length) return
        if (!target.parents('.menu-slide-date .menu-slide').length) {
          $('.collapse.show').collapse('hide')
        }
      })

      this.$store.dispatch('staff/config')
        .then(config => {
          if (typeof config != 'undefined' && config.hasOwnProperty('lang') && config.lang !== null) {
            this.$i18n.locale = config.lang
          }
        })

      const messaging = firebase.messaging()

      if (!(messaging.vapidKey)) {
        messaging.usePublicVapidKey("BAypPKzcac_gpt4YIM8WqpluVhAz39X997KQsfWkP45dCFVUOLcSXAZzvNLtLsPNPc4GyxynGTSzODLEqUu8w9I")
      }
      Notification
        .requestPermission()
        .then(() => firebase.messaging().getToken())
        .then((token) => {
          const localToken = localStorage.getItem('device_token')
          if (localToken != token) {
            localStorage.setItem('device_token', token)
            this.saveToken(token)
          }
        })
        .catch(function (err) {
        })

      messaging.onMessage((payload) => {
        let notify = payload.notification
        this.totalNotification++
      })

      messaging.onTokenRefresh(() => {
        messaging.getToken().then((refreshedToken) => {
          this.saveToken(refreshedToken)
          localStorage.setItem('device_token', token)
        }).catch(() => {
        })
      })
      eventBus.$on('update_total_notify', this.getTotalNotification)
    },
    beforeDestroy() {
      eventBus.$off('update_total_notify', this.getTotalNotification)
    },
    methods: {
      toggle: function () {
        $('.user-action').toggle()
        $('.language_action').hide()
      },
      language_toggle: function (lang) {
        $('.language_action').toggle()
        $('.user-action').toggle()
      },
      change_language (lang) {
        this.$store.dispatch('staff/changeLanguage', { lang })
          .then(() => {
            this.$i18n.locale = lang
          })
          .finally(() => {
            this.language_toggle()
          })
      },
      async logOut () {
        this.$store.dispatch('logout').then((result) => {
          this.$router.push({ name: 'auth.login' })
        }).catch((error) => {
          this.validationErrors = error.response.data.errors
        })
      },
      safeGet: function (object, keys) {
        return keys.split('.').reduce((xs, x) => (xs && xs[x]) ? xs[x] : undefined, object) || ''
      },

      saveToken: function (item) {
        this.$store.dispatch('saveToken', { token: item }).then((result) => {
        }).catch(() => {
        })
      },
      getTotalNotification () {
        this.$store.dispatch('getTotalNotification').then((res) => {
          if (res.status === 200) {
            this.totalNotification = res.data.results
          }
        }).catch((error) => {
          this.validationErrors = error.response.data.errors
        })
      }
    }
  }
</script>
