<template>
  <div
    v-if="!!options"
    :id="generateId"
    class="dropdown-filter-common"
  >
    <div
      :class="['dropdown-filter-common__data', { 'dropdown-filter-common__data--active': show_dropdown }]"
      @click="show_dropdown = !show_dropdown"
    >
      <span class="dropdown-filter-common__text">{{ dataChecked }}</span>
    </div>
    <div
      v-if="show_dropdown"
      class="dropdown-filter-common__box"
    >
      <div class="dropdown-filter-common__search">
        <input-common
          :default-value="filter_item"
          @input="value => filter_item = value"
        />
      </div>
      <ul class="dropdown-filter-common__list">
        <li
          v-if="!!emptyValue && filter_checked"
          class="dropdown-filter-common__item dropdown-filter-common__item--hover"
          @click="selectItem(null)"
        >
          {{ emptyValue }}
        </li>
        <li
          v-for="item in filteredList"
          :key="item.value"
          class="dropdown-filter-common__item dropdown-filter-common__item--hover"
          @click="selectItem(item)"
        >
          {{ item.text }}
        </li>
        <li
          v-if="!filteredList.length && options.length"
          class="dropdown-filter-common__item"
        >
          {{ $t('new_text.search_not_found') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import InputCommon from './Input'
  export default {
    components: {
      InputCommon
    },
    props: {
      options: {
        type: Array,
        default: () => []
      },
      placeholder: {
        type: String,
        default: ''
      },
      emptyValue: {
        type: String,
        default: ''
      },
      defaultValue: {
        type: [Number, String],
        default: ''
      }
    },
    emits: ['change'],
    data () {
      return {
        filter_checked: '',
        filter_item: '',
        show_dropdown: false
      }
    },
    computed: {
      dataChecked () {
        return this.filter_checked || this.placeholder
      },
      filteredList () {
        return this.options.filter(item => {
          const regex = `^.*${this.filter_item}.*$`
          if (this.filter_item !== '') {
            return new RegExp(regex, 'g').exec(item.text) !== null
          }

          return true
        })
      },
      generateId () {
        return Math.random().toString(36).substring(7)
      }
    },
    watch: {
      defaultValue () {
        this.changeSelected()
      },
      options () {
        this.changeSelected()
      }
    },
    mounted () {
      this.$nextTick(() => {
        window.addEventListener('click', this.clickOutsideDropDown)
      })
    },
    methods: {
      selectItem: function (item) {
        if (item) {
          this.filter_checked = item.text
          this.show_dropdown = false
          this.filter_item = ''
          this.$emit('change', item.value)
        } else {
          this.filter_checked = ''
          this.show_dropdown = false
          this.filter_item = ''
          this.$emit('change', '')
        }
      },
      clickOutsideDropDown: function (e) {
        const dropDown = document.getElementById(this.generateId)
        if (dropDown === null || typeof dropDown === 'undefined') {
          return
        }
        if (!dropDown.contains(e.target)) {
          this.show_dropdown = false
          this.filter_item = ''
        }
      },
      changeSelected: function () {
        if (this.defaultValue) {
          const defaultItem = this.options.filter(item => {
            return parseInt(this.defaultValue) === parseInt(item.value)
          })[0]

          this.filter_checked = defaultItem?.text || this.placeholder
        } else if (!this.defaultValue && this.filter_checked) {
          this.filter_checked = ''
        }
      }
    }
  }
</script>
