var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-action", {
    staticClass: "select-reason-for-non-contract",
    attrs: {
      title: _vm.$t("objects.patient.text.modal_reason_for_non_contract_title")
    },
    on: {
      close: function($event) {
        return _vm.setReasonForNonContractModalStatus(false)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "select-reason-for-non-contract__content" },
              _vm._l(_vm.reasons, function(data) {
                return _c("ListReasonBlock", {
                  key: data.id,
                  attrs: {
                    title: _vm.$t(data.title),
                    reasons: data.items,
                    "other-reason": _vm.other_reason,
                    "selected-id": _vm.selected_reason,
                    errors: _vm.errors
                  },
                  on: {
                    "choose-reason": _vm.chooseReason,
                    "input-other": _vm.inputOther
                  }
                })
              }),
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c("button-common", {
              staticClass: "select-reason-for-non-contract__btn-submit",
              on: { click: _vm.handleSubmit },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("new_text.button.decision")) +
                          "\n      "
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            }),
            _vm._v(" "),
            _c("button-common", {
              staticClass: "select-reason-for-non-contract__btn-close",
              on: {
                click: function($event) {
                  return _vm.setReasonForNonContractModalStatus(false)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("new_text.button.back")) +
                          "\n      "
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }