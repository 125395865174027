var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-action", {
    staticClass: "password-verify password-verify--delete",
    attrs: { title: _vm.formInfo.title },
    on: {
      close: function($event) {
        return _vm.$emit("close")
      }
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "password-verify__content" }, [
              _c("div", { staticClass: "password-verify__body" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.formInfo.description) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "password-verify__form" },
                [
                  _c("label", { staticClass: "password-verify__form__label" }, [
                    _vm._v(_vm._s(_vm.formInfo.label))
                  ]),
                  _vm._v(" "),
                  _c("input-common", {
                    class: [
                      "password-verify__form__input",
                      { "common-input-error": _vm.errors.password }
                    ],
                    attrs: {
                      type: "password",
                      placeholder: _vm.formInfo.placeholder
                    },
                    on: {
                      input: function($event) {
                        _vm.errors.password = ""
                      }
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.password
                    ? _c("p", { staticClass: "password-verify__form__error" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.errors.password) +
                            "\n        "
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c("button-common", {
              staticClass: "button-v2--confirm",
              attrs: { type: "submit" },
              on: { click: _vm.verifyPassword },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function() {
                    return [
                      _vm._v("\n        " + _vm._s(_vm.buttonText) + "\n      ")
                    ]
                  },
                  proxy: true
                }
              ])
            }),
            _vm._v(" "),
            _vm.cancelButton
              ? _c("button-common", {
                  staticClass: "button-v2--cancel",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("new_text.button.no")) +
                                "\n      "
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1618855105
                  )
                })
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }