<template>
  <div class="form-group sub-question">
    <p v-if="errors" class="error-massage">{{ errors }}</p>
    <strong><p v-if="question" v-html="question"></p></strong>
    <div class="radio-group h-align">
      <div :class="['check-container', 'ml-0']" v-for="item in options" @click="handleClick(item)">
        <span :class="['radio-button', handleClass(item)]"></span>
        <span class="label-text">{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "SubQuestion",
        props: [
            'options',
            'checked',
            'question',
            'filterOption'
        ],
        data () {
            return {
                selected_option: this.checked,
                errors: '',
            }
        },
        methods: {
            handleClick: function(value) {
                this.errors = '';
                this.selected_option = value;
                this.$emit('handleAnswer', this.selected_option);
            },
            handleClass: function(value) {
                return this.selected_option === value ? 'checked' : '';
            },
            isValid: function() {
                let options = this.options
                // filter special option brand type validate 
                if (this.filterOption) {
                    options = options.filter(option => option !== this.filterOption)
                }

                if (Object.values(options).includes(this.selected_option)) {
                    return true;
                }
                this.errors = this.$t('validation.interview.question');
                return false;
            }
        }
    }
</script>
