<template>
  <modal-action
    class="modify-duty"
    :title="$t('objects.setting.text.edit_tab')"
    :is-show-delete-button="true"
    @close="$emit('close')"
    @confirm="$emit('confirm', { type_name })"
    @delete="$emit('delete')"
  >
    <template #body>
      <div class="modify-duty__form">
        <div class="modify-duty__group">
          <label
            class="modify-duty__label"
            for="show-name"
          >{{ $t('new_text.showing_name') }}</label>
          <div class="modify-duty__content">
            <input
              id="show-name"
              v-model="type_name"
              :class="['modify-duty__input', { 'common-input-error': !type_name }]"
              type="text"
              :placeholder="$t('objects.setting.text.tab_name')"
            >
            <span
              v-if="!type_name"
              class="common-msg-error"
            >
              {{ $t('new_validation.common.required', { attribute: $t('new_text.showing_name') }) }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </modal-action>
</template>

<script>
  import ModalAction from '../../common/ModalAction.vue'

  export default {
    components: { ModalAction },
    props: {
      activeTabName: {
        type: String,
        default: ''
      }
    },
    emits: ['close', 'confirm', 'delete'],
    data () {
      return {
        type_name: ''
      }
    },
    created () {
      this.type_name = this.activeTabName
    }
  }
</script>
