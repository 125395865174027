const patient = {
  basic_information: 'The basic information is required',
  content_consultation: 'The content consultation is required',
  correct_birthday: 'Please select the correct date of birth',
  comment_required: 'Please enter a comment',
  empty_address: 'Please enter the address and the following address',
  reservation_end_must_be_less_than_365: 'Selectable period end date is up to 365 days later',
  first_name_required: 'Please enter your name',
  invalid_phone: 'Please enter a 10-11 digit number for the phone number.',
  invalid_profession: 'Please select a profession',
  invalid_profession_other: 'Please fill in other cases',
  kana_first_name_required: 'Please enter the furigana name',
  kana_last_name_required: 'Please enter the furigana surname',
  kana_required: 'Please enter furigana',
  last_name_required: 'Please enter your first and last name',
  other_consultation: 'The other consultation is required',
  main_symptom_required: 'Please enter main symptom (where do you care)',
  need_answer: 'Need an answer',
  no_patient_search: 'The searched patient was not found.',
  patient_name_invalid: 'No symbols can be used in the name',
  phone_number_invalid: 'Please enter a 10-11 digit number for the phone number.',
  phone_only_number: 'Please enter the phone number numerically',
  phone_only_single_number: 'Please enter only half-width numbers',
  please_enter_hiragana: 'Please enter only hiragana',
  prior_consultation: 'The prior consultation is required',
  reason_for_non_contract_required: 'Please enter the reason why a contract was not reached.',
  relationship_maxlength: 'Character limited',
  romaji_haffsize: 'Please enter half-width letters',
  romaji_required: 'Please enter your name (in Roman letters)',
  roma_first_name_required: 'Please enter your first name in romaji',
  roma_last_name_required: 'Please enter your last name in romaji',
  zipcode_greater_than_7: 'Please enter the postal code in 7-digit half-width numbers',
  zipcode_invalid: 'No applicable zip code was found'
}

export default patient
