var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-md-12 patient-detail-left patient-info-interview p-0" },
    [
      _c(
        "div",
        {
          staticClass: "print-component component-wrapper interview-checklist"
        },
        [
          _c("div", { staticClass: "bg-white" }, [
            Object.keys(_vm.answers).length !== 0
              ? _c(
                  "table",
                  { staticClass: "table table-interview-check-list" },
                  [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { attrs: { width: "40%" } }, [
                          _c("p", [
                            _vm._v(
                              "他の医院で歯並びの相談をされた事はありますか?"
                            )
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_1.is_answer_yes
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.answers.question_1.items.details,
                                  function(detail, index) {
                                    return _c("p", { key: index }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(detail.title) +
                                          ":" +
                                          _vm._s(detail.content) +
                                          "\n                "
                                      )
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            attrs: { width: "10%" }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.answers.question_1.answer) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("td", { attrs: { width: "40%" } }, [
                          _c("p", [
                            _vm._v("過去に矯正歯科治療された事はありますか?")
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_2.is_answer_yes
                            ? _c("div", [
                                _c("p", [
                                  _vm._v(
                                    "いつ頃 : " +
                                      _vm._s(
                                        _vm.answers.question_2.view_content_1
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "症状 : " +
                                      _vm._s(
                                        _vm.answers.question_2.view_content_2
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "矯正装置 : " +
                                      _vm._s(
                                        _vm.answers.question_2.view_content_3
                                      )
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            attrs: { width: "10%" }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.answers.question_2.answer) +
                                "\n            "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _c("p", [_vm._v(" アレルギーはありますか?")]),
                          _vm._v(" "),
                          _vm.answers.question_3.is_answer_yes
                            ? _c("div", [
                                _vm.answers.question_3.view_content_1
                                  ? _c("p", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.answers.question_3
                                              .view_content_1
                                          ) +
                                          "あり\n                "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "金属名:" +
                                      _vm._s(
                                        _vm.answers.question_3.view_content_2
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.answers.question_3.view_content_3
                                  ? _c("p", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.answers.question_3
                                              .view_content_3
                                          ) +
                                          "あり\n                "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "薬品名:" +
                                      _vm._s(
                                        _vm.answers.question_3.view_content_4
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.answers.question_3.view_content_5
                                  ? _c("p", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.answers.question_3
                                              .view_content_5
                                          ) +
                                          "あり\n                "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "発症時期:" +
                                      _vm._s(
                                        _vm.answers.question_3.view_content_6
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "原因植物:" +
                                      _vm._s(
                                        _vm.answers.question_3.view_content_7
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.answers.question_3.view_content_8
                                  ? _c("p", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.answers.question_3
                                              .view_content_8
                                          ) +
                                          "あり\n                "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "原因物質:" +
                                      _vm._s(
                                        _vm.answers.question_3.view_content_9
                                      )
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_3.answer) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", [
                            _vm._v(
                              "顎の症状で、今まで経験したものはありますか?"
                            )
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_4.is_answer_yes
                            ? _c("div", [
                                _c("p", [
                                  _vm._v(
                                    "いつ頃: " +
                                      _vm._s(
                                        _vm.answers.question_4.view_content_1
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.answers.question_4.view_content_2
                                    )
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_4.answer) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _c("p", [
                            _vm._v(
                              "今まで顔や顎を強くぶつけた・ケガをした経験はあり ますか?"
                            )
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_5.is_answer_yes
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.answers.question_5.items.details,
                                  function(detail, index) {
                                    return _c("p", { key: index }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(detail.title) +
                                          ":" +
                                          _vm._s(detail.content) +
                                          "\n                "
                                      )
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_5.answer) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", [
                            _vm._v("今までに口や顔の周りの癖はありましたか?")
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_6.is_answer_yes
                            ? _c("p", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.answers.question_6.view_content_2
                                    ) +
                                    "\n              "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_6.answer) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _c("p", [
                            _vm._v(
                              "喋りにくい言葉はありますか?(サ行、タ行が言いづらいなど)"
                            )
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_7.is_answer_yes
                            ? _c("p", [
                                _vm._v(
                                  "\n                具体的に： " +
                                    _vm._s(
                                      _vm.answers.question_7.view_content
                                    ) +
                                    "\n              "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_7.answer) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", [_vm._v("次のような症状はありますか?")]),
                          _vm._v(" "),
                          _vm.answers.question_8.is_answer_yes
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.converOptions(
                                      _vm.answers.question_8.view_content
                                    )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_8.answer) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _c("p", [_vm._v("寝相はどの状態が多いですか?")]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(_vm._s(_vm.answers.question_9.view_content))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.answers.question_9.view_content === ""
                                  ? "未入力"
                                  : ""
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", [
                            _vm._v("家族に不正咬合の方はいらっしゃいますか?")
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_10.is_answer_yes
                            ? _c(
                                "div",
                                _vm._l(_vm.answers.question_10.items, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "d-flex" },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(index + 1) + ".")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        _vm._l(item.details, function(
                                          detail,
                                          index
                                        ) {
                                          return _c("p", { key: index }, [
                                            _vm._v(
                                              _vm._s(detail.title) +
                                                ":" +
                                                _vm._s(
                                                  _vm.converOptions(detail)
                                                )
                                            )
                                          ])
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_10.answer) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _c("p", [
                            _vm._v("今まで耳鼻科を受診したことはありますか?")
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_11.is_answer_yes
                            ? _c(
                                "div",
                                _vm._l(_vm.answers.question_11.items, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "d-flex" },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(index + 1) + ".")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        _vm._l(item.details, function(
                                          detail,
                                          index
                                        ) {
                                          return _c("p", { key: index }, [
                                            _vm._v(
                                              _vm._s(detail.title) +
                                                ":" +
                                                _vm._s(
                                                  _vm.converOptions(detail)
                                                )
                                            )
                                          ])
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_11.answer) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", [_vm._v("現在、通院されていますか?")]),
                          _vm._v(" "),
                          _vm.answers.question_12.is_answer_yes
                            ? _c(
                                "div",
                                _vm._l(_vm.answers.question_12.items, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "d-flex" },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(index + 1) + ".")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        _vm._l(item.details, function(
                                          detail,
                                          index
                                        ) {
                                          return _c("p", { key: index }, [
                                            _vm._v(
                                              _vm._s(detail.title) +
                                                ":" +
                                                _vm._s(
                                                  _vm.converOptions(detail)
                                                )
                                            )
                                          ])
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_12.answer) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _c("p", [
                            _vm._v(
                              "身体全体で先天性の疾患や生まれつき気になる事、部位はありますか?"
                            )
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_13.is_answer_yes
                            ? _c(
                                "div",
                                _vm._l(_vm.answers.question_13.items, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "d-flex" },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(index + 1) + ".")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        _vm._l(item.details, function(
                                          detail,
                                          index
                                        ) {
                                          return _c("p", { key: index }, [
                                            _vm._v(
                                              _vm._s(detail.title) +
                                                ":" +
                                                _vm._s(
                                                  _vm.converOptions(detail)
                                                )
                                            )
                                          ])
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_13.answer) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", [
                            _vm._v("今までにかかった病気はありますか?")
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_14.is_answer_yes
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.converOptions(
                                      _vm.answers.question_14.view_content
                                    )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _c("p", [
                            _vm._v(_vm._s(_vm.answers.question_14.answer))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _c("p", [
                            _vm._v("今までに次のようなことがありましたか?")
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_15.is_answer_yes
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(_vm.answers.question_15.view_content)
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_15.answer) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", [_vm._v("現在飲んでいる薬はありますか?")]),
                          _vm._v(" "),
                          _c("div", [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.answers.question_16.view_content)
                              )
                            ]),
                            _vm._v(" "),
                            _vm.answers.question_16.is_answer_yes
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.answers.question_16.custom_items,
                                    function(item, index) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "d-flex" },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(index + 1) + ".")
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            _vm._l(item, function(detail, i) {
                                              return _c("p", { key: i }, [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(detail.title) +
                                                    ":" +
                                                    _vm._s(
                                                      _vm.converOptions(detail)
                                                    ) +
                                                    "\n                      "
                                                )
                                              ])
                                            }),
                                            0
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e()
                          ])
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_16.answer) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _c("p", [_vm._v("喫煙習慣はありますか?")]),
                          _vm._v(" "),
                          _vm.answers.question_17.is_answer_yes
                            ? _c("p", [
                                _vm._v(
                                  "\n                一日量: " +
                                    _vm._s(
                                      _vm.converOptions(
                                        _vm.answers.question_17.items.details[0]
                                      )
                                    ) +
                                    "本/日\n              "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_17.answer) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", [_vm._v("飲酒習慣はありますか?")]),
                          _vm._v(" "),
                          _vm.answers.question_18.is_answer_yes
                            ? _c("div", [
                                _c("p", [
                                  _vm._v(
                                    "よく飲まれるお酒:" +
                                      _vm._s(
                                        _vm.answers.question_18.view_content_1
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "一日量:" +
                                      _vm._s(
                                        _vm.answers.question_18.view_content_2
                                      ) +
                                      "mlまたは " +
                                      _vm._s(
                                        _vm.answers.question_18.view_content_3
                                      ) +
                                      "合くらい"
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_18.answer) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _c("p", [
                            _vm._v("身長: "),
                            _c("span", { staticClass: "mr-4" }, [
                              _vm._v(
                                _vm._s(_vm.answers.question_19.view_content_1)
                              )
                            ]),
                            _vm._v(" cm")
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("体重:"),
                            _c("span", { staticClass: "mr-4" }, [
                              _vm._v(
                                _vm._s(_vm.answers.question_19.view_content_2)
                              )
                            ]),
                            _vm._v(" kg")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                !(
                                  _vm.answers.question_19.view_content_1 ||
                                  _vm.answers.question_19.view_content_2
                                )
                                  ? _vm.INTERVIEW_OPTIONS_NOT_ENTERED
                                  : ""
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", [_vm._v("血液型")]),
                          _vm._v(" "),
                          _c("p", { staticClass: "ml-4" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.answers.question_20.view_content ===
                                    _vm.INTERVIEW_OPTIONS_NOT_ENTERED
                                    ? ""
                                    : _vm.answers.question_20.view_content
                                ) +
                                "\n              "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.answers.question_20.view_content ===
                                  _vm.INTERVIEW_OPTIONS_NOT_ENTERED
                                  ? _vm.INTERVIEW_OPTIONS_NOT_ENTERED
                                  : ""
                              ) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _c("p", [_vm._v("血圧の状態は?")]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.answers.question_21.view_content_1 ===
                                  _vm.INTERVIEW_OPTIONS_NOT_ENTERED
                                  ? ""
                                  : _vm.answers.question_21.view_content_1
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-flex" }, [
                            _c("p", [
                              _vm._v("最高: "),
                              _c("span", { staticClass: "ml-3" }, [
                                _vm._v(
                                  _vm._s(_vm.answers.question_21.view_content_2)
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "pl-4" }, [
                              _vm._v("\n                  最低:"),
                              _c("span", { staticClass: "ml-3" }, [
                                _vm._v(
                                  _vm._s(_vm.answers.question_21.view_content_3)
                                )
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.answers.question_21.view_content_1 ===
                                  _vm.INTERVIEW_OPTIONS_NOT_ENTERED
                                  ? _vm.INTERVIEW_OPTIONS_NOT_ENTERED
                                  : ""
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", [_vm._v("女性の方のみ")]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("初潮年齢 "),
                            _c("span", { staticClass: "ml-3" }, [
                              _vm._v(
                                _vm._s(_vm.answers.question_22.view_content_1)
                              )
                            ]),
                            _vm._v("歳 "),
                            _c("span", { staticClass: "ml-3" }, [
                              _vm._v(
                                _vm._s(_vm.answers.question_22.view_content_2)
                              )
                            ]),
                            _vm._v("ヶ月")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                !(
                                  _vm.answers.question_22.view_content_1 ||
                                  _vm.answers.question_22.view_content_2
                                )
                                  ? _vm.INTERVIEW_OPTIONS_NOT_ENTERED
                                  : ""
                              ) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _c("p", [
                            _vm._v(
                              "以前に歯科医院で歯を抜いたことがありますか?"
                            )
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_23.is_answer_yes
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(_vm.answers.question_23.view_content)
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_23.answer) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", [
                            _vm._v("最近、歯科医院を受診されましたか?")
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_24.is_answer_yes
                            ? _c("div", [
                                _c("p", [
                                  _c("span", { staticClass: "mx-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.answers.question_24.view_content_1
                                      )
                                    )
                                  ]),
                                  _vm._v(" 年 "),
                                  _c("span", { staticClass: "mx-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.answers.question_24.view_content_2
                                      )
                                    )
                                  ]),
                                  _vm._v(" 月ごろ")
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v("または "),
                                  _c("span", { staticClass: "mx-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.answers.question_24.view_content_3
                                      )
                                    )
                                  ]),
                                  _vm._v(" 年 "),
                                  _c("span", { staticClass: "mx-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.answers.question_24.view_content_4
                                      )
                                    )
                                  ]),
                                  _vm._v(" ヶ月前")
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "治療内容:" +
                                      _vm._s(
                                        _vm.answers.question_24.view_content_5
                                      )
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.answers.question_24.answer) +
                              "\n            "
                          )
                        ])
                      ])
                    ])
                  ]
                )
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }