<template>
  <div class="modal-patient-detail">
    <modal-action
      :title="$t('patient_page.patient_info')"
      @close="$emit('close')"
    >
      <template #body>
        <div class="modal-patient-detail__body">
          <section class="content">
            <div class="content-wrapper">
              <div class="row">
                <div class="col-md-6">
                  <div class="content-box">
                    <div class="content-inner bg-white">
                      <div class="row-content">
                        <div class="heading-two">
                          <h2>{{ $t('patient_page.basic_info') }}</h2>
                        </div>
                        <div class="row-content">
                          <div
                            class="row"
                          >
                            <div class="col-6  m-0 p-0">
                              <div class="form-group">
                                <label class="control-label">{{ $t('new_text.patient_id') }}</label>
                                <div
                                  v-if="sf_user_id"
                                  class="control-text"
                                >
                                  {{ sf_user_id }}
                                </div>
                                <span
                                  v-else
                                  class="control-text control-text--patient-id"
                                >
                                  {{ $t('objects.patient.text.syncing_to_salesforce') }}
                                </span>
                              </div>
                              <div class="form-group">
                                <label class="control-label">{{ $t('patient_page.romaji') }}</label>
                                <div class="control-text control-text--patient-name">
                                  {{ roma_full_name }}
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label">{{ $t('patient_page.sex') }}</label>
                                <div class="control-text">
                                  {{ gender }}
                                </div>
                              </div>
                            </div>
                            <div class="col-6  m-0 p-0">
                              <div class="form-group">
                                <label class="control-label">{{ $t('common.name') }}</label>
                                <div class="control-text control-text--patient-name">
                                  {{ full_name }}
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label">{{ $t('patient_page.furigana')
                                }}</label>
                                <div class="control-text control-text--patient-name">
                                  {{ kana_full_name }}
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label">{{ $t('patient_page.birthday')
                                }}</label>
                                <div class="control-text">
                                  {{ birthday }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row-content">
                        <div class="heading-two">
                          <h2>{{ $t('patient_page.contact_info') }}</h2>
                        </div>
                        <div class="row-content">
                          <div class="col-12  m-0 p-0">
                            <div class="form-group">
                              <label class="control-label same-width">{{ $t('patient_page.address')
                              }}</label>
                              <div class="control-text">
                                {{ address }}
                              </div>
                            </div>
                            <div class="form-group">
                              <label class="control-label same-width">{{ $t('main.phone_number')
                              }}</label>
                              <div class="control-text">
                                {{ tel }}
                              </div>
                            </div>
                            <div class="form-group">
                              <label class="control-label same-width">{{ $t('patient_page.email') }}</label>
                              <div class="control-text">
                                {{ email }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="content-box">
                    <div class="content-inner bg-white">
                      <div class="row-content">
                        <div class="col-12  m-0 p-0">
                          <div class="form-group">
                            <label class="control-label same-width modal-patient-detail__body__label">{{ $t('patient_page.patient_status') }}</label>
                            <span class="control-text">
                              {{ sf_status_c }}
                            </span>
                          </div>
                          <div class="form-group">
                            <label class="control-label same-width modal-patient-detail__body__label">{{ $t('patient_page.created_at') }}</label>
                            <span class="control-text">{{ created_at }}</span>
                          </div>
                          <div class="form-group">
                            <label class="control-label same-width modal-patient-detail__body__label">{{ $t('patient_page.updated_at') }}</label>
                            <span class="control-text">{{ updated_at }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row-content">
                        <div class="heading-two">
                          <h2>{{ $t('patient_page.assignee_info') }}</h2>
                        </div>
                        <div class="row-content">
                          <div class="col-12  m-0 p-0">
                            <div
                              v-for="(item,index) in person_in_charges"
                              :key="index"
                              class="form-group multi-row"
                            >
                              <label class="control-label same-width modal-patient-detail__body__label">
                                {{ index }}
                              </label>
                              <div class="multi-row__right">
                                <p
                                  v-for="staff in item"
                                  :key="staff.id"
                                  class="control-text"
                                >
                                  {{ staff.name }}{{ staff.deleted_at ? `(${($t('staff.retiree'))})` : '' }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row-content">
                        <div class="heading-two">
                          <h2>{{ $t('patient_page.other_notes') }}</h2>
                          <div class="form-group">
                            <span class="control-text block-ellipsis">{{ note }}</span>
                          </div>
                        </div>
                        <div
                          v-if="$store.getters.currentClinic.is_dmr || 0"
                          class="row-content"
                        >
                          <div class="col-12  m-0 p-0">
                            <div class="form-group">
                              <label class="control-schedule modal-patient-detail__body__label">
                                {{ $t('objects.patient.text.service_management') }}
                              </label>
                              <table
                                v-if="service_summary.length"
                                class="table table-striped table-medusa table-detail"
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      {{ $t('accounting_page.service_category') }}
                                    </th>
                                    <th class="text-left">
                                      {{ $t('accounting_page.overall_service') }}
                                    </th>
                                    <th class="text-left">
                                      {{ $t('accounting_page.number_remaining') }}
                                    </th>
                                    <th class="text-left">
                                      {{ $t('main.remaining_service_amount') }}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(item,index) in service_summary"
                                    :key="index"
                                  >
                                    <td>
                                      {{ item.cat_name }} ({{ item.name }})
                                    </td>
                                    <td class="text-left">
                                      {{ getIntVal(item.total_count) }}
                                    </td>
                                    <td class="text-left">
                                      {{ getIntVal(item.remain_count) }}
                                    </td>
                                    <td class="text-left">
                                      {{ getIntVal(item.remain_amount) }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </template>
      <div
        slot="footer"
        class="modal-v2__footer--content"
      >
        <router-link
          :to="{ name: 'patient.orders', params: { id: patient_id } }"
          target="_blank"
        >
          <button
            class="modal-v2__button modal-v2__button--cancel"
            @click="$emit('close')"
          >
            {{ $t('objects.patient.text.patient_technician_order') }}
          </button>
        </router-link>
        <router-link
          :to="{
            name: 'patient.detail',
            params: { id: patient_id },
            query: { reservation_id: reservation_id, tab_active: 'reservation_schedule' }
          }"
          target="_blank"
        >
          <button
            class="modal-v2__button modal-v2__button--confirm"
            @click="$emit('close')"
          >
            {{ $t('text.patient_details') }}
          </button>
        </router-link>
      </div>
    </modal-action>
  </div>
</template>

<script>
  import { formatDateTime, intVal } from '../../utils/helpers'
  import ModalAction from '../common/ModalAction'

  export default {
    components: {
      ModalAction
    },
    props: {
      dataPatient: {
        type: Object,
        default: () => {}
      }
    },
    emits: ['close'],
    data () {
      return {
        gender: '',
        birthday: '',
        sf_status_c: ' ',
        service_summary: [],
        person_in_charges: [],
        created_at: '',
        updated_at: '',
        roma_full_name: '',
        kana_full_name: '',
        patient_id: '',
        sf_user_id: '',
        reservation_id: '',
        patient_detail: '',
        note: '',
        full_name: '',
        address: '',
        tel: '',
        email: ''
      }
    },
    created () {
      this.getDetail()
    },
    methods: {
      getDetail: function () {
        this.patient_detail = this.dataPatient.extendedProps
        this.patient_id = this.patient_detail.patient_id
        this.sf_user_id = this.patient_detail.sf_user_id
        this.reservation_id = this.dataPatient.id
        this.gender = this.patient_detail.patient_info.gender === 1 ? this.$t('patient.modal.gender_options.female') : (this.patient_detail.patient_info.gender === 2 ? this.$t('patient.modal.gender_options.male') : '')
        this.birthday = formatDateTime(this.patient_detail.patient_info.birth_of_date, this.$t('datetime.year_month_day'))
        this.$t('patient.sf_status_c').forEach(item => {
          if (item.key === this.patient_detail.patient.sf_status_c) {
            this.sf_status_c = item.value
          }
        })
        this.service_summary = this.patient_detail.service_summary
        this.person_in_charges = this.patient_detail.customPerson
        this.created_at = formatDateTime(this.patient_detail.patient.created_at, this.$t('datetime.year_month_day_hour_minute'))
        this.updated_at = formatDateTime(this.patient_detail.patient.updated_at, this.$t('datetime.year_month_day_hour_minute'))
        this.roma_full_name = `${this.patient_detail.patient_info.roma_last_name} ${this.patient_detail.patient_info.roma_first_name}`
        this.kana_full_name = `${this.patient_detail.patient_info.kana_last_name} ${this.patient_detail.patient_info.kana_first_name}`
        this.note = this.patient_detail.patient_info.note
        this.full_name = !this.patient_detail.patient_info.first_name || !this.patient_detail.patient_info.last_name
          ? this.patient_detail.patient_info.full_name
          : `${this.patient_detail.patient_info.last_name} ${this.patient_detail.patient_info.first_name}`
        this.address = this.patient_detail.patient_info.address
        this.tel = this.patient_detail.patient_info.tel
        this.email = this.patient_detail.patient_info.email
      },
      getIntVal: function (item) {
        return intVal(item)
      }
    }
  }
</script>
