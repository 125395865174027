import { INTERVIEW_COMMON_OPTIONS, INTERVIEW_OPTIONS_NOT_ENTERED, KIDS_BRAND_TYPE } from '../../../../../utils/constants'
const QUESTION_NOT_REQUIRE = [9, 19, 20, 21, 22]
const DEFAULT_BY_BRAND_TYPE = [11, 17, 18]

export default {
  props: {
    className: {
      type: String,
      default: ''
    },
    patientInfo: {
      type: Object,
      default: () => {}
    }
  },
  created () {
    // Load state to data
    this.loadStateToData()
  },
  data () {
    return {
      errors: {
        require: ''
      }
    }
  },
  methods: {
    loadStateToData: async function () {
      const dataState = this.$store.getters.get_question(this.number)
      // answer not require
      if (!QUESTION_NOT_REQUIRE.includes(this.number)) {
        this.select_option = dataState.answer
      }

      dataState.items.details.forEach(objDetail => {
        for (const jaName in this.translate) {
          if (objDetail.title === jaName) {
            const enName = this.translate[jaName]
            this[enName] = objDetail.content || ''
          }
        }
      })
      // set default selection
      if (DEFAULT_BY_BRAND_TYPE.includes(this.number)) {
        this.select_option = dataState.answer
        if (this.select_option) {
          this.is_selected = true
        }
        if (this.isKid && !dataState.answer && !this.patientInfo.invalidAge) {
          this.select_option = INTERVIEW_OPTIONS_NOT_ENTERED
        }
      }
    },
    formatResult: function () {
      const data = this.$store.getters.get_default_answer_items(this.number)

      // special selection
      if (this.number === 21 && this.select_option === INTERVIEW_OPTIONS_NOT_ENTERED) {
        this.time = ''
        this.name = ''
      }

      // save answer if require
      if (!QUESTION_NOT_REQUIRE.includes(this.number)) {
        data.answer = this.select_option
      }

      // only save answer if YES
      // keep data on view
      if (data.answer === INTERVIEW_COMMON_OPTIONS.YES || QUESTION_NOT_REQUIRE.includes(this.number)) {
        data.items.details.forEach(objDetail => {
          for (const jaName in this.translate) {
            if (objDetail.title === jaName) {
              const enName = this.translate[jaName]
              objDetail.content = this[enName]
            }
          }
        })
      }
      return data
    },
    handleAnswer: function (value) {
      this.select_option = value
      if (DEFAULT_BY_BRAND_TYPE.includes(this.number)) {
        this.is_selected = false
      }
    },
    isValid: function () {
      if (Object.values(INTERVIEW_COMMON_OPTIONS).includes(this.select_option)) {
        return true
      }
      this.errors = this.$t('new_validation.patient_interview.need_an_answer')
      return false
    },
    selectOption (value) {
      if (!value.includes('その他')) {
        this.other = ''
      }
    }
  },
  computed: {
    handleChildAnswerClass: function () {
      return {
        'disable-child-answer': this.select_option === INTERVIEW_COMMON_OPTIONS.NO
      }
    },
    disableOtherInput: function () {
      return {
        'disable-other-answer': !this.selections.includes('その他')
      }
    },
    isKid: function () {
      return this.patientInfo.brand_type === KIDS_BRAND_TYPE
    }
  }
}
