var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "interview-v2" },
    [
      _c("div", { staticClass: "content-wrapper content-wrapper--interview" }, [
        _c(
          "div",
          { class: { "lazy-load lazy-load--big-space": _vm.lazy_load } },
          [
            !_vm.lazy_load
              ? _c(
                  "div",
                  {
                    staticClass: "patient-detail-left",
                    attrs: { id: "patient-detail-print-area" }
                  },
                  [
                    _vm.is_completed
                      ? _c("InterviewConfirmation", {
                          attrs: { "patient-info": _vm.patient_info },
                          on: {
                            "print-interview-sheet": _vm.printInterviewSheet
                          }
                        })
                      : _c("InterviewInput", {
                          ref: "interview_input",
                          attrs: {
                            "patient-info": _vm.patient_info,
                            "interview-info": _vm.interview_info
                          },
                          on: {
                            "show-confirm-save-modal": function($event) {
                              _vm.show_confirm_save_modal = true
                            }
                          }
                        })
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      !_vm.lazy_load && _vm.is_completed
        ? _c("iframe", {
            attrs: { src: _vm.printInterviewSheetUrl, name: "printInterview" },
            on: { load: _vm.onLoadInterviewSheetCompletedIframe }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("Loading"),
      _vm._v(" "),
      _vm.show_confirm_save_modal
        ? _c("modal-confirm", {
            attrs: {
              title: _vm.$t("objects.patient.text.modal_confirm_save.title"),
              text: _vm.$t("objects.patient.text.modal_confirm_save.text")
            },
            on: {
              no: function($event) {
                _vm.show_confirm_save_modal = false
              },
              yes: function($event) {
                _vm.show_confirm_save_modal = false
                _vm.$refs.interview_input.save()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }