import * as moment from 'moment'
import Swal from 'sweetalert2'
import { STAFF_POSITION } from './constants'

export const safeGet = (object, keys) => {
  return keys.split('.').reduce((xs, x) => (xs && xs[x]) ? xs[x] : undefined, object) || ''
}

export const mbtrim = (string) => {
  return (string ?? '').replace(/(^\s+|\s+$)/g, '')
}

export const mbtrimAll = (string) => {
  const str = (string ?? '').replace(/\s/g, '').replace(/^[,]+|[,]+$|[,]+/g, ',').trim()
  return str.replace(/^,|,$/g, '')
}

export const showErrorModal = (message) => {
  Swal.fire({
    toast: true,
    customClass: 'toast-error icon-horizontal-center',
    position: 'top',
    title: `<div class="d-flex"><div class="error-icon h-auto"></div><div class="swal-title-text">${message}</div></div>`,
    showConfirmButton: false,
    timer: 3000,
    onBeforeOpen: () => {
      document.getElementsByClassName('swal2-top')[0].style.top = '85px'
    }
  })
}

export const showSuccessModal = (message) => {
  Swal.fire({
    toast: true,
    customClass: 'toast-success',
    position: 'top',
    title: `<div class="d-flex"><div class="success-icon"></div><div class="swal-title-text">${message}</div></div>`,
    showConfirmButton: false,
    timer: 3000,
    onBeforeOpen: () => {
      document.getElementsByClassName('swal2-top')[0].style.top = '85px'
    }
  })
}

export const bytesToMegaBytes = bytes => (bytes / (1024 * 1024)).toFixed(2)

export function showWarningModal (message, option = {}) {
  Swal.fire({
    ...{
      toast: true,
      customClass: 'toast-warning',
      position: 'top',
      title: `<div class="warning-icon"></div><div class="swal-title-text">${message}</div>`,
      showConfirmButton: false,
      timer: 3000,
      onBeforeOpen: () => {
        document.getElementsByClassName('swal2-top')[0].style.top = '85px'
      }
    },
    ...option
  })
}

export function formatDateTime (dateString, formatString) {
  if (!dateString || !formatString) return ''
  const date = moment(dateString)
  return date.isValid() ? date.format(formatString) : ''
}

export function numberWithCommas (number) {
  try {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } catch (e) {
    return ''
  }
}

export function intVal (val) {
  if (!val || isNaN(val)) {
    return 0
  }
  return parseInt(val)
}

export function checkSpecialCharacter (string) {
  const format = /[`!#$%^&*()=[\]{};':"\\|,<>/?~]/

  return format.test(string)
}

export function showStaffPosition (staffPosition) {
  let position
  switch (staffPosition) {
    case '歯科医師':
      position = STAFF_POSITION.DOCTOR
      break
    case 'コーディネーター':
      position = STAFF_POSITION.CONSELOR
      break
    case '歯科衛生士':
      position = STAFF_POSITION.DENTAL_HYGIENIST
      break
    default:
      position = STAFF_POSITION.DENTAL_ASSISTANT
      break
  }
  return position
}

export function formatCurrency (val) {
  return new Intl.NumberFormat('ja-JP').format(val)
}

export function openNewTab (url) {
  return new Promise(function (resolve) {
    resolve(url)
  })
}

export function isAllAttrInObjEmpty (obj) {
  return Object.values(obj).every(value => value === null || value === '')
}

export function getExtension (fullName) {
  return fullName ? fullName.split('.').pop().toLowerCase() : ''
}
