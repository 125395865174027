var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-group date select-date-new", attrs: { id: _vm.id } },
    [
      _c("input", {
        staticClass: "form-control",
        attrs: { readonly: _vm.isReadOnly },
        on: { input: _vm.setDate }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "input-group-addon" }, [
        _c("span", { staticClass: "glyphicon glyphicon-calendar" }, [
          _c("img", { attrs: { src: _vm.icon } })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }