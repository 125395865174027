<template>
  <ul
    class="pagination-common"
  >
    <li :class="['page-item', currentPage > 1 ? 'prev-icon-active' : 'prev-icon-inactive']">
      <span
        class="page-link"
        @click="clickPrevPage"
      />
    </li>
    <li
      v-for="(item, index) in pagesNumber"
      :key="index"
      :class="[ item.value === currentPage ? 'page-item active' : 'page-item']"
    >
      <span
        v-if="item.type === 'space'"
        class="page-link page-link--no-action"
      >
        {{ item.value }}
      </span>
      <span
        v-else
        class="page-link"
        @click="switchPage(item.value)"
      >{{ item.value }}</span>
    </li>
    <li :class="['page-item', currentPage < lastPage ? 'next-icon-active' : 'next-icon-inactive']">
      <span
        class="page-link"
        @click="clickNextPage"
      />
    </li>
  </ul>
</template>
<script>
  import { LAYOUT_MODE, SCREEN_WIDTH_SMALL } from '../../utils/constants'

  export default {
    props: {
      currentPage: {
        type: Number,
        default: 1
      },
      lastPage: {
        type: Number,
        default: 1
      },
      offset: {
        type: Number,
        default: 2
      }
    },
    emits: ['change-page'],
    computed: {
      isSmartPhone () {
        const currentLayoutMode = parseInt(this.$store.state.auth.layout_mode)

        return currentLayoutMode === LAYOUT_MODE.SP || (!currentLayoutMode && window.innerWidth <= SCREEN_WIDTH_SMALL)
      },
      numberButtonDisplayed () {
        return this.isSmartPhone ? 4 : 7
      },
      pagesNumber: function () {
        const pagesArray = []
        let from = 1
        if (this.lastPage <= (this.isSmartPhone ? 7 : 10)) {
          while (from <= this.lastPage) {
            pagesArray.push({
              type: 'page',
              value: from
            })
            from++
          }
          return pagesArray
        }
        if (this.currentPage < this.numberButtonDisplayed) {
          while (from <= this.numberButtonDisplayed) {
            pagesArray.push({
              type: 'page',
              value: from
            })
            from++
          }
          pagesArray.push({
            type: 'space',
            value: '...'
          })
          pagesArray.push({
            type: 'page',
            value: this.lastPage - 1
          })
          pagesArray.push({
            type: 'page',
            value: this.lastPage
          })
          return pagesArray
        }

        pagesArray.push({
          type: 'page',
          value: 1
        })
        if (!this.isSmartPhone) {
          pagesArray.push({
            type: 'page',
            value: 2
          })
        }
        pagesArray.push({
          type: 'space',
          value: '...'
        })
        if (this.lastPage - this.currentPage >= this.numberButtonDisplayed - 1) {
          from = this.currentPage - 1
          while (from <= (this.isSmartPhone ? this.currentPage + 1 : this.currentPage + 2)) {
            pagesArray.push({
              type: 'page',
              value: from
            })
            from++
          }
          pagesArray.push({
            type: 'space',
            value: '...'
          })
          if (!this.isSmartPhone) {
            pagesArray.push({
              type: 'page',
              value: this.lastPage - 1
            })
          }
          pagesArray.push({
            type: 'page',
            value: this.lastPage
          })
          return pagesArray
        }
        from = this.lastPage - (this.numberButtonDisplayed - (this.isSmartPhone ? 0 : 1))
        while (from <= this.lastPage) {
          pagesArray.push({
            type: 'page',
            value: from
          })
          from++
        }

        return pagesArray
      }
    },
    methods: {
      clickPrevPage: function () {
        if (this.currentPage - 1 > 0) {
          this.$emit('change-page', this.currentPage - 1)
        }
      },
      clickNextPage: function () {
        if (this.currentPage + 1 <= this.lastPage) {
          this.$emit('change-page', this.currentPage + 1)
        }
      },
      switchPage: function (page) {
        if (this.currentPage !== page && page !== '...') {
          this.$emit('change-page', page)
        }
      }
    }
  }
</script>
