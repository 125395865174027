<template>
    <div :class="[className]">
        <div class="form-group">
            <label><b>女性の方のみ</b></label>
            <div class="form-inline justify-content-between pt-3">
                <span>初潮年齢：</span>
                <input v-model="name1" class="form-control col-md-4" type="text">
                <span>歳</span>
                <input v-model="name2" class="form-control col-md-4" type="text">
                <span>ヶ月</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { INTERVIEW_COMMON_OPTIONS } from '../../../../utils/constants'
    import Common from "./CommonFunction";

    export default {
        props: ['className'],
        mixins: [Common],
        data() {
            return {
                number: 22,
                question: '現在の結構状態はいかがですか？',
                name1: '',
                name2: '',
                translate: {
                    初潮年齢1: 'name1',
                    初潮年齢2: 'name2'
                },
            }
        }
    }
</script>
