<template>
  <div class="col-md-12 patient-detail-left patient-info-interview p-0">
    <div class="print-component component-wrapper interview-checklist">
      <div class="bg-white">
        <table
          v-if="Object.keys(answers).length !== 0"
          class="table table-interview-check-list"
        >
          <tbody>
            <tr>
              <td width="40%">
                <p>他の医院で歯並びの相談をされた事はありますか?</p>
                <div v-if="answers.question_1.is_answer_yes">
                  <p
                    v-for="(detail, index) in answers.question_1.items.details"
                    :key="index"
                  >
                    {{ detail.title }}:{{ detail.content }}
                  </p>
                </div>
              </td>
              <td
                width="10%"
                class="text-center"
              >
                {{ answers.question_1.answer }}
              </td>
              <td width="40%">
                <p>過去に矯正歯科治療された事はありますか?</p>
                <div v-if="answers.question_2.is_answer_yes">
                  <p>いつ頃 : {{ answers.question_2.view_content_1 }}</p>
                  <p>症状 : {{ answers.question_2.view_content_2 }}</p>
                  <p>矯正装置 : {{ answers.question_2.view_content_3 }}</p>
                </div>
              </td>
              <td
                width="10%"
                class="text-center"
              >
                {{ answers.question_2.answer }}
              </td>
            </tr>
            <tr>
              <td>
                <p> アレルギーはありますか?</p>
                <div v-if="answers.question_3.is_answer_yes">
                  <p v-if="answers.question_3.view_content_1">
                    {{ answers.question_3.view_content_1 }}あり
                  </p>
                  <p>金属名:{{ answers.question_3.view_content_2 }}</p>
                  <p v-if="answers.question_3.view_content_3">
                    {{ answers.question_3.view_content_3 }}あり
                  </p>
                  <p>薬品名:{{ answers.question_3.view_content_4 }}</p>
                  <p v-if="answers.question_3.view_content_5">
                    {{ answers.question_3.view_content_5 }}あり
                  </p>
                  <p>発症時期:{{ answers.question_3.view_content_6 }}</p>
                  <p>原因植物:{{ answers.question_3.view_content_7 }}</p>
                  <p v-if="answers.question_3.view_content_8">
                    {{ answers.question_3.view_content_8 }}あり
                  </p>
                  <p>原因物質:{{ answers.question_3.view_content_9 }}</p>
                </div>
              </td>
              <td class="text-center">
                {{ answers.question_3.answer }}
              </td>
              <td>
                <p>顎の症状で、今まで経験したものはありますか?</p>
                <div v-if="answers.question_4.is_answer_yes">
                  <p>いつ頃: {{ answers.question_4.view_content_1 }}</p>
                  <p>{{ answers.question_4.view_content_2 }}</p>
                </div>
              </td>
              <td class="text-center">
                {{ answers.question_4.answer }}
              </td>
            </tr>
            <tr>
              <td>
                <p>今まで顔や顎を強くぶつけた・ケガをした経験はあり ますか?</p>
                <div v-if="answers.question_5.is_answer_yes">
                  <p
                    v-for="(detail, index) in answers.question_5.items.details"
                    :key="index"
                  >
                    {{ detail.title }}:{{ detail.content }}
                  </p>
                </div>
              </td>
              <td class="text-center">
                {{ answers.question_5.answer }}
              </td>
              <td>
                <p>今までに口や顔の周りの癖はありましたか?</p>
                <p v-if="answers.question_6.is_answer_yes">
                  {{ answers.question_6.view_content_2 }}
                </p>
              </td>
              <td class="text-center">
                {{ answers.question_6.answer }}
              </td>
            </tr>
            <tr>
              <td>
                <p>喋りにくい言葉はありますか?(サ行、タ行が言いづらいなど)</p>
                <p v-if="answers.question_7.is_answer_yes">
                  具体的に： {{ answers.question_7.view_content }}
                </p>
              </td>
              <td class="text-center">
                {{ answers.question_7.answer }}
              </td>
              <td>
                <p>次のような症状はありますか?</p>
                <p v-if="answers.question_8.is_answer_yes">{{ converOptions(answers.question_8.view_content) }}</p>
              </td>
              <td class="text-center">
                {{ answers.question_8.answer }}
              </td>
            </tr>
            <tr>
              <td>
                <p>寝相はどの状態が多いですか?</p>
                <p>{{ answers.question_9.view_content }}</p>
              </td>
              <td class="text-center">
                {{ answers.question_9.view_content === '' ? '未入力' : '' }}
              </td>
              <td>
                <p>家族に不正咬合の方はいらっしゃいますか?</p>
                <div v-if="answers.question_10.is_answer_yes">
                  <div class="d-flex" v-for="(item,index) in answers.question_10.items" :key="index">
                    <span>{{ index + 1}}.</span>
                    <div>
                      <p  v-for="(detail, index) in item.details" :key="index">{{ detail.title }}:{{ converOptions(detail) }}</p>
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center">
                {{ answers.question_10.answer }}
              </td>
            </tr>
            <tr>
              <td>
                <p>今まで耳鼻科を受診したことはありますか?</p>
                <div v-if="answers.question_11.is_answer_yes">
                  <div class="d-flex" v-for="(item,index) in answers.question_11.items" :key="index">
                    <span>{{ index + 1}}.</span>
                    <div>
                      <p  v-for="(detail, index) in item.details" :key="index">{{ detail.title }}:{{ converOptions(detail) }}</p>
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center">
                {{ answers.question_11.answer }}
              </td>
              <td>
                <p>現在、通院されていますか?</p>
                <div v-if="answers.question_12.is_answer_yes">
                  <div class="d-flex" v-for="(item,index) in answers.question_12.items" :key="index">
                    <span>{{ index + 1}}.</span>
                    <div>
                      <p  v-for="(detail, index) in item.details" :key="index">{{ detail.title }}:{{ converOptions(detail) }}</p>
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center">
                {{ answers.question_12.answer }}
              </td>
            </tr>
            <tr>
              <td>
                <p>身体全体で先天性の疾患や生まれつき気になる事、部位はありますか?</p>
                <div v-if="answers.question_13.is_answer_yes">
                  <div class="d-flex" v-for="(item,index) in answers.question_13.items" :key="index">
                    <span>{{ index + 1}}.</span>
                    <div>
                      <p  v-for="(detail, index) in item.details" :key="index">{{ detail.title }}:{{ converOptions(detail) }}</p>
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center">
                {{ answers.question_13.answer }}
              </td>
              <td>
                <p>今までにかかった病気はありますか?</p>
                <p v-if="answers.question_14.is_answer_yes">{{ converOptions(answers.question_14.view_content) }}</p>
              </td>
              <td class="text-center">
                <p>{{ answers.question_14.answer }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>今までに次のようなことがありましたか?</p>
                <p v-if="answers.question_15.is_answer_yes">{{ answers.question_15.view_content }}</p>
              </td>
              <td class="text-center">
                {{ answers.question_15.answer }}
              </td>
              <td>
                <p>現在飲んでいる薬はありますか?</p>
                <div>
                  <p>{{ answers.question_16.view_content }}</p>
                  <div v-if="answers.question_16.is_answer_yes">
                    <div
                      v-for="(item, index) in answers.question_16.custom_items"
                      :key="index"
                      class="d-flex"
                    >
                      <span>{{ index + 1 }}.</span>
                      <div>
                        <p
                          v-for="(detail, i) in item"
                          :key="i"
                        >
                          {{ detail.title }}:{{ converOptions(detail) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center">
                {{ answers.question_16.answer }}
              </td>
            </tr>
            <tr>
              <td>
                <p>喫煙習慣はありますか?</p>
                <p v-if="answers.question_17.is_answer_yes">
                  一日量: {{ converOptions(answers.question_17.items.details[0]) }}本/日
                </p>
              </td>
              <td class="text-center">
                {{ answers.question_17.answer }}
              </td>
              <td>
                <p>飲酒習慣はありますか?</p>
                <div v-if="answers.question_18.is_answer_yes">
                  <p>よく飲まれるお酒:{{ answers.question_18.view_content_1 }}</p>
                  <p>一日量:{{ answers.question_18.view_content_2 }}mlまたは {{ answers.question_18.view_content_3 }}合くらい</p>
                </div>
              </td>
              <td class="text-center">
                {{ answers.question_18.answer }}
              </td>
            </tr>
            <tr>
              <td>
                <p>身長: <span class="mr-4">{{ answers.question_19.view_content_1 }}</span> cm</p>
                <p>体重:<span class="mr-4">{{ answers.question_19.view_content_2 }}</span> kg</p>
              </td>
              <td class="text-center">
                {{ !(answers.question_19.view_content_1 || answers.question_19.view_content_2) ? INTERVIEW_OPTIONS_NOT_ENTERED : '' }}
              </td>
              <td>
                <p>血液型</p>
                <p class="ml-4">
                  {{ answers.question_20.view_content === INTERVIEW_OPTIONS_NOT_ENTERED ? '' : answers.question_20.view_content }}
                </p>
              </td>
              <td class="text-center">
                {{ answers.question_20.view_content === INTERVIEW_OPTIONS_NOT_ENTERED ? INTERVIEW_OPTIONS_NOT_ENTERED : '' }}
              </td>
            </tr>
            <tr>
              <td>
                <p>血圧の状態は?</p>
                <p>{{ answers.question_21.view_content_1 === INTERVIEW_OPTIONS_NOT_ENTERED ? '' : answers.question_21.view_content_1 }}</p>
                <div class="d-flex">
                  <p>最高: <span class="ml-3">{{ answers.question_21.view_content_2 }}</span></p>
                  <p class="pl-4">
                    最低:<span class="ml-3">{{ answers.question_21.view_content_3 }}</span>
                  </p>
                </div>
              </td>
              <td class="text-center">
                {{ answers.question_21.view_content_1 === INTERVIEW_OPTIONS_NOT_ENTERED ? INTERVIEW_OPTIONS_NOT_ENTERED : '' }}
              </td>
              <td>
                <p>女性の方のみ</p>
                <p>初潮年齢 <span class="ml-3">{{ answers.question_22.view_content_1 }}</span>歳 <span class="ml-3">{{ answers.question_22.view_content_2 }}</span>ヶ月</p>
              </td>
              <td class="text-center">
                {{ !(answers.question_22.view_content_1 || answers.question_22.view_content_2) ? INTERVIEW_OPTIONS_NOT_ENTERED : '' }}
              </td>
            </tr>
            <tr>
              <td>
                <p>以前に歯科医院で歯を抜いたことがありますか?</p>
                <p v-if="answers.question_23.is_answer_yes">{{ answers.question_23.view_content }}</p>
              </td>
              <td class="text-center">
                {{ answers.question_23.answer }}
              </td>
              <td>
                <p>最近、歯科医院を受診されましたか?</p>
                <div v-if="answers.question_24.is_answer_yes">
                  <p><span class="mx-2">{{ answers.question_24.view_content_1 }}</span> 年 <span class="mx-2">{{ answers.question_24.view_content_2 }}</span> 月ごろ</p>
                  <p>または <span class="mx-2">{{ answers.question_24.view_content_3 }}</span> 年 <span class="mx-2">{{ answers.question_24.view_content_4 }}</span> ヶ月前</p>
                  <p>治療内容:{{ answers.question_24.view_content_5 }}</p>
                </div>
              </td>
              <td class="text-center">
                {{ answers.question_24.answer }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  import { OTHER_STRING, INTERVIEW_COMMON_OPTIONS, INTERVIEW_OPTIONS_NOT_ENTERED } from '../../../../utils/constants'
  import { cloneDeep } from 'lodash'

  export default {
    props: ['interviewInfo'],
    data () {
      return {
        answers: {},
        INTERVIEW_OPTIONS_NOT_ENTERED: INTERVIEW_OPTIONS_NOT_ENTERED
      }
    },
    created () {
      this.loadInterviewSheet()
    },
    methods: {
      converOptions (obj) {
        if (obj && typeof obj === 'object' && obj.content) {
          return obj.content.toString()
        }
        if (typeof obj === 'string') {
          return obj
        }
        return ''
      },
      filterOtherContent (content = '', other = '') {
        if (Array.isArray(content) && typeof other === 'string' && content.includes(OTHER_STRING)) {
          content = content.filter(e => e !== OTHER_STRING)
          content.push(other)
        } else if (typeof content === 'string' && typeof other === 'string' && other !== '') {
          other = OTHER_STRING + ': ' + other
          content += content !== '' && other !== '' ? ', ' + other : '' + other
        }

        return content
      },
      getContentFromQuestion (item, title) {
        const data = item.find(item => item.title === title)
        if (typeof data.content !== 'undefined') {
          if (Array.isArray(data.content)) {
            if (data.content.indexOf(OTHER_STRING) !== -1) {
              data.content.splice(data.content.indexOf(OTHER_STRING), 1)
            }

            return data.content.filter(n => n).join(', ')
          }
          return data.content
        }
        return ''
      },
      isAnswerYes (answer) {
        return answer === INTERVIEW_COMMON_OPTIONS.YES
      },
      loadInterviewSheet () {
        const selectOptionsQuestion = [8, 15]
        const answer = this.interviewInfo
        // set data for answer
        if (answer) {
          answer.forEach((objAnswer, index) => {
            const objMain = cloneDeep(objAnswer)
            const number = index + 1
            // custom content
            if (selectOptionsQuestion.includes(number)) {
              objMain.view_content = this.getContentFromQuestion(objMain.items.details, '選択肢').toString()
            }
            objMain.is_answer_yes = this.isAnswerYes(objMain.answer)
            switch (number) {
              case 2:
                if (objMain) {
                  objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, 'いつ頃')
                  objMain.view_content_2 = this.getContentFromQuestion(objMain.items.details, '症状')
                  const other_q2 = this.getContentFromQuestion(objMain.items.details, 'その他')
                  let content_q2 = this.getContentFromQuestion(objMain.items.details, '矯正装置')
                  content_q2 = this.filterOtherContent(content_q2, other_q2)
                  objMain.view_content_3 = content_q2.toString()
                }
                break
              case 3:
                objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, '金属アレルギー').toString()
                objMain.view_content_2 = this.getContentFromQuestion(objMain.items.details, '金属名')
                objMain.view_content_3 = this.getContentFromQuestion(objMain.items.details, '薬アレルギー').toString()
                objMain.view_content_4 = this.getContentFromQuestion(objMain.items.details, '薬品名')
                objMain.view_content_5 = this.getContentFromQuestion(objMain.items.details, '花粉症').toString()
                objMain.view_content_6 = this.getContentFromQuestion(objMain.items.details, '発症時期')
                objMain.view_content_7 = this.getContentFromQuestion(objMain.items.details, '原因植物')
                objMain.view_content_8 = this.getContentFromQuestion(objMain.items.details, 'その他のアレルギー').toString()
                objMain.view_content_9 = this.getContentFromQuestion(objMain.items.details, 'アレルギー物質')
                break
              case 4:
                objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, 'いつ頃')
                let content_q4 = this.getContentFromQuestion(objMain.items.details, '選択結果')
                const other_q4 = this.getContentFromQuestion(objMain.items.details, 'その他')
                content_q4 = this.filterOtherContent(content_q4, other_q4)
                objMain.view_content_2 = content_q4.toString()
                break
              case 6:
                objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, 'いつ頃')
                let content_q6 = this.getContentFromQuestion(objMain.items.details, '選択肢')
                const other_q6 = this.getContentFromQuestion(objMain.items.details, 'その他')
                content_q6 = this.filterOtherContent(content_q6, other_q6)
                objMain.view_content_2 = content_q6.toString()
                break
              case 7:
                objMain.view_content = this.getContentFromQuestion(objMain.items.details, '具体的に')
                break
              case 9:
                const content_1 = this.getContentFromQuestion(objMain.items.details, '仰向け')
                const content_2 = this.getContentFromQuestion(objMain.items.details, 'うつ伏せ')
                const content_3 = this.getContentFromQuestion(objMain.items.details, '頭の向き')
                const content_4 = this.getContentFromQuestion(objMain.items.details, '横向き')
                const content_5 = this.getContentFromQuestion(objMain.items.details, '向き')
                const content_6 = this.getContentFromQuestion(objMain.items.details, '様々寝相が変わる')
                objMain.view_content = [content_1.toString(), content_2.toString(), content_3, content_4.toString(), content_5, content_6.toString()].filter(Boolean).join(',')
                break
              case 14:
                let content_q14 = this.getContentFromQuestion(objMain.items.details, '選択肢')
                const other_q14 = this.getContentFromQuestion(objMain.items.details, 'その他')
                content_q14 = this.filterOtherContent(content_q14, other_q14)
                objMain.view_content = content_q14.toString()
                break
              case 16:
                let content_q16 = this.getContentFromQuestion(objMain.item_common.details, '選択肢')
                const other_q16 = this.getContentFromQuestion(objMain.item_common.details, 'その他')
                content_q16 = this.filterOtherContent(content_q16, other_q16)
                objMain.view_content = content_q16.toString()
                objMain.custom_items = []
                const vm = this
                objMain.items.forEach(function (subItem, index) {
                  const diagnostis = vm.getContentFromQuestion(subItem.details, '診断名')
                  const name1 = vm.getContentFromQuestion(subItem.details, '服薬量1')
                  const name2 = vm.getContentFromQuestion(subItem.details, '服薬量2')
                  const format_name = `1日 ${name1} 回 ${name2} 錠/包/ml`
                  objMain.custom_items.push([
                    { title: '薬品名', content: diagnostis },
                    { title: '服薬量', content: format_name }
                  ])
                })
                break
              case 18:
                objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, 'よく飲まれるお酒')
                objMain.view_content_2 = this.getContentFromQuestion(objMain.items.details, '一日量1')
                objMain.view_content_3 = this.getContentFromQuestion(objMain.items.details, '一日量2')
                break
              case 19:
                objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, '身長')
                objMain.view_content_2 = this.getContentFromQuestion(objMain.items.details, '体重')
                break
              case 20:
                const bloodType = this.getContentFromQuestion(objMain.items.details, '血液型')
                objMain.view_content = bloodType.toString()
                break
              case 21:
                const bloodPressureStatus = this.getContentFromQuestion(objMain.items.details, '血圧の状態')
                objMain.view_content_1 = bloodPressureStatus.toString()
                objMain.view_content_2 = this.getContentFromQuestion(objMain.items.details, '最高')
                objMain.view_content_3 = this.getContentFromQuestion(objMain.items.details, '最低')
                break
              case 22:
                objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, '初潮年齢1')
                objMain.view_content_2 = this.getContentFromQuestion(objMain.items.details, '初潮年齢2')
                break
              case 23:
                let content_q23 = this.getContentFromQuestion(objMain.items.details, '選択肢')
                const other_q23 = this.getContentFromQuestion(objMain.items.details, 'その他')
                content_q23 = this.filterOtherContent(content_q23, other_q23)
                objMain.view_content = content_q23.toString()
                break
              case 24:
                objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, '受診時期1')
                objMain.view_content_2 = this.getContentFromQuestion(objMain.items.details, '受診時期2')
                objMain.view_content_3 = this.getContentFromQuestion(objMain.items.details, '受診時期3')
                objMain.view_content_4 = this.getContentFromQuestion(objMain.items.details, '受診時期4')
                objMain.view_content_5 = this.getContentFromQuestion(objMain.items.details, '治療内容')
                break
              default:
            }
            const custom_name = 'question_' + number
            this.answers[custom_name] = objMain
          })
        }
      }
    }
  }
</script>
