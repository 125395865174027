var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "interview-content-consultation interview-input-block content-inner bg-white"
    },
    [
      _c("according-block", {
        attrs: { label: "相談内容", checked: _vm.show_block },
        on: {
          handleClick: function($event) {
            _vm.show_block = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show_block,
              expression: "show_block"
            }
          ],
          staticClass:
            "interview-content-consultation__content arrow-toggle-content page-content"
        },
        [
          _c("div", { staticClass: "interview-content-consultation__group" }, [
            _c("div", { staticClass: "page-content" }, [
              _c("h2", { staticClass: "title" }, [_vm._v("ご相談内容")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "form-group interview-content-consultation__group-padding"
                },
                [
                  _vm.errors.consultation_item
                    ? _c("p", { staticClass: "error-massage" }, [
                        _vm._v(_vm._s(_vm.errors.consultation_item))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-inline justify-content-between" },
                    [
                      _c("checkbox-common", {
                        attrs: { val: "矯正歯科治療", label: "矯正歯科治療" },
                        on: { input: _vm.handleConsultation },
                        model: {
                          value: _vm.consultation_item,
                          callback: function($$v) {
                            _vm.consultation_item = $$v
                          },
                          expression: "consultation_item"
                        }
                      }),
                      _vm._v(" "),
                      _c("checkbox-common", {
                        attrs: {
                          label: "ホワイトニング",
                          val: "ホワイトニング"
                        },
                        on: { input: _vm.handleConsultation },
                        model: {
                          value: _vm.consultation_item,
                          callback: function($$v) {
                            _vm.consultation_item = $$v
                          },
                          expression: "consultation_item"
                        }
                      }),
                      _vm._v(" "),
                      _c("checkbox-common", {
                        attrs: { label: "クリーニング", val: "クリーニング" },
                        on: { input: _vm.handleConsultation },
                        model: {
                          value: _vm.consultation_item,
                          callback: function($$v) {
                            _vm.consultation_item = $$v
                          },
                          expression: "consultation_item"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group pt-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-inline row checkbox-other-inline" },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-2" },
                          [
                            _c("checkbox-common", {
                              attrs: { label: "その他", val: "その他" },
                              on: { input: _vm.handleConsultation },
                              model: {
                                value: _vm.consultation_item,
                                callback: function($$v) {
                                  _vm.consultation_item = $$v
                                },
                                expression: "consultation_item"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c(
                            "div",
                            { class: ["other-input", _vm.diableOtherInput] },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.consultation_item_other,
                                    expression: "consultation_item_other"
                                  }
                                ],
                                staticClass: "form-control w-100",
                                attrs: {
                                  type: "text",
                                  name: "",
                                  placeholder: "その他の場合ご記入ください"
                                },
                                domProps: {
                                  value: _vm.consultation_item_other
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.consultation_item_other =
                                      $event.target.value
                                  }
                                }
                              })
                            ]
                          )
                        ])
                      ]
                    )
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-content" },
            [
              _c("h2", { staticClass: "title" }, [
                _vm._v("気になる箇所・症状")
              ]),
              _vm._v(" "),
              _vm._l(_vm.priority, function(value) {
                return _c(
                  "Item",
                  {
                    key: value,
                    ref: "priority",
                    refInFor: true,
                    attrs: { type: "priority", index: value - 1 }
                  },
                  [_vm._v("優先順位" + _vm._s(value))]
                )
              })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }