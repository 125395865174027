<template>
  <div class="setting">
    <side-bar />
    <div class="setting-v2">
      <router-view />
    </div>
  </div>
</template>
<script>
  import SideBar from '../../components/setting/SideBar'

  export default {
    components: {
      SideBar
    }
  }
</script>
