var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.className] },
    [
      _c("sub-question", {
        ref: "sub_question",
        attrs: {
          question: _vm.question,
          options: _vm.option_yes_no,
          checked: _vm.select_option,
          "is-required": false
        },
        on: {
          "handle-answer": function($event) {
            return _vm.handleAnswer($event)
          }
        }
      }),
      _vm._v(" "),
      _c("div", { class: _vm.handleChildAnswerClass }, [
        _c("div", { staticClass: "form-group" }, [
          _c(
            "div",
            { staticClass: "row form-inline" },
            [
              _c("checkbox-common", {
                attrs: { label: "頭痛", val: "頭痛" },
                model: {
                  value: _vm.selections,
                  callback: function($$v) {
                    _vm.selections = $$v
                  },
                  expression: "selections"
                }
              }),
              _vm._v(" "),
              _c("checkbox-common", {
                attrs: { label: "腰痛", val: "腰痛" },
                model: {
                  value: _vm.selections,
                  callback: function($$v) {
                    _vm.selections = $$v
                  },
                  expression: "selections"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row form-inline" },
            [
              _c("checkbox-common", {
                attrs: { label: "肩こり", val: "肩こり" },
                model: {
                  value: _vm.selections,
                  callback: function($$v) {
                    _vm.selections = $$v
                  },
                  expression: "selections"
                }
              }),
              _vm._v(" "),
              _c("checkbox-common", {
                attrs: { label: "めまい", val: "めまい" },
                model: {
                  value: _vm.selections,
                  callback: function($$v) {
                    _vm.selections = $$v
                  },
                  expression: "selections"
                }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }