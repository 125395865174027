<template>
  <div class="layout-v2__header">
    <ul class="layout-v2__header__menu">
      <li :class="['layout-v2__header__logo', { 'layout-v2__header__logo--only-order-detail': isOnlyOrderDetail }]">
        <img src="/images/logo-header-v2.svg">
        <div
          v-if="isShowNotificationIcon && typeNotification"
          class="layout-v2__header__notification"
          @click="$emit('show-notification')"
        >
          <lottie-player
            id="notification-icon"
            :src="`/images/icon-lottie/icon-notification-${typeNotification}.json`"
            loop
            :autoplay="!isRead"
          />
        </div>
      </li>
      <template v-if="!isOnlyOrderDetail">
        <li v-if="show_reservation && !isLabDesigner && !isInstructionAdmin">
          <router-link
            v-if="current_page === 'secretariat'"
            :to="{ name: 'reservation.list' }"
            @click.native="handleChangeRoute('reservation.list')"
          >
            {{ $t('sidebar_menu.reservation_calendar') }}
          </router-link>
          <router-link
            v-else
            :to="{ name: 'dashboard' }"
            @click.native="handleChangeRoute('dashboard')"
          >
            {{ $t('sidebar_menu.reservation_calendar') }}
          </router-link>
        </li>
        <li v-if="is_clinics_dmr && !isLabDesigner && !isInstructionAdmin">
          <router-link
            :to="{ name: 'reception' }"
            @click.native="handleChangeRoute('reception')"
          >
            {{ $t('sidebar_menu.recept') }}
          </router-link>
        </li>
        <li v-if="!isLabDesigner && !isInstructionAdmin">
          <router-link
            :to="{ name: 'patient.list' }"
            @click.native="handleChangeRoute('patient.list')"
          >
            {{ $t('sidebar_menu.patient') }}
          </router-link>
        </li>
        <li v-if="is_clinics_dmr && !isLabDesigner && !isInstructionAdmin">
          <router-link
            :to="{ name: 'accounting.list' }"
            @click.native="handleChangeRoute('accounting.list')"
          >
            {{ $t('sidebar_menu.accounting') }}
          </router-link>
        </li>
        <li v-if="is_show_order">
          <router-link
            :to="{ name: 'order.search' }"
            :class="getClass"
            @click.native="handleChangeRoute('order.search')"
          >
            {{ $t('sidebar_menu.order') }}
          </router-link>
        </li>
        <li v-if="!isLabDesigner && !isInstructionAdmin">
          <router-link
            :to="{ name: 'questionnaire' }"
            @click.native="handleChangeRoute('questionnaire')"
          >
            {{ $t('new_text.questionnaire_visit') }}
          </router-link>
        </li>
        <li
          v-if="!isLabDesigner && !isInstructionAdmin"
          v-click-outside="hideSubMenu"
          class="layout-v2__header__sub-menu"
          @click="toggleSubMenu('help_link')"
        >
          <a href="javascript:void(0)">{{ $t('new_text.supporting_materials') }}</a>
          <ul v-show="sub_menu_show === 'help_link' && supportMaterialLinks.length !== 0">
            <li
              v-for="(item, key) in supportMaterialLinks"
              :key="key"
              @click.middle="triggerClick(trigger_click_type.MENU, item.title)"
              @click="triggerClick(trigger_click_type.MENU, item.title)"
            >
              <a
                target="_blank"
                :href="item.display_link"
              >{{ item.display_title }}</a>
            </li>
          </ul>
        </li>
      </template>
    </ul>
    <template v-if="!isOnlyOrderDetail">
      <div class="layout-v2__header__info">
        <ul>
          <li
            v-if="is_loading"
            class="layout-v2__header__name"
          >
            <span>{{ clinic_formal_name || $t('new_text.admin') }}</span>
            <span>{{ staff_position }} {{ staff_fullname || instruction_admin_name }}</span>
          </li>
          <li
            v-if="!isLabDesigner && !isInstructionAdmin"
            v-click-outside="hiddenNotification"
            :class="['layout-v2__header__notify', { 'layout-v2__header__notify--active': show_notification_menu }]"
          >
            <div
              class="layout-v2__header__notify-cont"
              @click="showNotification"
            >
              <img
                v-if="show_notification_menu || totalNotification"
                src="/images/bell-red.svg"
              >
              <img
                v-else
                src="/images/bell-gray.svg"
              >
              <span
                v-if="totalNotification"
                class="layout-v2__header__notify-number"
              >{{ totalNotification }}</span>
            </div>
            <reservation-list
              v-if="show_notification_menu"
              @update-notify="UpdateNotify"
            />
          </li>
          <li
            v-click-outside="hiddenSettingMenu"
            class="layout-v2__header__setting"
          >
            <span @click="showSettingMenu">
              <img src="/images/icon-setting-gray.svg">
            </span>
            <ul
              v-show="show_setting_menu"
              class="layout-v2__header__setting__menu"
            >
              <li v-if="isInstructionAdmin">
                <a
                  class="layout-v2__header__setting__help"
                  :href="link_help"
                  target="_blank"
                >
                  {{ $t('sidebar_menu.help') }}
                  <img src="/images/icon-tab.svg">
                </a>
              </li>
              <li v-if="$store.getters.isAdmin">
                <a @click="moveToSetting('setting.account')">
                  {{ $t('sidebar_menu.preferences') }}
                </a>
              </li>
              <li v-if="$store.getters.isStaff && $store.getters.isAdmin">
                <a @click="moveToSetting('setting.basic-treatment-policy', true)">
                  {{ $t('new_text.basic_treatment_policy') }}
                </a>
              </li>
              <li>
                <a
                  href="#"
                  @click="showLanguageMenu"
                >
                  {{ $t('sidebar_menu.change_language') }}
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="layout-v2__header__setting__logout"
                  @click="logOut"
                >
                  {{ $t('sidebar_menu.log_out') }}
                </a>
              </li>
            </ul>
            <ul
              v-if="show_language_menu"
              class="layout-v2__header__setting__menu"
            >
              <li>
                <a
                  :class="{ 'disabled-link' : $i18n.locale === 'ja' }"
                  href="#"
                  @click="change_language('ja')"
                >
                  {{ $t('sidebar_menu.japanese') }}
                </a>
              </li>
              <li>
                <a
                  :class="{ 'disabled-link' : $i18n.locale === 'en' }"
                  href="#"
                  @click="change_language('en')"
                >
                  {{ $t('sidebar_menu.english') }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <ul class="layout-v2__header__menu-mobi">
        <li
          v-click-outside="hiddenSidebarMenu"
          class="layout-v2__header__sidebar"
        >
          <img
            :src="show_sidebar_menu ? '/images/icon-close-sidebar.svg' : '/images/icon-menu.svg'"
            @click="showSidebarMenu"
          >
          <ul
            v-show="show_sidebar_menu"
            class="layout-v2__header__sidebar__menu"
          >
            <div class="layout-v2__header__sidebar__line" />
            <li v-if="!isLabDesigner && !isInstructionAdmin">
              <a
                :class="page_order === 'order.create' ? 'is-active' : ''"
                @click="moveToOrder('order.create')"
              >
                {{ $t('order.sidebar.instructions') }}
              </a>
            </li>
            <div class="layout-v2__header__sidebar__line" />
            <li v-if="is_show_order">
              <a
                :class="page_order === 'order.search' ? 'is-active' : ''"
                @click="moveToOrder('order.search')"
              >
                {{ $t('order.sidebar.search') }}
              </a>
            </li>
            <div class="layout-v2__header__sidebar__line" />
            <li>
              <a @click="logOut">
                {{ $t('sidebar_menu.log_out') }}
              </a>
            </li>
            <div class="layout-v2__header__sidebar__line" />
          </ul>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import eventBus from '../../utils/event-bus'
  import ReservationList from '../reservation-notification/List.vue'
  import OrderDetail from '../../mixins/order-detail'
  import { checkInstructionForbidden, intVal } from '../../utils/helpers'
  import { get, isEmpty } from 'lodash'

  export default {
    components: {
      ReservationList
    },
    mixins: [OrderDetail],
    props: {
      isSpLayout: {
        type: Boolean,
        default: false
      }
    },
    emits: ['show-notification', 'request-notification'],
    data () {
      return {
        current_page: '',
        is_clinics_dmr: false,
        is_lab_clinic: false,
        show_reservation: process.env.MIX_SHOW_RESERVATION === 'true',
        totalNotification: 0,
        show_setting_menu: false,
        show_language_menu: false,
        show_notification_menu: false,
        link_help: process.env.MIX_LINK_HELP,
        clinic_formal_name: '',
        staff_fullname: '',
        staff_position: '',
        is_show_order: true,
        show_sidebar_menu: false,
        page_order: '',
        instruction_admin_name: '',
        is_loading: false,
        sub_menu_show: '',
        prev_route_name: ''
      }
    },
    computed: {
      ...mapState('link_master', ['support_material_links']),
      ...mapState('notification', ['notification_info']),
      isLabDesigner () {
        return this.$store.getters.isLabDesigner
      },
      isLabClinic () {
        return this.$store.getters.isLabClinic
      },
      isStaff () {
        return this.$store.getters.isStaff
      },
      isInstructionAdmin () {
        return this.$store.getters.isInstructionAdmin
      },
      getClass () {
        if (this.$route.path.includes('/order/')) {
          return 'router-link-exact-active router-link-active'
        }

        return ''
      },
      supportMaterialLinks () {
        const result = []
        this.support_material_links.map(item => {
          result.push({
            display_link: this.$i18n.locale === 'en' ? (item.link_en || item.link) : item.link,
            display_title: this.$i18n.locale === 'en' ? (item.title_en || item.title) : item.title,
            title: item.title
          })
        })

        return result
      },
      isShowNotificationIcon () {
        return this.isStaff && !this.isSpLayout && !isEmpty(this.notification_info)
      },
      isRead () {
        return intVal(get(this.notification_info, 'is_read', 0)) === 1
      },
      typeNotification () {
        return get(this.notification_info, 'color', null)
      }
    },
    watch: {
      typeNotification (newVal) {
        const iconUrl = `/images/icon-lottie/icon-notification-${newVal}.json`
        const iconNotification = document.getElementById('notification-icon')
        if (!iconNotification) {
          return
        }

        iconNotification.load(iconUrl)
      },
      isRead (newVal) {
        const iconNotification = document.getElementById('notification-icon')
        if (!iconNotification) {
          return
        }

        if (newVal) {
          iconNotification.stop()
        } else {
          iconNotification.play()
        }
      }
    },
    created () {
      if (this.isInstructionAdmin) {
        this.getInstructionAdminInfo()
      } else {
        this.getInfoUserAuthenticated()
        this.getTotalNotification()
      }
      this.getConfigOfStaff()
      if (!this.isLabDesigner) {
        this.getLinkMasters()
      }

      this.page_order = this.$route.name
      this.prev_route_name = this.$route.name
    },
    methods: {
      getInfoUserAuthenticated () {
        this.$store.dispatch('getLoggedInfo').then((result) => {
          const data = result?.data?.results
          const clinic = data?.user?.clinic || {}
          this.clinic_formal_name = clinic.formal_name || ''
          this.staff_fullname = data?.user?.name || ''
          this.is_clinics_dmr = clinic.is_dmr || 0
          this.staff_position = data?.user?.position?.name || ''
          if (!this.isLabDesigner && !this.isLabClinic) {
            this.is_show_order = false
          }
          localStorage.setItem('is_clinics_dmr', this.is_clinics_dmr)
          localStorage.setItem('clinic_formal_name', this.clinic_formal_name)
          this.is_loading = true
        }).catch(() => {})
      },
      getInstructionAdminInfo () {
        this.$store.dispatch('getInstructionAdminInfo').then((result) => {
          const data = result?.data?.results
          this.instruction_admin_name = data?.name
          const isInstructionAdmin = data.role_id === 2
          localStorage.setItem('is_instruction_admin', isInstructionAdmin)
          this.is_loading = true
        }).catch((error) => {
          checkInstructionForbidden(error, this)
        })
      },
      initCurrentPage () {
        const rootPath = this.$router.currentRoute.name.split('.')[0].trim()
        const childPath = this.$router.currentRoute.name.split('.')[1]
          ? this.$router.currentRoute.name.split('.')[1].trim()
          : ''

        if (rootPath === 'reservation' && childPath === 'secretariat') {
          this.current_page = 'secretariat'
        } else if (rootPath === 'reservation') {
          this.current_page = 'reservation'
        } else {
          this.current_page = ''
        }
      },
      getTotalNotification () {
        this.$store.dispatch('getTotalNotification').then((res) => {
          if (res.status === 200) {
            this.totalNotification = res.data.results
          }
        }).catch(() => {})
      },
      getConfigOfStaff () {
        this.$store.dispatch('staff/config').then(config => {
          if (config && config.lang) {
            this.$i18n.locale = config.lang
          }
        }).catch(() => {})
      },
      moveToOrder (page) {
        this.show_sidebar_menu = false
        this.$router.push({ name: page })
        this.page_order = page
      },
      moveToSetting (routeName, isTargetBlank = false) {
        this.show_setting_menu = false

        if (isTargetBlank) {
          window.open(this.$router.resolve({ name: routeName }).href)

          return
        }

        this.$router.push({ name: routeName }).catch(() => {})
      },
      hiddenSettingMenu () {
        this.show_setting_menu = false
        this.show_language_menu = false
      },
      showSettingMenu () {
        this.show_setting_menu = !this.show_setting_menu
        this.show_language_menu = false
        this.show_notification_menu = false
      },
      hiddenSidebarMenu () {
        this.show_sidebar_menu = false
      },
      showSidebarMenu () {
        this.show_sidebar_menu = !this.show_sidebar_menu
      },
      showLanguageMenu () {
        this.show_language_menu = true
        this.show_setting_menu = false
      },
      change_language (lang) {
        this.$store.dispatch('staff/changeLanguage', { lang }).then(() => {
          this.$i18n.locale = lang
          eventBus.$emit('change-language', lang)
          this.show_language_menu = false
          this.show_setting_menu = false
        }).catch(() => {})
      },
      showNotification () {
        this.show_notification_menu = !this.show_notification_menu
      },
      hiddenNotification () {
        this.show_notification_menu = false
      },
      UpdateNotify () {
        this.getTotalNotification()
      },
      logOut () {
        this.$store.commit('set_loading', true)

        this.$store.dispatch('logout').then(() => {
          this.$store.commit('set_loading', false)
          this.$router.push({ name: 'auth.login' })
        }).catch(() => {
          this.$store.commit('set_loading', false)
        })
      },
      hideSubMenu () {
        this.sub_menu_show = ''
      },
      getLinkMasters () {
        this.$store.dispatch('link_master/getLinkMasters').catch(() => {})
      },
      toggleSubMenu (menuId) {
        this.sub_menu_show = this.sub_menu_show !== menuId ? menuId : ''
      },
      handleChangeRoute (routeName) {
        if (this.prev_route_name !== routeName) {
          this.$emit('request-notification')
          this.prev_route_name = routeName
        }
      }
    }
  }
</script>
