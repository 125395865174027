export default {
  accounting: '会計',
  login: 'ログイン',
  order: '発注',
  password_reset: 'パスワード再設定',
  patient: '患者',
  questionnaire: '来院アンケート',
  reception: '受付',
  reservation: '予約カレンダー',
  setting: '設定'
}
