import axios from 'axios'
import { get } from 'lodash'
import { state as authState } from './auth'
import { RESPONSE_STATUS } from '../../utils/constants'

const CancelToken = axios.CancelToken
let cancel
const initPatient = {
  id: 0,
  sf_status_c: null,
  created_at: '',
  updated_at: '',
  customPerson: [],
  service_summary: [],
  reservationList: [],
  person_in_charges: [],
  form_data: {
    brandMasters: [],
    staffs: {}
  },
  info: {
    name: '',
    kana_last_name: '',
    kana_first_name: '',
    roma_last_name: '',
    roma_first_name: '',
    last_name: '',
    first_name: '',
    birth_of_date: '',
    gender: '',
    address: '',
    email: '',
    tel: '',
    note: '',
    brand_type: '',
    brand_master: {
      name: ''
    }
  },
  sf_next_reservation_date: '',
  sf_treatmentend_status_c: null
}
const initInterview = {
  id: 0,
  is_completed: 0,
  exists_interview: false
}
const initReservation = {
  id: 0,
  current_status: 0
}

export const state = {
  patient: { ...initPatient },
  interview: { ...initInterview },
  reservation: { ...initReservation },
  first_reservation: null,
  sms_history: {},
  reason_for_non_contract: '',
  reason_for_non_contract_modal_status: false
}

export const mutations = {
  set_patient (state, value) {
    if (value.info.gender === null) {
      value.info.gender = 0
    }
    state.patient = value
  },
  set_reservation (state, value) {
    state.reservation = value
  },
  set_reservationStatus (state, value) {
    state.reservation.current_status = value
  },
  set_firstReservation (state, value) {
    state.first_reservation = value
  },
  set_patientInterviewCompleted (state, value) {
    state.interview.is_completed = value
  },
  reset_patientState (state) {
    state.patient = { ...initPatient }
    state.interview = { ...initInterview }
    state.reservation = { ...initReservation }
    state.first_reservation = null
    state.sms_history = {}
  },
  set_reservationList (state, reservationList = []) {
    state.patient.reservationList = reservationList
  },
  set_existsPatientInterview (state, values) {
    state.interview.exists_interview = values
  },
  set_reasonForNonContract (state, value) {
    state.reason_for_non_contract = value
  },
  set_reasonForNonContractModalStatus (state, value) {
    state.reason_for_non_contract_modal_status = value
  }
}

export const getters = {
  get_patientInterviewCompleted: function (state) {
    if (state.interview) {
      return state.interview.is_completed || false
    }

    return false
  },
  get_patientInformation: function (state) {
    return state.patient.info
  },
  get_patient: function (state) {
    return state.patient
  },
  get_reservation: function (state) {
    return state.reservation
  },
  get_firstReservation (state) {
    return state.first_reservation
  },
  get_reservationList (state) {
    return state.patient.reservationList
  },
  get_existsPatientInterview: function (state) {
    if (state.interview) {
      return state.interview.exists_interview || false
    }

    return false
  },
  get_reasonForNonContract (state) {
    return state.reason_for_non_contract
  },
  get_reasonForNonContractModalStatus (state) {
    return state.reason_for_non_contract_modal_status
  }
}

export const actions = {
  getPatientInfo ({ dispatch, commit }, submitData) {
    return axios.get('/api/patient/info', {
      params: {
        patient_id: submitData.id,
        accept_deleted: submitData.accept_deleted || 0
      },
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getReservationList ({ dispatch, commit }, { patient_id }) {
    return axios.get('/api/reservation/list-by-patient', {
      params: {
        patient_id
      },
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        const { data: { results, status } } = res

        if (status) {
          commit('set_reservationList', results)
        }
      })
      .catch(error => {
        throw error
      })
  },
  getPatientDetail ({ dispatch, commit }, { id, reservation_id, accept_deleted }) {
    return axios.get('/api/patient', {
      params: {
        patient_id: id,
        reservation_id,
        accept_deleted
      },
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        commit('set_firstReservation', null)
        const { data: { results: patient, status } } = res
        if (status === RESPONSE_STATUS.OK && patient) {
          commit('set_patient', patient)

          const reservationList = get(patient, 'reservationList', [])
          for (const index in reservationList) {
            if (reservationList[index].id === patient.first_reservation_id) {
              commit('set_firstReservation', reservationList[index])
            }
          }
        }

        return res
      })
      .catch(error => {
        commit('set_firstReservation', null)
        throw error
      })
  },
  getSnippets ({ dispatch, commit }) {
    return axios.get('/api/snippet', {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getPatientReservations ({ dispatch, commit }, queryData) {
    return axios.get('/api/reception/reservations', {
      params: {
        search_keyword: queryData.search_keyword,
        patient_status: queryData.patient_status,
        date: queryData.search_date
      },
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getAccounting ({ dispatch, commit }, { id }) {
    return axios.get('/api/patient/accounting', {
      params: {
        patient_id: id
      },
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  updatePatientStatus ({ dispatch, commit }, submitData) {
    return axios.post('/api/patient/status', submitData, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getPatients ({ dispatch, commit }, { search_keyword, page, first_letter, order_field, order_type }) {
    return axios.get('/api/patient/list', {
      params: {
        search_keyword,
        page,
        first_letter,
        order_field,
        order_type
      },
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  searchDetail ({ dispatch, commit }, payload) {
    return axios.get(
      '/api/patient/search-detail',
      {
        params: payload,
        headers: { Authorization: 'Bearer ' + authState.token }
      }
    )
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getDeletedPatients ({ dispatch, commit }, queryData) {
    return axios.get('/api/patient/deleted-list', {
      params: {
        search_keyword: queryData.search_keyword,
        sort_field: queryData.sort_field,
        page: queryData.page,
        sort_type: queryData.sort_type
      },
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getPatientsById ({ dispatch, commit }, dataSubmit) {
    if (cancel !== undefined) {
      cancel()
    }
    return axios.get('/api/patient/list-by-id', {
      params: dataSubmit,
      headers: { Authorization: 'Bearer ' + authState.token },
      cancelToken: new CancelToken(function executor (c) {
        cancel = c
      })
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getPatientsByName ({ dispatch, commit }, dataSubmit) {
    if (cancel !== undefined) {
      cancel()
    }
    return axios.get('/api/patient/list-by-name', {
      params: dataSubmit,
      headers: { Authorization: 'Bearer ' + authState.token },
      cancelToken: new CancelToken(function executor (c) {
        cancel = c
      })
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getPatientHistories ({ dispatch, commit }, id) {
    return axios.get('/api/patient/histories', {
      params: {
        patient_id: id
      },
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getPatientHistoryDetail ({ dispatch, commit }, submitData) {
    return axios.get('/api/patient/history/detail', {
      params: submitData,
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  updatePatientInfo ({ dispatch, commit }, submitData) {
    return axios.post('/api/patient/update', submitData, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getPatientRegistrationFormData ({ dispatch, commit }) {
    return axios.get('/api/patient/registration-form', {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  addNewPatient ({ dispatch, commit }, submitData) {
    return axios.post('/api/patient/store', submitData, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getLastDraftId ({ dispatch, commit }, id) {
    return axios.get(`/api/patient/${id}/lastdraft`, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res.data.results.id
      })
  },
  getPatientCount () {
    return axios.get('/api/patient/count', {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },

  addNewPatientReservation ({ dispatch, commit }, submitData) {
    return axios.post('/api/patient/reservation', submitData, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },

  saveToken ({ dispatch, commit }, submitData) {
    return axios.post('/api/staff/token', submitData, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },

  getPatientActivities ({ dispatch, commit }, submitData) {
    return axios.get('/api/patient/activities', {
      params: submitData,
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },

  addMemo ({ dispatch, commit }, submitData) {
    return axios.post('/api/patient/activities/add-memo', submitData, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  deleteMemo ({ dispatch, commit }, submitData) {
    return axios.post('/api/patient/activities/delete-memo', submitData, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },

  sendPatientInterviewSms ({ dispatch, commit }, submitData) {
    const patientId = submitData.patient_id
    return axios.post(`/api/patient/${patientId}/send-sms`, submitData, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },

  getPatientInterviewStatus ({ dispatch, commit }, patientId) {
    return axios.get(`/api/patient/${patientId}/interview/status`, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        const { data: { results: interviewStatus } } = res
        let status = false
        let existsInterview = false
        if (typeof interviewStatus !== 'undefined') {
          status = interviewStatus.is_completed || 0
          existsInterview = true
        }
        commit('set_patientInterviewCompleted', status)
        commit('set_existsPatientInterview', existsInterview)

        return res
      })
      .catch(error => {
        throw error
      })
  },

  getInterviewSmsHistory ({ dispatch, commit }, patientId) {
    return axios.get(`/api/patient/${patientId}/sms-histories`, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        const { data: { results: { interview_status: interviewStatus } } } = res
        commit('set_patientInterviewCompleted', interviewStatus)

        return res
      })
      .catch(error => {
        throw error
      })
  },

  convert2Furigana ({ dispatch, commit }, str) {
    return axios.get('/api/patient/convert-to-furigana', {
      params: { str },
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },

  deletePatient ({ dispatch, commit }, patientId) {
    return axios.post(`/api/patient/${patientId}/delete`, {}, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },

  undoDeletionPatient ({ dispatch, commit }, patientId) {
    return axios.post(`/api/patient/${patientId}/undo-deletion`, {}, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },

  createDefaultPatientInterview ({ dispatch, commit }, submitData) {
    const patientId = submitData.patient_id
    return axios.post(`/api/patient/${patientId}/interview/create-default`, submitData, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        commit('set_existsPatientInterview', true)
        return res
      })
      .catch(error => {
        throw error
      })
  },
  printExamination ({ dispatch, commit }, { id, reservationId }) {
    return axios.get(`/api/patient/${id}/print-examination/${reservationId}`, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
  },

  getQuestionnaires ({ dispatch, commit }, id) {
    return axios.get(`/api/patient/${id}/questionnaire`, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
  },

  getBrandTypeOptions ({ dispatch, commit }) {
    return axios.get('/api/clinic/brands', { headers: { Authorization: 'Bearer ' + authState.token } })
  }
}
