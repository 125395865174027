export const printIndividualTechnicalInfo = {
  methods: {
    print (orderId) {
      const patientInfo = this.$store.state.order.patient_info
      const technicalInfoHeight = this.calculateHeaderHeightPage(
        patientInfo.patient_info,
        patientInfo.clinic.formal_name
      )
      this.channel_id = `${this.currentUser.id}_${new Date().getTime()}`
      this.$store.commit('set_loading', true)
      const data = {
        type: this.channel_type,
        channel_id: this.channel_id,
        header_height: technicalInfoHeight
      }
      this.connectChannel()
      this.$store.dispatch('printIndividualTecnicalInfo', { orderId, data }).catch(() => {
        this.$store.commit('set_loading', false)
      })
    },
    connectChannel () {
      Echo.channel(`${window.channelPrefix}order_print_technical_info.${this.channel_type}.${this.channel_id}`)
        .listen('.order_print_technical_info.completed', e => {
          this.$store.commit('set_loading', false)
          if (e.payLoad !== '') {
            const previewTab = window.open(e.payLoad.path, '_blank')
            previewTab.print()
          }

          Echo.leaveChannel(`${window.channelPrefix}order_print_technical_info.${this.channel_type}.${this.channel_id}`)
        })
        .error(() => {
          Echo.leaveChannel(`${window.channelPrefix}order_print_technical_info.${this.channel_type}.${this.channel_id}`)
          this.$store.commit('set_loading', false)
        })
    }
  }
}

export const headerPageToPrintTechnicalInfo = {
  methods: {
    calculateHeaderHeightPage (patientInfo, clinicName) {
      const patientName = patientInfo.roma_last_name + ' ' + patientInfo.roma_first_name

      $('.technical-info-print').remove()
      const html = `
        <div class="technical-info-print">
          <h2 class="technical-info-print__title">
            <span class="technical-info-print__gbangou"></span>
            <span class="technical-info-print__order-shipping-date"></span>
          </h2>
          <table class="technical-info-print__table">
            <tr class="row-full">
              <td class="row-label">
                クリニック
              </td>
              <td colspan="3">
                <span class="technical-info-print__clinic-name"></span>
              </td>
            </tr>
            <tr class="row-full">
              <td class="row-label">
                氏名
              </td>
              <td colspan="3">
                <span class="technical-info-print__name"></span>
              </td>
            </tr>
            <tr class="row-full">
              <td class="row-label">
                ローマ字
              </td>
              <td colspan="3">
                <span class="technical-info-print__roma-name"></span>
              </td>
            </tr>
            <tr>
              <td class="row-label">
                患者ID
              </td>
              <td class="technical-info-print__patient-name">sf_user_id</td>
              <td class="row-label right">
                注文ID
              </td>
              <td class="technical-info-print__numerals-font">
                code
              </td>
            </tr>
          </table>
        </div>
      `

      $('body').append(html)
      $('body .technical-info-print__gbangou').text('g308112')
      $('body .technical-info-print__order-shipping-date').text('12/12')
      $('body .technical-info-print__clinic-name').text(clinicName)
      $('body .technical-info-print__name').text(patientInfo.name)
      $('body .technical-info-print__roma-name').text(patientName)
      const result = $('.technical-info-print').height()
      $('.technical-info-print').remove()

      return result
    }
  }
}
