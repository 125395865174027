<template>
  <div class="item-pre">
    <div
      v-if="question"
      class="question"
    >
      <div class="form-group">
        <div
          class="control-text"
          v-html="question"
        />
      </div>
    </div>
    <div class="answer">
      <div
        v-if="!no_answer"
        :class="[checkQuestion ? 'option-m' : 'option-s','option_yes_no']"
      >
        <div
          class="yes"
          :class="{choose:answer === 'はい'}"
        >
          {{ $t('common.yes') }}
        </div>
        <div
          class="no"
          :class="{choose:answer === 'いいえ'}"
        >
          {{ $t('common.no') }}
        </div>
        <div
          v-if="checkQuestion"
          :class="[{choose:answer === '未入力'}, 'not-yet-entered']"
        >
          {{ $t('common.not_yet_entered') }}
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
  import { NOT_YET_ENTERED_QUESTIONS } from '../../../../utils/constants'

  export default {
    name: 'Answer',
    props: [
      'question',
      'answer',
      'no_answer',
      'question_id',
      'not_yet_entered'
    ],
    computed: {
      checkQuestion () {
        return NOT_YET_ENTERED_QUESTIONS.includes(this.question_id)
      }
    }
  }
</script>
