import activity from './activity'
import nav from './nav'
import orders from './orders'
import operationReason from './operation-reason'
import pageTitle from './page-title'
import patient from './patient'
import product from './product'
import setting from './setting'
import staff from './staff'
import reservation from './reservation'
import questionnaire from './questionnaire'
import allocation from './allocation'
import whitelist from './whitelist'

export default {
  activity,
  allocation,
  operation_reason: operationReason,
  nav,
  orders,
  questionnaire,
  page_title: pageTitle,
  patient,
  product,
  setting,
  staff,
  reservation,
  whitelist
}
