var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["interview-input-block__header", _vm.className] },
    [
      _vm.label ? _c("span", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
      _vm._v(" "),
      _c("p", {
        class: ["arrow", _vm.checked ? "toggle-icon" : ""],
        on: {
          click: function($event) {
            return _vm.handleClick($event, _vm.checked)
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }