<template>
  <div class="page page-print">
    <main class="patient-detail-page patient-detail-print interview-print">
      <div class="col-12 main-content">
        <section class="content">
          <h3 class="text-center pt-4 mb-0">
            <p class="border-bottom-print m-0 p-0 d-inline-block page-print__header">
              矯正歯科初診問診票
            </p>
          </h3>
          <div class="content-wrapper content-wrapper--interview-print">
            <div class="row">
              <div class="col-5 d-flex pr-0">
                <div class="d-flex flex-column w-100">
                  <div class="pt-25 padding-left-2">
                    <p class="border-bottom-print pl-10 ls-2 fs-15">
                      予約日時: <span class="ml-4">{{ patientDateBooking }}</span>
                    </p>
                  </div>
                  <div class="padding-left-2">
                    <p class="border-bottom-print pl-10 ls-2 fs-15">
                      患者ID: <span class="ml-4">{{ patient.sf_user_id }}</span>
                    </p>
                  </div>
                  <h4 class="fs-18 margin-top-3">◆患者様情報</h4>
                </div>
              </div>
              <div class="col-7 pl-52">
                <InterviewCheckList />
              </div>
              <div class="col-md-12 patient-detail-left patient-info-interview mt-0">
                <div class="print-component component-wrapper interview-checklist">
                  <div class="bg-white">
                    <table class="table table-interview-check-list table-interview-check-list--patient-info">
                      <tbody>
                        <tr>
                          <td
                            width="116px"
                            class="bg-highlight"
                          >
                            ふりがな
                          </td>
                          <td width="337px">
                            {{ isEmpty(patient_info) ? '' : truncateContent(`${patient_info.kana_last_name} ${patient_info.kana_first_name}`) }}
                          </td>
                          <td
                            width="88px"
                            class="bg-highlight text-center"
                          >
                            生年月日
                          </td>
                          <td
                            width="150px"
                            colspan="3"
                          >
                            <div class="d-flex justify-content-between align-items-center">
                              <p class="m-0">
                                （西暦）
                              </p>
                              <div class="d-flex justify-content-end pr-2">
                                <span class="mr-5">年</span>
                                <span class="mx-3">月</span>
                                <span class="ml-5">日</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="bg-highlight">
                            ローマ字
                          </td>
                          <td />
                          <td class="bg-highlight text-center">
                            年齢
                          </td>
                          <td class="col-data" />
                          <td
                            rowspan="2"
                            class="col-data text-center align-middle bg-highlight"
                          >
                            <p class="m-0">
                              女性
                            </p>
                            <p class="m-0">
                              のみ
                            </p>
                          </td>
                          <td
                            rowspan="2"
                            class="col-data text-center align-middle"
                          >
                            <p class="m-0">
                              現在妊娠中ですか?
                            </p>
                            <p class="m-0">
                              はい・いいえ
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td class="bg-highlight">
                            氏名
                          </td>
                          <td>{{ isEmpty(patient_info) ? '' : truncateContent(`${patient_info.last_name} ${patient_info.first_name}`) }}</td>
                          <td class="bg-highlight text-center">
                            性別
                          </td>
                          <td class="col-data">
                            <div class="d-flex justify-content-center">
                              <checkbox-common
                                label="女性"
                              />
                              <checkbox-common
                                class="ml-4"
                                label="男性"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            rowspan="2"
                            class="bg-highlight align-middle"
                          >
                            ご住所
                          </td>
                          <td
                            rowspan="2"
                            class="font-weight-bold text-left"
                          >
                            〒
                          </td>
                          <td class="bg-highlight text-center">
                            携帯電話
                          </td>
                          <td
                            colspan="3"
                            class="col-data text-left"
                          >
                            {{ patient_info.tel }}
                          </td>
                        </tr>
                        <tr>
                          <td class="bg-highlight text-center align-middle">
                            ご職業
                          </td>
                          <td
                            colspan="3"
                            class="col-data"
                          >
                            <div class="d-flex flex-column">
                              <div class="d-flex">
                                <checkbox-common
                                  label="会社員"
                                />
                                <checkbox-common
                                  class="ml-1"
                                  label="自営業"
                                />
                                <checkbox-common
                                  class="ml-1"
                                  label="学生"
                                />
                                <checkbox-common
                                  class="ml-1"
                                  label="主婦・主夫"
                                />
                              </div>
                              <div class="d-flex">
                                <checkbox-common
                                  label="その他"
                                />
                                <div class="d-flex items-center justify-content-between w-66">
                                  <span>&#12288;(</span><span>)</span>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <h4 class="fs-17 lh-15">
                      未成年の方は保護者の方の情報をご記入ください
                    </h4>
                  </div>
                  <div class="bg-white">
                    <table class="table table-interview-check-list table-interview-check-list--patient-info">
                      <tbody>
                        <tr>
                          <td
                            width="128px"
                            class="bg-highlight"
                          >
                            ふりがな
                          </td>
                          <td
                            width="324px"
                            class="font-weight-bold"
                          />
                          <td
                            width="88px"
                            class="bg-highlight text-center"
                          >
                            続柄
                          </td>
                          <td
                            width="150px"
                            colspan="3"
                          />
                        </tr>
                        <tr>
                          <td
                            class="bg-highlight"
                          >
                            氏名
                          </td>
                          <td
                            class="font-weight-bold"
                          />
                          <td class="bg-highlight text-center">
                            ご連絡先
                          </td>
                          <td
                            colspan="3"
                          />
                        </tr>
                        <tr>
                          <td
                            class="bg-highlight"
                          >
                            <p class="m-0">
                              ご住所
                            </p>
                            <p class="fs-13 lh-15 m-0">お住いが別の場合</p>
                          </td>
                          <td
                            rowspan="2"
                            colspan="3"
                            class="font-weight-bold text-left"
                          >
                            〒
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <div class="pb-2">
                  <h4>◆ご相談内容</h4>
                </div>
                <div class="bg-white pb-2">
                  <div class="d-flex">
                    <checkbox-common
                      class="ml-4 fs-18"
                      label="矯正歯科治療"
                    />
                    <checkbox-common
                      class="ml-4 fs-18"
                      label="ホワイトニング"
                    />
                    <checkbox-common
                      class="ml-4 fs-18"
                      label="クリーニング"
                    />
                    <checkbox-common
                      class="ml-4 fs-18"
                      label="その他"
                    />
                    <div class="d-flex justify-content-between w-25">
                      <span>&#12288;(</span>
                      <span>)</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 pl-4">
                <div class="pl-3">
                  <h4 class="fs-17 ls-06 m-0 padding-top-6">ご相談内容・気になる症状について</h4>
                  <div
                    v-for="i in 2"
                    :key="i"
                    class="row"
                  >
                    <div class="col-12">
                      <p class="m-0 fs-17 ls-20">
                        優先順位{{ i }}
                      </p>
                      <div class="row">
                        <div class="col-4">
                          <label class="fs-16 padding-top-6">1 &nbsp;. 部位</label>
                          <div class="d-flex pl-7 padding-top-4">
                            <div
                              v-for="(location, index) in locations"
                              :key="index"
                              class="d-flex"
                              :class="[{
                                'ml-25': index >= 1
                              }]"
                            >
                              <div class="d-flex flex-column">
                                <checkbox-common :label="location.label" class="fs-16" />
                                <div class="ml-3">
                                  <checkbox-common
                                    v-for="(subOption, idx) in location.options"
                                    :key="idx"
                                    :label="subOption.label"
                                    class="fs-16 padding-top-3"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-8">
                          <p class="m-0 dentition-number padding-top-6">
                            歯列番号
                          </p>
                          <div class="row padding-top-6">
                            <div class="col-6 p-0 d-flex">
                              <div class="d-flex align-items-end w-100">
                                <p class="m-0 bb-2 part__label pb-2">
                                  右上
                                </p>
                                <div class="d-flex flex-row-reverse w-100 part-detail">
                                  <div
                                    v-for="item in dentition.UL"
                                    :key="item.value"
                                    class="d-flex part-detail-item bb-2"
                                  >
                                    <checkbox-common />
                                    <span class="fs-15">{{ item.value }}</span>
                                  </div>
                                </div>
                                <span class="part-detail--border-right" />
                              </div>
                            </div>
                            <div class="col-6 pr-3 pl-0 d-flex">
                              <div class="d-flex w-100 align-items-end">
                                <div class="d-flex w-100 part-detail part-detail--end">
                                  <div
                                    v-for="item in dentition.UR"
                                    :key="item.value"
                                    class="d-flex part-detail-item bb-2"
                                  >
                                    <checkbox-common />
                                    <span class="fs-15">{{ item.value }}</span>
                                  </div>
                                </div>
                                <p class="m-0 bb-2 part__label pb-2">
                                  左上
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6  p-0 d-flex">
                              <div class="d-flex align-items-start w-100">
                                <p class="m-0 part__label part__label--ll pt-2">
                                  右下
                                </p>
                                <div class="d-flex flex-row-reverse w-100 part-detail part-detail--bottom">
                                  <div
                                    v-for="item in dentition.LL"
                                    :key="item.value"
                                    class="d-flex part-detail-item"
                                  >
                                    <span class="fs-15">{{ item.value }}</span>
                                    <checkbox-common />
                                  </div>
                                </div>
                                <span class="part-detail--border-right" />
                              </div>
                            </div>
                            <div class="col-6 pr-3 pl-0 d-flex">
                              <div class="d-flex w-100 align-item-start">
                                <div class="d-flex w-100 part-detail part-detail--end part-detail--bottom">
                                  <div
                                    v-for="item in dentition.LR"
                                    :key="item.value"
                                    class="d-flex part-detail-item"
                                  >
                                    <span class="fs-15">{{ item.value }}</span>
                                    <checkbox-common />
                                  </div>
                                </div>
                                <p class="m-0 part__label pt-2">
                                  左下
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <label class="fs-16">2 &nbsp;. 症状</label>
                          <div class="row">
                            <div class="col-10">
                              <div class="padding-left-10">
                                <div class="d-flex flex-wrap">
                                  <checkbox-common
                                    v-for="symptom in symptoms"
                                    :key="symptom.id"
                                    class-name="fs-16 ls-1 mr-22"
                                    :label="symptom.value"
                                  />
                                  <div class="d-flex w-100">
                                    <checkbox-common label="その他" class-name="fs-16 ls-10" />
                                    <div class="d-flex align-items-center justify-content-between w-50">
                                      <span>&#12288;(</span>
                                      <span>)</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <label class="fs-16">3 &nbsp;. &nbsp;気になる部位・症状について</label>
                          <div class="col-12">
                            <textarea
                              class="border-white"
                              style="resize: none;"
                              rows="3"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="clear: both"></div>
                </div>
              </div>
            </div>
            <div
              class="row page-break pt-50"
            >
              <div class="col-12 pl-4">
                <div class="pl-3">
                  <p class="m-0 fs-17 ls-20">
                    優先順位3
                  </p>
                  <div class="row">
                    <div class="col-4">
                      <label class="fs-16 padding-top-6">1. 部位</label>
                      <div class="d-flex pl-3">
                        <div
                          v-for="(location, index) in locations"
                          :key="index"
                          class="d-flex fs-16"
                          :class="[{
                            'ml-25': index >= 1
                          }]"
                        >
                          <div class="d-flex flex-column">
                            <checkbox-common :label="location.label" />
                            <div class="ml-3">
                              <checkbox-common
                                v-for="(subOption, idx) in location.options"
                                :key="idx"
                                :label="subOption.label"
                                class-name="fs-16 padding-top-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-8">
                      <p class="m-0 dentition-number">
                        歯列番号
                      </p>
                      <div class="row padding-top-6">
                        <div class="col-6 p-0 d-flex">
                          <div class="d-flex align-items-end w-100">
                            <p class="m-0 bb-2 part__label pb-2">
                              右上
                            </p>
                            <div class="d-flex flex-row-reverse w-100 part-detail">
                              <div
                                v-for="item in dentition.UL"
                                :key="item.value"
                                class="d-flex part-detail-item bb-2"
                              >
                                <checkbox-common />
                                <span>{{ item.value }}</span>
                              </div>
                            </div>
                            <span class="part-detail--border-right" />
                          </div>
                        </div>
                        <div class="col-6 pr-3 pl-0 d-flex">
                          <div class="d-flex w-100 align-items-end">
                            <div class="d-flex w-100 part-detail part-detail--end">
                              <div
                                v-for="item in dentition.UR"
                                :key="item.value"
                                class="d-flex part-detail-item bb-2"
                              >
                                <checkbox-common />
                                <span>{{ item.value }}</span>
                              </div>
                            </div>
                            <p class="m-0 bb-2 part__label pb-2">
                              左上
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6  p-0 d-flex">
                          <div class="d-flex align-items-start w-100">
                            <p class="m-0 part__label part__label--ll pt-2">
                              右下
                            </p>
                            <div class="d-flex flex-row-reverse w-100 part-detail part-detail--bottom">
                              <div
                                v-for="item in dentition.LL"
                                :key="item.value"
                                class="d-flex part-detail-item"
                              >
                                <span>{{ item.value }}</span>
                                <checkbox-common />
                              </div>
                            </div>
                            <span class="part-detail--border-right" />
                          </div>
                        </div>
                        <div class="col-6 pr-3 pl-0 d-flex">
                          <div class="d-flex w-100 align-item-start">
                            <div class="d-flex w-100 part-detail part-detail--end part-detail--bottom">
                              <div
                                v-for="item in dentition.LR"
                                :key="item.value"
                                class="d-flex part-detail-item"
                              >
                                <span>{{ item.value }}</span>
                                <checkbox-common />
                              </div>
                            </div>
                            <p class="m-0 part__label pt-2">
                              左下
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label>2. 症状</label>
                      <div class="row">
                        <div class="col-10">
                          <div class="padding-left-10">
                            <div class="d-flex flex-wrap">
                              <checkbox-common
                                v-for="symptom in symptoms"
                                :key="symptom.id"
                                class-name="fs-16 ls-10 mr-22"
                                :label="symptom.value"
                              />
                              <div class="d-flex w-100">
                                <checkbox-common label="その他" class-name="fs-16" />
                                <div class="d-flex align-items-center justify-content-between w-40">
                                  <span>&#12288;(</span>
                                  <span>)</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label>3. 気になる部位・症状について</label>
                      <div class="col-12">
                        <textarea
                          class="border-white"
                          style="resize: none;"
                          rows="1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="mt-14">
                  <h4 class="fs-18">◆事前問診&#12288;(※はい・いいえの該当する方を丸で囲み、具体的な内容についてご記入ください。)</h4>
                </div>
              </div>
              <div class="col-12">
                <div class="question-list">
                  <div class="d-flex w-100">
                    <div class="print-component component-wrapper interview-checklist w-100">
                      <div class="bg-white">
                        <div class="table-list">
                          <div class="d-flex">
                            <div class="w-50 d-flex table-print-left" >
                              <div class="d-flex w-100 flex-column">
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell is-first p-2">
                                      <p>他の医院で歯並びの相談をされた事はありますか?</p>
                                      <div class="ml-12">
                                        <p class="border-bottom-print">
                                          いつ頃
                                        </p>
                                        <p class="border-bottom-print">
                                          医院名
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="table-list__cell is-first w-20 text-center d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2">
                                      <p class="fs-15">アレルギーはありますか?</p>
                                      <div class="ml-12">
                                        <div class="d-flex flex-column">
                                          <checkbox-common
                                            label="金属アレルギー"
                                            class="mb-4 fs-15"
                                          />
                                          <div class="d-flex justify-content-between w-100 mb-13 fs-15 margin-top-6">
                                            <span>(金属名:</span>
                                            <span>)</span>
                                          </div>
                                          <checkbox-common
                                            label="薬アレルギー"
                                            class="mb-4 fs-15"
                                          />
                                          <div class="d-flex justify-content-between w-100 mb-13 fs-15 margin-top-6">
                                            <span>(薬品名:</span>
                                            <span>)</span>
                                          </div>
                                          <checkbox-common
                                            label="花粉症"
                                            class="mb-4 fs-15"
                                          />
                                          <div class="d-flex justify-content-between w-100 mb-13 margin-top-6">
                                            <span>(発症時期:</span>
                                            <span>原因植物:</span>
                                            <span>)</span>
                                          </div>
                                          <checkbox-common
                                            label="その他のアレルギー"
                                            class="mb-4 fs-15"
                                          />
                                          <div class="d-flex justify-content-between w-100 mb-13 fs-15 margin-top-6">
                                            <span>(原因物質:</span>
                                            <span>)</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2">
                                      <p>今までに口や顔の周りの癖はありましたか？</p>
                                      <div class="ml-12 d-flex flex-wrap">
                                        <div class="d-flex margin-bottom-7">
                                          <checkbox-common
                                            label="指しゃぶり"
                                            class-name="mr-4"
                                          />
                                          <checkbox-common
                                            label="爪かみ"
                                            class-name="mr-4"
                                          />
                                          <checkbox-common label="唇かみ" />
                                        </div>
                                        <div class="d-flex margin-bottom-7">
                                          <checkbox-common
                                            label="舌を出す"
                                            class-name="mr-4"
                                          />
                                          <checkbox-common label="口が開いていることが多い" />
                                        </div>
                                        <div class="d-flex margin-bottom-7">
                                          <checkbox-common
                                            label="頬づえ"
                                            class-name="mr-4"
                                          />
                                          <checkbox-common
                                            label="歯ぎしり"
                                            class-name="mr-4"
                                          />
                                          <checkbox-common
                                            label="いびき"
                                            class-name="mr-4"
                                          />
                                        </div>
                                        <div class="d-flex align-items-center w-100 margin-bottom-10">
                                          <checkbox-common label="その他" />
                                          <div class="d-flex align-items-center justify-content-between w-other-custom">
                                            <span>&#12288;(</span>
                                            <span>)</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2">
                                      <p class="mt-23 mb-11">喋りにくい言葉はありますか?</p>
                                      <p>(サ行、タ行が言いづらいなど)</p>
                                      <div class="ml-12">
                                        <p class="border-bottom-print py-2" />
                                        <p class="border-bottom-print py-2 mb-39" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex flex-grow-1">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2 h-100">
                                      <p class="mt-7 mb-10">寝相はどの状態が多いですか?</p>
                                      <div class="ml-12 d-flex flex-column">
                                        <checkbox-common
                                          class-name="mr-2"
                                          label="仰向け"
                                        />
                                        <div class="d-flex align-items-center mt-8">
                                          <checkbox-common
                                            class-name="mr-4"
                                            label="うつ伏せ"
                                          />
                                          <p class="m-0 ml-2 mr-3">
                                            首の向き
                                          </p>
                                          <checkbox-common
                                            class-name="mx-4"
                                            label="左"
                                          />
                                          <checkbox-common
                                            class-name="mr-4"
                                            label="右"
                                          />
                                        </div>
                                        <div class="d-flex align-items-center mt-8">
                                          <checkbox-common
                                            class-name="mr-5"
                                            label="横向き"
                                          />
                                          <p class="m-0 mr-5">
                                            向き
                                          </p>
                                          <checkbox-common
                                            class-name="mx-4"
                                            label="左"
                                          />
                                          <checkbox-common
                                            class-name="mr-4"
                                            label="右"
                                          />
                                        </div>
                                        <checkbox-common label="様々寝相が変わる" class-name="mt-8 mb-18" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="w-50 d-flex table-print-right" >
                              <div class="d-flex w-100 flex-column">
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell is-first p-2">
                                      <p class="fs-15">過去に矯正歯科治療された事はありますか?</p>
                                      <div class="ml-12">
                                        <p class="border-bottom-print">
                                          いつ頃
                                        </p>
                                        <p class="border-bottom-print">
                                          症状
                                        </p>
                                      </div>
                                      <div class="d-flex flex-column ml-2">
                                        <div class="d-flex">
                                          <p class="my-0">
                                            矯正装置
                                          </p>
                                          <checkbox-common
                                            label="マウスピース　(アライナー)"
                                            class-name="ml-4"
                                          />
                                        </div>
                                        <div class="d-flex my-5-10">
                                          <checkbox-common
                                            label="ワイヤー"
                                            class-name="mr-1"
                                          />
                                          <div class="d-flex align-items-center w-other-custom">
                                            <checkbox-common label="その他" />
                                            <div class="d-flex align-items-center justify-content-between w-other-custom1">
                                              <span>&#12288;(</span>
                                              <span>)</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="w-20 text-center table-list__cell is-first d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2">
                                      <p>顎の症状で、今まで経験したものはありますか?</p>
                                      <div class="ml-12">
                                        <p class="border-bottom-print">
                                          いつ頃
                                        </p>
                                        <div class="d-flex flex-column align-items-baseline">
                                          <checkbox-common
                                            class-name="d-flex flex-column align-items-baseline"
                                            label="顎の関節痛"
                                          />
                                          <checkbox-common label="顎の関節音(ガクガク, ゴリゴリなど)" class-name="margin-top-5" />
                                        </div>
                                        <div class="d-flex margin-top-8">
                                          <checkbox-common
                                            label="口が開きづらい"
                                            class-name="mr-4"
                                          />
                                          <checkbox-common label="口が閉じづらい" />
                                        </div>
                                        <div class="d-flex w-100 margin-top-8 margin-bottom-10">
                                          <checkbox-common label="その他" />
                                          <div class="d-flex align-items-center justify-content-between w-other-custom mb-1">
                                            <span>&#12288;(</span>
                                            <span>)</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2">
                                      <p class="qs-title">今まで顔や顎を強くぶつけた・ケガをした経験はあり ますか?</p>
                                      <div class="ml-12">
                                        <p class="border-bottom-print">
                                          いつ頃
                                        </p>
                                        <p class="border-bottom-print">
                                          部位
                                        </p>
                                        <p class="border-bottom-print mb-29">
                                          対処法
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2">
                                      <p class="mb-11">次のような症状はありますか?</p>
                                      <div class="ml-12 d-flex flex-wrap mb-12">
                                        <checkbox-common
                                          label="頭痛"
                                          class-name="mr-4"
                                        />
                                        <checkbox-common
                                          label="肩こり"
                                          class-name="mr-4"
                                        />
                                        <checkbox-common
                                          label="腰痛"
                                          class-name="mr-4"
                                        />
                                        <checkbox-common label="めまい" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2">
                                      <p class="mt-17 mb-11">家族に不正咬合の方はいらっしゃいますか?</p>
                                      <p>例:でこぼこ、出っ歯、反っ歯、受け口など</p>
                                      <div class="ml-12">
                                        <div
                                          v-for="i in 3"
                                          :key="i"
                                          class="d-flex"
                                        >
                                          <span>{{ i }}</span>
                                          <div :class="['pl-2 d-flex flex-column w-100', {'margin-top-6': i === 3}]">
                                            <p class="border-bottom-print">
                                              続柄
                                            </p>
                                            <p class="border-bottom-print">
                                              症状
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row page-break pt-50">
              <div class="col-12">
                <div class="question-list">
                  <div class="d-flex w-100">
                    <div class="print-component component-wrapper interview-checklist w-100">
                      <div class="bg-white">
                        <div class="table-list">
                          <div class="d-flex">
                            <div class="w-50 d-flex table-print-left">
                              <div class="d-flex w-100 flex-column">
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell is-first p-2">
                                      <p>今まで耳鼻科を受診したことはありますか？</p>
                                      <div class="ml-12">
                                        <div
                                          v-for="i in 3"
                                          :key="i"
                                          class="d-flex mb-16"
                                        >
                                          <span>{{ i }}</span>
                                          <div class="d-flex flex-column flex-grow-1">
                                            <div class="pl-2 d-flex flex-column w-100">
                                              <p class="border-bottom-print mb-11">
                                                診断名
                                              </p>
                                              <p class="border-bottom-print">
                                                いつ頃
                                              </p>
                                              <p class="border-bottom-print mb-10">
                                                治療内容
                                              </p>
                                            </div>
                                            <div class="pl-2 d-flex">
                                              <checkbox-common
                                                label="完治"
                                                class-name="mr-2"
                                              />
                                              <checkbox-common
                                                label="現在通院中"
                                                class-name="mr-2"
                                              />
                                              <checkbox-common label="症状が出たとき毎に通院" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="table-list__cell is-first w-20 text-center d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2">
                                      <p>今までにかかった病気はありますか?</p>
                                      <div class="ml-12">
                                        <div class="d-flex flex-column">
                                          <div class="d-flex">
                                            <checkbox-common
                                              label="糖尿病"
                                              class-name="mr-4"
                                            />
                                            <checkbox-common
                                              label="心臓病"
                                              class-name="mr-4"
                                            />
                                            <checkbox-common
                                              label="高血圧"
                                              class-name="mr-4"
                                            />
                                            <checkbox-common label="低血糖" />
                                          </div>
                                          <div class="d-flex mt-6">
                                            <checkbox-common
                                              label="腎臓病"
                                              class-name="mr-4"
                                            />
                                            <checkbox-common
                                              label="肝臓病"
                                              class-name="mr-4"
                                            />
                                            <checkbox-common
                                              label="結核"
                                              class-name="mr-4"
                                            />
                                            <checkbox-common
                                              label="肝炎"
                                              class-name="ml-3"
                                            />
                                          </div>
                                          <div class="d-flex align-items-center w-100 mb-3 mt-8">
                                            <checkbox-common label="その他" />
                                            <div class="d-flex align-items-center justify-content-between w-other-custom mb-1">
                                              <span>&#12288;(</span>
                                              <span>)</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2">
                                      <p>今までに次のようなことがありましたか?</p>
                                      <div class="ml-12">
                                        <div class="d-flex">
                                          <div class="d-flex flex-column mr-4">
                                            <checkbox-common label="傷が化膿しやすい" class-name="margin-bottom-8" />
                                            <checkbox-common label="蕁麻疹が出やすい" class-name="margin-bottom-8" />
                                            <checkbox-common label="口内炎が出やすい" class-name="margin-bottom-8" />
                                            <checkbox-common label="下痢しやすい" class-name="margin-bottom-8" />
                                            <checkbox-common label="風邪をひきやすい" class-name="margin-bottom-8" />
                                            <checkbox-common label="無カタラーゼ症" class-name="margin-bottom-8" />
                                          </div>
                                          <div class="d-flex flex-column">
                                            <checkbox-common label="血が止まりにくい" class-name="margin-bottom-8" />
                                            <checkbox-common label="湿疹が出やすい" class-name="margin-bottom-8" />
                                            <checkbox-common label="歯が染みやすい" class-name="margin-bottom-8" />
                                            <checkbox-common label="喘息がある" class-name="margin-bottom-8" />
                                            <checkbox-common label="薬を飲むと胃が痛くなる" class-name="margin-bottom-8" />
                                            <checkbox-common label="光線過敏症" class-name="margin-bottom-8" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2">
                                      <p>喫煙習慣はありますか？</p>
                                      <div class="ml-12 mt-17">
                                        <div class="d-flex">
                                          <span class="mr-2">一日量:</span>
                                          <div class="d-flex w-75">
                                            <p class="w-75 py-2 border-bottom-print" />
                                            <span>本/日</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2 h-100">
                                      <p class="py-3">飲酒習慣はありますか?</p>
                                      <div class="ml-12">
                                        <div class="d-flex margin-bottom-6">
                                          <p>よく飲まれるお酒：</p>
                                          <div class="my-3 border-bottom-print w-50" />
                                        </div>
                                        <div class="d-flex">
                                          <span class="mr-2">一日量:</span>
                                          <div class="d-flex w-75">
                                            <p class="w-30 py-2 border-bottom-print" />
                                            <span>ml&nbsp;または</span>
                                            <p class="w-30 border-bottom-print" />
                                            <p>合位</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2">
                                      <div class="d-flex py-16">
                                        <span class="mr-2">身長:</span>
                                        <div class="d-flex w-75">
                                          <p class="w-30 py-2 border-bottom-print" />
                                          <span>cm&nbsp;体重</span>
                                          <p class="w-30 border-bottom-print" />
                                          <p>kg</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="table-list__cell w-20 text-center align-middle p-6 qs-right" />
                                </div>
                                <div class="d-flex flex-grow-1">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2 h-100">
                                      <p class="margin-bottom-8">血圧の状態は?</p>
                                      <div class="ml-12">
                                        <div class="d-flex">
                                          <checkbox-common
                                            class-name="mr-4"
                                            label="高い"
                                          />
                                          <checkbox-common
                                            class-name="mr-4"
                                            label="普通"
                                          />
                                          <checkbox-common
                                            class-name="mr-4"
                                            label="低い"
                                          />
                                          <checkbox-common
                                            label="不明"
                                          />
                                        </div>
                                        <p class="border-bottom-print my-16-19">
                                          最高
                                        </p>
                                        <p class="border-bottom-print">
                                          最低
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="table-list__cell w-20 text-center d-flex flex-column justify-content-center qs-right" />
                                </div>
                              </div>
                            </div>
                            <div class="w-50 d-flex table-print-right">
                              <div class="d-flex w-100 flex-column">
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell is-first p-2">
                                      <p>現在、通院されていますか?</p>
                                      <div class="ml-12">
                                        <p class="border-bottom-print mb-11">
                                          病院名
                                        </p>
                                        <p class="border-bottom-print">
                                          受診科名
                                        </p>
                                        <p class="border-bottom-print mb-10">
                                          主治医
                                        </p>
                                        <p>
                                          診断名・症状
                                        </p>
                                        <p class="border-bottom-print mt-27 mb-22" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="w-20 text-center table-list__cell is-first d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2">
                                      <p class="qs-title mtn-6">
                                        身体全体で先天性の疾患や生まれつき気になる事、部位はありますか?
                                      </p>
                                      <div class="ml-12">
                                        <div
                                          v-for="i in 3"
                                          :key="i"
                                          class="d-flex mb-10"
                                        >
                                          <span>{{ i }}</span>
                                          <div class="pl-2 d-flex flex-column w-100">
                                            <p class="border-bottom-print mb-10">
                                              診断名
                                            </p>
                                            <p class="border-bottom-print">
                                              部位
                                            </p>
                                            <p class="border-bottom-print mb-10">
                                              状態
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2 h-100 pb-19">
                                      <p class="m-0">
                                        現在飲んでいる薬はありますか?
                                      </p>
                                      <div class="ml-12">
                                        <div class="d-flex flex-column">
                                          <div class="d-flex mt-10">
                                            <checkbox-common
                                              label="鎮痛剤 (痛み止め)"
                                              class-name="mr-4"
                                            />
                                            <checkbox-common label="ビタミン剤" />
                                          </div>
                                          <div class="d-flex mt-8">
                                            <checkbox-common
                                              label="ホルモン剤"
                                              class-name="mr-4"
                                            />
                                            <checkbox-common
                                              label="血圧の薬"
                                              class-name="mr-4"
                                            />
                                            <checkbox-common label="糖尿病の薬" />
                                          </div>
                                          <checkbox-common label="その他" class-name="my-8" />
                                        </div>
                                        <div
                                          v-for="i in 5"
                                          :key="i"
                                          class="d-flex margin-bottom-6"
                                        >
                                          <span>{{ i }}</span>
                                          <div class="pl-2 d-flex flex-column w-100">
                                            <p class="mb-95">
                                              薬品名
                                            </p>
                                            <div class="d-flex">
                                              <span class="mr-4">服薬量:</span>
                                              <div class="d-flex w-75">
                                                <span>1日</span>
                                                <p class="w-25 m-0 border-bottom-print" />
                                                <span>回</span>
                                                <p class="w-25 m-0 border-bottom-print" />
                                                <p class="m-0">
                                                  錠/包/ml
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right">
                                    <p>はい</p>
                                    <p>いいえ</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2">
                                      <p>血液型;</p>
                                      <div class="ml-12">
                                        <div class="d-flex margin-bottom-8">
                                          <checkbox-common
                                            class-name="mr-4"
                                            label="A"
                                          />
                                          <checkbox-common
                                            class-name="mr-4"
                                            label="B"
                                          />
                                          <checkbox-common
                                            class-name="mr-4"
                                            label="O"
                                          />
                                          <checkbox-common
                                            class-name="mr-4"
                                            label="AB"
                                          />
                                          <checkbox-common
                                            label="不明"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right" />
                                </div>
                                <div class="d-flex">
                                  <div class="qs-left">
                                    <div class="table-list__cell p-2 py-43">
                                      <p>女性の方のみ</p>
                                      <div class="ml-12">
                                        <div class="d-flex">
                                          <span>初潮年齢</span>
                                          <p class="w-25 py-2 border-bottom-print" />
                                          <span>歳</span>
                                          <p class="w-25 py-2 border-bottom-print" />
                                          <span>ヶ月</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row page-break pt-15">
              <div class="col-12 pt-4">
                <div class="print-component component-wrapper interview-checklist interview-print-other mt-4">
                  <div class="bg-white">
                    <table class="table table-interview-check-list table-interview-check-list--bottom">
                      <tbody>
                        <tr>
                          <td class="pb-31 w-custom">
                            <p class="mt-14">以前に歯科医院で歯を抜いたことがありますか?</p>
                            <div class="ml-12">
                              <p class="mt-11">
                                そのとき異常はありましたか?
                              </p>
                              <div class="d-flex mt-10">
                                <checkbox-common
                                  label="気分が悪くなった"
                                  class-name="mr-4"
                                />
                                <checkbox-common label="血が止まりにくくなった" />
                              </div>
                              <div class="d-flex align-items-center w-100 margin-top-6">
                                <checkbox-common label="その他" />
                                <div class="d-flex align-items-center justify-content-between w-other-custom mb-1">
                                  <span>&#12288;(</span>
                                  <span>)</span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td
                            width="70px"
                            class="align-middle text-center"
                          >
                            <p>はい</p>
                            <p>いいえ</p>
                          </td>
                          <td class="w-custom">
                            <p class="margin-bottom-8">
                              最近、歯科医院を受診されましたか?
                            </p>
                            <div class="ml-12">
                              <div class="d-flex">
                                <p class="w-10 py-2 border-bottom-print" />
                                <span>年</span>
                                <p class="w-10 py-2 border-bottom-print" />
                                <span>月ごろまたは</span>
                                <p class="w-10 py-2 border-bottom-print" />
                                <span>年</span>
                                <p class="w-10 py-2 border-bottom-print" />
                                <span>ヶ月前</span>
                              </div>
                            </div>
                            <p class="mt-10">治療内容</p>
                            <div class="ml-12">
                              <p class="pt-4 border-bottom-print" />
                              <span>&#12288;</span>
                              <p class="padding-bottom-6 border-bottom-print" />
                            </div>
                          </td>
                          <td
                            width="70px"
                            class="align-middle text-center"
                          >
                            <p>はい</p>
                            <p>いいえ</p>
                          </td>
                        </tr>
                        <tr>
                          <td class="pb-19">
                            <p class="margin-bottom-8">転居の予定はありますか?</p>
                            <div class="ml-12">
                              <div class="d-flex">
                                <p class="w-25 py-2 border-bottom-print" />
                                <span>年</span>
                                <p class="w-25 py-2 border-bottom-print" />
                                <span>月ごろ</span>
                              </div>
                              <p class="mb-4">
                                転居先
                              </p>
                              <p class="py-2 border-bottom-print" />
                            </div>
                          </td>
                          <td class="align-middle text-center">
                            <p>はい</p>
                            <p>いいえ</p>
                          </td>
                          <td colspan="2" />
                        </tr>
                        <tr>
                          <td colspan="4">
                            <p>その他、診療に際してご要望などございましたらご記入ください。</p>
                            <textarea
                              class="border-white"
                              style="resize: none;"
                              rows="5"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-end">
                      <p>ありがとうございました</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script>
  import InterviewCheckList from '../../components/patient/interview/print/InterviewCheckListPrint'
  import CheckboxCommon from '../../components/patient/interview/template/CheckboxCommon'
  import { truncateContent } from '../../utils/helpers'
  import moment from 'moment'
  import { isEmpty } from 'lodash'

  export default {
    components: { InterviewCheckList, CheckboxCommon },
    props: ['patientInfo'],
    data () {
      return {
        locations: [
          {
            value: '全体',
            label: '全体',
            options: [
              { value: '全体', label: '全体' },
              { value: '右側', label: '右側' },
              { value: '左側', label: '左側' }
            ]
          },
          {
            value: '上顎',
            label: '上顎',
            options: [
              { value: '全体', label: '全体' },
              { value: '右側', label: '右側' },
              { value: '左側', label: '左側' }
            ]
          },
          {
            value: '下顎',
            label: '下顎',
            options: [
              { value: '全体', label: '全体' },
              { value: '右側', label: '右側' },
              { value: '左側', label: '左側' }
            ]
          }
        ],
        dentition: {
          UL: [
            { value: '1' },
            { value: '2' },
            { value: '3' },
            { value: '4' },
            { value: '5' },
            { value: '6' },
            { value: '7' },
            { value: '8' }
          ],
          LL: [
            { value: '1' },
            { value: '2' },
            { value: '3' },
            { value: '4' },
            { value: '5' },
            { value: '6' },
            { value: '7' },
            { value: '8' }
          ],
          UR: [
            { value: '1' },
            { value: '2' },
            { value: '3' },
            { value: '4' },
            { value: '5' },
            { value: '6' },
            { value: '7' },
            { value: '8' }
          ],
          LR: [
            { value: '1' },
            { value: '2' },
            { value: '3' },
            { value: '4' },
            { value: '5' },
            { value: '6' },
            { value: '7' },
            { value: '8' }
          ]
        },
        symptoms: [
          { id: 1, value: 'ガタガタした歯並びや八重歯　(叢生)' },
          { id: 2, value: '出っ歯　(上顎前突)' },
          { id: 3, value: '受け口　(下顎前突)' },
          { id: 4, value: 'すきっ歯　(歯隙歯列)' },
          { id: 5, value: '前歯が閉じない　(開咬)' },
          { id: 6, value: '中心がずれている　(正中不一致)' },
          { id: 7, value: '噛み合わせが深い　(過蓋咬合)' },
          { id: 8, value: 'ガミースマイル' },
          { id: 9, value: '口ゴボ' }
        ],
        patient_info: {},
        interview_info: {},
        patient: {},
        first_time: {},
        is_interview_fetched: false,
        patient_id: this.$route.params.id
      }
    },
    computed: {
      patientDateBooking () {
        if (this.first_time) {
          const startedTo = this.first_time.started_to
          const endedFrom = this.first_time.ended_from
          if (startedTo && endedFrom) {
            const dateStarted = moment(startedTo).format('YYYY年MM月DD日')
            const timeStarted = moment(startedTo).format('HH:mm')
            const timeEnded = moment(endedFrom).format('HH:mm')
            return `${dateStarted} ${timeStarted} - ${timeEnded}`
          }
        }
        return ''
      }
    },
    watch: {
      patientInfo (patientInfo, oldPatientInfo) {
        if (patientInfo) {
          this.patient_info = patientInfo.patient_info
          this.interview_info = patientInfo.interview_info
          this.patient = patientInfo.patient
          this.first_time = patientInfo.first_time
          this.is_interview_fetched = true
          document.title = `${this.$t('text.questionnaire')}_${this.patient_id}_${this.patient_info.full_name}`
        }
      }
    },
    mounted () {
      if (this.$route.params.id) {
        window.patientData = this.setPatientData.bind(this)
      }
      if (this.patientInfo) {
        this.patient_info = this.patientInfo.patient_info
        this.interview_info = this.patientInfo.interview_info
        this.patient = this.patientInfo.patient
        this.first_time = this.patientInfo.first_time
        this.is_interview_fetched = true
      }
    },
    beforeCreate () {
      const userAgent = navigator.userAgent.toLowerCase()
      if (userAgent.indexOf('safari') !== -1) {
        document.body.style.backgroundColor = '#FFF'
      }
    },
    methods: {
      truncateContent,
      isEmpty,
      setPatientData: function (data) {
        const {
          patient_info: patientInfo,
          first_time: firstTime,
          patient
        } = data

        this.patient_info = patientInfo
        this.first_time = firstTime
        this.patient = patient
        this.is_interview_fetched = true
      }
    }
  }
</script>
