var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-action", {
    staticClass: "print-calendar",
    on: {
      close: function($event) {
        return _vm.$emit("close")
      }
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "print-calendar__content" },
              [
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("objects.reservation.text.print_visit_shedule")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("objects.reservation.text.include_memo"))
                  )
                ]),
                _vm._v(" "),
                _c("checkbox", {
                  attrs: {
                    label: _vm.$t("new_text.auto_check_print_calendar")
                  },
                  model: {
                    value: _vm.is_show_again,
                    callback: function($$v) {
                      _vm.is_show_again = $$v
                    },
                    expression: "is_show_again"
                  }
                }),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("objects.reservation.text.change_setting_clinic")
                    )
                  )
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c("button-common", {
              staticClass: "print-calendar__cancel",
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("new_text.button.cancel")) +
                          "\n      "
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            }),
            _vm._v(" "),
            _c("button-common", {
              staticClass: "print-calendar__no",
              on: {
                click: function($event) {
                  return _vm.$emit("handle-print", {
                    is_show_again: _vm.is_show_again,
                    is_print_memo: 0
                  })
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("objects.reservation.text.no_print_calendar")
                          ) +
                          "\n      "
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            }),
            _vm._v(" "),
            _c("button-common", {
              staticClass: "print-calendar__yes",
              on: {
                click: function($event) {
                  return _vm.$emit("handle-print", {
                    is_show_again: _vm.is_show_again,
                    is_print_memo: 1
                  })
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("objects.reservation.text.print_calendar")
                          ) +
                          "\n      "
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }