var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("header-layout", [
        _c(
          "span",
          { attrs: { slot: "header-layout-left" }, slot: "header-layout-left" },
          [
            _c("router-link", { attrs: { to: { name: "reservation.list" } } }, [
              _c("img", { attrs: { src: "/images/arrow-left.svg" } }),
              _c("span", [_vm._v("戻る")])
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("sidebar-menu"),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "reservation-setting" },
        [
          _c("div", { staticClass: "col-xl-12 main-content" }, [
            _c("section", { staticClass: "content" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-content",
                  attrs: { id: "nav-tabSettingContent" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane fade show active",
                      attrs: {
                        id: "nav-date-setting",
                        role: "tabpanel",
                        "aria-labelledby": "nav-home-tab"
                      }
                    },
                    [_c("ReservationDateSetting")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane fade",
                      attrs: {
                        id: "nav-type-setting",
                        role: "tabpanel",
                        "aria-labelledby": "nav-profile-tab"
                      }
                    },
                    [_c("ReservationTypeSetting")],
                    1
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("Loading")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", [
      _c(
        "div",
        {
          staticClass: "nav nav-tabs",
          attrs: { id: "nav-tab", role: "tablist" }
        },
        [
          _c(
            "a",
            {
              staticClass: "nav-item nav-link active",
              attrs: {
                id: "nav-home-tab",
                "data-toggle": "tab",
                href: "#nav-date-setting",
                role: "tab",
                "aria-controls": "nav-home",
                "aria-selected": "true"
              }
            },
            [_vm._v("予約日時設定")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "nav-item nav-link",
              attrs: {
                id: "nav-profile-tab",
                "data-toggle": "tab",
                href: "#nav-type-setting",
                role: "tab",
                "aria-controls": "nav-profile",
                "aria-selected": "false"
              }
            },
            [_vm._v("予約種別設定")]
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }