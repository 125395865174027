import Vue from 'vue'
import * as moment from 'moment'
import { showSuccessModal, showErrorModal } from '../utils/helpers'
import { RESPONSE_TYPE, COLUMN_TYPE, UPDATE_RESERVATION_TYPE } from '../utils/constants'
import ModalConfirmResizeOrMove from '../components/reservation/ModalConfirmResizeOrMove'
import { get, isEmpty } from 'lodash'

function checkFirstReservationOfPatient (data) {
  return this.$store.dispatch('isFirstReservation', data).then(response => {
    const { data: { status, results } } = response

    if (status !== RESPONSE_TYPE.OK) {
      showErrorModal(this.$t('message.something_went_wrong'))

      return false
    }

    if (results.is_first_reservation === true) {
      this.is_show_modal_confirm_first_reservation = true

      return false
    }

    return true
  }).catch(() => {
    showErrorModal(this.$t('message.something_went_wrong'))

    return false
  })
}

export const calendar = {
  methods: {
    checkFirstReservationOfPatient,
    moveScreenToReservationActive (reservationElementActive) {
      if (!reservationElementActive.length) {
        return
      }

      const reservationOffset = reservationElementActive.offset()
      const anchorY = $('.fixed-time ul li').first().offset()
      const anchorX = $('.fc-head-container table th.fc-resource-cell').first().offset()
      const anchorXWidth = 60
      const anchorYHeight = 40
      const w = $('.fc-view-container').width() - anchorXWidth
      const h = $('.fc-view-container').height() - anchorYHeight
      const distanceY = reservationOffset.top - anchorY.top
      const distanceX = reservationOffset.left - anchorX.left

      $('html, body').animate({
        scrollTop: $(document).height() - $(window).height()
      }, 1000)
      $('.fc-view').animate({
        scrollTop: distanceY - ((h - reservationElementActive.height()) / 2)
      }, 1000)
      $('.fc-view-container').animate({
        scrollLeft: distanceX - ((w - reservationElementActive.width()) / 2)
      }, 1000)
    },
    setReservationActiveData () {
      this.url_reservation_data = this.events.filter((e) => { return e.id === this.url_reservation_id })

      if (this.url_reservation_data.length === 0) {
        return
      }

      $('.reservation-v2').addClass('mask')
      $('.header-column:not(.header-column--add):not(.header-column--empty)').addClass('mask')
      const activeListTime = this.genActiveListTime()
      activeListTime.forEach(item => {
        $('.time-' + item.replace(':', '')).addClass('hide-mask')
      })

      const activeAllocationId = this.url_reservation_data[0].allocation_id || null
      $('.header-column-' + activeAllocationId).removeClass('mask')
      this.moveScreenToReservationActive($('.event-id-' + this.url_reservation_id))
    },
    removeActiveReservation () {
      if (!this.url_reservation_id) {
        return
      }

      this.$router.replace({ query: { search_date: this.$route.query.search_date } })
      this.url_reservation_id = null
      $('.reservation-v2').removeClass('mask')
      $('.header-column').removeClass('mask')
      $('.fixed-time ul li').removeClass('hide-mask')
      this.reloadReservationData()
    },
    genActiveListTime () {
      if (this.url_reservation_data.length === 0) {
        return
      }
      const period = 5
      const listTime = []
      const activeStartedTo = this.url_reservation_data[0].started_to.slice(10, 16)
      const activeEndedFrom = this.url_reservation_data[0].ended_from.slice(10, 16)
      const startTimeArr = activeStartedTo.split(':')

      for (let i = parseInt(startTimeArr[0]) * 60; i < 1440; i += period) { // 1440 = 60*24
        const hour = Math.floor(i / 60)
        const minute = i % 60
        const timeStr = (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute

        if (
          moment(timeStr, 'h:m').isSameOrAfter(moment(activeStartedTo, 'h:m')) &&
          moment(timeStr, 'h:m').isBefore(moment(activeEndedFrom, 'h:m'))
        ) {
          listTime.push(timeStr)
        }
      }

      return listTime
    },
    focusToReservationCancelled: function () {
      $('.calendar .fc-resource-cell[data-resource-id]').removeClass('calendar__focus__allocation calendar__focus__allocation--focus')
      $('.calendar .fixed-time ul li').removeClass('calendar__focus__time calendar__focus__time--focus')
      if (this.is_reservation_cancelled) {
        const fixedTime = document.querySelectorAll('.calendar .fixed-time ul li')
        const allocation = document.querySelector(
          '.calendar .fc-resource-cell[data-resource-id="' + this.reservation_cancelled.allocation_id + '"]'
        )
        if (!allocation) {
          return
        }

        allocation.setAttribute('class', 'fc-resource-cell calendar__focus__allocation calendar__focus__allocation--focus')
        const startTo = moment(this.reservation_cancelled.started_to)
        const endedFrom = moment(this.reservation_cancelled.ended_from)
        const minTime = this.is_closed_all_day ? '24:00:00' : this.min_active_time
        const maxTime = this.is_closed_all_day ? '24:00:00' : this.max_active_time
        if (
          startTo.isSameOrAfter(moment(this.selectedDate + ' ' + maxTime)) ||
          endedFrom.isSameOrBefore(moment(this.selectedDate + ' ' + minTime))
        ) {
          showErrorModal(this.$t('objects.reservation.messages.reservations_canceled_outside_business_time'))

          return
        }

        const start = moment(this.reservation_cancelled.started_to).format('YYYY-MM-DD')
        let lastTimeFocus
        let lastClass
        fixedTime.forEach(item => {
          const time = item.children[0].textContent
          let rowClass = item.getAttribute('class')
          if (moment(`${start} ${time}`) >= startTo && moment(`${start} ${time}`) <= endedFrom) {
            rowClass += ' calendar__focus__time calendar__focus__time--focus'
            lastTimeFocus = item
            lastClass = rowClass
          }

          if (item) {
            item.setAttribute('class', `${rowClass || ''}`)
          }
        })
        if (lastTimeFocus) {
          lastTimeFocus.setAttribute('class', lastClass)
        }
        this.moveScreenToReservationActive($('.calendar__cancelled--focus'))
      }
    }
  }
}
export const updateOrCreate = {
  methods: {
    setDateTo (date) {
      if (moment(date, moment.defaultFormat).toDate() > moment().add(365, 'days')) {
        this.data_reservation.date = ''
        showErrorModal(this.$t('sweet_alert.available_date_warning'))
      } else if (moment(date, moment.defaultFormat).toDate() < moment(process.env.MIX_SERVICE_RELEASE_DATE)) {
        this.data_reservation.date = ''
        showErrorModal(this.$t('sweet_alert.before_release_date_warning'))
      } else {
        this.data_reservation.date = date
        this.validateForm()
      }
    },
    updateStartTime (data) {
      this.data_reservation.time_start = data
      this.validateForm()
    },
    updateEndTime (data) {
      this.data_reservation.time_end = data
      this.validateForm()
    },
    validateForm () {
      const timeIsValid = this.data_reservation.date &&
        this.data_reservation.time_start &&
        this.data_reservation.time_end &&
        this.data_reservation.time_start < this.data_reservation.time_end

      if (this.data_reservation.blocked) {
        this.is_valid_submit = timeIsValid
      } else {
        this.is_valid_submit = this.data_reservation.patient_id && timeIsValid
      }

      return this.is_valid_submit
    },
    showModalReservationType () {
      if (!this.data_reservation.blocked) {
        this.is_show_modal_list_reservation_type = true
      }
    },
    showModalPersonCharge () {
      if (!this.data_reservation.blocked) {
        this.is_show_modal_person_in_charge = true
      }
    },
    selectReservationType (reservationType) {
      this.reservation_types = reservationType
      this.is_show_modal_list_reservation_type = false
    },
    removeReservationTypeSelected (index) {
      this.reservation_types.splice(index, 1)
    },
    selectPersonInCharge (item) {
      this.is_show_modal_person_in_charge = false
      if (this.person_in_charges.some(value => value.id === item.id)) {
        return false
      }
      this.person_in_charges.push({
        id: item.id,
        name: item.name,
        name_romaji: item.name_romaji,
        position_name: item.position_name
      })
    },
    removePersonInCharge (index) {
      this.person_in_charges.splice(index, 1)
    },
    checkExistReservationTime (postData) {
      return this.$store.dispatch('checkExistReservationTime', postData).then(result => {
        const data = result.data.results || ''

        if (data.length) {
          showErrorModal(data)
          this.$emit('reload')

          return false
        }

        return true
      }).catch(() => {
        showErrorModal(this.$t('message.something_went_wrong'))

        return false
      })
    },
    checkFirstReservationOfPatient,
    showModalTimeSetting (key) {
      if (this.show_modal_time_setting === key) {
        this.show_modal_time_setting = ''
        return
      }
      this.show_modal_time_setting = key
    },
    clickOutsideEvent (event) {
      if (!this.hasSomeParentTheClass(event.target, 'modal-create-reservation__time')) {
        this.show_modal_time_setting = ''
      }
    },
    hasSomeParentTheClass (element, className) {
      if (element.parentNode === null) return false
      if (element.className.split(' ').indexOf(className) >= 0) return true
      return this.hasSomeParentTheClass(element.parentNode, className)
    }
  }
}
export const resizeOrDrop = {
  methods: {
    findAllocation (id) {
      return this.allocations.find(item => item.id === id)
    },
    handleDropResizeEvent (info, isDrop = false) {
      if (this.is_lock_reservation) {
        info.revert()
        return
      }

      const resourceData = info?.newResource?.extendedProps || {}
      this.allocation_destination = { ...resourceData }
      if (isEmpty(this.allocation_destination)) {
        this.allocation_destination = this.findAllocation(get(info, 'event.extendedProps.allocation_id', 0))
      }

      if (
        isDrop &&
        (resourceData.column_type === COLUMN_TYPE.ADD_NEW || resourceData.column_type === COLUMN_TYPE.EMPTY)
      ) {
        info.revert()

        return
      }

      this.is_drop_reservation = isDrop
      this.event_resize_or_move = info
      const patient = info?.event?.extendedProps?.patient || {}
      const event = info?.event || {}

      if (patient.first_reservation_id && patient.first_reservation_id === parseInt(event.id, 10)) {
        this.is_show_modal_confirm_first_reservation = true

        return
      }

      let startDate = event.start ? moment(event.start) : ''
      const endDate = event.end ? moment(event.end) : ''

      if (startDate && startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) {
        startDate = endDate.clone().startOf('day')
      }

      const elementReservation = $('.reservation-id-' + event.id).first()
      const parentDiv = elementReservation.parent()[0]
      const scrollTop = $(window).scrollTop()
      const elementOffset = elementReservation.offset().top
      const distanceTop = (elementOffset - scrollTop)
      let placement = 'top'

      if (distanceTop < 450) {
        placement = 'bottom'
      }

      const scrollLeft = $(window).scrollLeft()
      const distanceLeft = elementReservation.offset().left - scrollLeft
      const bottomPositionEvent = elementReservation.offset().top + elementReservation.outerHeight()
      const resourceReservation = document.querySelector('.reservation-id-' + event.id)
      const distanceRight = window.innerWidth - resourceReservation.getBoundingClientRect().right
      const distanceBottom = window.innerHeight - resourceReservation.getBoundingClientRect().bottom

      if ((window.innerHeight - bottomPositionEvent) < 250 && distanceTop < 350) {
        placement = distanceLeft > 600 ? 'left' : 'right'
      }

      if (placement === 'left') {
        this.$nextTick(() => {
          const modalConfirmReservationChange = $('.modal-confirm-reservation-change')
          modalConfirmReservationChange.css('left', (parseInt(modalConfirmReservationChange.css('left')) - 12) + 'px')
        })
      }

      if (distanceLeft < 250) {
        placement = 'right'
      }

      if (distanceRight < 250) {
        placement = 'left'
      }

      if (distanceBottom < 250) {
        if (distanceRight > distanceLeft) {
          placement = 'right'
        } else {
          placement = 'left'
        }
      }

      let title = this.$t('message.reservation_confirm_message_title')

      if (info.event.extendedProps.blocked) {
        title = isDrop ? this.$t('message.block_time_confirm_message_move')
          : this.$t('message.block_time_confirm_message_change')
      }

      const ModalConfirmResizeOrMoveClass = Vue.extend(ModalConfirmResizeOrMove)
      const modalConfirmResizeOrMove = new ModalConfirmResizeOrMoveClass({
        i18n: this.$i18n,
        propsData: {
          startDate: startDate ? startDate.format('HH:mm') : '',
          endDate: endDate ? endDate.format('HH:mm') : ''
        }
      })
      modalConfirmResizeOrMove.$mount()
      const ref = this
      elementReservation.confirmation({
        placement: placement,
        title: title,
        btnOkClass: 'btn btn-sm',
        btnOkLabel: this.$t('common.yes'),
        btnCancelClass: 'btn btn-sm btn-default',
        btnCancelLabel: this.$t('common.no'),
        href: '#',
        target: '_self',
        singleton: true,
        popout: false,
        html: true,
        onShow: function () {
          parentDiv.classList.add('z-index-99')
        },
        onHide: function () {
          parentDiv.classList.remove('z-index-99')
        },
        onConfirm: function () {
          ref.handleUpdateReservation(UPDATE_RESERVATION_TYPE.RESIZE_OR_DROP)
        },
        onCancel: function () {
          ref.resetReservationDataResizeOrMove()
        },
        template: modalConfirmResizeOrMove.$el
      })

      if (event?.extendedProps?.blocked || 0) {
        if (distanceLeft < 250 || distanceRight < 250) {
          this.$nextTick(() => {
            const modalConfirmReservationChange = $('.modal-confirm-reservation-change')
            this.calcPositionConfirm(modalConfirmReservationChange)
            this.calcPositionConfirmArrow(modalConfirmReservationChange, elementReservation)
          })
        }
        elementReservation.confirmation('show')

        return
      }
      const startedTo = moment(event.start, this.$t('datetime.year_month_day_hour_minute'))
        .format(this.$t('datetime.year_month_day_hour_minute', 'en'))
      const submitData = {
        started_to: startedTo,
        patient_id: event?.extendedProps?.patient?.id || 0,
        id: event.id
      }
      this.$store.commit('set_loading', true)
      this.checkFirstReservationOfPatient(submitData).then(result => {
        this.$store.commit('set_loading', false)
        if (!result) {
          return
        }
        if (distanceLeft < 250 || distanceRight < 250) {
          this.$nextTick(() => {
            const modalConfirmReservationChange = $('.modal-confirm-reservation-change')
            this.calcPositionConfirm(modalConfirmReservationChange)
            this.calcPositionConfirmArrow(modalConfirmReservationChange, elementReservation)
          })
        }

        elementReservation.confirmation('show')
      })
    },
    calcPositionConfirm (elConfirm) {
      const heightHeader = 190
      const confirmOffsetTop = elConfirm.offset().top
      const topOfConfirm = elConfirm.css('top')

      if (confirmOffsetTop > heightHeader) {
        return
      }

      const top = parseInt(topOfConfirm) + heightHeader - confirmOffsetTop
      elConfirm.css('top', top + 'px')
    },
    calcPositionConfirmArrow (elConfirm, elementReservation) {
      const eventPositionTop = elementReservation.offset().top
      const eventPositionBottom = eventPositionTop + elementReservation.outerHeight()
      const confirmPositionTop = elConfirm.offset().top
      const confirmPositionBottom = confirmPositionTop + elConfirm.outerHeight()
      let position = null

      if (eventPositionTop < confirmPositionTop && eventPositionBottom > confirmPositionBottom) {
        return
      }
      const arrowSelected = $('.modal-confirm-reservation-change .arrow')
      if (confirmPositionTop < eventPositionTop) {
        position = (confirmPositionBottom - eventPositionTop) / 2

        if (position < 30) {
          arrowSelected.css('display', 'none')
          return
        }

        arrowSelected.css('bottom', position + 'px')
        arrowSelected.css('top', 'unset')

        return
      }

      position = (eventPositionBottom - confirmPositionTop) / 2

      if (position < 30) {
        arrowSelected.css('display', 'none')

        return
      }

      arrowSelected.css('top', position + 'px')
    },
    submitUpdateReservationResizeOrMove () {
      this.is_show_modal_confirm_first_reservation = false
      const event = this.event_resize_or_move?.event || {}

      let startDate = event.start ? moment(event.start) : ''
      const endDate = event.end ? moment(event.end) : ''

      if (startDate && startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) {
        startDate = endDate.clone().startOf('day')
      }

      const postData = {
        id: event.id,
        patient_id: event.extendedProps.patient_id,
        clinics_id: event.extendedProps.clinics_id,
        started_to: startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : '',
        ended_from: endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : '',
        allocation_id: event.extendedProps.allocation_id,
        disable_send_mail: true
      }

      if (this.event_resize_or_move.newResource) {
        postData.allocation_id = this.event_resize_or_move.newResource.id
      }
      this.$store.commit('set_loading', true)
      this.$store.dispatch('updateReservation', postData).then(() => {
        this.storeOperationReasons(event.id, !!get(this.event_resize_or_move, 'event.extendedProps.blocked', false))
        this.event_resize_or_move = null
        this.resetReservationDataResizeOrMove()
        this.requestDataCalendar()
        const message = event.extendedProps.blocked
          ? (this.is_drop_reservation ? this.$t('text.block_frame_moved_successfully') : this.$t('text.block_frame_changed_successfully'))
          : this.$t('reservation.reservation_updated')
        showSuccessModal(message)
      }).catch((error) => {
        const resErrors = error?.response?.data?.errors || {}
        const errors = []
        if (!isEmpty(resErrors) && resErrors.first_reservation === RESPONSE_TYPE.LOCKED) {
          showErrorModal(this.$t('objects.reservation.messages.reservation_can_not_change'))
        } else if (!isEmpty(resErrors) && resErrors.patient === RESPONSE_TYPE.HAS_ORDER_HISTORY) {
          showErrorModal(
            this.$t('objects.reservation.messages.reservation_can_not_change_because_has_order'),
            { width: 'auto' }
          )
        } else {
          Object.entries(resErrors).forEach(([key, val]) => {
            const message = this.$t(`new_validation.reservation.${val.toLowerCase()}`, {
              attribute: this.$t(`new_validation.reservation.attributes.${key.toLowerCase()}`)
            })
            errors.push(message)
          })

          if (errors.length) {
            showErrorModal(errors.join('\n'))
          }
        }
        this.resetReservationDataResizeOrMove()
        this.requestDataCalendar()
      })
    },
    resetReservationDataResizeOrMove () {
      if (this.event_resize_or_move) {
        this.event_resize_or_move.revert()
      }

      this.event_resize_or_move = null
      this.is_drop_reservation = false
      this.is_show_modal_confirm_first_reservation = false
      this.reloadReservationData()
    },
    handleDropResizeEventStart (info) {
      this.is_lock_reservation = false
      const patient = info?.event?.extendedProps?.patient || {}
      if (patient && parseInt(info?.event?.id) === parseInt(patient.first_reservation_id)) {
        if (patient.first_reservation_month) {
          this.is_lock_reservation = true
          return showErrorModal(this.$t('objects.reservation.messages.reservation_can_not_change'))
        }

        if (patient.order_count > 0) {
          this.is_lock_reservation = true
          return showErrorModal(
            this.$t('objects.reservation.messages.reservation_can_not_change_because_has_order'),
            { width: 'auto' }
          )
        }
      }

      this.is_resize_or_move = true
      this.removeActiveReservation()
    },
    handleDropResizeEventStop () {
      this.is_resize_or_move = false
      this.reloadReservationData()
    }
  }
}
