var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["upload-file", _vm.customClass] },
    [
      _vm.isCreate
        ? _c("div", [
            _c("h2", { staticClass: "upload-file__title" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("objects.orders.text.upload_technician_instructions")
                  )
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "upload-file__title__help-link",
                  on: {
                    click: function($event) {
                      return _vm.triggerClick(
                        _vm.trigger_click_type.MENU,
                        _vm.trigger_click_content.UPLOAD_HELP_LINK
                      )
                    },
                    mouseup: function($event) {
                      if ("button" in $event && $event.button !== 1) {
                        return null
                      }
                      return _vm.triggerClick(
                        _vm.trigger_click_type.MENU,
                        _vm.trigger_click_content.UPLOAD_HELP_LINK
                      )
                    }
                  }
                },
                [
                  _c(
                    "a",
                    { attrs: { href: _vm.upload_help_link, target: "_blank" } },
                    [_c("img", { attrs: { src: "/images/icon-help.svg" } })]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    { attrs: { href: _vm.upload_help_link, target: "_blank" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "objects.orders.text.upload_technician_instructions_help_link"
                          )
                        ) + "\n        "
                      )
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "upload-file__notes" }, [
              _c(
                "p",
                {
                  staticClass:
                    "upload-file__note upload-file__note--red pre-line"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "objects.orders.text.upload_technician_instructions_help"
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "upload-file__note pre-line" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "objects.orders.text.upload_technician_instructions_note_1"
                      )
                    ) +
                    "\n        "
                ),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "objects.orders.text.upload_technician_instructions_note_1_1"
                      )
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "upload-file__note" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "objects.orders.text.upload_technician_instructions_note_2"
                    )
                  )
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "upload-file__drag" }, [
              _c("input", {
                staticClass: "upload-file__input upload-file__input--hidden",
                attrs: {
                  id: "upload-instruction-file",
                  type: "file",
                  title: "",
                  "data-key": "upload_technician_instructions",
                  accept: _vm.upload_technician_instructions.join(", ")
                },
                on: {
                  change: function($event) {
                    return _vm.fileChange($event, true)
                  },
                  click: function($event) {
                    return _vm.clearValue($event)
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { staticClass: "upload-file__label" }, [
                _c("span", { staticClass: "pc" }, [
                  _vm._v(
                    _vm._s(_vm.$t("objects.orders.text.drag_limit_title"))
                  ),
                  _c("br")
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("objects.orders.text.drag_limit_size", {
                        limit: _vm.uploading_limit
                      })
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "upload-file__button",
                  attrs: { for: "upload-instruction-file" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("objects.orders.text.select_files")) +
                      "\n      "
                  )
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "upload-file__uploaded" }, [
        _vm.form_data.upload_technician_instructions.length
          ? _c(
              "h2",
              {
                staticClass: "upload-file__title upload-file__title--uploaded"
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t(
                        "objects.orders.text.uploaded_technician_instructions"
                      )
                    ) +
                    "\n    "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "upload-file__files" },
          _vm._l(_vm.form_data.upload_technician_instructions, function(
            item,
            key
          ) {
            return _c("div", { key: key, staticClass: "upload-file__file" }, [
              _c(
                "div",
                {
                  staticClass: "upload-file__inner",
                  class:
                    "upload-file__inner upload-file__inner--" +
                    _vm.getExtension(item.name),
                  on: {
                    click: function($event) {
                      return _vm.lightBoxPdf(item)
                    }
                  }
                },
                [
                  _vm.getExtension(item.name) === _vm.file_extension.PDF
                    ? _c("pdf", { attrs: { src: _vm.getUrlFile(item) } })
                    : _c("img", {
                        staticClass: "upload-file__image",
                        attrs: { src: _vm.getUrlFile(item) }
                      })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "upload-file__information" }, [
                _c("h3", { staticClass: "upload-file__file--name" }, [
                  _vm._v("\n            " + _vm._s(item.name) + "\n          ")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "upload-file__file--extension" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.getDate(item.lastModified)) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _vm.isCreate
                  ? _c(
                      "div",
                      {
                        staticClass: "upload-file__remove",
                        on: {
                          click: function($event) {
                            return _vm.removeFile(
                              item,
                              key,
                              "upload_technician_instructions"
                            )
                          }
                        }
                      },
                      [_c("img", { attrs: { src: "/images/close-x.svg" } })]
                    )
                  : _vm._e()
              ])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _vm.isCreate
        ? _c("div", [
            _c(
              "h2",
              {
                staticClass: "upload-file__title upload-file__title--not-first"
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("objects.orders.text.file_upload")))
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-file__title__help-link",
                    on: {
                      click: function($event) {
                        return _vm.triggerClick(
                          _vm.trigger_click_type.MENU,
                          _vm.trigger_click_content.TAKE_PICTURE_HELP_LINK
                        )
                      },
                      mouseup: function($event) {
                        if ("button" in $event && $event.button !== 1) {
                          return null
                        }
                        return _vm.triggerClick(
                          _vm.trigger_click_type.MENU,
                          _vm.trigger_click_content.TAKE_PICTURE_HELP_LINK
                        )
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.take_picture_help_link,
                          target: "_blank"
                        }
                      },
                      [_c("img", { attrs: { src: "/images/icon-help.svg" } })]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.take_picture_help_link,
                          target: "_blank"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("objects.orders.text.file_upload_help_link")
                          ) + "\n        "
                        )
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "upload-file__notes" }, [
              _c(
                "p",
                { staticClass: "upload-file__note upload-file__note--red" },
                [_vm._v(_vm._s(_vm.$t("objects.orders.text.file_upload_help")))]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "upload-file__note" }, [
                _vm._v(_vm._s(_vm.$t("objects.orders.text.file_upload_note_1")))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "upload-file__note" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("objects.orders.text.file_upload_note_2")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "upload-file__note" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("objects.orders.text.file_upload_note_3")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "upload-file__note upload-file__note--additional"
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t(
                          "objects.orders.text.file_upload_additional_note"
                        )
                      ) +
                      "\n      "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "upload-file__drag" }, [
              _c("input", {
                staticClass: "upload-file__input upload-file__input--hidden",
                attrs: {
                  id: "upload-file",
                  type: "file",
                  title: "",
                  multiple: "",
                  "data-key": "file_upload",
                  accept: _vm.order_uploaded_file.join(", ")
                },
                on: {
                  change: function($event) {
                    return _vm.fileChange($event, false)
                  },
                  click: function($event) {
                    return _vm.clearValue($event)
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { staticClass: "upload-file__label" }, [
                _c("span", { staticClass: "pc" }, [
                  _vm._v(_vm._s(_vm.$t("objects.orders.text.drag_limit_title")))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "sp" }, [
                  _vm._v(
                    _vm._s(_vm.$t("objects.orders.text.drag_limit_title_sp")) +
                      " "
                  )
                ]),
                _c("br"),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("objects.orders.text.drag_limit_size", {
                        limit: _vm.uploading_limit
                      })
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "upload-file__button",
                  attrs: { for: "upload-file" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("objects.orders.text.select_files")) +
                      "\n      "
                  )
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "upload-file__uploaded" }, [
        _vm.form_data.file_upload.length
          ? _c(
              "h2",
              {
                staticClass: "upload-file__title upload-file__title--uploaded"
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("objects.orders.text.file_uploaded")) +
                    "\n    "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "upload-file__files" },
          _vm._l(_vm.form_data.file_upload, function(item, key) {
            return _c("div", { key: key, staticClass: "upload-file__file" }, [
              _c(
                "div",
                {
                  class:
                    "upload-file__inner upload-file__inner--uploaded upload-file__inner--" +
                    _vm.getExtension(item.name),
                  on: {
                    click: function($event) {
                      return _vm.lightBoxPdf(item)
                    }
                  }
                },
                [
                  _vm.getExtension(item.name) === _vm.file_extension.PDF &&
                  _vm.is_rerender_pdf_file
                    ? _c("pdf", { attrs: { src: _vm.getUrlFile(item) } })
                    : _c("img", {
                        staticClass: "upload-file__image",
                        attrs: { src: _vm.getUrlFile(item) }
                      })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "upload-file__information" }, [
                _c("h3", { staticClass: "upload-file__file--name" }, [
                  _vm._v("\n            " + _vm._s(item.name) + "\n          ")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "upload-file__file--extension" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.getDate(item.lastModified)) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _vm.isCreate
                  ? _c(
                      "div",
                      {
                        staticClass: "upload-file__remove",
                        on: {
                          click: function($event) {
                            return _vm.removeFile(item, key, "file_upload")
                          }
                        }
                      },
                      [_c("img", { attrs: { src: "/images/close-x.svg" } })]
                    )
                  : _vm._e()
              ])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _vm.is_show_modal
        ? _c("ModalPDF", {
            attrs: { url: _vm.pdf_url, extension: _vm.extension },
            on: { "close-modal": _vm.closeModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }