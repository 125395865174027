<template>
  <div>
    <modal-action
      :class="['print-technical-info', { 'print-technical-info__disabled-confirm': sf_user_ids_selected.length === 0 }]"
      :title="$t('objects.reservation.text.print_order_detail_for_people_scheduled')"
      :is-show-cancel-button="true"
      @close="$emit('close')"
      @confirm="syncDataOrderFromSF(sf_user_ids_selected, order_type, order_field)"
    >
      <template #body>
        <div class="print-technical-info-modal">
          <div class="print-technical-info-modal__header">
            <div class="print-technical-info-modal__total">
              <div>
                {{ $t('objects.reservation.text.print_target') }}
              </div>
              <div>
                <span>{{ number_technical }}</span>{{ $t('new_text.first_name2') }}
              </div>
            </div>
            <div class="print-technical-info-modal__select">
              <common-select
                :default-value="sort_list"
                :options="options"
                key-value="value"
                key-display="text"
                @change="value => sort_list = value"
              />
            </div>
          </div>
          <table class="print-technical-info-modal__table">
            <thead>
              <tr>
                <th class="table__reservation-select-wrapper">
                  <div class="table__reservation-select">
                    <input
                      v-model="selectAll"
                      type="checkbox"
                      class="table__reservation-select__checkbox"
                    >
                    <span class="table__reservation-select__background" />
                  </div>
                </th>
                <th>{{ $t('new_text.id') }}</th>
                <th>{{ $t('new_text.name') }}</th>
                <th>{{ $t('new_text.age_title') }}</th>
                <th>{{ $t('objects.reservation.text.reservation_type') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(patient, index) in getList"
                :key="index"
              >
                <td class="table__reservation-select-wrapper">
                  <div class="table__reservation-select">
                    <input
                      :id="patient.sf_user_id"
                      v-model="sf_user_ids_selected"
                      :value="patient.sf_user_id"
                      type="checkbox"
                      class="table__reservation-select__checkbox"
                    >
                    <span class="table__reservation-select__background" />
                  </div>
                </td>
                <td class="number-medium">
                  {{ patient.sf_user_id }}
                </td>
                <td class="print-technical-info-modal__table__name">
                  {{ `${patient.name || ''} (${patient.kana_last_name || ''} ${patient.kana_first_name || ''})` }}
                </td>
                <td>{{ getAge(patient.birth_of_date) }}</td>
                <td class="break-word">{{ getReservationType(patient.duties) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template #confirm_button_content>
        {{ $t('objects.reservation.text.print_order_detail_of_checked_person', {num: sf_user_ids_selected.length}) }}
      </template>
    </modal-action>
  </div>
</template>

<script>
  import ModalAction from '../common/ModalAction'
  import helpers from '../../utils/helpers'
  import * as moment from 'moment'
  import Select from '../common/Select'
  import { headerPageToPrintTechnicalInfo } from '../../mixins/print-individual-technical-info'
  import { printTechnicalInfo } from '../../mixins/print-technical-info'
  import { orderBy } from 'lodash'

  export default {
    components: {
      ModalAction,
      CommonSelect: Select
    },
    mixins: [
      printTechnicalInfo,
      headerPageToPrintTechnicalInfo
    ],
    props: {
      date: {
        type: String,
        default: moment().format('YYYY-MM-DD')
      },
      reservationTechnicalInfoPrint: {
        type: Array,
        default: () => []
      }
    },
    emits: ['close'],
    data () {
      return {
        number_technical: 0,
        data_show: [],
        sf_user_ids_selected: [],
        options: [
          { value: 1, text: this.$t('new_text.name_asc_order') },
          { value: 2, text: this.$t('new_text.patient_id_asc_order') }
        ],
        sort_list: 1,
        order_type: '',
        order_field: ''
      }
    },
    computed: {
      selectAll: {
        get: function () {
          return this.sf_user_ids_selected.length !== 0
            ? this.sf_user_ids_selected.length === this.data_show.length
            : false
        },
        set: function (value) {
          const selected = []

          if (value) {
            this.data_show.forEach(function (reservation) {
              selected.push(reservation.sf_user_id)
            })
          }

          this.sf_user_ids_selected = selected
        }
      },
      getList () {
        this.resetData()

        return this.data_show
      }
    },
    methods: {
      getAge (birthOfDate) {
        if (birthOfDate) {
          return (moment(birthOfDate).isValid()) ? `${helpers.calculateAge(birthOfDate)}` : ''
        }

        return ''
      },
      getReservationType (duties) {
        const reservationType = []
        for (const key in duties) {
          reservationType.push(duties[key].master.duty_name)
        }

        return reservationType.join('+')
      },
      resetData () {
        this.data_show = Object.assign(this.data_show, this.reservationTechnicalInfoPrint)

        if (this.sort_list === 2) {
          this.data_show.sort((a, b) => parseInt(a.sf_user_id) - parseInt(b.sf_user_id))
          this.order_field = 'sf_user_id'
          this.order_type = 'ASC'
        } else {
          this.data_show = orderBy(this.data_show, ['kana_last_name', 'kana_first_name'], ['asc', 'asc'])
          this.order_field = 'kana_name'
          this.order_type = 'ASC'
        }
        this.number_technical = this.data_show.length
      }
    }

  }
</script>
