export default {
  text: {
    block_frame_editing: 'ブロック枠編集',
    cancel_reservation: '予約取消',
    cancellation_time: 'キャンセル日時',
    cancelled_reason: 'キャンセル理由',
    canceller: 'キャンセル者',
    change: '変更',
    change_setting_clinic: '（クリニック設定から設定を変更できます）',
    datetime: '予約日時',
    datetime_change: '変更日時',
    delete_allocation_title: '{name}を削除しますか？',
    edit_reservation: '予約編集',
    include_memo: '来院予定印刷にメモを含めますか？',
    instruction_note: '※受発注の品質管理の観点からキレカルでの技工指示書作成を推奨しております。ご協力のほどよろしくお願い致します。',
    memo: 'メモ',
    memo_title: '{date}メモ',
    name: '名',
    new_reservation: '新規予約',
    no_print_calendar: 'メモなしで印刷',
    no_reservation: '来院予約がありません',
    print_calendar: 'メモありで印刷',
    print_instruction: '技工指示書印刷',
    print_multiple_order_details: '注文詳細一括印刷',
    print_order_detail_for_people_scheduled: '本日来院予定者の注文詳細を印刷',
    print_order_detail_of_checked_person: 'チェックした{num}人の注文詳細を印刷',
    print_target: '印刷対象者',
    print_template: 'チェックした{num}件のテンプレートを印刷',
    print_visit_shedule: '来院予定を印刷します',
    reservation_date_and_time: '予約日時',
    reservation_status: {
      status_0: '新規申し込み',
      status_1: '受付前',
      status_2: '受付済',
      status_3: '会計前',
      status_4: '会計済'
    },
    reservation_change: '予約変更',
    reservation_print_instruction_title: '本日来院予定者の技工指示書テンプレートを印刷',
    reservation_type: '予約種別',
    reservations_frame: '予約枠',
    return_patient: '再診患者を含める',
    save_memo_confirm: '既にメモが入力されている場合\n' +
      '上書きされますが、よろしいですか？',
    save_memo_title: 'メモを保存します',
    schedule_visit: '本日来院予定',
    search_cancel_reservation_title: 'キャンセルリスト',
    unblock_frame: 'ブロック枠解除',
    update_memo: 'メモを更新',
    update_reservation: '予約を更新'
  },
  messages: {
    allocation_created: '枠を追加しました',
    allocation_deleted: '枠を削除しました',
    allocation_updated: '枠設定を更新しました',
    daily_memo_save_fail: 'メモの保存に失敗しました',
    daily_memo_save_successfully: 'メモの保存が完了しました',
    examination_template_not_exist: 'テンプレートPDFがアップロードされていません。 システム管理者にお問い合わせください。',
    no_reservation_information: '予約情報がないため技工指示書がダウンロードできません。',
    reservation_can_not_cancel_because_has_order: '※当患者様は発注済のため、初診予約のキャンセルは行えません',
    reservation_can_not_change: '※過去の初診予約の日時変更は行えません',
    reservation_can_not_change_because_has_order: '※当患者様は発注済のため、初診予約の日時変更は行えません',
    reservation_cancelled: '予約を取り消しました',
    reservation_has_been_canceled: 'この予約はすでにキャンセルされています',
    reservations_already_exist: 'この予約枠は使用されているため削除できません',
    reservations_canceled_outside_business_time: '営業時間外のため表示できません'
  }
}
