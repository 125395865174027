import {
  COLUMN_TYPE,
  OPERATION_ALLOCATION_REASON_TYPE,
  RESPONSE_ERRORS
} from '../utils/constants'
import { showErrorModal, showMessageError } from '../utils/helpers'
import { get, map } from 'lodash'

export const initData = {
  props: {
    allocations: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      name_allocation: '',
      sort_order: '',
      order_list: [],
      types: [],
      selected_type: COLUMN_TYPE.NOT_AUTO
    }
  },
  methods: {
    getTypes () {
      this.types = [
        {
          value: 0,
          text: this.$t('objects.allocation.setting_type_not_set')
        },
        {
          value: 1,
          text: this.$t('objects.allocation.setting_type_new_reservation')
        }
      ]

      const clinic = this.$store.getters.currentClinic || {}
      if (clinic && clinic.is_support_csl) {
        this.types.push(
          {
            value: 2,
            text: this.$t('objects.allocation.setting_type_csl')
          }
        )
      }
    },
    getSortOrderList (isCreate = false) {
      this.order_list = map(this.allocations, ({ sortOrder }) => ({ sortOrder }))
      if (isCreate) {
        this.order_list.push({ sortOrder: this.order_list.length + 1 })
      }
    }
  }
}

export const resetSortOrder = {
  methods: {
    resetSortOrder (resources, action, isSaveOperationReason = false) {
      let sortOrder = 0
      const dataOrder = []
      resources.forEach(function (item) {
        if (
          item.column_type === COLUMN_TYPE.NOT_AUTO ||
          item.column_type === COLUMN_TYPE.AUTO ||
          item.column_type === COLUMN_TYPE.CSL
        ) {
          item.sortOrder = ++sortOrder
          dataOrder.push({
            id: item.id,
            column_type: item.column_type || 0,
            sortOrder: item.sortOrder,
            column_name: item.column_name,
            is_selected: item.is_selected
          })
        }
      })
      this.$store.commit('set_loading', true)
      this.$store.dispatch('updateOrderAllocation', { dataOrder: dataOrder }).then(() => {
        this.$store.commit('set_loading', false)
        let message = this.$t('objects.reservation.messages.allocation_created')
        if (action === 'updated') {
          message = this.$t('objects.reservation.messages.allocation_updated')
          if (isSaveOperationReason) {
            this.storeOperationAllocationReasons(OPERATION_ALLOCATION_REASON_TYPE.UPDATE)
          }
        }
        if (action === 'deleted') {
          message = this.$t('objects.reservation.messages.allocation_deleted')
          if (isSaveOperationReason) {
            this.storeOperationAllocationReasons(OPERATION_ALLOCATION_REASON_TYPE.DELETE)
          }
        }
        this.$emit('succeed', message)
      }).catch((error) => {
        this.$store.commit('set_loading', false)
        const resErrors = get(error, 'response.data.errors') || {}
        if (get(resErrors, 'clinic', '') === RESPONSE_ERRORS.NO_CSL_SUPPORTED) {
          showErrorModal(this.$t('new_validation.allocation.clinic_not_support_csl'))

          return
        }

        showMessageError(error, this)
      })
    },
    storeOperationAllocationReasons (isEventTypeUpdate) {
      const postData = {
        ...this.operation_allocation_reason_resources,
        ...this.operation_allocation_reason_data,
        operation_type: isEventTypeUpdate
      }

      this.$store.dispatch('storeOperationAllocationReasons', postData).finally(() => {
        this.show_modal_operation_reason = false
      }).catch(() => {})
    }
  }
}
