export default {
  account_setting: 'アカウント設定',
  accounting_info: '会計情報',
  activity_log: 'アクティビティ',
  cancelled: 'キャンセル',
  clinic_confirmation_required: 'クリニック要確認',
  clinic_setting: 'クリニック設定',
  confirming_before_ordering: '受注前確認中',
  create_instructions: '発注',
  deleted_patient: '削除済患者情報',
  delivery_completed: '納品完了',
  instructions_for_saving_draft: '下書き保存中の指示書',
  interview_sheet: '問診票',
  ip_setting: 'IPアドレス制限',
  order_patient_search: '注文/患者検索',
  patient_info: '患者情報',
  preparing_to_ship: '発送準備中',
  questionnaire_list: '来院アンケート',
  reservation_date_setting: '予約日時設定',
  reservation_type_setting: '予約種別設定',
  setting: '設定',
  setup_design: 'セットアップデザイン',
  shipment_completed: '発送完了',
  technician_order: '技工発注'
}
