<template>
  <div :class="[className]">
    <div class="form-group row">
      <div class="form-inline">
        <p class="color-blue my-auto col-md-3">
          身長
        </p>
        <input
          v-model="cm"
          class="form-control col-md-7"
          type="text"
          name=""
        >
        <p class="my-auto col-md-1">
          cm
        </p>
      </div>
    </div>
    <div class="form-group row">
      <div class="form-inline">
        <p class="color-blue my-auto col-md-3">
          体重
        </p>
        <input
          v-model="kg"
          class="form-control col-md-7"
          type="text"
          name=""
        >
        <p class="my-auto col-md-1">
          kg
        </p>
      </div>
    </div>
    <div class="form-group">
      <div class="radio-group h-align form-inline inline-type-blood">
        <label class="color-blue mr-4">血液型</label>

        <div
          :class="['check-container', 'ml-0']"
          @click="handleClick('A')"
        >
          <span :class="['radio-button', handleClass('A')]" />
          <span class="label-text">A</span>
        </div>

        <div
          :class="['check-container', 'ml-0']"
          @click="handleClick('B')"
        >
          <span :class="['radio-button', handleClass('B')]" />
          <span class="label-text">B</span>
        </div>

        <div
          :class="['check-container', 'ml-0']"
          @click="handleClick('O')"
        >
          <span :class="['radio-button', handleClass('O')]" />
          <span class="label-text">O</span>
        </div>

        <div
          :class="['check-container', 'ml-0']"
          @click="handleClick('AB')"
        >
          <span :class="['radio-button', handleClass('AB')]" />
          <span class="label-text">AB</span>
        </div>

        <div
          :class="['check-container', 'ml-0']"
          @click="handleClick('不明')"
        >
          <span :class="['radio-button', handleClass('不明')]" />
          <span class="label-text">不明</span>
        </div>
        <div class="break-flex-inline" />
        <div
          :class="['check-container', 'inline-type-blood__last']"
          @click="handleClick(INTERVIEW_OPTIONS_NOT_ENTERED)"
        >
          <span :class="['radio-button', handleClass(INTERVIEW_OPTIONS_NOT_ENTERED)]" />
          <span class="label-text">{{ INTERVIEW_OPTIONS_NOT_ENTERED }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { INTERVIEW_OPTIONS_NOT_ENTERED } from '../../../../utils/constants'

  export default {
    props: {
      className: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        number_19: 19,
        number_20: 20,
        cm: '',
        kg: '',
        blood: '',
        translate_19: {
          身長: 'cm',
          体重: 'kg'
        },
        translate_20: {
          血液型: 'blood'
        },
        INTERVIEW_OPTIONS_NOT_ENTERED: INTERVIEW_OPTIONS_NOT_ENTERED
      }
    },
    created: function () {
      this.loadAnswerData()
    },
    methods: {
      loadAnswerData: async function () {
        const dataState19 = this.$store.getters.get_question(this.number_19)
        const dataState20 = this.$store.getters.get_question(this.number_20)

        dataState19.items.details.forEach(objDetail => {
          for (const jaName in this.translate_19) {
            if (objDetail.title === jaName) {
              const enName = this.translate_19[jaName]
              this[enName] = objDetail.content || ''
            }
          }
        })
        dataState20.items.details.forEach(objDetail => {
          for (const jaName in this.translate_20) {
            if (objDetail.title === jaName) {
              const enName = this.translate_20[jaName]
              this[enName] = objDetail.content || ''
            }
          }
        })
      },
      formatResult19: function () {
        const data = this.$store.getters.get_question(this.number_19)

        data.items.details.forEach(objDetail => {
          for (const jaName in this.translate_19) {
            if (objDetail.title === jaName) {
              const enName = this.translate_19[jaName]
              objDetail.content = this[enName]
            }
          }
        })
        return data
      },
      formatResult20: function () {
        const data = this.$store.getters.get_question(this.number_20)

        data.items.details.forEach(objDetail => {
          for (const jaName in this.translate_20) {
            if (objDetail.title === jaName) {
              const enName = this.translate_20[jaName]
              objDetail.content = this[enName]
            }
          }
        })
        return data
      },
      handleClick: function (value) {
        this.blood = value
      },
      handleClass: function (value) {
        return this.blood === value ? 'checked' : ''
      }
    }
  }
</script>
