export default {
  accounting: 'Accounting',
  login: 'Login',
  order: 'Order',
  password_reset: 'Password Reset',
  patient: 'Patient',
  questionnaire: 'Questionnaire',
  reception: 'Reception',
  reservation: 'Reservation',
  setting: 'Setting'
}
