var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "logo" }, [
    _vm.staff_id
      ? _c("div", { staticClass: "user-info__txt" }, [
          _c("div", { staticClass: "staff__txt" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.clinic_formal_name) +
                " " +
                _vm._s(_vm.staff_role) +
                " " +
                _vm._s(_vm.staff_fullname) +
                " (" +
                _vm._s(_vm.staff_id) +
                ")\n    "
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "header-icon" }, [
      _vm.visibleReloadButton
        ? _c(
            "div",
            {
              staticClass: "header-icon__inner",
              on: { click: _vm.handleReloadPage }
            },
            [_c("img", { attrs: { src: "/images/reload.svg" } })]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "header-icon__inner header-icon__inner--setting" },
        [
          _c("img", {
            attrs: { id: "user-settings", src: "/images/header-setting.svg" },
            on: {
              click: function($event) {
                return _vm.toggle()
              }
            }
          })
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "user-action" }, [
      _c("div", { staticClass: "user_menu", staticStyle: { height: "auto" } }, [
        _c("div", { staticClass: "icon" }, [
          _c("a", { attrs: { href: "#" }, on: { click: _vm.linkHelp } }, [
            _vm._m(0),
            _vm._v(" "),
            _c("p", { staticClass: "iconTitle info-text" }, [
              _vm._v(_vm._s(_vm.$t("sidebar_menu.help")))
            ]),
            _vm._v(" "),
            _vm._m(1)
          ])
        ]),
        _vm._v(" "),
        _vm.$store.getters.isAdmin
          ? _c(
              "div",
              { staticClass: "icon" },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "setting.account" } } },
                  [
                    _c("div", { staticClass: "iconImage" }, [
                      _c("img", { attrs: { src: "/images/settings.svg" } })
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "iconTitle" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("sidebar_menu.preferences")) +
                          "\n          "
                      )
                    ])
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "icon" }, [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  return _vm.language_toggle()
                }
              }
            },
            [
              _vm._m(2),
              _vm._v(" "),
              _c("p", { staticClass: "iconTitle" }, [
                _vm._v(_vm._s(_vm.$t("sidebar_menu.change_language")))
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "icon" }, [
          _c(
            "a",
            {
              staticClass: "logout",
              attrs: { href: "#" },
              on: { click: _vm.logOut }
            },
            [
              _c("div", { staticClass: "iconImage" }, [
                _c("img", { attrs: { src: "/images/logout.svg" } })
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "iconTitle" }, [
                _vm._v(_vm._s(_vm.$t("sidebar_menu.log_out")))
              ])
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "language_action" }, [
      _c("div", { staticClass: "language_menu" }, [
        _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                class: { "disabled-link": this.$i18n.locale === "ja" },
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    return _vm.change_language("ja")
                  }
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("sidebar_menu.japanese")) +
                    "\n          "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                class: { "disabled-link": this.$i18n.locale === "en" },
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    return _vm.change_language("en")
                  }
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("sidebar_menu.english")) +
                    "\n          "
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "iconImage" }, [
      _c("img", { staticClass: "info-tab", attrs: { src: "/images/info.svg" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "iconImage" }, [
      _c("img", {
        staticClass: "info-tab",
        attrs: { src: "/images/new-tab.svg" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "iconImage" }, [
      _c("img", { attrs: { src: "/images/language.svg" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }