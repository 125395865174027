var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "interview-content-consultation-v2 interview-content-consultation-v2--confirm"
    },
    [
      _c("according-block", {
        attrs: {
          label: _vm.$t("objects.patient.text.consultation_content"),
          checked: _vm.show_block
        },
        on: {
          handleClick: function($event) {
            _vm.show_block = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show_block,
              expression: "show_block"
            }
          ],
          staticClass:
            "interview-content-consultation-v2__content arrow-toggle-content page-content"
        },
        [
          _c(
            "div",
            { staticClass: "interview-content-consultation-v2__group" },
            [
              _c("div", { staticClass: "page-content" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("objects.patient.text.content_of_consultation")
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group interview-content-consultation-v2__group-padding"
                  },
                  [
                    _c("p", { staticClass: "form-text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.contentPosition) +
                          "\n          "
                      )
                    ])
                  ]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-content" },
            [
              _c("h2", { staticClass: "title title--areas" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("objects.patient.text.areas_concern")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _vm._l(_vm.consultation.points_symptoms, function(
                symptom,
                index
              ) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "interview-content-consultation-v2__block"
                  },
                  [
                    _c("label", { staticClass: "header-box" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("interview.priority_number", {
                              number: index + 1
                            })
                          ) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "interview-content-consultation-v2__block-part row"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-md-4 left-part-content interview-input"
                          },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { staticClass: "block-header" }, [
                                _vm._v(
                                  "1. " +
                                    _vm._s(_vm.$t("objects.patient.text.part"))
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "content content--large" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.getPartName(
                                          symptom.part,
                                          symptom.part_detail
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-8 right-part-content" },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { staticClass: "block-header" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "objects.patient.text.dentition_number"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "content content--large" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.getDentitionNumber(
                                          symptom.dentition_number
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "interview-content-consultation-v2__block-symptom"
                      },
                      [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { staticClass: "block-header" }, [
                            _vm._v(
                              "2. " +
                                _vm._s(
                                  _vm.$t("objects.patient.text.symptomatology")
                                )
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text" }, [
                            _c("p", { staticClass: "content" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.getSymptoms(symptom)) +
                                  "\n              "
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "symptoms-comment",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.getSymptomsComment(symptom.symptoms)
                                )
                              }
                            })
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "interview-content-consultation-v2__block-comments"
                      },
                      [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "block-header color-blue" },
                            [
                              _vm._v(
                                "3. " +
                                  _vm._s(
                                    _vm.$t("objects.patient.text.main_symptom")
                                  )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "content" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(symptom.consultation_content) +
                                "\n            "
                            )
                          ])
                        ])
                      ]
                    )
                  ]
                )
              })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }