import { helpers as vuelidateHelper } from 'vuelidate/lib/validators'

export const RESERVABLE_STATUS = {
  RESERVABLE: 'reservable',
  UNRESERVABLE: 'unreservable'
}

export const RESPONSE_TYPE = {
  MAXIMUM: 'MAXIMUM',
  EXIST_PUBLIC: 'EXIST_PUBLIC'
}

export const VALIDATE_RULE = {
  CUSTOM_ALPHA_NUMERIC: vuelidateHelper.regex('customAlphaNumeric', /^[a-zA-Z0-9@.]+$/),
  // eslint-disable-next-line no-irregular-whitespace
  CUSTOM_JAPANESE_NAME: vuelidateHelper.regex('customJapaneseName', /^[一-龠ぁ-ゔァ-ヴーａ-ｚＡ-Ｚ０-９a-zA-Z0-9々〆〤 　]+$/),
  CUSTOM_HAFF_SIZE_ROMAJI_NAME: vuelidateHelper.regex('customHaffSizeRomajiName', /^[a-zA-Z]+$/)
}

export const WORDING_SETTING = {
  KEY: {
    KIREILIGN: {
      NOTE_FORM_BOOKING: 'wording_booking_form_attention',
      MAIL_TITLE_BOOKING: 'wording_subject_of_email_booking',
      MAIL_TITLE_UPDATE: 'wording_subject_of_email_update',
      MAIL_TITLE_CANCEL: 'wording_subject_of_email_cancel',
      MAIL_TITLE_INTERVIEW_SUCCESS: 'wording_subject_of_interview_email_success',
      MAIL_INFO_RESERVATION_BOOKING: 'wording_reservation_info_in_email',
      MAIL_INFO_RESERVATION_UPDATE: 'wording_reservation_update_info_in_email',
      MAIL_INFO_RESERVATION_CANCEL: 'wording_reservation_cancellation_info_in_email',
      MAIL_INFO_INTERVIEW_SUCCESS: 'wording_interview_success_info_in_email',
      MAIL_ATTENTION: 'wording_mail_attention',
      MAIL_INTRODUCTION: 'wording_mail_introduction',
      MAIL_FOOTER: 'wording_mail_footer'
    },
    KIREILIGN_KIDS: {
      NOTE_FORM_BOOKING: 'wording_kids_booking_form_attention',
      MAIL_TITLE_BOOKING: 'wording_kids_subject_of_email_booking',
      MAIL_TITLE_UPDATE: 'wording_kids_subject_of_email_update',
      MAIL_TITLE_CANCEL: 'wording_kids_subject_of_email_cancel',
      MAIL_TITLE_INTERVIEW_SUCCESS: 'wording_kids_subject_of_interview_email_success',
      MAIL_INFO_RESERVATION_BOOKING: 'wording_kids_reservation_info_in_email',
      MAIL_INFO_RESERVATION_UPDATE: 'wording_kids_reservation_update_info_in_email',
      MAIL_INFO_RESERVATION_CANCEL: 'wording_kids_reservation_cancellation_info_in_email',
      MAIL_INFO_INTERVIEW_SUCCESS: 'wording_kids_interview_success_info_in_email',
      MAIL_ATTENTION: 'wording_kids_mail_attention',
      MAIL_INTRODUCTION: 'wording_kids_mail_introduction',
      MAIL_FOOTER: 'wording_kids_mail_footer'
    }
  }
}

export const EMAIL_MAXLENGTH = 80

export const IS_PUBLIC = 1

export const BRANDS = {
  KIREILIGN: {
    id: 1,
    name: 'キレイライン',
    tag: 'kireilign'
  },
  KIDS: {
    id: 2,
    name: 'キレイラインKIDS',
    tag: 'kids'
  },
  INVISALIGN: {
    id: 3,
    name: 'インビザライン',
    tag: 'invisalign'
  },
  WIRE: {
    id: 6,
    name: '口ゴボ',
    tag: 'wire'
  },
  BRAVA: {
    id: 8,
    name: 'ブラーバ',
    tag: 'brava'
  }
}

export const CONTACT_TYPE = [
  {
    id: '00',
    name: '未対応'
  },
  {
    id: '10',
    name: '電話のみ'
  },
  {
    id: '01',
    name: 'KL提供フォームのみ'
  },
  {
    id: '11',
    name: '電話＆KL提供フォーム'
  },
  {
    id: '02',
    name: '提携院独自フォームのみ'
  },
  {
    id: '12',
    name: '電話＆提携院独自フォーム'
  }
]
