var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "patient-list patient-list--v2" },
    [
      _c(
        "div",
        { staticClass: "patient-list__header" },
        [
          _c("h2", { staticClass: "patient-list__heading" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("new_text.patient_list")) + "\n      "
            ),
            _c("span", { staticClass: "patient-list__heading__number" }, [
              _vm._v(_vm._s(_vm.separatorNumber(_vm.pagination.total)))
            ])
          ]),
          _vm._v(" "),
          _c("button-common", {
            staticClass: "patient-list__btn patient-list__btn--create-patient",
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "patient.registration" })
              }
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _c("img", {
                      attrs: { src: "/images/icon-edit-patient.svg" }
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("objects.patient.button.patient_registration")
                        ) +
                        "\n      "
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "patient-list__search" }, [
        _c(
          "form",
          {
            attrs: { name: "patient-search" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submitSearchDetail(true)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "patient-list__inner patient-list__inner--left" },
              [
                _c(
                  "div",
                  { staticClass: "patient-list__search--id" },
                  [
                    _c("label", { attrs: { for: "search_id" } }, [
                      _vm._v(_vm._s(_vm.$t("new_text.patient_id")))
                    ]),
                    _vm._v(" "),
                    _c("input-common", {
                      attrs: {
                        id: "search_id",
                        "default-value": _vm.search.id,
                        placeholder: _vm.$t(
                          "objects.patient.text.search_by_id"
                        ),
                        name: "search_id",
                        type: "number"
                      },
                      on: {
                        input: function(value) {
                          return (_vm.search.id = value)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "patient-list__search--name" },
                  [
                    _c("label", { attrs: { for: "search_name" } }, [
                      _vm._v(_vm._s(_vm.$t("new_text.patient_name")))
                    ]),
                    _vm._v(" "),
                    _c("input-common", {
                      attrs: {
                        id: "search_name",
                        "default-value": _vm.search.name,
                        placeholder: _vm.$t(
                          "objects.patient.text.search_by_name"
                        ),
                        name: "search_name"
                      },
                      on: {
                        input: function(value) {
                          return (_vm.search.name = value)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "patient-list__search--phone" },
                  [
                    _c("label", { attrs: { for: "search_phone" } }, [
                      _vm._v(_vm._s(_vm.$t("new_text.phone_number")))
                    ]),
                    _vm._v(" "),
                    _c("input-common", {
                      attrs: {
                        id: "search_phone",
                        "default-value": _vm.search.tel,
                        placeholder: _vm.$t(
                          "objects.patient.text.search_by_tel"
                        ),
                        name: "search_phone",
                        type: "number"
                      },
                      on: {
                        keypress: _vm.validPhoneNumber,
                        paste: _vm.validPhoneNumber,
                        keyup: _vm.validPhoneNumber
                      },
                      model: {
                        value: _vm.search.tel,
                        callback: function($$v) {
                          _vm.$set(_vm.search, "tel", $$v)
                        },
                        expression: "search.tel"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "patient-list__inner patient-list__inner--right" },
              [
                _c("button-common", {
                  staticClass: "patient-list__btn patient-list__btn--search",
                  attrs: { type: "submit" },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("new_text.button.search")) +
                              "\n          "
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _vm._v(" "),
                _c("button-common", {
                  class: [
                    "patient-list__btn patient-list__btn--reset",
                    {
                      "patient-list__btn--reset--active": _vm.enableResetSearch
                    }
                  ],
                  attrs: { disabled: !_vm.enableResetSearch },
                  on: { click: _vm.resetSearch },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("new_text.button.reset")) +
                              "\n          "
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "patient-list__search--expanded" },
              [
                _c("button-common", {
                  staticClass:
                    "patient-list__btn patient-list__btn--detailed-search",
                  on: {
                    click: function($event) {
                      _vm.detailed_search = !_vm.detailed_search
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("new_text.button.advanced_search")
                              ) +
                              "\n            "
                          ),
                          _c("img", {
                            class: [{ show: _vm.detailed_search }],
                            attrs: { src: "/images/blue-arrow-down.svg" }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _vm._v(" "),
                _vm.detailed_search
                  ? _c(
                      "div",
                      { staticClass: "patient-list__detailed-search" },
                      [
                        _c("div", { staticClass: "patient-list__wrap" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "patient-list__search__row patient-list__search__row--radio"
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "patient-list__label patient-list__label--absolute"
                                },
                                [_vm._v(_vm._s(_vm.$t("new_text.sex")))]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.genders, function(sex) {
                                return _c("radio-common", {
                                  key: sex.id,
                                  attrs: {
                                    label: sex.name,
                                    val: sex.id,
                                    name: "gender",
                                    "is-checked": sex.id === _vm.search.gender
                                  },
                                  on: {
                                    select: function(value) {
                                      return (_vm.search.gender = value)
                                    }
                                  }
                                })
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "patient-list__search__row patient-list__search__row--select-date"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "patient-list__search__row--year"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "patient-list__label patient-list__label--absolute"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("new_text.birthday"))
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("select-common", {
                                    staticClass: "patient-list__search--year",
                                    attrs: {
                                      options: _vm.selectYear,
                                      "default-value": _vm.search.year,
                                      name: "search_year"
                                    },
                                    on: {
                                      change: function(value) {
                                        return (_vm.search.year = value)
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("new_text.year")))
                              ]),
                              _vm._v(" "),
                              _c("select-common", {
                                staticClass: "patient-list__search--month",
                                attrs: {
                                  options: _vm.selectMonth,
                                  "default-value": _vm.search.month,
                                  name: "search_month"
                                },
                                on: {
                                  change: function(value) {
                                    return (_vm.search.month = value)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("new_text.month")))
                              ]),
                              _vm._v(" "),
                              _c("select-common", {
                                staticClass: "patient-list__search--day",
                                attrs: {
                                  options: _vm.selectDayOfMonth(
                                    _vm.search.year,
                                    _vm.search.month
                                  ),
                                  "default-value": _vm.search.day,
                                  name: "search_day"
                                },
                                on: {
                                  change: function(value) {
                                    return (_vm.search.day = value)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("new_text.day")))
                              ]),
                              _vm._v(" "),
                              _c("select-common", {
                                staticClass: "patient-list__search--age",
                                attrs: {
                                  options: _vm.selectAge,
                                  "default-value":
                                    _vm.getAge !== ""
                                      ? _vm.getAge
                                      : _vm.search.age,
                                  name: "search_age"
                                },
                                on: { change: _vm.setAge }
                              }),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("new_text.age")))
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "patient-list__search__row patient-list__search__row--email"
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "patient-list__label patient-list__label--absolute"
                                },
                                [_vm._v(_vm._s(_vm.$t("new_text.email")))]
                              ),
                              _vm._v(" "),
                              _c("input-common", {
                                staticClass: "patient-list__search--email",
                                attrs: {
                                  id: "search_email",
                                  "default-value": _vm.search.email,
                                  placeholder: _vm.$t(
                                    "objects.patient.text.search_by_email"
                                  ),
                                  name: "search_email"
                                },
                                on: {
                                  input: function(value) {
                                    return (_vm.search.email = value)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "patient-list__wrap" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "patient-list__search__row patient-list__search__row--radio patient-list__search__row--period"
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "patient-list__label patient-list__label--absolute"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "objects.patient.text.specify_the_period"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.patient_conditions, function(
                                condition
                              ) {
                                return _c("radio-common", {
                                  key: condition.id,
                                  attrs: {
                                    label: condition.name,
                                    val: condition.id,
                                    name: "condition",
                                    "is-checked":
                                      condition.id ===
                                      _vm.search.patient_condition
                                  },
                                  on: {
                                    select: function(value) {
                                      return (_vm.search.patient_condition = value)
                                    }
                                  }
                                })
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "patient-list__search__row patient-list__search__row--selected-day"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "patient-list__search__row--year"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "patient-list__label patient-list__label--absolute"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "objects.patient.text.period_start_date"
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("select-common", {
                                    staticClass: "patient-list__search--year",
                                    attrs: {
                                      options: _vm.selectReservationYear,
                                      "default-value":
                                        _vm.search.reservation_start.year,
                                      disabled:
                                        _vm.search.patient_condition === "none",
                                      name: "search_reservation_start_year"
                                    },
                                    on: {
                                      change: function(value) {
                                        return (_vm.search.reservation_start.year = value)
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("new_text.year")))
                              ]),
                              _vm._v(" "),
                              _c("select-common", {
                                staticClass: "patient-list__search--month",
                                attrs: {
                                  options: _vm.selectMonth,
                                  "default-value":
                                    _vm.search.reservation_start.month,
                                  disabled:
                                    _vm.search.patient_condition === "none",
                                  name: "search_reservation_start_month"
                                },
                                on: {
                                  change: function(value) {
                                    return (_vm.search.reservation_start.month = value)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("new_text.month")))
                              ]),
                              _vm._v(" "),
                              _c("select-common", {
                                staticClass: "patient-list__search--day",
                                attrs: {
                                  options: _vm.selectDayOfMonth(
                                    _vm.search.reservation_start.year,
                                    _vm.search.reservation_start.month
                                  ),
                                  "default-value":
                                    _vm.search.reservation_start.day,
                                  disabled:
                                    _vm.search.patient_condition === "none",
                                  name: "search_reservation_start_day"
                                },
                                on: {
                                  change: function(value) {
                                    return (_vm.search.reservation_start.day = value)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.$t("new_text.day")) +
                                    _vm._s(_vm.$t("new_text.tilde"))
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "patient-list__search__row--year patient-list__search__row--relative"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "patient-list__label patient-list__label--end-date"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "objects.patient.text.period_end_date"
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("select-common", {
                                    staticClass: "patient-list__search--year",
                                    attrs: {
                                      options: _vm.selectReservationYear,
                                      "default-value":
                                        _vm.search.reservation_end.year,
                                      disabled:
                                        _vm.search.patient_condition === "none",
                                      name: "search_reservation_end_year"
                                    },
                                    on: {
                                      change: function(value) {
                                        return (_vm.search.reservation_end.year = value)
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("new_text.year")))
                              ]),
                              _vm._v(" "),
                              _c("select-common", {
                                staticClass: "patient-list__search--month",
                                attrs: {
                                  options: _vm.selectMonth,
                                  "default-value":
                                    _vm.search.reservation_end.month,
                                  disabled:
                                    _vm.search.patient_condition === "none",
                                  name: "search_reservation_end"
                                },
                                on: {
                                  change: function(value) {
                                    return (_vm.search.reservation_end.month = value)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("new_text.month")))
                              ]),
                              _vm._v(" "),
                              _c("select-common", {
                                staticClass: "patient-list__search--day",
                                attrs: {
                                  options: _vm.selectDayOfMonth(
                                    _vm.search.reservation_end.year,
                                    _vm.search.reservation_end.month
                                  ),
                                  "default-value":
                                    _vm.search.reservation_end.day,
                                  disabled:
                                    _vm.search.patient_condition === "none",
                                  name: "search_reservation_end_day"
                                },
                                on: {
                                  change: function(value) {
                                    return (_vm.search.reservation_end.day = value)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("new_text.day")))
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "patient-list__search__row patient-list__search__row--treatment-details",
                              attrs: { hidden: "" }
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "patient-list__label patient-list__label--absolute"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "objects.patient.text.treatment_details"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  on: { click: _vm.showDetailSelectTreatment }
                                },
                                [
                                  _c("input-common", {
                                    attrs: {
                                      id: "search_treatment_id",
                                      "default-value":
                                        _vm.search.treatment_name,
                                      placeholder: _vm.$t(
                                        "new_text.placeholder.please_select"
                                      ),
                                      disabled: "",
                                      name: "treatment_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "patient-list__wrap" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "patient-list__search__row patient-list__search__row--brand-type"
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "patient-list__label patient-list__label--absolute"
                                },
                                [_vm._v(_vm._s(_vm.$t("new_text.brand")))]
                              ),
                              _vm._v(" "),
                              _c("handmade-select", {
                                attrs: {
                                  options: _vm.brandTypeOptions,
                                  "key-value": "type",
                                  placeholder: _vm.$t(
                                    "objects.patient.text.search_by_brand_type"
                                  ),
                                  "empty-value": _vm.$t(
                                    "new_text.no_selection"
                                  ),
                                  "parent-class-scroll": "patient-list--v2"
                                },
                                model: {
                                  value: _vm.search.brand_type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.search, "brand_type", $$v)
                                  },
                                  expression: "search.brand_type"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "patient-list__search__row patient-list__search__row--status-c"
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "patient-list__label patient-list__label--absolute"
                                },
                                [_vm._v(_vm._s(_vm.$t("new_text.status")))]
                              ),
                              _vm._v(" "),
                              _c("handmade-select", {
                                attrs: {
                                  options: _vm.sfStatusCForSearchOptions,
                                  placeholder: _vm.$t(
                                    "objects.patient.text.search_by_sf_status_c"
                                  ),
                                  "empty-value": _vm.$t(
                                    "new_text.no_selection"
                                  ),
                                  "parent-class-scroll": "patient-list--v2"
                                },
                                model: {
                                  value: _vm.search.sf_status_c,
                                  callback: function($$v) {
                                    _vm.$set(_vm.search, "sf_status_c", $$v)
                                  },
                                  expression: "search.sf_status_c"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isSupportCsl
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "patient-list__search__row patient-list__search__row--csl-brand"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "patient-list__label patient-list__label--absolute"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("new_text.csl_brand"))
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("handmade-select", {
                                    attrs: {
                                      options: _vm.cslBrandTypeOptions,
                                      placeholder: _vm.$t(
                                        "new_text.placeholder.please_select"
                                      ),
                                      "empty-value": _vm.$t(
                                        "new_text.no_selection"
                                      ),
                                      "key-value": "type",
                                      "parent-class-scroll": "patient-list--v2"
                                    },
                                    model: {
                                      value: _vm.search.csl_brand_type,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.search,
                                          "csl_brand_type",
                                          $$v
                                        )
                                      },
                                      expression: "search.csl_brand_type"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          {
            class: [
              "common-list common-list--patient",
              { "common-list--patient-has-csl-brand": _vm.isSupportCsl },
              "common-list--sticky"
            ]
          },
          [
            _c("table", { staticClass: "main-list" }, [
              _c("thead", [
                _c("tr", [
                  _c(
                    "th",
                    {
                      staticClass: "common-list__id common-list__sort",
                      on: {
                        click: function($event) {
                          return _vm.sortBy("sf_user_id")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("new_text.id")) +
                          "\n              "
                      ),
                      _c("span", { staticClass: "common-list__icon" }, [
                        _c("img", {
                          attrs: { src: _vm.getIconSort("sf_user_id") }
                        })
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("th", { staticClass: "common-list__name" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("new_text.name")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "common-list__age" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("new_text.age_title")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "common-list__phone" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("new_text.phone_number")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "common-list__brand" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("new_text.brand")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "common-list__status" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("new_text.status")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "common-list__in-charge" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("new_text.in_charge")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticClass:
                        "common-list__first-return-day common-list__sort",
                      on: {
                        click: function($event) {
                          return _vm.sortBy("started_to")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("new_text.first_return_day")) +
                          "\n              "
                      ),
                      _c("span", { staticClass: "common-list__icon" }, [
                        _c("img", {
                          attrs: { src: _vm.getIconSort("started_to") }
                        })
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isSupportCsl
                    ? _c("th", { staticClass: "common-list__csl-brand" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("new_text.csl_brand")) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _vm.patients.length > 0
                ? _c(
                    "tbody",
                    _vm._l(_vm.patients, function(patient) {
                      return _c(
                        "tr",
                        {
                          key: patient.id,
                          on: {
                            click: function($event) {
                              return _vm.redirectDetail(patient)
                            }
                          }
                        },
                        [
                          _c("td", { staticClass: "common-list__id" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  patient.sf_user_id || patient.patient_id
                                ) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "common-list__name" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.renderFullName(patient)) +
                                "\n              "
                            ),
                            _c("span", [
                              _vm._v(_vm._s(_vm.renderNameFurigana(patient)))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "common-list__age" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.renderAge(patient.birth_of_date)) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "common-list__phone" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(patient.tel) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "common-list__brand" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.renderBrand(patient.brand_type)) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "common-list__status" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.renderStatus(patient.sf_status_c)) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "common-list__in-charge" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(patient.name_staff) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "common-list__first-return-day" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.renderFirstVisitDate(patient)) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isSupportCsl
                            ? _c(
                                "td",
                                { staticClass: "common-list__csl-brand" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.renderBrand(patient.csl_brand_type)
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    0
                  )
                : _c("tbody", { staticClass: "common-list__not-found" }, [
                    _c("tr", [
                      _c("td", [
                        _c(
                          "p",
                          { staticClass: "common-list__label-not-found" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t(
                                    "new_validation.patient.no_patient_search"
                                  )
                                ) +
                                "\n              "
                            )
                          ]
                        )
                      ])
                    ])
                  ])
            ]),
            _vm._v(" "),
            _vm.patients.length
              ? _c(
                  "div",
                  { staticClass: "common-list__pagination" },
                  [
                    _vm.patients.length
                      ? _c("pagination-common", {
                          staticClass: "pagination-common--patient",
                          attrs: {
                            "current-page": _vm.pagination.current_page,
                            "last-page": _vm.pagination.last_page
                          },
                          on: { "change-page": _vm.changePage }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _c("modal-select-treatment", {
        attrs: { "treatment-data": _vm.treatment_data },
        on: { treatmentData: _vm.setTreatment }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }