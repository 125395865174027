const INIT_INTERVIEW_SHEET =
    [
      {
        question: 1,
        answer: '',
        items: {
          details: [
            {
              title: 'いつ頃',
              content: ''
            },
            {
              title: '医院名',
              content: ''
            }
          ]
        }
      },
      {
        question: 2,
        answer: '',
        items: {
          details: [
            {
              title: 'いつ頃',
              content: ''
            },
            {
              title: '症状',
              content: ''
            },
            {
              title: '矯正装置',
              content: [
              ]
            },
            {
              title: 'その他',
              content: ''
            }
          ]
        }
      },
      {
        question: 3,
        answer: '',
        items: {
          details: [
            {
              title: '金属アレルギー',
              content: [

              ]
            },
            {
              title: '金属名',
              content: ''
            },
            {
              title: '薬アレルギー',
              content: [

              ]
            },
            {
              title: '薬品名',
              content: ''
            },
            {
              title: '花粉症',
              content: [
                ''
              ]
            },
            {
              title: '発症時期',
              content: ''
            },
            {
              title: '原因植物',
              content: ''
            },
            {
              title: 'その他のアレルギー',
              content: [
                ''
              ]
            },
            {
              title: 'アレルギー物質',
              content: ''
            }
          ]
        }
      },
      {
        question: 4,
        answer: '',
        items: {
          details: [
            {
              title: 'いつ頃',
              content: ''
            },
            {
              title: '選択結果',
              content: [

              ]
            },
            {
              title: 'その他',
              content: ''
            }
          ]
        }
      },
      {
        question: 5,
        answer: '',
        items: {
          details: [
            {
              title: 'いつ頃',
              content: ''
            },
            {
              title: '部位',
              content: ''
            },
            {
              title: '対処法',
              content: ''
            }
          ]
        }
      },
      {
        question: 6,
        answer: '',
        items: {
          details: [
            {
              title: 'いつ頃',
              content: ''
            },
            {
              title: '選択肢',
              content: [
              ]
            },
            {
              title: 'その他',
              content: ''
            }
          ]
        }
      },
      {
        question: 7,
        answer: '未入力',
        items: {
          details: [
            {
              title: '具体的に',
              content: ''
            }
          ]
        }
      },
      {
        question: 8,
        answer: '未入力',
        items: {
          details: [
            {
              title: '選択肢',
              content: [
              ]
            }
          ]
        }
      },
      {
        question: 9,
        items: {
          details: [
            {
              title: '仰向け',
              content: [

              ]
            },
            {
              title: 'うつ伏せ',
              content: [

              ]
            },
            {
              title: '頭の向き',
              content: ''
            },
            {
              title: '横向き',
              content: [
              ]
            },
            {
              title: '向き',
              content: ''
            },
            {
              title: '様々寝相が変わる',
              content: [
              ]
            }
          ]
        }
      },
      {
        question: 10,
        answer: '未入力',
        items: [
          {
            details: [
              {
                title: '続柄',
                content: ''
              },
              {
                title: '症状',
                content: ''
              }
            ]
          },
          {
            details: [
              {
                title: '続柄',
                content: ''
              },
              {
                title: '症状',
                content: ''
              }
            ]
          },
          {
            details: [
              {
                title: '続柄',
                content: ''
              },
              {
                title: '症状',
                content: ''
              }
            ]
          }
        ]
      },
      {
        question: 11,
        answer: '',
        items: [
          {
            details: [
              {
                title: '診断名',
                content: ''
              },
              {
                title: 'いつ頃',
                content: ''
              },
              {
                title: '治療内容',
                content: ''
              },
              {
                title: '治療状況',
                content: [

                ]
              }
            ]
          },
          {
            details: [
              {
                title: '診断名',
                content: ''
              },
              {
                title: 'いつ頃',
                content: ''
              },
              {
                title: '治療内容',
                content: ''
              },
              {
                title: '治療状況',
                content: [

                ]
              }
            ]
          },
          {
            details: [
              {
                title: '診断名',
                content: ''
              },
              {
                title: 'いつ頃',
                content: ''
              },
              {
                title: '治療内容',
                content: ''
              },
              {
                title: '治療状況',
                content: [

                ]
              }
            ]
          }
        ]
      },
      {
        question: 12,
        answer: '',
        items: [
          {
            details: [
              {
                title: '病院名',
                content: ''
              },
              {
                title: '受診科名',
                content: ''
              },
              {
                title: '主治医',
                content: ''
              },
              {
                title: '診断名・症状',
                content: ''
              }
            ]
          },
          {
            details: [
              {
                title: '病院名',
                content: ''
              },
              {
                title: '受診科名',
                content: ''
              },
              {
                title: '主治医',
                content: ''
              },
              {
                title: '診断名・症状',
                content: ''
              }
            ]
          },
          {
            details: [
              {
                title: '病院名',
                content: ''
              },
              {
                title: '受診科名',
                content: ''
              },
              {
                title: '主治医',
                content: ''
              },
              {
                title: '診断名・症状',
                content: ''
              }
            ]
          }
        ]
      },
      {
        question: 13,
        answer: '',
        items: [
          {
            details: [
              {
                title: '診断名',
                content: ''
              },
              {
                title: '部位',
                content: ''
              },
              {
                title: '状態',
                content: ''
              }
            ]
          },
          {
            details: [
              {
                title: '診断名',
                content: ''
              },
              {
                title: '部位',
                content: ''
              },
              {
                title: '状態',
                content: ''
              }
            ]
          },
          {
            details: [
              {
                title: '診断名',
                content: ''
              },
              {
                title: '部位',
                content: ''
              },
              {
                title: '状態',
                content: ''
              }
            ]
          }
        ]
      },
      {
        question: 14,
        answer: '',
        items: {
          details: [
            {
              title: '選択肢',
              content: [
              ]
            },
            {
              title: 'その他',
              content: ''
            }
          ]
        }
      },
      {
        question: 15,
        answer: '',
        items: {
          details: [
            {
              title: '選択肢',
              content: [
              ]
            }
          ]
        }
      },
      {
        question: 16,
        answer: '',
        item_common: {
          details: [
            {
              title: '選択肢',
              content: [
              ]
            },
            {
              title: 'その他',
              content: ''
            }
          ]
        },
        items: [
          {
            details: [
              {
                title: '診断名',
                content: ''
              },
              {
                title: '服薬量1',
                content: ''
              },
              {
                title: '服薬量2',
                content: ''
              }
            ]
          },
          {
            details: [
              {
                title: '診断名',
                content: ''
              },
              {
                title: '服薬量1',
                content: ''
              },
              {
                title: '服薬量2',
                content: ''
              }
            ]
          },
          {
            details: [
              {
                title: '診断名',
                content: ''
              },
              {
                title: '服薬量1',
                content: ''
              },
              {
                title: '服薬量2',
                content: ''
              }
            ]
          },
          {
            details: [
              {
                title: '診断名',
                content: ''
              },
              {
                title: '服薬量1',
                content: ''
              },
              {
                title: '服薬量2',
                content: ''
              }
            ]
          },
          {
            details: [
              {
                title: '診断名',
                content: ''
              },
              {
                title: '服薬量1',
                content: ''
              },
              {
                title: '服薬量2',
                content: ''
              }
            ]
          }
        ]
      },
      {
        question: 17,
        answer: '',
        items: {
          details: [
            {
              title: '一日量',
              content: ''
            }
          ]
        }
      },
      {
        question: 18,
        answer: '',
        items: {
          details: [
            {
              title: 'よく飲まれるお酒',
              content: ''
            },
            {
              title: '一日量1',
              content: ''
            },
            {
              title: '一日量2',
              content: ''
            }
          ]
        }
      },
      {
        question: 19,
        items: {
          details: [
            {
              title: '身長',
              content: ''
            },
            {
              title: '体重',
              content: ''
            }
          ]
        }
      },
      {
        question: 20,
        items: {
          details: [
            {
              title: '血液型',
              content: '未入力'
            }
          ]
        },
        answer: null
      },
      {
        question: 21,
        items: {
          details: [
            {
              title: '血圧の状態',
              content: '未入力'
            },
            {
              title: '最低',
              content: ''
            },
            {
              title: '最高',
              content: ''
            }
          ]
        },
        answer: null
      },
      {
        question: 22,
        items: {
          details: [
            {
              title: '初潮年齢1',
              content: ''
            },
            {
              title: '初潮年齢2',
              content: ''
            }
          ]
        }
      },
      {
        question: 23,
        answer: '未入力',
        items: {
          details: [
            {
              title: '選択肢',
              content: [
              ]
            },
            {
              title: 'その他',
              content: ''
            }
          ]
        }
      },
      {
        question: 24,
        answer: '未入力',
        items: {
          details: [
            {
              title: '受診時期1',
              content: ''
            },
            {
              title: '受診時期2',
              content: ''
            },
            {
              title: '受診時期3',
              content: ''
            },
            {
              title: '受診時期4',
              content: ''
            },
            {
              title: '治療内容',
              content: ''
            }
          ]
        }
      }
    ]

export default INIT_INTERVIEW_SHEET
