import JsPDF from 'jspdf'
import domtoimage from 'dom-to-image'
import * as moment from 'moment'

export const printCalendar = {
  methods: {
    printCalendar: async function () {
      document.getElementById('print-calendar').style.visibility = 'visible'
      this.is_print_calendar = true
      this.$store.commit('set_loading', true)

      const minTime = this.min_active_time_print
      const maxTime = this.max_active_time_print

      const doc = new JsPDF({
        orientation: 'p',
        unit: 'pt',
        format: 'a4'
      })

      for (const [key, value] of Object.entries(this.page_print_range)) {
        this.min_active_time_print = value.min_time
        this.max_active_time_print = value.max_time

        await this.$nextTick()

        if (key !== 'page-0') {
          document.querySelector('#print-calendar .fc-day-grid').style.display = 'none'
          doc.addPage()
        }
        const element = document.getElementsByClassName('fc-view')[1]

        await domtoimage.toPng(element, { quality: 0.95, width: element.scrollWidth, height: element.scrollHeight })
          .then(async (dataUrl) => {
            const headerEl = document.getElementsByClassName('medusa-date')[1]
            await domtoimage.toPng(headerEl, {
              quality: 1,
              width: headerEl.getBoundingClientRect().width,
              height: headerEl.getBoundingClientRect().height
            })
              .then((headerUrl) => {
                const imgProps = doc.getImageProperties(dataUrl)
                const pdfWidth = doc.internal.pageSize.getWidth()
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
                const headerElWidth = headerEl.getBoundingClientRect().width / 1.328
                const headerElHeight = headerEl.getBoundingClientRect().height / 1.328

                doc.addImage(headerUrl, 'PNG', (doc.internal.pageSize.getWidth() / 2) - (headerElWidth / 2), 9)
                doc.addImage(dataUrl, 'PNG', 0, 18 + headerElHeight, pdfWidth, pdfHeight)
              })
          })
      }
      const clinicName = localStorage.getItem('clinic_formal_name')
      doc.save(`${clinicName}_${this.$t('main.visit_schedule')}_${moment(new Date(this.selectedDate)).format('YYYY_MM_DD')}.pdf`)

      this.min_active_time_print = minTime
      this.max_active_time_print = maxTime
      this.$store.commit('set_loading', false)
      this.is_print_calendar = false
      document.getElementById('print-calendar').style.visibility = 'hidden'
      this.reloadReservationData()
    }
  }
}
