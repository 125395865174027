var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-patient-order" }, [
    _c("div", { staticClass: "search-patient-order__header" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t("reservation.select_patient")))]),
      _vm._v(" "),
      _c("img", {
        attrs: { src: "/images/icon-close-search.svg" },
        on: {
          click: function($event) {
            return _vm.$emit("close")
          }
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "search-patient-order__content" },
      [
        _vm._l(_vm.patients, function(p, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "search-patient-order__item",
              on: {
                click: function($event) {
                  return _vm.selectionHandler(p)
                }
              }
            },
            [
              _c("div", [
                _c("div", { staticClass: "search-patient-order__item__info" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$t("common.ID")))]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(p.sf_user_id ? p.sf_user_id : p.patient_id))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "search-patient-order__item__info d-flex" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("common.name")))]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(p.last_name) +
                          _vm._s(p.first_name) +
                          "(" +
                          _vm._s(p.kana_last_name) +
                          " " +
                          _vm._s(p.kana_first_name) +
                          ")"
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "search-patient-order__item__info" }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("reservation.reservation_status")))
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.patientSFStatus(p.sf_status_c)))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "search-patient-order__item__info" }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("reservation.first_visit_date")))
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.patientVisitedOrCancelledDate(p)))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("img", { attrs: { src: "/images/arrow-right-blue.svg" } })
            ]
          )
        }),
        _vm._v(" "),
        _c(
          "infinite-loading",
          {
            attrs: { identifier: _vm.infinite_scroll_id },
            on: { infinite: _vm.infiniteScrollHandler }
          },
          [
            _c("div", {
              staticClass: "result-loader",
              attrs: { slot: "no-more" },
              slot: "no-more"
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "result-loader",
                attrs: { slot: "no-results" },
                slot: "no-results"
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("patient.modal.search_no_result")) +
                    "\n      "
                )
              ]
            )
          ]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }