var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.className] },
    [
      _c("sub-question", {
        ref: "sub_question",
        attrs: {
          question: _vm.question,
          options: _vm.option_yes_no,
          checked: _vm.select_option,
          "is-required": false
        },
        on: {
          "handle-answer": function($event) {
            return _vm.handleAnswer($event)
          }
        }
      }),
      _vm._v(" "),
      _c("div", { class: _vm.handleChildAnswerClass }, [
        _c("label", { staticClass: "sub-title" }, [
          _vm._v("その時異常はありましたか？")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group no-flex" },
          [
            _c("checkbox-common", {
              attrs: { label: "気分が悪くなった", val: "気分が悪くなった" },
              on: { input: _vm.selectOption },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c("checkbox-common", {
              attrs: {
                label: "血が止まりにくかった",
                val: "血が止まりにくかった"
              },
              on: { input: _vm.selectOption },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-inline selection-other-option pt-3" },
              [
                _c("checkbox-common", {
                  attrs: { label: "その他", val: "その他" },
                  on: { input: _vm.selectOption },
                  model: {
                    value: _vm.selections,
                    callback: function($$v) {
                      _vm.selections = $$v
                    },
                    expression: "selections"
                  }
                }),
                _vm._v(" "),
                _c("div", { class: ["other-input", _vm.disableOtherInput] }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.other,
                        expression: "other"
                      }
                    ],
                    staticClass: "form-control w-100",
                    attrs: {
                      type: "text",
                      placeholder: "その他の場合ご記入ください"
                    },
                    domProps: { value: _vm.other },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.other = $event.target.value
                      }
                    }
                  })
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }