var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item-pre" }, [
    _vm.question
      ? _c("div", { staticClass: "question" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", {
              staticClass: "title",
              domProps: { innerHTML: _vm._s(_vm.question) }
            })
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "answer" },
      [
        !_vm.noAnswer
          ? _c(
              "div",
              {
                class: [
                  _vm.checkQuestion ? "option-m" : "option-s",
                  "option_yes_no"
                ]
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "yes",
                    class: { choose: _vm.answer === "はい" }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("common.yes")) + "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "no",
                    class: { choose: _vm.answer === "いいえ" }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("common.no")) + "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.checkQuestion
                  ? _c(
                      "div",
                      {
                        class: [
                          { choose: _vm.answer === "未入力" },
                          "not-yet-entered"
                        ]
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("common.not_yet_entered")) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }