<template>
  <div :class="[className]">
    <sub-question
      ref="sub_question"
      :question="question"
      :options="option_yes_no"
      :checked="select_option"
      @handleAnswer="handleAnswer($event)"
    />
    <div :class="handleChildAnswerClass">
      <div class="form-group">
        <label class="color-blue">一日量：</label>
        <div class="inline-input-dose">
          <div class="select-option form-inline">
            <input
              v-model="time"
              class="form-control col-md-8 mr-2"
              type="text"
            >
            <span>本/日</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SubQuestion from '../template/SubQuestion'
  import { INTERVIEW_COMMON_OPTIONS, INTERVIEW_OPTIONS_NOT_ENTERED } from '../../../../utils/constants'
  import Common from './CommonFunction'

  export default {
    components: {
      SubQuestion
    },
    mixins: [Common],
    props: {
      className: {
        type: String,
        default: ''
      },
      patientInfo: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {
        number: 17,
        select_option: '',
        question: '喫煙習慣はありますか？',
        option_yes_no: [
          INTERVIEW_COMMON_OPTIONS.YES,
          INTERVIEW_COMMON_OPTIONS.NO,
          INTERVIEW_OPTIONS_NOT_ENTERED
        ],
        time: '',
        translate: {
          一日量: 'time'
        }
      }
    },
    computed: {
      handleChildAnswerClass: function () {
        return {
          'disable-child-answer': this.select_option === INTERVIEW_COMMON_OPTIONS.NO
        }
      }

    }
  }
</script>
