<template>
  <div
    v-if="this.$store.state.loading.loading"
    id="spinner-loading"
    class="loader-wrapper"
  >
    <div class="loader" />
    <div class="loader-section section-left" />
    <div class="loader-section section-right" />
  </div>
</template>
<script>
  export default {
    name: 'Loading'
  }
</script>
