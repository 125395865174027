export default {
  date_of_visit: 'Date Of Visit',
  description_rating: 'Description Rating',
  q2: {
    1: 'Was Adequate And Polite',
    2: 'Was Enough',
    3: 'Was Inadequate',
    4: 'There Was No Explanation'
  },
  total_answer: 'Responses',
  summary: '(first visit {first} / revisit {revisit})',
  survey_response_date: 'Survey Response Date',
  table: {
    arrived_at: 'Date visit',
    q2: 'Description rating',
    q5: 'Recommended evaluation',
    type: 'First/Revisit',
    updated_at: 'Answer date',
    v2q1: 'Treatment evaluation'
  },
  text: {
    answer_list: 'Answer list',
    dashboard: 'Dashboard',
    evaluation_breakdown: 'Evaluation breakdown',
    first_visit_evaluation: 'First visit explanation evaluation',
    number_of_answers: 'Number of answers',
    nps_text: 'NPS Breakdown Number and Composition Ratio',
    re_visit_evaluation: 'Return visit explanation evaluation',
    re_visit_treatment_evaluation: 'Return visit treatment evaluation'
  },
  treatment_evaluation: 'Treatment Evaluation',
  type: 'First Visit/Return Visit',
  type_all: 'All',
  type_first: 'First Visit',
  type_revisit: 'Re-examination',
  v2q1: {
    1: 'Very Satisfied',
    2: 'Somewhat Satisfied',
    3: 'Neither Agree Nor Disagree',
    4: 'Somewhat Dissatisfied',
    5: 'Very Dissatisfied'
  }
}
