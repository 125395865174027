var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "questionnaire-layout" }, [
    _c("div", { staticClass: "questionnaire-layout__menu" }, [
      _c(
        "div",
        {
          class: [
            "questionnaire-layout__menu__item",
            {
              "questionnaire-layout__menu__item--active": _vm.menu_active === 1
            }
          ],
          on: {
            click: function($event) {
              _vm.menu_active = 1
            }
          }
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("objects.questionnaire.text.dashboard")))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "questionnaire-layout__menu__item",
            {
              "questionnaire-layout__menu__item--active": _vm.menu_active === 2
            }
          ],
          on: {
            click: function($event) {
              _vm.menu_active = 2
            }
          }
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("objects.questionnaire.text.answer_list")))
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "questionnaire-layout__body" },
      [
        _vm.menu_active === 1 ? _c("dashboard") : _vm._e(),
        _vm._v(" "),
        _vm.menu_active === 2 ? _c("list") : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }