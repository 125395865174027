<template>
  <div class="forgot-password bg-blue">
    <loading />
    <div class="forgot-password__wrapper">
      <div class="forgot-password__header">
        <img
          src="/images/kirei_lign_logo.png"
          class="forgot-password__logo"
          alt="Kireilign"
        >
      </div>
      <div class="forgot-password__info">
        <p>{{ $t('new_text.forgot_password_text_1') }}</p>
        <p>{{ $t('new_text.forgot_password_text_2') }}</p>
      </div>
      <form
        autocomplete="off"
        method="post"
        class="forgot-password__form"
        @submit.prevent="resetPassword"
      >
        <div>
          <div class="forgot-password__form__group">
            <label
              for="staff_id"
              class="forgot-password__label"
            >{{ $t('objects.staff.staff_id') }}</label>
            <span
              id="exclusion-icon"
              class="forgot-password__exclusion-icon"
            ><img src="/images/exclusion.svg"></span>
            <b-popover
              target="exclusion-icon"
              placement="top"
              triggers="hover focus"
              custom-class="exclusion-notify"
              fallback-placement="clockwise"
            >
              <p v-html="$t('new_message.forgotten_staff_id_or_email')" />
            </b-popover>
            <input
              id="staff_id"
              v-model="staff_id"
              type="text"
              class="forgot-password__input"
              :placeholder="$t('new_text.placeholder.required', { attribute: $t('objects.staff.staff_id') })"
              name="staff_id"
            >
            <span
              v-if="validation_errors.staff_id"
              class="forgot-password__error-msg"
            >
              {{ validation_errors.staff_id }}
            </span>
          </div>
          <div class="forgot-password__form__group forgot-password__form__group--second">
            <label
              for="email"
              class="forgot-password__label"
            >{{ $t('new_text.email') }}</label>
            <input
              id="email"
              v-model="email"
              type="text"
              class="forgot-password__input"
              :placeholder="$t('new_text.placeholder.required', { attribute: $t('new_text.email') })"
              name="email"
            >
            <span
              v-if="validation_errors.email"
              class="forgot-password__error-msg"
            >{{ validation_errors.email }}</span>
          </div>
        </div>
        <span
          v-if="response_error"
          class="forgot-password__error-msg forgot-password__error-msg--response"
        >{{ response_error }}</span>
        <div class="forgot-password__form__action">
          <button
            type="submit"
            name="submit"
            class="forgot-password__button"
          >
            {{
              $t('new_text.button.send_verification_email')
            }}
          </button>
          <router-link
            :to="{ name: 'auth.login' }"
            class="forgot-password__button forgot-password__button--back"
          >
            {{ $t('new_text.button.back_to_login') }}
          </router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import loading from '../components/Loading'
  import { BPopover } from 'bootstrap-vue/esm/components/popover'
  import { checkValidEmail, checkValidStaffLoginId, mbtrim, showErrorModal } from '../utils/helpers'
  import { get } from 'lodash'

  export default {
    components: {
      loading,
      BPopover
    },
    data () {
      return {
        staff_id: '',
        email: '',
        validation_errors: {
          staff_id: '',
          email: ''
        },
        response_error: ''
      }
    },
    created () {
      document.title = this.$t('objects.page_title.password_reset').toString()
    },
    methods: {
      async resetPassword () {
        const submitData = {
          staff_id: this.staff_id,
          email: this.email
        }
        if (!this.validateSubmitData(submitData)) return

        this.$store.commit('set_loading', true)
        this.$store.dispatch('sendMailResetPassword', {
          staff_id: submitData.staff_id,
          email: submitData.email
        }).then(() => {
          this.$store.commit('set_loading', false)
          this.$router.push({ name: 'email.send' })
        }).catch((error) => {
          const errors = get(error, 'response.data.errors', {})

          if (errors && errors.staff) {
            this.response_error = this.$t('new_message.invalid_staff_input')
          } else {
            showErrorModal(this.$t('new_message.something_went_wrong'))
          }

          this.$store.commit('set_loading', false)
        })
      },
      resetErrors () {
        this.validation_errors = {
          staff_id: '',
          email: ''
        }
        this.response_error = ''
      },
      validateSubmitData: function (data) {
        let isValid = true
        this.resetErrors()

        if (!mbtrim(data.staff_id)) {
          this.validation_errors.staff_id = this.$t(
            'new_validation.common.required',
            { attribute: this.$t('new_validation.common.attributes.staff_id') }
          )
          isValid = false
        } else if (!checkValidStaffLoginId(data.staff_id)) {
          this.validation_errors.staff_id = this.$t(
            'new_validation.common.invalid',
            { attribute: this.$t('new_validation.common.attributes.staff_id') }
          )
          isValid = false
        }

        if (!mbtrim(data.email)) {
          this.validation_errors.email = this.$t(
            'new_validation.common.required',
            { attribute: this.$t('new_validation.common.attributes.email') }
          )
          isValid = false
        } else {
          if (!checkValidEmail(data.email)) {
            this.validation_errors.email = this.$t(
              'new_validation.common.invalid',
              { attribute: this.$t('new_validation.common.attributes.email') }
            )
            isValid = false
          } else if (data.email.length > 80) {
            this.validation_errors.email = this.$t('new_validation.common.maximum', {
              attribute: this.$t('common_page.email'),
              max: 80
            })
            isValid = false
          }
        }

        return isValid
      }
    }
  }
</script>
