<template>
    <div class="interview-info">
        <Information :patient_info="patient_info" />
        <AdvanceConsultation/>
        <PreInterview/>
        <Other/>
    </div>
</template>

<script>
    import Information from '../interview/info/Information'
    import AdvanceConsultation from '../interview/info/AdvanceConsultation'
    import PreInterview from '../interview/info/Pre-Interview'
    import Other from '../interview/info/Other'
    import helpers from '../../../utils/helpers.js'
    import * as moment from 'moment'
    export default {
        name: "InterviewInfo",
        props: ['patient_info'],
        components: {
            Information,
            AdvanceConsultation,
            PreInterview,
            Other
        },
        created() {
            this.patient_info = this.patient_info;
        },
    }
</script>