<template>
  <div class="content-inner bg-white interview-block">
    <div class="page-content">
      <div class="header-title">
        <p>{{ $t('interview.other_consultation') }}</p>
        <p
          class="gray-arrow"
          :class="{ 'toggle-icon' : show }"
          @click="handleClick"
        />
      </div>
      <div
        v-show="show"
        class="other-page content-block"
      >
        <div class="patient-detail-left">
          <div class="row-content">
            <div class="col-6 pre-block">
              <div class="item-pre">
                <div class="question">
                  <div class="form-group">
                    <div class="control-text">1. 転居の予定はありますか？</div>
                  </div>
                </div>
                <Answer
                  :answer="others.plan_to_move"
                  :question_id="'plan_to_move'"
                >
                  <div class="form-group d-flex"><label class="control-label same-width my-0">{{ $t('interview.when') }}：</label>
                    <div class="control-text my-0">　{{others.when_to_move1}}　年　　{{others.when_to_move2}}　月 ごろ</div>
                  </div>
                  <div class="form-group d-flex"><label class="control-label same-width my-0">{{ $t('interview.relocation') }}：</label>
                    <div class="control-text my-0">{{ others.moving_destination }}</div>
                  </div>
                </Answer>
              </div>
            </div>
            <div class="col-6 pre-block">
              <div class="item-pre">
                <div class="question">
                  <div class="form-group">
                    <div class="control-text">2. その他、診察に際してご要望などございました
                      ら、ご記入ください。</div>
                  </div>
                </div>
                <div class="answer">
                  <div class="form-group">
                    <span
                      class="white-space-wrap"
                      v-html="others.other_requests"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Answer from '../template/AnswerCommon'

  export default {
    components: {
      Answer
    },
    data () {
      return {
        show: true,
        others: {}
      }
    },
    computed: {
      getInterviewOther () {
        return this.$store.getters.get_other
      },
      getMoveTime () {
        if (this.others.when_to_move1 || this.others.when_to_move2) {
          return `${this.others.when_to_move1} 年 ${this.others.when_to_move2} 月 ごろ`
        }
        return ''
      }
    },
    created () {
      this.loadInterviewOther()
    },
    methods: {
      handleClick () {
        this.show = !this.show
      },
      loadInterviewOther () {
        this.others = this.getInterviewOther
      }
    }
  }
</script>
