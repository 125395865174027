import axios from 'axios'
import { state } from './auth'

export const actions = {
  getTotalNotification () {
    return axios.get('/api/reservation-notifications/total', {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getList ({ dispatch, commit }, params) {
    return axios.get('/api/reservation-notifications/', {
      headers: { Authorization: 'Bearer ' + state.token },
      params
    })
  },
  show ({ dispatch, commit }, queryData) {
    return axios.get('/api/reservation-notifications/show', {
      params: {
        id: queryData.id
      },
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },

  readAll () {
    return axios.post('/api/reservation-notifications/read-all', {}, {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getUnreadNotifications () {
    return axios.get('/api/reservation-notifications/unread-notification', {
      headers: { Authorization: 'Bearer ' + state.token }
    })
  }
}
