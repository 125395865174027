const patient = {
  basic_information: '患者情報の必須項目を入力してください',
  content_consultation: '相談内容の必須項目を入力してください',
  correct_birthday: '正しい生年月日を選択してください',
  comment_required: 'コメントを入力してください',
  empty_address: '住所を入力してください',
  reservation_end_must_be_less_than_365: '選択可能な期間終了日は365日後までです',
  first_name_required: '名を入力してください',
  invalid_phone: '電話番号は10~11桁の数値を入力してください。',
  invalid_profession: 'ご職業を選択してください',
  invalid_profession_other: 'その他の場合ご記入ください',
  kana_first_name_required: 'ふりがな名を入力してください',
  kana_last_name_required: 'ふりがな姓を入力してください',
  kana_required: 'ふりがなを入力してください',
  last_name_required: '姓を入力してください',
  other_consultation: 'その他の必須項目を入力してください',
  main_symptom_required: '主訴（どこが気になっていますか）を入力してください',
  need_answer: '回答が必要です',
  no_patient_search: '検索された患者は見つかりませんでした',
  patient_name_invalid: '名前に記号は使用できません',
  phone_number_invalid: '電話番号は10~11桁の数値を入力してください。',
  phone_only_number: '電話番号は数値で入力してください',
  phone_only_single_number: '半角数字のみ入力してください',
  please_enter_hiragana: 'ひらがなのみ入力してください',
  prior_consultation: '事前問診の必須項目を入力してください',
  reason_for_non_contract_required: '契約に至らなかった理由を入力してください',
  relationship_maxlength: '使用できる文字数制限',
  romaji_haffsize: '半角英字を入力してください',
  romaji_required: 'お名前(ローマ字表記)を入力してください',
  roma_first_name_required: 'ローマ字（名）を入力してください',
  roma_last_name_required: 'ローマ字（姓）を入力してください',
  zipcode_greater_than_7: '郵便番号は7桁の半角数字で入力してください',
  zipcode_invalid: '該当する郵便番号はみつかりませんでした'
}

export default patient
