<template>
  <div class="order">
    <div class="order-content">
      <div
        :class="[
          'content',
          {
            'content--search-detail': show_search_detail,
            'content--lab-design': $store.getters.isLabDesigner,
            'content--hide-content-sp': hide_search_content_in_sp
          }
        ]"
      >
        <div class="content__header">
          <div class="content__header content__header--small">
            <div class="content__text">
              {{ headerTitle }}
            </div>
            <div
              v-if="isInstructionAdmin || isLabDesigner"
              class="content__group content__group--instruction-admin"
            >
              <button
                v-if="status === order_status.PREPARE.value || status === order_status.SHIPPED.value"
                :disabled="!checkOrderSelected"
                :class="['content__group__button content__group__button--first', { 'content__group__button--enable': checkOrderSelected }]"
                @click="printTechnicalInfo"
              >
                {{ $t('objects.orders.button.print_order_detail') }}
              </button>
              <button
                v-if="!isLabDesigner"
                :disabled="!checkOrderSelected"
                :class="['content__group__button', { 'content__group__button--enable': checkOrderSelected }]"
                @click="printInstruction"
              >
                {{ $t('objects.orders.button.print_instruction') }}
              </button>
            </div>
            <div
              v-else
              class="content__group"
            >
              <button
                v-if="status === order_status.SHIPPED.value || status === order_status.FINISH.value"
                :disabled="!checkOrderSelected"
                :class="['content__group__button content__group__button--first', {'content__group__button--enable': checkOrderSelected}]"
                @click="show_update_status_confirmation = true"
              >
                {{ textButtonChangeStatus }}
              </button>
              <button
                v-if="status !== order_status.FINISH.value"
                :disabled="!checkOrderSelected"
                :class="['content__group__button', {'content__group__button--enable': checkOrderSelected}]"
                @click="printInstruction"
              >
                {{ $t('objects.orders.text.print_checked_technician') }}
              </button>
            </div>
          </div>
        </div>
        <div class="content__search">
          <search-for-instruction-admin
            v-if="isInstructionAdmin"
            :type-search="typeSearch"
            :current-status="status"
            :reset-active="resetActive"
            :max-length="max_length"
            @submit="getOrderList"
            @reset="[order_list = [], data_search = {}]"
            @show-search-detail="show_search_detail = !show_search_detail"
            @set-hide-search-content-in-sp="[hide_search_content_in_sp = false, show_search_detail = false]"
          />
          <search-for-lab-designer
            v-else-if="isLabDesigner"
            :type-search="typeSearch"
            :current-status="status"
            :reset-active="resetActive"
            @submit="getOrderList"
            @reset="[order_list = [], data_search = {}]"
            @show-search-detail="show_search_detail = !show_search_detail"
            @set-hide-search-content-in-sp="[hide_search_content_in_sp = false, show_search_detail = false]"
          />
          <search
            v-else
            :type-search="typeSearch"
            :current-status="status"
            :reset-active="resetActive"
            @submit="getOrderList"
            @reset="[order_list = [], data_search = {}]"
            @show-search-detail="show_search_detail = !show_search_detail"
            @set-hide-search-content-in-sp="[hide_search_content_in_sp = false, show_search_detail = false]"
          />
        </div>
        <div class="content__list">
          <order-list-instruction-admin
            v-if="isInstructionAdmin"
            ref="orderList"
            :class="[getClass, {'common-list--sticky': currentLayoutMode === layout_mode.PC}]"
            :order-list="order_list"
            :current-status="status"
            :lazy-load="lazy_load"
            @order-selected="handleOrderSelected"
            @handle-sort="handleSort"
          />
          <order-list-lab-designer
            v-else-if="isLabDesigner"
            ref="orderList"
            :class="[getClass, {'common-list--sticky': currentLayoutMode === layout_mode.PC}]"
            :order-list="order_list"
            :current-status="status"
            :lazy-load="lazy_load"
            @order-selected="handleOrderSelected"
            @handle-sort="handleSort"
          />
          <order-list
            v-else
            ref="orderList"
            :class="[getClass, {'common-list--sticky': currentLayoutMode === layout_mode.PC}]"
            :order-list="order_list"
            :current-status="status"
            :lazy-load="lazy_load"
            @order-selected="handleOrderSelected"
            @handle-sort="handleSort"
          />
          <div
            v-if="order_list.length > 0"
            class="order-list-pagination"
          >
            <pagination-common
              :current-page="pagination.current_page"
              :last-page="pagination.last_page"
              @change-page="changePage"
            />
          </div>
        </div>
      </div>
    </div>
    <iframe
      v-if="is_fetching_data_print"
      name="printInstructions"
      :src="printInstructionUrl"
      @load="onLoadIframe"
    />
    <modal-confirm
      v-if="show_update_status_confirmation"
      :text="msgConfirmUpdateStatus"
      @no="show_update_status_confirmation = false"
      @yes="handleChangeStatus"
    />
  </div>
</template>

<script>
  import Search from '../../components/order/Search'
  import SearchForInstructionAdmin from '../../components/order/instruction-admin/Search'
  import SearchForLabDesigner from '../../components/order/lab-designer/Search'
  import ModalConfirm from '../../components/common/ModalConfirm'
  import { INSTRUCTION_PRINTED, LAYOUT_MODE, ORDER_STATUS } from '../../utils/constants'
  import { showErrorModal } from '../../utils/helpers'
  import OrderList from '../../components/order/OrderList'
  import OrderListInstructionAdmin from '../../components/order/instruction-admin/List'
  import OrderListLabDesigner from '../../components/order/lab-designer/List'
  import PaginationCommon from '../../components/common/PaginationCommon'
  import { find, isEmpty } from 'lodash'
  import { multiplePrintTechnicalInfo } from '../../mixins/multiple-print-technical-info'
  import { headerPageToPrintTechnicalInfo } from '../../mixins/print-individual-technical-info'

  export default {
    components: {
      PaginationCommon,
      OrderList,
      ModalConfirm,
      Search,
      SearchForInstructionAdmin,
      OrderListInstructionAdmin,
      SearchForLabDesigner,
      OrderListLabDesigner
    },
    mixins: [
      multiplePrintTechnicalInfo,
      headerPageToPrintTechnicalInfo
    ],
    data () {
      return {
        status: 0,
        order_status: ORDER_STATUS,
        order_list: [],
        pagination: {
          current_page: 1,
          last_page: 1,
          offset: 2,
          per_page: 20
        },
        order_selected: [],
        is_fetching_data_print: false,
        instruction_file_path: '',
        show_update_status_confirmation: false,
        show_search_detail: false,
        hide_search_content_in_sp: false,
        data_search: {},
        lazy_load: false,
        max_length: {
          order_id: 45,
          count: 2,
          code: 7,
          sf_user_id: 191,
          patient_name: 383
        },
        layout_mode: LAYOUT_MODE
      }
    },
    computed: {
      headerTitle () {
        return this.$t('objects.orders.text.status.' + this.status.toString())
      },
      checkOrderSelected () {
        return this.order_selected.length !== 0
      },
      printInstructionUrl () {
        return this.$router.resolve({ name: 'order.instruction.print' }).href
      },
      typeSearch () {
        const currentStatus = find(this.order_status, (i) => {
          return i.value === this.status
        })

        return currentStatus?.label || 'other'
      },
      getClass () {
        const isOrderChecking = this.status === this.order_status.CHECKING.value

        if (isOrderChecking) {
          return 'common-list--order-checking'
        }

        if ((this.isInstructionAdmin || this.isLabDesigner) && this.status === this.order_status.PREPARE.value) {
          return 'common-list--order-prepare'
        }

        if (
          this.isInstructionAdmin &&
          (this.status === this.order_status.SHIPPED.value || this.status === this.order_status.FINISH.value)
        ) {
          return 'common-list--order-shipped'
        }

        return 'common-list--order-design'
      },
      resetActive () {
        return (this.$route.name === 'order.search' && !!this.order_list.length)
      },
      textButtonChangeStatus () {
        if (this.status === this.order_status.SHIPPED.value) {
          return this.$t('objects.orders.text.checked_order')
        }

        if (this.status === this.order_status.FINISH.value) {
          return this.$t('objects.orders.button.change_order_finish_to_shipped')
        }

        return ''
      },
      msgConfirmUpdateStatus () {
        if (this.status === this.order_status.SHIPPED.value) {
          return this.$t('objects.orders.text.confirm_update_status_msg')
        }

        if (this.status === this.order_status.FINISH.value) {
          return this.$t('objects.orders.text.confirm_update_status_finish_msg')
        }

        return ''
      },
      isInstructionAdmin () {
        return this.$store.getters.isInstructionAdmin
      },
      isLabDesigner () {
        return this.$store.getters.isLabDesigner
      },
      initDataSearch () {
        if (
          (this.isInstructionAdmin || this.isLabDesigner) &&
          this.$route.params.status === this.order_status.PREPARE.label
        ) {
          return {
            is_acs: 0
          }
        }

        return {}
      },
      currentLayoutMode () {
        return parseInt(this.$store.state.auth.layout_mode)
      }
    },
    watch: {
      $route () {
        this.pagination.current_page = 1
        this.getOrderList()
      }
    },
    created () {
      document.title = this.$t('objects.page_title.order').toString()
      this.getOrderList()
    },
    methods: {
      getStatus () {
        const statusName = this.$route.params.status
        this.status = 0
        for (const key in this.order_status) {
          if (this.order_status[key].label === statusName) {
            this.status = this.order_status[key].value
            break
          }
        }
      },
      getOrderList (dataSearch = this.initDataSearch, isSubmitSearch = false) {
        this.lazy_load = true
        this.getStatus()
        this.data_search = dataSearch

        if (this.status === 0) {
          return this.$router.push({ name: 'not.found' })
        }
        if (isSubmitSearch) {
          this.pagination.current_page = 1
        }

        this.hide_search_content_in_sp = true
        // Get order list
        this.$store.commit('set_loading', true)
        let inputData = {
          page: this.pagination.current_page,
          status: this.status,
          data: dataSearch
        }
        let getListAction = 'getOrderList'
        if (this.isInstructionAdmin || this.isLabDesigner) {
          getListAction = this.isInstructionAdmin ? 'instructionAdminGetOrderList' : 'labDesignerGetOrderList'
          inputData = {
            page: this.pagination.current_page,
            status: this.status,
            ...dataSearch
          }
        }

        this.$store.dispatch(getListAction, inputData).then((result) => {
          const { data: { results } } = result
          this.order_list = results.data
          this.pagination.current_page = results.current_page
          this.pagination.last_page = results.last_page
          this.order_selected = []
          this.$refs.orderList.resetSelected()
          this.$store.commit('set_loading', false)
          this.lazy_load = false
          $('.order .order-content .content').animate({ scrollTop: 0 }, 100)
        }).catch((err) => {
          this.$store.commit('set_loading', false)
          this.lazy_load = false
          const {
            response: {
              data: { errors }
            }
          } = err
          let errorMessage = ''
          for (const key in errors) {
            if (errors[key] === 'MAXIMUM' && this.max_length[key] !== undefined) {
              errorMessage += this.$t('new_validation.order.max_length', {
                field: this.$t('orders.search.label.' + key),
                max: this.max_length[key]
              }) + '<br>'
            }

            if (errors[key] === 'INVALID' && this.max_length[key] !== undefined) {
              errorMessage += this.$t(
                'new_validation.order.half_size',
                { field: this.$t('orders.search.label.' + key) }
              ) + '<br>'
            }
          }

          if (isEmpty(errorMessage)) {
            return showErrorModal(this.$t('sweet_alert.something_went_wrong'))
          }

          showErrorModal(errorMessage)
        })
      },
      changePage: function (page) {
        if (this.pagination.current_page === page) {
          return
        }

        this.pagination.current_page = page
        this.getOrderList(this.data_search)
      },
      handleOrderSelected (orderSelected) {
        this.order_selected = orderSelected
      },
      handleChangeStatus () {
        this.show_update_status_confirmation = false
        this.$store.commit('set_loading', true)
        const data = {
          ids: this.order_selected
        }

        if (this.status === this.order_status.SHIPPED.value) {
          data.from = this.order_status.SHIPPED.value
          data.to = this.order_status.FINISH.value
        }

        if (this.status === this.order_status.FINISH.value) {
          data.from = this.order_status.FINISH.value
          data.to = this.order_status.SHIPPED.value
        }

        this.$store.dispatch('updateOrderStatus', data)
          .then(() => {
            this.getOrderList()
          })
          .catch(() => {
            showErrorModal(this.$t('new_message.something_went_wrong'))
            this.$store.commit('set_loading', false)
          })
      },
      printInstruction: async function () {
        if (!this.checkOrderSelected) {
          return
        }
        this.is_fetching_data_print = false
        this.$store.commit('set_loading', true)
        const customOrderSelected = []
        this.order_list.forEach(order => {
          const orderId = this.order_selected.find(id => {
            return id === order.order_id
          })
          if (orderId) {
            customOrderSelected.push(orderId)
          }
        })
        await this.$store.dispatch('printInstructionsFile', customOrderSelected).then((result) => {
          const { data: { results } } = result

          this.instruction_file_path = results.instruction_file_path
          if (this.instruction_file_path === '') {
            this.$store.commit('set_loading', false)
            const orderIds = Object.values(results.orders)
            const orderCodes = []
            orderIds.forEach(id => {
              const order = this.order_list.find(item => {
                return item.order_id === id
              })

              if (order) {
                orderCodes.push(order.code)
              }
            })

            showErrorModal(this.$t('objects.orders.message.cannot_print_because_exist_error_files', {
              orderIds: orderCodes.join('\n')
            }), { width: '680px' })

            return
          }
          this.is_fetching_data_print = true

          if (this.isInstructionAdmin) {
            this.updatePrintStatus(results.orders)
          }
        }).catch(() => {
          this.$store.commit('set_loading', false)
          showErrorModal(this.$t('message.something_went_wrong'))
          this.is_fetching_data_print = false
        })
      },
      onLoadIframe () {
        this.$store.commit('set_loading', false)
        frames.printInstructions.getListOrderInstruction([this.instruction_file_path, true])
      },
      updatePrintStatus (orderPrinted) {
        for (const order of this.order_list) {
          const printed = orderPrinted.filter(item => item.id === order.order_id)
          if (printed.length > 0) {
            order.instruction_print_status = INSTRUCTION_PRINTED
          }
        }
      },
      handleSort (data) {
        this.data_search = { ...this.data_search, ...data }
        this.getOrderList(this.data_search)
      },
      printTechnicalInfo () {
        const orderPrints = this.order_list.filter((item) => {
          return this.order_selected.includes(item.order_id)
        })

        this.multiplePrintTechnicalInfo(orderPrints)
      }
    }
  }
</script>
