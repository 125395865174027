<template>
    <div class="interview-input">
        <div class="interview-input__control">
            <button @click="openSaveConfirm" class="btn interview-input__control--save"><img src="/images/edit-white.svg"><span>保存</span></button>
            <button @click="saveDraft" class="btn interview-input__control--draft"><img src="/images/edit.svg"><span>下書き保存</span></button>
        </div>
        <div class="interview-input__content">
            <ul class="errors" v-if="error_messages.length">
                <li
                    v-for="(item, key) in error_messages"
                    :key="key">
                    {{ item }}
                </li>
            </ul>
            <information
                ref="patient_info"
                :patientInfo="patient_info"
                :interview_info="interview_info"
                @patientInfo="syncPatientInfo"
            ></information>
            <content-consultation
                ref="content_consultation"
            ></content-consultation>
            <advance-consultation
                :patientInfo="patient_info"
                ref="advance_consultation"
            ></advance-consultation>
            <other
                ref="other_interview"
            ></other>
        </div>
    </div>
</template>

<script>
    import Information from './Information'
    import ContentConsultation from './ContentConsultation'
    import AdvanceConsultation from './AdvanceConsultation'
    import Other from './Other'
    import helpers from '../../../../utils/helpers.js'
    import { RESPONSE_STATUS } from '../../../../utils/constants'

    export default {
        name: 'Main',
        props: ['patient_info', 'interview_info'],
        components: {
            Information,
            ContentConsultation,
            AdvanceConsultation,
            Other
        },
        data () {
            return {
                submit_data: {
                    patient_id: this.patient_info.patient_id,
                    interview_info: {},
                    patient_info: this.patient_info
                },
                error_messages: []
            }
        },
        methods: {
            openSaveConfirm: function (params) {
                if (this.validation()) {
                    this.$emit('show_confirm_save_modal')
                }
            },
            save: function () {
                this.$store.commit('set_loading', true)
                const patientInfo = this.patient_info
                const interviewInfo = {
                    consultation_content: this.$refs.content_consultation.getResultAnswer(),
                    interview_sheet: this.$refs.advance_consultation.getResultAnswer(),
                    others: this.$refs.other_interview.getResultAnswer()
                }
                this.submit_data = {
                    patient_id: this.patient_info.patient_id,
                    interview_info: interviewInfo,
                    patient_info: patientInfo,
                    is_draft: 0
                }
                this.$store.dispatch('savePatientInterviewData', this.submit_data).then((result) => {
                    const { data: { status, results } } = result
                    if (status === RESPONSE_STATUS.OK && results) {
                        helpers.showSuccessModal(this.$t('message.saved'))
                        setTimeout(function () {
                            location.reload()
                        }, 1000)
                    } else {
                        helpers.showErrorModal(this.$t('sweet_alert.something_went_wrong'))
                    }
                    this.$store.commit('set_loading', false)
                }).catch(() => {
                    this.$store.commit('set_loading', false)
                    helpers.showErrorModal(this.$t('sweet_alert.something_went_wrong'))
                    setTimeout(function () {
                        location.reload()
                    }, 1000)
                })
            },
            validation: function () {
                let errors = []
                if (!this.$refs.patient_info.validation()) {
                    errors.push(this.$t('validation.interview.basic_information'))
                }
                if (!this.$refs.content_consultation.validation()) {
                    errors.push(this.$t('validation.interview.content_consultation'))
                }
                if (!this.$refs.advance_consultation.validation()) {
                    errors.push(this.$t('validation.interview.prior_consultation'))
                }
                if (!this.$refs.other_interview.validation()) {
                    errors.push(this.$t('validation.interview.other_consultation'))
                }

                this.error_messages = errors
                if (this.error_messages.length) {
                    return false
                }

                return true
            },
            saveDraft: function () {
                if (!this.validationDraft()) {
                    return
                }

                const msg = '下書き保存では問診票は"入力済み"になりません。問診票\nを"入力済み"にしたい場合は、"保存"を行なってください。'
                this.$store.commit('set_loading', true)
                const patientInfo = this.patient_info
                const interviewInfo = {
                    consultation_content: this.$refs.content_consultation.getResultAnswer(),
                    interview_sheet: this.$refs.advance_consultation.getResultAnswer(),
                    others: this.$refs.other_interview.getResultAnswer()
                }
                this.submit_data = {
                    patient_id: this.patient_info.patient_id,
                    interview_info: interviewInfo,
                    patient_info: patientInfo,
                    is_draft: 1
                }
                helpers.showWarningModal(msg, {
                    customClass: {
                        container: 'swal2-container--sms',
                        popup: 'toast-warning-message'
                    },
                    width: '520px'
                })
                this.$store.dispatch('savePatientInterviewData', this.submit_data).then((result) => {
                    const { data: { status, results } } = result
                    if (status === RESPONSE_STATUS.OK && results) {
                        helpers.showSuccessModal(this.$t('message.saved'))
                        setTimeout(function () {
                            location.reload()
                        }, 1000)
                    } else {
                        helpers.showErrorModal(this.$t('sweet_alert.something_went_wrong'))
                        setTimeout(function () {
                            location.reload()
                        }, 1000)
                    }
                    this.$store.commit('set_loading', false)
                }).catch(() => {
                    this.$store.commit('set_loading', false)
                    helpers.showErrorModal(this.$t('sweet_alert.something_went_wrong'))
                    setTimeout(function () {
                        location.reload()
                    }, 1000)
                })
            },
            validationDraft: function () {
                let errors = []

                if (!this.$refs.patient_info.validation(null, true)) {
                    errors.push(this.$t('validation.interview.basic_information'))
                }

                this.error_messages = errors
                if (this.error_messages.length) {
                    return false
                }

                return true
            },
            syncPatientInfo: function (data) {
                this.patient_info = data
            }
        }
    }
</script>
