var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-action", {
    staticClass: "cancel-reservation",
    attrs: {
      title: _vm.$t("reservation.do_you_really_want_to_cancel_this_reservation")
    },
    on: {
      close: function($event) {
        return _vm.$emit("close")
      }
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "cancel-reservation__content" }, [
              _c("div", { staticClass: "cancel-reservation__header" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("text.cancellation_reason"))
                ),
                _c("span", [_vm._v(_vm._s(_vm.$t("common.required")))])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cancel-reservation__list-reason" },
                _vm._l(_vm.reasons, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      class: [
                        "cancel-reservation__item",
                        {
                          "cancel-reservation__reason-other": _vm.isOtherReason(
                            item.id
                          )
                        }
                      ]
                    },
                    [
                      _c("radio", {
                        attrs: {
                          label: _vm.$t("" + item.key),
                          val: item.id,
                          "is-checked": item.id === _vm.reason_selected,
                          name: "reason"
                        },
                        on: { select: _vm.chooseReason }
                      }),
                      _vm._v(" "),
                      _vm.isOtherReason(item.id)
                        ? _c(
                            "div",
                            { staticClass: "cancel-reservation__note" },
                            [
                              _c("input-common", {
                                class: [
                                  {
                                    "common-input-error":
                                      _vm.errors.other_reason
                                  }
                                ],
                                attrs: {
                                  value: _vm.other_reason,
                                  disabled: _vm.isNotOtherReason,
                                  placeholder: _vm.$t(
                                    "text.cancellation_note_placeholder"
                                  )
                                },
                                on: { input: _vm.setLengthOtherReason }
                              }),
                              _vm._v(" "),
                              _vm.errors.other_reason
                                ? _c(
                                    "span",
                                    { staticClass: "common-msg-error" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.errors.other_reason) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c("button-common", {
              staticClass: "cancel-reservation__submit",
              on: { click: _vm.handleCancel },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("common.cancel_reservation")) +
                          "\n      "
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            }),
            _vm._v(" "),
            _c("button-common", {
              staticClass: "cancel-reservation__close",
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("common.no")) + "\n      "
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }