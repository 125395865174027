<template>
    <div :class="[className]">
        <sub-question
            ref="sub_question"
            :question="question"
            :options="option_yes_no"
            :checked="select_option"
            @handleAnswer="handleAnswer($event)"
        ></sub-question>
        <div v-bind:class="handleChildAnswerClass">
            <div v-for="(detail, index) in details" :key="detail.detail_index" class="form-group block-group">
                <label class="block-group__first">{{ detail.detail_index }}</label>
                <div class="block-group__inline">
                    <div class="form-group">
                        <label class="color-blue">病院名：</label>
                        <input v-model="detail.name" class="form-control" type="text" name="">
                    </div>
                    <div class="form-group">
                        <label class="color-blue">受診科名：</label>
                        <input v-model="detail.department" class="form-control" type="text" name="">
                    </div>
                    <div class="form-group">
                        <label class="color-blue">主治医：</label>
                        <input v-model="detail.doctor" class="form-control" type="text" name="">
                    </div>
                    <div class="form-group">
                        <label class="color-blue">診断名・症状：</label>
                        <input v-model="detail.symptom" class="form-control" type="text" name="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SubQuestion from '../template/SubQuestion'
    import { INTERVIEW_COMMON_OPTIONS } from '../../../../utils/constants'

    export default {
        components: {
            SubQuestion,
        },
        props: ['className'],
        data() {
            return {
                number: 12,
                select_option: INTERVIEW_COMMON_OPTIONS.NO,
                question: '現在、通院されていますか？',
                option_yes_no: [
                    INTERVIEW_COMMON_OPTIONS.YES,
                    INTERVIEW_COMMON_OPTIONS.NO,
                ],
                details: [
                    {
                        detail_index: 1,
                        name: '',
                        department: '',
                        doctor: '',
                        symptom: ''
                    },
                    {
                        detail_index: 2,
                        name: '',
                        department: '',
                        doctor: '',
                        symptom: ''
                    },
                    {
                        detail_index: 3,
                        name: '',
                        department: '',
                        doctor: '',
                        symptom: ''
                    }
                ],
                translate: {
                    '病院名': 'name',
                    '受診科名': 'department',
                    '主治医': 'doctor',
                    '診断名・症状': 'symptom',
                },
            }
        },
        created: function () {
            this.loadAnswerData();
        },
        methods: {
            loadAnswerData: function() {
                const dataState = this.$store.getters.get_question(this.number);
                this.select_option = dataState.answer;

                dataState.items.forEach((objDetail, index) => {
                    let name = objDetail.details.find(obj => obj.title === '病院名').content
                    let department = objDetail.details.find(obj => obj.title === '受診科名').content
                    let doctor = objDetail.details.find(obj => obj.title === '主治医').content
                    let symptom = objDetail.details.find(obj => obj.title === '診断名・症状').content

                    this.details[index].name = name || '';
                    this.details[index].department = department || '';
                    this.details[index].doctor = doctor || '';
                    this.details[index].symptom = symptom || '';
                });
            },
            formatResult: function () {
                let data = this.$store.getters.get_default_answer_items(this.number)
                data.answer = this.select_option

                if (data.answer === INTERVIEW_COMMON_OPTIONS.YES) {
                    data.items.forEach((objDetail, index) => {
                        objDetail.details.find(obj => obj.title === '病院名').content = this.details[index].name
                        objDetail.details.find(obj => obj.title === '受診科名').content = this.details[index].department
                        objDetail.details.find(obj => obj.title === '主治医').content = this.details[index].doctor
                        objDetail.details.find(obj => obj.title === '診断名・症状').content = this.details[index].symptom
                    })
                }
                return data
            },
            handleAnswer: function (value) {
                this.select_option = value
            }
        },
        computed: {
            handleChildAnswerClass: function () {
                return {
                    'disable-child-answer': this.select_option === INTERVIEW_COMMON_OPTIONS.NO
                }
            },

        }
    }
</script>
