<template>
  <modal-action
    :title="formInfo.title"
    class="password-verify password-verify--delete"
    @close="$emit('close')"
  >
    <template #body>
      <div class="password-verify__content">
        <div class="password-verify__body">
          {{ formInfo.description }}
        </div>
        <div
          class="password-verify__form"
        >
          <label class="password-verify__form__label">{{ formInfo.label }}</label>
          <input-common
            v-model="password"
            type="password"
            :placeholder="formInfo.placeholder"
            :class="['password-verify__form__input', { 'common-input-error': errors.password }]"
            @input="errors.password = ''"
          />
          <p
            v-if="errors.password"
            class="password-verify__form__error"
          >
            {{ errors.password }}
          </p>
        </div>
      </div>
    </template>
    <template #footer>
      <button-common
        class="button-v2--confirm"
        type="submit"
        @click="verifyPassword"
      >
        <template #content>
          {{ buttonText }}
        </template>
      </button-common>
      <button-common
        v-if="cancelButton"
        class="button-v2--cancel"
        @click="$emit('close')"
      >
        <template #content>
          {{ $t('new_text.button.no') }}
        </template>
      </button-common>
    </template>
  </modal-action>
</template>
<script>
  import { safeGet } from '../../utils/helpers'
  import ModalAction from '../common/ModalAction'
  import ButtonCommon from '../common/Button'
  import InputCommon from '../common/Input'

  export default {
    name: 'ModalVerifyPassword',
    components: {
      ModalAction,
      ButtonCommon,
      InputCommon
    },
    props: {
      formInfo: {
        type: Object,
        default: () => {}
      },
      cancelButton: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        password: '',
        errors: {
          password: ''
        }
      }
    },
    computed: {
      buttonText: function () {
        return this.formInfo.button_text
      }
    },
    methods: {
      verifyPassword: function () {
        this.emptyErrors()

        if (!this.password) {
          this.errors.password = this.$t('new_message.password_empty')
          return
        }
        this.$store.commit('set_loading', true)
        this.$store.dispatch('verifyStaffPassword', { password: this.password }).then((result) => {
          this.$store.commit('set_loading', false)
          const verifyToken = safeGet(result, 'data.results.verify_token')
          if (verifyToken) {
            this.$emit('on-verified', verifyToken)
          } else {
            this.errors.password = this.$t('new_message.password_is_incorrect')
          }
        }).catch(() => {
          this.$store.commit('set_loading', false)
        })
      },
      emptyErrors: function () {
        this.errors = {
          password: ''
        }
      }
    }
  }
</script>
