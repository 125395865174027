var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-v2" }, [
      _c("div", { staticClass: "modal-v2__wrapper" }, [
        _c("div", { staticClass: "modal-v2__container" }, [
          _c("div", { staticClass: "modal-v2__header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _vm.isShowCloseButton
              ? _c(
                  "span",
                  {
                    staticClass: "modal-v2__header__close",
                    on: {
                      click: function($event) {
                        return _vm.$emit("close")
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: "/images/icon-close-modal-blue.svg" }
                    })
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-v2__body" }, [_vm._t("body")], 2),
          _vm._v(" "),
          _vm.isShowFooter
            ? _c(
                "div",
                { staticClass: "modal-v2__footer" },
                [
                  _vm._t("footer", [
                    _c("div", { staticClass: "modal-v2__footer__default" }, [
                      _vm.isShowDeleteButton
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "modal-v2__button modal-v2__button--delete",
                              on: {
                                click: function($event) {
                                  return _vm.$emit("delete")
                                }
                              }
                            },
                            [
                              _vm._t("delete_button_content", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("new_text.button.delete")) +
                                    "\n                "
                                )
                              ])
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: [
                            "modal-v2__footer__group",
                            {
                              "modal-v2__footer__group--flex": !_vm.isShowDeleteButton,
                              "modal-v2__footer__group--one-button":
                                !_vm.isShowCancelButton ||
                                !_vm.isShowApprovalButton
                            }
                          ]
                        },
                        [
                          _vm.isShowCancelButton
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "modal-v2__button modal-v2__button--cancel",
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("close")
                                    }
                                  }
                                },
                                [
                                  _vm._t("cancel_button_content", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t("new_text.button.cancel")
                                        ) +
                                        "\n                  "
                                    )
                                  ])
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isShowApprovalButton
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "modal-v2__button modal-v2__button--confirm",
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("confirm")
                                    }
                                  }
                                },
                                [
                                  _vm._t("confirm_button_content", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.$t("new_text.button.save")) +
                                        "\n                  "
                                    )
                                  ])
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      )
                    ])
                  ])
                ],
                2
              )
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }