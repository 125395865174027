var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.$store.state.loading.loading
    ? _c(
        "div",
        { staticClass: "loader-wrapper", attrs: { id: "spinner-loading" } },
        [
          _c("div", { staticClass: "loader" }),
          _vm._v(" "),
          _c("div", { staticClass: "loader-section section-left" }),
          _vm._v(" "),
          _c("div", { staticClass: "loader-section section-right" })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }