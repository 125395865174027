<template>
  <div class="questionnaire-layout">
    <div class="questionnaire-layout__menu">
      <div
        :class="['questionnaire-layout__menu__item', { 'questionnaire-layout__menu__item--active': menu_active === 1 }]"
        @click="menu_active = 1"
      >
        <span>{{ $t('objects.questionnaire.text.dashboard') }}</span>
      </div>
      <div
        :class="['questionnaire-layout__menu__item', { 'questionnaire-layout__menu__item--active': menu_active === 2 }]"
        @click="menu_active = 2"
      >
        <span>{{ $t('objects.questionnaire.text.answer_list') }}</span>
      </div>
    </div>
    <div class="questionnaire-layout__body">
      <dashboard v-if="menu_active === 1" />
      <list v-if="menu_active === 2" />
    </div>
  </div>
</template>

<script>
  import Dashboard from './Dashboard'
  import List from './List'

  export default {
    components: {
      Dashboard,
      List
    },
    data () {
      return {
        menu_active: 1
      }
    },
    created () {
      document.title = this.$t('objects.page_title.questionnaire').toString()
    }
  }
</script>
