export default {
  account_setting: 'Account settings',
  accounting_info: 'Accounting information',
  activity_log: 'Activity',
  cancelled: 'Cancelled',
  clinic_confirmation_required: 'Clinic confirmation required',
  clinic_setting: 'Clinic settings',
  confirming_before_ordering: 'Confirming before ordering',
  create_instructions: 'Order',
  deleted_patient: 'Deleted patient information',
  delivery_completed: 'Delivery completed',
  instructions_for_saving_draft: 'Instructions for saving draft',
  interview_sheet: 'Interview sheet',
  ip_setting: 'IP address restrictions',
  order_patient_search: 'Order / Patient Search',
  patient_info: 'Patient information',
  preparing_to_ship: 'Preparing to ship',
  questionnaire_list: 'Visit questionnaire',
  reservation_date_setting: 'Reservation date and time setting',
  reservation_type_setting: 'Reservation type setting',
  setting: 'Setting',
  setup_design: 'During setup design',
  shipment_completed: 'Shipment completed',
  technician_order: 'Technician order'
}
