var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "patient-activity__item patient-activity__item--invoice" },
    [
      _c("div", { staticClass: "patient-activity__item__info" }, [
        _c("p", { staticClass: "patient-activity__item__log-content" }, [
          _vm._v("\n      " + _vm._s(_vm.logContent) + "\n    ")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "patient-activity__item__date-time" }, [
        _c("span", [_vm._v(_vm._s(_vm.logDate))])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "patient-activity__item__action" },
        [
          _vm.isShowButton
            ? _c(
                "router-link",
                {
                  staticClass: "patient-activity__item__link",
                  attrs: {
                    target: "_blank",
                    to: {
                      name: "patient.accounting.detail",
                      params: {
                        id: _vm.activity.patient_id,
                        aid: _vm.activity.activityable_id
                      }
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("new_text.button.display")) +
                      "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }