export default {
  accept: '受付済',
  address: '住所',
  address_search: '住所検索',
  admin: '発注情報Admin権限',
  age: '歳',
  age_title: '年齢',
  allow: '許可する',
  approval: '許可',
  around_month: '月ごろ',
  author: '作成者',
  auto_check_print_calendar: '次回から確認しない',
  basic_treatment_policy: '基本治療方針',
  basic_treatment_policy_description: '基本的な治療方針がある場合、(例：IPR量１コンタクト0.5 mmまで、小臼歯も治せる範囲で改善希望、等） ' +
    '下記に記載してください。技工指示書に記載がある場合は技工指示書の指示が優先されます。',
  basic_treatment_policy_note: '※最大255文字まで',
  birthday: '生年月日',
  brand: 'ブランド',
  brand_type: 'ブランド種別',
  button: {
    address_search: '住所検索',
    advanced_search: '詳細検索',
    apply_changes: '変更を適用する',
    back: '戻る',
    back_to_login: 'ログインページへ戻る',
    cancel: 'キャンセル',
    close: '閉じる',
    copy: 'コピー',
    decision: '決定',
    delete: '削除する',
    delete_2: '削除',
    deletion: '取り消す',
    display: '表示',
    edit: '編集',
    exclude: '除外する',
    modify: '修正する',
    next: '次へ',
    no: 'いいえ',
    none: 'なし',
    order: '発注する',
    print_interview_sheets: '問診票一括印刷',
    print_reservation_types: '見出しリスト印刷',
    reset: 'リセット',
    return_without_saving: '保存せずに戻る',
    save2: '保存',
    save: '保存する',
    save_draft: '下書き保存',
    search: '検索',
    select: '選択',
    send: '送信',
    send_verification_email: '認証メールを送信する',
    yes: 'あり',
    yes_1: 'はい'
  },
  cancel: 'キャンセル',
  clinic: 'クリニック',
  color: '色',
  comment: 'コメント',
  condition: '条件',
  confirm_delete: '本当に削除しますか？',
  consider_taking_it_home: '持ち帰り検討',
  considered_at_other_hospitals: '他院でも検討',
  contact: 'ご連絡先',
  correspondent: '対応者',
  created_at: '作成日時',
  csl_brand: 'C再ブランド',
  csl_character: 'C再',
  currently_pregnant: '現在妊娠中',
  date_and_time: 'リクエスト日時',
  datetime: {
    hour_minute: 'HH:mm',
    month_day: 'MM月DD日',
    year_month_day: 'YYYY年MM月DD日',
    year_month_day_simple: 'YYYY年M月D日',
    year_month_day_hour_minute: 'YYYY年MM月DD日 HH:mm',
    year_month_day_hour_minute_second_simple: 'YYYY年M月D日 HH:mm:ss',
    year_month_day_hour_minute_str: 'YYYY年MM月DD日(dd) HH:mm'
  },
  day: '日',
  delete: '削除',
  delete_cancellation: '削除取消',
  delete_date_time: '削除日時',
  delivery: '配信設定',
  difficulty_improving_chief_complaint: '主訴改善困難',
  disallow: '許可しない',
  disapproval: '不許可',
  do_not_deliver: '配信しない',
  email: 'メールアドレス',
  fee_reason: '料金的理由',
  female: '女性',
  first: '第',
  first_name2: '名',
  first_name: '名前（名）',
  first_return_day: '初回来院日',
  forgot_password_text_1: 'パスワード再設定メールをお送りします',
  forgot_password_text_2: 'ご登録いただいたメールアドレスを入力してください',
  full_name: '氏名',
  furigana: 'ふりがな',
  hiragana: 'ひらがな',
  how_to_take_pictures: '写真の撮り方',
  how_to_write_technical_manual: '技工指示書の書き方',
  id: 'ID',
  in_charge: '担当',
  invisalign: 'インビザライン',
  kana_first_name: 'ふりがな（名）',
  kana_last_name: 'ふりがな（姓）',
  kata_first_name: 'ふりがな（名）',
  kata_last_name: 'ふりがな（姓）',
  label: 'ラベル',
  last_content: '更新内容',
  last_login: '最終ログイン',
  last_modified: '更新日時',
  last_name: '名前（姓）',
  last_updated: '最終更新日',
  login: 'ログイン',
  login_id: 'ログインID',
  login_information: 'ログイン情報',
  login_title: 'サインインしてください',
  login_url: '【キレカル ログインURL】',
  male: '男性',
  minute: '分',
  month: '月',
  name: '名前',
  name_asc_order: '名前 昇順',
  no_contract: '未契約',
  no_detail: '明細無',
  no_selection: '選択なし',
  not_applicable: '適応外',
  not_entered: '未入力',
  number_of_time: '回数',
  on_hold: '保留',
  operation: '操作',
  other: 'その他',
  other_address: 'ご住所※お住いが別の場合記入',
  other_mouthpiece_orthodontics: '他のマウスピース矯正',
  other_orthodontic_treatments: '他の矯正治療',
  password: 'パスワード',
  patient: '患者',
  patient_id: '患者ID',
  patient_id_asc_order: '患者ID 昇順',
  patient_list: '患者一覧',
  patient_name: '患者名',
  patient_status: '患者ステータス',
  person_in_charge: '担当者',
  phone_number: '電話番号',
  placeholder: {
    allocation_name: '枠名が入ります。',
    first_name: '名前（名）',
    input_characters: '半角英数字8文字以上',
    last_name: '名前（姓）',
    lesson_or_sport: '習い事やスポーツをされていましたら教えてください',
    mei: 'Mei',
    no_hyphens_needed: 'ハイフンは不要です',
    no_selection: '選択なし',
    other_fill: 'その他の場合ご記入ください',
    password: 'パスワードを入力してください',
    patient_id: '患者IDで検索',
    patient_name: '患者名で検索',
    please_enter: '入力してください',
    please_enter_your_login_password: 'ログインパスワードを入力してください',
    please_fill: 'ご記入ください',
    please_select: '選択してください',
    required: '{attribute}を入力してください',
    sei: 'Sei',
    user_name: 'ユーザー名を入力してください'
  },
  position: '役職',
  printed: '印刷済',
  profession: '職業',
  quantity: '数量',
  questionnaire: '問診票',
  questionnaire_2: 'アンケート',
  questionnaire_visit: '来院アンケート',
  reason: '理由',
  receive_sms: '配信する',
  registration_date: '登録日',
  relationship: '続柄',
  remember: 'ログイン情報を保存する',
  requesting: 'リクエスト中',
  required: '必須',
  research: '再検索',
  reservation: '予約',
  reservations_frame: '予約枠',
  reservations_frame_auto: '自動予約枠',
  roma_first_name: 'ローマ字（名）',
  roma_last_name: 'ローマ字（姓）',
  roman: 'ローマ字',
  scheduled_visit_today: '本日来院予定',
  search_by_id: '患者IDで検索',
  search_not_found: '検索が見つかりません',
  select_reservation_type: '予約種別を選択',
  send: '送信',
  sex: '性別',
  showing_name: '表示名',
  species_alias: '種別名',
  status: 'ステータス',
  strikethrough: '-',
  subtotal: '小計',
  supporting_materials: 'サポート資料',
  switch_mode_pc: 'デスクトップ画面に切替',
  switch_mode_pc_title: 'デスクトップ画面利用中',
  switch_mode_sp: 'スマートフォン画面に切替',
  switch_mode_sp_title: 'スマートフォン画面利用中',
  system_information: 'システム情報',
  tab_name: 'タブ名',
  take_a_position: '担当職務',
  tilde: '〜',
  time: '{number}回目',
  time_required: '所要時間',
  unprinted: '未印刷',
  user_name: 'ユーザー名',
  week: '週',
  wire_orthodontics: 'ワイヤー矯正',
  year: '年',
  zip_code: '郵便番号'
}
