var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.validationErrors
    ? _c("div", [
        _c(
          "ul",
          {
            staticClass: "justify-content-center errors",
            class: { "text-left": _vm.textLeft }
          },
          _vm._l(_vm.validationErrors, function(value, key, index) {
            return _c("li", [_vm._v(_vm._s(value))])
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }