<template>
  <div class="setting-v2 setting-v2--background">
    <div class="setting-v2__content">
      <div class="setting-v2__header">
        <div class="setting-v2__header__box">
          <div class="setting-v2__header__box__text">
            {{ $t('objects.setting.text.clinic_setting') }}
          </div>
          <div class="setting-v2__header__box__button">
            <button @click="actionUpdate">
              {{ $t('new_text.button.save') }}
            </button>
          </div>
        </div>
      </div>
      <div class="setting-v2__main">
        <div class="setting-clinic__item">
          <div class="setting-clinic__item__title">
            <span>{{ $t('objects.setting.text.clinic_information') }}</span>
          </div>
          <div class="setting-clinic__item__line" />
          <div class="setting-clinic__item__name">
            <label>{{ $t('objects.setting.text.clinic_name') }}</label>
            <span>{{ formal_name }}</span>
          </div>
          <div class="setting-clinic__item__note">
            <span>{{ $t('objects.setting.text.clinic_name_note') }}</span>
          </div>
        </div>
        <div class="setting-clinic__item">
          <div class="setting-clinic__item__title">
            <span>{{ $t('objects.setting.text.contact_information') }}</span>
          </div>
          <div class="setting-clinic__item__line" />
          <div class="setting-clinic__item__group">
            <div class="setting-clinic__item__info">
              <label>{{ $t('new_text.email') }}</label>
              <div>
                <common-input
                  v-model="email"
                  maxlength="80"
                  :default-value="email"
                  :class="{ 'common-input-error': errors.email }"
                  @input="handleMailChange"
                />
                <span
                  v-if="errors.email"
                  class="error common-msg-error"
                >
                  {{ errors.email }}
                </span>
              </div>
            </div>
            <div class="setting-clinic__item__info">
              <label>{{ $t('new_text.phone_number') }}</label>
              <div>
                <common-input
                  v-model="phone"
                  maxlength="11"
                  :class="{ 'common-input-error': errors.phone }"
                  :default-value="phone"
                  @input="handlePhoneChange"
                />
                <span
                  v-if="errors.phone"
                  class="error common-msg-error"
                >
                  {{ errors.phone }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="setting-clinic__item">
          <div class="setting-clinic__item__title">
            <span>{{ $t('objects.setting.text.automatic_setting') }}</span>
          </div>
          <div class="setting-clinic__item__line" />
          <div class="setting-clinic__item__group">
            <div class="setting-clinic__item__info">
              <common-checkbox
                class="setting-clinic__item__checkbox"
                :is-checked="is_reload === 1"
                :value="true"
                @input="chooseReloadingCycle"
              />
              <label class="setting-clinic__item__auto">{{ $t('objects.setting.text.reload_setting') }}</label>
              <div class="setting-clinic__select-reloading-cycle">
                <common-select
                  :disabled="!is_reload"
                  :default-value="reloading_cycle"
                  :options="minutes"
                  :class="['common-select', { 'common-input-error': !isReloadingCycle }]"
                  key-value="value"
                  key-display="text"
                  @change="value => reloading_cycle = value"
                />
                <span>{{ $t('objects.setting.text.every_minute') }}</span>
                <span
                  v-if="!isReloadingCycle"
                  class="common-msg-error"
                >
                  {{ $t('new_validation.common.required', { attribute: $t('new_text.time_required') }) }}
                </span>
              </div>
            </div>
            <div class="setting-clinic__item__info">
              <common-checkbox
                class="setting-clinic__item__checkbox"
                :is-checked="is_notify === 1"
                :value="true"
                @input="chooseNotificationCycle"
              />
              <label class="setting-clinic__item__auto">{{ $t('objects.setting.text.notification_setting') }}</label>
              <common-select
                :disabled="!is_notify"
                :default-value="notification_cycle"
                :options="minutes"
                key-value="value"
                key-display="text"
                @change="value => notification_cycle = value"
              />
              <span>{{ $t('objects.setting.text.every_minute') }}</span>
            </div>
          </div>
        </div>
        <div class="setting-clinic__item">
          <div class="setting-clinic__item__title">
            <span>{{ $t('objects.setting.text.setting_print_calendar') }}</span>
          </div>
          <div class="setting-clinic__item__line" />
          <div class="setting-clinic__h-align">
            <radio-common
              name="is_print_with_memo"
              :val="1"
              class="setting-clinic__h-align--right"
              :label="$t('objects.reservation.text.print_calendar')"
              :is-checked="is_print_with_memo === 1"
              @select="handleSelected"
            />
            <radio-common
              name="is_print_with_memo"
              :val="0"
              :label="$t('objects.reservation.text.no_print_calendar')"
              :is-checked="is_print_with_memo === 0"
              @select="handleSelected"
            />
          </div>
        </div>
        <div
          v-if="false"
          class="setting-clinic__item"
        >
          <div class="setting-clinic__item__title">
            <span>{{ $t('objects.setting.text.daily_report') }}</span>
          </div>
          <div class="setting-clinic__item__line" />
          <div class="setting-clinic__item__group">
            <div class="setting-clinic__item__info">
              <common-checkbox
                class="setting-clinic__item__checkbox"
                :is-checked="revenue_status === 1"
                :value="true"
                @input="value => revenue_status = value"
              />
              <label>{{ $t('objects.setting.text.send_report') }}</label>
            </div>
            <div class="setting-clinic__item__report">
              <div class="setting-clinic__item__info">
                <label>{{ $t('objects.setting.text.transmission_time') }}</label>
                <common-select
                  :default-value="revenue_time"
                  :options="renderHours"
                  key-value="value"
                  key-display="text"
                  @change="value => revenue_time = value"
                />
              </div>
              <div class="setting-clinic__item__info">
                <label>{{ $t('objects.setting.text.destination_email') }}</label>
                <div>
                  <common-input
                    v-model="revenue_mails"
                    :default-value="revenue_mails"
                    maxlength="80"
                    @input="validation('revenue_mails')"
                  />
                  <p
                    v-if="errors.revenue_mails"
                    class="error"
                  >
                    {{ errors.revenue_mails }}
                  </p>
                  <p>{{ $t('objects.setting.text.destination_email_note') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading />
    <modal-action
      v-if="is_show_modal_confirm"
      :title="$t('objects.setting.message.save_edit')"
      :is-show-close-button="false"
      class="modal-confirm modal-v2--confirm-update"
    >
      <template #footer>
        <button-common
          class="button-v2--close"
          @click="is_show_modal_confirm = false"
        >
          <template #content>
            {{ $t('new_text.button.no') }}
          </template>
        </button-common>
        <button-common
          class="button-v2--confirm"
          @click="updateClinicSetting"
        >
          <template #content>
            {{ $t('new_text.button.yes_1') }}
          </template>
        </button-common>
      </template>
    </modal-action>
  </div>
</template>

<script>
  import Input from '../../../components/common/Input'
  import Checkbox from '../../../components/common/Checkbox'
  import Select from '../../../components/common/Select'
  import ButtonCommon from '../../../components/common/Button'
  import ModalAction from '../../../components/common/ModalAction'
  import Loading from '../../../components/Loading'
  import {
    showSuccessModal,
    showErrorModal,
    checkValidEmail,
    showMessageError,
    mbtrim
  } from '../../../utils/helpers'
  import { required, numeric, email, maxLength } from 'vuelidate/lib/validators'
  import { RESPONSE_STATUS, REVENUE_MAIL_STATUS } from '../../../utils/constants'
  import * as moment from 'moment'
  import RadioCommon from '../../../components/common/Radio'

  const isPhone = (value) => {
    const pattern = /^\d+$/
    if (!value) {
      return true
    }
    return !((value && !pattern.test(value)) || (value.length < 10 || value.length > 11))
  }

  export default {
    components: {
      RadioCommon,
      CommonInput: Input,
      CommonCheckbox: Checkbox,
      CommonSelect: Select,
      ButtonCommon,
      ModalAction,
      Loading
    },
    data () {
      return {
        formal_name: '',
        email: '',
        phone: '',
        is_reload: 0,
        reloading_cycle: 0,
        reloading_cycle_temp: 0,
        is_notify: 0,
        notification_cycle: 0,
        notification_cycle_temp: 0,
        minutes: [
          { value: 5, text: 5 },
          { value: 10, text: 10 },
          { value: 15, text: 15 },
          { value: 30, text: 30 },
          { value: 60, text: 60 }
        ],
        revenue_status: 0,
        revenue_time: '22:00:00',
        revenue_mails: '',
        is_show_modal_confirm: false,
        errors: {
          email: '',
          phone: ''
        },
        is_print_with_memo: null
      }
    },
    computed: {
      renderHours () {
        const hours = []
        for (let i = 0; i < 24; i++) {
          const item = {
            value: `${i}:00:00`,
            text: `${i}:00`
          }
          hours.push(item)
        }
        return hours
      },
      isReloadingCycle () {
        if (!this.is_reload) {
          return true
        }

        const index = this.minutes.findIndex(item => item.value === this.reloading_cycle)

        return this.reloading_cycle && index !== -1
      }
    },
    created () {
      document.title = this.$t('objects.page_title.setting').toString()
      this.getClinicInfo()
    },
    validations () {
      return {
        email: {
          required,
          email,
          maxLength: maxLength(191)
        },
        phone: {
          required,
          isPhone,
          numeric
        }
      }
    },
    methods: {
      getClinicInfo: function () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('getSettingClinicInfo').then((result) => {
          const clinic = result.data.results
          this.formal_name = clinic.formal_name
          this.email = clinic.email
          this.phone = clinic.phone
          this.$store.commit('set_loading', false)
          this.revenue_mails = clinic.revenue_mails ? clinic.revenue_mails : clinic.email
          this.revenue_status = clinic.is_send_revenue ? REVENUE_MAIL_STATUS.ENABLE : REVENUE_MAIL_STATUS.DISABLE
          this.revenue_time = moment(clinic.revenue_time, 'HH:mm:ss').format('HH:mm:ss')
          this.is_reload = clinic.is_reload
          this.is_notify = clinic.is_notify
          this.reloading_cycle = (clinic.reloading_cycle) ? clinic.reloading_cycle : ''
          this.notification_cycle = (clinic.notification_cycle) ? clinic.notification_cycle : ''
          this.reloading_cycle_temp = this.reloading_cycle
          this.notification_cycle_temp = this.notification_cycle
          this.is_print_with_memo = clinic.is_print_with_memo
        }).catch((e) => {
          this.$store.commit('set_loading', false)
          showMessageError(e, this)
        })
      },
      actionUpdate: function () {
        if (!this.validation()) return

        this.is_show_modal_confirm = true
      },
      updateClinicSetting: function () {
        if (!this.isReloadingCycle) {
          showErrorModal(this.$t('new_validation.common.required', { attribute: this.$t('new_text.time_required') }))
          return
        }
        const submitData = {
          formal_name: this.formal_name,
          email: this.email,
          phone: this.phone,
          is_reload: this.is_reload,
          is_notify: this.is_notify,
          reloading_cycle: (this.is_reload) ? this.reloading_cycle : '',
          notification_cycle: (this.is_notify) ? this.notification_cycle : '',
          is_print_with_memo: this.is_print_with_memo
        }
        this.is_show_modal_confirm = false
        this.$store.commit('set_loading', true)
        this.$store.dispatch('updateBasicInfo', submitData).then((result) => {
          const { data: { status, results } } = result
          if (status === RESPONSE_STATUS.OK && results) {
            this.reloading_cycle_temp = this.reloading_cycle
            this.notification_cycle_temp = this.notification_cycle
            showSuccessModal(this.$t('new_message.saved'))
          }
          this.$store.commit('set_loading', false)
        }).catch(() => {
          showErrorModal(this.$t('new_message.something_went_wrong'))
          this.$store.commit('set_loading', false)
        })
      },
      chooseReloadingCycle: function (value) {
        this.is_reload = value
        this.reloading_cycle = (!this.is_reload) ? '' : (this.is_reload && this.reloading_cycle_temp) ? this.reloading_cycle_temp : 5
      },
      chooseNotificationCycle: function (value) {
        this.is_notify = value
        this.notification_cycle = (!this.is_notify) ? '' : (this.is_notify && this.notification_cycle_temp) ? this.notification_cycle_temp : 5
      },
      validation (errorField = null) {
        if (!errorField) {
          this.emptyError()
        }
        this.$v.$touch()
        const errorMessages = [
          {
            field: 'email',
            type: 'required',
            msg: this.$t('new_validation.common.required', { attribute: this.$t('new_validation.common.attributes.email') }),
            err_field: 'email'
          },
          {
            field: 'email',
            type: 'email',
            msg: this.$t('new_validation.common.invalid', { attribute: this.$t('new_validation.common.attributes.email') }),
            err_field: 'email'
          },
          {
            field: 'email',
            type: 'maxLength',
            msg: this.$t('new_validation.common.maximum', { max: 191, attribute: this.$t('new_validation.common.attributes.email') }),
            err_field: 'email'
          },
          {
            field: 'phone',
            type: 'required',
            msg: this.$t('new_validation.common.required', { attribute: this.$t('new_validation.common.attributes.phone_number') }),
            err_field: 'phone'
          },
          {
            field: 'phone',
            type: 'isPhone',
            msg: this.$t(
              'new_validation.common.between',
              {
                attribute: this.$t('new_validation.common.attributes.phone_number'),
                min: 10,
                max: 11,
                type: this.$t('new_validation.common.attributes.digit_number')
              }
            ),
            err_field: 'phone'
          },
          {
            field: 'phone',
            type: 'numeric',
            msg: this.$t('new_validation.common.numeric', { attribute: this.$t('new_validation.common.attributes.phone_number') }),
            err_field: 'phone'
          }
        ]
        const hasErrorArr = []
        for (const index in errorMessages) {
          const error = errorMessages[index]
          if (this.$v[error.field] && this.$v[error.field][error.type] === false) {
            hasErrorArr.push(error.err_field)
            this.errors[error.err_field] = error.msg
          } else if (hasErrorArr.indexOf(error.err_field) === -1) {
            this.errors[error.err_field] = ''
          }
        }

        if (this.email && !checkValidEmail(this.email)) {
          this.errors.email = this.$t('new_validation.common.invalid_email')
        }

        return !this.hasError()
      },
      hasError () {
        for (const index in this.errors) {
          if (Object.prototype.hasOwnProperty.call(this.errors, index) && this.errors[index]) {
            return true
          }
        }
        return false
      },
      emptyError () {
        for (const index in this.errors) {
          if (Object.prototype.hasOwnProperty.call(this.errors, index)) {
            this.errors[index] = ''
          }
        }
      },
      handleSelected (value) {
        this.is_print_with_memo = value
      },
      handleMailChange (value) {
        this.errors.email = ''

        if (!mbtrim(value)) {
          this.errors.email = this.$t(
            'new_validation.common.required',
            { attribute: this.$t('new_validation.common.attributes.email') }
          )

          return
        }

        if (!checkValidEmail(value)) {
          this.errors.email = this.$t('new_validation.common.invalid_email')
        }
      },
      handlePhoneChange (value) {
        this.errors.phone = ''
        if (!mbtrim(value)) {
          this.errors.phone = this.$t(
            'new_validation.common.required',
            { attribute: this.$t('new_validation.common.attributes.phone_number') }
          )

          return
        }

        if (!/^\d+$/.test(value)) {
          this.errors.phone = this.$t(
            'new_validation.common.numeric',
            { attribute: this.$t('new_validation.common.attributes.phone_number') }
          )

          return
        }

        if (!isPhone(value)) {
          this.errors.phone = this.$t(
            'new_validation.common.between',
            {
              attribute: this.$t('new_validation.common.attributes.phone_number'),
              min: 10,
              max: 11,
              type: this.$t('new_validation.common.attributes.digit_number')
            }
          )
        }
      }
    }
  }
</script>
