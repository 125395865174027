<template>
    <transition name="modal">
        <div class="ts-modal">
            <div class="ts-modal__wrapper" @click="$emit('close')">
                <div class="ts-modal__container" @click.stop>
                    <div class="d-flex justify-content-center h-100">
                        <div class="calc calculator time-calculator" ref="calcBody" tabindex="0" @focus="onFocus"
                             @keyup="handleKeyUp($event)"
                             @blur="onBlur">
                            <div class="display">
                                <div class="wrapper">
                                    <span class="time-hour">{{expression_show}}</span>
                                </div>
                            </div>
                            <p class="row">
                                <button @click="addToExpression(7)">7</button>
                                <button @click="addToExpression(8)">8</button>
                                <button @click="addToExpression(9)">9</button>
                                <button class="control-action" @click="removeOneCharacter()"><img
                                    :src="'/images/backspace.svg'">
                                </button>
                            </p>
                            <p class="row">
                                <button @click="addToExpression(4)">4</button>
                                <button @click="addToExpression(5)">5</button>
                                <button @click="addToExpression(6)">6</button>
                                <button class="control-action" @click="clearAll()">C</button>
                            </p>
                            <p class="row">
                                <button @click="addToExpression(1)">1</button>
                                <button @click="addToExpression(2)">2</button>
                                <button @click="addToExpression(3)">3</button>
                                <button class="tall" @click="calculateExpression(3)"><img :src="'/images/enter.svg'">
                                </button>
                            </p>
                            <p class="row">
                                <button @click="addToExpression(0)">0</button>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    import eventBus from '../utils/event-bus'
    import * as moment from 'moment'

    const KEYBOARD_NUMBERS_MAP = {
        48: 0, 49: 1, 50: 2, 51: 3, 52: 4, 53: 5, 54: 6, 55: 7, 56: 8, 57: 9,
        96: 0, 97: 1, 98: 2, 99: 3, 100: 4, 101: 5, 102: 6, 103: 7, 104: 8, 105: 9,
        110: '.', 190: '.'
    }
    const KEYBOARD_SYMBOLS_DEVIDE = [111, 191]
    const KEYBOARD_SYMBOLS_PLUS = [107, 186, 187]
    const KEYBOARD_SYMBOLS_MULTIPLY = [106, 222, 186, 187]
    const KEYBOARD_SYMBOLS_SUBTRACT = [109, 187, 189]
    const KEYBOARD_ENTER = [13]
    const KEYBOARD_DELETE = [8, 46]
    const KEYBOARD_ESCAPE = [27]

    export default {
        name: 'TimeCalculator',
        props: {
            currentTime: {
                type: String,
                default: '12:00',
            }
        },
        data() {
            return {
                expression_show: this.currentTime || '--:--',
                isShown: false,
                isFocused: true
            }
        },
        created: function () {
            let this_vue = this
            eventBus.$on('calculator_set_default', function (val) {
                this_vue.setDefault(val)
            })
        },
        methods: {
            removeOneCharacter() {
                let convertTime = this.expression_show.split("").reverse()
                let first = true
                convertTime.forEach(function (char, index) {
                    if (first && char !== '-' && char !== ':') {
                        convertTime[index] = '-'
                        first = false
                    }
                })
                this.expression_show = convertTime.reverse().join("")
            },
            clearAll() {
                this.expression_show = '--:--'
            },
            addToExpression(val) {
                let convertTime = this.expression_show.split("")
                let first = true
                convertTime.forEach(function (char, index) {
                    if (char === '-' && first) {
                        convertTime[index] = val
                        first = false
                    }
                })
                if (this.validateTime(convertTime)) {
                    this.expression_show = convertTime.join("")
                }
            },
            validateTime(convertTime) {
                let timeCheck = _.clone(convertTime)
                timeCheck.forEach(function (char, index) {
                    if (char === '-') {
                        timeCheck[index] = 0
                    }
                })
                return moment(timeCheck.join(""), "HH:mm", true).isValid()
            },
            calculateExpression() {
                try {
                    let result = moment(this.expression_show, "HH:mm", true).isValid()
                    if (result) {
                        this.$emit('time_result', this.expression_show)
                    } else {
                        this.$emit('close')
                    }
                } catch (err) {
                    //
                }
            },
            onFocus() {
                this.isFocused = true
            },
            onBlur() {
                this.isFocused = false
            },
            setDefault(val) {
                this.expression_show = val
            },
            forceFocus(prevent_scroll = true) {
                if (prevent_scroll) {
                    this.$nextTick(() => {
                        let x = window.scrollX, y = window.scrollY
                        this.$refs.calcBody.focus()
                        window.scrollTo(x, y)
                    })
                } else {
                    this.$nextTick(() => this.$refs.calcBody.focus())
                }
            },
            handleKeyUp: function (event) {
                if (!this.isFocused) return true
                let keyCode = event.which
                if (KEYBOARD_NUMBERS_MAP[keyCode] !== undefined) {
                    if (keyCode === 56 && event.shiftKey) {
                        this.multiplicationOperator()
                    } else {
                        this.addToExpression(KEYBOARD_NUMBERS_MAP[keyCode])
                    }
                } else if (_.includes(KEYBOARD_SYMBOLS_DEVIDE, keyCode)) {
                    this.divisionOperator()
                } else if (event.code === 'Semicolon' || event.code === 'Equal' || event.code === 'NumpadAdd') {
                    this.additionOperator()
                } else if (event.code === 'Quote' || event.code === 'NumpadMultiply') {
                    this.multiplicationOperator()
                } else if (event.code === 'Minus' || event.code === 'NumpadSubtract') {
                    this.subtractionOperator()
                } else if (_.includes(KEYBOARD_ENTER, keyCode)) {
                    this.calculateExpression()
                } else if (_.includes(KEYBOARD_DELETE, keyCode)) {
                    this.removeOneCharacter()
                } else if (_.includes(KEYBOARD_ESCAPE, keyCode)) {
                    this.clearAll()
                }
            }
        }
    }
</script>
