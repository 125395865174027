<template>
    <div :class="[className]">
        <sub-question
            ref="sub_question"
        :question="question"
        :options="option_yes_no"
        :checked="select_option"
        @handleAnswer="handleAnswer($event)"
        ></sub-question>
        <div v-bind:class="handleChildAnswerClass">
            <div class="form-group">
                <checkbox-common
                    v-model="metal_allergy"
                    label="金属アレルギー"
                    val="金属アレルギー">
                </checkbox-common>

                <div class="row">
                    <div class="col-md-4 my-auto"><span class="verical-pading-checkbox">金属名：</span></div>
                    <div class="col-md-8">
                        <input v-model="metal_name" class="form-control" type="text">
                    </div>
                </div>
            </div>

            <div class="form-group">
                <checkbox-common
                    v-model="medicine"
                    label="薬アレルギー"
                    val="薬アレルギー">
                </checkbox-common>
                <div class="row">
                    <div class="col-md-4 my-auto"><span class="verical-pading-checkbox">薬品名：</span></div>
                    <div class="col-md-8">
                        <input v-model="drug_name" class="form-control" type="text">
                    </div>
                </div>
            </div>

            <div class="form-group">
                <checkbox-common
                    v-model="hay_fever"
                    label="花粉症"
                    val="花粉症">
                </checkbox-common>
                <div class="form-group row">
                    <div class="col-md-4 my-auto"><span class="verical-pading-checkbox">発症時期：</span></div>
                    <div class="col-md-8">
                        <input v-model="time" class="form-control" type="text">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4 my-auto"><span class="verical-pading-checkbox">原因植物：</span></div>
                    <div class="col-md-8">
                        <input v-model="cause_plant" class="form-control" type="text">
                    </div>
                </div>
            </div>

            <div class="form-group">
                <checkbox-common
                    v-model="other"
                    label="その他のアレルギー"
                    val="その他のアレルギー">
                </checkbox-common>
                <div class="row">
                    <div class="col-md-4 my-auto"><span class="verical-pading-checkbox">原因物質：</span></div>
                    <div class="col-md-8">
                        <input v-model="allergies" class="form-control" type="text">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SubQuestion from '../template/SubQuestion'
    import CheckboxCommon from '../template/CheckboxCommon'
    import { INTERVIEW_COMMON_OPTIONS } from '../../../../utils/constants'
    import Common from "./CommonFunction";

    export default {
        components: {
            SubQuestion,
            CheckboxCommon
        },
        props: ['className'],
        mixins: [Common],
        data() {
            return {
                number: 3,
                select_option: INTERVIEW_COMMON_OPTIONS.NO,
                question: 'アレルギーはありますか？',
                metal_allergy: [],
                metal_name: '',
                medicine: [],
                drug_name: '',
                hay_fever: [],
                time: '',
                cause_plant: '',
                other: [],
                allergies: '',
                translate: {
                    金属アレルギー: 'metal_allergy',
                    金属名: 'metal_name',
                    薬アレルギー: 'medicine',
                    薬品名: 'drug_name',
                    花粉症: 'hay_fever',
                    発症時期: 'time',
                    原因植物: 'cause_plant',
                    その他のアレルギー: 'other',
                    アレルギー物質: 'allergies'
                },
                option_yes_no: [
                    INTERVIEW_COMMON_OPTIONS.YES,
                    INTERVIEW_COMMON_OPTIONS.NO,
                ],
            }
        }
    }
</script>
