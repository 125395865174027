export default {
  accept: 'Accepted',
  address: 'Address',
  address_search: 'Address search',
  admin: 'Ordering information Admin authority',
  age: 'Age',
  age_title: 'Age',
  allow: 'Allowed',
  approval: 'Permission',
  around_month: 'Around month',
  author: 'Author',
  auto_check_print_calendar: 'Do not check next time',
  basic_treatment_policy: 'Basic treatment guidelines',
  basic_treatment_policy_description: 'If you have a basic treatment policy, please write it down below' +
    ' (e.g. IPR amount up to 0.5 mm per contact, hope for improvement to the extent that premolars can also be treated, etc.).' +
    ' If there is a description in the technical instructions, the instructions in the technical instructions will take precedence.',
  basic_treatment_policy_note: '※Up to 255 characters',
  birthday: 'Birthday',
  brand: 'Brand',
  brand_type: 'Brand type',
  button: {
    address_search: 'Address Search',
    advanced_search: 'Advanced Search',
    apply_changes: 'Apply changes',
    back: 'Back',
    back_to_login: 'Back To Login',
    cancel: 'Cancel',
    close: 'Close',
    copy: 'copy',
    decision: 'Decision',
    delete: 'Delete',
    delete_2: 'Delete',
    deletion: 'Deletion',
    display: 'Display',
    edit: 'Edit',
    exclude: 'Exclude',
    modify: 'Modify',
    next: 'Next',
    no: 'No',
    none: 'None',
    order: 'Order',
    print_interview_sheets: 'Print Interview Sheets',
    print_reservation_types: 'Print Reservation Types',
    reset: 'Reset',
    return_without_saving: 'Return without saving',
    save2: 'Save',
    save: 'Save',
    save_draft: 'Save draft',
    search: 'Search',
    select: 'Select',
    send: 'Send',
    send_verification_email: 'Send Verification Email',
    yes: 'Yes',
    yes_1: 'Yes'
  },
  cancel: 'Cancel',
  clinic: 'Clinic',
  color: 'Color',
  comment: 'Comment',
  condition: 'Condition',
  confirm_delete: 'Do you really want to delete this?',
  consider_taking_it_home: 'Consider taking it home',
  considered_at_other_hospitals: 'Considered at other hospitals',
  contact: 'Contact',
  correspondent: 'Correspondent',
  created_at: 'Created at',
  csl_brand: 'CSL brand',
  csl_character: 'CSL',
  currently_pregnant: 'Currently pregnant',
  date_and_time: 'Date and time',
  datetime: {
    hour_minute: 'HH:mm',
    month_day: 'MM-DD',
    year_month_day: 'YYYY-MM-DD',
    year_month_day_simple: 'YYYY-M-D',
    year_month_day_hour_minute: 'YYYY-MM-DD HH:mm',
    year_month_day_hour_minute_second_simple: 'YYYY-M-D HH:mm:ss',
    year_month_day_hour_minute_str: 'YYYY-MM-DD HH:mm'
  },
  day: 'Day',
  delete: 'Delete',
  delete_cancellation: 'Delete cancellation',
  delete_date_time: 'Deleted date time',
  delivery: 'Delivery settings',
  difficulty_improving_chief_complaint: 'Difficulty improving chief complaint',
  disallow: 'Not allowed',
  disapproval: 'Disapproval',
  do_not_deliver: 'Do not deliver',
  email: 'Mail address',
  fee_reason: 'Fee reason',
  female: 'Female',
  first: 'First',
  first_name2: 'Name',
  first_name: 'First name',
  first_return_day: 'First return day',
  forgot_password_text_1: 'We will send you a password reset email',
  forgot_password_text_2: 'Please enter your registered email address',
  full_name: 'Full name',
  furigana: 'Furigana',
  hiragana: 'Hiragana',
  how_to_take_pictures: 'How to take pictures',
  how_to_write_technical_manual: 'How to write a technical manual',
  id: 'ID',
  in_charge: 'In charge',
  invisalign: 'Invisalign',
  kana_first_name: 'Kana first name',
  kana_last_name: 'Kana last name',
  kata_first_name: 'Kata first name',
  kata_last_name: 'Kata last name',
  label: 'Label',
  last_content: 'Update content',
  last_login: 'Last logged in',
  last_modified: 'Update date and time',
  last_name: 'Last name',
  last_updated: 'Last updated',
  login: 'Login',
  login_id: 'Login ID',
  login_information: 'Login information',
  login_title: 'Please sign in',
  login_url: '【Kirekal Login URL】',
  male: 'Male',
  minute: 'Minute',
  month: 'Month',
  name: 'Name',
  name_asc_order: 'Name ascending order',
  no_contract: 'No contract',
  no_detail: 'No detail',
  no_selection: 'No selection',
  not_applicable: 'Not applicable',
  not_entered: 'Not entered',
  number_of_time: 'Times',
  on_hold: 'On hold',
  operation: 'Operation',
  other: 'Other',
  other_address: 'Address * Enter if you live in a different place',
  other_mouthpiece_orthodontics: 'Other mouthpiece orthodontics',
  other_orthodontic_treatments: 'Other orthodontic treatments',
  password: 'Password',
  patient: 'Patient',
  patient_id: 'Patient ID',
  patient_id_asc_order: 'Patient ID ascending order',
  patient_list: 'Patient list',
  patient_name: 'Patient name',
  patient_status: 'Patient status',
  person_in_charge: 'Person in charge',
  phone_number: 'Phone number',
  placeholder: {
    allocation_name: 'Please enter the allocation name.',
    first_name: 'Name (first name)',
    input_characters: 'Using half-width English more than 8 characters numbers and letters',
    last_name: 'Name (last name)',
    lesson_or_sport: 'Please tell me if you have any lessons or sports',
    mei: 'Mei',
    no_hyphens_needed: 'No hyphens needed',
    no_selection: 'No selection',
    other_fill: 'In other cases, please fill in',
    password: 'Enter your password',
    patient_id: 'Search by patient ID',
    patient_name: 'Search by patient name',
    please_enter: 'Please enter',
    please_enter_your_login_password: 'Please enter your login password',
    please_fill: 'Please fill',
    please_select: 'Please select',
    required: 'Please enter {attribute}',
    sei: 'Sei',
    user_name: 'Enter your username'
  },
  position: 'Position',
  printed: 'Printed',
  profession: 'Profession',
  quantity: 'Quantity',
  questionnaire: 'Questionnaire',
  questionnaire_2: 'Questionnaire',
  questionnaire_visit: 'Visit questionnaire',
  reason: 'Reason',
  receive_sms: 'deliver',
  registration_date: 'Registration date',
  relationship: 'Relationship',
  remember: 'Save your login information',
  requesting: 'Requesting',
  required: 'required',
  research: 'Re-search',
  reservation: 'Reservation',
  reservations_frame: 'Reservation frame',
  reservations_frame_auto: 'Automatic reservation frame',
  roma_first_name: 'Roma first name',
  roma_last_name: 'Roma last name',
  roman: 'Romaji',
  scheduled_visit_today: 'Scheduled to visit today',
  search_by_id: 'Search by ID',
  search_not_found: 'Search not found',
  select_reservation_type: 'Select reservation type',
  send: 'Send',
  sex: 'Sex',
  showing_name: 'Showing name',
  species_alias: 'Species alias',
  status: 'Status',
  strikethrough: '-',
  subtotal: 'Subtotal',
  supporting_materials: 'Supporting materials',
  switch_mode_pc: 'Switch to desktop',
  switch_mode_pc_title: 'Desktop screen in use',
  switch_mode_sp: 'Switch to smartphone',
  switch_mode_sp_title: 'Smartphone screen in use',
  system_information: 'System information',
  tab_name: 'Tab name',
  take_a_position: 'Take a position',
  tilde: '~',
  time: '{number} times',
  time_required: 'time required',
  unprinted: 'Unprinted',
  user_name: 'Username',
  week: 'Week',
  wire_orthodontics: 'Wire orthodontics',
  year: 'Year',
  zip_code: 'Zip code'
}
