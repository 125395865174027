var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19.996",
        height: "22",
        viewBox: "0 0 19.996 22"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Group_28104",
            "data-name": "Group 28104",
            transform: "translate(0 0)"
          }
        },
        [
          _c(
            "g",
            { attrs: { id: "Group_28105", "data-name": "Group 28105" } },
            [
              _c("path", {
                attrs: {
                  id: "Path_25960",
                  "data-name": "Path 25960",
                  d:
                    "M97.584,64.964l-.546-.454a1.461,1.461,0,0,0-2.057.156l-5.72,6.8a.74.74,0,0,0-.166.375l-.276,2.022a.741.741,0,0,0,.734.841.754.754,0,0,0,.227-.035l1.93-.62a.726.726,0,0,0,.339-.228l5.719-6.8a1.439,1.439,0,0,0,.334-1.071,1.471,1.471,0,0,0-.517-.988m-6.528,7.912-.96.39.291-.986,5.722-6.8s.6.516.674.591Z",
                  transform: "translate(-78.111 -56.014)"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path_25961",
                  "data-name": "Path 25961",
                  d:
                    "M38.774,66.956h-6.5a.75.75,0,1,0,0,1.5h6.5a.75.75,0,1,0,0-1.5",
                  transform: "translate(-27.522 -58.456)"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path_25962",
                  "data-name": "Path 25962",
                  d:
                    "M37.774,98.465h-5.5a.75.75,0,0,0,0,1.5h5.5a.75.75,0,0,0,0-1.5",
                  transform: "translate(-27.522 -85.965)"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path_25963",
                  "data-name": "Path 25963",
                  d:
                    "M35.775,129.973h-3.5a.75.75,0,0,0,0,1.5h3.5a.75.75,0,0,0,0-1.5",
                  transform: "translate(-27.523 -113.473)"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path_25964",
                  "data-name": "Path 25964",
                  d:
                    "M5,3H6.5a.75.75,0,0,0,.75-.75.75.75,0,1,1,1.5,0A.75.75,0,0,0,9.5,3H11V4.5H5ZM16,8.266V4.5A1.5,1.5,0,0,0,14.5,3h-2V2.25a.75.75,0,0,0-.75-.75H10.123a2.252,2.252,0,0,0-4.245,0H4.25a.75.75,0,0,0-.75.75V3h-2A1.5,1.5,0,0,0,0,4.5v16A1.5,1.5,0,0,0,1.5,22h13A1.5,1.5,0,0,0,16,20.5v-.852a.75.75,0,0,0-1.5,0V20.5H1.5V4.5h2v.75A.75.75,0,0,0,4.25,6h7.5a.75.75,0,0,0,.75-.75V4.5h2V8.266a.75.75,0,1,0,1.5,0",
                  transform: "translate(0 0)"
                }
              })
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }