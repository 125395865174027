<template>
  <div :class="[className]">
    <div class="form-group blood-pressure">
      <strong><p>{{ question }}</p></strong>
      <div class="radio-group h-align form-inline">
        <div
          :class="['check-container', 'ml-0']"
          @click="handleClick('高い')"
        >
          <span :class="['radio-button', handleClass('高い')]" />
          <span class="label-text">高い</span>
        </div>
        <div
          :class="['check-container', 'ml-0']"
          @click="handleClick('普通')"
        >
          <span :class="['radio-button', handleClass('普通')]" />
          <span class="label-text">普通</span>
        </div>
        <div
          :class="['check-container', 'ml-0']"
          @click="handleClick('低い')"
        >
          <span :class="['radio-button', handleClass('低い')]" />
          <span class="label-text">低い</span>
        </div>
        <div
          :class="['check-container', 'ml-0']"
          @click="handleClick('不明')"
        >
          <span :class="['radio-button', handleClass('不明')]" />
          <span class="label-text">不明</span>
        </div>
        <div class="break-flex-inline" />
        <div
          :class="['check-container', 'blood-pressure__last']"
          @click="handleClick(INTERVIEW_OPTIONS_NOT_ENTERED)"
        >
          <span :class="['radio-button', handleClass(INTERVIEW_OPTIONS_NOT_ENTERED)]" />
          <span class="label-text">{{ INTERVIEW_OPTIONS_NOT_ENTERED }}</span>
        </div>
      </div>
    </div>
    <div class="form-group d-inline-flex row">
      <div class="col-md-6 form-inline">
        <p class="color-blue my-auto">
          最高：
        </p>
        <input
          v-model="time"
          class="form-control col-md-8"
          type="text"
          name=""
        >
      </div>
      <div class="col-md-6 form-inline">
        <p class="color-blue my-auto">
          最低：
        </p>
        <input
          v-model="name"
          class="form-control col-md-8"
          type="text"
          name=""
        >
      </div>
    </div>
  </div>
</template>

<script>
  import { INTERVIEW_OPTIONS_NOT_ENTERED } from '../../../../utils/constants'
  import Common from './CommonFunction'

  export default {
    mixins: [Common],
    props: {
      className: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        number: 21,
        question: '血圧の状態は？',
        time: '',
        name: '',
        select_option: [],
        translate: {
          血圧の状態: 'select_option',
          最高: 'time',
          最低: 'name'
        },
        INTERVIEW_OPTIONS_NOT_ENTERED: INTERVIEW_OPTIONS_NOT_ENTERED
      }
    },
    methods: {
      handleClick: function (value) {
        this.select_option = value
      },
      handleClass: function (value) {
        return this.select_option === value ? 'checked' : ''
      }
    }
  }
</script>
