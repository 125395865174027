<template>
  <div :class="[className]">
    <sub-question
      ref="sub_question"
      :question="question"
      :options="option_yes_no"
      :checked="select_option"
      @handleAnswer="handleAnswer($event)"
    />
    <div :class="handleChildAnswerClass">
      <div class="form-group">
        <label class="color-blue">具体的に：</label>
        <input
          v-model="content"
          class="form-control"
          type="text"
          name=""
        >
      </div>
    </div>
  </div>
</template>

<script>
  import SubQuestion from '../template/SubQuestion'
  import { INTERVIEW_COMMON_OPTIONS, INTERVIEW_OPTIONS_NOT_ENTERED } from '../../../../utils/constants'
  import Common from './CommonFunction'

  export default {
    components: {
      SubQuestion
    },
    mixins: [Common],
    props: {
      className: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        number: 7,
        select_option: '',
        question: '喋りにくい言葉はありますか？<br>（サ行、タ行が言いづらいなど）',
        option_yes_no: [
          INTERVIEW_COMMON_OPTIONS.YES,
          INTERVIEW_COMMON_OPTIONS.NO,
          INTERVIEW_OPTIONS_NOT_ENTERED
        ],
        content: '',
        translate: {
          具体的に: 'content'
        }
      }
    }
  }
</script>
