import { ROUTE_VISIBLE_RELOAD } from '../utils/constants'

export const reloadPage = {
  data () {
    return { routeName: this.$route.name }
  },
  computed: {
    visibleReloadButton () {
      return ROUTE_VISIBLE_RELOAD.includes(this.routeName)
    }
  }
}
