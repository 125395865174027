<template>
  <div class="patient-questionnaires">
    <div class="patient-questionnaires__header">
      <h2 class="patient-questionnaires__header__title">
        {{ $t("objects.patient.text.questionnaire.title") }}
      </h2>
    </div>
    <div
      v-if="lazyLoad || questionnaires.length > 0"
      :class="['patient-questionnaires__content', { 'lazy-load': (lazyLoad && !isLoading) }]"
    >
      <questionnaire
        v-for="(item, index) in questionnaires"
        :key="index"
        :questionnaire="item"
      />
    </div>
    <div
      v-else
      class="patient-questionnaires__no-data"
    >
      {{ $t("objects.patient.text.questionnaire.no_questionnaires") }}
    </div>
  </div>
</template>
<script>
  import Questionnaire from '../../../components/patient/v2/questionnaire/Questionnaire.vue'
  import { get } from 'lodash'
  import { showErrorModal } from '../../../utils/helpers'
  export default {
    components: {
      Questionnaire
    },
    data () {
      return {
        questionnaires: [],
        patient_id: '',
        lazyLoad: false
      }
    },
    computed: {
      isLoading: function () {
        return this.$store.getters.isLoading
      }
    },
    created () {
      this.patient_id = this.$route.params.id
      this.getQuestionnaires()
    },
    methods: {
      getQuestionnaires () {
        this.lazyLoad = true
        this.$store.dispatch('getQuestionnaires', this.patient_id).then((res) => {
          this.questionnaires = get(res, 'data.results') || []
          this.lazyLoad = false
          this.$nextTick(() => {
            const reservationId = this.$route.query.reservation_id
            if (reservationId) {
              const offset = $('#reservationId_' + reservationId).offset()
              if (offset === undefined) {
                return
              }
              $('.patient-questionnaires').animate({
                scrollTop: offset.top - 120
              }, 700)
            }
          })
        }).catch(() => {
          this.lazyLoad = false
          showErrorModal(this.$t('new_message.something_went_wrong'))
        })
      }
    }
  }
</script>
