export const getNumber = {
  methods: {
    getNumber (number) {
      switch (number) {
        case 1:
          return '①'
        case 2:
          return '②'
        case 3:
          return '③'
        case 4:
          return '④'
        case 5:
          return '⑤'
      }
    }
  }
}
