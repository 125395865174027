var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { class: ["mc-modal edit-staff", _vm.customClass] }, [
      _c("div", { staticClass: "edit-staff__wrapper" }, [
        _c("div", { staticClass: "edit-staff__header" }, [
          _c("h2", { staticClass: "edit-staff__heading" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("objects.setting.text.edit_information")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "edit-staff__close",
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("img", { attrs: { src: "/images/icon-close-modal-blue.svg" } })]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "edit-staff__body" }, [
          _c("h2", { staticClass: "edit-staff__title" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("objects.setting.text.staff_information")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit-staff__row" }, [
            _c(
              "div",
              { staticClass: "edit-staff__row edit-staff__row--left" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("new_text.name")) +
                    "\n          "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "edit-staff__row edit-staff__row--right edit-staff__row--top"
              },
              [
                _c(
                  "div",
                  { staticClass: "edit-staff__column edit-staff__column--1" },
                  [
                    _c("input-common", {
                      class: { "common-input-error": _vm.errors.lastname },
                      attrs: {
                        "default-value": _vm.lastname,
                        placeholder: _vm.$t("new_text.placeholder.last_name"),
                        maxlength: "80"
                      },
                      on: { input: _vm.updateLastName }
                    }),
                    _vm._v(" "),
                    _vm.errors.lastname
                      ? _c("span", { staticClass: "common-msg-error" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.lastname) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "edit-staff__column edit-staff__column--2" },
                  [
                    _c("input-common", {
                      class: { "common-input-error": _vm.errors.firstname },
                      attrs: {
                        "default-value": _vm.firstname,
                        placeholder: _vm.$t("new_text.placeholder.first_name"),
                        maxlength: 40
                      },
                      on: { input: _vm.updateFirstName }
                    }),
                    _vm._v(" "),
                    _vm.errors.firstname
                      ? _c("span", { staticClass: "common-msg-error" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.firstname) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit-staff__row" }, [
            _c(
              "div",
              { staticClass: "edit-staff__row edit-staff__row--left" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("new_text.roman")) +
                    "\n          "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "edit-staff__row edit-staff__row--right edit-staff__row--top"
              },
              [
                _c(
                  "div",
                  { staticClass: "edit-staff__column edit-staff__column--1" },
                  [
                    _c("input-common", {
                      class: { "common-input-error": _vm.errors.lastname_roma },
                      attrs: {
                        "default-value": _vm.lastname_roma,
                        placeholder: _vm.$t("new_text.placeholder.sei"),
                        maxlength: "191"
                      },
                      on: { input: _vm.updateLastNameRoma }
                    }),
                    _vm._v(" "),
                    _vm.errors.lastname_roma
                      ? _c("span", { staticClass: "common-msg-error" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.lastname_roma) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "edit-staff__column edit-staff__column--2" },
                  [
                    _c("input-common", {
                      class: {
                        "common-input-error": _vm.errors.firstname_roma
                      },
                      attrs: {
                        "default-value": _vm.firstname_roma,
                        placeholder: _vm.$t("new_text.placeholder.mei"),
                        maxlength: 191
                      },
                      on: { input: _vm.updateFirstNameRoma }
                    }),
                    _vm._v(" "),
                    _vm.errors.firstname_roma
                      ? _c("span", { staticClass: "common-msg-error" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.firstname_roma) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit-staff__row" }, [
            _c(
              "div",
              { staticClass: "edit-staff__row edit-staff__row--left" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("new_text.position")) +
                    "\n          "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "edit-staff__row edit-staff__row--right" },
              [
                _c(
                  "div",
                  { staticClass: "edit-staff__column edit-staff__column--1" },
                  [
                    _c("select-common", {
                      staticClass: "edit-staff__select",
                      attrs: {
                        options: _vm.positionList,
                        "default-value": _vm.position_id,
                        placeholder: _vm.$t(
                          "objects.setting.button.please_select"
                        )
                      },
                      on: { change: _vm.updatePosition }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "edit-staff__column edit-staff__column--2" },
                  [
                    _c("label", { staticClass: "edit-staff__label" }, [
                      _vm._v(_vm._s(_vm.$t("objects.setting.text.is_admin")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radio-group h-align" },
                      _vm._l(_vm.admin_role_options, function(item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "edit-staff__radio",
                            on: {
                              click: function($event) {
                                _vm.is_admin = item.value
                              }
                            }
                          },
                          [
                            _c("radio-common", {
                              attrs: {
                                label: item.label,
                                val: item.value,
                                "is-checked":
                                  _vm.staffInfo.is_admin === item.value,
                                name: "admin_role"
                              },
                              on: { select: _vm.updateIsAdmin }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "edit-staff__title" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("new_text.login_information")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit-staff__row" }, [
            _c(
              "div",
              { staticClass: "edit-staff__row edit-staff__row--left" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("objects.setting.text.staff_id")) +
                    "\n          "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "edit-staff__row edit-staff__row--right" },
              [
                _c(
                  "div",
                  { staticClass: "create-staff__space" },
                  [
                    _c("input-common", {
                      class: { "common-input-error": _vm.errors.staff_id },
                      attrs: {
                        "default-value": _vm.staff_id,
                        placeholder: _vm.$t("new_validation.common.required", {
                          attribute: _vm.$t("objects.setting.text.staff_id")
                        }),
                        maxlength: "191"
                      },
                      on: { input: _vm.updateStaffId }
                    }),
                    _vm._v(" "),
                    _vm.errors.staff_id
                      ? _c("span", { staticClass: "common-msg-error" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.staff_id) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  { staticClass: "edit-staff__label edit-staff__label--13" },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("objects.setting.text.staff_id_login_rule"))
                    )
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit-staff__row" }, [
            _c(
              "div",
              { staticClass: "edit-staff__row edit-staff__row--left" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t("objects.setting.text.email_address_optional")
                    ) +
                    "\n          "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "edit-staff__row edit-staff__row--right" },
              [
                _c(
                  "div",
                  { staticClass: "create-staff__space" },
                  [
                    _c("input-common", {
                      class: { "common-input-error": _vm.errors.staff_email },
                      attrs: {
                        "default-value": _vm.staff_email,
                        placeholder: _vm.$t("new_validation.common.required", {
                          attribute: _vm.$t(
                            "new_validation.common.attributes.email"
                          )
                        }),
                        maxlength: "80"
                      },
                      on: { input: _vm.updateStaffEmail }
                    }),
                    _vm._v(" "),
                    _vm.errors.staff_email
                      ? _c("span", { staticClass: "common-msg-error" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.staff_email) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit-staff__row" }, [
            _c(
              "div",
              { staticClass: "edit-staff__row edit-staff__row--left" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("objects.setting.text.password_init")) +
                    "\n          "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "edit-staff__row edit-staff__row--right" },
              [
                _c(
                  "div",
                  { staticClass: "create-staff__space" },
                  [
                    _c("input-common", {
                      class: { "common-input-error": _vm.errors.password },
                      attrs: {
                        "default-value": _vm.password,
                        placeholder: _vm.$t("new_validation.common.required", {
                          attribute: _vm.$t(
                            "objects.setting.text.password_init"
                          )
                        }),
                        maxlength: "191",
                        type: "password",
                        autocomplete: "new-password"
                      },
                      on: { input: _vm.updateStaffPassword }
                    }),
                    _vm._v(" "),
                    _vm.errors.password
                      ? _c("span", { staticClass: "common-msg-error" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.password) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  { staticClass: "edit-staff__label edit-staff__label--13" },
                  [_vm._v(_vm._s(_vm.$t("objects.setting.text.password_min")))]
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              "edit-staff__footer",
              { "edit-staff__footer--delete": !_vm.isLabDesigner }
            ]
          },
          [
            !_vm.isLabDesigner
              ? _c("button-common", {
                  staticClass: "edit-staff__button edit-staff__button--delete",
                  on: {
                    click: function($event) {
                      return _vm.$emit(
                        "show-modal-confirm-delete",
                        _vm.staffInfo.id
                      )
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("new_text.button.delete")) +
                                "\n          "
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1888926873
                  )
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "edit-staff__inner edit-staff__inner--right" },
              [
                _c("button-common", {
                  class: [
                    "edit-staff__button edit-staff__button--reset-password",
                    { disable: !_vm.staff_email }
                  ],
                  attrs: { disabled: !_vm.staff_email },
                  on: {
                    click: function($event) {
                      return _vm.submitForm(true)
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("objects.setting.text.reset_password")
                              ) +
                              "\n            "
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _vm._v(" "),
                _c("button-common", {
                  staticClass: "edit-staff__button edit-staff__button--save",
                  on: { click: _vm.submitForm },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("new_text.button.save")) +
                              "\n            "
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }