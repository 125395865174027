import { TRIGGER_CLICK_CONTENT, TRIGGER_CLICK_TYPE } from '../utils/constants'

export default {
  data () {
    return {
      trigger_click_type: TRIGGER_CLICK_TYPE,
      trigger_click_content: TRIGGER_CLICK_CONTENT
    }
  },
  methods: {
    triggerClick (type, content, orderId = 0) {
      if (this.$store.getters.isStaff) {
        let payload = {
          type: type,
          content: content
        }

        if (type !== this.trigger_click_type.MENU) {
          payload = { ...payload, order_id: orderId }
        }
        this.$store.dispatch('trigger/triggerClick', { payload }).finally(() => {})
      }
    }
  }
}
