var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page page-print" }, [
    _c("main", { staticClass: "patient-detail-page patient-detail-print" }, [
      _c("div", { staticClass: "col-12 main-content" }, [
        _c("section", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "content-wrapper content-wrapper--interview-print" },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-7 offset-5" },
                  [_c("InterviewCheckList")],
                  1
                ),
                _vm._v(" "),
                !_vm.is_loading
                  ? _c(
                      "div",
                      {
                        staticClass: "col-md-12 patient-detail-left",
                        attrs: { id: "patient-detail-print-area" }
                      },
                      [
                        _vm.is_completed
                          ? _c("InterviewConfirmation", {
                              attrs: { patient_info: _vm.patient_info }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }