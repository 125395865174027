var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-list-reservation-type" },
    [
      _c("modal-action", {
        attrs: { title: _vm.$t("new_text.select_reservation_type") },
        on: {
          close: function($event) {
            return _vm.$emit("close")
          },
          confirm: function($event) {
            return _vm.$emit("select", _vm.reservation_type_selected)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "modal-list-reservation-type__content" },
                  [
                    _c(
                      "ul",
                      {
                        class: [
                          "nav nav-tabs",
                          "modal-list-reservation-type__tabs",
                          { "lazy-load": _vm.lazy_load }
                        ]
                      },
                      _vm._l(_vm.reservation_types, function(item, index) {
                        return _c(
                          "li",
                          { key: index, staticClass: "nav-item" },
                          [
                            _c(
                              "a",
                              {
                                class: [
                                  "nav-link",
                                  _vm.selectedTab === index ? "active" : ""
                                ],
                                on: {
                                  click: function($event) {
                                    _vm.selected_tab = index
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      item[0].reservation_type_name
                                        ? item[0].reservation_type_name
                                        : index
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "modal-list-reservation-type__tab-content"
                      },
                      [
                        _vm._l(_vm.reservation_types, function(items, index) {
                          return [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.selectedTab === index,
                                    expression: "selectedTab === index"
                                  }
                                ],
                                key: index,
                                class: [
                                  _vm.selectedTab === index ? "active" : ""
                                ]
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "modal-list-reservation-type__box-content"
                                  },
                                  [
                                    _vm._l(items, function(item) {
                                      return [
                                        item.duty_name
                                          ? _c(
                                              "div",
                                              {
                                                key: item.id,
                                                class: [
                                                  "modal-list-reservation-type__item",
                                                  _vm.activeType(item.id)
                                                ],
                                                on: {
                                                  click: function($event) {
                                                    return _vm.selectType(item)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      "modal-list-reservation-type__text",
                                                      _vm.activeType(item.id)
                                                    ]
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "modal-list-reservation-type__color",
                                                      style: [
                                                        item.duty
                                                          ? {
                                                              backgroundColor:
                                                                item.duty.color
                                                            }
                                                          : ""
                                                      ]
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "modal-list-reservation-type__name"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.duty_name)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "modal-list-reservation-type__value"
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src:
                                                          "/images/clock-gray.svg"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        class: [
                                                          "modal-list-reservation-type__time",
                                                          _vm.activeType(
                                                            item.id
                                                          )
                                                        ]
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item.take_time
                                                            ) +
                                                            "分\n                      "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ]
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "confirm_button_content",
            fn: function() {
              return [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("common.decision")) + "\n    "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }