<template>
  <div>
    <modal-action
      v-show="!show_modal_operation_reason"
      class="delete-allocation"
      :title="$t('objects.reservation.text.delete_allocation_title', {name: allocationDelete.column_name})"
      :is-show-close-button="false"
    >
      <template #footer>
        <button-common
          class="cancel-reservation__submit"
          @click="handleCancel"
        >
          <template #content>
            {{ $t('new_text.button.delete') }}
          </template>
        </button-common>
        <button-common
          class="cancel-reservation__close"
          @click="$emit('close')"
        >
          <template #content>
            {{ $t('new_text.button.no') }}
          </template>
        </button-common>
      </template>
    </modal-action>
    <modal-operation-allocation-reason
      v-if="show_modal_operation_reason"
      :is-update="false"
      :resources="operation_allocation_reason_resources"
      @back="show_modal_operation_reason = false; $emit('close')"
      @confirm="handleAfterInputOperationReason"
    />
  </div>
</template>

<script>
  import { get, isObject } from 'lodash'
  import ModalAction from '../common/ModalAction'
  import ButtonCommon from '../common/Button'
  import ModalOperationAllocationReason from './ModalOperationAllocationReason'
  import { COLUMN_TYPE, RESPONSE_ERRORS } from '../../utils/constants'
  import { showErrorModal } from '../../utils/helpers'
  import { resetSortOrder } from '../../mixins/reservation-allocation'

  export default {
    components: {
      ModalAction,
      ButtonCommon,
      ModalOperationAllocationReason
    },
    mixins: [resetSortOrder],
    props: {
      allocationDelete: {
        type: [Object],
        default: () => {}
      },
      allocations: {
        type: Array,
        default: () => []
      }
    },
    emits: ['close'],
    data () {
      return {
        show_modal_operation_reason: false,
        operation_allocation_reason_resources: {}
      }
    },
    computed: {
      allocationType () {
        return this.allocationDelete.column_type || COLUMN_TYPE.NOT_AUTO
      },
      isStoreOperationAllocationReason () {
        return this.$store.getters.isDetectAutoFrameOperation &&
          (this.allocationType === COLUMN_TYPE.AUTO || this.allocationType === COLUMN_TYPE.CSL)
      }
    },
    methods: {
      handleCancel () {
        if (!this.isStoreOperationAllocationReason) {
          this.deleteColumn()

          return
        }

        this.$store.commit('set_loading', true)
        this.$store.dispatch('checkExistReservation', this.allocationDelete.id).then((result) => {
          const reservationExist = get(result, 'data.results.reservation', '') || ''
          this.$store.commit('set_loading', false)
          if (reservationExist === RESPONSE_ERRORS.EXIST) {
            showErrorModal(this.$t('objects.reservation.messages.reservations_already_exist'))

            return
          }

          this.operation_allocation_reason_resources = {
            before_name: this.allocationDelete.column_name || '',
            after_name: this.allocationDelete.column_name || '',
            before_type: this.allocationType,
            after_type: this.allocationType,
            allocation_id: this.allocationDelete.id
          }
          this.show_modal_operation_reason = true
        }).catch(() => {
          this.$store.commit('set_loading', false)
          showErrorModal(this.$t('new_message.something_went_wrong'))
        })
      },
      deleteColumn: function () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('deleteAllocation', { id: this.allocationDelete.id }).then(() => {
          const indexResource = parseInt(this.allocationDelete.sortOrder) - 1
          this.removeColumnId(indexResource)
        }).catch((error) => {
          this.$store.commit('set_loading', false)
          const resErrors = error?.response?.data?.errors || []

          if (
            !Array.isArray(resErrors) &&
            isObject(resErrors) &&
            resErrors.reservation_allocation === RESPONSE_ERRORS.EXIST
          ) {
            showErrorModal(this.$t('objects.reservation.messages.reservations_already_exist'))

            return
          }

          if (Array.isArray(resErrors) && resErrors.indexOf(RESPONSE_ERRORS.NOT_FOUND) >= 0) {
            showErrorModal(this.$t('reservation.allocation.not_found'))

            return
          }

          showErrorModal(resErrors, this)
        })
      },
      removeColumnId: function (keyIndex) {
        const resourceEvent = this.allocations

        if (Object.prototype.hasOwnProperty.call(resourceEvent, keyIndex)) {
          resourceEvent.splice(keyIndex, 1)
          this.resetSortOrder(resourceEvent, 'deleted', this.isStoreOperationAllocationReason)
        }
      },
      handleAfterInputOperationReason (data) {
        this.operation_allocation_reason_data = data
        this.deleteColumn()
      }
    }
  }
</script>
