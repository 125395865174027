<template>
  <div class="modal-interview-sms-sending-v2">
    <modal-action
      :title="$t('objects.patient.text.send_sms_title')"
      @close="$emit('close')"
    >
      <template #body>
        <div class="modal-interview-sms-sending-v2__body">
          <div class="modal-interview-sms-sending-v2__form">
            <div
              v-if="firstReservation && firstReservation.status === reservation_status.RESERVED"
              class="modal-interview-sms-sending-v2__row"
            >
              <span class="modal-interview-sms-sending-v2__label">{{ $t('objects.patient.text.reservation_date') }}</span>
              <span class="modal-interview-sms-sending-v2__text">{{ reservationDate(firstReservation) }}</span>
            </div>
            <div class="modal-interview-sms-sending-v2__row">
              <div class="modal-interview-sms-sending-v2__col modal-interview-sms-sending-v2__col--left">
                <span class="modal-interview-sms-sending-v2__label">{{ $t('new_text.name') }}</span>
                <span class="modal-interview-sms-sending-v2__text">{{ patientInfo.name }}</span>
              </div>
              <div class="modal-interview-sms-sending-v2__col modal-interview-sms-sending-v2__col--right">
                <span class="modal-interview-sms-sending-v2__label">ID</span>
                <span class="modal-interview-sms-sending-v2__text">{{ patient.sf_user_id || patient.id }}</span>
              </div>
            </div>
            <div class="modal-interview-sms-sending-v2__row">
              <span class="modal-interview-sms-sending-v2__label">{{ $t('new_text.phone_number') }}</span>
              <span class="modal-interview-sms-sending-v2__text">{{ patientInfo.tel }}</span>
            </div>
            <div class="modal-interview-sms-sending-v2__row modal-interview-sms-sending-v2__row--block">
              <span class="modal-interview-sms-sending-v2__label">{{ $t('objects.patient.text.transmission_history') }}</span>
              <div class="modal-interview-sms-sending-v2__content">
                <div class="modal-interview-sms-sending-v2__content-inside">
                  <p
                    v-for="item in sms_histories"
                    :key="item.id"
                    class="modal-interview-sms-sending-v2__text"
                  >
                    {{ formatTime(item.updated_at) }}&nbsp;&nbsp;&nbsp;&nbsp;{{ $t(`text.sms_history_status.${item.status}`) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="modal-interview-sms-sending-v2__footer">
          <div class="modal-interview-sms-sending-v2__btn-close">
            <button-common @click="$emit('close')">
              <template #content>
                {{ $t('new_text.button.close') }}
              </template>
            </button-common>
          </div>
          <div
            :class="['modal-interview-sms-sending-v2__btn', isInterviewCompleted ?
              'modal-interview-sms-sending-v2__btn--margin-left' : '']"
          >
            <button-common
              v-if="!isInterviewCompleted"
              class="modal-interview-sms-sending-v2__btn-send-sms"
              @click="$emit('confirm-button-click')"
            >
              <template #content>
                {{ $t('objects.patient.text.send_sms') }}
              </template>
            </button-common>
            <button-common
              v-if="!isInterviewCompleted"
              class="modal-interview-sms-sending-v2__btn-enter-questionnaire"
              :disabled="!patientReservations.length || !getReservedReservations.length"
              @click="handleDetail"
            >
              <template #content>
                {{ $t('objects.patient.text.enter_questionnaire') }}
              </template>
            </button-common>
          </div>
        </div>
      </template>
    </modal-action>
  </div>
</template>

<script>

  import ModalAction from '../../../common/ModalAction'
  import ButtonCommon from '../../../common/Button'
  import { RESERVATION_STATUS } from '../../../../utils/constants'
  import { filter } from 'lodash'
  import * as moment from 'moment'
  import { showWarningModal } from '../../../../utils/helpers'

  export default {
    components: {
      ModalAction,
      ButtonCommon
    },
    emits: ['close', 'confirm-button-click', 'detail-button-click'],
    data () {
      return {
        sms_histories: [],
        reservation_status: RESERVATION_STATUS
      }
    },
    computed: {
      patientInfo () {
        return this.$store.getters.get_patientInformation
      },
      patientReservations: function () {
        return this.$store.getters.get_reservationList
      },
      patient () {
        return this.$store.getters.get_patient
      },
      reservation () {
        return this.$store.getters.get_reservation
      },
      isInterviewCompleted: function () {
        return this.$store.getters.get_patientInterviewCompleted
      },
      firstReservation () {
        return this.$store.getters.get_firstReservation
      },
      getReservedReservations () {
        return filter(this.patientReservations, { status: 'reserved' })
      },
      isCompletedInterview: function () {
        return this.$store.getters.get_patientInterviewCompleted
      }
    },
    created () {
      this.getInterviewSmsHistory()
      this.getPatientInterviewStatus()
    },
    methods: {
      getInterviewSmsHistory: function () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('getInterviewSmsHistory', this.patientInfo.patient_id)
          .then((result) => {
            this.$store.commit('set_loading', false)
            const { data: { results: { sms_histories: smsHistories } } } = result
            this.sms_histories = smsHistories
            if (this.sms_histories.length === 0 && this.isCompletedInterview) {
              showWarningModal(this.$t('objects.patient.message.no_history_of_sending_sms'))
              this.$emit('close')
            }
          })
          .catch(() => {
            this.$store.commit('set_loading', false)
          })
      },
      getPatientInterviewStatus: function () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('getPatientInterviewStatus', this.patientInfo.patient_id)
          .finally(() => {
            this.$store.commit('set_loading', false)
          })
      },
      formatTime: function (time) {
        return moment(time).format('YYYY/MM/DD HH:mm:ss')
      },
      reservationDate: function (reservation) {
        const date = reservation.started_to ? moment(reservation.started_to).format('YYYY年MM月DD日') : ''
        const startDate = reservation.started_to ? moment(reservation.started_to).format('HH:mm') : ''
        const endDate = reservation.ended_from ? moment(reservation.ended_from).format('HH:mm') : ''

        return `${date} ${startDate} ~ ${endDate}`
      },
      handleDetail: function () {
        if (!this.patientReservations.length || !this.getReservedReservations.length) {
          return
        }

        this.$emit('detail-button-click')
      }
    }
  }
</script>
