<template>
  <div class="event-card">
    <div
      v-if="event.id && event.extendedProps.blocked === 0"
      :class="['event-card__reservation', { 'event-card__reservation--grey-background': reservationOverTime}]"
    >
      <div
        :class="['event-card__duty__print', { 'event-card__duty__print--border': Object.keys(duty).length === 0, 'event-card__duty__print--min': period === 5 }]"
        :style="`background-color: ${duty.color || default_duty_color}`"
      />
      <div :class="['event-card__body__print', { 'event-card__body__print--min': period === 5 }]">
        <div>
          <p :class="['event-card__patient__name event-card__patient__name__print', { 'event-card__patient__name__print--big': allocations <= 6 && period > 10 }]">
            {{ interviewStatus }}{{ patientInfo.name || '' }}<span>{{ getAge }}</span>
          </p>
        </div>
        <div
          v-if="period > 10"
          :class="['event-card__duty-name', { 'event-card__duty-name--min': period < 25, 'event-card__duty-name--big': allocations <= 6 && period > 15 }]"
        >
          <span :class="['event-card__patient__id', { 'event-card__patient__id--big': allocations <= 6 && period > 15 }]">
            {{ patient.sf_user_id || patient.id }}{{ brandName }}&nbsp;
          </span>
          <span>{{ getDutiesName }}</span>
        </div>
        <div
          v-if="displayMemo"
          :class="['event-card__memo', { 'event-card__memo--big': allocations <= 6 && period > 15 }]"
        >
          <span :style="`-webkit-line-clamp: ${memoLine}`">{{ event.extendedProps.memo }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="event.id && event.extendedProps.blocked === 1"
      class="event-card__blocked"
    >
      <div class="event-card__name-block">
        {{ $t('common.block') }}
      </div>
      <div
        v-if="period > 10 && event.extendedProps.memo"
        class="event-card__memo-block"
      >
        <span :style="`-webkit-line-clamp: ${memoBlockLine}`">{{ event.extendedProps.memo }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import * as moment from 'moment-timezone'
  import 'moment/locale/ja'
  import helpers from '../../utils/helpers.js'
  import { sortBy, get, filter, head } from 'lodash'

  export default {
    props: {
      event: {
        type: Object,
        default: () => {}
      },
      isPrintMemo: {
        type: Number,
        default: 0
      },
      isInterview: {
        type: Number,
        default: 0
      },
      allocations: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        default_duty_color: 'transparent'
      }
    },
    computed: {
      period: function () {
        const startedTime = new Date(this.event.start)
        const endedTime = new Date(this.event.end)

        return parseInt((endedTime - startedTime) / (1000 * 60))
      },
      startTime: function () {
        return moment(this.event.start).format('HH:mm')
      },
      endTime: function () {
        return moment(this.event.end).format('HH:mm')
      },
      patient: function () {
        return this.event.extendedProps.patient || {}
      },
      patientInfo: function () {
        return this.event.extendedProps.patient_info || {}
      },
      getAge: function () {
        if (this.event.extendedProps.patient_info) {
          return (moment(this.event.extendedProps.patient_info.birth_of_date).isValid()) ? `[${helpers.calculateAge(this.event.extendedProps.patient_info.birth_of_date)}]` : ''
        }

        return ''
      },
      reservationOverTime: function () {
        return this.event.id && moment().diff(moment(this.event.extendedProps.ended_from)) > 0
      },
      duties: function () {
        return this.event.extendedProps.duties || []
      },
      duty: function () {
        return this.duties.length > 0 ? (this.duties[0].master.duty || {}) : {}
      },
      dutyMaster: function () {
        return this.duties.length > 0 ? (this.duties[0].master || {}) : {}
      },
      getDutiesName: function () {
        let dutiesName = ''
        for (let i = 0; i < this.duties.length; i++) {
          dutiesName += this.duties[i].master.duty_name + ', '
        }
        return dutiesName.substring(0, dutiesName.length - 2)
      },
      memoBlockLine: function () {
        if (this.period === 15) {
          return 1
        }

        if (this.period === 20) {
          return 2
        }

        const line = (this.period - 25) / 5
        return line + 2
      },
      interviewStatus: function () {
        const isFirstReservation = this.event.extendedProps.patient &&
          (Number(this.event.extendedProps.patient.first_reservation_id) === Number(this.event.id))

        if (
          isFirstReservation &&
          this.isInterview &&
          this.event.extendedProps.interview_status !== null
        ) {
          return this.event.extendedProps.interview_status === 0 ? '(問)' : '(済)'
        }

        return ''
      },
      displayMemo: function () {
        return (this.period >= 20 && this.event.extendedProps.memo && this.isPrintMemo) ||
          (this.period < 30 && !this.dutyMaster.duty_name && this.event.extendedProps.memo && this.isPrintMemo)
      },
      memoLine: function () {
        if (this.period === 15 || this.period === 20) {
          return 1
        }

        if (this.period === 30) {
          return this.dutyMaster.duty_name ? 1 : 2
        }

        const line = (this.period - 35) / 5
        return this.dutyMaster.duty_name ? line + 1 : line + 2
      },
      staffs: function () {
        if (this.event.extendedProps.staffs && this.event.extendedProps.staffs.length !== 0) {
          return sortBy(this.event.extendedProps.staffs, [function (o) {
            return o.staff_info.position.ordering
          }])
        }

        return []
      },
      brandName: function () {
        const brand = head(filter(
          this.$t('objects.patient.brand_master_filter'),
          origin => origin.type === this.event.extendedProps.csl_brand_type)
        )
        const brandName = get(brand, 'name', '')
        if (brandName) {
          return `:${brandName} ${this.$t('new_text.csl_character')}`
        }

        const name = get(this.patientInfo, 'brand_master.name')
        if (parseInt(this.event.id) === this.patient.first_reservation_id && name) {
          return `:${name}`
        }

        return ''
      }
    }
  }
</script>
