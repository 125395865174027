import { showErrorModal } from '../utils/helpers'
import { RESPONSE_TYPE } from '../utils/constants'

export const printTechnicalInfo = {
  data () {
    return {
      print_technical_info_channel_id: ''
    }
  },
  methods: {
    syncDataOrderFromSF (data, orderType, orderField) {
      const sfUserIdsCanPrint = data

      if (sfUserIdsCanPrint.length === 0) {
        return
      }

      this.$store.commit('set_loading', true)

      const patientInfos = this.patientNameToPrint(sfUserIdsCanPrint)
      const headerHeights = {}
      const clinicFormalName = this.$store.getters.currentClinic.formal_name
      sfUserIdsCanPrint.forEach(sfUserId => {
        headerHeights[sfUserId] = this.calculateHeaderHeightPage(patientInfos[parseInt(sfUserId)], clinicFormalName)
      })

      this.print_technical_info_channel_id = 'print_technical_info_'
      this.print_technical_info_channel_id += this.$store.state.auth.user.id + '_' + (new Date()).getTime()
      this.print_technical_info_channel_id += '_' + Math.random().toString(36).substring(7)
      this.connectChannelPrintTechnicalInfo()

      this.$store.dispatch(
        'syncOrderFromSf',
        {
          sf_user_ids: data,
          date: this.date,
          channel_id: this.print_technical_info_channel_id,
          header_heights: headerHeights,
          order_type: orderType,
          order_field: orderField
        }
      ).catch(() => {
        const channel = `${window.channelPrefix}${this.print_technical_info_channel_id}`
        Echo.leaveChannel(channel)
        this.print_technical_info_channel_id = ''
        showErrorModal(this.$t('new_message.something_went_wrong'))
        this.$store.commit('set_loading', false)
      })
    },
    connectChannelPrintTechnicalInfo () {
      const channel = `${window.channelPrefix}${this.print_technical_info_channel_id}`

      Echo.channel(channel)
        .listen('.print_technical_info.multiple', e => {
          const { status, path = '' } = e.payload

          if (status === RESPONSE_TYPE.NG) {
            showErrorModal(this.$t('new_message.something_went_wrong'))
          }

          if (path) {
            window.open(path, '_blank')
          }

          this.$store.commit('set_loading', false)
          Echo.leaveChannel(channel)
          this.print_technical_info_channel_id = ''
        }).error(e => {
          Echo.leaveChannel(channel)
          this.print_technical_info_channel_id = ''
          this.$store.commit('set_loading', false)
          showErrorModal(this.$t('new_message.something_went_wrong'))
        })
    },
    patientNameToPrint (sfUserIds) {
      const sfUserIdsCanPrint = sfUserIds

      if (sfUserIdsCanPrint.length === 0) {
        return []
      }

      const result = {}

      this.$store.getters.getReservations.forEach(item => {
        if (item.patient && sfUserIdsCanPrint.includes(parseInt(item.patient.sf_user_id))) {
          result[item.patient.sf_user_id] = item.patient_info
        }
      })

      return result
    }
  }
}
