import axios from 'axios'
import { state as authState } from './auth'
import { ORDER_FILE_THUMB_STATUS, ORDER_FILE_TYPE } from '../../utils/constants'
import { safeGet } from '../../utils/helpers'
import { forEach, map, findIndex } from 'lodash'

export const state = {
  orders: [],
  prev_page: 0,
  current_order: {
    order_files: []
  },
  patient_info: {},
  sf_user_id_create_order: 0,
  order_search: []
}

export const mutations = {
  setOrders (state, value) {
    let orders = []
    forEach(value, function (order) {
      const orderFiles = map(order.order_files, function (item) {
        return { ...item, has_order_file_thumb: ORDER_FILE_THUMB_STATUS.LOADING }
      })
      order = { ...order, order_files: [...orderFiles], has_instruction_thumb: ORDER_FILE_THUMB_STATUS.LOADING }
      orders = [...orders, order]
    })
    state.orders = [...orders]
  },
  setOrder (state, value) {
    const orderFiles = map(value.order_files, function (item) {
      return { ...item, has_order_file_thumb: ORDER_FILE_THUMB_STATUS.LOADING }
    })
    state.current_order = {
      ...value,
      order_files: [...orderFiles],
      has_instruction_thumb: ORDER_FILE_THUMB_STATUS.LOADING
    }
  },
  setInstructionThumbUrl (state, thumbUrl) {
    state.current_order = {
      ...state.current_order,
      instruction_thumb_url: thumbUrl,
      has_instruction_thumb: ORDER_FILE_THUMB_STATUS.LOADED
    }
  },
  setInstructionThumbStatus (state, status) {
    state.current_order = {
      ...state.current_order,
      has_instruction_thumb: status
    }
  },
  setOrderFileThumbUrl (state, { orderFileId, thumbUrl }) {
    const orderFiles = state.current_order.order_files
    const index = findIndex(orderFiles, { id: orderFileId })
    orderFiles[index] = {
      ...orderFiles[index],
      order_file_thumb_url: thumbUrl,
      has_order_file_thumb: ORDER_FILE_THUMB_STATUS.LOADED
    }
    state.current_order.order_files = [...orderFiles]
  },
  setOrderFileThumbStatus (state, { orderFileId, status }) {
    const orderFiles = state.current_order.order_files
    const index = findIndex(orderFiles, { id: orderFileId })
    orderFiles[index] = {
      ...orderFiles[index],
      has_order_file_thumb: status
    }
    state.current_order.order_files = [...orderFiles]
  },
  setInstructionThumbUrlOrderList (state, { orderId, thumbUrl }) {
    const orders = [...state.orders]
    const orderIndex = findIndex(orders, { id: orderId })
    orders[orderIndex] = {
      ...orders[orderIndex],
      has_instruction_thumb: ORDER_FILE_THUMB_STATUS.LOADED,
      instruction_thumb_url: thumbUrl
    }
    state.orders = [...orders]
  },
  setInstructionThumbStatusOrderList (state, { orderId, status }) {
    const orders = [...state.orders]
    const orderIndex = findIndex(orders, { id: orderId })
    orders[orderIndex] = {
      ...orders[orderIndex],
      has_instruction_thumb: status
    }
    state.orders = [...orders]
  },
  setOrderFileThumbUrlOrderList (state, { orderId, orderFileId, thumbUrl }) {
    const orders = [...state.orders]
    const orderIndex = findIndex(orders, { id: orderId })
    const orderFiles = [...orders[orderIndex].order_files]
    const orderFileIndex = findIndex(orderFiles, { id: orderFileId })
    orderFiles[orderFileIndex] = {
      ...orderFiles[orderFileIndex],
      order_file_thumb_url: thumbUrl,
      has_order_file_thumb: ORDER_FILE_THUMB_STATUS.LOADED
    }
    orders[orderIndex].order_files[orderFileIndex] = { ...orderFiles[orderFileIndex] }
    state.orders = [...orders]
  },
  setOrderFileThumbStatusOrderList (state, { orderId, orderFileId, status }) {
    const orders = [...state.orders]
    const orderIndex = findIndex(orders, { id: orderId })
    const orderFiles = [...orders[orderIndex].order_files]
    const orderFileIndex = findIndex(orderFiles, { id: orderFileId })
    orderFiles[orderFileIndex] = {
      ...orderFiles[orderFileIndex],
      has_order_file_thumb: status
    }
    orders[orderIndex].order_files[orderFileIndex] = { ...orderFiles[orderFileIndex] }
    state.orders = [...orders]
  },
  setPatientInfo (state, value) {
    state.patient_info = value
  },
  setPatientToCreate (state, value) {
    state.sf_user_id_create_order = value
  },
  setOrderSearch (state, value) {
    state.order_search = value
  },
  setPrevPage (state, value) {
    state.prev_page = value
  }
}

export const getters = {
  getOrderSearch: function (state) {
    return state.order_search
  },
  getPrevPage: function (state) {
    return state.prev_page
  }
}

export const actions = {
  getOrderList ({ dispatch, commit }, queryData) {
    return axios.get('/api/order/', {
      params: queryData,
      headers: { Authorization: 'Bearer ' + authState.token }
    })
  },
  instructionAdminGetOrderList ({ dispatch, commit }, queryData) {
    return axios.get('/api/order/instruction-admin', {
      params: queryData,
      headers: { Authorization: 'Bearer ' + authState.token }
    })
  },
  labDesignerGetOrderList ({ dispatch, commit }, queryData) {
    return axios.get('/api/order/lab-designer', {
      params: queryData,
      headers: { Authorization: 'Bearer ' + authState.token }
    })
  },
  updateOrderStatus ({ dispatch, commit }, data) {
    return axios.put('/api/order/status', data, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
  },
  printInstructionsFile ({ dispatch, commit }, listId) {
    return axios.put('/api/order/instructions/', { ids: listId }, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
  },
  printInstructionFile ({ dispatch, commit }, orderId) {
    return axios.get(`/api/order/${orderId}/instruction`, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
  },
  saveOrder ({ commit }, formData) {
    return axios.post(
      '/api/order',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + authState.token
        }
      }
    )
  },
  getOrderDetail ({ commit }, orderId) {
    return axios.get(`/api/order/${orderId}`, {
      headers: { Authorization: 'Bearer ' + authState.token }
    }).then(res => {
      commit('setOrder', res?.data?.results ?? {})
      return res
    }).catch(err => {
      throw err
    })
  },
  countByStatus ({ commit }) {
    return axios.get('/api/order/count', { headers: { Authorization: 'Bearer ' + authState.token } })
  },
  getListOrderByPatient ({ commit }, patientId) {
    return axios.get(
      `/api/order/list-by-patient/${patientId}`,
      { headers: { Authorization: 'Bearer ' + authState.token } }
    ).then(res => {
      commit('setOrders', res?.data?.results?.orders || [])
      commit('setPatientInfo', res?.data?.results?.patient || {})

      return res
    })
  },
  countByStatusByInstructionAdmin ({ commit }) {
    return axios.get('/api/instruction-admin/count', { headers: { Authorization: 'Bearer ' + authState.token } })
  },
  getOrderFileThumbnail ({ commit }, { type, orderId, orderFileId, isOrderList }) {
    let url = `/api/order/thumb/${type}/${orderId}`
    if (type === ORDER_FILE_TYPE.ORDER_FILE) {
      url += `/${orderFileId}`
    }
    return axios.get(
      url,
      { headers: { Authorization: 'Bearer ' + authState.token } }
    ).then(res => {
      const thumbUrl = safeGet(res, 'data.results.thumb_url')
      if (isOrderList) {
        if (type === ORDER_FILE_TYPE.INSTRUCTION) {
          commit('setInstructionThumbUrlOrderList', { orderId, thumbUrl })
        } else {
          commit('setOrderFileThumbUrlOrderList', { orderId, orderFileId, thumbUrl })
        }
      } else {
        if (type === ORDER_FILE_TYPE.INSTRUCTION) {
          commit('setInstructionThumbUrl', thumbUrl)
        } else {
          commit('setOrderFileThumbUrl', { orderFileId, thumbUrl })
        }
      }
    }).catch(() => {
      if (isOrderList) {
        if (type === ORDER_FILE_TYPE.INSTRUCTION) {
          commit('setInstructionThumbStatusOrderList', { orderId, status: ORDER_FILE_THUMB_STATUS.FAILED })
        } else {
          commit('setOrderFileThumbStatusOrderList', { orderId, orderFileId, status: ORDER_FILE_THUMB_STATUS.FAILED })
        }
      } else {
        if (type === ORDER_FILE_TYPE.INSTRUCTION) {
          commit('setInstructionThumbStatus', ORDER_FILE_THUMB_STATUS.FAILED)
        } else {
          commit('setOrderFileThumbStatus', { orderFileId, status: ORDER_FILE_THUMB_STATUS.FAILED })
        }
      }
    })
  },
  exportOrders ({ dispatch }, queryData) {
    return axios.get('/api/order/export', {
      params: queryData,
      headers: { Authorization: 'Bearer ' + authState.token }
    })
  },
  instructionAdminExportOrders ({ dispatch }, queryData) {
    return axios.get('/api/order/instruction-admin/export', {
      params: queryData,
      headers: { Authorization: 'Bearer ' + authState.token }
    })
  },
  downloadCsvFile ({ dispatch }, path) {
    return axios.get('/api/order/export/download', {
      params: { path },
      headers: { Authorization: 'Bearer ' + authState.token, responseType: 'blob' }
    })
  },
  syncOrderFromSf ({ commit }, params) {
    return axios.post(
      '/api/print-technical-info/sync-order-from-sf',
      params,
      { headers: { Authorization: 'Bearer ' + authState.token } }
    )
  },
  printIndividualTecnicalInfo ({ dispatch, commit }, { orderId, data }) {
    return axios.get(`/api/order/${orderId}/print-technical-info`, {
      params: data,
      headers: { Authorization: 'Bearer ' + authState.token }
    })
  },
  printMultipleTechnicalInfo ({ commit }, params) {
    return axios.post(
      '/api/order/multiple-print-technical-info',
      params,
      { headers: { Authorization: 'Bearer ' + authState.token } }
    )
  },
  exportExamination ({ dispatch, commit }, { patientId, type }) {
    return axios.get(`/api/order/export-examination/${patientId}`, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
  },
  downloadDataSetup ({ dispatch, commit }, orderId) {
    return axios.get(`/api/order/download-data-setup/${orderId}`, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
  },
  uploadFiles ({ commit }, { orderId, formData }) {
    return axios.post(`/api/order/${orderId}/upload-files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + authState.token
      }
    })
  }
}
