<template>
  <div class="questionnaire-dashboard">
    <div class="questionnaire-dashboard__form">
      <div class="questionnaire-dashboard__form__input">
        <label>{{ $t('objects.questionnaire.date_of_visit') }}</label>
        <select-date
          id="arrived_time_from"
          :is-read-only="false"
          :default-date="data_search.arrived_time_from"
          @change="value => data_search.arrived_time_from = value"
        />
        <span class="questionnaire-dashboard__form__divide">〜</span>
        <select-date
          id="arrived_time_to"
          :is-read-only="false"
          :default-date="data_search.arrived_time_to"
          @change="value => data_search.arrived_time_to = value"
        />
      </div>
      <div class="questionnaire-dashboard__form__button">
        <common-button
          class="button-v2__search"
          @click="requestQuestionnaireDataAnalysis"
        >
          <template #content>
            {{ $t('common.search') }}
          </template>
        </common-button>
        <common-button
          :class="['button-v2__reset', { 'button-v2__reset--active': enableResetSearch }]"
          :disabled="!enableResetSearch"
          @click="resetSearchResult"
        >
          <template #content>
            {{ $t('new_text.button.reset') }}
          </template>
        </common-button>
      </div>
    </div>
    <div class="questionnaire-dashboard__content">
      <div class="questionnaire-dashboard__note">
        <label>{{ $t('objects.questionnaire.text.number_of_answers') }}</label>
        <strong class="number">{{ total }}</strong>
        ({{ $t('objects.questionnaire.type_first') }}
        <span class="number">{{ first }}</span>
        / {{ $t('objects.questionnaire.type_revisit') }}
        <span class="number">{{ re_visit }}</span>)
      </div>
      <div class="questionnaire-dashboard__chart">
        <div class="questionnaire-dashboard__chart__item">
          <div class="questionnaire-dashboard__chart__number">
            <span>NPS</span>
            <span class="number">{{ nps }}<small>％</small></span>
          </div>
          <div class="questionnaire-dashboard__chart__content">
            <label class="questionnaire-dashboard__chart__content-label">{{ $t('objects.questionnaire.text.nps_text') }}</label>
            <div class="questionnaire-dashboard__chart__content-chart">
              <common-chart :chart-data="chart_data.nps" />
            </div>
          </div>
        </div>
        <div class="questionnaire-dashboard__chart__item">
          <div class="questionnaire-dashboard__chart__number">
            <span>{{ $t('objects.questionnaire.text.first_visit_evaluation') }}</span>
            <span class="number">{{ first_visit_evaluation }}</span>
          </div>
          <div class="questionnaire-dashboard__chart__content">
            <label class="questionnaire-dashboard__chart__content-label">{{ $t('objects.questionnaire.text.evaluation_breakdown') }}</label>
            <div class="questionnaire-dashboard__chart__content-chart">
              <common-chart :chart-data="chart_data.v1q2" />
            </div>
          </div>
        </div>
        <div class="questionnaire-dashboard__chart__item">
          <div class="questionnaire-dashboard__chart__number">
            <span>{{ $t('objects.questionnaire.text.re_visit_evaluation') }}</span>
            <span class="number">{{ re_visit_evaluation }}</span>
          </div>
          <div class="questionnaire-dashboard__chart__content">
            <label class="questionnaire-dashboard__chart__content-label">{{ $t('objects.questionnaire.text.evaluation_breakdown') }}</label>
            <div class="questionnaire-dashboard__chart__content-chart">
              <common-chart :chart-data="chart_data.v2q2" />
            </div>
          </div>
        </div>
        <div class="questionnaire-dashboard__chart__item">
          <div class="questionnaire-dashboard__chart__number">
            <span>{{ $t('objects.questionnaire.text.re_visit_treatment_evaluation') }}</span>
            <span class="number">{{ re_visit_treatment_evaluation }}</span>
          </div>
          <div class="questionnaire-dashboard__chart__content">
            <label class="questionnaire-dashboard__chart__content-label">{{ $t('objects.questionnaire.text.evaluation_breakdown') }}</label>
            <div class="questionnaire-dashboard__chart__content-chart">
              <common-chart :chart-data="v2q1_chart_data" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SelectDate from '../../components/common/SelectDate'
  import Button from '../../components/common/Button'
  import Chart from '../../components/common/Chart'
  import { showErrorModal } from '../../utils/helpers'
  import * as moment from 'moment-timezone'

  export default {
    components: {
      SelectDate,
      CommonButton: Button,
      CommonChart: Chart
    },
    data () {
      return {
        data_search: {
          arrived_time_from: '',
          arrived_time_to: ''
        },
        chart_data: {
          nps: [
            {
              name: '批判者',
              value: 0,
              background: '#f66'
            },
            {
              name: '中立者',
              value: 0,
              background: '#fa5'
            },
            {
              name: '推薦者',
              value: 0,
              background: '#37f'
            }
          ],
          v1q2: [
            {
              name: '十分丁寧',
              value: 0
            },
            {
              name: '十分',
              value: 0
            },
            {
              name: '不十分',
              value: 0
            },
            {
              name: '評価なし',
              value: 0
            }
          ],
          v2q2: [
            {
              name: '十分丁寧',
              value: 0
            },
            {
              name: '十分',
              value: 0
            },
            {
              name: '不十分',
              value: 0
            },
            {
              name: '評価なし',
              value: 0
            }
          ],
          v2q1: [
            {
              name: '満足',
              value: 0
            },
            {
              name: 'やや満足',
              value: 0
            },
            {
              name: '普通',
              value: 0
            },
            {
              name: 'やや不満',
              value: 0
            },
            {
              name: '不満',
              value: 0
            }
          ]
        },
        v2q1_chart_data: [],
        total: 0,
        first: 0,
        re_visit: 0,
        nps: 0,
        first_visit_evaluation: 0,
        re_visit_evaluation: 0,
        re_visit_treatment_evaluation: 0
      }
    },
    computed: {
      enableResetSearch () {
        return this.data_search.arrived_time_from !== '' || this.data_search.arrived_time_to !== ''
      },
      isHiddenV2q1OptionThree () {
        const arrivedTimeFrom = this.data_search.arrived_time_from || false
        const timeHiddenV2q1OptionThree = moment(this.$configs.TIME_HIDDEN_V2Q1_OPTION_THREE)

        return arrivedTimeFrom && timeHiddenV2q1OptionThree.isSameOrBefore(arrivedTimeFrom)
      }
    },
    created () {
      this.requestQuestionnaireDataAnalysis()
    },
    methods: {
      resetSearchResult () {
        if (!this.enableResetSearch) {
          return
        }

        this.emptyDataSearch()
        this.requestQuestionnaireDataAnalysis()
      },
      emptyDataSearch () {
        this.data_search = {
          arrived_time_from: '',
          arrived_time_to: ''
        }
      },
      requestQuestionnaireDataAnalysis () {
        if (moment(this.data_search.arrived_time_from) > moment(this.data_search.arrived_time_to)) {
          showErrorModal(this.$t('new_validation.common.invalid_date'))

          return
        }

        this.resetData()
        this.$store.commit('set_loading', true)
        this.$store.dispatch('getQuestionnaireAnalysis', this.data_search).then((result) => {
          const { data: { results } } = result
          this.total = parseInt(results.total || 0)
          this.first = parseInt(results.first || 0)
          this.re_visit = parseInt(results.re_visit || 0)

          this.chart_data.nps[0].value = parseInt(results.q5_1 || 0)
          this.chart_data.nps[1].value = parseInt(results.q5_2 || 0)
          this.chart_data.nps[2].value = parseInt(results.q5_3 || 0)

          this.chart_data.v1q2[0].value = parseInt(results.v1q2_1 || 0)
          this.chart_data.v1q2[1].value = parseInt(results.v1q2_2 || 0)
          this.chart_data.v1q2[2].value = parseInt(results.v1q2_3 || 0)
          this.chart_data.v1q2[3].value = parseInt(results.v1q2_4 || 0)

          this.chart_data.v2q2[0].value = parseInt(results.v2q2_1 || 0)
          this.chart_data.v2q2[1].value = parseInt(results.v2q2_2 || 0)
          this.chart_data.v2q2[2].value = parseInt(results.v2q2_3 || 0)
          this.chart_data.v2q2[3].value = parseInt(results.v2q2_4 || 0)

          this.chart_data.v2q1[0].value = parseInt(results.v2q1_1 || 0)
          this.chart_data.v2q1[1].value = parseInt(results.v2q1_2 || 0)
          this.chart_data.v2q1[2].value = parseInt(results.v2q1_3 || 0)
          this.chart_data.v2q1[3].value = parseInt(results.v2q1_4 || 0)
          this.chart_data.v2q1[4].value = parseInt(results.v2q1_5 || 0)

          this.getDataChartV2q1()

          if (this.total !== 0) {
            const percentOfPromoters = parseInt(results.q5_3 || 0) * 100 / this.total
            const percentOfDetractors = parseInt(results.q5_1 || 0) * 100 / this.total

            this.nps = (percentOfPromoters - percentOfDetractors).toFixed(2)
          }

          const MAX_VALUE_Q2 = 4
          const MAX_VALUE_V2Q1 = 5

          if (this.first !== 0) {
            this.first_visit_evaluation = (MAX_VALUE_Q2 + 1) - parseInt(results.v1q2_total || 0) / this.first
            this.first_visit_evaluation = Math.round(this.first_visit_evaluation * 100) / 100
          }

          if (this.re_visit !== 0) {
            this.re_visit_evaluation = (MAX_VALUE_Q2 + 1) - parseInt(results.v2q2_total || 0) / this.re_visit
            this.re_visit_evaluation = Math.round(this.re_visit_evaluation * 100) / 100
            this.re_visit_treatment_evaluation = (MAX_VALUE_V2Q1 + 1) - parseInt(results.v2q1_total || 0) / this.re_visit
            this.re_visit_treatment_evaluation = Math.round(this.re_visit_treatment_evaluation * 100) / 100
          }

          this.$store.commit('set_loading', false)
        }).catch(() => {
          showErrorModal(this.$t('sweet_alert.something_went_wrong'))
          this.$store.commit('set_loading', false)
        })
      },
      resetData () {
        this.nps = 0
        this.first_visit_evaluation = 0
        this.re_visit_evaluation = 0
        this.re_visit_treatment_evaluation = 0
      },
      getDataChartV2q1 () {
        this.v2q1_chart_data = [...this.chart_data.v2q1]

        if (this.isHiddenV2q1OptionThree) {
          this.v2q1_chart_data.splice(2, 1)
        }
      }
    }
  }
</script>
