import axios from 'axios'
import { state as authState } from './auth'

export const actions = {
  getDesignerList ({ dispatch, commit }) {
    return axios.get('/api/designer/', {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
  }
}
