var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "instruction-print" }, [
    _c("iframe", {
      staticStyle: { width: "100% !important", height: "100% !important" },
      attrs: { src: _vm.instruction_file_url }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }