<template>
  <div :class="{ 'lazy-load lazy-load--big-space': is_loading_data }">
    <router-view />
  </div>
</template>

<script>
  import { showErrorModal } from '../../../../utils/helpers'
  import { FORBIDDEN } from '../../../../utils/http-code'

  export default {
    data () {
      return {
        is_loading_data: false
      }
    },
    created () {
      document.title = this.$t('objects.page_title.order').toString()
      this.getListOrder()
    },
    methods: {
      getListOrder () {
        this.is_loading_data = true
        this.$store.dispatch('getListOrderByPatient', this.$route.params.id).then(() => {
          this.is_loading_data = false
        }).catch((err) => {
          this.is_loading_data = false
          if (err.response.status === FORBIDDEN) {
            showErrorModal(this.$root.$i18n.t('new_message.forbidden'))
          } else {
            showErrorModal(this.$root.$i18n.t('new_message.something_went_wrong'))
          }
        })
      }
    }
  }
</script>
