<template>
  <ModalConfirmation
    custom_class="mc-modal--search-detail mc-modal--search-reservation"
    :is_show_cancellation_button="false"
    @close="closeModal"
    @confirmButtonClick="closeModal"
  >
    <template #header>
      <h3 class="mc-modal--search-detail__title">
        {{ $t('reservation.search_title') }}
      </h3>
    </template>
    <template #body>
      <div class="search-box">
        <form @submit.prevent="searchReservation">
          <div class="menu-search__search">
            <div class="input-group row-reverse">
              <input
                v-model="keyword"
                type="text"
                maxlength="50"
                class="form-control"
                :placeholder="$t('placeholder.patient_search')"
              >
              <div class="input-group-prepend btn btn-outline-secondary">
                <img src="/images/icon-search-gray.svg">
              </div>
            </div>
            <button
              type="submit"
              class="menu-search__button btn-v2"
            >
              {{ $t('common.search') }}
            </button>
          </div>
        </form>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t('common.name') }}</th>
            <th>{{ $t('reservation.time_start_to') }}</th>
            <th>{{ $t('accounting_list_page.reservations_frame') }}</th>
          </tr>
        </thead>
        <tbody :class="{'lazy-load lazy-load--big-space': lazy_load, 'overflow': renderClassOverflow}">
          <tr
            v-for="(reservation, index) in reservations"
            :key="index"
            @click="gotoReservationCalendar(reservation.started_to, reservation.id)"
          >
            <td>{{ reservation.p_sf_user_id || reservation.patient_id }}</td>
            <td>{{ `${reservation.name || ''} (${reservation.kana_last_name || ''} ${reservation.kana_first_name || ''})` }}</td>
            <td>{{ formatDate(reservation.started_to) }}</td>
            <td>{{ reservation.allocation ? (reservation.allocation.column_name || '') : '' }}</td>
          </tr>
          <span
            v-if="errors.no_result"
            class="error"
          >{{ errors.no_result }}</span>
          <infinite-loading
            v-if="reservations.length > 5"
            @infinite="infiniteScrollHandler"
          >
            <template #no-more>
              <span class="d-none" />
            </template>
            <template #no-results>
              <span class="d-none" />
            </template>
          </infinite-loading>
        </tbody>
      </table>
    </template>
    <template #confirm_button_content>
      {{ $t('common.close') }}
    </template>
  </ModalConfirmation>
</template>

<script>
  import ModalConfirmation from '../ModalConfirmation'
  import InfiniteLoading from 'vue-infinite-loading'
  import helpers from '../../utils/helpers'
  import * as moment from 'moment'
  import { RESPONSE_STATUS } from '../../utils/constants'
  import { safeGet } from '../../admin/utils/helpers'

  export default {
    components: {
      ModalConfirmation,
      InfiniteLoading
    },
    props: {
      date: {
        type: String,
        default: moment().format('YYYY-MM-DD')
      },
      searchKeyword: {
        type: String,
        default: ''
      }
    },
    emits: ['close', 'reload-reservation-data'],
    data () {
      return {
        page: 1,
        reservations: [],
        reservation_total: 0,
        count_loaded: 0,
        keyword: '',
        lazy_load: false,
        errors: {
          no_result: ''
        }
      }
    },
    computed: {
      renderClassOverflow () {
        return this.reservations.length > 5
      }
    },
    created () {
      this.keyword = this.searchKeyword
      this.searchReservation()
    },
    methods: {
      emptyErrors () {
        this.errors = {
          no_result: ''
        }
      },
      searchReservation () {
        this.keyword = helpers.mbtrim(this.keyword)

        if (this.keyword.length === 0) {
          this.errors.no_result = this.$t('validation.required', { attribute: this.$t('validation.attributes.keyword_searching') })

          return
        }
        this.emptyErrors()
        this.emptyData()
        this.lazy_load = true
        this.$store.dispatch('getByPatientData', { keyword: this.keyword, from_date: this.date, page: this.page })
          .then((result) => {
            const { data: { status, results } } = result
            this.lazy_load = false

            if (status === RESPONSE_STATUS.NG) {
              helpers.showErrorModal(this.$t('message.something_went_wrong'))

              return
            }
            this.reservations = results?.data || []

            if (this.reservations.length === 0) {
              this.errors.no_result = this.$t('reservation.no_search_result')

              return
            }

            this.page++
          })
          .catch((error) => {
            this.lazy_load = false
            const {
              response: {
                data: { errors }
              }
            } = error

            if (errors.keyword === 'REQUIRED') {
              this.errors.no_result = this.$t('validation.required', { attribute: this.$t('validation.attributes.keyword_searching') })

              return
            }

            helpers.showErrorModal(this.$t('message.something_went_wrong'))
          })
      },
      infiniteScrollHandler ($state) {
        setTimeout(() => {
          this.$store.dispatch('getByPatientData', { keyword: this.keyword, from_date: this.date, page: this.page }).then((result) => {
            const { data: { status, results } } = result

            if (status === RESPONSE_STATUS.NG) {
              helpers.showErrorModal(this.$t('message.something_went_wrong'))

              return
            }

            const reservations = results?.data || []
            this.reservation_total = results?.total || 0

            if (reservations.length) {
              this.reservations.push(...reservations)
              this.page++
              this.count_loaded += reservations.length

              if (this.count_loaded < this.reservation_total) {
                $state.loaded()
              } else {
                $state.complete()
              }
            } else {
              $state.complete()
            }
          }).catch(() => {
            $state.complete()
            helpers.showErrorModal(this.$t('message.something_went_wrong'))
          })
        }, 500)
      },
      closeModal () {
        this.emptyErrors()
        this.emptyData()
        this.keyword = ''
        this.$emit('close')
        this.$emit('reload-reservation-data')
      },
      emptyData () {
        this.page = 1
        this.reservations = []
        this.reservation_total = 0
        this.count_loaded = 0
      },
      formatDate (date) {
        return moment(date).format('YYYY-MM-DD HH:mm')
      },
      gotoReservationCalendar (date, reservationId) {
        if (this.date === moment(date).format('YYYY-MM-DD')) {
          this.$router.push({
            name: 'reservation.list',
            query: {
              search_date: moment(date).format('YYYY-MM-DD'),
              reservation_id: reservationId
            }
          })

          location.reload()
          return
        }

        const router = this.$router.resolve({
          name: 'reservation.list',
          query: {
            search_date: moment(date).format('YYYY-MM-DD'),
            reservation_id: reservationId
          }
        })

        window.open(router.href, '_blank')
      }
    }
  }
</script>
