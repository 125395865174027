var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "setting-v2 setting-v2--background" }, [
    _c("div", { staticClass: "setting-v2__content" }, [
      _c("div", { staticClass: "setting-v2__header" }, [
        _c("div", { staticClass: "setting-v2__header__box" }, [
          _c("div", { staticClass: "setting-v2__header__box__text" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("new_text.basic_treatment_policy")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "setting-v2__header__box__button" }, [
            _c("button", { on: { click: _vm.actionUpdate } }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("new_text.button.save")) +
                  "\n          "
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "setting-v2__main" }, [
        _c("div", { staticClass: "setting-basic-treatment-policy" }, [
          _c("div", { staticClass: "setting-basic-treatment-policy__note" }, [
            _vm._v(
              _vm._s(_vm.$t("new_text.basic_treatment_policy_description"))
            )
          ]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.basic_treatment_policy,
                expression: "basic_treatment_policy"
              }
            ],
            staticClass:
              "textarea-default setting-basic-treatment-policy__input",
            attrs: {
              placeholder: _vm.$t("new_text.placeholder.please_enter"),
              maxlength: "255"
            },
            domProps: { value: _vm.basic_treatment_policy },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.basic_treatment_policy = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", [
            _vm._v(_vm._s(_vm.$t("new_text.basic_treatment_policy_note")))
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }