var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _vm.isShow
      ? _c("div", { staticClass: "modal-duty" }, [
          _c(
            "div",
            { staticClass: "modal-duty__wrap" },
            _vm._l(_vm.defineColor, function(color, key) {
              return _c("div", {
                key: key,
                staticClass: "modal-duty__color",
                style: [
                  { backgroundColor: color.color },
                  _vm.dataDutyColor === color.color && {
                    boxShadow: "0 0 0 7px rgba(" + color.rgb + ", .5)"
                  }
                ],
                on: {
                  click: function($event) {
                    return _vm.handleChooseColor(color)
                  }
                }
              })
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }