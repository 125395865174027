export default {
  brand_master_filter: [
    {
      type: 0,
      name: 'Kireilign'
    },
    {
      type: 1,
      name: 'KIDS'
    },
    {
      type: 2,
      name: 'Invisalign'
    },
    {
      type: 3,
      name: 'Ceramic'
    },
    {
      type: 4,
      name: 'Kiyoclear'
    },
    {
      type: 5,
      name: 'Wire'
    },
    {
      type: 6,
      name: 'Kyosei Guide'
    },
    {
      type: 7,
      name: 'Brava'
    }
  ],
  button: {
    create_technician_instructions: 'Place a technician order',
    history: 'History',
    patient_registration: 'Patient registration',
    print_examination: 'Print technician instruction',
    template_first_visit: '{patient_id} template for first visit.pdf',
    template_re_visit: '{patient_id} template for re-visit.pdf'
  },
  gender_filter: [
    {
      id: 0,
      name: 'No selection'
    },
    {
      id: 1,
      name: 'Female'
    },
    {
      id: 2,
      name: 'Male'
    }
  ],
  message: {
    complaint_comment: 'Comments that are the main complaints of patients regarding areas and symptoms of concern',
    has_error_input: 'There is an error in the input',
    no_history_of_sending_sms: 'There is no history of sending SMS',
    phone_number_empty: 'The phone number is not registered.\nThe questionnaire will be handwritten by the patient.',
    phone_incorrect: 'The phone number format is incorrect.\nPlease register the correct phone number before sending the SMS.',
    phone_number_not_available: 'A phone number that cannot send SMS has been registered.\nThe questionnaire will be handwritten by the patient.',
    pregnancy_warning: 'If you are pregnant or may become pregnant, take an X-ray<br />You cannot receive treatment because you cannot do it.',
    save_draft_message: 'When saving a draft, the questionnaire will not be "completed".\n If you want to make the questionnaire "completed", please "save" it.',
    sms_can_be_sent_if_reservation_exist: 'SMS can be sent after registering the reservation information.',
    sms_sending_confirmation: 'To :tel\nAre you sure you want to send an SMS?',
    sms_sent: 'SMS has been sent',
    symptoms_type: {
      mouth: '※If the symptoms are strong, it may not be indicated.',
      gummy: '※Ideally, the gums of the upper jaw should not be visible when smiling.',
      logo: '※In the field of orthodontics, it is often said that the mouth is protruding, such as maxillary prognathism, maxillary prognathism, and mandibular retraction.<br/>' +
        '※There is no such expression in orthodontics.<br/>' +
        '※If the symptoms are strong, it may not be indicated.'
    }
  },
  patient_condition: [
    {
      id: 'none',
      name: 'None'
    },
    {
      id: 'first_visit',
      name: 'First visit'
    },
    {
      id: 're_examination',
      name: 'Revisit'
    }
  ],
  placeholder: {
    consultation_symptom: 'Example: I\'m worried that there is a gap in the upper front teeth. If the gap is filled, some rattling may remain, so I want to make it cheaper. \n \n It\'s rattling as a whole, so it doesn\'t matter if it takes money and time to arrange it neatly, so I want to do it until I\'m satisfied.',
    other_reason_placeholder: 'Please input reason if select other',
    post_comment: 'Post a comment'
  },
  sf_pre_contract_simulation: {
    0: '- Not implemented',
    1: '◯ Implemented'
  },
  sf_status_c: {
    0: 'Cancelled',
    1: 'Waiting for customer',
    2: 'New customer with no reservation',
    3: 'Reservation date adjusting',
    4: 'Already arrived',
    5: 'Existing patients at the clinic',
    6: 'Switching to other corrections',
    9: 'Deleted'
  },
  sf_status_c_new: [
    {
      id: 0,
      name: 'Cancelled'
    },
    {
      id: 1,
      name: 'Waiting for customer'
    },
    {
      id: 2,
      name: 'New customer with no reservation'
    },
    {
      id: 4,
      name: 'Already arrived'
    },
    {
      id: 5,
      name: 'Existing patients at the clinic'
    },
    {
      id: 9,
      name: 'Deleted'
    }
  ],
  sf_treatmentend_status_c: {
    1: 'Cancellation (refund) on the way',
    2: 'Cancellation on the way (continue with other treatment)',
    3: 'Cancellation on the way (patient satisfaction)',
    4: 'End of treatment',
    5: 'End of treatment (continue with other treatment)'
  },
  text: {
    accounting_creation: 'Accounting creation',
    activity_log: 'Activity log',
    add_charge: 'Add charge',
    address_contact: 'Address/Contact',
    aligner: 'Aligner',
    areas_concern: 'Areas of concern / symptoms',
    assignee_info: 'Person in charge information',
    basic_info: 'Basic information',
    basic_information: 'Basic information',
    centered_off: 'Centered off (midline mismatch)',
    cleaning: 'Cleaning',
    clear_teeth: 'Clear teeth (void dentition)',
    clinic_name: 'Clinic name',
    comment: 'Comment',
    consultation_content: 'Consultation content',
    consultation_symptom: 'Main symptom (where do you notice the pain)',
    contact_info: 'Contact / address information',
    content_of_consultation: 'Content of consultation',
    csl_reservation_information: 'Counseling reservation information',
    deep_occlusion: 'Deep occlusion (overcapsular occlusion)',
    delivery_setting: 'Delivery settings',
    dental_hygienist: 'Dental hygienist',
    dental_teacher: 'Dentist teacher',
    dentist: 'Dentist',
    dentition_number: 'Dentition number',
    description_delete_modal: 'All the reserved records so far are deleted and cannot be restored.\nIf you want to delete it, please complete the operation before deleting the patient information.',
    edit_date: 'Edit date and time',
    edit_history: 'Edit history',
    editor: 'Editor',
    enter_questionnaire: 'Enter the Questionnaire',
    expanded_floor: 'Expanded floor',
    front_teeth: 'Front teeth do not close (open bite)',
    gummy_smile: 'The gums of the upper jaw are exposed when smiling (Gummy Smile)',
    ip_address_of_first_appointment: 'IP address at the time of first appointment',
    left_side: 'Left',
    logobo: 'Mouth gobo',
    lower_jaw: 'Lower',
    mail_delivery_setting: 'Questionnaire mail delivery settings',
    main_symptom: 'Main symptom',
    medical_record_creation: 'Medical record creation',
    modal_confirm_save: {
      title: 'Save the questionnaire',
      text: 'If you save it, you will not be able to edit it again.\nIs it OK.'
    },
    modal_reason_for_non_contract_title: 'Please select the reason why a contract was not reached',
    move_destination: 'Move destination',
    new_reservation: 'New reservation',
    none: 'None',
    not_obtained: 'Not obtained',
    note_delete_modal: 'Please enter your login password to perform the deletion',
    number_services: 'Number of remaining services',
    orthodontic: 'Orthodontic appliance',
    orthodontic_treatment: 'Orthodontic treatment',
    other_address: 'Address * Enter if you live in a different place',
    other_notes: 'Other Remarks',
    other_question: {
      1: '1. Do you have any plans to move?',
      2: '2. If you have any other requests for medical examination, please write them down.'
    },
    overall: 'Overall',
    overall_service: 'Overall service',
    parent_info: 'Parent information',
    parent_information: 'Parent information (minors)',
    parent_name: 'Parent name',
    part: 'Part',
    patient_id: 'Patient ID',
    patient_information: 'Patient Information',
    patient_status: 'Patient status',
    patient_status_0: 'New reservation',
    patient_status_1: 'Before reception',
    patient_status_2: 'Checked In',
    patient_status_3: 'Before Actuarial',
    patient_status_4: 'Paid',
    patient_technician_order: 'Patient technician order',
    period_end_date: 'Period end date',
    period_start_date: 'Period start date',
    person_in_charge_information: 'Person in charge information',
    please_reload_while_getting_id: 'Contacting Salesforce. Please reload',
    print_interview: 'Print',
    prior_interview: 'Prior interview',
    priority: 'Priority',
    protruding: 'Protruding teeth (maxillary prognathum)',
    questionnaire: {
      answer_date: 'Answer Date',
      comment: 'Nice Comment',
      description_rating: 'Description Rating',
      first: 'Fisrt',
      improvement_comment: 'Improvement Comment',
      manager: 'Manager',
      re_exam: 'Re Examination',
      title: 'Visit Questionnaire',
      treatment_evaluation: 'Treatment Evaluation',
      visit_date: 'Visit Date',
      q2: {
        1: 'Was adequate and polite',
        2: 'Was enough',
        3: 'Was inadequate',
        4: 'There was no explanation'
      },
      v2q1: {
        1: 'Very satisfied',
        2: 'Somewhat satisfied',
        3: 'Neither agree nor disagree',
        4: 'Somewhat dissatisfied',
        5: 'Very dissatisfied'
      },
      no_questionnaires: 'No questionnaire have been answered'
    },
    questions: {
      1: 'Have you ever consulted about dentition at another clinic?',
      2: 'Have you ever had orthodontic treatment in the past?'
    },
    rattling_double: 'Rattling dentition and double teeth (crowding)',
    reason_for_non_contract_update_description: '※After selecting the reason, press the "Save" button to complete the update',
    reason_for_non_contracting: 'Reason for non contracting',
    receive_value: ['Don\'t deliver', 'Deliver'],
    receptacle: 'Receptacle (opposite occlusion)',
    remaining_service: 'Remaining service amount',
    reservation_date: 'Reservation date',
    reservation_schedule: 'Reservation schedule',
    right_side: 'Right',
    search_by_brand_type: 'Search by type of brand',
    search_by_email: 'Search by email address',
    search_by_id: 'Search by patient ID',
    search_by_name: 'Search by patient name',
    search_by_sf_status_c: 'Search by SF status of patient',
    search_by_tel: 'Search by phone number',
    send_sms: 'Send SMS',
    send_sms_title: 'Preliminary inquiry sheet guidance SMS transmission',
    service_category: 'Service category',
    service_management: 'Service management',
    simulation_flag: 'Pre-contract simulation',
    sms_delivery_setting: 'SMS delivery settings',
    sms_history: 'SMS history',
    sms_history_status: {
      0: 'Transmission failure',
      1: 'Transmission successful'
    },
    sms_sending_confirmation_title: 'Send SMS',
    specifically: 'specifically',
    specify_the_period: 'Specify the period',
    sport: 'Lessons and sports',
    symptom: 'Symptom',
    symptomatology: 'Symptomatology',
    syncing_to_salesforce: '(Syncing to Salesforce.\nPlease reload)',
    system_information: 'System information',
    technician_instructions_template: 'Technician instructions template',
    technician_order: 'Technician Order',
    title_delete_modal: 'Delete patient information',
    title_editing: 'Patient information editing',
    title_registration: 'Patient registration',
    transmission_history: 'Transmission history (indicates whether SMS delivery to the mobile phone company was successful / unsuccessful)',
    treatment_details: 'Treatment details',
    treatmentend_status: 'Reason for ending treatment',
    upper_jaw: 'Upper',
    when: 'When',
    whitening: 'Whitening'
  }
}
