var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.className] },
    [
      _c("sub-question", {
        ref: "sub_question",
        attrs: {
          question: _vm.question,
          options: _vm.option_yes_no,
          checked: _vm.select_option
        },
        on: {
          handleAnswer: function($event) {
            return _vm.handleAnswer($event)
          }
        }
      }),
      _vm._v(" "),
      _c("div", { class: _vm.handleChildAnswerClass }, [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "inline-input-dose" }, [
            _c(
              "div",
              { staticClass: "form-inline justify-content-between pr-5" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name1,
                      expression: "name1"
                    }
                  ],
                  staticClass: "form-control col-md-4",
                  attrs: { type: "text" },
                  domProps: { value: _vm.name1 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.name1 = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v("年")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name2,
                      expression: "name2"
                    }
                  ],
                  staticClass: "form-control col-md-4",
                  attrs: { type: "text" },
                  domProps: { value: _vm.name2 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.name2 = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v("月ごろ")])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-inline justify-content-between pt-3" },
              [
                _c("span", [_vm._v("または")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name3,
                      expression: "name3"
                    }
                  ],
                  staticClass: "form-control col-md-3",
                  attrs: { type: "text" },
                  domProps: { value: _vm.name3 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.name3 = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v("年")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name4,
                      expression: "name4"
                    }
                  ],
                  staticClass: "form-control col-md-3",
                  attrs: { type: "text" },
                  domProps: { value: _vm.name4 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.name4 = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v("ヶ月前")])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "color-blue" }, [_vm._v("治療内容：")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name5,
                expression: "name5"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text", name: "" },
            domProps: { value: _vm.name5 },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name5 = $event.target.value
              }
            }
          })
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }