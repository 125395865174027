var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ModalList", {
    attrs: {
      customClass: "list-modal--reservation-setting",
      is_show_cancellation_button: false
    },
    on: { close: _vm.closeModal, "confirm-button-click": _vm.closeModal },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _c("h3", { staticClass: "list-modal__title" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("reservation.warning_setting")) +
                  "\n    "
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c("h3", { staticClass: "list-modal__description" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("reservation.content_warning")) + " "
              ),
              _c("br"),
              _vm._v(
                " " + _vm._s(_vm.$t("reservation.check_warning")) + "\n    "
              )
            ]),
            _vm._v(" "),
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("ID")]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("common.name")))]),
                  _vm._v(" "),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t("reservation.time_start_to")))
                  ]),
                  _vm._v(" "),
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t("accounting_list_page.reservations_frame"))
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                { class: { overflow: _vm.renderClassOverflow } },
                _vm._l(_vm.reservations, function(reservation, index) {
                  return _c(
                    "tr",
                    {
                      key: index,
                      on: {
                        click: function($event) {
                          return _vm.gotoReservationCalendar(
                            reservation.started_to,
                            reservation.id
                          )
                        }
                      }
                    },
                    [
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            reservation.p_sf_user_id || reservation.patient_id
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            (reservation.name || "") +
                              " (" +
                              (reservation.kana_last_name || "") +
                              " " +
                              (reservation.kana_first_name || "") +
                              ")"
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(_vm.formatDate(reservation.started_to)))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            reservation.allocation
                              ? reservation.allocation.column_name || ""
                              : ""
                          )
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "confirm_button_content",
        fn: function() {
          return [_vm._v("\n    " + _vm._s(_vm.$t("common.close")) + "\n  ")]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }