<template>
  <div class="white-list">
    <div class="content">
      <div class="content-wrapper">
        <div class="white-list__header">
          <h2 class="title">
            {{ $t('objects.setting.text.whitelist_setting') }}
          </h2>
          <button
            class="white-list__btn-add-ip-address"
            @click="showMyIpAddress"
          >
            <span>{{ $t('objects.setting.text.add_ip_address') }}</span>
          </button>
        </div>
        <div class="common-list common-list--white-list">
          <table class="main-list main-list__search">
            <thead>
              <tr>
                <th>
                  {{ $t('new_text.status') }}
                </th>
                <th>
                  {{ $t('objects.setting.text.ip') }}
                </th>
                <th>
                  {{ $t('new_text.label') }}
                </th>
                <th>
                  {{ $t('objects.setting.text.staff_id_reqired') }}
                </th>
              </tr>
            </thead>
            <tbody v-if="white_list.length > 0">
              <tr
                v-for="item in white_list"
                :key="item.id"
                @click="handleDetailSetting(item)"
              >
                <td>{{ labelStatus(item.status) }}</td>
                <td>{{ item.ip_address }}</td>
                <td>{{ item.label }}</td>
                <td>{{ item.staff.name }}（{{ item.staff.login_id }})</td>
              </tr>
            </tbody>
            <tbody
              v-else
              class="common-list__not-found"
            >
              <tr>
                <td colspan="5">
                  <p class="common-list__label-not-found">
                    {{ $t('new_message.no_result_search') }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="white_list.length > 0"
            class="common-list__pagination"
          >
            <pagination-common
              v-if="pagination.last_page > 1"
              :current-page="pagination.current_page"
              :last-page="pagination.last_page"
              class="pagination-common--pagination"
              @change-page="changePage"
            />
          </div>
        </div>
      </div>
      <modal-create-setting-ip
        v-if="is_show_modal_create_whitelist"
        :user-info="user_info"
        @close="is_show_modal_create_whitelist = false"
        @succeed="handleSucceedWhiteList"
      />
      <modal-update-setting-ip
        v-if="is_show_modal_update_whitelist"
        :ip-data="ip_selected"
        @close="is_show_modal_update_whitelist = false"
        @succeed="handleUpdateSucceedWhiteList"
      />
    </div>
    <Loading />
  </div>
</template>

<script>
  import Loading from '../../../components/Loading'
  import PaginationCommon from '../../../components/common/PaginationCommon'
  import ModalCreateSettingIp from '../v2/ModalCreateSettingIp'
  import ModalUpdateSettingIp from '../v2/ModalUpdateSettingIp'
  import { showSuccessModal, showMessageError, showErrorModal } from '../../../utils/helpers'
  import { get } from 'lodash'

  export default {
    components: {
      Loading,
      PaginationCommon,
      ModalCreateSettingIp,
      ModalUpdateSettingIp
    },
    data () {
      return {
        white_list: [],
        detail_whitelist: [],
        pagination: {
          current_page: 1,
          last_page: 1,
          offset: 2
        },
        user_info: {},
        is_show_modal_create_whitelist: false,
        is_show_modal_update_whitelist: false,
        ip_selected: {}
      }
    },
    created () {
      document.title = this.$t('objects.page_title.setting').toString()
      this.getWhiteList()
    },
    methods: {
      changePage (page) {
        if (this.pagination.current_page === page) {
          return
        }
        this.pagination.current_page = page
        this.getWhiteList()
      },
      getWhiteList () {
        this.$store.commit('set_loading', true)
        const inputData = {
          page: this.pagination.current_page
        }
        this.$store.dispatch('getWhiteList', inputData).then((result) => {
          const { data: { results } } = result
          this.white_list = results.data
          this.pagination.current_page = results.current_page
          this.pagination.last_page = results.last_page
          this.$store.commit('set_loading', false)
        }).catch((e) => {
          this.$store.commit('set_loading', false)
          showMessageError(e, this)
        })
      },
      labelStatus: function (status) {
        switch (status) {
          case null:
            return this.$t('new_text.requesting')
          case 0:
            return this.$t('new_text.disapproval')
          case 1:
            return this.$t('new_text.approval')
        }
      },
      handleSucceedWhiteList: function () {
        this.is_show_modal_create_whitelist = false
        showSuccessModal(this.$t('new_message.saved'))
        this.getWhiteList()
      },
      handleUpdateSucceedWhiteList: function () {
        this.is_show_modal_update_whitelist = false
        showSuccessModal(this.$t('new_message.saved'))
        this.getWhiteList()
      },

      showMyIpAddress: function () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('getLoggedInfo').then((result) => {
          const data = result?.data?.results
          this.user_info.staff_fullname = data?.user?.name || ''
          this.user_info.login_id = get(data, 'user.login_id') || ''
          this.is_show_modal_create_whitelist = true
          this.$store.commit('set_loading', false)
        }).catch(() => {
          showErrorModal(this.$t('new_message.something_went_wrong'))
          this.$store.commit('set_loading', false)
        })
      },
      handleDetailSetting: function (item) {
        this.ip_selected = item
        this.is_show_modal_update_whitelist = true
      }
    }
  }
</script>
