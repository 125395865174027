var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comment-item__group" },
    [
      _c("div", { staticClass: "comment-item__content" }, [
        _c("span", [_vm._v(_vm._s(_vm.logContent))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "comment-item__further" }, [
        _c("div", { staticClass: "comment-item__info" }, [
          _c("span", { staticClass: "comment-item__date" }, [
            _vm._v(_vm._s(_vm.logDate))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "comment-item__author" }, [
            _vm._v(_vm._s(_vm.authorName))
          ])
        ]),
        _vm._v(" "),
        _vm.canDelete
          ? _c(
              "span",
              {
                staticClass: "comment-item__action",
                on: { click: _vm.showModalConfirmDelete }
              },
              [_vm._v(_vm._s(_vm.$t("new_text.delete")))]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.is_show_modal_delete_memo
        ? _c("modal-confirm-danger", {
            attrs: {
              title: _vm.$t("new_message.do_you_want_delete_memo"),
              "text-ok": _vm.$t("new_text.button.delete")
            },
            on: {
              confirm: _vm.handleDeleteMemo,
              close: function($event) {
                _vm.is_show_modal_delete_memo = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }