var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "interview-other-v2 content-inner bg-white" },
    [
      _c("according-block", {
        attrs: { label: _vm.$t("new_text.other"), checked: _vm.show_block },
        on: {
          handleClick: function($event) {
            _vm.show_block = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show_block,
              expression: "show_block"
            }
          ],
          staticClass:
            "interview-other-v2__content arrow-toggle-content page-content"
        },
        [
          _c("div", { staticClass: "form-group row pt-4" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("sub-question", {
                  ref: "sub_question",
                  attrs: {
                    question: _vm.question,
                    options: _vm.option_yes_no,
                    checked: _vm.plan_to_move,
                    "is-required": false
                  },
                  on: {
                    "handle-answer": function($event) {
                      return _vm.handleAnswer($event)
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { class: _vm.handleChildAnswerClass }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-inline interview-other-v2__date pr-5"
                      },
                      [
                        _c("label", { staticClass: "color-bold" }, [
                          _vm._v(
                            _vm._s(_vm.$t("objects.patient.text.when")) + "："
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.when_to_move1,
                              expression: "when_to_move1"
                            }
                          ],
                          staticClass: "form-control col-md-3",
                          attrs: { type: "text" },
                          domProps: { value: _vm.when_to_move1 },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.when_to_move1 = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "interview-other-v2__date-times" },
                          [_vm._v(_vm._s(_vm.$t("new_text.year")))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.when_to_move2,
                              expression: "when_to_move2"
                            }
                          ],
                          staticClass: "form-control col-md-3",
                          attrs: { type: "text" },
                          domProps: { value: _vm.when_to_move2 },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.when_to_move2 = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "interview-other-v2__date-monthly" },
                          [_vm._v(_vm._s(_vm.$t("new_text.around_month")))]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "form-inline interview-other-v2__move-destination"
                      },
                      [
                        _c("label", { staticClass: "color-bold" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("objects.patient.text.move_destination")
                            ) + "："
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.moving_destination,
                              expression: "moving_destination"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", name: "" },
                          domProps: { value: _vm.moving_destination },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.moving_destination = $event.target.value
                            }
                          }
                        })
                      ]
                    )
                  ])
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _c("label", { staticClass: "color-bold" }, [
                _vm._v(_vm._s(_vm.$t("objects.patient.text.other_question.2")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.other_requests,
                      expression: "other_requests"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { rows: "5", maxlength: "2000" },
                  domProps: { value: _vm.other_requests },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.other_requests = $event.target.value
                    }
                  }
                })
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }