var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "event-card" }, [
    _vm.event.id && _vm.event.extendedProps.blocked === 0
      ? _c(
          "div",
          {
            class: [
              "event-card__reservation",
              {
                "event-card__reservation--grey-background":
                  _vm.reservationOverTime
              }
            ]
          },
          [
            _c("div", {
              class: [
                "event-card__duty__print",
                {
                  "event-card__duty__print--border":
                    Object.keys(_vm.duty).length === 0,
                  "event-card__duty__print--min": _vm.period === 5
                }
              ],
              style:
                "background-color: " +
                (_vm.duty.color || _vm.default_duty_color)
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "event-card__body__print",
                  { "event-card__body__print--min": _vm.period === 5 }
                ]
              },
              [
                _c("div", [
                  _c(
                    "p",
                    {
                      class: [
                        "event-card__patient__name event-card__patient__name__print",
                        {
                          "event-card__patient__name__print--big":
                            _vm.allocations <= 6 && _vm.period > 10
                        }
                      ]
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.interviewStatus) +
                          _vm._s(_vm.patientInfo.name || "")
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.getAge))])
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm.period > 10
                  ? _c(
                      "div",
                      {
                        class: [
                          "event-card__duty-name",
                          {
                            "event-card__duty-name--min": _vm.period < 25,
                            "event-card__duty-name--big":
                              _vm.allocations <= 6 && _vm.period > 15
                          }
                        ]
                      },
                      [
                        _c(
                          "span",
                          {
                            class: [
                              "event-card__patient__id",
                              {
                                "event-card__patient__id--big":
                                  _vm.allocations <= 6 && _vm.period > 15
                              }
                            ]
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.patient.sf_user_id || _vm.patient.id
                                ) +
                                _vm._s(_vm.brandName) +
                                " \n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.getDutiesName))])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayMemo
                  ? _c(
                      "div",
                      {
                        class: [
                          "event-card__memo",
                          {
                            "event-card__memo--big":
                              _vm.allocations <= 6 && _vm.period > 15
                          }
                        ]
                      },
                      [
                        _c(
                          "span",
                          { style: "-webkit-line-clamp: " + _vm.memoLine },
                          [_vm._v(_vm._s(_vm.event.extendedProps.memo))]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.event.id && _vm.event.extendedProps.blocked === 1
      ? _c("div", { staticClass: "event-card__blocked" }, [
          _c("div", { staticClass: "event-card__name-block" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("common.block")) + "\n    ")
          ]),
          _vm._v(" "),
          _vm.period > 10 && _vm.event.extendedProps.memo
            ? _c("div", { staticClass: "event-card__memo-block" }, [
                _c(
                  "span",
                  { style: "-webkit-line-clamp: " + _vm.memoBlockLine },
                  [_vm._v(_vm._s(_vm.event.extendedProps.memo))]
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }