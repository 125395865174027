var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "setting-sidebar" }, [
    _c("li", { staticClass: "setting-sidebar__nav-link" }, [
      _c("label", [_vm._v(_vm._s(_vm.$t("objects.nav.setting")))])
    ]),
    _vm._v(" "),
    _c(
      "li",
      {
        class: [
          "setting-sidebar__nav-link",
          {
            "setting-sidebar__nav-link--active": _vm.isRoute("setting.account")
          }
        ]
      },
      [
        _c("router-link", { attrs: { to: { name: "setting.account" } } }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("objects.nav.account_setting")) +
              "\n    "
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    !_vm.$store.getters.isLabDesigner
      ? _c(
          "li",
          {
            class: [
              "setting-sidebar__nav-link",
              {
                "setting-sidebar__nav-link--active": _vm.isRoute(
                  "setting.clinic"
                )
              }
            ]
          },
          [
            _c("router-link", { attrs: { to: { name: "setting.clinic" } } }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("objects.nav.clinic_setting")) +
                  "\n    "
              )
            ])
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "li",
      {
        class: [
          "setting-sidebar__nav-link",
          {
            "setting-sidebar__nav-link--active": _vm.isRoute(
              "setting.white-list"
            )
          }
        ]
      },
      [
        _c("router-link", { attrs: { to: { name: "setting.white-list" } } }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("objects.nav.ip_setting")) + "\n    "
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    !_vm.$store.getters.isLabDesigner
      ? _c(
          "li",
          {
            class: [
              "setting-sidebar__nav-link",
              {
                "setting-sidebar__nav-link--active": _vm.isRoute(
                  "setting.deleted-patient"
                )
              }
            ]
          },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "setting.deleted-patient" } } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("objects.nav.deleted_patient")) +
                    "\n    "
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.$store.getters.isLabDesigner
      ? _c(
          "li",
          {
            class: [
              "setting-sidebar__nav-link",
              {
                "setting-sidebar__nav-link--active": _vm.isRoute(
                  "setting.reservation-datetime"
                )
              }
            ]
          },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "setting.reservation-datetime" } } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("objects.nav.reservation_date_setting")) +
                    "\n    "
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.$store.getters.isLabDesigner
      ? _c(
          "li",
          {
            class: [
              "setting-sidebar__nav-link",
              {
                "setting-sidebar__nav-link--active": _vm.isRoute(
                  "setting.reservation-type"
                )
              }
            ]
          },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "setting.reservation-type" } } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("objects.nav.reservation_type_setting")) +
                    "\n    "
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$store.getters.isStaff && _vm.$store.getters.isAdmin
      ? _c(
          "li",
          {
            class: [
              "setting-sidebar__nav-link",
              {
                "setting-sidebar__nav-link--active": _vm.isRoute(
                  "setting.basic-treatment-policy"
                )
              }
            ]
          },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "setting.basic-treatment-policy" } } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("new_text.basic_treatment_policy")) +
                    "\n    "
                )
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }