var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["calculator-panel calculator-main", _vm.isShown ? "active" : ""]
    },
    [
      _c("div", { staticClass: "d-flex justify-content-center h-100" }, [
        _c(
          "div",
          {
            ref: "calcBody",
            staticClass: "calc calculator",
            attrs: { tabindex: "0" },
            on: { focus: _vm.onFocus, blur: _vm.onBlur }
          },
          [
            _c("div", { staticClass: "display" }, [
              0
                ? _c("input", {
                    staticClass: "result",
                    attrs: { type: "text", disabled: "disabled" },
                    domProps: {
                      value: _vm.expression_show ? _vm.expression_show : 0
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "wrapper" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.expression_show ? _vm.expression_show : 0))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "add-define" }, [
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.plusWithSnippet(1000)
                    }
                  }
                },
                [_vm._v("+1,000円")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.plusWithSnippet(10000)
                    }
                  }
                },
                [_vm._v("+10,000円")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.plusWithSnippet(100000)
                    }
                  }
                },
                [_vm._v("+100,000円")]
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "row" }, [
              _c(
                "button",
                {
                  staticClass: "control-action",
                  on: {
                    click: function($event) {
                      return _vm.removeOneCharacter()
                    }
                  }
                },
                [_c("img", { attrs: { src: "/images/backspace.svg" } })]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "control-action",
                  on: {
                    click: function($event) {
                      return _vm.clearAll()
                    }
                  }
                },
                [_vm._v("C")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "control-action",
                  on: {
                    click: function($event) {
                      return _vm.divisionOperator()
                    }
                  }
                },
                [_vm._v("÷")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "control-action w-90",
                  on: {
                    click: function($event) {
                      return _vm.multiplicationOperator()
                    }
                  }
                },
                [_vm._v("×")]
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "row" }, [
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.addToExpression(7)
                    }
                  }
                },
                [_vm._v("7")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.addToExpression(8)
                    }
                  }
                },
                [_vm._v("8")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.addToExpression(9)
                    }
                  }
                },
                [_vm._v("9")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "control-action w-90",
                  on: {
                    click: function($event) {
                      return _vm.subtractionOperator()
                    }
                  }
                },
                [_vm._v("−")]
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "row" }, [
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.addToExpression(4)
                    }
                  }
                },
                [_vm._v("4")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.addToExpression(5)
                    }
                  }
                },
                [_vm._v("5")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.addToExpression(6)
                    }
                  }
                },
                [_vm._v("6")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "control-action w-90",
                  on: {
                    click: function($event) {
                      return _vm.additionOperator()
                    }
                  }
                },
                [_vm._v("+")]
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "row" }, [
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.addToExpression(1)
                    }
                  }
                },
                [_vm._v("1")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.addToExpression(2)
                    }
                  }
                },
                [_vm._v("2")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.addToExpression(3)
                    }
                  }
                },
                [_vm._v("3")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "tall w-90",
                  on: {
                    click: function($event) {
                      return _vm.calculateExpression(3)
                    }
                  }
                },
                [_c("img", { attrs: { src: "/images/enter.svg" } })]
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "row" }, [
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.addToExpression(0)
                    }
                  }
                },
                [_vm._v("0")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "dote",
                  on: {
                    click: function($event) {
                      return _vm.addToExpression(".")
                    }
                  }
                },
                [_vm._v(".")]
              )
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }