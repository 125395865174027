<template>
  <div :class="['upload-file', customClass]">
    <div v-if="isCreate">
      <h2 class="upload-file__title">
        <span>{{ $t('objects.orders.text.upload_technician_instructions') }}</span>
        <div
          class="upload-file__title__help-link"
          @click="triggerClick(trigger_click_type.MENU, trigger_click_content.UPLOAD_HELP_LINK)"
          @click.middle="triggerClick(trigger_click_type.MENU, trigger_click_content.UPLOAD_HELP_LINK)"
        >
          <a
            :href="upload_help_link"
            target="_blank"
          ><img src="/images/icon-help.svg">
          </a>
          <a
            :href="upload_help_link"
            target="_blank"
          >{{ $t('objects.orders.text.upload_technician_instructions_help_link') }}
          </a>
        </div>
      </h2>
      <div class="upload-file__notes">
        <p class="upload-file__note upload-file__note--red pre-line">{{ $t('objects.orders.text.upload_technician_instructions_help') }}</p>
        <p class="upload-file__note pre-line">
          {{ $t('objects.orders.text.upload_technician_instructions_note_1') }}
          <span>{{ $t('objects.orders.text.upload_technician_instructions_note_1_1') }}</span>
        </p>
        <p class="upload-file__note">{{ $t('objects.orders.text.upload_technician_instructions_note_2') }}</p>
      </div>
      <div class="upload-file__drag">
        <input
          id="upload-instruction-file"
          type="file"
          title=""
          class="upload-file__input upload-file__input--hidden"
          :data-key="'upload_technician_instructions'"
          :accept="upload_technician_instructions.join(', ')"
          @change="fileChange($event, true)"
          @click="clearValue($event)"
        >
        <label class="upload-file__label">
          <span class="pc">{{ $t('objects.orders.text.drag_limit_title') }}<br></span>
          <span>{{ $t('objects.orders.text.drag_limit_size', { limit: uploading_limit }) }}</span>
        </label>
        <br>
        <label
          for="upload-instruction-file"
          class="upload-file__button"
        >
          {{ $t('objects.orders.text.select_files') }}
        </label>
      </div>
    </div>
    <div class="upload-file__uploaded">
      <h2
        v-if="form_data.upload_technician_instructions.length"
        class="upload-file__title upload-file__title--uploaded"
      >
        {{ $t('objects.orders.text.uploaded_technician_instructions') }}
      </h2>
      <div class="upload-file__files">
        <div
          v-for="(item, key) in form_data.upload_technician_instructions"
          :key="key"
          class="upload-file__file"
        >
          <div
            :class="`upload-file__inner upload-file__inner--${getExtension(item.name)}`"
            class="upload-file__inner"
            @click="lightBoxPdf(item)"
          >
            <pdf
              v-if="getExtension(item.name) === file_extension.PDF"
              :src="getUrlFile(item)"
            />
            <img
              v-else
              class="upload-file__image"
              :src="getUrlFile(item)"
            >
          </div>
          <div class="upload-file__information">
            <h3 class="upload-file__file--name">
              {{ item.name }}
            </h3>
            <p class="upload-file__file--extension">
              {{ getDate(item.lastModified) }}
            </p>
            <div
              v-if="isCreate"
              class="upload-file__remove"
              @click="removeFile(item, key, 'upload_technician_instructions')"
            >
              <img src="/images/close-x.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isCreate">
      <h2 class="upload-file__title upload-file__title--not-first">
        <span>{{ $t('objects.orders.text.file_upload') }}</span>
        <div
          class="upload-file__title__help-link"
          @click="triggerClick(trigger_click_type.MENU, trigger_click_content.TAKE_PICTURE_HELP_LINK)"
          @click.middle="triggerClick(trigger_click_type.MENU, trigger_click_content.TAKE_PICTURE_HELP_LINK)"
        >
          <a
            :href="take_picture_help_link"
            target="_blank"
          >
            <img src="/images/icon-help.svg">
          </a>
          <a
            :href="take_picture_help_link"
            target="_blank"
          >{{ $t('objects.orders.text.file_upload_help_link') }}
          </a>
        </div>
      </h2>
      <div class="upload-file__notes">
        <p class="upload-file__note upload-file__note--red">{{ $t('objects.orders.text.file_upload_help') }}</p>
        <p class="upload-file__note">{{ $t('objects.orders.text.file_upload_note_1') }}</p>
        <p class="upload-file__note">
          {{ $t('objects.orders.text.file_upload_note_2') }}
        </p>
        <p class="upload-file__note">
          {{ $t('objects.orders.text.file_upload_note_3') }}
        </p>
        <p class="upload-file__note upload-file__note--additional">
          {{ $t('objects.orders.text.file_upload_additional_note') }}
        </p>
      </div>
      <div class="upload-file__drag">
        <input
          id="upload-file"
          type="file"
          title=""
          multiple
          class="upload-file__input upload-file__input--hidden"
          :data-key="'file_upload'"
          :accept="order_uploaded_file.join(', ')"
          @change="fileChange($event, false)"
          @click="clearValue($event)"
        >
        <label class="upload-file__label">
          <span class="pc">{{ $t('objects.orders.text.drag_limit_title') }}</span>
          <span class="sp">{{ $t('objects.orders.text.drag_limit_title_sp') }} </span><br>
          <span>{{ $t('objects.orders.text.drag_limit_size', { limit: uploading_limit }) }}</span>
        </label>
        <br>
        <label
          for="upload-file"
          class="upload-file__button"
        >
          {{ $t('objects.orders.text.select_files') }}
        </label>
      </div>
    </div>
    <div class="upload-file__uploaded">
      <h2
        v-if="form_data.file_upload.length"
        class="upload-file__title upload-file__title--uploaded"
      >
        {{ $t('objects.orders.text.file_uploaded') }}
      </h2>
      <div class="upload-file__files">
        <div
          v-for="(item, key) in form_data.file_upload"
          :key="key"
          class="upload-file__file"
        >
          <div
            :class="`upload-file__inner upload-file__inner--uploaded upload-file__inner--${getExtension(item.name)}`"
            @click="lightBoxPdf(item)"
          >
            <pdf
              v-if="
                (getExtension(item.name) === file_extension.PDF) &&
                  is_rerender_pdf_file
              "
              :src="getUrlFile(item)"
            />
            <img
              v-else
              class="upload-file__image"
              :src="getUrlFile(item)"
            >
          </div>
          <div class="upload-file__information">
            <h3 class="upload-file__file--name">
              {{ item.name }}
            </h3>
            <p class="upload-file__file--extension">
              {{ getDate(item.lastModified) }}
            </p>
            <div
              v-if="isCreate"
              class="upload-file__remove"
              @click="removeFile(item, key, 'file_upload')"
            >
              <img src="/images/close-x.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalPDF
      v-if="is_show_modal"
      :url="pdf_url"
      :extension="extension"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
  import ModalPDF from '../modal/ModalPDF'
  import {
    ORDER_UPLOAD_TYPE,
    UPLOAD_MAX_FILE_SIZE,
    MAX_FILE_UPLOAD, TYPE_VIDEO,
    FILE_NAME_MAX_LENGTH,
    SF_PRE_CONTRACT_SIMULATION,
    TYPE_STL,
    FILE_EXTENSION
  } from '../../utils/constants'
  import * as moment from 'moment-timezone'
  import pdf from 'vue-pdf'
  import { showErrorModal } from '../../admin/utils/helpers'
  import { checkValidFileName, getExtension, getInvalidFileNameChars } from '../../utils/helpers'
  import { mapState } from 'vuex'

  export default {
    components: {
      ModalPDF,
      pdf
    },
    props: {
      customClass: {
        type: String,
        default: ''
      },
      isCreate: {
        type: Boolean,
        default: true
      },
      sfPreContractSimulation: {
        type: Number,
        default: SF_PRE_CONTRACT_SIMULATION.NOT_IMPLEMENTED
      },
      orderHistory: {
        type: Number,
        default: 0
      }
    },
    emits: ['update-data'],
    data () {
      return {
        form_data: {
          upload_technician_instructions: [],
          file_upload: [],
          size_file_upload: 0
        },
        uploading_limit: UPLOAD_MAX_FILE_SIZE,
        pdf_url: '',
        is_show_modal: false,
        extension: '',
        order_uploaded_file: [...ORDER_UPLOAD_TYPE, ...TYPE_VIDEO, ...TYPE_STL],
        upload_technician_instructions: ORDER_UPLOAD_TYPE,
        total_files: 0,
        is_rerender_pdf_file: true,
        file_extension: FILE_EXTENSION
      }
    },
    computed: {
      ...mapState('link_master', [
        'upload_help_link',
        'take_picture_help_link'
      ]),
      uploadFileTitle () {
        if (this.orderHistory === 1 && this.sfPreContractSimulation > SF_PRE_CONTRACT_SIMULATION.NOT_IMPLEMENTED) {
          return this.$t('objects.orders.text.please_select_file_with_simulation')
        }

        return this.$t('objects.orders.text.please_select_file')
      }
    },
    methods: {
      getExtension,
      fileChange: function (e, isInstructionsFile) {
        const dataKey = e.target.getAttribute('data-key')
        const files = []
        const fileErrors = []

        if (!e.target.files.length || ['file_upload', 'upload_technician_instructions'].indexOf(dataKey) === -1) {
          return
        }

        if (e.target.files.length > MAX_FILE_UPLOAD) {
          showErrorModal(this.$t('new_validation.common.max_file_upload', { maximum: MAX_FILE_UPLOAD }))
          return
        }

        for (const item of e.target.files) {
          const size = item.size * Math.pow(10, -6)
          const checkFileValid = ((dataKey === 'file_upload') ? this.order_uploaded_file : this.upload_technician_instructions).filter(
            type => type === item.type
          )
          if (size === 0) {
            showErrorModal(this.$t('new_validation.order.invalid_file'))
            return
          }
          if (item.name.length > FILE_NAME_MAX_LENGTH) {
            showErrorModal(this.$t('new_validation.order.invalid_file_name_length', { max: FILE_NAME_MAX_LENGTH }))
            return
          }
          if (size > UPLOAD_MAX_FILE_SIZE) {
            showErrorModal(this.$t('new_validation.order.upload_max_file_size'))
            return
          }
          if (!checkValidFileName(item.name)) {
            const charsInvalid = getInvalidFileNameChars(item.name)
            showErrorModal(this.$t('new_validation.common.invalid_filename', { symbols: charsInvalid.join(' ') }))
            return
          }

          // In case the extension of uploaded file is STL, the server will check file valid or not.
          if (checkFileValid.length || (!isInstructionsFile && this.getExtension(item.name) === this.file_extension.STL)) {
            files.push(item)
            this.form_data.size_file_upload += size
          } else {
            fileErrors.push(this.$t('new_validation.order.invalid_file'))
          }
        }

        if (fileErrors.length) {
          showErrorModal(fileErrors.join('<br>'))
          return
        }

        if (dataKey === 'file_upload') {
          this.total_files += files.length

          if (this.total_files > MAX_FILE_UPLOAD) {
            showErrorModal(this.$t('new_validation.common.max_file_upload', { maximum: MAX_FILE_UPLOAD }))
            this.total_files -= files.length
            return
          }

          this.form_data.file_upload = [...this.form_data.file_upload, ...files]
        }
        if (dataKey === 'upload_technician_instructions') {
          this.form_data.upload_technician_instructions = [...files]
        }
        this.$emit('update-data', this.form_data)
      },
      getUrlFile: function (file) {
        if (file.url && typeof file.url !== 'undefined') {
          return file.url
        }

        if (TYPE_VIDEO.indexOf(file.type) > -1) {
          return '/images/icon-play.jpg'
        }

        // In case STL, just check the extension of uploaded file, no need to check file type, the server will do it.
        if (this.getExtension(file.name) === this.file_extension.STL) {
          return '/images/icon-stl.svg'
        }

        if (this.order_uploaded_file.indexOf(file.type) > -1) {
          return URL.createObjectURL(file)
        }

        return ''
      },
      getDate: function (date) {
        return moment(new Date(date)).format('YYYY-MM-DD HH:mm')
      },
      removeFile: function (image, imageId, key) {
        this.form_data.size_file_upload -= image.size * Math.pow(10, -6)
        if (key === 'file_upload') {
          this.total_files--
          this.form_data.file_upload = this.form_data.file_upload.filter((item, key) => {
            return key !== imageId
          })
          this.is_rerender_pdf_file = false

          setTimeout(() => {
            this.is_rerender_pdf_file = true
          }, 0)
        }
        if (key === 'upload_technician_instructions') {
          this.form_data.upload_technician_instructions = this.form_data.upload_technician_instructions.filter((item, key) => {
            return key !== imageId
          })
        }

        this.$emit('update-data', this.form_data)
      },
      lightBoxPdf: function (fileItem) {
        const url = this.getUrlFile(fileItem)
        const type = fileItem.type.split('/').shift()
        const fileExt = getExtension(fileItem.name)
        if (url && type !== 'video' && type !== 'model' && fileExt !== this.file_extension.STL) {
          this.pdf_url = url
          this.extension = type
          this.is_show_modal = true
        }
      },
      closeModal: function () {
        this.is_show_modal = false
      },
      clearValue (event) {
        event.target.value = ''
      }
    }
  }
</script>
