var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-common" },
    _vm._l(_vm.chartData, function(item, index) {
      return _c("div", { key: index, staticClass: "chart-common__item" }, [
        _c("div", { staticClass: "chart-common__chart" }, [
          _c(
            "span",
            {
              staticClass: "chart-common__bar",
              style:
                "background-color: " +
                (item.background || "#ad4") +
                "; height: " +
                _vm.renderHeight(item.value) +
                "%"
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.value,
                      expression: "item.value"
                    }
                  ],
                  staticClass: "chart-common__number number"
                },
                [_vm._v(_vm._s(_vm.numberWithCommas(item.value)))]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "chart-common__label" }, [
          _vm._v(_vm._s(item.name))
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }