<template>
    <div :class="[className]">
        <sub-question
            ref="sub_question"
            :question="question"
            :options="option_yes_no"
            :checked="select_option"
            @handleAnswer="handleAnswer($event)"
        ></sub-question>
        <div v-bind:class="handleChildAnswerClass">
            <div class="form-group">
                <label class="color-blue">いつ頃：</label>
                <input v-model="time" class="form-control" type="text" name="">
            </div>
            <div class="form-group">
                <label class="color-blue">部位：</label>
                <input v-model="part" class="form-control" type="text" name="">
            </div>
            <div class="form-group">
                <label class="color-blue">対処法：</label>
                <input v-model="countermeasure" class="form-control" type="text" name="">
            </div>
        </div>
    </div>
</template>

<script>
    import SubQuestion from '../template/SubQuestion'
    import { INTERVIEW_COMMON_OPTIONS } from '../../../../utils/constants'
    import Common from "./CommonFunction"

    export default {
        components: {
            SubQuestion
        },
        props: ['className'],
        mixins: [Common],
        data() {
            return {
                number: 5,
                select_option: INTERVIEW_COMMON_OPTIONS.NO,
                question: '今まで顔や顎を強くぶつけたりケガをしたことはありましたか？',
                option_yes_no: [
                    INTERVIEW_COMMON_OPTIONS.YES,
                    INTERVIEW_COMMON_OPTIONS.NO,
                ],
                time: '',
                part: '',
                countermeasure: '',
                translate: {
                    いつ頃: 'time',
                    部位: 'part',
                    対処法: 'countermeasure'
                },
            }
        }
    }
</script>
