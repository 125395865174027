var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order" }, [
    _c("div", { staticClass: "order-content" }, [
      _c(
        "div",
        { staticClass: "content content--create-order" },
        [
          _c("div", { staticClass: "content__header" }, [
            _c(
              "div",
              { staticClass: "content__header content__header--small" },
              [
                _c("div", { staticClass: "content__text" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("objects.orders.text.title_create_order")) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content__confirm" },
                  [
                    !_vm.is_draft
                      ? _c(
                          "button",
                          {
                            class: [
                              "btn-v2 bg-curious-blue cl-white-v2",
                              { "btn-v2--disable": !_vm.enableSubmit }
                            ],
                            attrs: { disabled: !_vm.enableSubmit },
                            on: { click: _vm.handleClick }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("objects.orders.button.confirm"))
                              )
                            ])
                          ]
                        )
                      : [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn-v2 bg-cl-white-v2 cl-curious-blue",
                              on: {
                                click: function($event) {
                                  _vm.is_draft = false
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("new_text.button.modify")))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn-v2 bg-curious-blue cl-white-v2",
                              on: { click: _vm.submitOrder }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("new_text.button.order")))
                              ])
                            ]
                          )
                        ]
                  ],
                  2
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content__info" },
            [
              !_vm.is_draft
                ? _c("InfoPatientOrder", {
                    attrs: { form: _vm.form },
                    on: {
                      "sf-pre-contract-simulation": _vm.sfPreContractSimulation,
                      "info-data": _vm.updatePatientInfo,
                      "order-history": _vm.orderHistory,
                      "is-show-sf-pre-contract-simulation":
                        _vm.showSfPreContractSimulation,
                      "is-show-first-contract-simulation":
                        _vm.showFirstContractSimulation
                    }
                  })
                : _c("InfoPatientOrderDraft", { attrs: { form: _vm.form } }),
              _vm._v(" "),
              _vm.$store.getters.isStaff && !_vm.is_draft
                ? _c(
                    "div",
                    { staticClass: "order-content__basic-treatment-policy" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "order-content__basic-treatment-policy__label"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("new_text.basic_treatment_policy"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "order-content__basic-treatment-policy__content"
                        },
                        [_vm._v(_vm._s(_vm.basicTreatmentPolicy))]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("UploadMediaFile", {
                attrs: {
                  "is-create": !_vm.is_draft,
                  "sf-pre-contract-simulation":
                    _vm.form.sf_pre_contract_simulation,
                  "order-history": _vm.form.order_history
                },
                on: { "update-data": _vm.updateFiles }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "content__footer" }, [_c("Footer")], 1),
          _vm._v(" "),
          _vm.is_show_modal_create_order_sp
            ? _c("modal-confirm", {
                attrs: {
                  title: _vm.$t("objects.orders.text.create_order_confirm")
                },
                on: {
                  no: function($event) {
                    _vm.is_show_modal_create_order_sp = false
                  },
                  yes: _vm.submitOrderSP
                }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }