<template>
  <div class="patient-activity__item patient-activity__item--patient">
    <div class="patient-activity__item__info">
      <p class="patient-activity__item__log-content">
        {{ logContent }}
      </p>
      <div
        v-if="isShowAuthor"
        class="patient-activity__item__author"
      >
        <label class="patient-activity__item__label">{{ $t('new_text.author') }}</label>
        <span class="patient-activity__item__name">{{ authorName || patientName }}</span>
      </div>
    </div>
    <div class="patient-activity__item__date-time">
      <span>{{ logDate }}</span>
    </div>
    <div class="patient-activity__item__action">
      <router-link
        v-if="isShowButton"
        class="patient-activity__item__link"
        target="_blank"
        :to="{ name: 'patient.detail', params: { id: activity.patient_id } }"
      >
        {{ $t('new_text.button.display') }}
      </router-link>
    </div>
  </div>
</template>
<script>
  import * as moment from 'moment'
  import { safeGet } from '../../../utils/helpers'
  import { ACTIVITY_LOG_ACTION } from '../../../utils/constants'

  export default {
    name: 'PatientActivity',
    props: {
      activity: {
        type: Object,
        default: () => {}
      },
      patientName: {
        type: String,
        default: ''
      }
    },
    computed: {
      logDate: function () {
        return moment(this.activity.created_at).format(this.$t('new_text.datetime.year_month_day_hour_minute'))
      },
      isShowButton: function () {
        return this.activity && this.activity.action !== ACTIVITY_LOG_ACTION.CHECK_IN && this.activity.action !== ACTIVITY_LOG_ACTION.DELETE
      },
      isShowAuthor: function () {
        return this.activity && this.activity.action !== ACTIVITY_LOG_ACTION.CHECK_IN
      },
      logContent: function () {
        if (!this.activity) return ''

        return this.$t('objects.activity.patient.' + this.activity.action)
      },
      authorName: function () {
        return safeGet(this.activity, 'activityable.name') || safeGet(this.activity, 'staff.name')
      }
    }
  }
</script>
