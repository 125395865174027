<template>
  <div class="modal-create-reservation">
    <modal-action
      :class="{ 'modal-create-reservation__disabled-confirm': !is_valid_submit, 'd-none': !is_show_modal_create }"
      :title="$t('text.new_reservation')"
      @close="$emit('close')"
      @confirm="handleSubmit"
    >
      <template #body>
        <div class="modal-create-reservation__body">
          <div class="modal-create-reservation__info">
            <div class="modal-create-reservation__header">
              <div class="modal-create-reservation__row">
                <span class="modal-create-reservation__label">{{ $t('common.date_time') }}</span>
                <div class="modal-create-reservation__input datepicker datepicker--header-calender">
                  <date-picker
                    v-model="data_reservation.date"
                    class="modal-create-reservation__date"
                    readonly="readonly"
                    :wrap="false"
                    :config="dpOptions"
                    @input="setDateTo"
                  />
                  <div class="modal-create-reservation__time">
                    <div
                      v-click-outside="clickOutsideEvent"
                      :class="[
                        'modal-create-reservation__wrapper',
                        { 'modal-create-reservation__wrapper--active': show_modal_time_setting === `start-time` }
                      ]"
                    >
                      <span
                        :class="['form-control time-input', { 'time-input--placeholder': !data_reservation.time_start }]"
                        @click="showModalTimeSetting('start-time')"
                        v-text="data_reservation.time_start || '--:--'"
                      />
                      <modal-time-setting
                        v-if="show_modal_time_setting === `start-time`"
                        :default-value="data_reservation.time_start"
                        @set-time="updateStartTime"
                      />
                    </div>
                    <span class="modal-create-reservation__connection">〜</span>
                    <div
                      v-click-outside="clickOutsideEvent"
                      :class="[
                        'modal-create-reservation__wrapper',
                        { 'modal-create-reservation__wrapper--active': show_modal_time_setting === `end-time` }
                      ]"
                    >
                      <span
                        :class="['form-control time-input', { 'time-input--placeholder': !data_reservation.time_end }]"
                        @click="showModalTimeSetting('end-time')"
                        v-text="data_reservation.time_end || '--:--'"
                      />
                      <modal-time-setting
                        v-if="show_modal_time_setting === `end-time`"
                        :default-value="data_reservation.time_end"
                        @set-time="updateEndTime"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-create-reservation__row modal-create-reservation__row-block">
                <span class="modal-create-reservation__label" />
                <div class="modal-create-reservation__input">
                  <Checkbox
                    :label="$t('text.block_frame_label')"
                    :value="true"
                    @input="toggleBlock"
                  />
                </div>
              </div>
            </div>
            <div class="modal-create-reservation__content">
              <div class="modal-create-reservation__row">
                <span class="modal-create-reservation__label">{{ $t('common.name') }}</span>
                <div class="modal-create-reservation__input">
                  <input-search
                    :default-value="keyword_search_name"
                    :disabled="data_reservation.blocked || data_reservation.patient_id !== 0"
                    :icon-disabled="data_reservation.blocked ? undefined : '/images/icon-close.svg'"
                    :placeholder="$t('text.select_patient_placeholder')"
                    class="modal-create-reservation__name"
                    type="search-name"
                    @search="searchNamePatient"
                  />
                  <span class="modal-create-reservation__label-id">{{ $t('common.ID') }}</span>
                  <input-search
                    :default-value="keyword_search_id"
                    :disabled="data_reservation.blocked || data_reservation.patient_id !== 0"
                    :icon-disabled="data_reservation.blocked ? undefined : '/images/icon-close.svg'"
                    class="modal-create-reservation__id"
                    @search="searchIDPatient"
                  />
                </div>
              </div>
              <div class="modal-create-reservation__row modal-create-reservation__row-type">
                <span class="modal-create-reservation__label">{{ $t('text.reservation_type') }}</span>
                <div class="modal-create-reservation__input modal-create-reservation__input-type">
                  <div
                    v-for="(item, index) in reservation_types"
                    :key="item.id"
                    class="modal-list-reservation-type__item modal-create-reservation__selected-item"
                  >
                    <div class="modal-list-reservation-type__text">
                      <span
                        class="modal-list-reservation-type__color modal-create-reservation__selected-color"
                        :style="[item.duty ? {backgroundColor: item.duty.color}:'']"
                      />
                      <span class="modal-list-reservation-type__name">{{ item.duty_name }}</span>
                    </div>
                    <div class="modal-list-reservation-type__value modal-create-reservation__selected-value">
                      <span class="modal-list-reservation-type__time">
                        {{ item.take_time }}分
                        <img
                          src="/images/icon-close.svg"
                          @click="removeReservationTypeSelected(index)"
                        >
                      </span>
                    </div>
                  </div>
                  <button-common
                    :class="{
                      'modal-create-reservation__btn-add': true,
                      'modal-create-reservation__btn-disabled': data_reservation.blocked
                    }"
                    @click="showModalReservationType"
                  >
                    <template #content>
                      <img
                        v-if="data_reservation.blocked"
                        src="/images/icon-plus-gray.svg"
                      >
                      <img
                        v-else
                        src="/images/icon-plus-v2.svg"
                      >
                      {{ $t('reservation.add_reservation_type') }}
                    </template>
                  </button-common>
                </div>
              </div>
              <div class="modal-create-reservation__row modal-create-reservation__row-charge">
                <span class="modal-create-reservation__label">{{ $t('common.responsible') }}</span>
                <div class="modal-create-reservation__input modal-create-reservation__input-charge">
                  <div
                    v-for="(staff, index) in person_in_charges"
                    :key="staff.id"
                    class="modal-list-reservation-type__item modal-create-reservation__selected-item"
                  >
                    <div class="modal-list-reservation-type__text modal-list-reservation-type__text--person-in-charge">
                      <span class="modal-create-reservation__position-name">{{ staff.position_name }}</span>
                      {{ staff.name }}
                    </div>
                    <div class="modal-list-reservation-type__value modal-create-reservation__selected-value">
                      <img
                        src="/images/icon-close.svg"
                        @click="removePersonInCharge(index)"
                      >
                    </div>
                  </div>
                  <button-common
                    :class="{
                      'modal-create-reservation__btn-add': true,
                      'modal-create-reservation__btn-disabled': data_reservation.blocked
                    }"
                    @click="showModalPersonCharge"
                  >
                    <template #content>
                      <img
                        v-if="data_reservation.blocked"
                        src="/images/icon-plus-gray.svg"
                      >
                      <img
                        v-else
                        src="/images/icon-plus-v2.svg"
                      >
                      {{ $t('text.add_charge') }}
                    </template>
                  </button-common>
                </div>
              </div>
              <div class="modal-create-reservation__row modal-create-reservation__row-brand">
                <span class="modal-create-reservation__label">{{ $t('patient_page.brand_type') }}</span>
                <div
                  v-if="data_reservation.patient_id"
                  class="modal-create-reservation__input modal-create-reservation__brand"
                >
                  {{ brand_text || '' }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-create-reservation__note">
            <span>{{ $t('common.note') }}</span>
            <textarea
              v-model="data_reservation.memo"
              :placeholder="$t('reservation.reservation_note_placeholder')"
            />
          </div>
        </div>
      </template>
      <template #confirm_button_content>
        {{ isDetectAutoFrameOperation ? $t('new_text.button.next') : $t('text.register_reservation') }}
      </template>
    </modal-action>
    <modal-search-name-patient
      v-if="is_show_modal_search_name"
      :keyword="keyword_search_name"
      @select="setPatient"
      @close="is_show_modal_search_name = false"
    />
    <modal-search-id-patient
      v-if="is_show_modal_search_id"
      :keyword="keyword_search_id"
      @select="setPatient"
      @close="is_show_modal_search_id = false"
    />
    <modal-list-reservation-type
      v-if="is_show_modal_list_reservation_type"
      :reservation-type-selected="reservation_types"
      @close="is_show_modal_list_reservation_type = false"
      @select="selectReservationType"
    />
    <modal-person-in-charge
      v-if="is_show_modal_person_in_charge"
      @close="is_show_modal_person_in_charge = false"
      @select="selectPersonInCharge"
    />
    <modal-confirm
      v-if="is_show_modal_confirm_first_reservation"
      :title="$t('patient_page.first_reservation_modal_title_insert')"
      :text="$t('patient_page.first_reservation_confirmation_insert')"
      @no="is_show_modal_confirm_first_reservation = false"
      @yes="handleNextStep"
    />
    <modal-operation-reason
      v-if="is_show_modal_operation_reason"
      :is-block="!!data_reservation.blocked"
      :resources="resources"
      @back="handleBack"
      @confirm="submitCreateReservation"
    />
  </div>
</template>

<script>
  import * as moment from 'moment'
  import { showErrorModal } from '../../utils/helpers'
  import DatePicker from 'vue-bootstrap-datetimepicker'
  import InputSearch from '../common/InputSearch'
  import ModalAction from '../common/ModalAction'
  import Checkbox from '../common/Checkbox'
  import ButtonCommon from '../common/Button'
  import ModalSearchNamePatient from '../common/ModalSearchNamePatient'
  import ModalSearchIdPatient from '../common/ModalSearchIdPatient'
  import ModalListReservationType from './ModalListReservationType'
  import ModalOperationReason from './ModalOperationReason.vue'
  import ModalPersonInCharge from './ModalPersonInCharge'
  import ModalConfirm from '../common/ModalConfirm'
  import ModalTimeSetting from '../modal/ModalTimeSetting'
  import { RESPONSE_TYPE, OPERATION_REASON_TYPE, COLUMN_TYPE } from '../../utils/constants'
  import { updateOrCreate } from '../../mixins/reservation'
  import { isEmpty, get } from 'lodash'

  export default {
    components: {
      ModalOperationReason,
      Checkbox,
      ButtonCommon,
      DatePicker,
      InputSearch,
      ModalAction,
      ModalSearchNamePatient,
      ModalSearchIdPatient,
      ModalListReservationType,
      ModalPersonInCharge,
      ModalConfirm,
      ModalTimeSetting
    },
    mixins: [updateOrCreate],
    props: {
      dataCalendar: {
        type: Object,
        default: () => {}
      }
    },
    emits: ['created', 'close'],
    data () {
      return {
        data_reservation: {
          allocation_id: 0,
          date: '',
          time_start: '',
          time_end: '',
          blocked: false,
          patient_id: 0,
          patient_name: '',
          reservation_types: [],
          person_in_charges: [],
          memo: ''
        },
        first_reservation_id: 0,
        brand_text: '',
        is_valid_submit: false,
        reservation_types: [],
        person_in_charges: [],
        note: '',
        is_show_modal_search_name: false,
        is_show_modal_person_in_charge: false,
        is_show_modal_search_id: false,
        is_show_modal_list_reservation_type: false,
        is_show_modal_confirm_first_reservation: false,
        is_show_modal_operation_reason: false,
        is_show_modal_create: true,
        keyword_search_name: '',
        keyword_search_id: '',
        dpOptions: {
          format: 'YYYY年MM月DD日',
          useCurrent: false,
          dayViewHeaderFormat: 'YYYY / MM',
          locale: 'ja',
          ignoreReadonly: true,
          inline: false
        },
        show_modal_time_setting: ''
      }
    },
    computed: {
      resources () {
        const date = moment(this.dataCalendar.startStr).format('YYYY/MM/DD')

        return {
          allocation_name: get(this.dataCalendar, 'resource._resource.extendedProps.column_name', ''),
          brand_type_name: this.brand_text,
          memo: this.data_reservation.memo,
          name: this.data_reservation.patient_name,
          reservation_time: `${date} ${this.data_reservation.time_start} ~ ${this.data_reservation.time_end}`,
          sf_user_id: this.keyword_search_id
        }
      },
      isDetectAutoFrameOperation: function () {
        const allocationAuto = get(this.dataCalendar, 'resource._resource.extendedProps.column_type', 0)

        return this.$store.getters.isDetectAutoFrameOperation &&
          [COLUMN_TYPE.AUTO, COLUMN_TYPE.CSL].includes(allocationAuto)
      }
    },
    created () {
      this.setData()
    },
    methods: {
      setData () {
        this.data_reservation.allocation_id = this.dataCalendar.resource.id
        const startStr = this.dataCalendar.startStr
        const startTime = moment(startStr)
        this.data_reservation.date = startTime.format(this.$t('datetime.year_month_day'))
        this.data_reservation.time_start = startTime.format('HH:mm')

        const endStr = this.dataCalendar.endStr
        const endTime = moment(endStr)
        this.data_reservation.time_end = endTime.format('HH:mm')

        if (moment(endStr, 'YYYY-MM-DD').diff(moment(startStr, 'YYYY-MM-DD'), 'days')) {
          this.data_reservation.time_end = '23:59'
        }

        this.validateForm()
      },
      toggleBlock (value) {
        this.data_reservation.blocked = value
        if (this.data_reservation.blocked) {
          this.data_reservation.patient_id = 0
          this.data_reservation.patient_name = ''
          this.first_reservation_id = 0
          this.keyword_search_name = ''
          this.keyword_search_id = ''
          this.reservation_types = []
          this.person_in_charges = []
        }

        this.validateForm()
      },
      searchNamePatient (keyword) {
        if (this.data_reservation.patient_id || this.data_reservation.blocked) {
          this.resetDataSearchPatient()

          return
        }

        this.keyword_search_name = keyword
        this.is_show_modal_search_name = true
      },
      searchIDPatient (keyword) {
        if (this.data_reservation.patient_id || this.data_reservation.blocked) {
          this.resetDataSearchPatient()

          return
        }

        this.keyword_search_id = keyword
        this.is_show_modal_search_id = true
      },
      resetDataSearchPatient () {
        this.data_reservation.patient_id = 0
        this.data_reservation.patient_name = ''
        this.keyword_search_id = ''
        this.keyword_search_name = ''
        this.validateForm()
      },
      showModalReservationType () {
        if (!this.data_reservation.blocked) {
          this.is_show_modal_list_reservation_type = true
        }
      },
      showModalPersonCharge () {
        if (!this.data_reservation.blocked) {
          this.is_show_modal_person_in_charge = true
        }
      },
      setPatient (patient) {
        this.data_reservation.patient_id = patient.id
        this.data_reservation.patient_name = patient.name
        this.first_reservation_id = patient.first_reservation_id
        this.is_show_modal_search_name = false
        this.is_show_modal_search_id = false
        this.brand_text = patient.brand_text
        this.keyword_search_name = patient.name
        this.keyword_search_id = patient.sf_user_id || patient.id
        this.validateForm()
      },
      handleSubmit () {
        this.data_reservation.date = moment(this.data_reservation.date, this.$t('datetime.year_month_day'))
          .format('YYYY-MM-DD')
        this.data_reservation.reservation_types = this.reservation_types.map(item => item.id)
        this.data_reservation.person_in_charges = this.person_in_charges.map(item => ({ id: item.id, type: null }))

        if (this.checkConflictWithOtherFrames(this.dataCalendar.resource.getEvents())) {
          showErrorModal(this.$t('message.already_booked'))

          return
        }

        if (!this.validateForm()) {
          return
        }

        this.$store.commit('set_loading', true)
        this.checkExistReservationTime({
          allocation_id: this.data_reservation.allocation_id,
          date: this.data_reservation.date,
          start_time: this.data_reservation.time_start,
          end_time: this.data_reservation.time_end
        }).then(result => {
          if (!result) {
            this.$store.commit('set_loading', false)
            return
          }

          if (this.first_reservation_id === 0 && !this.data_reservation.blocked) {
            this.$store.commit('set_loading', false)
            this.is_show_modal_confirm_first_reservation = true
          } else if (this.data_reservation.blocked) {
            this.handleNextStep()
          } else {
            this.checkFirstReservationOfPatient({
              patient_id: this.data_reservation.patient_id,
              started_to: `${this.data_reservation.date} ${this.data_reservation.time_start}`,
              id: 0
            }).then(result => {
              if (!result) {
                this.$store.commit('set_loading', false)

                return
              }

              this.handleNextStep()
            }).catch(() => {
              this.$store.commit('set_loading', false)
              showErrorModal(this.$t('message.something_went_wrong'))
            })
          }
        }).catch(() => {
          this.$store.commit('set_loading', false)
          showErrorModal(this.$t('message.something_went_wrong'))
        })
      },
      checkConflictWithOtherFrames (events) {
        const startTime = (new Date(`${this.data_reservation.date} ${this.data_reservation.time_start}`)).getTime()
        const endTime = (new Date(`${this.data_reservation.date} ${this.data_reservation.time_end}`)).getTime()
        let isConflicted = false
        for (const event of events) {
          if (event.end && event.start) {
            isConflicted = (startTime < event.end.getTime()) && (endTime > event.start.getTime())

            if (isConflicted) {
              return isConflicted
            }
          }
        }

        return false
      },
      submitCreateReservation (operationReason = {}) {
        this.is_show_modal_confirm_first_reservation = false
        this.$store.commit('set_loading', true)
        this.$store.dispatch('addNewReservation', this.data_reservation).then(result => {
          this.$store.commit('set_loading', false)
          const data = result?.data?.results

          if (data === RESPONSE_TYPE.DELETED) {
            showErrorModal(this.$t('reservation.reservation_type_deleted'))

            return
          }

          this.$emit('created', data)
          this.storeOperationReasons({
            ...operationReason,
            reservation_id: get(data, 'id', ''),
            operation_type: this.data_reservation.blocked ? OPERATION_REASON_TYPE.CREATE_BLOCK : OPERATION_REASON_TYPE.CREATE_RESERVATION
          })
        }).catch(error => {
          this.$store.commit('set_loading', false)
          const resErrors = error?.response?.data?.errors || {}
          const errors = []
          if (resErrors?.memo === RESPONSE_TYPE.MAXIMUM) {
            showErrorModal(this.$t('new_validation.reservation.memo_maxlength'))

            return
          }

          if (!isEmpty(resErrors) && resErrors.first_reservation === RESPONSE_TYPE.LOCKED) {
            showErrorModal(this.$t('objects.reservation.messages.reservation_can_not_change'))
            return
          }

          for (const index in resErrors) {
            errors.push(resErrors[index])
          }

          if (errors.length) {
            showErrorModal(errors.join('<br>'))

            return
          }

          showErrorModal(this.$t('message.something_went_wrong'))
        })
      },
      handleBack () {
        this.is_show_modal_operation_reason = false
        this.is_show_modal_create = true
      },
      handleNextStep () {
        if (this.isDetectAutoFrameOperation) {
          this.$store.commit('set_loading', false)
          this.is_show_modal_confirm_first_reservation = false
          this.is_show_modal_operation_reason = true
          this.is_show_modal_create = false

          return
        }

        this.submitCreateReservation()
      },
      storeOperationReasons (params = {}) {
        if (isEmpty(params) || !this.isDetectAutoFrameOperation) {
          return
        }

        this.$store.dispatch('storeOperationReasons', params).catch(() => {})
      }
    }
  }
</script>
