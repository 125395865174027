export default {
  note_send_reason_when_create_block: '*Block creating will be completed by sending the reason.',
  note_send_reason_when_create_reservation: '*Reservation creating will be completed by sending the reason.',
  note_send_reason_when_delete_allocation: '*Sending the reason will complete the deletion of the setting frame.',
  note_send_reason_when_update_allocation: '*Sending the reason will complete the modification of the setting frame.',
  note_send_reason_when_update_block: '*Block updating will be completed by sending the reason.',
  note_send_reason_when_update_reservation: '*Reservation updating will be completed by sending the reason.',
  note_specified_date_and_time: '*A block with a specified date and time',
  send_the_reason_to_the_supervisor: 'Please send the reason to the supervisor'
}
