<template>
  <logo>
    <div class="justify-content-center mt-5">
      <div class="form-group email-center">
        <input
          id="staff_id"
          v-model="staff_id"
          type="text"
          :placeholder="$t('login_page.staff_id')"
          class="form-control"
          disabled
        >
      </div>
    </div>
    <form
      autocomplete="off"
      method="post"
      @submit.prevent="initPassword"
    >
      <div class="justify-content-center form_container">
        <div class="form-group">
          <label for="pwd">{{ $t('common_page.password') }}</label>
          <span class="note-tip">{{ $t('common_page.input_characters') }}</span>
          <input
            id="pwd"
            v-model="password"
            type="password"
            placeholder=""
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label for="confirm-password">{{ $t('common_page.confirm_password') }}</label><span class="note-tip">{{ $t('common_page.input_characters') }}</span>
          <input
            id="confirm-password"
            v-model="password_confirmation"
            type="password"
            class="form-control"
          >
        </div>
      </div>
      <div class="mt-2 forget-password">
        <validation-errors
          v-if="validationErrors"
          :errors="validationErrors"
        />
      </div>
      <div class="d-flex justify-content-center mt-3 mb-4 login_container">
        <button
          type="submit"
          name="button"
          class="btn login_btn"
        >
          {{ $t('common_page.btn_login') }}
        </button>
      </div>
    </form>
  </logo>
</template>

<script>
  import logo from '../components/Logo'

  export default {
    components: {
      logo
    },
    data () {
      return {
        token: '',
        staff_id: '',
        password: '',
        password_confirmation: '',
        validationErrors: {}
      }
    },
    created () {
      document.title = this.$t('page_title.u1_4')
      this.$store.commit('set_loading', true)
      this.token = this.$route.params.token
      this.staff_id = this.$route.params.id
      this.$store.commit('set_loading', false)
    },
    methods: {
      async initPassword () {
        this.$store.commit('set_loading', true)
        const data = {
          token: this.token,
          staff_id: this.staff_id,
          password: this.password,
          password_confirmation: this.password_confirmation
        }
        this.$store.dispatch('initPasswordRequest', data).then(() => {
          this.$store.commit('set_loading', false)
          this.$router.push({ name: 'reset.complete' })
        }).catch((error) => {
          this.$store.commit('set_loading', false)
          this.validationErrors = error.response.data.errors
        })
      }
    }
  }
</script>
