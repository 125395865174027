import axios from 'axios'
import { state as authState } from './auth'

export const actions = {
  getPatientPersonInCharge ({ dispatch, commit }, patientId) {
    return axios.get(`/api/patient/${patientId}/person-in-charge`, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
  }
}
