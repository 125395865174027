import axios from 'axios'

export const namespaced = true

export const actions = {
  triggerClick ({ rootState }, { payload }) {
    return axios.post('/api/triggers/click', payload, {
      headers: { Authorization: 'Bearer ' + rootState.auth.token }
    })
  }
}
