<template>
  <transition name="modal">
    <div :class="['mc-modal create-staff', customClass]">
      <div class="create-staff__wrapper">
        <div class="create-staff__header">
          <h2 class="create-staff__heading">
            {{ $t('objects.setting.button.create_staff') }}
          </h2>
          <div
            class="create-staff__close"
            @click="hide"
          >
            <img src="/images/icon-close-modal-blue.svg">
          </div>
        </div>
        <div class="create-staff__body">
          <h2 class="create-staff__title">
            {{ $t('objects.setting.text.staff_information') }}
          </h2>
          <div class="create-staff__row">
            <div class="create-staff__row create-staff__row--left">
              {{ $t('new_text.name') }}
            </div>
            <div class="create-staff__row create-staff__row--right create-staff__row--top">
              <div class="create-staff__column create-staff__column--1">
                <input-common
                  :default-value="lastname"
                  :class="{ 'common-input-error': errors.lastname }"
                  :placeholder="$t('new_text.placeholder.last_name')"
                  maxlength="80"
                  @input="updateLastName"
                />
                <span
                  v-if="errors.lastname"
                  class="common-msg-error"
                >
                  {{ errors.lastname }}
                </span>
              </div>
              <div class="create-staff__column create-staff__column--2">
                <input-common
                  :default-value="firstname"
                  :class="{ 'common-input-error': errors.firstname }"
                  :placeholder="$t('new_text.placeholder.first_name')"
                  maxlength="40"
                  @input="updateFirstName"
                />
                <span
                  v-if="errors.firstname"
                  class="common-msg-error"
                >
                  {{ errors.firstname }}
                </span>
              </div>
            </div>
          </div>
          <div class="create-staff__row">
            <div class="create-staff__row create-staff__row--left">
              {{ $t('new_text.roman') }}
            </div>
            <div class="create-staff__row create-staff__row--right create-staff__row--top">
              <div class="create-staff__column create-staff__column--1">
                <input-common
                  :default-value="lastname_roma"
                  :placeholder="$t('new_text.placeholder.sei')"
                  :class="{ 'common-input-error': errors.lastname_roma }"
                  maxlength="191"
                  @input="updateLastNameRoma"
                />
                <span
                  v-if="errors.lastname_roma"
                  class="common-msg-error"
                >
                  {{ errors.lastname_roma }}
                </span>
              </div>
              <div class="create-staff__column create-staff__column--2">
                <input-common
                  :default-value="firstname_roma"
                  :class="{ 'common-input-error': errors.firstname_roma }"
                  :placeholder="$t('new_text.placeholder.mei')"
                  maxlength="191"
                  @input="updateFirstNameRoma"
                />
                <span
                  v-if="errors.firstname_roma"
                  class="common-msg-error"
                >
                  {{ errors.firstname_roma }}
                </span>
              </div>
            </div>
          </div>
          <div class="create-staff__row">
            <div class="create-staff__row create-staff__row--left">
              {{ $t('new_text.position') }}
            </div>
            <div class="create-staff__row create-staff__row--right">
              <div class="create-staff__column create-staff__column--1">
                <select-common
                  class="create-staff__select"
                  :options="positionList"
                  :placeholder="$t('objects.setting.button.please_select')"
                  @change="updatePosition"
                />
              </div>
              <div class="create-staff__column create-staff__column--2">
                <label class="create-staff__label">{{ $t('objects.setting.text.is_admin') }}</label>
                <div class="radio-group h-align">
                  <div
                    v-for="(item, index) in admin_role_options"
                    :key="index"
                    class="create-staff__radio"
                    @click="is_admin = item.value"
                  >
                    <radio-common
                      :label="item.label"
                      :val="item.value"
                      :name="'admin_role'"
                      :is-checked="item.value === 0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2 class="create-staff__title">
            {{ $t('new_text.login_information') }}
          </h2>
          <div class="create-staff__row">
            <div class="create-staff__row create-staff__row--left">
              {{ $t('objects.setting.text.staff_id') }}
            </div>
            <div class="create-staff__row create-staff__row--right">
              <div class="create-staff__space">
                <input-common
                  :class="{ 'common-input-error': errors.staff_id }"
                  :default-value="staff_id"
                  :placeholder="$t('new_validation.common.required', { attribute: $t('objects.setting.text.staff_id') })"
                  maxlength="191"
                  @input="updateStaffId"
                />
                <span
                  v-if="errors.staff_id"
                  class="common-msg-error"
                >
                  {{ errors.staff_id }}
                </span>
              </div>
              <label class="create-staff__label create-staff__label--13">{{ $t('objects.setting.text.staff_id_login_rule') }}</label>
            </div>
          </div>
          <div class="create-staff__row">
            <div class="create-staff__row create-staff__row--left">
              {{ $t('objects.setting.text.email_address_optional') }}
            </div>
            <div class="create-staff__row create-staff__row--right">
              <div class="create-staff__space">
                <input-common
                  :class="{ 'common-input-error': errors.staff_email }"
                  :default-value="staff_email"
                  :placeholder="$t('new_validation.common.required', { attribute: $t('new_validation.common.attributes.email') })"
                  maxlength="80"
                  @input="updateStaffEmail"
                />
                <span
                  v-if="errors.staff_email"
                  class="common-msg-error"
                >
                  {{ errors.staff_email }}
                </span>
              </div>
              <checkbox-common
                :value="send_mail"
                :label="$t('objects.setting.text.send_email')"
                :is-checked="send_mail"
                @input="updateSendMail"
              />
            </div>
          </div>
          <div class="create-staff__row">
            <div class="create-staff__row create-staff__row--left">
              {{ $t('objects.setting.text.password_init') }}
            </div>
            <div class="create-staff__row create-staff__row--right">
              <div class="create-staff__space">
                <input-common
                  :class="{ 'common-input-error': errors.password }"
                  :default-value="password"
                  :placeholder="$t('new_validation.common.required', { attribute: $t('objects.setting.text.password_init') })"
                  maxlength="191"
                  type="password"
                  autocomplete="new-password"
                  @input="updateStaffPassword"
                />
                <span
                  v-if="errors.password"
                  class="common-msg-error"
                >
                  {{ errors.password }}
                </span>
              </div>
              <label class="create-staff__label create-staff__label--13">{{ $t('objects.setting.text.password_min') }}</label>
            </div>
          </div>
        </div>
        <div class="create-staff__footer">
          <button-common
            class="create-staff__button create-staff__button--cancel"
            @click="hide"
          >
            <template #content>
              {{ $t('new_text.button.cancel') }}
            </template>
          </button-common>
          <button-common
            class="create-staff__button create-staff__button--save"
            @click="submitForm"
          >
            <template #content>
              {{ $t('new_text.button.save') }}
            </template>
          </button-common>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import { showValidationErrorModal, checkInvalidRomaji, isEmail } from '../../utils/helpers.js'
  import { VALIDATE_RULE } from '../../utils/constants'
  import { required, minLength, maxLength, alphaNum } from 'vuelidate/lib/validators'
  import InputCommon from '../common/Input'
  import CheckboxCommon from '../common/Checkbox'
  import ButtonCommon from '../common/Button'
  import SelectCommon from '../common/Select'
  import RadioCommon from '../common/Radio'

  export default {
    name: 'CreateStaffForm',
    components: {
      InputCommon,
      CheckboxCommon,
      ButtonCommon,
      SelectCommon,
      RadioCommon
    },
    props: {
      customClass: {
        type: String,
        default: ''
      },
      positionList: {
        type: Array,
        default: () => []
      }
    },
    emits: ['staff-added', 'close-modal', 'update-data-new-staff'],
    data () {
      return {
        firstname: '',
        lastname: '',
        firstname_roma: '',
        lastname_roma: '',
        position_id: '',
        admin_role_options: [
          {
            value: 0,
            label: this.$t('new_text.button.none')
          },
          {
            value: 1,
            label: this.$t('new_text.button.yes')
          }
        ],
        is_admin: 0,
        staff_id: '',
        staff_email: '',
        send_mail: true,
        password: '',
        errors: {}
      }
    },
    validations () {
      const rules = {
        firstname: { required, customJapaneseName: VALIDATE_RULE.CUSTOM_JAPANESE_NAME, maxLength: maxLength(40) },
        lastname: { required, customJapaneseName: VALIDATE_RULE.CUSTOM_JAPANESE_NAME, maxLength: maxLength(80) },
        firstname_roma: { required, maxLength: maxLength(191) },
        lastname_roma: { required, maxLength: maxLength(191) },
        position_id: { required },
        staff_id: { required, customAlphaNumeric: VALIDATE_RULE.CUSTOM_ALPHA_NUMERIC },
        password: { required, alphaNum, minLength: minLength(8) },
        staff_email: { isEmail, maxLength: maxLength(80) }
      }
      if (this.send_mail) {
        rules.staff_email = { required, isEmail, maxLength: maxLength(80) }
      }
      return rules
    },
    methods: {
      hide: function () {
        this.$emit('close-modal')
        this.resetForm()
      },
      validate: function () {
        const errors = []
        const errorMessages = {
          'lastname.required': this.$t('new_validation.common.last_name_required'),
          'lastname.customJapaneseName': this.$t('new_validation.common.invalid', { attribute: this.$t('new_text.placeholder.last_name') }),
          'lastname.maxLength': this.$t('new_validation.common.maximum', {
            attribute: this.$t('new_text.last_name'),
            max: 80
          }),
          'firstname.required': this.$t('new_validation.common.first_name_required'),
          'firstname.customJapaneseName': this.$t('new_validation.common.invalid', { attribute: this.$t('new_text.placeholder.first_name') }),
          'firstname.maxLength': this.$t('new_validation.common.maximum', {
            attribute: this.$t('new_text.first_name'),
            max: 40
          }),
          'lastname_roma.required': this.$t('new_validation.common.romaji_last_name_required'),
          'lastname_roma.maxLength': this.$t('new_validation.common.maximum', {
            attribute: this.$t('new_text.roma_last_name'),
            max: 191
          }),
          'firstname_roma.required': this.$t('new_validation.common.romaji_first_name_required'),
          'firstname_roma.maxLength': this.$t('new_validation.common.maximum', {
            attribute: this.$t('new_text.roma_first_name'),
            max: 191
          }),
          'position_id.required': this.$t('new_validation.common.required', { attribute: this.$t('new_validation.common.attributes.position') }),
          'staff_id.required': this.$t('new_validation.common.required', { attribute: this.$t('new_validation.common.attributes.staff_id') }),
          'staff_id.customAlphaNumeric': this.$t('objects.setting.text.staff_id_login_rule'),
          'staff_email.required': this.$t('new_validation.common.required', { attribute: this.$t('new_validation.common.attributes.email') }),
          'staff_email.isEmail': this.$t('new_validation.common.invalid_email'),
          'staff_email.maxLength': this.$t('new_validation.common.maximum', {
            attribute: this.$t('new_validation.common.attributes.email'),
            max: 80
          }),
          'password.required': this.$t('new_validation.common.required', { attribute: this.$t('new_validation.common.attributes.password') }),
          'password.alphaNum': this.$t('new_validation.staff.min_password', { min: 8 }),
          'password.minLength': this.$t('new_validation.staff.min_password', { min: 8 })
        }
        for (const key in errorMessages) {
          const parts = key.split('.')
          if (this.$v[parts[0]] && this.$v[parts[0]][parts[1]] === false) {
            this.errors[parts[0]] = errorMessages[key]
            errors.push(errorMessages[key])
          }
        }

        const validationHaffSizeLastRomajiName = this.checkHaffSizeRomajiName(this.lastname_roma)
        const validationHaffSizeFirstRomajiName = this.checkHaffSizeRomajiName(this.firstname_roma)
        if (validationHaffSizeLastRomajiName !== '' || validationHaffSizeFirstRomajiName !== '') {
          errors.push(validationHaffSizeFirstRomajiName || validationHaffSizeLastRomajiName)
        }

        const filtered = errors.filter(function (el) {
          return el != null
        })

        if (filtered.length) {
          showValidationErrorModal(filtered)
          return false
        }
        return true
      },
      submitForm: function () {
        this.errors = {}
        if (!this.validate()) return

        this.$emit('add-new-staff', {
          firstname: this.firstname,
          lastname: this.lastname,
          firstname_roma: this.firstname_roma,
          lastname_roma: this.lastname_roma,
          position_id: this.position_id,
          is_admin: this.is_admin,
          staff_id: this.staff_id,
          email: this.staff_email,
          send_mail: this.send_mail,
          password: this.password
        })
      },
      resetForm: function () {
        this.firstname = ''
        this.lastname = ''
        this.firstname_roma = ''
        this.lastname_roma = ''
        this.position_id = ''
        this.is_admin = 0
        this.staff_id = ''
        this.staff_email = ''
        this.send_mail = true
        this.password = ''
      },
      updateFirstName: function (data) {
        this.firstname = data
      },
      updateFirstNameRoma: function (data) {
        this.firstname_roma = data
      },
      updateLastName: function (data) {
        this.lastname = data
      },
      updateLastNameRoma: function (data) {
        this.lastname_roma = data
      },
      updateStaffId: function (data) {
        this.staff_id = data
      },
      updateStaffEmail: function (data) {
        this.staff_email = data
      },
      updateSendMail: function (data) {
        this.send_mail = data
      },
      updateStaffPassword: function (data) {
        this.password = data
      },
      updatePosition: function (data) {
        this.position_id = data
      },
      checkHaffSizeRomajiName (value) {
        if (value === '') {
          return ''
        }

        if (checkInvalidRomaji(value)) {
          return this.$t('new_validation.common.romaji_haffsize')
        }
      }
    }
  }
</script>
