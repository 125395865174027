var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "radio-common" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.checked,
          expression: "checked"
        }
      ],
      attrs: { id: _vm.generateId, type: "radio", name: _vm.name },
      domProps: { value: _vm.val, checked: _vm._q(_vm.checked, _vm.val) },
      on: {
        change: [
          function($event) {
            _vm.checked = _vm.val
          },
          _vm.onChange
        ]
      }
    }),
    _vm._v(" "),
    _c("label", { attrs: { for: _vm.generateId } }, [_vm._v(_vm._s(_vm.label))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }