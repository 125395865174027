<template>
  <div class="patient-v2__header patient-v2__header--normal">
    <h1 class="patient-v2__title">
      {{ customTitle }}
    </h1>
    <div class="patient-v2__action">
      <button-common
        class="button-v2--back"
        @click="$emit('return')"
      >
        <template #content>
          {{ $t('new_text.button.return_without_saving') }}
        </template>
      </button-common>
      <button-common
        class="button-v2--save"
        @click="$emit('submit')"
      >
        <template #content>
          {{ $t('new_text.button.save') }}
        </template>
      </button-common>
    </div>
  </div>
</template>

<script>
  import ButtonCommon from '../../common/Button'

  export default {
    components: {
      ButtonCommon
    },
    props: {
      customTitle: {
        type: String,
        default: ''
      }
    }
  }
</script>
