var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "pagination-common" },
    [
      _c(
        "li",
        {
          class: [
            "page-item",
            _vm.currentPage > 1 ? "prev-icon-active" : "prev-icon-inactive"
          ]
        },
        [
          _c("span", {
            staticClass: "page-link",
            on: { click: _vm.clickPrevPage }
          })
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.pagesNumber, function(item, index) {
        return _c(
          "li",
          {
            key: index,
            class: [
              item.value === _vm.currentPage ? "page-item active" : "page-item"
            ]
          },
          [
            item.type === "space"
              ? _c("span", { staticClass: "page-link page-link--no-action" }, [
                  _vm._v("\n      " + _vm._s(item.value) + "\n    ")
                ])
              : _c(
                  "span",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function($event) {
                        return _vm.switchPage(item.value)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.value))]
                )
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "li",
        {
          class: [
            "page-item",
            _vm.currentPage < _vm.lastPage
              ? "next-icon-active"
              : "next-icon-inactive"
          ]
        },
        [
          _c("span", {
            staticClass: "page-link",
            on: { click: _vm.clickNextPage }
          })
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }