var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "patient-v2__header patient-v2__header--normal" },
    [
      _c("h1", { staticClass: "patient-v2__title" }, [
        _vm._v("\n    " + _vm._s(_vm.customTitle) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "patient-v2__action" },
        [
          _c("button-common", {
            staticClass: "button-v2--back",
            on: {
              click: function($event) {
                return _vm.$emit("return")
              }
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("new_text.button.return_without_saving")
                        ) +
                        "\n      "
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _vm._v(" "),
          _c("button-common", {
            staticClass: "button-v2--save",
            on: {
              click: function($event) {
                return _vm.$emit("submit")
              }
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("new_text.button.save")) +
                        "\n      "
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }