var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "interview-content-consultation__block" }, [
    _c("label", { staticClass: "header-box" }, [_vm._t("default")], 2),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "interview-content-consultation__block-part row" },
      [
        _c(
          "div",
          { staticClass: "col-md-4 left-part-conent interview-input" },
          [
            _c("label", { staticClass: "block-header color-blue" }, [
              _vm._v("1. 部位")
            ]),
            _vm._v(" "),
            _vm.errors.location
              ? _c("p", { staticClass: "error-massage" }, [
                  _vm._v(_vm._s(_vm.errors.location))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex" },
              _vm._l(_vm.location, function(item) {
                return _c(
                  "div",
                  { staticClass: "d-block checkbox-solid col-4 p-0 m-0" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "check-container",
                        on: {
                          click: function($event) {
                            return _vm.toggleItem(item)
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "checkbox-button",
                          class: { checked: item.isChecked }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "label-text" }, [
                          _vm._v(_vm._s(item.value))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(item.options, function(subItem) {
                      return _c(
                        "div",
                        { staticClass: "checkbox-solid col-12 mt-2 pl-2" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "check-container",
                              class: { disable: !item.isChecked },
                              on: {
                                click: function($event) {
                                  return _vm.toggleSubItem(item, subItem)
                                }
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "checkbox-button",
                                class: { checked: subItem.isChecked }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label-text" }, [
                                _vm._v(_vm._s(subItem.value))
                              ])
                            ]
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              }),
              0
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-8 right-part-conent" }, [
          _c("label", { staticClass: "block-header color-blue" }, [
            _vm._v("歯列番号")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "part-selection" }, [
            _c("div", { staticClass: "row noMargin" }, [
              _c("div", { staticClass: "form-inline part-selection__ul" }, [
                _c("p", { staticClass: "part-selection__direction my-auto" }, [
                  _vm._v("UR")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  _vm._l(_vm.dentition.UR, function(item) {
                    return _c(
                      "div",
                      { staticClass: "checkbox-solid checkbox-solid-group" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "check-container sm-checkbox",
                            on: {
                              click: function($event) {
                                return _vm.toggleCheckbox(item)
                              }
                            }
                          },
                          [
                            _c("span", {
                              class: [
                                "checkbox-button",
                                { checked: item.isChecked }
                              ]
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "label-text" }, [
                              _vm._v(_vm._s(item.value))
                            ])
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-inline part-selection__ur" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  _vm._l(_vm.dentition.UL, function(item) {
                    return _c(
                      "div",
                      { staticClass: "checkbox-solid checkbox-solid-group" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "check-container sm-checkbox",
                            on: {
                              click: function($event) {
                                return _vm.toggleCheckbox(item)
                              }
                            }
                          },
                          [
                            _c("span", {
                              class: [
                                "checkbox-button",
                                { checked: item.isChecked }
                              ]
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "label-text" }, [
                              _vm._v(_vm._s(item.value))
                            ])
                          ]
                        )
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("p", { staticClass: "part-selection__direction my-auto" }, [
                  _vm._v("UL")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row noMargin" }, [
              _c("div", { staticClass: "form-inline part-selection__ll" }, [
                _c("p", { staticClass: "part-selection__direction my-auto" }, [
                  _vm._v("LR")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  _vm._l(_vm.dentition.LR, function(item) {
                    return _c(
                      "div",
                      { staticClass: "checkbox-solid checkbox-solid-group" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "check-container sm-checkbox",
                            on: {
                              click: function($event) {
                                return _vm.toggleCheckbox(item)
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "label-text" }, [
                              _vm._v(_vm._s(item.value))
                            ]),
                            _vm._v(" "),
                            _c("span", {
                              class: [
                                "checkbox-button",
                                { checked: item.isChecked }
                              ]
                            })
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-inline part-selection__lr" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  _vm._l(_vm.dentition.LL, function(item) {
                    return _c(
                      "div",
                      { staticClass: "checkbox-solid checkbox-solid-group" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "check-container sm-checkbox",
                            on: {
                              click: function($event) {
                                return _vm.toggleCheckbox(item)
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "label-text" }, [
                              _vm._v(_vm._s(item.value))
                            ]),
                            _vm._v(" "),
                            _c("span", {
                              class: [
                                "checkbox-button",
                                { checked: item.isChecked }
                              ]
                            })
                          ]
                        )
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("p", { staticClass: "part-selection__direction my-auto" }, [
                  _vm._v("LL")
                ])
              ])
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "interview-content-consultation__block-symptom" },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "block-header color-blue" }, [
            _vm._v("2. 症状")
          ]),
          _vm._v(" "),
          _vm.errors.symptoms
            ? _c("p", { staticClass: "error-massage" }, [
                _vm._v(_vm._s(_vm.errors.symptoms))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("radio-common", {
                  attrs: {
                    name: "symptoms" + _vm.index,
                    className: "sm-checkbox check-container",
                    label: "ガタガタした歯並びや八重歯（叢生）",
                    val: "ガタガタした歯並びや八重歯（叢生）"
                  },
                  on: { input: _vm.handleSymptom },
                  model: {
                    value: _vm.symptoms,
                    callback: function($$v) {
                      _vm.symptoms = $$v
                    },
                    expression: "symptoms"
                  }
                }),
                _vm._v(" "),
                _c("radio-common", {
                  attrs: {
                    name: "symptoms" + _vm.index,
                    className: "sm-checkbox check-container",
                    label: "出っ歯（上顎前突）",
                    val: "出っ歯（上顎前突）"
                  },
                  on: { input: _vm.handleSymptom },
                  model: {
                    value: _vm.symptoms,
                    callback: function($$v) {
                      _vm.symptoms = $$v
                    },
                    expression: "symptoms"
                  }
                }),
                _vm._v(" "),
                _c("radio-common", {
                  attrs: {
                    name: "symptoms" + _vm.index,
                    className: "sm-checkbox check-container",
                    label: "受け口（反対咬合）",
                    val: "受け口（反対咬合）"
                  },
                  on: { input: _vm.handleSymptom },
                  model: {
                    value: _vm.symptoms,
                    callback: function($$v) {
                      _vm.symptoms = $$v
                    },
                    expression: "symptoms"
                  }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "symptoms-comment" }, [
                  _vm._v(
                    "\n          ※症状が強い場合は適応にならない場合があります。\n        "
                  )
                ]),
                _vm._v(" "),
                _c("radio-common", {
                  attrs: {
                    name: "symptoms" + _vm.index,
                    className: "sm-checkbox check-container",
                    label: "すきっ歯（空隙歯列）",
                    val: "すきっ歯（空隙歯列）"
                  },
                  on: { input: _vm.handleSymptom },
                  model: {
                    value: _vm.symptoms,
                    callback: function($$v) {
                      _vm.symptoms = $$v
                    },
                    expression: "symptoms"
                  }
                }),
                _vm._v(" "),
                _c("radio-common", {
                  attrs: {
                    name: "symptoms" + _vm.index,
                    className: "sm-checkbox check-container",
                    label: "前歯が閉じない（開咬）",
                    val: "前歯が閉じない（開咬）"
                  },
                  on: { input: _vm.handleSymptom },
                  model: {
                    value: _vm.symptoms,
                    callback: function($$v) {
                      _vm.symptoms = $$v
                    },
                    expression: "symptoms"
                  }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "symptoms-comment" }, [
                  _vm._v(
                    "\n          ※症状が強い場合は適応にならない場合があります。\n        "
                  )
                ]),
                _vm._v(" "),
                _c("radio-common", {
                  attrs: {
                    name: "symptoms" + _vm.index,
                    className: "sm-checkbox check-container",
                    label: "中心がずれている（正中不一致）",
                    val: "中心がずれている（正中不一致）"
                  },
                  on: { input: _vm.handleSymptom },
                  model: {
                    value: _vm.symptoms,
                    callback: function($$v) {
                      _vm.symptoms = $$v
                    },
                    expression: "symptoms"
                  }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "symptoms-comment" }, [
                  _vm._v(
                    "\n          ※症状が強い場合は適応にならない場合があります。\n        "
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-8" },
              [
                _c("radio-common", {
                  attrs: {
                    name: "symptoms" + _vm.index,
                    className: "sm-checkbox check-container",
                    label: " 噛み合わせが深い（過蓋咬合）",
                    val: " 噛み合わせが深い（過蓋咬合）"
                  },
                  on: { input: _vm.handleSymptom },
                  model: {
                    value: _vm.symptoms,
                    callback: function($$v) {
                      _vm.symptoms = $$v
                    },
                    expression: "symptoms"
                  }
                }),
                _vm._v(" "),
                _c("radio-common", {
                  attrs: {
                    name: "symptoms" + _vm.index,
                    className: "sm-checkbox check-container",
                    label:
                      "スマイル時に上顎の歯肉が露出している（ガミースマイル）",
                    val:
                      "スマイル時に上顎の歯肉が露出している（ガミースマイル）"
                  },
                  on: { input: _vm.handleSymptom },
                  model: {
                    value: _vm.symptoms,
                    callback: function($$v) {
                      _vm.symptoms = $$v
                    },
                    expression: "symptoms"
                  }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "symptoms-comment" }, [
                  _vm._v(
                    "\n          ※本来であればスマイル時に、上顎の歯肉はギリギリ見えないのが理想的。\n        "
                  )
                ]),
                _vm._v(" "),
                _c("radio-common", {
                  attrs: {
                    name: "symptoms" + _vm.index,
                    className: "sm-checkbox check-container",
                    label: "ロゴボ",
                    val: "ロゴボ"
                  },
                  on: { input: _vm.handleSymptom },
                  model: {
                    value: _vm.symptoms,
                    callback: function($$v) {
                      _vm.symptoms = $$v
                    },
                    expression: "symptoms"
                  }
                }),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "symptom-other" },
                  [
                    _c("radio-common", {
                      attrs: {
                        name: "symptoms" + _vm.index,
                        className: "sm-checkbox check-container",
                        label: "その他",
                        val: "その他"
                      },
                      on: { input: _vm.handleSymptom },
                      model: {
                        value: _vm.symptoms,
                        callback: function($$v) {
                          _vm.symptoms = $$v
                        },
                        expression: "symptoms"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: ["other-input", _vm.diableOtherInput] },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.symptoms_other,
                              expression: "symptoms_other"
                            }
                          ],
                          staticClass: "form-control mt-2",
                          attrs: {
                            type: "text",
                            name: "",
                            placeholder: "その他の場合ご記入ください"
                          },
                          domProps: { value: _vm.symptoms_other },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.symptoms_other = $event.target.value
                            }
                          }
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "interview-content-consultation__block-comments" },
      [
        _c("label", { staticClass: "block-header color-blue" }, [
          _vm._v("3. コメント")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "fs-12" }, [
            _vm._v("気になる部位・症状について患者様の主訴となるコメント")
          ]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.comment,
                expression: "comment"
              }
            ],
            staticClass: "form-control",
            attrs: { rows: "3" },
            domProps: { value: _vm.comment },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.comment = $event.target.value
              }
            }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "symptoms-comment" }, [
      _vm._v(
        "\n          ※矯正歯科領域では、一般的に上下顎前突、上顎前突、下顎骨後退症など、口元が突出している状態のことが言われるケースが多い。 "
      ),
      _c("br"),
      _vm._v("\n          ※歯科矯正学ではこのような表現は存在しません。 "),
      _c("br"),
      _vm._v(
        "\n          ※症状が強い場合は適応にならない場合があります。\n        "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }