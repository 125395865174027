import { render, staticRenderFns } from "./OrderFileDetail.vue?vue&type=template&id=311177bf&"
import script from "./OrderFileDetail.vue?vue&type=script&lang=js&"
export * from "./OrderFileDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/medusa/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('311177bf')) {
      api.createRecord('311177bf', component.options)
    } else {
      api.reload('311177bf', component.options)
    }
    module.hot.accept("./OrderFileDetail.vue?vue&type=template&id=311177bf&", function () {
      api.rerender('311177bf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/order/OrderFileDetail.vue"
export default component.exports