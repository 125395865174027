var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "menu-sidebar" }, [
      _c("div", { staticClass: "menu-container" }, [
        _c("div", { staticClass: "menu-inner" }, [
          _c("div", { staticClass: "sidebar_content" }, [
            _c("nav", { staticClass: "nav-menu js-menu" }, [
              _c("ul", { staticClass: "u-list" }, [
                _vm.show_reservation && !_vm.isLabDesigner
                  ? _c(
                      "li",
                      {
                        staticClass: "menu_item",
                        attrs: { title: _vm.$t("sidebar_menu.reserve") }
                      },
                      [
                        _vm.current_page == "secretariat"
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "router-link-exact-active router-link-active",
                                attrs: { to: { name: "reservation.list" } }
                              },
                              [
                                _c("div", { staticClass: "item_inner" }, [
                                  _c("img", {
                                    attrs: {
                                      src: "/images/clipboard-with-pencil-.svg",
                                      alt: ""
                                    }
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("sidebar_menu.reserve"))
                                    )
                                  ])
                                ])
                              ]
                            )
                          : _c(
                              "router-link",
                              { attrs: { to: { name: "dashboard" } } },
                              [
                                _c("div", { staticClass: "item_inner" }, [
                                  _c("img", {
                                    attrs: {
                                      src: "/images/clipboard-with-pencil-.svg",
                                      alt: ""
                                    }
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("sidebar_menu.reserve"))
                                    )
                                  ])
                                ])
                              ]
                            )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_clinics_dmr === 1 && !_vm.isLabDesigner
                  ? _c(
                      "li",
                      {
                        staticClass: "menu_item",
                        attrs: { title: _vm.$t("sidebar_menu.recept") }
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "reception" } } },
                          [
                            _c("div", { staticClass: "item_inner" }, [
                              _c("img", {
                                attrs: {
                                  src: "/images/clipboard-reception.svg",
                                  alt: ""
                                }
                              }),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("sidebar_menu.recept")))
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isLabDesigner
                  ? _c(
                      "li",
                      {
                        staticClass: "menu_item",
                        attrs: { title: _vm.$t("sidebar_menu.patient") }
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "patient.list" } } },
                          [
                            _c("div", { staticClass: "item_inner" }, [
                              _c("img", {
                                attrs: {
                                  src: "/images/clipboard-patient.svg",
                                  alt: ""
                                }
                              }),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("sidebar_menu.patient")))
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_clinics_dmr === 1 && !_vm.isLabDesigner
                  ? _c(
                      "li",
                      {
                        staticClass: "menu_item",
                        attrs: { title: _vm.$t("sidebar_menu.accounting") }
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "accounting.list" } } },
                          [
                            _c("div", { staticClass: "item_inner" }, [
                              _c("img", {
                                attrs: {
                                  src: "/images/clipboard-accounting.svg",
                                  alt: ""
                                }
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("sidebar_menu.accounting"))
                                )
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "notification" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "notification.list" } } },
                [
                  _vm.totalNotification
                    ? _c("span", { staticClass: "notification-number" }, [
                        _vm._v(_vm._s(_vm.totalNotification))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn" }, [
                    _c("svg", { attrs: { viewBox: "0 0 21 24" } }, [
                      _c("defs"),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "a",
                        attrs: {
                          d:
                            "M10.5,24a3,3,0,0,0,3-3h-6A3,3,0,0,0,10.5,24Zm10.1-7.018c-.906-.973-2.6-2.437-2.6-7.232a7.4,7.4,0,0,0-6-7.273V1.5a1.5,1.5,0,1,0-3,0v.977A7.4,7.4,0,0,0,3,9.75c0,4.8-1.695,6.259-2.6,7.232A1.465,1.465,0,0,0,0,18a1.5,1.5,0,0,0,1.5,1.5H19.5A1.5,1.5,0,0,0,21,18,1.464,1.464,0,0,0,20.6,16.982Z"
                        }
                      })
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }