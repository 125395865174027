<template>
  <div>
    <div
      v-for="(item, index) in listPatient"
      :key="index"
    >
      <interview-print-answer
        v-if="item.interview_info.is_completed === 1"
        :patient-info="item"
      />
      <inter-view-print-without-answer
        v-if="item.interview_info.is_completed === 0"
        :patient-info="item"
      />
    </div>
  </div>
</template>

<script>
  import InterviewPrintAnswer from '../patients/InterviewPrintAnswer'
  import InterViewPrintWithoutAnswer from '../patients/InterviewPrintWithoutAnswer'
  import * as moment from 'moment-timezone'

  export default {
    name: 'InterviewPrintMulti',
    components: {
      InterviewPrintAnswer,
      InterViewPrintWithoutAnswer
    },
    data () {
      return {
        listPatient: []
      }
    },
    created () {
      window.getListPatient = this.getData.bind(this)
      var date = moment(this.selected_date).format('YYYYMMDD')
      document.title = `問診票_${date}`
    },
    methods: {
      getData: function (listPatient) {
        this.listPatient = listPatient
      }
    }
  }
</script>
