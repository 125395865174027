var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-form content-wrapper" }, [
    _c(
      "div",
      { staticClass: "login-form__small-screen" },
      [
        _c("loading"),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex justify-content-center" }, [
          _c("div", { staticClass: "login-form__title" }, [
            _c("h2", { staticClass: "title" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("new_text.login_title")) +
                  "\n        "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "login-form",
            attrs: { autocomplete: "off", method: "post" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.login($event)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "login-form__container" },
              [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "login-form__label",
                        attrs: { for: "staff_id" }
                      },
                      [_vm._v(_vm._s(_vm.$t("new_text.user_name")))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.staff_id,
                          expression: "staff_id"
                        }
                      ],
                      staticClass: "form-control login-form__input",
                      attrs: {
                        id: "staff_id",
                        placeholder: _vm.$t("new_text.placeholder.user_name")
                      },
                      domProps: { value: _vm.staff_id },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.staff_id = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.staff_id
                      ? _c("validation-errors", {
                          attrs: {
                            errors: _vm.errors.staff_id,
                            "text-left": true
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "login-form__label",
                        attrs: { for: "password" }
                      },
                      [_vm._v(_vm._s(_vm.$t("new_text.password")))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      staticClass: "form-control login-form__input",
                      attrs: {
                        id: "password",
                        type: "password",
                        placeholder: _vm.$t("new_text.placeholder.password")
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.password
                      ? _c("validation-errors", {
                          attrs: {
                            errors: _vm.errors.password,
                            "text-left": true
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("Checkbox", {
                      attrs: { label: _vm.$t("new_text.remember") },
                      model: {
                        value: _vm.remember,
                        callback: function($$v) {
                          _vm.remember = $$v
                        },
                        expression: "remember"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.errors.common_validation
                  ? _c("validation-errors", {
                      attrs: {
                        errors: _vm.errors.common_validation,
                        "text-left": true
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mt-3 mb-4 login-form_container" }, [
              _c(
                "button",
                {
                  staticClass: "btn login_btn",
                  attrs: { type: "submit", name: "button" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("new_text.login")) +
                      "\n        "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "login-form__switch-mode" }, [
              _c("span", { staticClass: "login-form__switch-mode__title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("new_text.switch_mode_sp_title")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "login-form__switch-mode__action" }, [
                _c(
                  "button",
                  {
                    staticClass: "login-form__switch-mode__button",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.changeLayoutMode(_vm.layout_mode.PC)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("new_text.switch_mode_pc")) +
                        "\n          "
                    )
                  ]
                )
              ])
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c("div", { staticClass: "brand_logo_container" }, [
        _c("img", {
          staticClass: "brand_logo",
          attrs: { src: "/images/logo-header-v2.svg", height: "160" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }