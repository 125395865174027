var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-action", {
    staticClass: "modal-v2--patient-edit-histories",
    attrs: { title: _vm.$t("objects.patient.text.edit_history") },
    on: {
      close: function($event) {
        return _vm.$emit("close")
      }
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "modal-search-patient__content" }, [
              _c(
                "div",
                { staticClass: "modal-search-patient__tab-content" },
                [
                  _c("table", { staticClass: "table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c(
                          "th",
                          { staticClass: "modal-search-patient__date" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t("objects.patient.text.edit_date")
                                ) +
                                "\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("th", {
                          staticClass: "modal-search-patient__sf-status"
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.patients_infos, function(item, index) {
                        return _c("tr", { key: index }, [
                          _c(
                            "td",
                            { staticClass: "modal-search-patient__data" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.formatDateTimeHistory(
                                      item.created_at,
                                      _vm.$t(
                                        "new_text.datetime.year_month_day_hour_minute"
                                      )
                                    )
                                  ) +
                                  "\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "modal-search-patient__sf-status" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn-v2 px-3",
                                  attrs: {
                                    to: {
                                      name: "patient.history",
                                      params: {
                                        id: _vm.patient_id,
                                        hid: item.id
                                      },
                                      query: {
                                        reservation_id: _vm.reservation_id
                                      }
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("new_text.button.display"))
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c("infinite-loading", {
                    attrs: { identifier: _vm.infinite_scroll_id },
                    on: { infinite: _vm.infiniteScrollHandler },
                    scopedSlots: _vm._u([
                      {
                        key: "no-more",
                        fn: function() {
                          return [_c("span", { staticClass: "d-none" })]
                        },
                        proxy: true
                      },
                      {
                        key: "no-results",
                        fn: function() {
                          return [_c("span", { staticClass: "d-none" })]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }