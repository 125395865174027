<template>
  <logo>
    <div class="d-flex justify-content-center token-expired">
      <p>{{ $t('reset_password.token_expired') }}</p>
    </div>
    <div class="d-flex justify-content-center mt-3 mb-4 login_container">
      <router-link
        :to="{name: 'password.request'}"
        class="btn login_btn"
      >
        {{ $t('reset_password.btn_reset') }}
      </router-link>
    </div>
  </logo>
</template>
<script>
  import logo from '../components/Logo'

  export default {
    components: {
      logo
    }
  }
</script>
