<template>
  <div class="modal-list-charge">
    <modal-action
      :title="$t('reservation.select_person_charge')"
      @close="$emit('close')"
    >
      <template #body>
        <div class="modal-list-charge__content">
          <div class="modal-list-charge__menu">
            <ul>
              <li
                v-for="(obj, id) in position_data"
                :key="id"
                :class="{ 'active': checkMenuActive(id) }"
                @click="selectPerson(id)"
              >
                <span class="modal-list-charge__name">{{ obj.name }}</span>
                <img
                  v-if="obj.staff_count !== 0"
                  :src="checkMenuActive(id) ? '/images/arrow-right-blue.svg' : '/images/arrow-right-grey-thin.svg'"
                >
              </li>
            </ul>
          </div>
            <div class="modal-list-charge__filter">
              <ul class="nav modal-list-charge__tabs">
                <li
                  v-for="(key) in Object.keys(staffs)"
                  :key="key"
                  class="nav-item"
                >
                  <a
                    :class="{'nav-link': true, 'active': (key === filter_active), 'disabled': (getNumberStaffs(key) === 0)}"
                    @click="selectFilter(key)"
                  >{{ filter_chars[key] }}</a>
                </li>
              </ul>
              <div class="modal-list-charge__tab">
                <div
                  v-for="item in staffs[filter_active]"
                  :key="item.id"
                  class="modal-list-charge__item"
                  @click="updateStaffData(item)"
                >
                  {{ item.name }}
                  <span class="modal-list-charge__romaji">({{ item.name_romaji }})</span>
                </div>
              </div>
          </div>
        </div>
      </template>
    </modal-action>
  </div>
</template>

<script>
  import ModalAction from '../common/ModalAction'
  import { orderBy } from 'lodash'
  import { FILTER_CHAR_POSITION_STAFF } from '../../utils/constants'

  export default {
    components: {
      ModalAction
    },
    emits: ['close', 'select'],
    data () {
      return {
        active_el: '',
        filter_active: 'all',
        position_name: '',
        filter_chars: FILTER_CHAR_POSITION_STAFF,
        staffs: {},
        position_data: {}
      }
    },
    created () {
      this.requestPositionData()
    },
    methods: {
      activeFirst: function () {
        const id = (Object.keys(this.position_data)[0])
        this.staffs = this.position_data?.[id]?.staffs || {}
        this.position_name = this.position_data?.[id].name || ''

        return id
      },
      selectPerson: function (index) {
        this.active_el = index
        const staffs = this.position_data?.[index]?.staffs || {}
        if (staffs) {
          this.position_name = this.position_data?.[index].name || ''
          this.staffs = staffs
        }
      },
      selectFilter: function (char) {
        this.filter_active = char
      },
      updateStaffData: function (item) {
        item.position_name = this.position_name
        this.$emit('select', item)
      },
      requestPositionData: function () {
        this.$store.dispatch('getPatientRegistrationFormData').then((result) => {
          const data = result?.data?.results || {}
          const positionData = data?.positions || {}
          this.position_data = orderBy(positionData, ['ordering'])
        }).catch(() => {})
      },
      checkMenuActive (id) {
        return this.active_el ? Number(this.active_el) === Number(id) : Number(id) === Number(this.activeFirst())
      },
      getNumberStaffs: function (key) {
        const unitStaffs = this.staffs ? this.staffs[key] : []
        if (unitStaffs) {
          return unitStaffs.length
        }
        return 0
      }
    }
  }
</script>
