<template>
  <select
    v-model="value"
    class="common-select"
    :disable="disable"
    @change="$emit('change', value)"
  >
    <option
      value=""
      disabled
      selected
      hidden
    >
      {{ placeholder }}
    </option>
    <option
      v-if="emptyValue && value"
      value=""
    >
      {{ emptyValue }}
    </option>
    <option
      v-for="(item, index) in options"
      :key="index"
      :value="item[keyValue]"
    >
      {{ item[keyDisplay] }}
    </option>
  </select>
</template>

<script>
  export default {
    props: {
      options: {
        type: Array,
        default: () => []
      },
      keyDisplay: {
        type: String,
        default: 'name'
      },
      keyValue: {
        type: String,
        default: 'id'
      },
      defaultValue: {
        type: [String, Number],
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      emptyValue: {
        type: String,
        default: ''
      },
      disable: {
        type: Boolean,
        default: false
      }
    },
    emits: ['change'],
    data () {
      return {
        value: this.defaultValue
      }
    },
    watch: {
      defaultValue (newValue) {
        this.value = this.defaultValue
      }
    }
  }
</script>
