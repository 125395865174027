<template>
    <div class="interview-content-consultation__block">
        <label class="header-box">
            <slot></slot>
        </label>
        <!-- part -->
        <div class="interview-content-consultation__block-part row">
            <div class="col-md-4 left-part-conent interview-input">
                <label class="block-header color-blue">1. 部位</label>
                <p v-if="errors.location" class="error-massage">{{ errors.location }}</p>
                <div class="d-flex">
                    <div class="d-block checkbox-solid col-4 p-0 m-0" v-for="item in location">
                        <div class="check-container" @click="toggleItem(item)">
                            <span class="checkbox-button" :class="{checked: item.isChecked}"></span>
                            <span class="label-text">{{item.value}}</span>
                        </div>
                        <div class="checkbox-solid col-12 mt-2 pl-2" v-for="subItem in item.options">
                            <div class="check-container" :class="{disable: !item.isChecked}"
                                 @click="toggleSubItem(item,subItem )">
                                <span class="checkbox-button" :class="{checked: subItem.isChecked}"></span>
                                <span class="label-text">{{subItem.value}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-8 right-part-conent">
                <label class="block-header color-blue">歯列番号</label>
                <div class="part-selection">
                    <div class="row noMargin">
                        <!-- UL -->
                        <div class="form-inline part-selection__ul">
                            <p class="part-selection__direction my-auto">UR</p>
                            <div class="form-group">
                                <div class="checkbox-solid checkbox-solid-group" v-for="item in dentition.UR">
                                    <div class="check-container sm-checkbox" @click="toggleCheckbox(item)">
                                        <span :class="['checkbox-button' , {checked: item.isChecked}]"></span>
                                        <span class="label-text">{{item.value}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- UR -->
                        <div class="form-inline part-selection__ur">
                            <div class="form-group">
                                <div class="checkbox-solid checkbox-solid-group" v-for="item in dentition.UL">
                                    <div class="check-container sm-checkbox" @click="toggleCheckbox(item)">
                                        <span :class="['checkbox-button' , {checked: item.isChecked}]"></span>
                                        <span class="label-text">{{item.value}}</span>
                                    </div>
                                </div>
                            </div>
                            <p class="part-selection__direction my-auto">UL</p>
                        </div>
                    </div>
                    <div class="row noMargin">
                        <!-- LL -->
                        <div class="form-inline part-selection__ll">
                            <p class="part-selection__direction my-auto">LR</p>
                            <div class="form-group">
                                <div class="checkbox-solid checkbox-solid-group" v-for="item in dentition.LR">
                                    <div class="check-container sm-checkbox" @click="toggleCheckbox(item)">
                                        <span class="label-text">{{item.value}}</span>
                                        <span :class="['checkbox-button' , {checked: item.isChecked}]"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- LR -->
                        <div class="form-inline part-selection__lr">
                            <div class="form-group">
                                <div class="checkbox-solid checkbox-solid-group" v-for="item in dentition.LL">
                                    <div class="check-container sm-checkbox" @click="toggleCheckbox(item)">
                                        <span class="label-text">{{item.value}}</span>
                                        <span :class="['checkbox-button' , {checked: item.isChecked}]"></span>
                                    </div>
                                </div>
                            </div>
                            <p class="part-selection__direction my-auto">LL</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- symptom -->
        <div class="interview-content-consultation__block-symptom">
            <div class="form-group">
                <label class="block-header color-blue">2. 症状</label>
                <p v-if="errors.symptoms" class="error-massage">{{ errors.symptoms }}</p>
                <div class="row">
                    <div class="col-md-4">
                        <radio-common
                            v-model="symptoms"
                            @input="handleSymptom"
                            :name="'symptoms'+index"
                            className="sm-checkbox check-container"
                            label="ガタガタした歯並びや八重歯（叢生）"
                            val="ガタガタした歯並びや八重歯（叢生）">
                        </radio-common>
                        <radio-common
                            v-model="symptoms"
                            @input="handleSymptom"
                            :name="'symptoms'+index"
                            className="sm-checkbox check-container"
                            label="出っ歯（上顎前突）"
                            val="出っ歯（上顎前突）">
                        </radio-common>
                        <radio-common
                            v-model="symptoms"
                            @input="handleSymptom"
                            :name="'symptoms'+index"
                            className="sm-checkbox check-container"
                            label="受け口（反対咬合）"
                            val="受け口（反対咬合）">
                        </radio-common>
            <p class="symptoms-comment">
              ※症状が強い場合は適応にならない場合があります。
            </p>
                        <radio-common
                            v-model="symptoms"
                            @input="handleSymptom"
                            :name="'symptoms'+index"
                            className="sm-checkbox check-container"
                            label="すきっ歯（空隙歯列）"
                            val="すきっ歯（空隙歯列）">
                        </radio-common>
                        <radio-common
                            v-model="symptoms"
                            @input="handleSymptom"
                            :name="'symptoms'+index"
                            className="sm-checkbox check-container"
                            label="前歯が閉じない（開咬）"
                            val="前歯が閉じない（開咬）">
                        </radio-common>
            <p class="symptoms-comment">
              ※症状が強い場合は適応にならない場合があります。
            </p>
                        <radio-common
                            v-model="symptoms"
                            @input="handleSymptom"
                            :name="'symptoms'+index"
                            className="sm-checkbox check-container"
                            label="中心がずれている（正中不一致）"
                            val="中心がずれている（正中不一致）">
                        </radio-common>
            <p class="symptoms-comment">
              ※症状が強い場合は適応にならない場合があります。
            </p>
                    </div>
                    <div class="col-md-8">
                        <radio-common
                            v-model="symptoms"
                            @input="handleSymptom"
                            :name="'symptoms'+index"
                            className="sm-checkbox check-container"
                            label=" 噛み合わせが深い（過蓋咬合）"
                            val=" 噛み合わせが深い（過蓋咬合）">
                        </radio-common>
                        <radio-common
                            v-model="symptoms"
                            @input="handleSymptom"
                            :name="'symptoms'+index"
                            className="sm-checkbox check-container"
                            label="スマイル時に上顎の歯肉が露出している（ガミースマイル）"
                            val="スマイル時に上顎の歯肉が露出している（ガミースマイル）">
                        </radio-common>
            <p class="symptoms-comment">
              ※本来であればスマイル時に、上顎の歯肉はギリギリ見えないのが理想的。
            </p>
                        <radio-common
                            v-model="symptoms"
                            @input="handleSymptom"
                            :name="'symptoms'+index"
                            className="sm-checkbox check-container"
                            label="ロゴボ"
                            val="ロゴボ">
                        </radio-common>
            <p class="symptoms-comment">
              ※矯正歯科領域では、一般的に上下顎前突、上顎前突、下顎骨後退症など、口元が突出している状態のことが言われるケースが多い。 <br>
              ※歯科矯正学ではこのような表現は存在しません。 <br>
              ※症状が強い場合は適応にならない場合があります。
            </p>
                        <div class="symptom-other">
                            <radio-common
                                v-model="symptoms"
                                @input="handleSymptom"
                                :name="'symptoms'+index"
                                className="sm-checkbox check-container"
                                label="その他"
                                val="その他">
                            </radio-common>
                            <div :class="['other-input', diableOtherInput]">
                                <input v-model="symptoms_other" class="form-control mt-2" type="text" name="" placeholder="その他の場合ご記入ください">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- comments -->
        <div class="interview-content-consultation__block-comments">
            <label class="block-header color-blue">3. コメント</label>
            <div class="form-group">
                <label class="fs-12">気になる部位・症状について患者様の主訴となるコメント</label>
                <textarea class="form-control" rows="3" v-model="comment"></textarea>
            </div>
        </div>
    </div>

</template>

<script>
    import CheckboxCommon from '../template/CheckboxCommon'
    import RadioCommon from '../template/RadioCommon'

    const KEY_DENTITION = ['UL', 'UR', 'LL', 'LR']

    export default {
        name: "ItemConsultation",
        props: ['index'],
        components: {
            CheckboxCommon,
            RadioCommon
        },
        data() {
            return {
                location: [
                    {
                        value: "全体",
                        label: "全体",
                        isChecked: false,
                        options: [
                            {value: "全体", isChecked: false, label: "全体"},
                            {value: "右側", isChecked: false, label: "右側"},
                            {value: "左側", isChecked: false, label: "左側"}
                        ]
                    },
                    {
                        value: "上顎",
                        label: "上顎",
                        isChecked: false,
                        options: [
                            {value: "全体", isChecked: false, label: "全体"},
                            {value: "右側", isChecked: false, label: "右側"},
                            {value: "左側", isChecked: false, label: "左側"}
                        ]
                    },
                    {
                        value: "下顎",
                        label: "下顎",
                        isChecked: false,
                        options: [
                            {value: "全体", isChecked: false, label: "全体"},
                            {value: "右側", isChecked: false, label: "右側"},
                            {value: "左側", isChecked: false, label: "右側"}
                        ]
                    }
                ],
                symptoms: [],
                symptomOther: '',
                dentition: {
                    UL: [
                        {value: "1", isChecked: false},
                        {value: "2", isChecked: false},
                        {value: "3", isChecked: false},
                        {value: "4", isChecked: false},
                        {value: "5", isChecked: false},
                        {value: "6", isChecked: false},
                        {value: "7", isChecked: false},
                        {value: "8", isChecked: false},
                    ],
                    LL: [
                        {value: "1", isChecked: false},
                        {value: "2", isChecked: false},
                        {value: "3", isChecked: false},
                        {value: "4", isChecked: false},
                        {value: "5", isChecked: false},
                        {value: "6", isChecked: false},
                        {value: "7", isChecked: false},
                        {value: "8", isChecked: false},
                    ],
                    UR: [
                        {value: "8", isChecked: false},
                        {value: "7", isChecked: false},
                        {value: "6", isChecked: false},
                        {value: "5", isChecked: false},
                        {value: "4", isChecked: false},
                        {value: "3", isChecked: false},
                        {value: "2", isChecked: false},
                        {value: "1", isChecked: false},
                    ],
                    LR: [
                        {value: "8", isChecked: false},
                        {value: "7", isChecked: false},
                        {value: "6", isChecked: false},
                        {value: "5", isChecked: false},
                        {value: "4", isChecked: false},
                        {value: "3", isChecked: false},
                        {value: "2", isChecked: false},
                        {value: "1", isChecked: false},
                    ]
                },
                symptoms_other: '',
                comment: '',
                errors: {
                    symptoms: '',
                    location: ''
                }
            }
        },
        created: function () {
            this.loadStateToData();
        },
        methods: {
            loadStateToData: function() {
                const consultation_content = this.getConsultationContent;
                if (typeof consultation_content.points_symptoms === 'object') {
                    const point = consultation_content.points_symptoms[this.index];
                    if (typeof point === 'undefined') {
                        return
                    }
                    this.symptoms = point.symptoms;
                    this.symptoms_other = point.symptoms_other;
                    this.comment = point.consultation_content;

                    // part detail
                    const selected_option = point.part_detail ? point.part_detail.substr(point.part_detail.length - 2) : '';
                    this.location.forEach(function(item) {
                        if (item.value === point.part) {
                            item.isChecked = true;
                            item.options.forEach(function(option) {
                                if (option.value === selected_option) {
                                    option.isChecked = true;
                                }
                            })
                        }
                    });

                    // dentition_number
                    const vm = this;
                    const dentition_number = point.dentition_number;
                    dentition_number.forEach(function(item) {
                        const dentition = item.split("-");
                        if (dentition.length) {
                            vm.dentition[dentition[0]].find(obj => obj.value == dentition[1]).isChecked = true;
                        }
                    });
                }
            },
            toggleItem: function (item) {
                item.isChecked = !item.isChecked
                this.location.forEach(function (location) {
                    if (location.value !== item.value) {
                        location.isChecked = false
                        location.options.forEach(function (option) {
                            option.isChecked = false
                        })
                    }
                })
            },
            toggleSubItem: function (item, subItem) {
                subItem.isChecked = !subItem.isChecked
                item.options.forEach(function (option) {
                    if (option.value !== subItem.value) {
                        option.isChecked = false
                    }
                })
            },
            getResultAnswer() {
                let interview = {
                    part: "",
                    part_detail: "",
                    dentition_number: [],
                    symptoms: '',
                    symptoms_other: "",
                    consultation_content: ""
                };
                this.location.forEach(function (location) {
                    if (location.isChecked) {
                        interview.part = location.value;
                        location.options.forEach(function (option) {
                            if (option.isChecked) {
                                interview.part_detail = option.value
                            }
                        })
                    }
                });

                interview.symptoms = this.symptoms;
                interview.symptoms_other = this.symptoms_other;
                interview.consultation_content = this.comment;

                let dentition_array = []
                let dentition = this.dentition
                KEY_DENTITION.forEach(function (key) {
                    dentition[key].forEach(function (dentition) {
                        if (dentition.isChecked) {
                            dentition_array.push(key + '-' + dentition.value)
                        }
                    })
                })
                interview.dentition_number = dentition_array
                return interview;
            },
            toggleCheckbox(item) {
                item.isChecked = !item.isChecked
            },
            validation: function () {
                this.validateSymptom()
                this.validationLocation()

                for (const index in this.errors) {
                    if (this.errors[index]) {
                        return false
                    }
                }

                return true
            },
            validationLocation: function () {
                let isValid = false
                this.location.forEach(function (location) {
                    if (location.isChecked) {
                        isValid = true
                    }
                    location.options.forEach(function (option) {
                        if (option.isChecked) {
                            isValid = true
                        }
                    })
                })
                if (isValid) {
                    this.errors.location = ''
                } else {
                    this.errors.location = this.$t('validation.interview.question')
                }
            },
            validateSymptom: function () {
                if (this.symptoms.length) {
                    this.errors.symptoms = ''
                } else {
                    this.errors.symptoms = this.$t('validation.interview.question')
                }
            },
            handleSymptom: function (value) {
                if (value.length) {
                    this.errors.symptoms = ''
                }
            }
        },
        computed: {
            getConsultationContent: function() {
                return this.$store.getters.get_consultation_content;
            },
            diableOtherInput: function () {
                return {
                    'disable-other-answer': !this.symptoms.includes('その他')
                }
            },
        }
    }
</script>

<style scoped>

</style>
