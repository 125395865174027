var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-create-reservation" },
    [
      _c("modal-action", {
        class: {
          "modal-create-reservation__disabled-confirm": !_vm.is_valid_submit,
          "d-none": !_vm.is_show_modal_create
        },
        attrs: { title: _vm.$t("text.new_reservation") },
        on: {
          close: function($event) {
            return _vm.$emit("close")
          },
          confirm: _vm.handleSubmit
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("div", { staticClass: "modal-create-reservation__body" }, [
                  _c("div", { staticClass: "modal-create-reservation__info" }, [
                    _c(
                      "div",
                      { staticClass: "modal-create-reservation__header" },
                      [
                        _c(
                          "div",
                          { staticClass: "modal-create-reservation__row" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "modal-create-reservation__label"
                              },
                              [_vm._v(_vm._s(_vm.$t("common.date_time")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "modal-create-reservation__input datepicker datepicker--header-calender"
                              },
                              [
                                _c("date-picker", {
                                  staticClass: "modal-create-reservation__date",
                                  attrs: {
                                    readonly: "readonly",
                                    wrap: false,
                                    config: _vm.dpOptions
                                  },
                                  on: { input: _vm.setDateTo },
                                  model: {
                                    value: _vm.data_reservation.date,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.data_reservation,
                                        "date",
                                        $$v
                                      )
                                    },
                                    expression: "data_reservation.date"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "modal-create-reservation__time"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "click-outside",
                                            rawName: "v-click-outside",
                                            value: _vm.clickOutsideEvent,
                                            expression: "clickOutsideEvent"
                                          }
                                        ],
                                        class: [
                                          "modal-create-reservation__wrapper",
                                          {
                                            "modal-create-reservation__wrapper--active":
                                              _vm.show_modal_time_setting ===
                                              "start-time"
                                          }
                                        ]
                                      },
                                      [
                                        _c("span", {
                                          class: [
                                            "form-control time-input",
                                            {
                                              "time-input--placeholder": !_vm
                                                .data_reservation.time_start
                                            }
                                          ],
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.data_reservation.time_start ||
                                                "--:--"
                                            )
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.showModalTimeSetting(
                                                "start-time"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _vm.show_modal_time_setting ===
                                        "start-time"
                                          ? _c("modal-time-setting", {
                                              attrs: {
                                                "default-value":
                                                  _vm.data_reservation
                                                    .time_start
                                              },
                                              on: {
                                                "set-time": _vm.updateStartTime
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "modal-create-reservation__connection"
                                      },
                                      [_vm._v("〜")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "click-outside",
                                            rawName: "v-click-outside",
                                            value: _vm.clickOutsideEvent,
                                            expression: "clickOutsideEvent"
                                          }
                                        ],
                                        class: [
                                          "modal-create-reservation__wrapper",
                                          {
                                            "modal-create-reservation__wrapper--active":
                                              _vm.show_modal_time_setting ===
                                              "end-time"
                                          }
                                        ]
                                      },
                                      [
                                        _c("span", {
                                          class: [
                                            "form-control time-input",
                                            {
                                              "time-input--placeholder": !_vm
                                                .data_reservation.time_end
                                            }
                                          ],
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.data_reservation.time_end ||
                                                "--:--"
                                            )
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.showModalTimeSetting(
                                                "end-time"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _vm.show_modal_time_setting ===
                                        "end-time"
                                          ? _c("modal-time-setting", {
                                              attrs: {
                                                "default-value":
                                                  _vm.data_reservation.time_end
                                              },
                                              on: {
                                                "set-time": _vm.updateEndTime
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "modal-create-reservation__row modal-create-reservation__row-block"
                          },
                          [
                            _c("span", {
                              staticClass: "modal-create-reservation__label"
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "modal-create-reservation__input"
                              },
                              [
                                _c("Checkbox", {
                                  attrs: {
                                    label: _vm.$t("text.block_frame_label"),
                                    value: true
                                  },
                                  on: { input: _vm.toggleBlock }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-create-reservation__content" },
                      [
                        _c(
                          "div",
                          { staticClass: "modal-create-reservation__row" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "modal-create-reservation__label"
                              },
                              [_vm._v(_vm._s(_vm.$t("common.name")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "modal-create-reservation__input"
                              },
                              [
                                _c("input-search", {
                                  staticClass: "modal-create-reservation__name",
                                  attrs: {
                                    "default-value": _vm.keyword_search_name,
                                    disabled:
                                      _vm.data_reservation.blocked ||
                                      _vm.data_reservation.patient_id !== 0,
                                    "icon-disabled": _vm.data_reservation
                                      .blocked
                                      ? undefined
                                      : "/images/icon-close.svg",
                                    placeholder: _vm.$t(
                                      "text.select_patient_placeholder"
                                    ),
                                    type: "search-name"
                                  },
                                  on: { search: _vm.searchNamePatient }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "modal-create-reservation__label-id"
                                  },
                                  [_vm._v(_vm._s(_vm.$t("common.ID")))]
                                ),
                                _vm._v(" "),
                                _c("input-search", {
                                  staticClass: "modal-create-reservation__id",
                                  attrs: {
                                    "default-value": _vm.keyword_search_id,
                                    disabled:
                                      _vm.data_reservation.blocked ||
                                      _vm.data_reservation.patient_id !== 0,
                                    "icon-disabled": _vm.data_reservation
                                      .blocked
                                      ? undefined
                                      : "/images/icon-close.svg"
                                  },
                                  on: { search: _vm.searchIDPatient }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "modal-create-reservation__row modal-create-reservation__row-type"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "modal-create-reservation__label"
                              },
                              [_vm._v(_vm._s(_vm.$t("text.reservation_type")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "modal-create-reservation__input modal-create-reservation__input-type"
                              },
                              [
                                _vm._l(_vm.reservation_types, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.id,
                                      staticClass:
                                        "modal-list-reservation-type__item modal-create-reservation__selected-item"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "modal-list-reservation-type__text"
                                        },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "modal-list-reservation-type__color modal-create-reservation__selected-color",
                                            style: [
                                              item.duty
                                                ? {
                                                    backgroundColor:
                                                      item.duty.color
                                                  }
                                                : ""
                                            ]
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "modal-list-reservation-type__name"
                                            },
                                            [_vm._v(_vm._s(item.duty_name))]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "modal-list-reservation-type__value modal-create-reservation__selected-value"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "modal-list-reservation-type__time"
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(item.take_time) +
                                                  "分\n                      "
                                              ),
                                              _c("img", {
                                                attrs: {
                                                  src: "/images/icon-close.svg"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.removeReservationTypeSelected(
                                                      index
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _c("button-common", {
                                  class: {
                                    "modal-create-reservation__btn-add": true,
                                    "modal-create-reservation__btn-disabled":
                                      _vm.data_reservation.blocked
                                  },
                                  on: { click: _vm.showModalReservationType },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "content",
                                      fn: function() {
                                        return [
                                          _vm.data_reservation.blocked
                                            ? _c("img", {
                                                attrs: {
                                                  src:
                                                    "/images/icon-plus-gray.svg"
                                                }
                                              })
                                            : _c("img", {
                                                attrs: {
                                                  src:
                                                    "/images/icon-plus-v2.svg"
                                                }
                                              }),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "reservation.add_reservation_type"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                })
                              ],
                              2
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "modal-create-reservation__row modal-create-reservation__row-charge"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "modal-create-reservation__label"
                              },
                              [_vm._v(_vm._s(_vm.$t("common.responsible")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "modal-create-reservation__input modal-create-reservation__input-charge"
                              },
                              [
                                _vm._l(_vm.person_in_charges, function(
                                  staff,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: staff.id,
                                      staticClass:
                                        "modal-list-reservation-type__item modal-create-reservation__selected-item"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "modal-list-reservation-type__text modal-list-reservation-type__text--person-in-charge"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "modal-create-reservation__position-name"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(staff.position_name)
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(staff.name) +
                                              "\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "modal-list-reservation-type__value modal-create-reservation__selected-value"
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: "/images/icon-close.svg"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.removePersonInCharge(
                                                  index
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _c("button-common", {
                                  class: {
                                    "modal-create-reservation__btn-add": true,
                                    "modal-create-reservation__btn-disabled":
                                      _vm.data_reservation.blocked
                                  },
                                  on: { click: _vm.showModalPersonCharge },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "content",
                                      fn: function() {
                                        return [
                                          _vm.data_reservation.blocked
                                            ? _c("img", {
                                                attrs: {
                                                  src:
                                                    "/images/icon-plus-gray.svg"
                                                }
                                              })
                                            : _c("img", {
                                                attrs: {
                                                  src:
                                                    "/images/icon-plus-v2.svg"
                                                }
                                              }),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t("text.add_charge")
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                })
                              ],
                              2
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "modal-create-reservation__row modal-create-reservation__row-brand"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "modal-create-reservation__label"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("patient_page.brand_type"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.data_reservation.patient_id
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "modal-create-reservation__input modal-create-reservation__brand"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.brand_text || "") +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-create-reservation__note" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("common.note")))]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data_reservation.memo,
                          expression: "data_reservation.memo"
                        }
                      ],
                      attrs: {
                        placeholder: _vm.$t(
                          "reservation.reservation_note_placeholder"
                        )
                      },
                      domProps: { value: _vm.data_reservation.memo },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.data_reservation,
                            "memo",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "confirm_button_content",
            fn: function() {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.isDetectAutoFrameOperation
                        ? _vm.$t("new_text.button.next")
                        : _vm.$t("text.register_reservation")
                    ) +
                    "\n    "
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _vm.is_show_modal_search_name
        ? _c("modal-search-name-patient", {
            attrs: { keyword: _vm.keyword_search_name },
            on: {
              select: _vm.setPatient,
              close: function($event) {
                _vm.is_show_modal_search_name = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_search_id
        ? _c("modal-search-id-patient", {
            attrs: { keyword: _vm.keyword_search_id },
            on: {
              select: _vm.setPatient,
              close: function($event) {
                _vm.is_show_modal_search_id = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_list_reservation_type
        ? _c("modal-list-reservation-type", {
            attrs: { "reservation-type-selected": _vm.reservation_types },
            on: {
              close: function($event) {
                _vm.is_show_modal_list_reservation_type = false
              },
              select: _vm.selectReservationType
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_person_in_charge
        ? _c("modal-person-in-charge", {
            on: {
              close: function($event) {
                _vm.is_show_modal_person_in_charge = false
              },
              select: _vm.selectPersonInCharge
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_confirm_first_reservation
        ? _c("modal-confirm", {
            attrs: {
              title: _vm.$t(
                "patient_page.first_reservation_modal_title_insert"
              ),
              text: _vm.$t("patient_page.first_reservation_confirmation_insert")
            },
            on: {
              no: function($event) {
                _vm.is_show_modal_confirm_first_reservation = false
              },
              yes: _vm.handleNextStep
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_operation_reason
        ? _c("modal-operation-reason", {
            attrs: {
              "is-block": !!_vm.data_reservation.blocked,
              resources: _vm.resources
            },
            on: { back: _vm.handleBack, confirm: _vm.submitCreateReservation }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }