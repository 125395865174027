import common from './common'
import order from './order'
import patient from './patient'
import reservation from './reservation'
import setting from './setting'
import staff from './staff'
import allocation from './allocation'

const validation = {
  allocation,
  common,
  order,
  patient,
  reservation,
  setting,
  staff
}

export default validation
