var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "handmade-select",
      attrs: { tabindex: 0 },
      on: {
        blur: function($event) {
          _vm.open = false
        }
      }
    },
    [
      _c(
        "div",
        {
          class: [
            "handmade-select__selected",
            { "handmade-select__selected--open": _vm.open },
            { "handmade-select__selected--disable": _vm.disable }
          ],
          on: { click: _vm.handleOpen }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.renderSelected(_vm.value) || _vm.placeholder) +
              "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "handmade-select__options",
            { "handmade-select__options--hide": !_vm.open }
          ]
        },
        [
          _vm.emptyValue !== "" && _vm.value !== ""
            ? _c(
                "div",
                {
                  staticClass: "handmade-select__options__item",
                  on: {
                    click: function($event) {
                      _vm.open = false
                      _vm.$emit("input", "")
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(_vm.emptyValue) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.options, function(option, i) {
            return _c(
              "div",
              {
                key: i,
                class: [
                  "handmade-select__options__item",
                  {
                    "handmade-select__options__item--selected":
                      option[_vm.keyValue] === _vm.value
                  }
                ],
                on: {
                  click: function($event) {
                    _vm.open = false
                    _vm.$emit("input", option[_vm.keyValue])
                  }
                }
              },
              [_vm._v("\n      " + _vm._s(option[_vm.keyDisplay]) + "\n    ")]
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }