var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.className] },
    [
      _c("sub-question", {
        ref: "sub_question",
        attrs: {
          question: _vm.question,
          options: _vm.option_yes_no,
          checked: _vm.select_option,
          "is-required": _vm.invalid_age
        },
        on: {
          "handle-answer": function($event) {
            return _vm.handleAnswer($event)
          }
        }
      }),
      _vm._v(" "),
      _c("div", { class: _vm.handleChildAnswerClass }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "color-bold" }, [
            _vm._v("よく飲まれるお酒：")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.type_sake,
                expression: "type_sake"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.type_sake },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.type_sake = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "color-bold" }, [_vm._v("一日量：")]),
          _vm._v(" "),
          _c("div", { staticClass: "inline-input-dose" }, [
            _c(
              "div",
              {
                staticClass: "select-option form-inline justify-content-between"
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.content1,
                      expression: "content1"
                    }
                  ],
                  staticClass: "form-control col-md-4",
                  attrs: { type: "text" },
                  domProps: { value: _vm.content1 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.content1 = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v("mlまたは")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.content2,
                      expression: "content2"
                    }
                  ],
                  staticClass: "form-control col-md-4",
                  attrs: { type: "text" },
                  domProps: { value: _vm.content2 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.content2 = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v("合位")])
              ]
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }