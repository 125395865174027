import { render, staticRenderFns } from "./ModalConfirmDeleteAllocation.vue?vue&type=template&id=05651aa8&"
import script from "./ModalConfirmDeleteAllocation.vue?vue&type=script&lang=js&"
export * from "./ModalConfirmDeleteAllocation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/medusa/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05651aa8')) {
      api.createRecord('05651aa8', component.options)
    } else {
      api.reload('05651aa8', component.options)
    }
    module.hot.accept("./ModalConfirmDeleteAllocation.vue?vue&type=template&id=05651aa8&", function () {
      api.rerender('05651aa8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/reservation/ModalConfirmDeleteAllocation.vue"
export default component.exports