var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("modal-action", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.show_modal_operation_reason,
            expression: "!show_modal_operation_reason"
          }
        ],
        staticClass: "delete-allocation",
        attrs: {
          title: _vm.$t("objects.reservation.text.delete_allocation_title", {
            name: _vm.allocationDelete.column_name
          }),
          "is-show-close-button": false
        },
        scopedSlots: _vm._u([
          {
            key: "footer",
            fn: function() {
              return [
                _c("button-common", {
                  staticClass: "cancel-reservation__submit",
                  on: { click: _vm.handleCancel },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("new_text.button.delete")) +
                              "\n        "
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _vm._v(" "),
                _c("button-common", {
                  staticClass: "cancel-reservation__close",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("new_text.button.no")) +
                              "\n        "
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _vm.show_modal_operation_reason
        ? _c("modal-operation-allocation-reason", {
            attrs: {
              "is-update": false,
              resources: _vm.operation_allocation_reason_resources
            },
            on: {
              back: function($event) {
                _vm.show_modal_operation_reason = false
                _vm.$emit("close")
              },
              confirm: _vm.handleAfterInputOperationReason
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }