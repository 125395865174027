var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.className] }, [
    _c("strong", [_c("p", [_vm._v(_vm._s(_vm.question))])]),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.handleChildAnswerClass },
      [
        _c("checkbox-common", {
          attrs: { label: "仰向け", val: "仰向け" },
          model: {
            value: _vm.back,
            callback: function($$v) {
              _vm.back = $$v
            },
            expression: "back"
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("checkbox-common", {
              attrs: { label: "うつ伏せ", val: "うつ伏せ" },
              model: {
                value: _vm.prone,
                callback: function($$v) {
                  _vm.prone = $$v
                },
                expression: "prone"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row checkbox-multi-inline form-inline" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-8 radio-multi-inline radio-group h-align"
                  },
                  _vm._l(_vm.head_options, function(item, key) {
                    return _c(
                      "div",
                      {
                        key: key,
                        class: ["check-container", "ml-0"],
                        on: {
                          click: function($event) {
                            return _vm.handleHeadClick(item)
                          }
                        }
                      },
                      [
                        _c("span", {
                          class: ["radio-button", _vm.handleHeadClass(item)]
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "label-text" }, [
                          _vm._v(_vm._s(item))
                        ])
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("checkbox-common", {
              attrs: { label: "横向き", val: "横向き" },
              model: {
                value: _vm.sideways,
                callback: function($$v) {
                  _vm.sideways = $$v
                },
                expression: "sideways"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row checkbox-multi-inline form-inline" },
              [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-8 radio-multi-inline radio-group h-align"
                  },
                  _vm._l(_vm.head_options, function(item, key) {
                    return _c(
                      "div",
                      {
                        key: key,
                        class: ["check-container", "ml-0"],
                        on: {
                          click: function($event) {
                            return _vm.handleDirectionClick(item)
                          }
                        }
                      },
                      [
                        _c("span", {
                          class: [
                            "radio-button",
                            _vm.handleDirectionClass(item)
                          ]
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "label-text" }, [
                          _vm._v(_vm._s(item))
                        ])
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group pb-4" },
          [
            _c("checkbox-common", {
              attrs: { label: "様々寝相が変わる", val: "様々寝相が変わる" },
              model: {
                value: _vm.various,
                callback: function($$v) {
                  _vm.various = $$v
                },
                expression: "various"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3" }, [
      _c("span", [_vm._v("首の向き")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3" }, [
      _c("span", [_vm._v("向き")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }