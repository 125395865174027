const reservation = {
  attributes: {
    allocation_id: 'Allocation ID',
    clinics_id: 'Clinic ID',
    ended_from: 'End date and time',
    id: 'ID',
    patient_id: 'Patient ID',
    started_to: 'Start date and time'
  },
  invalid_date: 'Set the end date after the start date',
  memo_maxlength: 'The string is too long. \nYou can enter up to 65,535 single-byte characters',
  must_be_date: 'Must be date',
  required: 'Please enter the {attribute}',
  reservation_canceled: 'The reservation has already been cancelled',
  reservations_already_exist: 'Reservations already exist'
}

export default reservation
