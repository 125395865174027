<template>
    <div class="page page-print">
        <main class="patient-detail-page patient-detail-print">
            <div class="col-12 main-content">
                <section class="content">
                    <div class="content-wrapper content-wrapper--interview-print">
                        <div class="row">
                            <div class="col-7 offset-5">
                                <InterviewCheckList></InterviewCheckList>
                            </div>
                            <div class="col-md-12 patient-detail-left" id="patient-detail-print-area" v-if="!is_loading">
                                <InterviewConfirmation :patient_info="patient_info" v-if="is_completed"></InterviewConfirmation>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    </div>
</template>

<script>
    import helpers from '../../utils/helpers.js'
    import InterviewInput from '../../components/patient/interview/input/InterviewInput'
    import InterviewConfirmation from '../../components/patient/interview/InterviewConfirmation'
    import InterviewCheckList from '../../components/patient/interview/print/InterviewCheckListPrint'

    export default {
        name: "InterviewPrint",
        components: {
            InterviewInput,
            InterviewConfirmation,
            InterviewCheckList
        },
        data() {
            return {
                is_clinics_dmr: 0,
                patient_id: this.$route.params.id,
                patient_info: null,
                is_loading: true,
                is_completed: 0
            }
        },
        computed: {
            patientInfo() {
                return this.$store.getters.get_patientInformation
            }
        },
        created() {
            this.$store.commit('set_loading', true);
            if (localStorage.getItem('is_clinics_dmr')) {
                this.is_clinics_dmr = localStorage.getItem('is_clinics_dmr');
            }
            this.$store.dispatch('getPatientInterviewData', this.$route.params.id).then((result) => {
                const results = helpers.safeGet(result.data, 'results');
                this.patient_info = results.patient_info;
                this.is_loading = false;
                this.is_completed = results.interview_info.is_completed;
                this.$store.commit('set_loading', false);
            }).catch((error) => {
                this.$store.commit('set_loading', false);
            });
        }
    }
</script>
