<template>
  <div class="login-form content-wrapper">
    <div class="login-form__small-screen">
      <loading />
      <div class="d-flex justify-content-center">
        <div class="brand_logo_container">
          <img
            src="/images/logo-header-v2.svg"
            class="brand_logo"
            height="160"
          >
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="login-form__title">
          <h2 class="title">
            {{ $t('new_text.login_title') }}
          </h2>
        </div>
      </div>
      <form
        class="login-form"
        autocomplete="off"
        method="post"
        @submit.prevent="login"
      >
        <div class="login-form__container">
          <div class="form-group">
            <label
              class="login-form__label"
              for="staff_id"
            >{{ $t('new_text.user_name') }}</label>
            <input
              id="staff_id"
              v-model="staff_id"
              class="form-control login-form__input"
              :placeholder="$t('new_text.placeholder.user_name')"
            >
            <validation-errors
              v-if="errors.staff_id"
              :errors="errors.staff_id"
              :text-left="true"
            />
          </div>
          <div class="form-group">
            <label
              class="login-form__label"
              for="password"
            >{{ $t('new_text.password') }}</label>
            <input
              id="password"
              v-model="password"
              type="password"
              class="form-control login-form__input"
              :placeholder="$t('new_text.placeholder.password')"
            >
            <validation-errors
              v-if="errors.password"
              :errors="errors.password"
              :text-left="true"
            />
          </div>
          <div class="form-group">
            <Checkbox
              v-model="remember"
              :label="$t('new_text.remember')"
            />
          </div>
          <validation-errors
            v-if="errors.common_validation"
            :errors="errors.common_validation"
            :text-left="true"
          />
        </div>
        <div class="mt-3 mb-4 login-form_container">
          <button
            type="submit"
            name="button"
            class="btn login_btn"
          >
            {{ $t('new_text.login') }}
          </button>
        </div>
        <div class="login-form__switch-mode">
          <span class="login-form__switch-mode__title">
            {{ $t('new_text.switch_mode_sp_title') }}
          </span>
          <div class="login-form__switch-mode__action">
            <button
              type="button"
              class="login-form__switch-mode__button"
              @click="changeLayoutMode(layout_mode.PC)"
            >
              {{ $t('new_text.switch_mode_pc') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import Checkbox from '../components/common/Checkbox'
  import loading from '../components/Loading'
  import { SCREEN_WIDTH_MEDIUM, FORBIDDEN_CODE } from '../utils/constants'
  import { get } from 'lodash'
  import { LayoutModeMixin } from '../mixins/layout-mode'

  export default {
    components: {
      Checkbox,
      loading
    },
    mixins: [LayoutModeMixin],
    data () {
      return {
        staff_id: '',
        password: '',
        error: false,
        success: false,
        remember: false,
        errors: {
          staff_id: '',
          password: '',
          common_validation: ''
        }
      }
    },
    computed: {
      isLabDesigner () {
        return this.$store.getters.isLabDesigner
      },
      isInstructionAdmin () {
        return this.$store.getters.isInstructionAdmin
      }
    },
    watch: {
      window_width (newWidth) {
        if (newWidth > SCREEN_WIDTH_MEDIUM && !this.currentLayoutMode) {
          return this.$router.push({ name: 'auth.login' })
        }
      },
      currentLayoutMode (newMode) {
        if (newMode === this.layout_mode.PC) {
          return this.$router.push({ name: 'auth.login' })
        }
      }
    },
    created () {
      document.title = this.$t('new_text.login').toString()
      this.$store.commit('set_loading', false)
      if (
        this.currentLayoutMode === this.layout_mode.PC ||
        (!this.currentLayoutMode && this.window_width > SCREEN_WIDTH_MEDIUM)
      ) {
        this.$router.push({ name: 'auth.login' })
      }
    },
    methods: {
      async login () {
        this.$store.commit('set_loading', true)
        const vm = this
        vm.errors = {
          staff_id: '',
          password: '',
          common_validation: ''
        }

        const data = {
          staff_id: vm.staff_id,
          password: vm.password,
          remember: vm.remember
        }

        return vm.$store.dispatch('login', { data }).then((result) => {
          vm.$store.commit('set_loading', false)
          if (!this.currentLayoutMode) {
            this.changeLayoutMode(this.layout_mode.SP)
          }

          if (typeof result.data.results.first_login !== 'undefined' && result.data.results.first_login) {
            return vm.$router.push({
              name: 'password.initial',
              params: { id: vm.staff_id, token: result.data.results.token }
            })
          }

          if (vm.isLabDesigner || vm.isInstructionAdmin) {
            return vm.$router.push({ name: 'order.search' })
          }

          return vm.$router.push({ name: 'login.top' })
        }).catch((error) => {
          const response = get(error, 'response') || {}
          const { response: { data: { errors } } } = error
          vm.$store.commit('set_loading', false)
          if (Array.isArray(errors)) {
            vm.errors.staff_id = errors.includes('STAFF_ID_REQUEST')
              ? [vm.$t('new_validation.common.required', { attribute: vm.$t('new_text.user_name') })] : ''
            vm.errors.password = errors.includes('PASSWORD_REQUEST')
              ? [vm.$t('new_validation.common.required', { attribute: vm.$t('new_text.password') })] : ''

            if (errors.includes('AUTH_FAILED')) {
              vm.errors.common_validation = [vm.$t('new_message.login_failed')]
            } else if (errors.includes('ACCOUNT_DELETED')) {
              vm.errors.common_validation = [vm.$t('new_message.account_is_deleted')]
            } else if (errors.includes('ACCOUNT_DEACTIVATED')) {
              vm.errors.common_validation = [vm.$t('new_message.account_is_deactivated')]
            }
          }

          if (response.status === FORBIDDEN_CODE) {
            this.errors.common_validation = [vm.$t('new_message.login_forbidden')]
          }
        })
      }
    }
  }
</script>
