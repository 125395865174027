<template>
  <modal-action
    class="print-calendar"
    @close="$emit('close')"
  >
    <template #body>
      <div class="print-calendar__content">
        <div>{{ $t('objects.reservation.text.print_visit_shedule') }}</div>
        <div>{{ $t('objects.reservation.text.include_memo') }}</div>
        <checkbox
          v-model="is_show_again"
          :label="$t('new_text.auto_check_print_calendar')"
        />
        <div>{{ $t('objects.reservation.text.change_setting_clinic') }}</div>
      </div>
    </template>
    <template #footer>
      <button-common
        class="print-calendar__cancel"
        @click="$emit('close')"
      >
        <template #content>
          {{ $t('new_text.button.cancel') }}
        </template>
      </button-common>
      <button-common
        class="print-calendar__no"
        @click="$emit('handle-print', { is_show_again, is_print_memo: 0 })"
      >
        <template #content>
          {{ $t('objects.reservation.text.no_print_calendar') }}
        </template>
      </button-common>
      <button-common
        class="print-calendar__yes"
        @click="$emit('handle-print', { is_show_again, is_print_memo: 1 })"
      >
        <template #content>
          {{ $t('objects.reservation.text.print_calendar') }}
        </template>
      </button-common>
    </template>
  </modal-action>
</template>
<script>
  import ModalAction from '../common/ModalAction'
  import Button from '../common/Button'
  import Checkbox from '../patient/v2/interview/template/Checkbox'

  export default {
    components: {
      Checkbox,
      ModalAction,
      ButtonCommon: Button
    },
    emits: ['handle-print'],
    data () {
      return {
        is_show_again: []
      }
    }
  }
</script>
