var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "patient-orders__table" }, [
    _c("div", { staticClass: "patient-orders__table__title" }, [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.$t("objects.orders.text.number_orders", {
              number_order: _vm.orders.length
            })
          ) +
          "\n  "
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "patient-orders__table__list" }, [
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("objects.orders.text.order_id")))]),
            _vm._v(" "),
            _c("th", [
              _vm._v(_vm._s(_vm.$t("objects.orders.text.order_date")))
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(_vm._s(_vm.$t("objects.orders.text.course_name")))
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(_vm._s(_vm.$t("new_text.number_of_time", { number: "" })))
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                _vm._s(_vm.$t("objects.orders.text.estimated_shipping_date"))
              )
            ]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.$t("new_text.status")))]),
            _vm._v(" "),
            _vm.isShowButtonPrint ? _c("th") : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.orders, function(order) {
            return _c(
              "tr",
              {
                key: order.id,
                class: [order.id == _vm.orderId ? "cursor-not-allow" : ""]
              },
              [
                _c(
                  "td",
                  {
                    on: {
                      click: function($event) {
                        return _vm.moveToOrderDetail(order.id)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(order.code) + "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    on: {
                      click: function($event) {
                        return _vm.moveToOrderDetail(order.id)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.formatDateTime(order.created_at)) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "fs-12",
                    on: {
                      click: function($event) {
                        return _vm.moveToOrderDetail(order.id)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.getProductName(order)) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    on: {
                      click: function($event) {
                        return _vm.moveToOrderDetail(order.id)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("new_text.time", {
                            number: order.product_number
                          })
                        ) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    on: {
                      click: function($event) {
                        return _vm.moveToOrderDetail(order.id)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.formatDateTime(order.order_shipping_date)) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "fs-12",
                    on: {
                      click: function($event) {
                        return _vm.moveToOrderDetail(order.id)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          order.order_status
                            ? _vm.$t(
                                "objects.orders.text.status." +
                                  order.order_status
                              )
                            : ""
                        ) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.isShowButtonPrint
                  ? _c(
                      "td",
                      [
                        _c("button-common", {
                          staticClass: "patient-orders__table__list__btn-print",
                          on: {
                            click: function($event) {
                              _vm.$emit(
                                "print-individual-technical-info",
                                order.id
                              )
                              _vm.triggerClick(
                                _vm.trigger_click_type.PRINT_OH,
                                order.sf_gbangou,
                                order.id
                              )
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "content",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "objects.orders.button.print_order_detail"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }