<template>
  <div :class="['common-list', { 'common-list--lazy-load': (lazyLoad && !isLoading) }]">
    <table class="main-list">
      <thead>
        <tr>
          <th
            v-if="isShowColumn('order_select')"
            class="common-list__order-select-wrapper"
          >
            <div class="common-list__order-select">
              <input
                v-if="orderList.length"
                v-model="selectAll"
                type="checkbox"
                class="common-list__order-checkbox"
                @change="$emit('order-selected', order_list_selected)"
              >
              <span class="common-list__order-checkbox common-list__order-checkbox--background" />
            </div>
          </th>
          <th
            :class="['common-list__order-code common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
            @click="sortBy('order_id')"
          >
            {{ $t('objects.orders.text.order_id') }}
            <span class="common-list__icon">
              <img :src="getIconSort('order_id')">
            </span>
          </th>
          <th
            :class="['common-list__order-created-at common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
            @click="sortBy('orders.created_at')"
          >
            {{ $t('objects.orders.text.order_date') }}
            <span class="common-list__icon">
              <img :src="getIconSort('orders.created_at')">
            </span>
          </th>
          <th
            :class="['common-list__product-name common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
            @click="sortBy('order_product_name')"
          >
            {{ $t('objects.orders.text.course_name') }}
            <span class="common-list__icon">
              <img :src="getIconSort('order_product_name')">
            </span>
          </th>
          <th
            :class="['common-list__product-number common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
            @click="sortBy('order_product_number')"
          >
            {{ $t('new_text.number_of_time') }}
            <span class="common-list__icon">
              <img :src="getIconSort('order_product_number')">
            </span>
          </th>
          <th
            :class="['common-list__patient-name common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
            @click="sortBy('patient_name')"
          >
            {{ $t('new_text.patient_name') }}
            <span class="common-list__icon">
              <img :src="getIconSort('patient_name')">
            </span>
          </th>
          <th
            :class="['common-list__patient-id common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
            @click="sortBy('sf_user_id')"
          >
            {{ $t('new_text.patient_id') }}
            <span class="common-list__icon">
              <img :src="getIconSort('sf_user_id')">
            </span>
          </th>
          <th
            v-if="isShowColumn('order_received_date')"
            :class="['common-list__order-received-date common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
            @click="sortBy('order_received_date')"
          >
            {{ $t('objects.orders.text.order_received_date') }}
            <span class="common-list__icon">
              <img :src="getIconSort('order_received_date')">
            </span>
          </th>
          <th
            v-if="isShowColumn('order_shipping_date')"
            :class="['common-list__order-shipping-date common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
            @click="sortBy('order_shipping_date')"
          >
            {{ $t('objects.orders.text.estimated_shipping_date') }}
            <span class="common-list__icon">
              <img :src="getIconSort('order_shipping_date')">
            </span>
          </th>
          <th
            v-if="isShowColumn('sf_delivery_date')"
            :class="['common-list__order-shipping-date common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
            @click="sortBy('sf_delivery_date')"
          >
            {{ $t('objects.orders.text.date_shipment') }}
            <span class="common-list__icon">
              <img :src="getIconSort('sf_delivery_date')">
            </span>
          </th>
          <th
            v-if="isShowColumn('order_status')"
            :class="['common-list__order-status common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
            @click="sortBy('order_status')"
          >
            {{ $t('new_text.status') }}
            <span class="common-list__icon">
              <img :src="getIconSort('order_status')">
            </span>
          </th>
          <th
            v-if="isShowColumn('order_sf_gbangou')"
            :class="['common-list__order-g-bangou common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
            @click="sortBy('orders.sf_gbangou')"
          >
            {{ $t('objects.orders.text.g_number') }}
            <span class="common-list__icon">
              <img :src="getIconSort('orders.sf_gbangou')">
            </span>
          </th>
          <th
            :class="['common-list__clinic-name common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
            @click="sortBy('clinic_name')"
          >
            {{ $t('objects.orders.text.clinic_name') }}
            <span class="common-list__icon">
              <img :src="getIconSort('clinic_name')">
            </span>
          </th>
          <th
            v-if="isShowColumn('print_status')"
            :class="['common-list__print-status common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
            @click="sortBy('instruction_print_status')"
          >
            {{ $t('objects.orders.text.print_status') }}
            <span class="common-list__icon">
              <img :src="getIconSort('instruction_print_status')">
            </span>
          </th>
          <th
            v-if="isShowColumn('worker_setup')"
            :class="['common-list__worker-setup common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
            @click="sortBy('orders.sf_worker_setup_id')"
          >
            <div class="common-list__text-box">
              <span class="common-list__text-box__text">{{ $t('objects.orders.text.worker_setup') }}</span>
              <span class="common-list__text-box__icon">
                <img :src="getIconSort('orders.sf_worker_setup_id')">
              </span>
            </div>
          </th>
          <th
            v-if="isShowColumn('worker_presscut')"
            :class="['common-list__worker-presscut common-list__sort', {'common-list__sort--disable': isDisableSort}]"
            @click="sortBy('orders.sf_worker_presscut_id')"
          >
            <div class="common-list__text-box">
              <span class="common-list__text-box__text">{{ $t('objects.orders.text.worker_presscut') }}</span>
              <span class="common-list__text-box__icon">
                <img :src="getIconSort('orders.sf_worker_presscut_id')">
              </span>
            </div>
          </th>
          <th
            v-if="isShowColumn('presscut_complete_date')"
            :class="['common-list__presscut-complete-date common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
            @click="sortBy('sf_presscut_complete_date__c')"
          >
            <div class="common-list__text-box">
              <span class="common-list__text-box__text">{{ $t('objects.orders.text.presscut_complete_date') }}</span>
              <span class="common-list__text-box__icon">
                <img :src="getIconSort('sf_presscut_complete_date__c')">
              </span>
            </div>
          </th>
          <th
            v-if="isShowColumn('technical_info_print_status')"
            :class="[
              'common-list__technical-info-print-status common-list__sort',
              {'common-list__sort--disable': isDisableSort}
            ]"
            @click="sortBy('instruction_admin_print_technical_info_status')"
          >
            {{ $t('objects.orders.text.order_details') }}
            <span class="common-list__icon">
              <img :src="getIconSort('instruction_admin_print_technical_info_status')">
            </span>
          </th>
        </tr>
      </thead>
      <tbody v-if="orderList.length > 0">
        <tr
          v-for="(order, index) in orderList"
          :key="index"
          class="c-pointer"
          @click="gotoOrderDetailPage(order, $event)"
        >
          <td
            v-if="isShowColumn('order_select')"
            class="common-list__order-select-wrapper"
          >
            <div class="common-list__order-select">
              <input
                :id="order.order_id"
                v-model="order_list_selected"
                :value="order.order_id"
                type="checkbox"
                class="common-list__order-checkbox"
                @change="$emit('order-selected', order_list_selected)"
              >
              <span class="common-list__order-checkbox common-list__order-checkbox--background common-list__order-checkbox--one" />
            </div>
          </td>
          <td class="common-list__order-code">
            {{ order.code }}
          </td>
          <td class="common-list__order-created-at">
            {{ getDate(order.created_at) }}
          </td>
          <td class="common-list__product-name">
            {{ order.order_product_name }}
          </td>
          <td class="common-list__product-number">
            {{ order.order_product_number }}
          </td>
          <td class="common-list__patient-name">
            {{ order.patient_name }}
          </td>
          <td class="common-list__patient-id">
            {{ order.sf_user_id }}
          </td>
          <td
            v-if="isShowColumn('order_received_date')"
            class="common-list__order-received-date"
          >
            {{ getDate(order.order_received_date) }}
          </td>
          <td
            v-if="isShowColumn('order_shipping_date')"
            class="common-list__order-shipping-date"
          >
            {{ getDate(order.order_shipping_date) }}
          </td>
          <td
            v-if="isShowColumn('sf_delivery_date')"
            class="common-list__order-shipping-date"
          >
            {{ getDate(order.sf_delivery_date) }}
          </td>
          <td
            v-if="isShowColumn('order_status')"
            class="common-list__order-status"
          >
            {{ getOrderStatusLabel(order.order_status) }}
          </td>
          <td
            v-if="isShowColumn('order_sf_gbangou')"
            class="common-list__order-g-bangou"
          >
            {{ order.sf_gbangou }}
          </td>
          <td class="common-list__clinic-name">
            {{ order.clinic_name }}
          </td>
          <td
            v-if="isShowColumn('print_status')"
            :class="[
              'common-list__print-status', order.instruction_print_status
                ? 'common-list__print-status--printed'
                : 'common-list__print-status--unprinted'
            ]"
          >
            {{ order.instruction_print_status ? $t('new_text.printed') : $t('new_text.unprinted') }}
          </td>
          <td
            v-if="isShowColumn('worker_setup')"
            class="common-list__worker-setup"
          >
            {{ order.name_worker_setup }}
          </td>
          <td
            v-if="isShowColumn('worker_presscut')"
            class="common-list__worker-presscut"
          >
            {{ order.name_worker_presscut }}
          </td>
          <td
            v-if="isShowColumn('presscut_complete_date')"
            class="common-list__presscut-complete-date"
          >
            {{ getDate(order.sf_presscut_complete_date__c) }}
          </td>
          <td
            v-if="isShowColumn('technical_info_print_status')"
            :class="[
              'common-list__print-status', order.instruction_admin_print_technical_info_status
                ? 'common-list__print-status--printed'
                : 'common-list__print-status--unprinted'
            ]"
          >
            {{ order.instruction_admin_print_technical_info_status ? $t('new_text.printed') : $t('new_text.unprinted') }}
          </td>
        </tr>
      </tbody>
      <tbody
        v-else
        class="common-list__not-found"
      >
        <tr>
          <td>
            <p
              v-if="!lazyLoad"
              class="common-list__label-not-found"
            >
              {{ notFoundText }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { ORDER_STATUS } from '../../../utils/constants'
  import * as moment from 'moment-timezone'
  import sortMixin from '../../../mixins/sort'
  import { isEmpty } from 'lodash'

  export default {
    mixins: [sortMixin],
    props: {
      orderList: {
        type: Array,
        default: () => []
      },
      currentStatus: {
        type: Number,
        default: null
      },
      isSearchDefault: {
        type: Boolean,
        default: false
      },
      lazyLoad: {
        type: Boolean,
        default: false
      }
    },
    emits: ['handle-sort'],
    data () {
      return {
        ORDER_COLUMN_STATUS: {
          order_select: {
            CHECKING: { value: 2, label: 'checking' },
            SETUP_DESIGN: { value: 3, label: 'setup-design' },
            PREPARE: { value: 4, label: 'prepare' },
            SHIPPED: { value: 5, label: 'shipped' },
            CANCELLED: { value: 6, label: 'cancelled' },
            DELIVERED: { value: 7, label: 'delivered' }
          },
          order_shipping_date: {
            SEARCH: { value: -1, label: 'search' },
            SETUP_DESIGN: { value: 3, label: 'setup-design' },
            PREPARE: { value: 4, label: 'prepare' },
            CANCELLED: { value: 6, label: 'cancelled' }
          },
          sf_delivery_date: {
            SHIPPED: { value: 5, label: 'shipped' },
            DELIVERED: { value: 7, label: 'delivered' }
          },
          order_sf_gbangou: {
            SEARCH: { value: -1, label: 'search' },
            SETUP_DESIGN: { value: 3, label: 'setup-design' },
            PREPARE: { value: 4, label: 'prepare' },
            SHIPPED: { value: 5, label: 'shipped' },
            CANCELLED: { value: 6, label: 'cancelled' },
            DELIVERED: { value: 7, label: 'delivered' }
          },
          worker_setup: {
            SEARCH: { value: -1, label: 'search' },
            SETUP_DESIGN: { value: 3, label: 'setup-design' },
            PREPARE: { value: 4, label: 'prepare' },
            SHIPPED: { value: 5, label: 'shipped' },
            CANCELLED: { value: 6, label: 'cancelled' },
            DELIVERED: { value: 7, label: 'delivered' }
          },
          worker_presscut: {
            SEARCH: { value: -1, label: 'search' },
            SETUP_DESIGN: { value: 3, label: 'setup-design' },
            PREPARE: { value: 4, label: 'prepare' },
            SHIPPED: { value: 5, label: 'shipped' },
            CANCELLED: { value: 6, label: 'cancelled' },
            DELIVERED: { value: 7, label: 'delivered' }
          },
          lab_name: {
            SEARCH: { value: -1, label: 'search' },
            SETUP_DESIGN: { value: 3, label: 'setup-design' },
            PREPARE: { value: 4, label: 'prepare' },
            SHIPPED: { value: 5, label: 'shipped' },
            CANCELLED: { value: 6, label: 'cancelled' },
            DELIVERED: { value: 7, label: 'delivered' }
          },
          print_status: {
            CHECKING: { value: 2, label: 'checking' }
          },
          order_status: {
            SEARCH: { value: -1, label: 'search' }
          },
          technical_info_print_status: {
            PREPARE: { value: 4, label: 'prepare' }
          },
          order_received_date: {
            SEARCH: { value: -1, label: 'search' },
            SETUP_DESIGN: { value: 3, label: 'setup-design' },
            PREPARE: { value: 4, label: 'prepare' },
            SHIPPED: { value: 5, label: 'shipped' },
            CANCELLED: { value: 6, label: 'cancelled' },
            DELIVERED: { value: 7, label: 'delivered' }
          },
          presscut_complete_date: {
            PREPARE: { value: 4, label: 'prepare' },
            SHIPPED: { value: 5, label: 'shipped' },
            DELIVERED: { value: 7, label: 'delivered' }
          }
        },
        order_list_selected: [],
        order_status: ORDER_STATUS
      }
    },
    computed: {
      isLoading: function () {
        return this.$store.getters.isLoading
      },
      selectAll: {
        get: function () {
          return this.order_list_selected.length !== 0
            ? this.order_list_selected.length === this.orderList.length
            : false
        },
        set: function (value) {
          const selected = []

          if (value) {
            this.orderList.forEach(function (order) {
              selected.push(order.order_id)
            })
          }

          this.order_list_selected = selected
        }
      },
      notFoundText () {
        return this.isSearchDefault
          ? this.$t('objects.orders.text.order_search_notification')
          : this.$t('orders.list.message.no_result_search')
      },
      isDisableSort () {
        return this.orderList.length === 0
      }
    },
    watch: {
      $route () {
        this.sort_type = ''
        this.sort_field = null
      }
    },
    methods: {
      isShowColumn (column) {
        const vm = this
        return _.find(this.ORDER_COLUMN_STATUS[column], function (i) {
          return i.value === vm.currentStatus
        })
      },
      getOrderStatusLabel (status) {
        for (const key in ORDER_STATUS) {
          if (ORDER_STATUS[key].value === status) {
            return this.$t('objects.orders.text.status.' + ORDER_STATUS[key].value.toString())
          }
        }
      },
      getDate (dateTime) {
        if (dateTime === '0000-00-00 00:00:00' || isEmpty(dateTime)) {
          return ''
        }

        return moment(dateTime).format('YYYY/MM/DD')
      },
      resetSelected () {
        this.order_list_selected = []
      },
      gotoOrderDetailPage (order, event) {
        if (event.target.nodeName === 'A' || event.target.nodeName === 'INPUT') {
          return
        }

        const url = this.$router.resolve({
          name: 'patient.orders.detail',
          params: {
            id: order.patient_id,
            order_id: order.order_id
          }
        }).href

        window.open(url, '_blank')
      },
      sortBy (field) {
        if (this.orderList.length === 0) {
          return
        }
        this.setDataSort(field)
        const dataSort = {}
        dataSort.order_field = this.sort_field
        dataSort.order_type = this.sort_type
        this.$emit('handle-sort', dataSort)
      }
    }
  }
</script>
