<template>
  <div class="common-list common-list__patient-services">
    <table class="main-list">
      <thead>
        <tr>
          <th class="common-list__services-category">
            {{ $t('objects.patient.text.service_category') }}
          </th>
          <th class="common-list__overall-services">
            {{ $t('objects.patient.text.overall_service') }}
          </th>
          <th class="common-list__number-services">
            {{ $t('objects.patient.text.number_services') }}
          </th>
          <th class="common-list__remaining_service">
            {{ $t('objects.patient.text.remaining_service') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, key) in serviceSummary"
          :key="key"
        >
          <td class="common-list__services-category">
            {{ item.cat_name }}
            <span>({{ item.name }})</span>
          </td>
          <td class="common-list__overall-services">
            {{ getIntVal(item.total_count) }}
          </td>
          <td class="common-list__number-services">
            {{ getIntVal(item.remain_count) }}
          </td>
          <td class="common-list__remaining_service">
            {{ getPrice(item.remain_amount) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import { intVal, formatCurrency } from '../../../utils/helpers.js'
  import { accountingMixin } from '../../../utils/accounting.js'

  export default {
    mixins: [accountingMixin],
    props: {
      serviceSummary: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      getIntVal: function (item) {
        return intVal(item)
      },
      getPrice: function (price) {
        return formatCurrency(price)
      }
    }
  }
</script>
