var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value,
          expression: "value"
        }
      ],
      staticClass: "common-select",
      attrs: { disable: _vm.disable },
      on: {
        change: [
          function($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function(o) {
                return o.selected
              })
              .map(function(o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.value = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
          function($event) {
            return _vm.$emit("change", _vm.value)
          }
        ]
      }
    },
    [
      _c(
        "option",
        { attrs: { value: "", disabled: "", selected: "", hidden: "" } },
        [_vm._v("\n    " + _vm._s(_vm.placeholder) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.emptyValue && _vm.value
        ? _c("option", { attrs: { value: "" } }, [
            _vm._v("\n    " + _vm._s(_vm.emptyValue) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.options, function(item, index) {
        return _c(
          "option",
          { key: index, domProps: { value: item[_vm.keyValue] } },
          [_vm._v("\n    " + _vm._s(item[_vm.keyDisplay]) + "\n  ")]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }