var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order" }, [
    _c("div", { staticClass: "order-content" }, [
      _c(
        "div",
        {
          class: [
            "content",
            {
              "content--search-detail": _vm.show_search_detail,
              "content--lab-design": _vm.$store.getters.isLabDesigner,
              "content--hide-content-sp": _vm.hide_search_content_in_sp
            }
          ]
        },
        [
          _c("div", { staticClass: "content__header" }, [
            _c(
              "div",
              { staticClass: "content__header content__header--small" },
              [
                _c("div", { staticClass: "content__text" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("order.sidebar.search")) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: [
                      "order__download",
                      {
                        "order__download--disable": _vm.order_list.length === 0
                      }
                    ],
                    attrs: { disabled: _vm.order_list.length === 0 },
                    on: { click: _vm.handleExportCsv }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("objects.orders.text.download_csv")) +
                        "\n          "
                    )
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content__search" },
            [
              _vm.isInstructionAdmin
                ? _c("search-for-instruction-admin", {
                    attrs: {
                      "type-search": "search",
                      "reset-active": _vm.resetActive,
                      "max-length": _vm.max_length
                    },
                    on: {
                      submit: _vm.getOrderList,
                      reset: function($event) {
                        ;[(_vm.order_list = []), (_vm.data_search = {})]
                      },
                      "show-search-detail": function($event) {
                        _vm.show_search_detail = !_vm.show_search_detail
                      },
                      "set-hide-search-content-in-sp": function($event) {
                        ;[
                          (_vm.hide_search_content_in_sp = false),
                          (_vm.show_search_detail = false)
                        ]
                      }
                    }
                  })
                : _c("search", {
                    attrs: {
                      "type-search": "search",
                      "reset-active": _vm.resetActive
                    },
                    on: {
                      submit: _vm.getOrderList,
                      reset: function($event) {
                        ;[(_vm.order_list = []), (_vm.data_search = {})]
                      },
                      "show-search-detail": function($event) {
                        _vm.show_search_detail = !_vm.show_search_detail
                      },
                      "set-hide-search-content-in-sp": function($event) {
                        ;[
                          (_vm.hide_search_content_in_sp = false),
                          (_vm.show_search_detail = false)
                        ]
                      }
                    }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content__list" },
            [
              _vm.isInstructionAdmin
                ? _c("order-list-instruction-admin", {
                    class: [
                      "common-list--order-search common-list--instruction-admin",
                      {
                        "common-list--sticky":
                          _vm.currentLayoutMode === _vm.layout_mode.PC
                      }
                    ],
                    attrs: {
                      "order-list": _vm.order_list,
                      "current-status": -1,
                      "is-search-default": _vm.is_search_default
                    },
                    on: { "handle-sort": _vm.handleSort }
                  })
                : _c("order-list", {
                    class: [
                      "common-list--order-search",
                      {
                        "common-list--sticky":
                          _vm.currentLayoutMode === _vm.layout_mode.PC
                      }
                    ],
                    attrs: {
                      "order-list": _vm.order_list,
                      "current-status": -1,
                      "is-search-default": _vm.is_search_default
                    },
                    on: { "handle-sort": _vm.handleSort }
                  }),
              _vm._v(" "),
              _vm.order_list.length
                ? _c(
                    "div",
                    { staticClass: "order-list-pagination" },
                    [
                      _c("pagination-common", {
                        attrs: {
                          "current-page": _vm.pagination.current_page,
                          "last-page": _vm.pagination.last_page
                        },
                        on: { "change-page": _vm.changePage }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }