var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "questionnaire",
      attrs: { id: "reservationId_" + _vm.questionnaire.reservations_id }
    },
    [
      _c("table", { staticClass: "questionnaire__table" }, [
        _c("thead", [
          _c("tr", [
            _c(
              "th",
              {
                staticClass: "questionnaire__table__head",
                attrs: { colspan: "4" }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.questionnaire.type === _vm.interview_type.FIRST
                        ? _vm.$t("objects.patient.text.questionnaire.first")
                        : _vm.$t("objects.patient.text.questionnaire.re_exam")
                    ) +
                    "\n        "
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("tbody", { staticClass: "questionnaire__table__body" }, [
          _c("tr", [
            _c("th", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("objects.patient.text.questionnaire.manager")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("td", { attrs: { colspan: "3" } }, [
              _vm._v("\n          " + _vm._s(_vm.staffs) + "\n        ")
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t("objects.patient.text.questionnaire.visit_date")
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "number-medium" }, [
              _vm._v("\n          " + _vm._s(_vm.arrivedTime) + "\n        ")
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t("objects.patient.text.questionnaire.answer_date")
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "number-medium" }, [
              _vm._v("\n          " + _vm._s(_vm.updatedTime) + "\n        ")
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t(
                      "objects.patient.text.questionnaire.description_rating"
                    )
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.questionnaire.type === _vm.interview_type.FIRST
                      ? _vm.getAnswer("q2", "v1q2")
                      : _vm.getAnswer("q2", "v2q2")
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t(
                      "objects.patient.text.questionnaire.treatment_evaluation"
                    )
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.questionnaire.type === _vm.interview_type.FIRST
                      ? "-"
                      : _vm.getAnswer("v2q1", "v2q1")
                  ) +
                  "\n        "
              )
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("objects.patient.text.questionnaire.comment")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "questionnaire__comment",
                attrs: { colspan: "3" }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.questionnaire.q3 || "") +
                    "\n        "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t(
                      "objects.patient.text.questionnaire.improvement_comment"
                    )
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "questionnaire__comment",
                attrs: { colspan: "3" }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.questionnaire.q4 || "") +
                    "\n        "
                )
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }