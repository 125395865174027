import axios from 'axios'
import { state } from './auth'

export const actions = {
  getClinicDailyMemo ({ dispatch, commit }, params) {
    return axios.get('/api/clinic-daily-memo', {
      params,
      headers: { Authorization: 'Bearer ' + state.token }
    })
  },
  saveClinicDailyMemo ({ dispatch, commit }, params) {
    return axios.post('/api/clinic-daily-memo', params, {
      headers: { Authorization: 'Bearer ' + state.token }
    })
  }
}
