import * as moment from 'moment'
import Swal from 'sweetalert2'
import { STAFF_POSITION, FORBIDDEN, FORBIDDEN_CODE } from './constants'

const helpers = {
  safeGet: function (object, keys) {
    return keys.split('.').reduce((xs, x) => (xs && xs[x]) ? xs[x] : undefined, object) || ''
  },
  formatDateTime: function (dateString, formatString) {
    if (!dateString || !formatString) return ''
    const date = moment(dateString)
    return date.isValid() ? date.format(formatString) : ''
  },
  formatCurrency: function (val) {
    return new Intl.NumberFormat('ja-JP').format(val)
  },
  parseDateTime: function (dateString, inFormat, outFormat) {
    if (!dateString || !inFormat) return ''
    const date = moment(dateString, inFormat)
    if (outFormat) {
      return date.isValid() ? date.format(outFormat) : ''
    }
    return date.isValid() ? date : ''
  },
  medicalRecordInNewTab: function (url) {
    window.open(url, '_blank')
    $(window).on('storage', this.onEndMedicalRecord)
  },
  endMediaRecordInNewTab: function (url) {
    localStorage.setItem('end_edit_medical_record', 'end_edit_medical_record')
    localStorage.removeItem('end_edit_medical_record')
    location.assign(url)
  },
  onEndMedicalRecord: function (event) {
    if (event.originalEvent.key !== 'end_edit_medical_record') return
    $(window).off('storage', this.reload)
    location.reload()
  },
  getTaxRate: function (dateSring, fullAmount) {
    const issuedDate = moment(dateSring)
    const rate8start = moment('2014-04-01')
    const rate8end = moment('2019-09-30')
    if (issuedDate.isAfter(rate8end, 'day')) return fullAmount ? 1.1 : 0.1
    if (issuedDate.isBefore(rate8start, 'day')) return fullAmount ? 1.05 : 0.05
    return fullAmount ? 1.08 : 0.08
  },
  showNotifyModal: function (message = '予約情報が更新されました') {
    Swal.fire({
      toast: true,
      customClass: 'toast-notify',
      position: 'top-end',
      title: `<div class="notify-icon"></div><a href="/notification/list" class="swal-title-text">${message}</a>`,
      showConfirmButton: false,
      timer: 3000
    })
  },
  showSuccessModal: function (message) {
    Swal.fire({
      toast: true,
      customClass: 'toast-success',
      position: 'top',
      title: `<div class="success-icon"></div><div class="swal-title-text">${message}</div>`,
      showConfirmButton: false,
      timer: 3000,
      onBeforeOpen: () => {
        document.getElementsByClassName('swal2-top')[0].style.top = '85px'
      }
    })
  },
  showWarningModal: function (message, option = {}) {
    Swal.fire({
      ...{
        toast: true,
        customClass: 'toast-warning',
        position: 'top',
        title: `<div class="warning-icon"></div><div class="swal-title-text">${message}</div>`,
        showConfirmButton: false,
        timer: 3000,
        onBeforeOpen: () => {
          document.getElementsByClassName('swal2-top')[0].style.top = '85px'
        }
      },
      ...option
    }
    )
  },
  showErrorModal: function (message) {
    Swal.fire({
      toast: true,
      customClass: 'toast-error',
      position: 'top',
      title: `<div class="error-icon"></div><div class="swal-title-text">${message}</div>`,
      showConfirmButton: false,
      timer: 3000,
      onBeforeOpen: () => {
        document.getElementsByClassName('swal2-top')[0].style.top = '85px'
      }
    })
  },
  showErrorMessage: function (errors) {
    if (errors.length) {
      Swal.fire({
        toast: true,
        type: 'error',
        position: 'top',
        title: errors.join('\n'),
        showConfirmButton: false,
        timer: 1500,
        onBeforeOpen: () => {
          $('.swal2-top').css({ top: 125 + 'px' })
        }
      })
    }
  },
  showToastErrorsByTransKey: function (res, context) {
    const resErrors = res?.response?.data?.errors || {}
    const errors = []
    for (const index in resErrors) {
      if (typeof resErrors[index] === 'string') {
        errors.push(context.$t(resErrors[index]))
      } else if (typeof resErrors[index] === 'object') {
        for (const key in resErrors[index]) {
          errors.push(context.$t(resErrors[index][key]))
        }
      }
    }
    if (errors.length) {
      this.showErrorModal(errors)
    }
  },
  capitalize: function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },
  mbtrim: function (string) {
    return string.replace(/(^\s+|\s+$)/g, '')
  },
  intVal: function (val) {
    if (!val || isNaN(val)) {
      return 0
    }
    return parseInt(val)
  },
  getYearsBetweenTwoDate: function (start, end) {
    const startDate = moment(start)
    const endDate = moment(end)
    const years = endDate.diff(startDate, 'year')
    return years
  },
  showStaffPosition: function (staffPosition) {
    let position
    switch (staffPosition) {
      case '歯科医師':
        position = STAFF_POSITION.DOCTOR
        break
      case 'コーディネーター':
        position = STAFF_POSITION.CONSELOR
        break
      case '歯科衛生士':
        position = STAFF_POSITION.DENTAL_HYGIENIST
        break
      default:
        position = STAFF_POSITION.DENTAL_ASSISTANT
        break
    }
    return position
  },
  calculateAge: function (birthday) {
    return moment().diff(birthday, 'years')
  },
  getAgeString: function (birthday, text) {
    return (moment(birthday).isValid()) ? `(${helpers.calculateAge(birthday)}${text}) ` : ''
  },
  formatNumber: function (val) {
    return new Intl.NumberFormat('ja-JP').format(val)
  },
  isValidJapaneseName: function (str) {
    // eslint-disable-next-line no-irregular-whitespace
    return str ? /^[一-龠ぁ-ゔァ-ヴーａ-ｚＡ-Ｚ０-９a-zA-Z0-9々〆〤 　]+$/.test(str) : true
  },
  checkValidEmail: function (email) {
    return /^\w+([.+-]?\w+)*@\w+([.+-]?\w+)*(\.\w{2,3})+$/.test(email)
  }
}

export default helpers

export function truncateContent (text, max = 20) {
  return text ? text.substr(0, max - 1) + (text.length > max ? '...' : '') : ''
}

export function showToastErrors (res, casted = false) {
  let errors = []
  if (casted) {
    errors = res
  } else {
    const resErrors = res?.response?.data?.errors || {}
    for (const index in resErrors) {
      if (typeof resErrors[index] === 'string') {
        errors.push(resErrors[index])
      } else if (typeof resErrors[index] === 'object') {
        for (const key in resErrors[index]) {
          errors.push(resErrors[index][key])
        }
      }
    }
  }

  if (errors.length) {
    helpers.showErrorModal(errors)
  }
}

export function showMessageModal (option) {
  Swal.fire({
    ...{
      toast: true,
      position: 'top',
      showConfirmButton: false,
      imageWidth: 32,
      imageHeight: 32,
      timer: 3000,
      width: '380px',
      customClass: {
        container: 'med-message'
      },
      onBeforeOpen: () => {
        document.getElementsByClassName('med-message')[0].style.top = '85px'
      }
    },
    ...option
  })
}

export function showWarningModal (message, option = {}) {
  Swal.fire({
    ...{
      toast: true,
      customClass: 'toast-warning',
      position: 'top',
      title: `<div class="warning-icon"></div><div class="swal-title-text">${message}</div>`,
      showConfirmButton: false,
      timer: 3000,
      onBeforeOpen: () => {
        document.getElementsByClassName('swal2-top')[0].style.top = '85px'
      }
    },
    ...option
  }
  )
}

export function showSuccessModal (message, option = {}) {
  showMessageModal(
    {
      title: message,
      imageUrl: '/images/icon-toast-success.svg',
      customClass: {
        container: 'med-message',
        popup: 'med-message__popup med-message__popup--success',
        title: 'med-message__title',
        header: 'med-message__header',
        image: 'med-message__image'
      },
      ...option
    }
  )
}

export function showLargeSuccessModal (message, option = {}) {
  showMessageModal(
    {
      title: message,
      imageUrl: '/images/icon-success-v2.svg',
      imageWidth: 46,
      imageHeight: 46,
      width: '334px',
      customClass: {
        container: 'med-message med-message--large',
        popup: 'med-message__popup med-message__popup--success',
        title: 'med-message__title'
      },
      ...option
    }
  )
}

export function showErrorModal (message, option = {}) {
  showMessageModal(
    {
      title: message,
      imageUrl: '/images/icon-toast-error.svg',
      customClass: {
        container: 'med-message',
        popup: 'med-message__popup med-message__popup--error',
        title: 'med-message__title',
        header: 'med-message__header',
        image: 'med-message__image'
      },
      ...option
    }
  )
}

export function showValidationErrorModal (errors) {
  showErrorModal(errors.join('<br>'), {
    customClass: {
      container: 'med-message med-message--error-validation',
      popup: 'med-message__popup med-message__popup--error',
      title: 'med-message__title',
      header: 'med-message__header',
      image: 'med-message__image'
    }
  })
}

export function formatDateTime (dateString, formatString) {
  if (!dateString || !formatString) return ''
  const date = moment(dateString)
  return date.isValid() ? date.format(formatString) : ''
}

export function intVal (val) {
  if (!val || isNaN(val)) {
    return 0
  }
  return parseInt(val)
}

export function getAgeString (birthday, text) {
  return (moment(birthday).isValid()) ? `(${helpers.calculateAge(birthday)}${text}) ` : ''
}

export function calculateAge (birthday) {
  return moment().diff(birthday, 'years')
}

export function safeGet (object, keys) {
  return keys.split('.').reduce((xs, x) => (xs && xs[x]) ? xs[x] : undefined, object) || ''
}

export function capitalize (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function isValidJapaneseName (str) {
  // eslint-disable-next-line no-irregular-whitespace
  return str ? /^[一-龠ぁ-ゔァ-ヴーａ-ｚＡ-Ｚ０-９a-zA-Z0-9々〆〤 　]+$/.test(str) : true
}

export function checkValidEmail (email) {
  return /^\w+([.+-]+?\w+)*@\w+([.+-]+?\w+)*(\.\w{2,})+$/.test(email)
}

export function isEmail (email) {
  if (!email) {
    return true
  }

  return checkValidEmail(email)
}

export function isAlpha (char) {
  if (/^[A-Za-z]+$/.test(char)) {
    return true
  }
  return false
}

export function checkValidPhone (value) {
  value = mbtrim(value)

  let pattern = /^\d+$/

  if (!pattern.test(value)) {
    pattern = /^[\uFF10-\uFF19]+$/
    if (pattern.test(value)) {
      return ('phone_only_single_number')
    }
    return ('phone_only_number')
  }

  if (value.length < 10) {
    return ('invalid_phone')
  }

  if (value.length > 11) {
    this.phone = value.substring(0, value.length - 1)
  }
}

export function checkValidStaffLoginId (id) {
  const ipRegx = /^[a-zA-Z0-9@.]+$/

  return ipRegx.test(id)
}

export function mbtrim (string) {
  return string.replace(/(^\s+|\s+$)/g, '')
}

export function mbTrimRomaji (string) {
  string = string.replace(/[^a-zA-Z ]+/g, '')
  string = string.replace(/(\s\s+)/g, ' ')
  return string.replace(/(^\s+|\s+$)/g, '')
}

export function showMessageError (error, vm) {
  const option = {
    customClass: {
      container: 'med-message',
      popup: 'med-message__popup med-message__popup--error',
      title: 'med-message__title',
      header: 'med-message__header',
      image: 'med-message__image'
    }
  }
  let message = ''
  switch (error?.response?.status) {
    case 400:
      if (error?.response?.data?.errors?.message === FORBIDDEN) {
        showErrorModal(vm.$t('new_message.forbidden'), option)
        return vm.$router.push({ name: 'dashboard' })
      }
      break
    case 422:
      message = vm.$t('new_message.unprocessable_entity')
      break
    case 401:
      message = vm.$t('new_message.unauthorized')
      break
    case 404:
      message = vm.$t('new_message.not_found')
      break
    default:
      message = vm.$t('new_message.something_went_wrong')
  }
  showErrorModal(message, option)
}

export function getOrdinalSuffix (number) {
  if (number % 10 === 1 && number !== 11) {
    return 'st'
  }

  if (number % 10 === 2 && number !== 12) {
    return 'nd'
  }

  if (number % 10 === 3 && number !== 13) {
    return 'rd'
  }

  return 'th'
}

export function isJson (str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function formatCurrency (val) {
  return new Intl.NumberFormat('ja-JP').format(val)
}

export function numberWithCommas (number) {
  try {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } catch (e) {
    return ''
  }
}

export function medicalRecordInNewTab (url) {
  window.open(url, '_blank')
  $(window).on('storage', onEndMedicalRecord)
}

export function onEndMedicalRecord (event) {
  if (event.originalEvent.key !== 'end_edit_medical_record') return
  $(window).off('storage', this.reload)
  location.reload()
}

export function checkValidFileName (fileName) {
  // eslint-disable-next-line no-misleading-character-class
  const pattern = /^(?!\.)[-\w^&'@{}[\],$=!#().%+~,一-龠ぁ-ゔァ-ヴガ-プーａ-ｚＡ-Ｚ０-９々〆〤 ]+$/
  return pattern.test(fileName)
}

export function getInvalidFileNameChars (fileName) {
  const name = fileName.split('.').slice(0, -1).join('.')

  // eslint-disable-next-line no-misleading-character-class
  const pattern = /^(?!\.)[-\w^&'@{}[\],$=!#().%+~,一-龠ぁ-ゔァ-ヴガ-プーａ-ｚＡ-Ｚ０-９々〆〤 ]+$/
  return name.replace(/:/g, '/').split('').filter(function (val) {
    return !pattern.test(val)
  })
}

export function checkInvalidRomaji (value) {
  const ONLY_CHARACTER = '^[a-zA-Z]+$'
  const rex = RegExp(ONLY_CHARACTER)
  return !rex.test(value)
}

export function checkInstructionForbidden (error, vm) {
  if (safeGet(error, 'response.status') === FORBIDDEN_CODE && safeGet(error, 'response.data.results.message') === FORBIDDEN) {
    vm.$store.commit('logout')
    window.location.reload()
  }
}

export function isValidUrl (value) {
  var res = value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g)
  return (res !== null)
}

export function convertToFullWidth (inputString) {
  const charsets = {
    latin: {
      halfRE: /[!-~]/g,
      fullRE: /[！-～]/g,
      delta: 0xFEE0
    },
    hangul1: {
      halfRE: /[ﾡ-ﾾ]/g,
      fullRE: /[ᆨ-ᇂ]/g,
      delta: -0xEDF9
    },
    hangul2: {
      halfRE: /[ￂ-ￜ]/g,
      fullRE: /[ᅡ-ᅵ]/g,
      delta: -0xEE61
    },
    kana: {
      delta: 0,
      half: '｡｢｣､･ｦｧｨｩｪｫｬｭｮｯｰｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜﾝﾞﾟ',
      full: '。「」、・ヲァィゥェォャュョッーアイウエオカキクケコサシ' +
        'スセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワン゛゜'
    },
    extras: {
      delta: 0,
      half: '¢£¬¯¦¥₩\u0020|←↑→↓■°',
      full: '￠￡￢￣￤￥￦\u3000￨￩￪￫￬￭￮'
    }
  }

  const toFull = set => c => set.delta
    ? String.fromCharCode(c.charCodeAt(0) + set.delta)
    : [...set.full][[...set.half].indexOf(c)]
  const re = (set, way) => set[way + 'RE'] || new RegExp('[' + set[way] + ']', 'g')
  const sets = Object.keys(charsets).map(i => charsets[i])

  return sets.reduce((str, set) => str.replace(re(set, 'half'), toFull(set)), inputString)
}

export function openNewTab (url) {
  return new Promise(function (resolve) {
    resolve(url)
  })
}

export function getBrandNameFromType (type, brandFilter) {
  const brandItem = brandFilter.find(item => item.type === type)
  return brandItem ? brandItem.name : ''
}

export function truncateString (str, maxLength) {
  if (!str) {
    return ''
  }

  if (str.length > maxLength) {
    return str.slice(0, maxLength - 1) + '...'
  }

  return str
}

export function getExtension (fullName) {
  return fullName ? fullName.split('.').pop().toLowerCase() : ''
}
