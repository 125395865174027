<template>
  <transition name="modal">
    <div class="modal-v2">
      <div class="modal-v2__wrapper">
        <div class="modal-v2__container">
          <div class="modal-v2__header">
            <h4>{{ title }}</h4>
            <span
              v-if="isShowCloseButton"
              class="modal-v2__header__close"
              @click="$emit('close')"
            >
              <img src="/images/icon-close-modal-blue.svg">
            </span>
          </div>
          <div class="modal-v2__body">
            <slot name="body" />
          </div>
          <div
            v-if="isShowFooter"
            class="modal-v2__footer"
          >
            <slot name="footer">
              <div class="modal-v2__footer__default">
                <button
                  v-if="isShowDeleteButton"
                  class="modal-v2__button modal-v2__button--delete"
                  @click="$emit('delete')"
                >
                  <slot name="delete_button_content">
                    {{ $t('new_text.button.delete') }}
                  </slot>
                </button>
                <div
                  :class="[
                    'modal-v2__footer__group',
                    {
                      'modal-v2__footer__group--flex': !isShowDeleteButton,
                      'modal-v2__footer__group--one-button': !isShowCancelButton || !isShowApprovalButton
                    }
                  ]"
                >
                  <button
                    v-if="isShowCancelButton"
                    class="modal-v2__button modal-v2__button--cancel"
                    @click="$emit('close')"
                  >
                    <slot name="cancel_button_content">
                      {{ $t('new_text.button.cancel') }}
                    </slot>
                  </button>
                  <button
                    v-if="isShowApprovalButton"
                    class="modal-v2__button modal-v2__button--confirm"
                    @click="$emit('confirm')"
                  >
                    <slot name="confirm_button_content">
                      {{ $t('new_text.button.save') }}
                    </slot>
                  </button>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  export default {
    props: {
      isShowCloseButton: {
        type: Boolean,
        default: true
      },
      isShowCancelButton: {
        type: Boolean,
        default: true
      },
      isShowApprovalButton: {
        type: Boolean,
        default: true
      },
      isShowDeleteButton: {
        type: Boolean,
        default: false
      },
      isShowFooter: {
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        default: ''
      }
    },
    emits: [
      'close',
      'confirm',
      'delete'
    ]
  }
</script>
