var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "interview-other interview-input-block content-inner bg-white"
    },
    [
      _c("according-block", {
        attrs: { label: "その他", checked: _vm.show_block },
        on: {
          handleClick: function($event) {
            _vm.show_block = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show_block,
              expression: "show_block"
            }
          ],
          staticClass:
            "interview-other__content arrow-toggle-content page-content"
        },
        [
          _c("div", { staticClass: "form-group row pt-4" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("sub-question", {
                  ref: "sub_question",
                  attrs: {
                    question: _vm.question,
                    options: _vm.option_yes_no,
                    checked: _vm.plan_to_move
                  },
                  on: {
                    handleAnswer: function($event) {
                      return _vm.handleAnswer($event)
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { class: _vm.handleChildAnswerClass }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "color-blue" }, [
                      _vm._v("いつ頃：")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "select-option row interview-other__date"
                      },
                      [
                        _c("div", { staticClass: "col-md-4" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.when_to_move1,
                                expression: "when_to_move1"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.when_to_move1 },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.when_to_move1 = $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "interview-other__date-times" },
                            [_vm._v("年")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-5" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.when_to_move2,
                                expression: "when_to_move2"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.when_to_move2 },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.when_to_move2 = $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "interview-other__date-monthly" },
                            [_vm._v("月ごろ")]
                          )
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "color-blue" }, [
                      _vm._v("転居先：")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.moving_destination,
                          expression: "moving_destination"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", name: "" },
                      domProps: { value: _vm.moving_destination },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.moving_destination = $event.target.value
                        }
                      }
                    })
                  ])
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _c("p", [
                _vm._v(
                  "2. その他、診察に際してご要望などございましたら、ご記入ください。"
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.other_requests,
                      expression: "other_requests"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    rows: "5",
                    maxlength: "2000",
                    placeholder:
                      "相談内容１行目\n相談内容２行目\n相談内容３行目\n相談内容４行目\n相談内容５行目"
                  },
                  domProps: { value: _vm.other_requests },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.other_requests = $event.target.value
                    }
                  }
                })
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }