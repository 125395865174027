<template>
  <modal-action
    class="cancel-reservation"
    :title="$t('reservation.do_you_really_want_to_cancel_this_reservation')"
    @close="$emit('close')"
  >
    <template #body>
      <div class="cancel-reservation__content">
        <div class="cancel-reservation__header">
          {{ $t('text.cancellation_reason') }}<span>{{ $t('common.required') }}</span>
        </div>
        <div class="cancel-reservation__list-reason">
          <div
            v-for="item in reasons"
            :key="item.id"
            :class="['cancel-reservation__item', { 'cancel-reservation__reason-other': isOtherReason(item.id) }]"
          >
            <radio
              :label="$t(`${item.key}`)"
              :val="item.id"
              :is-checked="item.id === reason_selected"
              name="reason"
              @select="chooseReason"
            />
            <div
              v-if="isOtherReason(item.id)"
              class="cancel-reservation__note"
            >
              <input-common
                :value="other_reason"
                :disabled="isNotOtherReason"
                :placeholder="$t('text.cancellation_note_placeholder')"
                :class="[{ 'common-input-error': errors.other_reason }]"
                @input="setLengthOtherReason"
              />
              <span
                v-if="errors.other_reason"
                class="common-msg-error"
              >
                {{ errors.other_reason }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <button-common
        class="cancel-reservation__submit"
        @click="handleCancel"
      >
        <template #content>
          {{ $t('common.cancel_reservation') }}
        </template>
      </button-common>
      <button-common
        class="cancel-reservation__close"
        @click="$emit('close')"
      >
        <template #content>
          {{ $t('common.no') }}
        </template>
      </button-common>
    </template>
  </modal-action>
</template>
<script>
  import ModalAction from '../common/ModalAction'
  import Input from '../common/Input'
  import Radio from '../common/Radio'
  import Button from '../common/Button'
  import helpers, { showErrorModal } from '../../utils/helpers'
  import { get } from 'lodash'

  export default {
    components: {
      ModalAction,
      InputCommon: Input,
      ButtonCommon: Button,
      Radio
    },
    props: {
      reservationId: {
        type: [Number, String],
        default: 0
      }
    },
    emits: ['close'],
    data () {
      return {
        other_id: 6,
        reasons: [
          { id: 1, name: '都合が悪くなったため', key: 'reservation.reason_cancel.inconvenient' },
          { id: 2, name: '面倒になったため', key: 'reservation.reason_cancel.troublesome' },
          { id: 3, name: '体調不良のため', key: 'reservation.reason_cancel.poor_physical_condition' },
          { id: 4, name: '他の矯正法に決めたため', key: 'reservation.reason_cancel.another_method' },
          { id: 5, name: '引越・転勤のため', key: 'reservation.reason_cancel.moving' },
          { id: 6, name: 'その他', key: 'reservation.reason_cancel.other' },
          { id: 7, name: '無断キャンセル', key: 'reservation.reason_cancel.unauthorized_cancellation' },
          { id: 8, name: '歯科矯正をやらないことにしたため', key: 'reservation.reason_cancel.dont_correct' },
          { id: 9, name: '料金、治療方針に納得がいかないため', key: 'reservation.reason_cancel.dont_satisfied_with_price' },
          { id: 10, name: '他院で予約したいため', key: 'reservation.reason_cancel.want_to_reservation_at_another_hospital' }
        ],
        other_reason: '',
        reason_selected: 0,
        errors: {
          other_reason: ''
        }
      }
    },
    computed: {
      isNotOtherReason () {
        return this.reason_selected !== this.other_id
      },
      prepareReason () {
        if (this.isNotOtherReason) {
          return get(this.reasons.find(reason => reason.id === this.reason_selected), 'name')
        }
        return `その他: ${helpers.mbtrim(this.other_reason)}`
      }
    },
    methods: {
      isOtherReason (id) {
        return id === this.other_id
      },
      setLengthOtherReason (value) {
        this.errors.other_reason = ''
        this.other_reason = value.substring(0, 240)
      },
      chooseReason (reasonId) {
        this.errors.other_reason = ''
        this.reason_selected = reasonId
      },
      handleCancel () {
        if (this.reason_selected === 0) {
          showErrorModal(this.$t('validation.reservation.cancelled_reason_required'))

          return
        }

        if (this.reason_selected === this.other_id && helpers.mbtrim(this.other_reason) === '') {
          showErrorModal(this.$t('validation.reservation.cancelled_reason_other_detail_required'))
          this.errors.other_reason = this.$t('validation.reservation.cancelled_reason_other_detail_required')

          return
        }

        this.$emit('submit', { reservation_id: this.reservationId, cancelled_reason: this.prepareReason })
      }
    }
  }
</script>
