<template>
  <div class="info-order">
    <div
      v-if="!form_data.is_patient_has_reserved_reservation"
      class="info-order__warning-block"
    >
      <img
        class="info-order__warning-icon"
        src="/images/warning-grey.svg"
      >
      <span
        class="info-order__warning-content"
      >{{ $t('objects.orders.message.patient_does_not_have_any_reserved_reservation') }}</span>
    </div>
    <div class="info-order__row">
      <div class="info-order__group">
        <label>{{ $t('objects.orders.text.patient_id') }}</label>
        <input-search
          :default-value="form_data.keyword_search_id"
          :disabled="form_data.data_order.patient.sf_user_id !== ''"
          :disable-select-patient="disable_select_patient"
          icon-disabled="/images/icon-close.svg"
          class="info-order__search"
          @search="searchIDPatient"
        />
      </div>
      <div class="info-order__group">
        <label>{{ $t('objects.orders.text.patient_name') }}</label>
        <input-search
          :placeholder="$t('text.select_patient_placeholder')"
          :default-value="form_data.keyword_search_name"
          :disabled="form_data.data_order.patient.sf_user_id !== ''"
          :disable-select-patient="disable_select_patient"
          icon-disabled="/images/icon-close.svg"
          class="info-order__search"
          type="search-name"
          @search="searchNamePatient"
        />
      </div>
      <div class="info-order__group">
        <label>{{ $t('objects.orders.text.patient_romaji_name') }}</label>
        <div
          v-if="form_data.data_order.patient.roma_first_name"
          class="info-order__patient three-dot"
        >
          <span>{{ form_data.data_order.patient.roma_last_name }} {{ form_data.data_order.patient.roma_first_name }}</span>
        </div>
      </div>
    </div>
    <div class="info-order__row info-order__row--custom">
      <div class="info-order__group">
        <label>{{ $t('objects.orders.text.clinic_name') }}</label>
        <div
          v-if="form_data.data_order.clinic.formal_name"
          class="info-order__patient three-dot"
        >
          <span>{{ form_data.data_order.clinic.formal_name }}</span>
        </div>
      </div>
      <div class="info-order__group">
        <label>{{ $t('objects.orders.text.person_in_charge') }}</label>
        <div
          v-if="form_data.staffs_name !== ''"
          class="info-order__patient"
        >
          <span>{{ form_data.staffs_name }}</span>
        </div>
      </div>
      <div class="info-order__group">
        <label>{{ $t('objects.orders.text.clean_line') }}</label>
        <common-select
          :class="['info-order__select', { 'info-order__select--red': form_data.data_order.order_visit_number === '' }]"
          :default-value="form_data.data_order.order_visit_number"
          :options="order_visit_number_option"
          :placeholder="$t('objects.orders.placeholder.visit_number')"
          key-value="value"
          key-display="text"
          @change="value => updateOrder(value)"
        />
      </div>
      <div class="info-order__note">
        <div class="note-item">
          <span class="note-item__content">{{ $t('objects.orders.text.order_visit_number_note') }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="order_history > 0 && sf_pre_contract_simulation > sf_pre_contract_simulation_constant.NOT_IMPLEMENTED"
      class="info-order__row info-order__row--full-width"
    >
      <div class="info-order__group info-order__group--simulation">
        <label>{{ $t('objects.orders.text.pre_contract_simulation') }}</label>
        <div class="info-order__simulation three-dot">
          <span>{{ reContractSimulation }}</span>
        </div>
      </div>
    </div>
    <modal-search-name-patient
      v-if="is_show_modal_search_name"
      :keyword="form_data.keyword_search_name"
      :is-required-sf-user-id="true"
      @select="setPatient"
      @close="is_show_modal_search_name = false"
    />
    <modal-search-id-patient
      v-if="is_show_modal_search_id"
      :keyword="form_data.keyword_search_id"
      :is-required-sf-user-id="true"
      @select="setPatient"
      @close="is_show_modal_search_id = false"
    />
    <search-patient
      v-if="is_show_modal_search_responsive"
      :keyword-id="form_data.keyword_search_id"
      :keyword-name="form_data.keyword_search_name"
      :search-by-id="search_by_id"
      :is-required-sf-user-id="true"
      @select="setPatient"
      @close="is_show_modal_search_responsive = false"
    />
  </div>
</template>

<script>
  import InputSearch from '../../components/common/InputSearch'
  import ModalSearchNamePatient from '../common/ModalSearchNamePatient'
  import ModalSearchIdPatient from '../common/ModalSearchIdPatient'
  import Select from '../common/Select'
  import * as moment from 'moment'
  import SearchPatient from './SearchPatient'
  import { showErrorModal } from '../../utils/helpers'
  import {
    SF_PRE_CONTRACT_SIMULATION,
    LAYOUT_MODE,
    SCREEN_WIDTH_SMALL,
    PATIENT_SF_STATUS_C
  } from '../../utils/constants'
  import { includes } from 'lodash'

  export default {
    components: {
      InputSearch,
      ModalSearchNamePatient,
      ModalSearchIdPatient,
      CommonSelect: Select,
      SearchPatient
    },
    props: {
      form: {
        type: Object,
        default: () => {}
      }
    },
    emits: [
      'update-info',
      'sf-pre-contract-simulation',
      'order-history',
      'is-show-sf-pre-contract-simulation',
      'is-show-first-contract-simulation'
    ],
    data () {
      return {
        form_data: {
          data_order: {
            patient: {
              id: '',
              sf_user_id: '',
              name: '',
              roma_last_name: '',
              roma_first_name: ''
            },
            order_visit_number: '',
            clinic: {
              sf_clinic_id: 0,
              formal_name: ''
            },
            delivery_date: ''
          },
          keyword_search_name: '',
          keyword_search_id: '',
          staffs_name: '',
          is_patient_has_reserved_reservation: true
        },
        is_show_modal_search_name: false,
        is_show_modal_search_id: false,
        is_show_modal_search_responsive: false,
        order_visit_number_option: [
          { value: 1, text: this.$t('objects.orders.text.order_visit_number_option.1') },
          { value: 3, text: this.$t('objects.orders.text.order_visit_number_option.3') },
          { value: 5, text: this.$t('objects.orders.text.order_visit_number_option.5') },
          { value: 6, text: this.$t('objects.orders.text.order_visit_number_option.6') },
          { value: 9, text: this.$t('objects.orders.text.order_visit_number_option.9') },
          { value: 10, text: this.$t('objects.orders.text.order_visit_number_option.10') },
          { value: 15, text: this.$t('objects.orders.text.order_visit_number_option.15') },
          { value: 'kids', text: this.$t('objects.orders.text.order_visit_number_option.kids') },
          { value: 0, text: this.$t('objects.orders.text.order_visit_number_option.0') }
        ],
        search_by_id: false,
        disable_select_patient: false,
        is_show_first_contract_simulation: 0,
        sf_pre_contract_simulation: SF_PRE_CONTRACT_SIMULATION.NOT_IMPLEMENTED,
        order_history: 0,
        is_show_sf_pre_contract_simulation: false,
        sf_pre_contract_simulation_constant: SF_PRE_CONTRACT_SIMULATION
      }
    },
    computed: {
      reContractSimulation () {
        return this.$t('objects.orders.text.sf_pre_contract_simulation')[this.sf_pre_contract_simulation]
      },
      currentLayoutMode () {
        return parseInt(this.$store.state.auth.layout_mode)
      }
    },
    created () {
      this.autoFillPatient()
    },
    mounted () {
      this.form_data.keyword_search_id = this.form.sf_user_id
      this.form_data.keyword_search_name = this.form.name_info
      this.form_data.data_order.patient.id = this.form.patient_id
      this.form_data.data_order.patient.sf_user_id = this.form.sf_user_id
      this.form_data.data_order.patient.roma_first_name = this.form.roma_first_name
      this.form_data.data_order.patient.roma_last_name = this.form.roma_last_name
      this.form_data.data_order.delivery_date = this.form.delivery_date
      this.form_data.data_order.clinic.formal_name = this.form.formal_name
      this.form_data.data_order.order_visit_number = this.form.order_visit_number
      this.form_data.staffs_name = this.form.staffs_name
      this.form_data.is_patient_has_reserved_reservation = this.form.is_patient_has_reserved_reservation
      this.is_show_sf_pre_contract_simulation = this.form.is_show_sf_pre_contract_simulation
      this.is_show_first_contract_simulation = this.form.is_show_first_contract_simulation
      this.order_history = this.form.order_history
      this.sf_pre_contract_simulation = this.form.sf_pre_contract_simulation
    },
    methods: {
      updateOrder (value) {
        this.form_data.data_order.order_visit_number = value
        this.$emit('info-data', this.form_data)
      },
      searchNamePatient (keyword) {
        if (this.form_data.data_order.patient.sf_user_id) {
          this.resetDataSearchPatient()

          return
        }

        this.form_data.keyword_search_name = keyword
        if (
          this.currentLayoutMode === LAYOUT_MODE.SP ||
          (!this.currentLayoutMode && window.innerWidth <= SCREEN_WIDTH_SMALL)
        ) {
          this.is_show_modal_search_responsive = true
          this.search_by_id = false
        } else {
          this.is_show_modal_search_name = true
        }
      },
      searchIDPatient (keyword) {
        if (this.form_data.data_order.patient.sf_user_id) {
          this.resetDataSearchPatient()

          return
        }

        this.form_data.keyword_search_id = keyword
        if (
          this.currentLayoutMode === LAYOUT_MODE.SP ||
          (!this.currentLayoutMode && window.innerWidth <= SCREEN_WIDTH_SMALL)
        ) {
          this.is_show_modal_search_responsive = true
          this.search_by_id = true
        } else {
          this.is_show_modal_search_id = true
        }
      },
      setPatient (patient) {
        this.form_data.data_order.patient.id = patient.id
        this.form_data.data_order.patient.sf_user_id = patient.sf_user_id
        this.form_data.data_order.patient.name = patient.name
        this.form_data.data_order.patient.roma_last_name = patient.roma_last_name
        this.form_data.data_order.patient.roma_first_name = patient.roma_first_name
        this.is_show_modal_search_name = false
        this.is_show_modal_search_id = false
        this.is_show_modal_search_responsive = false
        this.form_data.keyword_search_name = patient.name
        this.form_data.keyword_search_id = patient.sf_user_id || patient.id
        this.form_data.is_patient_has_reserved_reservation = this.isPatientHasReservedReservation(patient)
        this.getInfoClinic(patient)
        this.getPatientPersonInCharge()
        this.order_history = patient.order_count
        this.$emit('order-history', patient.order_count)
        this.is_show_sf_pre_contract_simulation = true
        this.$emit('is-show-sf-pre-contract-simulation', this.is_show_sf_pre_contract_simulation)
        this.sf_pre_contract_simulation = patient.sf_pre_contract_simulation
        this.$emit('sf-pre-contract-simulation', patient.sf_pre_contract_simulation)
      },
      resetDataSearchPatient () {
        this.form_data.data_order.patient.id = ''
        this.form_data.data_order.patient.sf_user_id = ''
        this.form_data.data_order.patient.name = ''
        this.form_data.data_order.patient.roma_last_name = ''
        this.form_data.data_order.patient.roma_first_name = ''
        this.form_data.staffs_name = ''
        this.form_data.keyword_search_id = ''
        this.form_data.keyword_search_name = ''
        this.form_data.is_patient_has_reserved_reservation = true
        this.order_history = 0
        this.sf_pre_contract_simulation = SF_PRE_CONTRACT_SIMULATION.NOT_IMPLEMENTED
        this.is_show_sf_pre_contract_simulation = false
        this.$emit('order-history', this.order_history)
        this.$emit('sf-pre-contract-simulation', this.sf_pre_contract_simulation)

        this.$emit('info-data', this.form_data)
      },
      getInfoClinic (patient) {
        const dataClinic = this.$store.getters.currentUser
        const isInstructionAdmin = this.$store.getters.isInstructionAdmin

        if (typeof dataClinic !== 'undefined' && !isInstructionAdmin) {
          this.form_data.data_order.clinic.sf_clinic_id = dataClinic.clinic.sf_clinic_id
          this.form_data.data_order.clinic.formal_name = dataClinic.clinic.formal_name
        } else {
          this.form_data.data_order.clinic.sf_clinic_id = patient.sf_clinic_id
          this.form_data.data_order.clinic.formal_name = patient.formal_name
        }
        this.form_data.data_order.delivery_date = moment().add(21, 'days').format('YYYY/MM/DD')
      },
      getPatientPersonInCharge () {
        this.$store.dispatch('getPatientPersonInCharge', this.form_data.data_order.patient.id).then((result) => {
          const { data: { results } } = result
          if (results) {
            const arrNameStaffs = []
            Object.entries(results).forEach(item => {
              if (item[1].staff.position_id === 1) {
                arrNameStaffs.push(item[1].staff.name)
              }
            })
            this.form_data.staffs_name = arrNameStaffs.join(', ')
          }
          this.$emit('info-data', this.form_data)
        })
      },
      autoFillPatient () {
        const sfUserId = this.$store.state.order.sf_user_id_create_order
        this.$store.commit('setPatientToCreate', 0)

        if (!sfUserId) {
          return
        }

        const params = {
          search_keyword: sfUserId
        }

        this.$store.dispatch('getPatientsById', params).then(response => {
          const results = response?.data?.results?.data || []

          if (results.length) {
            const patientInfo = results[0]
            this.setPatient({
              id: patientInfo.patient_id,
              name: `${patientInfo.last_name || ''} ${patientInfo.first_name || ''}`,
              sf_user_id: patientInfo.sf_user_id,
              sf_status_c: patientInfo.sf_status_c,
              first_reservation_id: patientInfo.first_reservation_id || 0,
              brand_text: patientInfo.brand_master_name,
              roma_last_name: patientInfo.roma_last_name,
              roma_first_name: patientInfo.roma_first_name,
              order_count: patientInfo.order_count,
              sf_pre_contract_simulation: patientInfo.sf_pre_contract_simulation
            })
            this.disable_select_patient = true

            return
          }

          showErrorModal(this.$t('new_message.not_found'))
        })
      },
      isPatientHasReservedReservation (patient) {
        return includes(
          [
            PATIENT_SF_STATUS_C.NEW,
            PATIENT_SF_STATUS_C.VISITED,
            PATIENT_SF_STATUS_C.EXISTS
          ],
          patient.sf_status_c
        ) &&
          patient.first_reservation_id > 0
      }
    }
  }
</script>
