var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "common-list common-list__patient-services" },
    [
      _c("table", { staticClass: "main-list" }, [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "common-list__services-category" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("objects.patient.text.service_category")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "common-list__overall-services" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("objects.patient.text.overall_service")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "common-list__number-services" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("objects.patient.text.number_services")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "common-list__remaining_service" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("objects.patient.text.remaining_service")) +
                  "\n        "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.serviceSummary, function(item, key) {
            return _c("tr", { key: key }, [
              _c("td", { staticClass: "common-list__services-category" }, [
                _vm._v("\n          " + _vm._s(item.cat_name) + "\n          "),
                _c("span", [_vm._v("(" + _vm._s(item.name) + ")")])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "common-list__overall-services" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.getIntVal(item.total_count)) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "common-list__number-services" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.getIntVal(item.remain_count)) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "common-list__remaining_service" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.getPrice(item.remain_amount)) +
                    "\n        "
                )
              ])
            ])
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }