var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "interview-content-consultation-v2" },
    [
      _c("according-block", {
        attrs: {
          label: _vm.$t("objects.patient.text.consultation_content"),
          checked: _vm.show_block
        },
        on: {
          handleClick: function($event) {
            _vm.show_block = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show_block,
              expression: "show_block"
            }
          ],
          staticClass:
            "interview-content-consultation-v2__content arrow-toggle-content page-content"
        },
        [
          _c(
            "div",
            { staticClass: "interview-content-consultation-v2__group" },
            [
              _c("div", { staticClass: "page-content" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("objects.patient.text.content_of_consultation")
                      ) +
                      "\n          "
                  ),
                  _c("span", { staticClass: "required" }, [_vm._v("*")])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group interview-content-consultation-v2__group-padding"
                  },
                  [
                    _vm.errors.consultation_item
                      ? _c("p", { staticClass: "error-message" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.consultation_item) +
                              "\n          "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-inline" },
                      [
                        _c("checkbox-common", {
                          attrs: {
                            label: _vm.$t(
                              "objects.patient.text.orthodontic_treatment"
                            ),
                            val: "矯正歯科治療"
                          },
                          on: { input: _vm.handleConsultation },
                          model: {
                            value: _vm.consultation_item,
                            callback: function($$v) {
                              _vm.consultation_item = $$v
                            },
                            expression: "consultation_item"
                          }
                        }),
                        _vm._v(" "),
                        _c("checkbox-common", {
                          attrs: {
                            label: _vm.$t("objects.patient.text.whitening"),
                            val: "ホワイトニング"
                          },
                          on: { input: _vm.handleConsultation },
                          model: {
                            value: _vm.consultation_item,
                            callback: function($$v) {
                              _vm.consultation_item = $$v
                            },
                            expression: "consultation_item"
                          }
                        }),
                        _vm._v(" "),
                        _c("checkbox-common", {
                          attrs: {
                            label: _vm.$t("objects.patient.text.cleaning"),
                            val: "クリーニング"
                          },
                          on: { input: _vm.handleConsultation },
                          model: {
                            value: _vm.consultation_item,
                            callback: function($$v) {
                              _vm.consultation_item = $$v
                            },
                            expression: "consultation_item"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "consultation-other" },
                          [
                            _c("checkbox-common", {
                              attrs: {
                                label: _vm.$t("new_text.other"),
                                val: "その他"
                              },
                              on: { input: _vm.handleConsultation },
                              model: {
                                value: _vm.consultation_item,
                                callback: function($$v) {
                                  _vm.consultation_item = $$v
                                },
                                expression: "consultation_item"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { class: ["other-input", _vm.disableOtherInput] },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.consultation_item_other,
                                      expression: "consultation_item_other"
                                    }
                                  ],
                                  staticClass: "form-control w-100",
                                  attrs: {
                                    type: "text",
                                    name: "",
                                    placeholder: _vm.$t(
                                      "new_text.placeholder.other_fill"
                                    )
                                  },
                                  domProps: {
                                    value: _vm.consultation_item_other
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.consultation_item_other =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-content" },
            [
              _c("h2", { staticClass: "title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("objects.patient.text.areas_concern")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _vm._l(_vm.priority, function(value) {
                return _c(
                  "Item",
                  {
                    key: value,
                    ref: "priority",
                    refInFor: true,
                    attrs: {
                      type: "priority",
                      index: value - 1,
                      "custom-class": value > 1 ? "border-top" : ""
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.getPriorityText(value)) +
                        "\n      "
                    )
                  ]
                )
              })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }