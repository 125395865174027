var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comment-tab" },
    [
      _c("div", { staticClass: "comment-tab__form" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.memo,
              expression: "memo"
            }
          ],
          class: [
            "comment-tab__input",
            { "common-input-error": _vm.is_show_error_msg }
          ],
          attrs: {
            placeholder: _vm.$t("objects.patient.placeholder.post_comment"),
            rows: "1"
          },
          domProps: { value: _vm.memo },
          on: {
            keydown: _vm.styleHeightTextArea,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.memo = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "comment-tab__button", on: { click: _vm.addMemo } },
          [_vm._v("\n      " + _vm._s(_vm.$t("new_text.send")) + "\n    ")]
        )
      ]),
      _vm._v(" "),
      _vm.is_show_error_msg
        ? _c("span", { staticClass: "common-msg-error" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("new_validation.patient.comment_required")) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "comment-tab__list" },
        [
          _vm._l(_vm.comments_data, function(activity, index) {
            return [
              _c("comment-item", {
                key: index,
                attrs: { activity: activity, author: _vm.patientInfo.name },
                on: { "delete-memo": _vm.handleDeleteMemo }
              })
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.page < _vm.last_page
        ? _c("infinite-loading", {
            on: { infinite: _vm.infiniteScrollHandler },
            scopedSlots: _vm._u(
              [
                {
                  key: "no-more",
                  fn: function() {
                    return [_c("span", { staticClass: "d-none" })]
                  },
                  proxy: true
                },
                {
                  key: "no-results",
                  fn: function() {
                    return [_c("span", { staticClass: "d-none" })]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1445979750
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }