<template>
    <div :class="[className]">
        <sub-question
            ref="sub_question"
        :question="question"
        :options="option_yes_no"
        :checked="select_option"
        @handleAnswer="handleAnswer($event)"
        ></sub-question>
        <div v-bind:class="handleChildAnswerClass">
            <div class="form-group">
                <label class="color-blue">いつ頃：</label>
                <input v-model="time" class="form-control" type="text" name="">
            </div>
            <div class="form-group">
                <label class="color-blue">症状：</label>
                <input v-model="symptom" class="form-control" type="text" name="">
            </div>
            <div class="form-group">
                <label class="color-blue">矯正装置：</label>
                <div class="form-inline justify-content-between">
                    <checkbox-common 
                        v-model="selections" 
                        label="マウスピース（アライナー）" 
                        val="マウスピース（アライナー）">
                    </checkbox-common>
                    <checkbox-common 
                        v-model="selections" 
                        label="ワイヤー" 
                        val="ワイヤー">
                    </checkbox-common>
                </div>
                <div class="form-inline selection-other-option pt-3">
                    <checkbox-common 
                        v-model="selections" 
                        label="その他"
                        val="その他">
                    </checkbox-common>
                    <div :class="['other-input', diableOtherInput]">
                        <input v-model="other" class="form-control w-100" type="text">  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SubQuestion from '../template/SubQuestion'
    import CheckboxCommon from '../template/CheckboxCommon'
    import { INTERVIEW_COMMON_OPTIONS } from '../../../../utils/constants'
    import Common from "./CommonFunction";

    export default {
        components: {
            SubQuestion,
            CheckboxCommon
        },
        props: ['className'],
        mixins: [Common],
        data() {
            return {
                number: 2,
                select_option: INTERVIEW_COMMON_OPTIONS.NO,
                question: '過去に矯正歯科治療された事はありますか？',
                option_yes_no: [
                    INTERVIEW_COMMON_OPTIONS.YES,
                    INTERVIEW_COMMON_OPTIONS.NO,
                ],
                time: '',
                symptom: '',
                selections: [],
                other: '',
                translate: {
                    いつ頃: 'time', 
                    症状: 'symptom', 
                    矯正装置: 'selections', 
                    その他: 'other'
                },
            }
        }
    }
</script>
