var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-inner bg-white interview-block" }, [
    _c("div", { staticClass: "page-content" }, [
      _c("div", { staticClass: "header-title" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("interview.prior_consultation")))]),
        _vm._v(" "),
        _c("p", {
          staticClass: "gray-arrow",
          class: { "toggle-icon": _vm.show },
          on: { click: _vm.handleClick }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ],
          staticClass: "pre-interview-page content-block"
        },
        [
          _c("div", { staticClass: "patient-detail-left" }, [
            _c("div", { staticClass: "row-content" }, [
              _c(
                "div",
                { staticClass: "col-6 pre-block" },
                [
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-1",
                      attrs: {
                        question:
                          "他の医院で歯並びの相談をされたことはありますか？",
                        answer: _vm.answers.question_1.answer,
                        question_id: _vm.answers.question_1.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "content-text" },
                          _vm._l(_vm.answers.question_1.items.details, function(
                            detail,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "form-group d-flex" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label same-width my-0"
                                  },
                                  [_vm._v(_vm._s(detail.title))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "control-text my-0" },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(detail.content) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-3",
                      attrs: {
                        question: "アレルギーはありますか?",
                        answer: _vm.answers.question_3.answer,
                        question_id: _vm.answers.question_3.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _vm.answers.question_3.view_content_1
                            ? _c("div", { staticClass: "control-text my-0" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.answers.question_3.view_content_1
                                    ) +
                                    "あり\n                  "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [_vm._v("金属名：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.answers.question_3.view_content_2
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_3.view_content_3
                            ? _c("div", { staticClass: "control-text my-0" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.answers.question_3.view_content_3
                                    ) +
                                    "あり\n                  "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [_vm._v("薬品名：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.answers.question_3.view_content_4
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_3.view_content_5
                            ? _c("div", { staticClass: "control-text my-0" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.answers.question_3.view_content_5
                                    ) +
                                    "あり\n                  "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [_vm._v("発症時期：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.answers.question_3.view_content_6
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [_vm._v("原因植物：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.answers.question_3.view_content_7
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.answers.question_3.view_content_8
                            ? _c("div", { staticClass: "control-text my-0" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.answers.question_3.view_content_8
                                    ) +
                                    "あり\n                  "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [_vm._v("原因物質：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.answers.question_3.view_content_9
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-6",
                      attrs: {
                        question: "今までに口や顔の周りの癖はありましたか？",
                        answer: _vm.answers.question_6.answer,
                        question_id: _vm.answers.question_6.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.answers.question_6.view_content_1
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.answers.question_6.view_content_2) +
                                "\n                  "
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-7",
                      attrs: {
                        question:
                          "喋りにくい言葉はありますか？<br>（サ行、タ行が言いづらいなど）",
                        answer: _vm.answers.question_7.answer,
                        question_id: _vm.answers.question_7.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("objects.patient.text.specifically")
                                  ) + ":"
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(_vm.answers.question_7.view_content) +
                                  "\n                    "
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-10",
                      attrs: {
                        question:
                          "家族に不正咬合の方はいますか？例：でこぼこ、出っ歯、反っ歯、受け口など",
                        answer: _vm.answers.question_10.answer,
                        question_id: _vm.answers.question_10.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "content-text" },
                          _vm._l(_vm.answers.question_10.items, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "d-flex" },
                              [
                                _c("div", { staticClass: "number-answer" }, [
                                  _vm._v(_vm._s(index + 1))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "content-text" },
                                  _vm._l(item.details, function(detail, i) {
                                    return _c("div", { key: i }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group d-flex" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "control-label same-width my-0"
                                            },
                                            [_vm._v(_vm._s(detail.title) + ":")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "control-text my-0"
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm.convertOptions(detail)
                                                  ) +
                                                  "\n                          "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-12",
                      attrs: {
                        question: "現在、通院されていますか？",
                        answer: _vm.answers.question_12.answer,
                        question_id: _vm.answers.question_12.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "content-text" },
                          _vm._l(_vm.answers.question_12.items, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "d-flex" },
                              [
                                _c("div", { staticClass: "number-answer" }, [
                                  _vm._v(_vm._s(index + 1))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "content-text" },
                                  _vm._l(item.details, function(detail, i) {
                                    return _c("div", { key: i }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group d-flex" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "control-label same-width my-0"
                                            },
                                            [_vm._v(_vm._s(detail.title) + ":")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "control-text my-0"
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm.convertOptions(detail)
                                                  ) +
                                                  "\n                          "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-14",
                      attrs: {
                        question: "今までにかかった病気はありますか？",
                        answer: _vm.answers.question_14.answer,
                        question_id: _vm.answers.question_14.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.convertOptions(
                                      _vm.answers.question_14.view_content
                                    )
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      attrs: {
                        question: "今までに次のようなことがありましたか？",
                        answer: _vm.answers.question_15.answer,
                        question_id: _vm.answers.question_15.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(_vm.answers.question_15.view_content) +
                                  "\n                    "
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-17",
                      attrs: {
                        question: "喫煙習慣はありますか？",
                        answer: _vm.answers.question_17.answer,
                        question_id: _vm.answers.question_17.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [_vm._v("一日量：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.convertOptions(
                                      _vm.answers.question_17.items.details[0]
                                    )
                                  ) +
                                  " " +
                                  _vm._s(_vm.$t("interview.today")) +
                                  "\n                    "
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-18",
                      attrs: {
                        question: "飲酒習慣はありますか？",
                        answer: _vm.answers.question_18.answer,
                        question_id: _vm.answers.question_18.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [_vm._v("よく飲まれるお酒：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.answers.question_18.view_content_1
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [_vm._v("一日量：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(_vm.answers.question_18.view_content_2)
                              ),
                              _c("span", { staticClass: "p-2" }, [
                                _vm._v("mlまたは")
                              ]),
                              _vm._v(
                                _vm._s(_vm.answers.question_18.view_content_3) +
                                  " 合位\n                    "
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-21",
                      attrs: {
                        question: "血圧の状態は？",
                        answer: _vm.answers.question_21.answer,
                        no_answer: true,
                        question_id: _vm.answers.question_21.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c("label", [
                              _vm._v(
                                _vm._s(_vm.answers.question_21.view_content_1)
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("interview.highest")) + "："
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.answers.question_21.view_content_2
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("interview.lowest")) + "："
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.answers.question_21.view_content_3
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-24",
                      attrs: {
                        question: "最近、歯科医院を受診されましたか？",
                        answer: _vm.answers.question_24.answer,
                        question_id: _vm.answers.question_24.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "control-text" }, [
                              _vm._v(
                                _vm._s(_vm.answers.question_24.view_content_1)
                              ),
                              _c("span", { staticClass: "p-2" }, [
                                _vm._v("年")
                              ]),
                              _vm._v(
                                _vm._s(_vm.answers.question_24.view_content_2)
                              ),
                              _c("span", { staticClass: "p-2" }, [
                                _vm._v("月ごろ")
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "control-text" }, [
                              _c("span", { staticClass: "p-2" }, [
                                _vm._v("または")
                              ]),
                              _vm._v(
                                _vm._s(_vm.answers.question_24.view_content_3)
                              ),
                              _c("span", { staticClass: "p-2" }, [
                                _vm._v("年")
                              ]),
                              _vm._v(
                                _vm._s(_vm.answers.question_24.view_content_4)
                              ),
                              _c("span", { staticClass: "p-2" }, [
                                _vm._v("ヶ月前")
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [_vm._v("治療内容：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                _vm._s(_vm.answers.question_24.view_content_5)
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-6 pre-block" },
                [
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-2",
                      attrs: {
                        question: "過去に矯正歯科治療されたことはありますか？",
                        answer: _vm.answers.question_2.answer,
                        question_id: _vm.answers.question_2.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [_vm._v("いつ頃：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.answers.question_2.view_content_1
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [_vm._v("症状：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.answers.question_2.view_content_2
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [_vm._v("矯正装置：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.answers.question_2.view_content_3
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      attrs: {
                        question: "顎の症状で今までに何か経験がありますか？",
                        answer: _vm.answers.question_4.answer,
                        question_id: _vm.answers.question_4.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c(
                              "label",
                              { staticClass: "control-label same-width my-0" },
                              [_vm._v("いつ頃：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.answers.question_4.view_content_1
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.answers.question_4.view_content_2
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-5",
                      attrs: {
                        question:
                          "今まで顔や顎を強くぶつけた・ケガをした経験はありますか？",
                        answer: _vm.answers.question_5.answer,
                        question_id: _vm.answers.question_5.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "content-text" },
                          _vm._l(_vm.answers.question_5.items.details, function(
                            detail,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "form-group d-flex" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label same-width my-0"
                                  },
                                  [_vm._v(_vm._s(detail.title))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "control-text my-0" },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(detail.content) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-8",
                      attrs: {
                        question: "次のような症状はありますか？",
                        answer: _vm.answers.question_8.answer,
                        question_id: _vm.answers.question_8.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.convertOptions(
                                      _vm.answers.question_8.view_content
                                    )
                                  ) +
                                  "\n                    "
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-pre" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "content-text" }, [
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c("div", { staticClass: "control-text my-0" }, [
                            _vm._v(
                              "\n                      " +
                                _vm._s(_vm.answers.question_9.view_content) +
                                "\n                    "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-11",
                      attrs: {
                        question: "今まで耳鼻科を受診したことはありますか？",
                        answer: _vm.answers.question_11.answer,
                        question_id: _vm.answers.question_11.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "content-text" },
                          _vm._l(_vm.answers.question_11.items, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "d-flex" },
                              [
                                _c("div", { staticClass: "number-answer" }, [
                                  _vm._v(_vm._s(index + 1))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "content-text" },
                                  _vm._l(item.details, function(detail, i) {
                                    return _c("div", { key: i }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group d-flex" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "control-label same-width my-0"
                                            },
                                            [_vm._v(_vm._s(detail.title) + ":")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "control-text my-0"
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm.convertOptions(detail)
                                                  ) +
                                                  "\n                          "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-13",
                      attrs: {
                        question:
                          "身体全体で先天性の疾患や生まれつき気になる事、部位はありますか？",
                        answer: _vm.answers.question_13.answer,
                        question_id: _vm.answers.question_13.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "content-text" },
                          _vm._l(_vm.answers.question_13.items, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "d-flex" },
                              [
                                _c("div", { staticClass: "number-answer" }, [
                                  _vm._v(_vm._s(index + 1))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "content-text" },
                                  _vm._l(item.details, function(detail, i) {
                                    return _c("div", { key: i }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group d-flex" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "control-label same-width my-0"
                                            },
                                            [_vm._v(_vm._s(detail.title) + ":")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "control-text my-0"
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm.convertOptions(detail)
                                                  ) +
                                                  "\n                          "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-16",
                      attrs: {
                        question: "現在飲んでいる薬はありますか？",
                        answer: _vm.answers.question_16.answer,
                        question_id: _vm.answers.question_16.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "content-text" },
                          [
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.answers.question_16.view_content) +
                                  "\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.answers.question_16.custom_items,
                              function(details, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "d-flex" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "number-answer" },
                                      [_vm._v(_vm._s(index + 1))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "content-text" },
                                      _vm._l(details, function(detail, i) {
                                        return _c("div", { key: i }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "form-group d-flex"
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "control-label same-width my-0"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(detail.title) + ":"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "control-text my-0"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(
                                                        _vm.convertOptions(
                                                          detail
                                                        )
                                                      ) +
                                                      "\n                          "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      }),
                                      0
                                    )
                                  ]
                                )
                              }
                            )
                          ],
                          2
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-pre question-no-19" }, [
                    _c("div", { staticClass: "answer" }, [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c("label", { staticClass: "same-width my-0" }, [
                              _vm._v("身長")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.answers.question_19.view_content_1
                                  ) +
                                  " cm\n                      "
                              )
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-pre question-no-19" }, [
                    _c("div", { staticClass: "answer" }, [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c("label", { staticClass: "same-width my-0" }, [
                              _vm._v("体重")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.answers.question_19.view_content_2
                                  ) +
                                  " kg\n                      "
                              )
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-pre question-no-20" }, [
                    _c("div", { staticClass: "answer" }, [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c("label", { staticClass: "same-width my-0" }, [
                              _vm._v("血液型")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.answers.question_20.view_content) +
                                  "\n                      "
                              )
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-22",
                      attrs: {
                        question: "女性の方のみ",
                        answer: _vm.answers.question_22.answer,
                        no_answer: true,
                        question_id: _vm.answers.question_22.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(_vm.answers.question_22.view_content_1)
                              ),
                              _c("span", { staticClass: "p-2" }, [
                                _vm._v("歳")
                              ]),
                              _vm._v(
                                _vm._s(_vm.answers.question_22.view_content_2)
                              ),
                              _c("span", { staticClass: "pt-2" }, [
                                _vm._v("ヶ月")
                              ])
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "answer-common",
                    {
                      staticClass: "question-no-23",
                      attrs: {
                        question:
                          "以前に歯科医院で歯を抜いたことがありますか？",
                        answer: _vm.answers.question_23.answer,
                        question_id: _vm.answers.question_23.question
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "content-text" }, [
                          _c("div", { staticClass: "form-group d-flex" }, [
                            _c("div", { staticClass: "control-text my-0" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(_vm.answers.question_23.view_content) +
                                  "\n                    "
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "question" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "control-text" }, [
          _vm._v("寝相はどの状態が多いですか？")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }