export const state = {
  loading: false,
  loading_count: 0
}

export const mutations = {
  set_loading (state, loading) {
    state.loading = loading
  },
  set_loading_count (state, value) {
    state.loading_count = value
  }
}

export const getters = {
  loading_count: function (state) {
    return state.loading_count
  },
  isLoading: function (state) {
    return state.loading
  }
}
