var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notify-menu" }, [
    _c("h2", { staticClass: "notify-menu__title" }, [
      _vm._v(
        "\n    " + _vm._s(_vm.$t("notification_page.notification")) + "\n  "
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        class:
          "notify-menu__list " +
          (_vm.isNoData ? "notify-menu__list--no-data" : "")
      },
      [
        _c(
          "ul",
          { staticClass: "list-patient" },
          _vm._l(_vm.notifications, function(item, index) {
            return _c(
              "li",
              {
                key: index,
                class: [
                  "item item-patient",
                  { active: item.id == _vm.selected }
                ]
              },
              [
                _c("div", { staticClass: "list-patient__after" }, [
                  _c("div", { staticClass: "list-patient__date" }, [
                    _c("div", { staticClass: "list-patient__label" }, [
                      _c(
                        "span",
                        {
                          class: [
                            "label-span",
                            item.reservation_status === "reserve"
                              ? "label--blue"
                              : "label--red"
                          ]
                        },
                        [_vm._v(_vm._s(_vm.getNotifyType(item)))]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list-patient__fl" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.formatDateTimeNotify(
                              item.created_at,
                              _vm.$t("datetime.year_month_day")
                            )
                          ) +
                          "\n              "
                      ),
                      _c("span", { staticClass: "date-time" }, [
                        _vm._v(_vm._s(_vm.getTime(item.created_at)))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "notify-detail" }, [
                    _c(
                      "span",
                      {
                        staticClass: "notify-detail__clone",
                        on: { click: _vm.cloneDetail }
                      },
                      [
                        _c("img", {
                          attrs: { src: "/images/icon-close-gray.svg" }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "notify-detail__head" }, [
                      _c("p", { staticClass: "content-furigana" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.fillFuriganaName(
                                item.reservation.patient_info
                              )
                            ) +
                            "\n              "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list-patient__box" }, [
                        _c("p", [
                          _vm._v(
                            "\n                  " +
                              _vm._s(item.reservation.patient_info.name) +
                              _vm._s(_vm.$t("notification_page.mr")) +
                              " " +
                              _vm._s(
                                _vm.fillAge(item.reservation.patient_info)
                              ) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "list-patient__right" }, [
                          _c("span", { staticClass: "mark-bg" }, [
                            _vm._v("ID")
                          ]),
                          _vm._v(
                            _vm._s(
                              item.reservation.sf_user_id ||
                                item.reservation.patient_id
                            ) + "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          { staticClass: "btn-v2", attrs: { type: "button" } },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "label-span label--blueBtn",
                                attrs: {
                                  to: {
                                    name: "patient.detail",
                                    params: { id: item.reservation.patient_id },
                                    query: {
                                      reservation_id: item.reservation_id
                                    }
                                  },
                                  target: "_blank"
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "notification_page.display_patient_information"
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list-patient__reservation" }, [
                        _c("p", { staticClass: "mark-bg" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm.$t(
                                  "notification_page.reservation_date_and_time"
                                )
                              ) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "date-time" }, [
                          _c("span", { staticClass: "number" }, [
                            _vm._v(
                              _vm._s(_vm.getYear(item.reservation.started_to))
                            )
                          ]),
                          _vm._v("年\n                  "),
                          _c("span", { staticClass: "number" }, [
                            _vm._v(
                              _vm._s(_vm.getMonth(item.reservation.started_to))
                            )
                          ]),
                          _vm._v("月\n                  "),
                          _c("span", { staticClass: "number" }, [
                            _vm._v(
                              _vm._s(_vm.getDay(item.reservation.started_to))
                            )
                          ]),
                          _vm._v("日\n                  "),
                          _c(
                            "span",
                            { staticClass: "number display-content" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDateTimeNotify(
                                    item.reservation.started_to,
                                    "HH:mm"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._v("〜\n                  "),
                          _c("span", { staticClass: "number" }, [
                            _vm._v(
                              _vm._s(
                                _vm.formatDateTimeNotify(
                                  item.reservation.ended_from,
                                  "HH:mm"
                                )
                              )
                            )
                          ])
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notify-detail__cont" }, [
                      _c(
                        "button",
                        { staticClass: "btn-v2 notify-detail__top" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "label-span label--blueBtn",
                              attrs: {
                                to: {
                                  name: "reservation.list",
                                  query: {
                                    search_date: _vm.formatDate(
                                      item.reservation.started_to
                                    ),
                                    reservation_id: item.reservation.id
                                  }
                                },
                                target: "_blank"
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("notification_page.show_reservation")
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("h4", { staticClass: "cont__title" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.reservation.allocation.column_name) +
                            "\n              "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "mark-bg" }, [
                        _vm._v("\n                メモ\n              ")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "notify-detail__memo" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.reservation.memo) +
                            "\n              "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn-v2 notify-detail__bottom",
                          on: { click: _vm.cloneDetail }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("common.close")) +
                              "\n              "
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  item.readed_at == null
                    ? _c("span", {
                        staticClass: "round-active round-in round--mg"
                      })
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "list-patient__before",
                    on: {
                      click: function($event) {
                        return _vm.showDetail(item.id)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "list-patient__date" }, [
                      _c("div", { staticClass: "list-patient__label" }, [
                        _c(
                          "span",
                          {
                            class: [
                              "label-span",
                              item.reservation_status === "reserve"
                                ? "label--blue"
                                : "label--red"
                            ]
                          },
                          [_vm._v(_vm._s(_vm.getNotifyType(item)))]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list-patient__fl" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.formatDateTimeNotify(
                                item.created_at,
                                _vm.$t("datetime.year_month_day")
                              )
                            ) +
                            "\n              "
                        ),
                        _c("span", { staticClass: "date-time" }, [
                          _vm._v(_vm._s(_vm.getTime(item.created_at)))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list-patient__content" }, [
                      _c("span", { staticClass: "content-furigana" }, [
                        _vm._v(
                          _vm._s(
                            _vm.fillFuriganaName(item.reservation.patient_info)
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list-patient__box" }, [
                        _c("p", [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.reservation.patient_info.name) +
                              _vm._s(_vm.$t("notification_page.mr")) +
                              " " +
                              _vm._s(
                                _vm.fillAge(item.reservation.patient_info)
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "list-patient__right" }, [
                          _c("span", { staticClass: "mark-bg" }, [
                            _vm._v("ID")
                          ]),
                          _vm._v(
                            _vm._s(
                              item.reservation.sf_user_id ||
                                item.reservation.patient_id
                            ) + "\n              "
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list-patient__reservation" }, [
                      _c("p", { staticClass: "mark-bg" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "notification_page.reservation_date_and_time"
                              )
                            ) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "date-time" }, [
                        _c("span", { staticClass: "number" }, [
                          _vm._v(
                            _vm._s(_vm.getYear(item.reservation.started_to))
                          )
                        ]),
                        _vm._v("年\n              "),
                        _c("span", { staticClass: "number" }, [
                          _vm._v(
                            _vm._s(_vm.getMonth(item.reservation.started_to))
                          )
                        ]),
                        _vm._v("月\n              "),
                        _c("span", { staticClass: "number" }, [
                          _vm._v(
                            _vm._s(_vm.getDay(item.reservation.started_to))
                          )
                        ]),
                        _vm._v("日\n              "),
                        _c("span", { staticClass: "number display-content" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formatDateTimeNotify(
                                item.reservation.started_to,
                                "HH:mm"
                              )
                            )
                          )
                        ]),
                        _vm._v("〜\n              "),
                        _c("span", { staticClass: "number" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formatDateTimeNotify(
                                item.reservation.ended_from,
                                "HH:mm"
                              )
                            )
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    item.readed_at == null
                      ? _c("span", { staticClass: "round-active round--mg" })
                      : _vm._e()
                  ]
                )
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "infinite-loading",
          {
            attrs: { identifier: _vm.infinite_scroll_id },
            on: { infinite: _vm.infiniteScrollHandler },
            scopedSlots: _vm._u([
              {
                key: "no-more",
                fn: function() {
                  return [_c("span", { staticClass: "d-none" })]
                },
                proxy: true
              }
            ])
          },
          [
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isNoData,
                    expression: "isNoData"
                  }
                ],
                staticClass: "no-notification",
                attrs: { slot: "no-results" },
                slot: "no-results"
              },
              [
                _c("div", { staticClass: "no-notification__cont" }, [
                  _c("img", { attrs: { src: "/images/icon-alarm.svg" } }),
                  _vm._v(" "),
                  _c("h5", { staticClass: "no-notification__title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("notification_page.currently_no_notifications")
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "no-notification__text",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("notification_page.makes_new_reservation")
                      )
                    }
                  })
                ])
              ]
            )
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isNoData,
            expression: "!isNoData"
          }
        ],
        staticClass: "mark-all"
      },
      [
        _c("p", { staticClass: "mark-all__note" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t("notification_page.notice_will_be_deleted_in_30_days")
              ) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "mark-all__read", on: { click: _vm.readAll } }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("notification_page.mark_all_as_read")) +
              "\n    "
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }