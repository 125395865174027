var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-action", {
    staticClass: "modal-ip-setting modal-ip-setting--create",
    attrs: {
      title: _vm.$t("objects.setting.text.create_ip_setting"),
      "is-show-cancel-button": false
    },
    on: {
      close: function($event) {
        return _vm.$emit("close")
      },
      confirm: _vm.submit
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "modal-ip-setting__container" }, [
              _c("div", { staticClass: "modal-ip-setting__content" }, [
                _c("div", { staticClass: "modal-ip-setting__content__label" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.$t("objects.setting.text.request_staff_id"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "break-all" }, [
                    _vm._v(
                      _vm._s(_vm.userInfo.staff_fullname) +
                        "(" +
                        _vm._s(_vm.userInfo.login_id) +
                        ")"
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "modal-ip-setting__item modal-ip-setting__item--label"
                },
                [_vm._v(_vm._s(_vm.$t("objects.setting.text.setting")))]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "modal-ip-setting__group--bd modal-ip-setting__group--content-input"
                },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "modal-ip-setting__item modal-ip-setting__item--label"
                    },
                    [_vm._v(_vm._s(_vm.$t("new_text.label")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-ip-setting__group--content" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.label,
                            expression: "label"
                          }
                        ],
                        class: [
                          "modal-ip-setting__item modal-ip-setting__item--control modal-ip-setting__item--label-input",
                          { "common-input-error": _vm.error_messages.label }
                        ],
                        attrs: {
                          placeholder: _vm.$t(
                            "objects.setting.text.label_placeholder"
                          ),
                          required: "required"
                        },
                        domProps: { value: _vm.label },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.label = $event.target.value
                            },
                            _vm.handleValidationLabel
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.error_messages.label
                        ? _c(
                            "span",
                            { staticClass: "common-msg-error error-message" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.error_messages.label) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-ip-setting__group--content-input" },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "modal-ip-setting__item modal-ip-setting__item--label"
                    },
                    [_vm._v(_vm._s(_vm.$t("objects.setting.text.ip")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "modal-ip-setting__group--content modal-ip-setting__group--content--ip"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "modal-ip-setting__group--inner-input" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ip_address,
                                expression: "ip_address"
                              }
                            ],
                            class: [
                              "modal-ip-setting__item modal-ip-setting__item--control",
                              {
                                "common-input-error":
                                  _vm.error_messages.ip_address
                              }
                            ],
                            attrs: { required: "required" },
                            domProps: { value: _vm.ip_address },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.ip_address = $event.target.value
                                },
                                _vm.handleIPChange
                              ]
                            }
                          }),
                          _vm._v(" "),
                          _vm.error_messages.ip_address
                            ? _c(
                                "span",
                                {
                                  staticClass: "common-msg-error error-message"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.error_messages.ip_address) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "modal-ip-setting__group--generate-ip" },
                        [
                          _c("span", { on: { click: _vm.fillMyIpAddress } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("objects.setting.text.enter_ip_address")
                              )
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", [
                _c(
                  "label",
                  {
                    staticClass:
                      "modal-ip-setting__item modal-ip-setting__item--label modal-ip-setting__item--label__accept"
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("objects.setting.text.allow_accept_ip_address")
                        ) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-ip-setting__h-align" },
                [
                  _c("radio-common", {
                    staticClass: "modal-ip-setting__h-align--right",
                    attrs: {
                      name: "status",
                      val: 0,
                      label: _vm.$t("new_text.disallow"),
                      "is-checked": _vm.status === 0
                    },
                    on: { select: _vm.handleSelected }
                  }),
                  _vm._v(" "),
                  _c("radio-common", {
                    attrs: {
                      name: "status",
                      val: 1,
                      label: _vm.$t("new_text.allow"),
                      "is-checked": _vm.status === 1
                    },
                    on: { select: _vm.handleSelected }
                  })
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "confirm_button_content",
        fn: function() {
          return [
            _vm._v("\n    " + _vm._s(_vm.$t("new_text.button.save")) + "\n  ")
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }