var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order" },
    [
      _c("div", { staticClass: "order-content" }, [
        _c(
          "div",
          {
            class: [
              "content",
              {
                "content--search-detail": _vm.show_search_detail,
                "content--lab-design": _vm.$store.getters.isLabDesigner,
                "content--hide-content-sp": _vm.hide_search_content_in_sp
              }
            ]
          },
          [
            _c("div", { staticClass: "content__header" }, [
              _c(
                "div",
                { staticClass: "content__header content__header--small" },
                [
                  _c("div", { staticClass: "content__text" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.headerTitle) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.isInstructionAdmin || _vm.isLabDesigner
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "content__group content__group--instruction-admin"
                        },
                        [
                          _vm.status === _vm.order_status.PREPARE.value ||
                          _vm.status === _vm.order_status.SHIPPED.value
                            ? _c(
                                "button",
                                {
                                  class: [
                                    "content__group__button content__group__button--first",
                                    {
                                      "content__group__button--enable":
                                        _vm.checkOrderSelected
                                    }
                                  ],
                                  attrs: { disabled: !_vm.checkOrderSelected },
                                  on: { click: _vm.printTechnicalInfo }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "objects.orders.button.print_order_detail"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isLabDesigner
                            ? _c(
                                "button",
                                {
                                  class: [
                                    "content__group__button",
                                    {
                                      "content__group__button--enable":
                                        _vm.checkOrderSelected
                                    }
                                  ],
                                  attrs: { disabled: !_vm.checkOrderSelected },
                                  on: { click: _vm.printInstruction }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "objects.orders.button.print_instruction"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    : _c("div", { staticClass: "content__group" }, [
                        _vm.status === _vm.order_status.SHIPPED.value ||
                        _vm.status === _vm.order_status.FINISH.value
                          ? _c(
                              "button",
                              {
                                class: [
                                  "content__group__button content__group__button--first",
                                  {
                                    "content__group__button--enable":
                                      _vm.checkOrderSelected
                                  }
                                ],
                                attrs: { disabled: !_vm.checkOrderSelected },
                                on: {
                                  click: function($event) {
                                    _vm.show_update_status_confirmation = true
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.textButtonChangeStatus) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.status !== _vm.order_status.FINISH.value
                          ? _c(
                              "button",
                              {
                                class: [
                                  "content__group__button",
                                  {
                                    "content__group__button--enable":
                                      _vm.checkOrderSelected
                                  }
                                ],
                                attrs: { disabled: !_vm.checkOrderSelected },
                                on: { click: _vm.printInstruction }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "objects.orders.text.print_checked_technician"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content__search" },
              [
                _vm.isInstructionAdmin
                  ? _c("search-for-instruction-admin", {
                      attrs: {
                        "type-search": _vm.typeSearch,
                        "current-status": _vm.status,
                        "reset-active": _vm.resetActive,
                        "max-length": _vm.max_length
                      },
                      on: {
                        submit: _vm.getOrderList,
                        reset: function($event) {
                          ;[(_vm.order_list = []), (_vm.data_search = {})]
                        },
                        "show-search-detail": function($event) {
                          _vm.show_search_detail = !_vm.show_search_detail
                        },
                        "set-hide-search-content-in-sp": function($event) {
                          ;[
                            (_vm.hide_search_content_in_sp = false),
                            (_vm.show_search_detail = false)
                          ]
                        }
                      }
                    })
                  : _vm.isLabDesigner
                  ? _c("search-for-lab-designer", {
                      attrs: {
                        "type-search": _vm.typeSearch,
                        "current-status": _vm.status,
                        "reset-active": _vm.resetActive
                      },
                      on: {
                        submit: _vm.getOrderList,
                        reset: function($event) {
                          ;[(_vm.order_list = []), (_vm.data_search = {})]
                        },
                        "show-search-detail": function($event) {
                          _vm.show_search_detail = !_vm.show_search_detail
                        },
                        "set-hide-search-content-in-sp": function($event) {
                          ;[
                            (_vm.hide_search_content_in_sp = false),
                            (_vm.show_search_detail = false)
                          ]
                        }
                      }
                    })
                  : _c("search", {
                      attrs: {
                        "type-search": _vm.typeSearch,
                        "current-status": _vm.status,
                        "reset-active": _vm.resetActive
                      },
                      on: {
                        submit: _vm.getOrderList,
                        reset: function($event) {
                          ;[(_vm.order_list = []), (_vm.data_search = {})]
                        },
                        "show-search-detail": function($event) {
                          _vm.show_search_detail = !_vm.show_search_detail
                        },
                        "set-hide-search-content-in-sp": function($event) {
                          ;[
                            (_vm.hide_search_content_in_sp = false),
                            (_vm.show_search_detail = false)
                          ]
                        }
                      }
                    })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content__list" },
              [
                _vm.isInstructionAdmin
                  ? _c("order-list-instruction-admin", {
                      ref: "orderList",
                      class: [
                        _vm.getClass,
                        {
                          "common-list--sticky":
                            _vm.currentLayoutMode === _vm.layout_mode.PC
                        }
                      ],
                      attrs: {
                        "order-list": _vm.order_list,
                        "current-status": _vm.status,
                        "lazy-load": _vm.lazy_load
                      },
                      on: {
                        "order-selected": _vm.handleOrderSelected,
                        "handle-sort": _vm.handleSort
                      }
                    })
                  : _vm.isLabDesigner
                  ? _c("order-list-lab-designer", {
                      ref: "orderList",
                      class: [
                        _vm.getClass,
                        {
                          "common-list--sticky":
                            _vm.currentLayoutMode === _vm.layout_mode.PC
                        }
                      ],
                      attrs: {
                        "order-list": _vm.order_list,
                        "current-status": _vm.status,
                        "lazy-load": _vm.lazy_load
                      },
                      on: {
                        "order-selected": _vm.handleOrderSelected,
                        "handle-sort": _vm.handleSort
                      }
                    })
                  : _c("order-list", {
                      ref: "orderList",
                      class: [
                        _vm.getClass,
                        {
                          "common-list--sticky":
                            _vm.currentLayoutMode === _vm.layout_mode.PC
                        }
                      ],
                      attrs: {
                        "order-list": _vm.order_list,
                        "current-status": _vm.status,
                        "lazy-load": _vm.lazy_load
                      },
                      on: {
                        "order-selected": _vm.handleOrderSelected,
                        "handle-sort": _vm.handleSort
                      }
                    }),
                _vm._v(" "),
                _vm.order_list.length > 0
                  ? _c(
                      "div",
                      { staticClass: "order-list-pagination" },
                      [
                        _c("pagination-common", {
                          attrs: {
                            "current-page": _vm.pagination.current_page,
                            "last-page": _vm.pagination.last_page
                          },
                          on: { "change-page": _vm.changePage }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm.is_fetching_data_print
        ? _c("iframe", {
            attrs: { name: "printInstructions", src: _vm.printInstructionUrl },
            on: { load: _vm.onLoadIframe }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.show_update_status_confirmation
        ? _c("modal-confirm", {
            attrs: { text: _vm.msgConfirmUpdateStatus },
            on: {
              no: function($event) {
                _vm.show_update_status_confirmation = false
              },
              yes: _vm.handleChangeStatus
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }