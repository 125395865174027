<template>
  <div class="radio-common">
    <input
      :id="generateId"
      v-model="checked"
      type="radio"
      :value="val"
      :name="name"
      @change="onChange"
    >
    <label :for="generateId">{{ label }}</label>
  </div>
</template>

<script>
  export default {
    name: 'RadioCommon',
    props: {
      isChecked: {
        type: Boolean,
        default: false
      },
      label: {
        type: [Number, String],
        default: ''
      },
      val: {
        type: [Number, String],
        default: ''
      },
      name: {
        type: [Number, String],
        default: ''
      }
    },
    data () {
      return {
        checkedProxy: false
      }
    },
    computed: {
      checked: {
        get () {
          return this.isChecked
        },
        set (val) {
          this.checkedProxy = val
        }
      },
      generateId () {
        return Math.random().toString(36).substring(7)
      }
    },
    watch: {
      isChecked (newVal) {
        this.$el.querySelector('input[type="radio"]').checked = newVal
      }
    },
    mounted () {
      if (this.isChecked) {
        this.$el.querySelector('input[type="radio"]').checked = true
      }
    },
    methods: {
      onChange: function () {
        this.$emit('select', this.checkedProxy)
      }
    }
  }
</script>
