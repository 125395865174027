var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.options
    ? _c(
        "div",
        {
          staticClass: "dropdown-filter-common",
          attrs: { id: _vm.generateId }
        },
        [
          _c(
            "div",
            {
              class: [
                "dropdown-filter-common__data",
                { "dropdown-filter-common__data--active": _vm.show_dropdown }
              ],
              on: {
                click: function($event) {
                  _vm.show_dropdown = !_vm.show_dropdown
                }
              }
            },
            [
              _c("span", { staticClass: "dropdown-filter-common__text" }, [
                _vm._v(_vm._s(_vm.dataChecked))
              ])
            ]
          ),
          _vm._v(" "),
          _vm.show_dropdown
            ? _c("div", { staticClass: "dropdown-filter-common__box" }, [
                _c(
                  "div",
                  { staticClass: "dropdown-filter-common__search" },
                  [
                    _c("input-common", {
                      attrs: { "default-value": _vm.filter_item },
                      on: {
                        input: function(value) {
                          return (_vm.filter_item = value)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "dropdown-filter-common__list" },
                  [
                    !!_vm.emptyValue && _vm.filter_checked
                      ? _c(
                          "li",
                          {
                            staticClass:
                              "dropdown-filter-common__item dropdown-filter-common__item--hover",
                            on: {
                              click: function($event) {
                                return _vm.selectItem(null)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n        " + _vm._s(_vm.emptyValue) + "\n      "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.filteredList, function(item) {
                      return _c(
                        "li",
                        {
                          key: item.value,
                          staticClass:
                            "dropdown-filter-common__item dropdown-filter-common__item--hover",
                          on: {
                            click: function($event) {
                              return _vm.selectItem(item)
                            }
                          }
                        },
                        [_vm._v("\n        " + _vm._s(item.text) + "\n      ")]
                      )
                    }),
                    _vm._v(" "),
                    !_vm.filteredList.length && _vm.options.length
                      ? _c(
                          "li",
                          { staticClass: "dropdown-filter-common__item" },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("new_text.search_not_found")) +
                                "\n      "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                )
              ])
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }