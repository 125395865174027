export default {
  allocation_types: {
    0: 'Not set',
    1: 'For new reservation',
    2: 'For CSL'
  },
  frame_csl: 'CSL',
  setting_type_csl: 'For CSL',
  setting_type_new_reservation: 'For new reservation',
  setting_type_not_set: 'Not set'
}
