<template>
    <div :class="['checkbox-input-custom my-auto checkbox-common', className]">
        <input type="checkbox" :value="val" :id="generateId" :name="name" v-model="checked" @change="onChange">
        <label :for="generateId">{{ label }}</label>
    </div>
</template><script>
    export default {
        name: "CheckboxCommon",
        props: ['value', 'label', 'className', 'val', 'name'],
        data () {
            return {
                checkedProxy: false
            }
        },
        computed: {
            checked: {
                get () {
                    return this.value
                },
                set (val) {
                    this.checkedProxy = val
                }
            },
            generateId () {
                return Math.random().toString(36).substring(7)
            }
        },
        methods: {
            onChange: function () {
                this.$emit('input', this.checkedProxy)
            }
        }
    }
</script>