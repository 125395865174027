var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reservation-setting" },
    [
      _c("div", { staticClass: "reservation-setting__list-type" }, [
        _c("div", { staticClass: "reservation-setting__title" }, [
          _c("span", { staticClass: "reservation-setting__title__text" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t("objects.setting.text.reservation_type_master_setting")
                ) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "reservation-setting__wrapper" }, [
          _c("div", { staticClass: "reservation-setting__content" }, [
            _c("div", { staticClass: "reservation-setting__edit" }, [
              _c(
                "div",
                {
                  staticClass: "reservation-setting__edit__btn",
                  on: { click: _vm.editReservationMasterTab }
                },
                [
                  _c(
                    "span",
                    { staticClass: "reservation-setting__edit__btn__text" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("objects.setting.button.edit_tab")) +
                          "\n            "
                      )
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "reservation-setting__tab" }, [
              _c(
                "ul",
                { staticClass: "nav nav-tabs reservation-setting__nav" },
                [
                  _vm._l(_vm.reservation_master, function(
                    reservationType,
                    index
                  ) {
                    return _c("li", { key: index, staticClass: "nav-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          class: {
                            active:
                              parseInt(index) ===
                              parseInt(_vm.active_master_tab)
                          },
                          on: {
                            click: function($event) {
                              return _vm.updateTabActive(
                                index,
                                reservationType[0].reservation_type_name
                                  ? reservationType[0].reservation_type_name
                                  : index
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              reservationType[0].reservation_type_name
                                ? reservationType[0].reservation_type_name
                                : index
                            )
                          )
                        ]
                      )
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item reservation-setting__nav__item" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "reservation-setting__nav__add",
                          on: { click: _vm.addDutyTab }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("objects.setting.button.add_tab"))
                          )
                        ]
                      )
                    ]
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-content reservation-setting__tab__wrapper"
                },
                [
                  _c(
                    "div",
                    { staticClass: "reservation-setting__tab__content" },
                    [
                      _vm._l(
                        _vm.reservation_master[_vm.active_master_tab],
                        function(duty, index) {
                          return [
                            duty.duty_name
                              ? _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "reservation-setting__box",
                                    on: {
                                      click: function($event) {
                                        return _vm.handleEditItemDuty(duty)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "reservation-setting__box__text"
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "reservation-setting__box__color",
                                          style: {
                                            backgroundColor:
                                              duty.duty && duty.duty.color
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "reservation-setting__box__name"
                                          },
                                          [_vm._v(_vm._s(duty.duty_name))]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "reservation-setting__box__value"
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "reservation-setting__box__icon-clock"
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "reservation-setting__box__time"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(duty.take_time) +
                                                _vm._s(
                                                  _vm.$t("new_text.minute")
                                                )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "reservation-setting__box reservation-setting__box--add-block",
                          on: { click: _vm.handleAddItemDuty }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "reservation-setting__box__add-item"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("objects.setting.button.add_item")
                                )
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    2
                  )
                ]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "reservation-setting__duty" }, [
          _c("div", { staticClass: "reservation-setting__title" }, [
            _c("span", { staticClass: "reservation-setting__title__text" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("objects.setting.text.job_master_setting")) +
                  "\n        "
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "reservation-setting__duty__list" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "reservation-setting__duty__row reservation-setting__duty__head",
                  on: {
                    click: function($event) {
                      return _vm.handleCloseModalDuty($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "reservation-setting__duty__group",
                      on: {
                        click: function($event) {
                          return _vm.handleCloseModalDuty($event)
                        }
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("objects.setting.text.job_title")))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "reservation-setting__duty__group",
                      on: {
                        click: function($event) {
                          return _vm.handleCloseModalDuty($event)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "duty-frame duty-color-label" },
                        [_vm._v(_vm._s(_vm.$t("new_text.color")))]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.colors_duty, function(duty, key) {
                return _c(
                  "div",
                  {
                    key: key,
                    staticClass:
                      "reservation-setting__duty__row reservation-setting__duty__body",
                    on: {
                      click: function($event) {
                        return _vm.handleCloseModalDuty($event)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "reservation-setting__duty__group",
                        on: {
                          click: function($event) {
                            return _vm.handleCloseModalDuty($event)
                          }
                        }
                      },
                      [_c("span", [_vm._v(_vm._s(duty.title))])]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "reservation-setting__duty__group",
                        on: {
                          click: function($event) {
                            return _vm.handleCloseModalDuty($event)
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "reservation-setting__duty__color",
                          style: { backgroundColor: duty.color },
                          attrs: { "data-color": duty.color },
                          on: {
                            click: function($event) {
                              _vm.duty_id = duty.id
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("modal-duty-color", {
                          attrs: {
                            "duty-id": _vm.duty_id,
                            "data-duty-id": duty.id,
                            "data-duty-color": duty.color
                          },
                          on: { "update-duty-master": _vm.updateDutyMaster }
                        })
                      ],
                      1
                    )
                  ]
                )
              })
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _vm.is_show_modal_delete_duty_tab
        ? _c("modal-confirm-danger", {
            staticClass: "reservation-setting__confirm-delete",
            on: {
              confirm: _vm.deleteReservationMasterTab,
              close: function($event) {
                _vm.is_show_modal_delete_duty_tab = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_delete_duty_item
        ? _c("modal-confirm-danger", {
            staticClass: "reservation-setting__confirm-delete",
            on: {
              confirm: _vm.requestDeleteReservationType,
              close: function($event) {
                _vm.is_show_modal_delete_duty_item = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_edit_reservation_type_modal
        ? _c("edit-type-setting", {
            ref: "editTypeSetting",
            attrs: {
              "modal-type": _vm.modal_type,
              "colors-duty": _vm.colors_duty,
              "duty-item": _vm.duty_item
            },
            on: {
              close: _vm.handleCloseModal,
              confirm: _vm.handleConfirm,
              delete: _vm.removeDutyItem
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_edit_tab
        ? _c("edit-tab-type-setting", {
            ref: "editTabTypeSetting",
            attrs: { "active-tab-name": _vm.active_tab_name },
            on: {
              close: _vm.handleCloseModal,
              confirm: _vm.updateReservationMasterTab,
              delete: _vm.removeDutyTab
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("loading")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }