var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-blue", attrs: { id: "login-form" } },
    [
      _c("loading"),
      _vm._v(" "),
      _c("div", { staticClass: "container h-100" }, [
        _c("div", { staticClass: "d-flex justify-content-center h-100" }, [
          _c(
            "div",
            { staticClass: "user_card" },
            [_vm._m(0), _vm._v(" "), _vm._t("default")],
            2
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c("div", { staticClass: "brand_logo_container" }, [
        _c("img", {
          staticClass: "brand_logo",
          attrs: {
            src: "/images/kirei_lign_logo.png",
            alt: "Logo",
            height: "160"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }