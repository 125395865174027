<template>
	<div :class="['interview-input-block__header', className]">
        <span v-if="label">{{ label }}</span>
        <p :class="['arrow', checked ? 'toggle-icon' : '']" @click="handleClick($event, checked)"></p>
    </div>
</template>

<script>
    export default {
        name: "AccordingBlock",
		props: {
            checked: { type: Boolean, default: true },
            className: { type: String, default: '' },
            label: { type: String, default: '' },
        },
        data () {
            return {
                checkedProxy: false
            }
        },
		methods: {
            handleClick: function(event, checked) {
                this.checkedProxy = !checked;
                this.$emit('handleClick', this.checkedProxy);
            }
        }
    }
</script>
