var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "interview-info-v2" },
    [
      _c("div", { staticClass: "interview-info-v2__header" }, [
        _c("div", { staticClass: "interview-info-v2__header-content" }, [
          _c("div", { staticClass: "interview-info-v2__header-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("new_text.questionnaire")) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "interview-info-v2__header-action" }, [
            _c(
              "button",
              {
                staticClass:
                  "interview-info-v2__btn interview-info-v2__btn--sms-history",
                on: {
                  click: function($event) {
                    _vm.show_sms_sending_modal = true
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("objects.patient.text.sms_history")) +
                    "\n        "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "interview-info-v2__btn interview-info-v2__btn--print",
                on: {
                  click: function($event) {
                    return _vm.$emit("print-interview-sheet")
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("objects.patient.text.print_interview")) +
                    "\n        "
                )
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "interview-info-v2__content" },
        [
          _c("Information", { attrs: { "patient-info": _vm.patientInfo } }),
          _vm._v(" "),
          _c("content-consultation"),
          _vm._v(" "),
          _c("advance-consultation"),
          _vm._v(" "),
          _c("other")
        ],
        1
      ),
      _vm._v(" "),
      _vm.show_sms_sending_modal
        ? _c("modal-interview-s-m-s-sending", {
            on: {
              close: function($event) {
                _vm.show_sms_sending_modal = false
              },
              "detail-button-click": _vm.handleButtonDetailClick
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }