export default {
  button: {
    change_order_finish_to_shipped: 'チェックした注文を発送完了に戻す',
    confirm: '発注確認',
    create_instructions: 'WEB入力で指示書を作成する',
    download_setup_data: 'セットアップデータをダウンロード',
    open_simulation: 'シミュレーションを開く',
    patient_information: '患者情報',
    print_instruction: '「技工指示書」を印刷',
    print_order_detail: '注文詳細を印刷',
    update_latest_information: '最新情報に更新',
    upload: 'アップロード'
  },
  message: {
    cannot_print_because_exist_error_files: '技工指示書がアップロードされていない発注情報があるため印刷できません\n' +
      '以下の発注情報を確認してください\n' +
      '{orderIds}',
    cannot_print_because_no_image: '画像がありませんので印刷できません',
    not_found_data_setup: 'セットアップデータのダウンロードはアライナー発注時のみダウンロードできます。\n' +
      'アライナー発注にもかかわらずダウンロードが出来なかった場合は、技工所までお問い合わせください。',
    patient_does_not_have_any_reserved_reservation: '発注するためには、初診予約を登録する必要があります。予約カレンダーから初診予約を登録してから、発注を行ってください。',
    simulation_url_is_not_set: 'シミュレーションURLが設定されておりません。'
  },
  placeholder: {
    g_number: 'g番号で検索',
    order_id: '注文IDで検索',
    count: '回数で検索',
    visit_number: '注文ステージ数を選択して下さい'
  },
  text: {
    status: {
      0: '予約なし',
      1: 'クリニック要確認',
      2: '受注前確認中',
      3: 'セットアップデザイン中',
      4: '発送準備中',
      5: '発送完了',
      6: 'キャンセル',
      7: '納品完了'
    },
    about_expansion: '拡大床利用について',
    about_oral_data: '口腔データについて',
    aligner_shipping_date: 'アライナー発送予定日',
    amount_of_money: '金額',
    asc_all: 'すべて',
    asc_excluding: 'ACSを除く',
    asc_only: 'ACSのみ',
    checked_order: 'チェックした注文を納品完了にする',
    clean_line: '今回の注文ステージ数',
    clinic_name: 'クリニック',
    close_advanced_search: '詳細検索を閉じる',
    confirm_update_status_msg: 'チェックした発注情報のステータスを\n納品完了に変更します',
    confirm_update_status_finish_msg: 'チェックした発注情報のステータスを\n発送完了に変更します',
    consultation_day: '診察日',
    contact_laboratory: '技工所からご連絡',
    count: '回数',
    course_name: 'コース名',
    create_order_confirm: '発注しますか？',
    create_order_success: '患者ID {sf_user_id} の発注が完了しました',
    date_shipment: '発送日',
    delivery_completion_date: '納品完了日',
    delivery_date: '希望納品日',
    doctor_in_charge: '担当医',
    download_csv: '検索結果をCSVでダウンロード',
    drag_limit_title: 'アップロードしたいファイルをドラッグ、または選択してください',
    drag_limit_title_sp: 'ファイルをアップロードしてください。',
    drag_limit_size: '(1ファイルあたりのサイズ上限　{limit}MB)',
    estimated_shipping_date: '発送予定日',
    file_upload: '顔貌写真・口腔内写真・レントゲンアップロード',
    file_upload_additional_note: 'リテーナー初回注文時（＝動的治療終了時）にも治療前後の比較としてアップロードをお願いします。',
    file_upload_help: '初回注文時は必ずアップロードをしてください。（アップロードなしまたは規格に満たないデータ等で確認できない情報は技工物に反映できかねます）',
    file_upload_help_link: '写真の撮り方',
    file_upload_note_1: '・顔貌写真３枚（正貌口唇閉鎖・正貌スマイル・側貌口唇閉鎖）',
    file_upload_note_2: '・口腔内写真５枚（正面・左右側貌・上下咬合面）',
    file_upload_note_3: '・パントモ撮影データ（全体が表示されている状態）',
    file_uploaded: 'アップロード済ファイル',
    // text for file upload additional
    file_upload_additional: '顔貌写真・口腔内写真・レントゲン再アップロード',
    file_upload_additional_help_link: '写真の撮り方',
    file_upload_additional_note_1: 'SheepMedical受注担当から追加で資料のリクエストがあった場合のみこちらから再アップロードしてください。',
    file_upload_additional_note_2: '再アップロードが完了しましたら、その旨をメールでご連絡ください。',
    file_upload_additional_note_3: '技工指示書は再アップロードできませんので、技工指示書に誤りがあった場合には新規の発注をお願いいたします。',
    file_uploaded_additional: '追加アップロード対象ファイル',
    file_uploaded_additional_success: '追加ファイルのアップロードが完了しました',
    first_order: {
      0: '発注しない',
      1: '発注する'
    },
    footer_content: '技工所情報はキレカルサポートサイトをご確認ください\n' +
      '<a target="_blank" href="https://support.kirekar.com/faq/page-2208/">' +
      'https://support.kirekar.com/faq/page-2208/</a>',
    footer_title: 'キレカルの操作方法や運用ルールについてはクリニックサポート担当までご連絡ください。',
    for_set: 'セット分',
    g_number: 'g番号',
    ipr_amount: 'IPR量',
    list_display: '一覧表示',
    number_order_image: '{number}回目　注文画像',
    number_orders: '発注履歴　(合計{number_order}回)',
    number_treatment: '総治療回数{number}回',
    order_contract_simulation: '発注する',
    order_creation_notification: '※受発注のやりとりに齟齬が生じないようWEB入力を推奨しております。ご協力のほどよろしくお願い致します。',
    order_date: '発注日',
    order_detail: '注文の内容',
    order_details: '注文詳細',
    order_id: '注文ID',
    order_mouthpiece: '今回の注文マウスピース',
    order_received_date: '受注日',
    order_search_notification: '検索条件を指定して検索してください',
    order_visit_number_note: 'シミュレーション、拡大床、再製作、リテーナーはその他を選択してください',
    order_visit_number_note_other: {
      1: 'シミュレーション、拡大床、再製作、リテーナー、',
      2: 'キレイラインKIDS等はその他を選択してください'
    },
    order_visit_number_option: {
      0: 'その他',
      1: '1ステージ',
      3: '3ステージ(【旧】)',
      5: '5ステージ',
      6: '6ステージ(【旧】追加一括)',
      9: '9ステージ(【旧】追加一括)',
      10: '10ステージ',
      15: '15ステージ',
      kids: 'キレイラインKIDS'
    },
    orthodontic_phase: '矯正フェーズ',
    patient_id: '患者ID',
    patient_name: '氏名',
    patient_romaji_name: 'ローマ字',
    person_in_charge: '担当医',
    please_select_file: '※ファイルを選択してください',
    please_select_file_with_simulation: '※契約前シミュレーション実施者の契約後初回発注は、初診表をアップロードしてください。',
    pre_contract_simulation: '契約前シミュレーション',
    presscut_complete_date: 'プレスカット\n完了日',
    presscut_complete_date_label: 'プレスカット完了日',
    print_checked_technician: 'チェックした技工指示書を一括で印刷',
    print_instruction_status: '指示書',
    print_status: '技工指示書',
    product_time: '{name} {number}回目',
    scan_data: 'スキャンデータ不良（再製時は有料で承ります）',
    select_files: 'ファイルを選択',
    setup_press_cut: 'プレスカット担当者',
    setup_staff: 'セットアップ担当者',
    sf_exvolume_1u: '1Stage目拡大量上 {value} mm',
    sf_exvolume_1l: '1Stage目拡大量下 {value} mm',
    sf_exvolume_2u: '2Stage目拡大量上 {value} mm',
    sf_exvolume_2l: '2Stage目拡大量下 {value} mm',
    sf_exvolume_3u: '3Stage目拡大量上 {value} mm',
    sf_exvolume_3l: '3Stage目拡大量下 {value} mm',
    sf_exvolume_4u: '4Stage目拡大量上 {value} mm',
    sf_exvolume_4l: '4Stage目拡大量下 {value} mm',
    sf_exvolume_5u: '5Stage目拡大量上 {value} mm',
    sf_exvolume_5l: '5Stage目拡大量下 {value} mm',
    sf_pre_contract_simulation: {
      0: '- 未実施',
      1: '◯ 実施'
    },
    stl_file_upload_title: '口腔内スキャンアップロード',
    stl_file_upload_note_1: 'データフォーマット：片顎ごとのファイル',
    stl_file_upload_note_2: 'ファイル形式：STLデータ',
    stl_file_upload_additional_note: '石膏模型送付またはクラウド経由で技工所にスキャンを送信する場合はアップロード不要です',
    stl_file_uploaded: 'アップロード済口腔内スキャン',
    technician_instructions: '技工所メモ',
    this_order: '今回の発注',
    title_create_order: '発注',
    unprinted: '未印刷',
    upload_technician_instructions: '技工指示書アップロード',
    upload_technician_instructions_help: '未記入やチェック漏れ、文字の認識が困難で内容が確認できない場合は再アップロードが必要となる場合がございます。\n' +
      '（確認に時間を要すると納期遅れの原因につながりますことをご了承願います）',
    upload_technician_instructions_help_link: '技工指示書の書き方',
    upload_technician_instructions_note_1: '・初診の技工指示書：契約前シミュレーション /',
    upload_technician_instructions_note_1_1: '1stステージアライナー',
    upload_technician_instructions_note_2: '・再診の技工指示書：上記以外',
    uploaded_technician_instructions: 'アップロード済技工指示書',
    worker_setup: 'セットアップ\n担当者',
    worker_presscut: 'プレスカット\n担当者'
  }
}
