<template>
  <div class="search-patient-order">
    <div class="search-patient-order__header">
      <span>{{ $t('reservation.select_patient') }}</span>
      <img
        src="/images/icon-close-search.svg"
        @click="$emit('close')"
      >
    </div>
    <div class="search-patient-order__content">
      <div
        v-for="(p, index) in patients"
        :key="index"
        class="search-patient-order__item"
        @click="selectionHandler(p)"
      >
        <div>
          <div class="search-patient-order__item__info">
            <label>{{ $t('common.ID') }}</label>
            <span>{{ p.sf_user_id ? p.sf_user_id : p.patient_id }}</span>
          </div>
          <div class="search-patient-order__item__info d-flex">
            <label>{{ $t('common.name') }}</label>
            <span>{{ p.last_name }}{{ p.first_name }}({{ p.kana_last_name }} {{ p.kana_first_name }})</span>
          </div>
          <div class="search-patient-order__item__info">
            <label>{{ $t('reservation.reservation_status') }}</label>
            <span>{{ patientSFStatus(p.sf_status_c) }}</span>
          </div>
          <div class="search-patient-order__item__info">
            <label>{{ $t('reservation.first_visit_date') }}</label>
            <span>{{ patientVisitedOrCancelledDate(p) }}</span>
          </div>
        </div>
        <img src="/images/arrow-right-blue.svg">
      </div>
      <infinite-loading
        :identifier="infinite_scroll_id"
        @infinite="infiniteScrollHandler"
      >
        <div
          slot="no-more"
          class="result-loader"
        />
        <span
          slot="no-results"
          class="result-loader"
        >
          {{ $t('patient.modal.search_no_result') }}
        </span>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
  import helpers from '../../utils/helpers'
  import InfiniteLoading from 'vue-infinite-loading'

  export default {
    components: {
      InfiniteLoading
    },
    props: {
      keywordId: {
        type: String,
        default: ''
      },
      keywordName: {
        type: String,
        default: ''
      },
      isRequiredSfUserId: {
        type: Boolean,
        default: false
      },
      sfClinicId: {
        type: String,
        default: ''
      },
      searchById: {
        type: Boolean,
        default: false
      }
    },
    emits: ['select', 'close'],
    data () {
      return {
        count_data: '',
        selected_col: 'all',
        selected_letter: '',
        infinite_scroll_id: 1,
        page: 1,
        patients: [],
        patient_count: 0,
        count_loaded: 0,
        loading: false,
        lazy_load: true
      }
    },
    computed: {
      isLabDesigner: function () {
        return this.$store.getters.isLabDesigner
      },
      isInstructionAdmin () {
        return this.$store.getters.isInstructionAdmin
      }
    },
    methods: {
      selectionHandler: function (patientInfo) {
        this.$emit('select', {
          id: patientInfo.patient_id,
          name: this.getPatientFullName(patientInfo),
          sf_user_id: patientInfo.sf_user_id,
          sf_status_c: patientInfo.sf_status_c,
          first_reservation_id: patientInfo.first_reservation_id || 0,
          brand_text: patientInfo.brand_master_name,
          roma_last_name: patientInfo.roma_last_name,
          roma_first_name: patientInfo.roma_first_name,
          order_count: patientInfo.order_count,
          sf_pre_contract_simulation: patientInfo.sf_pre_contract_simulation
        })
      },
      infiniteScrollHandler: function ($state) {
        if (this.loading) {
          return
        }

        this.loading = true
        let listPatient = []
        if (this.searchById || (this.searchById && this.keywordId)) {
          listPatient = this.getListPatientById()
        } else {
          listPatient = this.getListPatientByName()
        }
        listPatient.then((result) => {
          const loadPatients = result?.data?.results?.data || []
          this.patient_count = result?.data?.results?.total || 0

          if (loadPatients.length) {
            this.patients.push(...loadPatients)
            this.page++
            this.count_loaded += loadPatients.length

            if (this.count_loaded < this.patient_count) {
              $state.loaded()
            } else {
              if (this.page === 2) {
                $state.loaded()
                this.$refs.infiniteLoading.stateChanger.status = 0
              }
              $state.complete()
            }
          } else {
            if (this.page === 1) {
              $state.reset()
            }
            $state.complete()
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
          $state.complete()
        })
      },
      getPatientFullName: function (item) {
        return `${item.last_name || ''} ${item.first_name || ''}`
      },
      patientSFStatus: function (key) {
        const salesforceStatusC = this.$t('patient.salesforce_status_c')
        return salesforceStatusC[key]
      },
      patientStatus: function (status) {
        if (status === 0) {
          return this.$t('patient.cancellation_time')
        }

        return this.$t('patient.first_visit_time')
      },
      patientVisitedOrCancelledDate: function (patient) {
        if (patient.sf_status_c === 0) {
          return helpers.formatDateTime(patient.sf_cancelled_reservation_date, this.$t('datetime.year_month_day_hour_minute'))
        }
        if (patient.sf_next_reservation_date !== null) {
          return helpers.formatDateTime(patient.sf_next_reservation_date, this.$t('datetime.year_month_day_hour_minute'))
        }

        return this.$t('staff.none')
      },
      getListPatientById () {
        const sfClinicId = this.isLabDesigner ? this.sfClinicId
          : this.isInstructionAdmin ? 0
            : this.$store.getters.currentClinic.sf_clinic_id

        return this.$store.dispatch('getPatientsById', {
          sf_clinic_id: sfClinicId,
          is_required_sf_user_id: this.isRequiredSfUserId,
          search_keyword: this.keywordId,
          page: this.page
        })
      },
      getListPatientByName () {
        const sfClinicId = this.isLabDesigner ? this.sfClinicId
          : this.isInstructionAdmin ? 0
            : this.$store.getters.currentClinic.sf_clinic_id

        return this.$store.dispatch('getPatientsByName', {
          sf_clinic_id: sfClinicId,
          is_required_sf_user_id: this.isRequiredSfUserId,
          search_keyword: this.keywordName,
          page: this.page,
          first_letter: this.selected_letter
        })
      }
    }
  }
</script>
