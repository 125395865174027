<template>
  <logo>
    <form
      method="post"
      class="login-form"
      autocomplete="off"
      @submit.prevent="login"
    >
      <div class="justify-content-center form_container login-form__container">
        <div class="form-group">
          <label
            class="login-form__label"
            for="staff_id"
          >
            {{ $t('login_page.staff_id') }}
          </label>
          <span
            id="exclusion-icon"
            class="exclusion-icon"
          >
            <img src="/images/exclusion.svg">
          </span>
          <b-popover
            target="exclusion-icon"
            placement="top"
            triggers="hover focus"
            custom-class="exclusion-notify"
            fallback-placement="clockwise"
          >
            <p v-html="$t('login_page.forgotten_staff_id')" />
          </b-popover>
          <input
            id="staff_id"
            v-model="staff_id"
            type="text"
            class="form-control login-form__input"
            :placeholder="$t('login_page.please_enter_staff_id')"
          >
          <validation-errors
            v-if="errors.staff_id_request"
            :errors="errors.staff_id_request"
            :text-left="true"
          />
        </div>
        <div class="form-group">
          <label
            class="login-form__label"
            for="pwd"
          >
            {{ $t('common_page.password') }}
          </label>
          <span class="login-form__tip note-tip">{{ $t('common_page.input_characters') }}</span>
          <input
            id="pwd"
            v-model="password"
            type="password"
            class="form-control login-form__input"
            :placeholder="$t('login_page.password_placeholder')"
          >
          <validation-errors
            v-if="errors.password_request"
            :text-left="true"
            :errors="errors.password_request"
          />
        </div>
        <validation-errors
          v-if="errors.common_validation"
          :errors="errors.common_validation"
          :text-left="true"
        />
      </div>
      <div class="d-flex justify-content-center mt-3 mb-4 login_container">
        <button
          type="submit"
          name="button"
          class="btn login_btn"
        >
          {{ $t('common_page.btn_login') }}
        </button>
      </div>
      <div class="forget-password login-form__forgot text-center mb-4">
        <router-link
          :to="{ name: 'password.request' }"
          style="padding:15px 25px;color:#7FC7EF;text-decoration: underline;"
        >
          {{ $t('login_page.forgot_pass') }}
        </router-link>
      </div>
      <div class="login-form__switch-mode">
        <span class="login-form__switch-mode__title">
          {{ $t('new_text.switch_mode_pc_title') }}
        </span>
        <div class="login-form__switch-mode__action">
          <button
            type="button"
            class="login-form__switch-mode__button login-form__switch-mode__button--pc"
            @click="changeLayoutMode(layout_mode.SP)"
          >
            {{ $t('new_text.switch_mode_sp') }}
          </button>
        </div>
      </div>
    </form>
  </logo>
</template>

<script>
  import logo from '../components/Logo'
  import { BPopover } from 'bootstrap-vue/esm/components/popover'
  import { SCREEN_WIDTH_MEDIUM, FORBIDDEN_CODE } from '../utils/constants'
  import { get } from 'lodash'
  import { LayoutModeMixin } from '../mixins/layout-mode'

  export default {
    components: {
      logo,
      BPopover
    },
    mixins: [LayoutModeMixin],
    data () {
      return {
        staff_id: '',
        password: '',
        error: false,
        success: false,
        remember: false,
        errors: {
          staff_id_request: '',
          password_request: '',
          common_validation: ''
        }
      }
    },
    computed: {
      isLabDesigner () {
        return this.$store.getters.isLabDesigner
      },
      isInstructionAdmin () {
        return this.$store.getters.isInstructionAdmin
      }
    },
    watch: {
      window_width (newWidth) {
        if (newWidth <= SCREEN_WIDTH_MEDIUM && !this.currentLayoutMode) {
          return this.$router.push({ name: 'auth.login.small' })
        }
      },
      currentLayoutMode (newMode) {
        if (newMode === this.layout_mode.SP) {
          return this.$router.push({ name: 'auth.login.small' })
        }
      }
    },
    created () {
      document.title = this.$t('page_title.u1_1')
      if (
        this.currentLayoutMode === this.layout_mode.SP ||
        (!this.currentLayoutMode && this.window_width <= SCREEN_WIDTH_MEDIUM)
      ) {
        this.$router.push({ name: 'auth.login.small' })
      }
    },
    methods: {
      async login () {
        this.$store.commit('set_loading', true)
        const that = this
        that.errors = {
          staff_id_request: '',
          password_request: '',
          common_validation: ''
        }

        const data = {
          staff_id: this.staff_id,
          password: this.password
        }
        return this.$store.dispatch('login', { data }).then((result) => {
          this.$store.commit('set_loading', false)
          if (!this.currentLayoutMode) {
            this.changeLayoutMode(this.layout_mode.PC)
          }

          if (typeof result.data.results.first_login !== 'undefined' && result.data.results.first_login) {
            return this.$router.push({
              name: 'password.initial',
              params: { id: that.staff_id, token: result.data.results.token }
            })
          }

          if (this.isLabDesigner || this.isInstructionAdmin) {
            return this.$router.push({ name: 'order.search' })
          }

          return this.$router.push({ name: 'dashboard' })
        }).catch((error) => {
          const response = get(error, 'response') || {}
          const errors = response?.data?.errors || []

          this.$store.commit('set_loading', false)

          if (Array.isArray(errors)) {
            this.errors.staff_id_request = errors.includes('STAFF_ID_REQUEST') ? [that.$t('login_page.please_enter_staff_id')] : ''
            this.errors.password_request = errors.includes('PASSWORD_REQUEST') ? [that.$t('login_page.password_placeholder')] : ''

            if (errors.includes('AUTH_FAILED')) {
              this.errors.common_validation = [that.$t('login_page.failed')]
            } else if (errors.includes('ACCOUNT_DELETED')) {
              this.errors.common_validation = [that.$t('login_page.account_is_deleted')]
            } else if (errors.includes('ACCOUNT_DEACTIVATED')) {
              this.errors.common_validation = [that.$t('login_page.account_is_deactivated')]
            }
          }

          if (response.status === FORBIDDEN_CODE) {
            this.errors.common_validation = [that.$t('new_message.login_forbidden')]
          }
        })
      }
    }
  }
</script>
