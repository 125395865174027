<template>
  <div class="modal-list-reservation-type">
    <modal-action
      :title="$t('new_text.select_reservation_type')"
      @close="$emit('close')"
      @confirm="$emit('select', reservation_type_selected)"
    >
      <template #body>
        <div class="modal-list-reservation-type__content">
          <ul :class="['nav nav-tabs', 'modal-list-reservation-type__tabs', {'lazy-load': lazy_load}]">
            <li
              v-for="(item, index) in reservation_types"
              :key="index"
              class="nav-item"
            >
              <a
                :class="['nav-link', selectedTab === index ? 'active' : '']"
                @click="selected_tab = index"
              >
                {{ item[0].reservation_type_name ? item[0].reservation_type_name : index }}
              </a>
            </li>
          </ul>
          <div class="modal-list-reservation-type__tab-content">
            <template v-for="(items, index) in reservation_types">
              <div
                v-show="selectedTab === index"
                :key="index"
                :class="[ selectedTab === index ? 'active' : '']"
              >
                <div class="modal-list-reservation-type__box-content">
                  <template v-for="item in items">
                    <div
                      v-if="item.duty_name"
                      :key="item.id"
                      :class="[ 'modal-list-reservation-type__item', activeType(item.id) ]"
                      @click="selectType(item)"
                    >
                      <div :class="[ 'modal-list-reservation-type__text', activeType(item.id) ]">
                        <span
                          class="modal-list-reservation-type__color"
                          :style="[item.duty ? {backgroundColor: item.duty.color}:'']"
                        />
                        <span class="modal-list-reservation-type__name">{{ item.duty_name }}</span>
                      </div>
                      <div class="modal-list-reservation-type__value">
                        <img src="/images/clock-gray.svg">
                        <span
                          :class="[ 'modal-list-reservation-type__time', activeType(item.id) ]"
                        >
                          {{ item.take_time }}分
                        </span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template #confirm_button_content>
        {{ $t('common.decision') }}
      </template>
    </modal-action>
  </div>
</template>

<script>
  import ModalAction from '../common/ModalAction'
  import helpers from '../../utils/helpers'

  export default {
    components: {
      ModalAction
    },
    props: {
      reservationTypeSelected: {
        type: Array,
        default: () => []
      }
    },
    emits: ['close', 'select'],
    data () {
      return {
        selected_tab: 0,
        reservation_types: {},
        lazy_load: false,
        reservation_type_selected: []
      }
    },
    computed: {
      selectedTab: function () {
        let defaultSelected = null
        for (const id in this.reservation_types) {
          if (defaultSelected === null) {
            defaultSelected = id
          }

          if (this.selected_tab === id) {
            return this.selected_tab
          }
        }

        return defaultSelected
      }
    },
    created () {
      for (const reservationType of this.reservationTypeSelected) {
        this.reservation_type_selected.push(reservationType)
      }
      this.requestReservationType()
    },
    methods: {
      selectType: function (reservationType) {
        let indexSelected = null
        const existed = this.reservation_type_selected.some((item, index) => {
          if (item.id === reservationType.id) {
            indexSelected = index

            return true
          }
        })

        if (!existed) {
          this.reservation_type_selected.push(reservationType)
        }

        if (indexSelected !== null) {
          this.reservation_type_selected.splice(indexSelected, 1)
        }
      },
      activeType: function (reservationTypeId) {
        const existed = this.reservation_type_selected.some((item) => {
          if (item.id === reservationTypeId) {
            return true
          }
        })

        return {
          'modal-list-reservation-type__active': existed
        }
      },
      requestReservationType: function () {
        this.lazy_load = true
        this.$store.dispatch('getListReservationType').then((result) => {
          this.lazy_load = false
          this.reservation_types = result.data?.results?.reservationType || {}
          this.$emit('set-list-reservation-types', this.reservation_types)
        }).catch(() => {
          helpers.showErrorModal(this.$t('sweet_alert.something_went_wrong'))
        })
      }
    }
  }
</script>
