<template>
  <div
    :id="id"
    class="input-group date select-date-new"
  >
    <input
      class="form-control"
      :readonly="isReadOnly"
      @input="setDate"
    >
    <span class="input-group-addon">
      <span class="glyphicon glyphicon-calendar">
        <img :src="icon">
      </span>
    </span>
  </div>
</template>

<script>
  import * as moment from 'moment-timezone'

  export default {
    props: {
      options: {
        type: Object,
        default: () => {}
      },
      id: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: '/images/blue-calendar.svg'
      },
      defaultDate: {
        type: String,
        default: ''
      },
      isReadOnly: {
        type: Boolean,
        default: true
      },
      isPositionBottom: {
        type: Boolean,
        default: false
      }
    },
    emits: ['change'],
    data () {
      return {
        dpOptions: {
          format: this.$t('datetime.year_month_day'),
          useCurrent: false,
          dayViewHeaderFormat: 'YYYY / MM',
          locale: this.$i18n.locale,
          ignoreReadonly: true
        },
        dateTimePicker: null
      }
    },
    watch: {
      defaultDate (newValue) {
        if (newValue === '') {
          $(`#${this.id}`).data('DateTimePicker').clear()
        }
      }
    },
    created () {
      this.dpOptions = { ...this.dpOptions, ...this.options, date: this.defaultDate }
    },
    mounted () {
      if (this.isPositionBottom) {
        this.dpOptions.widgetPositioning = {
          vertical: 'bottom'
        }
      }

      this.initDateTimePicker()
    },
    methods: {
      initDateTimePicker () {
        $(`#${this.id}`).datetimepicker(this.dpOptions).on('dp.change', event => {
          const getDate = event.date
          if (getDate) {
            const checkDate = getDate._pf.charsLeftOver === 0 &&
              getDate._pf.unusedTokens.length === 0 &&
              getDate._pf.unusedInput.length === 0 &&
              getDate.isValid()

            if (!checkDate) {
              $(`#${this.id}`).data('DateTimePicker').date(this.defaultDate)
              this.$emit('change', this.defaultDate)
            } else {
              this.$emit('change', getDate.format('YYYY-MM-DD'))
            }
          } else {
            this.$emit('change', '')
          }
        }).on('dp.hide', () => {
          $(`#${this.id}`).data('DateTimePicker').viewMode('days')
        }).on('dp.error', () => {
          $(`#${this.id}`).data('DateTimePicker').date(new Date())
        })
      },
      setDate (event) {
        const input = event.target.value

        if (
          /^[\d]{4}-[\d]{2}-[\d]{2}$/.test(input) ||
          /^[\d]{4}\/[\d]{2}\/[\d]{2}$/.test(input)
        ) {
          if (moment(input).isValid()) {
            $(`#${this.id}`).data('DateTimePicker').date(input)
          }
        }
      }
    }
  }
</script>
