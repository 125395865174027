<template>
    <div class="content-inner bg-white interview-block">
        <div class="page-content">
            <div class="header-title">
                <p>{{ $t('interview.consultation_title') }}</p>
                <p class="gray-arrow" v-bind:class="{ 'toggle-icon' : show }" @click="handleClick"></p>
            </div>
            <div v-show="show" class="advance-consultation-page content-block">
                <div class="patient-detail-left col-md-12">
                    <div class="heading-two">
                        <p class="content-title">{{ $t('interview.consultation_content') }}</p>
                    </div>
                    <div class="row-content">
                        <div class="col-12 m-0 p-0">
                            <div class="form-group">
                                <div class="control-text">
                                    {{ contentPosition }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="heading-two">
                        <p class="content-title">{{ $t('interview.places_of_concern_symptoms') }}</p>
                    </div>
                    <div class="row-content" v-for="(symptom, index) in consultation.points_symptoms">
                        <div class="col-12 m-0 p-0">
                            <div class="form-group"><label class="control-label block-title">{{ $t('interview.priority_number', { number: index + 1 }) }}</label></div>
                        </div>
                        <div class="col-12 m-0 p-0">
                            <div class="d-flex two-label">
                                <div class="form-group col-3"><label class="control-label">{{ $t('interview.location') }}</label>
                                    <div class="control-text">{{ getPartName(symptom.part, symptom.part_detail) }}</div>
                                </div>
                                <div class="form-group col-3"><label class="control-label">{{ $t('interview.dentition_number') }}</label>
                                    <div class="control-text">{{ getDentitionNumber(symptom.dentition_number) }}</div>
                                </div>
                                <div class="form-group col-6"><label class="control-label">{{ $t('interview.specific_consultation') }}</label>
                                    <div class="control-text">{{ symptom.consultation_content }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 m-0 p-0">
                            <div class="form-group col-12">
                                <label class="control-label">{{ $t('interview.symptom') }}</label>
                <div class="control-text">
                  {{ getSymptoms(symptom) }}
                  <p class="symptoms-comment">
                    {{ getSymptomsComment(symptom.symptoms) }}
                  </p>
                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { OTHER_STRING, SYMPTOMS_TYPE } from '../../../../utils/constants.js'

    export default {
        name: 'advance-consultation-page',
        data() {
            return {
                show: true,
                consultation: {},
        symptoms_type: SYMPTOMS_TYPE
            }
        },
        created() {
            this.loadAdvanceConsultation();
        },
        computed: {
            getAdvanceConsultation() {
                return this.$store.getters.get_consultation_content;
            },
            contentPosition () {
                if (typeof this.consultation === 'undefined' || !Array.isArray(this.consultation.consultation_item)) {
                    return ''
                }
                if (this.consultation.consultation_item.includes(OTHER_STRING)) {
                    this.consultation.consultation_item = this.consultation.consultation_item.filter(e => e !== OTHER_STRING);
                    this.consultation.consultation_item.push(this.consultation.consultation_item_other);
                }
                return this.consultation.consultation_item.toString();
            }
        },
        methods: {
            handleClick() {
                this.show = !this.show
            },
            loadAdvanceConsultation() {
                this.consultation = this.getAdvanceConsultation;
            },
      getDentitionNumber (value) {
        return value ? value.toString() : ''
      },
            getSymptoms (symptom) {
                if (symptom.symptoms === OTHER_STRING) {
                    return symptom.symptoms_other
                }
                return symptom.symptoms
            },
      getSymptomsComment (symptom) {
        if (symptom === this.symptoms_type.MOUTH || symptom === this.symptoms_type.FRONT_TEETH_DO_NOT_CLOSE || symptom === this.symptoms_type.OFF_CENTER) {
          return this.$t('interview.symptoms_type.mouth')
        } else if (symptom === this.symptoms_type.GUMMY_SMILE) {
          return this.$t('interview.symptoms_type.gummy')
        } else if (symptom === this.symptoms_type.LOGO) {
          return this.$t('interview.symptoms_type.logo')
        }
      },
            getPartName(part, detail) {
                if (!part && !detail) {
                    return '';
                }
                return part + '-' + detail;
            }
        }
    }
</script>
