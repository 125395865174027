<template>
  <div class="order">
    <div class="order-content">
      <div class="content content--create-order">
        <div class="content__header">
          <div class="content__header content__header--small">
            <div class="content__text">
              {{ $t('objects.orders.text.title_create_order') }}
            </div>
            <div class="content__confirm">
              <button
                v-if="!is_draft"
                :disabled="!enableSubmit"
                :class="['btn-v2 bg-curious-blue cl-white-v2', { 'btn-v2--disable': !enableSubmit } ]"
                @click="handleClick"
              >
                <span>{{ $t('objects.orders.button.confirm') }}</span>
              </button>
              <template v-else>
                <button
                  class="btn-v2 bg-cl-white-v2 cl-curious-blue"
                  @click="is_draft = false"
                >
                  <span>{{ $t('new_text.button.modify') }}</span>
                </button>
                <button
                  class="btn-v2 bg-curious-blue cl-white-v2"
                  @click="submitOrder"
                >
                  <span>{{ $t('new_text.button.order') }}</span>
                </button>
              </template>
            </div>
          </div>
        </div>
        <div class="content__info">
          <InfoPatientOrder
            v-if="!is_draft"
            :form="form"
            @sf-pre-contract-simulation="sfPreContractSimulation"
            @info-data="updatePatientInfo"
            @order-history="orderHistory"
            @is-show-sf-pre-contract-simulation="showSfPreContractSimulation"
            @is-show-first-contract-simulation="showFirstContractSimulation"
          />
          <InfoPatientOrderDraft
            v-else
            :form="form"
          />
          <div
            v-if="$store.getters.isStaff && !is_draft"
            class="order-content__basic-treatment-policy"
          >
            <div class="order-content__basic-treatment-policy__label">{{ $t('new_text.basic_treatment_policy') }}</div>
            <div class="order-content__basic-treatment-policy__content">{{ basicTreatmentPolicy }}</div>
          </div>
          <UploadMediaFile
            :is-create="!is_draft"
            :sf-pre-contract-simulation="form.sf_pre_contract_simulation"
            :order-history="form.order_history"
            @update-data="updateFiles"
          />
        </div>
        <div class="content__footer">
          <Footer />
        </div>
        <modal-confirm
          v-if="is_show_modal_create_order_sp"
          :title="$t('objects.orders.text.create_order_confirm')"
          @no="is_show_modal_create_order_sp = false"
          @yes="submitOrderSP"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import InfoPatientOrder from '../../components/order/InfoPatientOrder'
  import InfoPatientOrderDraft from '../../components/order/InfoPatientOrderDraft'
  import Footer from '../../components/order/Footer'
  import ModalConfirm from '../../components/common/ModalConfirm'
  import UploadMediaFile from '../../components/order/UploadMediaFile'
  import { showErrorModal, showLargeSuccessModal, showSuccessModal, safeGet } from '../../utils/helpers'
  import {
    POST_MAX_SIZE,
    SCREEN_WIDTH_MEDIUM,
    FILE_NAME_MAX_LENGTH,
    SF_PRE_CONTRACT_SIMULATION,
    LAYOUT_MODE, RESPONSE_ERRORS
  } from '../../utils/constants'
  import { get } from 'lodash'

  export default {
    components: {
      InfoPatientOrder,
      InfoPatientOrderDraft,
      Footer,
      UploadMediaFile,
      ModalConfirm
    },
    data () {
      return {
        form: {
          patient_id: '',
          sf_user_id: '',
          name_info: '',
          roma_first_name: '',
          roma_last_name: '',
          delivery_date: '',
          formal_name: '',
          staffs_name: '',
          order: 1,
          upload_technician_instructions: [],
          file_upload: [],
          size_file_upload: 0,
          order_visit_number: '',
          is_show_sf_pre_contract_simulation: false,
          is_show_first_contract_simulation: 0,
          order_history: 0,
          sf_pre_contract_simulation: SF_PRE_CONTRACT_SIMULATION.NOT_IMPLEMENTED,
          is_patient_has_reserved_reservation: true
        },
        is_draft: false,
        is_show_modal_create_order_sp: false
      }
    },
    computed: {
      enableSubmit () {
        return this.form.sf_user_id !== '' &&
          this.form.name_info !== '' &&
          this.form.upload_technician_instructions.length !== 0 &&
          this.form.order_visit_number !== '' &&
          this.form.is_patient_has_reserved_reservation
      },
      basicTreatmentPolicy () {
        return this.$store.state.auth.clinic.sf_basic_treatment_policy__c || ''
      }
    },
    created () {
      document.title = this.$t('objects.page_title.order').toString()
    },
    methods: {
      updateFiles (value) {
        this.form.upload_technician_instructions = value.upload_technician_instructions
        this.form.file_upload = value.file_upload
        this.form.size_file_upload = value.size_file_upload
      },
      updatePatientInfo (value) {
        this.form.patient_id = value.data_order.patient.id
        this.form.sf_user_id = value.keyword_search_id
        this.form.name_info = value.keyword_search_name
        this.form.roma_first_name = value.data_order.patient.roma_first_name
        this.form.roma_last_name = value.data_order.patient.roma_last_name
        this.form.delivery_date = value.data_order.delivery_date
        this.form.formal_name = value.data_order.clinic.formal_name
        this.form.staffs_name = value.staffs_name
        this.form.order_visit_number = value.data_order.order_visit_number
        this.form.is_patient_has_reserved_reservation = value.is_patient_has_reserved_reservation
      },
      orderHistory (value) {
        this.form.order_history = value
      },
      sfPreContractSimulation (value) {
        this.form.sf_pre_contract_simulation = value
      },
      showSfPreContractSimulation (value) {
        this.form.is_show_sf_pre_contract_simulation = value
      },
      showFirstContractSimulation (value) {
        this.form.is_show_first_contract_simulation = value
      },
      handleClick () {
        if (!this.enableSubmit) {
          return
        }

        if (this.form.size_file_upload > POST_MAX_SIZE) {
          return showErrorModal(this.$t('new_validation.common.post_max_size', { maximum: POST_MAX_SIZE }))
        }

        this.is_draft = true
      },
      submitOrder () {
        if (!this.enableSubmit) {
          return
        }

        this.$store.commit('set_loading', true)
        const formData = new FormData()
        formData.append('patient_id', this.form.patient_id)
        formData.append(
          'order_visit_number',
          this.form.order_visit_number === 'kids' ? 1 : this.form.order_visit_number
        )
        const fileInstruction = this.form.upload_technician_instructions[0] || false

        if (fileInstruction) {
          formData.append('instructions', fileInstruction)
        }

        for (let i = 0; i < this.form.file_upload.length; i++) {
          const file = this.form.file_upload[i]
          formData.append('file_uploads[]', file)
        }

        this.form_data = formData
        const currentLayoutMode = parseInt(this.$store.state.auth.layout_mode)
        if (currentLayoutMode === LAYOUT_MODE.SP || (!currentLayoutMode && window.innerWidth <= SCREEN_WIDTH_MEDIUM)) {
          this.$store.commit('set_loading', false)
          this.is_show_modal_create_order_sp = true

          return
        }

        this.$store.dispatch('saveOrder', formData).then(res => {
          this.$store.commit('set_loading', false)
          showSuccessModal(this.$t('objects.orders.text.create_order_success', { sf_user_id: this.form.sf_user_id }))
          location.reload()
        }).catch((e) => {
          this.$store.commit('set_loading', false)
          const instructions = safeGet(e?.response?.data?.errors, 'instructions') === 'INVALID_FILE_NAME_LENGTH'
          if (instructions) {
            showErrorModal(this.$t('new_validation.order.invalid_file_name_length', { max: FILE_NAME_MAX_LENGTH }))
            return
          }

          showErrorModal(this.getErrorMsgFromException(e))
        })
      },
      submitOrderSP () {
        this.$store.commit('set_loading', true)

        this.$store.dispatch('saveOrder', this.form_data).then(res => {
          this.$store.commit('set_loading', false)
          this.is_show_modal_create_order_sp = false
          showLargeSuccessModal(
            this.$t('objects.orders.text.create_order_success', { sf_user_id: this.form.sf_user_id })
          )

          this.$router.push({ name: 'login.top' })
        }).catch((e) => {
          this.$store.commit('set_loading', false)
          this.is_show_modal_create_order_sp = false
          showErrorModal(this.getErrorMsgFromException(e))
        })
      },
      getErrorMsgFromException (e) {
        let errorMsg = this.$t('new_message.something_went_wrong')
        if (get(e, 'response.data.errors.patient', '') === RESPONSE_ERRORS.NO_RESERVATION) {
          errorMsg = this.$t('objects.orders.message.patient_does_not_have_any_reserved_reservation')
        }

        const itemInFileUploadsError = get(e.response.data, 'errors', null) ?? null
        if (itemInFileUploadsError) {
          if (Object.keys(itemInFileUploadsError)
            .find(i => itemInFileUploadsError[i] === RESPONSE_ERRORS.MUST_BE_VALID_STL_FILE)) {
            errorMsg = this.$t('new_validation.order.invalid_file')
          } else if (Object.keys(itemInFileUploadsError)
            .find(i => itemInFileUploadsError[i] === RESPONSE_ERRORS.INVALID_FILE_NAME_LENGTH)) {
            errorMsg = this.$t('new_validation.order.invalid_file_name_length')
          }
        }

        return errorMsg
      }
    }
  }
</script>
