import { showErrorModal } from '../utils/helpers'
import { RESPONSE_TYPE, TECHNICAL_INFO_PRINTED } from '../utils/constants'
import { isEmpty } from 'lodash'

export const multiplePrintTechnicalInfo = {
  data () {
    return {
      channel_print_multiple_id: ''
    }
  },
  methods: {
    multiplePrintTechnicalInfo (orderPrints) {
      if (orderPrints.length === 0) {
        return
      }

      this.$store.commit('set_loading', true)
      const headerHeights = {}
      const sfOrderIds = []
      orderPrints.forEach(item => {
        if (item.sf_order_id) {
          const patientInfo = {
            name: item.patient_name,
            roma_last_name: item.patient_roma_last_name,
            roma_first_name: item.patient_roma_first_name
          }
          sfOrderIds.push(item.sf_order_id)
          headerHeights[item.sf_order_id] = this.calculateHeaderHeightPage(patientInfo, item.clinic_name)
        }
      })

      this.channel_print_multiple_id = 'print_technical_info_'
      this.channel_print_multiple_id += this.$store.state.auth.user.id + '_' + (new Date()).getTime()
      this.channel_print_multiple_id += '_' + Math.random().toString(36).substring(7)
      this.connectChannelPrintTechnicalInfo()

      this.$store.dispatch(
        'printMultipleTechnicalInfo',
        {
          channel_id: this.channel_print_multiple_id,
          header_heights: headerHeights,
          sf_order_ids: sfOrderIds
        }
      ).catch(() => {
        const channel = `${window.channelPrefix}${this.channel_print_multiple_id}`
        Echo.leaveChannel(channel)
        this.channel_print_multiple_id = ''
        showErrorModal(this.$t('new_message.something_went_wrong'))
        this.$store.commit('set_loading', false)
      })
    },
    connectChannelPrintTechnicalInfo () {
      const channel = `${window.channelPrefix}${this.channel_print_multiple_id}`

      Echo.channel(channel)
        .listen('.print_technical_info.order.multiple', e => {
          const { status, path = '', order_ids: orderIds = [] } = e.payload

          if (status === RESPONSE_TYPE.NG) {
            showErrorModal(this.$t('new_message.something_went_wrong'))
          }

          if (path) {
            const link = document.createElement('a')
            link.setAttribute('href', path)
            link.setAttribute('download', '「技工所からの連絡」を印刷')
            link.click()
            link.remove()
          }

          if (!isEmpty(orderIds)) {
            let statusField = 'designer_print_technical_info_status'
            if (this.isInstructionAdmin) {
              statusField = 'instruction_admin_print_technical_info_status'
            }

            this.updatePrintTechnicalInfoStatus(statusField, orderIds)
          }

          this.$store.commit('set_loading', false)
          Echo.leaveChannel(channel)
          this.channel_print_multiple_id = ''
        }).error(e => {
          Echo.leaveChannel(channel)
          this.channel_print_multiple_id = ''
          this.$store.commit('set_loading', false)
          showErrorModal(this.$t('new_message.something_went_wrong'))
        })
    },
    updatePrintTechnicalInfoStatus (statusField, orderIds) {
      for (const order of this.order_list) {
        const printed = orderIds.filter(item => item === order.order_id)
        if (printed.length > 0) {
          order[statusField] = TECHNICAL_INFO_PRINTED
        }
      }
    }
  }
}
