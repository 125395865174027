export const PATTERN_EMAIL = /^\w+([.+-]+?\w+)*@\w+([.+-]+?\w+)*(\.\w{2,})+$/

export const RESPONSE_STATUS = {
  OK: 'OK',
  NG: 'NG'
}

export const CASE_STATUS = {
  NO_PUBLISHED: 0,
  PUBLISHED: 1
}

export const CLINIC_IMAGE_TYPE = {
  MAIN: 0,
  TOP: 1,
  EQUIPMENT: 2,
  MEMBER: 3,
  BANNER: 4
}

export const RESPONSE_ERRORS = {
  IMAGE_TYPE_NOT_ALLOW: 'IMAGE_TYPE_NOT_ALLOW',
  CLINIC_URL_INVALID: 'CLINIC_URL_INVALID',
  IMAGE_EXCEED_MAX_SIZE: 'IMAGE_EXCEED_MAX_SIZE',
  OVERLAPPED: 'OVERLAPPED',
  BRAND_NOT_SUPPORT: 'BRAND_NOT_SUPPORT',
  NO_BRAND_SUPPORTED: 'NO_BRAND_SUPPORTED',
  INVALID: 'INVALID'
}

export const PROFESSIONS = [
  {
    id: 0,
    label: '未入力'
  },
  {
    id: 1,
    label: '会社員'
  },
  {
    id: 2,
    label: '自営業'
  },
  {
    id: 3,
    label: '学生'
  },
  {
    id: 4,
    label: '主婦・主夫'
  },
  {
    id: 9,
    label: 'その他'
  }
]

export const PROFESSION_OTHER_ID = 9

export const ACTIVITY_LOG_TYPE = {
  MEDICAL_RECORD: 'medical_record',
  PATIENT: 'patient',
  INVOICE: 'invoice',
  RESERVATION: 'reservation',
  MEMO: 'memo',
  PATIENT_INTERVIEW: 'interview_sheet'
}

export const ORDER_FILE_THUMB_STATUS = {
  LOADING: 0,
  LOADED: 1,
  FAILED: 2
}

export const ORDER_FILE_TYPE = {
  INSTRUCTION: 1,
  ORDER_FILE: 0
}

export const STAFF_POSITION = {
  DOCTOR: 'Dr',
  CONSELOR: 'DC',
  DENTAL_HYGIENIST: 'DH',
  DENTAL_ASSISTANT: ''
}

export const INTERVIEW_TYPE = {
  FIRST: 1,
  RE_VISIT: 2
}

export const UPDATE_CLINIC_TYPE = {
  BASIC: '0',
  ADVERTISE: '1',
  RECOMMENDED: '2',
  BANNER: '3',
  WORDING: '4'
}

export const BRAND_IDS = {
  KIREILIGN: 1,
  KIDS: 2,
  INVISALIGN: 3,
  CERAMIC: 4,
  KIYOCLEAR: 5,
  WIRE: 6,
  KYOSEI_GUIDE: 7,
  BRAVA: 8
}

export const IS_SUPPORT_KIREILIGN = 1

export const BRAND_MASTERS = [
  {
    id: 1,
    type: 0,
    tag: 'kireilign',
    name: 'キレイライン'
  },
  {
    id: 2,
    type: 1,
    tag: 'kids',
    name: 'キレイラインKIDS'
  },
  {
    id: 3,
    type: 2,
    tag: 'invisalign',
    name: 'インビザライン'
  },
  {
    id: 4,
    type: 3,
    tag: 'ceramic',
    name: 'セラミック'
  },
  {
    id: 5,
    type: 4,
    tag: 'kiyoclear',
    name: 'キヨクリア'
  },
  {
    id: 6,
    type: 5,
    tag: 'wire',
    name: '口ゴボ'
  },
  {
    id: 7,
    type: 6,
    tag: 'kyosei-guide',
    name: '矯正歯科ガイド'
  },
  {
    id: 8,
    type: 7,
    tag: 'brava',
    name: 'ブラーバ'
  }
]

export const ADDITIONAL_BRAND_DOMAIN_IDS = {
  SECOND: 2,
  THIRD: 3
}

export const FILE_EXTENSION = {
  STL: 'stl'
}
