<template>
  <div class="comment-tab">
    <div class="comment-tab__form">
      <textarea
        v-model="memo"
        :placeholder="$t('objects.patient.placeholder.post_comment')"
        :class="['comment-tab__input', { 'common-input-error': is_show_error_msg }]"
        rows="1"
        @keydown="styleHeightTextArea"
      />
      <button
        class="comment-tab__button"
        @click="addMemo"
      >
        {{ $t('new_text.send') }}
      </button>
    </div>
    <span
      v-if="is_show_error_msg"
      class="common-msg-error"
    >
      {{ $t('new_validation.patient.comment_required') }}
    </span>

    <div class="comment-tab__list">
      <template v-for="(activity, index) in comments_data">
        <comment-item
          :key="index"
          :activity="activity"
          :author="patientInfo.name"
          @delete-memo="handleDeleteMemo"
        />
      </template>
    </div>

    <infinite-loading
      v-if="page < last_page"
      @infinite="infiniteScrollHandler"
    >
      <template #no-more>
        <span class="d-none" />
      </template>
      <template #no-results>
        <span class="d-none" />
      </template>
    </infinite-loading>
  </div>
</template>

<script>
  import InfiniteLoading from 'vue-infinite-loading'
  import { ACTIVITY_LOG_TYPE } from '../../../../utils/constants'
  import { mbtrim, safeGet, showMessageError } from '../../../../utils/helpers'
  import CommentItem from './CommentItem'
  import { showErrorModal } from '../../../../clinic-admin/utils/helpers'

  export default {
    components: {
      CommentItem,
      InfiniteLoading
    },
    data () {
      return {
        page: 1,
        last_page: 1,
        comments_data: [],
        is_show_modal_delete_memo: false,
        patient_id: '',
        memo: '',
        is_show_error_msg: false
      }
    },
    computed: {
      patientInfo () {
        return this.$store.getters.get_patient.info
      }
    },
    watch: {
      memo (val) {
        if (val) {
          this.is_show_error_msg = false
        }
      }
    },
    created () {
      this.patient_id = this.$route.params.id
      this.getPatientComments()
    },
    methods: {
      getPatientComments () {
        this.$store.dispatch('getPatientActivities', {
          patient_id: this.patient_id,
          type: ACTIVITY_LOG_TYPE.MEMO
        }).then((result) => {
          const data = safeGet(result.data, 'results')
          const activitiesData = safeGet(data, 'activities') || []

          this.page = safeGet(data, 'current_page')
          this.last_page = safeGet(data, 'last_page')
          this.comments_data.push(...activitiesData)
        }).catch((error) => {
          showMessageError(error, this)
        })
      },
      infiniteScrollHandler ($state) {
        this.page = this.page < this.last_page ? this.page + 1 : this.page

        this.$store.dispatch('getPatientActivities', {
          page: this.page,
          patient_id: this.patient_id,
          type: ACTIVITY_LOG_TYPE.MEMO
        }).then((result) => {
          const data = safeGet(result.data, 'results')
          const activitiesData = safeGet(data, 'activities') || []

          this.page = safeGet(data, 'current_page')
          this.last_page = safeGet(data, 'last_page')
          this.comments_data.push(...activitiesData)
          $state.complete()
        }).catch((error) => {
          $state.complete()
          showMessageError(error, this)
        })
      },
      addMemo () {
        if (!mbtrim(this.memo || '')) {
          this.is_show_error_msg = true
          showErrorModal(this.$t('new_validation.patient.comment_required'))

          return
        }

        this.$store.commit('set_loading', true)

        this.$store.dispatch('addMemo', {
          patient_id: this.patient_id,
          memo: this.memo.replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;')
            .replace(/(?:\r\n|\r|\n)/g, '<br>')
        }).then(async (result) => {
          const data = safeGet(result.data, 'results')
          this.comments_data.unshift(data)
          this.memo = ''
          this.$store.commit('set_loading', false)
        }).catch((error) => {
          this.$store.commit('set_loading', false)
          showMessageError(error, this)
        })
      },
      showModalConfirmDelete () {
        this.is_show_modal_delete_memo = true
      },
      handleDeleteMemo (id) {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('deleteMemo', { id }).then(() => {
          this.comments_data = this.comments_data.filter((item) => {
            return item.id !== id.id
          })

          this.$store.commit('set_loading', false)
        }).catch((error) => {
          showMessageError(error, this)
          this.$store.commit('set_loading', false)
        })
      },
      styleHeightTextArea (event) {
        setTimeout(() => {
          event.target.style.cssText = 'height: auto; padding: 0'
          event.target.style.cssText = 'height: ' + event.target.scrollHeight + 'px'
        }, 0)
      }
    }
  }
</script>
