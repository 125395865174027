<template>
  <modal-action
    class="modify-duty"
    :title="title"
    :is-show-delete-button="isShowButtonDelete"
    :is-show-footer="isShowFooter"
    @close="handleClose"
    @confirm="handleSubmit"
    @delete="$emit('delete')"
  >
    <template #body>
      <div class="modify-duty__form">
        <div class="modify-duty__group">
          <label
            class="modify-duty__label"
            for="show-name"
          >{{ $t('objects.setting.text.species_alias') }}</label>
          <div class="modify-duty__row">
            <input
              id="show-name"
              v-model="duty_name"
              :disabled="!!isPreset"
              :class="['modify-duty__input', { 'common-input-error': !validDutyName }]"
              type="text"
              :placeholder="$t('new_text.placeholder.required', { attribute: $t('new_text.species_alias') })"
            >
            <span
              v-if="!validDutyName"
              class="common-msg-error"
            >
              {{ $t('new_validation.common.required', { attribute: $t('new_text.species_alias') }) }}
            </span>
          </div>
        </div>
        <div class="modify-duty__group">
          <label class="modify-duty__label">{{ $t('objects.setting.text.required_time') }}</label>
          <div class="modify-duty__row">
            <select
              v-model="take_time"
              :disabled="!!isPreset"
              :class="['modify-duty__select', { 'common-input-error': !validTakeTime }]"
            >
              <option
                value=""
                hidden
              >
                {{ $t('new_text.placeholder.please_select') }}
              </option>
              <option
                v-for="(time, index) in takeTimeData"
                :key="index"
                :value="time"
              >
                {{ time }}
              </option>
            </select>
            <span
              v-if="!validTakeTime"
              class="common-msg-error"
            >
              {{ $t('new_validation.common.required', { attribute: $t('new_text.time_required') }) }}
            </span>
          </div>
        </div>
        <div class="modify-duty__group">
          <label class="modify-duty__label">{{ $t('objects.setting.text.take_a_position') }}</label>
          <select
            v-model="duty_color"
            :disabled="!!isPreset"
            class="modify-duty__select"
          >
            <option
              v-for="(duty, index) in colorsDuty"
              :key="index"
              :value="duty.id"
            >
              {{ duty.title }}
            </option>
          </select>
        </div>
      </div>
    </template>
  </modal-action>
</template>

<script>
  import { MODAL_TYPE_SETTINGS } from '../../../utils/constants'
  import ModalAction from '../../common/ModalAction'
  import { showErrorModal } from '../../../utils/helpers'

  const OTHER_STAFF_ID = 5

  export default {
    components: { ModalAction },
    props: {
      modalType: {
        type: String,
        default: ''
      },
      colorsDuty: {
        type: Array,
        default: () => []
      },
      dutyItem: {
        type: Object,
        default: () => {}
      }
    },
    emits: ['close', 'confirm', 'delete'],
    data () {
      return {
        duty_name: '',
        duty_color: '',
        take_time: '',
        is_show_button_delete: true,
        is_submit: false
      }
    },
    computed: {
      validDutyName () {
        if (!this.duty_name) {
          if (this.modalType === MODAL_TYPE_SETTINGS.EDIT_ITEM) {
            return false
          }

          return !this.is_submit
        }

        return true
      },
      validTakeTime () {
        return !(this.take_time && !this.takeTimeData.includes(this.take_time))
      },
      title () {
        return this.modalType === MODAL_TYPE_SETTINGS.EDIT_ITEM
          ? this.$t('objects.setting.text.reservation_type_edit')
          : this.$t('objects.setting.text.reservation_type_registration')
      },
      isShowFooter () {
        return this.modalType === MODAL_TYPE_SETTINGS.ADD_ITEM ||
          (this.modalType === MODAL_TYPE_SETTINGS.EDIT_ITEM && !this.isPreset)
      },
      isShowButtonDelete () {
        return this.modalType !== MODAL_TYPE_SETTINGS.ADD_ITEM && (!!this.isPreset || !this.reservationsCount)
      },
      isPreset () {
        return this.dutyItem.is_preset
      },
      reservationsCount () {
        return this.dutyItem.reservations_count
      },
      takeTimeData () {
        const time = []
        for (let i = 5; i <= 90; i += 5) {
          time.push(i)
        }

        return time
      }
    },
    created () {
      if (this.modalType === MODAL_TYPE_SETTINGS.EDIT_ITEM) {
        this.duty_name = this.dutyItem.duty_name
        this.duty_color = this.dutyItem.duty.id
        this.take_time = this.dutyItem.take_time || ''
      }

      if (this.modalType === MODAL_TYPE_SETTINGS.ADD_ITEM) {
        this.duty_color = OTHER_STAFF_ID
      }
    },
    methods: {
      handleClose () {
        this.duty_name = ''
        this.duty_color = ''
        this.take_time = ''
        this.$emit('close')
      },
      handleSubmit () {
        this.is_submit = true
        if (!this.takeTimeData.includes(this.take_time)) {
          showErrorModal(this.$t('new_validation.common.required', { attribute: this.$t('new_text.time_required') }))
          return
        }
        this.$emit('confirm', { duty_name: this.duty_name, take_time: this.take_time, duty_color: this.duty_color })
      }
    }
  }
</script>
