var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.other
    ? _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("h4", [_vm._v("◆ その他")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-md-12 patient-detail-left patient-info-interview p-0"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "print-component component-wrapper interview-checklist"
                },
                [
                  _c("div", { staticClass: "bg-white" }, [
                    _c(
                      "table",
                      { staticClass: "table table-interview-check-list" },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { attrs: { width: "90%" } }, [
                              _c("p", [_vm._v("転居の予定はありますか?")]),
                              _vm._v(" "),
                              _vm.isAnswerYes
                                ? _c("p", { staticClass: "pl-3" }, [
                                    _c("span", { staticClass: "mr-2" }, [
                                      _vm._v(_vm._s(_vm.other.when_to_move1))
                                    ]),
                                    _vm._v(" 年 "),
                                    _c("span", { staticClass: "mx-2" }, [
                                      _vm._v(_vm._s(_vm.other.when_to_move2))
                                    ]),
                                    _vm._v(" 月ごろ\n                  ")
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("p", { staticClass: "pl-3" }, [
                                _vm._v(_vm._s(_vm.other.moving_destination))
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { width: "10%" }
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.other.plan_to_move) +
                                    "\n                "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "2" } }, [
                              _c("p", [
                                _vm._v(
                                  "その他、診察に際してご要望などございましたら、ご記入ください。"
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "white-space-wrap" }, [
                                _vm._v(_vm._s(_vm.other.other_requests))
                              ])
                            ])
                          ])
                        ])
                      ]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _vm._m(0)
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-end" }, [
      _c("p", [_vm._v("以上")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }