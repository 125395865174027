<template>
  <modal-action
    class="modal-v2--reservation-cancel"
    :title="$t('objects.reservation.text.search_cancel_reservation_title')"
    :is-show-cancel-button="false"
    @close="closeModal"
    @confirm="closeModal"
  >
    <template #body>
      <div class="reservation-cancel-modal">
        <form @submit.prevent="loadReservationCancel">
          <div class="reservation-cancel-modal__search">
            <div class="datepicker datepicker--header-calender">
              <div>
                <date-picker
                  v-model="started_to"
                  :class="['reservation-cancel-modal__search__picker', { 'common-input-error': isValidStartedTo }]"
                  readonly="readonly"
                  :wrap="false"
                  :config="dpOptions"
                />
                <span
                  v-if="isValidStartedTo"
                  class="common-msg-error"
                >
                  {{ isValidStartedTo }}
                </span>
              </div>
              <span class="reservation-cancel-modal__search__link"> 〜 </span>
              <div>
                <date-picker
                  v-model="ended_from"
                  :class="['reservation-cancel-modal__search__picker', { 'common-input-error': isValidEndedFrom }]"
                  readonly="readonly"
                  :wrap="false"
                  :config="dpOptions"
                />
                <span
                  v-if="isValidEndedFrom"
                  class="common-msg-error"
                >
                  {{ isValidEndedFrom }}
                </span>
              </div>
            </div>
            <button
              type="submit"
              class="btn-v2 reservation-cancel-modal__search__submit"
            >
              {{ $t('common.search') }}
            </button>
            <div class="reservation-cancel-modal__search__total">
              <div class="reservation-cancel-modal__search__total__text">
                {{ $t('objects.reservation.text.canceller') }}
              </div>
              <div><span>{{ reservation_cancelled_total }}</span>{{ $t('new_text.first_name2') }}</div>
            </div>
          </div>
        </form>
        <table :class="['reservation-cancel-modal__table', {'lazy-load lazy-load--no-background': lazy_load}]">
          <thead>
            <tr>
              <th>{{ $t('objects.reservation.text.reservations_frame') }}</th>
              <th
                class="common-list__sort"
                @click="sortBy('started_to')"
              >
                {{ $t('objects.reservation.text.reservation_date_and_time') }}
                <span class="common-list__icon common-list__sort">
                  <img :src="getIconSort('started_to')">
                </span>
              </th>
              <th
                class="common-list__sort"
                @click="sortBy('reservations.sf_user_id')"
              >
                {{ $t('objects.patient.text.patient_id') }}
                <span class="common-list__icon common-list__sort">
                  <img :src="getIconSort('reservations.sf_user_id')">
                </span>
              </th>
              <th>{{ $t('new_text.name') }}</th>
              <th
                class="common-list__sort"
                @click="sortBy('cancelled_at')"
              >
                {{ $t('objects.reservation.text.cancellation_time') }}
                <span class="common-list__icon common-list__sort">
                  <img :src="getIconSort('cancelled_at')">
                </span>
              </th>
              <th>{{ $t('objects.reservation.text.cancelled_reason') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(reservation, index) in reservation_cancelled"
              :key="index"
              @click="gotoReservationCalendar(reservation.started_to, reservation.id, $event)"
            >
              <td>
                <span class="three-dot">
                  {{ reservation.column_name }}
                </span>
              </td>
              <td>{{ formatDate(reservation.started_to, reservation.ended_from) }}</td>
              <td>
                <router-link
                  target="_blank"
                  class=""
                  :to="{ name: 'patient.detail', params: { id: reservation.patient_id } }"
                >
                  {{ reservation.sf_user_id || reservation.patient_id }}
                </router-link>
              </td>
              <td>
                <router-link
                  target="_blank"
                  class="three-dot"
                  :to="{ name: 'patient.detail', params: { id: reservation.patient_id } }"
                >
                  {{ reservation.name }}
                </router-link>
              </td>
              <td>{{ formatDate(reservation.cancelled_at) }}</td>
              <td>{{ reservation.cancelled_reason }}</td>
            </tr>
            <span
              v-if="errors.no_result"
              class="error"
            >{{ errors.no_result }}</span>
            <infinite-loading
              :identifier="infinite_scroll_id"
              @infinite="infiniteScrollHandler"
            >
              <template #no-more>
                <span class="d-none" />
              </template>
              <template #no-results>
                <span class="d-none" />
              </template>
            </infinite-loading>
          </tbody>
        </table>
      </div>
    </template>
    <template #confirm_button_content>
      {{ $t('new_text.button.close') }}
    </template>
  </modal-action>
</template>

<script>
  import ModalAction from '../common/ModalAction'
  import InfiniteLoading from 'vue-infinite-loading'
  import helpers from '../../utils/helpers'
  import * as moment from 'moment'
  import DatePicker from 'vue-bootstrap-datetimepicker'
  import sortMixin from '../../mixins/sort'

  export default {
    components: {
      ModalAction,
      InfiniteLoading,
      DatePicker
    },
    mixins: [sortMixin],
    props: {
      date: {
        type: String,
        default: moment().format('YYYY-MM-DD')
      }
    },
    emits: ['close', 'reload-reservation-data'],
    data () {
      return {
        page: 1,
        reservation_cancelled: [],
        reservation_cancelled_total: 0,
        count_loaded: 0,
        dpOptions: {
          format: 'YYYY年MM月DD日',
          useCurrent: false,
          dayViewHeaderFormat: 'YYYY / MM',
          locale: 'ja',
          ignoreReadonly: true,
          inline: false
        },
        started_to: '',
        ended_from: '',
        infinite_scroll_id: 1,
        errors: {
          no_result: ''
        },
        lazy_load: false
      }
    },
    computed: {
      isValidStartedTo () {
        if (!moment(this.started_to, this.$t('datetime.year_month_day')).isValid()) {
          return this.$t('new_validation.common.invalid', { attribute: this.$t('new_text.day') })
        }

        return ''
      },
      isValidEndedFrom () {
        if (!moment(this.ended_from, this.$t('datetime.year_month_day')).isValid()) {
          return this.$t('new_validation.common.invalid', { attribute: this.$t('new_text.day') })
        }

        return ''
      }
    },
    created () {
      this.started_to = this.date
      this.ended_from = this.date
    },
    methods: {
      loadReservationCancel () {
        if (this.isValidStartedTo || this.isValidEndedFrom) {
          return
        }

        this.page = 1
        this.reservation_cancelled = []
        this.count_loaded = 0
        this.infinite_scroll_id++
      },
      infiniteScrollHandler ($state) {
        if (this.lazy_load) {
          return
        }

        this.lazy_load = true
        const submitData = {
          started_to: moment(this.started_to, this.$t('datetime.year_month_day')).format(this.$t('datetime.year_month_day', 'en')),
          ended_from: moment(this.ended_from, this.$t('datetime.year_month_day')).format(this.$t('datetime.year_month_day', 'en')),
          page: this.page,
          paginate: true
        }

        if (this.sort_field) {
          submitData.order_type = this.sort_type
          submitData.order_field = this.sort_field
        }

        this.emptyErrors()
        this.$store.dispatch('getListReservationCancel', submitData)
          .then((result) => {
            const reservationCancelled = result?.data?.results?.data || []
            this.reservation_cancelled_total = result?.data?.results?.total || 0

            if (reservationCancelled.length === 0) {
              this.errors.no_result = this.$t('new_message.no_result_search')
              $state.complete()
              this.lazy_load = false

              return
            }

            if (reservationCancelled.length) {
              this.reservation_cancelled.push(...reservationCancelled)
              this.page++
              this.count_loaded += reservationCancelled.length

              if (this.count_loaded < this.reservation_cancelled_total) {
                $state.loaded()
              } else {
                $state.complete()
              }
            } else {
              if (this.page === 1) {
                $state.reset()
              }
              $state.complete()
            }
            this.lazy_load = false
          })
          .catch(() => {
            this.lazy_load = false
            helpers.showErrorModal(this.$t('new_message.something_went_wrong'))
          })
      },
      closeModal () {
        this.emptyErrors()
        this.emptyData()
        this.$emit('close')
        this.$emit('reload-reservation-data')
      },
      emptyData () {
        this.page = 1
        this.reservation_cancelled = []
        this.reservation_cancelled_total = 0
        this.count_loaded = 0
      },
      emptyErrors () {
        this.errors = {
          no_result: ''
        }
      },
      formatDate (startedTo, endedFrom = '') {
        if (startedTo && endedFrom) {
          return moment(startedTo).format('YYYY/MM/DD HH:mm') + ' ～ ' + moment(endedFrom).format('HH:mm')
        }

        return moment(startedTo).format('YYYY/MM/DD HH:mm')
      },
      gotoReservationCalendar (date, reservationId, event) {
        if (event.target.tagName === 'A') {
          return
        }

        if (this.date === moment(date).format('YYYY-MM-DD')) {
          this.$router.push({
            name: 'reservation.list',
            query: {
              search_date: moment(date).format('YYYY-MM-DD'),
              reservation_id: reservationId
            }
          })
          location.reload()
          return
        }
        const router = this.$router.resolve({
          name: 'reservation.list',
          query: {
            search_date: moment(date).format('YYYY-MM-DD'),
            reservation_id: reservationId
          }
        })
        window.open(router.href, '_blank')
      },
      sortBy (field) {
        this.setDataSort(field)
        this.loadReservationCancel()
      }
    }
  }
</script>
