<template>
  <div class="instruction-print">
    <iframe
      style="width: 100% !important; height: 100% !important;"
      :src="instruction_file_url"
    />
  </div>
</template>

<script>

  export default {
    data () {
      return {
        instruction_file_url: [],
        is_multiple: true
      }
    },
    created () {
      window.getListOrderInstruction = this.getData.bind(this)
    },
    methods: {
      getData: async function (data) {
        this.instruction_file_url = data[0]
        this.is_multiple = data[1]
        await this.getFilePdf()
      },
      getFilePdf: async function () {
        const previewTab = this.is_multiple ? window.open(this.instruction_file_url, '_blank') : window.frames[0]

        setTimeout(() => {
          previewTab.print()
        }, 100)
      }
    }
  }
</script>
