import * as moment from 'moment'

export default {
  methods: {
    getServiceDate: function (item) {
      const startDate = moment(item.start_date)
      const endDate = moment(item.end_date)
      if (startDate.isValid() && endDate.isValid()) {
        return `${startDate.format(this.$t('datetime.year_month_day'))} ~ ${endDate.format(this.$t('datetime.year_month_day'))}`
      }
      return ''
    }
  }
}

export const accountingMixin = {
  methods: {
    getServiceDate: function (item) {
      const startDate = moment(item.start_date)
      const endDate = moment(item.end_date)
      if (startDate.isValid() && endDate.isValid()) {
        return `${startDate.format(this.$t('new_text.year_month_day'))} ~ ${endDate.format(this.$t('new_text.year_month_day'))}`
      }
      return ''
    }
  }
}
