<template>
  <div class="content-inner bg-white interview-block">
    <div class="page-content">
      <div class="header-title">
        <p>{{ $t('interview.prior_consultation') }}</p>
        <p
          class="gray-arrow"
          :class="{ 'toggle-icon' : show }"
          @click="handleClick"
        />
      </div>
      <div
        v-show="show"
        class="pre-interview-page content-block"
      >
        <div class="patient-detail-left">
          <div class="row-content">
            <div class="col-6 pre-block">
              <!-- Q1 -->
              <answer-common
                :question="'他の医院で歯並びの相談をされたことはありますか？'"
                :answer="answers.question_1.answer"
                :question_id="answers.question_1.question"
                class="question-no-1"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div
                      v-for="(detail, index) in answers.question_1.items.details"
                      :key="index"
                      class="form-group d-flex"
                    >
                      <label class="control-label same-width my-0">{{ detail.title }}</label>
                      <div class="control-text my-0">
                        {{ detail.content }}
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>
              <!-- Q3 -->
              <answer-common
                :question="'アレルギーはありますか?'"
                :answer="answers.question_3.answer"
                :question_id="answers.question_3.question"
                class="question-no-3"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div
                      v-if="answers.question_3.view_content_1"
                      class="control-text my-0"
                    >
                      {{ answers.question_3.view_content_1 }}あり
                    </div>
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">金属名：</label>
                      <div class="control-text my-0">
                        {{ answers.question_3.view_content_2 }}
                      </div>
                    </div>
                    <div
                      v-if="answers.question_3.view_content_3"
                      class="control-text my-0"
                    >
                      {{ answers.question_3.view_content_3 }}あり
                    </div>
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">薬品名：</label>
                      <div class="control-text my-0">
                        {{ answers.question_3.view_content_4 }}
                      </div>
                    </div>
                    <div
                      v-if="answers.question_3.view_content_5"
                      class="control-text my-0"
                    >
                      {{ answers.question_3.view_content_5 }}あり
                    </div>
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">発症時期：</label>
                      <div class="control-text my-0">
                        {{ answers.question_3.view_content_6 }}
                      </div>
                    </div>
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">原因植物：</label>
                      <div class="control-text my-0">
                        {{ answers.question_3.view_content_7 }}
                      </div>
                    </div>
                    <div
                      v-if="answers.question_3.view_content_8"
                      class="control-text my-0"
                    >
                      {{ answers.question_3.view_content_8 }}あり
                    </div>
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">原因物質：</label>
                      <div class="control-text my-0">
                        {{ answers.question_3.view_content_9 }}
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q6 -->
              <answer-common
                :question="'今までに口や顔の周りの癖はありましたか？'"
                :answer="answers.question_6.answer"
                :question_id="answers.question_6.question"
                class="question-no-6"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div class="form-group d-flex">
                      <div class="control-text my-0">
                        {{ answers.question_6.view_content_1 }}
                      </div>
                    </div>
                    <div class="control-text my-0">
                      {{ answers.question_6.view_content_2 }}
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q7 -->
              <answer-common
                :question="'喋りにくい言葉はありますか？<br>（サ行、タ行が言いづらいなど）'"
                :answer="answers.question_7.answer"
                :question_id="answers.question_7.question"
                class="question-no-7"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">{{ $t('objects.patient.text.specifically') }}:</label>
                      <div class="control-text my-0">
                        {{ answers.question_7.view_content }}
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q10 -->
              <answer-common
                :question="'家族に不正咬合の方はいますか？例：でこぼこ、出っ歯、反っ歯、受け口など'"
                :answer="answers.question_10.answer"
                :question_id="answers.question_10.question"
                class="question-no-10"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div
                      v-for="(item,index) in answers.question_10.items"
                      :key="index"
                      class="d-flex"
                    >
                      <div class="number-answer">{{ index+1 }}</div>
                      <div class="content-text">
                        <div
                          v-for="(detail, i) in item.details"
                          :key="i"
                        >
                          <div class="form-group d-flex">
                            <label class="control-label same-width my-0">{{ detail.title }}:</label>
                            <div class="control-text my-0">
                              {{ convertOptions(detail) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q12 -->
              <answer-common
                :question="'現在、通院されていますか？'"
                :answer="answers.question_12.answer"
                :question_id="answers.question_12.question"
                class="question-no-12"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div
                      v-for="(item,index) in answers.question_12.items"
                      :key="index"
                      class="d-flex"
                    >
                      <div class="number-answer">{{ index+1 }}</div>
                      <div class="content-text">
                        <div
                          v-for="(detail, i) in item.details"
                          :key="i"
                        >
                          <div class="form-group d-flex">
                            <label class="control-label same-width my-0">{{ detail.title }}:</label>
                            <div class="control-text my-0">
                              {{ convertOptions(detail) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q14 -->
              <answer-common
                :question="'今までにかかった病気はありますか？'"
                :answer="answers.question_14.answer"
                :question_id="answers.question_14.question"
                class="question-no-14"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div class="form-group d-flex">
                      <div class="control-text my-0">
                        {{ convertOptions(answers.question_14.view_content) }}
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q15 -->
              <answer-common
                :question="'今までに次のようなことがありましたか？'"
                :answer="answers.question_15.answer"
                :question_id="answers.question_15.question"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div class="form-group d-flex">
                      <div class="control-text my-0">
                        {{ answers.question_15.view_content }}
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q17 -->
              <answer-common
                :question="'喫煙習慣はありますか？'"
                :answer="answers.question_17.answer"
                :question_id="answers.question_17.question"
                class="question-no-17"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">一日量：</label>
                      <div class="control-text my-0">
                        {{ convertOptions(answers.question_17.items.details[0]) }} {{ $t('interview.today') }}
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q18 -->
              <answer-common
                :question="'飲酒習慣はありますか？'"
                :answer="answers.question_18.answer"
                :question_id="answers.question_18.question"
                class="question-no-18"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">よく飲まれるお酒：</label>
                      <div class="control-text my-0">
                        {{ answers.question_18.view_content_1 }}
                      </div>
                    </div>
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">一日量：</label>
                      <div class="control-text my-0">
                        {{ answers.question_18.view_content_2 }}<span class="p-2">mlまたは</span>{{ answers.question_18.view_content_3 }} 合位
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q21 -->
              <answer-common
                :question="'血圧の状態は？'"
                :answer="answers.question_21.answer"
                :no_answer="true"
                :question_id="answers.question_21.question"
                class="question-no-21"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div class="form-group d-flex">
                      <label>{{ answers.question_21.view_content_1 }}</label>
                    </div>
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">{{ $t('interview.highest') }}：</label>
                      <div class="control-text my-0">
                        {{ answers.question_21.view_content_2 }}
                      </div>
                    </div>
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">{{ $t('interview.lowest') }}：</label>
                      <div class="control-text my-0">
                        {{ answers.question_21.view_content_3 }}
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q24 -->
              <answer-common
                :question="'最近、歯科医院を受診されましたか？'"
                :answer="answers.question_24.answer"
                :question_id="answers.question_24.question"
                class="question-no-24"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div class="form-group">
                      <div class="control-text">{{ answers.question_24.view_content_1 }}<span class="p-2">年</span>{{ answers.question_24.view_content_2 }}<span class="p-2">月ごろ</span></div>
                    </div>
                    <div class="form-group">
                      <div class="control-text"><span class="p-2">または</span>{{ answers.question_24.view_content_3 }}<span class="p-2">年</span>{{ answers.question_24.view_content_4 }}<span class="p-2">ヶ月前</span></div>
                    </div>
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">治療内容：</label>
                      <div class="control-text my-0">{{ answers.question_24.view_content_5 }}</div>
                    </div>
                  </div>
                </div>
              </answer-common>
            </div>
            <div class="col-6 pre-block">

              <!-- Q2 -->
              <answer-common
                :question="'過去に矯正歯科治療されたことはありますか？'"
                :answer="answers.question_2.answer"
                :question_id="answers.question_2.question"
                class="question-no-2"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">いつ頃：</label>
                      <div class="control-text my-0">
                        {{ answers.question_2.view_content_1 }}
                      </div>
                    </div>
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">症状：</label>
                      <div class="control-text my-0">
                        {{ answers.question_2.view_content_2 }}
                      </div>
                    </div>
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">矯正装置：</label>
                      <div class="control-text my-0">
                        {{ answers.question_2.view_content_3 }}
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q4 -->
              <answer-common
                :question="'顎の症状で今までに何か経験がありますか？'"
                :answer="answers.question_4.answer"
                :question_id="answers.question_4.question"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div class="form-group d-flex">
                      <label class="control-label same-width my-0">いつ頃：</label>
                      <div class="control-text my-0">
                        {{ answers.question_4.view_content_1 }}
                      </div>
                    </div>
                    <div class="form-group d-flex">
                      <div class="control-text my-0">
                        {{ answers.question_4.view_content_2 }}
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q5 -->
              <answer-common
                :question="'今まで顔や顎を強くぶつけた・ケガをした経験はありますか？'"
                :answer="answers.question_5.answer"
                :question_id="answers.question_5.question"
                class="question-no-5"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div
                      v-for="(detail, index) in answers.question_5.items.details"
                      :key="index"
                      class="form-group d-flex"
                    >
                      <label class="control-label same-width my-0">{{ detail.title }}</label>
                      <div class="control-text my-0">
                        {{ detail.content }}
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q8 -->
              <answer-common
                :question="'次のような症状はありますか？'"
                :answer="answers.question_8.answer"
                :question_id="answers.question_8.question"
                class="question-no-8"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div class="form-group d-flex">
                      <div class="control-text my-0">
                        {{ convertOptions(answers.question_8.view_content) }}
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q9 -->
              <div class="item-pre">
                <div class="question">
                  <div class="form-group">
                    <div class="control-text">寝相はどの状態が多いですか？</div>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="content-text">
                    <div class="form-group d-flex">
                      <div class="control-text my-0">
                        {{ answers.question_9.view_content }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Q11 -->
              <answer-common
                :question="'今まで耳鼻科を受診したことはありますか？'"
                :answer="answers.question_11.answer"
                :question_id="answers.question_11.question"
                class="question-no-11"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div
                      v-for="(item, index) in answers.question_11.items"
                      :key="index"
                      class="d-flex"
                    >
                      <div class="number-answer">{{ index+1 }}</div>
                      <div class="content-text">
                        <div
                          v-for="(detail, i) in item.details"
                          :key="i"
                        >
                          <div class="form-group d-flex">
                            <label class="control-label same-width my-0">{{ detail.title }}:</label>
                            <div class="control-text my-0">
                              {{ convertOptions(detail) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q13 -->
              <answer-common
                :question="'身体全体で先天性の疾患や生まれつき気になる事、部位はありますか？'"
                :answer="answers.question_13.answer"
                :question_id="answers.question_13.question"
                class="question-no-13"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div
                      v-for="(item,index) in answers.question_13.items"
                      :key="index"
                      class="d-flex"
                    >
                      <div class="number-answer">{{ index+1 }}</div>
                      <div class="content-text">
                        <div
                          v-for="(detail, i) in item.details"
                          :key="i"
                        >
                          <div class="form-group d-flex">
                            <label class="control-label same-width my-0">{{ detail.title }}:</label>
                            <div class="control-text my-0">
                              {{ convertOptions(detail) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q16 -->
              <answer-common
                :question="'現在飲んでいる薬はありますか？'"
                :answer="answers.question_16.answer"
                :question_id="answers.question_16.question"
                class="question-no-16"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div class="control-text my-0">
                      {{ answers.question_16.view_content }}
                    </div>
                    <div
                      v-for="(details,index) in answers.question_16.custom_items"
                      :key="index"
                      class="d-flex"
                    >
                      <div class="number-answer">{{ index+1 }}</div>
                      <div class="content-text">
                        <div
                          v-for="(detail, i) in details"
                          :key="i"
                        >
                          <div class="form-group d-flex">
                            <label class="control-label same-width my-0">{{ detail.title }}:</label>
                            <div class="control-text my-0">
                              {{ convertOptions(detail) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q19 -->
              <div class="item-pre question-no-19">
                <div class="answer">
                  <div class="d-flex">
                    <div class="content-text">
                      <div class="form-group d-flex">
                        <label class="same-width my-0">身長</label>
                        <div class="control-text my-0">
                          {{ answers.question_19.view_content_1 }} cm
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Q19 -->
              <div class="item-pre question-no-19">
                <div class="answer">
                  <div class="d-flex">
                    <div class="content-text">
                      <div class="form-group d-flex">
                        <label class="same-width my-0">体重</label>
                        <div class="control-text my-0">
                          {{ answers.question_19.view_content_2 }} kg
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Q20 -->
              <div class="item-pre question-no-20">
                <div class="answer">
                  <div class="d-flex">
                    <div class="content-text">
                      <div class="form-group d-flex">
                        <label class="same-width my-0">血液型</label>
                        <div class="control-text my-0">
                          {{ answers.question_20.view_content }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Q22 -->
              <answer-common
                :question="'女性の方のみ'"
                :answer="answers.question_22.answer"
                :no_answer="true"
                :question_id="answers.question_22.question"
                class="question-no-22"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div class="form-group d-flex">
                      <div class="control-text my-0">
                        {{ answers.question_22.view_content_1 }}<span class="p-2">歳</span>{{ answers.question_22.view_content_2 }}<span class="pt-2">ヶ月</span>
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>

              <!-- Q23 -->
              <answer-common
                :question="'以前に歯科医院で歯を抜いたことがありますか？'"
                :answer="answers.question_23.answer"
                :question_id="answers.question_23.question"
                class="question-no-23"
              >
                <div class="d-flex">
                  <div class="content-text">
                    <div class="form-group d-flex">
                      <div class="control-text my-0">
                        {{ answers.question_23.view_content }}
                      </div>
                    </div>
                  </div>
                </div>
              </answer-common>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AnswerCommon from '../template/AnswerCommon'
  import { OTHER_STRING } from '../../../../utils/constants.js'
  import { cloneDeep } from 'lodash'

  export default {
    name: 'PreInterviewPage',
    components: {
      AnswerCommon
    },
    data () {
      return {
        show: true,
        answers: {}
      }
    },
    created () {
      this.loadInterviewSheet()
    },
    methods: {
      convertOptions (obj) {
        if (obj && typeof obj === 'object' && obj.content) {
          return obj.content.toString()
        }
        if (typeof obj === 'string') {
          return obj
        }
        return ''
      },
      handleClick () {
        this.show = !this.show
      },
      getContentFromQuestion (item, title) {
        const data = item.find(item => item.title === title)
        if (typeof data.content !== 'undefined') {
          if (Array.isArray(data.content)) {
            return data.content.join(' ')
          }
          return data.content
        }
        return ''
      },
      filterOtherContent: function (content = '', other = '') {
        if (Array.isArray(content) && typeof other === 'string' && content.includes(OTHER_STRING)) {
          content = content.filter(e => e !== OTHER_STRING)
          content.push(other)
        } else if (typeof content === 'string' && typeof other === 'string') {
          content += ' ' + other
        }

        return content
      },
      loadInterviewSheet: function () {
        const selectOptionsQuestion = [8, 15]
        const answer = this.$store.getters.get_interviewsheet
        // set data for answer
        answer.forEach((objAnswer, index) => {
          const objMain = cloneDeep(objAnswer)
          const number = index + 1
          // custom content
          if (selectOptionsQuestion.includes(number)) {
            objMain.view_content = this.getContentFromQuestion(objMain.items.details, '選択肢').toString()
          }

          switch (number) {
            case 2:
              objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, 'いつ頃')
              objMain.view_content_2 = this.getContentFromQuestion(objMain.items.details, '症状')
              const otherQ2 = this.getContentFromQuestion(objMain.items.details, 'その他')
              let contentQ2 = this.getContentFromQuestion(objMain.items.details, '矯正装置')
              contentQ2 = this.filterOtherContent(contentQ2, otherQ2)
              objMain.view_content_3 = contentQ2.toString()
              break
            case 3:
              objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, '金属アレルギー').toString()
              objMain.view_content_2 = this.getContentFromQuestion(objMain.items.details, '金属名')
              objMain.view_content_3 = this.getContentFromQuestion(objMain.items.details, '薬アレルギー').toString()
              objMain.view_content_4 = this.getContentFromQuestion(objMain.items.details, '薬品名')
              objMain.view_content_5 = this.getContentFromQuestion(objMain.items.details, '花粉症').toString()
              objMain.view_content_6 = this.getContentFromQuestion(objMain.items.details, '発症時期')
              objMain.view_content_7 = this.getContentFromQuestion(objMain.items.details, '原因植物')
              objMain.view_content_8 = this.getContentFromQuestion(objMain.items.details, 'その他のアレルギー').toString()
              objMain.view_content_9 = this.getContentFromQuestion(objMain.items.details, 'アレルギー物質')
              break
            case 4:
              objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, 'いつ頃')
              let contentQ4 = this.getContentFromQuestion(objMain.items.details, '選択結果')
              const otherQ4 = this.getContentFromQuestion(objMain.items.details, 'その他')
              contentQ4 = this.filterOtherContent(contentQ4, otherQ4)
              objMain.view_content_2 = contentQ4.toString()
              break
            case 6:
              objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, 'いつ頃')
              let contentQ6 = this.getContentFromQuestion(objMain.items.details, '選択肢')
              const otherQ6 = this.getContentFromQuestion(objMain.items.details, 'その他')
              contentQ6 = this.filterOtherContent(contentQ6, otherQ6)
              objMain.view_content_2 = contentQ6.toString()
              break
            case 7:
              objMain.view_content = this.getContentFromQuestion(objMain.items.details, '具体的に')
              break
            case 9:
              const content1 = this.getContentFromQuestion(objMain.items.details, '仰向け')
              const content2 = this.getContentFromQuestion(objMain.items.details, 'うつ伏せ')
              const content3 = this.getContentFromQuestion(objMain.items.details, '頭の向き')
              const content4 = this.getContentFromQuestion(objMain.items.details, '横向き')
              const content5 = this.getContentFromQuestion(objMain.items.details, '向き')
              const content6 = this.getContentFromQuestion(objMain.items.details, '様々寝相が変わる')
              objMain.view_content = [content1.toString(), content2.toString(), content3, content4.toString(), content5, content6.toString()].filter(Boolean).join(',')
              if (!objMain.view_content) {
                objMain.view_content = '(未入力)'
              }
              break
            case 14:
              let contentQ14 = this.getContentFromQuestion(objMain.items.details, '選択肢')
              const otherQ14 = this.getContentFromQuestion(objMain.items.details, 'その他')
              contentQ14 = this.filterOtherContent(contentQ14, otherQ14)
              objMain.view_content = contentQ14.toString()
              break
            case 16:
              let contentQ16 = this.getContentFromQuestion(objMain.item_common.details, '選択肢')
              const otherQ16 = this.getContentFromQuestion(objMain.item_common.details, 'その他')
              contentQ16 = this.filterOtherContent(contentQ16, otherQ16)
              objMain.view_content = contentQ16.toString()
              objMain.custom_items = []
              const vm = this
              objMain.items.forEach(function (subItem, index) {
                let diagnostis = vm.getContentFromQuestion(subItem.details, '診断名')
                let name1 = vm.getContentFromQuestion(subItem.details, '服薬量1')
                let name2 = vm.getContentFromQuestion(subItem.details, '服薬量2')
                const formatName = `1日 ${name1} 回 ${name2} 錠/包/ml`
                objMain.custom_items.push([
                  { title: '薬品名', content: diagnostis },
                  { title: '服薬量', content: formatName }
                ])
              })
              break
            case 18:
              objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, 'よく飲まれるお酒')
              objMain.view_content_2 = this.getContentFromQuestion(objMain.items.details, '一日量1')
              objMain.view_content_3 = this.getContentFromQuestion(objMain.items.details, '一日量2')
              break
            case 19:
              objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, '身長')
              objMain.view_content_2 = this.getContentFromQuestion(objMain.items.details, '体重')
              break
            case 20:
              const bloodType = this.getContentFromQuestion(objMain.items.details, '血液型')
              objMain.view_content = bloodType.toString()
              break
            case 21:
              const bloodPressureStatus = this.getContentFromQuestion(objMain.items.details, '血圧の状態')
              objMain.view_content_1 = bloodPressureStatus.toString()
              objMain.view_content_2 = this.getContentFromQuestion(objMain.items.details, '最高')
              objMain.view_content_3 = this.getContentFromQuestion(objMain.items.details, '最低')
              break
            case 22:
              objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, '初潮年齢1')
              objMain.view_content_2 = this.getContentFromQuestion(objMain.items.details, '初潮年齢2')
              break
            case 23:
              let contentQ23 = this.getContentFromQuestion(objMain.items.details, '選択肢')
              const otherQ23 = this.getContentFromQuestion(objMain.items.details, 'その他')
              contentQ23 = this.filterOtherContent(contentQ23, otherQ23)
              objMain.view_content = otherQ23.toString()
              break
            case 24:
              objMain.view_content_1 = this.getContentFromQuestion(objMain.items.details, '受診時期1');
              objMain.view_content_2 = this.getContentFromQuestion(objMain.items.details, '受診時期2');
              objMain.view_content_3 = this.getContentFromQuestion(objMain.items.details, '受診時期3');
              objMain.view_content_4 = this.getContentFromQuestion(objMain.items.details, '受診時期4');
              objMain.view_content_5 = this.getContentFromQuestion(objMain.items.details, '治療内容');
              break
          }
          const customName = 'question_' + number
          this.answers[customName] = objMain
        })
      }
    }
  }
</script>
