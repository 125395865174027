<template>
  <div class="header-notification">
    <span
      class="header-notification__close"
      @click="$emit('close')"
    ><img src="/images/icon-close-dask-gray.svg"></span>
    <div
      v-dompurify-html="get(notification_info, 'content', '')"
      class="header-notification__content"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { get } from 'lodash'

  export default {
    emits: ['close'],
    computed: {
      ...mapState('notification', ['notification_info'])
    },
    methods: {
      get
    }
  }
</script>
