var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkbox-v2" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.checked,
          expression: "checked"
        }
      ],
      attrs: { id: _vm.generateId, type: "checkbox", name: _vm.name },
      domProps: {
        value: _vm.val,
        checked: Array.isArray(_vm.checked)
          ? _vm._i(_vm.checked, _vm.val) > -1
          : _vm.checked
      },
      on: {
        change: [
          function($event) {
            var $$a = _vm.checked,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = _vm.val,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.checked = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.checked = $$c
            }
          },
          _vm.onChange
        ]
      }
    }),
    _vm._v(" "),
    _c("label", { attrs: { for: _vm.generateId } }, [_vm._v(_vm._s(_vm.label))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }