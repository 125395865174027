<template>
  <div>
    <modal-action
      class="modal-ip-setting"
      :title="ip_address"
      @close="$emit('close')"
    >
      <template #body>
        <div class="modal-ip-setting__container">
          <div class="modal-ip-setting__content">
            <div class="modal-ip-setting__content__label">
              <label>{{ $t('objects.setting.text.request_staff_id') }}</label>
              <span class="break-all">{{ staff.name }} ({{ staff.login_id }})</span>
            </div>
            <div class="modal-ip-setting__content__label">
              <label>{{ $t('new_text.date_and_time') }}</label>
              <span>{{ updated_at }}</span>
            </div>
          </div>
          <label class="modal-ip-setting__item modal-ip-setting__item--label">
            {{ $t('objects.setting.text.setting') }}
          </label>
          <div class="modal-ip-setting__group--bd modal-ip-setting__group--content-input">
            <label class="modal-ip-setting__item modal-ip-setting__item--label">{{ $t('new_text.label') }}</label>
            <div class="modal-ip-setting__group--content">
              <input
                v-model="label"
                :class="[
                  'modal-ip-setting__item modal-ip-setting__item--control modal-ip-setting__item--label-input',
                  { 'common-input-error': error_messages.label }
                ]"
                :placeholder="$t('objects.setting.text.label_placeholder')"
                required="required"
                @input="handleValidationLabel"
              >
              <span
                v-if="error_messages.label"
                class="common-msg-error error-message"
              >
                {{ error_messages.label }}
              </span>
            </div>
          </div>
          <div class="modal-ip-setting__group--content-input">
            <label class="modal-ip-setting__item modal-ip-setting__item--label">
              {{ $t('objects.setting.text.ip') }}</label>
            <div class="modal-ip-setting__group--content modal-ip-setting__group--content--ip">
              <div class="modal-ip-setting__group--inner-input">
                <input
                  v-model="ip_address"
                  :class="['modal-ip-setting__item modal-ip-setting__item--control', { 'common-input-error': error_messages.ip_address }]"
                  required="required"
                  @input="handleIPChange"
                >
                <span
                  v-if="error_messages.ip_address"
                  class="common-msg-error error-message"
                >
                  {{ error_messages.ip_address }}
                </span>
              </div>
              <div class="modal-ip-setting__group--generate-ip">
                <span @click="fillMyIpAddress">{{ $t('objects.setting.text.enter_ip_address') }}</span>
              </div>
            </div>
          </div>
          <div>
            <label class="modal-ip-setting__item modal-ip-setting__item--label modal-ip-setting__item--label__accept">
              {{ $t('objects.setting.text.allow_accept_ip_address') }}
            </label>
          </div>
          <div class="modal-ip-setting__h-align">
            <radio-common
              name="status"
              :val="0"
              class="modal-ip-setting__h-align--right"
              :label="$t('new_text.disallow')"
              :is-checked="status === 0"
              @select="handleSelected"
            />
            <radio-common
              name="status"
              :val="1"
              :label="$t('new_text.allow')"
              :is-checked="status === 1"
              @select="handleSelected"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="modal-ip-setting__footer">
          <div class="modal-ip-setting__btn-delete">
            <button-common @click="is_show_modal_delete_whitelist = true">
              <template #content>
                {{ $t('new_text.button.delete') }}
              </template>
            </button-common>
          </div>
          <div class="modal-ip-setting__btn">
            <button-common
              class="modal-v2__button modal-v2__button--confirm"
              @click="submit"
            >
              <template #content>
                {{ $t('new_text.button.save') }}
              </template>
            </button-common>
          </div>
        </div>
      </template>
    </modal-action>
    <modal-confirm-delete-white-list
      v-if="is_show_modal_delete_whitelist"
      :ip-data="ipData"
      @close="is_show_modal_delete_whitelist = false"
      @deleted="handleDeleteWhiteList"
    />
  </div>
</template>
<script>

  import ModalAction from '../../../components/common/ModalAction'
  import { showErrorModal } from '../../../utils/helpers'
  import { required, maxLength } from 'vuelidate/lib/validators'
  import RadioCommon from '../../../components/common/Radio'
  import { RESPONSE_ERRORS, RESPONSE_STATUS } from '../../../utils/constants'
  import ButtonCommon from '../../../components/common/Button'
  import ModalConfirmDeleteWhiteList from '../v2/ModalConfirmDeleteWhiteList'

  export default {
    components: {
      RadioCommon,
      ModalAction,
      ButtonCommon,
      ModalConfirmDeleteWhiteList
    },
    props: {
      ipData: {
        type: Object,
        default: () => {}
      }
    },
    emits: ['close'],
    data () {
      return {
        ip_address: '',
        label: '',
        status: 0,
        updated_at: '',
        staff: {},
        is_show_modal_delete_whitelist: false,
        error_messages: {
          ip_address: '',
          label: ''
        }
      }
    },
    created () {
      this.status = this.ipData.status
      this.ip_address = this.ipData.ip_address
      this.label = this.ipData.label
      this.updated_at = this.ipData.updated_at
      this.staff = this.ipData.staff || {}
    },
    validations () {
      return {
        label: {
          required,
          maxLength: maxLength(50)
        },
        ip_address: { required }
      }
    },
    methods: {
      handleDeleteWhiteList: function () {
        this.is_show_modal_delete_whitelist = false
        this.$emit('succeed')
      },
      handleSelected (value) {
        this.status = value
      },
      submit () {
        if (!this.validate() || this.error_messages.ip_address) {
          return
        }

        const postData = {
          ip_label: this.label,
          ip_address: this.ip_address,
          ip_status: this.status,
          id: this.ipData.id
        }
        this.$store.commit('set_loading', true)
        this.$store.dispatch('updateWhiteList', postData).then((result) => {
          const { data: { status, results } } = result
          if (status !== RESPONSE_STATUS.NG) {
            this.$emit('succeed')
          } else {
            if (results === RESPONSE_ERRORS.IP_EXIST) {
              showErrorModal(this.$t('objects.setting.text.ip_exist'))
            }
          }
          this.$store.commit('set_loading', false)
        }).catch((error) => {
          this.$store.commit('set_loading', false)
          const resErrors = error?.response?.data?.errors || []

          showErrorModal(resErrors)
        })
      },
      fillMyIpAddress: function () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('showMyIpAddress').then((res) => {
          if (res.status === 200) {
            this.ip_address = res.data.results
            this.error_messages.ip_address = ''
            this.$store.commit('set_loading', false)
          }
        }).catch(() => {
          showErrorModal(this.$t('new_message.something_went_wrong'))
          this.$store.commit('set_loading', false)
        })
      },
      validate () {
        this.$v.$touch()
        if (!this.$v.$anyError) {
          return true
        }
        const errors = []
        const errorMessages = {
          'label.required': this.$t('new_validation.common.required', { attribute: this.$t('new_text.label') }),
          'label.maxLength': this.$t('new_validation.common.maximum', {
            attribute: this.$t('new_text.label'),
            max: 50
          }),
          'ip_address.required': this.$t('validation.required', { attribute: this.$t('objects.setting.text.ip_address') })
        }
        for (const key in errorMessages) {
          const parts = key.split('.')

          if (this.$v[parts[0]] && this.$v[parts[0]][parts[1]] === false) {
            this.error_messages[parts[0]] = errorMessages[key]
            errors.push(errorMessages[key])
          }
        }

        if (errors.length) {
          showErrorModal(errors.join('<br>'), { width: '440px' })

          return false
        }

        return true
      },
      handleIPChange: function (event) {
        this.error_messages.ip_address = ''
        const { currentTarget: { value } } = event
        let pattern = /^[0-9.]+$/

        if (!pattern.test(value)) {
          this.error_messages.ip_address = this.$t('new_validation.setting.invalid_character_ip_address')

          return
        }

        pattern = /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/

        if (!pattern.test(value)) {
          this.error_messages.ip_address = this.$t('new_validation.setting.invalid_ip_address')
        }
      },
      handleValidationLabel: function (e) {
        if (!e.target.value) {
          this.error_messages.label = this.$t('new_validation.common.required', { attribute: this.$t('new_text.label') })

          return
        }

        if (e.target.value.length > 50) {
          this.error_messages.label = this.$t('new_validation.common.maximum', { attribute: this.$t('new_text.label'), max: 50 })

          return
        }

        this.error_messages.label = ''
      }
    }
  }
  </script>
