var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.className] },
    [
      _c("sub-question", {
        ref: "sub_question",
        attrs: {
          question: _vm.question,
          options: _vm.option_yes_no,
          checked: _vm.select_option
        },
        on: {
          handleAnswer: function($event) {
            return _vm.handleAnswer($event)
          }
        }
      }),
      _vm._v(" "),
      _c("div", { class: _vm.handleChildAnswerClass }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("checkbox-common", {
              attrs: { label: "金属アレルギー", val: "金属アレルギー" },
              model: {
                value: _vm.metal_allergy,
                callback: function($$v) {
                  _vm.metal_allergy = $$v
                },
                expression: "metal_allergy"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-8" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.metal_name,
                      expression: "metal_name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.metal_name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.metal_name = $event.target.value
                    }
                  }
                })
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("checkbox-common", {
              attrs: { label: "薬アレルギー", val: "薬アレルギー" },
              model: {
                value: _vm.medicine,
                callback: function($$v) {
                  _vm.medicine = $$v
                },
                expression: "medicine"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-8" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.drug_name,
                      expression: "drug_name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.drug_name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.drug_name = $event.target.value
                    }
                  }
                })
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("checkbox-common", {
              attrs: { label: "花粉症", val: "花粉症" },
              model: {
                value: _vm.hay_fever,
                callback: function($$v) {
                  _vm.hay_fever = $$v
                },
                expression: "hay_fever"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-8" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.time,
                      expression: "time"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.time },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.time = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-8" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cause_plant,
                      expression: "cause_plant"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.cause_plant },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.cause_plant = $event.target.value
                    }
                  }
                })
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("checkbox-common", {
              attrs: { label: "その他のアレルギー", val: "その他のアレルギー" },
              model: {
                value: _vm.other,
                callback: function($$v) {
                  _vm.other = $$v
                },
                expression: "other"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm._m(4),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-8" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.allergies,
                      expression: "allergies"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.allergies },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.allergies = $event.target.value
                    }
                  }
                })
              ])
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4 my-auto" }, [
      _c("span", { staticClass: "verical-pading-checkbox" }, [
        _vm._v("金属名：")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4 my-auto" }, [
      _c("span", { staticClass: "verical-pading-checkbox" }, [
        _vm._v("薬品名：")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4 my-auto" }, [
      _c("span", { staticClass: "verical-pading-checkbox" }, [
        _vm._v("発症時期：")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4 my-auto" }, [
      _c("span", { staticClass: "verical-pading-checkbox" }, [
        _vm._v("原因植物：")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4 my-auto" }, [
      _c("span", { staticClass: "verical-pading-checkbox" }, [
        _vm._v("原因物質：")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }