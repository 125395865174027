<template>
  <div
    :id="'reservationId_' + questionnaire.reservations_id"
    class="questionnaire"
  >
    <table class="questionnaire__table">
      <thead>
        <tr>
          <th
            class="questionnaire__table__head"
            colspan="4"
          >
            {{
              questionnaire.type === interview_type.FIRST
                ? $t("objects.patient.text.questionnaire.first")
                : $t("objects.patient.text.questionnaire.re_exam")
            }}
          </th>
        </tr>
      </thead>
      <tbody class="questionnaire__table__body">
        <tr>
          <th>
            {{ $t("objects.patient.text.questionnaire.manager") }}
          </th>
          <td colspan="3">
            {{ staffs }}
          </td>
        </tr>
        <tr>
          <th>
            {{ $t("objects.patient.text.questionnaire.visit_date") }}
          </th>
          <td class="number-medium">
            {{ arrivedTime }}
          </td>
          <th>
            {{ $t("objects.patient.text.questionnaire.answer_date") }}
          </th>
          <td class="number-medium">
            {{ updatedTime }}
          </td>
        </tr>
        <tr>
          <th>
            {{ $t("objects.patient.text.questionnaire.description_rating") }}
          </th>
          <td>
            {{ questionnaire.type === interview_type.FIRST ? getAnswer('q2', 'v1q2') : getAnswer('q2', 'v2q2') }}
          </td>
          <th>
            {{ $t("objects.patient.text.questionnaire.treatment_evaluation") }}
          </th>
          <td>
            {{ questionnaire.type === interview_type.FIRST ? "-" : getAnswer('v2q1', 'v2q1') }}
          </td>
        </tr>
        <tr>
          <th>
            {{ $t("objects.patient.text.questionnaire.comment") }}
          </th>
          <td
            colspan="3"
            class="questionnaire__comment"
          >
            {{ questionnaire.q3 || '' }}
          </td>
        </tr>
        <tr>
          <th>
            {{ $t("objects.patient.text.questionnaire.improvement_comment") }}
          </th>
          <td
            colspan="3"
            class="questionnaire__comment"
          >
            {{ questionnaire.q4 || '' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import helpers from '../../../../utils/helpers.js'
  import { INTERVIEW_TYPE } from '../../../../utils/constants'

  export default {
    props: {
      questionnaire: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {
        interview_type: INTERVIEW_TYPE
      }
    },
    computed: {
      staffs () {
        return (this.questionnaire.staffs || [])
          .map(item => {
            return `${helpers.showStaffPosition(item.staff_info.position.name)} ${
              item.staff_info.name
            }`
          })
          .join(', ')
      },

      arrivedTime () {
        return helpers.formatDateTime(
          this.questionnaire.arrived_at || '',
          this.$t('datetime.year_month_day_hour_minute')
        )
      },

      updatedTime () {
        return helpers.formatDateTime(
          this.questionnaire.updated_at || '',
          this.$t('datetime.year_month_day_hour_minute')
        )
      }
    },
    methods: {
      getAnswer (type, item) {
        return this.$t('objects.patient.text.questionnaire.' + type)[this.questionnaire[item]] || ''
      }
    }
  }
</script>
