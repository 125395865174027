<template>
  <div :class="[className]">
    <sub-question
      ref="sub_question"
      :question="question"
      :options="option_yes_no"
      :checked="select_option"
      @handleAnswer="handleAnswer($event)"
    />
    <div :class="handleChildAnswerClass">
      <div class="form-group">
        <checkbox-common
          v-model="selections"
          label="気分が悪くなった"
          val="気分が悪くなった"
        />
        <checkbox-common
          v-model="selections"
          label="血が止まりにくかった"
          val="血が止まりにくかった"
        />
        <div class="form-inline selection-other-option pt-3">
          <checkbox-common
            v-model="selections"
            label="その他"
            val="その他"
          />
          <div :class="['other-input', diableOtherInput]">
            <input
              v-model="other"
              class="form-control w-100"
              type="text"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SubQuestion from '../template/SubQuestion'
  import CheckboxCommon from '../template/CheckboxCommon'
  import { INTERVIEW_COMMON_OPTIONS, INTERVIEW_OPTIONS_NOT_ENTERED } from '../../../../utils/constants'
  import Common from './CommonFunction'

  export default {
    components: {
      SubQuestion,
      CheckboxCommon
    },
    mixins: [Common],
    props: {
      className: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        number: 23,
        select_option: '',
        question: '以前に歯科医院で歯を抜いたことがありますか？',
        option_yes_no: [
          INTERVIEW_COMMON_OPTIONS.YES,
          INTERVIEW_COMMON_OPTIONS.NO,
          INTERVIEW_OPTIONS_NOT_ENTERED
        ],
        selections: [],
        other: '',
        translate: {
          選択肢: 'selections',
          その他: 'other'
        }
      }
    },
    computed: {
      handleChildAnswerClass: function () {
        return {
          'disable-child-answer': this.select_option === INTERVIEW_COMMON_OPTIONS.NO
        }
      },
      diableOtherInput: function () {
        return {
          'disable-other-answer': !this.selections.includes('その他')
        }
      }
    }
  }
</script>
