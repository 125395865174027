var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "event-card" }, [
    _vm.event.id && _vm.event.extendedProps.blocked === 0
      ? _c(
          "div",
          {
            class: [
              "event-card__reservation",
              {
                "event-card__reservation--grey-background":
                  _vm.reservationOverTime
              }
            ]
          },
          [
            _c(
              "div",
              {
                class: [
                  "event-card__duty",
                  {
                    "event-card__duty--border":
                      Object.keys(_vm.duty).length === 0,
                    "event-card__duty--min": _vm.period === 5
                  }
                ],
                style:
                  "background-color: " +
                  (_vm.duty.color || _vm.default_duty_color)
              },
              [
                _c("span", { staticClass: "event-card__btn-move" }, [
                  Object.keys(_vm.duty).length === 0
                    ? _c("img", {
                        staticClass: "event-card__btn-move",
                        attrs: { src: "/images/icon-move-black.svg" }
                      })
                    : _c("img", {
                        staticClass: "event-card__btn-move",
                        attrs: { src: "/images/icon-move.svg" }
                      })
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "event-card__body",
                  { "event-card__body--min": _vm.period === 5 }
                ]
              },
              [
                _c("div", { staticClass: "event-card__patient" }, [
                  _c(
                    "p",
                    {
                      class: [
                        "event-card__patient__name",
                        { "event-card__patient__name--min": _vm.period === 5 }
                      ],
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.handleClick("patientDetail")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.patientInfo.name || "")
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.getAge))])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.period > 5
                    ? _c(
                        "p",
                        {
                          class: [
                            "event-card__patient__id",
                            {
                              "event-card__patient__id--with-duties-name":
                                _vm.period === 20
                            }
                          ]
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.patient.sf_user_id || _vm.patient.id) +
                              "\n          "
                          ),
                          _vm.period === 20 && _vm.brandName
                            ? _c(
                                "span",
                                {
                                  class: {
                                    "event-card__brand": true,
                                    "event-card__brand--not-duties": !_vm.getDutiesName
                                  }
                                },
                                [_vm._v(_vm._s(_vm.brandName))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.period === 20
                            ? _c("span", [_vm._v(_vm._s(_vm.getDutiesName))])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isShowQuestionnaireButton
                            ? _c(
                                "label",
                                {
                                  staticClass:
                                    "button-v2 button-v2--questionnaire",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.moveToPatientQuestionnaires(
                                        "patientQuestionnaires",
                                        _vm.patient.id,
                                        _vm.event.id,
                                        $event
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("new_text.questionnaire_2")
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showIconInterview && _vm.period > 5
                    ? _c(
                        "span",
                        {
                          class: [
                            "event-card__interview",
                            {
                              "event-card__interview--incomplete":
                                _vm.event.extendedProps.interview_status === 0
                            }
                          ]
                        },
                        [_vm._v("問")]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.brandName && _vm.period > 20
                  ? _c(
                      "span",
                      {
                        class: {
                          "event-card__brand": true,
                          "event-card__brand--not-duties": !_vm.getDutiesName
                        }
                      },
                      [_vm._v(_vm._s(_vm.brandName))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.period > 10 && _vm.period !== 20
                  ? _c(
                      "div",
                      {
                        class: [
                          "event-card__duty-name",
                          { "event-card__duty-name--min": _vm.period < 40 }
                        ]
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.getDutiesName) + "\n      "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayMemo
                  ? _c(
                      "div",
                      {
                        class: [
                          "event-card__memo",
                          { "event-card__memo--small": _vm.period <= 20 }
                        ]
                      },
                      [
                        _c(
                          "span",
                          { style: "-webkit-line-clamp: " + _vm.memoLine },
                          [_vm._v(_vm._s(_vm.event.extendedProps.memo))]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                (_vm.period > 30 || !_vm.event.extendedProps.memo) &&
                _vm.staffs.length !== 0
                  ? _c("div", { staticClass: "event-card__staff" }, [
                      _vm.staffs.length > 1
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.bottom-start",
                                  value: {
                                    content: _vm.showStaffsTooltip,
                                    trigger: "hover",
                                    autoHide: true,
                                    html: false
                                  },
                                  expression:
                                    "{ content: showStaffsTooltip, trigger: 'hover', autoHide: true, html: false }",
                                  modifiers: { "bottom-start": true }
                                }
                              ]
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.showStaffPosition(
                                      _vm.staffs[0].staff_info.position.name
                                    ) +
                                      " " +
                                      _vm.staffs[0].staff_info.name
                                  ) +
                                  "\n          "
                              ),
                              _vm.staffs.length - 1 !== 0
                                ? _c("span", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s("+" + (_vm.staffs.length - 1)) +
                                        "\n          "
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.showStaffPosition(
                                    _vm.staffs[0].staff_info.position.name
                                  ) +
                                    " " +
                                    _vm.staffs[0].staff_info.name
                                ) +
                                "\n        "
                            )
                          ])
                    ])
                  : _vm._e()
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.event.id && _vm.event.extendedProps.blocked === 1
      ? _c("div", { staticClass: "event-card__blocked" }, [
          _c("div", { staticClass: "event-card__name-block" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("common.block")) + "\n    ")
          ]),
          _vm._v(" "),
          _vm.period > 10 && _vm.event.extendedProps.memo
            ? _c("div", { staticClass: "event-card__memo-block" }, [
                _c(
                  "span",
                  { style: "-webkit-line-clamp: " + _vm.memoBlockLine },
                  [_vm._v(_vm._s(_vm.event.extendedProps.memo))]
                )
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.event.id
      ? _c(
          "div",
          {
            class: [
              "event-card__empty",
              { "event-card__empty--anchor": _vm.period > 30 }
            ]
          },
          [
            _c("div", [
              _c("p", [
                _vm._v(_vm._s(_vm.startTime) + "〜" + _vm._s(_vm.endTime))
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("(" + _vm._s(_vm.period)),
                _c("span", [_vm._v(_vm._s(_vm.$t("common.minute")))]),
                _vm._v(")")
              ])
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }