<template>
  <div :class="[className]">
    <strong><p>{{ question }}</p></strong>
    <div :class="handleChildAnswerClass">
      <checkbox-common
        v-model="back"
        label="仰向け"
        val="仰向け"
      />
      <div class="form-group">
        <checkbox-common
          v-model="prone"
          label="うつ伏せ"
          val="うつ伏せ"
        />
        <div class="row checkbox-multi-inline form-inline">
          <div class="col-md-3">
            <span>首の向き</span>
          </div>
          <div class="col-md-8 radio-multi-inline radio-group h-align">
            <div
              v-for="(item, key) in head_options"
              :key="key"
              :class="['check-container', 'ml-0']"
              @click="handleHeadClick(item)"
            >
              <span :class="['radio-button', handleHeadClass(item)]" />
              <span class="label-text">{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <checkbox-common
          v-model="sideways"
          label="横向き"
          val="横向き"
        />
        <div class="row checkbox-multi-inline form-inline">
          <div class="col-md-3">
            <span>向き</span>
          </div>
          <div class="col-md-8 radio-multi-inline radio-group h-align">
            <div
              v-for="(item, key) in head_options"
              :key="key"
              :class="['check-container', 'ml-0']"
              @click="handleDirectionClick(item)"
            >
              <span :class="['radio-button', handleDirectionClass(item)]" />
              <span class="label-text">{{ item }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group pb-4">
        <checkbox-common
          v-model="various"
          label="様々寝相が変わる"
          val="様々寝相が変わる"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import CheckboxCommon from '../template/CheckboxCommon'
  import Common from './CommonFunction'

  export default {
    components: {
      CheckboxCommon
    },
    mixins: [Common],
    props: {
      className: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        number: 9,
        back: [],
        prone: [],
        sideways: [],
        various: [],
        head: '',
        direction: '',
        question: '寝相はどの状態が多いですか？',
        head_options: [
          '左',
          '右',
          'どちらも'
        ],
        translate: {
          仰向け: 'back',
          うつ伏せ: 'prone',
          頭の向き: 'head',
          横向き: 'sideways',
          向き: 'direction',
          様々寝相が変わる: 'various'
        }
      }
    },
    methods: {
      handleHeadClick: function (value) {
        this.head = value
      },
      handleHeadClass: function (value) {
        return this.head === value ? 'checked' : ''
      },
      handleDirectionClick: function (value) {
        this.direction = value
      },
      handleDirectionClass: function (value) {
        return this.direction === value ? 'checked' : ''
      }
    }
  }
</script>
