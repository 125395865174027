import axios from 'axios'
import { state } from './auth'

export const actions = {
  getInfoClinic ({ dispatch, commit }) {
    return axios.get('/api/clinic/info', {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        state.clinic = res?.data?.results || {}

        return res
      })
      .catch(error => {
        throw error
      })
  },
  getSettingClinicInfo ({ dispatch, commit }) {
    return axios.get('/api/clinic/setting/info', {
      headers: { Authorization: 'Bearer ' + state.token }
    })
  },
  getAllPrefectures ({ dispatch, commit }) {
    return axios.get('/api/patient/prefectures', {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  updateBasicInfo ({ dispatch, commit }, data) {
    return axios.post('/api/clinic/update', data, {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getClinicList ({ dispatch, commit }) {
    return axios.get('/api/clinic/', {
      headers: { Authorization: 'Bearer ' + state.token }
    })
  },
  updateBasicTreatmentPolicy ({ dispatch, commit }, params) {
    return axios.put('/api/clinic/treatment-policy', params, {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        commit('updateClinicData', {
          key: 'sf_basic_treatment_policy__c',
          value: params.sf_basic_treatment_policy__c
        })

        return res
      })
  }
}
