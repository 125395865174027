<template>
  <div class="patient-system-info">
    <div class="patient-system-info__wrap">
      <div class="patient-system-info__group">
        <div class="patient-system-info__row">
          <label class="patient-system-info__label">{{ $t('new_text.patient_status') }}</label>
          <p class="patient-system-info__data">
            {{ patientStatus }}
          </p>
        </div>
        <div class="patient-system-info__row">
          <label class="patient-system-info__label">{{ $t('new_text.registration_date') }}</label>
          <p class="patient-system-info__data">
            {{ createdAt }}
          </p>
        </div>
        <div class="patient-system-info__row">
          <label class="patient-system-info__label">{{ $t('new_text.last_updated') }}</label>
          <p class="patient-system-info__data">
            {{ updatedAt }}
          </p>
        </div>
        <div class="patient-system-info__row">
          <label class="patient-system-info__label">{{ $t('objects.patient.text.ip_address_of_first_appointment') }}</label>
          <p class="patient-system-info__data">
            {{ ipAddress }}
          </p>
        </div>
      </div>
      <div class="patient-system-info__title patient-system-info__title--line-bottom">
        {{ $t('objects.patient.text.person_in_charge_information') }}
      </div>
      <div class="patient-system-info__group">
        <div
          v-for="(item, key) in customPerson"
          :key="key"
          class="patient-system-info__row"
        >
          <label class="patient-system-info__label">{{ key }}</label>
          <p class="patient-system-info__data">
            <span
              v-for="(staff, index) in item"
              :key="index"
              class="patient-system-info__staff"
            >{{ staff.name }}</span>
          </p>
        </div>
      </div>
      <div class="patient-system-info__title patient-system-info__title--line-bottom">
        {{ $t('objects.patient.text.other_notes') }}
      </div>
      <div class="patient-system-info__group">
        <div
          v-if="otherNote"
          class="patient-system-info__row"
        >
          <span class="patient-system-info__data patient-system-info__data--note">{{ otherNote }}</span>
        </div>
        <div
          v-if="$store.getters.currentClinic.is_dmr || 0"
          class="patient-system-info__row patient-system-info__row--service"
        >
          <label class="patient-system-info__label">{{ $t('objects.patient.text.service_management') }}</label>
          <patient-service-summary
            v-if="serviceSummary.length"
            :service-summary="serviceSummary"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatDateTime } from '../../../utils/helpers'
  import PatientServiceSummary from './PatientServiceSummary.vue'
  import { get } from 'lodash'

  export default {
    name: 'PatientSystemInfo',
    components: {
      PatientServiceSummary
    },
    props: {
      patient: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      patientStatus () {
        const status = this.patient.sf_status_c
        return typeof status !== 'number' ? ''
          : this.$t('objects.patient.sf_status_c.' + this.patient.sf_status_c)
      },
      createdAt () {
        return formatDateTime(this.patient.created_at, this.$t('new_text.datetime.year_month_day_hour_minute'))
      },
      ipAddress () {
        return get(this.patient, 'ip_address') || this.$t('objects.patient.text.not_obtained')
      },
      updatedAt () {
        return formatDateTime(this.patient.updated_at, this.$t('new_text.datetime.year_month_day_hour_minute'))
      },
      customPerson: function () {
        return this.patient.customPerson
      },
      otherNote: function () {
        return this.patient?.info?.note || ''
      },
      serviceSummary: function () {
        return this.patient.service_summary
      }
    },
    methods: {
      checkDeliver: function (deliver = 0) {
        return (typeof deliver !== 'undefined' && deliver === 1) ? this.$t('new_text.receive_sms') : this.$t('new_text.do_not_deliver')
      }
    }
  }

</script>
