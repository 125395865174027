var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.className] }, [
    _c("div", { staticClass: "form-group no-flex" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "form-inline justify-content-between pt-3" }, [
        _c("span", [_vm._v("初潮年齢：")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name1,
              expression: "name1"
            }
          ],
          staticClass: "form-control col-md-4",
          attrs: { type: "text" },
          domProps: { value: _vm.name1 },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name1 = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("span", [_vm._v("歳")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name2,
              expression: "name2"
            }
          ],
          staticClass: "form-control col-md-4",
          attrs: { type: "text" },
          domProps: { value: _vm.name2 },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name2 = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("span", [_vm._v("ヶ月")])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("女性の方のみ")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }