var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { key: _vm.key, staticClass: "reservation-v2" }, [
    _c(
      "div",
      { staticClass: "reservation-v2__calendar" },
      [
        _c("calendar", {
          ref: "calendar",
          attrs: {
            toggle: _vm.toggle,
            "is-users-manipulating": _vm.is_users_manipulating,
            "selected-date": _vm.selected_date,
            "is-print-memo": _vm.is_print_memo
          },
          on: {
            "selected-date": _vm.selectedDate,
            "set-total-reservation-cancelled": _vm.setToTalReservationCancelled,
            "set-total-reservation-reserved": _vm.setToTalReservationReserved,
            "set-reservation-print-instruction":
              _vm.setReservationPrintInstruction,
            "set-reservation-print-technical-info":
              _vm.setReservationPrintTechnicalInfo
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "reservation-v2__search" },
      [
        _c("search", {
          ref: "search",
          attrs: { toggle: _vm.toggle, "selected-date": _vm.selected_date },
          on: {
            "toggle-menu": _vm.toggleMenu,
            "set-is-users-manipulating": _vm.setIsUsersManipulating,
            refresh: _vm.refresh,
            "print-calendar": _vm.handlePrintCalendar,
            "selected-date": _vm.selectedDate
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }