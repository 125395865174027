<template>
  <div :class="[className]">
    <sub-question
      ref="sub_question"
      :question="question"
      :options="option_yes_no"
      :checked="select_option"
      @handleAnswer="handleAnswer($event)"
    />
    <div :class="handleChildAnswerClass">
      <div
        v-for="detail in details"
        :key="detail.detail_index"
        class="form-group block-group"
      >
        <label class="block-group__first">{{ detail.detail_index }}</label>
        <div class="block-group__inline">
          <div class="form-group">
            <label class="color-blue">診断名：</label>
            <input
              v-model="detail.diagnostis"
              class="form-control"
              type="text"
              name=""
            >
          </div>
          <div class="form-group">
            <label class="color-blue">いつ頃：</label>
            <input
              v-model="detail.time"
              class="form-control"
              type="text"
              name=""
            >
          </div>
          <div class="form-group">
            <label class="color-blue">治療内容：</label>
            <input
              v-model="detail.content"
              class="form-control"
              type="text"
              name=""
            >
          </div>
          <div class="form-inline justify-content-between">
            <checkbox-common
              v-model="detail.selections"
              label="完治"
              val="完治"
            />
            <checkbox-common
              v-model="detail.selections"
              label="現在通院中"
              val="現在通院中"
            />
            <checkbox-common
              v-model="detail.selections"
              label="症状が出た時毎に通院"
              val="症状が出た時毎に通院"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SubQuestion from '../template/SubQuestion'
  import CheckboxCommon from '../template/CheckboxCommon'
  import { INTERVIEW_COMMON_OPTIONS, INTERVIEW_OPTIONS_NOT_ENTERED, KIDS_BRAND_TYPE } from '../../../../utils/constants'

  export default {
    components: {
      SubQuestion,
      CheckboxCommon
    },
    props: {
      className: {
        type: String,
        default: ''
      },
      patientInfo: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {
        number: 11,
        select_option: '',
        question: '今まで耳鼻科を受診したことはありますか？',
        option_yes_no: [
          INTERVIEW_COMMON_OPTIONS.YES,
          INTERVIEW_COMMON_OPTIONS.NO,
          INTERVIEW_OPTIONS_NOT_ENTERED
        ],
        details: [
          {
            detail_index: 1,
            diagnostis: '',
            time: '',
            content: '',
            selections: []
          },
          {
            detail_index: 2,
            diagnosis: '',
            time: '',
            content: '',
            selections: []
          },
          {
            detail_index: 3,
            diagnosis: '',
            time: '',
            content: '',
            selections: []
          }
        ],
        translate: {
          診断名: 'diagnostis',
          いつ頃: 'time',
          治療内容: 'content',
          治療状況: 'selections'
        }
      }
    },
    computed: {
      handleChildAnswerClass: function () {
        return {
          'disable-child-answer': this.select_option === INTERVIEW_COMMON_OPTIONS.NO
        }
      },
      diableOtherInput: function () {
        return {
          'disable-other-answer': !this.selections.includes('その他')
        }
      },
      isKid: function () {
        return this.patientInfo.brand_type === KIDS_BRAND_TYPE
      }
    },
    created: function () {
      this.loadAnswerData()
    },
    methods: {
      loadAnswerData: function () {
        const dataState = this.$store.getters.get_question(this.number)
        this.select_option = dataState.answer
        if (this.isKid && !dataState.answer) {
          this.select_option = INTERVIEW_OPTIONS_NOT_ENTERED
        }
        dataState.items.forEach((objMain, index) => {
          objMain.details.forEach(objDetail => {
            for (const jaName in this.translate) {
              if (objDetail.title === jaName) {
                const enName = this.translate[jaName]
                this.details[index][enName] = objDetail.content || ''
              }
            }
          })
        })
      },
      formatResult: function () {
        const data = this.$store.getters.get_default_answer_items(this.number)
        data.answer = this.select_option

        if (data.answer === INTERVIEW_COMMON_OPTIONS.YES) {
          data.items.forEach((objMain, index) => {
            objMain.details.forEach(objDetail => {
              for (const jaName in this.translate) {
                if (objDetail.title === jaName) {
                  const enName = this.translate[jaName]
                  objDetail.content = this.details[index][enName]
                }
              }
            })
          })
        }
        return data
      },
      handleAnswer: function (value) {
        this.select_option = value
      }
    }
  }
</script>
