<template>
  <div>
    <div v-if="reservationPatients.length > 0">
      <ul
        v-for="res in reservationPatients"
        :key="res.id"
        class="reservation-list"
      >
        <li class="reservation__item">
          <div class="reservation__item__allocation">
            <label>{{ safeGet(res, 'allocation.column_name') }}</label>
            <span v-if="is_clinics_dmr === '1' || is_clinics_dmr === 1">{{ getReservationStatus(res) }}</span>
          </div>
          <div class="reservation__item__date">
            <span class="reservation__item__tag">
              {{ $t('objects.reservation.text.datetime') }}
            </span>
            <span class="reservation__item__time">{{ getReservationDate(res) }}</span>
          </div>
          <div
            v-if="res.duties.length"
            class="reservation__item__duty-type"
          >
            <div
              v-for="(duty, idx) in res.duties"
              :key="idx"
              class="reservation__item__type"
            >
              <span
                v-if="duty.master && duty.master.duty"
                :style="{ background: duty.master.duty.color }"
              />
              <label v-if="duty.master">
                {{ duty.master.duty_name }} {{ getTakeTime(duty.master.take_time) }}
              </label>
            </div>
          </div>
          <div
            v-if="res.memo"
            class="reservation__item__memo"
          >
            <span class="reservation__item__tag reservation__item__custom">
              {{ $t('objects.reservation.text.memo') }}
            </span>
            <p class="reservation__item__reason">
              {{ res.memo }}
            </p>
          </div>
          <div
            v-if="res.reservation_cancelled"
            class="reservation__item__memo"
          >
            <span class="reservation__item__tag reservation__item__custom">
              {{ $t('objects.reservation.text.cancelled_reason') }}
            </span>
            <p class="reservation__item__reason">
              {{ safeGet(res, 'reservation_cancelled.cancelled_reason') }}
            </p>
          </div>
          <div
            v-if="res.status === reservation_status.RESERVED"
            class="reservation__item__action"
          >
            <button
              type="button"
              class="btn-v2 btn-v2--print-examination"
              @click="printExamination(res.id)"
            >
              {{ $t('objects.patient.button.print_examination') }}
            </button>
            <router-link
              v-if="res.allocation && res.clinics_id === res.allocation.clinic_id && show_reservation"
              class="btn-v2 btn-v2--display label-span label--blueBtn"
              :to="{
                name: 'reservation.list',
                query: {
                  search_date: res.started_to,
                  reservation_id: res.id
                }
              }"
            >
              <span>{{ $t('new_text.button.display') }}</span>
            </router-link>
            <button
              type="button"
              class="btn-v2 btn-v2--cancel"
              :disabled="firstReservationMonth && firstReservationId === res.id"
              @click="cancelHandle(res.id)"
            >
              {{ $t('new_text.button.cancel') }}
            </button>
          </div>
          <div class="reservation__item__line" />
        </li>
        <reservation-history
          :reservation="res"
        />
      </ul>
    </div>
    <div
      v-else
      class="no-reservation"
    >
      <img src="/images/icon-schedule.svg">
      <span>{{ $t('objects.reservation.text.no_reservation') }}</span>
      <button-common
        class="button-v2--primary button-v2--120"
        @click="$router.push({ name: 'dashboard' })"
      >
        <template #content>
          {{ $t('objects.patient.text.new_reservation') }}
        </template>
      </button-common>
    </div>
    <modal-reason-cancel
      v-if="selected_reservation_id"
      :reservation-id="selected_reservation_id"
      @close="selected_reservation_id = 0"
      @submit="submitCancelReservation"
    />
  </div>
</template>

<script>
  import { safeGet, showErrorModal, showSuccessModal } from '../../../../utils/helpers.js'
  import * as moment from 'moment'
  import ModalReasonCancel from '../../../reservation/ModalReasonCancel'
  import { RESERVATION_STATUS, RESPONSE_TYPE } from '../../../../utils/constants'
  import ReservationHistory from './ReservationHistory'
  import ButtonCommon from '../../../common/Button'
  import { printExamination } from '../../../../mixins/print-examination'
  import { isEmpty, get } from 'lodash'

  export default {
    components: {
      ReservationHistory,
      ModalReasonCancel,
      ButtonCommon
    },
    mixins: [printExamination],
    props: {
      firstReservationId: {
        type: Number,
        default: 0
      },
      firstReservationMonth: {
        type: String,
        default: null
      },
      orderCount: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        is_show_modal_edit_history: false,
        show_reservation: process.env.MIX_SHOW_RESERVATION,
        reservation_status: RESERVATION_STATUS,
        selected_reservation_id: 0,
        path_name_accept_deleted: ['patients.fee', 'patient.accounting.detail'],
        path_name: this.$router.currentRoute.name,
        patient_id: '',
        reservation_id: '',
        is_clinics_dmr: 0
      }
    },
    computed: {
      reservationPatients: function () {
        return this.$store.getters.get_reservationList
      },
      reservation: function () {
        return this.$store.getters.get_reservation
      },
      currentStatus: function () {
        return this.reservation.current_status
      },
      acceptDeleted: function () {
        const pathName = this.$route.name

        return Number(this.path_name_accept_deleted.includes(pathName))
      }
    },
    created () {
      this.patient_id = this.$route.params.id
      this.reservation_id = this.$route.query.reservation_id
      this.show_reservation = this.show_reservation === 'true'

      if (localStorage.getItem('is_clinics_dmr')) {
        this.is_clinics_dmr = localStorage.getItem('is_clinics_dmr')
      }

      this.loadFont()
    },
    methods: {
      safeGet (object, keys) {
        return safeGet(object, keys)
      },
      getReservationStatus (reservation) {
        if (reservation.status === this.reservation_status.CANCELLED) {
          return this.$t('new_text.button.cancel')
        }
        let currentStatus = reservation.current_status
        if (this.reservation_id === reservation.id) {
          currentStatus = this.currentStatus
        }
        return this.$t('objects.reservation.text.reservation_status.status_' + currentStatus)
      },
      getReservationDate: function (reservation) {
        const date = reservation.started_to ? moment(reservation.started_to).format('YYYY年MM月DD日') : ''
        const startDate = reservation.started_to ? moment(reservation.started_to).format('HH:mm') : ''
        const endDate = reservation.ended_from ? moment(reservation.ended_from).format('HH:mm') : ''
        return `${date} ${startDate} ~ ${endDate}`
      },
      cancelHandle (id) {
        if (this.firstReservationMonth && id === this.firstReservationId) {
          return
        }

        if (id === this.firstReservationId && this.orderCount > 0) {
          return showErrorModal(
            this.$t('objects.reservation.messages.reservation_can_not_cancel_because_has_order'),
            { width: 'auto' }
          )
        }

        this.selected_reservation_id = id
      },
      submitCancelReservation (data) {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('cancelReservation', data).then(() => {
          this.getPatientDetail()
          this.$store.commit('set_loading', false)
          this.selected_reservation_id = 0
          showSuccessModal(this.$t('objects.reservation.messages.reservation_cancelled'))
        }).catch(error => {
          this.$store.commit('set_loading', false)
          this.selected_reservation_id = 0
          const resErrors = get(error, 'response.data.errors', {})

          if (!isEmpty(resErrors) && resErrors.first_reservation === RESPONSE_TYPE.LOCKED) {
            return showErrorModal(this.$t('objects.reservation.messages.reservation_can_not_change'))
          }

          if (!isEmpty(resErrors) && resErrors.patient === RESPONSE_TYPE.HAS_ORDER_HISTORY) {
            return showErrorModal(
              this.$t('objects.reservation.messages.reservation_can_not_cancel_because_has_order'),
              { width: 'auto' }
            )
          }

          if (resErrors?.reservation === RESPONSE_TYPE.CANCELLED) {
            return showErrorModal(this.$t('objects.reservation.messages.reservation_has_been_canceled'))
          }

          showErrorModal(this.$t('new_message.something_went_wrong'))
        })
      },
      getPatientDetail () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('getPatientDetail', {
          id: this.patient_id,
          reservation_id: this.reservation_id,
          accept_deleted: this.acceptDeleted
        }).finally(() => {
          this.$store.commit('set_loading', false)
        })
      },
      selectReservation: function (reservation, event) {
        if (event.target.nodeName === 'BUTTON' || event.target.nodeName === 'SPAN' || event.target.nodeName === 'IMG') {
          return
        }

        if (parseInt(reservation.id) === parseInt(this.reservation_id) || reservation.status !== RESERVATION_STATUS.RESERVED) {
          return
        }

        window.location.href = this.$router.resolve({
          name: 'patient.detail',
          params: { id: this.patient_id },
          query: { reservation_id: reservation.id }
        }).href
      },
      getTakeTime (takeTime) {
        return takeTime ? `${takeTime}${this.$t('new_text.minute')}` : ''
      },
      printExamination (reservationId) {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('printExamination', {
          id: this.patient_id,
          reservationId: reservationId
        }).then(response => {
          const data = response?.data?.results
          if (data) {
            this.createFilePDF(data)
          } else {
            this.$store.commit('set_loading', false)
          }
        }).catch(() => {
          this.$store.commit('set_loading', false)
          showErrorModal(this.$t('new_message.something_went_wrong'))
        })
      }
    }
  }
</script>
