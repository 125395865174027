<template>
  <div class="patient-detail__information">
    <div class="patient-detail__information__wrap">
      <div class="patient-detail__information__title patient-detail__information__title--line-bottom">
        {{ $t('objects.patient.text.basic_info') }}
      </div>
      <div class="patient-detail__information__group">
        <div class="patient-detail__information__row patient-detail__information__row--half">
          <label class="patient-detail__information__label">{{ $t('new_text.name') }}</label>
          <p class="patient-detail__information__data">
            {{ patientFullName }}
          </p>
        </div>
        <div class="patient-detail__information__row patient-detail__information__row--half">
          <label class="patient-detail__information__label">{{ $t('new_text.hiragana') }}</label>
          <p class="patient-detail__information__data">
            {{ patientKanaName }}
          </p>
        </div>
        <div class="patient-detail__information__row patient-detail__information__row--half">
          <label class="patient-detail__information__label">{{ $t('new_text.roman') }}</label>
          <p class="patient-detail__information__data">
            {{ patientRomaName }}
          </p>
        </div>
        <div class="patient-detail__information__row patient-detail__information__row--half">
          <label class="patient-detail__information__label">{{ $t('new_text.sex') }}</label>
          <p class="patient-detail__information__data">
            {{ sex }}
          </p>
        </div>
        <div class="patient-detail__information__row patient-detail__information__row--half">
          <label class="patient-detail__information__label">{{ $t('new_text.birthday') }}</label>
          <p class="patient-detail__information__data">
            {{ birthDate }}{{ renderAge(patientInfo.birth_of_date) }}
          </p>
        </div>
        <div class="patient-detail__information__row patient-detail__information__row--half">
          <label class="patient-detail__information__label">{{ $t('new_text.brand_type') }}</label>
          <p class="patient-detail__information__data">
            {{ brandMaster }}
          </p>
        </div>
        <div class="patient-detail__information__row patient-detail__information__row--half">
          <label class="patient-detail__information__label">{{ $t('objects.patient.text.simulation_flag') }}</label>
          <p class="patient-detail__information__data">
            {{ preContractSimulation }}
          </p>
        </div>
        <div class="patient-detail__information__row patient-detail__information__row--half">
          <label class="patient-detail__information__label">{{ $t('objects.patient.text.treatmentend_status') }}</label>
          <p class="patient-detail__information__data">
            {{ treatmentendStatus }}
          </p>
        </div>
        <div
          v-if="patientInfo.reason_for_non_contract"
          class="patient-detail__information__row"
        >
          <label class="patient-detail__information__label">{{ $t('objects.patient.text.reason_for_non_contracting') }}
          </label>
          <div class="patient-detail__information__data">
            <span class="ws-pre-wrap">{{ patientInfo.reason_for_non_contract }}</span>
          </div>
        </div>
      </div>
      <div class="patient-detail__information__title patient-detail__information__title--line-bottom patient-detail__information__title--contact-info">
        {{ $t('objects.patient.text.contact_info') }}
      </div>
      <div class="patient-detail__information__group">
        <div class="patient-detail__information__row patient-detail__information__row">
          <label class="patient-detail__information__label">{{ $t('new_text.address') }}</label>
          <p class="patient-detail__information__data">
            {{ patientInfo.address }}
          </p>
        </div>
        <div class="patient-detail__information__row patient-detail__information__row">
          <label class="patient-detail__information__label">{{ $t('new_text.phone_number') }}</label>
          <p class="patient-detail__information__data">
            {{ patientInfo.tel }}
          </p>
        </div>
        <div class="patient-detail__information__row patient-detail__information__row">
          <label class="patient-detail__information__label">{{ $t('new_text.email') }}</label>
          <p class="patient-detail__information__data">
            {{ patientInfo.email }}
          </p>
        </div>
      </div>
      <div class="patient-detail__information__title patient-detail__information__title--line-bottom patient-detail__information__title--contact-info">
        {{ $t('new_text.delivery') }}
      </div>
      <div class="patient-detail__information__group">
        <div class="patient-detail__information__row patient-detail__information__row--half">
          <label class="patient-detail__information__label">{{ $t('objects.patient.text.sms_delivery_setting') }}</label>
          <p class="patient-detail__information__data">
            {{ checkDeliver(patientInfo.is_receive_sms) }}
          </p>
        </div>
        <div
          v-if="false"
          class="patient-detail__information__row patient-detail__information__row--half"
        >
          <label class="patient-detail__information__label">{{ $t('objects.patient.text.mail_delivery_setting') }}</label>
          <p class="patient-detail__information__data">
            {{ checkDeliver(patientInfo.is_receive_email) }}
          </p>
        </div>
      </div>
      <div v-if="typeof (cslBrandType) !== 'undefined' && cslBrandType !== null">
        <div class="patient-detail__information__title patient-detail__information__title--line-bottom patient-detail__information__title--csl-info">
          {{ $t('objects.patient.text.csl_reservation_information') }}
        </div>
        <div class="patient-detail__information__group">
          <div class="patient-detail__information__row">
            <label class="patient-detail__information__label-bold">{{ $t('new_text.brand_type') }}</label>
            <p class="patient-detail__information__data">
              {{ cslBrandName }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatDateTime, getAgeString, getBrandNameFromType } from '../../../utils/helpers'

  export default {
    name: 'PatientInformation',
    props: {
      patientInfo: {
        type: Object,
        default: () => {}
      },
      sfTreatmentendStatusC: {
        type: Number,
        default: null
      },
      sfPreContractSimulation: {
        type: Number,
        default: null
      },
      cslBrandType: {
        type: Number,
        default: null
      }
    },
    computed: {
      patientFullName () {
        return !this.patientInfo.last_name || !this.patientInfo.first_name
          ? this.patientInfo.name
          : `${this.patientInfo.last_name} ${this.patientInfo.first_name}`
      },
      patientKanaName () {
        return `${this.patientInfo.kana_last_name} ${this.patientInfo.kana_first_name}`
      },
      patientRomaName () {
        return `${this.patientInfo.roma_last_name} ${this.patientInfo.roma_first_name}`
      },
      birthDate () {
        const birthOfDate = this.patientInfo.birth_of_date
        return formatDateTime(birthOfDate, this.$t('datetime.year_month_day'))
      },
      sex () {
        const genderFilter = this.$t('objects.patient.gender_filter')
        const gender = this.patientInfo.gender
        const genderItem = genderFilter.filter(item => item.id === gender)
        return genderItem.length !== 0 ? genderItem.shift().name : ''
      },
      brandMaster () {
        return getBrandNameFromType(this.patientInfo.brand_type, this.$t('objects.patient.brand_master_filter'))
      },
      treatmentendStatus () {
        return typeof this.sfTreatmentendStatusC !== 'number' ? '-'
          : this.$t('objects.patient.sf_treatmentend_status_c.' + this.sfTreatmentendStatusC)
      },
      preContractSimulation () {
        return typeof this.sfPreContractSimulation !== 'number' ? ''
          : this.$t('objects.patient.sf_pre_contract_simulation.' + this.sfPreContractSimulation)
      },
      cslBrandName () {
        if (typeof (this.cslBrandType) === 'undefined' || this.cslBrandType === null) {
          return ''
        }

        return getBrandNameFromType(this.cslBrandType, this.$t('objects.patient.brand_master_filter'))
      }
    },
    methods: {
      checkDeliver: function (deliver) {
        return typeof deliver !== 'number' ? ''
          : (deliver === 1 ? this.$t('new_text.receive_sms') : this.$t('new_text.do_not_deliver'))
      },
      renderAge: function (date) {
        return getAgeString(date, this.$t('new_text.age'))
      }
    }
  }
</script>
