<template>
  <div class="print-component component-wrapper interview-checklist">
    <div class="bg-white">
      <table class="table table-interview-check-list table-interview-scan text-center">
        <tbody>
          <tr>
            <td
              colspan="3"
              class="bg-highlight stretch-width"
            >
              X-ray
            </td>
            <td class="bg-highlight stretch-width">
              Photo
            </td>
            <td class="bg-highlight stretch-width">
              Scan
            </td>
          </tr>
          <tr>
            <td class="bg-highlight stretch-width">
              パノラマ
            </td>
            <td class="bg-highlight stretch-width">
              セファロ
            </td>
            <td class="bg-highlight stretch-width">
              CT
            </td>
            <td
              class="col-data"
              rowspan="2"
            />
            <td
              class="col-data"
              rowspan="2"
            />
          </tr>
          <tr class="row-fill-data">
            <td class="col-data" />
            <td class="col-data" />
            <td class="col-data" />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InterviewCheckListPrint'
  }
</script>
