export default {
  filter_position: {
    all: 'すべて',
    a: 'あ',
    ka: 'か',
    sa: 'さ',
    ta: 'た',
    na: 'な',
    ha: 'は',
    ma: 'ま',
    ya: 'や',
    ra: 'ら',
    wa: 'わ'
  },
  filter_designer_list: {
    all: {
      name: '全て表示'
    },
    is_admin: {
      name: 'マネージャ権限のみ',
      id_filter: 1
    },
    not_admin: {
      name: 'スタッフ権限のみ',
      id_filter: 0
    }
  },
  filter_staff_list: {
    all: {
      name: '全て表示'
    },
    is_admin: {
      name: 'マネージャ権限のみ',
      id_filter: 1
    },
    not_admin: {
      name: 'スタッフ権限のみ',
      id_filter: 0
    },
    is_dentist: {
      name: '歯科医師のみ',
      id_filter: 1
    },
    clean_staff: {
      name: '歯科衛生士のみ',
      id_filter: 3
    },
    counselors: {
      name: 'コーディネーターのみ',
      id_filter: 2
    },
    normal_staff: {
      name: 'スタッフのみ',
      id_filter: 4
    }
  },
  staff_id: 'スタッフID'
}
