var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-action", {
    staticClass: "modal-confirm modal-confirm-danger",
    attrs: {
      title: _vm.title || _vm.$t("new_text.confirm_delete"),
      "is-show-close-button": false
    },
    on: {
      close: function($event) {
        return _vm.$emit("close")
      },
      confirm: function($event) {
        return _vm.$emit("confirm")
      }
    },
    scopedSlots: _vm._u(
      [
        _vm.text
          ? {
              key: "body",
              fn: function() {
                return [
                  _vm._t("body", [_c("span", [_vm._v(_vm._s(_vm.text))])])
                ]
              },
              proxy: true
            }
          : null,
        {
          key: "cancel_button_content",
          fn: function() {
            return [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.textNo || _vm.$t("new_text.button.no")) +
                  "\n  "
              )
            ]
          },
          proxy: true
        },
        {
          key: "confirm_button_content",
          fn: function() {
            return [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.textOk || _vm.$t("new_text.button.exclude")) +
                  "\n  "
              )
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }