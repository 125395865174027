<template>
    <div :class="[className]">
        <sub-question
            ref="sub_question"
            :question="question"
            :options="option_yes_no"
            :checked="select_option"
            @handleAnswer="handleAnswer($event)"
        ></sub-question>
        <div v-bind:class="handleChildAnswerClass">
            <div class="form-group">
                <checkbox-common
                    v-model="selections" 
                    label="傷が化膿しやすい" 
                    val="傷が化膿しやすい">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="血が止まりにくい" 
                    val="血が止まりにくい">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="蕁麻疹が出やすい" 
                    val="蕁麻疹が出やすい">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="湿疹が出やすい" 
                    val="湿疹が出やすい">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="口内炎が出やすい" 
                    val="口内炎が出やすい">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="歯が染みやすい" 
                    val="歯が染みやすい">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="下痢しやすい" 
                    val="下痢しやすい">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="喘息がある" 
                    val="喘息がある">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="風邪をひきやすい" 
                    val="風邪をひきやすい">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="薬を飲むと胃が悪くなる" 
                    val="薬を飲むと胃が悪くなる">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="無カタラーゼ症" 
                    val="無カタラーゼ症">
                </checkbox-common>
                <checkbox-common
                    v-model="selections" 
                    label="光線過敏症" 
                    val="光線過敏症">
                </checkbox-common>
            </div>
        </div>
    </div>
</template>

<script>
    import SubQuestion from '../template/SubQuestion'
    import CheckboxCommon from '../template/CheckboxCommon'
    import { INTERVIEW_COMMON_OPTIONS } from '../../../../utils/constants'
    import Common from "./CommonFunction";

    export default {
        components: {
            SubQuestion,
            CheckboxCommon
        },
        props: ['className'],
        mixins: [Common],
        data() {
            return {
                number: 15,
                select_option: INTERVIEW_COMMON_OPTIONS.NO,
                question: '今までに次のようなことがありましたか？',
                option_yes_no: [
                    INTERVIEW_COMMON_OPTIONS.YES,
                    INTERVIEW_COMMON_OPTIONS.NO,
                ],
                selections: [],
                translate: {
                    選択肢: 'selections'
                },
            }
        },
        computed: {
            handleChildAnswerClass: function () {
                return {
                    'disable-child-answer': this.select_option === INTERVIEW_COMMON_OPTIONS.NO
                }
            },
        }
    }
</script>
