import { render, staticRenderFns } from "./ReservationSchedule.vue?vue&type=template&id=6aff7353&"
import script from "./ReservationSchedule.vue?vue&type=script&lang=js&"
export * from "./ReservationSchedule.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/medusa/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6aff7353')) {
      api.createRecord('6aff7353', component.options)
    } else {
      api.reload('6aff7353', component.options)
    }
    module.hot.accept("./ReservationSchedule.vue?vue&type=template&id=6aff7353&", function () {
      api.rerender('6aff7353', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/patient/v2/detail/ReservationSchedule.vue"
export default component.exports