var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-reason-for-non-contract__grid-item" },
    [
      _c("div", { staticClass: "select-reason-for-non-contract__title" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
      ]),
      _vm._v(" "),
      _vm._l(_vm.reasons, function(item) {
        return _c(
          "div",
          { key: item.id, staticClass: "select-reason-for-non-contract__item" },
          [
            _c("radio", {
              attrs: {
                label: _vm.$t("" + item.key),
                val: item.id,
                "is-checked": item.id === _vm.selectedId,
                name: "reason"
              },
              on: {
                select: function($event) {
                  return _vm.$emit("choose-reason", item.id)
                }
              }
            }),
            _vm._v(" "),
            _vm.isOtherReason(item.id)
              ? _c(
                  "div",
                  { staticClass: "select-reason-for-non-contract__other" },
                  [
                    _c("input-common", {
                      class: [
                        { "common-input-error": _vm.errors.other_reason }
                      ],
                      attrs: {
                        "default-value": _vm.otherReason,
                        disabled: _vm.isNotOtherReason,
                        placeholder: _vm.$t(
                          "objects.patient.placeholder.other_reason_placeholder"
                        )
                      },
                      on: {
                        input: function(value) {
                          return _vm.$emit("input-other", value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.other_reason
                      ? _c("span", { staticClass: "common-msg-error" }, [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.errors.other_reason) +
                              "\n      "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }