<template>
  <div
    :class="[
      'layout-v2',
      'layout-v2--' + getClassName,
      { 'layout-v2--smart-phone': isSPLayout },
      { 'layout-v2--has-notification': isShowNotificationComponent }
    ]"
  >
    <header-component
      :is-sp-layout="isSPLayout"
      @show-notification="handelShowNotification"
      @request-notification="requestGetNotification"
    />
    <notification
      v-if="isShowNotificationComponent"
      @close="handelReadAndClose"
    />
    <div class="layout-v2__content">
      <router-view />
    </div>
    <modal-select-reason-for-non-contract
      v-if="isShowModalForNonContract"
    />
    <loading />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Header from '../components/layouts/Header'
  import Notification from '../components/layouts/Notification'
  import Loading from '../components/Loading'
  import { LayoutModeMixin } from '../mixins/layout-mode'
  import { SCREEN_WIDTH_MEDIUM, NOTIFICATION_FORCE_DISPLAY, NOTIFICATION_READ } from '../utils/constants'
  import { isEmpty, get, isNull } from 'lodash'
  import { intVal } from '../utils/helpers'
  import ModalSelectReasonForNonContract
    from '../components/patient/v2/reason-for-non-contract/ModalSelectReasonForNonContract'

  export default {
    components: {
      'header-component': Header,
      Notification,
      ModalSelectReasonForNonContract,
      Loading
    },
    mixins: [LayoutModeMixin],
    data () {
      return {
        is_show_notification: false
      }
    },
    computed: {
      ...mapState('notification', ['notification_info']),
      getClassName () {
        if (this.$route.name === 'reservation.list') {
          return 'reservation-list'
        }
        return this.$route.name
      },
      isSPLayout () {
        return this.currentLayoutMode === this.layout_mode.SP ||
          (!this.currentLayoutMode && this.window_width <= SCREEN_WIDTH_MEDIUM)
      },
      isStaff () {
        return this.$store.getters.isStaff
      },
      isShowNotificationComponent () {
        return this.isStaff && !this.isSPLayout && this.is_show_notification
      },
      isShowModalForNonContract () {
        return this.$store.getters.get_reasonForNonContractModalStatus
      }
    },
    created () {
      this.requestGetNotification()
    },
    methods: {
      handelReadAndClose () {
        this.handleShowNotification(false)
        this.$store.dispatch('notification/markAsReadNotification', { id: this.notification_info?.id || 0 })
      },
      handelShowNotification () {
        if (!this.is_show_notification) {
          this.handleShowNotification(true)
        }
      },
      requestGetNotification: function () {
        if (!this.isStaff || this.isSPLayout) {
          return
        }

        this.$store.dispatch('notification/getNotificationInfo').then((result) => {
          const data = get(result, 'data.results', {})
          if (isEmpty(data)) {
            this.handleShowNotification(false)

            return
          }

          if (isNull(this.$store.state.auth.is_open_notification)) {
            const condition = data.force_display === NOTIFICATION_FORCE_DISPLAY.EVERY_TIME ||
              (
                data.force_display === NOTIFICATION_FORCE_DISPLAY.ONE_TIME &&
                intVal(data.is_read) === NOTIFICATION_READ.NONE
              )
            this.handleShowNotification(condition)
          } else {
            if (
              (
                data.force_display === NOTIFICATION_FORCE_DISPLAY.EVERY_TIME ||
                data.force_display === NOTIFICATION_FORCE_DISPLAY.ONE_TIME
              ) &&
              intVal(data.is_read) === NOTIFICATION_READ.NONE
            ) {
              this.handleShowNotification(true)

              return
            }

            this.is_show_notification = String(localStorage.getItem('is_open_notification')) === 'true'
          }
        })
      },
      handleShowNotification (value) {
        this.is_show_notification = value
        this.$store.commit('setIsOpenNotification', value)
      }
    }
  }
</script>
