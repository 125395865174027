export default {
  allocation_types: {
    0: '設定しない',
    1: '新規予約枠に設定',
    2: 'カウンセリング再診枠に設定'
  },
  frame_csl: 'C再自動',
  setting_type_csl: 'カウンセリング再診枠に設定',
  setting_type_new_reservation: '新規予約枠に設定',
  setting_type_not_set: '設定しない'
}
