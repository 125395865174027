<template>
  <div class="modal-create-reservation modal-create-reservation--update">
    <modal-action
      :class="{ 'modal-create-reservation__disabled-confirm': !is_valid_submit, 'd-none': !is_show_modal_update }"
      :title="getTitle"
      @close="$emit('close')"
    >
      <template #body>
        <div class="modal-create-reservation__body">
          <div class="modal-create-reservation__left-panel">
            <div class="modal-create-reservation__header">
              <div class="modal-create-reservation__row">
                <span class="modal-create-reservation__label">{{ $t('common.date_time') }}</span>
                <div class="modal-create-reservation__input datepicker datepicker--header-calender">
                  <span
                    v-if="isBlockedBusiness || isLocked"
                    class="modal-create-reservation--read-only modal-create-reservation--date-picker"
                  >{{ data_reservation.date }}</span>
                  <date-picker
                    v-else
                    v-model="data_reservation.date"
                    class="modal-create-reservation__date"
                    readonly="readonly"
                    :wrap="false"
                    :config="dpOptions"
                    :disabled="isBlockedBusiness || isLocked"
                    @input="setDateTo"
                  />
                  <div class="modal-create-reservation__time">
                    <span
                      v-if="isBlockedBusiness || isLocked"
                      class="modal-create-reservation--read-only modal-create-reservation--time-picker"
                    >{{ data_reservation.time_start }}</span>
                    <div
                      v-else
                      v-click-outside="clickOutsideEvent"
                      :class="[
                        'modal-create-reservation__wrapper',
                        { 'modal-create-reservation__wrapper--active': show_modal_time_setting === `start-time` }
                      ]"
                    >
                      <span
                        :class="['form-control time-input', { 'time-input--placeholder': !data_reservation.time_start }]"
                        @click="showModalTimeSetting('start-time')"
                        v-text="data_reservation.time_start || '--:--'"
                      />
                      <modal-time-setting
                        v-if="show_modal_time_setting === `start-time`"
                        :default-value="data_reservation.time_start"
                        @set-time="updateStartTime"
                      />
                    </div>
                    <span class="modal-create-reservation__connection">〜</span>
                    <span
                      v-if="isBlockedBusiness || isLocked"
                      class="modal-create-reservation--read-only modal-create-reservation--time-picker"
                    >{{ data_reservation.time_end }}</span>
                    <div
                      v-else
                      v-click-outside="clickOutsideEvent"
                      :class="[
                        'modal-create-reservation__wrapper',
                        { 'modal-create-reservation__wrapper--active': show_modal_time_setting === `end-time` }
                      ]"
                    >
                      <span
                        :class="['form-control time-input', { 'time-input--placeholder': !data_reservation.time_end }]"
                        @click="showModalTimeSetting('end-time')"
                        v-text="data_reservation.time_end || '--:--'"
                      />
                      <modal-time-setting
                        v-if="show_modal_time_setting === `end-time`"
                        :default-value="data_reservation.time_end"
                        @set-time="updateEndTime"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="isLocked"
                class="modal-create-reservation__row modal-create-reservation__locked-msg"
              >
                {{ reservationCanNotChangeMessages }}
              </div>
              <div
                v-else
                class="modal-create-reservation__row modal-create-reservation__row-block"
              />
            </div>
            <div class="modal-create-reservation__content">
              <div class="modal-create-reservation__row">
                <span class="modal-create-reservation__label">{{ $t('common.name') }}</span>
                <div
                  v-if="!data_reservation.blocked"
                  class="modal-create-reservation__input"
                >
                  <div class="modal-create-reservation__name-update">
                    {{ keyword_search_name }}
                  </div>
                  <div class="modal-create-reservation__id-update">
                    <span class="modal-create-reservation__label-id">{{ $t('common.ID') }}</span>
                    {{ keyword_search_id || '' }}
                  </div>
                </div>
                <div
                  v-else
                  class="modal-create-reservation__input"
                >
                  <input-search
                    :disabled="true"
                    :icon-disabled="undefined"
                    :placeholder="$t('text.select_patient_placeholder')"
                    class="modal-create-reservation__name"
                  />
                  <span class="modal-create-reservation__label-id">{{ $t('new_text.id') }}</span>
                  <input-search
                    :disabled="true"
                    :icon-disabled="undefined"
                    class="modal-create-reservation__id"
                  />
                </div>
              </div>
              <div class="modal-create-reservation__row modal-create-reservation__row-type">
                <span class="modal-create-reservation__label">{{ $t('text.reservation_type') }}</span>
                <div
                  :class="['modal-create-reservation__input modal-create-reservation__input-type',
                           {'blocked-frame': data_reservation.blocked}]"
                >
                  <div
                    v-for="(item, index) in reservation_types"
                    :key="item.id"
                    class="modal-list-reservation-type__item modal-create-reservation__selected-item"
                  >
                    <div class="modal-list-reservation-type__text">
                      <span
                        class="modal-list-reservation-type__color modal-create-reservation__selected-color"
                        :style="[ item.duty ? { backgroundColor: item.duty.color } : '' ]"
                      />
                      <span class="modal-list-reservation-type__name">{{ item.duty_name }}</span>
                    </div>
                    <div class="modal-list-reservation-type__value modal-create-reservation__selected-value">
                      <span class="modal-list-reservation-type__time">
                        {{ item.take_time }}{{ $t('common.minute') }}
                        <img
                          src="/images/icon-close.svg"
                          @click="removeReservationTypeSelected(index)"
                        >
                      </span>
                    </div>
                  </div>
                  <button-common
                    :class="{
                      'modal-create-reservation__btn-add': true,
                      'modal-create-reservation__btn-disabled': data_reservation.blocked
                    }"
                    @click="showModalReservationType"
                  >
                    <template #content>
                      <img
                        v-if="data_reservation.blocked"
                        src="/images/icon-plus-gray.svg"
                      >
                      <img
                        v-else
                        src="/images/icon-plus-v2.svg"
                      >
                      {{ $t('reservation.add_reservation_type') }}
                    </template>
                  </button-common>
                </div>
              </div>
              <div class="modal-create-reservation__row modal-create-reservation__row-charge">
                <span class="modal-create-reservation__label">{{ $t('common.responsible') }}</span>
                <div
                  :class="['modal-create-reservation__input modal-create-reservation__input-charge',
                           {'blocked-frame': data_reservation.blocked}]"
                >
                  <div
                    v-for="(staff, index) in person_in_charges"
                    :key="staff.id"
                    class="modal-list-reservation-type__item modal-create-reservation__selected-item"
                  >
                    <div class="modal-list-reservation-type__text modal-list-reservation-type__text--person-in-charge">
                      <span class="modal-create-reservation__position-name">{{ staff.position_name }}</span>
                      {{ staff.name }}
                    </div>
                    <div class="modal-list-reservation-type__value modal-create-reservation__selected-value">
                      <img
                        src="/images/icon-close.svg"
                        @click="removePersonInCharge(index)"
                      >
                    </div>
                  </div>
                  <button-common
                    :class="{
                      'modal-create-reservation__btn-add': true,
                      'modal-create-reservation__btn-disabled': data_reservation.blocked
                    }"
                    @click="showModalPersonCharge"
                  >
                    <template #content>
                      <img
                        v-if="data_reservation.blocked"
                        src="/images/icon-plus-gray.svg"
                      >
                      <img
                        v-else
                        src="/images/icon-plus-v2.svg"
                      >
                      {{ $t('text.add_charge') }}
                    </template>
                  </button-common>
                </div>
              </div>
              <div class="modal-create-reservation__row modal-create-reservation__row-brand">
                <span class="modal-create-reservation__label">{{ $t('patient_page.brand_type') }}</span>
                <div
                  v-if="data_reservation.patient_id"
                  class="modal-create-reservation__input modal-create-reservation__brand"
                >
                  {{ brand_text || '' }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-create-reservation__right-panel">
            <div
              v-if="!data_reservation.blocked && !hasOrderHistory"
              class="modal-create-reservation__reason-for-non-contract"
            >
              <div class="modal-create-reservation__reason-for-non-contract__header">
                <span>{{ $t('objects.patient.text.reason_for_non_contracting') }}</span>
                <button
                  class="button-tertiary button-tertiary--small"
                  @click="setReasonForNonContractModalStatus(true)"
                >
                  {{ $t('new_text.button.select') }}
                </button>
              </div>
              <span
                class="fw-400 ws-pre-wrap modal-create-reservation__reason-for-non-contract__content"
              >{{ data_reservation.reason_for_non_contract }}</span>
            </div>
            <div
              :class="[
                isBlockedBusiness ? 'modal-create-reservation__right-panel__business-blocked' : '',
                (!data_reservation.blocked && !hasOrderHistory) ? 'modal-create-reservation__right-panel__reservation' : ''
              ]"
            >
              <span>{{ $t('common.note') }}</span>
              <textarea
                v-model="data_reservation.memo"
                :disabled="isBlockedBusiness"
                :placeholder="$t('reservation.reservation_note_placeholder')"
              />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="modal-create-reservation__footer">
          <div class="modal-create-reservation__btn-delete">
            <button-common
              :disabled="isBlockedBusiness || isLocked"
              @click="handleClickDelete"
            >
              <template #content>
                {{ data_reservation.blocked ? $t('objects.reservation.text.unblock_frame') :
                  $t('objects.reservation.text.cancel_reservation') }}
              </template>
            </button-common>
          </div>
          <div class="modal-create-reservation__btn">
            <button-common
              v-if="!data_reservation.blocked"
              class="modal-create-reservation__btn-patient"
              @click="gotoPatientDetail"
            >
              <template #content>
                {{ $t('text.patient_details') }}
              </template>
            </button-common>
            <button-common
              class="modal-create-reservation__btn-update"
              :disabled="!is_valid_submit || isBlockedBusiness"
              @click="handleSubmit"
            >
              <template #content>
                {{ data_reservation.blocked ? $t('objects.reservation.text.update_memo') :
                  $t('objects.reservation.text.update_reservation') }}
              </template>
            </button-common>
          </div>
        </div>
      </template>
    </modal-action>
    <modal-list-reservation-type
      v-if="is_show_modal_list_reservation_type"
      :reservation-type-selected="reservation_types"
      @close="is_show_modal_list_reservation_type = false"
      @select="selectReservationType"
    />
    <modal-person-in-charge
      v-if="is_show_modal_person_in_charge"
      @close="is_show_modal_person_in_charge = false"
      @select="selectPersonInCharge"
    />
    <modal-reason-cancel
      v-if="is_show_modal_reason_cancel"
      :reservation-id="data_reservation.reservation_id"
      @close="is_show_modal_reason_cancel = false"
      @submit="handleSubmitCancelReservation"
    />
    <modal-confirm
      v-if="is_show_modal_confirm_first_reservation"
      :title="$t('patient_page.first_reservation_modal_title_change')"
      :text="$t('patient_page.first_reservation_confirmation_change')"
      @no="is_show_modal_confirm_first_reservation = false"
      @yes="handleUpdateReservation"
    />
    <modal-confirm-danger
      v-if="is_show_modal_confirm_cancel_block"
      class="modal-confirm-danger--delete-block"
      :title="$t('message.block_removal_confirmation')"
      :text-ok="$t('common.yes')"
      :text-no="$t('common.no')"
      @confirm="submitCancelReservation({ reservation_id: data_reservation.reservation_id })"
      @close="is_show_modal_confirm_cancel_block = false"
    />
    <modal-operation-reason
      v-if="is_show_modal_operation_reasons"
      :is-update="true"
      :is-block="!!data_reservation.blocked"
      :resources="operationReasonResources"
      @back="handleCloseOperationReasonModal"
      @confirm="submitUpdateReservation"
    />
  </div>
</template>

<script>
  import * as moment from 'moment'
  import { showErrorModal } from '../../utils/helpers'
  import DatePicker from 'vue-bootstrap-datetimepicker'
  import ModalAction from '../common/ModalAction'
  import ButtonCommon from '../common/Button'
  import ModalListReservationType from './ModalListReservationType'
  import ModalOperationReason from './ModalOperationReason'
  import ModalPersonInCharge from './ModalPersonInCharge'
  import ModalReasonCancel from './ModalReasonCancel'
  import ModalConfirm from '../common/ModalConfirm'
  import InputSearch from '../common/InputSearch'
  import ModalTimeSetting from '../modal/ModalTimeSetting'
  import { RESPONSE_TYPE, COLUMN_TYPE, OPERATION_REASON_TYPE } from '../../utils/constants'
  import { updateOrCreate } from '../../mixins/reservation'
  import ModalConfirmDanger from '../common/ModalConfirmDanger'
  import { isEmpty, get, head, filter } from 'lodash'

  export default {
    components: {
      ButtonCommon,
      DatePicker,
      ModalAction,
      ModalListReservationType,
      ModalOperationReason,
      ModalPersonInCharge,
      ModalConfirm,
      ModalReasonCancel,
      InputSearch,
      ModalTimeSetting,
      ModalConfirmDanger
    },
    mixins: [updateOrCreate],
    props: {
      eventEdit: {
        type: Object,
        default: () => {}
      },
      allocations: {
        type: Array,
        default: () => []
      }
    },
    emits: ['created', 'close'],
    data () {
      return {
        data_reservation: {
          allocation_id: 0,
          date: '',
          time_start: '',
          time_end: '',
          blocked: false,
          flag_csv: 0,
          patient_id: 0,
          patient_name: '',
          reservation_types: [],
          person_in_charges: [],
          reservation_id: 0,
          memo: '',
          sf_user_id: 0,
          reason_for_non_contract: ''
        },
        original_time_start: '',
        original_time_end: '',
        original_date: '',
        first_reservation_id: 0,
        brand_text: '',
        is_valid_submit: false,
        reservation_types: [],
        person_in_charges: [],
        note: '',
        is_show_modal_person_in_charge: false,
        is_show_modal_list_reservation_type: false,
        is_show_modal_confirm_first_reservation: false,
        is_show_modal_reason_cancel: false,
        is_show_modal_confirm_cancel_block: false,
        is_show_modal_select_reason_for_non_contract: false,
        is_show_modal_operation_reasons: false,
        dpOptions: {
          format: 'YYYY年MM月DD日',
          useCurrent: false,
          dayViewHeaderFormat: 'YYYY / MM',
          locale: 'ja',
          ignoreReadonly: true,
          inline: false
        },
        show_modal_time_setting: '',
        operation_reason_post_data: null,
        is_show_modal_update: true
      }
    },
    computed: {
      getTitle () {
        return this.data_reservation.blocked ? this.$t('objects.reservation.text.block_frame_editing')
          : this.$t('objects.reservation.text.edit_reservation')
      },
      isBlockedBusiness () {
        return Boolean(this.data_reservation.blocked && this.data_reservation.flag_csv)
      },
      isLocked () {
        return this.first_reservation_id === parseInt(this.data_reservation.reservation_id, 10) &&
          (!!get(this.eventEdit, 'extendedProps.patient.first_reservation_month') || this.hasOrderHistory)
      },
      reasonForNonContract: function () {
        return this.$store.getters.get_reasonForNonContract
      },
      hasOrderHistory () {
        return !this.data_reservation.blocked && get(this.eventEdit, 'extendedProps.patient.order_count', 0) !== 0
      },
      allocation () {
        return this.allocations.find(item => item.id === get(this.eventEdit, 'extendedProps.allocation_id', 0))
      },
      isDetectAutoFrameOperation: function () {
        return this.$store.getters.isDetectAutoFrameOperation &&
          [COLUMN_TYPE.AUTO, COLUMN_TYPE.CSL].includes(get(this.allocation, 'column_type', COLUMN_TYPE.NOT_AUTO))
      },
      operationReasonResources () {
        const eventData = this.eventEdit?.extendedProps || {}
        let startDate = this.data_reservation.date && this.data_reservation.time_start
          ? moment(`${this.data_reservation.date} ${this.data_reservation.time_start}`)
          : ''
        const endDate = this.data_reservation.date && this.data_reservation.time_end
          ? moment(`${this.data_reservation.date} ${this.data_reservation.time_end}`)
          : ''
        if (startDate && startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) {
          startDate = endDate.clone().startOf('day')
        }

        const reservationTime = (startDate ? startDate.format('YYYY/MM/DD HH:mm') : '') +
          ' ~ ' + (startDate ? endDate.format('HH:mm') : '')

        return {
          sf_user_id: get(eventData, 'sf_user_id', 0),
          name: get(eventData, 'patient_info.name', ''),
          allocation_name: get(this.allocation, 'column_name'),
          brand_type_name: this.brand_text,
          memo: this.data_reservation.memo,
          reservation_time: reservationTime
        }
      },
      reservationCanNotChangeMessages () {
        if (get(this.eventEdit, 'extendedProps.patient.first_reservation_month')) {
          return this.$t('objects.reservation.messages.reservation_can_not_change')
        }

        if (this.hasOrderHistory) {
          return this.$t('objects.reservation.messages.reservation_can_not_change_because_has_order')
        }

        return ''
      }
    },
    watch: {
      reasonForNonContract (newValue) {
        this.data_reservation.reason_for_non_contract = newValue
      }
    },
    created () {
      this.setData()
    },
    methods: {
      setData () {
        const extendedProps = this.eventEdit?.extendedProps || {}
        this.data_reservation.allocation_id = extendedProps.allocation_id
        const startStr = this.eventEdit.start
        const startTime = moment(startStr)
        this.data_reservation.date = startTime.format(this.$t('datetime.year_month_day'))
        this.data_reservation.time_start = startTime.format('HH:mm')

        this.original_time_start = this.data_reservation.time_start
        this.original_date = moment(this.data_reservation.date, this.$t('datetime.year_month_day'))
          .format('YYYY-MM-DD')
        const endStr = this.eventEdit.end
        const endTime = moment(endStr)
        this.data_reservation.time_end = endTime.format('HH:mm')

        if (moment(endStr, 'YYYY-MM-DD').diff(moment(startStr, 'YYYY-MM-DD'), 'days')) {
          this.data_reservation.time_end = '23:59'
        }

        this.original_time_end = this.data_reservation.time_end

        this.data_reservation.blocked = extendedProps.block
        this.data_reservation.flag_csv = extendedProps.flag_csv
        this.disable_send_mail = true
        this.data_reservation.memo = extendedProps.memo
        this.data_reservation.reason_for_non_contract = get(extendedProps, 'patient_info.reason_for_non_contract', '')
        this.setReasonForNonContract(this.data_reservation.reason_for_non_contract)
        this.data_reservation.patient_id = extendedProps.patient_id
        this.data_reservation.sf_user_id = extendedProps.sf_user_id
        const patient = extendedProps?.patient || {}
        this.first_reservation_id = patient.first_reservation_id || 0
        const patientInfo = extendedProps.patient_info || {}
        this.data_reservation.patient_name = `${patientInfo.last_name || ''} ${patientInfo.first_name || ''}`
        const brand = head(filter(
          this.$t('objects.patient.brand_master_filter'),
          origin => origin.type === get(extendedProps, 'csl_brand_type'))
        )
        const brandName = get(brand, 'name', '')
        if (brandName) {
          this.brand_text = `${brandName} ${this.$t('new_text.csl_character')}`
        } else {
          this.brand_text = patientInfo.brand_master ? (patientInfo.brand_master.name || '') : ''
        }

        const staffs = extendedProps.staffs || []

        this.person_in_charges = staffs.map(item => {
          if (item.staff_info) {
            return {
              id: item.staff_info.id,
              name: item.staff_info.name,
              name_romaji: item.staff_info.name_romaji,
              position_name: item.staff_info.position ? item.staff_info.position.name : null
            }
          }
        })

        this.data_reservation.reservation_id = this.eventEdit.id

        for (const item of extendedProps.duties) {
          if (!item.master) continue
          this.reservation_types.push({
            id: item.master.id,
            duty_name: item.master.duty_name,
            take_time: item.master.take_time,
            is_deleted: item.master.is_deleted,
            duty: {
              color: item.master.duty ? item.master.duty.color : ''
            }
          })
        }
        this.keyword_search_id = this.data_reservation.sf_user_id || this.data_reservation.patient_id
        this.keyword_search_name = this.data_reservation.patient_name
        this.validateForm()
      },
      handleSubmit () {
        if (this.isBlockedBusiness) {
          return this.$emit('close')
        }

        this.data_reservation.date = moment(this.data_reservation.date, this.$t('datetime.year_month_day'))
          .format('YYYY-MM-DD')
        this.data_reservation.reservation_types = this.reservation_types.map(item => item.id)
        this.data_reservation.person_in_charges = this.person_in_charges.map(item => ({ id: item.id, type: null }))

        if (this.checkConflictWithOtherFrames(this.eventEdit.getResources()[0].getEvents())) {
          showErrorModal(this.$t('message.already_booked'))

          return
        }

        if (!this.validateForm()) {
          return
        }

        this.$store.commit('set_loading', true)
        this.checkExistReservationTime({
          allocation_id: this.data_reservation.allocation_id,
          date: this.data_reservation.date,
          start_time: this.data_reservation.time_start,
          end_time: this.data_reservation.time_end,
          reservation_id: this.data_reservation.reservation_id
        }).then(result => {
          if (!result) {
            this.$store.commit('set_loading', false)

            return
          }

          if (
            this.first_reservation_id === parseInt(this.data_reservation.reservation_id, 10) &&
            !this.isLocked
          ) {
            this.$store.commit('set_loading', false)
            this.is_show_modal_confirm_first_reservation = true
          } else if (this.data_reservation.blocked || this.isLocked) {
            this.handleUpdateReservation()
          } else {
            this.checkFirstReservationOfPatient({
              patient_id: this.data_reservation.patient_id,
              started_to: `${this.data_reservation.date} ${this.data_reservation.time_start}`,
              id: this.data_reservation.reservation_id
            }).then(result => {
              if (!result) {
                this.$store.commit('set_loading', false)

                return
              }

              this.handleUpdateReservation()
            }).catch(() => {
              this.$store.commit('set_loading', false)
              showErrorModal(this.$t('message.something_went_wrong'))
            })
          }
        }).catch(() => {
          this.$store.commit('set_loading', false)
          showErrorModal(this.$t('message.something_went_wrong'))
        })
      },
      checkConflictWithOtherFrames (events) {
        const startTime = (new Date(`${this.data_reservation.date} ${this.data_reservation.time_start}`)).getTime()
        const endTime = (new Date(`${this.data_reservation.date} ${this.data_reservation.time_end}`)).getTime()

        for (const event of events) {
          if (
            event.end &&
            event.start &&
            (startTime < event.end.getTime()) &&
            (endTime > event.start.getTime()) &&
            (this.eventEdit.id !== event.id)
          ) {
            return true
          }
        }

        return false
      },
      submitUpdateReservation (operationReasonPostData = null) {
        this.operation_reason_post_data = operationReasonPostData
        this.is_show_modal_confirm_first_reservation = false
        this.is_show_modal_operation_reasons = false
        this.is_show_modal_update = true
        const dataPost = Object.assign({}, this.data_reservation)
        delete dataPost.patient_name
        dataPost.disable_send_mail = true
        this.$store.commit('set_loading', true)
        this.$store.dispatch('updateReservationInfo', dataPost).then(result => {
          this.$store.commit('set_loading', false)
          const data = result?.data?.results
          if (data === RESPONSE_TYPE.DELETED) {
            showErrorModal(this.$t('reservation.reservation_type_deleted'))

            return
          }

          this.storeOperationReasons()
          this.$emit('updated', data)
        }).catch(error => {
          this.$store.commit('set_loading', false)
          const resErrors = error?.response?.data?.errors || {}
          const errors = []

          if (resErrors?.reservation === RESPONSE_TYPE.CANCELLED) {
            return showErrorModal(this.$t('new_validation.reservation.reservation_canceled'))
          }

          if (resErrors?.memo === RESPONSE_TYPE.MAXIMUM) {
            return showErrorModal(this.$t('new_validation.reservation.memo_maxlength'))
          }

          if (!isEmpty(resErrors) && resErrors.first_reservation === RESPONSE_TYPE.LOCKED) {
            return showErrorModal(this.$t('objects.reservation.messages.reservation_can_not_change'))
          }

          if (!isEmpty(resErrors) && resErrors.patient === RESPONSE_TYPE.HAS_ORDER_HISTORY) {
            return showErrorModal(
              this.$t('objects.reservation.messages.reservation_can_not_change_because_has_order'),
              { width: 'auto' }
            )
          }

          for (const index in resErrors) {
            errors.push(resErrors[index])
          }

          if (errors.length) {
            return showErrorModal(errors.join('<br>'))
          }

          showErrorModal(this.$t('message.something_went_wrong'))
        })
      },
      gotoPatientDetail () {
        const url = this.$router.resolve({
          name: 'patient.detail',
          params: { id: this.data_reservation.patient_id },
          query: { reservation_id: this.data_reservation.reservation_id, tab_active: 'reservation_schedule' }
        }).href
        window.open(url, '_blank').focus()
      },
      handleClickDelete () {
        if (this.isBlockedBusiness) {
          return this.$emit('close')
        }

        if (this.isLocked) {
          return
        }

        if (this.data_reservation.blocked) {
          this.is_show_modal_confirm_cancel_block = true
        } else {
          this.is_show_modal_reason_cancel = true
        }
      },
      handleSubmitCancelReservation (data) {
        this.is_show_modal_reason_cancel = false
        this.submitCancelReservation(data)
      },
      submitCancelReservation (data) {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('cancelReservation', data).then(() => {
          this.$store.commit('set_loading', false)
          this.$emit('canceled', this.data_reservation.blocked)
        }).catch(error => {
          this.$store.commit('set_loading', false)
          const resErrors = get(error, 'response.data.errors', {}) || {}

          if (!isEmpty(resErrors) && resErrors.first_reservation === RESPONSE_TYPE.LOCKED) {
            showErrorModal(this.$t('objects.reservation.messages.reservation_can_not_change'))

            return
          }

          if (!isEmpty(resErrors) && resErrors.patient === RESPONSE_TYPE.HAS_ORDER_HISTORY) {
            showErrorModal(
              this.$t('objects.reservation.messages.reservation_can_not_cancel_because_has_order'),
              { width: 'auto' }
            )

            return
          }

          if (resErrors?.reservation === RESPONSE_TYPE.CANCELLED) {
            showErrorModal(this.$t('objects.reservation.messages.reservation_has_been_canceled'))

            return
          }

          showErrorModal(this.$t('new_message.something_went_wrong'))
        })
      },
      setReasonForNonContractModalStatus (value) {
        this.$store.commit('set_reasonForNonContractModalStatus', value)
      },
      setReasonForNonContract (value) {
        this.$store.commit('set_reasonForNonContract', value)
      },
      handleUpdateReservation () {
        const isUpdateTime = this.data_reservation.date !== this.original_date ||
          this.data_reservation.time_start !== this.original_time_start ||
          this.data_reservation.time_end !== this.original_time_end

        if (this.isDetectAutoFrameOperation && isUpdateTime) {
          this.$store.commit('set_loading', false)
          this.is_show_modal_operation_reasons = true
          this.is_show_modal_confirm_first_reservation = false
          this.is_show_modal_update = false

          return
        }

        this.submitUpdateReservation()
      },
      storeOperationReasons () {
        if (!this.operation_reason_post_data) {
          return
        }

        const postData = {
          ...this.operation_reason_post_data,
          reservation_id: this.data_reservation.reservation_id,
          operation_type: this.data_reservation.blocked
            ? OPERATION_REASON_TYPE.UPDATE_BLOCK
            : OPERATION_REASON_TYPE.UPDATE_RESERVATION
        }
        this.$store.dispatch('storeOperationReasons', postData).catch(() => {})
      },
      handleCloseOperationReasonModal () {
        this.is_show_modal_operation_reasons = false
        this.is_show_modal_update = true
      }
    }
  }
</script>
