var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "input-search-v2": true,
        "input-search-v2__disabled": _vm.disabled
      }
    },
    [
      _vm.iconPosition === "left"
        ? _c("span", { staticClass: "input-search-v2__icon-left" }, [
            _c("img", {
              attrs: { src: _vm.icon },
              on: {
                click: function($event) {
                  return _vm.$emit("search", _vm.keyword)
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "search-name" && _vm.isMac
        ? _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.keyword,
                expression: "keyword"
              }
            ],
            attrs: { disabled: _vm.disabled, placeholder: _vm.placeholder },
            domProps: { value: _vm.keyword },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.keyword = $event.target.value
                },
                _vm.onInput
              ]
            }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.keyword,
                expression: "keyword"
              }
            ],
            attrs: { disabled: _vm.disabled, placeholder: _vm.placeholder },
            domProps: { value: _vm.keyword },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.keyword = $event.target.value
                },
                function($event) {
                  return _vm.$emit("input", $event.target.value)
                }
              ],
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.$emit("search", _vm.keyword)
              }
            }
          }),
      _vm._v(" "),
      _vm.iconPosition === "right" && !_vm.disableSelectPatient
        ? _c("span", { staticClass: "input-search-v2__icon-right" }, [
            _c("img", {
              attrs: { src: _vm.disabled ? _vm.iconDisabled : _vm.icon },
              on: {
                click: function($event) {
                  return _vm.$emit("search", _vm.keyword)
                }
              }
            })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }