var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-sidebar" },
    [
      _vm.isLabDesigner
        ? _c(
            "div",
            { staticClass: "order-sidebar__nav-link" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "order",
                      params: { status: _vm.ORDER_STATUS.PREPARE.label }
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("objects.nav.preparing_to_ship")) +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.number_prepare))])
            ],
            1
          )
        : [
            _c(
              "div",
              { staticClass: "order-sidebar__nav-link" },
              [
                _c("router-link", { attrs: { to: { name: "order.create" } } }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("objects.nav.create_instructions")) +
                      "\n      "
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "order-sidebar__nav-link" },
              [
                _c("router-link", { attrs: { to: { name: "order.search" } } }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("objects.nav.order_patient_search")) +
                      "\n      "
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "order-sidebar__nav-link" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "order",
                        params: { status: _vm.ORDER_STATUS.CHECKING.label }
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("objects.nav.confirming_before_ordering")
                        ) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.number_checking))])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "order-sidebar__nav-link" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "order",
                        params: { status: _vm.ORDER_STATUS.SETUP_DESIGN.label }
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("objects.nav.setup_design")) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.number_setup))])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "order-sidebar__nav-link" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "order",
                        params: { status: _vm.ORDER_STATUS.PREPARE.label }
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("objects.nav.preparing_to_ship")) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.number_prepare))])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "order-sidebar__nav-link" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "order",
                        params: { status: _vm.ORDER_STATUS.SHIPPED.label }
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("objects.nav.shipment_completed")) +
                        "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "order-sidebar__nav-link" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "order",
                        params: { status: _vm.ORDER_STATUS.FINISH.label }
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("objects.nav.delivery_completed")) +
                        "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "order-sidebar__nav-link" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "order",
                        params: { status: _vm.ORDER_STATUS.CANCELLED.label }
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("objects.nav.cancelled")) +
                        "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.isShowMenuInstructionsDraft
              ? _c("div", { staticClass: "order-sidebar__nav-link" }, [
                  _c("a", { attrs: { href: "#" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("objects.nav.instructions_for_saving_draft")
                      )
                    )
                  ])
                ])
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }