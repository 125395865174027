<template>
  <div class="deleted-patient">
    <div class="content">
      <div class="content-wrapper">
        <div class="deleted-patient__header">
          <h2 class="title">
            {{ $t('objects.setting.text.deleted_patient') }}
          </h2>
          <form @submit.prevent="searchPatient">
            <div class="deleted-patient__form">
              <div class="input-group input-group--search">
                <input
                  v-model="search_keyword"
                  type="text"
                  class="form-control"
                  :placeholder="$t('placeholder.patient_search')"
                >
                <button
                  class="input-group-prepend btn"
                  type="submit"
                >
                  <img src="/images/icon-search-gray.svg">
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="common-list common-list--deleted-patient">
          <table class="table main-list main-list__search">
            <thead>
              <tr>
                <th
                  class="common-list__id common-list__sort"
                  @click="sortBy('sf_user_id')"
                >
                  {{ $t('new_text.id') }}
                  <span class="common-list__icon">
                    <img :src="getIconSort('sf_user_id')">
                  </span>
                </th>
                <th>
                  {{ $t('new_text.name') }}
                </th>
                <th>
                  {{ $t('new_text.brand') }}
                </th>
                <th
                  class="common-list__id common-list__sort"
                  @click="sortBy('deleted_at')"
                >
                  {{ $t('objects.setting.text.del_date') }}
                  <span class="common-list__icon">
                    <img :src="getIconSort('deleted_at')">
                  </span>
                </th>
                <th>
                  {{ $t('new_text.operation') }}
                </th>
              </tr>
            </thead>
            <tbody v-if="patients.length > 0">
              <tr
                v-for="patient in patients"
                :key="patient.patient_id"
              >
                <td>
                  {{ patient.sf_user_id }}
                </td>
                <td>
                  {{ getPatientName(patient) }}
                </td>
                <td>
                  {{ getKidsLabel(patient) }}
                </td>
                <td>
                  {{ formatDateTimePatient(patient.deleted_at, $t('datetime.year_month_day_hour_minute')) }}
                </td>
                <td>
                  <a
                    class="main-list__action main-list__action--undo-deletion"
                    @click="showModalUndoDeletionPatient(patient.patient_id)"
                  >
                    {{ $t('objects.setting.text.delete_cancellation') }}
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody
              v-else
              class="common-list__not-found"
            >
              <tr>
                <td colspan="5">
                  <p class="common-list__label-not-found">
                    {{ $t('patient_page.no_results') }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="patients.length > 0"
            class="common-list__pagination"
          >
            <pagination-common
              v-if="pagination.last_page > 0"
              :current-page="pagination.current_page"
              :last-page="pagination.last_page"
              class="pagination-common--pagination"
              @change-page="changePage"
            />
          </div>
          <modal-action
            v-if="is_show_modal_undo_deletion"
            :title="$t('objects.setting.text.undo_deletion')"
            class="modal-confirm modal-confirm--red"
            @close="is_show_modal_undo_deletion = false"
          >
            <template #body>
              <span>{{ $t('objects.setting.text.note_cancel_delete') }}</span>
            </template>
            <template #footer>
              <div class="modal-v2__footer__content modal-v2__footer__content--flex">
                <button-common
                  class="cancel-reservation__submit"
                  @click="handleUndoDeletion"
                >
                  <template #content>
                    {{ $t('new_text.button.deletion') }}
                  </template>
                </button-common>
                <button-common
                  class="cancel-reservation__close"
                  @click="is_show_modal_undo_deletion = false"
                >
                  <template #content>
                    {{ $t('new_text.button.no') }}
                  </template>
                </button-common>
              </div>
            </template>
          </modal-action>
        </div>
      </div>
    </div>
    <Loading />
  </div>
</template>

<script>
  import Loading from '../../../components/Loading'
  import PaginationCommon from '../../../components/common/PaginationCommon'
  import { formatDateTime, safeGet, showErrorModal, showMessageError } from '../../../utils/helpers'
  import { KIDS_BRAND_TYPE, RESPONSE_ERRORS, RESPONSE_STATUS } from '../../../utils/constants'
  import ModalAction from '../../../components/common/ModalAction'
  import ButtonCommon from '../../../components/common/Button'
  import sortMixin from '../../../mixins/sort'

  export default {
    components: {
      ModalAction,
      ButtonCommon,
      PaginationCommon,
      Loading
    },
    mixins: [sortMixin],
    data () {
      return {
        patients: [],
        pagination: {
          current_page: 1,
          last_page: 11,
          offset: 2
        },
        search_keyword: '',
        is_show_modal_undo_deletion: false,
        patient_id: null
      }
    },
    created () {
      document.title = this.$t('objects.page_title.setting').toString()
      this.getPatientsList()
    },
    methods: {
      searchPatient () {
        this.pagination.current_page = 1
        this.getPatientsList()
      },
      getPatientsList () {
        this.$store.commit('set_loading', true)
        const inputData = {
          search_keyword: this.search_keyword,
          page: this.pagination.current_page,
          sort_field: this.sort_field,
          sort_type: this.sort_type
        }

        this.$store.dispatch('getDeletedPatients', inputData).then((result) => {
          const { data: { results } } = result
          this.patients = results.data
          this.pagination.current_page = results.current_page
          this.pagination.last_page = results.last_page
          this.$store.commit('set_loading', false)
        }).catch((e) => {
          this.$store.commit('set_loading', false)
          showMessageError(e, this)
        })
      },
      changePage (page) {
        this.pagination.current_page = page
        this.getPatientsList()
      },
      getPatientName (patient) {
        let patientName = safeGet(patient, 'last_name').length === 0 || safeGet(patient, 'first_name').length === 0
          ? safeGet(patient, 'name')
          : `${safeGet(patient, 'last_name')} ${safeGet(patient, 'first_name')}`
        const patientFuriganaName = patient ? `${patient.kana_last_name || ''} ${patient.kana_first_name || ''}` : ''
        if (patientFuriganaName !== '') {
          patientName += `（${patientFuriganaName}）`
        }

        return patientName
      },
      formatDateTimePatient (value, format) {
        return formatDateTime(value, format)
      },
      getKidsLabel (patient) {
        const brand = patient.brand_master
        if (brand && brand.type === KIDS_BRAND_TYPE) {
          return 'KIDS'
        }
      },
      showModalUndoDeletionPatient (patientId) {
        this.is_show_modal_undo_deletion = true
        this.patient_id = patientId
      },
      handleUndoDeletion () {
        this.$store.commit('set_loading', true)
        this.is_show_modal_undo_deletion = false
        this.$store.dispatch('undoDeletionPatient', this.patient_id).then((result) => {
          const { data: { status, results: { type } } } = result
          this.$store.commit('set_loading', false)
          if (status === RESPONSE_STATUS.NG && type === RESPONSE_ERRORS.PATIENT_DUPLICATE) {
            showErrorModal(this.$t('objects.setting.text.undo_deletion_patient_exist_error'))
            return
          }
          if (status === RESPONSE_STATUS.OK) {
            this.getPatientsList()
            return
          }
          showErrorModal(this.$t('new_message.something_went_wrong'))
        }).catch(() => {
          this.$store.commit('set_loading', false)
          showErrorModal(this.$t('new_message.something_went_wrong'))
        })
      },
      sortBy (field) {
        this.setDataSort(field)
        this.getPatientsList()
      }
    }
  }
</script>
