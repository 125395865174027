<template>
  <div class="order-sidebar">
    <div
      v-if="isLabDesigner"
      class="order-sidebar__nav-link"
    >
      <router-link :to="{ name: 'order', params: { status: ORDER_STATUS.PREPARE.label } }">
        {{ $t('objects.nav.preparing_to_ship') }}
      </router-link>
      <span>{{ number_prepare }}</span>
    </div>
    <template
      v-else
    >
      <div class="order-sidebar__nav-link">
        <router-link :to="{ name: 'order.create' }">
          {{ $t('objects.nav.create_instructions') }}
        </router-link>
      </div>
      <div class="order-sidebar__nav-link">
        <router-link :to="{ name: 'order.search' }">
          {{ $t('objects.nav.order_patient_search') }}
        </router-link>
      </div>
      <div class="order-sidebar__nav-link">
        <router-link :to="{ name: 'order', params: { status: ORDER_STATUS.CHECKING.label } }">
          {{ $t('objects.nav.confirming_before_ordering') }}
        </router-link>
        <span>{{ number_checking }}</span>
      </div>
      <div class="order-sidebar__nav-link">
        <router-link :to="{ name: 'order', params: { status: ORDER_STATUS.SETUP_DESIGN.label } }">
          {{ $t('objects.nav.setup_design') }}
        </router-link>
        <span>{{ number_setup }}</span>
      </div>
      <div class="order-sidebar__nav-link">
        <router-link :to="{ name: 'order', params: { status: ORDER_STATUS.PREPARE.label } }">
          {{ $t('objects.nav.preparing_to_ship') }}
        </router-link>
        <span>{{ number_prepare }}</span>
      </div>
      <div class="order-sidebar__nav-link">
        <router-link :to="{ name: 'order', params: { status: ORDER_STATUS.SHIPPED.label } }">
          {{ $t('objects.nav.shipment_completed') }}
        </router-link>
      </div>
      <div class="order-sidebar__nav-link">
        <router-link :to="{ name: 'order', params: { status: ORDER_STATUS.FINISH.label } }">
          {{ $t('objects.nav.delivery_completed') }}
        </router-link>
      </div>
      <div class="order-sidebar__nav-link">
        <router-link :to="{ name: 'order', params: { status: ORDER_STATUS.CANCELLED.label } }">
          {{ $t('objects.nav.cancelled') }}
        </router-link>
      </div>
      <div
        v-if="isShowMenuInstructionsDraft"
        class="order-sidebar__nav-link"
      >
        <a href="#">{{ $t('objects.nav.instructions_for_saving_draft') }}</a>
      </div>
    </template>
  </div>
</template>

<script>
  import { ORDER_STATUS } from '../../utils/constants'

  export default {
    props: {
      isShowMenuInstructionsDraft: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        ORDER_STATUS: ORDER_STATUS,
        number_checking: 0,
        number_confirm: 0,
        number_prepare: 0,
        number_setup: 0
      }
    },
    computed: {
      isLabDesigner () {
        return this.$store.getters.isLabDesigner
      },
      isInstructionAdmin () {
        return this.$store.getters.isInstructionAdmin
      }
    },
    created () {
      this.countOrdersByStatus()
    },
    methods: {
      countOrdersByStatus () {
        const callApi = this.isLabDesigner ? 'countByStatus' : 'countByStatusByInstructionAdmin'
        this.$store.dispatch(callApi).then(result => {
          const data = result.data?.results[0] ?? {}
          this.number_checking = data.checking
          this.number_confirm = data.confirm
          this.number_prepare = data.prepare
          this.number_setup = data.setup
        })
      }
    }
  }
</script>
