import axios from 'axios'
import { state as auth } from './auth'

export const actions = {
  storeOperationAllocationReasons ({ dispatch, commit }, params = {}) {
    return axios.post('/api/operation-allocation-reasons', params, {
      headers: { Authorization: 'Bearer ' + auth.token }
    })
  }
}
