<template>
  <div :class="['modal-person-select__v2', { 'modal-person-select__v2--hide-menu': hidePositionName }]">
    <modal-action
      :title="$t('new_text.person_in_charge')"
      @close="$emit('close')"
    >
      <template #body>
        <div class="position-content">
          <div
            v-if="!hidePositionName"
            class="position-menu"
          >
            <ul>
              <li
                v-for="item in positionOrdered"
                :key="item.id"
                :class="{ 'active': active_el ? active_el === item.id : item.id === activeFirst }"
                @click="selectPerson(item.id)"
              >
                <span class="position-name">{{ item.name }}</span>
                <span
                  v-if="item.staff_count !== 0"
                  class="caret"
                />
              </li>
            </ul>
          </div>
          <div class="position-filter">
            <ul class="nav nav-tabs">
              <li
                v-for="(staff, key) in staffs"
                :key="key"
                class="nav-item"
              >
                <a
                  :class="{ 'nav-link': true, 'active': (key === filter_active), 'disabled': (getNumberStaffs(key) === 0) }"
                  data-toggle="tab"
                  :href="`#menu_${key}`"
                  @click="selectFilter(key)"
                >
                  {{ filter_chars[key] }}
                </a>
              </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content">
              <div
                v-for="(staff, index) in staffs"
                :id="`menu_${index}`"
                :key="index"
                :class="{ 'tab-pane': true , 'active': (index === filter_active) }"
              >
                <div
                  v-for="(item, keyItem) in staff"
                  :key="keyItem"
                  class="staff-block"
                  @click="updateStaffData(item)"
                >
                  <div class="name">
                    {{ item.name }}
                  </div>
                  <div class="romaji-name">
                    ({{ item.name_romaji }})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </modal-action>
  </div>
</template>
<script>
  import { safeGet } from '../../utils/helpers'
  import { FILTER_STAFF_LIST } from '../../utils/constants'
  import { orderBy } from 'lodash'
  import ModalAction from '../common/ModalAction'

  export default {
    components: {
      ModalAction
    },
    props: {
      positionData: {
        type: Object,
        default: () => {}
      },
      positionType: {
        type: Number,
        default: 0
      }
    },
    emits: ['person-data'],
    data () {
      return {
        active_el: '',
        filter_active: 'all',
        position_name: '',
        filter_chars: this.$t('objects.staff.filter_position'),
        staffs: {},
        position_data_temp: {}
      }
    },
    computed: {
      activeFirst: {
        get () {
          return Number((Object.keys(this.position_data_temp)[0]))
        },
        set (positionData) {
          const key = (this.positionType === FILTER_STAFF_LIST.IS_DENTIST.ID_FILTER ||
            this.positionType === FILTER_STAFF_LIST.COUNSELORS.ID_FILTER) ? this.positionType - 1 : 0

          const id = (Object.keys(positionData)[key])
          this.active_el = Number(id)
          this.staffs = safeGet(positionData, `${id}.staffs`)
          this.position_name = safeGet(positionData, `${id}.name`)
        }
      },
      hidePositionName () {
        return this.positionType === FILTER_STAFF_LIST.IS_DENTIST.ID_FILTER || this.positionType === FILTER_STAFF_LIST.COUNSELORS.ID_FILTER
      },
      positionOrdered () {
        return orderBy(this.positionData, ['ordering'])
      }
    },
    watch: {
      positionData (positionDataNew) {
        this.position_data_temp = positionDataNew
        this.activeFirst = positionDataNew
      }
    },
    created () {
      const idFirst = this.positionOrdered[0].id
      this.selectPerson(idFirst)
    },
    methods: {
      selectPerson: function (id) {
        this.active_el = id
        const staffs = safeGet(this.positionData, `${id}.staffs`)
        if (staffs) {
          this.position_name = safeGet(this.positionData, `${id}.name`)
          this.staffs = staffs
          this.filter_active = 'all'
        }
      },
      selectFilter: function (char) {
        this.filter_active = char
      },
      updateStaffData: function (item) {
        item.position_name = this.position_name
        this.$emit('person-data', item)
      },
      getNumberStaffs: function (key) {
        const unitStaffs = this.staffs ? this.staffs[key] : []
        if (unitStaffs) {
          return unitStaffs.length
        }
        return 0
      }
    }
  }
</script>
