var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.className] }, [
    _c("div", { staticClass: "form-group" }, [
      _c("div", { staticClass: "form-inline" }, [
        _c("p", { staticClass: "color-bold my-auto col-md-2" }, [
          _vm._v("\n        身長 :\n      ")
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.cm,
              expression: "cm"
            }
          ],
          staticClass: "form-control col-md-4",
          attrs: { type: "text", name: "" },
          domProps: { value: _vm.cm },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.cm = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("p", { staticClass: "my-auto col-md-1" }, [
          _vm._v("\n        cm\n      ")
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("div", { staticClass: "form-inline" }, [
        _c("p", { staticClass: "color-bold my-auto col-md-2" }, [
          _vm._v("\n        体重 :\n      ")
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.kg,
              expression: "kg"
            }
          ],
          staticClass: "form-control col-md-4",
          attrs: { type: "text", name: "" },
          domProps: { value: _vm.kg },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.kg = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("p", { staticClass: "my-auto col-md-1" }, [
          _vm._v("\n        kg\n      ")
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group no-flex" }, [
      _c(
        "div",
        { staticClass: "radio-group h-align form-inline inline-type-blood" },
        [
          _c("label", { staticClass: "color-bold mr-4" }, [_vm._v("血液型 :")]),
          _vm._v(" "),
          _c(
            "div",
            {
              class: ["check-container", "ml-0"],
              on: {
                click: function($event) {
                  return _vm.handleClick("A")
                }
              }
            },
            [
              _c("span", {
                class: ["common-radio-button", _vm.handleClass("A")]
              }),
              _vm._v(" "),
              _c("span", { staticClass: "label-text" }, [_vm._v("A")])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: ["check-container", "ml-0"],
              on: {
                click: function($event) {
                  return _vm.handleClick("B")
                }
              }
            },
            [
              _c("span", {
                class: ["common-radio-button", _vm.handleClass("B")]
              }),
              _vm._v(" "),
              _c("span", { staticClass: "label-text" }, [_vm._v("B")])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: ["check-container", "ml-0"],
              on: {
                click: function($event) {
                  return _vm.handleClick("O")
                }
              }
            },
            [
              _c("span", {
                class: ["common-radio-button", _vm.handleClass("O")]
              }),
              _vm._v(" "),
              _c("span", { staticClass: "label-text" }, [_vm._v("O")])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: ["check-container", "ml-0"],
              on: {
                click: function($event) {
                  return _vm.handleClick("AB")
                }
              }
            },
            [
              _c("span", {
                class: ["common-radio-button", _vm.handleClass("AB")]
              }),
              _vm._v(" "),
              _c("span", { staticClass: "label-text" }, [_vm._v("AB")])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: ["check-container", "ml-0"],
              on: {
                click: function($event) {
                  return _vm.handleClick("不明")
                }
              }
            },
            [
              _c("span", {
                class: ["common-radio-button", _vm.handleClass("不明")]
              }),
              _vm._v(" "),
              _c("span", { staticClass: "label-text" }, [_vm._v("不明")])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "break-flex-inline" }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: ["check-container", "inline-type-blood__last"],
              on: {
                click: function($event) {
                  return _vm.handleClick(_vm.INTERVIEW_OPTIONS_NOT_ENTERED)
                }
              }
            },
            [
              _c("span", {
                class: [
                  "common-radio-button",
                  _vm.handleClass(_vm.INTERVIEW_OPTIONS_NOT_ENTERED)
                ]
              }),
              _vm._v(" "),
              _c("span", { staticClass: "label-text" }, [
                _vm._v(_vm._s(_vm.INTERVIEW_OPTIONS_NOT_ENTERED))
              ])
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }