var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reservation-history" },
    _vm._l(_vm.histories, function(history) {
      return _c("ul", { key: history.id, staticClass: "reservation-list" }, [
        _c("li", { staticClass: "reservation__item" }, [
          _c("div", { staticClass: "reservation__item__allocation" }, [
            _c("label", [
              _vm._v(_vm._s(_vm.safeGet(history, "allocation.column_name")))
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("objects.reservation.text.change")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "reservation__item__date" }, [
            _c("span", { staticClass: "reservation__item__tag" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("objects.reservation.text.datetime")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "reservation__item__time" }, [
              _vm._v(_vm._s(_vm.reservationDate(history)))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "reservation__item__date-change" }, [
            _c("span", { staticClass: "reservation__item__tag" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("objects.reservation.text.datetime_change")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "reservation__item__time" }, [
              _vm._v(_vm._s(_vm.reservationHistoryCreate(history)))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "reservation__item__line" })
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }