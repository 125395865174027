<template>
  <div :class="[className]">
    <sub-question
      ref="sub_question"
      :question="question"
      :options="option_yes_no"
      :checked="select_option"
      @handleAnswer="handleAnswer($event)"
    />
    <div :class="handleChildAnswerClass">
      <div class="form-group">
        <div class="inline-input-dose">
          <div class="form-inline justify-content-between pr-5">
            <input
              v-model="name1"
              class="form-control col-md-4"
              type="text"
            >
            <span>年</span>
            <input
              v-model="name2"
              class="form-control col-md-4"
              type="text"
            >
            <span>月ごろ</span>
          </div>
          <div class="form-inline justify-content-between pt-3">
            <span>または</span>
            <input
              v-model="name3"
              class="form-control col-md-3"
              type="text"
            >
            <span>年</span>
            <input
              v-model="name4"
              class="form-control col-md-3"
              type="text"
            >
            <span>ヶ月前</span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="color-blue">治療内容：</label>
        <input
          v-model="name5"
          class="form-control"
          type="text"
          name=""
        >
      </div>
    </div>
  </div>
</template>

<script>
  import SubQuestion from '../template/SubQuestion'
  import { INTERVIEW_COMMON_OPTIONS, INTERVIEW_OPTIONS_NOT_ENTERED } from '../../../../utils/constants'
  import Common from './CommonFunction'

  export default {
    components: {
      SubQuestion
    },
    mixins: [Common],
    props: {
      className: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        number: 24,
        select_option: '',
        question: '最近、歯科医院を受診されましたか？',
        option_yes_no: [
          INTERVIEW_COMMON_OPTIONS.YES,
          INTERVIEW_COMMON_OPTIONS.NO,
          INTERVIEW_OPTIONS_NOT_ENTERED
        ],
        name1: '',
        name2: '',
        name3: '',
        name4: '',
        name5: '',
        translate: {
          受診時期1: 'name1',
          受診時期2: 'name2',
          受診時期3: 'name3',
          受診時期4: 'name4',
          治療内容: 'name5'
        }
      }
    },
    computed: {
      handleChildAnswerClass: function () {
        return {
          'disable-child-answer': this.select_option === INTERVIEW_COMMON_OPTIONS.NO
        }
      }

    }
  }
</script>
