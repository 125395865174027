var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "22",
        viewBox: "0 0 16 22",
        fill: "#333"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M-5220.5-5010a1.5,1.5,0,0,1-1.5-1.5v-16a1.5,1.5,0,0,1,1.5-1.5h2v-.75a.749.749,0,0,1,.75-.75h1.628A2.256,2.256,0,0,1-5214-5032a2.257,2.257,0,0,1,2.123,1.5h1.628a.749.749,0,0,1,.75.75v.75h2a1.5,1.5,0,0,1,1.5,1.5v16a1.5,1.5,0,0,1-1.5,1.5Zm0-1.5h13v-16h-2v.75a.751.751,0,0,1-.75.751h-7.5a.751.751,0,0,1-.75-.751v-.75h-2Zm3.5-16h6v-1.5h-1.5a.749.749,0,0,1-.75-.75.751.751,0,0,0-.751-.75.749.749,0,0,0-.75.75.749.749,0,0,1-.75.75h-1.5Zm-.467,13.746a.746.746,0,0,1-.542-.218l-.772-.772a.748.748,0,0,1,0-1.056.747.747,0,0,1,1.058,0l.257.257,1.257-1.257a.748.748,0,0,1,1.058,0,.748.748,0,0,1,0,1.056l-1.772,1.772a.747.747,0,0,1-.528.218Zm4.215-.581a.747.747,0,0,1-.747-.747.748.748,0,0,1,.747-.749h3.5a.749.749,0,0,1,.748.749.748.748,0,0,1-.748.747Zm-4.215-3.109a.746.746,0,0,1-.542-.218l-.772-.772a.748.748,0,0,1,0-1.056.747.747,0,0,1,1.058,0l.257.257,1.257-1.257a.747.747,0,0,1,1.058,0,.748.748,0,0,1,0,1.056l-1.772,1.772a.747.747,0,0,1-.528.218Zm4.215-.58a.748.748,0,0,1-.747-.748.746.746,0,0,1,.747-.747h3.5a.748.748,0,0,1,.748.747.749.749,0,0,1-.748.748Zm-4.215-3.111a.744.744,0,0,1-.542-.218l-.772-.772a.748.748,0,0,1,0-1.056.747.747,0,0,1,1.058,0l.257.257,1.257-1.257a.747.747,0,0,1,1.058,0,.748.748,0,0,1,0,1.056l-1.772,1.772a.747.747,0,0,1-.528.218Zm4.215-.58a.747.747,0,0,1-.747-.747.748.748,0,0,1,.747-.748h3.5a.749.749,0,0,1,.748.748.749.749,0,0,1-.748.747Z",
          transform: "translate(5222 5032)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }