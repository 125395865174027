<template>
  <div
    :key="key"
    class="reservation-v2"
  >
    <div class="reservation-v2__calendar">
      <calendar
        ref="calendar"
        :toggle="toggle"
        :is-users-manipulating="is_users_manipulating"
        :selected-date="selected_date"
        :is-print-memo="is_print_memo"
        @selected-date="selectedDate"
        @set-total-reservation-cancelled="setToTalReservationCancelled"
        @set-total-reservation-reserved="setToTalReservationReserved"
        @set-reservation-print-instruction="setReservationPrintInstruction"
        @set-reservation-print-technical-info="setReservationPrintTechnicalInfo"
      />
    </div>
    <div class="reservation-v2__search">
      <search
        ref="search"
        :toggle="toggle"
        :selected-date="selected_date"
        @toggle-menu="toggleMenu"
        @set-is-users-manipulating="setIsUsersManipulating"
        @refresh="refresh"
        @print-calendar="handlePrintCalendar"
        @selected-date="selectedDate"
      />
    </div>
  </div>
</template>

<script>
  import Calendar from '../../components/reservation/Calendar'
  import Search from '../../components/reservation/Search'
  import * as moment from 'moment-timezone'

  export default {
    components: {
      Calendar,
      Search
    },
    data () {
      return {
        toggle: true,
        is_users_manipulating: false,
        key: 0,
        selected_date: '',
        is_print_memo: 0
      }
    },
    created () {
      document.title = this.$t('objects.page_title.reservation').toString()
      this.selected_date = (this.$route.query.search_date && moment(this.$route.query.search_date).isValid())
        ? moment(this.$route.query.search_date).format('YYYY-MM-DD')
        : (
          (localStorage.getItem('search_date') && moment(localStorage.getItem('search_date')).isValid())
            ? localStorage.getItem('search_date')
            : moment().format('YYYY-MM-DD')
        )

      if (!localStorage.getItem('search_date')) {
        localStorage.setItem('search_date', this.selected_date)
      }

      if (!this.$route.query.search_date || !moment(this.$route.query.search_date).isValid()) {
        this.$router.replace({ name: 'dashboard', query: { search_date: this.selected_date } })
      }
    },
    methods: {
      toggleMenu () {
        this.toggle = !this.toggle
      },
      setIsUsersManipulating (value) {
        this.is_users_manipulating = value
      },
      refresh () {
        this.key += 1
      },
      selectedDate: function (date) {
        this.selected_date = date
        localStorage.setItem('search_date', date)
      },
      setToTalReservationCancelled: function (value) {
        this.$refs.search.total_reservation_cancelled = value
      },
      setToTalReservationReserved: function (value) {
        this.$refs.search.total_reservation_reserved = value
      },
      setReservationPrintInstruction (data) {
        this.$refs.search.reservation_instruction_print = data
      },
      setReservationPrintTechnicalInfo (data) {
        this.$refs.search.reservation_technical_info_print = data
      },
      handlePrintCalendar (isPrintMemo) {
        this.is_print_memo = isPrintMemo
        this.$nextTick(() => {
          this.$refs.calendar.printCalendar()
        })
      }
    }
  }
</script>
