var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { class: ["mc-modal registered", _vm.customClass] }, [
      _c("div", { staticClass: "registered__wrapper" }, [
        _c("div", { staticClass: "registered__header" }, [
          _c("h2", { staticClass: "registered__heading" }, [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.$t("objects.setting.text.staff_registration_completed")
                ) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "registered__close",
              on: {
                click: function($event) {
                  return _vm.$emit("close-modal")
                }
              }
            },
            [_c("img", { attrs: { src: "/images/close-icon-blue.svg" } })]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "registered__body" }, [
          _c("h2", { staticClass: "registered__title" }, [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.$t(
                    "objects.setting.text.staff_information_after_registered"
                  )
                ) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "registered__information" }, [
            _c("div", { staticClass: "registered__row" }, [
              _c(
                "div",
                { staticClass: "registered__row registered__row--left" },
                [
                  _c("h3", { staticClass: "registered__column-title" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("new_text.name")) +
                        "\n              "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "registered__row registered__row--right" },
                [
                  _c("p", { staticClass: "registered__text break-word" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.staffInfo.lastname +
                            " " +
                            _vm.staffInfo.firstname +
                            "(" +
                            _vm.staffInfo.lastname_roma +
                            " " +
                            _vm.staffInfo.firstname_roma +
                            ")"
                        ) +
                        "\n              "
                    )
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "registered__row" }, [
              _c(
                "div",
                { staticClass: "registered__row registered__row--left" },
                [
                  _c("h3", { staticClass: "registered__column-title" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("new_text.position")) +
                        "\n              "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "registered__row registered__row--right" },
                [
                  _c("p", { staticClass: "registered__text" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.staffInfo.position.name || "") +
                        "\n              "
                    )
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "registered__row" }, [
              _c(
                "div",
                { staticClass: "registered__row registered__row--left" },
                [
                  _c("h3", { staticClass: "registered__column-title" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("objects.setting.text.is_admin")) +
                        "\n              "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "registered__row registered__row--right" },
                [
                  _c("p", { staticClass: "registered__text" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.staffInfo.is_admin === 1
                            ? _vm.$t("new_text.button.yes")
                            : _vm.$t("new_text.button.none")
                        ) +
                        "\n              "
                    )
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "h2",
            { staticClass: "registered__title registered__title--sub" },
            [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t(
                      "objects.setting.text.information_is_displayed_only_once"
                    )
                  ) +
                  "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "registered__account" }, [
            _c("div", { staticClass: "registered__login-text" }, [
              _vm.staffInfo.send_mail
                ? _c("div", { staticClass: "registered__box" }, [
                    _c("p", { staticClass: "registered__full-size" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t("objects.setting.text.account_information")
                          ) +
                          "\n              "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t("objects.setting.text.send_email", {
                              email: _vm.staffInfo.email
                            })
                          ) +
                          "\n              "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("objects.setting.text.check_email")) +
                          "\n              "
                      )
                    ])
                  ])
                : _c("div", { staticClass: "registered__box" }, [
                    _c(
                      "div",
                      { staticClass: "registered__copy" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "registered__copy--content",
                            attrs: { id: "copy-content" }
                          },
                          [
                            _c("p", { staticClass: "registered__full-size" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "objects.setting.text.account_information"
                                    )
                                  ) +
                                  "\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "break-word" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("new_text.name")) +
                                  ": " +
                                  _vm._s(
                                    _vm.staffInfo.lastname +
                                      " " +
                                      _vm.staffInfo.firstname
                                  ) +
                                  "\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("new_text.login_id")) +
                                  ":  " +
                                  _vm._s(_vm.staffInfo.login_id)
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("objects.setting.text.password_init")
                                ) +
                                  ": " +
                                  _vm._s(_vm.staffInfo.password)
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "registered__full-size" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("new_text.login_url")) +
                                  "\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(_vm.loginUrl))])
                          ]
                        ),
                        _vm._v(" "),
                        _c("button-common", {
                          staticClass:
                            "registered__button registered__button--copy",
                          on: { click: _vm.copyToClipboard },
                          scopedSlots: _vm._u([
                            {
                              key: "content",
                              fn: function() {
                                return [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "objects.setting.button.copy_to_clipboard"
                                        )
                                      ) +
                                      "\n                  "
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "registered__footer registered__footer--center" },
          [
            _c("button-common", {
              staticClass: "registered__button registered__button--close",
              on: {
                click: function($event) {
                  return _vm.$emit("close-modal")
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("new_text.button.close")) +
                          "\n          "
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }