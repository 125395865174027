export const state = {
  isReload: false
}

export const mutations = {
  SET_IS_RELOAD: (state) => {
    state.isReload = !state.isReload
  }
}

export const actions = {
  handleReloadAction ({ commit }) {
    commit('SET_IS_RELOAD')
  }
}
