import * as firebase from 'firebase/app'
import 'firebase/messaging'

const config = {
  apiKey: 'AIzaSyBGKC-lAQxpVNV5OMpMhByGokfCazFrLkQ',
  authDomain: 'medusa-d4e3b.firebaseapp.com',
  databaseURL: 'https://medusa-d4e3b.firebaseio.com',
  projectId: 'medusa-d4e3b',
  storageBucket: 'medusa-d4e3b.appspot.com',
  messagingSenderId: '1061934551998',
  appId: '1:1061934551998:web:996e4ddab48ca122ce1fef',
  measurementId: 'G-Y6J3QW0Z2H'
}

firebase.initializeApp(config)

export default firebase
