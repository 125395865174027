<template>
  <div
    :class="[
      'patient-v2',
      {
        'patient-v2--order': isPatientOrder,
        'patient-v2--order-detail': isOrderDetailPage
      }
    ]"
  >
    <div
      v-if="!isOnlyOrderDetail"
      class="patient-v2__header"
    >
      <div class="patient-v2__header__left">
        <div class="patient-v2__header__info patient-v2__header__info--name">
          <p class="patient-v2__header__name">
            {{ patientKanaName }}
          </p>
          <p class="patient-v2__header__age">
            <span>{{ patientFullName }}</span>{{ age }}
          </p>
        </div>
        <div class="patient-v2__header__info patient-v2__header__info--id">
          <div class="patient-v2__header__center">
            <p class="patient-v2__header__label">
              {{ $t('objects.patient.text.patient_id') }}
            </p>
            <p>
              {{ intVal(patient.sf_user_id) || $t('objects.patient.text.please_reload_while_getting_id') }}
            </p>
            <p
              v-if="is_clinics_dmr === '1' || is_clinics_dmr === 1"
              class="patient-v2__header__button"
            >
              {{
                $route.name === 'patient.histories'
                  ? $t('new_text.accept')
                  : $t('objects.patient.text.patient_status_' + currentStatus)
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="patient-v2__header__right">
        <div
          v-if="isShowAction"
          class="patient-v2__header__action"
        >
          <button-common
            class="button-v2--secondary button-v2--120"
            @click="$router.push({ name: 'dashboard' })"
          >
            <template #content>
              {{ $t('objects.patient.text.new_reservation') }}
            </template>
          </button-common>
          <button-common
            v-if="is_clinics_dmr === '1' || is_clinics_dmr === 1"
            class="button-v2--secondary button-v2--120"
            @click="
              $router.push(
                {
                  name: 'patient.accounting.edit',
                  params: { id: patient_id, aid: 'nodmr' },
                  query: {reservation_id}
                }
              )
            "
          >
            <template #content>
              {{ $t('objects.patient.text.accounting_creation') }}
            </template>
          </button-common>
          <button-common
            v-if="is_clinics_dmr === '1' || is_clinics_dmr === 1"
            class="button-v2--primary button-v2--120"
            @click="getLastDraftId"
          >
            <template #content>
              {{ $t('objects.patient.text.medical_record_creation') }}
            </template>
          </button-common>
        </div>
      </div>
    </div>
    <div :class="['patient-v2__body', { 'patient-v2__body--smart-phone': isSmartPhone }]">
      <side-bar
        v-if="!isOnlyOrderDetail && !isSmartPhone"
        :is-hide-side-bar="is_hide_side_bar"
        :patient-id="patient_id"
        :reservation-id="reservation_id"
        :is-clinics-dmr="is_clinics_dmr"
        :is-interview="isInterview"
      />
      <div class="patient-v2__content">
        <router-view />
      </div>
    </div>
    <div
      v-if="!isOnlyOrderDetail && !isSmartPhone"
      :class="['patient-v2__sidebar__toggle', { 'patient-v2__sidebar__toggle--hide' : is_hide_side_bar }]"
      src="/images/icon-toggle-left-sidebar.svg"
      @click="is_hide_side_bar = !is_hide_side_bar"
    />
  </div>
</template>

<script>
  import * as moment from 'moment'
  import { calculateAge, intVal, medicalRecordInNewTab } from '../../utils/helpers'
  import SideBar from '../../components/patient/v2/SideBar'
  import ButtonCommon from '../../components/common/Button'
  import OrderDetail from '../../mixins/order-detail'
  import { isEmpty, get, filter } from 'lodash'
  import { LAYOUT_MODE, SCREEN_WIDTH_SMALL } from '../../utils/constants'

  export default {
    components: {
      SideBar,
      ButtonCommon
    },
    mixins: [OrderDetail],
    data () {
      return {
        reservation_id: '',
        patient_id: '',
        show_sms_sending_modal: false,
        is_clinics_dmr: false,
        path_name_accept_deleted: ['patients.fee', 'patient.accounting.detail'],
        is_hide_side_bar: false
      }
    },
    computed: {
      isInterview: function () {
        const currentUser = this.$store.getters.currentUser

        if (currentUser && currentUser.clinic) {
          return currentUser.clinic.is_interview
        }

        return 0
      },
      isInterviewCompleted: function () {
        return this.$store.getters.get_patientInterviewCompleted
      },
      currentStatus: function () {
        return this.$store.getters.get_reservation.current_status
      },
      acceptDeleted: function () {
        return Number(this.path_name_accept_deleted.includes(this.$route.name))
      },
      patient: function () {
        return this.$store.getters.get_patient
      },
      patientInfo: function () {
        return this.patient.info
      },
      patientKanaName: function () {
        return `${this.patientInfo.kana_last_name} ${this.patientInfo.kana_first_name}`
      },
      patientFullName: function () {
        return this.patientInfo.last_name.length === 0 || this.patientInfo.first_name.length === 0
          ? this.patientInfo.name
          : `${this.patientInfo.last_name} ${this.patientInfo.first_name}`
      },
      age: function () {
        const birthOfDate = this.patientInfo.birth_of_date

        return moment(birthOfDate).isValid() ? `（${calculateAge(birthOfDate)}）` : ''
      },
      isShowAction: function () {
        return this.$route.name === 'patient.detail' ||
          this.$route.name === 'patient.activity.log' ||
          this.$route.name === 'patient.history' ||
          this.$route.name === 'patient.questionnaire'
      },
      isOrderDetailPage () {
        return this.$route.name === 'patient.orders.detail'
      },
      isPatientOrder () {
        return this.$route.name === 'patient.orders'
      },
      isPatientInterview () {
        return this.$route.name === 'patient.interview'
      },
      isSmartPhone () {
        const currentLayoutMode = parseInt(this.$store.state.auth.layout_mode)

        return currentLayoutMode === LAYOUT_MODE.SP || (!currentLayoutMode && window.innerWidth <= SCREEN_WIDTH_SMALL)
      }
    },
    created () {
      this.reservation_id = this.$route.query.reservation_id
      this.patient_id = this.$route.params.id
      this.getPatientData()
      if (localStorage.getItem('is_clinics_dmr')) {
        this.is_clinics_dmr = localStorage.getItem('is_clinics_dmr')
      }
    },
    methods: {
      getPatientData () {
        if (this.patient_id !== this.$store.getters.get_patient.id) {
          this.$store.commit('reset_patientState')
          this.$store.commit('set_loading', true)
          this.$store.dispatch('getPatientDetail', {
            id: this.patient_id,
            reservation_id: this.reservation_id,
            accept_deleted: this.acceptDeleted
          }).then((res) => {
            const reservationList = get(res, 'data.results.reservationList') || []
            const reservedReservations = filter(reservationList, { status: 'reserved' })
            this.$store.commit('set_loading', false)

            if (this.isPatientInterview && isEmpty(reservedReservations)) {
              window.location.href = this.$router.resolve({
                name: 'patient.detail',
                params: { id: this.patient_id }
              }).href
            }

            if (!this.$store.getters.get_patient.id) {
              this.$router.push({ name: 'not.found' })
            }
          }).finally(() => {
            if (this.$route.name !== 'patient.orders.detail') {
              this.$store.commit('set_loading', false)
            }
          })
        }
      },
      intVal (number) {
        return intVal(number)
      },
      getLastDraftId () {
        this.$store.dispatch('getLastDraftId', this.patient_id)
          .then((draftId) => {
            let routeData

            if (draftId) {
              routeData = this.$router.resolve({
                name: 'medical.edit',
                params: { id: this.patient_id, mid: draftId },
                query: { reservation_id: this.reservation_id }
              })
            } else {
              routeData = this.$router.resolve({
                name: 'medical.registration',
                params: { id: this.patient_id },
                query: { reservation_id: this.reservation_id }
              })
            }

            medicalRecordInNewTab(routeData.href)
          })
      }
    }
  }
</script>
