import SweetAlert from 'sweetalert2'
import { findKey } from 'lodash'
import { BRANDS } from './constants'

export function safeGet (object, keys) {
  return keys.split('.').reduce((xs, x) => (xs && xs[x]) ? xs[x] : undefined, object) || ''
}

export function checkValidEmail (email) {
  return /^\w+([.+-]+?\w+)*@\w+([.+-]+?\w+)*(\.\w{2,})+$/.test(email)
}

export function showMessageModal (option) {
  SweetAlert.fire({
    ...{
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 3000,
      width: '400px',
      customClass: {
        container: 'med-message'
      },
      onBeforeOpen: () => {
        document.getElementsByClassName('swal2-top')[0].style.top = '85px'
      }
    },
    ...option
  })
}

export function showSuccessModal (message, option = {}) {
  showMessageModal(
    {
      title: message,
      imageUrl: '/images/icon-success.svg',
      imageWidth: 40,
      imageHeight: 40,
      customClass: {
        container: 'med-message',
        popup: 'med-message__popup med-message__popup--success',
        title: 'med-message__title'
      },
      ...option
    }
  )
}

export function showErrorModal (message, option = {}) {
  showMessageModal(
    {
      title: message,
      imageUrl: '/images/icon-error2.svg',
      imageWidth: 29,
      imageHeight: 27,
      customClass: {
        container: 'med-message',
        popup: 'med-message__popup med-message__popup--error',
        title: 'med-message__title'
      },
      ...option
    }
  )
}

export function mbtrim (string) {
  return string.replace(/(^\s+|\s+$)/g, '')
}

export function isNumeric (obj) {
  return (typeof obj === 'number' || typeof obj === 'string') &&
    !isNaN(obj - parseFloat(obj))
}

export function showWarningModal (message, option = {}) {
  SweetAlert.fire({
    ...{
      toast: true,
      customClass: 'toast-warning',
      position: 'top',
      title: `<div class="warning-icon"></div><div class="swal-title-text">${message}</div>`,
      showConfirmButton: false,
      timer: 3000,
      onBeforeOpen: () => {
        document.getElementsByClassName('swal2-top')[0].style.top = '85px'
      }
    },
    ...option
  })
}

export function checkValidIpAddress (ip) {
  const ipRegx = /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/

  return ipRegx.test(ip)
}

export function findBrandByTag (tag) {
  const key = findKey(BRANDS, ['tag', tag])
  if (!key) {
    return null
  }
  return BRANDS[key]
}
