import axios from 'axios'
import { state } from './auth'

export const actions = {
  getAccountList ({ dispatch, commit }, params) {
    return axios.get('/api/setting/account', {
      params,
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getStreatmentList ({ dispatch, commit }, { filter_keyword, page }) {
    return axios.get('/api/setting/action', {
      params: {
        filter_keyword: filter_keyword,
        page: page
      },
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  getServiceList ({ dispatch, commit }, { filter_keyword, page }) {
    return axios.get('/api/setting/service', {
      params: {
        filter_keyword: filter_keyword,
        page: page
      },
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  deleteStaff ({ dispatch, commit }, staffId) {
    return axios.post('/api/staff/delete', { staffId: staffId },
      {
        headers: { Authorization: 'Bearer ' + state.token }
      }
    )
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  }
}
