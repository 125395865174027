<template>
  <div class="checkbox-v2">
    <input
      :id="generateId"
      v-model="checked"
      type="checkbox"
      :value="val"
      :name="name"
      :disabled="disabled"
      @change="onChange"
    >
    <label :for="generateId">
      <span>{{ label }}</span>
    </label>
  </div>
</template><script>
  export default {
    props: {
      isChecked: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      val: {
        type: [String, Number],
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        checkedProxy: false
      }
    },
    computed: {
      checked: {
        get () {
          return this.isChecked
        },
        set (val) {
          this.checkedProxy = val
        }
      },
      generateId () {
        return Math.random().toString(36).substring(7)
      }
    },
    methods: {
      onChange: function () {
        this.$emit('input', this.checkedProxy)
      }
    }
  }
</script>
