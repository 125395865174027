var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "white-list" },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "content-wrapper" }, [
            _c("div", { staticClass: "white-list__header" }, [
              _c("h2", { staticClass: "title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("objects.setting.text.whitelist_setting")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "white-list__btn-add-ip-address",
                  on: { click: _vm.showMyIpAddress }
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("objects.setting.text.add_ip_address"))
                    )
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "common-list common-list--white-list" }, [
              _c("table", { staticClass: "main-list main-list__search" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("new_text.status")) +
                          "\n              "
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("objects.setting.text.ip")) +
                          "\n              "
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("new_text.label")) +
                          "\n              "
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t("objects.setting.text.staff_id_reqired")
                          ) +
                          "\n              "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _vm.white_list.length > 0
                  ? _c(
                      "tbody",
                      _vm._l(_vm.white_list, function(item) {
                        return _c(
                          "tr",
                          {
                            key: item.id,
                            on: {
                              click: function($event) {
                                return _vm.handleDetailSetting(item)
                              }
                            }
                          },
                          [
                            _c("td", [
                              _vm._v(_vm._s(_vm.labelStatus(item.status)))
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.ip_address))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.label))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(item.staff.name) +
                                  "（" +
                                  _vm._s(item.staff.login_id) +
                                  ")"
                              )
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  : _c("tbody", { staticClass: "common-list__not-found" }, [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "5" } }, [
                          _c(
                            "p",
                            { staticClass: "common-list__label-not-found" },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$t("new_message.no_result_search")
                                  ) +
                                  "\n                "
                              )
                            ]
                          )
                        ])
                      ])
                    ])
              ]),
              _vm._v(" "),
              _vm.white_list.length > 0
                ? _c(
                    "div",
                    { staticClass: "common-list__pagination" },
                    [
                      _vm.pagination.last_page > 1
                        ? _c("pagination-common", {
                            staticClass: "pagination-common--pagination",
                            attrs: {
                              "current-page": _vm.pagination.current_page,
                              "last-page": _vm.pagination.last_page
                            },
                            on: { "change-page": _vm.changePage }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _vm.is_show_modal_create_whitelist
            ? _c("modal-create-setting-ip", {
                attrs: { "user-info": _vm.user_info },
                on: {
                  close: function($event) {
                    _vm.is_show_modal_create_whitelist = false
                  },
                  succeed: _vm.handleSucceedWhiteList
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.is_show_modal_update_whitelist
            ? _c("modal-update-setting-ip", {
                attrs: { "ip-data": _vm.ip_selected },
                on: {
                  close: function($event) {
                    _vm.is_show_modal_update_whitelist = false
                  },
                  succeed: _vm.handleUpdateSucceedWhiteList
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("Loading")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }