var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("logo", [
    _c("div", { staticClass: "d-flex justify-content-center send-email" }, [
      _c("p", [
        _vm._v("\n      " + _vm._s(_vm.$t("email_forgot_password.text_1"))),
        _c("br"),
        _vm._v("\n      " + _vm._s(_vm.$t("email_forgot_password.text_2"))),
        _c("br"),
        _vm._v(
          "\n      " + _vm._s(_vm.$t("email_forgot_password.text_3")) + "\n    "
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }