var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("modal-action", {
        class: [
          "modal-v2--reservation-print-instruction",
          {
            "modal-v2--reservation-print-instruction__disabled-confirm":
              _vm.reservation_ids_selected.length === 0
          }
        ],
        attrs: {
          title: _vm.$t(
            "objects.reservation.text.reservation_print_instruction_title"
          ),
          "is-show-cancel-button": true
        },
        on: { close: _vm.closeModal, confirm: _vm.downLoadMultipleExamination },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "reservation-print-instruction-modal" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "reservation-print-instruction-modal__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "reservation-print-instruction-modal__total"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "reservation-print-instruction-modal__total__text"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "objects.reservation.text.schedule_visit"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", [
                              _c("span", [
                                _vm._v(_vm._s(_vm.number_reservation))
                              ]),
                              _vm._v(
                                _vm._s(_vm.$t("new_text.first_name2")) +
                                  "\n            "
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "reservation-print-instruction-modal__checkbox"
                          },
                          [
                            _c("checkbox-common", {
                              attrs: {
                                name: "is_show_re_examination",
                                "is-checked": _vm.is_show_re_examination === 1,
                                label: _vm.$t(
                                  "objects.reservation.text.return_patient"
                                )
                              },
                              model: {
                                value: _vm.is_show_re_examination,
                                callback: function($$v) {
                                  _vm.is_show_re_examination = $$v
                                },
                                expression: "is_show_re_examination"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "table",
                      {
                        staticClass:
                          "reservation-print-instruction-modal__table"
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "table__reservation-select-wrapper"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "table__reservation-select" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.selectAll,
                                          expression: "selectAll"
                                        }
                                      ],
                                      staticClass:
                                        "table__reservation-checkbox",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(_vm.selectAll)
                                          ? _vm._i(_vm.selectAll, null) > -1
                                          : _vm.selectAll
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = _vm.selectAll,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.selectAll = $$a.concat([
                                                  $$v
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.selectAll = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.selectAll = $$c
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass:
                                        "table__reservation-checkbox table__reservation-checkbox--background"
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("th", [_vm._v(_vm._s(_vm.$t("new_text.id")))]),
                            _vm._v(" "),
                            _c("th", [_vm._v(_vm._s(_vm.$t("new_text.name")))]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("new_text.age_title")))
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "objects.reservation.text.reservation_type"
                                  )
                                )
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.getList, function(reservation, index) {
                            return _c(
                              "tr",
                              {
                                key: index,
                                on: {
                                  click: function($event) {
                                    return _vm.gotoReservationCalendar(
                                      reservation.started_to,
                                      reservation.id,
                                      $event
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "table__reservation-select-wrapper"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "table__reservation-select"
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.reservation_ids_selected,
                                              expression:
                                                "reservation_ids_selected"
                                            }
                                          ],
                                          staticClass:
                                            "table__reservation-checkbox",
                                          attrs: {
                                            id: reservation.id,
                                            type: "checkbox"
                                          },
                                          domProps: {
                                            value: reservation.id,
                                            checked: Array.isArray(
                                              _vm.reservation_ids_selected
                                            )
                                              ? _vm._i(
                                                  _vm.reservation_ids_selected,
                                                  reservation.id
                                                ) > -1
                                              : _vm.reservation_ids_selected
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a =
                                                  _vm.reservation_ids_selected,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = reservation.id,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.reservation_ids_selected = $$a.concat(
                                                      [$$v]
                                                    ))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.reservation_ids_selected = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.reservation_ids_selected = $$c
                                              }
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass:
                                            "table__reservation-checkbox table__reservation-checkbox--background table__reservation-checkbox--one"
                                        })
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(reservation.sf_user_id))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "reservation-print-instruction-modal__table__name"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          (reservation.name || "") +
                                            " (" +
                                            (reservation.kana_last_name || "") +
                                            " " +
                                            (reservation.kana_first_name ||
                                              "") +
                                            ")"
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getAge(reservation.birth_of_date)
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getReservationType(reservation.duties)
                                    )
                                  )
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "confirm_button_content",
            fn: function() {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("objects.reservation.text.print_template", {
                        num: _vm.reservation_ids_selected.length
                      })
                    ) +
                    "\n    "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }