<template>
  <div class="popover show modal-confirm-reservation-change">
    <div class="arrow" />
    <div class="modal-confirm-reservation-change__overplay" />
    <h3 class="popover-title modal-confirm-reservation-change__title" />
    <div class="modal-confirm-reservation-change__time-range">
      <div class="modal-confirm-reservation-change__time-change">
        {{ startDate }} 〜 {{ endDate }}
      </div>
    </div>
    <div class="modal-confirm-reservation-change__footer">
      <button-common
        data-dismiss="confirmation"
        class="btn-cancel"
      />
      <button-common
        data-apply="confirmation"
        class="btn-confirm"
      />
    </div>
  </div>
</template>

<script>
  import Button from '../common/Button'

  export default {
    components: {
      ButtonCommon: Button
    },
    props: {
      startDate: {
        type: String,
        default: ''
      },
      endDate: {
        type: String,
        default: ''
      }
    }
  }
</script>
