import axios from 'axios'
import { state } from './auth'

export const actions = {
  getQuestionnaireAnalysis ({ dispatch, commit }, params) {
    return axios.get('/api/questionnaires/analytic', {
      params,
      headers: { Authorization: 'Bearer ' + state.token }
    })
  },

  getListQuestionnaire ({ commit }, params) {
    return axios.get('/api/questionnaires', {
      params,
      headers: { Authorization: 'Bearer ' + state.token }
    })
  },

  getReservationQuestionnaires ({ dispatch, commit }, params) {
    return axios.get('/api/reservation/questionnaires', {
      params,
      headers: { Authorization: 'Bearer ' + state.token }
    })
  }
}
