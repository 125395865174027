export default {
  button: {
    add_item: '予約種別を追加する',
    add_tab: '追加する',
    copy_to_clipboard: 'クリップボードにコピーする',
    create_staff: '新規スタッフの追加',
    edit_tab: '現在表示しているタブを編集',
    please_select: '選択してください'
  },
  message: {
    save_edit: '編集内容を保存しますか?',
    save_reset_password: '変更内容を保存してパスワード再設定メールを送信しますか？'
  },
  placeholder: {
    other_staff: 'その他スタッフ'
  },
  text: {
    account_information: '【アカウント情報】',
    account_setting: 'アカウント設定',
    add_ip_address: 'IPアドレスの追加',
    allow_accept_ip_address: 'このIPアドレスからのアクセスを許可する',
    automatic_setting: '自動更新機能',
    block_time: 'ブロック時間',
    business_days_setting: '営業日設定',
    business_hours: '営業時間',
    check_email: 'メールボックスを確認するよう連絡ください。コピーしてお伝えください',
    clinic_information: 'クリニック情報',
    clinic_name: 'クリニック名',
    clinic_name_note: 'クリニック名は領収書クリニック情報に反映されます。 \n キレイライン公式ホームページ表記には影響ありません。',
    clinic_setting: 'クリニック設定',
    confirm_save_no_business_day_set: '営業日が設定されていません\nこのまま保存してもよろしいですか？',
    contact_information: '連絡先情報',
    create_ip_setting: '新規IPアドレスの追加',
    daily_report: '売上日報',
    del_date: '削除日時',
    delete_cancellation: '削除取消',
    delete_it: '本当に削除しますか？',
    deleted_patient: '削除済患者情報',
    destination_email: '送信先',
    destination_email_note: 'カンマ区切りで複数入力可能です',
    edit_information: 'スタッフ情報の編集',
    edit_tab: 'タブの編集',
    email_address_optional: 'メールアドレス\n(任意)',
    enter_ip_address: '自分のIPアドレスを入力する',
    every_minute: '分毎',
    illegal_input: '不正な入力です',
    information_is_displayed_only_once: '以下のログイン情報は一度きりのみの表示です。\n コピーしてお伝えください。',
    invalid_input: '時刻のフォーマットが正しくありません',
    ip: 'IPアドレス',
    ip_address: 'IPアドレス',
    ip_exist: 'IPアドレスは既に存在します',
    ip_setting: 'IPアドレス制限',
    is_admin: '管理者権限',
    job_title: '職務名',
    job_master_setting: '職務マスタ設定',
    label_placeholder: 'クリニック内ネットワークIP',
    long_term_leave_setting: '長期休業設定',
    no_business_day_set: '営業日が設定されていません',
    not_found: 'スタッフ情報が見つかりませんでした',
    note_cancel_delete: '患者情報の削除を取り消しますか？',
    notification_setting: '予約情報自動通知する',
    overlapped_block_time: '指定の時間帯にブロック枠を含んでいます',
    password_init: '初期パスワード',
    password_min: '半角英数字8文字以上で入力してください',
    periodic_block_setting: '定期ブロック設定',
    periodic_require_day_of_week: '曜日を選択してください',
    periodic_require_week: '0〜5で週を入力してください',
    registration_date: '登録日',
    reload_setting: '予約ダッシュボード自動更新する',
    request_staff_id: 'リクエストスタッフID',
    required_time: '所要時間',
    resend_reset_password_success: 'パスワード再設定メールを送信しました',
    reservation_date_setting: '予約日時設定',
    reservation_duplicate_time_zone: '時間帯設定が重複しています',
    reservation_type_edit: '予約種別編集',
    reservation_type_registration: '予約種別登録',
    reservation_type_master_setting: '予約種別マスタ設定',
    reservation_type_setting: '予約種別設定',
    reset_password: 'パスワード再設定メール送信',
    search_by_id: 'スタッフ名・IDで検索',
    send_email: '招待メールを送信する',
    send_report: '売上日報を送信する',
    set_weekly: '0を入力すると、毎週の設定となります',
    setting: '設定',
    setting_print_calendar: '来院予定印刷設定',
    special_date_setting: '特別営業日設定',
    species_alias: '種別名',
    staff_id: 'スタッフID',
    staff_id_reqired: 'リクエストスタッフID',
    staff_id_login_rule: '半角英数字および記号(@.のみ利用可能)',
    staff_information: 'スタッフ情報',
    staff_information_after_registered: '以下のスタッフの登録が完了しました',
    staff_name: 'スタッフ名',
    tab_name: 'タブ名',
    staff_registration_completed: '新規スタッフ登録完了',
    take_a_position: '担当職務',
    transmission_time: '送信時刻',
    undo_deletion: '削除を取り消す',
    undo_deletion_patient_exist_error: '同じメールアドレスと電話番号を使用している患者情報が存在しているため削除の取り消しが行なえません。\n削除の取り消しが必要な場合はメールアドレスと電話番号が重複している患者情報を変更してください。',
    whitelist_setting: 'IPアドレス制限',
    yes: '有'
  }
}
