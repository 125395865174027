var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-v2 setting-v2--background" },
    [
      _c("div", { staticClass: "setting-v2__content" }, [
        _c("div", { staticClass: "setting-v2__header" }, [
          _c("div", { staticClass: "setting-v2__header__box" }, [
            _c("div", { staticClass: "setting-v2__header__box__text" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("objects.setting.text.clinic_setting")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "setting-v2__header__box__button" }, [
              _c("button", { on: { click: _vm.actionUpdate } }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("new_text.button.save")) +
                    "\n          "
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "setting-v2__main" }, [
          _c("div", { staticClass: "setting-clinic__item" }, [
            _c("div", { staticClass: "setting-clinic__item__title" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("objects.setting.text.clinic_information"))
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "setting-clinic__item__line" }),
            _vm._v(" "),
            _c("div", { staticClass: "setting-clinic__item__name" }, [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("objects.setting.text.clinic_name")))
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.formal_name))])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "setting-clinic__item__note" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("objects.setting.text.clinic_name_note")))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "setting-clinic__item" }, [
            _c("div", { staticClass: "setting-clinic__item__title" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("objects.setting.text.contact_information"))
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "setting-clinic__item__line" }),
            _vm._v(" "),
            _c("div", { staticClass: "setting-clinic__item__group" }, [
              _c("div", { staticClass: "setting-clinic__item__info" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("new_text.email")))]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("common-input", {
                      class: { "common-input-error": _vm.errors.email },
                      attrs: { maxlength: "80", "default-value": _vm.email },
                      on: { input: _vm.handleMailChange },
                      model: {
                        value: _vm.email,
                        callback: function($$v) {
                          _vm.email = $$v
                        },
                        expression: "email"
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.email
                      ? _c("span", { staticClass: "error common-msg-error" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.email) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "setting-clinic__item__info" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("new_text.phone_number")))]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("common-input", {
                      class: { "common-input-error": _vm.errors.phone },
                      attrs: { maxlength: "11", "default-value": _vm.phone },
                      on: { input: _vm.handlePhoneChange },
                      model: {
                        value: _vm.phone,
                        callback: function($$v) {
                          _vm.phone = $$v
                        },
                        expression: "phone"
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.phone
                      ? _c("span", { staticClass: "error common-msg-error" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.phone) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "setting-clinic__item" }, [
            _c("div", { staticClass: "setting-clinic__item__title" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("objects.setting.text.automatic_setting")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "setting-clinic__item__line" }),
            _vm._v(" "),
            _c("div", { staticClass: "setting-clinic__item__group" }, [
              _c(
                "div",
                { staticClass: "setting-clinic__item__info" },
                [
                  _c("common-checkbox", {
                    staticClass: "setting-clinic__item__checkbox",
                    attrs: { "is-checked": _vm.is_reload === 1, value: true },
                    on: { input: _vm.chooseReloadingCycle }
                  }),
                  _vm._v(" "),
                  _c("label", { staticClass: "setting-clinic__item__auto" }, [
                    _vm._v(
                      _vm._s(_vm.$t("objects.setting.text.reload_setting"))
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "setting-clinic__select-reloading-cycle" },
                    [
                      _c("common-select", {
                        class: [
                          "common-select",
                          { "common-input-error": !_vm.isReloadingCycle }
                        ],
                        attrs: {
                          disabled: !_vm.is_reload,
                          "default-value": _vm.reloading_cycle,
                          options: _vm.minutes,
                          "key-value": "value",
                          "key-display": "text"
                        },
                        on: {
                          change: function(value) {
                            return (_vm.reloading_cycle = value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("objects.setting.text.every_minute"))
                        )
                      ]),
                      _vm._v(" "),
                      !_vm.isReloadingCycle
                        ? _c("span", { staticClass: "common-msg-error" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t("new_validation.common.required", {
                                    attribute: _vm.$t("new_text.time_required")
                                  })
                                ) +
                                "\n              "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "setting-clinic__item__info" },
                [
                  _c("common-checkbox", {
                    staticClass: "setting-clinic__item__checkbox",
                    attrs: { "is-checked": _vm.is_notify === 1, value: true },
                    on: { input: _vm.chooseNotificationCycle }
                  }),
                  _vm._v(" "),
                  _c("label", { staticClass: "setting-clinic__item__auto" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("objects.setting.text.notification_setting")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("common-select", {
                    attrs: {
                      disabled: !_vm.is_notify,
                      "default-value": _vm.notification_cycle,
                      options: _vm.minutes,
                      "key-value": "value",
                      "key-display": "text"
                    },
                    on: {
                      change: function(value) {
                        return (_vm.notification_cycle = value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("objects.setting.text.every_minute")))
                  ])
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "setting-clinic__item" }, [
            _c("div", { staticClass: "setting-clinic__item__title" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("objects.setting.text.setting_print_calendar"))
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "setting-clinic__item__line" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "setting-clinic__h-align" },
              [
                _c("radio-common", {
                  staticClass: "setting-clinic__h-align--right",
                  attrs: {
                    name: "is_print_with_memo",
                    val: 1,
                    label: _vm.$t("objects.reservation.text.print_calendar"),
                    "is-checked": _vm.is_print_with_memo === 1
                  },
                  on: { select: _vm.handleSelected }
                }),
                _vm._v(" "),
                _c("radio-common", {
                  attrs: {
                    name: "is_print_with_memo",
                    val: 0,
                    label: _vm.$t("objects.reservation.text.no_print_calendar"),
                    "is-checked": _vm.is_print_with_memo === 0
                  },
                  on: { select: _vm.handleSelected }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          false
            ? _c("div", { staticClass: "setting-clinic__item" }, [
                _c("div", { staticClass: "setting-clinic__item__title" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("objects.setting.text.daily_report")))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "setting-clinic__item__line" }),
                _vm._v(" "),
                _c("div", { staticClass: "setting-clinic__item__group" }, [
                  _c(
                    "div",
                    { staticClass: "setting-clinic__item__info" },
                    [
                      _c("common-checkbox", {
                        staticClass: "setting-clinic__item__checkbox",
                        attrs: {
                          "is-checked": _vm.revenue_status === 1,
                          value: true
                        },
                        on: {
                          input: function(value) {
                            return (_vm.revenue_status = value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("objects.setting.text.send_report"))
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "setting-clinic__item__report" }, [
                    _c(
                      "div",
                      { staticClass: "setting-clinic__item__info" },
                      [
                        _c("label", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("objects.setting.text.transmission_time")
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("common-select", {
                          attrs: {
                            "default-value": _vm.revenue_time,
                            options: _vm.renderHours,
                            "key-value": "value",
                            "key-display": "text"
                          },
                          on: {
                            change: function(value) {
                              return (_vm.revenue_time = value)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "setting-clinic__item__info" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("objects.setting.text.destination_email")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("common-input", {
                            attrs: {
                              "default-value": _vm.revenue_mails,
                              maxlength: "80"
                            },
                            on: {
                              input: function($event) {
                                return _vm.validation("revenue_mails")
                              }
                            },
                            model: {
                              value: _vm.revenue_mails,
                              callback: function($$v) {
                                _vm.revenue_mails = $$v
                              },
                              expression: "revenue_mails"
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.revenue_mails
                            ? _c("p", { staticClass: "error" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.errors.revenue_mails) +
                                    "\n                "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "objects.setting.text.destination_email_note"
                                )
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("Loading"),
      _vm._v(" "),
      _vm.is_show_modal_confirm
        ? _c("modal-action", {
            staticClass: "modal-confirm modal-v2--confirm-update",
            attrs: {
              title: _vm.$t("objects.setting.message.save_edit"),
              "is-show-close-button": false
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _c("button-common", {
                        staticClass: "button-v2--close",
                        on: {
                          click: function($event) {
                            _vm.is_show_modal_confirm = false
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function() {
                                return [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("new_text.button.no")) +
                                      "\n        "
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          605777601
                        )
                      }),
                      _vm._v(" "),
                      _c("button-common", {
                        staticClass: "button-v2--confirm",
                        on: { click: _vm.updateClinicSetting },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function() {
                                return [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("new_text.button.yes_1")) +
                                      "\n        "
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          326476865
                        )
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2741184481
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }