var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "patient-detail__information" }, [
    _c("div", { staticClass: "patient-detail__information__wrap" }, [
      _c(
        "div",
        {
          staticClass:
            "patient-detail__information__title patient-detail__information__title--line-bottom"
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("objects.patient.text.basic_info")) +
              "\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "patient-detail__information__group" }, [
        _c(
          "div",
          {
            staticClass:
              "patient-detail__information__row patient-detail__information__row--half"
          },
          [
            _c("label", { staticClass: "patient-detail__information__label" }, [
              _vm._v(_vm._s(_vm.$t("new_text.name")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "patient-detail__information__data" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.patientFullName) + "\n        "
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "patient-detail__information__row patient-detail__information__row--half"
          },
          [
            _c("label", { staticClass: "patient-detail__information__label" }, [
              _vm._v(_vm._s(_vm.$t("new_text.hiragana")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "patient-detail__information__data" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.patientKanaName) + "\n        "
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "patient-detail__information__row patient-detail__information__row--half"
          },
          [
            _c("label", { staticClass: "patient-detail__information__label" }, [
              _vm._v(_vm._s(_vm.$t("new_text.roman")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "patient-detail__information__data" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.patientRomaName) + "\n        "
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "patient-detail__information__row patient-detail__information__row--half"
          },
          [
            _c("label", { staticClass: "patient-detail__information__label" }, [
              _vm._v(_vm._s(_vm.$t("new_text.sex")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "patient-detail__information__data" }, [
              _vm._v("\n          " + _vm._s(_vm.sex) + "\n        ")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "patient-detail__information__row patient-detail__information__row--half"
          },
          [
            _c("label", { staticClass: "patient-detail__information__label" }, [
              _vm._v(_vm._s(_vm.$t("new_text.birthday")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "patient-detail__information__data" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.birthDate) +
                  _vm._s(_vm.renderAge(_vm.patientInfo.birth_of_date)) +
                  "\n        "
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "patient-detail__information__row patient-detail__information__row--half"
          },
          [
            _c("label", { staticClass: "patient-detail__information__label" }, [
              _vm._v(_vm._s(_vm.$t("new_text.brand_type")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "patient-detail__information__data" }, [
              _vm._v("\n          " + _vm._s(_vm.brandMaster) + "\n        ")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "patient-detail__information__row patient-detail__information__row--half"
          },
          [
            _c("label", { staticClass: "patient-detail__information__label" }, [
              _vm._v(_vm._s(_vm.$t("objects.patient.text.simulation_flag")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "patient-detail__information__data" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.preContractSimulation) +
                  "\n        "
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "patient-detail__information__row patient-detail__information__row--half"
          },
          [
            _c("label", { staticClass: "patient-detail__information__label" }, [
              _vm._v(_vm._s(_vm.$t("objects.patient.text.treatmentend_status")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "patient-detail__information__data" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.treatmentendStatus) + "\n        "
              )
            ])
          ]
        ),
        _vm._v(" "),
        _vm.patientInfo.reason_for_non_contract
          ? _c("div", { staticClass: "patient-detail__information__row" }, [
              _c(
                "label",
                { staticClass: "patient-detail__information__label" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("objects.patient.text.reason_for_non_contracting")
                    ) + "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "patient-detail__information__data" }, [
                _c("span", { staticClass: "ws-pre-wrap" }, [
                  _vm._v(_vm._s(_vm.patientInfo.reason_for_non_contract))
                ])
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "patient-detail__information__title patient-detail__information__title--line-bottom patient-detail__information__title--contact-info"
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("objects.patient.text.contact_info")) +
              "\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "patient-detail__information__group" }, [
        _c(
          "div",
          {
            staticClass:
              "patient-detail__information__row patient-detail__information__row"
          },
          [
            _c("label", { staticClass: "patient-detail__information__label" }, [
              _vm._v(_vm._s(_vm.$t("new_text.address")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "patient-detail__information__data" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.patientInfo.address) + "\n        "
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "patient-detail__information__row patient-detail__information__row"
          },
          [
            _c("label", { staticClass: "patient-detail__information__label" }, [
              _vm._v(_vm._s(_vm.$t("new_text.phone_number")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "patient-detail__information__data" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.patientInfo.tel) + "\n        "
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "patient-detail__information__row patient-detail__information__row"
          },
          [
            _c("label", { staticClass: "patient-detail__information__label" }, [
              _vm._v(_vm._s(_vm.$t("new_text.email")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "patient-detail__information__data" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.patientInfo.email) + "\n        "
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "patient-detail__information__title patient-detail__information__title--line-bottom patient-detail__information__title--contact-info"
        },
        [_vm._v("\n      " + _vm._s(_vm.$t("new_text.delivery")) + "\n    ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "patient-detail__information__group" }, [
        _c(
          "div",
          {
            staticClass:
              "patient-detail__information__row patient-detail__information__row--half"
          },
          [
            _c("label", { staticClass: "patient-detail__information__label" }, [
              _vm._v(
                _vm._s(_vm.$t("objects.patient.text.sms_delivery_setting"))
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "patient-detail__information__data" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.checkDeliver(_vm.patientInfo.is_receive_sms)) +
                  "\n        "
              )
            ])
          ]
        ),
        _vm._v(" "),
        false
          ? _c(
              "div",
              {
                staticClass:
                  "patient-detail__information__row patient-detail__information__row--half"
              },
              [
                _c(
                  "label",
                  { staticClass: "patient-detail__information__label" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("objects.patient.text.mail_delivery_setting")
                      )
                    )
                  ]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "patient-detail__information__data" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.checkDeliver(_vm.patientInfo.is_receive_email)
                      ) +
                      "\n        "
                  )
                ])
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      typeof _vm.cslBrandType !== "undefined" && _vm.cslBrandType !== null
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "patient-detail__information__title patient-detail__information__title--line-bottom patient-detail__information__title--csl-info"
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("objects.patient.text.csl_reservation_information")
                    ) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "patient-detail__information__group" }, [
              _c("div", { staticClass: "patient-detail__information__row" }, [
                _c(
                  "label",
                  { staticClass: "patient-detail__information__label-bold" },
                  [_vm._v(_vm._s(_vm.$t("new_text.brand_type")))]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "patient-detail__information__data" }, [
                  _vm._v(
                    "\n            " + _vm._s(_vm.cslBrandName) + "\n          "
                  )
                ])
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }