var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-top" }, [
    _c("div", { staticClass: "login-top__content" }, [
      _c(
        "div",
        { staticClass: "login-top__content--btn-action" },
        [
          _c("router-link", { attrs: { to: { name: "order.create" } } }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("objects.nav.create_instructions")) +
                "\n      "
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }