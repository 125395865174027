var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("common-modal-action", {
        staticClass: "modal-allocation",
        attrs: { title: _vm.$t("reservation.allocation.title") },
        on: {
          close: function($event) {
            return _vm.$emit("close")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("div", { staticClass: "modal-allocation__container" }, [
                  _c("div", { staticClass: "modal-allocation__group" }, [
                    _c(
                      "label",
                      {
                        staticClass:
                          "modal-allocation__item modal-allocation__item--label"
                      },
                      [_vm._v(_vm._s(_vm.$t("reservation.allocation.name")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-allocation__item-content" },
                      [
                        _c("common-input", {
                          class: [
                            "modal-allocation__item-content__name",
                            { "common-input-error": _vm.errors.name_allocation }
                          ],
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "new_text.placeholder.allocation_name"
                            ),
                            maxlength: "50",
                            required: "required"
                          },
                          on: { input: _vm.handleNameChange },
                          model: {
                            value: _vm.name_allocation,
                            callback: function($$v) {
                              _vm.name_allocation = $$v
                            },
                            expression: "name_allocation"
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.name_allocation
                          ? _c("span", { staticClass: "common-msg-error" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.name_allocation) +
                                  "\n            "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-allocation__group" }, [
                    _c(
                      "label",
                      {
                        staticClass:
                          "modal-allocation__item modal-allocation__item--label"
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("reservation.allocation.position"))
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "modal-allocation__item-content modal-allocation__item-content--position"
                      },
                      [
                        _c("common-select", {
                          staticClass: "common-select--color-primary",
                          attrs: {
                            options: _vm.order_list,
                            "key-value": "sortOrder",
                            "key-display": "sortOrder",
                            "default-value": _vm.sort_order
                          },
                          on: {
                            change: function(sortOrder) {
                              return (_vm.sort_order = sortOrder)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-allocation__group" }, [
                    _c(
                      "label",
                      {
                        staticClass:
                          "modal-allocation__item modal-allocation__item--label"
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("reservation.allocation.auto_check"))
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "modal-allocation__item-content modal-allocation__item-content--type"
                      },
                      [
                        _c("common-select", {
                          staticClass: "common-select--color-primary",
                          attrs: {
                            options: _vm.types,
                            "key-value": "value",
                            "key-display": "text",
                            "default-value": _vm.selected_type
                          },
                          on: {
                            change: function(value) {
                              return (_vm.selected_type = value)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "modal-allocation__item modal-allocation__item--label modal-allocation__item--text"
                    },
                    [_vm._v(_vm._s(_vm.$t("reservation.allocation.text")))]
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c("div", { staticClass: "modal-create-reservation__footer" }, [
                  _c(
                    "div",
                    { staticClass: "modal-create-reservation__btn-delete" },
                    [
                      _c("common-button", {
                        on: { click: _vm.handleClickDelete },
                        scopedSlots: _vm._u([
                          {
                            key: "content",
                            fn: function() {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "reservation.allocation.button.cancel"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-allocation__btn" },
                    [
                      _c("common-button", {
                        staticClass:
                          "modal-v2__button modal-v2__button--confirm",
                        on: { click: _vm.handleSubmit },
                        scopedSlots: _vm._u([
                          {
                            key: "content",
                            fn: function() {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.isDetectAutoFrameOperation
                                        ? _vm.$t("new_text.button.next")
                                        : _vm.$t("new_text.button.save")
                                    ) +
                                    "\n            "
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _vm.show_modal_delete_resource
        ? _c("modal-confirm-delete-allocation", {
            attrs: {
              "allocation-delete": _vm.allocation,
              allocations: _vm.allocations
            },
            on: {
              close: function($event) {
                _vm.show_modal_delete_resource = false
              },
              succeed: _vm.handleDeleteAllocation
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.show_modal_operation_reason
        ? _c("modal-operation-allocation-reason", {
            attrs: {
              "is-update": true,
              resources: _vm.operation_allocation_reason_resources
            },
            on: {
              back: function($event) {
                _vm.show_modal_operation_reason = false
              },
              confirm: _vm.handleAfterInputOperationReason
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }