export default {
  button: {
    add_item: 'Add booking type',
    add_tab: 'Add tab',
    copy_to_clipboard: 'Copy to clipboard',
    create_staff: 'Add new staff',
    edit_tab: 'Edit the currently displayed tab',
    please_select: 'Please select'
  },
  message: {
    save_edit: 'Do you want to save your edits?',
    save_reset_password: 'Do you want to save your information and send password reset email?'
  },
  placeholder: {
    other_staff: 'Other staff'
  },
  text: {
    account_information: '【Account information】',
    account_setting: 'Account settings',
    add_ip_address: 'Add IP address',
    allow_accept_ip_address: 'Allow access from this IP address',
    automatic_setting: 'Automatic update function',
    block_time: 'Block time',
    business_days_setting: 'Setting business days',
    business_hours: 'business hours',
    check_email: 'Please contact me to check your mailbox. Please copy and tell',
    clinic_information: 'Clinic information',
    clinic_name: 'Clinic name',
    clinic_name_note: 'The clinic name will be reflected in the receipt clinic information. \n It does not affect the notation on the official website of Kirei Line.',
    clinic_setting: 'Clinic settings',
    confirm_save_no_business_day_set: 'No business days have been set\nAre you sure you want to save it as it is?',
    contact_information: 'Contact information',
    create_ip_setting: 'Frame setting',
    daily_report: 'Daily Sales Report',
    del_date: 'Delete date and time',
    delete_cancellation: 'Delete cancellation',
    delete_it: 'Do you really want to delete this？',
    deleted_patient: 'Deleted patient information',
    destination_email: 'Destination',
    destination_email_note: 'You can enter multiple numbers separated by commas',
    edit_information: 'Editing staff information',
    edit_tab: 'Edit tab',
    email_address_optional: 'Mail address\n(optional)',
    enter_ip_address: 'Enter your ip address',
    every_minute: 'every minute',
    illegal_input: 'Illegal input',
    information_is_displayed_only_once: 'The following login information is displayed only once.\nPlease copy and tell us.',
    invalid_input: 'The time format is incorrect',
    ip: 'IP',
    ip_address: 'Add IP address',
    ip_exist: 'IP address already exists',
    ip_setting: 'IP address restrictions',
    is_admin: 'Administrative authority',
    job_title: 'Job title',
    job_master_setting: 'Job master setting',
    label_placeholder: 'In-clinic network IP',
    long_term_leave_setting: 'Long-term leave setting',
    no_business_day_set: 'No business days have been set',
    not_found: 'No staff information was found',
    note_cancel_delete: 'Do you want to cancel the deletion of patient information?',
    notification_setting: 'Automatic notification of reservation information',
    overlapped_block_time: 'The block frame is included in the specified time zone',
    password_init: 'Initial password',
    password_min: 'Please enter at least 8 single-byte alphanumeric characters',
    periodic_block_setting: 'Periodic block setting',
    periodic_require_day_of_week: 'Please select the day of the week',
    periodic_require_week: 'Please enter the week from 0 to 5',
    registration_date: 'Order day',
    reload_setting: 'Automatically update reservation dashboard',
    request_staff_id: 'Request staff id',
    required_time: 'Required time',
    resend_reset_password_success: 'I sent a password reset email',
    reservation_date_setting: 'Reservation date and time setting',
    reservation_duplicate_time_zone: 'Duplicate time zone setting',
    reservation_type_edit: 'Reservation type edit',
    reservation_type_registration: 'Reservation type registration',
    reservation_type_master_setting: 'Reservation type master setting',
    reservation_type_setting: 'Reservation type setting',
    reset_password: 'Send password reset email',
    search_by_id: 'Search by staff name / ID',
    send_email: 'Send an invitation email',
    send_report: 'Send daily sales report',
    set_weekly: 'If you enter 0, it will be set weekly.',
    setting: 'Setting',
    setting_print_calendar: 'Scheduled visit print settings',
    special_date_setting: 'Special date setting',
    species_alias: 'Species alias',
    staff_id: 'Staff ID',
    staff_id_reqired: 'Request staff ID',
    staff_id_login_rule: 'Half-width alphanumeric characters and symbols (only @. Can be used)',
    staff_information: 'Staff information',
    staff_information_after_registered: 'Registration of the following staff has been completed',
    staff_name: 'Staff name',
    staff_registration_completed: 'New staff registration completed',
    tab_name: 'Tab name',
    take_a_position: 'Take a position',
    transmission_time: 'Transmission time',
    undo_deletion: 'Undo deletion',
    undo_deletion_patient_exist_error: 'The deletion cannot be canceled because the patient information using the same email address and phone number exists.\nIf you need to cancel the deletion, please change the patient information with duplicate email address and phone number.',
    whitelist_setting: 'Whitelist settings',
    yes: 'Yes'
  }
}
