var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", [
    _c("div", { staticClass: "header_inner clearfix" }, [
      _c("div", { staticClass: "header-box header-account" }, [
        _c("div", { staticClass: "col-xl-3 col-lg-3 col-sm-4" }, [
          _c(
            "div",
            { staticClass: "header-layout-left" },
            [_vm._t("header-layout-left")],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-9 col-lg-9 col-sm-8" }, [
          _c(
            "div",
            { staticClass: "header-layout-right" },
            [
              _vm._t("header-layout-right", [
                _vm._t("header-layout-right-1", [
                  _c(
                    "div",
                    { staticClass: "header-user-info float-right col-md-4" },
                    [_c("user-info")],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm._t("header-layout-right-2")
              ])
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }