<template>
  <div class="account">
    <div class="account__content">
      <div class="account__header">
        <div class="account__inner account__inner--left">
          <h2 class="account__heading">
            {{ $t('objects.setting.text.account_setting') }}
          </h2>
        </div>
        <div class="account__inner account__inner--right">
          <div class="account__box">
            <form @submit.prevent="getAccountList">
              <label
                for="search"
                class="account__label account__label--search"
                @click="getAccountList"
              />
              <input-common
                id="search"
                class="account__search"
                :default-value="str_search"
                :placeholder="$t('objects.setting.text.search_by_id')"
                :maxlength="191"
                @input="updateSearch"
              />
            </form>
          </div>
          <div class="account__box">
            <select
              v-model="filter_key_selected"
              class="account__select"
              @change="getAccountList"
            >
              <option
                v-for="(filter, key) in filterStaffList"
                :key="key"
                :value="key"
              >
                {{ filter.name }}
              </option>
            </select>
          </div>
          <button-common
            class="account__btn"
            @click="is_show_modal_create = true"
          >
            <template #content>
              {{ $t('objects.setting.button.create_staff') }}
            </template>
          </button-common>
        </div>
      </div>
      <div class="account__body">
        <div class="common-list common-list--account">
          <table class="main-list">
            <thead>
              <tr>
                <th class="common-list__name">
                  {{ $t('objects.setting.text.staff_name') }}
                </th>
                <th class="common-list__position">
                  {{ $t('new_text.position') }}
                </th>
                <th
                  :class="['common-list__id common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
                  @click="sort('login_id')"
                >
                  {{ $t('new_text.id') }}
                  <span class="common-list__icon">
                    <img :src="getIconSort('login_id')">
                  </span>
                </th>
                <th
                  :class="['common-list__datetime common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
                  @click="sort('last_login')"
                >
                  {{ $t('new_text.last_login') }}
                  <span class="common-list__icon">
                    <img :src="getIconSort('last_login')">
                  </span>
                </th>
                <th class="common-list__email">
                  {{ $t('new_text.email') }}
                </th>
                <th
                  :class="['common-list__datetime common-list__sort', { 'common-list__sort--disable': isDisableSort }]"
                  @click="sort('created_at')"
                >
                  {{ $t('objects.setting.text.registration_date') }}
                  <span class="common-list__icon">
                    <img :src="getIconSort('created_at')">
                  </span>
                </th>
                <th class="common-list__is-admin">
                  {{ $t('objects.setting.text.is_admin') }}
                </th>
              </tr>
            </thead>
            <tbody v-if="login_possible.length > 0">
              <tr
                v-for="staff in login_possible"
                :key="staff.id"
                @click="showDetailStaff(staff)"
              >
                <td class="common-list__name">
                  {{ staff.name }}
                </td>
                <td class="common-list__position">
                  {{ getPositionName(staff) }}
                </td>
                <td class="common-list__id">
                  {{ staff.login_id }}
                </td>
                <td class="common-list__datetime">
                  {{ renderDate(staff.last_login) }}
                </td>
                <td class="common-list__email">
                  {{ staff.email }}
                </td>
                <td class="common-list__datetime">
                  {{ renderDate(staff.created_at) }}
                </td>
                <td class="common-list__is-admin">
                  {{ staff.is_admin ? $t('objects.setting.text.yes') : '' }}
                </td>
              </tr>
            </tbody>
            <tbody
              v-else
              class="common-list__not-found"
            >
              <tr>
                <td colspan="7">
                  <img src="/images/dentist.svg">
                  <p class="common-list__label-not-found">
                    {{ $t('objects.setting.text.not_found') }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="login_possible.length > 0"
            class="common-list__pagination"
          >
            <pagination-common
              v-if="login_possible.length"
              :current-page="pagination.current_page"
              :last-page="pagination.last_page"
              class="pagination-common--account"
              @change-page="getAccountList"
            />
          </div>
        </div>
      </div>
    </div>
    <modal-create-staff-form
      v-if="is_show_modal_create"
      :position-list="position_master"
      @close-modal="is_show_modal_create = false"
      @add-new-staff="handleAddNewStaff"
    />
    <modal-staff-registered
      v-if="is_show_registered"
      :staff-info="new_staff_info"
      @close-modal="closeModalRegistered"
    />
    <modal-edit-staff-form
      v-if="is_show_modal_edit"
      :staff-info="staff_info"
      :position-list="position_master"
      @close="is_show_modal_edit = false"
      @delete-staff="deleteStaff"
      @update-staff="handleBeforeUpdateStaff"
      @show-modal-confirm-delete="showConfirmDeleteStaff"
    />
    <modal-action
      v-if="is_show_modal_confirm_delete"
      :title="$t('objects.setting.text.delete_it')"
      :is-show-close-button="false"
      class="modal-confirm modal-v2--delete"
      @close="is_show_modal_confirm_delete = false"
    >
      <template #footer>
        <button-common
          class="button-v2--confirm"
          @click="deleteStaff"
        >
          <template #content>
            {{ $t('new_text.button.exclude') }}
          </template>
        </button-common>
        <button-common
          class="button-v2--close"
          @click="is_show_modal_confirm_delete = false"
        >
          <template #content>
            {{ $t('new_text.button.no') }}
          </template>
        </button-common>
      </template>
    </modal-action>
    <modal-action
      v-if="is_show_modal_confirm"
      :title="messageConfirmUpdate"
      :is-show-close-button="false"
      class="modal-confirm modal-v2--confirm-update"
    >
      <template #footer>
        <button-common
          class="button-v2--close"
          @click="closeModalUpdateConfirm"
        >
          <template #content>
            {{ $t('new_text.button.no') }}
          </template>
        </button-common>
        <button-common
          class="button-v2--confirm"
          @click="handleUpdateStaff"
        >
          <template #content>
            {{ $t('new_text.button.yes_1') }}
          </template>
        </button-common>
      </template>
    </modal-action>
  </div>
</template>
<script>
  import { safeGet, mbtrim, formatDateTime, showMessageError, showSuccessModal } from '../../../utils/helpers'
  import InputCommon from '../../../components/common/Input'
  import ButtonCommon from '../../../components/common/Button'
  import PaginationCommon from '../../../components/common/PaginationCommon'
  import { staffMixin } from '../../../mixins/staff'
  import ModalStaffRegistered from '../../../components/v2/ModalStaffRegistered'
  import ModalCreateStaffForm from '../../../components/v2/ModalCreateStaffForm'
  import ModalEditStaffForm from '../../../components/v2/ModalEditStaffForm'
  import ModalAction from '../../../components/common/ModalAction'
  import { RESPONSE_TYPE } from '../../../utils/constants'
  import { showErrorModal } from '../../../admin/utils/helpers'
  import sortMixin from '../../../mixins/sort'

  export default {
    components: {
      InputCommon,
      ButtonCommon,
      PaginationCommon,
      ModalCreateStaffForm,
      ModalStaffRegistered,
      ModalEditStaffForm,
      ModalAction
    },
    mixins: [staffMixin, sortMixin],
    data () {
      return {
        email: '',
        position_master: [],
        login_possible: [],
        filter_key_selected: 'all',
        str_search: '',
        is_show_modal_create: false,
        is_show_modal_edit: false,
        is_show_modal_confirm_delete: false,
        is_show_registered: false,
        is_show_modal_confirm: false,
        new_staff_info: {},
        pagination: {},
        staff_info: {},
        delete_staff_id: '',
        update_data: {},
        is_reset_password: false
      }
    },
    computed: {
      filterStaffList () {
        if (this.isLabDesigner) {
          return this.$t('objects.staff.filter_designer_list')
        }
        return this.$t('objects.staff.filter_staff_list')
      },
      isLabDesigner () {
        return this.$store.getters.isLabDesigner
      },
      messageConfirmUpdate () {
        if (this.is_reset_password) {
          return this.$t('objects.setting.message.save_reset_password')
        } else {
          return this.$t('objects.setting.message.save_edit')
        }
      },
      isDisableSort () {
        return this.login_possible.length === 0
      }
    },
    created () {
      document.title = this.$t('objects.page_title.setting').toString()
      this.getAccountList()
      this.filter_key_selected = Object.keys(this.filterStaffList)[0]
    },
    methods: {
      getAccountList (page = 1) {
        this.$store.commit('set_loading', true)
        const query = {
          str: mbtrim(this.str_search),
          filter_key: this.filter_key_selected.toUpperCase(),
          filter_id: this.filterStaffList[this.filter_key_selected].id_filter,
          page
        }
        if (this.sort_field) {
          query.sort_field = this.sort_field
          query.sort_type = this.sort_type
        }
        this.$store.dispatch('getAccountList', query).then((result) => {
          const data = safeGet(result.data, 'results')
          this.pagination = safeGet(data, 'login_possible')
          this.position_master = safeGet(data, 'position_master')
          this.login_possible = this.pagination.data
          this.$store.commit('set_loading', false)
        }).catch((e) => {
          this.$store.commit('set_loading', false)
          showMessageError(e, this)
        })
      },
      renderDate (date) {
        return date ? formatDateTime(date, this.$t('datetime.year_month_day_hour_minute')) : ''
      },
      updateSearch (value) {
        this.str_search = value
      },
      sort (field) {
        if (this.isDisableSort) {
          return
        }

        this.setDataSort(field)
        this.getAccountList()
      },
      updateNewStaffInfo: function (data) {
        this.is_show_modal_create = false
        if (data && typeof data !== 'undefined') {
          this.new_staff_info = data
          this.is_show_registered = true
        }
      },
      handleAddNewStaff (submitData) {
        this.$store.commit('set_loading', true)
        this.is_show_modal_create = false
        this.$store.dispatch('staff/adminAddNewStaff', submitData).then((result) => {
          const staff = safeGet(result, 'data.results.staff')
          if (staff) {
            staff.password = submitData.password
            staff.send_mail = submitData.send_mail

            this.updateNewStaffInfo(staff)
            showSuccessModal(this.$t('new_message.saved'))
            this.is_show_registered = true
          }
          this.$store.commit('set_loading', false)
        }).catch((error) => {
          this.$store.commit('set_loading', false)
          const resErrors = safeGet(error, 'response.data.errors') || {}
          const errors = []
          let errorMessage = this.$t('message.something_went_wrong')
          for (const index in resErrors) {
            if (resErrors[index]) {
              if (resErrors[index] === RESPONSE_TYPE.MAXIMUM) {
                showErrorModal(this.$t('new_validation.common.maximum', {
                  attribute: this.$t(`new_validation.common.attributes.${index}`), max: 80
                }))

                return
              }
              errors.push(resErrors[index])
            }
          }
          if (errors.length) errorMessage = errors.join('<br>')
          showErrorModal(errorMessage)
        })
      },
      handleBeforeUpdateStaff (submitData) {
        this.update_data = submitData
        this.is_reset_password = this.update_data.is_reset_password
        this.is_show_modal_confirm = true
      },
      closeModalUpdateConfirm () {
        this.update_data = {}
        this.is_show_modal_confirm = false
      },
      handleUpdateStaff () {
        this.$store.commit('set_loading', true)
        this.is_show_modal_edit = false
        this.is_show_modal_confirm = false
        this.$store.dispatch('staff/adminUpdateStaffInfo', this.update_data).then(() => {
          this.$store.commit('set_loading', false)
          this.update_data = {}
          this.getAccountList()
          if (this.is_reset_password) {
            showSuccessModal(this.$t('objects.setting.text.resend_reset_password_success'))
          } else {
            showSuccessModal(this.$t('new_message.saved'))
          }
        }).catch((error) => {
          this.$store.commit('set_loading', false)
          this.update_data = {}
          const resErrors = safeGet(error, 'response.data.errors') || {}
          const errors = []
          let errorMessage = this.$t('message.something_went_wrong')
          for (const index in resErrors) {
            if (resErrors[index]) {
              if (resErrors[index] === RESPONSE_TYPE.MAXIMUM) {
                showErrorModal(this.$t('new_validation.common.maximum', {
                  attribute: this.$t(`new_validation.common.attributes.${index}`), max: 80
                }))

                return
              }
              errors.push(resErrors[index])
            }
          }
          if (errors.length) errorMessage = errors.join('<br>')
          showErrorModal(errorMessage)
        })
      },
      showDetailStaff: function (staff) {
        this.staff_info = staff
        this.is_show_modal_edit = true
      },
      showConfirmDeleteStaff: function (staffId) {
        this.is_show_modal_confirm_delete = true
        if (typeof staffId !== 'undefined' && staffId) {
          this.delete_staff_id = staffId
        }
      },
      deleteStaff: function () {
        this.is_show_modal_confirm_delete = false
        this.$store.commit('set_loading', true)
        if (this.delete_staff_id && typeof this.delete_staff_id !== 'undefined') {
          this.$store.dispatch('deleteStaff', this.delete_staff_id).then((result) => {
            if (result.status === 200) {
              this.is_show_modal_edit = false
              this.getAccountList()
            }
            this.$store.commit('set_loading', false)
          }).catch((e) => {
            this.$store.commit('set_loading', false)
            showMessageError(e, this)
          })
        }
      },
      closeModalRegistered () {
        this.is_show_registered = false
        this.getAccountList(this.pagination.current_page)
      }
    }
  }
</script>
