import axios from 'axios'
import { state as authState } from './auth'
import { NOTIFICATION_READ } from '../../utils/constants'

export const namespaced = true

export const state = {
  notification_info: null
}

export const mutations = {
  setNotificationInfo (state, data) {
    state.notification_info = { ...data }
  },
  markAsReadNotification (state) {
    state.notification_info.is_read = NOTIFICATION_READ.READ
  }
}

export const actions = {
  getNotificationInfo ({ dispatch, commit }) {
    return axios.get('/api/notifications/publish', { headers: { Authorization: 'Bearer ' + authState.token } })
      .then((res) => {
        const { data: { results } } = res
        commit('setNotificationInfo', results)

        return res
      }).catch(() => {})
  },
  markAsReadNotification ({ dispatch, commit }, { id }) {
    return axios.put(`/api/notifications/${id}/mark-as-read`, {},
      { headers: { Authorization: 'Bearer ' + authState.token } }
    )
      .then(() => {
        commit('markAsReadNotification')
      }).catch(() => {
        dispatch('getNotificationInfo')
      })
  }
}
