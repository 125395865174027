import axios from 'axios'
import { state as authState } from './auth'

export const namespaced = true

export const state = {
  isMoveModeActive: false,
  reservation: null,
  mirror_template: ''
}

export const mutations = {
  setMoveModeActive (state, data) {
    state.isMoveModeActive = data
  },
  setReservation (state, data) {
    state.reservation = data
  },
  setMirrorTemplate (state, data) {
    state.mirror_template = data
  },
  resetMoveModeData (state) {
    state.reservation = null
    state.isMoveModeActive = null
  }
}

export const actions = {
  initMoveModeData ({ commit }, data) {
    if (!data) {
      return
    }
    commit('setReservation', data.reservation)
    commit('setMoveModeActive', true)
  },
  checkDayOff ({ dispatch }, postData) {
    return axios.post('/api/reservation/check-day-off', postData, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  }
}

export const getters = {
  getMoveModeActive: state => state.isMoveModeActive,
  getReservation: state => state.reservation,
  getMirrorTemplate: state => state.mirror_template
}
