<template>
  <div class="interview-content-consultation interview-input-block content-inner bg-white">
    <according-block
      label="相談内容"
      :checked="show_block"
      @handleClick="show_block = $event"
    />
    <div
      v-show="show_block"
      class="interview-content-consultation__content arrow-toggle-content page-content"
    >
      <div class="interview-content-consultation__group">
        <!-- 1 -->
        <div class="page-content">
          <h2 class="title">ご相談内容</h2>
          <div class="form-group interview-content-consultation__group-padding">
            <p v-if="errors.consultation_item " class="error-massage">{{ errors.consultation_item }}</p>
            <div class="form-inline justify-content-between">
              <checkbox-common
                v-model="consultation_item"
                val="矯正歯科治療"
                label="矯正歯科治療"
                @input="handleConsultation"
              />
              <checkbox-common
                v-model="consultation_item"
                label="ホワイトニング"
                val="ホワイトニング"
                @input="handleConsultation"
              />
              <checkbox-common
                v-model="consultation_item"
                label="クリーニング"
                val="クリーニング"
                @input="handleConsultation"
              />
            </div>
            <div class="form-group pt-2">
              <div class="form-inline row checkbox-other-inline">
                <div class="col-md-2">
                  <checkbox-common
                    v-model="consultation_item"
                    label="その他"
                    val="その他"
                    @input="handleConsultation"
                  />
                </div>
                <div class="col-md-6">
                  <div :class="['other-input', diableOtherInput]">
                    <input
                      v-model="consultation_item_other"
                      class="form-control w-100"
                      type="text"
                      name=""
                      placeholder="その他の場合ご記入ください"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 2 -->
      <div class="page-content">
        <h2 class="title">気になる箇所・症状</h2>
        <Item
          v-for="(value) in priority"
          ref="priority"
          :key="value"
          :type="'priority'"
          :index="value-1"
        >優先順位{{value}}</Item>
      </div>
    </div>
  </div>
</template>

<script>
  import CheckboxCommon from '../template/CheckboxCommon'
  import AccordingBlock from '../template/AccordingBlock'
  import Item from '../input/ItemConsultation'

  export default {
    components: {
      AccordingBlock,
      Item,
      CheckboxCommon
    },
    data () {
      return {
        show_block: true,
        priority: [1, 2, 3],
        consultation_item: [],
        consultation_item_other: '',
        resultAnswer: {
          consultation_item: '',
          consultation_item_other: '',
          points_symptoms: []
        },
        errors: {
          consultation_item: '',
          points_symptoms: {
            part: '',
            part_detail: ''
          }
        }
      }
    },
    computed: {
      diableOtherInput: function () {
        return {
          'disable-other-answer': !this.consultation_item.includes('その他')
        }
      }
    },
    created: function () {
      this.loadStateToData()
    },
    methods: {
      loadStateToData: function () {
        const dataState = this.$store.getters.get_consultation_content
        this.consultation_item = dataState.consultation_item
        this.consultation_item_other = dataState.consultation_item_other
        this.resultAnswer = dataState
      },
      getResultAnswer: function () {
        const vm = this
        this.resultAnswer.consultation_item = this.consultation_item
        this.resultAnswer.consultation_item_other = this.consultation_item_other
        this.$refs.priority.forEach(function (item, index) {
          vm.resultAnswer.points_symptoms[index] = item.getResultAnswer()
        })
        return this.resultAnswer
      },
      validation: function () {
        // only validate consultation and first block
        return !!(this.validateConsultation() && this.$refs.priority[0].validation())
      },
      validateConsultation: function () {
        if (this.consultation_item.length) {
          this.errors.consultation_item = ''
          return true
        }
        this.errors.consultation_item = this.$t('validation.interview.question')
        return false
      },
      handleConsultation: function (value) {
        if (value.length) {
          this.errors.consultation_item = ''
        }
      }
    }
  }
</script>
