var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "ts-modal" }, [
      _c(
        "div",
        {
          staticClass: "ts-modal__wrapper",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "ts-modal__container",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-content-center h-100" },
                [
                  _c(
                    "div",
                    {
                      ref: "calcBody",
                      staticClass: "calc calculator time-calculator",
                      attrs: { tabindex: "0" },
                      on: {
                        focus: _vm.onFocus,
                        keyup: function($event) {
                          return _vm.handleKeyUp($event)
                        },
                        blur: _vm.onBlur
                      }
                    },
                    [
                      _c("div", { staticClass: "display" }, [
                        _c("div", { staticClass: "wrapper" }, [
                          _c("span", { staticClass: "time-hour" }, [
                            _vm._v(_vm._s(_vm.expression_show))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "row" }, [
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addToExpression(7)
                              }
                            }
                          },
                          [_vm._v("7")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addToExpression(8)
                              }
                            }
                          },
                          [_vm._v("8")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addToExpression(9)
                              }
                            }
                          },
                          [_vm._v("9")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "control-action",
                            on: {
                              click: function($event) {
                                return _vm.removeOneCharacter()
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: { src: "/images/backspace.svg" }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "row" }, [
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addToExpression(4)
                              }
                            }
                          },
                          [_vm._v("4")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addToExpression(5)
                              }
                            }
                          },
                          [_vm._v("5")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addToExpression(6)
                              }
                            }
                          },
                          [_vm._v("6")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "control-action",
                            on: {
                              click: function($event) {
                                return _vm.clearAll()
                              }
                            }
                          },
                          [_vm._v("C")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "row" }, [
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addToExpression(1)
                              }
                            }
                          },
                          [_vm._v("1")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addToExpression(2)
                              }
                            }
                          },
                          [_vm._v("2")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addToExpression(3)
                              }
                            }
                          },
                          [_vm._v("3")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "tall",
                            on: {
                              click: function($event) {
                                return _vm.calculateExpression(3)
                              }
                            }
                          },
                          [_c("img", { attrs: { src: "/images/enter.svg" } })]
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "row" }, [
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addToExpression(0)
                              }
                            }
                          },
                          [_vm._v("0")]
                        )
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }