var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("logo", [
    _c("div", { staticClass: "d-flex justify-content-center error-page" }, [
      _c("p", [
        _vm._v("\n      " + _vm._s(_vm.$t("error_page.error")) + "\n    ")
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex justify-content-center mt-3 mb-4 login_container"
      },
      [
        _c(
          "router-link",
          {
            staticClass: "btn login_btn",
            attrs: { to: { name: "auth.login" } }
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("common_page.btn_login")) + "\n    "
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }