import * as moment from 'moment'
import helpers from '../utils/helpers'
import { mapActions, mapGetters } from 'vuex'
import { get } from 'lodash'

export const csvExportReservationType = {
  computed: {
    ...mapGetters(['currentUser']),
    formatFileNameCSV: function () {
      const dateStr = moment(this.selectedDate, this.$t('datetime.year_month_day')).format('YYYYMMDD')
      const fileName = `Reservation_type_${dateStr}.csv`
      return fileName.replace(/\s/g, '_').replace(/）/g, ')').replace(/（/g, '(')
    }
  },
  methods: {
    ...mapActions([
      'getListReservationWithDuties'
    ]),
    exportReservationTypeData: function () {
      const queryData = {
        date: moment(this.selectedDate, this.$t('datetime.year_month_day')).format(this.$t('datetime.year_month_day', 'en')),
        clinic_id: this.currentUser.clinic_id
      }
      this.$store.commit('set_loading', true)
      this.$emit('set-is-users-manipulating', true)

      this.getListReservationWithDuties(queryData).then((result) => {
        const apiData = helpers.safeGet(result.data, 'results')
        const self = this
        const jsonData = []

        if (!apiData || apiData.length === 0) {
          helpers.showWarningModal(this.$t('message.no_data_to_export_csv'), { customClass: 'toast-warning toast-warning-export' })
          this.$store.commit('set_loading', false)
          this.$emit('set-is-users-manipulating', false)
          return
        }

        const sfStatusCList = this.$t('objects.patient.sf_status_c')
        apiData.forEach(item => {
          const furiganaName = helpers.safeGet(item, 'furigana_name')
          const startTo = helpers.formatDateTime(helpers.safeGet(item, 'started_to'), 'YYYY/MM/DD HH:mm:ss')
          const name = helpers.safeGet(item, 'name')
          const sfUserId = helpers.safeGet(item, 'sf_user_id')
          const bookingType = helpers.safeGet(item, 'booking_type')
          const allocationName = get(item, 'allocation_name', '')
          const patientStatus = get(item, 'patient_status', -1)
          const patientStatusStr = sfStatusCList[patientStatus] ? sfStatusCList[patientStatus] : ''
          const brandName = get(item, 'brand_name', '')

          jsonData.push({
            予約開始日時: `"${startTo}"`,
            患者ID: `"${sfUserId}"`,
            患者名: `"${name}"`,
            ふりがな: `"${furiganaName}"`,
            予約種別: `"${bookingType}"`,
            枠名: `"${allocationName}"`,
            患者ステータス: `"${patientStatusStr}"`,
            ブランド種別: `"${brandName}"`
          })
        })

        if (jsonData.length > 0) {
          const csvHeader = 'data:text/csv;charset=utf-8,\ufeff'
          let csvContent = '\uFEFF'
          csvContent += [
            Object.keys(jsonData[0]).join(','),
            ...jsonData.map(item => Object.values(item).join(','))
          ]
            .join('\n')
            .replace(/(^\[)|(]$)/gm, '')

          const link = document.createElement('a')
          const blob = new Blob([csvContent], { type: csvHeader })
          const dataContent = URL.createObjectURL(blob)
          link.setAttribute('href', dataContent)
          link.setAttribute('download', self.formatFileNameCSV)
          link.click()
          link.remove()
        }

        this.$store.commit('set_loading', false)
        this.$emit('set-is-users-manipulating', false)
      }).catch((error) => {
        const resErrors = helpers.safeGet(error, 'response.data.errors') || {}
        const errors = []

        for (const index in resErrors) {
          errors.push(resErrors[index])
        }
        helpers.showErrorMessage(errors)

        this.$store.commit('set_loading', false)
        this.$emit('set-is-users-manipulating', false)
      })
    }
  }
}
