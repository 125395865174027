var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "common-list",
        { "common-list--lazy-load": _vm.lazyLoad && !_vm.isLoading }
      ]
    },
    [
      _c("table", { staticClass: "main-list" }, [
        _c("thead", [
          _c("tr", [
            _vm.isShowColumn("order_select")
              ? _c("th", { staticClass: "common-list__order-select-wrapper" }, [
                  _c("div", { staticClass: "common-list__order-select" }, [
                    _vm.orderList.length
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectAll,
                              expression: "selectAll"
                            }
                          ],
                          staticClass: "common-list__order-checkbox",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.selectAll)
                              ? _vm._i(_vm.selectAll, null) > -1
                              : _vm.selectAll
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.selectAll,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selectAll = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selectAll = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selectAll = $$c
                                }
                              },
                              function($event) {
                                return _vm.$emit(
                                  "order-selected",
                                  _vm.order_list_selected
                                )
                              }
                            ]
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", {
                      staticClass:
                        "common-list__order-checkbox common-list__order-checkbox--background"
                    })
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "th",
              {
                class: [
                  "common-list__order-code common-list__sort",
                  { "common-list__sort--disable": _vm.isDisableSort }
                ],
                on: {
                  click: function($event) {
                    return _vm.sortBy("order_id")
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("orders.list.item.order_id")) +
                    "\n          "
                ),
                _c("span", { staticClass: "common-list__icon" }, [
                  _c("img", { attrs: { src: _vm.getIconSort("order_id") } })
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                class: [
                  "common-list__order-created-at common-list__sort",
                  { "common-list__sort--disable": _vm.isDisableSort }
                ],
                on: {
                  click: function($event) {
                    return _vm.sortBy("orders.created_at")
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("orders.list.item.created_at")) +
                    "\n          "
                ),
                _c("span", { staticClass: "common-list__icon" }, [
                  _c("img", {
                    attrs: { src: _vm.getIconSort("orders.created_at") }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                class: [
                  "common-list__product-name common-list__sort",
                  { "common-list__sort--disable": _vm.isDisableSort }
                ],
                on: {
                  click: function($event) {
                    return _vm.sortBy("order_product_name")
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("orders.list.item.order_product_name")) +
                    "\n          "
                ),
                _c("span", { staticClass: "common-list__icon" }, [
                  _c("img", {
                    attrs: { src: _vm.getIconSort("order_product_name") }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                class: [
                  "common-list__product-number common-list__sort",
                  { "common-list__sort--disable": _vm.isDisableSort }
                ],
                on: {
                  click: function($event) {
                    return _vm.sortBy("order_product_number")
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("orders.list.item.order_product_number")) +
                    "\n          "
                ),
                _c("span", { staticClass: "common-list__icon" }, [
                  _c("img", {
                    attrs: { src: _vm.getIconSort("order_product_number") }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                class: [
                  "common-list__patient-name common-list__sort",
                  { "common-list__sort--disable": _vm.isDisableSort }
                ],
                on: {
                  click: function($event) {
                    return _vm.sortBy("patient_name")
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("orders.list.item.patient_name")) +
                    "\n          "
                ),
                _c("span", { staticClass: "common-list__icon" }, [
                  _c("img", { attrs: { src: _vm.getIconSort("patient_name") } })
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                class: [
                  "common-list__patient-id common-list__sort",
                  { "common-list__sort--disable": _vm.isDisableSort }
                ],
                on: {
                  click: function($event) {
                    return _vm.sortBy("sf_user_id")
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("orders.list.item.patient_id")) +
                    "\n          "
                ),
                _c("span", { staticClass: "common-list__icon" }, [
                  _c("img", { attrs: { src: _vm.getIconSort("sf_user_id") } })
                ])
              ]
            ),
            _vm._v(" "),
            _vm.isShowColumn("order_shipping_date")
              ? _c(
                  "th",
                  {
                    class: [
                      "common-list__order-shipping-date common-list__sort",
                      { "common-list__sort--disable": _vm.isDisableSort }
                    ],
                    on: {
                      click: function($event) {
                        return _vm.sortBy(_vm.getField)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.currentStatus !==
                            _vm.order_status.SHIPPED.value &&
                            _vm.currentStatus !== _vm.order_status.FINISH.value
                            ? _vm.$t("orders.list.item.order_shipping_date")
                            : _vm.$t("orders.list.item.order_delivery_date")
                        ) +
                        "\n          "
                    ),
                    _c("span", { staticClass: "common-list__icon" }, [
                      _c("img", {
                        attrs: { src: _vm.getIconSort(_vm.getField) }
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isShowColumn("order_status")
              ? _c(
                  "th",
                  {
                    class: [
                      "common-list__order-status common-list__sort",
                      { "common-list__sort--disable": _vm.isDisableSort }
                    ],
                    on: {
                      click: function($event) {
                        return _vm.sortBy("order_status")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("orders.list.item.status")) +
                        "\n          "
                    ),
                    _c("span", { staticClass: "common-list__icon" }, [
                      _c("img", {
                        attrs: { src: _vm.getIconSort("order_status") }
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isShowColumn("order_sf_gbangou")
              ? _c(
                  "th",
                  {
                    class: [
                      "common-list__order-g-bangou common-list__sort",
                      { "common-list__sort--disable": _vm.isDisableSort }
                    ],
                    on: {
                      click: function($event) {
                        return _vm.sortBy("orders.sf_gbangou")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("orders.list.item.g_bangou")) +
                        "\n          "
                    ),
                    _c("span", { staticClass: "common-list__icon" }, [
                      _c("img", {
                        attrs: { src: _vm.getIconSort("orders.sf_gbangou") }
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "th",
              {
                class: [
                  "common-list__clinic-name common-list__sort",
                  { "common-list__sort--disable": _vm.isDisableSort }
                ],
                on: {
                  click: function($event) {
                    return _vm.sortBy("clinic_name")
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("orders.list.item.clinic_name")) +
                    "\n          "
                ),
                _c("span", { staticClass: "common-list__icon" }, [
                  _c("img", { attrs: { src: _vm.getIconSort("clinic_name") } })
                ])
              ]
            ),
            _vm._v(" "),
            _vm.isShowColumn("print_status")
              ? _c(
                  "th",
                  {
                    class: [
                      "common-list__print-status common-list__sort",
                      { "common-list__sort--disable": _vm.isDisableSort }
                    ],
                    on: {
                      click: function($event) {
                        return _vm.sortBy("instruction_print_status")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("objects.orders.text.print_status")) +
                        "\n          "
                    ),
                    _c("span", { staticClass: "common-list__icon" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.getIconSort("instruction_print_status")
                        }
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isShowColumn("worker_setup")
              ? _c(
                  "th",
                  {
                    class: [
                      "common-list__worker-setup common-list__sort",
                      { "common-list__sort--disable": _vm.isDisableSort }
                    ],
                    on: {
                      click: function($event) {
                        return _vm.sortBy("orders.sf_worker_setup_id")
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "common-list__text-box" }, [
                      _c(
                        "span",
                        { staticClass: "common-list__text-box__text" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("objects.orders.text.worker_setup"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "common-list__text-box__icon" },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.getIconSort("orders.sf_worker_setup_id")
                            }
                          })
                        ]
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isShowColumn("worker_presscut")
              ? _c(
                  "th",
                  {
                    class: [
                      "common-list__worker-presscut common-list__sort",
                      { "common-list__sort--disable": _vm.isDisableSort }
                    ],
                    on: {
                      click: function($event) {
                        return _vm.sortBy("orders.sf_worker_presscut_id")
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "common-list__text-box" }, [
                      _c(
                        "span",
                        { staticClass: "common-list__text-box__text" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("objects.orders.text.worker_presscut")
                            )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "common-list__text-box__icon" },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.getIconSort(
                                "orders.sf_worker_presscut_id"
                              )
                            }
                          })
                        ]
                      )
                    ])
                  ]
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _vm.orderList.length > 0
          ? _c(
              "tbody",
              _vm._l(_vm.orderList, function(order, index) {
                return _c(
                  "tr",
                  {
                    key: index,
                    staticClass: "c-pointer",
                    on: {
                      click: function($event) {
                        return _vm.gotoOrderDetailPage(order, $event)
                      }
                    }
                  },
                  [
                    _vm.isShowColumn("order_select")
                      ? _c(
                          "td",
                          { staticClass: "common-list__order-select-wrapper" },
                          [
                            _c(
                              "div",
                              { staticClass: "common-list__order-select" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.order_list_selected,
                                      expression: "order_list_selected"
                                    }
                                  ],
                                  staticClass: "common-list__order-checkbox",
                                  attrs: {
                                    id: order.order_id,
                                    type: "checkbox"
                                  },
                                  domProps: {
                                    value: order.order_id,
                                    checked: Array.isArray(
                                      _vm.order_list_selected
                                    )
                                      ? _vm._i(
                                          _vm.order_list_selected,
                                          order.order_id
                                        ) > -1
                                      : _vm.order_list_selected
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$a = _vm.order_list_selected,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = order.order_id,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.order_list_selected = $$a.concat(
                                                [$$v]
                                              ))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.order_list_selected = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.order_list_selected = $$c
                                        }
                                      },
                                      function($event) {
                                        return _vm.$emit(
                                          "order-selected",
                                          _vm.order_list_selected
                                        )
                                      }
                                    ]
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass:
                                    "common-list__order-checkbox common-list__order-checkbox--background common-list__order-checkbox--one"
                                })
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isLabDesigner || _vm.isInstructionAdmin
                      ? _c(
                          "td",
                          { staticClass: "common-list__order-code" },
                          [
                            _c(
                              "router-link",
                              {
                                class: [
                                  "common-list__patient-link",
                                  { disable: _vm.isInstructionAdmin }
                                ],
                                attrs: {
                                  target: "_blank",
                                  to: {
                                    name: "patient.orders.detail",
                                    params: {
                                      id: order.patient_id,
                                      order_id: order.order_id
                                    }
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(order.code) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _c("td", { staticClass: "common-list__order-code" }, [
                          _vm._v(
                            "\n          " + _vm._s(order.code) + "\n        "
                          )
                        ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "common-list__order-created-at" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getDate(order.created_at)) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "common-list__product-name" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(order.order_product_name) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "common-list__product-number" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(order.order_product_number) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    !_vm.isLabDesigner || _vm.isInstructionAdmin
                      ? _c(
                          "td",
                          { staticClass: "common-list__patient-name" },
                          [
                            _c(
                              "router-link",
                              {
                                class: [
                                  "common-list__patient-link",
                                  { disable: _vm.isInstructionAdmin }
                                ],
                                attrs: {
                                  target: "_blank",
                                  to: {
                                    name: "patient.orders",
                                    params: { id: order.patient_id }
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(order.patient_name) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _c("td", { staticClass: "common-list__patient-name" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(order.patient_name) +
                              "\n        "
                          )
                        ]),
                    _vm._v(" "),
                    !_vm.isLabDesigner || _vm.isInstructionAdmin
                      ? _c(
                          "td",
                          { staticClass: "common-list__patient-id" },
                          [
                            _c(
                              "router-link",
                              {
                                class: [
                                  "common-list__patient-link",
                                  { disable: _vm.isInstructionAdmin }
                                ],
                                attrs: {
                                  target: "_blank",
                                  to: {
                                    name: "patient.orders",
                                    params: { id: order.patient_id }
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(order.sf_user_id) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _c("td", { staticClass: "common-list__patient-id" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(order.sf_user_id) +
                              "\n        "
                          )
                        ]),
                    _vm._v(" "),
                    _vm.isShowColumn("order_shipping_date")
                      ? _c(
                          "td",
                          { staticClass: "common-list__order-shipping-date" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.currentStatus !==
                                    _vm.order_status.SHIPPED.value &&
                                    _vm.currentStatus !==
                                      _vm.order_status.FINISH.value
                                    ? _vm.getDate(order.order_shipping_date)
                                    : _vm.getDate(order.sf_delivery_date)
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isShowColumn("order_status")
                      ? _c("td", { staticClass: "common-list__order-status" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.getOrderStatusLabel(order.order_status)
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isShowColumn("order_sf_gbangou")
                      ? _c(
                          "td",
                          { staticClass: "common-list__order-g-bangou" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(order.sf_gbangou) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("td", { staticClass: "common-list__clinic-name" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(order.clinic_name) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.isShowColumn("print_status")
                      ? _c(
                          "td",
                          {
                            class: [
                              "common-list__print-status",
                              order.instruction_print_status
                                ? "common-list__print-status--printed"
                                : "common-list__print-status--unprinted"
                            ]
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  order.instruction_print_status
                                    ? _vm.$t("orders.list.print_status.printed")
                                    : _vm.$t(
                                        "orders.list.print_status.unprinted"
                                      )
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isShowColumn("worker_setup")
                      ? _c("td", { staticClass: "common-list__worker-setup" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(order.name_worker_setup) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isShowColumn("worker_presscut")
                      ? _c(
                          "td",
                          { staticClass: "common-list__worker-presscut" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(order.name_worker_presscut) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              }),
              0
            )
          : _c("tbody", { staticClass: "common-list__not-found" }, [
              _c("tr", [
                _c("td", [
                  !_vm.lazyLoad
                    ? _c("p", { staticClass: "common-list__label-not-found" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.notFoundText) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }