var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page page-print" }, [
    _c(
      "main",
      {
        staticClass: "patient-detail-page patient-detail-print interview-print"
      },
      [
        _c("div", { staticClass: "col-12 main-content" }, [
          _c("section", { staticClass: "content" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "content-wrapper content-wrapper--interview-print"
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-5 d-flex pr-0" }, [
                    _c("div", { staticClass: "d-flex flex-column w-100" }, [
                      _c("div", { staticClass: "pt-25 padding-left-2" }, [
                        _c(
                          "p",
                          {
                            staticClass: "border-bottom-print pl-10 ls-2 fs-15"
                          },
                          [
                            _vm._v("\n                    予約日時: "),
                            _c("span", { staticClass: "ml-4" }, [
                              _vm._v(_vm._s(_vm.patientDateBooking))
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "padding-left-2" }, [
                        _c(
                          "p",
                          {
                            staticClass: "border-bottom-print pl-10 ls-2 fs-15"
                          },
                          [
                            _vm._v("\n                    患者ID: "),
                            _c("span", { staticClass: "ml-4" }, [
                              _vm._v(_vm._s(_vm.patient.sf_user_id))
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "fs-18 margin-top-3" }, [
                        _vm._v("◆患者様情報")
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-7 pl-52" },
                    [_c("InterviewCheckList")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-md-12 patient-detail-left patient-info-interview mt-0"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "print-component component-wrapper interview-checklist"
                        },
                        [
                          _c("div", { staticClass: "bg-white" }, [
                            _c(
                              "table",
                              {
                                staticClass:
                                  "table table-interview-check-list table-interview-check-list--patient-info"
                              },
                              [
                                _c("tbody", [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "bg-highlight",
                                        attrs: { width: "116px" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                          ふりがな\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("td", { attrs: { width: "337px" } }, [
                                      _vm._v(
                                        "\n                          " +
                                          _vm._s(
                                            _vm.isEmpty(_vm.patient_info)
                                              ? ""
                                              : _vm.truncateContent(
                                                  _vm.patient_info
                                                    .kana_last_name +
                                                    " " +
                                                    _vm.patient_info
                                                      .kana_first_name
                                                )
                                          ) +
                                          "\n                        "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "bg-highlight text-center",
                                        attrs: { width: "88px" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                          生年月日\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._m(1)
                                  ]),
                                  _vm._v(" "),
                                  _vm._m(2),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", { staticClass: "bg-highlight" }, [
                                      _vm._v(
                                        "\n                          氏名\n                        "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.isEmpty(_vm.patient_info)
                                            ? ""
                                            : _vm.truncateContent(
                                                _vm.patient_info.last_name +
                                                  " " +
                                                  _vm.patient_info.first_name
                                              )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "bg-highlight text-center"
                                      },
                                      [
                                        _vm._v(
                                          "\n                          性別\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "col-data" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center"
                                        },
                                        [
                                          _c("checkbox-common", {
                                            attrs: { label: "女性" }
                                          }),
                                          _vm._v(" "),
                                          _c("checkbox-common", {
                                            staticClass: "ml-4",
                                            attrs: { label: "男性" }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "bg-highlight align-middle",
                                        attrs: { rowspan: "2" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                          ご住所\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "font-weight-bold text-left",
                                        attrs: { rowspan: "2" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                          〒\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "bg-highlight text-center"
                                      },
                                      [
                                        _vm._v(
                                          "\n                          携帯電話\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "col-data text-left",
                                        attrs: { colspan: "3" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(_vm.patient_info.tel) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "bg-highlight text-center align-middle"
                                      },
                                      [
                                        _vm._v(
                                          "\n                          ご職業\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "col-data",
                                        attrs: { colspan: "3" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex flex-column" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c("checkbox-common", {
                                                  attrs: { label: "会社員" }
                                                }),
                                                _vm._v(" "),
                                                _c("checkbox-common", {
                                                  staticClass: "ml-1",
                                                  attrs: { label: "自営業" }
                                                }),
                                                _vm._v(" "),
                                                _c("checkbox-common", {
                                                  staticClass: "ml-1",
                                                  attrs: { label: "学生" }
                                                }),
                                                _vm._v(" "),
                                                _c("checkbox-common", {
                                                  staticClass: "ml-1",
                                                  attrs: { label: "主婦・主夫" }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c("checkbox-common", {
                                                  attrs: { label: "その他" }
                                                }),
                                                _vm._v(" "),
                                                _vm._m(3)
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(4),
                          _vm._v(" "),
                          _vm._m(5)
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-3" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _vm._m(6),
                    _vm._v(" "),
                    _c("div", { staticClass: "bg-white pb-2" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("checkbox-common", {
                            staticClass: "ml-4 fs-18",
                            attrs: { label: "矯正歯科治療" }
                          }),
                          _vm._v(" "),
                          _c("checkbox-common", {
                            staticClass: "ml-4 fs-18",
                            attrs: { label: "ホワイトニング" }
                          }),
                          _vm._v(" "),
                          _c("checkbox-common", {
                            staticClass: "ml-4 fs-18",
                            attrs: { label: "クリーニング" }
                          }),
                          _vm._v(" "),
                          _c("checkbox-common", {
                            staticClass: "ml-4 fs-18",
                            attrs: { label: "その他" }
                          }),
                          _vm._v(" "),
                          _vm._m(7)
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 pl-4" }, [
                    _c(
                      "div",
                      { staticClass: "pl-3" },
                      [
                        _c(
                          "h4",
                          { staticClass: "fs-17 ls-06 m-0 padding-top-6" },
                          [_vm._v("ご相談内容・気になる症状について")]
                        ),
                        _vm._v(" "),
                        _vm._l(2, function(i) {
                          return _c("div", { key: i, staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("p", { staticClass: "m-0 fs-17 ls-20" }, [
                                _vm._v(
                                  "\n                      優先順位" +
                                    _vm._s(i) +
                                    "\n                    "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-4" }, [
                                  _c(
                                    "label",
                                    { staticClass: "fs-16 padding-top-6" },
                                    [_vm._v("1  . 部位")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex pl-7 padding-top-4"
                                    },
                                    _vm._l(_vm.locations, function(
                                      location,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "d-flex",
                                          class: [
                                            {
                                              "ml-25": index >= 1
                                            }
                                          ]
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex flex-column"
                                            },
                                            [
                                              _c("checkbox-common", {
                                                staticClass: "fs-16",
                                                attrs: { label: location.label }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "ml-3" },
                                                _vm._l(
                                                  location.options,
                                                  function(subOption, idx) {
                                                    return _c(
                                                      "checkbox-common",
                                                      {
                                                        key: idx,
                                                        staticClass:
                                                          "fs-16 padding-top-3",
                                                        attrs: {
                                                          label: subOption.label
                                                        }
                                                      }
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-8" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "m-0 dentition-number padding-top-6"
                                    },
                                    [
                                      _vm._v(
                                        "\n                          歯列番号\n                        "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "row padding-top-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "col-6 p-0 d-flex" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-end w-100"
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "m-0 bb-2 part__label pb-2"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                右上\n                              "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row-reverse w-100 part-detail"
                                                },
                                                _vm._l(
                                                  _vm.dentition.UL,
                                                  function(item) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: item.value,
                                                        staticClass:
                                                          "d-flex part-detail-item bb-2"
                                                      },
                                                      [
                                                        _c("checkbox-common"),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "fs-15"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.value)
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass:
                                                  "part-detail--border-right"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-6 pr-3 pl-0 d-flex"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex w-100 align-items-end"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex w-100 part-detail part-detail--end"
                                                },
                                                _vm._l(
                                                  _vm.dentition.UR,
                                                  function(item) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: item.value,
                                                        staticClass:
                                                          "d-flex part-detail-item bb-2"
                                                      },
                                                      [
                                                        _c("checkbox-common"),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "fs-15"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.value)
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "m-0 bb-2 part__label pb-2"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                左上\n                              "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-6  p-0 d-flex" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-start w-100"
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "m-0 part__label part__label--ll pt-2"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                右下\n                              "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-row-reverse w-100 part-detail part-detail--bottom"
                                              },
                                              _vm._l(_vm.dentition.LL, function(
                                                item
                                              ) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.value,
                                                    staticClass:
                                                      "d-flex part-detail-item"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "fs-15" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.value)
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("checkbox-common")
                                                  ],
                                                  1
                                                )
                                              }),
                                              0
                                            ),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass:
                                                "part-detail--border-right"
                                            })
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-6 pr-3 pl-0 d-flex" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex w-100 align-item-start"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex w-100 part-detail part-detail--end part-detail--bottom"
                                              },
                                              _vm._l(_vm.dentition.LR, function(
                                                item
                                              ) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.value,
                                                    staticClass:
                                                      "d-flex part-detail-item"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "fs-15" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.value)
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("checkbox-common")
                                                  ],
                                                  1
                                                )
                                              }),
                                              0
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "m-0 part__label pt-2"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                左下\n                              "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("label", { staticClass: "fs-16" }, [
                                    _vm._v("2  . 症状")
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-10" }, [
                                      _c(
                                        "div",
                                        { staticClass: "padding-left-10" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex flex-wrap" },
                                            [
                                              _vm._l(_vm.symptoms, function(
                                                symptom
                                              ) {
                                                return _c("checkbox-common", {
                                                  key: symptom.id,
                                                  attrs: {
                                                    "class-name":
                                                      "fs-16 ls-1 mr-22",
                                                    label: symptom.value
                                                  }
                                                })
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "d-flex w-100" },
                                                [
                                                  _c("checkbox-common", {
                                                    attrs: {
                                                      label: "その他",
                                                      "class-name":
                                                        "fs-16 ls-10"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _vm._m(8, true)
                                                ],
                                                1
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _vm._m(9, true)
                            ])
                          ])
                        }),
                        _vm._v(" "),
                        _c("div", { staticStyle: { clear: "both" } })
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row page-break pt-50" }, [
                  _c("div", { staticClass: "col-12 pl-4" }, [
                    _c("div", { staticClass: "pl-3" }, [
                      _c("p", { staticClass: "m-0 fs-17 ls-20" }, [
                        _vm._v(
                          "\n                  優先順位3\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-4" }, [
                          _c("label", { staticClass: "fs-16 padding-top-6" }, [
                            _vm._v("1. 部位")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex pl-3" },
                            _vm._l(_vm.locations, function(location, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "d-flex fs-16",
                                  class: [
                                    {
                                      "ml-25": index >= 1
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column" },
                                    [
                                      _c("checkbox-common", {
                                        attrs: { label: location.label }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "ml-3" },
                                        _vm._l(location.options, function(
                                          subOption,
                                          idx
                                        ) {
                                          return _c("checkbox-common", {
                                            key: idx,
                                            attrs: {
                                              label: subOption.label,
                                              "class-name":
                                                "fs-16 padding-top-3"
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("p", { staticClass: "m-0 dentition-number" }, [
                            _vm._v(
                              "\n                      歯列番号\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row padding-top-6" }, [
                            _c("div", { staticClass: "col-6 p-0 d-flex" }, [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-end w-100" },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "m-0 bb-2 part__label pb-2"
                                    },
                                    [
                                      _vm._v(
                                        "\n                            右上\n                          "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row-reverse w-100 part-detail"
                                    },
                                    _vm._l(_vm.dentition.UL, function(item) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.value,
                                          staticClass:
                                            "d-flex part-detail-item bb-2"
                                        },
                                        [
                                          _c("checkbox-common"),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(item.value))
                                          ])
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "part-detail--border-right"
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-6 pr-3 pl-0 d-flex" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex w-100 align-items-end"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex w-100 part-detail part-detail--end"
                                      },
                                      _vm._l(_vm.dentition.UR, function(item) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.value,
                                            staticClass:
                                              "d-flex part-detail-item bb-2"
                                          },
                                          [
                                            _c("checkbox-common"),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(item.value))
                                            ])
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "m-0 bb-2 part__label pb-2"
                                      },
                                      [
                                        _vm._v(
                                          "\n                            左上\n                          "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6  p-0 d-flex" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-start w-100"
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "m-0 part__label part__label--ll pt-2"
                                    },
                                    [
                                      _vm._v(
                                        "\n                            右下\n                          "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row-reverse w-100 part-detail part-detail--bottom"
                                    },
                                    _vm._l(_vm.dentition.LL, function(item) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.value,
                                          staticClass: "d-flex part-detail-item"
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.value))
                                          ]),
                                          _vm._v(" "),
                                          _c("checkbox-common")
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "part-detail--border-right"
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-6 pr-3 pl-0 d-flex" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex w-100 align-item-start"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex w-100 part-detail part-detail--end part-detail--bottom"
                                      },
                                      _vm._l(_vm.dentition.LR, function(item) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.value,
                                            staticClass:
                                              "d-flex part-detail-item"
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.value))
                                            ]),
                                            _vm._v(" "),
                                            _c("checkbox-common")
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "m-0 part__label pt-2" },
                                      [
                                        _vm._v(
                                          "\n                            左下\n                          "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("label", [_vm._v("2. 症状")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-10" }, [
                              _c("div", { staticClass: "padding-left-10" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-wrap" },
                                  [
                                    _vm._l(_vm.symptoms, function(symptom) {
                                      return _c("checkbox-common", {
                                        key: symptom.id,
                                        attrs: {
                                          "class-name": "fs-16 ls-10 mr-22",
                                          label: symptom.value
                                        }
                                      })
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex w-100" },
                                      [
                                        _c("checkbox-common", {
                                          attrs: {
                                            label: "その他",
                                            "class-name": "fs-16"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _vm._m(10)
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm._m(11)
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _vm._m(12),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "question-list" }, [
                      _c("div", { staticClass: "d-flex w-100" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "print-component component-wrapper interview-checklist w-100"
                          },
                          [
                            _c("div", { staticClass: "bg-white" }, [
                              _c("div", { staticClass: "table-list" }, [
                                _c("div", { staticClass: "d-flex" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-50 d-flex table-print-left"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex w-100 flex-column"
                                        },
                                        [
                                          _vm._m(13),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "qs-left" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-list__cell p-2"
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "fs-15" },
                                                      [
                                                        _vm._v(
                                                          "アレルギーはありますか?"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "ml-12" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column"
                                                          },
                                                          [
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                staticClass:
                                                                  "mb-4 fs-15",
                                                                attrs: {
                                                                  label:
                                                                    "金属アレルギー"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._m(14),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                staticClass:
                                                                  "mb-4 fs-15",
                                                                attrs: {
                                                                  label:
                                                                    "薬アレルギー"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._m(15),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                staticClass:
                                                                  "mb-4 fs-15",
                                                                attrs: {
                                                                  label:
                                                                    "花粉症"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._m(16),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                staticClass:
                                                                  "mb-4 fs-15",
                                                                attrs: {
                                                                  label:
                                                                    "その他のアレルギー"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._m(17)
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(18)
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "qs-left" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-list__cell p-2"
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        "今までに口や顔の周りの癖はありましたか？"
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ml-12 d-flex flex-wrap"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex margin-bottom-7"
                                                          },
                                                          [
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "指しゃぶり",
                                                                  "class-name":
                                                                    "mr-4"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "爪かみ",
                                                                  "class-name":
                                                                    "mr-4"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "唇かみ"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex margin-bottom-7"
                                                          },
                                                          [
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "舌を出す",
                                                                  "class-name":
                                                                    "mr-4"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "口が開いていることが多い"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex margin-bottom-7"
                                                          },
                                                          [
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "頬づえ",
                                                                  "class-name":
                                                                    "mr-4"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "歯ぎしり",
                                                                  "class-name":
                                                                    "mr-4"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "いびき",
                                                                  "class-name":
                                                                    "mr-4"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-items-center w-100 margin-bottom-10"
                                                          },
                                                          [
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "その他"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._m(19)
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(20)
                                          ]),
                                          _vm._v(" "),
                                          _vm._m(21),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex flex-grow-1"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "qs-left" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "table-list__cell p-2 h-100"
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "mt-7 mb-10"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "寝相はどの状態が多いですか?"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ml-12 d-flex flex-column"
                                                        },
                                                        [
                                                          _c(
                                                            "checkbox-common",
                                                            {
                                                              attrs: {
                                                                "class-name":
                                                                  "mr-2",
                                                                label: "仰向け"
                                                              }
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-items-center mt-8"
                                                            },
                                                            [
                                                              _c(
                                                                "checkbox-common",
                                                                {
                                                                  attrs: {
                                                                    "class-name":
                                                                      "mr-4",
                                                                    label:
                                                                      "うつ伏せ"
                                                                  }
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "m-0 ml-2 mr-3"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                          首の向き\n                                        "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "checkbox-common",
                                                                {
                                                                  attrs: {
                                                                    "class-name":
                                                                      "mx-4",
                                                                    label: "左"
                                                                  }
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "checkbox-common",
                                                                {
                                                                  attrs: {
                                                                    "class-name":
                                                                      "mr-4",
                                                                    label: "右"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-items-center mt-8"
                                                            },
                                                            [
                                                              _c(
                                                                "checkbox-common",
                                                                {
                                                                  attrs: {
                                                                    "class-name":
                                                                      "mr-5",
                                                                    label:
                                                                      "横向き"
                                                                  }
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "m-0 mr-5"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                          向き\n                                        "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "checkbox-common",
                                                                {
                                                                  attrs: {
                                                                    "class-name":
                                                                      "mx-4",
                                                                    label: "左"
                                                                  }
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "checkbox-common",
                                                                {
                                                                  attrs: {
                                                                    "class-name":
                                                                      "mr-4",
                                                                    label: "右"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "checkbox-common",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "様々寝相が変わる",
                                                                "class-name":
                                                                  "mt-8 mb-18"
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._m(22)
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-50 d-flex table-print-right"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex w-100 flex-column"
                                        },
                                        [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "qs-left" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-list__cell is-first p-2"
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "fs-15" },
                                                      [
                                                        _vm._v(
                                                          "過去に矯正歯科治療された事はありますか?"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._m(23),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-column ml-2"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex"
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "my-0"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                          矯正装置\n                                        "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "マウスピース　(アライナー)",
                                                                  "class-name":
                                                                    "ml-4"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex my-5-10"
                                                          },
                                                          [
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "ワイヤー",
                                                                  "class-name":
                                                                    "mr-1"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-items-center w-other-custom"
                                                              },
                                                              [
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "その他"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _vm._m(24)
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(25)
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "qs-left" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-list__cell p-2"
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        "顎の症状で、今まで経験したものはありますか?"
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "ml-12" },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "border-bottom-print"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                        いつ頃\n                                      "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column align-items-baseline"
                                                          },
                                                          [
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  "class-name":
                                                                    "d-flex flex-column align-items-baseline",
                                                                  label:
                                                                    "顎の関節痛"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "顎の関節音(ガクガク, ゴリゴリなど)",
                                                                  "class-name":
                                                                    "margin-top-5"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex margin-top-8"
                                                          },
                                                          [
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "口が開きづらい",
                                                                  "class-name":
                                                                    "mr-4"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "口が閉じづらい"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex w-100 margin-top-8 margin-bottom-10"
                                                          },
                                                          [
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "その他"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._m(26)
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(27)
                                          ]),
                                          _vm._v(" "),
                                          _vm._m(28),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "qs-left" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-list__cell p-2"
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-11" },
                                                      [
                                                        _vm._v(
                                                          "次のような症状はありますか?"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ml-12 d-flex flex-wrap mb-12"
                                                      },
                                                      [
                                                        _c("checkbox-common", {
                                                          attrs: {
                                                            label: "頭痛",
                                                            "class-name": "mr-4"
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c("checkbox-common", {
                                                          attrs: {
                                                            label: "肩こり",
                                                            "class-name": "mr-4"
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c("checkbox-common", {
                                                          attrs: {
                                                            label: "腰痛",
                                                            "class-name": "mr-4"
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c("checkbox-common", {
                                                          attrs: {
                                                            label: "めまい"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(29)
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "qs-left" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-list__cell p-2"
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mt-17 mb-11"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "家族に不正咬合の方はいらっしゃいますか?"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("p", [
                                                      _vm._v(
                                                        "例:でこぼこ、出っ歯、反っ歯、受け口など"
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "ml-12" },
                                                      _vm._l(3, function(i) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: i,
                                                            staticClass:
                                                              "d-flex"
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(_vm._s(i))
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                class: [
                                                                  "pl-2 d-flex flex-column w-100",
                                                                  {
                                                                    "margin-top-6":
                                                                      i === 3
                                                                  }
                                                                ]
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "border-bottom-print"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                            続柄\n                                          "
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "border-bottom-print"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                            症状\n                                          "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      }),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(30)
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row page-break pt-50" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "question-list" }, [
                      _c("div", { staticClass: "d-flex w-100" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "print-component component-wrapper interview-checklist w-100"
                          },
                          [
                            _c("div", { staticClass: "bg-white" }, [
                              _c("div", { staticClass: "table-list" }, [
                                _c("div", { staticClass: "d-flex" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-50 d-flex table-print-left"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex w-100 flex-column"
                                        },
                                        [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "qs-left" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-list__cell is-first p-2"
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        "今まで耳鼻科を受診したことはありますか？"
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "ml-12" },
                                                      _vm._l(3, function(i) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: i,
                                                            staticClass:
                                                              "d-flex mb-16"
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(_vm._s(i))
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex flex-column flex-grow-1"
                                                              },
                                                              [
                                                                _vm._m(
                                                                  31,
                                                                  true
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pl-2 d-flex"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "checkbox-common",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "完治",
                                                                          "class-name":
                                                                            "mr-2"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "checkbox-common",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "現在通院中",
                                                                          "class-name":
                                                                            "mr-2"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "checkbox-common",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "症状が出たとき毎に通院"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      }),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(32)
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "qs-left" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-list__cell p-2"
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        "今までにかかった病気はありますか?"
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "ml-12" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex"
                                                              },
                                                              [
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "糖尿病",
                                                                      "class-name":
                                                                        "mr-4"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "心臓病",
                                                                      "class-name":
                                                                        "mr-4"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "高血圧",
                                                                      "class-name":
                                                                        "mr-4"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "低血糖"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex mt-6"
                                                              },
                                                              [
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "腎臓病",
                                                                      "class-name":
                                                                        "mr-4"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "肝臓病",
                                                                      "class-name":
                                                                        "mr-4"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "結核",
                                                                      "class-name":
                                                                        "mr-4"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "肝炎",
                                                                      "class-name":
                                                                        "ml-3"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-items-center w-100 mb-3 mt-8"
                                                              },
                                                              [
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "その他"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _vm._m(33)
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(34)
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "qs-left" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-list__cell p-2"
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        "今までに次のようなことがありましたか?"
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "ml-12" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex flex-column mr-4"
                                                              },
                                                              [
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "傷が化膿しやすい",
                                                                      "class-name":
                                                                        "margin-bottom-8"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "蕁麻疹が出やすい",
                                                                      "class-name":
                                                                        "margin-bottom-8"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "口内炎が出やすい",
                                                                      "class-name":
                                                                        "margin-bottom-8"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "下痢しやすい",
                                                                      "class-name":
                                                                        "margin-bottom-8"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "風邪をひきやすい",
                                                                      "class-name":
                                                                        "margin-bottom-8"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "無カタラーゼ症",
                                                                      "class-name":
                                                                        "margin-bottom-8"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex flex-column"
                                                              },
                                                              [
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "血が止まりにくい",
                                                                      "class-name":
                                                                        "margin-bottom-8"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "湿疹が出やすい",
                                                                      "class-name":
                                                                        "margin-bottom-8"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "歯が染みやすい",
                                                                      "class-name":
                                                                        "margin-bottom-8"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "喘息がある",
                                                                      "class-name":
                                                                        "margin-bottom-8"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "薬を飲むと胃が痛くなる",
                                                                      "class-name":
                                                                        "margin-bottom-8"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "光線過敏症",
                                                                      "class-name":
                                                                        "margin-bottom-8"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(35)
                                          ]),
                                          _vm._v(" "),
                                          _vm._m(36),
                                          _vm._v(" "),
                                          _vm._m(37),
                                          _vm._v(" "),
                                          _vm._m(38),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex flex-grow-1"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "qs-left" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "table-list__cell p-2 h-100"
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "margin-bottom-8"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "血圧の状態は?"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "ml-12"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex"
                                                            },
                                                            [
                                                              _c(
                                                                "checkbox-common",
                                                                {
                                                                  attrs: {
                                                                    "class-name":
                                                                      "mr-4",
                                                                    label:
                                                                      "高い"
                                                                  }
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "checkbox-common",
                                                                {
                                                                  attrs: {
                                                                    "class-name":
                                                                      "mr-4",
                                                                    label:
                                                                      "普通"
                                                                  }
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "checkbox-common",
                                                                {
                                                                  attrs: {
                                                                    "class-name":
                                                                      "mr-4",
                                                                    label:
                                                                      "低い"
                                                                  }
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "checkbox-common",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "不明"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "border-bottom-print my-16-19"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                        最高\n                                      "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "border-bottom-print"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                        最低\n                                      "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass:
                                                  "table-list__cell w-20 text-center d-flex flex-column justify-content-center qs-right"
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-50 d-flex table-print-right"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex w-100 flex-column"
                                        },
                                        [
                                          _vm._m(39),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "qs-left" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-list__cell p-2"
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "qs-title mtn-6"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                      身体全体で先天性の疾患や生まれつき気になる事、部位はありますか?\n                                    "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "ml-12" },
                                                      _vm._l(3, function(i) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: i,
                                                            staticClass:
                                                              "d-flex mb-10"
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(_vm._s(i))
                                                            ]),
                                                            _vm._v(" "),
                                                            _vm._m(40, true)
                                                          ]
                                                        )
                                                      }),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(41)
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "qs-left" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-list__cell p-2 h-100 pb-19"
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "m-0" },
                                                      [
                                                        _vm._v(
                                                          "\n                                      現在飲んでいる薬はありますか?\n                                    "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "ml-12" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex mt-10"
                                                              },
                                                              [
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "鎮痛剤 (痛み止め)",
                                                                      "class-name":
                                                                        "mr-4"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "ビタミン剤"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex mt-8"
                                                              },
                                                              [
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "ホルモン剤",
                                                                      "class-name":
                                                                        "mr-4"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "血圧の薬",
                                                                      "class-name":
                                                                        "mr-4"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "checkbox-common",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "糖尿病の薬"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "その他",
                                                                  "class-name":
                                                                    "my-8"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _vm._l(5, function(i) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: i,
                                                              staticClass:
                                                                "d-flex margin-bottom-6"
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(i)
                                                                )
                                                              ]),
                                                              _vm._v(" "),
                                                              _vm._m(42, true)
                                                            ]
                                                          )
                                                        })
                                                      ],
                                                      2
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(43)
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "qs-left" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-list__cell p-2"
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v("血液型;")
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "ml-12" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex margin-bottom-8"
                                                          },
                                                          [
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  "class-name":
                                                                    "mr-4",
                                                                  label: "A"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  "class-name":
                                                                    "mr-4",
                                                                  label: "B"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  "class-name":
                                                                    "mr-4",
                                                                  label: "O"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  "class-name":
                                                                    "mr-4",
                                                                  label: "AB"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "checkbox-common",
                                                              {
                                                                attrs: {
                                                                  label: "不明"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass:
                                                "w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right"
                                            })
                                          ]),
                                          _vm._v(" "),
                                          _vm._m(44)
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row page-break pt-15" }, [
                  _c("div", { staticClass: "col-12 pt-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "print-component component-wrapper interview-checklist interview-print-other mt-4"
                      },
                      [
                        _c("div", { staticClass: "bg-white" }, [
                          _c(
                            "table",
                            {
                              staticClass:
                                "table table-interview-check-list table-interview-check-list--bottom"
                            },
                            [
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", { staticClass: "pb-31 w-custom" }, [
                                    _c("p", { staticClass: "mt-14" }, [
                                      _vm._v(
                                        "以前に歯科医院で歯を抜いたことがありますか?"
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "ml-12" }, [
                                      _c("p", { staticClass: "mt-11" }, [
                                        _vm._v(
                                          "\n                              そのとき異常はありましたか?\n                            "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex mt-10" },
                                        [
                                          _c("checkbox-common", {
                                            attrs: {
                                              label: "気分が悪くなった",
                                              "class-name": "mr-4"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("checkbox-common", {
                                            attrs: {
                                              label: "血が止まりにくくなった"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center w-100 margin-top-6"
                                        },
                                        [
                                          _c("checkbox-common", {
                                            attrs: { label: "その他" }
                                          }),
                                          _vm._v(" "),
                                          _vm._m(45)
                                        ],
                                        1
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _vm._m(46),
                                  _vm._v(" "),
                                  _vm._m(47),
                                  _vm._v(" "),
                                  _vm._m(48)
                                ]),
                                _vm._v(" "),
                                _vm._m(49),
                                _vm._v(" "),
                                _vm._m(50)
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(51)
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "text-center pt-4 mb-0" }, [
      _c(
        "p",
        {
          staticClass:
            "border-bottom-print m-0 p-0 d-inline-block page-print__header"
        },
        [_vm._v("\n            矯正歯科初診問診票\n          ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { width: "150px", colspan: "3" } }, [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c("p", { staticClass: "m-0" }, [
            _vm._v(
              "\n                              （西暦）\n                            "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-end pr-2" }, [
            _c("span", { staticClass: "mr-5" }, [_vm._v("年")]),
            _vm._v(" "),
            _c("span", { staticClass: "mx-3" }, [_vm._v("月")]),
            _vm._v(" "),
            _c("span", { staticClass: "ml-5" }, [_vm._v("日")])
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "bg-highlight" }, [
        _vm._v("\n                          ローマ字\n                        ")
      ]),
      _vm._v(" "),
      _c("td"),
      _vm._v(" "),
      _c("td", { staticClass: "bg-highlight text-center" }, [
        _vm._v("\n                          年齢\n                        ")
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "col-data" }),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "col-data text-center align-middle bg-highlight",
          attrs: { rowspan: "2" }
        },
        [
          _c("p", { staticClass: "m-0" }, [
            _vm._v(
              "\n                            女性\n                          "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "m-0" }, [
            _vm._v(
              "\n                            のみ\n                          "
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "col-data text-center align-middle",
          attrs: { rowspan: "2" }
        },
        [
          _c("p", { staticClass: "m-0" }, [
            _vm._v(
              "\n                            現在妊娠中ですか?\n                          "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "m-0" }, [
            _vm._v(
              "\n                            はい・いいえ\n                          "
            )
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex items-center justify-content-between w-66" },
      [_c("span", [_vm._v("　(")]), _c("span", [_vm._v(")")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h4", { staticClass: "fs-17 lh-15" }, [
        _vm._v(
          "\n                    未成年の方は保護者の方の情報をご記入ください\n                  "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg-white" }, [
      _c(
        "table",
        {
          staticClass:
            "table table-interview-check-list table-interview-check-list--patient-info"
        },
        [
          _c("tbody", [
            _c("tr", [
              _c(
                "td",
                { staticClass: "bg-highlight", attrs: { width: "128px" } },
                [
                  _vm._v(
                    "\n                          ふりがな\n                        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("td", {
                staticClass: "font-weight-bold",
                attrs: { width: "324px" }
              }),
              _vm._v(" "),
              _c(
                "td",
                {
                  staticClass: "bg-highlight text-center",
                  attrs: { width: "88px" }
                },
                [
                  _vm._v(
                    "\n                          続柄\n                        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("td", { attrs: { width: "150px", colspan: "3" } })
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "bg-highlight" }, [
                _vm._v(
                  "\n                          氏名\n                        "
                )
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "font-weight-bold" }),
              _vm._v(" "),
              _c("td", { staticClass: "bg-highlight text-center" }, [
                _vm._v(
                  "\n                          ご連絡先\n                        "
                )
              ]),
              _vm._v(" "),
              _c("td", { attrs: { colspan: "3" } })
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "bg-highlight" }, [
                _c("p", { staticClass: "m-0" }, [
                  _vm._v(
                    "\n                            ご住所\n                          "
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "fs-13 lh-15 m-0" }, [
                  _vm._v("お住いが別の場合")
                ])
              ]),
              _vm._v(" "),
              _c(
                "td",
                {
                  staticClass: "font-weight-bold text-left",
                  attrs: { rowspan: "2", colspan: "3" }
                },
                [
                  _vm._v(
                    "\n                          〒\n                        "
                  )
                ]
              )
            ])
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pb-2" }, [
      _c("h4", [_vm._v("◆ご相談内容")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-between w-25" }, [
      _c("span", [_vm._v("　(")]),
      _vm._v(" "),
      _c("span", [_vm._v(")")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-between w-50" },
      [_c("span", [_vm._v("　(")]), _vm._v(" "), _c("span", [_vm._v(")")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("label", { staticClass: "fs-16" }, [
          _vm._v("3  .  気になる部位・症状について")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12" }, [
          _c("textarea", {
            staticClass: "border-white",
            staticStyle: { resize: "none" },
            attrs: { rows: "3" }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-between w-40" },
      [_c("span", [_vm._v("　(")]), _vm._v(" "), _c("span", [_vm._v(")")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("label", [_vm._v("3. 気になる部位・症状について")]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12" }, [
          _c("textarea", {
            staticClass: "border-white",
            staticStyle: { resize: "none" },
            attrs: { rows: "1" }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "mt-14" }, [
        _c("h4", { staticClass: "fs-18" }, [
          _vm._v(
            "◆事前問診　(※はい・いいえの該当する方を丸で囲み、具体的な内容についてご記入ください。)"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "qs-left" }, [
        _c("div", { staticClass: "table-list__cell is-first p-2" }, [
          _c("p", [_vm._v("他の医院で歯並びの相談をされた事はありますか?")]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-12" }, [
            _c("p", { staticClass: "border-bottom-print" }, [
              _vm._v(
                "\n                                        いつ頃\n                                      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "border-bottom-print" }, [
              _vm._v(
                "\n                                        医院名\n                                      "
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "table-list__cell is-first w-20 text-center d-flex flex-column justify-content-center p-6 qs-right"
        },
        [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "d-flex justify-content-between w-100 mb-13 fs-15 margin-top-6"
      },
      [_c("span", [_vm._v("(金属名:")]), _vm._v(" "), _c("span", [_vm._v(")")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "d-flex justify-content-between w-100 mb-13 fs-15 margin-top-6"
      },
      [_c("span", [_vm._v("(薬品名:")]), _vm._v(" "), _c("span", [_vm._v(")")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "d-flex justify-content-between w-100 mb-13 margin-top-6"
      },
      [
        _c("span", [_vm._v("(発症時期:")]),
        _vm._v(" "),
        _c("span", [_vm._v("原因植物:")]),
        _vm._v(" "),
        _c("span", [_vm._v(")")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "d-flex justify-content-between w-100 mb-13 fs-15 margin-top-6"
      },
      [
        _c("span", [_vm._v("(原因物質:")]),
        _vm._v(" "),
        _c("span", [_vm._v(")")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right"
      },
      [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "d-flex align-items-center justify-content-between w-other-custom"
      },
      [_c("span", [_vm._v("　(")]), _vm._v(" "), _c("span", [_vm._v(")")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right"
      },
      [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "qs-left" }, [
        _c("div", { staticClass: "table-list__cell p-2" }, [
          _c("p", { staticClass: "mt-23 mb-11" }, [
            _vm._v("喋りにくい言葉はありますか?")
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("(サ行、タ行が言いづらいなど)")]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-12" }, [
            _c("p", { staticClass: "border-bottom-print py-2" }),
            _vm._v(" "),
            _c("p", { staticClass: "border-bottom-print py-2 mb-39" })
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right"
        },
        [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right"
      },
      [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-12" }, [
      _c("p", { staticClass: "border-bottom-print" }, [
        _vm._v(
          "\n                                        いつ頃\n                                      "
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "border-bottom-print" }, [
        _vm._v(
          "\n                                        症状\n                                      "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "d-flex align-items-center justify-content-between w-other-custom1"
      },
      [_c("span", [_vm._v("　(")]), _vm._v(" "), _c("span", [_vm._v(")")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-20 text-center table-list__cell is-first d-flex flex-column justify-content-center p-6 qs-right"
      },
      [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "d-flex align-items-center justify-content-between w-other-custom mb-1"
      },
      [_c("span", [_vm._v("　(")]), _vm._v(" "), _c("span", [_vm._v(")")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right"
      },
      [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "qs-left" }, [
        _c("div", { staticClass: "table-list__cell p-2" }, [
          _c("p", { staticClass: "qs-title" }, [
            _vm._v("今まで顔や顎を強くぶつけた・ケガをした経験はあり ますか?")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-12" }, [
            _c("p", { staticClass: "border-bottom-print" }, [
              _vm._v(
                "\n                                        いつ頃\n                                      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "border-bottom-print" }, [
              _vm._v(
                "\n                                        部位\n                                      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "border-bottom-print mb-29" }, [
              _vm._v(
                "\n                                        対処法\n                                      "
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right"
        },
        [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right"
      },
      [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right"
      },
      [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pl-2 d-flex flex-column w-100" }, [
      _c("p", { staticClass: "border-bottom-print mb-11" }, [
        _vm._v(
          "\n                                              診断名\n                                            "
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "border-bottom-print" }, [
        _vm._v(
          "\n                                              いつ頃\n                                            "
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "border-bottom-print mb-10" }, [
        _vm._v(
          "\n                                              治療内容\n                                            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "table-list__cell is-first w-20 text-center d-flex flex-column justify-content-center p-6 qs-right"
      },
      [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "d-flex align-items-center justify-content-between w-other-custom mb-1"
      },
      [_c("span", [_vm._v("　(")]), _vm._v(" "), _c("span", [_vm._v(")")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right"
      },
      [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right"
      },
      [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "qs-left" }, [
        _c("div", { staticClass: "table-list__cell p-2" }, [
          _c("p", [_vm._v("喫煙習慣はありますか？")]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-12 mt-17" }, [
            _c("div", { staticClass: "d-flex" }, [
              _c("span", { staticClass: "mr-2" }, [_vm._v("一日量:")]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex w-75" }, [
                _c("p", { staticClass: "w-75 py-2 border-bottom-print" }),
                _vm._v(" "),
                _c("span", [_vm._v("本/日")])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right"
        },
        [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "qs-left" }, [
        _c("div", { staticClass: "table-list__cell p-2 h-100" }, [
          _c("p", { staticClass: "py-3" }, [_vm._v("飲酒習慣はありますか?")]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-12" }, [
            _c("div", { staticClass: "d-flex margin-bottom-6" }, [
              _c("p", [_vm._v("よく飲まれるお酒：")]),
              _vm._v(" "),
              _c("div", { staticClass: "my-3 border-bottom-print w-50" })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex" }, [
              _c("span", { staticClass: "mr-2" }, [_vm._v("一日量:")]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex w-75" }, [
                _c("p", { staticClass: "w-30 py-2 border-bottom-print" }),
                _vm._v(" "),
                _c("span", [_vm._v("ml または")]),
                _vm._v(" "),
                _c("p", { staticClass: "w-30 border-bottom-print" }),
                _vm._v(" "),
                _c("p", [_vm._v("合位")])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "table-list__cell w-20 text-center d-flex flex-column justify-content-center p-6 qs-right"
        },
        [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "qs-left" }, [
        _c("div", { staticClass: "table-list__cell p-2" }, [
          _c("div", { staticClass: "d-flex py-16" }, [
            _c("span", { staticClass: "mr-2" }, [_vm._v("身長:")]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex w-75" }, [
              _c("p", { staticClass: "w-30 py-2 border-bottom-print" }),
              _vm._v(" "),
              _c("span", [_vm._v("cm 体重")]),
              _vm._v(" "),
              _c("p", { staticClass: "w-30 border-bottom-print" }),
              _vm._v(" "),
              _c("p", [_vm._v("kg")])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "table-list__cell w-20 text-center align-middle p-6 qs-right"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "qs-left" }, [
        _c("div", { staticClass: "table-list__cell is-first p-2" }, [
          _c("p", [_vm._v("現在、通院されていますか?")]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-12" }, [
            _c("p", { staticClass: "border-bottom-print mb-11" }, [
              _vm._v(
                "\n                                        病院名\n                                      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "border-bottom-print" }, [
              _vm._v(
                "\n                                        受診科名\n                                      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "border-bottom-print mb-10" }, [
              _vm._v(
                "\n                                        主治医\n                                      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n                                        診断名・症状\n                                      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "border-bottom-print mt-27 mb-22" })
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "w-20 text-center table-list__cell is-first d-flex flex-column justify-content-center p-6 qs-right"
        },
        [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pl-2 d-flex flex-column w-100" }, [
      _c("p", { staticClass: "border-bottom-print mb-10" }, [
        _vm._v(
          "\n                                            診断名\n                                          "
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "border-bottom-print" }, [
        _vm._v(
          "\n                                            部位\n                                          "
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "border-bottom-print mb-10" }, [
        _vm._v(
          "\n                                            状態\n                                          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right"
      },
      [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pl-2 d-flex flex-column w-100" }, [
      _c("p", { staticClass: "mb-95" }, [
        _vm._v(
          "\n                                            薬品名\n                                          "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex" }, [
        _c("span", { staticClass: "mr-4" }, [_vm._v("服薬量:")]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex w-75" }, [
          _c("span", [_vm._v("1日")]),
          _vm._v(" "),
          _c("p", { staticClass: "w-25 m-0 border-bottom-print" }),
          _vm._v(" "),
          _c("span", [_vm._v("回")]),
          _vm._v(" "),
          _c("p", { staticClass: "w-25 m-0 border-bottom-print" }),
          _vm._v(" "),
          _c("p", { staticClass: "m-0" }, [
            _vm._v(
              "\n                                                錠/包/ml\n                                              "
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right"
      },
      [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "qs-left" }, [
        _c("div", { staticClass: "table-list__cell p-2 py-43" }, [
          _c("p", [_vm._v("女性の方のみ")]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-12" }, [
            _c("div", { staticClass: "d-flex" }, [
              _c("span", [_vm._v("初潮年齢")]),
              _vm._v(" "),
              _c("p", { staticClass: "w-25 py-2 border-bottom-print" }),
              _vm._v(" "),
              _c("span", [_vm._v("歳")]),
              _vm._v(" "),
              _c("p", { staticClass: "w-25 py-2 border-bottom-print" }),
              _vm._v(" "),
              _c("span", [_vm._v("ヶ月")])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "w-20 text-center table-list__cell d-flex flex-column justify-content-center p-6 qs-right"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "d-flex align-items-center justify-content-between w-other-custom mb-1"
      },
      [_c("span", [_vm._v("　(")]), _vm._v(" "), _c("span", [_vm._v(")")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "td",
      { staticClass: "align-middle text-center", attrs: { width: "70px" } },
      [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "w-custom" }, [
      _c("p", { staticClass: "margin-bottom-8" }, [
        _vm._v(
          "\n                            最近、歯科医院を受診されましたか?\n                          "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ml-12" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c("p", { staticClass: "w-10 py-2 border-bottom-print" }),
          _vm._v(" "),
          _c("span", [_vm._v("年")]),
          _vm._v(" "),
          _c("p", { staticClass: "w-10 py-2 border-bottom-print" }),
          _vm._v(" "),
          _c("span", [_vm._v("月ごろまたは")]),
          _vm._v(" "),
          _c("p", { staticClass: "w-10 py-2 border-bottom-print" }),
          _vm._v(" "),
          _c("span", [_vm._v("年")]),
          _vm._v(" "),
          _c("p", { staticClass: "w-10 py-2 border-bottom-print" }),
          _vm._v(" "),
          _c("span", [_vm._v("ヶ月前")])
        ])
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mt-10" }, [_vm._v("治療内容")]),
      _vm._v(" "),
      _c("div", { staticClass: "ml-12" }, [
        _c("p", { staticClass: "pt-4 border-bottom-print" }),
        _vm._v(" "),
        _c("span", [_vm._v("　")]),
        _vm._v(" "),
        _c("p", { staticClass: "padding-bottom-6 border-bottom-print" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "td",
      { staticClass: "align-middle text-center", attrs: { width: "70px" } },
      [_c("p", [_vm._v("はい")]), _vm._v(" "), _c("p", [_vm._v("いいえ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "pb-19" }, [
        _c("p", { staticClass: "margin-bottom-8" }, [
          _vm._v("転居の予定はありますか?")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ml-12" }, [
          _c("div", { staticClass: "d-flex" }, [
            _c("p", { staticClass: "w-25 py-2 border-bottom-print" }),
            _vm._v(" "),
            _c("span", [_vm._v("年")]),
            _vm._v(" "),
            _c("p", { staticClass: "w-25 py-2 border-bottom-print" }),
            _vm._v(" "),
            _c("span", [_vm._v("月ごろ")])
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "mb-4" }, [
            _vm._v(
              "\n                              転居先\n                            "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "py-2 border-bottom-print" })
        ])
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "align-middle text-center" }, [
        _c("p", [_vm._v("はい")]),
        _vm._v(" "),
        _c("p", [_vm._v("いいえ")])
      ]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "2" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "4" } }, [
        _c("p", [
          _vm._v("その他、診療に際してご要望などございましたらご記入ください。")
        ]),
        _vm._v(" "),
        _c("textarea", {
          staticClass: "border-white",
          staticStyle: { resize: "none" },
          attrs: { rows: "5" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-end" }, [
      _c("p", [_vm._v("ありがとうございました")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }