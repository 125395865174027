var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "patient-detail" },
    [
      _c("div", { staticClass: "patient-detail__content" }, [
        _c("div", { staticClass: "patient-detail__content__header" }, [
          _c("label", { staticClass: "patient-detail__content__title" }, [
            _vm._v(_vm._s(_vm.$t("objects.patient.text.patient_information")))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "patient-detail__content__action" },
            [
              _c("button-common", {
                staticClass: "button-v2--tertiary",
                on: { click: _vm.showModalEditHistory },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c("img", {
                          attrs: { src: "/images/icon-history-v2.svg" }
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("objects.patient.button.history")) +
                            "\n          "
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _vm._v(" "),
              _c("button-common", {
                staticClass: "button-v2--tertiary",
                on: { click: _vm.handleEditPatient },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c("img", {
                          attrs: { src: "/images/icon-edit-v2.svg" }
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("new_text.button.edit")) +
                            "\n          "
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _vm._v(" "),
              _vm.$store.getters.isAdmin
                ? _c("button-common", {
                    staticClass: "button-v2--delete",
                    on: { click: _vm.handleDeletePatient },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function() {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("new_text.button.delete_2")) +
                                  "\n          "
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2042168628
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "patient-detail__content__information" },
          [
            _c("patient-information", {
              attrs: {
                "patient-info": _vm.patient.info,
                "sf-treatmentend-status-c":
                  _vm.patient.sf_treatmentend_status_c,
                "sf-pre-contract-simulation":
                  _vm.patient.sf_pre_contract_simulation,
                "csl-brand-type": _vm.patient.csl_brand_type
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "patient-detail__content__system-information" },
          [
            _c(
              "label",
              {
                staticClass:
                  "patient-detail__content__title patient-detail__content__title--system-info"
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("objects.patient.text.system_information")) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c("patient-system-information", {
              attrs: { patient: _vm.patient }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "patient-detail__sidebar" }, [
        _c("div", { staticClass: "patient-detail__sidebar__tab" }, [
          _c(
            "span",
            {
              class: [
                "patient-detail__sidebar__tab-item",
                {
                  "patient-detail__sidebar__tab-item--active":
                    _vm.tab_active === "comment"
                }
              ],
              on: {
                click: function($event) {
                  _vm.tab_active = "comment"
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("new_text.comment")))]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              class: [
                "patient-detail__sidebar__tab-item",
                {
                  "patient-detail__sidebar__tab-item--active":
                    _vm.tab_active === "reservation_schedule"
                }
              ],
              on: {
                click: function($event) {
                  _vm.tab_active = "reservation_schedule"
                }
              }
            },
            [
              _vm._v(
                _vm._s(_vm.$t("objects.patient.text.reservation_schedule"))
              )
            ]
          )
        ]),
        _vm._v(" "),
        _vm.tab_active === "comment"
          ? _c(
              "div",
              { staticClass: "patient-detail__sidebar__content" },
              [_c("comment-tab")],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.tab_active === "reservation_schedule"
          ? _c(
              "div",
              { staticClass: "patient-detail__sidebar__content" },
              [
                _c("reservation-schedule", {
                  attrs: {
                    "first-reservation-id": _vm.patient.first_reservation_id,
                    "first-reservation-month":
                      _vm.patient.first_reservation_month,
                    "order-count": _vm.patient.order_count
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.is_show_modal_edit_history
        ? _c("modal-edit-history", {
            on: {
              close: function($event) {
                _vm.is_show_modal_edit_history = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_delete_patient
        ? _c("modal-verify-password", {
            attrs: { "form-info": _vm.form_info, "cancel-button": true },
            on: {
              "on-verified": _vm.submitDeletedPatient,
              close: function($event) {
                _vm.is_show_modal_delete_patient = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }