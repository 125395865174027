var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("sidebar-menu"),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "secretariat" },
        [
          _c("div", { staticClass: "col-xl-12 main-content" }, [
            _c("section", { staticClass: "content" }, [
              _c(
                "div",
                { staticClass: "inner" },
                [
                  _c(
                    "b-tabs",
                    {
                      attrs: {
                        "nav-wrapper-class": "tabs-nav-wrapper",
                        justified: ""
                      }
                    },
                    [
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            title: _vm.$t("reservation.spreadsheet"),
                            active: ""
                          }
                        },
                        [
                          _c("div", { staticClass: "column-filter" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selected_col,
                                    expression: "selected_col"
                                  }
                                ],
                                staticClass: "form-control border-blue",
                                staticStyle: { width: "90px", float: "right" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selected_col = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "all" } }, [
                                  _vm._v("全体")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "new" } }, [
                                  _vm._v("新規枠")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "existing" } }, [
                                  _vm._v("既存枠")
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "label-bg label-status" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "reservation.number_of_reservations_by_type"
                                  )
                                ) +
                                "\n                            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "table",
                            {
                              staticClass: "table b-table",
                              attrs: {
                                role: "table",
                                "aria-busy": "false",
                                "aria-colcount": "3"
                              }
                            },
                            [
                              _c("thead", { attrs: { role: "rowgroup" } }, [
                                _c("tr", { attrs: { role: "row" } }, [
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "table-b-table-default text-left",
                                      attrs: {
                                        role: "columnheader",
                                        scope: "col",
                                        "aria-colindex": "1"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            クリニック名\n                                        "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.selected_col == "all" ||
                                  _vm.selected_col == "new"
                                    ? _c(
                                        "th",
                                        {
                                          staticClass:
                                            "table-b-table-default text-center col-20",
                                          attrs: {
                                            role: "columnheader",
                                            scope: "col",
                                            "aria-colindex": "2"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            新規枠予約数\n                                        "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.selected_col == "all" ||
                                  _vm.selected_col == "existing"
                                    ? _c(
                                        "th",
                                        {
                                          staticClass:
                                            "table-b-table-default text-center col-20",
                                          attrs: {
                                            role: "columnheader",
                                            scope: "col",
                                            "aria-colindex": "3"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            既存枠予約数\n                                        "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                { attrs: { role: "rowgroup" } },
                                _vm._l(_vm.spread_sheet_items, function(item) {
                                  return _c("tr", { attrs: { role: "row" } }, [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-left",
                                        attrs: {
                                          "aria-colindex": "1",
                                          role: "cell"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(item.clinic_name) +
                                            "\n                                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.selected_col == "all" ||
                                    _vm.selected_col == "new"
                                      ? _c(
                                          "td",
                                          {
                                            staticClass: "text-center",
                                            attrs: {
                                              "aria-colindex": "2",
                                              role: "cell"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(item.new_number) +
                                                "\n                                        "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.selected_col == "all" ||
                                    _vm.selected_col == "existing"
                                      ? _c(
                                          "td",
                                          {
                                            staticClass: "text-center",
                                            attrs: {
                                              "aria-colindex": "3",
                                              role: "cell"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(item.existing_number) +
                                                "\n                                        "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                }),
                                0
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            title: _vm.$t("reservation.reservation_setting")
                          }
                        },
                        [
                          _c("span", { staticClass: "label-bg label-status" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "reservation.new_customer_reception_status"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("b-table", {
                            attrs: {
                              "sticky-header": "",
                              items: _vm.reservation_setting_items,
                              fields: _vm.reservation_setting_fields
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "cell(frame_reception_new)",
                                fn: function(ref) {
                                  var value = ref.value
                                  var item = ref.item
                                  return [
                                    _vm.$store.getters.isAdmin
                                      ? _c(
                                          "b-nav",
                                          {
                                            staticClass: "row-nav",
                                            attrs: { pills: "" }
                                          },
                                          [
                                            _c(
                                              "b-nav-item-dropdown",
                                              {
                                                staticClass: "box-nav-item",
                                                attrs: {
                                                  text: value,
                                                  left: "",
                                                  "data-id": item.id
                                                }
                                              },
                                              _vm._l(
                                                _vm.reservation_setting_actions,
                                                function(action) {
                                                  return _c(
                                                    "b-dropdown-item-button",
                                                    {
                                                      key: action.name,
                                                      attrs: {
                                                        active:
                                                          value == action.name,
                                                        value: action.value
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.eventClickDropdownItem
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(action.name) +
                                                          "\n                                            "
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "line-height": "50px"
                                            }
                                          },
                                          [_vm._v(_vm._s(value))]
                                        )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("Loading")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }