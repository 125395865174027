var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.className] },
    [
      _c("sub-question", {
        ref: "sub_question",
        attrs: {
          question: _vm.question,
          options: _vm.option_yes_no,
          checked: _vm.select_option
        },
        on: {
          handleAnswer: function($event) {
            return _vm.handleAnswer($event)
          }
        }
      }),
      _vm._v(" "),
      _c("div", { class: _vm.handleChildAnswerClass }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "color-blue" }, [_vm._v("一日量：")]),
          _vm._v(" "),
          _c("div", { staticClass: "inline-input-dose" }, [
            _c("div", { staticClass: "select-option form-inline" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.time,
                    expression: "time"
                  }
                ],
                staticClass: "form-control col-md-8 mr-2",
                attrs: { type: "text" },
                domProps: { value: _vm.time },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.time = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("span", [_vm._v("本/日")])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }