export default {
  date_of_visit: '来院日',
  description_rating: '説明評価',
  q2: {
    1: '十分かつ丁寧であった',
    2: '十分だった',
    3: '不十分だった',
    4: '説明はなかった'
  },
  total_answer: '回答数',
  summary: '(初診 {first} / 再診 {revisit})',
  survey_response_date: 'アンケート回答日',
  table: {
    arrived_at: '来院日時',
    q2: '説明評価',
    q5: 'おすすめ評価',
    type: '初診/再診',
    updated_at: '回答日時',
    v2q1: '治療評価'
  },
  text: {
    answer_list: '回答一覧',
    dashboard: 'ダッシュボード',
    evaluation_breakdown: '評価内訳',
    first_visit_evaluation: '初診説明評価',
    number_of_answers: '回答数',
    nps_text: 'NPS内訳数と構成比',
    re_visit_evaluation: '再診説明評価',
    re_visit_treatment_evaluation: '再診治療評価'
  },
  treatment_evaluation: '治療評価（初診はブランクのみ）',
  type: '初診／再診',
  type_all: '全て',
  type_first: '初診',
  type_revisit: '再診',
  v2q1: {
    1: '非常に満足',
    2: 'やや満足',
    3: 'どちらともいえない',
    4: 'やや不満',
    5: '非常に不満'
  }
}
