var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "22",
        viewBox: "0 0 16 22",
        fill: "#333"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-65)" } }, [
        _c("g", { attrs: { transform: "translate(73 7.5)" } }, [
          _c("g", { attrs: { transform: "translate(0)" } }, [
            _c("path", {
              attrs: {
                d:
                  "M291.252,224h-6.5a.748.748,0,0,0,0,1.5h6.5a.748.748,0,1,0,0-1.5Z",
                transform: "translate(-288 -223)"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M291.252,224h-6.5a.748.748,0,0,0,0,1.5h6.5a.748.748,0,1,0,0-1.5Z",
                transform: "translate(-288 -219)"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M288.252,224h-3.5a.748.748,0,0,0,0,1.5h3.5a.748.748,0,1,0,0-1.5Z",
                transform: "translate(-288 -215)"
              }
            })
          ])
        ]),
        _c("g", { attrs: { transform: "translate(64)" } }, [
          _c("g", { attrs: { transform: "translate(0)" } }, [
            _c("path", {
              attrs: {
                d:
                  "M79.5,3h-2V2.25a.75.75,0,0,0-.75-.75H75.123a2.252,2.252,0,0,0-4.245,0H69.25a.75.75,0,0,0-.75.75V3h-2A1.5,1.5,0,0,0,65,4.5v16A1.5,1.5,0,0,0,66.5,22h13A1.5,1.5,0,0,0,81,20.5V4.5A1.5,1.5,0,0,0,79.5,3ZM70,3h1.5a.75.75,0,0,0,.75-.75.75.75,0,1,1,1.5,0A.75.75,0,0,0,74.5,3H76V4.5H70Zm9.5,17.5h-13V4.5h2v.75a.75.75,0,0,0,.75.75h7.5a.75.75,0,0,0,.75-.75V4.5h2Z",
                transform: "translate(-64)"
              }
            })
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }