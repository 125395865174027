<template>
  <div class="patient-v2__left-sidebar">
    <ul :class="['patient-v2__sidebar', { 'patient-v2__sidebar--hide': isHideSideBar }]">
      <li>
        <router-link
          :class="{ 'link-active' : $route.name === 'patient.detail' }"
          :to="{ name: 'patient.detail' , params: { id: patientId } }"
        >
          <patient-info />
          <span>{{ $t('objects.nav.patient_info') }}</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'patient.activity.log', params: { id: patientId } }"
        >
          <patient-activity />
          <span>{{ $t('objects.nav.activity_log') }}</span>
        </router-link>
      </li>
      <li v-if="isInterview">
        <a
          :class="[
            $route.name === 'patient.interview'
              ? 'router-link-exact-active router-link-active'
              : '' ,
            {'lazy-load': lazy_load}
          ]"
          @click="handlePatientInterviewClick()"
        >
          <patient-interview />
          <span>{{ $t('objects.nav.interview_sheet') }}</span>
          <span
            v-if="!isInterviewCompleted"
            class="interview"
          >{{ $t('text.not_entered') }}</span>
        </a>
      </li>
      <li v-if="isClinicsDmr === '1' || isClinicsDmr === 1">
        <router-link
          :to="{ name: 'patients.fee', params:{ id: patientId } }"
        >
          <calculator />
          <span>{{ $t('objects.nav.accounting_info') }}</span>
        </router-link>
      </li>
      <li v-if="!$store.getters.isDisabledLabClinic">
        <router-link
          :class="[
            $route.name.includes('patient.orders')
              ? 'router-link-exact-active router-link-active'
              : ''
          ]"
          :to="{ name: 'patient.orders', params:{ id: patientId } }"
        >
          <order-list />
          <span>{{ $t('objects.nav.technician_order') }}</span>
        </router-link>
      </li>
      <li>
        <router-link
          :class="[
            $route.name.includes('patient.questionnaires')
              ? 'router-link-exact-active router-link-active'
              : ''
          ]"
          :to="{ name: 'patient.questionnaires', params: { id: patientId } }"
        >
          <patient-questionnaires />
          <span>{{ $t('objects.nav.questionnaire_list') }}</span>
        </router-link>
      </li>
    </ul>
    <modal-interview-s-m-s-sending
      v-if="show_sms_sending_modal"
      @close="show_sms_sending_modal = false"
      @detail-button-click="handleButtonDetailClick"
      @confirm-button-click="handleButtonConfirmClick"
    />
    <modal-confirm
      v-if="show_sms_sending_confirmation"
      class="modal-confirm--black"
      :title="$t('objects.patient.text.sms_sending_confirmation_title')"
      :text="smsSendingConfirmationMessage"
      @no="show_sms_sending_confirmation = false"
      @yes="handleSendSMS"
    />
  </div>
</template>

<script>
  import Calculator from '../../../components/svgs/Calculator'
  import PatientInfo from '../../../components/svgs/PatientInfo'
  import PatientActivity from '../../../components/svgs/PatientActivity'
  import PatientInterview from '../../../components/svgs/PatientInterview'
  import PatientQuestionnaires from '../../../components/svgs/PatientQuestionnaires'
  import OrderList from '../../../components/svgs/OrderList'
  import ModalInterviewSMSSending from './interview/ModalInterviewSMSSending'
  import ModalConfirm from '../../common/ModalConfirm'
  import { AVAILABLE_PHONE_NUMBER_INTERVIEW, RESPONSE_TYPE } from '../../../utils/constants'
  import { showSuccessModal, showWarningModal, showErrorModal, intVal } from '../../../utils/helpers'
  import { trans } from '../../../utils/translate'
  import orderMixin from '../../../mixins/order'

  export default {
    components: {
      Calculator,
      PatientInfo,
      PatientActivity,
      PatientInterview,
      OrderList,
      ModalInterviewSMSSending,
      ModalConfirm,
      PatientQuestionnaires
    },
    mixins: [orderMixin],
    props: {
      reservationId: {
        type: [String, Number],
        default: ''
      },
      patientId: {
        type: [String, Number],
        default: ''
      },
      isHideSideBar: {
        type: Boolean,
        default: false
      },
      isClinicsDmr: {
        type: [String, Number],
        default: 0
      },
      isInterview: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        lazy_load: false,
        show_sms_sending_modal: false,
        show_sms_sending_confirmation: false,
        is_test: false
      }
    },
    computed: {
      patientInfo () {
        return this.$store.getters.get_patientInformation
      },
      isInterviewCompleted: function () {
        return this.$store.getters.get_patientInterviewCompleted
      },
      smsSendingConfirmationMessage: function () {
        return trans(this.$t('objects.patient.message.sms_sending_confirmation'), { tel: this.patientInfo.tel || ' ' })
      }
    },
    watch: {
      'patientInfo.patient_id': function () {
        this.getPatientInterviewStatus()
      }
    },
    created () {
      this.is_test = this.$route.query.test
    },
    methods: {
      handlePatientInterviewClick: function () {
        if (this.isInterviewCompleted) {
          this.$router.push({ name: 'patient.interview' }).catch(() => {})
        } else {
          this.show_sms_sending_modal = true
        }
      },
      handleButtonConfirmClick: function () {
        if (this.patientInfo && this.patientInfo.tel) {
          const first3Digits = this.patientInfo.tel.substring(0, 3)

          if (
            (!AVAILABLE_PHONE_NUMBER_INTERVIEW.includes(first3Digits) || ![10, 11].includes(this.patientInfo.tel.length)) &&
            !this.is_test
          ) {
            showErrorModal(this.$t('objects.patient.message.phone_incorrect'))
          } else {
            this.show_sms_sending_confirmation = true
          }
        } else {
          showErrorModal(this.$t('objects.patient.message.phone_number_not_available'))
          this.$router.push({ name: 'patient.interview' }).catch(() => {})
        }
      },
      handleButtonDetailClick: function () {
        this.show_sms_sending_modal = false
        this.$router.push({ name: 'patient.interview' }).catch(() => {
        })
      },
      handleSendSMS: function () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('sendPatientInterviewSms', { patient_id: this.patientId, test: this.is_test })
          .then(() => {
            this.$store.commit('set_loading', false)
            this.show_sms_sending_confirmation = false
            this.show_sms_sending_modal = false

            showSuccessModal(this.$t('objects.patient.message.sms_sent'))
          })
          .catch((error) => {
            this.$store.commit('set_loading', false)
            this.show_sms_sending_confirmation = false
            this.show_sms_sending_modal = false

            const { response: { data: { results: { type } } } } = error
            if (typeof type !== 'undefined') {
              switch (type) {
                case RESPONSE_TYPE.NONE:
                  showWarningModal(this.$t('objects.patient.message.phone_number_empty'))
                  break
                case RESPONSE_TYPE.INVALID:
                  showWarningModal(this.$t('objects.patient.message.phone_number_not_available'))
                  break
                case RESPONSE_TYPE.EXPIRED:
                  showWarningModal(this.$t('objects.patient.message.sms_can_be_sent_if_reservation_exist'))
                  break
                default:
                  showWarningModal(this.$t('message.something_went_wrong'))
                  break
              }
            } else {
              showWarningModal(this.$t('message.something_went_wrong'))
            }
          })
      },
      getPatientInterviewStatus: function () {
        this.lazy_load = true
        this.$store.dispatch('getPatientInterviewStatus', this.patientInfo.patient_id)
          .finally(() => {
            this.lazy_load = false
          })
      }
    }
  }
</script>
