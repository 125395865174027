<template>
  <div>
    <common-modal-action
      class="modal-allocation"
      :title="$t('reservation.allocation.title')"
      @close="$emit('close')"
    >
      <template #body>
        <div class="modal-allocation__container">
          <div class="modal-allocation__group">
            <label class="modal-allocation__item modal-allocation__item--label">{{ $t('reservation.allocation.name') }}</label>
            <div class="modal-allocation__item-content">
              <common-input
                v-model="name_allocation"
                type="text"
                :class="['modal-allocation__item-content__name', {'common-input-error': errors.name_allocation }]"
                :placeholder="$t('new_text.placeholder.allocation_name')"
                maxlength="50"
                required="required"
                @input="handleNameChange"
              />
              <span
                v-if="errors.name_allocation"
                class="common-msg-error"
              >
                {{ errors.name_allocation }}
              </span>
            </div>
          </div>
          <div class="modal-allocation__group">
            <label class="modal-allocation__item modal-allocation__item--label">{{ $t('reservation.allocation.position') }}</label>
            <div class="modal-allocation__item-content modal-allocation__item-content--position">
              <common-select
                class="common-select--color-primary"
                :options="order_list"
                key-value="sortOrder"
                key-display="sortOrder"
                :default-value="sort_order"
                @change="sortOrder => sort_order = sortOrder"
              />
            </div>
          </div>
          <div class="modal-allocation__group">
            <label class="modal-allocation__item modal-allocation__item--label">{{ $t('reservation.allocation.auto_check') }}</label>
            <div class="modal-allocation__item-content modal-allocation__item-content--type">
              <common-select
                class="common-select--color-primary"
                :options="types"
                key-value="value"
                key-display="text"
                :default-value="selected_type"
                @change="value => selected_type = value"
              />
            </div>
          </div>
          <label class="modal-allocation__item modal-allocation__item--label modal-allocation__item--text">{{ $t('reservation.allocation.text') }}</label>
        </div>
      </template>
      <template #footer>
        <div class="modal-create-reservation__footer">
          <div class="modal-create-reservation__btn-delete">
            <common-button @click="handleClickDelete">
              <template #content>
                {{ $t('reservation.allocation.button.cancel') }}
              </template>
            </common-button>
          </div>
          <div class="modal-allocation__btn">
            <common-button
              class="modal-v2__button modal-v2__button--confirm"
              @click="handleSubmit"
            >
              <template #content>
                {{ isDetectAutoFrameOperation ? $t('new_text.button.next') : $t('new_text.button.save') }}
              </template>
            </common-button>
          </div>
        </div>
      </template>
    </common-modal-action>
    <modal-confirm-delete-allocation
      v-if="show_modal_delete_resource"
      :allocation-delete="allocation"
      :allocations="allocations"
      @close="show_modal_delete_resource = false"
      @succeed="handleDeleteAllocation"
    />
    <modal-operation-allocation-reason
      v-if="show_modal_operation_reason"
      :is-update="true"
      :resources="operation_allocation_reason_resources"
      @back="show_modal_operation_reason = false;"
      @confirm="handleAfterInputOperationReason"
    />
  </div>
</template>

<script>
  import { CommonButton, CommonInput, CommonModalAction, CommonSelect } from '../common'
  import { clone } from 'lodash'
  import { showErrorModal } from '../../utils/helpers'
  import { required, maxLength } from 'vuelidate/lib/validators'
  import { initData, resetSortOrder } from '../../mixins/reservation-allocation'
  import ModalConfirmDeleteAllocation from './ModalConfirmDeleteAllocation'
  import ModalOperationAllocationReason from './ModalOperationAllocationReason'
  import { COLUMN_TYPE } from '../../utils/constants'

  export default {
    components: {
      ModalConfirmDeleteAllocation,
      ModalOperationAllocationReason,
      CommonButton,
      CommonSelect,
      CommonModalAction,
      CommonInput
    },
    mixins: [initData, resetSortOrder],
    props: {
      allocation: {
        type: Object,
        default: () => ({})
      },
      selectedDate: {
        type: String,
        default: ''
      }
    },
    emits: ['close'],
    data () {
      return {
        show_modal_delete_resource: false,
        show_modal_operation_reason: false,
        operation_allocation_reason_resources: {},
        operation_allocation_reason_data: null,
        errors: {}
      }
    },
    validations () {
      return {
        name_allocation: { required, maxLength: maxLength(50) },
        sort_order: { required },
        selected_type: { required }
      }
    },
    computed: {
      allocationType () {
        return this.allocation.column_type || COLUMN_TYPE.NOT_AUTO
      },
      isDetectAutoFrameOperation () {
        return this.$store.getters.isDetectAutoFrameOperation &&
          (this.allocationType === COLUMN_TYPE.AUTO || this.allocationType === COLUMN_TYPE.CSL)
      },
      isStoreOperationAllocationReason () {
        return this.isDetectAutoFrameOperation && this.allocationType !== this.selected_type
      }
    },
    created () {
      this.getSortOrderList()
      this.getTypes()
      this.setData()
    },
    methods: {
      setData () {
        this.name_allocation = this.allocation.column_name
        this.sort_order = this.allocation.sortOrder
        this.selected_type = this.allocation.column_type
        const clinic = this.$store.getters.currentClinic || {}
        if ((!clinic || !clinic.is_support_csl) && this.selected_type === COLUMN_TYPE.CSL) {
          this.selected_type = COLUMN_TYPE.NOT_AUTO
        }
      },
      submit () {
        const postData = {
          id: this.allocation.id,
          column_name: this.name_allocation,
          column_type: this.selected_type,
          sortOrder: this.sort_order,
          is_selected: true
        }

        let indexAllocationEdit = null
        let indexAllocationByOrder = null
        this.allocations.forEach((item, index) => {
          if (item.id === this.allocation.id) {
            indexAllocationEdit = index
          }

          if (item.sortOrder === this.sort_order) {
            indexAllocationByOrder = index
          }
        })

        const allocations = clone(this.allocations)
        if (
          indexAllocationEdit !== null &&
          indexAllocationByOrder !== null &&
          indexAllocationEdit !== indexAllocationByOrder
        ) {
          if (indexAllocationByOrder < indexAllocationEdit) {
            allocations.splice(indexAllocationByOrder, 0, postData)
            indexAllocationEdit += 1
            allocations.splice(indexAllocationEdit, 1)
          } else {
            allocations.splice(indexAllocationEdit, 1)
            allocations.splice(indexAllocationByOrder, 0, postData)
          }
        } else {
          allocations[indexAllocationEdit] = postData
        }
        this.resetSortOrder(allocations, 'updated', this.isStoreOperationAllocationReason)
      },
      handleClickDelete () {
        this.show_modal_delete_resource = true
      },
      handleDeleteAllocation (message) {
        this.$emit('succeed', message)
      },
      validate () {
        this.$v.$touch()
        if (!this.$v.$anyError) {
          return true
        }

        const errors = []
        const errorMessages = {
          'name_allocation.required': this.$t('reservation.allocation.errors.required', { field: this.$t('reservation.allocation.name') }),
          'name_allocation.maxLength': this.$t('reservation.allocation.errors.maximum', { field: this.$t('reservation.allocation.name'), max: 50 }),
          'sort_order.required': this.$t('reservation.allocation.errors.required', { field: this.$t('reservation.allocation.position') }),
          'selected_type.required': this.$t('reservation.allocation.errors.required', { field: this.$t('reservation.allocation.auto_check') })
        }
        for (const key in errorMessages) {
          const parts = key.split('.')
          if (this.$v[parts[0]] && this.$v[parts[0]][parts[1]] === false) {
            this.errors[parts[0]] = errorMessages[key]
            errors.push(errorMessages[key])
          }
        }
        if (errors.length) {
          showErrorModal(errors.join('<br>'), { width: '440px' })

          return false
        }

        return true
      },
      handleNameChange (value) {
        this.errors.name_allocation = ''

        if (!value) {
          this.errors.name_allocation = this.$t('reservation.allocation.errors.required', { field: this.$t('reservation.allocation.name') })
        }
      },
      handleSubmit () {
        this.errors = {}
        if (!this.validate()) {
          return
        }

        if (!this.isStoreOperationAllocationReason) {
          this.show_modal_operation_reason = false
          this.submit()

          return
        }

        this.operation_allocation_reason_resources = {
          before_name: this.allocation.column_name || '',
          after_name: this.name_allocation,
          before_type: this.allocationType,
          after_type: this.selected_type,
          allocation_id: this.allocation.id
        }
        this.show_modal_operation_reason = true
      },
      handleAfterInputOperationReason (data) {
        this.operation_allocation_reason_data = data
        this.submit()
      }
    }
  }
</script>
