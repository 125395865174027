export default {
  activity_log_loading: 'アクティビティログを読み込んでいます...',
  invoice: {
    complete: '患者会計が完了しました',
    delete: '患者会計が削除されました',
    issue: '患者会計が発行されました',
    update: '患者会計が更新されました',
    re_issue: '患者会計が再発行されました'
  },
  medical_record: {
    create: 'カルテ#{id}が作成されました',
    delete: 'カルテ#{id}が削除されました',
    update: 'カルテ#{id}が更新されました'
  },
  patient: {
    cancel_check_in: '来院受付取り消し',
    check_in: 'チェックインしました',
    delete: '患者情報が削除されました',
    update: '患者情報が更新されました',
    recover: '患者情報の削除が取り消されました'
  },
  patient_interview: {
    complete: '問診票が完了しました',
    create: '問診票が作成されました'
  },
  patient_info_not_found: '「条件にあてはまる患者情報がみつかりません」',
  reservation: {
    accept: '{date} の新規予約を受け付けました',
    cancel: '{date} の新規予約がキャンセルされました',
    change_schedule: '新規予約日程が {date} に変更されました'
  }
}
