<template>
  <logo>
    <div class="d-flex justify-content-center error-page">
      <p>
        {{ $t('error_page.error') }}
      </p>
    </div>
    <div class="d-flex justify-content-center mt-3 mb-4 login_container">
      <router-link
        :to="{name: 'auth.login'}"
        class="btn login_btn"
      >
        {{ $t('common_page.btn_login') }}
      </router-link>
    </div>
  </logo>
</template>
<script>
  import logo from '../components/Logo'

  export default {
    components: {
      logo
    }
  }
</script>
