<template>
    <div v-if="validationErrors">
        <ul class="justify-content-center errors" :class="{'text-left' : textLeft}">
            <li v-for="(value, key, index) in validationErrors">{{ value }}</li>
        </ul>
    </div>
</template>
<script>
    export default {
        data(){
            return {

            }
        },
        name: 'validation-errors',
        props: {
            errors: {},
            textLeft: false,
        },
        computed: {
            validationErrors(){
                let errors = Object.values(this.errors);
                errors = errors.flat();
                return errors;
            }
        }
    }
</script>