var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-operation-reason modal-operation-reason--allocation"
    },
    [
      _c("modal-action", {
        attrs: {
          title: _vm.$t(
            "objects.operation_reason.send_the_reason_to_the_supervisor"
          )
        },
        on: {
          close: function($event) {
            return _vm.$emit("back")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("div", { staticClass: "modal-operation-reason__body" }, [
                  _c("div", { staticClass: "modal-operation-reason__info" }, [
                    _c("div", { staticClass: "modal-operation-reason__row" }, [
                      _c(
                        "label",
                        { staticClass: "modal-operation-reason__label" },
                        [_vm._v(_vm._s(_vm.$t("new_text.reservations_frame")))]
                      ),
                      _vm._v(" "),
                      _vm.isUpdate
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "modal-operation-reason__data d-flex align-items-center"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "modal-operation-reason__data__fixed-width"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.truncateString(
                                        _vm.resources.before_name || "",
                                        13
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("img", {
                                staticClass:
                                  "modal-operation-reason__data__divide",
                                attrs: { src: "/images/arrow_right_alt.svg" }
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.truncateString(
                                      _vm.resources.after_name || "",
                                      13
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        : _c(
                            "span",
                            { staticClass: "modal-operation-reason__data" },
                            [_vm._v(_vm._s(_vm.resources.before_name))]
                          )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-operation-reason__row" }, [
                      _c(
                        "label",
                        { staticClass: "modal-operation-reason__label" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("new_text.reservations_frame_auto"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isUpdate
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "modal-operation-reason__data d-flex align-items-center"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "modal-operation-reason__data__fixed-width"
                                },
                                [_vm._v(_vm._s(_vm.beforeTypeName))]
                              ),
                              _vm._v(" "),
                              _c("img", {
                                staticClass:
                                  "modal-operation-reason__data__divide",
                                attrs: { src: "/images/arrow_right_alt.svg" }
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.afterTypeName))])
                            ]
                          )
                        : _c(
                            "span",
                            { staticClass: "modal-operation-reason__data" },
                            [_vm._v(_vm._s(_vm.beforeTypeName))]
                          )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-operation-reason__content" },
                    [
                      _c(
                        "div",
                        { staticClass: "modal-operation-reason__row" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "modal-operation-reason__label modal-operation-reason__label--required"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("new_text.name")) +
                                  "\n              "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "modal-operation-reason__required"
                                },
                                [_vm._v(_vm._s(_vm.$t("new_text.required")))]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "modal-operation-reason__input" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.operator_name,
                                    expression: "operator_name"
                                  }
                                ],
                                ref: "operator_name",
                                class: [
                                  "input-default",
                                  {
                                    "common-input-error":
                                      _vm.errors.operator_name
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t("validation.required", {
                                    attribute: _vm.$t("new_text.name")
                                  })
                                },
                                domProps: { value: _vm.operator_name },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.operator_name = $event.target.value
                                    },
                                    function($event) {
                                      return _vm.validate("operator_name")
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _vm.errors.operator_name
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "common-msg-error error-message"
                                    },
                                    [_vm._v(_vm._s(_vm.errors.operator_name))]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "modal-operation-reason__row modal-operation-reason__row--operator-reason"
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "modal-operation-reason__label modal-operation-reason__label--operator-reason modal-operation-reason__label--required"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("new_text.reason")) +
                                  "\n              "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "modal-operation-reason__required"
                                },
                                [_vm._v(_vm._s(_vm.$t("new_text.required")))]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "modal-operation-reason__operator-reason"
                            },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.operation_reason,
                                    expression: "operation_reason"
                                  }
                                ],
                                staticClass:
                                  "textarea-default textarea-default--resize-none textarea-default--h-70",
                                class: [
                                  "modal-operation-reason__textarea",
                                  {
                                    "common-input-error":
                                      _vm.errors.operation_reason
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    "new_text.placeholder.please_enter"
                                  )
                                },
                                domProps: { value: _vm.operation_reason },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.operation_reason = $event.target.value
                                    },
                                    function($event) {
                                      return _vm.validate("operation_reason")
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _vm.errors.operation_reason
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "common-msg-error error-message"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.errors.operation_reason)
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "modal-operation-reason__note" },
                                [_vm._v(_vm._s(_vm.note))]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "modal-operation-reason__footer-default" },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-operation-reason__footer-group" },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "button-v2 button-v2--secondary button-v2--120",
                            on: {
                              click: function($event) {
                                return _vm.$emit("back")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("new_text.button.back")) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "button-v2 button-v2--primary button-v2--120",
                            attrs: { disabled: !_vm.isNotError },
                            on: { click: _vm.handleSubmit }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("new_text.button.send")) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }