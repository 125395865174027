var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.className] },
    [
      _c("sub-question", {
        ref: "sub_question",
        attrs: {
          question: _vm.question,
          options: _vm.option_yes_no,
          checked: _vm.select_option
        },
        on: {
          handleAnswer: function($event) {
            return _vm.handleAnswer($event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.handleChildAnswerClass },
        _vm._l(_vm.details, function(detail) {
          return _c(
            "div",
            { key: detail.detail_index, staticClass: "form-group block-group" },
            [
              _c("label", { staticClass: "block-group__first" }, [
                _vm._v(_vm._s(detail.detail_index))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "block-group__inline" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "color-blue" }, [
                    _vm._v("診断名：")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: detail.diagnostis,
                        expression: "detail.diagnostis"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "" },
                    domProps: { value: detail.diagnostis },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(detail, "diagnostis", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "color-blue" }, [
                    _vm._v("いつ頃：")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: detail.time,
                        expression: "detail.time"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "" },
                    domProps: { value: detail.time },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(detail, "time", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "color-blue" }, [
                    _vm._v("治療内容：")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: detail.content,
                        expression: "detail.content"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "" },
                    domProps: { value: detail.content },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(detail, "content", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-inline justify-content-between" },
                  [
                    _c("checkbox-common", {
                      attrs: { label: "完治", val: "完治" },
                      model: {
                        value: detail.selections,
                        callback: function($$v) {
                          _vm.$set(detail, "selections", $$v)
                        },
                        expression: "detail.selections"
                      }
                    }),
                    _vm._v(" "),
                    _c("checkbox-common", {
                      attrs: { label: "現在通院中", val: "現在通院中" },
                      model: {
                        value: detail.selections,
                        callback: function($$v) {
                          _vm.$set(detail, "selections", $$v)
                        },
                        expression: "detail.selections"
                      }
                    }),
                    _vm._v(" "),
                    _c("checkbox-common", {
                      attrs: {
                        label: "症状が出た時毎に通院",
                        val: "症状が出た時毎に通院"
                      },
                      model: {
                        value: detail.selections,
                        callback: function($$v) {
                          _vm.$set(detail, "selections", $$v)
                        },
                        expression: "detail.selections"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }