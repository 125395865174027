<template>
  <div class="interview-v2">
    <div class="content-wrapper content-wrapper--interview">
      <div :class="{ 'lazy-load lazy-load--big-space': lazy_load }">
        <div
          v-if="!lazy_load "
          id="patient-detail-print-area"
          class="patient-detail-left"
        >
          <InterviewConfirmation
            v-if="is_completed"
            :patient-info="patient_info"
            @print-interview-sheet="printInterviewSheet"
          />
          <InterviewInput
            v-else
            ref="interview_input"
            :patient-info="patient_info"
            :interview-info="interview_info"
            @show-confirm-save-modal="show_confirm_save_modal = true"
          />
        </div>
      </div>
    </div>
    <iframe
      v-if="!lazy_load && is_completed"
      :src="printInterviewSheetUrl"
      name="printInterview"
      @load="onLoadInterviewSheetCompletedIframe"
    />
    <Loading />
    <modal-confirm
      v-if="show_confirm_save_modal"
      :title="$t('objects.patient.text.modal_confirm_save.title')"
      :text="$t('objects.patient.text.modal_confirm_save.text')"
      @no="show_confirm_save_modal = false"
      @yes="show_confirm_save_modal = false; $refs.interview_input.save()"
    />
  </div>
</template>

<script>
  import Loading from '../../../components/Loading'
  import InterviewInput from '../../../components/patient/v2/interview/input/InterviewInput'
  import ModalConfirm from '../../../components/common/ModalConfirm'
  import InterviewConfirmation from '../../../components/patient/v2/interview/info/InterviewConfirmation'

  export default {
    name: 'Interview',
    components: {
      Loading,
      InterviewInput,
      ModalConfirm,
      InterviewConfirmation
    },
    emits: ['result-data'],
    data () {
      return {
        is_clinics_dmr: 0,
        patient_id: this.$route.params.id,
        patient_info: null,
        interview_info: null,
        first_time: null,
        show_confirm_save_modal: false,
        is_completed: 0,
        patient: {},
        lazy_load: true
      }
    },
    computed: {
      patientInfo () {
        return this.$store.getters.get_patientInformation
      },
      isCompletedInterview: function () {
        return this.$store.getters.get_patientInterviewCompleted
      },
      printInterviewSheetUrl: function () {
        return this.$router.resolve({
          name: 'patient.interview.print.answer',
          params: { id: this.patient_id }
        }).href
      },
      fillPatientFuriganaName () {
        if (this.patientInfo) {
          return `${this.patientInfo.kana_last_name || ''} ${this.patientInfo.kana_first_name || ''}`
        }

        return ''
      }
    },
    created () {
      document.title = this.$t('objects.page_title.patient').toString()
      if (localStorage.getItem('is_clinics_dmr')) {
        this.is_clinics_dmr = localStorage.getItem('is_clinics_dmr')
      }

      this.getPatientInterviewData()
    },
    methods: {
      getPatientInterviewData: function () {
        this.lazy_load = true
        this.$store.dispatch('getPatientInterviewData', this.$route.params.id).then((response) => {
          const {
            data: {
              results: {
                patient_info: patientInfo,
                interview_info: interviewInfo,
                first_time: firstTime,
                patient
              }
            }
          } = response
          this.patient_info = patientInfo
          this.interview_info = interviewInfo
          this.is_completed = interviewInfo ? interviewInfo.is_completed : 0
          this.first_time = firstTime
          this.patient = patient
          this.$emit('result-data', {
            path_name: this.$route.name,
            result: interviewInfo
          })
          if (patient && patientInfo && firstTime) {
            this.$store.commit('set_loadingDataPrintingStatus', false)
            this.$store.commit('set_firstTime', firstTime)
          }
          this.lazy_load = false
        }).catch(() => {
          this.lazy_load = false
        })
      },
      onLoadInterviewSheetCompletedIframe () {
        frames.printInterview.interviewData({
          patient_info: this.patient_info,
          interview_info: this.interview_info,
          first_time: this.first_time,
          patient: this.patient
        })
      },
      printInterviewSheet: function () {
        const title = window.parent.document.title
        document.title = window.parent.document.title = `${this.$t('new_text.questionnaire')}_${this.patient_id}_${this.patientInfo.full_name}`
        frames.printInterview.print()
        window.parent.document.title = title
      }
    }
  }
</script>
