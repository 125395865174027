export default {
  data () {
    return {
      sort_type: '',
      sort_field: null
    }
  },
  methods: {
    getIconSort (field) {
      if (field === this.sort_field && this.sort_type === 'DESC') {
        return '/images/icon-sort-desc.svg'
      }

      if (field === this.sort_field && this.sort_type === 'ASC') {
        return '/images/icon-sort-asc.svg'
      }

      return '/images/icon-sort-gray.svg'
    },
    setDataSort (field) {
      if (this.sort_field !== field) {
        this.sort_type = 'DESC'
      } else {
        this.sort_type = this.sort_type === 'DESC' ? 'ASC' : 'DESC'
      }

      this.sort_field = field
    }
  }
}
