<template>
  <div :class="[className]">
    <sub-question
      ref="sub_question"
      :question="question"
      :options="option_yes_no"
      :checked="select_option"
      @handleAnswer="handleAnswer($event)"
    />
    <div :class="handleChildAnswerClass">
      <div class="form-group">
        <checkbox-common
          v-model="selections"
          label="頭痛"
          val="頭痛"
        />
        <checkbox-common
          v-model="selections"
          label="肩こり"
          val="肩こり"
        />
        <checkbox-common
          v-model="selections"
          label="腰痛"
          val="腰痛"
        />
        <checkbox-common
          v-model="selections"
          label="めまい"
          val="めまい"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import SubQuestion from '../template/SubQuestion'
  import CheckboxCommon from '../template/CheckboxCommon'
  import { INTERVIEW_COMMON_OPTIONS, INTERVIEW_OPTIONS_NOT_ENTERED } from '../../../../utils/constants'
  import Common from './CommonFunction'

  export default {
    components: {
      SubQuestion,
      CheckboxCommon
    },
    mixins: [Common],
    props: {
      className: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        number: 8,
        select_option: '',
        question: '次のような症状はありますか？',
        option_yes_no: [
          INTERVIEW_COMMON_OPTIONS.YES,
          INTERVIEW_COMMON_OPTIONS.NO,
          INTERVIEW_OPTIONS_NOT_ENTERED
        ],
        selections: [],
        translate: {
          選択肢: 'selections'
        }
      }
    }
  }
</script>
