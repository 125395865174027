<template>
  <div class="select-reason-for-non-contract__grid-item">
    <div class="select-reason-for-non-contract__title">
      {{ title }}
    </div>
    <div
      v-for="item in reasons"
      :key="item.id"
      class="select-reason-for-non-contract__item"
    >
      <radio
        :label="$t(`${item.key}`)"
        :val="item.id"
        :is-checked="item.id === selectedId"
        name="reason"
        @select="$emit('choose-reason', item.id)"
      />
      <div
        v-if="isOtherReason(item.id)"
        class="select-reason-for-non-contract__other"
      >
        <input-common
          :default-value="otherReason"
          :disabled="isNotOtherReason"
          :placeholder="$t('objects.patient.placeholder.other_reason_placeholder')"
          :class="[{ 'common-input-error': errors.other_reason }]"
          @input="value => $emit('input-other', value)"
        />
        <span
          v-if="errors.other_reason"
          class="common-msg-error"
        >
          {{ errors.other_reason }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>

  import Radio from '../../../common/Radio.vue'
  import InputCommon from '../../../common/Input.vue'

  export default {
    components: { InputCommon, Radio },
    props: {
      title: {
        type: String,
        default: () => ''
      },
      reasons: {
        type: Array,
        default: () => []
      },
      otherReason: {
        type: String,
        default: () => ''
      },
      selectedId: {
        type: Number,
        default: () => 0
      },
      errors: {
        type: Object,
        default: () => {
          return { other_reason: '' }
        }
      }
    },
    data () {
      return {
        other_reason_id: 9
      }
    },
    computed: {
      isNotOtherReason () {
        return this.selectedId !== this.other_reason_id
      }
    },
    methods: {
      isOtherReason (id) {
        return id === this.other_reason_id
      }
    }
  }
</script>
