<template>
    <div :class="[className]">
        <sub-question
            ref="sub_question"
            :question="question"
            :options="option_yes_no"
            :checked="select_option"
            @handleAnswer="handleAnswer($event)"
        ></sub-question>
        <div v-bind:class="handleChildAnswerClass">
            <div class="form-group">
                <label class="color-blue">いつ頃：</label>
                <input v-model="time" class="form-control" type="text" name="">
            </div>
            <div class="form-group">
                <label class="color-blue">医院名：</label>
                <input v-model="name" class="form-control" type="text" name="">
            </div>
        </div>
    </div>
</template>

<script>
    import SubQuestion from '../template/SubQuestion'
    import { INTERVIEW_COMMON_OPTIONS } from '../../../../utils/constants'
    import Common from "./CommonFunction";

    export default {
        components: {
            SubQuestion
        },
        props: ['className'],
        mixins: [Common],
        data() {
            return {
                number: 1,
                select_option: INTERVIEW_COMMON_OPTIONS.NO,
                question: '他の医院で歯並びの相談をされた事はありますか？',
                time: '',
                name: '',
                details: [],
                option_yes_no: [
                    INTERVIEW_COMMON_OPTIONS.YES,
                    INTERVIEW_COMMON_OPTIONS.NO,
                ],
                translate: {いつ頃: 'time', 医院名: 'name'},
            }
        }
    }
</script>
