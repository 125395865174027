<template>
  <modal-action
    :title="$t('objects.patient.text.edit_history')"
    class="modal-v2--patient-edit-histories"
    @close="$emit('close')"
  >
    <template #body>
      <div class="modal-search-patient__content">
        <div class="modal-search-patient__tab-content">
          <table class="table">
            <thead>
              <tr>
                <th class="modal-search-patient__date">
                  {{ $t('objects.patient.text.edit_date') }}
                </th>
                <th class="modal-search-patient__sf-status" />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in patients_infos"
                :key="index"
              >
                <td class="modal-search-patient__data">
                  {{ formatDateTimeHistory(item.created_at, $t('new_text.datetime.year_month_day_hour_minute')) }}
                </td>
                <td class="modal-search-patient__sf-status">
                  <router-link
                    :to="{ name: 'patient.history', params: { id: patient_id, hid: item.id }, query: {reservation_id: reservation_id} }"
                    class="btn-v2 px-3"
                  >
                    <span>{{ $t('new_text.button.display') }}</span>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <infinite-loading
            :identifier="infinite_scroll_id"
            @infinite="infiniteScrollHandler"
          >
            <template #no-more>
              <span class="d-none" />
            </template>
            <template #no-results>
              <span class="d-none" />
            </template>
          </infinite-loading>
        </div>
      </div>
    </template>
  </modal-action>
</template>
<script>
  import InfiniteLoading from 'vue-infinite-loading'
  import ModalAction from '../common/ModalAction'
  import { formatDateTime, safeGet } from '../../utils/helpers'

  export default {
    components: {
      InfiniteLoading,
      ModalAction
    },
    data () {
      return {
        patient_id: '',
        reservation_id: '',
        infinite_scroll_id: 1,
        patients_infos: [],
        loading: false,
        patient_count: 0,
        count_loaded: 0
      }
    },
    created () {
      this.patient_id = this.$route.params.id
      this.reservation_id = this.$route.query.reservation_id
    },
    methods: {
      formatDateTimeHistory (value, format) {
        return formatDateTime(value, format)
      },
      infiniteScrollHandler: function ($state) {
        if (this.loading) {
          return
        }

        this.loading = true
        this.$store.dispatch('getPatientHistories', this.patient_id).then((result) => {
          const loadPatients = safeGet(result, 'data.results.info_histories') || []
          this.patient_count = result?.data?.results?.total || 0

          if (loadPatients.length) {
            this.patients_infos.push(...loadPatients)
            this.page++
            this.count_loaded += loadPatients.length

            if (this.count_loaded < this.patient_count) {
              $state.loaded()
            } else {
              if (this.page === 2) {
                $state.loaded()
                this.$refs.infiniteLoading.stateChanger.status = 0
              }
              $state.complete()
            }
          } else {
            if (this.page === 1) {
              $state.reset()
            }
            $state.complete()
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
          $state.complete()
        })
      }
    }
  }
</script>
