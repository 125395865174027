<template>
  <div class="page page-print page-print-answer">
    <main class="patient-detail-page patient-detail-print interview-print">
      <div class="col-12 main-content">
        <section class="content">
          <div class="content-wrapper content-wrapper--interview-print">
            <div class="d-flex justify-content-center align-items-center position-relative">
              <label
                v-if="shouldPrintBrandName"
                class="brand-name"
              >{{ getBrandName }}</label>
              <h3 class="text-center p-3 mb-0">
                <p class="border-bottom-print m-0 p-0 d-inline-block">
                  {{ isKid ? '小児' : '' }}矯正歯科初診問診票
                </p>
              </h3>
              <h4
                class="sf-user-id-tag"
              >
                患者ID: {{ patient.sf_user_id }}
              </h4>
            </div>
            <div class="row">
              <div class="col-5 d-flex pr-0">
                <div class="d-flex flex-column w-100">
                  <div class="pt-25 padding-left-2 pb-4">
                    <p class="border-bottom-print pl-10 ls-2 fs-15">
                      予約日時: <span class="ml-4">{{ patientDateBooking }}</span>
                    </p>
                  </div>
                  <div class="padding-left-2 pb-4">
                    <p class="border-bottom-print pl-10 ls-2 fs-15">
                      患者ID: <span class="ml-4">{{ patient.sf_user_id }}</span>
                    </p>
                  </div>
                  <h4 class="fs-18 margin-top-3">◆患者様情報</h4>
                </div>
              </div>
              <div class="col-7">
                <InterviewCheckList />
              </div>
            </div>
            <div class="row">
              <div class="col-12 patient-detail-left patient-info-interview mt-0">
                <div class="print-component component-wrapper interview-checklist">
                  <div class="bg-white">
                    <table class="table  table-interview-check-list table-interview-info">
                      <tbody>
                        <tr>
                          <td
                            width="60px"
                            class="bg-highlight font-weight-bold"
                          >
                            ふりがな
                          </td>
                          <td width="200px">
                            {{ truncateContent(`${patient_info.kana_last_name} ${patient_info.kana_first_name}`) }}
                          </td>
                          <td
                            width="80px"
                            class="bg-highlight font-weight-bold"
                          >
                            生年月日
                          </td>
                          <td
                            width="200px"
                            colspan="3"
                          >
                            <div class="d-flex justify-content-end">
                              <span class="mx-5">{{ birthOfDate.year }}年</span>
                              <span class="mx-5">{{ birthOfDate.month }}月</span>
                              <span class="ml-5">{{ birthOfDate.day }}日</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="bg-highlight font-weight-bold">
                            ローマ字
                          </td>
                          <td>
                            {{ truncateContent(`${patient_info.roma_last_name} ${patient_info.roma_first_name}`) }}
                          </td>
                          <td class="bg-highlight font-weight-bold">
                            年齢
                          </td>
                          <td
                            colspan="3"
                            class="col-data text-center"
                          >
                            {{ patientAge }}歳
                          </td>
                        </tr>
                        <tr>
                          <td
                            rowspan="3"
                            class="bg-highlight font-weight-bold align-middle"
                          >
                            氏名
                          </td>
                          <td
                            rowspan="3"
                            class="text-left align-middle"
                          >
                            {{ truncateContent(`${patient_info.last_name} ${patient_info.first_name}`) }}
                          </td>
                          <td class="bg-highlight font-weight-bold">
                            性別
                          </td>
                          <td
                            class="col-data"
                            colspan="3"
                          >
                            <span class="mr-2">{{ fillSex }}</span>
                            <span>{{ patientPregnancy }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="bg-highlight font-weight-bold">
                            {{ isKid ? '習い事・スポーツ' : 'ご職業' }}
                          </td>
                          <td
                            colspan="3"
                            class="col-data"
                          >
                            {{ isKid ? formatGuardianInfo.lessonSport : fillProfessions }}
                          </td>
                        </tr>
                        <tr>
                          <td class="bg-highlight font-weight-bold">
                            携帯電話
                          </td>
                          <td
                            colspan="3"
                            class="col-data"
                          >
                            {{ patient_info.tel }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="bg-highlight"
                          >
                            ご住所
                          </td>
                          <td
                            rowspan="2"
                            colspan="5"
                            class="text-left"
                          >
                            〒 {{ patient_info.zip_code }}
                            <p>{{ patient_info.address }}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <h4>
                      未成年の方は保護者の方の情報をご記入ください
                    </h4>
                  </div>
                  <div class="bg-white guardian-info guardian--first">
                    <table class="table table-interview-info table-interview-check-list">
                      <tbody>
                        <tr>
                          <td
                            width="60px"
                            class="bg-highlight font-weight-bold"
                          >
                            ふりがな
                          </td>
                          <td
                            width="200px"
                          >
                            {{ truncateContent(formatGuardianInfo.kanaName) }}
                          </td>
                          <td
                            class="bg-highlight font-weight-bold"
                            width="80px"
                          >
                            続柄
                          </td>
                          <td
                            width="200px"
                            colspan="3"
                          >
                            {{ formatGuardianInfo.relationship }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            rowspan="2"
                            class="bg-highlight font-weight-bold align-middle"
                          >
                            氏名
                          </td>
                          <td
                            rowspan="2"
                            class="text-left align-middle"
                          >
                            {{ truncateContent(formatGuardianInfo.name) }}
                          </td>
                          <td class="bg-highlight font-weight-bold">
                            ご連絡先
                          </td>
                          <td
                            colspan="3"
                            class="col-data"
                          >
                            {{ formatGuardianInfo.phone }}
                          </td>
                        </tr>
                        <tr>
                          <td class="bg-highlight font-weight-bold">
                            ご職業
                          </td>
                          <td
                            colspan="3"
                            class="col-data"
                          >
                            {{ fillGuardianOccupation }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="bg-highlight"
                          >
                            <p>ご住所</p>
                            <p>お住いが別の場合</p>
                          </td>
                          <td
                            rowspan="2"
                            colspan="5"
                            class="text-left"
                          >
                            〒
                            <p>{{ formatGuardianInfo.address }}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="bg-white guardian-info guardian--second">
                    <h4>
                      {{ $t('interview.guardian_info') }}
                    </h4>
                    <table class="table table-interview-info table-interview-check-list">
                      <tbody>
                        <tr>
                          <td
                            width="60px"
                            class="bg-highlight font-weight-bold"
                          >
                            ふりがな
                          </td>
                          <td
                            width="200px"
                          >
                            {{ truncateContent(formatGuardianInfo.guardian2KanaName) }}
                          </td>
                          <td
                            class="bg-highlight font-weight-bold"
                            width="80px"
                          >
                            続柄
                          </td>
                          <td
                            width="200px"
                            colspan="3"
                          >
                            {{ formatGuardianInfo.guardian2Relationship }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            rowspan="2"
                            class="bg-highlight font-weight-bold align-middle"
                          >
                            氏名
                          </td>
                          <td
                            rowspan="2"
                            class="text-left align-middle"
                          >
                            {{ truncateContent(formatGuardianInfo.guardian2Name) }}
                          </td>
                          <td class="bg-highlight font-weight-bold">
                            ご連絡先
                          </td>
                          <td
                            colspan="3"
                            class="col-data"
                          >
                            {{ formatGuardianInfo.guardian2Phone }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3 page-break-after">
              <div class="col-12">
                <h4 class="pb-2">◆ ご相談内容</h4>
                <p>{{ getConsultationItem }}</p>
                <p>ご相談内容・気になる症状について</p>
                <div
                  v-for="(symptom, index) in getConsultationPointsSymptoms"
                  :key="index"
                  class="mx-2"
                >
                  <p class="m-0">
                    優先順位{{ index + 1 }}
                  </p>
                  <p class="m-0">
                    部位: <span class="ml-2">{{ getPartName(symptom.part, symptom.part_detail) }}</span>
                  </p>
                  <p class="m-0">
                    歯列番号: <span class="ml-2">{{ getDentitionNumber(symptom.dentition_number) }}</span>
                  </p>
                  <p class="m-0">
                    症状: <span class="ml-2">{{ getSymptoms(symptom) }}</span>
                  </p>
                  <p class="m-0 symptoms-comment">
                    <span>{{ getSymptomsComment (symptom) }}</span>
                  </p>
                  <p class="m-0">
                    主訴:
                  </p>
                  <p class="mb-4">
                    {{ symptom.consultation_content }}
                  </p>
                  <span>&#12288;</span>
                </div>
              </div>
            </div>
            <div class="row page-break">
              <div class="col-12">
                <h4
                  class="pt-3 text-right"
                >
                  患者ID: {{ patient.sf_user_id }}
                </h4>
                <h4>
                  ◆ 事前問診
                </h4>
                <interview-sheet
                  v-if="is_interview_fetched"
                  :interview-info="getInterviewsheet"
                />
              </div>
            </div>
            <other :other="getOther" />
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script>
  import InterviewCheckList from '../../components/patient/interview/print/InterviewCheckListPrint'
  import InterviewSheet from '../../components/patient/interview/print_answer/InterviewSheet'
  import helpers, { truncateContent } from '../../utils/helpers'
  import moment from 'moment'
  import { FEMALE, PROFESSIONS, PROFESSION_OTHER_ID, OTHER_STRING, KIDS_BRAND_TYPE, SYMPTOMS_TYPE } from '../../utils/constants'
  import { find, result, isEmpty, get } from 'lodash'
  import Other from '../../components/patient/interview/print_answer/Other'

  export default {
    components: { Other, InterviewCheckList, InterviewSheet },
    props: {
      patientInfo: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {
        patient_info: {},
        interview_info: {},
        patient: {},
        first_time: {},
        is_interview_fetched: false,
        patient_id: this.$route.params.id,
        symptoms_type: SYMPTOMS_TYPE
      }
    },
    computed: {
      patientDateBooking () {
        if (this.first_time) {
          const startedTo = this.first_time.started_to
          const endedFrom = this.first_time.ended_from
          if (startedTo && endedFrom) {
            const dateStarted = moment(startedTo).format('YYYY年MM月DD日')
            const timeStarted = moment(startedTo).format('HH:mm')
            const timeEnded = moment(endedFrom).format('HH:mm')
            return `${dateStarted} ${timeStarted} - ${timeEnded}`
          }
        }
        return ''
      },
      birthOfDate () {
        const date = moment(new Date(this.patient_info.birth_of_date), 'YYYY-MM-DD')
        return {
          year: date.format('YYYY'),
          month: date.format('MM'),
          day: date.format('DD')
        }
      },
      isValidBirthday () {
        return moment(new Date(this.patient_info.birth_of_date), 'YYYY-MM-DD').isValid()
      },
      patientAge () {
        return this.isValidBirthday ? moment().diff(moment(this.patient_info.birth_of_date, 'YYYY-MM-DD'), 'years') : ''
      },
      isShowGuardian () {
        return this.patientAge < 18
      },
      fillSex () {
        return this.patient_info.gender === FEMALE ? '女性' : '男性'
      },
      patientPregnancy () {
        if (this.patient_info.gender === FEMALE) {
          return this.patient_info.pregnancy_status ? '(現在妊娠中)' : '(妊娠していない)'
        }
        return ''
      },
      fillProfessions () {
        if (this.patient_info.occupation !== PROFESSION_OTHER_ID) {
          return result(find(PROFESSIONS, (obj) => {
            return obj.id === this.patient_info.occupation
          }), 'label')
        } else {
          return this.patient_info.other_occupations
        }
      },
      formatGuardianInfo () {
        const lessonSport = `${this.patient_info.lesson_sport || ''}`

        if (this.isShowGuardian) {
          const kanaName = `${this.patient_info.guardian_kana_last_name || ''}${this.patient_info.guardian_kana_first_name || ''}`
          const name = `${this.patient_info.guardian_last_name || ''}${this.patient_info.guardian_first_name || ''}`
          const relationship = `${this.patient_info.guardian_relationship || ''}`
          const phone = `${this.patient_info.guardian_phone_number || ''}`
          const address = `${this.patient_info.guardian_address || ''}`

          const guardian2KanaName = `${this.patient_info.guardian2_kana_last_name || ''}${this.patient_info.guardian2_kana_first_name || ''}`
          const guardian2Name = `${this.patient_info.guardian2_last_name || ''}${this.patient_info.guardian2_first_name || ''}`
          const guardian2Relationship = `${this.patient_info.guardian2_relationship || ''}`
          const guardian2Phone = `${this.patient_info.guardian2_phone_number || ''}`

          return {
            kanaName,
            name,
            relationship,
            phone,
            address,
            lessonSport,
            guardian2KanaName,
            guardian2Name,
            guardian2Relationship,
            guardian2Phone
          }
        }

        return {
          lessonSport
        }
      },
      getConsultationItem () {
        if (typeof this.interview_info.interview_info === 'undefined') {
          return ''
        }

        const consultation_content = this.interview_info.interview_info.consultation_content

        if (typeof consultation_content === 'undefined' || !Array.isArray(consultation_content.consultation_item)) {
          return ''
        }

        if (consultation_content.consultation_item.includes(OTHER_STRING)) {
          consultation_content.consultation_item = consultation_content.consultation_item.filter(e => e !== OTHER_STRING);
          consultation_content.consultation_item.push(consultation_content.consultation_item_other);
        }

        return consultation_content.consultation_item.toString();
      },
      getConsultationPointsSymptoms () {
        if (typeof this.interview_info.interview_info !== 'undefined') {
          return this.interview_info.interview_info.consultation_content.points_symptoms
        }

        return []
      },
      getInterviewsheet () {
        if (typeof this.interview_info.interview_info !== 'undefined') {
          return this.interview_info.interview_info.interview_sheet
        }
        return []
      },
      getOther () {
        if (typeof this.interview_info.interview_info !== 'undefined') {
          return this.interview_info.interview_info.others
        }
        return {}
      },
      isKid () {
        return this.patient_info.brand_type === KIDS_BRAND_TYPE
      },
      fillGuardianOccupation () {
        if (!this.isShowGuardian) {
          return ''
        }

        const occupation = PROFESSIONS.find((element) => this.patient_info.guardian_occupation === element.id)

        return occupation?.id === PROFESSION_OTHER_ID ? this.patient_info.guardian_other_occupations : occupation?.label
      },
      getBrandName () {
        return get(this.patient_info, 'brand_master.name', '')
      },
      shouldPrintBrandName () {
        return !this.isKid && !isEmpty(this.getBrandName)
      }
    },
    watch: {
      patientInfo (patientInfo, oldPatientInfo) {
        if (patientInfo) {
          this.patient_info = patientInfo.patient_info
          this.interview_info = patientInfo.interview_info
          this.patient = patientInfo.patient
          this.first_time = patientInfo.first_time
          this.is_interview_fetched = true
          document.title = `${this.$t('text.questionnaire')}_${this.patient_id}_${this.patient_info.full_name}`
        }
      }
    },
    async mounted () {
      if (this.$route.params.id) {
        window.interviewData = this.setInterviewData.bind(this)
      }
      if (this.patientInfo) {
        this.patient_info = this.patientInfo.patient_info
        this.interview_info = this.patientInfo.interview_info
        this.patient = this.patientInfo.patient
        this.first_time = this.patientInfo.first_time
        this.is_interview_fetched = true
      }
    },
    methods: {
      truncateContent,
      getDentitionNumber (value) {
        return value ? value.toString() : ''
      },
      getPartName (part, detail) {
        if (!part && !detail) {
          return ''
        }
        return `${part}-${detail}`
      },
      getSymptoms (symptom) {
        if (symptom.symptoms === OTHER_STRING) {
          return symptom.symptoms_other
        }
        return symptom.symptoms
      },
      getSymptomsComment (symptom) {
        if (symptom.symptoms === this.symptoms_type.MOUTH || symptom.symptoms === this.symptoms_type.FRONT_TEETH_DO_NOT_CLOSE || symptom.symptoms === this.symptoms_type.OFF_CENTER) {
          return this.$t('interview.symptoms_type.mouth')
        } else if (symptom.symptoms === this.symptoms_type.GUMMY_SMILE) {
          return this.$t('interview.symptoms_type.gummy')
        } else if (symptom.symptoms === this.symptoms_type.LOGO) {
          return this.$t('interview.symptoms_type.logo')
        }
      },
      setInterviewData (data) {
        const {
          patient_info: patientInfo,
          interview_info: interviewInfo,
          first_time: firstTime,
          patient
        } = data

        this.patient_info = patientInfo
        this.interview_info = interviewInfo
        this.first_time = firstTime
        this.patient = patient
        this.is_interview_fetched = true
      }
    }
  }
</script>
