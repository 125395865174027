<template>
  <div class="event-card">
    <div
      v-if="event.id && event.extendedProps.blocked === 0"
      :class="['event-card__reservation', { 'event-card__reservation--grey-background': reservationOverTime}]"
    >
      <div
        :class="['event-card__duty', { 'event-card__duty--border': Object.keys(duty).length === 0, 'event-card__duty--min': period === 5 }]"
        :style="`background-color: ${duty.color || default_duty_color}`"
      >
        <span class="event-card__btn-move">
          <img
            v-if="Object.keys(duty).length === 0"
            class="event-card__btn-move"
            src="/images/icon-move-black.svg"
          >
          <img
            v-else
            class="event-card__btn-move"
            src="/images/icon-move.svg"
          >
        </span>
      </div>
      <div :class="['event-card__body', { 'event-card__body--min': period === 5 }]">
        <div class="event-card__patient">
          <p
            :class="['event-card__patient__name', { 'event-card__patient__name--min': period === 5 }]"
            @click.stop="handleClick('patientDetail')"
          >
            {{ patientInfo.name || '' }}<span>{{ getAge }}</span>
          </p>
          <p
            v-if="period > 5"
            :class="['event-card__patient__id', { 'event-card__patient__id--with-duties-name': period === 20 }]"
          >
            {{ patient.sf_user_id || patient.id }}
            <span
              v-if="period === 20 && brandName"
              :class="{ 'event-card__brand': true, 'event-card__brand--not-duties': !getDutiesName }"
            >{{ brandName }}</span>
            <span v-if="period === 20">{{ getDutiesName }}</span>
            <label
              v-if="isShowQuestionnaireButton"
              class="button-v2 button-v2--questionnaire"
              @click.stop="moveToPatientQuestionnaires('patientQuestionnaires', patient.id, event.id, $event)"
            >
              {{ $t('new_text.questionnaire_2') }}
            </label>
          </p>
          <span
            v-if="showIconInterview && period > 5"
            :class="[
              'event-card__interview',
              { 'event-card__interview--incomplete': event.extendedProps.interview_status === 0 }
            ]"
          >問</span>
        </div>
        <span
          v-if="brandName && period > 20"
          :class="{ 'event-card__brand': true, 'event-card__brand--not-duties': !getDutiesName }"
        >{{ brandName }}</span>
        <div
          v-if="period > 10 && period !== 20"
          :class="['event-card__duty-name', { 'event-card__duty-name--min': period < 40 }]"
        >
          {{ getDutiesName }}
        </div>
        <div
          v-if="displayMemo"
          :class="['event-card__memo', { 'event-card__memo--small': period <= 20 }]"
        >
          <span :style="`-webkit-line-clamp: ${memoLine}`">{{ event.extendedProps.memo }}</span>
        </div>
        <div
          v-if="
            (period > 30 || !event.extendedProps.memo)
              && staffs.length !== 0
          "
          class="event-card__staff"
        >
          <span
            v-if="staffs.length > 1"
            v-tooltip.bottom-start="{ content: showStaffsTooltip, trigger: 'hover', autoHide: true, html: false }"
          >
            {{ `${showStaffPosition(staffs[0].staff_info.position.name)} ${staffs[0].staff_info.name}` }}
            <span v-if="staffs.length - 1 !== 0">
              {{ `+${staffs.length - 1}` }}
            </span>
          </span>
          <span v-else>
            {{ `${showStaffPosition(staffs[0].staff_info.position.name)} ${staffs[0].staff_info.name}` }}
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="event.id && event.extendedProps.blocked === 1"
      class="event-card__blocked"
    >
      <div class="event-card__name-block">
        {{ $t('common.block') }}
      </div>
      <div
        v-if="period > 10 && event.extendedProps.memo"
        class="event-card__memo-block"
      >
        <span :style="`-webkit-line-clamp: ${memoBlockLine}`">{{ event.extendedProps.memo }}</span>
      </div>
    </div>
    <div
      v-if="!event.id"
      :class="['event-card__empty', { 'event-card__empty--anchor': period > 30 }]"
    >
      <div>
        <p>{{ startTime }}〜{{ endTime }}</p>
        <p>({{ period }}<span>{{ $t('common.minute') }}</span>)</p>
      </div>
    </div>
  </div>
</template>

<script>
  import * as moment from 'moment-timezone'
  import 'moment/locale/ja'
  import helpers from '../../utils/helpers.js'
  import { sortBy, get, filter, head } from 'lodash'

  export default {
    props: {
      event: {
        type: Object,
        default: () => {}
      },
      isInterview: {
        type: Number,
        default: 0
      },
      isKids: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        default_duty_color: 'transparent'
      }
    },
    computed: {
      period: function () {
        const startedTime = new Date(this.event.start)
        const endedTime = new Date(this.event.end)

        return parseInt((endedTime - startedTime) / (1000 * 60))
      },
      startTime: function () {
        return moment(this.event.start).format('HH:mm')
      },
      endTime: function () {
        return moment(this.event.end).format('HH:mm')
      },
      duties: function () {
        return this.event.extendedProps.duties || []
      },
      duty: function () {
        return this.duties.length > 0 ? (this.duties[0].master.duty || {}) : {}
      },
      dutyMaster: function () {
        return this.duties.length > 0 ? (this.duties[0].master || {}) : {}
      },
      getDutiesName: function () {
        let dutiesName = ''
        for (let i = 0; i < this.duties.length; i++) {
          dutiesName += this.duties[i].master.duty_name + ', '
        }
        return dutiesName.substring(0, dutiesName.length - 2)
      },
      patient: function () {
        return this.event.extendedProps.patient || {}
      },
      patientInfo: function () {
        return this.event.extendedProps.patient_info || {}
      },
      getAge: function () {
        if (this.event.extendedProps.patient_info) {
          return helpers.getAgeString(this.event.extendedProps.patient_info.birth_of_date, '')
        }

        return ''
      },
      showIconInterview: function () {
        const isFirstReservation = this.event.extendedProps.patient &&
          (Number(this.event.extendedProps.patient.first_reservation_id) === Number(this.event.id))

        return isFirstReservation &&
          this.isInterview &&
          this.event.extendedProps.interview_status !== null
      },
      staffs: function () {
        if (this.event.extendedProps.staffs && this.event.extendedProps.staffs.length !== 0) {
          return sortBy(this.event.extendedProps.staffs, [function (o) {
            return o.staff_info.position.ordering
          }])
        }

        return []
      },
      displayMemo: function () {
        return (this.period >= 20 && this.event.extendedProps.memo) ||
          (this.period < 30 && !this.dutyMaster.duty_name && this.event.extendedProps.memo)
      },
      memoLine: function () {
        if (this.period === 15 || this.period === 20) {
          return 1
        }

        if (this.period === 30 || this.period === 35) {
          if (this.dutyMaster.duty_name) {
            if (this.staffs.length === 0) {
              return 2
            }
            return 1
          }
          return 2
        }

        const line = (this.period - 35) / 5

        if (this.dutyMaster.duty_name) {
          if (this.staffs.length === 0) {
            return line + 1
          }
          return line
        } else {
          if (this.staffs.length === 0) {
            return line + 3
          }
          return line + 2
        }
      },
      memoBlockLine: function () {
        if (this.period === 15) {
          return 1
        }

        if (this.period === 20) {
          return 2
        }

        const line = (this.period - 25) / 5
        return line + 2
      },
      reservationOverTime: function () {
        return this.event.id && moment().diff(moment(this.event.extendedProps.ended_from)) > 0
      },
      isShowQuestionnaireButton () {
        return this.event.extendedProps.has_completed_questionnaire || false
      },
      brandName: function () {
        const brand = head(filter(
          this.$t('objects.patient.brand_master_filter'),
          origin => origin.type === this.event.extendedProps.csl_brand_type)
        )
        const brandName = get(brand, 'name', '')
        if (brandName) {
          return `${brandName} ${this.$t('new_text.csl_character')}`
        }

        if (parseInt(this.event.id) === this.patient.first_reservation_id) {
          return get(this.patientInfo, 'brand_master.name') || ''
        }

        return ''
      }
    },
    methods: {
      handleClick (type) {
        this.$emit(type, this.event)
      },
      showStaffsTooltip () {
        let staffs = ''

        for (const [key, value] of Object.entries(this.staffs)) {
          if (key > 0) {
            const info = `${this.showStaffPosition(value.staff_info.position.name)} ${value.staff_info.name}\n`
            staffs = staffs + info
          }
        }

        return staffs
      },
      showStaffPosition: function (staffPosition) {
        return helpers.showStaffPosition(staffPosition)
      },
      moveToPatientQuestionnaires (type, patientId, reservationId) {
        this.$emit(type, { patienId: patientId, reservationId: reservationId })
      }
    }
  }
</script>
