<template>
    <logo>
        <div class="d-flex justify-content-center send-email">
            <p>
                {{ $t('reset_password_complete.text') }}
            </p>
        </div>
        <div class="d-flex justify-content-center mt-3 mb-4 login_container">
            <router-link :to="{name: 'dashboard'}" class="btn login_btn">
                {{ $t('common_page.btn_login') }}
            </router-link>
        </div>
    </logo>
</template>
<script>
    import logo from '../components/Logo'

    export default {
        components: {
            logo
        },
        created() {
            document.title = this.$t('page_title.u1_5');
        },
    }
</script>
