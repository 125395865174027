var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "search-order search-order--instruction-admin",
      attrs: { name: "search-order" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _c("div", { staticClass: "search-order__fields" }, [
        _c("div", { staticClass: "search-order__top" }, [
          _c("div", { staticClass: "search-order__row" }, [
            _c(
              "div",
              { staticClass: "search-order__group" },
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("objects.orders.text.order_date")))
                ]),
                _vm._v(" "),
                _c("select-date", {
                  attrs: {
                    id: "order_date",
                    "is-read-only": false,
                    "default-date": _vm.data_search.order_date
                  },
                  on: {
                    change: function(value) {
                      return (_vm.data_search.order_date = value)
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "search-order__group search-order__group--clinic"
              },
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("objects.orders.text.clinic_name")))
                ]),
                _vm._v(" "),
                _c("dropdown-filter-common", {
                  attrs: {
                    options: _vm.optionsClinic,
                    "default-value": _vm.data_search.sf_clinic_id,
                    "empty-value": _vm.$t("new_text.no_selection"),
                    placeholder: _vm.$t("new_text.placeholder.please_select")
                  },
                  on: { change: _vm.changeClinic }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "search-order__group search-order__unprinted" },
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("objects.orders.text.order_details")))
                ]),
                _vm._v(" "),
                _c("common-checkbox", {
                  attrs: {
                    label: _vm.$t("objects.orders.text.unprinted"),
                    "is-checked": !!_vm.data_search.is_unprint_technician_info,
                    value: true
                  },
                  on: {
                    input: function(value) {
                      return (_vm.data_search.is_unprint_technician_info = value
                        ? 1
                        : 0)
                    }
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "search-order__row" }, [
            _c(
              "div",
              { staticClass: "search-order__group" },
              [
                _c("label", [_vm._v(_vm._s(_vm.$t("new_text.patient_id")))]),
                _vm._v(" "),
                _c("input-search", {
                  staticClass: "input-default",
                  attrs: {
                    "default-value": _vm.data_search.sf_user_id,
                    placeholder: _vm.$t("new_text.placeholder.patient_id")
                  },
                  on: {
                    search: _vm.searchIDPatient,
                    input: function(value) {
                      return (_vm.data_search.sf_user_id = value)
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "search-order__group" },
              [
                _c("label", [_vm._v(_vm._s(_vm.$t("new_text.patient_name")))]),
                _vm._v(" "),
                _c("input-search", {
                  staticClass: "input-default",
                  attrs: {
                    "default-value": _vm.data_search.patient_name,
                    placeholder: _vm.$t("new_text.placeholder.patient_name"),
                    type: "search-name"
                  },
                  on: {
                    search: _vm.searchNamePatient,
                    input: function(value) {
                      return (_vm.data_search.patient_name = value)
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "search-order__group" },
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("objects.orders.text.g_number")))
                ]),
                _vm._v(" "),
                _c("common-input", {
                  attrs: {
                    placeholder: _vm.$t("objects.orders.placeholder.g_number"),
                    "default-value": _vm.data_search.code
                  },
                  on: {
                    input: function(value) {
                      return (_vm.data_search.code = value)
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "search-order__group search-order__group--latest"
              },
              [
                _c("label", [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t("objects.orders.text.order_received_date")
                      ) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("select-date", {
                  attrs: {
                    id: "order_received_date",
                    "is-read-only": false,
                    "default-date": _vm.data_search.order_received_date
                  },
                  on: {
                    change: function(value) {
                      return (_vm.data_search.order_received_date = value)
                    }
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "search-order__bottom" }, [
          _c(
            "h3",
            {
              staticClass: "search-order__bottom__title",
              on: {
                click: function($event) {
                  ;[
                    (_vm.is_show_bottom = !_vm.is_show_bottom),
                    _vm.$emit("show-search-detail")
                  ]
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("new_text.button.advanced_search")) +
                  "\n        "
              ),
              _c("img", {
                attrs: {
                  src: _vm.is_show_bottom
                    ? "/images/blue-arrow-up.svg"
                    : "/images/blue-arrow-down.svg"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "h3",
            {
              staticClass:
                "search-order__bottom__title search-order__bottom__title--sp",
              on: {
                click: function($event) {
                  ;[
                    (_vm.is_show_bottom = !_vm.is_show_bottom),
                    _vm.$emit("show-search-detail")
                  ]
                }
              }
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("new_text.button.advanced_search")))
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("objects.orders.text.close_advanced_search")
                    ) +
                    "\n          "
                ),
                _c("img", {
                  attrs: {
                    src: _vm.is_show_bottom
                      ? "/images/blue-arrow-up.svg"
                      : "/images/blue-arrow-down.svg"
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.is_show_bottom,
                  expression: "is_show_bottom"
                }
              ],
              staticClass: "search-order__bottom__content"
            },
            [
              _c("div", { staticClass: "search-order__row" }, [
                _c(
                  "div",
                  { staticClass: "search-order__group" },
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("objects.orders.text.order_id")))
                    ]),
                    _vm._v(" "),
                    _c("common-input", {
                      attrs: {
                        placeholder: _vm.$t(
                          "objects.orders.placeholder.order_id"
                        ),
                        "default-value": _vm.data_search.order_id
                      },
                      on: {
                        input: function(value) {
                          return (_vm.data_search.order_id = value)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "search-order__group" },
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("objects.orders.text.course_name")))
                    ]),
                    _vm._v(" "),
                    _c("dropdown-filter-common", {
                      attrs: {
                        options: _vm.optionsProduct,
                        "default-value": _vm.data_search.product_id,
                        "empty-value": _vm.$t("new_text.no_selection"),
                        placeholder: _vm.$t(
                          "new_text.placeholder.please_select"
                        )
                      },
                      on: {
                        change: function(value) {
                          return (_vm.data_search.product_id = value)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "search-order__group" },
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("objects.orders.text.count")))
                    ]),
                    _vm._v(" "),
                    _c("common-input", {
                      attrs: {
                        maxlength: "2",
                        placeholder: _vm.$t("objects.orders.placeholder.count"),
                        "default-value": _vm.data_search.count
                      },
                      on: {
                        input: function(value) {
                          return (_vm.data_search.count = value)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "search-order__group search-order__group--latest"
                  },
                  [
                    _c("label", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "objects.orders.text.estimated_shipping_date"
                            )
                          ) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("select-date", {
                      attrs: {
                        id: "order_shipping_date",
                        "is-read-only": false,
                        "default-date": _vm.data_search.order_shipping_date,
                        "is-position-bottom": true
                      },
                      on: {
                        change: function(value) {
                          return (_vm.data_search.order_shipping_date = value)
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "search-order__row" }, [
                _c(
                  "div",
                  {
                    class: [
                      "search-order__group",
                      {
                        "search-order__group--large": !_vm.isShowPresscutCompleteDate
                      }
                    ]
                  },
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("objects.orders.text.setup_staff")))
                    ]),
                    _vm._v(" "),
                    _c("dropdown-filter-common", {
                      attrs: {
                        options: _vm.options_designer,
                        "default-value": _vm.data_search.worker_setup_id,
                        "empty-value": _vm.$t("new_text.no_selection"),
                        placeholder: _vm.$t(
                          "new_text.placeholder.please_select"
                        )
                      },
                      on: {
                        change: function(value) {
                          return (_vm.data_search.worker_setup_id = value)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      "search-order__group",
                      {
                        "search-order__group--large": !_vm.isShowPresscutCompleteDate
                      }
                    ]
                  },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(_vm.$t("objects.orders.text.setup_press_cut"))
                      )
                    ]),
                    _vm._v(" "),
                    _c("dropdown-filter-common", {
                      attrs: {
                        options: _vm.options_designer,
                        "default-value": _vm.data_search.worker_presscut_id,
                        "empty-value": _vm.$t("new_text.no_selection"),
                        placeholder: _vm.$t(
                          "new_text.placeholder.please_select"
                        )
                      },
                      on: {
                        change: function(value) {
                          return (_vm.data_search.worker_presscut_id = value)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "search-order__group search-order__group--multi-select-date"
                  },
                  [
                    _c("label", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "objects.orders.text.presscut_complete_date_label"
                            )
                          ) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c("select-date", {
                          attrs: {
                            id: "sf_presscut_complete_date__c_start",
                            "is-read-only": false,
                            "default-date":
                              _vm.data_search
                                .sf_presscut_complete_date__c_start,
                            "is-position-bottom": true
                          },
                          on: {
                            change: function(value) {
                              return (_vm.data_search.sf_presscut_complete_date__c_start = value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "split" }, [_vm._v("〜")]),
                        _vm._v(" "),
                        _c("select-date", {
                          attrs: {
                            id: "sf_presscut_complete_date__c_end",
                            "is-read-only": false,
                            "default-date":
                              _vm.data_search.sf_presscut_complete_date__c_end,
                            "is-position-bottom": true
                          },
                          on: {
                            change: function(value) {
                              return (_vm.data_search.sf_presscut_complete_date__c_end = value)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search-order__button" },
        [
          _c("common-button", {
            staticClass:
              "search-order__button__submit search-order__btn search-order__btn--submit",
            attrs: { type: "submit" },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("new_text.button.search")) +
                        "\n      "
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _vm._v(" "),
          _c("common-button", {
            staticClass: "search-order__button__research search-order__btn",
            on: {
              click: function($event) {
                return _vm.$emit("set-hide-search-content-in-sp")
              }
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("new_text.research")) +
                        "\n      "
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _vm._v(" "),
          _c("common-button", {
            class: [
              "search-order__button__reset search-order__btn search-order__btn",
              {
                "search-order__button__reset--active":
                  _vm.enableResetSearch || _vm.resetActive
              }
            ],
            attrs: { disabled: !_vm.enableResetSearch && !_vm.resetActive },
            on: { click: _vm.resetSearchResult },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("new_text.button.reset")) +
                        "\n      "
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.is_show_modal_search_name
        ? _c("modal-search-name-patient", {
            attrs: {
              "sf-clinic-id": _vm.data_search.sf_clinic_id,
              keyword: _vm.data_search.patient_name
            },
            on: {
              select: _vm.setPatient,
              close: function($event) {
                _vm.is_show_modal_search_name = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_search_id
        ? _c("modal-search-id-patient", {
            attrs: {
              "sf-clinic-id": _vm.data_search.sf_clinic_id,
              keyword: _vm.data_search.sf_user_id
            },
            on: {
              select: _vm.setPatient,
              close: function($event) {
                _vm.is_show_modal_search_id = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_search_responsive
        ? _c("search-patient", {
            attrs: {
              "keyword-id": _vm.data_search.sf_user_id,
              "keyword-name": _vm.data_search.patient_name,
              "search-by-id": _vm.search_by_id,
              "is-required-sf-user-id": true
            },
            on: { select: _vm.setPatient, close: _vm.closeSearchPatientSP }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }