var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info-order info-order--draft" }, [
    _c("div", { staticClass: "info-order__row" }, [
      _c("div", { staticClass: "info-order__group" }, [
        _c("label", [_vm._v(_vm._s(_vm.$t("objects.orders.text.patient_id")))]),
        _vm._v(" "),
        _c("div", { staticClass: "info-order__patient" }, [
          _c("span", [_vm._v(_vm._s(_vm.form.sf_user_id))]),
          _vm._v(" "),
          _c("div", { staticClass: "info-order__line" })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "info-order__group" }, [
        _c("label", [
          _vm._v(_vm._s(_vm.$t("objects.orders.text.patient_name")))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-order__patient" }, [
          _c("span", { staticClass: "three-dot" }, [
            _vm._v(_vm._s(_vm.form.name_info))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info-order__line" })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "info-order__group" }, [
        _c("label", [
          _vm._v(_vm._s(_vm.$t("objects.orders.text.patient_romaji_name")))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-order__patient" }, [
          _c("span", { staticClass: "three-dot" }, [
            _vm._v(
              _vm._s(_vm.form.roma_last_name) +
                " " +
                _vm._s(_vm.form.roma_first_name)
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info-order__line" })
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "info-order__row info-order__row--custom" }, [
      _c("div", { staticClass: "info-order__group" }, [
        _c("label", [
          _vm._v(_vm._s(_vm.$t("objects.orders.text.clinic_name")))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-order__patient" }, [
          _c("span", { staticClass: "three-dot three-dot--one-line" }, [
            _vm._v(_vm._s(_vm.form.formal_name))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info-order__line" })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "info-order__group" }, [
        _c("label", [
          _vm._v(_vm._s(_vm.$t("objects.orders.text.person_in_charge")))
        ]),
        _vm._v(" "),
        _vm.form.staffs_name
          ? _c("div", { staticClass: "info-order__patient" }, [
              _c("span", { staticClass: "three-dot three-dot--one-line" }, [
                _vm._v(_vm._s(_vm.form.staffs_name.toString()))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "info-order__line" })
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "info-order__group" }, [
        _c("label", [_vm._v(_vm._s(_vm.$t("objects.orders.text.clean_line")))]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "info-order__patient info-order__order-clinic-status"
          },
          [
            _c("span", { staticClass: "three-dot three-dot--one-line" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "objects.orders.text.order_visit_number_option." +
                      _vm.form.order_visit_number
                  )
                )
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-order__line" })
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "info-order__row info-order__row--full-width" }, [
      _c(
        "div",
        { staticClass: "info-order__group info-order__delivery-date" },
        [
          _c("label", [
            _vm._v(_vm._s(_vm.$t("objects.orders.text.delivery_date")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info-order__patient" }, [
            _c("span", [_vm._v(_vm._s(_vm.form.delivery_date))])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }