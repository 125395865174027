<template>
    <div :class="['calculator-panel calculator-main', isShown ? 'active' : '']">
        <div class="d-flex justify-content-center h-100">
            <div class="calc calculator" ref="calcBody" tabindex="0" @focus="onFocus" @blur="onBlur">
                <div class="display">
                    <input type="text" disabled="disabled" class="result"
                           :value="expression_show ? expression_show : 0" v-if="0"/>
                    <div class="wrapper">
                        <span>{{expression_show ? expression_show : 0}}</span>
                    </div>
                </div>
                <div class="add-define">
                    <button @click="plusWithSnippet(1000)">+1,000円</button>
                    <button @click="plusWithSnippet(10000)">+10,000円</button>
                    <button @click="plusWithSnippet(100000)">+100,000円</button>
                </div>
                <p class="row">
                    <button class="control-action" @click="removeOneCharacter()"><img :src="'/images/backspace.svg'">
                    </button>
                    <button class="control-action" @click="clearAll()">C</button>
                    <button class="control-action" @click="divisionOperator()">÷</button>
                    <button class="control-action w-90" @click="multiplicationOperator()">×</button>
                </p>
                <p class="row">
                    <button @click="addToExpression(7)">7</button>
                    <button @click="addToExpression(8)">8</button>
                    <button @click="addToExpression(9)">9</button>
                    <button class="control-action w-90" @click="subtractionOperator()">−</button>
                </p>
                <p class="row">
                    <button @click="addToExpression(4)">4</button>
                    <button @click="addToExpression(5)">5</button>
                    <button @click="addToExpression(6)">6</button>
                    <button class="control-action w-90" @click="additionOperator()">+</button>
                </p>
                <p class="row">
                    <button @click="addToExpression(1)">1</button>
                    <button @click="addToExpression(2)">2</button>
                    <button @click="addToExpression(3)">3</button>
                    <button class="tall w-90" @click="calculateExpression(3)"><img :src="'/images/enter.svg'"></button>
                </p>
                <p class="row">
                    <button @click="addToExpression(0)">0</button>
                    <button class="dote" @click="addToExpression('.')">.</button>
                </p>
            </div>

        </div>
    </div>
</template>
<script>
    import eventBus from '../utils/event-bus'

    const KEYBOARD_NUMBERS_MAP = {
        48: 0, 49: 1, 50: 2, 51: 3, 52: 4, 53: 5, 54: 6, 55: 7, 56: 8, 57: 9,
        96: 0, 97: 1, 98: 2, 99: 3, 100: 4, 101: 5, 102: 6, 103: 7, 104: 8, 105: 9,
        110: '.', 190: '.'
    };
    const KEYBOARD_SYMBOLS_DEVIDE = [111, 191];
    const KEYBOARD_SYMBOLS_PLUS = [107, 186, 187];
    const KEYBOARD_SYMBOLS_MULTIPLY = [106, 222, 186, 187];
    const KEYBOARD_SYMBOLS_SUBTRACT = [109, 187, 189];
    const KEYBOARD_ENTER = [13];
    const KEYBOARD_DELETE = [8, 46];
    const KEYBOARD_ESCAPE = [27];

    export default {
        name: 'calculator',
        data() {
            return {
                expression_show: '',
                expression_calculate: '',
                should_send_result: false,
                isShown: false,
                isFocused: false,
            }
        },
        created: function () {
            let this_vue = this;
            eventBus.$on('calculator_set_default', function (val) {
                this_vue.setDefault(val);
            });
        },
        mounted: function () {
            let this_vue = this;
            $(document).on('keyup', function (event) {
                if (!this_vue.isFocused) return true;
                let keyCode = event.which;
                if (KEYBOARD_NUMBERS_MAP[keyCode] !== undefined) {
                    if (keyCode == 56 && event.shiftKey) {
                        this_vue.multiplicationOperator();
                    } else {
                        this_vue.addToExpression(KEYBOARD_NUMBERS_MAP[keyCode]);
                    }
                }
                else if (_.includes(KEYBOARD_SYMBOLS_DEVIDE, keyCode)) {
                    this_vue.divisionOperator();
                }
                else if (event.code == 'Semicolon' || event.code == 'Equal' || event.code == 'NumpadAdd') {
                    this_vue.additionOperator();
                }
                else if (event.code == 'Quote' || event.code == 'NumpadMultiply') {
                    this_vue.multiplicationOperator();
                }
                else if (event.code == 'Minus' || event.code == 'NumpadSubtract') {
                    this_vue.subtractionOperator();
                }
                else if (_.includes(KEYBOARD_ENTER, keyCode)) {
                    this_vue.calculateExpression();
                }
                else if (_.includes(KEYBOARD_DELETE, keyCode)) {
                    this_vue.removeOneCharacter();
                }
                else if (_.includes(KEYBOARD_ESCAPE, keyCode)) {
                    this_vue.clearAll();
                }
            });

            $('.calculator-panel').click(function () {
                this_vue.forceFocus(false);
            });
        },
        methods: {
            plusWithSnippet(val) {
                this.expression_show += `+${val}`
                this.expression_calculate += `+${val}`
                this.should_send_result = false;
                this.calculateExpression()
            },
            removeOneCharacter() {
                this.expression_show = this.expression_show.substring(0, this.expression_show.length - 1);
                this.expression_calculate = this.expression_calculate.substring(0, this.expression_calculate.length - 1);
                this.should_send_result = false;
            },
            clearAll() {
                this.expression_show = '';
                this.expression_calculate = '';
                this.should_send_result = false;
            },
            divisionOperator() {
                this.expression_show += `÷`;
                this.expression_calculate += `/`;
                this.should_send_result = false;
            },
            multiplicationOperator() {
                this.expression_show += `×`;
                this.expression_calculate += `*`;
                this.should_send_result = false;
            },
            additionOperator() {
                this.expression_show += `+`;
                this.expression_calculate += `+`;
                this.should_send_result = false;
            },
            subtractionOperator() {
                this.expression_show += '-';
                this.expression_calculate += '-';
                this.should_send_result = false;
            },
            addToExpression(val) {
                this.expression_show += `${val}`;
                this.expression_calculate += `${val}`;
                this.should_send_result = false;
            },
            calculateExpression() {
                try {
                    let result = eval(this.expression_calculate);
                    this.expression_show = result;
                    if (this.should_send_result) {
                        eventBus.$emit('calculator_result', result);
                        this.should_send_result = false;
                        this.hide();
                        this.clearAll();
                    }
                    this.should_send_result = true;
                } catch (err) {
                    this.should_send_result = false;
                }
            },
            show() {
                this.isShown = true;
                this.forceFocus();
            },
            hide() {
                this.isShown = false;
            },
            isHidden() {
                return !this.isShown;
            },
            onFocus() {
                this.isFocused = true;
            },
            onBlur() {
                this.isFocused = false;
            },
            setDefault(val) {
                this.expression_show = val;
                this.expression_calculate = val;
            },
            forceFocus(prevent_scroll = true) {
                if (prevent_scroll) {
                    this.$nextTick(() => {
                        let x = window.scrollX, y = window.scrollY;
                        if (this.$refs.calcBody) {
                            this.$refs.calcBody.focus();
                        }
                        window.scrollTo(x, y);
                    });

                } else {
                    this.$nextTick(() => this.$refs.calcBody.focus());
                }
            }
        }
    }
</script>