<template>
    <div class="date-setting-content">
        <div class="date-setting-content__header">
            <h2 class="date-setting-content__header__title">{{ $t('text.type_setting') }}</h2>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="row-content">
                    <div class="action-content">
                        <p class="medusa-bg">{{ $t('text.reservation_type_master_setting') }}</p>
                    </div>
                    <div class="col-12 m0 m-0 p-0">
                        <div class="reservation-type">
                            <div class="reservation-type-content">
                                <div class="reservation-tab">
                                    <ul class="nav nav-tabs">
                                        <li v-for="(reservationType,index) in reservationMaster"
                                            class="nav-item">

                                            <a class="nav-link"
                                               :class="{ 'active': index === 1 }"
                                               :data-id="index"
                                               :data-name="reservationType[0].reservation_type_name ? reservationType[0].reservation_type_name : index"
                                               data-toggle="tab"
                                               @click="updateTabActive(index)"
                                               v-bind:href="'#type'+index">{{reservationType[0].reservation_type_name ?
                                                reservationType[0].reservation_type_name : index}}</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link add-duty-tab"
                                               @click="addDutyTab">+</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <div v-for="(reservationType,index) in reservationMaster"
                                             class="tab-pane" :class="{ 'active': index === 1 }"
                                             v-bind:id="'type'+index">
                                            <div class="reservation-box-content"
                                                 id="reservation-type-setting">
                                                <template v-for="(duty,key) in reservationType">
                                                    <div v-if="duty.duty_name"
                                                         class="reservation-box"
                                                         @click="editDutyBlock(index, key)">
                                                        <div class="box-text">
                                                            <span class="note-color"
                                                                  v-bind:style='{background: duty.duty ?  duty.duty.color : ""}'></span>{{duty.duty_name}}
                                                        </div>
                                                        <div class="box-value">
                                                            <span class="icon-clock"></span>
                                                            <span class="title">{{duty.take_time}}{{ $t('common.minute') }}</span>
                                                            <span class="edit-type" v-if="!duty.is_preset"><img
                                                                    :src="'/images/edit.svg'"/></span>
                                                        </div>
                                                    </div>
                                                </template>
                                                <div class="reservation-add-box add-block"
                                                     @click="addDutyBlock(index)">
                                                    <div class="box-text">
                                                        <span class="add-type"><img
                                                                src="/images/plus.svg"/>
                                                        </span>{{ $t('text.tap_to_add_item') }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="remove-tab" @click="editReservationMasterTab($event)"><p>{{ $t('text.edit_current_tab') }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row reservation-duty">
            <div class="col-md-12">
                <div class="row-content">
                    <div class="action-content">
                        <p class="medusa-bg">{{ $t('text.job_master_setting') }}</p>
                    </div>
                    <div class="col-12 m0 m-0 p-0">
                        <div class="reservation-duty-type">
                            <table class="table table-borderless">
                                <thead>
                                <tr>
                                    <th scope="col">
                                        {{ $t('text.job_title') }}
                                        <span scope="col" class="duty-frame duty-color-label">{{ $t('common.color') }}</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(duty,key) in colorsDuty">
                                    <td scope="row">
                                        <div class="accordions">
                                            <div v-on:click="toggle(duty)"
                                                 v-bind:class="[duty.staff.length?(duty.active?'close':'open'):'disabled' , 'togglebutton']">
                                                {{duty.title}}
                                            </div>
                                            <slide-up-down :active="duty.active">
                                                <div class="duty-staff" v-for="staff in duty.staff">
                                                    <div class="element">
                                                        {{staff.name}}
                                                    </div>
                                                </div>
                                            </slide-up-down>
                                        </div>
                                        <span class="note-color duty-color duty-frame "
                                              @click="showDutyPopup($event,duty.id)"
                                              v-bind:data-color="duty.color"
                                              v-bind:style="{background:duty.color}">
                                        </span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <modal-confirmation
      v-if="is_show_modal_delete_duty_tab"
      custom_class="mc-modal--red-style mc-modal--reservation-type-delete"
      :is_show_close_button="false"
      @confirmButtonClick="deleteReservationMasterTab"
      @close="is_show_modal_delete_duty_tab = false"
    >
      <template #body>
        <span class="mc-modal__body__content">
          {{ $t('reservation_type.msg_confirm_delete', { active_tab_name }) }}
        </span>
      </template>
      <template #confirm_button_content>
        {{ $t('common.delete') }}
      </template>
      <template #cancel_button_content>
        {{ $t('common.no') }}
      </template>
    </modal-confirmation>
        <loading/>
    </div>
</template>
<script>
    import Swal from 'sweetalert2'
    import helpers from '../utils/helpers.js'
    import Loading from './Loading'
    import SlideUpDown from 'vue-slide-up-down'
    import { RESPONSE_ERRORS } from '../utils/constants'
    import ModalConfirmation from './ModalConfirmation'

    export default {
    components: {
      Loading,
      SlideUpDown,
      ModalConfirmation
    },
        data() {
            return {
                reservationMaster: {},
                activeMasterTab: 1,
                colorsDuty: [],
                defineColor: [
                    {
                        "color": "#24C0ED",
                        "rgb": "36,192,237"
                    },
                    {
                        "color": "#EF7F7F",
                        "rgb": "239,127,127"
                    },
                    {
                        "color": "#71D590",
                        "rgb": "113,213,144"
                    },
                    {
                        "color": "#EBEF7F",
                        "rgb": "235,239,127"
                    },
                    {

                        "color": "#FFAA44",
                        "rgb": "255,170,68"
                    },
                    {
                        "color": "#8877EE",
                        "rgb": "136,119,238"
                    },
                    {
                        "color": "#4768A7",
                        "rgb": "71,104,167"
                    },
                    {
                        "color": "#886B6B",
                        "rgb": "136,107,107"
                    }
                ],
                takeTime: [10, 15, 20, 30, 45, 60, 90],
        other_staff: 5,
        is_show_modal_delete_duty_tab: false,
        active_tab_name: '',
        is_setting_type: 1
      }
    },
        created() {
            var ordered = {}
            Object.keys(this.reservationMaster).sort().forEach(key => ordered[key] = this.reservationMaster[key])
            this.reservationMaster = ordered
            this.getListDutyMaster()
            this.requestReservationType(this.is_setting_type)
        },
        updated() {
            $('[data-toggle="tab"][href="#type' + this.activeMasterTab + '"]').trigger('click')
        },
        methods: {
            insertOrModifyDuty: function (objectDuty) {
                let ref = this
                let duty_name = ''
                let take_time = ''
        let dutyId = ''
                let dutyData = {}, extraClass = '', disabled = ''
                let modal_footer = `<button type="button" id="duty-cancel" class="swal2-button swal2-button--cancel swal2-styled" aria-label="" >${this.$t('common.cancel')}</button>
                    <button type="button" id="duty-confirm" class="swal2-button swal2-button--confirm swal2-confirm swal2-styled" aria-label=""><i class="edit-button"></i>${this.$t('reservation.reservation_register_type')}</button>`

                if (objectDuty.type === 'update') {
                    if (this.reservationMaster[objectDuty.tabId] && this.reservationMaster[objectDuty.tabId][objectDuty.keyIndex]) {
                        dutyData = this.reservationMaster[objectDuty.tabId][objectDuty.keyIndex]
                        dutyData.tabId = objectDuty.tabId
                        duty_name = dutyData.duty_name
                        take_time = dutyData.take_time
            dutyId = dutyData.duty ? dutyData.duty.id : ''
                        modal_footer= ''
                        if (dutyData.is_preset || dutyData.reservations_count) {
                            extraClass += ' preset-item '
                        }
                        if (!dutyData.is_preset) {
                            modal_footer = `<button type="button" id="duty-delete" class="swal2-button btn swal2-delete swal2-styled" aria-label="" >${ this.$t('common.delete') }</button>
                                        <button type="button" id="duty-cancel" class="swal2-button swal2-button--cancel swal2-cancel swal2-styled" aria-label="" >${this.$t('common.cancel')}</button>
                                        <button type="button" id="duty-confirm" class="swal2-button swal2-button--confirm swal2-confirm swal2-styled" aria-label=""><i class="edit-button"></i>${ this.$t('common.save') }</button>`
                        } else {
                            disabled = 'disabled'
                        }
                    }
                }

                let dutyColor = ''
                this.colorsDuty.forEach(function (duty) {
                    dutyColor += ` <option value="${duty.id}" ${(!dutyId && duty.id === ref.other_staff) || duty.id == dutyId ? 'selected' : ''}>${duty.title}</option> `
                })

                let dutyTakeTime = ''
                this.takeTime.forEach(function (takeTime) {
                    dutyTakeTime += ` <option value="${takeTime}" ${takeTime == take_time ? 'selected' : ''}>${takeTime}</option> `
                })

                Swal.fire({
                    title: this.$t('text.reservation_type_registration'),
                    customClass: {
                        popup: 'reservation-master-duty swal2-popup--radius' + extraClass,
                        footer: 'swal2-footer--custom'
                    },
                    html: `<div class="duty-content">
                                <div class="form-group ">
                                    <label for="duty-name" class="col-sm-3 col-form-label"><span class="label-bg">${ this.$t('text.alias') }</span></label>
                                    <div class="col-sm-9">
                                      <input type="text" name="duty_name" class="form-control border-blue" id="duty-name" ${disabled} value="${duty_name}" placeholder="${ this.$t('text.please_enter') }">
                                    </div>
                                  </div>
                                    <div class="form-group">
                                        <label for="take-time" class="col-sm-3"><span class="label-bg">${ this.$t('text.time_required') }</span></label>
                                        <div class="col-sm-9 ">
                                           <div class="input-select" id="take-time">
                                              <select class="form-control border-blue" ${disabled} name="take_time">
                                                   <option value="" hidden>${ this.$t('common.selection') }</option>
                                                   ${dutyTakeTime}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="duty-color" class="col-sm-3"><span class="label-bg">${ this.$t('text.duty_in_charge') }</span></label>
                                        <div class="col-sm-9">
                                           <div class="input-select">
                                              <select class="form-control border-blue" ${disabled} name="duty_color" id="duty-color">
                                                    ${dutyColor}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            `,
                    showCloseButton: true,
                    showConfirmButton: false,
                    showCancelButton: false,
                    footer: modal_footer,
                    onOpen: () => {
                        //Action
                        $('#duty-confirm').click(function () {
                            Swal.clickConfirm()
                        })

                        $('#duty-cancel').click(function () {
                            Swal.clickCancel()
                        })

                        $('#duty-delete').click(function () {
                            ref.deleteReservationType(dutyData)
                        })
                    },
          preConfirm: () => {
            if (!$('[name=duty_name]').val()) {
              Swal.showValidationMessage(
                this.$t('reservation.please_enter_duty_name')
              )

              return false
            }
            if (objectDuty.type === 'update') {
              if (ref.reservationMaster[objectDuty.tabId] &&
                this.reservationMaster[objectDuty.tabId][objectDuty.keyIndex]) {
                const dutyData = this.reservationMaster[objectDuty.tabId][objectDuty.keyIndex]
                if (dutyData.is_preset) {
                  Swal.showValidationMessage(
                    this.$t('reservation.presets_cannot_be_changed')
                  )

                  return false
                }
              }
            }

            return true
          }
                }).then((result) => {
                    if (result.value) {
                        let dutyMasterTab = ref.reservationMaster[objectDuty.tabId]
                        if (dutyMasterTab) {
              const dutyName = $('[name=duty_name]').val()
              const reservationTypeName = $('.nav-item a.active').attr('data-name') || ''
              if (!helpers.mbtrim(dutyName)) {
                return helpers.showErrorModal(this.$t('reservation.please_enter_type_name'))
              }
                          if (objectDuty.type === 'insert') {
                                let postData = {
                                    duty_color: $('[name=duty_color]').val(),
                                    duty_name: dutyName,
                                    reservation_type: objectDuty.tabId,
                                    take_time: $('[name=take_time]').val(),
                                    reservation_type_name: reservationTypeName
                                }
                                ref.insertReservationMaster(dutyMasterTab, postData)
                            }

                            if (objectDuty.type === 'update') {
                                if (ref.reservationMaster[objectDuty.tabId] && this.reservationMaster[objectDuty.tabId][objectDuty.keyIndex]) {
                                    let dutyData = this.reservationMaster[objectDuty.tabId][objectDuty.keyIndex]
                                    let postData = {
                                        reservation_master_id: dutyData.id,
                                        reservation_type: dutyData.reservation_type,
                                        duty_color: $('[name=duty_color]').val(),
                                        duty_name: dutyName,
                                        take_time: $('[name=take_time]').val(),
                                        reservation_type_name: reservationTypeName
                                    }
                                    ref.updateReservationMaster(dutyData, postData)
                                }
                            }
                        }
                    }
                })
            },
            updateTabActive: function (tabIndex) {
                this.activeMasterTab = tabIndex
            },
            addDutyTab: function () {
                let objectKeys = _.map(_.keys(this.reservationMaster), _.parseInt)
                let maxValue = _.max(objectKeys) || 1
                var newTabIndex = maxValue + 1
                for (var i = 1; i <= maxValue; i++) {
                    if (objectKeys[i - 1] != i) {
                        newTabIndex = i
                        break
                    }
                }
                this.addReservationMasterTab(newTabIndex)
            },
            editReservationMasterTab: function (event) {
                let ref = this
                let tabId = parseInt($('.nav-item a.active').attr('data-id')) || 0
                if (tabId && ref.reservationMaster[tabId].length > 0) {
                    let item = ref.reservationMaster[tabId][0]
                    let reservationTypeName = item.reservation_type_name
                    if (reservationTypeName == null) {
                        reservationTypeName = tabId
                    }
                    Swal.fire({
                        title: this.$t('text.edit_tab'),
                        customClass: 'reservation-master-duty',
                        html: `<div class="duty-content">
                                <div class="form-group ">
                                    <label for="reservation-type-name" class="col-sm-3 col-form-label"><span class="label-bg">${ this.$t('text.display_name') }</span></label>
                                    <div class="col-sm-9">
                                      <input type="text" name="reservation_type_name" class="form-control border-blue" id="reservation-type-name" value="${reservationTypeName}" placeholder="${ this.$t('text.please_enter') }">
                                    </div>
                                  </div>
                            </div>
                            `,
                        showCloseButton: true,
                        showConfirmButton: false,
                        showCancelButton: false,
                        footer: `
                                 <button type="button" id="reservation-type-name-delete" class="swal2-styled" style="background: #EE423D; color:#FFFFFF; border-radius:0.25em" aria-label="" >${ this.$t('common.delete') }</button>
                                 <button type="button" id="reservation-type-name-confirm" class="swal2-confirm swal2-styled" aria-label=""><i class="edit-button"></i>${ this.$t('common.save') }</button>`,
                        onOpen: () => {
                            //Action
                            $('#reservation-type-name-confirm').click(function () {
                                Swal.clickConfirm()
                            })

                            $('#reservation-type-name-delete').click(function () {
                                Swal.clickCancel()
                            })
                        },
                        preConfirm: () => {
                            if (!$('[name=reservation_type_name]').val()) {
                                Swal.showValidationMessage(
                                    this.$t('reservation.please_select_content')
                                )
                                return false
                            }
                            return true
                        }
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.cancel) {
                            this.removeDutyTab()
                            return
                        }
                        if (result.value) {
                            let postData = {
                                reservation_type: tabId,
                                reservation_type_name: $('[name=reservation_type_name]').val(),
                            }
                            !helpers.mbtrim(postData.reservation_type_name) ? helpers.showErrorModal(this.$t('reservation.please_enter_display_name')) : ref.updateReservationMasterTab(postData)
                        }
                    })
                }
            },
      removeDutyTab: function (event) {
        this.is_show_modal_delete_duty_tab = true
        this.active_tab_name = $('.nav-item a.active').attr('data-name') || ''
      },
            addDutyBlock: function (tabId) {
                var objectDuty = {tabId: tabId, type: 'insert'}
                this.insertOrModifyDuty(objectDuty)
            },
            editDutyBlock: function (tabId, keyIndex) {
                var objectDuty = {tabId: tabId, type: 'update', keyIndex: keyIndex}
                this.insertOrModifyDuty(objectDuty)
            },
            showDutyPopup: function (event, duty_id) {
                let ref = this
                let colorHtml = ''
                this.defineColor.forEach(function (color) {
                    colorHtml += `
                        <div class="block-color" data-color="${color.color}" data-rgb="${color.rgb}" style="background: ${color.color}"></div>
                    `
                })
                $(event.target).confirmation({
                    title: 'x',
                    placement: 'right',
                    singleton: true,
                    popout: true,
                    trigger: 'click',
                    template: `
                                <div class="popover show">
                                 <div class="arrow"></div>
                                    <div class="popover-content">
                                         <div class="duty-block">
                                             ${colorHtml}
                                         </div>
                                    </div>
                                </div>`
                })
                $(event.target).on('inserted.bs.confirmation', function (e) {
                    let color = $(this).attr('data-color')
                    $('.block-color').css({'box-shadow': 'none'})
                    let rgb = $('.block-color[data-color="' + color + '"]').data('rgb')
                    if (rgb) {
                        $('.block-color[data-color="' + color + '"]').css({
                            'box-shadow': '0 0 0 7px rgba(' + rgb + ',0.5)',
                            '-moz-box-shadow': '0 0 0 7px rgba(' + rgb + ',0.5)',
                            '-webkit-box-shadow': '0 0 0 7px rgba(' + rgb + ',0.5)',
                        })
                    }

                    $('.duty-block .block-color').click(function (e) {
                        e.stopImmediatePropagation()
                        let color = $(this).data('color')
                        let rgb = $(this).data('color')
                        let postData = {
                            duty_id: duty_id,
                            color: color,
                            rgb: rgb,
                        }
                        let dutyObject = _.find(ref.colorsDuty, ['id', duty_id])

                        ref.updateDutyMaster(postData, dutyObject.color)
                        $(event.target).confirmation('hide')
                    })
                })
                $(event.target).confirmation('show')
            },
            getListDutyMaster: function () {
                this.$store.dispatch('getListDutyMaster').then((result) => {
                    this.colorsDuty = helpers.safeGet(result, 'data.results')
                    this.colorsDuty.forEach(function (item) {
                        item.active = item.active ? true : false
                    })
                }).catch((error) => {
                    let res_errors = helpers.safeGet(error, 'response.data.errors') || {},
                        errors = []
                    for (let index in res_errors) {
                        errors.push(res_errors[index])
                    }
                    if (errors.length) {
                        Swal.fire({
                            toast: true,
                            type: 'error',
                            position: 'top',
                            title: errors.join('\n'),
                            showConfirmButton: false,
                            timer: 1500,
                            onBeforeOpen: () => {
                                $('.swal2-top').css({'top': 125 + 'px'})
                            }
                        })
                    }
                })
            },
            requestReservationType: function (isSettingType = 0) {
                this.$store.dispatch('getListReservationType', isSettingType).then((result) => {
                    let data = helpers.safeGet(result, 'data.results.reservationType')
                    if (_.size(data)) {
                        this.reservationMaster = data
                    }
                }).catch((error) => {
                })
            },
            insertReservationMaster: function (dutyMasterTab, postData) {
                this.$store.commit('set_loading', true)
                this.$store.dispatch('insertReservationType', postData).then((result) => {
                    let reservationMaster = helpers.safeGet(result, 'data.results')
                    dutyMasterTab.push(reservationMaster)
                    helpers.showSuccessModal(this.$t('message.has_registered'))
                    this.$store.commit('set_loading', false)
                }).catch((error) => {
                    let res_errors = helpers.safeGet(error, 'response.data.errors') || {},
                        errors = []
                    for (let index in res_errors) {
                        errors.push(res_errors[index])
                    }
                    helpers.showErrorMessage(errors)
                    this.$store.commit('set_loading', false)
                })
            },
            updateReservationMaster: function (dutyMasterTab, postData) {
                this.$store.commit('set_loading', true)
                this.$store.dispatch('updateReservationType', postData).then((result) => {
                    let reservationMaster = helpers.safeGet(result, 'data.results')
                    dutyMasterTab.duty = reservationMaster.duty
                    dutyMasterTab.duty_name = reservationMaster.duty_name
                    dutyMasterTab.take_time = reservationMaster.take_time
                    dutyMasterTab.reservation_type = reservationMaster.reservation_type
                    dutyMasterTab.reservation_type_name = reservationMaster.reservation_type_name
                    dutyMasterTab.clinic_id = reservationMaster.clinic_id
                    dutyMasterTab.is_preset = reservationMaster.is_preset
                    dutyMasterTab.is_deleted = reservationMaster.is_deleted
                    dutyMasterTab.reservations_count = reservationMaster.reservations_count

                    helpers.showSuccessModal(this.$t('message.has_been_updated'))
                    this.$store.commit('set_loading', false)
                }).catch((error) => {
                    let res_errors = helpers.safeGet(error, 'response.data.errors') || {},
                        errors = []
                    for (let index in res_errors) {
                        errors.push(res_errors[index])
                    }
                    helpers.showErrorMessage(errors)
                    this.$store.commit('set_loading', false)
                })
            },
            updateReservationMasterTab: function (postData) {
                this.$store.commit('set_loading', true)
                this.$store.dispatch('updateReservationMasterTab', postData).then((result) => {
                    helpers.showSuccessModal(this.$t('message.has_been_updated'))
                    this.$store.commit('set_loading', false)
                    this.requestReservationType()
                }).catch((error) => {
                    helpers.showErrorMessage(error)
                    this.$store.commit('set_loading', false)
                })
            },
            addReservationMasterTab: function (tabIndex) {
                this.$store.commit('set_loading', true)
                let postData = {'tabIndex': tabIndex}
                this.$store.dispatch('addReservationMasterTab', postData).then((result) => {
                    let reservationMaster = helpers.safeGet(result, 'data.results')
                    this.$set(this.reservationMaster, reservationMaster.reservation_type, [reservationMaster])
                    this.activeMasterTab = reservationMaster.reservation_type
                    helpers.showSuccessModal(this.$t('message.has_been_updated'))
                    this.$store.commit('set_loading', false)
                }).catch((error) => {
                    let res_errors = helpers.safeGet(error, 'response.data.errors') || {},
                        errors = []
                    for (let index in res_errors) {
                        errors.push(res_errors[index])
                    }
                    helpers.showErrorMessage(errors)
                    this.$store.commit('set_loading', false)
                })
            },
            deleteReservationMasterTab: function () {
        const tabIndex = parseInt($('.nav-item a.active').attr('data-id')) || 0
        this.is_show_modal_delete_duty_tab = false
                this.$store.commit('set_loading', true)
                let postData = {'tabIndex': tabIndex}
                this.$store.dispatch('deleteReservationMasterTab', postData).then((result) => {
                    let is_Delete = helpers.safeGet(result, 'data.results.is_delete')
                    if (is_Delete) {
                        let tabId = tabIndex

                        this.$delete(this.reservationMaster, tabId)
                        helpers.showSuccessModal(this.$t('message.has_been_deleted'))
                        let objectKeys = _.map(_.keys(this.reservationMaster), _.parseInt)
                        objectKeys = objectKeys.sort(function (a, b) {
                            return a - b
                        })
                        let minValue = ''
                        if (_.size(objectKeys)) {
                            for (var i = 0; i < _.size(objectKeys); i++) {
                                if (objectKeys[i] < tabId) {
                                    minValue = objectKeys[i]
                                }
                            }
                            if (!minValue) {
                                minValue = objectKeys[0]
                            }
                        }
                        if (minValue) {
                            this.activeMasterTab = minValue
                        }
                    } else {
                        helpers.showErrorModal(this.$t('reservation.preset_items_cannot_be_changed'))
                    }

                    this.$store.commit('set_loading', false)
                }).catch((error) => {
                    let res_errors = helpers.safeGet(error, 'response.data.errors') || {},
                        errors = []
                    for (let index in res_errors) {
                        errors.push(res_errors[index])
                    }
                    helpers.showErrorMessage(errors)
                    this.$store.commit('set_loading', false);
                })
            },
            updateDutyMaster: function (postData, duty_color) {
                let ref = this
                this.$store.commit('set_loading', true)
                this.$store.dispatch('updateDutyMaster', postData).then((result) => {
                    let data = helpers.safeGet(result, 'data.results')
                    let dutyObject = _.find(this.colorsDuty, ['id', postData.duty_id])
                    dutyObject.color = data.color
                    dutyObject.rgb = data.rgb

                    Object.keys(this.reservationMaster).forEach(function (key) {
                        if (ref.reservationMaster[key]) {
                            ref.reservationMaster[key].forEach(function (item) {
                                if (item.duty && item.duty.id == data.id && item.duty.color == duty_color) {
                                    item.duty.color = data.color
                                }
                            })
                        }
                    })
                    this.$store.commit('set_loading', false)
                }).catch((error) => {
                    let res_errors = helpers.safeGet(error, 'response.data.errors') || {},
                        errors = []
                    for (let index in res_errors) {
                        errors.push(res_errors[index])
                    }
                    helpers.showErrorMessage(errors)
                    this.$store.commit('set_loading', false)
                })
            },
            toggle(duty) {
                duty.active = !duty.active
            },

            deleteReservationType: function (RTObject) {
                let ref = this
                Swal.fire({
                    title: '',
                    customClass: 'confirm-delete-rt',
                    html: `<div class="removeDutyTab">
                                <h3 class="popover-title">${ this.$t('message.confirmation_delete') }</h3>
                                <div class="popover-content">
                                    <button id="delete-rt-cancel" class="btn-cancel">${ this.$t('common.no') }</button>
                                    <button id="delete-rt-confirm" class="btn-confirm">${ this.$t('common.delete') }</button>
                                </div>
                            </div>`,
                    showCloseButton: true,
                    showConfirmButton: false,
                    showCancelButton: false,
                    footer: '',
                    onOpen: () => {
                        $('#delete-rt-cancel').click(function () {
                            Swal.clickCancel()
                        })
                        $('#delete-rt-confirm').click(function () {
                            ref.requestDeleteReservationType(RTObject)
                            Swal.clickCancel()
                        })
                    }
                })
            },
      requestDeleteReservationType: function (RTObject) {
        const postData = {
          reservation_master_id: RTObject.id
        }

        this.$store.commit('set_loading', true)
        this.$store.dispatch('deleteReservationType', postData).then((result) => {
          const dataTab = helpers.safeGet(result, 'data.results')
          this.reservationMaster[RTObject.tabId] = dataTab[RTObject.tabId]
          this.$store.commit('set_loading', false)
        }).catch((error) => {
          this.$store.commit('set_loading', false)
          const resErrors = helpers.safeGet(error, 'response.data.errors') || {}
          const errors = []

          for (const index in resErrors) {
            if (resErrors[index] === RESPONSE_ERRORS.RESERVATION_TYPE_USING) {
              helpers.showErrorModal(this.$t('reservation_type.errors.reservation_type_using'))

              return
            } else {
              errors.push(resErrors[index])
            }
          }

          helpers.showErrorMessage(errors)
        })
      }
        }
    }
</script>
