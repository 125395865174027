<template>
  <transition name="modal">
    <div
      v-if="isShow"
      class="modal-duty"
    >
      <div class="modal-duty__wrap">
        <div
          v-for="(color, key) in defineColor"
          :key="key"
          class="modal-duty__color"
          :style="[
            { backgroundColor: color.color },
            dataDutyColor === color.color && { boxShadow: `0 0 0 7px rgba(${ color.rgb }, .5)` }
          ]"
          @click="handleChooseColor(color)"
        />
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    props: {
      dutyId: {
        type: Number,
        default: 1
      },
      dataDutyId: {
        type: Number,
        default: 0
      },
      dataDutyColor: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        defineColor: [
          {
            color: '#ff6666',
            rgb: '255, 102, 102'
          },
          {
            color: '#bb7744',
            rgb: '187, 119, 68'
          },
          {
            color: '#ffaa55',
            rgb: '255, 170, 85'
          },
          {
            color: '#ffdd33',
            rgb: '255, 221, 51'
          },
          {
            color: '#aadd44',
            rgb: '170, 221, 68'
          },
          {
            color: '#55eecc',
            rgb: '85, 238, 204'
          },
          {
            color: '#22ccff',
            rgb: '34, 204, 255'
          },
          {
            color: '#3377ff',
            rgb: '51, 119, 255'
          },
          {
            color: '#aa44ff',
            rgb: '170, 68, 255'
          },
          {
            color: '#4455aa',
            rgb: '68, 85, 170'
          }
        ]
      }
    },
    computed: {
      isShow () {
        return this.dutyId === this.dataDutyId
      }
    },
    methods: {
      handleChooseColor (color) {
        this.$emit('update-duty-master', {
          duty_id: this.dataDutyId,
          color: color.color,
          rgb: color.rgb
        })
      }
    }
  }
</script>
