import * as moment from 'moment-timezone'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { showSuccessModal, showErrorModal } from '../utils/helpers'
import { RESPONSE_TYPE } from '../utils/constants'
import { isEmpty } from 'lodash'

export const createReservation = {
  computed: {
    ...mapGetters('reservation_move_mode', [
      'getMoveModeActive',
      'getReservation',
      'getMirrorTemplate'
    ]),
    isEnableMoveMode: function () {
      return !!(this.getMoveModeActive && this.getReservation)
    },
    currentCalendarActiveDate: function () {
      const calendarApi = this.$refs.fullCalendar.getApi()
      return calendarApi ? moment(calendarApi.getDate()) : moment()
    },
    isDraftReservationExist: function () {
      if (!this.isEnableMoveMode || !this.on_mouse_coordinate || !this.on_mouse_data_time || !this.on_mouse_allocation_id) {
        return false
      }

      const expectedReservationData = Object.assign({}, this.getReservation)
      const date = this.currentCalendarActiveDate.format('YYYY-MM-DD')
      const timePeriod = moment(expectedReservationData.ended_from).diff(moment(expectedReservationData.started_to))
      const startTime = moment(`${date} ${this.on_mouse_data_time}`).format('YYYY-MM-DD HH:mm:ss')
      const endTime = moment(startTime).add(moment.duration(timePeriod).asMinutes(), 'minutes').format('YYYY-MM-DD HH:mm:ss')

      expectedReservationData.started_to = startTime
      expectedReservationData.ended_from = endTime
      expectedReservationData.end = endTime
      expectedReservationData.start = startTime
      expectedReservationData.allocation_id = parseInt(this.on_mouse_allocation_id)

      return this.checkExistReservationTime(expectedReservationData)
    }
  },
  methods: {
    ...mapMutations('reservation_move_mode', [
      'setMirrorTemplate',
      'resetMoveModeData'
    ]),
    ...mapActions('reservation_move_mode', [
      'initMoveModeData'
    ]),
    handleClickEnableMoveMode: function (resource) {
      if (!resource) {
        return false
      }

      const classList = resource?.jsEvent?.target?.classList || null

      return classList && classList.contains('event-card__btn-move')
    },
    getReservationMirrorContainer: function () {
      return this.isEnableMoveMode
        ? document.querySelector('.draft-container')
        : null
    },
    createReservationMirrorContainer: function () {
      if (!this.isEnableMoveMode) {
        return
      }
      let wrapper = this.getReservationMirrorContainer()
      const reservationContainer = document.querySelector('.reservation-v2__calendar')
      if (!wrapper && reservationContainer) {
        wrapper = document.createElement('div')
        wrapper.classList.add('draft-container')
        reservationContainer.appendChild(wrapper)
      }
    },
    appendMirrorFromStore: function () {
      if (!this.isEnableMoveMode) {
        return
      }
      const wrapper = this.getReservationMirrorContainer()
      if (wrapper) {
        wrapper.appendChild(this.getMirrorTemplate)
      }
    },
    getReservationMirror: function () {
      if (!this.isEnableMoveMode) {
        return null
      }
      const eventMovedId = this.active_reservation_id || this.getReservation.id
      return document.querySelector(`.clone-move-event.event-id-${eventMovedId}`)
    },
    createMirrorReservationOnMove: function (initPosition) {
      if (!this.isEnableMoveMode) {
        return
      }
      const self = this
      const eventMovedId = this.active_reservation_id || this.getReservation.id
      this.createReservationMirrorContainer()
      const container = this.getReservationMirrorContainer()
      if (eventMovedId && container) {
        const eventTarget = document.querySelector('.fc-event:not(.clone-move-event).event-id-' + eventMovedId)
        eventTarget.classList.add('target-move-event')
        if (eventTarget) {
          this.active_reservation_on_mouse = true
          const eventClone = eventTarget.cloneNode(true)
          eventClone.classList.add('clone-move-event')
          eventClone.classList.remove('target-move-event')
          container.appendChild(eventClone)
          const resourceCell = document.querySelector('.fc-resource-cell')
          eventClone.style.width = (resourceCell.getBoundingClientRect().width - 6) + 'px'
          eventClone.style.height = (eventTarget.getBoundingClientRect().height - 2) + 'px'
          eventClone.style.zIndex = '2'
          eventClone.style.position = 'fixed'
          eventClone.style.bottom = 'unset'
          eventClone.style.background = '#ccc'
          if (initPosition) {
            eventClone.style.width = (resourceCell.getBoundingClientRect().width - 1) + 'px'
            eventClone.style.top = initPosition.y + 10 + 'px'
            eventClone.style.left = initPosition.x + resourceCell.getBoundingClientRect().width + 10 + 'px'
          }
          this.setMirrorTemplate(eventClone)
          window.onmousemove = function (e) {
            self.on_mouse_coordinate = { x: e.pageX, y: e.pageY }
            $('#calendar .fc-time-grid .fc-widget-content[data-resource-id]').each(function () {
              if (e.pageX > $(this).offset().left && e.pageX < ($(this).offset().left + $(this).outerWidth())) {
                self.on_mouse_allocation_id = $(this).data('resource-id')
              }
            })
            const mirror = self.getReservationMirror()
            if (self.active_reservation_on_mouse && mirror) {
              mirror.style.width = (resourceCell.getBoundingClientRect().width - 1) + 'px'
              mirror.style.top = self.on_mouse_coordinate.y + 10 + 'px'
              mirror.style.left = self.on_mouse_coordinate.x + 10 + 'px'

              if (self.isDraftReservationExist || $(e.target).hasClass('fc-nonbusiness')) {
                mirror.classList.add('block-drag-event')
              } else {
                mirror.classList.remove('block-drag-event')
              }
            } else {
              mirror.classList.remove('block-drag-event')
            }
          }
          window.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
              if (self.isEnableMoveMode) {
                self.active_reservation_on_mouse = false
                self.move_mode_show_modal_confirm_continue = true
              }
            }
          })
        }
      }
    }
  }
}
export const checkReservation = {
  methods: {
    checkExistReservationTime: function (reservation) {
      const listReservations = [...this.events]
      if (listReservations.length === 0) {
        return false
      }
      const startTime = moment(reservation.started_to)
      const endTime = moment(reservation.ended_from)
      for (let i = 0; i < listReservations.length; i++) {
        const r = listReservations[i]
        if (r && (r.allocation_id !== reservation.allocation_id || r.id === reservation.id)) {
          continue
        }
        if ((moment(r.started_to) === startTime && moment(r.ended_from) === endTime) ||
          (moment(r.started_to) < endTime && moment(r.ended_from) > startTime) ||
          (moment(r.started_to) < startTime && moment(r.ended_from) > startTime) ||
          (moment(r.started_to) < endTime && moment(r.ended_from) > endTime)) {
          return true
        }
      }
      return false
    },
    checkPopupChangeFirstReservation: function (submitData) {
      return this.$store.dispatch('isFirstReservation', submitData)
    }
  }
}
export const popupReservation = {
  methods: {
    handleTimeGridHover: function () {
      const self = this
      $('.fc-time-grid .fc-widget-content').mousemove(function (e) {
        if (!$(this).hasClass('fc-day')) {
          let timeCell = $(this).parent().data('time')
          const rect = e.target.getBoundingClientRect()
          const y = e.clientY - rect.top

          if (y > 27) { // 27 is height of half cell
            timeCell = moment(timeCell, 'HH:mm:ss').add(5, 'minutes').format('HH:mm:ss')
          }

          self.on_mouse_data_time = timeCell
        }
      })
      $('.fc-event-container .fc-time-grid-event').hover(function () {
        self.on_mouse_data_time = $(this).find('.fc-time').data('start')
      })
    },
    hardResetDomEvent: function () {
      $('.fc-time-grid-event.target-move-event').removeClass('target-move-event')
    },
    lockEventDraggable: function () {
      $('.fc-event.fc-draggable').addClass('fc-temporary-lock').removeClass('fc-draggable')
    },
    unlockEventDraggable: function () {
      $('.fc-event.fc-temporary-lock').removeClass('fc-temporary-lock').addClass('fc-draggable')
    },
    lockEventResize: function () {
      $('.fc-resizer.fc-end-resizer').attr('style', 'display: none !important')
    },
    unlockEventResize: function () {
      $('.fc-resizer.fc-end-resizer').attr('style', 'display: inline !important')
    },
    removeMirrorEvent: function () {
      $('.draft-container').remove()
      $('.clone-move-event').remove()
      window.onmousemove = null
    },
    resetReservationMoveMode: function () {
      this.resetMoveModeData()
      this.active_reservation_id = null
      this.active_reservation_on_mouse = false
      this.on_mouse_coordinate = null
      this.on_mouse_data_time = null
      this.on_mouse_allocation_id = null
      this.move_mode_current_trigger_action = null
      this.unlockEventDraggable()
      this.unlockEventResize()
      this.removeMirrorEvent()
    },
    switchEventToOnMouse: function () {
      if (!this.isEnableMoveMode) {
        return
      }
      const container = this.getReservationMirrorContainer()
      const mirror = this.getReservationMirror()
      if (container && mirror) {
        container.appendChild(mirror)
        mirror.style.position = 'fixed'
        mirror.style.left = this.on_mouse_allocation_id
        mirror.style.top = this.on_mouse_coordinate.y + 10 + 'px'
        mirror.style.left = this.on_mouse_coordinate.x + 10 + 'px'
        this.active_reservation_on_mouse = true
      }
    },
    moveModeSubmitUpdateReservation () {
      this.move_mode_show_modal_confirm_update_reservation = false
      if (this.isEnableMoveMode && this.reservationInfoInDraft &&
        this.getReservation.id === this.reservationInfoInDraft.id) {
        const postData = {
          id: this.reservationInfoInDraft.id,
          patient_id: this.reservationInfoInDraft.patient_id,
          clinics_id: this.reservationInfoInDraft.clinics_id,
          started_to: this.reservationInfoInDraft.start,
          ended_from: this.reservationInfoInDraft.end,
          allocation_id: this.reservationInfoInDraft.allocation_id,
          disable_send_mail: true
        }
        this.$store.commit('set_loading', true)
        this.$store.dispatch('updateReservation', postData).then((res) => {
          this.$store.commit('set_loading', false)
          const {
            data: {
              status
            }
          } = res
          if (status === 'OK') {
            this.storeOperationReasons(this.reservationInfoInDraft.id, false)
            showSuccessModal(this.is_block_frame_manipulation ? this.$t('text.block_frame_moved_successfully') : this.$t('reservation.reservation_updated'))
            this.requestListReservation(true)
          }
        }).catch((error) => {
          this.$store.commit('set_loading', false)
          const resErrors = error?.response?.data?.errors || {}
          const errors = []
          if (!isEmpty(resErrors) && resErrors.first_reservation === RESPONSE_TYPE.LOCKED) {
            showErrorModal(this.$t('objects.reservation.messages.reservation_can_not_change'))
          } else if (!isEmpty(resErrors) && resErrors.patient === RESPONSE_TYPE.HAS_ORDER_HISTORY) {
            showErrorModal(
              this.$t('objects.reservation.messages.reservation_can_not_change_because_has_order'),
              { width: 'auto' }
            )
          } else {
            Object.entries(resErrors).forEach(([key, val]) => {
              const message = this.$t(
                `new_validation.reservation.${val.toLowerCase()}`,
                { attribute: this.$t(`new_validation.reservation.attributes.${key.toLowerCase()}`) }
              )
              errors.push(message)
            })

            if (errors.length) {
              showErrorModal(errors.join('\n'))
            }
          }

          this.switchEventToOnMouse()
        })
      }
    },
    moveModeDismissUpdateReservation: function () {
      this.move_mode_show_modal_confirm_update_reservation = false
      setTimeout(() => {
        this.move_mode_show_modal_confirm_continue = true
      }, 500)
    },
    moveModeContinue: function () {
      this.switchEventToOnMouse()
      this.move_mode_show_modal_confirm_continue = false
      this.move_mode_current_trigger_action = null
    },
    moveModeExit: function () {
      this.move_mode_show_modal_confirm_continue = false
      if (!this.isEnableMoveMode) {
        return
      }
      this.hardResetDomEvent()
      this.resetReservationMoveMode()
      this.reloadReservationData()
    },
    showMoveConfirmUpdateReservation: function () {
      this.show_modal_update_draft = false
      this.move_mode_show_modal_confirm_update_reservation = true
    },
    hideModalUpdateDraft: function () {
      this.show_modal_update_draft = false
      this.moveModeDismissUpdateReservation()
      this.reloadReservationData()
    }
  }
}
