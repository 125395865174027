<template>
  <transition name="modal">
    <div :class="['list-modal', customClass]">
      <div class="list-modal__wrapper">
        <div class="list-modal__container">
          <div class="list-modal__header">
            <slot name="header" />
            <button
              v-if="isShowCloseButton"
              type="button"
              aria-label="Close this dialog"
              class="list-modal__header__close"
              @click="$emit('close')"
            >
              ×
            </button>
          </div>

          <div class="list-modal__body">
            <slot name="body" />
          </div>

          <div class="list-modal__footer">
            <button
              v-if="isShowApprovalButton"
              class="list-modal__footer__button"
              @click="$emit('confirm-button-click')"
            >
              <slot name="confirm_button_content" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  export default {
    props: {
      customClass: {
        type: String,
        default: ''
      },
      isShowCloseButton: {
        type: Boolean,
        default: true
      },
      isShowApprovalButton: {
        type: Boolean,
        default: true
      }
    },
    emits: ['close', 'confirm-button-click']
  }
</script>
