var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "interview-input" }, [
    _c("div", { staticClass: "interview-input__control" }, [
      _c(
        "button",
        {
          staticClass: "btn interview-input__control--save",
          on: { click: _vm.openSaveConfirm }
        },
        [
          _c("img", { attrs: { src: "/images/edit-white.svg" } }),
          _c("span", [_vm._v("保存")])
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn interview-input__control--draft",
          on: { click: _vm.saveDraft }
        },
        [
          _c("img", { attrs: { src: "/images/edit.svg" } }),
          _c("span", [_vm._v("下書き保存")])
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "interview-input__content" },
      [
        _vm.error_messages.length
          ? _c(
              "ul",
              { staticClass: "errors" },
              _vm._l(_vm.error_messages, function(item, key) {
                return _c("li", { key: key }, [
                  _vm._v("\n                " + _vm._s(item) + "\n            ")
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c("information", {
          ref: "patient_info",
          attrs: {
            patientInfo: _vm.patient_info,
            interview_info: _vm.interview_info
          },
          on: { patientInfo: _vm.syncPatientInfo }
        }),
        _vm._v(" "),
        _c("content-consultation", { ref: "content_consultation" }),
        _vm._v(" "),
        _c("advance-consultation", {
          ref: "advance_consultation",
          attrs: { patientInfo: _vm.patient_info }
        }),
        _vm._v(" "),
        _c("other", { ref: "other_interview" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }