var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-search-patient" },
    [
      _c("modal-action", {
        attrs: { title: _vm.$t("reservation.select_patient") },
        on: {
          close: function($event) {
            return _vm.$emit("close")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("div", { staticClass: "modal-search-patient__content" }, [
                  _c(
                    "div",
                    { staticClass: "modal-search-patient__tab-content" },
                    [
                      _c("table", { staticClass: "table" }, [
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              { staticClass: "modal-search-patient__id" },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("common.ID")) +
                                    "\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "modal-search-patient__name modal-search-patient__name-id"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("common.name")) +
                                    "\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "modal-search-patient__sf-status modal-search-patient__sf-status-id"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t("reservation.reservation_status")
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "modal-search-patient__time modal-search-patient__time-id"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t("reservation.first_visit_date")
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.patients, function(p, index) {
                            return _c(
                              "tr",
                              {
                                key: index,
                                on: {
                                  click: function($event) {
                                    return _vm.selectionHandler(p)
                                  }
                                }
                              },
                              [
                                _c(
                                  "td",
                                  { staticClass: "modal-search-patient__id" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          p.sf_user_id
                                            ? p.sf_user_id
                                            : p.patient_id
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "modal-search-patient__name modal-search-patient__name-id"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(p.last_name) +
                                        _vm._s(p.first_name) +
                                        "(" +
                                        _vm._s(p.kana_last_name) +
                                        " " +
                                        _vm._s(p.kana_first_name) +
                                        ")\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "modal-search-patient__sf-status modal-search-patient__sf-status-id"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.patientSFStatus(p.sf_status_c)
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "modal-search-patient__time" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.patientVisitedOrCancelledDate(p)
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "infinite-loading",
                        {
                          attrs: { identifier: _vm.infiniteScrollId },
                          on: { infinite: _vm.infiniteScrollHandler }
                        },
                        [
                          _c("div", {
                            staticClass: "result-loader",
                            attrs: { slot: "no-more" },
                            slot: "no-more"
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "result-loader",
                              attrs: { slot: "no-results" },
                              slot: "no-results"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("patient.modal.search_no_result")
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }