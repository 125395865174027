var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "layout-v2",
        "layout-v2--" + _vm.getClassName,
        { "layout-v2--smart-phone": _vm.isSPLayout },
        { "layout-v2--has-notification": _vm.isShowNotificationComponent }
      ]
    },
    [
      _c("header-component", {
        attrs: { "is-sp-layout": _vm.isSPLayout },
        on: {
          "show-notification": _vm.handelShowNotification,
          "request-notification": _vm.requestGetNotification
        }
      }),
      _vm._v(" "),
      _vm.isShowNotificationComponent
        ? _c("notification", { on: { close: _vm.handelReadAndClose } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "layout-v2__content" }, [_c("router-view")], 1),
      _vm._v(" "),
      _vm.isShowModalForNonContract
        ? _c("modal-select-reason-for-non-contract")
        : _vm._e(),
      _vm._v(" "),
      _c("loading")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }