var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "patient-activity",
        { "patient-activity--no-content": _vm.activities_data.length === 0 }
      ]
    },
    [
      _c("div", { staticClass: "patient-activity__header" }, [
        _c("h2", { staticClass: "patient-activity__header__title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("objects.patient.text.activity_log")) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "patient-activity__content" }, [
        _vm.activities_data.length > 0
          ? _c(
              "div",
              { staticClass: "patient-activity__content__list" },
              [
                _vm._l(_vm.activities_data, function(item) {
                  return [
                    item.type === _vm.activity_log_type.MEDICAL_RECORD
                      ? _c("MedicalRecordActivity", {
                          key: item.id,
                          attrs: { activity: item }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === _vm.activity_log_type.INVOICE
                      ? _c("InvoiceActivity", {
                          key: item.id,
                          attrs: {
                            activity: item,
                            "model-data":
                              _vm.invoice_data[item.activityable_id] || {}
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === _vm.activity_log_type.RESERVATION
                      ? _c("ReservationActivity", {
                          key: item.id,
                          attrs: {
                            activity: item,
                            "patient-name": _vm.patientFullName,
                            "model-data":
                              _vm.reservation_data[item.activityable_id] || {},
                            "max-date": _vm.maxCreatedAt
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === _vm.activity_log_type.PATIENT
                      ? _c("PatientActivity", {
                          key: item.id,
                          attrs: {
                            activity: item,
                            "patient-name": _vm.patientFullName
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === _vm.activity_log_type.PATIENT_INTERVIEW
                      ? _c("PatientInterviewActivity", {
                          key: item.id,
                          attrs: {
                            activity: item,
                            "patient-name": _vm.patientFullName
                          }
                        })
                      : _vm._e()
                  ]
                })
              ],
              2
            )
          : _c("span", { staticClass: "patient-activity__content__list" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.is_loading
                      ? _vm.$t("objects.activity.activity_log_loading")
                      : _vm.$t("objects.activity.patient_info_not_found")
                  ) +
                  "\n    "
              )
            ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "patient-activity__content__pagination" },
          [
            _vm.activities_data.length > 0
              ? _c("pagination-common", {
                  attrs: {
                    "current-page": _vm.page,
                    "last-page": _vm.last_page
                  },
                  on: { "change-page": _vm.getPatientActivities }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("Loading")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }