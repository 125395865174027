<template>
  <div class="info-order info-order--draft">
    <div class="info-order__row">
      <div class="info-order__group">
        <label>{{ $t('objects.orders.text.patient_id') }}</label>
        <div class="info-order__patient">
          <span>{{ form.sf_user_id }}</span>
          <div class="info-order__line" />
        </div>
      </div>
      <div class="info-order__group">
        <label>{{ $t('objects.orders.text.patient_name') }}</label>
        <div class="info-order__patient">
          <span class="three-dot">{{ form.name_info }}</span>
          <div class="info-order__line" />
        </div>
      </div>
      <div class="info-order__group">
        <label>{{ $t('objects.orders.text.patient_romaji_name') }}</label>
        <div class="info-order__patient">
          <span class="three-dot">{{ form.roma_last_name }} {{ form.roma_first_name }}</span>
          <div class="info-order__line" />
        </div>
      </div>
    </div>
    <div class="info-order__row info-order__row--custom">
      <div class="info-order__group">
        <label>{{ $t('objects.orders.text.clinic_name') }}</label>
        <div class="info-order__patient">
          <span class="three-dot three-dot--one-line">{{ form.formal_name }}</span>
          <div class="info-order__line" />
        </div>
      </div>
      <div class="info-order__group">
        <label>{{ $t('objects.orders.text.person_in_charge') }}</label>
        <div
          v-if="form.staffs_name"
          class="info-order__patient"
        >
          <span class="three-dot three-dot--one-line">{{ form.staffs_name.toString() }}</span>
          <div class="info-order__line" />
        </div>
      </div>
      <div class="info-order__group">
        <label>{{ $t('objects.orders.text.clean_line') }}</label>
        <div class="info-order__patient info-order__order-clinic-status">
          <span class="three-dot three-dot--one-line">{{ $t('objects.orders.text.order_visit_number_option.' + form.order_visit_number) }}</span>
          <div class="info-order__line" />
        </div>
      </div>
    </div>
    <div class="info-order__row info-order__row--full-width">
      <div class="info-order__group info-order__delivery-date">
        <label>{{ $t('objects.orders.text.delivery_date') }}</label>
        <div class="info-order__patient">
          <span>{{ form.delivery_date }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      form: {
        type: Object,
        default: () => {}
      }
    }
  }
</script>
