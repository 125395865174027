<template>
  <div>
    <div class="instruction-print">
      <div class="instruction-print__info">
        <div class="instruction-print__info__row">
          <div class="instruction-print__info__col">
            <h3>技工指示書</h3>
          </div>
          <div class="instruction-print__info__col">
            <div class="instruction-print__info__item instruction-print__info__item--time">
              <div class="instruction-print__info__label">
                診察日：
              </div>
              <div class="instruction-print__info__value">
                {{ today }}
              </div>
            </div>
            <div class="instruction-print__info__item instruction-print__info__item--time">
              <div class="instruction-print__info__label">
                希望納品日：
              </div>
            </div>
          </div>
        </div>
        <div class="instruction-print__info__row">
          <div class="instruction-print__info__col">
            <div class="instruction-print__info__item instruction-print__info__item--clinic">
              <div class="instruction-print__info__label">
                歯科院：
              </div>
              <div
                v-if="clinic.print_name_1 || clinic.print_name_2"
                class="nstruction-print__info__value"
              >
                {{ clinic.print_name_1 }}<br>
                {{ clinic.print_name_2 }}
              </div>
              <div
                v-else
                class="nstruction-print__info__value"
              >
                {{ clinic.formal_name }}
              </div>
            </div>
          </div>
          <div class="instruction-print__info__col">
            <div class="instruction-print__info__item instruction-print__info__item--times">
              <div class="nstruction-print__info__value">
                初回　・　2回目以降　・　最終　・　調整
              </div>
            </div>
          </div>
        </div>
        <div class="instruction-print__info__row instruction-print__info__row--patient">
          <div class="instruction-print__info__col">
            <div class="instruction-print__info__item">
              <div class="instruction-print__info__label">
                患者ID：
              </div>
              <div class="nstruction-print__info__value">
                {{ patient.sf_user_id }}
              </div>
            </div>
          </div>
          <div class="instruction-print__info__col">
            <div class="instruction-print__info__item">
              <div class="instruction-print__info__label">
                担当医：
              </div>
              <div class="nstruction-print__info__value" />
            </div>
          </div>
        </div>
        <div class="instruction-print__info__row instruction-print__info__row--staff">
          <div class="instruction-print__info__col">
            <div class="instruction-print__info__item">
              <div class="instruction-print__info__label">
                氏名：
              </div>
              <div class="nstruction-print__info__value">
                {{ name }}
              </div>
            </div>
          </div>
          <div class="instruction-print__info__col">
            <div class="instruction-print__info__item">
              <div class="instruction-print__info__label">
                ローマ字：
              </div>
              <div class="nstruction-print__info__value">
                {{ romaName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="instruction-print__guideline">
        <div class="instruction-print__guideline__title">
          技工指示
        </div>
        <div class="instruction-print__guideline__image">
          <img src="/images/print_instruction_teeth.png">
        </div>
        <div class="instruction-print__guideline__molar-setup">
          <span class="instruction-print__checkbox" />
          臼歯部のセットアップは不可
        </div>
        <div class="instruction-print__guideline__row instruction-print__guideline__row--one">
          <div class="instruction-print__guideline__box">
            <div class="instruction-print__guideline__label">
              iTero ID
            </div>
          </div>
          <div class="instruction-print__guideline__box">
            <div class="instruction-print__guideline__label">
              拡大床
            </div>
            <div class="instruction-print__guideline__option">
              <p>
                <span class="instruction-print__checkbox" />
                上
              </p>
              <p>
                <span class="instruction-print__checkbox" />
                下
              </p>
            </div>
          </div>
          <div class="instruction-print__guideline__box">
            <div class="instruction-print__guideline__label">
              上顎2-3間に隙間許容
            </div>
            <div class="instruction-print__guideline__option">
              <p>
                <span class="instruction-print__checkbox" />
                可
              </p>
              <div style="height: 46px;" />
            </div>
          </div>
          <div class="instruction-print__guideline__box">
            <div class="instruction-print__guideline__label">
              IPR
            </div>
            <div class="instruction-print__guideline__option">
              <p>
                <span class="instruction-print__checkbox" />
                印象採得前に実施済
              </p>
              <p>
                <span class="instruction-print__checkbox" />
                受渡し時に実施予定
              </p>
            </div>
          </div>
        </div>
        <div class="instruction-print__guideline__row instruction-print__guideline__row--two">
          <div class="instruction-print__guideline__box">
            <div class="instruction-print__guideline__label" />
            <div class="instruction-print__guideline__option">
              <p>
                <span class="instruction-print__checkbox" />
                U6-6 40mm以上　拡大可
              </p>
              <p>
                <span class="instruction-print__checkbox" />
                Dr.指示拡大量を超えて拡大可
              </p>
            </div>
          </div>
          <div class="instruction-print__guideline__box">
            <div class="instruction-print__guideline__label">
              上顎両側１番の位置
            </div>
            <div class="instruction-print__guideline__option">
              <p>
                <span class="instruction-print__checkbox" />
                維持
              </p>
              <p>
                <span class="instruction-print__checkbox" />
                唇側へ：許容・積極的に移動
              </p>
              <p>
                <span class="instruction-print__checkbox" />
                舌側へ：許容・積極的に移動
              </p>
            </div>
          </div>
          <div class="instruction-print__guideline__box">
            <div class="instruction-print__guideline__label">
              矮小歯の補綴予定
            </div>
            <div class="instruction-print__guideline__option">
              <p>
                <span class="instruction-print__checkbox" />
                あり
              </p>
              <div class="instruction-print__guideline__note instruction-print__guideline__note--three">
                <span>(補綴用のスペースを確保してください）</span>
                <span>※チェックがない場合スペースを埋めるか</span>
                <span>どうか技工士の判断に任せます</span>
              </div>
            </div>
          </div>
          <div class="instruction-print__guideline__box">
            <div class="instruction-print__guideline__label">
              素材指定
            </div>
            <div class="instruction-print__guideline__option">
              <p class="instruction-print__guideline__note">
                (Zendura)
              </p>
            </div>
          </div>
        </div>
      </div>
      <table class="instruction-print__table instruction-print__table--note">
        <thead>
          <tr>
            <th>通常発注</th>
            <th width="11.5%">
              数量
            </th>
            <th width="6%">
              無料
            </th>
            <th width="11.4%">
              無料個数
            </th>
            <th width="24%">
              無料申請理由※
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="padding-bottom: 0 !important;  padding-top: 1px !important;">
              <span class="fontSize11">通常キレイライン上下セット</span>
            </td>
            <td />
            <td class="text-center">
              <span class="instruction-print__checkbox" />
            </td>
            <td />
            <td />
          </tr>
        </tbody>
      </table>
      <table class="instruction-print__table instruction-print__table--order">
        <thead>
          <tr>
            <th>
              リテ－ナ－/追加アライナ－/拡大装置等発注
            </th>
            <th width="11.5%">
              数量
            </th>
            <th width="6%">
              無料
            </th>
            <th width="11.4%">
              無料個数
            </th>
            <th width="24%">
              無料申請理由※
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            <td />
            <td class="text-center">
              <span class="instruction-print__checkbox" />
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td />
            <td />
            <td class="text-center">
              <span class="instruction-print__checkbox" />
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td />
            <td />
            <td class="text-center">
              <span class="instruction-print__checkbox" />
            </td>
            <td />
            <td />
          </tr>
        </tbody>
      </table>
      <div class="instruction-print__reason">
        <p class="instruction-print__reason__title">
          <span>無料申請理由</span>(※)　下記より選択いただき、アルファベットを記載ください
        </p>
        <div class="instruction-print__reason__option">
          <span>Ａ．クリニックスタッフまたはモニター</span>
          <span>Ｃ．再セットアップ依頼</span>
        </div>
        <p class="instruction-print__reason__note">
          2019年3月末までにご契約された方のみ9回目、10回目を無料にて製作させて頂きます。
        </p>
      </div>
      <table class="instruction-print__table instruction-print__table--product">
        <thead>
          <tr>
            <th>
              <label>コース契約情報</label>
              <span class="note">(※契約時のみ契約コースの回数に○を付けてください。以降は事務局にて回数を把握しますので都度記載いただく必要はございません。)</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="font-weight-600">
              <span>初回限定割引（　１　・　４　・　７　・　１０　）回コース</span>
              <span>追加用（　１　・　３　・　６　・　９　）回コース</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="instruction-print__address">
        <div class="instruction-print__address__title">
          技工所情報（ご注文に関するお問い合わせはSheepMedical＜03-3551-0357＞へお願い致します）
        </div>
        <div class="instruction-print__address__content">
          SheepMedical株式会社　東京都中央区八丁堀3-26-8 高橋ビル5階　／　株式会社ホワイトライン　東京都渋谷区西原3-20-5-1F　／　株式会社ACS　鹿児島県曽於郡大崎町假宿1102-1　／　田振デンタルラボ　福岡県八女郡広川町大字久泉950-10　／　株式会社エイコー桐生　滋賀県草津市馬場町1100番地9　／　株式会社Union Design Network　東京都千代田区岩本町3-4-1 トリキンビル3階　／　株式会社MCDデザイン　神奈川県横浜市中区翁町1-4 大有ビル3F　／　株式会社WKDデザイン　愛知県名古屋市中区栄一丁目13番4号 みその大林ビル7階C号　／　吉田デンタルデザイン株式会社　大阪市福島区福島5-1-1 若杉西梅田ビル301号　／　株式会社Rデザイン　東京都文京区音羽1-20-14 MBS音羽ビル8F　／　メディカル・コミュニケーション　岡山県岡山市北区青江5-1-1 ライフプラザ青江　／　有限会社キンキラボワーク　兵庫県伊丹市南町4-5-29　／　矯正ラボATOM　埼玉県川口市本町3-5-3-105　／　株式会社北部九州デンタルラボ　福岡県福岡市中央区天神3-6-4 briTENJIN 7階　／　札幌中央歯科技工株式会社　札幌市中央区北2条西2丁目29番地2 ウエノビル4階 A号室　／　株式会社デントラボ宮城　宮城県仙台市宮城野区名掛丁202-1 GIFT仙台東口 3階B　／　株式会社アーチボーテ　大阪府大阪市福島区福島4-6-31 機動ビル1F　／　株式会社R&S　大阪市住吉区我孫子3-11-26 ニューキタノビル401
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { intVal } from '../../../utils/helpers'

  export default {
    data () {
      return {
        patient_id: '',
        patient: {}
      }
    },
    computed: {
      clinic () {
        return this.patient.clinic || {}
      },
      patientInfo () {
        return this.patient.patient_info || {}
      },
      today () {
        return moment().format('YYYY年MM月DD日')
      },
      name () {
        return this.patientInfo.name || ''
      },
      romaName () {
        return `${this.patientInfo.roma_last_name || ''} ${this.patientInfo.roma_first_name || ''}`
      }
    },
    created () {
      this.patient_id = this.$route.params.id
      window.setDataInstruction = this.setData.bind(this)
      window.printPatientInstruction = this.print.bind(this)
      this.removeAllStyle()
      this.addStylePrint()
      this.addStyleHtmlAndBody()
      this.setTitlePage()
    },
    methods: {
      setData (patient) {
        this.patient = patient
        this.setTitlePage()
      },
      print (patient) {
        if (this.patient.id) {
          window.print()
        }
      },
      removeAllStyle () {
        $('link[rel=stylesheet]').remove()
      },
      addStylePrint () {
        $('head').append(`<link rel="stylesheet" href="${window.styleLinkPrintIntruction}" />`)
      },
      addStyleHtmlAndBody () {
        $('html').addClass('html_instruction_print')
        $('body').addClass('body_instruction_print')
      },
      setTitlePage () {
        document.title = this.$t(
          'objects.patient.button.technician_instruction_template',
          { patient_id: this.patient.sf_user_id || this.patient.id }
        )
      }
    }
  }
</script>
