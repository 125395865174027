<template>
  <transition name="modal">
    <div :class="['mc-modal registered', customClass]">
      <div class="registered__wrapper">
        <div class="registered__header">
          <h2 class="registered__heading">
            {{ $t('objects.setting.text.staff_registration_completed') }}
          </h2>
          <div
            class="registered__close"
            @click="$emit('close-modal')"
          >
            <img src="/images/close-icon-blue.svg">
          </div>
        </div>
        <div class="registered__body">
          <h2 class="registered__title">
            {{ $t('objects.setting.text.staff_information_after_registered') }}
          </h2>
          <div class="registered__information">
            <div class="registered__row">
              <div class="registered__row registered__row--left">
                <h3 class="registered__column-title">
                  {{ $t('new_text.name') }}
                </h3>
              </div>
              <div class="registered__row registered__row--right">
                <p class="registered__text break-word">
                  {{ `${staffInfo.lastname} ${staffInfo.firstname}(${staffInfo.lastname_roma} ${staffInfo.firstname_roma})` }}
                </p>
              </div>
            </div>
            <div class="registered__row">
              <div class="registered__row registered__row--left">
                <h3 class="registered__column-title">
                  {{ $t('new_text.position') }}
                </h3>
              </div>
              <div class="registered__row registered__row--right">
                <p class="registered__text">
                  {{ staffInfo.position.name || '' }}
                </p>
              </div>
            </div>
            <div class="registered__row">
              <div class="registered__row registered__row--left">
                <h3 class="registered__column-title">
                  {{ $t('objects.setting.text.is_admin') }}
                </h3>
              </div>
              <div class="registered__row registered__row--right">
                <p class="registered__text">
                  {{ staffInfo.is_admin === 1 ? $t('new_text.button.yes') : $t('new_text.button.none') }}
                </p>
              </div>
            </div>
          </div>
          <h2 class="registered__title registered__title--sub">
            {{ $t('objects.setting.text.information_is_displayed_only_once') }}
          </h2>
          <div class="registered__account">
            <div class="registered__login-text">
              <div
                v-if="staffInfo.send_mail"
                class="registered__box"
              >
                <p class="registered__full-size">
                  {{ $t('objects.setting.text.account_information') }}
                </p>
                <p>
                  {{ $t('objects.setting.text.send_email', { email: staffInfo.email }) }}
                </p>
                <p>
                  {{ $t('objects.setting.text.check_email') }}
                </p>
              </div>
              <div
                v-else
                class="registered__box"
              >
                <div class="registered__copy">
                  <div
                    id="copy-content"
                    class="registered__copy--content"
                  >
                    <p class="registered__full-size">
                      {{ $t('objects.setting.text.account_information') }}
                    </p>
                    <p class="break-word">
                      {{ $t('new_text.name') }}: {{ staffInfo.lastname + ' ' + staffInfo.firstname }}
                    </p>
                    <p>{{ $t('new_text.login_id') }}:  {{ staffInfo.login_id }}</p>
                    <p>{{ $t('objects.setting.text.password_init') }}: {{ staffInfo.password }}</p>
                    <p class="registered__full-size">
                      {{ $t('new_text.login_url') }}
                    </p>
                    <p>{{ loginUrl }}</p>
                  </div>
                  <button-common
                    class="registered__button registered__button--copy"
                    @click="copyToClipboard"
                  >
                    <template #content>
                      {{ $t('objects.setting.button.copy_to_clipboard') }}
                    </template>
                  </button-common>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="registered__footer registered__footer--center">
          <button-common
            class="registered__button registered__button--close"
            @click="$emit('close-modal')"
          >
            <template #content>
              {{ $t('new_text.button.close') }}
            </template>
          </button-common>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import ButtonCommon from '../common/Button'
  import { showSuccessModal } from '../../utils/helpers'

  export default {
    components: {
      ButtonCommon
    },
    props: {
      customClass: {
        type: String,
        default: ''
      },
      staffInfo: {
        type: Object,
        default: () => {}
      }
    },
    emits: ['close-modal'],
    computed: {
      loginUrl: function () {
        if (!this.$store.state.auth.user || !this.$store.state.auth.user.misc) {
          return ''
        }

        return this.$store.state.auth.user.misc.login_url
      }
    },
    methods: {
      copyToClipboard: function () {
        const elm = document.getElementById('copy-content')
        if (document.body.createTextRange) {
          const range = document.body.createTextRange()
          range.moveToElementText(elm)
          range.select()
        } else if (window.getSelection) {
          const selection = window.getSelection()
          const range = document.createRange()
          range.selectNodeContents(elm)
          selection.removeAllRanges()
          selection.addRange(range)
        }
        document.execCommand('copy')
        showSuccessModal(this.$t('new_text.button.copy'))
      }
    }
  }
</script>
