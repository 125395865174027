var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "instruction-print" }, [
      _c("div", { staticClass: "instruction-print__info" }, [
        _c("div", { staticClass: "instruction-print__info__row" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "instruction-print__info__col" }, [
            _c(
              "div",
              {
                staticClass:
                  "instruction-print__info__item instruction-print__info__item--time"
              },
              [
                _c("div", { staticClass: "instruction-print__info__label" }, [
                  _vm._v("\n              診察日：\n            ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "instruction-print__info__value" }, [
                  _vm._v(
                    "\n              " + _vm._s(_vm.today) + "\n            "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _vm._m(1)
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "instruction-print__info__row" }, [
          _c("div", { staticClass: "instruction-print__info__col" }, [
            _c(
              "div",
              {
                staticClass:
                  "instruction-print__info__item instruction-print__info__item--clinic"
              },
              [
                _c("div", { staticClass: "instruction-print__info__label" }, [
                  _vm._v("\n              歯科院：\n            ")
                ]),
                _vm._v(" "),
                _vm.clinic.print_name_1 || _vm.clinic.print_name_2
                  ? _c(
                      "div",
                      { staticClass: "nstruction-print__info__value" },
                      [
                        _vm._v(
                          "\n              " + _vm._s(_vm.clinic.print_name_1)
                        ),
                        _c("br"),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.clinic.print_name_2) +
                            "\n            "
                        )
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "nstruction-print__info__value" },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.clinic.formal_name) +
                            "\n            "
                        )
                      ]
                    )
              ]
            )
          ]),
          _vm._v(" "),
          _vm._m(2)
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "instruction-print__info__row instruction-print__info__row--patient"
          },
          [
            _c("div", { staticClass: "instruction-print__info__col" }, [
              _c("div", { staticClass: "instruction-print__info__item" }, [
                _c("div", { staticClass: "instruction-print__info__label" }, [
                  _vm._v("\n              患者ID：\n            ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "nstruction-print__info__value" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.patient.sf_user_id) +
                      "\n            "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _vm._m(3)
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "instruction-print__info__row instruction-print__info__row--staff"
          },
          [
            _c("div", { staticClass: "instruction-print__info__col" }, [
              _c("div", { staticClass: "instruction-print__info__item" }, [
                _c("div", { staticClass: "instruction-print__info__label" }, [
                  _vm._v("\n              氏名：\n            ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "nstruction-print__info__value" }, [
                  _vm._v(
                    "\n              " + _vm._s(_vm.name) + "\n            "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "instruction-print__info__col" }, [
              _c("div", { staticClass: "instruction-print__info__item" }, [
                _c("div", { staticClass: "instruction-print__info__label" }, [
                  _vm._v("\n              ローマ字：\n            ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "nstruction-print__info__value" }, [
                  _vm._v(
                    "\n              " + _vm._s(_vm.romaName) + "\n            "
                  )
                ])
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _vm._m(6),
      _vm._v(" "),
      _vm._m(7),
      _vm._v(" "),
      _vm._m(8),
      _vm._v(" "),
      _vm._m(9)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "instruction-print__info__col" }, [
      _c("h3", [_vm._v("技工指示書")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "instruction-print__info__item instruction-print__info__item--time"
      },
      [
        _c("div", { staticClass: "instruction-print__info__label" }, [
          _vm._v("\n              希望納品日：\n            ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "instruction-print__info__col" }, [
      _c(
        "div",
        {
          staticClass:
            "instruction-print__info__item instruction-print__info__item--times"
        },
        [
          _c("div", { staticClass: "nstruction-print__info__value" }, [
            _vm._v(
              "\n              初回　・　2回目以降　・　最終　・　調整\n            "
            )
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "instruction-print__info__col" }, [
      _c("div", { staticClass: "instruction-print__info__item" }, [
        _c("div", { staticClass: "instruction-print__info__label" }, [
          _vm._v("\n              担当医：\n            ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "nstruction-print__info__value" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "instruction-print__guideline" }, [
      _c("div", { staticClass: "instruction-print__guideline__title" }, [
        _vm._v("\n        技工指示\n      ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "instruction-print__guideline__image" }, [
        _c("img", { attrs: { src: "/images/print_instruction_teeth.png" } })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "instruction-print__guideline__molar-setup" }, [
        _c("span", { staticClass: "instruction-print__checkbox" }),
        _vm._v("\n        臼歯部のセットアップは不可\n      ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "instruction-print__guideline__row instruction-print__guideline__row--one"
        },
        [
          _c("div", { staticClass: "instruction-print__guideline__box" }, [
            _c("div", { staticClass: "instruction-print__guideline__label" }, [
              _vm._v("\n            iTero ID\n          ")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "instruction-print__guideline__box" }, [
            _c("div", { staticClass: "instruction-print__guideline__label" }, [
              _vm._v("\n            拡大床\n          ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "instruction-print__guideline__option" }, [
              _c("p", [
                _c("span", { staticClass: "instruction-print__checkbox" }),
                _vm._v("\n              上\n            ")
              ]),
              _vm._v(" "),
              _c("p", [
                _c("span", { staticClass: "instruction-print__checkbox" }),
                _vm._v("\n              下\n            ")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "instruction-print__guideline__box" }, [
            _c("div", { staticClass: "instruction-print__guideline__label" }, [
              _vm._v("\n            上顎2-3間に隙間許容\n          ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "instruction-print__guideline__option" }, [
              _c("p", [
                _c("span", { staticClass: "instruction-print__checkbox" }),
                _vm._v("\n              可\n            ")
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { height: "46px" } })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "instruction-print__guideline__box" }, [
            _c("div", { staticClass: "instruction-print__guideline__label" }, [
              _vm._v("\n            IPR\n          ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "instruction-print__guideline__option" }, [
              _c("p", [
                _c("span", { staticClass: "instruction-print__checkbox" }),
                _vm._v("\n              印象採得前に実施済\n            ")
              ]),
              _vm._v(" "),
              _c("p", [
                _c("span", { staticClass: "instruction-print__checkbox" }),
                _vm._v("\n              受渡し時に実施予定\n            ")
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "instruction-print__guideline__row instruction-print__guideline__row--two"
        },
        [
          _c("div", { staticClass: "instruction-print__guideline__box" }, [
            _c("div", { staticClass: "instruction-print__guideline__label" }),
            _vm._v(" "),
            _c("div", { staticClass: "instruction-print__guideline__option" }, [
              _c("p", [
                _c("span", { staticClass: "instruction-print__checkbox" }),
                _vm._v("\n              U6-6 40mm以上　拡大可\n            ")
              ]),
              _vm._v(" "),
              _c("p", [
                _c("span", { staticClass: "instruction-print__checkbox" }),
                _vm._v(
                  "\n              Dr.指示拡大量を超えて拡大可\n            "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "instruction-print__guideline__box" }, [
            _c("div", { staticClass: "instruction-print__guideline__label" }, [
              _vm._v("\n            上顎両側１番の位置\n          ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "instruction-print__guideline__option" }, [
              _c("p", [
                _c("span", { staticClass: "instruction-print__checkbox" }),
                _vm._v("\n              維持\n            ")
              ]),
              _vm._v(" "),
              _c("p", [
                _c("span", { staticClass: "instruction-print__checkbox" }),
                _vm._v(
                  "\n              唇側へ：許容・積極的に移動\n            "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _c("span", { staticClass: "instruction-print__checkbox" }),
                _vm._v(
                  "\n              舌側へ：許容・積極的に移動\n            "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "instruction-print__guideline__box" }, [
            _c("div", { staticClass: "instruction-print__guideline__label" }, [
              _vm._v("\n            矮小歯の補綴予定\n          ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "instruction-print__guideline__option" }, [
              _c("p", [
                _c("span", { staticClass: "instruction-print__checkbox" }),
                _vm._v("\n              あり\n            ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "instruction-print__guideline__note instruction-print__guideline__note--three"
                },
                [
                  _c("span", [_vm._v("(補綴用のスペースを確保してください）")]),
                  _vm._v(" "),
                  _c("span", [_vm._v("※チェックがない場合スペースを埋めるか")]),
                  _vm._v(" "),
                  _c("span", [_vm._v("どうか技工士の判断に任せます")])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "instruction-print__guideline__box" }, [
            _c("div", { staticClass: "instruction-print__guideline__label" }, [
              _vm._v("\n            素材指定\n          ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "instruction-print__guideline__option" }, [
              _c("p", { staticClass: "instruction-print__guideline__note" }, [
                _vm._v("\n              (Zendura)\n            ")
              ])
            ])
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "table",
      {
        staticClass: "instruction-print__table instruction-print__table--note"
      },
      [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("通常発注")]),
            _vm._v(" "),
            _c("th", { attrs: { width: "11.5%" } }, [
              _vm._v("\n            数量\n          ")
            ]),
            _vm._v(" "),
            _c("th", { attrs: { width: "6%" } }, [
              _vm._v("\n            無料\n          ")
            ]),
            _vm._v(" "),
            _c("th", { attrs: { width: "11.4%" } }, [
              _vm._v("\n            無料個数\n          ")
            ]),
            _vm._v(" "),
            _c("th", { attrs: { width: "24%" } }, [
              _vm._v("\n            無料申請理由※\n          ")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("tbody", [
          _c("tr", [
            _c(
              "td",
              {
                staticStyle: {
                  "padding-bottom": "0 !important",
                  "padding-top": "1px !important"
                }
              },
              [
                _c("span", { staticClass: "fontSize11" }, [
                  _vm._v("通常キレイライン上下セット")
                ])
              ]
            ),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", { staticClass: "text-center" }, [
              _c("span", { staticClass: "instruction-print__checkbox" })
            ]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td")
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "table",
      {
        staticClass: "instruction-print__table instruction-print__table--order"
      },
      [
        _c("thead", [
          _c("tr", [
            _c("th", [
              _vm._v(
                "\n            リテ－ナ－/追加アライナ－/拡大装置等発注\n          "
              )
            ]),
            _vm._v(" "),
            _c("th", { attrs: { width: "11.5%" } }, [
              _vm._v("\n            数量\n          ")
            ]),
            _vm._v(" "),
            _c("th", { attrs: { width: "6%" } }, [
              _vm._v("\n            無料\n          ")
            ]),
            _vm._v(" "),
            _c("th", { attrs: { width: "11.4%" } }, [
              _vm._v("\n            無料個数\n          ")
            ]),
            _vm._v(" "),
            _c("th", { attrs: { width: "24%" } }, [
              _vm._v("\n            無料申請理由※\n          ")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("tbody", [
          _c("tr", [
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", { staticClass: "text-center" }, [
              _c("span", { staticClass: "instruction-print__checkbox" })
            ]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td")
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", { staticClass: "text-center" }, [
              _c("span", { staticClass: "instruction-print__checkbox" })
            ]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td")
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", { staticClass: "text-center" }, [
              _c("span", { staticClass: "instruction-print__checkbox" })
            ]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td")
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "instruction-print__reason" }, [
      _c("p", { staticClass: "instruction-print__reason__title" }, [
        _c("span", [_vm._v("無料申請理由")]),
        _vm._v(
          "(※)　下記より選択いただき、アルファベットを記載ください\n      "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "instruction-print__reason__option" }, [
        _c("span", [_vm._v("Ａ．クリニックスタッフまたはモニター")]),
        _vm._v(" "),
        _c("span", [_vm._v("Ｃ．再セットアップ依頼")])
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "instruction-print__reason__note" }, [
        _vm._v(
          "\n        2019年3月末までにご契約された方のみ9回目、10回目を無料にて製作させて頂きます。\n      "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "table",
      {
        staticClass:
          "instruction-print__table instruction-print__table--product"
      },
      [
        _c("thead", [
          _c("tr", [
            _c("th", [
              _c("label", [_vm._v("コース契約情報")]),
              _vm._v(" "),
              _c("span", { staticClass: "note" }, [
                _vm._v(
                  "(※契約時のみ契約コースの回数に○を付けてください。以降は事務局にて回数を把握しますので都度記載いただく必要はございません。)"
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("tbody", [
          _c("tr", [
            _c("td", { staticClass: "font-weight-600" }, [
              _c("span", [
                _vm._v(
                  "初回限定割引（　１　・　４　・　７　・　１０　）回コース"
                )
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v("追加用（　１　・　３　・　６　・　９　）回コース")
              ])
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "instruction-print__address" }, [
      _c("div", { staticClass: "instruction-print__address__title" }, [
        _vm._v(
          "\n        技工所情報（ご注文に関するお問い合わせはSheepMedical＜03-3551-0357＞へお願い致します）\n      "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "instruction-print__address__content" }, [
        _vm._v(
          "\n        SheepMedical株式会社　東京都中央区八丁堀3-26-8 高橋ビル5階　／　株式会社ホワイトライン　東京都渋谷区西原3-20-5-1F　／　株式会社ACS　鹿児島県曽於郡大崎町假宿1102-1　／　田振デンタルラボ　福岡県八女郡広川町大字久泉950-10　／　株式会社エイコー桐生　滋賀県草津市馬場町1100番地9　／　株式会社Union Design Network　東京都千代田区岩本町3-4-1 トリキンビル3階　／　株式会社MCDデザイン　神奈川県横浜市中区翁町1-4 大有ビル3F　／　株式会社WKDデザイン　愛知県名古屋市中区栄一丁目13番4号 みその大林ビル7階C号　／　吉田デンタルデザイン株式会社　大阪市福島区福島5-1-1 若杉西梅田ビル301号　／　株式会社Rデザイン　東京都文京区音羽1-20-14 MBS音羽ビル8F　／　メディカル・コミュニケーション　岡山県岡山市北区青江5-1-1 ライフプラザ青江　／　有限会社キンキラボワーク　兵庫県伊丹市南町4-5-29　／　矯正ラボATOM　埼玉県川口市本町3-5-3-105　／　株式会社北部九州デンタルラボ　福岡県福岡市中央区天神3-6-4 briTENJIN 7階　／　札幌中央歯科技工株式会社　札幌市中央区北2条西2丁目29番地2 ウエノビル4階 A号室　／　株式会社デントラボ宮城　宮城県仙台市宮城野区名掛丁202-1 GIFT仙台東口 3階B　／　株式会社アーチボーテ　大阪府大阪市福島区福島4-6-31 機動ビル1F　／　株式会社R&S　大阪市住吉区我孫子3-11-26 ニューキタノビル401\n      "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }