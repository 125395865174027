var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "forgot-password bg-blue" },
    [
      _c("loading"),
      _vm._v(" "),
      _c("div", { staticClass: "forgot-password__wrapper" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "forgot-password__info" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("new_text.forgot_password_text_1")))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.$t("new_text.forgot_password_text_2")))])
        ]),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "forgot-password__form",
            attrs: { autocomplete: "off", method: "post" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.resetPassword($event)
              }
            }
          },
          [
            _c("div", [
              _c(
                "div",
                { staticClass: "forgot-password__form__group" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "forgot-password__label",
                      attrs: { for: "staff_id" }
                    },
                    [_vm._v(_vm._s(_vm.$t("objects.staff.staff_id")))]
                  ),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "exclusion-icon",
                        placement: "top",
                        triggers: "hover focus",
                        "custom-class": "exclusion-notify",
                        "fallback-placement": "clockwise"
                      }
                    },
                    [
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("new_message.forgotten_staff_id_or_email")
                          )
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.staff_id,
                        expression: "staff_id"
                      }
                    ],
                    staticClass: "forgot-password__input",
                    attrs: {
                      id: "staff_id",
                      type: "text",
                      placeholder: _vm.$t("new_text.placeholder.required", {
                        attribute: _vm.$t("objects.staff.staff_id")
                      }),
                      name: "staff_id"
                    },
                    domProps: { value: _vm.staff_id },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.staff_id = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.validation_errors.staff_id
                    ? _c(
                        "span",
                        { staticClass: "forgot-password__error-msg" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.validation_errors.staff_id) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "forgot-password__form__group forgot-password__form__group--second"
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "forgot-password__label",
                      attrs: { for: "email" }
                    },
                    [_vm._v(_vm._s(_vm.$t("new_text.email")))]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email"
                      }
                    ],
                    staticClass: "forgot-password__input",
                    attrs: {
                      id: "email",
                      type: "text",
                      placeholder: _vm.$t("new_text.placeholder.required", {
                        attribute: _vm.$t("new_text.email")
                      }),
                      name: "email"
                    },
                    domProps: { value: _vm.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.email = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.validation_errors.email
                    ? _c(
                        "span",
                        { staticClass: "forgot-password__error-msg" },
                        [_vm._v(_vm._s(_vm.validation_errors.email))]
                      )
                    : _vm._e()
                ]
              )
            ]),
            _vm._v(" "),
            _vm.response_error
              ? _c(
                  "span",
                  {
                    staticClass:
                      "forgot-password__error-msg forgot-password__error-msg--response"
                  },
                  [_vm._v(_vm._s(_vm.response_error))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "forgot-password__form__action" },
              [
                _c(
                  "button",
                  {
                    staticClass: "forgot-password__button",
                    attrs: { type: "submit", name: "submit" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("new_text.button.send_verification_email")
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "forgot-password__button forgot-password__button--back",
                    attrs: { to: { name: "auth.login" } }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("new_text.button.back_to_login")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "forgot-password__header" }, [
      _c("img", {
        staticClass: "forgot-password__logo",
        attrs: { src: "/images/kirei_lign_logo.png", alt: "Kireilign" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "forgot-password__exclusion-icon",
        attrs: { id: "exclusion-icon" }
      },
      [_c("img", { attrs: { src: "/images/exclusion.svg" } })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }