<template>
  <header>
    <div class="header_inner clearfix">
      <div class="header-box header-account">
        <div class="col-xl-3 col-lg-3 col-sm-4">
          <div class="header-layout-left">
            <slot name="header-layout-left" />
          </div>
        </div>
        <div class="col-xl-9 col-lg-9 col-sm-8">
          <div class="header-layout-right">
            <slot name="header-layout-right">
              <slot name="header-layout-right-1">
                <div class="header-user-info float-right col-md-4">
                  <user-info />
                </div>
              </slot>
              <slot name="header-layout-right-2" />
            </slot>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
  import UserInfo from './UserInfo'
  export default {
    name: 'HeaderLayout',
    components: {
      UserInfo
    }
  }
</script>
