<template>
  <logo>
    <div class="justify-content-center mt-5">
      <div class="form-group email-center">
        <input
          id="email"
          v-model="email"
          type="email"
          placeholder="example@kireilign.com"
          value="example@kireilign.com"
          class="form-control"
          readonly
        >
      </div>
    </div>
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="resetPassword"
    >
      <div class="justify-content-center form_container">
        <div class="form-group">
          <label for="pwd">{{ $t('common_page.password') }}</label>
          <span class="note-tip">{{ $t('common_page.input_characters') }}</span>
          <input
            id="pwd"
            v-model="password"
            type="password"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label for="confirm-password">{{ $t('common_page.confirm_password') }}</label>
          <span class="note-tip">{{ $t('common_page.input_characters') }}</span>
          <input
            id="confirm-password"
            v-model="password_confirmation"
            type="password"
            class="form-control"
          >
        </div>
      </div>
      <div class="mt-2 forget-password">
        <validation-errors
          v-if="validationErrors"
          :errors="validationErrors"
        />
      </div>
      <div class="d-flex justify-content-center mt-3 mb-4 login_container">
        <button
          type="submit"
          name="button"
          class="btn login_btn"
        >
          {{ $t('common_page.button_settings') }}
        </button>
      </div>
    </form>
  </logo>
</template>

<script>
  import logo from '../components/Logo'
  import { PASSWORD_RESET_TYPE, RESPONSE_STATUS } from '../utils/constants'
  import { get } from 'lodash'

  export default {
    components: {
      logo
    },
    data () {
      return {
        token: '',
        email: '',
        password: '',
        password_confirmation: '',
        validationErrors: {},
        staff_id: ''
      }
    },
    created () {
      document.title = this.$t('page_title.u1_4')
      this.$store.commit('set_loading', true)
      this.token = this.$route.params.token
      this.$store.dispatch('getEmailByToken', { token: this.token }).then((result) => {
        const { data: { status, results } } = result
        this.$store.commit('set_loading', false)
        if (status === RESPONSE_STATUS.NG) {
          return this.$router.push({ name: 'not.found' })
        }
        if (results.type === PASSWORD_RESET_TYPE.NONE) {
          return this.$router.push({ name: 'not.found' })
        }
        if (results.type === PASSWORD_RESET_TYPE.EXPIRED) {
          return this.$router.push({ name: 'token.expired' })
        }
        this.email = results.email
        this.staff_id = results.staff_id
      }).catch(() => {
        this.$store.commit('set_loading', false)
        return this.$router.push({ name: 'not.found' })
      })
    },
    methods: {
      async resetPassword () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('resetPasswordRequest', {
          token: this.token,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          staff_id: this.staff_id
        }).then(() => {
          this.$store.commit('set_loading', false)
          this.$router.push({ name: 'reset.complete' })
        }).catch((error) => {
          this.$store.commit('set_loading', false)
          const tokenError = get(error, 'response.data.errors.token', false)
          if (tokenError) {
            if (tokenError === PASSWORD_RESET_TYPE.NONE) {
              return this.$router.push({ name: 'not.found' })
            }
            if (tokenError === PASSWORD_RESET_TYPE.EXPIRED) {
              return this.$router.push({ name: 'token.expired' })
            }

            // Failed validation
            return this.$router.push({ name: 'not.found' })
          }
          this.validationErrors = error.response.data.errors
        })
      }
    }
  }
</script>
