export default {
  activity_log_loading: 'Loading activity log...',
  invoice: {
    complete: 'Patient accounting completed',
    delete: 'Patient accounting deleted',
    issue: 'Patient accounting has been issued',
    update: 'Patient accounting has been updated',
    re_issue: 'Patient accounting reissued'
  },
  medical_record: {
    create: 'Medical record {id} created',
    delete: 'Medical record {id} has been deleted',
    update: 'Medical record {id} has been updated'
  },
  patient: {
    cancel_check_in: 'I cancel checked in',
    check_in: 'I checked in',
    delete: 'Patient information deleted',
    update: 'Patient information updated',
    recover: 'The deletion of patient information has been cancelled'
  },
  patient_interview: {
    complete: 'The questionnaire has been completed',
    create: 'Questionnaire was created'
  },
  patient_info_not_found: 'Patient information that meets the conditions cannot be found.',
  reservation: {
    accept: 'New booking accepted {date}',
    cancel: 'New booking canceled {date}',
    change_schedule: 'New booking schedule changed {date}'
  }
}
