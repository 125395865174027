import axios from 'axios'
import { state as authState } from './auth'
import INIT_INTERVIEW_SHEET from '../question'

export const state = {
  consultation: {
    consultation_item: [],
    consultation_item_other: '',
    points_symptoms: [
      {
        part: '',
        part_detail: '',
        dentition_number: [],
        symptoms: '',
        symptoms_other: '',
        consultation_content: ''
      },
      {
        part: '',
        part_detail: '',
        dentition_number: [],
        symptoms: '',
        symptoms_other: '',
        consultation_content: ''
      },
      {
        part: '',
        part_detail: '',
        dentition_number: [],
        symptoms: '',
        symptoms_other: '',
        consultation_content: ''
      }
    ]
  },
  interview_sheet: INIT_INTERVIEW_SHEET,
  others: {
    plan_to_move: '',
    when_to_move1: '',
    when_to_move2: '',
    moving_destination: '',
    other_requests: ''
  },
  is_loading_data_printing: true,
  first_time: {}
}
export const getters = {
  get_interviewsheet: (state) => {
    return state.interview_sheet
  },
  get_other: (state) => {
    return state.others
  },
  get_question: (state) => {
    // eslint-disable-next-line eqeqeq
    return number => state.interview_sheet.find(obj => obj.question == number)
  },
  get_consultation_content: (state) => {
    return state.consultation
  },
  get_interview_info: (state) => {
    return {
      consultation: state.consultation,
      interview_sheet: state.interview_sheet,
      others: state.others
    }
  },
  get_default_answer_items: function (state) {
    return number => INIT_INTERVIEW_SHEET.find(obj => obj.question === number)
  },
  get_loadingDataPrintingStatus: (state) => {
    return state.is_loading_data_printing
  },
  get_firstTime: (state) => {
    return state.first_time
  }
}

export const actions = {
  getPatientInterviewData ({ dispatch, commit }, id) {
    commit('resetPatientInterview')
    return axios.get(`/api/patient/${id}/interview`, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        if (res.data.results.interview_info) {
          commit('set_interview_info', res.data.results.interview_info.interview_info)
        }
        return res
      })
      .catch(error => {
        throw error
      })
  },
  savePatientInterviewData ({ dispatch, commit }, data) {
    return axios.post(`/api/patient/${data.patient_id}/interview`, data, {
      headers: { Authorization: 'Bearer ' + authState.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  }
}

export const mutations = {
  set_interview_info (state, obj) {
    // mapping
    if (typeof obj.interview_sheet === 'object') {
      state.interview_sheet = obj.interview_sheet
    }
    if (typeof obj.others === 'object') {
      Object.assign(state.others, obj.others)
    }
    if (typeof obj.consultation_content === 'object') {
      state.consultation = obj.consultation_content
    }
  },
  set_interviewsheet (state, value) {
    state.interview_sheet = value
  },
  set_others (state, value) {
    state.others = value
  },
  resetPatientInterview (state) {
    state.consultation = {
      consultation_item: [],
      consultation_item_other: '',
      points_symptoms: [
        {
          part: '',
          part_detail: '',
          dentition_number: [],
          symptoms: '',
          symptoms_other: '',
          consultation_content: ''
        },
        {
          part: '',
          part_detail: '',
          dentition_number: [],
          symptoms: '',
          symptoms_other: '',
          consultation_content: ''
        },
        {
          part: '',
          part_detail: '',
          dentition_number: [],
          symptoms: '',
          symptoms_other: '',
          consultation_content: ''
        }
      ]
    }
    state.interview_sheet = INIT_INTERVIEW_SHEET
    state.others = {
      plan_to_move: '',
      when_to_move1: '',
      when_to_move2: '',
      moving_destination: '',
      other_requests: ''
    }
  },
  set_loadingDataPrintingStatus: (state, value) => {
    state.is_loading_data_printing = value
  },
  set_firstTime: (state, value) => {
    state.first_time = value
  }
}
