var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-order" },
    [
      !_vm.form_data.is_patient_has_reserved_reservation
        ? _c("div", { staticClass: "info-order__warning-block" }, [
            _c("img", {
              staticClass: "info-order__warning-icon",
              attrs: { src: "/images/warning-grey.svg" }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "info-order__warning-content" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "objects.orders.message.patient_does_not_have_any_reserved_reservation"
                  )
                )
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "info-order__row" }, [
        _c(
          "div",
          { staticClass: "info-order__group" },
          [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("objects.orders.text.patient_id")))
            ]),
            _vm._v(" "),
            _c("input-search", {
              staticClass: "info-order__search",
              attrs: {
                "default-value": _vm.form_data.keyword_search_id,
                disabled: _vm.form_data.data_order.patient.sf_user_id !== "",
                "disable-select-patient": _vm.disable_select_patient,
                "icon-disabled": "/images/icon-close.svg"
              },
              on: { search: _vm.searchIDPatient }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "info-order__group" },
          [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("objects.orders.text.patient_name")))
            ]),
            _vm._v(" "),
            _c("input-search", {
              staticClass: "info-order__search",
              attrs: {
                placeholder: _vm.$t("text.select_patient_placeholder"),
                "default-value": _vm.form_data.keyword_search_name,
                disabled: _vm.form_data.data_order.patient.sf_user_id !== "",
                "disable-select-patient": _vm.disable_select_patient,
                "icon-disabled": "/images/icon-close.svg",
                type: "search-name"
              },
              on: { search: _vm.searchNamePatient }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "info-order__group" }, [
          _c("label", [
            _vm._v(_vm._s(_vm.$t("objects.orders.text.patient_romaji_name")))
          ]),
          _vm._v(" "),
          _vm.form_data.data_order.patient.roma_first_name
            ? _c("div", { staticClass: "info-order__patient three-dot" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.form_data.data_order.patient.roma_last_name) +
                      " " +
                      _vm._s(_vm.form_data.data_order.patient.roma_first_name)
                  )
                ])
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "info-order__row info-order__row--custom" }, [
        _c("div", { staticClass: "info-order__group" }, [
          _c("label", [
            _vm._v(_vm._s(_vm.$t("objects.orders.text.clinic_name")))
          ]),
          _vm._v(" "),
          _vm.form_data.data_order.clinic.formal_name
            ? _c("div", { staticClass: "info-order__patient three-dot" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.form_data.data_order.clinic.formal_name))
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-order__group" }, [
          _c("label", [
            _vm._v(_vm._s(_vm.$t("objects.orders.text.person_in_charge")))
          ]),
          _vm._v(" "),
          _vm.form_data.staffs_name !== ""
            ? _c("div", { staticClass: "info-order__patient" }, [
                _c("span", [_vm._v(_vm._s(_vm.form_data.staffs_name))])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "info-order__group" },
          [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("objects.orders.text.clean_line")))
            ]),
            _vm._v(" "),
            _c("common-select", {
              class: [
                "info-order__select",
                {
                  "info-order__select--red":
                    _vm.form_data.data_order.order_visit_number === ""
                }
              ],
              attrs: {
                "default-value": _vm.form_data.data_order.order_visit_number,
                options: _vm.order_visit_number_option,
                placeholder: _vm.$t("objects.orders.placeholder.visit_number"),
                "key-value": "value",
                "key-display": "text"
              },
              on: {
                change: function(value) {
                  return _vm.updateOrder(value)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "info-order__note" }, [
          _c("div", { staticClass: "note-item" }, [
            _c("span", { staticClass: "note-item__content" }, [
              _vm._v(
                _vm._s(_vm.$t("objects.orders.text.order_visit_number_note"))
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.order_history > 0 &&
      _vm.sf_pre_contract_simulation >
        _vm.sf_pre_contract_simulation_constant.NOT_IMPLEMENTED
        ? _c(
            "div",
            { staticClass: "info-order__row info-order__row--full-width" },
            [
              _c(
                "div",
                {
                  staticClass: "info-order__group info-order__group--simulation"
                },
                [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("objects.orders.text.pre_contract_simulation")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "info-order__simulation three-dot" },
                    [_c("span", [_vm._v(_vm._s(_vm.reContractSimulation))])]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_search_name
        ? _c("modal-search-name-patient", {
            attrs: {
              keyword: _vm.form_data.keyword_search_name,
              "is-required-sf-user-id": true
            },
            on: {
              select: _vm.setPatient,
              close: function($event) {
                _vm.is_show_modal_search_name = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_search_id
        ? _c("modal-search-id-patient", {
            attrs: {
              keyword: _vm.form_data.keyword_search_id,
              "is-required-sf-user-id": true
            },
            on: {
              select: _vm.setPatient,
              close: function($event) {
                _vm.is_show_modal_search_id = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_search_responsive
        ? _c("search-patient", {
            attrs: {
              "keyword-id": _vm.form_data.keyword_search_id,
              "keyword-name": _vm.form_data.keyword_search_name,
              "search-by-id": _vm.search_by_id,
              "is-required-sf-user-id": true
            },
            on: {
              select: _vm.setPatient,
              close: function($event) {
                _vm.is_show_modal_search_responsive = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }