<template>
  <div>
    <modal-action
      :class="['modal-v2--reservation-print-instruction',
               { 'modal-v2--reservation-print-instruction__disabled-confirm': reservation_ids_selected.length === 0 }]"
      :title="$t('objects.reservation.text.reservation_print_instruction_title')"
      :is-show-cancel-button="true"
      @close="closeModal"
      @confirm="downLoadMultipleExamination"
    >
      <template #body>
        <div class="reservation-print-instruction-modal">
          <div class="reservation-print-instruction-modal__header">
            <div class="reservation-print-instruction-modal__total">
              <div class="reservation-print-instruction-modal__total__text">
                {{ $t('objects.reservation.text.schedule_visit') }}
              </div>
              <div>
                <span>{{ number_reservation }}</span>{{ $t('new_text.first_name2') }}
              </div>
            </div>
            <div class="reservation-print-instruction-modal__checkbox">
              <checkbox-common
                v-model="is_show_re_examination"
                name="is_show_re_examination"
                :is-checked="is_show_re_examination === 1"
                :label="$t('objects.reservation.text.return_patient')"
              />
            </div>
          </div>
          <table class="reservation-print-instruction-modal__table">
            <thead>
              <tr>
                <th class="table__reservation-select-wrapper">
                  <div class="table__reservation-select">
                    <input
                      v-model="selectAll"
                      type="checkbox"
                      class="table__reservation-checkbox"
                    >
                    <span class="table__reservation-checkbox table__reservation-checkbox--background" />
                  </div>
                </th>
                <th>{{ $t('new_text.id') }}</th>
                <th>{{ $t('new_text.name') }}</th>
                <th>{{ $t('new_text.age_title') }}</th>
                <th>{{ $t('objects.reservation.text.reservation_type') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(reservation, index) in getList"
                :key="index"
                @click="gotoReservationCalendar(reservation.started_to, reservation.id, $event)"
              >
                <td class="table__reservation-select-wrapper">
                  <div class="table__reservation-select">
                    <input
                      :id="reservation.id"
                      v-model="reservation_ids_selected"
                      :value="reservation.id"
                      type="checkbox"
                      class="table__reservation-checkbox"
                    >
                    <span class="table__reservation-checkbox table__reservation-checkbox--background table__reservation-checkbox--one" />
                  </div>
                </td>
                <td>{{ reservation.sf_user_id }}</td>
                <td class="reservation-print-instruction-modal__table__name">
                  {{ `${reservation.name || ''} (${reservation.kana_last_name || ''} ${reservation.kana_first_name || ''})` }}
                </td>
                <td>{{ getAge(reservation.birth_of_date) }}</td>
                <td>{{ getReservationType(reservation.duties) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template #confirm_button_content>
        {{ $t('objects.reservation.text.print_template', {num: reservation_ids_selected.length}) }}
      </template>
    </modal-action>
  </div>
</template>

<script>
  import ModalAction from '../common/ModalAction'
  import helpers from '../../utils/helpers'
  import * as moment from 'moment'
  import CheckboxCommon from '../common/Checkbox'
  import { printExamination } from '../../mixins/print-examination'

  export default {
    components: {
      ModalAction,
      CheckboxCommon
    },
    mixins: [printExamination],
    props: {
      date: {
        type: String,
        default: moment().format('YYYY-MM-DD')
      },
      reservationInstructionPrint: {
        type: Array,
        default: () => []
      }
    },
    emits: ['close', 'reload-reservation-data'],
    data () {
      return {
        reservation_ids_selected: [],
        is_show_re_examination: false,
        is_show_re_examination_old: false,
        number_reservation: 0,
        data_show: []
      }
    },
    computed: {
      selectAll: {
        get: function () {
          return this.reservation_ids_selected.length !== 0
            ? this.reservation_ids_selected.length === this.data_show.length
            : false
        },
        set: function (value) {
          const selected = []

          if (value) {
            this.data_show.forEach(function (reservation) {
              selected.push(reservation.id)
            })
          }

          this.reservation_ids_selected = selected
        }
      },
      getList () {
        this.resetData()

        return this.data_show
      }
    },
    created () {
      this.loadFont()
    },
    methods: {
      closeModal () {
        this.$emit('close')
        this.$emit('reload-reservation-data')
      },
      getAge (birthOfDate) {
        if (birthOfDate) {
          return (moment(birthOfDate).isValid()) ? `${helpers.calculateAge(birthOfDate)}` : ''
        }

        return ''
      },
      getReservationType (duties) {
        const reservationType = []
        for (const key in duties) {
          reservationType.push(duties[key].master.duty_name)
        }

        return reservationType.join('+')
      },
      gotoReservationCalendar (date, reservationId, event) {
        if (event.target.nodeName === 'A' || event.target.nodeName === 'INPUT') {
          return
        }
        this.$router.push({
          name: 'reservation.list',
          query: {
            search_date: moment(date).format('YYYY-MM-DD'),
            reservation_id: reservationId
          }
        })
        location.reload()
      },
      downLoadMultipleExamination () {
        if (this.reservation_ids_selected.length === 0) {
          return
        }

        const submitData = {
          date: moment(this.date, this.$t('datetime.year_month_day')).format(this.$t('datetime.year_month_day', 'en')),
          ids: this.reservation_ids_selected
        }

        this.$store.commit('set_loading', true)
        this.$store.dispatch('getDataPrintMultipleExamination', submitData)
          .then(response => {
            const data = response?.data?.results || {}
            const reservations = data?.reservations || []

            if (!reservations.length) {
              helpers.showErrorModal(this.$t('new_message.something_went_wrong'))
              this.$store.commit('set_loading', false)
            }

            this.printMultipleExamination(data, moment(this.date).format('YYYYMMDD'))
          })
          .catch(e => {
            this.$store.commit('set_loading', false)
            helpers.showErrorModal(this.$t('new_message.something_went_wrong'))
          })
      },
      resetData () {
        this.data_show = []
        if (!this.is_show_re_examination) {
          for (let i = 0; i < this.reservationInstructionPrint.length; i++) {
            if (this.reservationInstructionPrint[i].id === this.reservationInstructionPrint[i].first_reservation_id) {
              this.data_show.push(this.reservationInstructionPrint[i])
            }
          }
        } else {
          this.data_show = Object.assign(this.data_show, this.reservationInstructionPrint)
        }

        this.number_reservation = this.data_show.length

        if (this.is_show_re_examination_old !== this.is_show_re_examination) {
          this.reservation_ids_selected = []
          this.is_show_re_examination_old = !this.is_show_re_examination_old
        }
      }
    }
  }
</script>
