var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { class: ["list-modal", _vm.customClass] }, [
      _c("div", { staticClass: "list-modal__wrapper" }, [
        _c("div", { staticClass: "list-modal__container" }, [
          _c(
            "div",
            { staticClass: "list-modal__header" },
            [
              _vm._t("header"),
              _vm._v(" "),
              _vm.isShowCloseButton
                ? _c(
                    "button",
                    {
                      staticClass: "list-modal__header__close",
                      attrs: {
                        type: "button",
                        "aria-label": "Close this dialog"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._v("\n            ×\n          ")]
                  )
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "list-modal__body" }, [_vm._t("body")], 2),
          _vm._v(" "),
          _c("div", { staticClass: "list-modal__footer" }, [
            _vm.isShowApprovalButton
              ? _c(
                  "button",
                  {
                    staticClass: "list-modal__footer__button",
                    on: {
                      click: function($event) {
                        return _vm.$emit("confirm-button-click")
                      }
                    }
                  },
                  [_vm._t("confirm_button_content")],
                  2
                )
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }