var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-notification" }, [
    _c(
      "span",
      {
        staticClass: "header-notification__close",
        on: {
          click: function($event) {
            return _vm.$emit("close")
          }
        }
      },
      [_c("img", { attrs: { src: "/images/icon-close-dask-gray.svg" } })]
    ),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "dompurify-html",
          rawName: "v-dompurify-html",
          value: _vm.get(_vm.notification_info, "content", ""),
          expression: "get(notification_info, 'content', '')"
        }
      ],
      staticClass: "header-notification__content"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }