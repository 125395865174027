var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.className] },
    [
      _c("sub-question", {
        ref: "sub_question",
        attrs: {
          question: _vm.question,
          options: _vm.option_yes_no,
          checked: _vm.select_option
        },
        on: {
          "handle-answer": function($event) {
            return _vm.handleAnswer($event)
          }
        }
      }),
      _vm._v(" "),
      _c("div", { class: _vm.handleChildAnswerClass }, [
        _c(
          "div",
          { staticClass: "form-group display-grid" },
          [
            _c("checkbox-common", {
              attrs: { label: "傷が化膿しやすい", val: "傷が化膿しやすい" },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c("checkbox-common", {
              attrs: { label: "下痢しやすい", val: "下痢しやすい" },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c("checkbox-common", {
              attrs: { label: "血が止まりにくい", val: "血が止まりにくい" },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c("checkbox-common", {
              attrs: { label: "喘息がある", val: "喘息がある" },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c("checkbox-common", {
              attrs: { label: "蕁麻疹が出やすい", val: "蕁麻疹が出やすい" },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c("checkbox-common", {
              attrs: { label: "風邪をひきやすい", val: "風邪をひきやすい" },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c("checkbox-common", {
              attrs: { label: "湿疹が出やすい", val: "湿疹が出やすい" },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c("checkbox-common", {
              attrs: {
                label: "薬を飲むと胃が悪くなる",
                val: "薬を飲むと胃が悪くなる"
              },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c("checkbox-common", {
              attrs: { label: "口内炎が出やすい", val: "口内炎が出やすい" },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c("checkbox-common", {
              attrs: { label: "無カタラーゼ症", val: "無カタラーゼ症" },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c("checkbox-common", {
              attrs: { label: "歯が染みやすい", val: "歯が染みやすい" },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            }),
            _vm._v(" "),
            _c("checkbox-common", {
              attrs: { label: "光線過敏症", val: "光線過敏症" },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }