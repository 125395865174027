<template>
  <modal
    name="treatment-pop"
    class="modal-person-select"
    :width="850"
    :height="'auto'"
    :click-to-close="false"
  >
    <div class="swal2-center swal2-fade swal2-shown">
      <div
        aria-labelledby="swal2-title"
        aria-describedby="swal2-content"
        class="swal2-modal person-select"
        tabindex="-1"
        role="dialog"
        aria-live="assertive"
        aria-modal="true"
      >
        <div class="swal2-header">
          <h2
            class="swal2-title"
            style="display: flex;"
          >
            処置内容
          </h2>
          <button
            type="button"
            class="swal2-close"
            aria-label="Close this dialog"
            style="display: flex;"
            @click="hide"
          >
            ×
          </button>
        </div>
        <div class="position-content">
          <div class="treatment-menu">
            <ul>
              <li
                v-for="(obj, id) in treatmentData"
                :key="id"
                class="item popper-item"
                :class="{ 'active': active_el ? active_el === obj.id : obj.id === activeFirst }"
                @click="selectTreatment(obj.id)"
                v-if="obj.treatments_count"
              >
                <span class="pos-name">
                  {{ obj.name }}
                </span>
                <span class="caret" />
              </li>
              <li
                class="item custom"
                :class="{ 'active': active_el === TREATMENT_TYPE.CUSTOM }"
                :data-count="0"
                @click="createTreatmentCustom"
              >
                <div class="name">
                  {{ $t('medical_page.other_item') }}
                </div>
              </li>
              <li
                class="item memo"
                :class="{ 'active': active_el === TREATMENT_TYPE.MEMO }"
                @click="createTreatmentMemo"
              >
                <div class="name">
                  <img src="/images/note-book.svg">
                  {{ $t('medical_page.memo') }}
                </div>
              </li>
            </ul>
          </div>
          <div class="treatment-filter">
            <div
              v-for="item in treatments"
              :key="item.id"
              class="item-treatment"
              @click="updateTreatmentData(item)"
            >
              <div class="name">
                {{ item.name }}
              </div>
              <div class="price">
                {{ formatNumber(item.price) }} {{ $t('medical_page.yen') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
  import helpers from '../utils/helpers.js'
  import { TREATMENT_TYPE, CATEGORY_TREATMENT_ID_CLINIC } from '../utils/constants'
  import { flatten } from 'lodash'

  export default {
    name: 'modal-select-treatment',
    props: {
      treatmentData: {
        type: Object,
        default: () => {}
      }
    },
    emits: [
      'treatmentData',
      'setTreatmentData'
    ],
    data () {
      return {
        active_el: '',
        treatments: {},
        treatment_category_name: '',
        TREATMENT_TYPE: TREATMENT_TYPE
      }
    },
    computed: {
      activeFirst: function () {
        const key = (Object.keys(this.treatmentData)[0])
        this.treatments = this.formatTreatment(helpers.safeGet(this.treatmentData, `${key}.treatments`))
        this.treatment_category_name = helpers.safeGet(this.treatmentData, `${key}.name`)

        return helpers.safeGet(this.treatmentData, `${key}.id`)
      }
    },
    methods: {
      show: function () {
        this.$modal.show('treatment-pop')
        document.documentElement.style.overflow = 'hidden'
      },
      hide: function () {
        this.$modal.hide('treatment-pop')
        document.documentElement.style.overflow = 'auto'
      },
      selectTreatment: function (id) {
        this.active_el = id

        if (id === CATEGORY_TREATMENT_ID_CLINIC) {
          id = 0
        }

        const treatments = this.formatTreatment(helpers.safeGet(this.treatmentData, `${id}.treatments`))
        this.treatment_category_name = helpers.safeGet(this.treatmentData, `${id}.name`)
        this.treatments = treatments
      },
      formatNumber: function (val) {
        return new Intl.NumberFormat('ja-JP').format(val)
      },
      formatTreatment: function (treatmentData) {
        const treatments = []
        for (const key in treatmentData) {
          if (treatmentData[key].length) {
            treatments.push(treatmentData[key])
          }
        }
        return flatten(treatments)
      },
      updateTreatmentData: function (item) {
        item.treatment_type = item.clinic_id ? TREATMENT_TYPE.CLINIC : TREATMENT_TYPE.TREATMENT
        item.treatment_category_name = this.treatment_category_name
        item.content = ''
        item.count = 1
        this.$emit('treatmentData', item)
        this.hide()
      },
      createTreatmentMemo: function () {
        const item = {
          id: 0,
          treatment_category_id: 0,
          treatment_category_name: this.$t('medical_page.memo'),
          price: 0,
          name: this.$t('medical_page.memo'),
          treatment_type: TREATMENT_TYPE.MEMO,
          count: 1,
          content: '',
          service_add: 0,
          service_offer: 0
        }
        this.active_el = 'memo'
        this.$emit('setTreatmentData', item)
        this.hide()
      },
      createTreatmentCustom: function () {
        const item = {
          id: 0,
          treatment_category_id: 0,
          treatment_category_name: this.$t('medical_page.other_item'),
          price: 0,
          name: '',
          treatment_type: TREATMENT_TYPE.CUSTOM,
          count: 1,
          content: '',
          service_add: 0,
          service_offer: 0
        }
        this.active_el = 'custom'
        this.$emit('setTreatmentData', item)
        this.hide()
      }
    }
  }
</script>
