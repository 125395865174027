<template>
  <div class="order">
    <SidebarOder />
    <router-view />
  </div>
</template>

<script>
  import SidebarOder from '../components/order/SidebarOder'
  import { CLINIC_RECORD_TYPE } from '../utils/constants'
  import { get } from 'lodash'

  export default {
    name: 'OrderLayout',
    components: { SidebarOder },
    computed: {
      isLabDesigner () {
        return this.$store.getters.isLabDesigner
      },
      isLabClinic () {
        return this.$store.getters.isLabClinic
      }
    },
    created () {
      this.checkIsLab()
    },
    methods: {
      checkIsLab () {
        if (!this.$store.getters.isInstructionAdmin) {
          this.$store.dispatch('getInfoClinic').then((result) => {
            const clinic = get(result, 'data.results') || {}
            const isLabDesigner = typeof clinic.record_type_id !== 'undefined' &&
              clinic.record_type_id === CLINIC_RECORD_TYPE.LAB_DESIGNER

            const isLabClinic = typeof clinic.record_type_id !== 'undefined' &&
              clinic.record_type_id === CLINIC_RECORD_TYPE.CLINIC &&
              clinic.is_lab === 1

            if (!isLabDesigner && !isLabClinic) {
              return this.$router.push({ name: 'not.found' })
            }
          })
        }
      }
    }
  }
</script>
