var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.className] },
    [
      _c("sub-question", {
        ref: "sub_question",
        attrs: {
          question: _vm.question,
          options: _vm.option_yes_no,
          checked: _vm.select_option
        },
        on: {
          "handle-answer": function($event) {
            return _vm.handleAnswer($event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.handleChildAnswerClass },
        _vm._l(_vm.details, function(detail) {
          return _c(
            "div",
            { key: detail.detail_index, staticClass: "form-group block-group" },
            [
              _c(
                "label",
                {
                  class: ["block-group__first", "number-" + detail.detail_index]
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.getNumber(detail.detail_index)) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "block-group__inline" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "color-bold" }, [
                    _vm._v("診断名：")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: detail.name,
                        expression: "detail.name"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "" },
                    domProps: { value: detail.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(detail, "name", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "color-bold" }, [
                    _vm._v("部位：")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: detail.part,
                        expression: "detail.part"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "" },
                    domProps: { value: detail.part },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(detail, "part", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "color-bold" }, [
                    _vm._v("状態：")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: detail.state,
                        expression: "detail.state"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "" },
                    domProps: { value: detail.state },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(detail, "state", $event.target.value)
                      }
                    }
                  })
                ])
              ])
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }