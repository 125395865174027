<template>
  <div
    class="handmade-select"
    :tabindex="0"
    @blur="open = false"
  >
    <div
      :class="[
        'handmade-select__selected',
        { 'handmade-select__selected--open': open },
        { 'handmade-select__selected--disable': disable }
      ]"
      @click="handleOpen"
    >
      {{ renderSelected(value) || placeholder }}
    </div>
    <div :class="['handmade-select__options', { 'handmade-select__options--hide': !open }]">
      <div
        v-if="emptyValue !== '' && value !== ''"
        class="handmade-select__options__item"
        @click="
          open = false;
          $emit('input', '');
        "
      >
        {{ emptyValue }}
      </div>
      <div
        v-for="(option, i) in options"
        :key="i"
        :class="[
          'handmade-select__options__item',
          { 'handmade-select__options__item--selected': option[keyValue] === value }
        ]"
        @click="
          open = false;
          $emit('input', option[keyValue]);
        "
      >
        {{ option[keyDisplay] }}
      </div>
    </div>
  </div>
</template>

<script>
  import { isEmpty } from 'lodash'

  export default {
    props: {
      options: {
        type: Array,
        required: true
      },
      keyDisplay: {
        type: String,
        default: 'name'
      },
      keyValue: {
        type: String,
        default: 'id'
      },
      value: {
        type: [String, Number],
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      disable: {
        type: Boolean,
        default: false
      },
      parentClassScroll: {
        type: String,
        default: ''
      },
      emptyValue: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        open: false
      }
    },
    mounted () {
      if (!this.parentClassScroll) {
        document.addEventListener('scroll', this.handleClose)

        return
      }

      const elements = document.getElementsByClassName(this.parentClassScroll)
      if (!elements) {
        return
      }

      elements[0].addEventListener('scroll', this.handleClose)
    },
    destroyed () {
      if (!this.parentClassScroll) {
        document.removeEventListener('scroll', this.handleClose)
      }
    },
    methods: {
      handleOpen () {
        if (this.disable) {
          return
        }

        this.open = !this.open
      },
      handleClose () {
        this.open = false
      },
      renderSelected (value) {
        if (value !== '' && !isEmpty(this.options)) {
          return this.options.find(option => option[this.keyValue] === value)?.[this.keyDisplay] || ''
        }

        return ''
      }
    }
  }
</script>
