<template>
  <div class="modal-operation-reason">
    <modal-action
      :title="$t('objects.operation_reason.send_the_reason_to_the_supervisor')"
      @close="$emit('back')"
    >
      <template #body>
        <div class="modal-operation-reason__body">
          <div class="modal-operation-reason__info">
            <div class="modal-operation-reason__row modal-operation-reason__row--half">
              <label class="modal-operation-reason__label">{{ $t('common.date_time') }}</label>
              <span class="modal-operation-reason__data number-normal">{{ reservationTime }}</span>
            </div>
            <div class="modal-operation-reason__row modal-operation-reason__row--half">
              <label class="modal-operation-reason__label">{{ $t('new_text.reservations_frame') }}</label>
              <span class="modal-operation-reason__data modal-operation-reason__data--ellipsis">{{ allocationName }}</span>
            </div>
            <div class="modal-operation-reason__row modal-operation-reason__row--half">
              <label class="modal-operation-reason__label">{{ $t('new_text.patient') }}</label>
              <div :class="['modal-operation-reason__data', { 'modal-operation-reason__data--ellipsis': !isBlock }]">
                <span
                  v-if="!isBlock"
                  class="modal-operation-reason__patient-sf-user-id number-normal"
                >{{ patientSfUserId }}</span>
                <span>{{ nameSpecified }}</span>
              </div>
            </div>
            <div class="modal-operation-reason__row modal-operation-reason__row--half">
              <label class="modal-operation-reason__label">{{ $t('new_text.brand_type') }}</label>
              <span class="modal-operation-reason__data">{{ brandName }}</span>
            </div>
            <div class="modal-operation-reason__row">
              <label class="modal-operation-reason__label">{{ $t('objects.reservation.text.memo') }}</label>
              <span class="modal-operation-reason__data modal-operation-reason__data--memo">{{ memo }}</span>
            </div>
          </div>
          <div class="modal-operation-reason__content">
            <div class="modal-operation-reason__row">
              <label class="modal-operation-reason__label modal-operation-reason__label--required">
                {{ $t('new_text.name') }}
                <span class="modal-operation-reason__required">{{ $t('new_text.required') }}</span>
              </label>
              <div class="modal-operation-reason__input">
                <input
                  ref="operator_name"
                  v-model="operator_name"
                  :placeholder="$t('validation.required', { attribute: $t('new_text.name') })"
                  :class="['input-default', { 'common-input-error': errors.operator_name }]"
                  @input="validation('operator_name')"
                >
                <span
                  v-if="errors.operator_name"
                  class="common-msg-error error-message"
                >{{ errors.operator_name }}</span>
              </div>
            </div>
            <div class="modal-operation-reason__row modal-operation-reason__row--operator-reason">
              <label class="modal-operation-reason__label modal-operation-reason__label--operator-reason modal-operation-reason__label--required">
                {{ $t('new_text.reason') }}
                <span class="modal-operation-reason__required">{{ $t('new_text.required') }}</span>
              </label>
              <div class="modal-operation-reason__operator-reason">
                <textarea
                  v-model="operation_reason"
                  class="textarea-default textarea-default--resize-none textarea-default--h-70"
                  :placeholder="$t('new_text.placeholder.please_enter')"
                  :class="['modal-operation-reason__textarea', { 'common-input-error': errors.operation_reason }]"
                  @input="validation('operation_reason')"
                />
                <span
                  v-if="errors.operation_reason"
                  class="common-msg-error error-message"
                >{{ errors.operation_reason }}</span>
                <p class="modal-operation-reason__note">{{ note }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="modal-operation-reason__footer-default">
          <div class="modal-operation-reason__footer-group">
            <button
              class="button-v2 button-v2--secondary button-v2--120"
              @click="$emit('back')"
            >
              {{ $t('new_text.button.back') }}
            </button>
            <button
              class="button-v2 button-v2--primary button-v2--120"
              :disabled="!isNotError"
              @click="handleSubmit"
            >
              {{ $t('new_text.button.send') }}
            </button>
          </div>
        </div>
      </template>
    </modal-action>
  </div>
</template>

<script>
  import { maxLength, required } from 'vuelidate/lib/validators'
  import ModalAction from '../common/ModalAction'
  import { get, isEmpty, every, has } from 'lodash'

  export default {
    components: {
      ModalAction
    },
    props: {
      isUpdate: { type: Boolean, default: false },
      isBlock: { type: Boolean, default: false },
      resources: { type: Object, default: () => {} }
    },
    emits: ['back', 'confirm'],
    data () {
      return {
        errors: {
          operator_name: '',
          operation_reason: ''
        },
        operator_name: '',
        operation_reason: ''
      }
    },
    computed: {
      patientSfUserId () {
        return get(this.resources, 'sf_user_id', '')
      },
      nameSpecified () {
        return this.isBlock ? this.$t('objects.operation_reason.note_specified_date_and_time') : get(this.resources, 'name', '')
      },
      allocationName () {
        return get(this.resources, 'allocation_name', '')
      },
      brandName () {
        return this.isBlock ? '-' : get(this.resources, 'brand_type_name', '')
      },
      memo () {
        return get(this.resources, 'memo', '')
      },
      reservationTime () {
        return get(this.resources, 'reservation_time', '')
      },
      isNotError () {
        return Boolean(every(this.errors, isEmpty) && this.operator_name && this.operation_reason)
      },
      note () {
        const type = this.isUpdate ? 'update' : 'create'
        if (!this.isBlock) {
          return this.$t(`objects.operation_reason.note_send_reason_when_${type}_reservation`)
        }

        return this.$t(`objects.operation_reason.note_send_reason_when_${type}_block`)
      }
    },
    mounted () {
      this.$nextTick(() => this.$refs.operator_name.focus())
    },
    validations () {
      return {
        operator_name: { required, maxLength: maxLength(191) },
        operation_reason: { required }
      }
    },
    methods: {
      handleSubmit () {
        if (!this.isNotError) {
          return
        }

        this.$emit('confirm', { operator_name: this.operator_name, operation_reason: this.operation_reason })
      },
      getErrorMessages () {
        // Define the error rules and error messages
        return [
          {
            field: 'operator_name',
            type: 'required',
            msg: this.$t('new_validation.common.required', { attribute: this.$t('new_text.name') }),
            err_field: 'operator_name'
          },
          {
            field: 'operator_name',
            type: 'maxLength',
            msg: this.$t('new_validation.common.maximum', { max: 191, attribute: this.$t('new_text.name') }),
            err_field: 'operator_name'
          },
          {
            field: 'operation_reason',
            type: 'required',
            msg: this.$t('new_validation.common.required', { attribute: this.$t('new_text.reason') }),
            err_field: 'operation_reason'
          }
        ]
      },
      validation (errorField) {
        // Clear all errors if no specific error field is provided
        if (!errorField) {
          Object.keys(this.errors).forEach(key => {
            this.errors[key] = ''
          })
        }

        // Trigger validation touch
        this.$v.$touch()

        // Get error messages, filter if a specific field is provided
        const errorMessages = errorField
          ? this.getErrorMessages().filter(error => error.field === errorField)
          : this.getErrorMessages()

        // Clear this.errors[errorField] before validate
        if (has(this.errors, errorField)) {
          this.errors[errorField] = ''
        }

        // Process each error message
        errorMessages.forEach(error => {
          this.processErrorMessage(error)
        })
      },
      processErrorMessage (error) {
        const { field, type, err_field, msg } = error
        if (this.$v[field] && this.$v[field][type] === false) {
          if (!this.errors[err_field]) {
            this.errors[err_field] = msg
          }
        }
      }
    }
  }
</script>
