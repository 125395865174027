const messages = {
  account_is_deactivated: 'Account has been deactivated',
  account_is_deleted: 'Account is suspended',
  available_date_warning: 'Available dates are one year',
  before_release_date_warning: 'Date before service provided cannot be selected',
  confirmation_delete: 'Are you sure you want to delete?',
  do_you_want_delete_memo: 'Do you want to delete the memo?',
  forbidden: 'You cannot access it with this account.',
  forgotten_staff_id_or_email: 'If you do not know your staff ID or email address,</br>please contact the clinic administrator',
  has_been_deleted: 'Has been deleted',
  has_been_updated: 'Has been updated',
  has_registered: 'Has registered',
  invalid_staff_input: 'No staff information exists that matches the information entered',
  login_failed: 'There is an error in the login information',
  login_forbidden: 'You cannot log in with this account',
  no_result_search: 'There is no data that matches the conditions',
  not_found: 'Not found',
  password_empty: 'Please enter your password',
  password_is_incorrect: 'Your password is incorrect',
  preset_items_cannot_be_changed: 'Preset items cannot be changed',
  saved: 'Saved',
  something_went_wrong: 'Something went wrong',
  unauthorized: 'Unauthorized',
  unprocessable_entity: 'Unprocessable entity'
}

export default messages
