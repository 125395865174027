import { formatDateTime, getExtension, safeGet } from '../utils/helpers'
import { FILE_EXTENSION, ORDER_FILE_THUMB_STATUS, ORDER_FILE_TYPE } from '../utils/constants'

export default {
  data () {
    return {
      order_file_type: ORDER_FILE_TYPE,
      order_file_thumb_status: ORDER_FILE_THUMB_STATUS
    }
  },
  computed: {
    isLab () {
      const clinic = this.$store.getters.currentUser.clinic || {}
      return clinic.is_lab || false
    }
  },
  methods: {
    getOrderFileThumbnail (type, orderId, orderFileId = 0, isOrderList = false, fileName = '') {
      // Cause the STL files have no thumbnail
      if (type === ORDER_FILE_TYPE.ORDER_FILE && fileName !== '' && getExtension(fileName) === FILE_EXTENSION.STL) {
        if (isOrderList) {
          this.$store.commit('setOrderFileThumbUrlOrderList', { orderId, orderFileId })
        } else {
          this.$store.commit('setOrderFileThumbUrl', { orderFileId })
        }

        return
      }
      this.$store.dispatch('getOrderFileThumbnail', { type, orderId, orderFileId, isOrderList })
    },
    downloadOrderFile (url) {
      if (this.isLoadFileFail) {
        return false
      }

      window.open(url, '_blank')
    },
    formatDateTime (dateTime, format) {
      return formatDateTime(dateTime, format)
    },
    safeGet (object, key) {
      return safeGet(object, key)
    }
  }
}
