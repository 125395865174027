<template>
  <modal-action
    :title="title"
    :is-show-close-button="isShowCloseButton"
    class="modal-confirm"
    @close="$emit('no')"
    @confirm="$emit('yes')"
  >
    <template
      v-if="text"
      #body
    >
      <slot name="body">
        <span>{{ text }}</span>
      </slot>
    </template>
    <template #cancel_button_content>
      {{ textNo || $t('common.no') }}
    </template>
    <template #confirm_button_content>
      {{ textOk || $t('common.yes') }}
    </template>
  </modal-action>
</template>

<script>
  import ModalAction from './ModalAction'
  export default {
    components: {
      ModalAction
    },
    props: {
      isShowCloseButton: {
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''
      },
      textNo: {
        type: String,
        default: ''
      },
      textOk: {
        type: String,
        default: ''
      }
    },
    emits: ['no', 'yes']
  }
</script>
