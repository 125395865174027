<template>
  <div class="setting-v2 setting-v2--background">
    <div class="setting-v2__content">
      <div class="setting-v2__header">
        <div class="setting-v2__header__box">
          <div class="setting-v2__header__box__text">
            {{ $t('new_text.basic_treatment_policy') }}
          </div>
          <div class="setting-v2__header__box__button">
            <button @click="actionUpdate">
              {{ $t('new_text.button.save') }}
            </button>
          </div>
        </div>
      </div>
      <div class="setting-v2__main">
        <div class="setting-basic-treatment-policy">
          <div class="setting-basic-treatment-policy__note">{{ $t('new_text.basic_treatment_policy_description') }}</div>
          <textarea
            v-model="basic_treatment_policy"
            class="textarea-default setting-basic-treatment-policy__input"
            :placeholder="$t('new_text.placeholder.please_enter')"
            maxlength="255"
          />
          <div>{{ $t('new_text.basic_treatment_policy_note') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { get } from 'lodash'
  import { showErrorModal, showMessageError, showSuccessModal } from '../../utils/helpers'

  export default {
    data () {
      return {
        basic_treatment_policy: ''
      }
    },
    created () {
      document.title = this.$t('objects.page_title.setting').toString()
      this.getClinicInfo()
    },
    methods: {
      getClinicInfo: function () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('getSettingClinicInfo').then((result) => {
          this.$store.commit('set_loading', false)
          const clinic = get(result, 'data.results', {}) || {}
          this.basic_treatment_policy = clinic.sf_basic_treatment_policy__c
        }).catch((e) => {
          this.$store.commit('set_loading', false)
          showMessageError(e, this)
        })
      },
      actionUpdate () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch(
          'updateBasicTreatmentPolicy', { sf_basic_treatment_policy__c: this.basic_treatment_policy }
        ).then(() => {
          showSuccessModal(this.$t('new_message.saved'))
          this.$store.commit('set_loading', false)
        }).catch(() => {
          showErrorModal(this.$t('new_message.something_went_wrong'))
          this.$store.commit('set_loading', false)
        })
      }
    }
  }
</script>
