<template>
    <div :class="[className]">
        <sub-question
            ref="sub_question"
            :question="question"
            :options="option_yes_no"
            :checked="select_option"
            @handleAnswer="handleAnswer($event)"
        ></sub-question>
        <div v-bind:class="handleChildAnswerClass">
            <div class="form-group">
                <checkbox-common
                    v-model="selections"
                    label="鎮痛剤（痛み止め）"
                    val="鎮痛剤（痛み止め）">
                </checkbox-common>
                <checkbox-common
                    v-model="selections"
                    label="ビタミン剤"
                    val="ビタミン剤">
                </checkbox-common>
                <checkbox-common
                    v-model="selections"
                    label="ホルモン剤"
                    val="ホルモン剤">
                </checkbox-common>
                <checkbox-common
                    v-model="selections"
                    label="血圧の薬"
                    val="血圧の薬">
                </checkbox-common>
                <checkbox-common
                    v-model="selections"
                    label="糖尿病の薬"
                    val="糖尿病の薬">
                </checkbox-common>
                <div class="form-inline selection-other-option pt-3">
                    <checkbox-common
                        v-model="selections"
                        label="その他"
                        val="その他">
                    </checkbox-common>
                    <div :class="['other-input', diableOtherInput]">
                        <input v-model="other" class="form-control w-100" type="text">
                    </div>
                </div>
            </div>
            <div v-for="(detail, index) in details" :key="detail.detail_index" class="form-group block-group">
                <label class="block-group__first">{{ detail.detail_index }}</label>
                <div class="block-group__inline">
                    <div class="form-group">
                        <label class="color-blue">薬品名：</label>
                        <input v-model="detail.diagnostis" class="form-control" type="text" name="">
                    </div>
                    <div class="form-group">
                        <label class="color-blue">服薬量：</label>
                        <div class="form-inline justify-content-between">
                            <span>１日</span>
                            <input v-model="detail.name1" class="form-control col-md-3" type="text">
                            <span>回</span>
                            <input v-model="detail.name2" class="form-control col-md-3" type="text">
                            <span>錠/包/ml</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SubQuestion from '../template/SubQuestion'
    import CheckboxCommon from '../template/CheckboxCommon'
    import { INTERVIEW_COMMON_OPTIONS } from '../../../../utils/constants'

    export default {
        components: {
            SubQuestion,
            CheckboxCommon
        },
        props: ['className'],
        data() {
            return {
                number: 16,
                select_option: INTERVIEW_COMMON_OPTIONS.NO,
                question: '現在飲んでいる薬はありますか？',
                option_yes_no: [
                    INTERVIEW_COMMON_OPTIONS.YES,
                    INTERVIEW_COMMON_OPTIONS.NO,
                ],
                selections: [],
                other: '',
                translate: {
                    選択肢: 'selections',
                    その他: 'other'
                },
                translate_details: {
                    診断名: 'diagnostis',
                    服薬量1: 'name1',
                    服薬量2: 'name2',
                },
                details: [
                    {
                        detail_index: 1,
                        diagnostis: '',
                        name1: '',
                        name2: '',
                    },
                    {
                        detail_index: 2,
                        diagnostis: '',
                        name1: '',
                        name2: '',
                    },
                    {
                        detail_index: 3,
                        diagnostis: '',
                        name1: '',
                        name2: '',
                    },
                    {
                        detail_index: 4,
                        diagnostis: '',
                        name1: '',
                        name2: '',
                    },
                    {
                        detail_index: 5,
                        diagnostis: '',
                        name1: '',
                        name2: '',
                    }
                ],
            }
        },
        created: function () {
            this.loadAnswerData();
        },
        methods: {
            handleAnswer: function(value) {
                this.select_option = value;
            },
            loadAnswerData: async function () {
                const question_data = this.$store.getters.get_question(this.number)
                this.select_option = question_data.answer

                question_data.item_common.details.forEach(objDetail => {
                    for (let jaName in this.translate) {
                        if (objDetail.title === jaName) {
                            let enName = this['translate'][jaName]
                            this[enName] = objDetail.content || ''
                        }
                    }
                })
                question_data.items.forEach((objMain, index) => {
                    objMain.details.forEach(objDetail => {
                        for (let jaName in this.translate_details) {
                            if (objDetail.title === jaName) {
                                let enName = this['translate_details'][jaName]
                                this.details[index][enName] = objDetail.content || ''
                            }
                        }
                    })
                })
            },
            formatResult: function () {
                let data = this.$store.getters.get_default_answer_items(this.number)
                data.answer = this.select_option

                if (data.answer === INTERVIEW_COMMON_OPTIONS.YES) {
                    data.item_common.details.forEach(objDetail => {
                        for (let jaName in this.translate) {
                            if (objDetail.title === jaName) {
                                let enName = this['translate'][jaName]
                                objDetail.content = this[enName]
                            }
                        }
                    })
                    data.items.forEach((objMain, index) => {
                        objMain.details.forEach(objDetail => {
                            for (let jaName in this.translate_details) {
                                if (objDetail.title === jaName) {
                                    let enName = this['translate_details'][jaName]
                                    objDetail.content = this.details[index][enName]
                                }
                            }
                        })
                    })
                }
                return data
            }
        },
        computed: {
            handleChildAnswerClass: function () {
                return {
                    'disable-child-answer': this.select_option ===INTERVIEW_COMMON_OPTIONS.NO
                }
            },
            diableOtherInput: function () {
                return {
                    'disable-other-answer': !this.selections.includes('その他')
                }
            },
        }
    }
</script>
