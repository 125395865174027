import DashboardLayout from './layouts/DashboardLayout'
import AuthLayout from './layouts/AuthLayout'

import Login from './pages/Login'
import LoginForSmallScreen from './pages/LoginForSmallScreen'
import ForgotPassword from './pages/ForgotPassword.vue'
import EmailForgetPassword from './pages/EmailForgetPassword'
import ResetPassword from './pages/ResetPasword'
import ResetPasswordComplete from './pages/ResetPasswordComplete'
import PatientDetail from './pages/patients/v2/Detail'
import PatientHistory from './pages/patients/v2/PatientHistory'
import PatientEdit from './pages/patients/v2/Edit'
import PatientList from './pages/patients/v2/PatientList'
import PatientActivityLog from './pages/patients/v2/ActivityLog'
import PatientInterview from './pages/patients/v2/Interview'
import PatientInterviewPrint from './pages/patients/InterviewPrint'
import InterviewPrintWithoutAnswer from './pages/patients/InterviewPrintWithoutAnswer'
import InterviewPrintAnswer from './pages/patients/InterviewPrintAnswer'
import InterviewPrintMulti from './pages/patients/InterviewPrintMulti'
import PatientQuestionnaires from './pages/patients/v2/QuestionnaireList'

import Questionnaire from './pages/questionnaire/Index'

import SettingAccount from './pages/setting/v2/Account'

import ReservationSetting from './pages/reservation/Setting'
import Secretariat from './pages/reservation/Secretariat'
import NotFound from './pages/404'
import TokenExpired from './pages/TokenExpired'
import InitialPassword from './pages/InitialPassword'
import LoginNG from './pages/whitelist/LoginNG'
import WhiteList from './pages/setting/v2/WhiteList'

// Update UI
import NewLayout from './layouts/NewLayout'
import PatientLayoutV2 from './layouts/v2/PatientLayout'
import SettingLayoutV2 from './layouts/v2/SettingLayout'
import ReservationList from './pages/reservation/ReservationList'
import Order from './pages/order/Order'
import OrderSearch from './pages/order/Search'
import OrderCreate from './pages/order/Create'
import PatientOrderMain from './pages/patients/v2/orders/Main'
import PatientOrderList from './pages/patients/v2/orders/List'
import PatientOrderDetail from './pages/patients/v2/orders/Detail'
import PatientRegistration from './pages/patients/v2/Registration'
import SettingClinic from './pages/setting/v2/Clinic'
import SettingBasicTreatmentPolicy from './pages/setting/BasicTreatmentPolicy'
import ReservationType from './pages/setting/v2/ReservationType'
import ReservationDateTime from './pages/setting/v2/ReservationDateTime'
import DeletedPatientInfo from './pages/setting/v2/DeletedPatient'
import OrderInstructionPrint from './components/order/OrderInstructionPrint'
import PrintInstruction from './components/patient/v2/PrintInstruction'
import LoginTop from './pages/LoginTop'
import VueRouter from 'vue-router'
import store from './store'
import OrderLayout from './layouts/OrderLayout'
import { safeGet } from './utils/helpers'

const routes = [
  {
    path: '/',
    component: NewLayout,
    meta: { requiresAuth: true },
    children: [
      { path: '', redirect: { name: 'dashboard' }, meta: { requiresClinic: true } },
      { path: 'dashboard', name: 'dashboard', component: ReservationList, meta: { requiresClinic: true } },
      {
        path: 'order/',
        component: OrderLayout,
        meta: { requiresIsLabEnable: true },
        children: [
          { path: 'create', name: 'order.create', component: OrderCreate },
          { path: 'search', name: 'order.search', component: OrderSearch },
          { path: ':status', name: 'order', component: Order }
        ]
      },
      { path: 'order/instruction/print', name: 'order.instruction.print', component: OrderInstructionPrint },
      { path: 'patient/registration', name: 'patient.registration', component: PatientRegistration, meta: { requiresClinic: true } },
      { path: 'patient', name: 'patient.list', component: PatientList },
      {
        path: 'patient/:id/',
        component: PatientLayoutV2,
        children: [
          { path: '', name: 'patient.detail', component: PatientDetail, meta: { requiresClinic: true } },
          { path: 'activity/log', name: 'patient.activity.log', component: PatientActivityLog, meta: { requiresClinic: true } },
          {
            path: 'orders',
            component: PatientOrderMain,
            children: [
              { path: '', name: 'patient.orders', component: PatientOrderList, meta: { requiresIsLabEnable: true } },
              {
                path: ':order_id',
                name: 'patient.orders.detail',
                component: PatientOrderDetail,
                meta: { requiresIsLabEnable: true }
              }
            ]
          },
          { path: 'history/:hid', name: 'patient.history', component: PatientHistory, meta: { requiresClinic: true } },
          { path: 'interview', name: 'patient.interview', component: PatientInterview, meta: { requiresClinic: true, requiresIsInterviewSheet: true } },
          { path: 'questionnaires', name: 'patient.questionnaires', component: PatientQuestionnaires, meta: { requiresClinic: true } }
        ]
      },
      { path: 'patient/:id/edit', name: 'patient.edit', component: PatientEdit, meta: { requiresClinic: true } },
      {
        path: 'setting/',
        component: SettingLayoutV2,
        children: [
          { path: 'clinic', name: 'setting.clinic', component: SettingClinic, meta: { requiresClinic: true } },
          { path: 'deleted-patient', name: 'setting.deleted-patient', component: DeletedPatientInfo, meta: { requiresClinic: true } },
          { path: 'reservation-type', name: 'setting.reservation-type', component: ReservationType, meta: { requiresClinic: true } },
          { path: 'reservation-datetime', name: 'setting.reservation-datetime', component: ReservationDateTime, meta: { requiresClinic: true } },
          { path: 'account', name: 'setting.account', component: SettingAccount },
          { path: 'whitelist', name: 'setting.white-list', component: WhiteList },
          {
            path: 'basic-treatment-policy',
            name: 'setting.basic-treatment-policy',
            component: SettingBasicTreatmentPolicy,
            meta: { requiresClinic: true }
          }
        ]
      },
      { path: 'top', name: 'login.top', component: LoginTop },
      { path: 'questionnaires', name: 'questionnaire', component: Questionnaire, meta: { requiresClinic: true } }
    ]
  },
  {
    path: '/patient/:id/print-instruction',
    name: 'patient.print.instruction',
    component: PrintInstruction,
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    component: DashboardLayout,
    meta: {
      requiresClinic: true
    },
    children: [
      { path: 'patient/:id/interview/print', name: 'patient.interview.print', component: PatientInterviewPrint, meta: { requiresAuth: true, requiresIsInterviewSheet: true } },
      { path: 'patient/:id/interview/print-without-answer', name: 'patient.interview.print.without.answer', component: InterviewPrintWithoutAnswer, meta: { requiresAuth: true, requiresIsInterviewSheet: true } },
      { path: 'patient/:id/interview/print-answer', name: 'patient.interview.print.answer', component: InterviewPrintAnswer, meta: { requiresAuth: true, requiresIsInterviewSheet: true } },
      { path: 'patient/interview/print-multi', name: 'patient.interview.print.multi', component: InterviewPrintMulti, meta: { requiresAuth: true, requiresIsInterviewSheet: true } }
    ]
  },
  {
    path: '/',
    component: DashboardLayout,
    children: [
      {
        path: 'whitelist/login-request', name: 'whitelist.login.request', component: LoginNG, meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/',
    component: AuthLayout,
    children: [
      { path: '/token/expired', component: TokenExpired, name: 'token.expired' },
      { path: '/login', name: 'auth.login', component: Login, meta: { requiresAuth: false } },
      { path: '/forgot/password', name: 'password.request', component: ForgotPassword },
      { path: '/email/send', name: 'email.send', component: EmailForgetPassword },
      { path: '/password/reset/:token', name: 'password.reset', component: ResetPassword },
      { path: '/reset/complete', name: 'reset.complete', component: ResetPasswordComplete },
      { path: '/password/initial/:token/?id=:id', name: 'password.initial', component: InitialPassword, meta: { requiresAuth: false } },
      { path: '/small/login', name: 'auth.login.small', component: LoginForSmallScreen, meta: { requiresAuth: false } }
    ]
  },
  { path: '*', component: NotFound, name: 'not.found' }
]

if (process.env.MIX_SHOW_RESERVATION === 'true') {
  routes[0].children.push(
    { path: 'reservation/list', name: 'reservation.list', component: ReservationList, meta: { requiresAuth: true, requiresClinic: true } },
    { path: 'reservation/setting', name: 'reservation.setting', component: ReservationSetting, meta: { requiresAuth: true, requiresClinic: true } },
    { path: 'reservation/secretariat', name: 'reservation.secretariat', component: Secretariat, meta: { requiresAuth: true, requiresClinic: true } }
  )
}

export const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      if (
        store.getters.isLabDesigner &&
        (
          (to.name === 'order' && safeGet(to, 'params.status') !== 'prepare') ||
          to.name === 'order.create' ||
          to.name === 'patient.orders' ||
          to.name === 'order.search'
        )
      ) {
        return next({ name: 'order', params: { status: 'prepare' } })
      }

      if (
        (
          (to.name === 'patient.orders.detail' || to.name === 'patient.orders') &&
          store.getters.isInstructionAdmin
        ) ||
        (to.name === 'patient.orders.detail' && store.getters.isLabDesigner)
      ) {
        return next()
      }

      if (
        to.matched.some(record => record.meta.requiresClinic) &&
        (store.getters.isLabDesigner || store.getters.isInstructionAdmin)
      ) {
        return next({ name: 'order.search' })
      }

      if (to.matched.some(record => record.meta.requiresIsLabEnable) && store.getters.isDisabledLabClinic) {
        return next({ name: 'dashboard', query: { search_date: localStorage.getItem('search_date') } })
      }

      if (to.path === '/reservation/list' || to.path === '/dashboard') {
        if (!to.query.search_date) {
          if (localStorage.getItem('search_date')) {
            return next({ path: to.path, query: { search_date: localStorage.getItem('search_date') } })
          }
        }
      }
      if (to.path === '/whitelist/login-request' || to.path === '/whitelist/login-request/') {
        if (store.getters.isDisallowIp === false) {
          return next({ name: 'dashboard', query: { search_date: localStorage.getItem('search_date') } })
        }
      }
      if (to.matched.some(record => record.meta.requiresIsInterviewSheet) && !store.getters.isInterviewSheet) {
        return next({ name: 'dashboard', query: { search_date: localStorage.getItem('search_date') } })
      }
      return next()
    }
    next({ name: 'auth.login' })
  } else {
    return next()
  }
})

export default router
