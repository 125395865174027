var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["interview-content-consultation-v2__block", _vm.customClass] },
    [
      _c("label", { staticClass: "header-box" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "interview-content-consultation-v2__block-part row" },
        [
          _c(
            "div",
            { staticClass: "col-md-4 left-part-content interview-input" },
            [
              _c("label", { staticClass: "block-header" }, [
                _vm._v(
                  "\n        1. " +
                    _vm._s(_vm.$t("objects.patient.text.part")) +
                    "\n        "
                ),
                _vm.index === 0
                  ? _c("span", { staticClass: "required" }, [
                      _vm._v("\n          *\n        ")
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.errors.location
                ? _c("p", { staticClass: "error-message" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.errors.location) + "\n      "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex" },
                _vm._l(_vm.location, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "d-block checkbox-solid col-4 p-0 m-0 pr-4"
                    },
                    [
                      _c(
                        "div",
                        { class: index < 2 ? "border-right" : "" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "check-container",
                              on: {
                                click: function($event) {
                                  return _vm.toggleItem(item)
                                }
                              }
                            },
                            [
                              _c("span", {
                                class: [
                                  "common-radio-button",
                                  { checked: item.isChecked }
                                ]
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label-text" }, [
                                _vm._v(_vm._s(item.value))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(item.options, function(subItem) {
                            return _c(
                              "div",
                              {
                                key: subItem.value,
                                staticClass: "checkbox-solid col-12 mt-2 pl-2"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    class: [
                                      "check-container",
                                      { disable: !item.isChecked }
                                    ],
                                    on: {
                                      click: function($event) {
                                        return _vm.toggleSubItem(item, subItem)
                                      }
                                    }
                                  },
                                  [
                                    _c("span", {
                                      class: [
                                        "common-radio-button",
                                        {
                                          checked: subItem.isChecked,
                                          "common-radio-button--disable": !item.isChecked
                                        }
                                      ]
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "label-text" }, [
                                      _vm._v(_vm._s(subItem.value))
                                    ])
                                  ]
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-8 right-part-content" }, [
            _c("label", { staticClass: "block-header" }, [
              _vm._v(_vm._s(_vm.$t("objects.patient.text.dentition_number")))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "part-selection" }, [
              _c("div", { staticClass: "row noMargin" }, [
                _c("div", { staticClass: "form-inline part-selection__ul" }, [
                  _c(
                    "p",
                    { staticClass: "part-selection__direction my-auto" },
                    [_vm._v("\n              UR\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    _vm._l(_vm.dentition.UR, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.value,
                          staticClass: "checkbox-solid checkbox-solid-group"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "check-container sm-checkbox",
                              on: {
                                click: function($event) {
                                  return _vm.toggleCheckbox(item)
                                }
                              }
                            },
                            [
                              _c("span", {
                                class: [
                                  "checkbox-button",
                                  { checked: item.isChecked }
                                ]
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label-text" }, [
                                _vm._v(_vm._s(item.value))
                              ])
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-inline part-selection__ur" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    _vm._l(_vm.dentition.UL, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.value,
                          staticClass: "checkbox-solid checkbox-solid-group"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "check-container sm-checkbox",
                              on: {
                                click: function($event) {
                                  return _vm.toggleCheckbox(item)
                                }
                              }
                            },
                            [
                              _c("span", {
                                class: [
                                  "checkbox-button",
                                  { checked: item.isChecked }
                                ]
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label-text" }, [
                                _vm._v(_vm._s(item.value))
                              ])
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "part-selection__direction my-auto" },
                    [_vm._v("\n              UL\n            ")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row noMargin" }, [
                _c("div", { staticClass: "form-inline part-selection__ll" }, [
                  _c(
                    "p",
                    { staticClass: "part-selection__direction my-auto" },
                    [_vm._v("\n              LR\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    _vm._l(_vm.dentition.LR, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.value,
                          staticClass: "checkbox-solid checkbox-solid-group"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "check-container sm-checkbox",
                              on: {
                                click: function($event) {
                                  return _vm.toggleCheckbox(item)
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "label-text" }, [
                                _vm._v(_vm._s(item.value))
                              ]),
                              _vm._v(" "),
                              _c("span", {
                                class: [
                                  "checkbox-button",
                                  { checked: item.isChecked }
                                ]
                              })
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-inline part-selection__lr" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    _vm._l(_vm.dentition.LL, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.value,
                          staticClass: "checkbox-solid checkbox-solid-group"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "check-container sm-checkbox",
                              on: {
                                click: function($event) {
                                  return _vm.toggleCheckbox(item)
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "label-text" }, [
                                _vm._v(_vm._s(item.value))
                              ]),
                              _vm._v(" "),
                              _c("span", {
                                class: [
                                  "checkbox-button",
                                  { checked: item.isChecked }
                                ]
                              })
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "part-selection__direction my-auto" },
                    [_vm._v("\n              LL\n            ")]
                  )
                ])
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "interview-content-consultation-v2__block-symptom" },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "block-header" }, [
              _vm._v(
                "\n        2. " +
                  _vm._s(_vm.$t("objects.patient.text.symptomatology")) +
                  "\n        "
              ),
              _vm.index === 0
                ? _c("span", { staticClass: "required" }, [
                    _vm._v("\n          *\n        ")
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.errors.symptoms
              ? _c("p", { staticClass: "error-message" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.errors.symptoms) + "\n      "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("radio-common", {
                    attrs: {
                      name: "symptoms" + _vm.index,
                      "class-name": "sm-checkbox check-container",
                      label: _vm.$t("objects.patient.text.rattling_double"),
                      val: "ガタガタした歯並びや八重歯（叢生）"
                    },
                    on: { select: _vm.handleSymptom },
                    model: {
                      value: _vm.symptoms,
                      callback: function($$v) {
                        _vm.symptoms = $$v
                      },
                      expression: "symptoms"
                    }
                  }),
                  _vm._v(" "),
                  _c("radio-common", {
                    attrs: {
                      name: "symptoms" + _vm.index,
                      "class-name": "sm-checkbox check-container",
                      label: _vm.$t("objects.patient.text.protruding"),
                      val: "出っ歯（上顎前突）"
                    },
                    on: { select: _vm.handleSymptom },
                    model: {
                      value: _vm.symptoms,
                      callback: function($$v) {
                        _vm.symptoms = $$v
                      },
                      expression: "symptoms"
                    }
                  }),
                  _vm._v(" "),
                  _c("radio-common", {
                    attrs: {
                      name: "symptoms" + _vm.index,
                      "class-name": "sm-checkbox check-container",
                      label: _vm.$t("objects.patient.text.receptacle"),
                      val: "受け口（反対咬合）"
                    },
                    on: { select: _vm.handleSymptom },
                    model: {
                      value: _vm.symptoms,
                      callback: function($$v) {
                        _vm.symptoms = $$v
                      },
                      expression: "symptoms"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "symptoms-comment" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("objects.patient.message.symptoms_type.mouth")
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("radio-common", {
                    attrs: {
                      name: "symptoms" + _vm.index,
                      "class-name": "sm-checkbox check-container",
                      label: _vm.$t("objects.patient.text.clear_teeth"),
                      val: "すきっ歯（空隙歯列）"
                    },
                    on: { select: _vm.handleSymptom },
                    model: {
                      value: _vm.symptoms,
                      callback: function($$v) {
                        _vm.symptoms = $$v
                      },
                      expression: "symptoms"
                    }
                  }),
                  _vm._v(" "),
                  _c("radio-common", {
                    attrs: {
                      name: "symptoms" + _vm.index,
                      "class-name": "sm-checkbox check-container",
                      label: _vm.$t("objects.patient.text.front_teeth"),
                      val: "前歯が閉じない（開咬）"
                    },
                    on: { select: _vm.handleSymptom },
                    model: {
                      value: _vm.symptoms,
                      callback: function($$v) {
                        _vm.symptoms = $$v
                      },
                      expression: "symptoms"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "symptoms-comment" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("objects.patient.message.symptoms_type.mouth")
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("radio-common", {
                    attrs: {
                      name: "symptoms" + _vm.index,
                      "class-name": "sm-checkbox check-container",
                      label: _vm.$t("objects.patient.text.centered_off"),
                      val: "中心がずれている（正中不一致）"
                    },
                    on: { select: _vm.handleSymptom },
                    model: {
                      value: _vm.symptoms,
                      callback: function($$v) {
                        _vm.symptoms = $$v
                      },
                      expression: "symptoms"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "symptoms-comment" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("objects.patient.message.symptoms_type.mouth")
                        ) +
                        "\n          "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("radio-common", {
                    attrs: {
                      name: "symptoms" + _vm.index,
                      "class-name": "sm-checkbox check-container",
                      label: _vm.$t("objects.patient.text.deep_occlusion"),
                      val: "噛み合わせが深い（過蓋咬合）"
                    },
                    on: { select: _vm.handleSymptom },
                    model: {
                      value: _vm.symptoms,
                      callback: function($$v) {
                        _vm.symptoms = $$v
                      },
                      expression: "symptoms"
                    }
                  }),
                  _vm._v(" "),
                  _c("radio-common", {
                    attrs: {
                      name: "symptoms" + _vm.index,
                      "class-name": "sm-checkbox check-container",
                      label: _vm.$t("objects.patient.text.gummy_smile"),
                      val:
                        "スマイル時に上顎の歯肉が露出している（ガミースマイル）"
                    },
                    on: { select: _vm.handleSymptom },
                    model: {
                      value: _vm.symptoms,
                      callback: function($$v) {
                        _vm.symptoms = $$v
                      },
                      expression: "symptoms"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "symptoms-comment" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("objects.patient.message.symptoms_type.gummy")
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("radio-common", {
                    attrs: {
                      name: "symptoms" + _vm.index,
                      "class-name": "sm-checkbox check-container",
                      label: _vm.$t("objects.patient.text.logobo"),
                      val: "口ゴボ"
                    },
                    on: { select: _vm.handleSymptom },
                    model: {
                      value: _vm.symptoms,
                      callback: function($$v) {
                        _vm.symptoms = $$v
                      },
                      expression: "symptoms"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "symptoms-comment",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("objects.patient.message.symptoms_type.logo")
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "symptom-other" },
                    [
                      _c("radio-common", {
                        attrs: {
                          name: "symptoms" + _vm.index,
                          "class-name": "sm-checkbox check-container",
                          label: _vm.$t("new_text.other"),
                          val: "その他"
                        },
                        on: { select: _vm.handleSymptom },
                        model: {
                          value: _vm.symptoms,
                          callback: function($$v) {
                            _vm.symptoms = $$v
                          },
                          expression: "symptoms"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: ["other-input", _vm.disableOtherInput] },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.symptoms_other,
                                expression: "symptoms_other"
                              }
                            ],
                            staticClass: "form-control mt-2",
                            attrs: {
                              type: "text",
                              name: "",
                              placeholder: _vm.$t(
                                "new_text.placeholder.other_fill"
                              )
                            },
                            domProps: { value: _vm.symptoms_other },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.symptoms_other = $event.target.value
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "interview-content-consultation-v2__block-comments" },
        [
          _c("label", { staticClass: "block-header color-blue" }, [
            _vm._v(
              "3. " +
                _vm._s(_vm.$t("objects.patient.text.consultation_symptom"))
            )
          ]),
          _vm._v(" "),
          _vm.isShowIconRequired
            ? _c("span", { staticClass: "required" }, [_vm._v("*")])
            : _vm._e(),
          _vm._v(" "),
          _vm.errors.main_symptoms
            ? _c("p", { staticClass: "error-message" }, [
                _vm._v("\n      " + _vm._s(_vm.errors.main_symptoms) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "fs-14" }, [
              _vm._v(
                _vm._s(_vm.$t("objects.patient.message.complaint_comment"))
              )
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.mainSymptoms,
                  expression: "mainSymptoms"
                }
              ],
              staticClass: "form-control",
              attrs: {
                rows: "3",
                placeholder: _vm.$t(
                  "objects.patient.placeholder.consultation_symptom"
                )
              },
              domProps: { value: _vm.mainSymptoms },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.mainSymptoms = $event.target.value
                }
              }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }