var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.className] },
    [
      _c("sub-question", {
        ref: "sub_question",
        attrs: {
          question: _vm.question,
          options: _vm.option_yes_no,
          checked: _vm.select_option
        },
        on: {
          handleAnswer: function($event) {
            return _vm.handleAnswer($event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.handleChildAnswerClass },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("checkbox-common", {
                attrs: {
                  label: "鎮痛剤（痛み止め）",
                  val: "鎮痛剤（痛み止め）"
                },
                model: {
                  value: _vm.selections,
                  callback: function($$v) {
                    _vm.selections = $$v
                  },
                  expression: "selections"
                }
              }),
              _vm._v(" "),
              _c("checkbox-common", {
                attrs: { label: "ビタミン剤", val: "ビタミン剤" },
                model: {
                  value: _vm.selections,
                  callback: function($$v) {
                    _vm.selections = $$v
                  },
                  expression: "selections"
                }
              }),
              _vm._v(" "),
              _c("checkbox-common", {
                attrs: { label: "ホルモン剤", val: "ホルモン剤" },
                model: {
                  value: _vm.selections,
                  callback: function($$v) {
                    _vm.selections = $$v
                  },
                  expression: "selections"
                }
              }),
              _vm._v(" "),
              _c("checkbox-common", {
                attrs: { label: "血圧の薬", val: "血圧の薬" },
                model: {
                  value: _vm.selections,
                  callback: function($$v) {
                    _vm.selections = $$v
                  },
                  expression: "selections"
                }
              }),
              _vm._v(" "),
              _c("checkbox-common", {
                attrs: { label: "糖尿病の薬", val: "糖尿病の薬" },
                model: {
                  value: _vm.selections,
                  callback: function($$v) {
                    _vm.selections = $$v
                  },
                  expression: "selections"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-inline selection-other-option pt-3" },
                [
                  _c("checkbox-common", {
                    attrs: { label: "その他", val: "その他" },
                    model: {
                      value: _vm.selections,
                      callback: function($$v) {
                        _vm.selections = $$v
                      },
                      expression: "selections"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { class: ["other-input", _vm.diableOtherInput] }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.other,
                          expression: "other"
                        }
                      ],
                      staticClass: "form-control w-100",
                      attrs: { type: "text" },
                      domProps: { value: _vm.other },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.other = $event.target.value
                        }
                      }
                    })
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.details, function(detail, index) {
            return _c(
              "div",
              {
                key: detail.detail_index,
                staticClass: "form-group block-group"
              },
              [
                _c("label", { staticClass: "block-group__first" }, [
                  _vm._v(_vm._s(detail.detail_index))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "block-group__inline" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "color-blue" }, [
                      _vm._v("薬品名：")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: detail.diagnostis,
                          expression: "detail.diagnostis"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", name: "" },
                      domProps: { value: detail.diagnostis },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(detail, "diagnostis", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "color-blue" }, [
                      _vm._v("服薬量：")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-inline justify-content-between" },
                      [
                        _c("span", [_vm._v("１日")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: detail.name1,
                              expression: "detail.name1"
                            }
                          ],
                          staticClass: "form-control col-md-3",
                          attrs: { type: "text" },
                          domProps: { value: detail.name1 },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(detail, "name1", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("回")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: detail.name2,
                              expression: "detail.name2"
                            }
                          ],
                          staticClass: "form-control col-md-3",
                          attrs: { type: "text" },
                          domProps: { value: detail.name2 },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(detail, "name2", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("錠/包/ml")])
                      ]
                    )
                  ])
                ])
              ]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }