var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "date-setting-content" },
    [
      _c("div", { staticClass: "date-setting-content__header" }, [
        _c("h2", { staticClass: "date-setting-content__header__title" }, [
          _vm._v(_vm._s(_vm.$t("text.type_setting")))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "row-content" }, [
            _c("div", { staticClass: "action-content" }, [
              _c("p", { staticClass: "medusa-bg" }, [
                _vm._v(_vm._s(_vm.$t("text.reservation_type_master_setting")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 m0 m-0 p-0" }, [
              _c("div", { staticClass: "reservation-type" }, [
                _c("div", { staticClass: "reservation-type-content" }, [
                  _c("div", { staticClass: "reservation-tab" }, [
                    _c(
                      "ul",
                      { staticClass: "nav nav-tabs" },
                      [
                        _vm._l(_vm.reservationMaster, function(
                          reservationType,
                          index
                        ) {
                          return _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                class: { active: index === 1 },
                                attrs: {
                                  "data-id": index,
                                  "data-name": reservationType[0]
                                    .reservation_type_name
                                    ? reservationType[0].reservation_type_name
                                    : index,
                                  "data-toggle": "tab",
                                  href: "#type" + index
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.updateTabActive(index)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    reservationType[0].reservation_type_name
                                      ? reservationType[0].reservation_type_name
                                      : index
                                  )
                                )
                              ]
                            )
                          ])
                        }),
                        _vm._v(" "),
                        _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link add-duty-tab",
                              on: { click: _vm.addDutyTab }
                            },
                            [_vm._v("+")]
                          )
                        ])
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content" },
                      _vm._l(_vm.reservationMaster, function(
                        reservationType,
                        index
                      ) {
                        return _c(
                          "div",
                          {
                            staticClass: "tab-pane",
                            class: { active: index === 1 },
                            attrs: { id: "type" + index }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "reservation-box-content",
                                attrs: { id: "reservation-type-setting" }
                              },
                              [
                                _vm._l(reservationType, function(duty, key) {
                                  return [
                                    duty.duty_name
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "reservation-box",
                                            on: {
                                              click: function($event) {
                                                return _vm.editDutyBlock(
                                                  index,
                                                  key
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "box-text" },
                                              [
                                                _c("span", {
                                                  staticClass: "note-color",
                                                  style: {
                                                    background: duty.duty
                                                      ? duty.duty.color
                                                      : ""
                                                  }
                                                }),
                                                _vm._v(
                                                  _vm._s(duty.duty_name) +
                                                    "\n                                                    "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "box-value" },
                                              [
                                                _c("span", {
                                                  staticClass: "icon-clock"
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "title" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(duty.take_time) +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.minute"
                                                          )
                                                        )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                !duty.is_preset
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "edit-type"
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src:
                                                              "/images/edit.svg"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "reservation-add-box add-block",
                                    on: {
                                      click: function($event) {
                                        return _vm.addDutyBlock(index)
                                      }
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "box-text" }, [
                                      _vm._m(0, true),
                                      _vm._v(
                                        _vm._s(_vm.$t("text.tap_to_add_item")) +
                                          "\n                                                "
                                      )
                                    ])
                                  ]
                                )
                              ],
                              2
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "remove-tab",
                      on: {
                        click: function($event) {
                          return _vm.editReservationMasterTab($event)
                        }
                      }
                    },
                    [_c("p", [_vm._v(_vm._s(_vm.$t("text.edit_current_tab")))])]
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row reservation-duty" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "row-content" }, [
            _c("div", { staticClass: "action-content" }, [
              _c("p", { staticClass: "medusa-bg" }, [
                _vm._v(_vm._s(_vm.$t("text.job_master_setting")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 m0 m-0 p-0" }, [
              _c("div", { staticClass: "reservation-duty-type" }, [
                _c("table", { staticClass: "table table-borderless" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(_vm.$t("text.job_title")) +
                            "\n                                    "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "duty-frame duty-color-label",
                            attrs: { scope: "col" }
                          },
                          [_vm._v(_vm._s(_vm.$t("common.color")))]
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.colorsDuty, function(duty, key) {
                      return _c("tr", [
                        _c("td", { attrs: { scope: "row" } }, [
                          _c(
                            "div",
                            { staticClass: "accordions" },
                            [
                              _c(
                                "div",
                                {
                                  class: [
                                    duty.staff.length
                                      ? duty.active
                                        ? "close"
                                        : "open"
                                      : "disabled",
                                    "togglebutton"
                                  ],
                                  on: {
                                    click: function($event) {
                                      return _vm.toggle(duty)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(duty.title) +
                                      "\n                                        "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "slide-up-down",
                                { attrs: { active: duty.active } },
                                _vm._l(duty.staff, function(staff) {
                                  return _c(
                                    "div",
                                    { staticClass: "duty-staff" },
                                    [
                                      _c("div", { staticClass: "element" }, [
                                        _vm._v(
                                          "\n                                                    " +
                                            _vm._s(staff.name) +
                                            "\n                                                "
                                        )
                                      ])
                                    ]
                                  )
                                }),
                                0
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "note-color duty-color duty-frame ",
                            style: { background: duty.color },
                            attrs: { "data-color": duty.color },
                            on: {
                              click: function($event) {
                                return _vm.showDutyPopup($event, duty.id)
                              }
                            }
                          })
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.is_show_modal_delete_duty_tab
        ? _c("modal-confirmation", {
            attrs: {
              custom_class:
                "mc-modal--red-style mc-modal--reservation-type-delete",
              is_show_close_button: false
            },
            on: {
              confirmButtonClick: _vm.deleteReservationMasterTab,
              close: function($event) {
                _vm.is_show_modal_delete_duty_tab = false
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function() {
                    return [
                      _c("span", { staticClass: "mc-modal__body__content" }, [
                        _vm._v(
                          "\n      " +
                            _vm._s(
                              _vm.$t("reservation_type.msg_confirm_delete", {
                                active_tab_name: _vm.active_tab_name
                              })
                            ) +
                            "\n    "
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "confirm_button_content",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n    " + _vm._s(_vm.$t("common.delete")) + "\n  "
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "cancel_button_content",
                  fn: function() {
                    return [
                      _vm._v("\n    " + _vm._s(_vm.$t("common.no")) + "\n  ")
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2883720484
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c("loading")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "add-type" }, [
      _c("img", { attrs: { src: "/images/plus.svg" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }