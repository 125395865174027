var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "patient-system-info" }, [
    _c("div", { staticClass: "patient-system-info__wrap" }, [
      _c("div", { staticClass: "patient-system-info__group" }, [
        _c("div", { staticClass: "patient-system-info__row" }, [
          _c("label", { staticClass: "patient-system-info__label" }, [
            _vm._v(_vm._s(_vm.$t("new_text.patient_status")))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "patient-system-info__data" }, [
            _vm._v("\n          " + _vm._s(_vm.patientStatus) + "\n        ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "patient-system-info__row" }, [
          _c("label", { staticClass: "patient-system-info__label" }, [
            _vm._v(_vm._s(_vm.$t("new_text.registration_date")))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "patient-system-info__data" }, [
            _vm._v("\n          " + _vm._s(_vm.createdAt) + "\n        ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "patient-system-info__row" }, [
          _c("label", { staticClass: "patient-system-info__label" }, [
            _vm._v(_vm._s(_vm.$t("new_text.last_updated")))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "patient-system-info__data" }, [
            _vm._v("\n          " + _vm._s(_vm.updatedAt) + "\n        ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "patient-system-info__row" }, [
          _c("label", { staticClass: "patient-system-info__label" }, [
            _vm._v(
              _vm._s(
                _vm.$t("objects.patient.text.ip_address_of_first_appointment")
              )
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "patient-system-info__data" }, [
            _vm._v("\n          " + _vm._s(_vm.ipAddress) + "\n        ")
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "patient-system-info__title patient-system-info__title--line-bottom"
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t("objects.patient.text.person_in_charge_information")
              ) +
              "\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "patient-system-info__group" },
        _vm._l(_vm.customPerson, function(item, key) {
          return _c(
            "div",
            { key: key, staticClass: "patient-system-info__row" },
            [
              _c("label", { staticClass: "patient-system-info__label" }, [
                _vm._v(_vm._s(key))
              ]),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "patient-system-info__data" },
                _vm._l(item, function(staff, index) {
                  return _c(
                    "span",
                    { key: index, staticClass: "patient-system-info__staff" },
                    [_vm._v(_vm._s(staff.name))]
                  )
                }),
                0
              )
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "patient-system-info__title patient-system-info__title--line-bottom"
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("objects.patient.text.other_notes")) +
              "\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "patient-system-info__group" }, [
        _vm.otherNote
          ? _c("div", { staticClass: "patient-system-info__row" }, [
              _c(
                "span",
                {
                  staticClass:
                    "patient-system-info__data patient-system-info__data--note"
                },
                [_vm._v(_vm._s(_vm.otherNote))]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.$store.getters.currentClinic.is_dmr || 0
          ? _c(
              "div",
              {
                staticClass:
                  "patient-system-info__row patient-system-info__row--service"
              },
              [
                _c("label", { staticClass: "patient-system-info__label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("objects.patient.text.service_management"))
                  )
                ]),
                _vm._v(" "),
                _vm.serviceSummary.length
                  ? _c("patient-service-summary", {
                      attrs: { "service-summary": _vm.serviceSummary }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }