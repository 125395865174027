export default {
  note_send_reason_when_create_block: '※理由を送信することで、ブロック登録が完了します。',
  note_send_reason_when_create_reservation: '※理由を送信することで、予約登録が完了します。',
  note_send_reason_when_delete_allocation: '※理由を送信することで、設定枠の削除が完了します。',
  note_send_reason_when_update_allocation: '※理由を送信することで、設定枠の変更が完了します。',
  note_send_reason_when_update_block: '※理由を送信することで、ブロック時間変更が完了します。',
  note_send_reason_when_update_reservation: '※理由を送信することで、予約変更が完了します。',
  note_specified_date_and_time: '※指定日時のブロックです',
  send_the_reason_to_the_supervisor: '管理監督者へ理由を送信してください'
}
