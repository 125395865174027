import axios from 'axios'
import { state } from './auth'

export const namespaced = true

export const actions = {
  changeLanguage ({ rootState }, { lang }) {
    return axios.post('/api/staff/language', { lang }, {
      headers: { Authorization: 'Bearer ' + rootState.auth.token }
    })
  },
  config ({ rootState }) {
    return axios.get('/api/staff/config', {
      headers: { Authorization: 'Bearer ' + rootState.auth.token }
    }).then((result) => {
      return result.data.results
    })
  },
  getStaffList () {
    return axios.get('/api/staff/list', {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  adminAddNewStaff ({ dispatch, commit }, postData) {
    return axios.post('/api/staff/register', postData, {
      headers: { Authorization: 'Bearer ' + state.token }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  adminDeleteStaff ({ dispatch, commit }, staffId) {
    return axios.post('/api/staff/delete', { staffId: staffId },
      {
        headers: { Authorization: 'Bearer ' + state.token }
      }
    )
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  adminUpdateStaffInfo ({ dispatch, commit }, staffInfo) {
    return axios.post('/api/staff/update', staffInfo,
      {
        headers: { Authorization: 'Bearer ' + state.token }
      }
    )
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  }
}
