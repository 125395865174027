var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "calendar",
        { "calendar__focus--cancelled": _vm.is_reservation_cancelled }
      ]
    },
    [
      _c("full-calendar", {
        ref: "fullCalendar",
        attrs: {
          id: "calendar",
          "default-view": "resourceTimeGridDay",
          "scroll-time": _vm.scroll_time,
          locale: _vm.locale,
          plugins: _vm.calendar_plugins,
          "scheduler-license-key": _vm.scheduler_licenseKey,
          header: _vm.header,
          "title-format": _vm.title_format,
          "default-date": _vm.selectedDate,
          "custom-buttons": _vm.custom_buttons,
          "time-format": _vm.time_format,
          "min-time": _vm.is_closed_all_day ? "00:00:00" : _vm.min_active_time,
          "max-time": _vm.is_closed_all_day ? "24:00:00" : _vm.max_active_time,
          "slot-label-format": _vm.slot_label_format,
          "slot-duration": _vm.slot_duration,
          "slot-label-interval": _vm.slot_label_interval,
          "snap-duration": _vm.snap_duration,
          "event-time-format": _vm.event_time_format,
          editable: _vm.editable,
          selectable: _vm.selectable,
          "unselect-cancel": _vm.unselect_class,
          "event-limit": _vm.event_limit,
          "select-mirror": _vm.select_mirror,
          "resource-order": _vm.resource_order,
          resources: _vm.resources,
          events: _vm.events,
          "event-overlap": _vm.handleEventOverLap,
          "select-overlap": _vm.handleSelectOverLap,
          "resource-render": _vm.resourceRender,
          "dates-render": _vm.datesRender,
          "event-render": _vm.eventRender
        },
        on: {
          windowResize: _vm.windowResize,
          select: _vm.clickToFullCalendar,
          eventClick: _vm.clickToReservation,
          eventResize: function($event) {
            return _vm.handleDropResizeEvent($event)
          },
          eventDrop: function($event) {
            return _vm.handleDropResizeEvent($event, true)
          },
          eventDragStart: function($event) {
            return _vm.handleDropResizeEventStart($event)
          },
          eventDragStop: _vm.handleDropResizeEventStop,
          dateClick: _vm.removeActiveReservation,
          eventResizeStart: function($event) {
            return _vm.handleDropResizeEventStart($event)
          },
          eventResizeStop: _vm.handleDropResizeEventStop
        }
      }),
      _vm._v(" "),
      _c("full-calendar", {
        ref: "printedCalendar",
        attrs: {
          id: "print-calendar",
          "default-view": "resourceTimeGridDay",
          "scroll-time": _vm.scroll_time,
          locale: _vm.locale,
          plugins: _vm.calendar_plugins,
          "scheduler-license-key": _vm.scheduler_licenseKey,
          header: _vm.header,
          "title-format": _vm.title_format,
          "default-date": _vm.selectedDate,
          "custom-buttons": _vm.custom_buttons,
          "time-format": _vm.time_format,
          "min-time": _vm.is_closed_all_day
            ? "00:00:00"
            : _vm.min_active_time_print,
          "max-time": _vm.is_closed_all_day
            ? "24:00:00"
            : _vm.max_active_time_print,
          "slot-label-format": _vm.slot_label_format,
          "slot-duration": _vm.slot_duration,
          "slot-label-interval": _vm.slot_label_interval,
          "event-time-format": _vm.event_time_format,
          editable: _vm.isPrintMemo,
          selectable: _vm.selectable,
          "unselect-cancel": _vm.unselect_class,
          "event-limit": _vm.event_limit,
          "select-mirror": _vm.select_mirror,
          "resource-order": _vm.resource_order,
          resources: _vm.resources_print,
          events: _vm.events,
          "event-overlap": _vm.handleEventOverLap,
          "select-overlap": _vm.handleSelectOverLap,
          "resource-render": _vm.resourceRender,
          "dates-render": _vm.datesPrintRender,
          "event-render": _vm.eventPrintRender
        },
        on: { windowResize: _vm.windowResize }
      }),
      _vm._v(" "),
      _vm.data_create_reservation
        ? _c("modal-create-reservation", {
            attrs: { "data-calendar": _vm.data_create_reservation },
            on: {
              close: function($event) {
                ;[
                  (_vm.data_create_reservation = null),
                  _vm.reloadReservationData(),
                  _vm.removeFrameReservation()
                ]
              },
              created: _vm.handleAfterCreateReservation
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data_update_reservation
        ? _c("modal-update-reservation", {
            attrs: {
              "event-edit": _vm.data_update_reservation,
              allocations: _vm.allocations
            },
            on: {
              close: function($event) {
                ;[
                  (_vm.data_update_reservation = null),
                  _vm.reloadReservationData(),
                  (_vm.is_reservation_cancelled = false)
                ]
              },
              updated: _vm.handleAfterUpdateReservation,
              canceled: _vm.handleAfterCancel
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        class: [
          "icon-switch switch-prev",
          { "switch-prev--disable": _vm.direction_position === 0 }
        ],
        attrs: { id: "btn-left" },
        on: {
          click: function($event) {
            return _vm.switchReservationFrame("left")
          }
        }
      }),
      _vm._v(" "),
      _c("div", {
        class: [
          "icon-switch switch-next",
          {
            "switch-next--disable":
              _vm.direction_position === _vm.resources.length - _vm.max_resource
          }
        ],
        attrs: { id: "btn-right" },
        on: {
          click: function($event) {
            return _vm.switchReservationFrame()
          }
        }
      }),
      _vm._v(" "),
      _vm.data_patient_detail
        ? _c("modal-patient-detail", {
            attrs: { "data-patient": _vm.data_patient_detail },
            on: {
              close: function($event) {
                ;[(_vm.data_patient_detail = null), _vm.reloadReservationData()]
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_confirm_first_reservation
        ? _c("modal-confirm", {
            attrs: {
              title: _vm.$t(
                "patient_page.first_reservation_modal_title_change"
              ),
              text: _vm.$t("patient_page.first_reservation_confirmation_change")
            },
            on: {
              no: function($event) {
                return _vm.resetReservationDataResizeOrMove()
              },
              yes: function($event) {
                return _vm.handleUpdateReservation(
                  _vm.update_reservation_type.RESIZE_OR_DROP
                )
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.show_modal_update_draft
        ? _c("modal-confirm", {
            attrs: {
              title: _vm.$t(
                "patient_page.first_reservation_modal_title_change"
              ),
              text: _vm.$t("patient_page.first_reservation_confirmation_change")
            },
            on: {
              no: _vm.hideModalUpdateDraft,
              yes: _vm.showMoveConfirmUpdateReservation
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.move_mode_show_modal_confirm_update_reservation
        ? _c("modal-confirm", {
            attrs: {
              title: _vm.updateReservationModalMessageTitle,
              text: _vm.updateReservationModalMessageBody
            },
            on: {
              no: _vm.moveModeDismissUpdateReservation,
              yes: function($event) {
                return _vm.handleUpdateReservation(
                  _vm.update_reservation_type.MOVE_MODE
                )
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.move_mode_show_modal_confirm_continue
        ? _c("modal-confirm", {
            attrs: {
              title: _vm.continueMoveModeMessageTitle,
              text: _vm.continueMoveModeMessageBody
            },
            on: {
              no: function($event) {
                ;[_vm.moveModeExit(), _vm.changeDate()]
              },
              yes: _vm.moveModeContinue
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_create_allocation
        ? _c("modal-create-allocation", {
            attrs: {
              allocations: _vm.allocations,
              "selected-date": _vm.selectedDate
            },
            on: {
              close: function($event) {
                ;[
                  (_vm.is_show_modal_create_allocation = false),
                  _vm.reloadReservationData()
                ]
              },
              succeed: _vm.handleSucceedAllocation
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.allocation_edit
        ? _c("modal-update-allocation", {
            attrs: {
              allocations: _vm.allocations,
              "selected-date": _vm.selectedDate,
              allocation: _vm.allocation_edit
            },
            on: {
              close: function($event) {
                ;[(_vm.allocation_edit = null), _vm.reloadReservationData()]
              },
              succeed: _vm.handleSucceedAllocation
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal_operation_reasons
        ? _c("modal-operation-reason", {
            attrs: {
              "is-update": true,
              "is-block": _vm.eventUpdateTypeBlock,
              resources: _vm.operationReasonResources
            },
            on: {
              back: _vm.dismissUpdateReservation,
              confirm: _vm.submitUpdateReservation
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }