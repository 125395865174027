var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page page-print page-print-answer" }, [
    _c(
      "main",
      {
        staticClass: "patient-detail-page patient-detail-print interview-print"
      },
      [
        _c("div", { staticClass: "col-12 main-content" }, [
          _c("section", { staticClass: "content" }, [
            _c(
              "div",
              {
                staticClass: "content-wrapper content-wrapper--interview-print"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-center position-relative"
                  },
                  [
                    _vm.shouldPrintBrandName
                      ? _c("label", { staticClass: "brand-name" }, [
                          _vm._v(_vm._s(_vm.getBrandName))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h3", { staticClass: "text-center p-3 mb-0" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "border-bottom-print m-0 p-0 d-inline-block"
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.isKid ? "小児" : "") +
                              "矯正歯科初診問診票\n              "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("h4", { staticClass: "sf-user-id-tag" }, [
                      _vm._v(
                        "\n              患者ID: " +
                          _vm._s(_vm.patient.sf_user_id) +
                          "\n            "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-5 d-flex pr-0" }, [
                    _c("div", { staticClass: "d-flex flex-column w-100" }, [
                      _c("div", { staticClass: "pt-25 padding-left-2 pb-4" }, [
                        _c(
                          "p",
                          {
                            staticClass: "border-bottom-print pl-10 ls-2 fs-15"
                          },
                          [
                            _vm._v("\n                    予約日時: "),
                            _c("span", { staticClass: "ml-4" }, [
                              _vm._v(_vm._s(_vm.patientDateBooking))
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "padding-left-2 pb-4" }, [
                        _c(
                          "p",
                          {
                            staticClass: "border-bottom-print pl-10 ls-2 fs-15"
                          },
                          [
                            _vm._v("\n                    患者ID: "),
                            _c("span", { staticClass: "ml-4" }, [
                              _vm._v(_vm._s(_vm.patient.sf_user_id))
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "fs-18 margin-top-3" }, [
                        _vm._v("◆患者様情報")
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-7" },
                    [_c("InterviewCheckList")],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 patient-detail-left patient-info-interview mt-0"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "print-component component-wrapper interview-checklist"
                        },
                        [
                          _c("div", { staticClass: "bg-white" }, [
                            _c(
                              "table",
                              {
                                staticClass:
                                  "table  table-interview-check-list table-interview-info"
                              },
                              [
                                _c("tbody", [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "bg-highlight font-weight-bold",
                                        attrs: { width: "60px" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                          ふりがな\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("td", { attrs: { width: "200px" } }, [
                                      _vm._v(
                                        "\n                          " +
                                          _vm._s(
                                            _vm.truncateContent(
                                              _vm.patient_info.kana_last_name +
                                                " " +
                                                _vm.patient_info.kana_first_name
                                            )
                                          ) +
                                          "\n                        "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "bg-highlight font-weight-bold",
                                        attrs: { width: "80px" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                          生年月日\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        attrs: { width: "200px", colspan: "3" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end"
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "mx-5" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.birthOfDate.year) +
                                                    "年"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "mx-5" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.birthOfDate.month
                                                  ) + "月"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "ml-5" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.birthOfDate.day) +
                                                    "日"
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "bg-highlight font-weight-bold"
                                      },
                                      [
                                        _vm._v(
                                          "\n                          ローマ字\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        "\n                          " +
                                          _vm._s(
                                            _vm.truncateContent(
                                              _vm.patient_info.roma_last_name +
                                                " " +
                                                _vm.patient_info.roma_first_name
                                            )
                                          ) +
                                          "\n                        "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "bg-highlight font-weight-bold"
                                      },
                                      [
                                        _vm._v(
                                          "\n                          年齢\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "col-data text-center",
                                        attrs: { colspan: "3" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(_vm.patientAge) +
                                            "歳\n                        "
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "bg-highlight font-weight-bold align-middle",
                                        attrs: { rowspan: "3" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                          氏名\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-left align-middle",
                                        attrs: { rowspan: "3" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.truncateContent(
                                                _vm.patient_info.last_name +
                                                  " " +
                                                  _vm.patient_info.first_name
                                              )
                                            ) +
                                            "\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "bg-highlight font-weight-bold"
                                      },
                                      [
                                        _vm._v(
                                          "\n                          性別\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "col-data",
                                        attrs: { colspan: "3" }
                                      },
                                      [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(_vm.fillSex))
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.patientPregnancy))
                                        ])
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "bg-highlight font-weight-bold"
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.isKid
                                                ? "習い事・スポーツ"
                                                : "ご職業"
                                            ) +
                                            "\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "col-data",
                                        attrs: { colspan: "3" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.isKid
                                                ? _vm.formatGuardianInfo
                                                    .lessonSport
                                                : _vm.fillProfessions
                                            ) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "bg-highlight font-weight-bold"
                                      },
                                      [
                                        _vm._v(
                                          "\n                          携帯電話\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "col-data",
                                        attrs: { colspan: "3" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(_vm.patient_info.tel) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", { staticClass: "bg-highlight" }, [
                                      _vm._v(
                                        "\n                          ご住所\n                        "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-left",
                                        attrs: { rowspan: "2", colspan: "5" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                          〒 " +
                                            _vm._s(_vm.patient_info.zip_code) +
                                            "\n                          "
                                        ),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(_vm.patient_info.address)
                                          )
                                        ])
                                      ]
                                    )
                                  ])
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "bg-white guardian-info guardian--first"
                            },
                            [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-interview-info table-interview-check-list"
                                },
                                [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "bg-highlight font-weight-bold",
                                          attrs: { width: "60px" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          ふりがな\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("td", { attrs: { width: "200px" } }, [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.truncateContent(
                                                _vm.formatGuardianInfo.kanaName
                                              )
                                            ) +
                                            "\n                        "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "bg-highlight font-weight-bold",
                                          attrs: { width: "80px" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          続柄\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          attrs: {
                                            width: "200px",
                                            colspan: "3"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.formatGuardianInfo
                                                  .relationship
                                              ) +
                                              "\n                        "
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "bg-highlight font-weight-bold align-middle",
                                          attrs: { rowspan: "2" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          氏名\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-left align-middle",
                                          attrs: { rowspan: "2" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.truncateContent(
                                                  _vm.formatGuardianInfo.name
                                                )
                                              ) +
                                              "\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "bg-highlight font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            "\n                          ご連絡先\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "col-data",
                                          attrs: { colspan: "3" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.formatGuardianInfo.phone
                                              ) +
                                              "\n                        "
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "bg-highlight font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            "\n                          ご職業\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "col-data",
                                          attrs: { colspan: "3" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.fillGuardianOccupation
                                              ) +
                                              "\n                        "
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _vm._m(1),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-left",
                                          attrs: { rowspan: "2", colspan: "5" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          〒\n                          "
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatGuardianInfo.address
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "bg-white guardian-info guardian--second"
                            },
                            [
                              _c("h4", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$t("interview.guardian_info")) +
                                    "\n                  "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-interview-info table-interview-check-list"
                                },
                                [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "bg-highlight font-weight-bold",
                                          attrs: { width: "60px" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          ふりがな\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("td", { attrs: { width: "200px" } }, [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.truncateContent(
                                                _vm.formatGuardianInfo
                                                  .guardian2KanaName
                                              )
                                            ) +
                                            "\n                        "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "bg-highlight font-weight-bold",
                                          attrs: { width: "80px" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          続柄\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          attrs: {
                                            width: "200px",
                                            colspan: "3"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.formatGuardianInfo
                                                  .guardian2Relationship
                                              ) +
                                              "\n                        "
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "bg-highlight font-weight-bold align-middle",
                                          attrs: { rowspan: "2" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          氏名\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-left align-middle",
                                          attrs: { rowspan: "2" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.truncateContent(
                                                  _vm.formatGuardianInfo
                                                    .guardian2Name
                                                )
                                              ) +
                                              "\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "bg-highlight font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            "\n                          ご連絡先\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "col-data",
                                          attrs: { colspan: "3" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.formatGuardianInfo
                                                  .guardian2Phone
                                              ) +
                                              "\n                        "
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-3 page-break-after" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("h4", { staticClass: "pb-2" }, [
                        _vm._v("◆ ご相談内容")
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.getConsultationItem))]),
                      _vm._v(" "),
                      _c("p", [_vm._v("ご相談内容・気になる症状について")]),
                      _vm._v(" "),
                      _vm._l(_vm.getConsultationPointsSymptoms, function(
                        symptom,
                        index
                      ) {
                        return _c("div", { key: index, staticClass: "mx-2" }, [
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v(
                              "\n                  優先順位" +
                                _vm._s(index + 1) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v("\n                  部位: "),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getPartName(
                                    symptom.part,
                                    symptom.part_detail
                                  )
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v("\n                  歯列番号: "),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getDentitionNumber(
                                    symptom.dentition_number
                                  )
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v("\n                  症状: "),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v(_vm._s(_vm.getSymptoms(symptom)))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "m-0 symptoms-comment" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.getSymptomsComment(symptom)))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v(
                              "\n                  主訴:\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-4" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(symptom.consultation_content) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v("　")])
                        ])
                      })
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row page-break" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("h4", { staticClass: "pt-3 text-right" }, [
                        _vm._v(
                          "\n                患者ID: " +
                            _vm._s(_vm.patient.sf_user_id) +
                            "\n              "
                        )
                      ]),
                      _vm._v(" "),
                      _c("h4", [
                        _vm._v("\n                ◆ 事前問診\n              ")
                      ]),
                      _vm._v(" "),
                      _vm.is_interview_fetched
                        ? _c("interview-sheet", {
                            attrs: { "interview-info": _vm.getInterviewsheet }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("other", { attrs: { other: _vm.getOther } })
              ],
              1
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h4", [
        _vm._v(
          "\n                    未成年の方は保護者の方の情報をご記入ください\n                  "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "bg-highlight" }, [
      _c("p", [_vm._v("ご住所")]),
      _vm._v(" "),
      _c("p", [_vm._v("お住いが別の場合")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }