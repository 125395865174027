<template>
  <div class="patient-detail">
    <div class="patient-detail__content">
      <div class="patient-detail__content__header">
        <label class="patient-detail__content__title">{{ $t('objects.patient.text.patient_information') }}</label>
        <div class="patient-detail__content__action">
          <button-common
            class="button-v2--tertiary"
            @click="showModalEditHistory"
          >
            <template #content>
              <img src="/images/icon-history-v2.svg">
              {{ $t('objects.patient.button.history') }}
            </template>
          </button-common>
          <button-common
            class="button-v2--tertiary"
            @click="handleEditPatient"
          >
            <template #content>
              <img src="/images/icon-edit-v2.svg">
              {{ $t('new_text.button.edit') }}
            </template>
          </button-common>
          <button-common
            v-if="$store.getters.isAdmin"
            class="button-v2--delete"
            @click="handleDeletePatient"
          >
            <template #content>
              {{ $t('new_text.button.delete_2') }}
            </template>
          </button-common>
        </div>
      </div>
      <div class="patient-detail__content__information">
        <patient-information
          :patient-info="patient.info"
          :sf-treatmentend-status-c="patient.sf_treatmentend_status_c"
          :sf-pre-contract-simulation="patient.sf_pre_contract_simulation"
          :csl-brand-type="patient.csl_brand_type"
        />
      </div>
      <div class="patient-detail__content__system-information">
        <label class="patient-detail__content__title patient-detail__content__title--system-info">
          {{ $t('objects.patient.text.system_information') }}
        </label>
        <patient-system-information :patient="patient" />
      </div>
    </div>
    <div class="patient-detail__sidebar">
      <div class="patient-detail__sidebar__tab">
        <span
          :class="[
            'patient-detail__sidebar__tab-item',
            { 'patient-detail__sidebar__tab-item--active': tab_active === 'comment' }
          ]"
          @click="tab_active = 'comment'"
        >{{ $t('new_text.comment') }}</span>
        <span
          :class="[
            'patient-detail__sidebar__tab-item',
            { 'patient-detail__sidebar__tab-item--active': tab_active === 'reservation_schedule' }
          ]"
          @click="tab_active = 'reservation_schedule'"
        >{{ $t('objects.patient.text.reservation_schedule') }}</span>
      </div>
      <div
        v-if="tab_active === 'comment'"
        class="patient-detail__sidebar__content"
      >
        <comment-tab />
      </div>
      <div
        v-if="tab_active === 'reservation_schedule'"
        class="patient-detail__sidebar__content"
      >
        <reservation-schedule
          :first-reservation-id="patient.first_reservation_id"
          :first-reservation-month="patient.first_reservation_month"
          :order-count="patient.order_count"
        />
      </div>
    </div>
    <modal-edit-history
      v-if="is_show_modal_edit_history"
      @close="is_show_modal_edit_history = false"
    />
    <modal-verify-password
      v-if="is_show_modal_delete_patient"
      :form-info="form_info"
      :cancel-button="true"
      @on-verified="submitDeletedPatient"
      @close="is_show_modal_delete_patient = false"
    />
  </div>
</template>

<script>
  import { showErrorModal } from '../../../utils/helpers'
  import PatientInformation from '../../../components/patient/v2/PatientInformation'
  import PatientSystemInformation from '../../../components/patient/v2/PatientSystemInformation'
  import ReservationSchedule from '../../../components/patient/v2/detail/ReservationSchedule'
  import ButtonCommon from '../../../components/common/Button'
  import ModalVerifyPassword from '../../../components/v2/ModalVerifyPassword'
  import { RESPONSE_STATUS } from '../../../utils/constants'
  import ModalEditHistory from '../../../components/v2/ModalEditHistory'
  import CommentTab from '../../../components/patient/v2/comment/CommentTab.vue'

  export default {
    components: {
      ReservationSchedule,
      PatientInformation,
      PatientSystemInformation,
      ButtonCommon,
      ModalVerifyPassword,
      ModalEditHistory,
      CommentTab
    },
    data () {
      return {
        tab_active: 'comment',
        patient_id: '',
        reservation_id: '',
        form_info: {
          title: this.$t('objects.patient.text.title_delete_modal'),
          description: this.$t('objects.patient.text.description_delete_modal'),
          label: this.$t('objects.patient.text.note_delete_modal'),
          placeholder: this.$t('new_text.placeholder.please_enter_your_login_password'),
          button_text: this.$t('new_text.button.delete')
        },
        is_show_modal_delete_patient: false,
        is_show_modal_edit_history: false
      }
    },
    computed: {
      patient: function () {
        return this.$store.getters.get_patient
      }
    },
    created () {
      document.title = this.$t('objects.page_title.patient').toString()
      this.patient_id = this.$route.params.id
      this.reservation_id = this.$route.query.reservation_id
      if (this.reservation_id && this.$route.query.tab_active === 'reservation_schedule') {
        this.tab_active = 'reservation_schedule'
      }
    },
    methods: {
      handleEditPatient () {
        this.$router.push({ name: 'patient.edit', params: { id: this.patient_id }, query: { reservation_id: this.reservation_id } })
      },
      handleDeletePatient: function () {
        this.is_show_modal_delete_patient = true
      },
      showModalEditHistory () {
        this.is_show_modal_edit_history = true
      },
      submitDeletedPatient: function () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('deletePatient', this.patient_id).then((result) => {
          this.$store.commit('set_loading', false)
          const { data: { status } } = result
          if (status === RESPONSE_STATUS.OK) {
            this.$store.commit('reset_patientState')
            this.$router.push({ name: 'patient.list' })
          } else {
            showErrorModal(this.$t('new_message.something_went_wrong'))
          }
        }).catch(() => {
          this.$store.commit('set_loading', false)
          showErrorModal(this.$t('new_message.something_went_wrong'))
        })
      }
    }
  }
</script>
