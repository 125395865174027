/**
 * 200 - OK
 * Standard response for successful HTTP requests. The actual response will depend on the request method used.
 * In a GET request, the response will contain an entity corresponding to the requested resource. In a POST
 * request, the response will contain an entity describing or containing the result of the action.
 */
export const OK = 200

/*
   * Client Error codes (4xx)
   */
/**
 * 400 - Bad Request
 * The server cannot or will not process the request due to an apparent client error (e.g., malformed request
 * syntax, size too large, invalid request message framing, or deceptive request routing).
 */
export const BAD_REQUEST = 400

/**
 * 401 - Unauthorized
 * Similar to 403 Forbidden, but specifically for use when authentication is required and has failed or has not
 * yet been provided. The response must include a WWW-Authenticate header field containing a challenge applicable
 * to the requested resource. See Basic access authentication and Digest access authentication. 401 semantically
 * means "unauthenticated", i.e. the user does not have the necessary credentials.
 * Note: Some sites issue HTTP 401 when an IP address is banned from the website (usually the website domain)
 * and that specific address is refused permission to access a website.
 */
export const UNAUTHORIZED = 401

/**
 * 403 - Forbidden
 * The request was valid, but the server is refusing action. The user might not have the necessary permissions
 * for a resource, or may need an account of some sort.
 */
export const FORBIDDEN = 403

/**
 * 404 - Not Found
 * The requested resource could not be found but may be available in the future. Subsequent requests by the
 * client are permissible.
 */
export const NOT_FOUND = 404

/*
   * Server Error codes (5xx)
   */
/**
/**
 * 500 - Internal Server Error
 * A generic error message, given when an unexpected condition was encountered and no more specific message
 * is suitable.
 */
export const INTERNAL_SERVER_ERROR = 500

export const NOT_IMPLEMENTED = 501
