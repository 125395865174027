<template>
  <modal-action
    class="select-reason-for-non-contract"
    :title="$t('objects.patient.text.modal_reason_for_non_contract_title')"
    @close="setReasonForNonContractModalStatus(false)"
  >
    <template #body>
      <div class="select-reason-for-non-contract__content">
        <ListReasonBlock
          v-for="data in reasons"
          :key="data.id"
          :title="$t(data.title)"
          :reasons="data.items"
          :other-reason="other_reason"
          :selected-id="selected_reason"
          :errors="errors"
          @choose-reason="chooseReason"
          @input-other="inputOther"
        />
      </div>
    </template>
    <template #footer>
      <button-common
        class="select-reason-for-non-contract__btn-submit"
        @click="handleSubmit"
      >
        <template #content>
          {{ $t('new_text.button.decision') }}
        </template>
      </button-common>
      <button-common
        class="select-reason-for-non-contract__btn-close"
        @click="setReasonForNonContractModalStatus(false)"
      >
        <template #content>
          {{ $t('new_text.button.back') }}
        </template>
      </button-common>
    </template>
  </modal-action>
</template>

<script>
  import ButtonCommon from '../../../common/Button.vue'
  import ModalAction from '../../../common/ModalAction.vue'
  import ListReasonBlock from './ListReasonBlock.vue'
  import { OTHER_REASON_PREFIX } from '../../../../utils/constants'
  import { mbtrim } from '../../../../utils/helpers'

  export default {
    components: { ListReasonBlock, ModalAction, ButtonCommon },
    data () {
      return {
        reasons: [
          {
            id: 1,
            title: 'new_text.on_hold',
            items: [
              {
                id: 1,
                name: '他院でも検討',
                key: 'new_text.considered_at_other_hospitals'
              },
              {
                id: 2,
                name: '持ち帰り検討',
                key: 'new_text.consider_taking_it_home'
              }
            ]
          },
          {
            id: 2,
            title: 'new_text.other_orthodontic_treatments',
            items: [
              {
                id: 3,
                name: 'ワイヤー矯正',
                key: 'new_text.wire_orthodontics'
              },
              {
                id: 4,
                name: 'インビザライン',
                key: 'new_text.invisalign'
              },
              {
                id: 5,
                name: '他のマウスピース矯正',
                key: 'new_text.other_mouthpiece_orthodontics'
              }
            ]
          },
          {
            id: 3,
            title: 'new_text.no_contract',
            items: [
              {
                id: 6,
                name: '適応外',
                key: 'new_text.not_applicable'
              },
              {
                id: 7,
                name: '主訴改善困難',
                key: 'new_text.difficulty_improving_chief_complaint'
              },
              {
                id: 8,
                name: '料金的理由',
                key: 'new_text.fee_reason'
              }
            ]
          },
          {
            id: 4,
            title: 'new_text.other',
            items: [
              {
                id: 0,
                name: '未入力',
                key: 'new_text.not_entered'
              },
              {
                id: 9,
                name: 'その他',
                key: 'new_text.other'
              }
            ]
          }
        ],
        other_reason: '',
        selected_reason: 0,
        other_reason_id: 9,
        errors: {
          other_reason: ''
        }
      }
    },
    computed: {
      reason: function () {
        return this.$store.getters.get_reasonForNonContract
      }
    },
    created () {
      this.findReason()
    },
    methods: {
      chooseReason (reasonId) {
        this.errors.other_reason = ''
        this.selected_reason = reasonId
      },
      inputOther (value) {
        this.errors.other_reason = ''
        this.other_reason = value
      },
      findReason () {
        if (!this.reason) {
          return
        }

        if (this.reason.startsWith(OTHER_REASON_PREFIX)) {
          this.selected_reason = this.other_reason_id
          this.other_reason = this.reason.replace(OTHER_REASON_PREFIX, '')
          return
        }

        const allReasonItems = this.reasons.map(reason => reason.items).flat()
        this.selected_reason = allReasonItems.find(item => item.name === this.reason)?.id || 0
      },
      handleSubmit () {
        let submitReason = ''
        if (this.selected_reason === this.other_reason_id) {
          if (mbtrim(this.other_reason) === '') {
            this.errors.other_reason = this.$t('new_validation.patient.reason_for_non_contract_required')
            return
          }

          submitReason = `${OTHER_REASON_PREFIX}${this.other_reason}`
        } else if (this.selected_reason !== 0) {
          const allReasonItems = this.reasons.map(reason => reason.items).flat()
          submitReason = allReasonItems.find(item => item.id === this.selected_reason)?.name
        }

        this.$store.commit('set_reasonForNonContract', submitReason)
        this.setReasonForNonContractModalStatus(false)
      },
      setReasonForNonContractModalStatus (value) {
        this.$store.commit('set_reasonForNonContractModalStatus', value)
      }
    }
  }
</script>
